import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import FilterForm, { FilterFormResult } from 'components/LoanOriginationSystem/ApplicationsFilter/FilterForm';
import {
  changePopupFilters,
  clearPopupFilters,
  getProductIntermediaries,
  getProductLabels,
  toggleFiltersPopup,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import useViewType from 'hooks/useViewType';
import { ViewType } from 'LoanOriginationSystemApplications/Types';

const ApplicationsFilter = () => {
  const dispatch = useDispatch();
  const { selectedProduct, filters } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications);
  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, selectedProduct?.id)) || [];

  const viewType = useViewType();

  const onApply = (result: FilterFormResult) => {
    dispatch(
      changePopupFilters({
        ...result,
        statusesIds: result.statuses.map(({ id }) => id),
        viewType,
      }),
    );
  };

  const onClosePopup = () => {
    dispatch(toggleFiltersPopup());
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters(viewType));
  };

  useEffect(() => {
    dispatch(getProductLabels(selectedProduct!.name));
    dispatch(getProductIntermediaries(selectedProduct!.name));
  }, []);

  return (
    <FilterLayout onClose={onClosePopup} onClearFiltersClick={onClearFiltersClick}>
      <FilterForm
        statuses={applicationStatuses}
        selectedStatusesIds={filters.selectedStatusesIds}
        labels={filters.labels}
        selectedLabels={filters.selectedLabels}
        intermediaries={filters.intermediaries}
        selectedIntermediaries={filters.selectedIntermediaries}
        createdDateFrom={filters.createdDateRange.from}
        createdDateTo={filters.createdDateRange.to}
        updatedDateFrom={filters.updatedDateRange.from}
        updatedDateTo={filters.updatedDateRange.to}
        onApply={onApply}
        hideStatusFilter={viewType === ViewType.Column}
      />
    </FilterLayout>
  );
};

export default ApplicationsFilter;
