import { AnyAction, isPending, isFulfilled, isRejected } from '@reduxjs/toolkit';

const hasPrefix = (action: AnyAction, prefix: string) => action.type.startsWith(prefix);

const isThunkActionWithPrefix = (isOfType: (action: any) => action is any) => (
  prefix: string,
  actionTypesToSkip: string[] = [],
) => (action: AnyAction): action is AnyAction =>
  hasPrefix(action, prefix) && isOfType(action) && !actionTypesToSkip.some((type) => action.type.includes(type));

export const isPendingActionWithPrefix = isThunkActionWithPrefix(isPending);
export const isRejectedActionWithPrefix = isThunkActionWithPrefix(isRejected);
export const isFulfilledActionWithPrefix = isThunkActionWithPrefix(isFulfilled);
