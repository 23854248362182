enum OrganizationActionType {
  GetOrganizationInfo = 'organization/getOrganizationDetailedInfo',
  GetOrganizationInfoSuccess = 'organization/getOrganizationDetailedInfoSuccess',
  GetOrganizationInfoFailure = 'organization/getOrganizationDetailedInfoFailure',
  ChangeOrganizationInfoRequest = 'organization/changeOrganizationInfoRequest',
  ChangeOrganizationInfoSuccess = 'organization/changeOrganizationInfoSuccess',
  ChangeOrganizationInfoFailure = 'organization/changeOrganizationInfoFailure',
  GetSeats = 'organization/getSeats',
  GetSeatsSuccess = 'organization/getSeatsSuccess',
  GetSeatsFailure = 'organization/getSeatsFailure', 
  GetFreeSeat = 'organization/getFreeSeat',
  GetFreeSeatSuccess = 'organization/getFreeSeatSuccess',
  GetFreeSeatFailure = 'organization/getFreeSeatFailure',
  OpenSeatsAreFullPopUp = 'organization/openSeatsAreFullPopUp',
  CloseSeatsAreFullPopUp = 'organization/closeSeatsAreFullPopUp',
}

export default OrganizationActionType;
