import React, { FC } from 'react';
import Button from 'components/Button';
import QuestionIcon from 'components/QuestionIcon';
import PageName from 'constants/PageName';
import styles from './PermissionGroupsPageWrapper.module.scss';

export interface PermissionGroupsPageWrapperProps {
  children: React.ReactNode;
  onEditPermissionGroupsClick: () => void;
}

const PermissionGroupsPageWrapper: FC<PermissionGroupsPageWrapperProps> = (props) => {
  const { onEditPermissionGroupsClick, children } = props;

  return (
    <div className={styles.permissionGroupsPage}>
      <div className={styles.permissionGroupsHeader}>
        <div className={styles.permissionGroupsHeader__title}>
          <h2>{PageName.PermissionGroups}</h2>
          <QuestionIcon
            className={styles.tooltip}
            size={24}
            tooltip="Create custom permission types and set platform access levels"
            tooltipPosition="top"
          />
        </div>
        <div>
          <Button kind="primary" size="default" onClick={onEditPermissionGroupsClick}>
            Edit Permission Groups
          </Button>
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
};

export default PermissionGroupsPageWrapper;
