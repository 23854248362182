import intermediariesPagination from 'components/LoanOriginationSystem/IntermediariesDashboard/Pagination';
import { LoanOriginationSystemIntermediariesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

export default (api: LoanOriginationSystemIntermediariesApi) => {
  return intermediariesPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getIntermediaries(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        dueCreatedDateRange: params.dueCreatedDateRange,
        dueUpdatedDateRange: params.dueUpdatedDateRange,
        borrowerTypes: params.borrowerTypes,
        members: params.selectedMembers,
      },
      params.sortingType,
    );
  });
};
