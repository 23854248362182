import React from 'react';
import styles from './DetailsCard.module.scss';
import { SkeletonText } from 'components/Skeleton';
import SkeletonCardsForm from 'components/LoanOriginationSystem/CardsForm/SkeletonCardsForm';

const LAYOUT = [[5], [3]];

const DetailsCardSkeleton = () => {
  return (
    <div className={styles.container}>
      <SkeletonText lineHeight="36px" lineWidth="300px" width="300px" height="24px" color="primary20" marginBottom="14px" />
      <SkeletonCardsForm layout={LAYOUT} textColor="primary10" />
    </div>
  );
};

export default DetailsCardSkeleton;
