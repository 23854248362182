import React from 'react';
import styles from './StatusHeader.module.scss';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import WrapperWithTooltip from 'components/Tooltip';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

interface StatusHeaderProps {
  title: string;
  currencySymbol: string;
  applications: SimplifiedApplication[] | null;
}

const StatusHeader = ({ title, applications, currencySymbol }: StatusHeaderProps) => {
  const totalAmount = applications?.reduce(
    (total, current) => total + (current.variables[ApplicationDefaultVariable.LoanAmount] as number),
    0,
  );
  const loanAmountDisplayValue = formatMonetaryValue(totalAmount || 0, currencySymbol);

  return (
    <div>
      <div className={styles.statusHeader__line}>
        <WrapperWithTooltip tooltip={title}>
          <h5 className={styles.statusHeader__line__title}>{title}</h5>
        </WrapperWithTooltip>
        <p className={styles.statusHeader__line__count}>{applications?.length || 0}</p>
      </div>
      <p className={styles.statusHeader__subTitle}>{loanAmountDisplayValue}</p>
    </div>
  );
};

export default StatusHeader;
