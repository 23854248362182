import React, { FC, useState, useEffect, useMemo } from 'react';
import { UpdateWebhookEndpointParams, WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import useInputValidation from 'hooks/useInputValidation';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import DetailsForm from 'components/DetailsForm';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import TextInput from 'components/TextInput';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import areArraysEqual from 'utils/areArraysEqual';
import { validateUrl } from 'utils/validation/validation';
import styles from './WebhookEndpointOverview.module.scss';

export interface WebhookEndpointOverviewProps {
  webhookEndpoint: WebhookEndpoint | null;
  onUpdateWebhookEndpoint: (webhookEndpointId: string, params: Partial<UpdateWebhookEndpointParams>) => Promise<void>;
  isUpdateInProgress?: boolean;
}

const URL_VALIDATORS = [validateUrl];

const WebhookEndpointOverview: FC<WebhookEndpointOverviewProps> = ({
  webhookEndpoint,
  onUpdateWebhookEndpoint,
  isUpdateInProgress,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [events, setEvents] = useState<string[]>([]);
  const [eventsInputWasFocused, setEventsInputWasFocused] = useState(false);
  const [eventsInputBlurred, setEventsInputBlurred] = useState(false);

  const isFormDataWasChanged = () => {
    if (!webhookEndpoint) {
      return false;
    }

    return url !== webhookEndpoint.url
      || description !== webhookEndpoint.description
      || !areArraysEqual(events, webhookEndpoint.events);
  };

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => isFormDataWasChanged());

  const eventsMultiselectInputError = useMemo(() => {
    return events.length ? '' : 'This field is required';
  }, [events]);

  useEffect(() => {
    if (!webhookEndpoint) {
      return;
    }

    setUrl(webhookEndpoint.url);
    setDescription(webhookEndpoint.description || '');
    setEvents(webhookEndpoint.events);
  }, [webhookEndpoint]);

  const handleSwitchEditMode = useCallbackWithUnsavedChanges(() => {
    if (isEditMode && webhookEndpoint) {
      setUrl(webhookEndpoint.url);
      setDescription(webhookEndpoint.description || '');
      setEvents(webhookEndpoint.events);
    }

    setIsEditMode(!isEditMode);
  });

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => setUrl(event.currentTarget.value);
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => setDescription(event.currentTarget.value);

  const handleSelectEvent = (eventType: string) => {
    setEvents([...events, eventType]);
  };

  const handleDeselectEvent = (eventType: string) => {
    setEvents(events.filter((event) => event !== eventType));
  };

  const handleSaveChanges = async () => {
    if (!webhookEndpoint) {
      return;
    }

    await onUpdateWebhookEndpoint(webhookEndpoint.id, {
      ...(url !== webhookEndpoint.url ? { url } : {}),
      ...(description !== webhookEndpoint.description ? { description } : {}),
      ...(!areArraysEqual(events, webhookEndpoint.events) ? { events } : {}),
    });

    setIsEditMode(false);
  };

  const [
    onUrlFieldBlur,
    onUrlFieldFocus,
    urlErrorMessage,
    isUrlFieldValid,
  ] = useInputValidation({
    validators: URL_VALIDATORS,
    value: url,
    onChange: (value) => setUrl(value),
    required: true,
    disableResetValueOnError: true,
  });

  const isFormDataInvalid = () => {
    return !isUrlFieldValid || !events.length;
  };

  const fields = webhookEndpoint
    ? [{
      label: 'URL',
      value: webhookEndpoint.url,
    }, {
      label: 'Description',
      value: webhookEndpoint.description,
    }, {
      label: 'Listening For',
      value: webhookEndpoint.events.join(', '),
    }] : null;

  return (
    <>
      <DetailsForm
        title="Overview"
        labelWidth="150px"
        fields={fields}
        isEditMode={isEditMode}
        onSwitchEditMode={handleSwitchEditMode}
        isSaveChangesButtonDisabled={!isFormDataWasChanged() || isFormDataInvalid()}
        isSaveChangesInProgress={isUpdateInProgress}
        onSaveChanges={handleSaveChanges}
      >
        <TextInput
          containerClassName={styles.textInputContainer}
          labelTitle="URL"
          value={url}
          onChange={handleUrlChange}
          onBlur={onUrlFieldBlur}
          onFocus={onUrlFieldFocus}
          errorMessage={urlErrorMessage}
        />
        <TextInput
          containerClassName={styles.textInputContainer}
          labelTitle="Description"
          value={description}
          onChange={handleDescriptionChange}
        />
        <EventTypeMultiselect
          labelTitle="Listening For"
          id="listeningFor"
          selectedEventTypes={events}
          onSelectEventType={handleSelectEvent}
          onDeselectEventType={handleDeselectEvent}
          onBlur={() => setEventsInputBlurred(true)}
          onFocus={() => setEventsInputWasFocused(true)}
          errorMessage={(eventsInputBlurred && eventsInputWasFocused) ? eventsMultiselectInputError : ''}
        />
      </DetailsForm>
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChanges}
        usePortal
      />}
    </>
  );
};

export default WebhookEndpointOverview;
