import { TabSwitchOption } from 'components/TabSwitch';

export enum CompanyGeneralSettingsTab {
  CompanyInformation = 'CompanyInformation',
  RegionSettings = 'RegionSettings',
}

export const COMPANY_GENERAL_SETTINGS_TABS: TabSwitchOption[] = [
  { id: CompanyGeneralSettingsTab.CompanyInformation, label: 'Company Information' },
  { id: CompanyGeneralSettingsTab.RegionSettings, label: 'Region Settings' },
];

export const COMPANY_GENERAL_SETTINGS_TAB_ROUTES = {
  [CompanyGeneralSettingsTab.CompanyInformation]: 'company-information',
  [CompanyGeneralSettingsTab.RegionSettings]: 'region-settings',
};
