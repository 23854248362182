import React, { FC } from 'react';
import clsx from 'clsx';
import QuestionIcon from 'components/QuestionIcon';
import styles from './ApplicationAutomationCard.module.scss';

export interface ApplicationAutomationCardProps {
  title?: string;
  tooltip?: React.ReactNode;
  cardBodyClassName?: string;
  actions?: React.ReactNode;
}

const ApplicationAutomationCard: FC<ApplicationAutomationCardProps> = ({
  title,
  tooltip,
  children,
  cardBodyClassName,
  actions,
}) => (
  <div className={styles.cardContainer}>
    <div className={styles.header}>
      <div className={styles.titleWithTooltip}>
        <h4>{title}</h4>
        {tooltip && <QuestionIcon className={styles.questionIcon} tooltip={tooltip} size={24} />}
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
    <div className={clsx(styles.cardBody, cardBodyClassName)}>{children}</div>
  </div>
);

export default ApplicationAutomationCard;
