import labelsPagination from 'components/LoanOriginationSystem/LabelsDetails/Pagination';
import LoanOriginationSystemLabelsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

export default (api: LoanOriginationSystemLabelsRestApi) => {
  return labelsPagination.getItemsFetcherMiddleware(async (params) => {
    const { page, perPage, search, sortingType } = params;
    return api.getLabels({
      filters: {
        count: perPage,
        offset: (page - 1) * perPage,
        search,
      },
      sortingType,
    });
  });
};
