import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BaseCalculation, CreateCalculationParams, UpdateCalculationParams } from './Base/CalculationsApi';

export interface ProductCalculation extends BaseCalculation {
  productId: string;
  createdAt: Date;
}

export interface CreateProductCalculationParams extends CreateCalculationParams {
  productId: string;
}

export interface ProductCalculationsApi {
  getByProduct(productId: string): Promise<ProductCalculation[]>;
  create(params: CreateProductCalculationParams): Promise<ProductCalculation>;
  update(id: string, params: UpdateCalculationParams): Promise<ProductCalculation>;
  delete(id: string): Promise<void>;
}

export default class ProductCalculationsRestApi extends LoanOriginationSystemApi<ProductCalculation>
  implements ProductCalculationsApi {
  protected resourceName = 'product-calculations';

  public getByProduct(productId: string): Promise<ProductCalculation[]> {
    const params = new URLSearchParams();

    params.set('productId', productId);

    return this.fetch(`/${this.resourceName}/?${params}`);
  }

  public create(params: CreateProductCalculationParams) {
    return this.createResource({
      calculation: {
        ...params,
      },
    });
  }

  public update(id: string, params: UpdateCalculationParams) {
    return this.updateResource(id, {
      calculation: { ...params },
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
