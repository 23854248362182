import { AnyAction } from 'redux';
import {
  GET_API_ACTIVATIONS_REQUEST,
  GET_API_ACTIVATIONS_SUCCESS,
  GET_API_ACTIVATIONS_FAILURE,
  UPDATE_API_ACTIVATIONS_REQUEST,
  UPDATE_API_ACTIVATIONS_SUCCESS,
  OPEN_API_ACTIVATIONS_POPUP,
  CLOSE_API_ACTIVATIONS_POPUP,
  ApiActivationActionType,
} from './ActionTypes';
import { ApiActivationsState } from './Types';
import pagination from 'pages/ApiActivation/Pagination';
import { ApiActivationSortingFields } from 'DecisionStrategy/DecisionStrategiesTypes';
import apiActivationFilterAndSortingSaver from './FilterAndSortingSaver';
import { setItemsTotal } from 'utils/reducers/setStateField';

export const PER_PAGE_DEFAULT = 100;

const getInitialState = (): ApiActivationsState => ({
  items: [],
  page: 1,
  itemsTotal: undefined,
  itemsPerPage: PER_PAGE_DEFAULT,
  updaters: [],
  sortingType: apiActivationFilterAndSortingSaver.getSavedSorting() || {
    field: ApiActivationSortingFields.StrategyName,
    ascending: true,
  },
  selectedUpdaters: [],
  searchInputValue: '',
  searched: {},
  showArchived: false,
  versionsStatuses: ['active', 'testing'],
  isLoading: false,
  isPopUpOpen: false,
  popUpType: 'change',
  currentGroup: null,
  versionsList: [],
  ...apiActivationFilterAndSortingSaver.getSavedFilters(),
});

const ApiActivationReducer = (state = getInitialState(), action: AnyAction): ApiActivationsState => {
  switch (action.type) {
    case GET_API_ACTIVATIONS_REQUEST: {
      return {
        ...getInitialState(),
        showArchived: state.showArchived,
        searchInputValue: state.searchInputValue,
        selectedUpdaters: state.selectedUpdaters,
        updaters: state.updaters,
        ...action.payload,
        isLoading: true,
      };
    }
    case GET_API_ACTIVATIONS_SUCCESS: {
      const { perPage, total, searchInputValue, items, users, selectedUpdaters } = action.payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal: total,
              page: 1,
              itemsPerPage: PER_PAGE_DEFAULT,
              showArchived: state.showArchived,
              updaters: users,
              selectedUpdaters,
            },
          },
        };
      }
      return {
        ...state,
        itemsTotal: total,
        itemsPerPage: perPage,
        items,
        updaters: users,
        selectedUpdaters,
        isLoading: false,
      };
    }
    case ApiActivationActionType.ClearApiActivationData:
    case GET_API_ACTIVATIONS_FAILURE: {
      return getInitialState();
    }
    case UPDATE_API_ACTIVATIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_API_ACTIVATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case OPEN_API_ACTIVATIONS_POPUP: {
      const { popUpType, activationInfo } = action.payload;

      return {
        ...state,
        isPopUpOpen: true,
        popUpType,
        currentGroup: activationInfo,
      };
    }
    case CLOSE_API_ACTIVATIONS_POPUP: {
      return {
        ...state,
        isPopUpOpen: false,
        currentGroup: null,
        versionsList: [],
      };
    }
    case ApiActivationActionType.GetDropdownGroupedStrategiesSuccess: {
      const { strategies } = action.payload;
      return {
        ...state,
        versionsList: strategies,
      };
    }
    case ApiActivationActionType.ClearDropdownStrategiesList: {
      return {
        ...state,
        versionsList: getInitialState().versionsList,
      };
    }
    case ApiActivationActionType.SetStrategyGroupsSearchInput: {
      return {
        ...state,
        searchInputValue: action.payload,
        isLoading: true,
      };
    }
    case ApiActivationActionType.SetShowArchived: {
      return {
        ...state,
        ...setItemsTotal(state, state.searchInputValue ? ['searched', state.searchInputValue] : []),
        showArchived: action.payload,
        isLoading: true,
      };
    }
    case ApiActivationActionType.SetUpdaters: {
      return {
        ...state,
        ...setItemsTotal(state, state.searchInputValue ? ['searched', state.searchInputValue] : []),
        selectedUpdaters: action.payload,
        isLoading: true,
      };
    }
    case ApiActivationActionType.SortStrategies: {
      return {
        ...state,
        sortingType: action.payload,
      };
    }
    case ApiActivationActionType.RequestDropdownStrategiesSuccess: {
      return {
        ...state,
        versionsList: action.payload,
      };
    }
    default:
      return state;
  }
};

export default pagination.wrapReducer<ApiActivationsState>(ApiActivationReducer);
