import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { AvatarIcon, DuplicateImage } from 'static/images';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import { DATE_FORMAT } from 'LoanOriginationSystemApplications/constants';
import formatDate from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import { TagLine } from 'components/LoanOriginationSystem/ApplicationLabels';
import SearchHighlight from 'components/SearchHighlight';
import { useHistory } from 'react-router';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import styles from './Card.module.scss';
import { RowActionsContainer } from 'components/RowActions';
import WrapperWithTooltip from 'components/Tooltip';
import ApplicationRowActions from 'components/LoanOriginationSystem/ApplicationsDashboard/ApplicationRowActions/ApplicationRowActions';
import { formatMonetaryValueWithAccessPermissions } from 'utils/formatMonetaryValue';
import useVariableAccessPermissionsBySystemName from 'Variables/useVariableAccessPermissionsBySystemName';
import WrapperWithLink from 'components/WrapperWithLink';
import CoBorrowerInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation';
import { DefaultApplicationStatusName } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { useInView } from 'react-intersection-observer';
import { BLANK_BORROWER_FULL_NAME } from 'LoanOriginationSystemBorrowers/utils';
import { DuplicateApplicationParams } from 'LoanOriginationSystemApplications/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

interface CardProps extends SimplifiedApplication {
  className?: string;
  currencySymbol: string;
  highlightString: string;
  updatingState: ApplicationUpdatingState | null;
  disabled?: boolean;
  onApplicationUpdatingStateReset: (applicationId: string) => void;
  deleteDisabled: boolean;
  onEditApplication: (displayId: string) => void;
  onDeleteApplication: (id: string) => void;
  onDuplicateApplication: (params: DuplicateApplicationParams) => void;
}

const MAX_AVATARS_COUNT = 3;
const APPLICATION_FAILURE_RESET_TIMEOUT = 5800;

const Card = React.memo(({
  className,
  currencySymbol,
  highlightString,
  updatingState,
  disabled,
  onApplicationUpdatingStateReset,
  deleteDisabled,
  onEditApplication,
  onDeleteApplication,
  onDuplicateApplication,
  ...data
}: CardProps) => {
  const { ref, inView } = useInView({ threshold: 0 });

  const [shouldShowApprovalAnimation, setShouldShowApprovalAnimation] = useState(false);

  const containerRef = useRef<HTMLElement | null>(null);
  const history = useHistory();
  const onClick = () => {
    if (disabled) {
      return;
    }

    history.push(`/los/applications/${data.displayId}`);
  };

  const loanAmountAccessPermissions = useVariableAccessPermissionsBySystemName(ApplicationDefaultVariable.LoanAmount);
  const loanAmount = (data.variables[ApplicationDefaultVariable.LoanAmount] as number | null);
  const loanAmountDisplayValue = typeof loanAmount === 'number'
    ? formatMonetaryValueWithAccessPermissions(loanAmountAccessPermissions, loanAmount, currencySymbol)
    : '$-';

  const renderTeamMembersSection = () => {
    if (!data.teamMembers.length) {
      return (
        <WrapperWithTooltip tooltip="Unassigned">
          <AvatarIcon className={styles.avatarIcon} />
        </WrapperWithTooltip>
      );
    }

    return (
      <UserAvatarInlineList
        source={data.teamMembers}
        size="small"
        maxCount={MAX_AVATARS_COUNT}
        className={styles.members}
      />
    );
  };

  const handleLoaderStateReset = useCallback(() => {
    onApplicationUpdatingStateReset(data.id);
    setShouldShowApprovalAnimation(data.status.name === DefaultApplicationStatusName.Approved);
  }, [data.id, data.status.name]);

  const handleApprovalAnimationComplete = () => {
    setShouldShowApprovalAnimation(false);
  };

  const onEdit = useCallback(() => onEditApplication(data.displayId), [data.displayId]);
  const onDelete = useCallback(() => onDeleteApplication(data.id), [data.id]);
  const onDuplicate = useCallback(() => onDuplicateApplication({
    id: data.id,
    originalApplicationId: data.originalApplicationId,
    displayId: data.displayId,
    borrowerFullName: data.borrowerFullName,
  }), [data.id]);

  const renderBody = () => {
    return <>
      <div className={styles.body}>
        <div>
          <LoaderWithState
            className={styles.loader}
            onStateReset={handleLoaderStateReset}
            failureTimeout={APPLICATION_FAILURE_RESET_TIMEOUT}
            state={updatingState as LoaderState | null}
          />
          <div className={styles.card__labels}>
            {data.labels.map((label) => (
              <TagLine key={label.id} {...label} />
            ))}
          </div>
          <div className={styles.card__title}>
            <div className={clsx(!data.borrowerFullName && styles.blankBorrowerFullName)}>
              <SearchHighlight search={highlightString} multiWord>
                {data.borrowerFullName || BLANK_BORROWER_FULL_NAME}
              </SearchHighlight>
            </div>
            <CoBorrowerInfo coBorrowerFullNames={data.coborrowerFullNames} />
          </div>
          <p className={styles.card__subTitle}>
            <SearchHighlight search={highlightString} multiWord>
              {formatDisplayId(data.displayId)}
            </SearchHighlight>{' '}
            {data.originalApplicationId && (
              <WrapperWithTooltip tooltip="Duplicated Application">
                <DuplicateImage className={styles.duplicateIcon} />
              </WrapperWithTooltip>
            )}
            <span className={clsx(styles.dot, data.originalApplicationId && styles.hasIcon)}>&middot;</span>
            {formatDate(data.createdAt, DATE_FORMAT)}
          </p>
        </div>
        {!updatingState && (
          <ApplicationRowActions
            onEdit={onEdit}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            className={styles.moreButton}
            deleteDisabled={deleteDisabled}
          />
        )}
      </div>
      <div className={styles.card__footer}>
        <h5 className={styles.card__value}>{loanAmountDisplayValue}</h5>
        {renderTeamMembersSection()}
      </div>
    </>;
  };

  const combinedContainerRef = useCallback((el: HTMLElement | null) => {
    containerRef.current = el;
    ref(el);
  }, []);

  return (
    <RowActionsContainer>
      <WrapperWithLink
        href={`/los/applications/${data.displayId}`}
        className={styles.card__wrappingLink}
        onClick={onClick}
      >
        <div className={clsx(styles.card, className)} ref={combinedContainerRef}>
          {inView ? renderBody() : null}
          {shouldShowApprovalAnimation && (
            <ConfettiAnimation onAnimationComplete={handleApprovalAnimationComplete} targetElementRef={containerRef} />
          )}
        </div>
      </WrapperWithLink>
    </RowActionsContainer>
  );
});

export default Card;
