import { AnyAction } from 'redux';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { ApplicationsSortingType, TableViewData } from 'api/LoanOriginationSystem/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemIntermediaryDetailsActionTypes } from './ActionTypes';
import { IntermediaryDetailsTab } from 'components/LoanOriginationSystem/IntermediaryDetails';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

export interface GetIntermediaryData extends AnyAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryData;
  payload: {
    id: string;
  };
}

export interface GetIntermediaryDataSuccess extends AnyAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryDataSuccess;
  payload: {
    data: Intermediary;
  };
}

export interface ResetIntermediaryDetails extends AnyAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.ResetIntermediaryDetails;
}

export interface GetIntermediaryApplicationsAction extends AnyAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplications;
  payload: {
    intermediaryId: string;
    sortingType: ApplicationsSortingType;
  };
}

export interface GetIntermediaryApplicationsSuccessAction extends AnyAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplicationsSuccess;
  payload: TableViewData<SimplifiedApplication>;
}

export interface SortIntermediaryApplicationsAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.SortIntermediaryApplications;
  payload: {
    intermediaryId: string;
    sortingType: ApplicationsSortingType;
  };
}

export interface SelectIntermediaryViewTabAction {
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.SelectIntermediaryViewTab;
  payload: {
    tab: IntermediaryDetailsTab;
  };
}

export const getIntermediaryData = (id: string): GetIntermediaryData => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryData,
  payload: {
    id,
  },
});

export const getIntermediaryDataSuccess = (data: Intermediary): GetIntermediaryDataSuccess => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryDataSuccess,
  payload: {
    data,
  },
});

export const resetIntermediaryDetails = (): ResetIntermediaryDetails => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.ResetIntermediaryDetails,
});

export const getIntermediaryApplications = (
  intermediaryId: string,
  sortingType: ApplicationsSortingType,
): GetIntermediaryApplicationsAction => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplications,
  payload: {
    intermediaryId,
    sortingType,
  },
});

export const getIntermediaryApplicationsSuccess = (
  data: TableViewData<SimplifiedApplication>,
): GetIntermediaryApplicationsSuccessAction => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplicationsSuccess,
  payload: data,
});

export const getIntermediaryDataFailure = FailedActionCreator(
  LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryDataFailure,
);

export const getIntermediaryApplicationsFailure = FailedActionCreator(
  LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplicationsFailure,
);

export const sortIntermediaryApplications = (
  intermediaryId: string,
  sortingType: ApplicationsSortingType,
): SortIntermediaryApplicationsAction => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.SortIntermediaryApplications,
  payload: {
    intermediaryId,
    sortingType,
  },
});

export const selectIntermediaryViewTab = (tab: IntermediaryDetailsTab): SelectIntermediaryViewTabAction => ({
  type: LoanOriginationSystemIntermediaryDetailsActionTypes.SelectIntermediaryViewTab,
  payload: { tab },
});
