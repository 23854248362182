import { BranchInfoState, RuleData } from 'BranchInfo/Types';
import { useQueryParams } from 'hooks/useQueryParam';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CalculationScriptData,
  CompleteCalculationScriptData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  RuleRequestData,
  SimpleOutputRequestData,
} from 'RuleCreate/Types';
import { closeEditRulePopUp, editCalculationScript, editRule, editSimpleOutput } from 'RuleEdit/ActionsCreator';
import EditRulePopUp from 'RuleEdit/EditRulePopUp';
import { ReduxState } from 'types/redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';

interface EditRulePopUpContainerProps {
  strategyId: string;
  handleOpenVariableCreation: () => void;
}

const EditRulePopUpContainer: FC<EditRulePopUpContainerProps> = ({ strategyId, handleOpenVariableCreation }) => {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const moduleKey = params.get('module-branch-key')!;
  const branchIndex = parseInt(params.get('branchIndex')!, 10);
  const branchKey = `${moduleKey}_${branchIndex}`;

  const { branchList } = useSelector<ReduxState, BranchInfoState>((state) => state.branchInfo);
  const currentBranch = branchList![branchKey]!;
  const { rules, conditions, calculationMode } = currentBranch!;
  const { ruleModuleType: currentRuleModuleType, ruleType, id, entityType } = useSelector(
    (state: ReduxState) => state.ruleEdit,
  );

  const currentRule = rules.find((rule) => rule.id === id) as RuleData;
  const currentCondition = conditions.find((rule) => rule.id === id) as RuleData;

  const onEditRule = async (data: RuleRequestData) => {
    await dispatchRoutine(
      editRule({ ...data, strategyId, moduleKey, branchIndex, id, entityType } as CompleteRuleRequestData),
    );
  };

  const onEditCalculationScript = async (calculationScriptData: CalculationScriptData): Promise<void> => {
    const { comparisonValue, propertyAttribute } = calculationScriptData;
    await dispatchRoutine(
      editCalculationScript({
        propertyAttribute,
        strategyId,
        moduleKey,
        comparisonValue,
        branchIndex,
        calculationMode,
        id,
      } as CompleteCalculationScriptData),
    );
  };

  const onEditSimpleOutput = async (simpleOutputData: SimpleOutputRequestData): Promise<void> => {
    await dispatchRoutine(
      editSimpleOutput({
        ...simpleOutputData,
        strategyId,
        moduleKey,
        branchIndex,
        id,
      } as CompleteSimpleOutputRequestData),
    );
  };

  const onClosePopUp = () => dispatch(closeEditRulePopUp());

  return (
    <EditRulePopUp
      moduleType={currentRuleModuleType}
      ruleType={ruleType}
      currentCondition={currentCondition}
      currentRule={currentRule}
      closePopUp={onClosePopUp}
      editRule={onEditRule}
      editCalculationScript={onEditCalculationScript}
      editSimpleOutput={onEditSimpleOutput}
      openVariableCreation={handleOpenVariableCreation}
      entityType={entityType}
      calculationMode={calculationMode}
    />
  );
};

export default EditRulePopUpContainer;
