import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DigifiApi from 'components/DigifiApi';
import MainLayout, { PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ReduxState } from 'types/redux';
import { closeGenerateFirstSecretWizard, openGenerateFirstSecretWizard } from 'GenerateFirstSecretWizard/Actions';
import { closeGenerateSecondSecretWizard, openGenerateSecondSecretWizard } from 'GenerateSecondSecretWizard/Actions';
import PageContent from 'MainLayout/PageContent';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import GenerateFirstSecretWizard from 'GenerateFirstSecretWizard/GenerateFirstSecretWizard';
import GenerateSecondSecretWizard from 'GenerateSecondSecretWizard/GenerateSecondSecretWizard';
import {
  GenerateFirstSecretRequest,
  VerifyPasswordRequest as VerifyPasswordForGenerateFirstSecret,
} from 'GenerateFirstSecret/GenerateFirstSecretActionCreator';
import {
  GenerateSecondSecretRequest,
  VerifyPasswordRequest as VerifyPasswordForGenerateSecondSecret,
} from 'GenerateSecondSecret/GenerateSecondSecretActionCreator';
import { GenerateFirstSecretWizardState } from 'GenerateFirstSecretWizard/generateFirstSecretWizardTypes';
import { GenerateSecondSecretWizardState } from 'GenerateSecondSecretWizard/generateSecondSecretWizardTypes';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';

const DigifiApiPage = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const { apiCredentials } = useOrganizationDetailedInfo();

  const generateFirstSecretWizard: GenerateFirstSecretWizardState = useSelector(
    (state: ReduxState) => state.generateFirstSecretWizard,
  );
  const generateSecondSecretWizard: GenerateSecondSecretWizardState = useSelector(
    (state: ReduxState) => state.generateSecondSecretWizard,
  );
  const openGenerateFirstSecret = () => {
    dispatch(openGenerateFirstSecretWizard());
  };
  const openGenerateSecondSecret = () => {
    dispatch(openGenerateSecondSecretWizard());
  };
  const generateFirstSecret = async () => dispatchRoutine(GenerateFirstSecretRequest());
  const generateSecondSecret = async () => dispatchRoutine(GenerateSecondSecretRequest());
  const verifyPasswordForGenerateFirstSecret = (password: string) =>
    dispatchRoutine(VerifyPasswordForGenerateFirstSecret(password));
  const verifyPasswordForGenerateSecondSecret = (password: string) =>
    dispatchRoutine(VerifyPasswordForGenerateSecondSecret(password));
  const closeGenerateFirstSecret = () => {
    dispatch(closeGenerateFirstSecretWizard());
  };
  const closeGenerateSecondSecret = () => {
    dispatch(closeGenerateSecondSecretWizard());
  };

  const getOverlay = (): ReactElement | null => {
    if (generateFirstSecretWizard.isOpen) {
      return (
        <GenerateFirstSecretWizard
          step={generateFirstSecretWizard.currentStepIndex}
          closePopUp={closeGenerateFirstSecret}
          title="Generate New Secret 1"
          verifyPassword={verifyPasswordForGenerateFirstSecret}
          generateFirstSecret={generateFirstSecret}
        />
      );
    }
    if (generateSecondSecretWizard.isOpen) {
      return (
        <GenerateSecondSecretWizard
          step={generateSecondSecretWizard.currentStepIndex}
          closePopUp={closeGenerateSecondSecret}
          title="Generate New Secret 2"
          verifyPassword={verifyPasswordForGenerateSecondSecret}
          generateSecondSecret={generateSecondSecret}
        />
      );
    }
    return null;
  };

  const leftNav = makeLeftNavigation(NavigationLinkId.DigiFiAPIKeys, ApplicationSectionName.CompanySettings);

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <DigifiApi
            apiCredentials={apiCredentials}
            openGenerateFirstSecret={openGenerateFirstSecret}
            openGenerateSecondSecret={openGenerateSecondSecret}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default DigifiApiPage;
