import React, { useEffect, useState } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import useSorting from 'hooks/useSorting';
import { useHistory } from 'react-router';
import Table, { TableActionCell } from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import ApplicationTableRow from './ApplicationTableRow';
import Pagination from 'components/Pagination/Pagination';
import SkeletonApplicationTableBody from './SkeletonApplicationTableBoby';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import {
  ApplicationsTableColumnSizesSmall,
  ApplicationsTableColumnSizesMedium,
  ApplicationsTableColumnSizesWide,
} from './TableColumnSizes';
import styles from './ApplicationsTable.module.scss';
import { ApplicationSortingField, ApplicationsSortingType } from 'api/Types';
import { StandardVariables } from 'Variables/VariablesTypes';
import { DuplicateApplicationParams } from 'LoanOriginationSystemApplications/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';
import useWindowSize from 'hooks/useWindowSize';

export interface ApplicationsTableProps {
  source: NullableItems<SimplifiedApplication>;
  currencySymbol: string | null;
  paginationProps: PaginationProps;
  standardVariables: StandardVariables | null;
  deleteDisabled: boolean;
  sortingType: ApplicationsSortingType;
  onSort: (field: ApplicationSortingField, ascending: boolean) => void;
  onDeleteApplication?: (displayId: string) => void;
  onEditApplication?: (id: string) => void;
  onDuplicateApplication?: (params: DuplicateApplicationParams) => void;
  searchInputValue: string;
}

export enum ApplicationsTableSize {
  Small = 'small',
  Medium = 'medium',
  Wide = 'wide',
}

const ApplicationsTableBreakpoints = {
  [ApplicationsTableSize.Medium]: 1670,
  [ApplicationsTableSize.Wide]: 1820,
};

export const ApplicationsTableColumnSizes = {
  [ApplicationsTableSize.Small]: ApplicationsTableColumnSizesSmall,
  [ApplicationsTableSize.Medium]: ApplicationsTableColumnSizesMedium,
  [ApplicationsTableSize.Wide]: ApplicationsTableColumnSizesWide,
};

const ApplicationsTable = ({
  source,
  currencySymbol,
  paginationProps,
  sortingType,
  standardVariables,
  deleteDisabled,
  onSort,
  onDeleteApplication,
  onEditApplication,
  onDuplicateApplication,
  searchInputValue,
}: ApplicationsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);
  const history = useHistory();

  const { width: windowWidth } = useWindowSize();
  const [tableSize, setTableSize] = useState(ApplicationsTableSize.Wide);

  useEffect(() => {
    if (!windowWidth) {
      return;
    }

    if (windowWidth >= ApplicationsTableBreakpoints[ApplicationsTableSize.Wide]) {
      setTableSize(ApplicationsTableSize.Wide);
      return;
    }

    if (windowWidth >= ApplicationsTableBreakpoints[ApplicationsTableSize.Medium]) {
      setTableSize(ApplicationsTableSize.Medium);
      return;
    }

    setTableSize(ApplicationsTableSize.Small);
  }, [windowWidth]);

  const onClick = (displayId: string) => {
    history.push(`/los/applications/${displayId}`);
  };

  const getApplicationTableBody = () => {
    return source.map((application, index) =>
      application && currencySymbol ? (
        <ApplicationTableRow
          standardVariables={standardVariables}
          key={application.id}
          application={application}
          currencySymbol={currencySymbol}
          deleteDisabled={deleteDisabled}
          onClick={onClick}
          onEdit={onEditApplication}
          onDelete={onDeleteApplication}
          onDuplicate={onDuplicateApplication}
          searchInputValue={searchInputValue}
          tableSize={tableSize}
        />
      ) : (
        <SkeletonApplicationTableBody tableSize={tableSize} key={index} showSearchMatch={searchInputValue.trim().length > 0} />
      ),
    );
  };

  return (
    <TableWrapperWithFooter>
      <div className={styles.table}>
        <Table>
          <TableHead sticky>
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].BorrowerFullName}
              ascending={getSortingType(ApplicationSortingField.BorrowerFullName)}
              onClick={() => changeSorting(ApplicationSortingField.BorrowerFullName)}
            >
              Borrower
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].DisplayId}
              ascending={getSortingType(ApplicationSortingField.DisplayId)}
              onClick={() => changeSorting(ApplicationSortingField.DisplayId)}
            >
              Application ID
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].CreatedAt}
              ascending={getSortingType(ApplicationSortingField.CreatedAt)}
              onClick={() => changeSorting(ApplicationSortingField.CreatedAt)}
            >
              Application Date
            </TableHeadCell>
            {tableSize !== ApplicationsTableSize.Small ? (
              <TableHeadCell
                width={ApplicationsTableColumnSizes[tableSize].UpdatedAt}
                ascending={getSortingType(ApplicationSortingField.UpdatedAt)}
                onClick={() => changeSorting(ApplicationSortingField.UpdatedAt)}
              >
                Last Updated
              </TableHeadCell>
            ) : null}
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].Status}
              ascending={getSortingType(ApplicationSortingField.Status)}
              onClick={() => changeSorting(ApplicationSortingField.Status)}
            >
              Status
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].BorrowerEmail}
              ascending={getSortingType(ApplicationSortingField.BorrowerEmail)}
              onClick={() => changeSorting(ApplicationSortingField.BorrowerEmail)}
            >
              Email
            </TableHeadCell>
            {tableSize === ApplicationsTableSize.Wide ? (
              <TableHeadCell
                width={ApplicationsTableColumnSizes[tableSize].BorrowerPhoneNumber}
                ascending={getSortingType(ApplicationSortingField.BorrowerPhoneNumber)}
                onClick={() => changeSorting(ApplicationSortingField.BorrowerPhoneNumber)}
              >
                Phone
              </TableHeadCell>
            ) : null}
            <TableHeadCell width={ApplicationsTableColumnSizes[tableSize].TeamMembers}>Team Members</TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableColumnSizes[tableSize].LoanAmount}
              className={styles.totalColumnHeadCell}
              ascending={getSortingType(ApplicationSortingField.LoanAmount)}
              onClick={() => changeSorting(ApplicationSortingField.LoanAmount)}
            >
              Loan Amount
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>{getApplicationTableBody()}</TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    </TableWrapperWithFooter>
  );
};

export default ApplicationsTable;
