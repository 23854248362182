import CoreSystemApi from 'api/Core/CoreSystemApi';
import {
  ProductSectionAccessPermissions,
  ProductSectionAccessPermissionsMap,
} from 'ProductSectionAccessPermissions/Types';

export interface IProductSectionAccessPermissionsApi {
  getAll(): Promise<ProductSectionAccessPermissions[]>;
  update(id: string, updateData: ProductSectionAccessPermissionsMap): Promise<ProductSectionAccessPermissions>;
}

export default class ProductSectionAccessPermissionsApi extends CoreSystemApi<ProductSectionAccessPermissions> implements IProductSectionAccessPermissionsApi {
  protected resourceName = 'product-permissions';

  public async getAll() {
    return this.getResources();
  }

  public async update(id: string, updateData: ProductSectionAccessPermissionsMap) {
    return this.fetch<ProductSectionAccessPermissions>(`/${this.resourceName}/${id}`, 'PATCH', updateData);
  }
}
