import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import { WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { deleteWebhookEvent, getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';

const webhookEndpointEventsTableUpdatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isFulfilled(deleteWebhookEvent)(action) && action.meta.actionOrigin === WebhookEventsActionOrigin.WebhookEndpointEventsTable) {
    const { webhookEndpointDetails: { webhookEndpointEventsTable } } = getState();

    dispatch(getWebhookEvents({
      filters: {
        search: webhookEndpointEventsTable.search,
        count: WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT,
        offset: 0,
        webhookId: action.payload.webhook.id,
      },
      sortingType: webhookEndpointEventsTable.sortingType,
      actionOrigin: WebhookEventsActionOrigin.WebhookEndpointEventsTable,
    }) as unknown as AnyAction);
  }

  return result;
}) as Middleware;

export default webhookEndpointEventsTableUpdatesMiddleware;
