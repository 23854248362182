import React, { FC, useEffect } from 'react';
import styles from 'components/LoanOriginationSystem/DecisionResultContextualView/DecisionResultContextualView.module.scss';
import { CloseButtonImage } from 'static/images';
import clsx from 'clsx';
import useStateReset from 'hooks/useStateReset';
import { DecisionState, requestDecision } from 'Decisions/DecisionStore';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { useDecisionResultApi } from 'providers/ApiServiceProvider';
import DecisionResultOverview from 'components/DecisionResultOverview';
import { ExtendedDecision } from 'api/DecisionEngine/DecisionApi';
import RowActions from 'components/RowActions';
import { DecisionActionTypes } from 'Decisions/actionTypes';
import DecisionResultOverviewSkeleton from 'components/DecisionResultOverview/DecisionResultOverviewSkeleton';

interface DecisionResultOverviewProps {
  decisionId: string;
  onClose: () => void;
  resultId?: string | null;
}

const DecisionResultContextualView: FC<DecisionResultOverviewProps> = ({
  decisionId,
  resultId,
  onClose,
}) => {
  const dispatch = useDispatch();
  useStateReset(DecisionActionTypes.ResetDecisionState);
  const decisionResultApi = useDecisionResultApi();

  const renderNotFoundDecision = () => (
    <>
      <div className={styles.notFoundHeader}>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <ErrorPageInfo errorType={ErrorPageType.NotFoundContextualView} errorTitle="The page was not found." />
    </>
  );

  const {
    data,
    loading,
    decisionId: loadedDecisionId,
    resultId: loadedResultId,
  } = useSelector<ReduxState, DecisionState>((state) => state.decision);

  useEffect(() => {
    if (!data || decisionId !== loadedDecisionId || resultId !== loadedResultId) {
      dispatch(requestDecision({ decisionId, resultId }));
    }
  }, [decisionId, resultId]);

  const handleDownloadResult = async (id: string) => {
    const response = await decisionResultApi.download(id);
    downloadBlobFile(response);
  }

  const renderHeader = (decisionData: ExtendedDecision) => (
    <div className={styles.header}>
      <h2>
        {decisionData.resultsInGroup > 1 ?`${decisionData.decisionName} (${decisionData.name})` : decisionData.decisionName}
      </h2>
      <div className={styles.actionsBtn}>
        <RowActions
          btnClassName={styles.actionsButton}
          alwaysVisible
          actions={[{
            title: 'Download Results',
            handler: () => handleDownloadResult(decisionData!.id),
            separatorRequired: true,
          }]}
        />
      </div>
      <CloseButtonImage onClick={onClose} className={styles.closeButton} />
    </div>
  );

  const renderContent = (decisionData: ExtendedDecision) => {
    return (
      <>
        {renderHeader(decisionData)}
        <DecisionResultOverview
          decisionData={decisionData}
          variablesContainerClassName={styles.variablesContainer}
        />
      </>
    );
  }

  return (
    <div className={clsx(styles.viewContainer)}>
      {data && renderContent(data)}
      {loading && <DecisionResultOverviewSkeleton />}
      {!data && !loading && renderNotFoundDecision()}
    </div>
  );
};

export default DecisionResultContextualView;
