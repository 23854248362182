import { useMemo } from 'react';
import { FormLayout } from 'api/LoanOriginationSystem/Types';
import sortByPosition from 'utils/sortByPosition';

interface BaseFormLayoutItem {
  position: number;
}

interface RowFormLayoutItem extends BaseFormLayoutItem {
  row: number;
}

interface ColumnFormLayoutItem extends BaseFormLayoutItem {
  column: number;
}

export type FormLayoutItem = RowFormLayoutItem | ColumnFormLayoutItem;

const DEFAULT_LENGTH = 0;

const isRowItem = (item: RowFormLayoutItem | ColumnFormLayoutItem): item is RowFormLayoutItem => {
  return typeof (item as RowFormLayoutItem).row !== 'undefined';
};

const useFormLayout = <Item extends FormLayoutItem>(
  items: Item[] | null,
  defaultLength = DEFAULT_LENGTH,
): FormLayout<Item> | null => {
  return useMemo(() => {
    if (!items) {
      return null;
    }

    if (!items.length) {
      return new Array(defaultLength).fill([]);
    }

    const maxIndex = Math.max(
      ...items.map((item) => {
        return isRowItem(item) ? item.row : (item as ColumnFormLayoutItem).column;
      }),
    );

    const layout: FormLayout<Item> = new Array(maxIndex + 1 > defaultLength ? maxIndex + 1 : defaultLength).fill([]);

    items.forEach((item) => {
      const field = isRowItem(item) ? item.row : (item as ColumnFormLayoutItem).column;

      layout[field] = [...layout[field], item];
    });

    return layout.map((layoutItem) => {
      return sortByPosition(layoutItem);
    });
  }, [defaultLength, items]);
};

export default useFormLayout;
