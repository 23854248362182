import { AssigneeTeamMembersType, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';

export const getTeamMemberIdsByProduct = (product: Product, currentUserId?: string) => {
  if (!currentUserId) {
    return null;
  }

  if (product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.OnCreate) {
    return [currentUserId];
  }

  if (product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.SpecificTeamMembers) {
    return product.settings.teamMembersToAssign;
  }

  return [];
};
