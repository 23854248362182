import React, { useEffect, useState } from 'react';
import { confirmAccountEmail } from 'AccountDetails/AccountDetailsActionCreator';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import useQueryParam from 'hooks/useQueryParam';
import { AppRoutes } from 'routes/RouteService';
import { Redirect } from 'react-router';
import VerificationLinkExpired from 'pages/VerificationLinkExpired';
import Loading from 'pages/Loading';
import { EmailVerificationResult } from 'api/Core/EmailVerificationApi';
import { resendEmailVerificationForExpiredToken } from 'EmailVerificationLinkExpired/Actions';
import { useHistory } from 'react-router-dom';

type ConfirmationState = EmailVerificationResult | null;

export const EMAIL_VERIFICATION_RESULT = Symbol('EMAIL_VERIFICATION_RESULT');

const ConfirmEmail = () => {
  const history = useHistory();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();
  const token = useQueryParam('activationToken')!;
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>(null);

  const confirmEmail = async () => {
    const {
      [EMAIL_VERIFICATION_RESULT as any]: result, // for some reason TypeScript disallows symbols for index access
    } = await dispatchRoutineWithResult(confirmAccountEmail(token));

    setConfirmationState(result as EmailVerificationResult);
  };

  useEffect(() => {
    confirmEmail();
  }, []);

  const resendVerificationEmail = async () => {
    await dispatchRoutine(resendEmailVerificationForExpiredToken(token));
    history.push(AppRoutes.SignIn);
  };

  switch (confirmationState) {
    case EmailVerificationResult.EmailVerified:
      return <Redirect to={AppRoutes.Home} />;
    case EmailVerificationResult.TokenExpired:
      return <VerificationLinkExpired onClickResendEmail={resendVerificationEmail} />;
    default:
      return <Loading />;
  }
};

export default ConfirmEmail;
