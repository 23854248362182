import React, { FC } from 'react';
import { ApplicationDetailsCard } from 'api/LoanOriginationSystem/ApplicationDetailsCardsApi';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCalculationsLogic from 'hooks/useCalculationsLogic';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import useFullApplicationData from 'components/LoanOriginationSystem/CreateApplication/Forms/useFullApplicationData';

interface CreateApplicationDetailsFormProps {
  cards: ApplicationDetailsCard[] | null;
  applicationDetailsFormData: FormLayoutData;
  borrowerFormData: FormLayoutData;
  intermediaryFormData: FormLayoutData;
  coBorrowersFormData: FormLayoutData[];
  selectedBorrower: Borrower | null;
  selectedCoBorrowers: Array<Borrower | null>;
  selectedIntermediary: Intermediary | null;
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  renderActions?: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  productCalculations: ProductCalculation[] | null;
}

const CreateApplicationDetailsForm: FC<CreateApplicationDetailsFormProps> = ({
  applicationDetailsFormData,
  borrowerFormData,
  intermediaryFormData,
  coBorrowersFormData,
  selectedIntermediary,
  selectedBorrower,
  selectedCoBorrowers,
  onFieldChange,
  cards,
  productCalculations,
  renderActions,
}) => {
  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    intermediaryFormData,
    coBorrowersFormData,
    selectedBorrower,
    selectedIntermediary,
    selectedCoBorrowers,
    applicationDetailsFormData,
  });

  const fullApplicationDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);

  const fields = useCardFields(cards);
  const validateApplicationsDetailsData = useConfigurableFormValidation(fields);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, fullApplicationDataWithCalculations);

  return (
    <>
      <CardsForm
        cards={cards}
        data={fullApplicationDataWithCalculations || {}}
        onFieldChange={onFieldChange}
        isEditMode
        displayHeader={false}
        displayFieldsAttributes={displayFieldsAttributes}
        displaySkeleton={!displayFieldsAttributes}
        calculations={productCalculations}
      />
      {renderActions?.(!cards, !validateApplicationsDetailsData(fullApplicationDataWithCalculations || {}, displayFieldsAttributes))}
    </>
  );
};

export default CreateApplicationDetailsForm;
