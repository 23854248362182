import { ApplicationSortingField } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import {
  GetOrganizationApplicationsAction,
  getOrganizationApplicationsFailure,
  getOrganizationApplicationsSuccess,
} from './ActionCreator';
import { LoanOriginationSystemOrganizationActionType } from './ActionTypes';
import { SimplifiedApplication, SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const GetOrganizationApplicationsMiddleware = (api: SimplifiedApplicationsApi) =>
  Fetcher<SimplifiedApplication[], GetOrganizationApplicationsAction>(
    LoanOriginationSystemOrganizationActionType.GetOrganizationApplications,
    getOrganizationApplicationsSuccess,
    getOrganizationApplicationsFailure,
    async () => {
      const { items } = await api.getAll(undefined, undefined, {
        ascending: true,
        field: ApplicationSortingField.BorrowerFullName,
      });

      return items;
    },
  );

export default GetOrganizationApplicationsMiddleware;
