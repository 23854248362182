import { AnyAction } from 'redux';
import { OrganizationInfo } from 'api/Core/OrganizationApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { UserOrganizationsActionTypes } from 'UserOrganizations/UserOrganizationsActionTypes';

export interface GetOrganizationsAction extends AnyAction {
  type: UserOrganizationsActionTypes.GetOrganizations;
  payload: {
    includeLogo?: boolean;
  };
}

interface GetOrganizationsParams {
  includeLogo?: boolean;
}

export interface GetOrganizationsSuccessAction extends AnyAction {
  type: UserOrganizationsActionTypes.GetOrganizationsSuccess;
  payload: {
    organizations: OrganizationInfo[];
  };
}

export interface GetOrganizationsFailedAction extends AnyAction {
  type: UserOrganizationsActionTypes.GetOrganizationsFailed;
}

export const getOrganizations = (params?: GetOrganizationsParams): GetOrganizationsAction => {
  return {
    type: UserOrganizationsActionTypes.GetOrganizations,
    payload: {
      includeLogo: params?.includeLogo,
    },
  };
};

export const getOrganizationsSuccess = (organizations: OrganizationInfo[]): GetOrganizationsSuccessAction => {
  return {
    type: UserOrganizationsActionTypes.GetOrganizationsSuccess,
    payload: {
      organizations,
    },
  };
};

export const getOrganizationsFailed = FailedActionCreator(UserOrganizationsActionTypes.GetOrganizationsFailed);
