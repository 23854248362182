import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from './stores';

const initializeSentry = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.001, // 0.1% of transactions will be sent to sentry
    normalizeDepth: 10,
  });
};

const initializeAppInsights = () => {
  if (!process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
    return;
  }

  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history }
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
};

const initializeTrackingSystem = () => {
  const trackingSystemType = process.env.REACT_APP_TRACKING_SYSTEM;

  switch (trackingSystemType) {
    case 'AppInsights': {
      return initializeAppInsights();
    }
    case 'Sentry': {
      return initializeSentry();
    }
    default: {
      return undefined;
    }
  }
};

export default initializeTrackingSystem;
