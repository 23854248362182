import { Option } from 'components/SelectInput/SelectInput';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { ComparisonsTypes } from 'containers/EditRulePopUpContainer/Types';
import { ApiActivationSortingType, ModuleType, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { RulePopUpType } from 'RuleCreate/Types';
import {
  VariableValueType
} from 'Variables/VariablesTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/Types';

export interface ApiDecisionStrategies {
  rows: DecisionStrategy[];
  numItems: number;
}

interface ShortUserInfo {
  firstName: string;
  lastName: string;
  imageId?: string;
}

export interface DecisionStrategy {
  id: string;
  name: string;
  version: number;
  status: SystemStatus;
  isLocked: boolean;
  versionsCount: number;
  description: string;
  moduleRuns: Module[];
  updatedAt: Date;
  updatedBy: ShortUserInfo;
  createdBy: ShortUserInfo;
  latestVersion: boolean;
  modules: { [key: string]: StrategyModuleBranch[] };
}

export interface StrategyModuleBranch {
  display_name: string;
  description: string;
  ruleset: Rule[];
  conditions: Rule[];
}

export interface ResponseBranchData {
  display_name: string;
  description: string;
  name: string;
  ruleset: string[];
  conditions: string[];
  type: ModuleType;
  inputs: string[];
  outputs: string[];
  dataintegration_id: string;
}

export interface ApiDecisionStrategyUpdates {
  rows: StrategyUpdateType[];
  numItems: number;
}

export interface Module {
  active: boolean;
  description: string;
  display_name: string;
  lookup_name: string;
  type: ModuleType;
}

export interface MultipleRule {
  condition_test: keyof typeof ComparisonsTypes;
  state_property_attribute: string;
  state_property_attribute_value_comparison: string | VariableValueType;
  state_property_attribute_value_comparison_type: ComparisonOperandType | VariableValueType;
  state_property_attribute_value_maximum: string;
  state_property_attribute_value_maximum_type: ComparisonOperandType;
  state_property_attribute_value_minimum: string;
  state_property_attribute_value_minimum_type: ComparisonOperandType;
  optionSeparator?: string;
  _id: string;
}

export interface ConditionOutput {
  _id: string;
  value: string;
  value_type: ComparisonOperandType;
  variable: string;
  variable_title: string;
  variable_display_title: string;
  variable_id: string;
}

export interface Rule {
  _id: string;
  strategy: string;
  multiple_rules: MultipleRule[];
  rule_type: RulePopUpType;
  type: ModuleType;
  condition_output: ConditionOutput[];
  calculation_inputs: string[];
}

export interface ApiModuleBranch {
  display_name: string;
  description: string;
  ruleset: Rule[];
  conditions: Rule[];
}

export interface ApiStrategy {
  data: DecisionStrategy;
}

export interface ResponseWithMessage {
  message: string;
}

export interface OutputVariable {
  data_type: string;
  label: string;
  value: string;
}
export interface ModuleRulesList {
  data: {
    modules: {
      [key: string]: ApiModuleBranch[];
    };
    segment_initial_score?: string;
    segment_output_variable?: string;
  };
  outputVariablesMap: OutputVariable[];
}

export enum ExternalIntegrationVariableType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
}

export interface ApiExternalIntegrationVariableModuleInput {
  data_type: ExternalIntegrationVariableType;
  example: string;
  display_name: string;
  input_variable: string;
  input_type: string;
  description: string;
}

export interface ApiExternalIntegrationVariableModuleOutput {
  api_name: string;
  data_type: ExternalIntegrationVariableType;
  example: string;
  display_name: string;
  output_variable: string;
  description: string;
}

export type ApiExternalIntegrationVariableModule = [
  {
    inputs: ApiExternalIntegrationVariableModuleInput[];
    outputs: ApiExternalIntegrationVariableModuleOutput[];
  },
];

export interface InputOption {
  label: string;
  value: string;
}

export interface ApiExternalIntegrationVariable {
  data: {
    modules: {
      [moduleId: string]: ApiExternalIntegrationVariableModule;
    };
  };
  numOptions: InputOption[];
  boolOptions: InputOption[];
  stringOptions: InputOption[];
  dateOptions: InputOption[];
}

export interface CompleteGetExternalIntegrationRequestData {
  strategyId: string;
  moduleId: string;
  branchIndex: number;
}

export interface ExternalIntegrationInputsList {
  name: string;
  type: ExternalIntegrationVariableType;
  variable: string;
  example: string;
  inputType: ComparisonOperandType;
  description: string;
}

export interface ExternalIntegrationOutputsList {
  apiName: string;
  name: string;
  type: ExternalIntegrationVariableType;
  variable: string;
  example: string;
  description: string;
}

export interface CompleteGetExternalIntegrationResponseData {
  inputList: ExternalIntegrationInputsList[];
  outputList: ExternalIntegrationOutputsList[];
  numberVariables: Option[];
  booleanVariables: Option[];
  stringVariables: Option[];
  dateVariables: Option[];
}

export interface ProcessingStrategy {
  _id: string;
  display_name: string;
  status: SystemStatus;
}

export interface ProcessingResponseData {
  strategies: ProcessingStrategy[];
}

export interface ApiIntegrationsList {
  formoptions: {
    dataintegration: ApiIntegration[];
  };
}

export interface ApiIntegration {
  label: string;
  value: string;
  description?: string;
}

export interface Integration {
  name: string;
  value: string;
  description?: string;
}

export enum BranchMethod {
  EditBranch = 'editSegment',
  DeleteBranch = 'delete',
  EditModule = 'editModule',
  AddBranch = 'addSegment',
}

export interface ApiActivationVersion {
  label: number;
  value: string;
}

export interface ApiActivationVersionData {
  status: SystemStatus;
  display_title: string;
  id: string;
  version: number;
}

export interface IndividualCaseCompiledModule {
  display_name: string;
  name: string;
  type: string;
}

export interface IndividualCaseModule {
  display_name: string;
  name: string;
  type: string;
}

export interface ApiActivations {
  groupedVersions: {
    name: string;
    versions: ApiActivationVersionData[];
  }[];
}

export interface StrategyListParams {
  page?: number;
  perPage?: number;
  showArchived?: boolean;
  searchInputValue?: string;
  selectedUpdaters?: UserInfo[];
}

export interface ApiActivationParams extends StrategyListParams {
  versionsStatuses?: SystemStatus[];
  sortingType?: ApiActivationSortingType;
}

export interface OutputData {
  strategyId: string;
  moduleName: string;
}
