import { MinifiedProductSectionAccessPermissions, prettifyProductSectionAccessPermissions } from 'SessionInfo/Utils';
import { ProductSectionAccessPermissionsMap } from 'ProductSectionAccessPermissions/Types';
import base64ToObject from 'utils/base64ToObject';

const ACCESS_PERMISSIONS_HEADER = 'x-digifi-access-permissions';

const getAccessPermissionsInfo = (response: Response): ProductSectionAccessPermissionsMap | null => {
  const accessPermissionsHeaderValue = response.headers.get(ACCESS_PERMISSIONS_HEADER);

  if (accessPermissionsHeaderValue) {
    const parsedValue = base64ToObject<MinifiedProductSectionAccessPermissions>(accessPermissionsHeaderValue);
    const permissions = prettifyProductSectionAccessPermissions(parsedValue);

    return permissions;
  }

  return null;
};

export default getAccessPermissionsInfo;
