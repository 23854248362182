import { createAsyncThunk } from '@reduxjs/toolkit';
import IntermediaryVariableConfigurationsRestApi from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import {
  BaseCreateVariableConfigurationParams,
  BaseUpdateVariableConfigurationParams,
  UpdateVariableConfigurationConditionalDisplayRuleParams,
  UpdateVariableConfigurationRequiredAttribute,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import responseHandlers from 'api/ResponseHandlers';

type UpdateIntermediaryVariableConfigurationPositionParams = Pick<BaseUpdateVariableConfigurationParams, 'position' | 'column' | 'cardId'> & {
  id: string;
  sourceCardId: string;
}

const intermediaryVariableConfigurationApi = new IntermediaryVariableConfigurationsRestApi(responseHandlers);

export const createIntermediaryVariableConfiguration = createAsyncThunk(
  'intermediaryProfileCards/createIntermediaryVariableConfiguration',
  async (params: BaseCreateVariableConfigurationParams) => {
    const configuration = await intermediaryVariableConfigurationApi.create(params);

    return {
      configuration,
    };
  },
);

export const updateIntermediaryVariableConfigurationPosition = createAsyncThunk(
  'intermediaryProfileCards/updateIntermediaryVariableConfigurationPosition',
  async (params: UpdateIntermediaryVariableConfigurationPositionParams) => {
    const { id, sourceCardId, ...updateParams } = params;

    const configuration = await intermediaryVariableConfigurationApi.update(id, updateParams);

    return {
      configuration,
      sourceCardId,
    };
  },
);

export const updateConditionalDisplayRule = createAsyncThunk(
  'intermediaryVariableConfigurations/updateConditionalDisplayRule',
  async (params: UpdateVariableConfigurationConditionalDisplayRuleParams) => {
    const configuration = await intermediaryVariableConfigurationApi.update(params.id, {
      conditionalDisplayRule: params.conditionalDisplayRule,
    });

    return {
      configuration,
    };
  },
);

export const updateIntermediaryVariableConfigurationRequiredAttribute = createAsyncThunk(
  'intermediaryProfileCards/updateIntermediaryVariableConfigurationRequiredAttribute',
  async (params: UpdateVariableConfigurationRequiredAttribute) => {
    const { id, required } = params;

    const configuration = await intermediaryVariableConfigurationApi.update(id, {
      required,
    });

    return {
      configuration,
    };
  },
)

export const deleteIntermediaryVariableConfiguration = createAsyncThunk(
  'intermediaryProfileCards/deleteIntermediaryVariableConfiguration',
  async (id: string) => {
    await intermediaryVariableConfigurationApi.delete(id);

    return {
      id,
    };
  },
);
