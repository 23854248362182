import { CalculatedVariablesState } from './Types';
import {
  CreateProductCalculationSuccessAction,
  DeleteProductCalculationSuccessAction,
  GetProductCalculationsSuccessAction,
} from 'ProductCalculations/ActionCreator';
import { ProductCalculationsType } from 'ProductCalculations/ActionTypes';
import withStateReset from 'utils/reducers/withStateReset';
import { CalculatedVariablesActionType } from './ActionTypes';

export const initialState: CalculatedVariablesState = {
  calculationIds: null,
};

export type CalculatedVariablesActions =
  | GetProductCalculationsSuccessAction
  | CreateProductCalculationSuccessAction
  | DeleteProductCalculationSuccessAction;

const calculatedVariablesReducer = (state = initialState, action: CalculatedVariablesActions) => {
  switch (action.type) {
    case ProductCalculationsType.GetProductCalculationsSuccess: {
      return {
        ...state,
        calculationIds: action.payload.calculations.map((calculation) => calculation.id),
      };
    }
    case ProductCalculationsType.CreateProductCalculationSuccess: {
      if (!state.calculationIds) {
        return state;
      }

      return {
        ...state,
        calculationIds: [...state.calculationIds, action.payload.calculation.id],
      };
    }
    case ProductCalculationsType.DeleteProductCalculationSuccess: {
      if (!state.calculationIds) {
        return state;
      }

      return {
        ...state,
        calculationIds: state.calculationIds.filter((calculationId) => calculationId !== action.payload.calculationId),
      };
    }
    default: {
      return state;
    }
  }
};

export default withStateReset(calculatedVariablesReducer, CalculatedVariablesActionType.ResetState, () => initialState);
