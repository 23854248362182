import React, { useEffect } from 'react';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import ReportingDashboard from 'components/LoanOriginationSystem/ReportingDashboard';
import ReportingDashboardAdditionalFilters from 'components/LoanOriginationSystem/ReportingDashboard/ReportingDashboardAdditionalFilters';
import {
  getReportingDashboardData,
  hideReportingDashboardAdditionalFiltersPopup,
  ReportingDashboardAdditionalFiltersModel,
  ReportingDashboardFiltersModel,
  ReportingDashboardStep,
  setReportingDashboardIsLoading,
  setReportingDashboardStep,
  showReportingDashboardAdditionalFiltersPopup,
} from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getProductsList } from 'LoanOriginationSystemApplications/ActionCreator';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { loanOriginationSystemIntermediariesApi } from 'stores/loanOriginationSystem';

const INTERMEDIARIES_SEARCH_RESULT_LIMIT = 50;

const Reporting = () => {
  const leftNav = makeLeftNavigation(NavigationLinkId.Reporting, ApplicationSectionName.LoanOriginationSystem);
  const dispatch = useDispatch();

  const { isLoading, additionalFiltersPopupShown, data, filters, step, additionalFilters } = useSelector(
    (state: ReduxState) => state.reportingDashboard,
  );
  const { productsList } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications);
  const organizationMembers = useOrganizationMembers();

  const loadData = async (
    newFilters: ReportingDashboardFiltersModel,
    newAdditionalFilters: ReportingDashboardAdditionalFiltersModel,
  ) => {
    dispatch(setReportingDashboardIsLoading(true));

    await dispatch(
      getReportingDashboardData({
        filters: newFilters,
        additionalFilters: newAdditionalFilters,
      }),
    );

    dispatch(setReportingDashboardIsLoading(false));
  };

  const handleOnAdditionalFiltersChange = (newAdditionalFilters: ReportingDashboardAdditionalFiltersModel) => {
    batch(() => {
      loadData(filters, newAdditionalFilters);
      handleOnHideAdditionalFiltersPopup();
    });
  };

  const handleOnShowAdditionalFiltersPopup = () => {
    batch(() => {
      dispatch(getProductsList());
      dispatch(showReportingDashboardAdditionalFiltersPopup());
    });
  };

  const handleOnHideAdditionalFiltersPopup = () => {
    dispatch(hideReportingDashboardAdditionalFiltersPopup());
  };

  const handleStepChange = (newStep: ReportingDashboardStep) => {
    dispatch(setReportingDashboardStep({ step: newStep }));
  };

  const getIntermediaries = async (search: string) => {
    return loanOriginationSystemIntermediariesApi.findIntermediaries(search, INTERMEDIARIES_SEARCH_RESULT_LIMIT);
  };

  const getIntermediaryById = async (id: string) => {
    return loanOriginationSystemIntermediariesApi.getIntermediary(id);
  };

  const renderRightSidePopupView = () => {
    if (additionalFiltersPopupShown) {
      return (
        <ReportingDashboardAdditionalFilters
          organizationMembers={organizationMembers}
          productsList={productsList || []}
          getIntermediaries={getIntermediaries}
          getIntermediaryById={getIntermediaryById}
          onClose={handleOnHideAdditionalFiltersPopup}
          filters={additionalFilters}
          onChange={handleOnAdditionalFiltersChange}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    return () => {
      handleOnHideAdditionalFiltersPopup();
    };
  }, []);

  const additionalFiltersAreApplied = Object.values(additionalFilters).some((value) => value);

  return (
    <MainLayout leftNav={leftNav} rightSidePopupView={renderRightSidePopupView()}>
      <PageWrapperWithFooter>
        <PageContent>
          <ReportingDashboard
            isLoading={isLoading}
            data={data}
            filters={filters}
            onLoadData={(newFilters) => loadData(newFilters, additionalFilters)}
            onShowAdditionalFiltersPopup={handleOnShowAdditionalFiltersPopup}
            onStepChange={handleStepChange}
            step={step}
            additionalFiltersActive={additionalFiltersAreApplied}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Reporting;
