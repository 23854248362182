import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Footer.module.scss';

interface FooterProps {
  separator?: boolean;
  className?: string;
}

const Footer: FC<FooterProps> = ({ separator, className }) => {
  return (
    <div className={clsx(styles.footerContainer, separator && styles.separator, className)}>
      <p>© 2022 DigiFi, Inc.</p>
    </div>
  );
};

export default Footer;
