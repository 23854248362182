import { isPending, AnyAction } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import { OrganizationUsersState } from './OrganizationUserStore';
import { OrganizationUsersActionTypes } from './ActionTypes';
import { OrganizationUsersSortingType } from 'api/Core/UserApi';

type OrganizationUsersFiltersToSave = Pick<
  OrganizationUsersState,
  'showInactive' | 'permissionGroupId' | 'phoneAuthentication'
>;

const getFiltersToSave = (state: ReduxState) => {
  const { showInactive, permissionGroupId, phoneAuthentication } = state.organizationUsers;

  return {
    showInactive,
    permissionGroupId,
    phoneAuthentication,
  };
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.organizationUsers;

  return sortingType;
};

const saveTriggerActions = [
  (action: AnyAction) =>
    isPending(action) && action.type.includes(OrganizationUsersActionTypes.RequestOrganizationUsers),
];

const organizationUsersFilterAndSortingSaver = FilterAndSortingSaver<
  OrganizationUsersFiltersToSave,
  OrganizationUsersSortingType
>({
  key: 'organizationUsers',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: OrganizationUsersActionTypes.ResetState,
});

export default organizationUsersFilterAndSortingSaver;
