import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { deleteWebhookEvent, retryWebhookEvent } from './Thunks';

const webhookEventNotificationsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isFulfilled(retryWebhookEvent)(action)) {
    const { webhookEvents } = getState();
    const retriedWebhookEvent = webhookEvents.webhookEventsById[action.payload.webhookEventId];

    const message = getMessage(
      MessageType.WebhookEventRetried,
      { eventType: retriedWebhookEvent.event.type, endpoint: retriedWebhookEvent.webhook.url },
    );

    notification.createNotification(
      message,
      'success',
      dispatch,
    );
  }

  if (isFulfilled(deleteWebhookEvent)(action)) {
    notification.createNotification(getMessage(MessageType.WebhookEventDeleted), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default webhookEventNotificationsMiddleware;
