import Fetcher from 'middlewares/Fetcher';
import { UnlockBorrowerAction, unlockBorrowerFailure, unlockBorrowerSuccess } from './ActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';
import {
  Borrower,
  LoanOriginationSystemBorrowersApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const UnlockBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower, UnlockBorrowerAction>(
    LoanOriginationSystemBorrowerLockType.UnlockBorrower,
    unlockBorrowerSuccess,
    unlockBorrowerFailure,
    (action) => {
      return api.unlockBorrower(action.payload.borrowerId);
    },
  );

export default UnlockBorrowerMiddleware;
