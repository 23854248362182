import React, { useEffect } from 'react';
import NoEmailsConnected from 'components/LoanOriginationSystem/NoEmailsConnected';
import { getOrganizationEmail } from 'utils/emailGetters';
import { useDispatch, useSelector } from 'react-redux';
import { openDeleteEmailPopup, sortEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import { ReduxState } from 'types/redux';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';
import { EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { useHistory } from 'react-router-dom';
import EmailsTable from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import { isEqual } from 'lodash';
import { initialState as filtersInitialState } from 'LoanOriginationSystemEmails/Filters/Reducer';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';
import { setSearchInputValue } from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import Header from './Header';
import SearchNotFound from 'components/SearchNotFound';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';

interface EmailsTabProps {
  borrowerId: string;
  borrowerEmail: string;
  coBorrowerEmail: string;
  organizationEmail: string;
}

const EmailsTab = ({ borrowerId, borrowerEmail, coBorrowerEmail, organizationEmail }: EmailsTabProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const organizationInfo = useOrganizationDetailedInfo();

  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  useStateReset(LoanOriginationSystemEmailsActionTypes.ResetState);

  const params: EmailsPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    createdAtDateRange: filters.createdAtRange,
    selectedBorrower: borrowerId,
  };

  const emails = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  useEffect(() => {
    dispatch(pagination.reloadCurrentPage(params));
  }, []);

  const isNoEmails =
    paginationProps.itemsTotal === 0 && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const handleSort = (field: EmailsSortingField, ascending: boolean) => {
    dispatch(sortEmails(field, ascending, borrowerId));
  };

  const handleSearch = (search: string) => {
    dispatch(setSearchInputValue(search, borrowerId));
  };

  const handleOpenEmail = (id: string) => history.replace(`?tab-id=${queryParams.get('tab-id')}&emailId=${id}`);
  const handleDeleteEmail = (id: string) => dispatch(openDeleteEmailPopup(id));

  const renderBody = () => {
    if (filters.searchInputValue && paginationProps.itemsTotal === 0) {
      return <SearchNotFound searchValue={filters.searchInputValue} />;
    }

    return (
      <EmailsTable
        paginationProps={paginationProps}
        emails={emails}
        onSort={handleSort}
        sortingType={sortingType}
        onOpenEmail={handleOpenEmail}
        onDeleteEmail={handleDeleteEmail}
        organizationEmail={getOrganizationEmail(organizationInfo.id)}
        searchValue={filters.searchInputValue}
        stickyMarginTop={TABLE_HEADER_OFFSET}
      />
    );
  };

  return (
    <>
      {isNoEmails ? (
        <NoEmailsConnected
          headerText="Emails"
          email={organizationEmail}
          small
          borrowerEmail={borrowerEmail}
          coBorrowerEmail={coBorrowerEmail}
        />
      ) : (
        <>
          <Header
            searchInputValue={filters.searchInputValue}
            subTitleHidden={isNoEmails}
            onSearch={handleSearch}
            borrowerEmail={borrowerEmail}
            coBorrowerEmail={coBorrowerEmail}
            areEmailsLoading={paginationProps.itemsTotal === undefined}
          />
          {renderBody()}
        </>
      )}
    </>
  );
};

export default EmailsTab;
