import React from 'react';
import {
  EmptyDecisionEngineResultsImage,
  EmptyEmailsImage,
  EmptyHistoryImage,
  EmptyNotesImage,
  EmptyStatusRules,
  EmptyTasksImage,
} from 'static/images';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import getPosition from 'utils/getPosition';
import {
  ApplicationTab,
  ApplicationTabType,
  UpdateApplicationTabParams,
} from 'api/LoanOriginationSystem/ApplicationTabsApi';
import TabsList, {
  SkeletonTabsList,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/TabsList';
import LoadingMask from 'components/LoadingMask';
import {
  DataTab,
  DocumentsTab,
  SuccessTab,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs';
import styles from './ApplicationSetupBody.module.scss';

export interface ApplicationSetupBodyProps {
  applicationTabs: ApplicationTab[] | null;
  onTabAdd: (type: ApplicationTabType) => void;
  onTabDelete: (tab: ApplicationTab) => void;
  onTabSelect: (tab: ApplicationTab) => void;
  selectedTab: ApplicationTab | null;
  onTabUpdate: (id: string, params: UpdateApplicationTabParams) => void;
  showLoadingMask?: boolean;
  selectedProduct?: Product | null;
}

const tabOptionsByApplicationType = new Map([
  [ApplicationTabType.Tasks, { icon: <EmptyTasksImage />, tabType: 'Tasks' }],
  [ApplicationTabType.DecisionEngine, { icon: <EmptyDecisionEngineResultsImage />, tabType: 'Decision Engine' }],
  [ApplicationTabType.Notes, { icon: <EmptyNotesImage />, tabType: 'Notes' }],
  [ApplicationTabType.Emails, { icon: <EmptyEmailsImage />, tabType: 'Emails' }],
  [ApplicationTabType.StatusRules, { icon: <EmptyStatusRules />, tabType: 'Status Rules' }],
  [ApplicationTabType.History, { icon: <EmptyHistoryImage />, tabType: 'History' }],
]);

const ApplicationSetupBody = ({
  onTabAdd,
  onTabDelete,
  onTabSelect,
  onTabUpdate,
  applicationTabs,
  selectedTab,
  selectedProduct,
  showLoadingMask,
}: ApplicationSetupBodyProps) => {
  const handleTabNameUpdate = (id: string, name: string) => {
    onTabUpdate(id, { name });
  };

  const handleTabReorder = (tabToReorder: ApplicationTab, index: number) => {
    if (!applicationTabs) {
      return;
    }

    const sortedTabs = applicationTabs
      .filter((tab) => tab.id !== tabToReorder.id)
      .sort((firstTab, secondTab) => firstTab.position - secondTab.position);

    const position = getPosition(sortedTabs, index);

    onTabUpdate(tabToReorder.id, { position });
  };

  const renderTabContent = () => {
    if (!selectedTab || !selectedProduct) {
      return null;
    }

    if (selectedTab.type === ApplicationTabType.DataTab) {
      return <DataTab tab={selectedTab} onTabNameUpdate={handleTabNameUpdate} />;
    }

    if (selectedTab.type === ApplicationTabType.Documents) {
      return <DocumentsTab product={selectedProduct} tab={selectedTab} onTabNameUpdate={handleTabNameUpdate} />;
    }

    const { icon, tabType } = tabOptionsByApplicationType.get(selectedTab.type) || { icon: null, tabType: '' };

    return <SuccessTab icon={icon} tabType={tabType} tab={selectedTab} onTabNameUpdate={handleTabNameUpdate} />;
  };

  const renderTabsList = () => {
    if (!applicationTabs) {
      return <SkeletonTabsList className={styles.tabsList} />;
    }

    return (
      <TabsList
        selectedTab={selectedTab}
        onTabSelect={onTabSelect}
        onTabAdd={onTabAdd}
        onTabDelete={onTabDelete}
        onTabReorder={handleTabReorder}
        tabs={applicationTabs}
      />
    );
  };

  return (
    <div className={styles.container}>
      {renderTabsList()}
      {renderTabContent()}
      {showLoadingMask && <LoadingMask background="light" />}
    </div>
  );
};

export default ApplicationSetupBody;
