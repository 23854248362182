import React from 'react';
import clsx from 'clsx';
import { ColumnIcon, TableIcon, TileIcon } from 'static/images';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import styles from './ViewSwitcher.module.scss';
import WrapperWithTooltip from 'components/Tooltip';

interface ViewSwitcherProps {
  viewType: ViewType;
  onChange?: (viewType: ViewType) => void;
  isTileView?: boolean;
  className?: string;
  btnClass?: string;
  selectedBtnClass?: string;
}

const PIPELINE_VIEW_TOOLTIP = 'Pipeline View';
const TILE_VIEW_TOOLTIP = 'Card View';
const TABLE_VIEW_TOOLTIP = 'Table View';

const ViewSwitcher = ({
  viewType,
  onChange,
  isTileView,
  className,
  btnClass,
  selectedBtnClass,
}: ViewSwitcherProps) => {
  const renderButton = (icon: React.ReactChild, type: ViewType, tooltip: string) => {
    const classes = clsx(
      styles.root__button,
      viewType === type && styles.root__button__selected,
      btnClass,
      viewType === type && selectedBtnClass,
    );

    return (
      <WrapperWithTooltip tooltip={tooltip}>
        <div onClick={() => onChange?.(type)} className={classes}>
          {icon}
        </div>
      </WrapperWithTooltip>
    );
  };

  return (
    <div className={clsx(styles.root, className)}>
      {isTileView
        ? renderButton(<TileIcon />, ViewType.Tile, TILE_VIEW_TOOLTIP)
        : renderButton(<ColumnIcon />, ViewType.Column, PIPELINE_VIEW_TOOLTIP)}
      {renderButton(<TableIcon />, ViewType.Table, TABLE_VIEW_TOOLTIP)}
    </div>
  );
};

export default ViewSwitcher;
