import { AnyAction } from 'redux';
import { Reducer } from './types';

const withStateReset = <RootState extends {}, Action extends AnyAction>(
  reducer: Reducer<RootState, Action>,
  resetAction: string,
  getState: (state: RootState, action: Action) => RootState,
) => {
  return (state: RootState, action: Action) => {
    return action.type === resetAction ? getState(state, action) : reducer(state, action);
  };
};

export default withStateReset;
