import React, { useEffect, FC, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import { ReduxState } from 'types/redux';
import getPosition from 'utils/getPosition';
import sortByPosition from 'utils/sortByPosition';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import DocumentsUploadConfiguration from 'components/LoanOriginationSystem/DocumentsUploadConfiguration';
import LoadingMask from 'components/LoadingMask';
import HeaderWithActions from 'components/HeaderWithActions';
import Button from 'components/Button';
import {
  createApplicationDocumentConfiguration,
  deleteApplicationDocumentConfiguration,
  getApplicationDocumentConfigurations,
  updateApplicationDocumentConfiguration,
  updateApplicationDocumentConfigurationRequiredAttribute,
} from 'ApplicationDocumentConfigurations/Thunks';
import { getApplicationDocumentConfigurationsSelector } from 'ApplicationDocumentConfigurations/Selectors';
import EditApplicationDocumentConfigurationPopup from './EditApplicationDocumentConfigurationPopup';
import { EditApplicationDocumentConfigurationData } from './EditApplicationDocumentConfigurationPopup/EditApplicationDocumentConfigurationPopup';
import styles from './ApplicationDocumentsConfigurationPage.module.scss'

export interface ApplicationDocumentsConfigurationPageProps {
  productId?: string;
}

const ApplicationDocumentsConfigurationPage: FC<ApplicationDocumentsConfigurationPageProps> = ({ productId }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const applicationDocumentConfigurations = useSelector((state: ReduxState) => {
    return productId ? getApplicationDocumentConfigurationsSelector(state, productId) : null;
  });
  const [isBlockingRequestInProgress, useBlockingRequestCallback] = useBlockingRequest();
  const [
    isAddApplicationDocumentConfigurationPopupVisible,
    setAddApplicationDocumentConfigurationPopupVisibility,
  ] = useState(false);
  const [
    applicationDocumentConfigurationForEdit,
    setApplicationDocumentConfigurationForEdit,
  ] = useState<ApplicationDocumentConfiguration | null>(null);
  const [isAddApplicationDocumentConfigurationInProgress, setIsAddApplicationDocumentConfigurationInProgress] = useState(false);
  const [isEditApplicationDocumentConfigurationInProgress, setIsEditApplicationDocumentConfigurationInProgress] = useState(false);

  useEffect(() => {
    if (productId) {
      dispatch(getApplicationDocumentConfigurations(productId));
    }
  }, [productId]);

  const handleAddApplicationDocumentConfiguration = async (data: EditApplicationDocumentConfigurationData) => {
    if (!productId || !applicationDocumentConfigurations) {
      return;
    }

    try {
      setIsAddApplicationDocumentConfigurationInProgress(true);

      await dispatch(createApplicationDocumentConfiguration({
        name: data.name,
        folderId: data.folderId,
        position: getPosition(sortByPosition(applicationDocumentConfigurations), applicationDocumentConfigurations.length),
        productId,
      }));

      setAddApplicationDocumentConfigurationPopupVisibility(false);
    } finally {
      setIsAddApplicationDocumentConfigurationInProgress(false);
    }
  };

  const handleEditApplicationDocumentConfiguration = async (data: EditApplicationDocumentConfigurationData) => {
    if (!applicationDocumentConfigurationForEdit) {
      return;
    }

    try {
      setIsEditApplicationDocumentConfigurationInProgress(true);

      await dispatch(updateApplicationDocumentConfiguration({
        id: applicationDocumentConfigurationForEdit.id,
        name: data.name,
        folderId: data.folderId,
      }));

      setApplicationDocumentConfigurationForEdit(null);
    } finally {
      setIsEditApplicationDocumentConfigurationInProgress(false);
    }
  };

  const handleReorder = useBlockingRequestCallback(async (configuration: ApplicationDocumentConfiguration, position: number) => {
    await dispatch(updateApplicationDocumentConfiguration({
      id: configuration.id,
      position,
    }));
  });

  const handleEditDocumentConfiguration = (configuration: ApplicationDocumentConfiguration) => {
    setApplicationDocumentConfigurationForEdit(configuration);
  };

  const handleChangeDocumentConfigurationRequiredAttribute = (configuration: ApplicationDocumentConfiguration, required: boolean) => {
    dispatch(updateApplicationDocumentConfigurationRequiredAttribute({
      id: configuration.id,
      required,
    }));
  };

  const handleRemoveDocumentConfiguration = useBlockingRequestCallback(async (configuration: ApplicationDocumentConfiguration) => {
    await dispatch(deleteApplicationDocumentConfiguration(configuration.id))
  });

  const handleEditDocumentConfigurationConditionalDisplayRule = useCallback(
    async ({ id }: ApplicationDocumentConfiguration, conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null) => {
      await dispatchWithUnwrap(updateApplicationDocumentConfiguration({
        id,
        conditionalDisplayRule,
      }));
    },
    [],
  );

  const renderHeaderActions = () => (
    <>
      <Button kind="secondary" onClick={() => setAddApplicationDocumentConfigurationPopupVisibility(true)}>
        Add Document
      </Button>
    </>
  );

  return (
    <>
      <div>
        <HeaderWithActions title="Document Upload" headerActions={renderHeaderActions()} />
        {(!applicationDocumentConfigurations || !!applicationDocumentConfigurations.length) && (
          <div className={styles.container}>
            <DocumentsUploadConfiguration
              documentConfigurations={applicationDocumentConfigurations}
              onEditDocumentConfiguration={handleEditDocumentConfiguration}
              onChangeDocumentConfigurationRequiredAttribute={handleChangeDocumentConfigurationRequiredAttribute}
              onRemoveDocumentConfiguration={handleRemoveDocumentConfiguration}
              onEditDocumentConfigurationConditionalDisplayRule={handleEditDocumentConfigurationConditionalDisplayRule}
              onReorder={handleReorder}
            />
            {isBlockingRequestInProgress && <LoadingMask />}
          </div>
        )}
      </div>
      {isAddApplicationDocumentConfigurationPopupVisible && productId && (
        <EditApplicationDocumentConfigurationPopup
          productId={productId}
          onSave={handleAddApplicationDocumentConfiguration}
          onClose={() => setAddApplicationDocumentConfigurationPopupVisibility(false)}
          isLoading={isAddApplicationDocumentConfigurationInProgress}
          usePortal
        />
      )}
      {applicationDocumentConfigurationForEdit && productId && (
        <EditApplicationDocumentConfigurationPopup
          productId={productId}
          onSave={handleEditApplicationDocumentConfiguration}
          onClose={() => setApplicationDocumentConfigurationForEdit(null)}
          isLoading={isEditApplicationDocumentConfigurationInProgress}
          documentConfiguration={applicationDocumentConfigurationForEdit}
          usePortal
        />
      )}
    </>
  );
};

export default ApplicationDocumentsConfigurationPage;
