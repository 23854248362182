import React, { FocusEvent } from 'react';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import { LoaderState } from 'components/LoaderWithState';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import SkeletonCardsLayout from 'components/LoanOriginationSystem/CardsLayoutConfiguration/SkeletonCardsLayout';
import { DEFAULT_SKELETON_CARDS_LAYOUT } from 'components/LoanOriginationSystem/CardsLayoutConfiguration';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import Button from 'components/Button';
import ButtonWithImage from 'components/ButtonWithImage';
import CardsRow from './CardsRow';
import SkeletonCardsForm from './SkeletonCardsForm';
import styles from './CardsForm.module.scss';

export interface CardsFormProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  > {
  cards: CardType[] | null;
  data: FormLayoutData;
  isEditMode?: boolean;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onFieldChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  onSwitchEditMode?: () => void;
  title?: string;
  switchEditModeButton?: string;
  className?: string;
  displayHeader?: boolean;
  columnsPerCards?: number;
  skeletonCardsLayout?: Array<Array<number>>;
  isSaveChangesButtonDisabled?: boolean;
  isUpdatingInProgress?: boolean;
  areFieldsInvalid?: boolean;
  onSaveChangesClick?: () => void;
  displayFieldsAttributes?: Record<string, boolean>;
  displaySkeleton?: boolean;
  calculations?: CalculationType[] | null;
}

const CardsForm = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  >({
  title,
  cards,
  data,
  isEditMode,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  onLoaderStateReset,
  loaderStateById,
  formatVariableConfigurationDisplayTitle,
  onSwitchEditMode,
  switchEditModeButton,
  displayHeader = true,
  className,
  columnsPerCards,
  skeletonCardsLayout = DEFAULT_SKELETON_CARDS_LAYOUT,
  isSaveChangesButtonDisabled,
  isUpdatingInProgress,
  onSaveChangesClick,
  areFieldsInvalid,
  displayFieldsAttributes,
  displaySkeleton,
  calculations,
}: CardsFormProps<VariableConfigurationType, CardType, CalculationType>) => {
  const cardsLayout = useFormLayout(cards);

  const renderForm = () => {
    if (!cardsLayout || displaySkeleton) {
      return isEditMode
        ? <SkeletonCardsLayout cardClassName={styles.skeletonCard} maxColumnsPerCard={columnsPerCards} layout={skeletonCardsLayout} />
        : <SkeletonCardsForm maxColumnsPerCard={columnsPerCards} layout={skeletonCardsLayout} />;
    }

    return (
      <>
        {cardsLayout.map((cardsInRow, index) => (
          <CardsRow
            onLoaderStateReset={onLoaderStateReset}
            loaderStateById={loaderStateById}
            key={index}
            cards={cardsInRow}
            data={data}
            onFieldChange={onFieldChange}
            onFieldFocus={onFieldFocus}
            onFieldBlur={onFieldBlur}
            isEditMode={isEditMode}
            formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
            columnsPerCards={columnsPerCards}
            rowIndex={index}
            displayFieldsAttributes={displayFieldsAttributes}
            calculations={calculations}
          />
        ))}
      </>
    );
  };

  const renderHeaderButton = () => {
    if (isEditMode) {
      return (
        <div className={styles.editModeActions}>
          <Button className={styles.closeEditorButton} kind="secondary" onClick={() => onSwitchEditMode?.()}>
            Close Editor
          </Button>
          <WithFieldsValidationButton
            kind="primary"
            className={styles.saveChangesButton}
            disabled={isSaveChangesButtonDisabled}
            onClick={onSaveChangesClick}
            isLoading={isUpdatingInProgress}
            areFieldsInvalid={areFieldsInvalid}
          >
            Save Changes
          </WithFieldsValidationButton>
        </div>
      );
    }

    return (
      <ButtonWithImage
        className={styles.editDataButton}
        disabled={!cardsLayout}
        title={switchEditModeButton || 'Edit Data'}
        kind="edit"
        onClick={() => onSwitchEditMode?.()}
      />
    );
  };

  return (
    <div className={className}>
      {displayHeader && <div className={styles.cardsFormHeader}>
        <h4>{title}</h4>
        {renderHeaderButton()}
      </div>}
      {renderForm()}
    </div>
  );
};

export default CardsForm;
