import React, { FC, ChangeEvent, useState, useRef } from 'react';
import TextInput from 'components/TextInput';
import styles from './SecurePhoneAuthenticationForm.module.scss';
import TopRightButton from 'components/TopRightInputButton';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import TimerMessage from 'TimerMessage/TimeMessage';

interface SecurePhoneAuthenticationFormProps {
  onResendCode: () => void;
  resendInSeconds: number;
  onSubmit: (code: string) => Promise<void>;
}

const CODE_LENGTH = 6;

const SecurePhoneAuthenticationForm: FC<SecurePhoneAuthenticationFormProps> = ({
  resendInSeconds,
  onResendCode,
  onSubmit,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [code, setCode] = useState('');

  const topRightElement = (
    <TopRightButton disabled={resendInSeconds > 0} onClick={onResendCode}>
      Resend Code
    </TopRightButton>
  );

  const submitIsDisabled = code.length !== CODE_LENGTH;

  return (
    <div>
      <TextInput
        labelTitle="Phone Authentication"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        placeholder={`${CODE_LENGTH} digits`}
        topRightElement={topRightElement}
        containerClassName={styles.codeInput}
        value={code}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        autoFocus
      />
      {!!resendInSeconds && (
        <TimerMessage>
          You can request another code in <strong>{resendInSeconds}s</strong>
        </TimerMessage>
      )}
      <ButtonWithLoadingState
        kind="primary"
        size="form"
        onClick={() => onSubmit(code)}
        type="submit"
        fullWidth
        className={styles.submitButton}
        disabled={submitIsDisabled}
        ref={submitButtonRef}
      >
        Authenticate
      </ButtonWithLoadingState>
    </div>
  );
};

export default SecurePhoneAuthenticationForm;
