import React, { FC, ReactNode } from 'react';
import { Draggable, DraggingStyle } from 'react-beautiful-dnd';

interface DocumentFoldersTableDraggableRowProps {
  getDroppableIndex: (droppableId: string) => number;
  getIsCombineMode?: (droppableId: string) => boolean;
  draggableIndex: number;
  draggableId: string;
  currentDraggableId: string | null;
  placeholderRow: ReactNode;
  children: (isDragging: boolean, isCombineTarget: boolean) => ReactNode;
}

const COMBINE_ANIMATION_SCALE_VALUE = 0.75;

const getDraggingStateStyleOverride = (isDragging: boolean) => (isDragging ? { opacity: 1 } : { transform: 'none' });

const getDropAnimationStyleOverride = ({
  isDropAnimating,
  isCombineMode,
  itemHeight,
  draggableIndex,
  droppableIndex,
}: {
  isDropAnimating: boolean;
  isCombineMode: boolean;
  itemHeight: number;
  draggableIndex: number;
  droppableIndex: number;
}) => {
  if (!isDropAnimating) return {};

  const translateYValue = isCombineMode ? itemHeight * (droppableIndex - draggableIndex) : 0;
  const scaleValue = isCombineMode ? COMBINE_ANIMATION_SCALE_VALUE : 1;

  return {
    transform: `translate(0px, ${translateYValue}px) scale(${scaleValue})`,
  };
};

const DocumentFoldersTableDraggableRow: FC<DocumentFoldersTableDraggableRowProps> = ({
  getDroppableIndex,
  getIsCombineMode = () => true,
  draggableIndex,
  draggableId,
  currentDraggableId,
  placeholderRow,
  children,
}) => (
  <>
    {currentDraggableId === draggableId ? placeholderRow : null}
    <Draggable draggableId={draggableId} index={draggableIndex}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            ...getDraggingStateStyleOverride(snapshot.isDragging),
            ...getDropAnimationStyleOverride({
              isDropAnimating: snapshot.isDragging && snapshot.isDropAnimating,
              isCombineMode: getIsCombineMode(snapshot.combineWith ?? ''),
              itemHeight: (provided.draggableProps.style as DraggingStyle).height,
              draggableIndex,
              droppableIndex: getDroppableIndex(snapshot.combineWith ?? ''),
            }),
          }}
        >
          {children(snapshot.isDragging, !!snapshot.combineTargetFor)}
        </div>
      )}
    </Draggable>
  </>
);

export default DocumentFoldersTableDraggableRow;
