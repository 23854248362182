import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import {
  ApplicationHistoryFilterType,
  typesByHistoryFilterTypeMap,
  ApplicationHistoryType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';

export const getFilterType = (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.filterType;

export const getSelectedMembers = (state: ReduxState) =>
  state.loanOriginationSystemApplicationPage.history.selectedMembers;

export const getSortingType = (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.sortingType;

export const getSearchValue = (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.search;

export const getRecordIdsByApplicationId = (state: ReduxState, applicationId: string) =>
  state.loanOriginationSystemApplicationPage.history.recordsIdsByApplicationId[applicationId];

export const getRecordsById = (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.recordsById;

export const getRecordsByApplicationId = createSelector(
  [getRecordIdsByApplicationId, getRecordsById],
  (recordsIds, recordsById) => {
    if (!recordsIds) {
      return null;
    }

    return recordsIds.map((id) => recordsById[id]);
  },
);

const valuesContainSearch = (search: string) => (data: object) =>
  Object.values(data).some((dataValue) => {
    if (typeof dataValue === 'string') {
      return dataValue.toLowerCase().includes(search);
    }

    if (typeof dataValue === 'number' || typeof dataValue === 'boolean') {
      return String(dataValue)
        .toLowerCase()
        .includes(search);
    }

    return false;
  });

export const getApplicationHistoryRecords = createSelector(
  [getRecordsByApplicationId, getFilterType, getSelectedMembers, getSearchValue, getSortingType],
  (records, filterType, selectedMembers, search, { ascending, field }) => {
    if (!records) {
      return null;
    }

    const selectedTypes = typesByHistoryFilterTypeMap.get(filterType) || [];

    return records
      .filter((record) =>
        filterType === ApplicationHistoryFilterType.AllTypes ? record : selectedTypes.includes(record.type),
      )
      .filter((record) =>
        selectedMembers.length === 0 ? record : selectedMembers.some((user) => user.id === record.createdBy?.id),
      )
      .filter((record) => {
        if (!search) {
          return record;
        }

        if (record.type === ApplicationHistoryType.ApplicationUpdated) {
          return record.data.variables.some(valuesContainSearch(search));
        }

        return valuesContainSearch(search)(record.data);
      })
      .sort((recordA, recordB) =>
        ascending
          ? new Date(recordA[field]).getTime() - new Date(recordB[field]).getTime()
          : new Date(recordB[field]).getTime() - new Date(recordA[field]).getTime(),
      );
  },
);
