import React from 'react';
import {
  ApplicationHistoryRecord,
  ApplicationHistoryType,
  ApplicationUpdatedData,
  ApplicationUpdatedVariable,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { VariableValue } from 'api/Types';
import { Variable } from 'Variables/VariablesTypes';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatValueByDataType from 'utils/formatValueByDataType';
import { getVariableAccessPermissions } from 'Variables/utils';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import { useVariableBySystemName } from 'Variables/VariablesBySystemNameLoader';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import SearchHighlight from 'components/SearchHighlight';
import CollapsibleText from 'components/CollapsibleText';
import {
  StatusTransitionIcon,
  ApplicationCreatedIcon,
  TaskCompletedIcon,
  TaskDeletedIcon,
  EmailCreatedIcon,
  EmailDeletedIcon,
  DocumentUploadedIcon,
  DocumentDeletedIcon,
  NoteCreatedIcon,
  NoteDeletedIcon,
  CoborrowerAddedIcon,
  CoborrowerDeletedIcon,
  ApplicationUpdatedIcon, RunDecisionIcon,
} from 'static/images';
import styles from './HistoryRecord.module.scss';
import formatDisplayId from 'utils/userIdFormat';

export interface HistoryListItemProps {
  record: ApplicationHistoryRecord;
  search: string;
}

const getFormattedValue = (value: VariableValue, variable: Variable | undefined, permissionGroupId: PermissionGroupId) => {
  return formatValueByDataType(value, variable, {
    accessPermissions: getVariableAccessPermissions(variable, permissionGroupId),
  });
};

const getUpdatedVariableMessage = () => ({
  displayName,
  oldValue,
  newValue,
  systemName,
}: ApplicationUpdatedVariable) => {
  const variable = useVariableBySystemName(systemName);
  const userPermissionGroupId = useUserPermissionGroupId()!;

  return `${displayName} (${
    typeof oldValue === 'undefined' ? 'set' : `from ${getFormattedValue(oldValue, variable, userPermissionGroupId)}`
  } to ${getFormattedValue(newValue, variable, userPermissionGroupId)})`;
};

const getApplicationUpdatedRecordDescription = (data: ApplicationUpdatedData) => {
  const isSingleVariableUpdated = data.variables.length === 1;
  const updatedVariablesMessage = data.variables.map(getUpdatedVariableMessage()).join(', ');

  return `The following variable${isSingleVariableUpdated ? '' : 's'} ${
    isSingleVariableUpdated ? 'was' : 'were'
  } updated: ${updatedVariablesMessage}.`;
};

const getHistoryRecordParams = (record: ApplicationHistoryRecord) => {
  switch (record.type) {
    case ApplicationHistoryType.ApplicationCreated: {
      return {
        title: 'Application Created',
        icon: <ApplicationCreatedIcon />,
        description: `The application for ${record.data.borrowerName} was created.`,
      };
    }
    case ApplicationHistoryType.ApplicationUpdated: {
      return {
        title: 'Updated Application Data',
        description: getApplicationUpdatedRecordDescription(record.data),
        isDescriptionCollabsible: true,
        icon: <ApplicationUpdatedIcon />,
      };
    }
    case ApplicationHistoryType.ApplicationDuplicated: {
      return {
        title: 'Application Created',
        icon: <ApplicationCreatedIcon />,
        description: `The application for ${
          record.data.borrowerName
        } was created as a duplicate from Application ${formatDisplayId(record.data.originalApplicationDisplayId)}.`,
      };
    }
    case ApplicationHistoryType.DecisionRun: {
      return {
        title: 'Ran Decision Engine',
        icon: <RunDecisionIcon />,
        description: `The decision engine ran ${record.data.decisionName
        } with the strategy ${
          record.data.strategyDisplayName
        } and the decision ${
          record.data.result
        }.`,
      };
    }
    case ApplicationHistoryType.StatusTransition: {
      return {
        title: 'Changed Application Status',
        icon: <StatusTransitionIcon />,
        description: `This status of ${record.data.name}’s application was updated from ${record.data.transitionFrom} to ${record.data.transitionTo}.`,
      };
    }
    case ApplicationHistoryType.TaskCreated: {
      return {
        title: 'Added New Task',
        description: `The following task was created: “${record.data.description}”.`,
        icon: <TaskCompletedIcon />,
      };
    }
    case ApplicationHistoryType.TaskCompleted: {
      return {
        title: 'Completed Task',
        description: `The following task was completed: “${record.data.description}”.`,
        icon: <TaskCompletedIcon />,
      };
    }
    case ApplicationHistoryType.TaskDeleted: {
      return {
        title: 'Deleted Task',
        description: `The following task was deleted: “${record.data.description}”.`,
        icon: <TaskDeletedIcon />,
      };
    }
    case ApplicationHistoryType.EmailCreated: {
      return {
        title: 'Email',
        description: `An email with the subject “${record.data.subject}” was sent from ${record.data.from} to ${record.data.to}.`,
        icon: <EmailCreatedIcon />,
      };
    }
    case ApplicationHistoryType.EmailDeleted: {
      return {
        title: 'Deleted Email',
        description: `An email with the subject “${record.data.subject}” from ${record.data.from} to ${record.data.to} was deleted.`,
        icon: <EmailDeletedIcon />,
      };
    }
    case ApplicationHistoryType.DocumentCreated: {
      return {
        title: 'Uploaded Document',
        description: `The following document was uploaded: ${record.data.documentName}.`,
        icon: <DocumentUploadedIcon />,
      };
    }
    case ApplicationHistoryType.DocumentDeleted: {
      return {
        title: 'Deleted Document',
        description: `The following document was deleted: ${record.data.documentName}.`,
        icon: <DocumentDeletedIcon />,
      };
    }
    case ApplicationHistoryType.NoteCreated: {
      return {
        title: 'Created Note',
        description: `The following note was created: ${record.data.description}.`,
        isDescriptionCollabsible: true,
        icon: <NoteCreatedIcon />,
      };
    }
    case ApplicationHistoryType.NoteDeleted: {
      return {
        title: 'Deleted Note',
        description: `The following note was deleted: ${record.data.description}.`,
        isDescriptionCollabsible: true,
        icon: <NoteDeletedIcon />,
      };
    }
    case ApplicationHistoryType.CoborrowerAdded: {
      return {
        title: 'Added Coborrower',
        description: `The Co-Borrower ${record.data.coborrowerName} was added to the application.`,
        icon: <CoborrowerAddedIcon />,
      };
    }
    case ApplicationHistoryType.CoborrowerRemoved: {
      return {
        title: 'Removed Coborrower',
        description: `The Co-Borrower ${record.data.coborrowerName} was removed from the application.`,
        icon: <CoborrowerDeletedIcon />,
      };
    }
    case ApplicationHistoryType.IntermediaryAdded: {
      return {
        title: 'Added Intermediary',
        description: `The Intermediary ${record.data.intermediaryName} was added to the application.`,
        icon: <CoborrowerAddedIcon />,
      };
    }
    case ApplicationHistoryType.IntermediaryRemoved: {
      return {
        title: 'Removed Intermediary',
        description: `The Intermediary ${record.data.intermediaryName} was removed from the application.`,
        icon: <CoborrowerDeletedIcon />,
      };
    }
    default: {
      return {
        title: '',
        description: '',
        icon: null,
      };
    }
  }
};

const HistoryRecord = ({ record, search }: HistoryListItemProps) => {
  const { title, icon, description, isDescriptionCollabsible = false } = getHistoryRecordParams(record);

  return (
    <div className={styles.record}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.recordContent}>
        <h6 className={styles.contentTitle}>{title}</h6>
        <p className={styles.contentDescription}>
          {isDescriptionCollabsible ? (
            <CollapsibleText btnClassName={styles.collapseBtn} textLength={description.length}>
              <SearchHighlight search={search}>{description}</SearchHighlight>
            </CollapsibleText>
          ) : (
            <SearchHighlight search={search}>{description}</SearchHighlight>
          )}
        </p>
      </div>
      <div className={styles.recordInfo}>
        <WithSystemApiUserAvatar size="tiny" user={record.createdBy} />
        <p>{formatDate(record.createdAt, DateTimeFormat.Long)}</p>
      </div>
    </div>
  );
};

export default HistoryRecord;
