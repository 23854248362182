import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BeforeCapture, DragStart, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { ReduxState } from 'types/redux';
import { Variable, VariableClientType } from 'Variables/VariablesTypes';
import { getVariableType } from 'Variables/utils';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import {
  closeCreateVariablePopup,
  getListVariables,
  openCreateVariablePopup,
  resetVariablesList,
  setSearchInputValue,
} from 'LoanOriginationSystemVariablesList/ActionCreator';
import DndListContext from 'components/DndList/DndListContext';
import Portal from 'components/Portal';
import CreateVariablePopup from 'components/CreateVariablePopup';
import VariablesPane from './VariablesPane';
import VariablesRightSidePopupLayout from './VariablesRightSidePopupLayout';
import pagination from './pagination';
import styles from './VariablesConfiguration.module.scss';
import { ListVariableAttributes } from './VariablesPane/VariablesPane';

export interface VariablesConfigurationProps {
  variablesPaneOpen?: boolean;
  children: (
    placeholderStyles: CSSProperties | null,
    droppableType: string,
    draggingId: string | null,
    sourceDroppableId: string | null,
  ) => JSX.Element;
  onClosePane: () => void;
  onReorder: (result: DropResult) => void;
  onAddVariable: (
    cardId: string,
    column: number,
    row: number,
    variable: Variable,
  ) => void;
  allowStandardVariables?: boolean;
  disabledVariablesIds?: string[];
  getListVariableAttributes?: (variable: Variable) => ListVariableAttributes;
}

const VARIABLES_PANE_DROPPABLE_ID = 'VARIABLES_PANE_DROPPABLE_ID';
const VARIABLE_DROPPABLE_TYPE = 'variable';

const VariablesConfiguration = ({
  children,
  variablesPaneOpen,
  onClosePane,
  onAddVariable,
  onReorder,
  allowStandardVariables,
  disabledVariablesIds,
  getListVariableAttributes,
}: VariablesConfigurationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const [hideVariablesPane, setHideVariablesPane] = useState(false);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [sourceDroppableId, setSourceDroppableId] = useState<string | null>(null);
  const { isCreatingVariablePopupOpen, isCreatingVariableInProgress, search } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemVariablesList,
  );

  const paginationParams = { search, allowStandardVariables };

  const variables = pagination.usePaginatedItems(paginationParams);
  const paginationProps = pagination.usePagination(paginationParams);

  useEffect(() => {
    if (variablesPaneOpen) {
      dispatch(getListVariables(search, allowStandardVariables));
    }

    return () => {
      if (variablesPaneOpen) {
        dispatch(resetVariablesList());
      }
    };
  }, [variablesPaneOpen]);

  const handleCloseCreateVariablePopup = () => dispatch(closeCreateVariablePopup());
  const handleOpenCreateVariablePopup = () => dispatch(openCreateVariablePopup());
  const handleCreateVariable = async (variable: VariableClientType) => {
    const simpleVariableData = getVariableType(variable);
    const formattedVariable = { ...variable, ...simpleVariableData };

    const action = setActionOrigin(
      createVariableRequest(formattedVariable),
      CreateVariableActionOrigin.VariablesConfiguration,
    );

    await dispatchRoutine(action);

    dispatch(getListVariables(search, allowStandardVariables));
  };

  const handleVariablesSearch = (newSearch: string) => dispatch(setSearchInputValue(newSearch, allowStandardVariables));

  const handleDragStart = (event: DragStart) => {
    if (event.source.droppableId === VARIABLES_PANE_DROPPABLE_ID) {
      setHideVariablesPane(true);
    }

    setSourceDroppableId(event.source.droppableId);
  };

  const handleDragEnd = (result: DropResult) => {
    if (result.source.droppableId === VARIABLES_PANE_DROPPABLE_ID) {
      setHideVariablesPane(false);
    }

    setSourceDroppableId(null);
    setDraggingId(null);
  };

  const handleReorder = (result: DropResult) => {
    if (result.source.droppableId === VARIABLES_PANE_DROPPABLE_ID && result.destination) {
      const { droppableId, index } = result.destination;

      const [cardId, columnIndex] = droppableId.split('-');

      return onAddVariable(cardId, Number(columnIndex), index, variables[result.source.index]!);
    }

    if (
      result.source.droppableId === result.destination?.droppableId &&
      result.source.index === result.destination?.index
    ) {
      return null;
    }

    return onReorder(result);
  };

  const handleBeforeCapture = (beforeCapture: BeforeCapture) => {
    setDraggingId(beforeCapture.draggableId);
  };

  const handleBackdropClick = () => {
    onClosePane();
  };

  const rightSidePopupLayoutStyle = clsx(styles.rightSidePopup, {
    [styles.hiddenRightSidePopup]: hideVariablesPane,
  });

  const hasMoreVariablesToLoad =
    typeof paginationProps.itemsTotal !== 'undefined' ? paginationProps.itemsTotal !== variables.length : false;

  const renderVariablesPane = (placeholderStyles: CSSProperties | null) => {
    if (!variablesPaneOpen) {
      return null;
    }

    return (
      <VariablesPane
        onClosePane={onClosePane}
        placeholderStyles={placeholderStyles}
        paneDroppableId={VARIABLES_PANE_DROPPABLE_ID}
        paneDroppableType={VARIABLE_DROPPABLE_TYPE}
        draggingId={draggingId}
        variables={variables}
        hasMore={hasMoreVariablesToLoad}
        search={search}
        onSearch={handleVariablesSearch}
        onCreateNewVariable={handleOpenCreateVariablePopup}
        onLoadMore={paginationProps.nextPage}
        allowStandardVariables={allowStandardVariables}
        disabledVariablesIds={disabledVariablesIds}
        getListVariableAttributes={getListVariableAttributes}
      />
    );
  };

  const renderCreateVariablePopup = () => {
    if (!isCreatingVariablePopupOpen) {
      return null;
    }

    return (
      <CreateVariablePopup
        isSavingInProgress={isCreatingVariableInProgress}
        className={styles.createVariablePopup}
        onClose={handleCloseCreateVariablePopup}
        onSave={handleCreateVariable}
      />
    );
  };

  return (
    <>
      <DndListContext
        onReorder={handleReorder}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onBeforeCapture={handleBeforeCapture}
      >
        {(placeholderStyles) => (
          <>
            {children(placeholderStyles, VARIABLE_DROPPABLE_TYPE, draggingId, sourceDroppableId)}
            <Portal>
              <VariablesRightSidePopupLayout className={rightSidePopupLayoutStyle}>
                {renderVariablesPane(placeholderStyles)}
              </VariablesRightSidePopupLayout>
            </Portal>
          </>
        )}
      </DndListContext>
      {renderCreateVariablePopup()}
      {variablesPaneOpen && <div className={styles.backdrop} onClick={handleBackdropClick} />}
    </>
  );
};

export default VariablesConfiguration;
