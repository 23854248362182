import React, { FC, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { ReduxState } from 'types/redux';
import { EmptyTableImage } from 'static/images';
import { WebhookEvent, WebhookEventSortingField, WebhookEventSortingType } from 'api/Webhooks/WebhookEventsApi';
import WebhookEventsTable from 'components/Webhooks/WebhookEventsTable';
import QuestionIcon from 'components/QuestionIcon';
import SearchInput from 'components/SearchInput';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { useDispatch, useSelector } from 'react-redux';
import useStateReset from 'hooks/useStateReset';
import pagination, { WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT } from 'Webhooks/WebhookEndpointDetails/WebhookEndpointEventsTable/Pagination';
import { setSearchInputValue } from 'Webhooks/WebhookEventsTable/Filters/WebhookEventsTableFiltersStore';
import { changeSortingType } from 'Webhooks/WebhookEventsTable/WebhookEventsTableStore';
import { WebhookEndpointEventsTableActionType } from 'Webhooks/WebhookEndpointDetails/WebhookEndpointEventsTable/WebhookEndpointEventsTableActionType';
import { createGetWebhookEventsSelector } from 'WebhookEvents/Selectors';
import { getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import styles from './WebhookEndpointEvents.module.scss';

export interface WebhookEndpointEventsProps {
  webhookEndpointId: string;
  onDeleteWebhookEvent: (webhookEventId: string) => void;
  onResendWebhookEvent: (webhookEventId: string) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
}

const WebhookEndpointEvents: FC<WebhookEndpointEventsProps> = ({
  webhookEndpointId,
  onDeleteWebhookEvent,
  onWebhookEventClick,
  onResendWebhookEvent,
}) => {
  const dispatch = useDispatch();

  const sortingType = useSelector((state: ReduxState) => state.webhookEndpointDetails.webhookEndpointEventsTable.sortingType);
  const search = useSelector((state: ReduxState) => state.webhookEndpointDetails.webhookEndpointEventsTable.search);

  useStateReset(WebhookEndpointEventsTableActionType.ResetState);

  const params = {
    sortingType,
    search,
    webhookId: webhookEndpointId,
  };

  const requestWebhookEndpointEvents = (
    webhookEndpointIdToApply: string,
    sortingTypeToApply: WebhookEventSortingType,
    searchToApply: string,
  ) => {
    dispatch(getWebhookEvents({
      filters: { offset: 0, count: WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT, search: searchToApply, webhookId: webhookEndpointIdToApply },
      sortingType: sortingTypeToApply,
      actionOrigin: WebhookEventsActionOrigin.WebhookEndpointEventsTable,
    }));
  };

  const webhookEndpointEventsIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const getWebhookEventsSelector = useMemo(() => {
    return createGetWebhookEventsSelector();
  }, []);

  const webhookEndpointEvents = useSelector((state: ReduxState) => {
    return getWebhookEventsSelector(state, webhookEndpointEventsIds);
  });

  const renderTooltip = () => (
    <>
      <p>Events include only the updated information</p>
      <p>and are automatically deleted after 3 days</p>
    </>
  );

  useEffect(() => {
    requestWebhookEndpointEvents(webhookEndpointId, sortingType, search);
  }, [webhookEndpointId]);

  const handleSearchClear = () => {
    dispatch(setSearchInputValue(''));

    requestWebhookEndpointEvents(webhookEndpointId, sortingType, '');
  };

  const debouncedSearch = useMemo(() => {
    return debounce(requestWebhookEndpointEvents, 300);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInputValue(event.target.value));

    debouncedSearch(webhookEndpointId, sortingType, event.target.value);
  };

  const handleSort = (sortingField: WebhookEventSortingField, ascending: boolean) => {
    dispatch(changeSortingType({
      field: sortingField,
      ascending,
    }));

    requestWebhookEndpointEvents(webhookEndpointId, {
      field: sortingField,
      ascending,
    }, search);
  };

  const shouldRenderNoItemsAddedSection = paginationProps.itemsTotal === 0 && !search;

  const renderContent = () => {
    if (shouldRenderNoItemsAddedSection) {
      return (
        <NoItems
          className={styles.noItemsAddedSection}
          title="This endpoint has no associated events yet!"
          icon={<EmptyTableImage />}
        />
      );
    }

    if (search && !webhookEndpointEvents.length) {
      return (
        <SearchNotFound searchValue={search} />
      );
    }

    return (
      <WebhookEventsTable
        source={webhookEndpointEvents}
        paginationProps={paginationProps}
        sortingType={sortingType}
        searchInputValue={search}
        onSort={handleSort}
        onWebhookEventClick={onWebhookEventClick}
        onWebhookEventDelete={onDeleteWebhookEvent}
        onWebhookEventResend={onResendWebhookEvent}
      />
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Events</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
      </div>
      {!shouldRenderNoItemsAddedSection && <SearchInput
        value={search}
        containerClassName={styles.searchInputContainer}
        placeholder="Search"
        onClear={handleSearchClear}
        onChange={handleSearchChange}
      />}
      {renderContent()}
    </div>
  );
};

export default WebhookEndpointEvents;
