import React, { FC } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  WebhookEndpoint,
  WebhookEndpointSortingField,
  WebhookEndpointSortingType,
} from 'api/Webhooks/WebhookEndpointsApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import Pagination from 'components/Pagination';
import WebhookEndpointItemRow from './WebhookEndpointItemRow';
import { WebhookEndpointsColumnSize } from './TableColumnSize';
import SkeletonWebhookEndpointItemRow from './SkeletonWebhookEndpointItemRow';
import styles from './WebhookEndpointsTable.module.scss';

interface WebhookEndpointsTableProps {
  source: NullableItems<WebhookEndpoint>;
  paginationProps: PaginationProps;
  sortingType: WebhookEndpointSortingType;
  onSort: (field: WebhookEndpointSortingField, ascending: boolean) => void;
  onWebhookEndpointEdit: (id: string) => void;
  onWebhookEndpointClick: (id: string) => void;
  onWebhookEndpointDelete: (id: string) => void;
  onWebhookEndpointStatusChange: (id: string, active: boolean) => void;
}

const WebhookEndpointsTable: FC<WebhookEndpointsTableProps> = ({
  source,
  paginationProps,
  sortingType,
  onSort,
  onWebhookEndpointEdit,
  onWebhookEndpointDelete,
  onWebhookEndpointClick,
  onWebhookEndpointStatusChange,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonTableRow = (index: number) => <SkeletonWebhookEndpointItemRow key={index} />;

  const renderTableRow = (item: WebhookEndpoint) => {
    return (
      <WebhookEndpointItemRow
        key={item.id}
        webhookEndpoint={item}
        onClick={() => onWebhookEndpointClick(item.id)}
        onWebhookEndpointDelete={onWebhookEndpointDelete}
        onWebhookEndpointEdit={onWebhookEndpointEdit}
        onChangeEndpointStatus={onWebhookEndpointStatusChange}
      />
    );
  };

  return (
    <Table>
      <div className={styles.table}>
        <Table>
          <TableHead sticky>
            <TableHeadCell
              width={WebhookEndpointsColumnSize.Url}
              ascending={getSortingType(WebhookEndpointSortingField.Url)}
              onClick={() => changeSorting(WebhookEndpointSortingField.Url)}
            >
              Url
            </TableHeadCell>
            <TableHeadCell
              width={WebhookEndpointsColumnSize.Description}
              ascending={getSortingType(WebhookEndpointSortingField.Description)}
              onClick={() => changeSorting(WebhookEndpointSortingField.Description)}
            >
              Description
            </TableHeadCell>
            <TableHeadCell width={WebhookEndpointsColumnSize.ListeningFor}>
              Listening For
            </TableHeadCell>
            <TableHeadCell
              width={WebhookEndpointsColumnSize.Status}
              ascending={getSortingType(WebhookEndpointSortingField.Status)}
              onClick={() => changeSorting(WebhookEndpointSortingField.Status)}
            >
              Status
            </TableHeadCell>
            <TableHeadCell
              width={WebhookEndpointsColumnSize.UpdatedAt}
              ascending={getSortingType(WebhookEndpointSortingField.UpdatedAt)}
              onClick={() => changeSorting(WebhookEndpointSortingField.UpdatedAt)}
            >
              Updated
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent
              renderSkeletonTableRow={renderSkeletonTableRow}
              renderTableRow={renderTableRow}
              rows={source}
            />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    </Table>
  );
};

export default WebhookEndpointsTable;
