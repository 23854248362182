import React, { useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { WebhooksIllustration } from 'static/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  WebhookEndpointSortingField,
  WebhookEndpointSortingType,
} from 'api/Webhooks/WebhookEndpointsApi';
import useStateReset from 'hooks/useStateReset';
import { ReduxState } from 'types/redux';
import Button from 'components/Button';
import QuestionIcon from 'components/QuestionIcon';
import WebhookEndpointsTable from 'components/Webhooks/WebhookEndpointsTable';
import NeedHelpSection from 'components/NeedHelpSection';
import { getWebhookEndpoints } from 'WebhookEndpoints/Thunks';
import pagination, { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from 'Webhooks/WebhookEndpointsTable/Pagination';
import { getWebhookEndpointsSelector } from 'WebhookEndpoints/Selectors';
import { changeSortingType } from 'Webhooks/WebhookEndpointsTable/WebhookEndpointsTableStore';
import { WebhookEndpointsTableActionType } from 'Webhooks/WebhookEndpointsTable/WebhookEndpointsTableActionType';
import styles from './WebhookEndpointsDashboard.module.scss';

export interface WebhookEndpointsDashboardProps {
  onDeleteWebhook: (id: string) => void;
  onCreateWebhookEndpointButtonClick: () => void;
  onChangeWebhookEndpointStatus: (id: string, active: boolean) => void;
}

const WebhookEndpointsDashboard: FC<WebhookEndpointsDashboardProps> = ({
  onDeleteWebhook,
  onCreateWebhookEndpointButtonClick,
  onChangeWebhookEndpointStatus,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sortingType = useSelector((state: ReduxState) => state.webhookEndpointsTable.sortingType);

  useStateReset(WebhookEndpointsTableActionType.ResetState);

  const params = { sortingType };

  const webhookEndpointsIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const webhookEndpoints = useSelector((state: ReduxState) => getWebhookEndpointsSelector(state, webhookEndpointsIds));

  const renderTooltip = () => (
    <>
      <p>Endpoints are locations (URLs) that we</p>
      <p>will send events to when data is updated</p>
    </>
  );

  const requestWebhookEndpoints = (sortingTypeToApply: WebhookEndpointSortingType) => {
    dispatch(getWebhookEndpoints({
      filters: { count: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT, offset: 0 },
      sortingType: sortingTypeToApply,
    }));
  }

  useEffect(() => {
    requestWebhookEndpoints(sortingType);
  }, []);

  const handleSort = (field: WebhookEndpointSortingField, ascending: boolean) => {
    const updatedSortingType = { field, ascending };

    dispatch(changeSortingType(updatedSortingType));

    requestWebhookEndpoints(updatedSortingType);
  };

  const shouldRenderNoItemsAddedSection = paginationProps.itemsTotal === 0;

  const handleEditWebhookEndpoint = (id: string) => {
    history.replace(`?edit=${id}`);
  };

  const renderContent = () => {
    if (shouldRenderNoItemsAddedSection) {
      return (
        <div className={styles.noItemsAddedSection}>
          <h5 className={styles.noItemsAddedSectionTitle}>Listen To DigiFi Events</h5>
          <div className={styles.noItemsAddedSectionDescription}>
            <p>Create webhooks endpoints, so that DigiFi can</p>
            <p>notify you when asynchronous events occur.</p>
          </div>
          <WebhooksIllustration />
          <NeedHelpSection
            link="https://docs.digifi.io/reference/webhooks-introduction"
            linkTitle="Developer Guide"
            className={styles.needHelpSection}
          />
        </div>
      );
    }

    return (
      <WebhookEndpointsTable
        source={webhookEndpoints}
        paginationProps={paginationProps}
        sortingType={sortingType}
        onSort={handleSort}
        onWebhookEndpointEdit={handleEditWebhookEndpoint}
        onWebhookEndpointClick={handleEditWebhookEndpoint}
        onWebhookEndpointDelete={onDeleteWebhook}
        onWebhookEndpointStatusChange={onChangeWebhookEndpointStatus}
      />
    );
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Endpoints</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
        <Button onClick={onCreateWebhookEndpointButtonClick} className={styles.addEndpointButton} kind="primary">
          Add Endpoint
        </Button>
      </div>
      {renderContent()}
    </div>
  );
};

export default WebhookEndpointsDashboard;
