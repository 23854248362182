import React from 'react';

interface WrapperWithLinkProps {
  children: React.ReactNode;
  href: string;
  onClick: () => void;
  className?: string;
}

const WrapperWithLink = ({
  children,
  href,
  onClick,
  className,
}: WrapperWithLinkProps) => {
  const onWrappingLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    event.preventDefault();
    onClick();
  };

  return (
    <a href={href} onClick={onWrappingLinkClick} className={className}>
      {children}
    </a>
  );
};

export default WrapperWithLink;
