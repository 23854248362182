import {
  ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE,
  CLOSE_ADD_RULE_POP_UP,
  CREATE_CALCULATION_SCRIPT,
  CREATE_CALCULATION_SCRIPT_FAILURE,
  CREATE_CALCULATION_SCRIPT_SUCCESS,
  CREATE_RULE,
  CREATE_RULE_FAILURE,
  CREATE_RULE_SUCCESS,
  CREATE_SIMPLE_OUTPUT,
  CREATE_SIMPLE_OUTPUT_FAILURE,
  CREATE_SIMPLE_OUTPUT_SUCCESS,
  DUPLICATE_RULE,
  DUPLICATE_RULE_SUCCESS,
  OPEN_ADD_RULE_POP_UP,
  SET_CURRENT_RULE_TYPE,
  UPLOAD_RULE_SET,
  UPLOAD_RULE_SET_FAILURE,
  UPLOAD_RULE_SET_SUCCESS,
} from './ActionType';
import {
  AddRuleVariableCreationToggleAction,
  CloseAddRulePopUpAction,
  CompleteCalculationScriptData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  CompleteUploadRuleSetRequestData,
  DuplicateRuleAction,
  DuplicateRuleRequestData,
  DuplicateRuleSuccessAction,
  EntityType,
  OpenAddRulePopUpAction,
  RulePopUpType,
  SaveCalculationScriptAction,
  SaveCalculationScriptSuccessAction,
  SaveRuleAction,
  SaveRuleSuccessAction,
  SaveSimpleOutputAction,
  SaveSimpleOutputSuccessAction,
  SetCurrentRuleTypeAction,
  UploadRuleSetAction,
  UploadRuleSetSuccessAction,
} from './Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { DUPLICATE_BRANCH_FAILURE } from 'BranchInfo/ActionTypes';
import { CalculationScriptMode } from 'api/Types';

export const openAddRulePopUp = (payload: {
  entityType: EntityType;
  moduleType: ModuleType;
  calculationMode?: CalculationScriptMode;
}): OpenAddRulePopUpAction => ({
  type: OPEN_ADD_RULE_POP_UP,
  payload,
});

export const closeAddRulePopUp = (): CloseAddRulePopUpAction => ({
  type: CLOSE_ADD_RULE_POP_UP,
});

export const setCurrentRuleType = (payload: RulePopUpType): SetCurrentRuleTypeAction => ({
  type: SET_CURRENT_RULE_TYPE,
  payload,
});

export const createRule = (payload: CompleteRuleRequestData): SaveRuleAction => ({
  type: CREATE_RULE,
  payload,
});

export const duplicateRule = (payload: DuplicateRuleRequestData): DuplicateRuleAction => ({
  type: DUPLICATE_RULE,
  payload,
});

export const duplicateRuleSuccess = (payload: DuplicateRuleRequestData): DuplicateRuleSuccessAction => ({
  type: DUPLICATE_RULE_SUCCESS,
  payload,
});

export const createRuleSuccess = (payload: CompleteRuleRequestData): SaveRuleSuccessAction => ({
  type: CREATE_RULE_SUCCESS,
  payload,
});

export const createRuleFailure = FailedActionCreator(CREATE_RULE_FAILURE);
export const duplicateRuleFailure = FailedActionCreator(DUPLICATE_BRANCH_FAILURE);

export const createCalculationScript = (payload: CompleteCalculationScriptData): SaveCalculationScriptAction => ({
  type: CREATE_CALCULATION_SCRIPT,
  payload,
});

export const createCalculationScriptSuccess = (
  payload: CompleteCalculationScriptData,
): SaveCalculationScriptSuccessAction => ({
  type: CREATE_CALCULATION_SCRIPT_SUCCESS,
  payload,
});

export const createCalculationScriptFailure = FailedActionCreator(CREATE_CALCULATION_SCRIPT_FAILURE);

export const createSimpleOutput = (payload: CompleteSimpleOutputRequestData): SaveSimpleOutputAction => ({
  type: CREATE_SIMPLE_OUTPUT,
  payload,
});

export const createSimpleOutputSuccess = (payload: CompleteSimpleOutputRequestData): SaveSimpleOutputSuccessAction => ({
  type: CREATE_SIMPLE_OUTPUT_SUCCESS,
  payload,
});

export const createSimpleOutputFailure = FailedActionCreator(CREATE_SIMPLE_OUTPUT_FAILURE);

export const uploadRuleSet = (payload: CompleteUploadRuleSetRequestData): UploadRuleSetAction => ({
  type: UPLOAD_RULE_SET,
  payload,
});

export const uploadRuleSetSuccess = (payload: CompleteUploadRuleSetRequestData): UploadRuleSetSuccessAction => ({
  type: UPLOAD_RULE_SET_SUCCESS,
  payload,
});

export const uploadRuleSetFailure = FailedActionCreator(UPLOAD_RULE_SET_FAILURE);

export const openVariableCreation = (): AddRuleVariableCreationToggleAction => ({
  type: ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE,
  payload: true,
});

export const closeVariableCreation = (): AddRuleVariableCreationToggleAction => ({
  type: ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE,
  payload: false,
});
