export enum LoanOriginationSystemBorrowerLockType {
  UnlockBorrower = 'loanOriginationSystemBorrowerLock/unlockBorrower',
  UnlockBorrowerSuccess = 'loanOriginationSystemBorrowerLock/unlockBorrowerSuccess',
  UnlockBorrowerFailure = 'loanOriginationSystemBorrowerLock/unlockBorrowerFailure',
  LockBorrower = 'loanOriginationSystemBorrowerLock/lockBorrower',
  LockBorrowerSuccess = 'loanOriginationSystemBorrowerLock/lockBorrowerSuccess',
  LockBorrowerFailure = 'loanOriginationSystemBorrowerLock/lockBorrowerFailure',
  SetBorrowerToUnlock = 'loanOriginationSystemBorrowerLock/setBorrowerToUnlock',
  SetBorrowerToLock = 'loanOriginationSystemBorrowerLock/setBorrowerToLock',
}
