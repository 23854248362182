import { ChangeStrategyArchiveStatusParams, DecisionEngineApi } from 'api/Types';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import {
  ArchiveStrategiesAction,
  archiveStrategiesFailure,
  archiveStrategiesSuccess,
  StrategyActionOrigin,
  getDecisionStrategyInfoRequest,
  RestoreStrategiesAction,
  restoreStrategiesFailure,
  restoreStrategiesSuccess,
} from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { StrategyArchiveStatusActionType } from 'DecisionStrategy/ActionTypes';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { getStrategyGroupsData } from 'StrategyGroupsData/Actions';
import { getVersionsListData } from 'VersionsViewData/Actions';
import errorTypeToMessageTypeMap from 'constants/errorTypeToMessageTypeMap';
import { RequestError } from 'utils/fetch';
import { ArchiveEntityType } from 'components/ArchiveStrategyWizard/changeStrategyStatusWizardReducer';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';

const getChangeArchiveStatusParams = (payload: ChangeStrategyArchiveStatusParams) =>
  payload.type === 'group' ? { groupName: payload.strategyName } : { id: payload.id };

export const ArchiveStrategiesMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ChangeStrategyArchiveStatusParams, ArchiveStrategiesAction, null, RequestError>(
    StrategyArchiveStatusActionType.ArchiveStrategy,
    archiveStrategiesSuccess,
    archiveStrategiesFailure,
    async ({ payload }) => {
      await api.archiveStrategies(getChangeArchiveStatusParams(payload));
      return payload;
    },
  );

export const RestoreStrategiesMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ChangeStrategyArchiveStatusParams, RestoreStrategiesAction>(
    StrategyArchiveStatusActionType.RestoreStrategy,
    restoreStrategiesSuccess,
    restoreStrategiesFailure,
    async ({ payload }) => {
      await api.restoreStrategies(getChangeArchiveStatusParams(payload));
      return payload;
    },
  );

export const onChangeArchiveStatusSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  const isStrategiesArchived = action.type === StrategyArchiveStatusActionType.ArchiveStrategySuccess;
  const isStrategiesRestored = action.type === StrategyArchiveStatusActionType.RestoreStrategySuccess;
  if (isStrategiesArchived || isStrategiesRestored) {
    const { version, strategyName, type, id } = action.payload;
    const messageNamePrefix = type === 'group' ? 'Strategy' : 'Version';
    const messageType = isStrategiesArchived
      ? MessageType[`${messageNamePrefix}Archived`]
      : MessageType[`${messageNamePrefix}Restored`];

    const successMessage = getMessage(messageType, { version, strategyName });
    notification.createNotification(successMessage, 'success', dispatch);

    const { actionOrigin } = action.meta;
    if (actionOrigin === StrategyActionOrigin.StrategiesGroupsList) {
      dispatch(getStrategyGroupsData());
    }

    if (actionOrigin === StrategyActionOrigin.StrategyGroupVersionsList) {
      dispatch(getVersionsListData());
      dispatch(getStrategyGroupsData());
    }

    if (
      actionOrigin === StrategyActionOrigin.VersionOverview ||
      actionOrigin === StrategyActionOrigin.StrategyOverviewVersionsList
    ) {
      dispatch(getDecisionStrategyInfoRequest(id!));
      dispatch(getDecisionStrategyUpdatesRequest(id!));
      dispatch(getVersionsListData());
    }

    if (actionOrigin === StrategyActionOrigin.StrategyOverviewVersionsList) {
      dispatch(getVersionsListData());
    }
  }

  return result;
};

export const onChangeArchiveStatusFailureMiddleware: Middleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === StrategyArchiveStatusActionType.ArchiveStrategyFailure) {
    const error = action.payload;
    const messageType = errorTypeToMessageTypeMap[error.errorType];
    const { changeStrategyStatusWizard } = getState();

    notification.createNotification(
      getMessage(messageType, {
        strategyName: changeStrategyStatusWizard.strategyName,
        ...(changeStrategyStatusWizard.entityType === ArchiveEntityType.Version
          ? { version: changeStrategyStatusWizard.versionNumber }
          : {}),
      }),
      'error',
      dispatch,
    );
  }

  return result;
};
