import React, { FC } from 'react';
import AceEditor from 'react-ace';
import { WebhookEvent, WebhookEventStatus } from 'api/Webhooks/WebhookEventsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import { Tag } from 'components/Tag';
import WrapperWithTooltip from 'components/Tooltip';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import styles from './WebhookEventDetailsPopup.module.scss';

interface WebhookEventDetailsPopupProps {
  webhookEvent: WebhookEvent;
  onResendEvent: (id: string) => void;
  onDeleteEvent: (id: string) => void;
  isResending?: boolean;
  onClose: () => void;
}

const WebhookEventDetailsPopup: FC<WebhookEventDetailsPopupProps> = ({
  webhookEvent,
  onResendEvent,
  onDeleteEvent,
  isResending,
  onClose,
}) => {
  const renderTag = () => {
    if (webhookEvent.status === WebhookEventStatus.Succeeded) {
      return <Tag className={styles.tag} color="green">Succeeded</Tag>
    }

    return <Tag className={styles.tag} color="red">Failed</Tag>;
  };

  const renderEventDetailsDataField = (label: string, value: string) => {
    return (
      <div key={label} className={styles.eventDetailsDataField}>
        <p className={styles.eventDetailsDataFieldLabel}>{label}</p>
        <p className={styles.eventDetailsDataFieldValue}>{value || <TableBodyCellNoContent />}</p>
      </div>
    );
  }

  const renderResendEventButtonTooltip = () => {
    return webhookEvent.status === WebhookEventStatus.Succeeded ? 'This event was already sent successfully' : '';
  };

  return (
    <PopUp title={webhookEvent.event.type} closePopUp={onClose}>
      <PopUpContent>
        <div className={styles.subtitle}>
          <p className={styles.eventDate}>{formatDate(webhookEvent.lastRunAt, DateTimeFormat.Short)}</p>
          {renderTag()}
        </div>
        <div className={styles.eventDataPreviewContainer}>
          <label className={styles.label}>Event Data</label>
          <AceEditor
            className={styles.eventDataPreview}
            name="eventPreview"
            mode="json"
            theme="xcode"
            fontSize="15px"
            value={JSON.stringify(webhookEvent.event.data, null, 2)}
            height='300px'
            readOnly
            showPrintMargin
            highlightActiveLine={false}
            width='100%'
            editorProps={{ $blockScrolling: false }}
          />
        </div>
        <div className={styles.eventSendingData}>
          <label className={styles.label}>Event Details</label>
          <div className={styles.eventDetailsDataFields}>
            {renderEventDetailsDataField('Event Id', webhookEvent.event.id)}
            {renderEventDetailsDataField('Last Run', formatDate(webhookEvent.lastRunAt, DateTimeFormat.Short))}
            {renderEventDetailsDataField(
              'Next Run',
              webhookEvent.nextRunAt ? formatDate(webhookEvent.nextRunAt, DateTimeFormat.Short) : '',
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <WrapperWithTooltip tooltip={renderResendEventButtonTooltip()}>
            <span>
              <Button
                className={styles.resendEventButton}
                kind="primary"
                size="form"
                onClick={() => onResendEvent(webhookEvent.id)}
                isLoading={isResending}
                disabled={isResending || webhookEvent.status === WebhookEventStatus.Succeeded}
              >
                Resend Event
              </Button>
            </span>
          </WrapperWithTooltip>
          <Button
            kind="warning"
            size="form"
            onClick={() => onDeleteEvent(webhookEvent.id)}
            disabled={isResending}
          >
            Delete Event
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default WebhookEventDetailsPopup;
