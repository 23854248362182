import { AnyAction, Middleware } from 'redux';
import { SendGridActionType } from './ActionTypes';
import SendGridIntegrationApi from 'api/LoanOriginationSystem/SendGridIntegrationApi';
import SendGridImportApi from 'api/LoanOriginationSystem/SendGridImportApi';
import SendGridTemplateApi from 'api/LoanOriginationSystem/SendGridTemplateApi';
import Fetcher, { RoutineHandler } from 'middlewares/Fetcher';
import notification from 'handlers/notification/notificationActionCreator';
import {
  SaveSendGridIntegrationRequestAction,
  SaveSendGridIntegrationSuccess,
  SaveSendGridIntegrationError,
  GetSendGridIntegrationRequest,
  GetSendGridIntegrationSuccess,
  GetSendGridIntegrationFailure,
  GetSendGridIntegrationSuccessAction,
  GetTemplatesToImportRequestSuccessAction,
  GetTemplatesToImportRequestSuccess,
  GetTemplatesToImportRequestFailure,
  GetSenderEmailsRequestSuccess,
  GetSenderEmailsRequestFailure,
  importTemplatesRequestError,
  importTemplatesRequestSuccess,
  ImportTemplatesRequestAction,
  GetTemplatesRequestAction,
  GetTemplatesRequestSuccess,
  GetTemplatesRequestFailure,
  GetSenderEmailsRequestSuccessAction,
  updateTemplateStatusRequestSuccess,
  updateTemplateStatusRequestFailure,
  updateTemplateVariablesMappingRequestSuccess,
  updateTemplateVariablesMappingRequestFailure,
  UpdateTemplateVariablesMappingRequestSuccessAction,
  updateTemplateSenderEmailRequestSuccess,
  updateTemplateSenderEmailRequestFailure,
  UpdateTemplateSenderEmailRequestSuccessAction,
  UpdateTemplateStatusSuccessAction,
  GetTemplatesRequest,
  DeleteTemplateRequestSuccess,
  DeleteTemplateRequestError,
  DeleteTemplateRequestAction,
  DeleteTemplateRequestSuccessAction,
  GetTemplateAction,
  getTemplateSuccess,
  getTemplateFailure,
} from './ActionCreator';
import { ImportableSendGridTemplate, SendGridTemplate, SendGridIntegration } from './Types';
import { TableViewData } from 'api/Types';
import templatesPagination, { TEMPLATES_PER_PAGE_DEFAULT } from 'pages/SendGrid/Pagination';
import Debouncer from 'middlewares/Debouncer';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './Filters/ActionTypes';
import getMessage, { MessageType } from 'constants/messages';

export const SaveSendGridIntegrationMiddleware: (api: SendGridIntegrationApi) => Middleware = (api) =>
  Fetcher<SendGridIntegration, SaveSendGridIntegrationRequestAction>(
    SendGridActionType.SaveSendGridIntegrationRequest,
    SaveSendGridIntegrationSuccess,
    SaveSendGridIntegrationError,
    ({ payload }) => api.saveSendGridIntegration(payload),
  );

export const SaveSendGridIntegrationSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === SendGridActionType.SaveSendGridIntegrationRequestSuccess) {
      notification.createNotification('Changes saved successfully!', 'success', dispatch);
      dispatch(GetSendGridIntegrationRequest());
    }
  },
);

export const GetSendGridIntegrationMiddleware: (api: SendGridIntegrationApi) => Middleware = (api) =>
  Fetcher<SendGridIntegration, GetSendGridIntegrationSuccessAction>(
    SendGridActionType.GetSendGridIntegrationRequest,
    GetSendGridIntegrationSuccess,
    GetSendGridIntegrationFailure,
    () => api.getSendGridIntegration(),
  );

export const GetTemplatesRequestMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<TableViewData<SendGridTemplate>, GetTemplatesRequestAction>(
    SendGridActionType.GetTemplatesRequest,
    GetTemplatesRequestSuccess,
    GetTemplatesRequestFailure,
    ({ payload }) =>
      api.getSendGridTemplates(
        {
          search: payload.filters.searchInputValue,
          offset: 0,
          count: TEMPLATES_PER_PAGE_DEFAULT,
          active: payload.filters.active,
          members: payload.filters.selectedMembers,
          dueCreatedDateRange: payload.filters.dueCreatedDateRange,
          dueUpdatedDateRange: payload.filters.dueUpdatedDateRange,
        },
        payload.sortingType,
      ),
  );

export const TemplatesPaginationMiddleware = (api: SendGridTemplateApi) => {
  return templatesPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getSendGridTemplates(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.searchInputValue,
        members: params.selectedMembers,
        active: params.active,
        dueCreatedDateRange: params.dueCreatedDateRange,
        dueUpdatedDateRange: params.dueUpdatedDateRange,
      },
      params.sortingType,
    );
  });
};

export const TemplatesSearchMiddleware = Debouncer(
  (payload: string, getState) => {
    const { sendGrid } = getState();
    const { filters, sortingType } = sendGrid;

    return GetTemplatesRequest({
      filters,
      sortingType,
    });
  },
  [LoanOriginationSystemTemplatesFiltersActionTypes.SetSearchInputValue],
);

export const SortTemplatesMiddleware: Middleware = RoutineHandler(async (action: AnyAction, { dispatch, getState }) => {
  if (action.type === SendGridActionType.SortTemplates) {
    const { sendGrid } = getState();
    const { filters } = sendGrid;

    dispatch(
      GetTemplatesRequest({
        filters,
        sortingType: action.payload.sortingType,
      }),
    );
  }
}) as Middleware;

export const GetSenderEmailsRequestMiddleware: (api: SendGridImportApi) => Middleware = (api) =>
  Fetcher<string[], GetSenderEmailsRequestSuccessAction>(
    SendGridActionType.GetSenderEmailsRequest,
    GetSenderEmailsRequestSuccess,
    GetSenderEmailsRequestFailure,
    () => api.getSendGridSenderEmails(),
  );

export const GetTemplatesToImportRequestMiddleware: (api: SendGridImportApi) => Middleware = (api) =>
  Fetcher<ImportableSendGridTemplate[], GetTemplatesToImportRequestSuccessAction>(
    SendGridActionType.GetTemplatesToImportRequest,
    GetTemplatesToImportRequestSuccess,
    GetTemplatesToImportRequestFailure,
    () => api.getSendGridTemplatesToImport(),
  );

export const DeleteTemplateRequestMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate, DeleteTemplateRequestAction>(
    SendGridActionType.DeleteTemplateRequest,
    DeleteTemplateRequestSuccess,
    DeleteTemplateRequestError,
    ({ payload }) => api.delete(payload),
  );

export const DeleteTemplateRequestSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch, getState }) => {
    if (action.type === SendGridActionType.DeleteTemplateRequestSuccess) {
      const { payload: template } = action as DeleteTemplateRequestSuccessAction;

      notification.createNotification(`The template ${template.name} has been deleted.`, 'success', dispatch);

      const { sendGrid } = getState();

      const { filters, sortingType } = sendGrid;

      dispatch(
        GetTemplatesRequest({
          filters,
          sortingType,
        }),
      );
    }
  },
);

export const ImportSendGridTemplatesMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate[], ImportTemplatesRequestAction>(
    SendGridActionType.ImportTemplatesRequest,
    importTemplatesRequestSuccess,
    importTemplatesRequestError,
    ({ payload }) => api.importBatch(payload),
  );

export const ImportSendGridTemplatesSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch, getState }) => {
    if (action.type === SendGridActionType.ImportTemplatesRequestSuccess) {
      notification.createNotification('Templates have been imported.', 'success', dispatch);

      const { sendGrid } = getState();

      const { filters, sortingType } = sendGrid;

      dispatch(
        GetTemplatesRequest({
          filters,
          sortingType,
        }),
      );
    }
  },
);

export const UpdateTemplateStatusRequestMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate, UpdateTemplateStatusSuccessAction>(
    SendGridActionType.UpdateTemplateStatusRequest,
    updateTemplateStatusRequestSuccess,
    updateTemplateStatusRequestFailure,
    ({ payload }) => api.updateTemplate(payload.templateId, { active: payload.active }),
  );

export const UpdateTemplateSenderEmailRequestMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate, UpdateTemplateSenderEmailRequestSuccessAction>(
    SendGridActionType.UpdateTemplateSenderEmailRequest,
    updateTemplateSenderEmailRequestSuccess,
    updateTemplateSenderEmailRequestFailure,
    ({ payload }) => api.updateTemplate(payload.templateId, { senderEmail: payload.senderEmail }),
  );

export const UpdateTemplateVariablesMappingRequestMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate, UpdateTemplateVariablesMappingRequestSuccessAction>(
    SendGridActionType.UpdateTemplateVariablesMappingRequest,
    updateTemplateVariablesMappingRequestSuccess,
    updateTemplateVariablesMappingRequestFailure,
    ({ payload }) => api.updateTemplate(payload.templateId, { variables: payload.variables }),
  );

export const UpdateTemplateStatusRequestSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === SendGridActionType.UpdateTemplateStatusRequestSuccess) {
      const {
        payload: { templateName, active },
      } = action as UpdateTemplateStatusSuccessAction;

      const statusMessage = active ? 'activated' : 'deactivated';

      notification.createNotification(
        getMessage(MessageType.UpdateTemplateStatusSuccess, {
          templateName,
          statusMessage,
        }),
        'success',
        dispatch,
      );
    }
  },
);

export const UpdateTemplateVariablesMappingRequestSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === SendGridActionType.UpdateTemplateVariablesMappingRequestSuccess) {
      const {
        payload: { templateName },
      } = action as UpdateTemplateStatusSuccessAction;

      notification.createNotification(
        `Template ${templateName} variables mapping have been updated`,
        'success',
        dispatch,
      );
    }
  },
);

export const GetEmailTemplateMiddleware: (api: SendGridTemplateApi) => Middleware = (api) =>
  Fetcher<SendGridTemplate, GetTemplateAction>(
    SendGridActionType.GetTemplate,
    getTemplateSuccess,
    getTemplateFailure,
    ({ payload }) => api.getById(payload.id),
  );
