import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';

export default class ChangeApplicationStatusError extends Error {
  constructor(
    message: string,
    readonly applicationId: string,
    readonly applicationDisplayId: string,
    readonly applicationName: string,
    readonly initialStatus: ApplicationStatus,
    readonly previousIndex: number | undefined,
  ) {
    super(message);
  }
}
