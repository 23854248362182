import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortBorrowersAction } from './ActionCreator';
import {
  FilterBorrowersByMembersAction,
  ChangePopupFiltersAction,
  ResetAllBorrowersFilters,
} from './Filters/ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './Filters/ActionTypes';
import { LoanOriginationSystemBorrowersFiltersState } from './Filters/Types';
import { BorrowersSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

type BorrowerFiltersToSave = Pick<
  LoanOriginationSystemBorrowersFiltersState,
  'selectedMembers' | 'isCompany' | 'isPerson' | 'dueUpdatedDateRange' | 'dueCreatedDateRange'
>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters } = state.loanOriginationSystemBorrowers;

  return pick(filters, ['selectedMembers', 'isCompany', 'isPerson', 'dueUpdatedDateRange', 'dueCreatedDateRange']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemBorrowers;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<FilterBorrowersByMembersAction>(
    LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers,
  ),
  isActionOfType<ChangePopupFiltersAction>(LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllBorrowersFilters>(LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters),
  isActionOfType<SortBorrowersAction>(LoanOriginationSystemBorrowersActionTypes.SortBorrowers),
];

const borrowersFilterAndSortingSaver = FilterAndSortingSaver<BorrowerFiltersToSave, BorrowersSortingType>({
  key: 'borrowers',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemBorrowersActionTypes.ResetState,
});

export default borrowersFilterAndSortingSaver;
