import React, { FC } from 'react';
import formatDate from 'utils/dateFormat';
import StatisticBlock from 'components/StatisticBlock/StatisticBlock';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { AppRoutes } from 'routes/RouteService';

interface StatisticsProps {
  amountOwed: number;
  applicationsCount: number | null;
  decisionsCount: number | null;
  date: Date | null;
  currencySign: string | null;
}

const Statistics: FC<StatisticsProps> = ({ decisionsCount, applicationsCount, amountOwed, date, currencySign }) => {
  const formattedOwedAmount = currencySign && formatMonetaryValue(amountOwed, currencySign, true);

  const itemsToRender =
    applicationsCount === null || decisionsCount === null
      ? null
      : [
          { name: 'Applications', value: applicationsCount },
          { name: 'Decisions', value: decisionsCount },
        ];

  return (
    <StatisticBlock
      title="Amount Owed"
      value={formattedOwedAmount}
      subTitle={date === null ? null : `as of ${formatDate(date)}`}
      secondaryTitle="Current Month Usage"
      items={itemsToRender}
      buttonTitle="Make A Payment"
      buttonSubTitle="Open Payment Portal"
      onButtonClick={() => window.open(`${process.env.REACT_APP_CORE_API_URL}${AppRoutes.CustomerPortal}`)}
    />
  );
};

export default Statistics;
