import { Middleware } from 'redux';

import { VariablesActionType } from './ActionTypes';
import {
  changeVariableStatusFailure,
  changeVariableStatusSuccess,
  ChangeVariableStatusRequestAction,
  ChangeVariableStatusSuccessAction,
} from './VariablesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { VariablesApi } from 'api/Core/VariablesApi';
import { MessageType } from 'constants/messages';

const ChangeVariableStatusMiddleware: (api: VariablesApi) => Middleware = (api) =>
  Fetcher<ChangeVariableStatusSuccessAction['payload'], ChangeVariableStatusRequestAction>(
    VariablesActionType.ChangeVariableStatus,
    changeVariableStatusSuccess,
    changeVariableStatusFailure,
    async ({ payload }) => {
      const variable = await api.changeVariableStatus(payload.variableId, payload.variableStatus);

      return {
        variable,
        messageType: payload.variableStatus ? MessageType.VariableArchived : MessageType.VariableRestored,
      };
    },
  );

export default ChangeVariableStatusMiddleware;
