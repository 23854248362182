import { ModuleType, StrategiesList, Strategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import { NullableItems } from 'pagination';
import { StrategyVersionType } from 'VersionsViewData/Types';
import { StrategyGroupVersion } from 'api/Types';

export const getModuleType = (urlModuleParam: string): ModuleType => {
  const segments = urlModuleParam.split('_');
  return segments[segments.length - 2] as ModuleType;
};

export const mergeStrategyVersionsWithDetailedAttributes = (
  strategyVersions: NullableItems<StrategyVersionType>,
  strategiesDetailedAttributes: StrategiesList,
) => {
  return strategyVersions.map((strategy) => {
    if (!strategy) {
      return null;
    }

    const details = strategiesDetailedAttributes[strategy.id];

    if (!details) {
      return strategy;
    }

    const { status, description = '', isLocked } = details;

    return {
      ...strategy,
      status,
      description,
      isLocked,
    };
  });
};

export const isDetailedStrategy = (strategy: StrategyGroupVersion | Strategy | undefined): strategy is Strategy => {
  return typeof strategy !== 'undefined' && 'moduleList' in strategy;
};
