import React from 'react';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';
import { DecisionsTableColumnDisplayName } from 'components/Decisions/Table/types';
import { SIZES } from 'components/Decisions/Table/columnSizes';
import { SkeletonCircle } from 'components/Skeleton';

const SkeletonHistoryLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Date]} noPadding>
        <SkeletonCircle width="24px" height="24px" color="primary10" marginRight="8px" />
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.StrategyName]} noPadding>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.DecisionName]} noPadding>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Source]} noPadding>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.ExecutionTime]} noPadding>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Result]} noPadding>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonHistoryLine;
