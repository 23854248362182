import { AuthApi, CreateAccountData, SignInData } from 'api/Core/AuthApi';
import { CreateNewAccountInviteData } from 'api/Core/UserInviteService';
import { AuthEventEmitterType, AuthEventMessage } from 'eventHandlers/AuthEventEmitter';
import { userInviteApi } from 'stores';
import { RequestError } from 'utils/fetch';

interface AuthHandlerDependencies {
  authApi: AuthApi;
  localStorage: Storage;
  authEvents: AuthEventEmitterType;
}

const IS_LOGGED_IN_KEY = 'isLoggedIn';
const TRUE = 'true';

export interface AuthHandler {
  signIn: (data: SignInData) => Promise<void>;
  acceptInvite: (inviteToken: string, data?: CreateNewAccountInviteData) => Promise<boolean>;
  selectOrganization: (organizationId: string) => Promise<void>;
  resendPhoneVerificationCode: () => Promise<void>;
  verifyPhoneVerificationCode: (code: string) => Promise<void>;
  sendForgotPasswordLink: (email: string) => Promise<void>;
  resetPassword: (password: string, token: string) => Promise<void>;
  createAccount: (data: CreateAccountData) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
  clearState: () => void;
  logout: () => void;
  isLoggedIn: () => boolean;
}

const createAuthHandler = ({ localStorage, authApi, authEvents }: AuthHandlerDependencies): AuthHandler => {
  const authHandler = {
    signIn: async (data: SignInData) => {
      localStorage.setItem(IS_LOGGED_IN_KEY, TRUE);

      await authApi.signIn(data);
    },
    acceptInvite: async (inviteToken: string, userData?: CreateNewAccountInviteData) => {
      try {
        await userInviteApi.acceptInvite(inviteToken, userData);
      } catch (error) {
        const { responseStatus, data } = error as RequestError;

        if (error instanceof RequestError && responseStatus === 400 && data?.userDataIsNotProvided) {
          return false;
        }

        throw error;
      }

      return true;
    },
    selectOrganization: async (organizationId: string) => {
      const operation = authApi.selectOrganization(organizationId);
      authEvents.emit(AuthEventMessage.SelectOrganization, operation);
      await operation;
    },
    resendPhoneVerificationCode: async () => {
      await authApi.resendPhoneVerificationCode();
    },
    verifyPhoneVerificationCode: async (code: string) => {
      await authApi.verifyPhoneVerificationCode(code);
    },
    sendForgotPasswordLink: async (email: string) => {
      await authApi.sendForgotPasswordLink(email);
    },
    resetPassword: async (password: string, token: string) => {
      await authApi.resetPassword(password, token);
    },
    createAccount: async (data: CreateAccountData) => {
      await authApi.createAccount(data);

      localStorage.setItem(IS_LOGGED_IN_KEY, TRUE);
    },
    resendVerificationEmail: async () => {
      await authApi.resendVerificationEmail();
    },
    clearState: () => {
      localStorage.removeItem(IS_LOGGED_IN_KEY);
      authEvents.emit(AuthEventMessage.Logout);
    },
    logout: () => {
      authHandler.clearState();
      authApi.logout();
    },
    isLoggedIn: () => {
      return localStorage.getItem(IS_LOGGED_IN_KEY) === TRUE;
    },
  };

  window.addEventListener('storage', (event) => {
    if (event.key === IS_LOGGED_IN_KEY && event.newValue !== TRUE) {
      authHandler.clearState();
    }
  });

  return authHandler;
};

export default createAuthHandler;
