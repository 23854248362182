import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SetShowArchivedAction, SetUpdatersAction, SetSortingTypeAction, ResetFiltersAction } from './Actions';
import { VersionsListDataActions } from './ActionTypes';
import { VersionsListDataState } from './Reducer';
import { VersionsTableViewSortingType } from 'VersionsViewData/Types';

type VersionsFiltersToSave = Pick<VersionsListDataState, 'selectedUpdaters' | 'showArchived'>;

const getFiltersToSave = (state: ReduxState) => {
  return pick(state.versionsListData, ['selectedUpdaters', 'showArchived']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.versionsListData;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<SetShowArchivedAction>(VersionsListDataActions.SetShowArchived),
  isActionOfType<SetUpdatersAction>(VersionsListDataActions.SetUpdaters),
  isActionOfType<SetSortingTypeAction>(VersionsListDataActions.SetSortingType),
  isActionOfType<ResetFiltersAction>(VersionsListDataActions.ResetFilters),
];

const versionsFilterAndSortingSaver = FilterAndSortingSaver<VersionsFiltersToSave, VersionsTableViewSortingType>({
  key: 'versions',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: VersionsListDataActions.ClearListData,
});

export default versionsFilterAndSortingSaver;
