import webhookEventsPagination from './Pagination';
import { WebhookEventsApi } from 'api/Webhooks/WebhookEventsApi';
import { saveWebhookEvents } from 'WebhookEvents/WebhookEventsStore';

export default (api: WebhookEventsApi) => {
  return webhookEventsPagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.find({
      count: params.perPage,
      offset: (params.page - 1) * params.perPage,
      statuses: params.statuses,
      search: params.search,
      eventTypes: params.eventTypes,
      dueCreatedDateFrom: params.dueCreatedDateFrom,
      dueCreatedDateTo: params.dueCreatedDateTo,
    }, params.sortingType);

    dispatch(saveWebhookEvents(items));

    return {
      items: items.map((item) => item.id),
      total,
    };
  });
};
