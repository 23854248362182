import { createReducer } from '@reduxjs/toolkit';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  getPermissionGroups,
  createPermissionGroup,
  updatePermissionGroup,
  bulkUpdatePermissionGroups,
  openEditPermissionGroups,
  closeEditPermissionGroups,
} from './Actions';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';
import {
  isPendingActionWithPrefix,
  isFulfilledActionWithPrefix,
  isRejectedActionWithPrefix,
} from 'utils/actions/ActionWithPrefix';
import { PermissionGroupsActionTypePrefix } from './ActionTypes';

export interface PermissionGroupsState {
  groupsById: Record<string, PermissionGroup>;
  isEditGroupsContextualViewOpen: boolean;
  isUpdatePending: boolean;
}

const initialState: PermissionGroupsState = {
  groupsById: {},
  isEditGroupsContextualViewOpen: false,
  isUpdatePending: false,
};

const isPendingPermissionGroupsAction = isPendingActionWithPrefix(PermissionGroupsActionTypePrefix);
const isFulfilledPermissionGroupsAction = isFulfilledActionWithPrefix(PermissionGroupsActionTypePrefix);
const isRejectedPermissionGroupsAction = isRejectedActionWithPrefix(PermissionGroupsActionTypePrefix);

const productSectionAccessPermissionsReducer = createReducer<PermissionGroupsState>(initialState, (builder) => {
  builder
    .addCase(getPermissionGroups.fulfilled, (state, action) => {
      state.groupsById = { ...state.groupsById, ...normalizeEntityArray(action.payload) };
    })
    .addCase(createPermissionGroup.fulfilled, (state, action) => {
      state.groupsById[action.payload.id] = action.payload;
    })
    .addCase(updatePermissionGroup.fulfilled, (state, action) => {
      state.groupsById[action.payload.id] = action.payload;
    })
    .addCase(bulkUpdatePermissionGroups.fulfilled, (state, action) => {
      state.groupsById = normalizeEntityArray(action.payload);
    })
    .addCase(openEditPermissionGroups, (state) => {
      state.isEditGroupsContextualViewOpen = true;
    })
    .addCase(closeEditPermissionGroups, (state) => {
      state.isEditGroupsContextualViewOpen = false;
    })
    .addMatcher(isPendingPermissionGroupsAction, (state) => {
      state.isUpdatePending = true;
    })
    .addMatcher(
      (action) => isFulfilledPermissionGroupsAction(action) || isRejectedPermissionGroupsAction(action),
      (state) => {
        state.isUpdatePending = false;
      },
    );
});

export default productSectionAccessPermissionsReducer;
