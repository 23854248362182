import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { ProductConfigurationDocumentFolder } from 'api/Types';

export const getSortingType = (state: ReduxState) => state.productConfigurationDocumentFolders.sortingType;

const getFolderIdsByProductId = (state: ReduxState, productId: string) =>
  state.productConfigurationDocumentFolders.foldersIdsByProductId[productId];

const getFoldersById = (state: ReduxState) => state.productConfigurationDocumentFolders.foldersById;

export const getFoldersByProductId = createSelector(
  [getFolderIdsByProductId, getFoldersById],
  (foldersIds, foldersById) => {
    if (!foldersIds) {
      return null;
    }

    return foldersIds.map((id) => foldersById[id]);
  },
);

export const getFolderToUpdateId = (state: ReduxState) => state.productConfigurationDocumentFolders.folderToUpdateId;

export const getFolderToUpdate = createSelector(
  [getFoldersById, getFolderToUpdateId],
  (foldersIds, folderToUpdateId) => {
    if (!foldersIds || !folderToUpdateId) {
      return null;
    }

    return foldersIds[folderToUpdateId];
  },
);

export const getParentFolderId = (state: ReduxState) => state.productConfigurationDocumentFolders.parentFolderId;

export const getParentFolder = createSelector([getFoldersById, getParentFolderId], (foldersIds, parentFolderId) => {
  if (!foldersIds || !parentFolderId) {
    return null;
  }

  return foldersIds[parentFolderId];
});

export const getNestedFolders = createSelector(
  [getFoldersByProductId, getParentFolderId, getSortingType],
  (folders, parentFolderId, { ascending, field }) => {
    if (!folders) return null;

    return folders
      .filter(({ parentId }) => parentId === parentFolderId)
      .sort((folderA, folderB) =>
        ascending ? folderA[field].localeCompare(folderB[field]) : folderB[field].localeCompare(folderA[field]),
      );
  },
);

const getPath = (
  folders: Record<string, ProductConfigurationDocumentFolder>,
  id: string | null,
): ProductConfigurationDocumentFolder[] =>
  id && folders[id] ? [...getPath(folders, folders[id].parentId), folders[id]] : [];

export const getNestedFoldersPath = createSelector([getFoldersById, getParentFolderId], (foldersById, parentFolderId) =>
  getPath(foldersById, parentFolderId),
);
