import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import useStateReset from 'hooks/useStateReset';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ProductConfigurationDocumentFoldersSortingFields } from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';
import { DocuSignLogo, FolderImage, ShareImageCustomColor } from 'static/images';
import { ReduxState } from 'types/redux';
import { getDocuSignIntegration } from 'ESignIntegrations/ActionCreator';
import { updateProduct, UpdateProductActionOrigin } from 'LoanOriginationSystemProducts/ActionCreator';
import {
  openDocumentFolderPopup,
  getDocumentFolders,
  updateDocumentFolder,
  setFolderToUpdate,
  setParentFolder,
  sortDocumentFolders,
} from 'ProductConfigurationDocumentFolders/ActionCreator';
import { ProductConfigurationDocumentFoldersPopUpType } from 'ProductConfigurationDocumentFolders/Types';
import {
  getFoldersByProductId,
  getNestedFolders,
  getNestedFoldersPath,
  getSortingType,
} from 'ProductConfigurationDocumentFolders/Selectors';
import { ProductConfigurationDocumentFoldersActionTypes } from 'ProductConfigurationDocumentFolders/ActionTypes';
import SuccessTab, {
  SuccessTabProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/SuccessTab/SuccessTab';
import BaseTabLayout from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/BaseTabLayout';
import StyledSwitch from 'components/StyledSwitch';
import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import DocumentFoldersTable from './DocumentFoldersTable';
import DocumentFoldersBreadcrumbs from 'components/DocumentFoldersBreadcrumbs';
import { AppRoutes } from 'routes/RouteService';
import LoadingMask from 'components/LoadingMask';
import styles from './DocumentsTab.module.scss';

export type DocumentsTabProps = Omit<SuccessTabProps, 'tabType' | 'icon'> & {
  product: Product;
};

const DocumentsTab = ({ product, ...restProps }: DocumentsTabProps) => {
  const dispatch = useDispatch();
  const [isDocumentCombineInProgress, setIsDocumentCombineInProgress] = useState(false);
  const { docuSignIntegration, isDocuSignIntegrationLoaded } = useSelector((state: ReduxState) => ({
    docuSignIntegration: state.eSignIntegrations.docuSignIntegration,
    isDocuSignIntegrationLoaded: state.eSignIntegrations.isDocuSignIntegrationLoaded,
  }));
  const { documentFolders, nestedFolders, nestedFoldersPath, sortingType } = useSelector((state: ReduxState) => ({
    documentFolders: getFoldersByProductId(state, product.id),
    nestedFolders: getNestedFolders(state, product.id),
    nestedFoldersPath: getNestedFoldersPath(state),
    sortingType: getSortingType(state),
  }));

  useEffect(() => {
    dispatch(getDocuSignIntegration());
    dispatch(getDocumentFolders(product.id));
  }, []);

  useStateReset(ProductConfigurationDocumentFoldersActionTypes.ResetState);

  const handleDocuSignEnabledChange = (checked: boolean) => {
    dispatch(
      setActionOrigin(
        updateProduct(product.id, { settings: { docuSignEnabled: checked } }),
        UpdateProductActionOrigin.ConfigurationDocumentsTab,
      ),
    );
  };

  const handleOpenDocuSignSettingsClick = () => {
    window.open(AppRoutes.DocuSignCredentials);
  };

  const handleCreateFolderClick = () =>
    dispatch(openDocumentFolderPopup(ProductConfigurationDocumentFoldersPopUpType.Create));

  const renderActions = () => (
    <Button className={styles.addVariableButton} kind="secondary" onClick={handleCreateFolderClick}>
      Create Folder
    </Button>
  );

  const renderSubtitle = () => (
    <DocumentFoldersBreadcrumbs
      className={styles.breadcrumbsContainer}
      breadcrumbClassName={styles.foldersBreadcrumb}
      rootLinkName="Folder Structure"
      links={nestedFoldersPath}
      onLinkClick={(id: string | null) => dispatch(setParentFolder(id))}
    />
  );

  const renderNoItems = () => (
    <SuccessTab
      {...restProps}
      className={styles.foldersContainer}
      icon={<FolderImage />}
      tabType="Documents"
      renderActions={renderActions}
    >
      <p>You can add folders to provide a structure. Folders</p>
      <p>will automatically be added to each new Application.</p>

      <LinkButton onClick={handleCreateFolderClick} className={styles.createFolderLink}>
        Create Folder
      </LinkButton>
    </SuccessTab>
  );

  const renderDocumentFolders = () => {
    if (documentFolders?.length) {
      return (
        <BaseTabLayout
          {...restProps}
          renderActions={renderActions}
          renderSubtitle={renderSubtitle}
          className={styles.foldersContainer}
          inputClassName={styles.foldersTabTitleInput}
        >
          <DocumentFoldersTable
            folders={nestedFolders ?? []}
            sortingType={sortingType}
            onSort={(field: ProductConfigurationDocumentFoldersSortingFields, ascending: boolean) =>
              dispatch(sortDocumentFolders({ field, ascending }))
            }
            onRowClick={(id: string) => dispatch(setParentFolder(id))}
            onRenameFolderClick={(id: string) =>
              batch(() => {
                dispatch(openDocumentFolderPopup(ProductConfigurationDocumentFoldersPopUpType.Rename));
                dispatch(setFolderToUpdate(id));
              })
            }
            onDeleteFolderClick={(id: string) =>
              batch(() => {
                dispatch(openDocumentFolderPopup(ProductConfigurationDocumentFoldersPopUpType.Delete));
                dispatch(setFolderToUpdate(id));
              })
            }
            onFoldersCombine={async (folderId: string, parentId: string) => {
              setIsDocumentCombineInProgress(true);
              await dispatch(updateDocumentFolder({ folderId, folder: { parentId } }));
              setIsDocumentCombineInProgress(false);
            }}
          />
        </BaseTabLayout>
      );
    }

    return renderNoItems();
  };

  const renderDocuSignContainer = () => (
    <div className={styles.configurationContainer}>
      <h5 className={styles.configurationTitle}>Electronic Signature</h5>
      <div className={styles.docuSignSwitchContainer}>
        <StyledSwitch
          disabled={!docuSignIntegration}
          color="primary"
          checked={product.settings.docuSignEnabled}
          className={styles.switchButton}
          onChange={(event, checked) => handleDocuSignEnabledChange(checked)}
        />
        <DocuSignLogo />
      </div>
      <div className={styles.informationSection}>
        {!docuSignIntegration && isDocuSignIntegrationLoaded && (
          <p>Please add your DocuSign credentials to activate.</p>
        )}
        <Button
          onClick={handleOpenDocuSignSettingsClick}
          size="form"
          className={styles.openDocuSignSettingsButton}
          kind="secondary"
        >
          <ShareImageCustomColor className={styles.shareImage} />
          Open DocuSign Settings
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {renderDocumentFolders()}
      {renderDocuSignContainer()}
      {isDocumentCombineInProgress && <LoadingMask background="light" />}
    </div>
  );
};

export default DocumentsTab;
