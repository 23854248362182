import DecisionEngineSystemApi from './DecisionEngineSystemApi';
import { NormalizedStatus, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { DataFilter, TableViewData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DecisionsSortingFieldType } from 'Decisions/DecisionsStore';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { IndividualCaseCompiledModule, IndividualCaseModule } from 'api/DecisionEngineStrategiesType';
import { IVariableShortInfo } from 'Variables/VariablesTypes';

export enum DecisionResultType {
  Passed = 'passed',
  Failed = 'failed',
  Error = 'error',
}

export interface Decision {
  id: string;
  name: string;
  createdBy: UserInfo | null;
  createdAt: Date;
  source: string;
  strategyNames: string[];
  resultsCount: number;
  resultStatuses: Record<DecisionResultType, number>;
  applicationId: string | null;
  applicationName: string | null;
  executionTime: number;
}

export interface DecisionsFilter {
  offset?: number;
  decisionResults?: DecisionResultType[];
  decisionSource?: 'Api' | 'Web' | null;
  strategyStatus?: NormalizedStatus | null;
  strategyName?: string | null;
  decisionDate?: {
    from: Date | null;
    to: Date | null;
  };
  count?: number;
  selectedMembers?: UserInfo[];
  search?: string;
  applicationId?: string | null;
}

export interface ExtendedDecision {
  id: string;
  name: string;
  decisionName: string;
  strategyId: string;
  strategyName: string;
  strategyVersion: number;
  strategyStatus: SystemStatus;
  decisionId: string;
  resultType: DecisionResultType;
  applicationName: string | null;
  applicationId: string | null;
  applicationDisplayId: string | null;
  resultsInGroup: number;
  source: 'Web' | 'Api';
  executionTime: number;
  inputs: Record<string, VariableValue>;
  outputs: Record<string, VariableValue>;
  declineReasons: string[];
  errorMessages: string[];
  compiledModules: IndividualCaseCompiledModule[];
  modules: IndividualCaseModule[];
  createdBy: UserInfo | null;
  createdAt: Date;
  decisionExecutionTime: number;
  variablesMap: Record<string, IVariableShortInfo>;
}

interface GetDecisionResultsCountFilters {
  createdAtFrom?: Date;
  createdAtTo?: Date;
}

export interface IDecisionApi {
  getAll(
    filters: DecisionsFilter,
    sortingType: DecisionsSortingFieldType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<Decision>>;
  getDecisionRunsCount(filters: GetDecisionResultsCountFilters): Promise<number>;
  findById(id: string, resultId?: string): Promise<ExtendedDecision>;
  delete(decisionId: string): Promise<Decision>;
}

class DecisionApi extends DecisionEngineSystemApi<Decision> implements IDecisionApi {
  protected resourceName = 'decisions';

  public async getAll(
    filters: DecisionsFilter,
    sortingType: DecisionsSortingFieldType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<Decision>> {
    const params = this.getParams(filters, sortingType);
    const decisions = await this.getResources<TableViewData<Decision>>(params, abortSignal);
    return decisions;
  }

  public async getDecisionRunsCount(filters: GetDecisionResultsCountFilters) {
    const params = new URLSearchParams();
    if (filters.createdAtFrom) {
      params.append('createdAtFrom', filters.createdAtFrom.toISOString());
    }
    if (filters.createdAtTo) {
      params.append('createdAtTo', filters.createdAtTo.toISOString());
    }

    const { total } = await this.fetch<{ total: number }>(`/decisions/total-count?${params}`);

    return total;
  }

  public findById(decisionId: string, resultId?: string | null): Promise<ExtendedDecision> {
    const params = new URLSearchParams();
    if (resultId) {
      params.append('result-id', resultId);
    }

    let queryString = params.toString();
    if (queryString) {
      queryString = `?${queryString}`;
    }

    return this.fetch<ExtendedDecision>(
      `/${this.resourceName}/${decisionId}${queryString}`,
      'GET',
    );
  }

  public async delete(decisionId: string) {
    return this.fetch<Decision>(`/${this.resourceName}/${decisionId}`, 'DELETE');
  }

  private getParams(filters: DecisionsFilter, sortingType?: DecisionsSortingFieldType) {
    const params = this.getPaginationUrlSearchParams(filters as DataFilter, sortingType);
    if (filters.selectedMembers) {
      filters.selectedMembers.forEach((member) => params.append('team-member-ids', member.id));
    }
    if (filters.decisionResults) {
      filters.decisionResults.forEach((result) => params.append('result-statuses', result));
    }
    if (filters.decisionSource) {
      params.append('source', filters.decisionSource);
    }
    if (filters.strategyStatus) {
      params.append('strategy-status', filters.strategyStatus);
    }
    if (filters.strategyName) {
      params.append('strategy-name', filters.strategyName);
    }
    if (filters.decisionDate?.from) {
      params.append('created-at-from', formatDate(filters.decisionDate.from, DateTimeFormat.ISOString));
    }
    if (filters.decisionDate?.to) {
      params.append('created-at-to', formatDate(filters.decisionDate.to, DateTimeFormat.ISOString));
    }
    if (filters.applicationId) {
      params.append('application-id', filters.applicationId);
    }

    return params;
  }
}

export default DecisionApi;
