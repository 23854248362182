import { createAsyncThunk } from '@reduxjs/toolkit';
import BorrowerVariableConfigurationsRestApi, {
  CreateBorrowerVariableConfigurationParams,
} from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import responseHandlers from 'api/ResponseHandlers';
import {
  BaseUpdateVariableConfigurationParams,
  UpdateVariableConfigurationConditionalDisplayRuleParams,
  UpdateVariableConfigurationRequiredAttribute,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

type UpdateBorrowerVariableConfigurationPositionParams = Pick<BaseUpdateVariableConfigurationParams, 'position' | 'cardId' | 'column'> & {
  id: string;
  sourceCardId: string;
}

const borrowerVariableConfigurationsApi = new BorrowerVariableConfigurationsRestApi(responseHandlers);

export const createBorrowerVariableConfiguration = createAsyncThunk(
  'borrowerVariableConfiguration/createBorrowerVariableConfiguration',
  async (params: CreateBorrowerVariableConfigurationParams) => {
    const configuration = await borrowerVariableConfigurationsApi.create(params);

    return {
      configuration,
    };
  },
);

export const updateConditionalDisplayRule = createAsyncThunk(
  'borrowerVariableConfiguration/updateConditionalDisplayRule',
  async (params: UpdateVariableConfigurationConditionalDisplayRuleParams) => {
    const configuration = await borrowerVariableConfigurationsApi.update(params.id, {
      conditionalDisplayRule: params.conditionalDisplayRule,
    });

    return {
      configuration,
    };
  },
);

export const updateBorrowerVariableConfigurationPosition = createAsyncThunk(
  'borrowerVariableConfiguration/updateBorrowerVariableConfigurationPosition',
  async (params: UpdateBorrowerVariableConfigurationPositionParams) => {
    const { id, sourceCardId, ...updateParams } = params;

    const configuration = await borrowerVariableConfigurationsApi.update(id, updateParams);

    return {
      configuration,
      sourceCardId,
    };
  },
);

export const updateBorrowerVariableConfigurationRequiredAttribute = createAsyncThunk(
  'borrowerVariableConfiguration/updateBorrowerVariableConfigurationRequiredAttribute',
  async (params: UpdateVariableConfigurationRequiredAttribute) => {
    const { id, required } = params;

    const configuration = await borrowerVariableConfigurationsApi.update(id, {
      required,
    });

    return {
      configuration,
    };
  },
);

export const deleteBorrowerVariableConfiguration = createAsyncThunk(
  'borrowerVariableConfiguration/deleteBorrowerVariableConfiguration',
  async (id: string) => {
    await borrowerVariableConfigurationsApi.delete(id);

    return {
      id,
    };
  },
);
