import { AnyAction } from 'redux';

import {
  ACTION_TYPE_CREATE_VARIABLE_FAILURE,
  ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
  ACTION_TYPE_EDIT_VARIABLE_FAILURE,
  ACTION_TYPE_EDIT_VARIABLE_REQUEST,
  ACTION_TYPE_EDIT_VARIABLE_SUCCESS,
  GET_VARIABLES_REQUEST,
  GET_VARIABLES_REQUEST_FAILURE,
  GET_VARIABLES_REQUEST_SUCCESS,
  VariablesActionType,
} from './ActionTypes';

import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ItemsWithTotalCount } from 'api/Types';
import { NewVariableApiType, Variable } from './VariablesTypes';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { MessageType } from 'constants/messages';
import { VariableItem } from 'api/VariablesType';
import { VariablesSortingField, VariablesSortingType } from 'api/Core/VariablesApi';
import { VariablesFiltersState } from './Filters/Types';
import { PermissionGroupId } from 'PermissionGroups/Types';

export enum CreateVariableActionOrigin {
  Default = 'Default',
  VariablesConfiguration = 'VariablesConfiguration',
  ApplicationStatusRule = 'ApplicationStatusRule',
  SendGridVariablesMapping = 'SendGridVariablesMapping',
  DocuSignVariablesMapping = 'DocuSignVariablesMapping',
}

export interface EditVariableSuccessParams {
  variable: VariableItem;
  permissionGroupId?: PermissionGroupId;
}

export interface VariablesWithParams extends ItemsWithTotalCount<Variable> {
  searchInputValue: string;
}

export interface GetVariablesSuccessAction extends AnyAction {
  payload: VariablesWithParams;
}

export interface EditVariableRequestAction extends AnyAction {
  payload: NewVariableApiType;
}

export interface EditVariableSuccessAction extends AnyAction {
  type: typeof ACTION_TYPE_EDIT_VARIABLE_SUCCESS;
  payload: {
    variable: VariableItem;
    meta?: {
      permissionGroupId: PermissionGroupId;
    };
  };
}

export interface CreateVariableRequestAction extends ActionWithOrigin<CreateVariableActionOrigin> {
  type: typeof ACTION_TYPE_CREATE_VARIABLE_REQUEST;
  payload: NewVariableApiType;
}

export interface CreateVariableSuccessAction extends ActionWithOrigin<CreateVariableActionOrigin> {
  type: typeof ACTION_TYPE_CREATE_VARIABLE_SUCCESS;
  payload: {
    variable: VariableItem;
  };
}

export interface GetVariablesRequestAction extends AnyAction {
  type: typeof GET_VARIABLES_REQUEST;
}

export const getVariablesRequest = (
  filters?: VariablesFiltersState,
  sortingType?: VariablesSortingType,
  showArchived?: boolean,
): GetVariablesRequestAction => ({
  type: GET_VARIABLES_REQUEST,
  payload: {
    filters,
    sortingType,
    showArchived,
  },
});

export const getVariablesSuccess = (payload: VariablesWithParams): GetVariablesSuccessAction => ({
  type: GET_VARIABLES_REQUEST_SUCCESS,
  payload,
});

export const getVariablesError = FailedActionCreator(GET_VARIABLES_REQUEST_FAILURE);

export interface ChangeVariableStatusRequestAction {
  type: VariablesActionType.ChangeVariableStatus;
  payload: {
    variableId: string;
    variableName: string;
    variableStatus: boolean;
  };
}

export const changeVariableStatusRequest = (
  variableId: string,
  variableName: string,
  variableStatus: boolean,
): ChangeVariableStatusRequestAction => ({
  type: VariablesActionType.ChangeVariableStatus,
  payload: {
    variableId,
    variableName,
    variableStatus,
  },
});

export interface ChangeVariableStatusSuccessAction {
  type: typeof VariablesActionType.ChangeVariableStatusSuccess;
  payload: {
    variable: Variable;
    messageType: MessageType;
    isError?: boolean;
  };
}

export const changeVariableStatusSuccess = (
  payload: ChangeVariableStatusSuccessAction['payload'],
): ChangeVariableStatusSuccessAction => ({
  type: VariablesActionType.ChangeVariableStatusSuccess,
  payload,
});

export const changeVariableStatusFailure = FailedActionCreator(VariablesActionType.ChangeVariableStatusFailure);

export const editVariableRequest = (variableData: NewVariableApiType): EditVariableRequestAction => ({
  type: ACTION_TYPE_EDIT_VARIABLE_REQUEST,
  payload: variableData,
});

export const editVariableFailure = FailedActionCreator(ACTION_TYPE_EDIT_VARIABLE_FAILURE);

export const editVariableSuccess = ({
  variable,
  permissionGroupId,
}: EditVariableSuccessParams): EditVariableSuccessAction => ({
  type: ACTION_TYPE_EDIT_VARIABLE_SUCCESS,
  payload: {
    variable,
    meta: permissionGroupId ? { permissionGroupId } : undefined,
  },
});

export const createVariableRequest = (variableData: NewVariableApiType): CreateVariableRequestAction => ({
  type: ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  payload: variableData,
});

export const createVariableFailure = FailedActionCreator(ACTION_TYPE_CREATE_VARIABLE_FAILURE);

export const createVariableSuccess = (variable: VariableItem): CreateVariableSuccessAction => ({
  type: ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
  payload: {
    variable,
  },
});

export interface SortVariablesAction extends AnyAction {
  type: VariablesActionType.SortVariables;
  payload: VariablesSortingType;
}

export interface SetShowArchivedAction {
  type: typeof VariablesActionType.SetShowArchived;
  payload: boolean;
}

export const sortVariables = (field: VariablesSortingField, ascending: boolean): SortVariablesAction => ({
  type: VariablesActionType.SortVariables,
  payload: {
    ascending,
    field,
  },
});

export const setShowArchived = (payload: SetShowArchivedAction['payload']): SetShowArchivedAction => ({
  type: VariablesActionType.SetShowArchived,
  payload,
});
