import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { NewTask, TaskApplicationInfo, TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import styles from './CreateTaskForm.module.scss';
import moment from 'moment';
import Button from 'components/Button';
import TasksForm from 'components/LoanOriginationSystem/TasksForm';
import { formatDate } from 'components/DatePicker/utils';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';

export interface CreateTaskFormProps {
  members: UserInfo[];
  applications: TaskApplicationInfo[];
  handleTaskCreate: (data: NewTask) => void;
  isCreating: boolean;
  setDataWasChanged: (changed: boolean) => void;
  applicationId?: string;
}

const CreateTaskForm = ({
  members,
  applications,
  handleTaskCreate,
  isCreating,
  setDataWasChanged,
  applicationId,
}: CreateTaskFormProps) => {
  const tomorrowDate = formatDate(
    moment()
      .clone()
      .add(1, 'days')
      .toDate(),
  );

  const [membersProps, selectedMembers] = useAutoCompletionMultiSelectProps(
    members,
    members[0] ? [members[0]] : [],
    (member) => member.id,
    (member) => `${member.firstName} ${member.lastName}`,
    (member) => <WithSystemApiUserAvatar user={member} size="small" />,
    (member) => <WithSystemApiUserAvatar user={member} size="small" />,
  );

  const newTaskInitialValue = {
    description: '',
    status: TaskStatus.NotDone,
    teamMembers: [members[0]],
    application: null,
    dueDate: tomorrowDate,
  };

  const [selectedStatus, setSelectedStatus] = useState<TaskStatus>(TaskStatus.NotDone);
  const [selectedApplication, setSelectedApplication] = useState<TaskApplicationInfo | null>(null);
  const [dueDate, setDueDate] = useState<string>(tomorrowDate);
  const [description, setDescription] = useState('');

  const getNewTask = () => ({
    description,
    status: selectedStatus,
    teamMembers: selectedMembers,
    application: selectedApplication!,
    dueDate: dueDate!,
  });

  useEffect(() => {
    setDataWasChanged(!isEqual(newTaskInitialValue, getNewTask()));
  }, [description, selectedStatus, dueDate, selectedApplication, selectedMembers]);

  useEffect(() => {
    const preselectedApplication = applications.find(({ id }) => id === applicationId) || null;

    setSelectedApplication(preselectedApplication);
  }, [applications]);

  const validateTask = () => {
    return description && dueDate;
  };

  return (
    <div className={styles.taskFormContainer}>
      <TasksForm
        description={description}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        applications={applications}
        members={members}
        dueDate={dueDate}
        setDueDate={setDueDate}
        selectedApplication={selectedApplication}
        setSelectedApplication={setSelectedApplication}
        setDescription={setDescription}
        membersProps={membersProps}
        lockApplication={!!applicationId}
        showGoToApplicationLink={!applicationId}
      />
      <Button
        size="form"
        kind="primary"
        className={styles.createButton}
        disabled={!validateTask()}
        onClick={() => handleTaskCreate(getNewTask())}
        isLoading={isCreating}
      >
        Create Task
      </Button>
    </div>
  );
};

export default CreateTaskForm;
