import React, { useEffect } from 'react';
import styles from './EditTask.module.scss';
import { CloseButtonImage } from 'static/images';
import { editTask, getTaskData, resetEditTask } from 'LoanOriginationSystemTasks/EditTask/ActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getOrganizationApplications } from 'LoanOriginationSystemOrganization/ActionCreator';
import EditTaskForm from './EditTaskForm';
import { NewTask } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { FormSkeleton } from 'components/Skeleton';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import useOrganizationMembers from 'hooks/useOrganizationMembers';

interface EditTaskProps {
  onClose: () => void;
  id: string;
  setDataWasChanged: (changed: boolean) => void;
  lockApplication?: boolean;
  showGoToApplicationLink?: boolean;
  onEditSuccess: () => void;
}

const EditTask = ({
  onClose,
  id,
  setDataWasChanged,
  lockApplication = false,
  showGoToApplicationLink = true,
  onEditSuccess,
}: EditTaskProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { task, isUpdatingInProgress } = useSelector((state: ReduxState) => state.loanOriginationSystemTasks.editTask);
  const { applicationsListInfo } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemOrganization,
  );
  const organizationMembers = useOrganizationMembers();

  useEffect(() => {
    return () => {
      dispatch(resetEditTask());
    };
  }, []);

  useEffect(() => {
    dispatch(getOrganizationApplications());
  }, []);

  useEffect(() => {
    if (!task) {
      dispatch(getTaskData(id));
    }
  }, [task]);

  const handleTaskEdit = async (taskId: string, editTaskData: NewTask) => {
    await dispatchRoutine(editTask(taskId, editTaskData));

    notification.createNotification(getMessage(MessageType.TaskUpdated), 'success', dispatch);

    onEditSuccess();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Edit Task</h2>
          <CloseButtonImage className={styles.closeButton} onClick={onClose} />
        </div>
        {!task || (organizationMembers.length === 0 && <FormSkeleton />)}
        {task && organizationMembers && (
          <EditTaskForm
            applications={applicationsListInfo}
            organizationMembers={organizationMembers}
            task={task}
            setDataWasChanged={setDataWasChanged}
            isUpdatingInProgress={isUpdatingInProgress}
            handleTaskEdit={handleTaskEdit}
            lockApplication={lockApplication}
            showGoToApplicationLink={showGoToApplicationLink}
          />
        )}
      </div>
    </div>
  );
};

export default EditTask;
