import Debouncer from 'middlewares/Debouncer';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './Filters/ActionTypes';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';

export default Debouncer(
  (
    payload: {
      searchInputValue: string;
      borrowerId?: string;
    },
    getState,
  ) => {
    const { borrowerId } = payload;
    const { loanOriginationSystemEmails } = getState();
    const { filters, sortingType } = loanOriginationSystemEmails;

    const params: EmailsPaginationParams = {
      search: filters.searchInputValue,
      sortingType,
      createdAtDateRange: filters.createdAtRange,
      selectedBorrower: borrowerId || null,
    };

    return pagination.setPage(1, params);
  },
  [LoanOriginationSystemEmailsFiltersActionTypes.SetSearchInputValue],
);
