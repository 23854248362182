import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { NormalizedCard } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import {
  getIntermediaryProfileCards,
  createIntermediaryProfileCard,
  updateIntermediaryProfileCard,
  deleteIntermediaryProfileCard,
} from './Thunks';
import {
  createIntermediaryVariableConfiguration,
  deleteIntermediaryVariableConfiguration,
  updateIntermediaryVariableConfigurationPosition,
} from 'IntermediaryVariableConfigurations/Thunks';
import {
  addVariableConfigurationToCard,
  deleteVariableConfigurationFromCard,
  updateCardsStore,
  updateVariableConfigurationOnCards,
} from 'Cards/Utils';

export interface IntermediaryProfileCardsState {
  cardsById: Record<string, NormalizedCard>;
}

const initialState: IntermediaryProfileCardsState = {
  cardsById: {},
};

const intermediaryProfileCardsReducer = createReducer<IntermediaryProfileCardsState>(initialState, (builder) => {
  builder.addCase(deleteIntermediaryProfileCard.fulfilled, (state, action) => {
    delete state.cardsById[action.payload.id];
  });

  builder.addCase(deleteIntermediaryVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfigurationFromCard(state, action.payload.id);
  });

  builder.addCase(updateIntermediaryVariableConfigurationPosition.fulfilled, (state, action) => {
    updateVariableConfigurationOnCards(state, action.payload.sourceCardId, action.payload.configuration);
  });

  builder.addCase(createIntermediaryVariableConfiguration.fulfilled, (state, action) => {
    addVariableConfigurationToCard(state, action.payload.configuration);
  });

  builder.addMatcher(
    isFulfilled(getIntermediaryProfileCards, createIntermediaryProfileCard, updateIntermediaryProfileCard),
    (state, action) => {
      updateCardsStore(state, action.payload.intermediaryProfileCards);
    },
  );
});

export default intermediaryProfileCardsReducer;
