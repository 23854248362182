import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import { DetailsCardSkeleton } from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile/DetailsCard';

const EditApplicationFormSkeleton = () => {
  return (
    <>
      <SkeletonRectangle width="300px" height="28px" color="primary6" marginBottom="24px" />
      <DetailsCardSkeleton />
    </>
  );
};

export default EditApplicationFormSkeleton;
