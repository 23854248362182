import React, { FC, ReactElement, useEffect } from 'react';
import styles from './StrategyPage.module.scss';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { map } from 'lodash';
import StrategyOverview, { StrategyPageTabType } from 'components/StrategyOverview/index';
import MainLayout, { PageContent, PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  changeStrategyModuleCollapseState,
  collapseAllStrategyModules,
  getDecisionStrategyInfoRequest,
  removeCollapsedState,
  updateStrategyDescriptionRequest,
  updateStrategyNameRequest,
} from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { ReduxState } from 'types/redux';
import { decisionStrategyUpdatesPagination } from './Pagination';
import {
  getDecisionStrategyUpdatesRequest,
  setSortingType,
} from 'DecisionStrategyUpdates/ActionCreator';
import { openDeleteDecisionStrategyModuleWizard } from 'DeleteDecisionStrategyModuleWizard/Actions';
import { DecisionStrategiesState, ModuleType, Strategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import { openCreateNewStrategyVersionPopUp } from 'CreateNewStrategyVersion/CreateNewStrategyVersionActionCreator';
import { useQueryParams } from 'hooks/useQueryParam';
import { getBranchInfoRequest, reorderBranchRulesRequest } from 'BranchInfo/ActionCreator';
import { BranchInfoState, RuleData as BranchInfoRuleData, RuleType } from 'BranchInfo/Types';
import EditExternalIntegrationVariablesPopUp from 'EditExternalIntegrationVariablesPopUp/EditExternalIntegrationVariablesPopUp';
import {
  closeEditExternalIntegrationVariablesPopUp,
  getExternalIntegrationVariables,
  openAssignOutputVariablePopUp,
  openEditExternalIntegrationVariablesPopUp,
  assignOutputVariable,
  closeAssignOutputVariablePopUp,
} from 'EditExternalIntegrationVariablesPopUp/ActionCreator';
import ExternalIntegration from 'components/ExternalIntegration';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { getModuleType, isDetailedStrategy } from './utils';
import { editExternalIntegrationRequest } from 'EditExternalIntegration/ActionCreator';
import { ExternalIntegrationInputsList } from 'api/DecisionEngineStrategiesType';
import CreateVariablePopup from 'components/CreateVariablePopup';
import { getVariableType } from 'Variables/utils';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import { VariableClientType } from 'Variables/VariablesTypes';
import RuleBuilderPopUpContainer from 'containers/RuleBuilderPopUpContainer';
import RulesModuleContainer from 'containers/RulesModuleContainer';
import AddModuleContainer from 'containers/AddModuleContainer';
import DeleteRulePopUp from 'RuleDelete/RuleDeletePoUp';
import { closeVariableCreation, openAddRulePopUp, openVariableCreation } from 'RuleCreate/ActionCreator';
import { DeleteRulePopUpState } from 'RuleDelete/Types';
import EditRulePopUpContainer from 'containers/EditRulePopUpContainer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { openEditRulePopUp } from 'RuleEdit/ActionsCreator';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import { openDeleteRulePopUp } from 'RuleDelete/ActionCreator';
import { EntityType } from 'RuleCreate/Types';
import FullPageLoader from 'components/FullPageLoader';
import { getBranchKey } from 'BranchInfo/utils/getBranchKey';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import Footer from 'components/Footer';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { StrategyGroupsState } from 'StrategyGroupsData/Reducer';
import NavigationLinkId from 'enums/NavigationLinkId';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import ArchiveStrategyWizard from 'components/ArchiveStrategyWizard/ArchiveStrategyWizardConnector';
import DeleteStrategyWizard from 'DeleteStrategyWizard/DeleteStrategyWizardConnector';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import DuplicateStrategyWizard from 'DuplicateStrategyWizard';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { BranchKeys, StrategyGroup, StrategyGroupVersion } from 'api/Types';
import { DecisionStrategyUpdatesSortingField } from 'DecisionStrategyUpdates/Types';

const findBasicStrategyAttributes = (
  strategyGroupList: (StrategyGroup | null)[],
  strategyId: string,
): StrategyGroupVersion | undefined => {
  return strategyGroupList
    .map((strategyGroup): StrategyGroupVersion | undefined => {
      return strategyGroup?.versions.find((strategy) => strategyId === strategy.id);
    })
    .find((value) => value);
};

const StrategyPage: FC = () => {
  const { strategy: strategyId, tab: strategyPageTab } = useParams<{ strategy: string; tab: StrategyPageTabType }>();
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();
  const history = useHistory();
  const branchIndex = parseInt(params.get('branchIndex')!, 10);
  const downloadApi = useDownloadApi();
  const closeContextualView = useCloseContextualView();
  const decisionStrategiesState = useSelector<ReduxState, DecisionStrategiesState>((state) => state.decisionStrategies);
  const decisionStrategyGroupsState = useSelector<ReduxState, StrategyGroupsState>((state) => state.strategyGroups);
  useEffect(() => {
    dispatch(getDecisionStrategyInfoRequest(strategyId));
  }, [strategyId]);

  const strategyGroupList = decisionStrategyGroupsState.items;
  const detailedStrategyInfo = decisionStrategiesState.strategiesDetailedAttributes[strategyId];
  const strategyInfo = detailedStrategyInfo || findBasicStrategyAttributes(strategyGroupList, strategyId);

  useEffect(() => {
    return () => {
      dispatch(removeCollapsedState(strategyId));
    };
  }, []);

  const onTabChange = (tab: StrategyPageTabType): void => history.replace(tab);

  const userState = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);

  const editExternalIntegrationVariablesPopUp = useSelector(
    (state: ReduxState) => state.editExternalIntegrationVariablesPopUp,
  );

  const ruleCreate = useSelector((state: ReduxState) => state.ruleCreate);
  const ruleEdit = useSelector((state: ReduxState) => state.ruleEdit);

  const handleOpenDownloadPage = (population?: string): void => {
    const moduleKey = params.get('module-branch-key')!;
    const moduleType = population || getModuleType(moduleKey);
    downloadApi.downloadRulesTemplate(moduleType, strategyId, moduleKey, branchIndex);
  };

  const handleStrategyNameUpdate = (id: string, name: string) => {
    dispatch(updateStrategyNameRequest(id, name));
  };

  const handleStrategyDescriptionUpdate = (id: string, description: string) => {
    dispatch(updateStrategyDescriptionRequest(id, description.trim()));
  };

  const openDeleteModulePopUp = (modalLookupName: string, moduleIndex: number) => {
    dispatch(openDeleteDecisionStrategyModuleWizard(strategyId, modalLookupName, moduleIndex));
  };

  const onOpenEditExternalIntegrationVariablesPopUp = () => {
    dispatch(openEditExternalIntegrationVariablesPopUp());
  };

  const onOpenAssignOutputVariablePopUp = (assignedOutput: string) => {
    dispatch(openAssignOutputVariablePopUp(assignedOutput));
  };

  const leftNav = makeLeftNavigation(NavigationLinkId.Strategies, ApplicationSectionName.DecisionEngine);

  const { sortingType } = useSelector((state: ReduxState) => state.decisionStrategyUpdates);
  const decisionStrategyUpdatesPaginationProps = decisionStrategyUpdatesPagination.usePagination({
    strategyId,
    sortingType,
  });
  const strategyUpdatesPage = decisionStrategyUpdatesPagination.usePaginatedItems({ strategyId, sortingType });

  const onOpenCreateNewStrategyVersionPopUp = () => {
    /**
     * We can assume strategyInfo is present and has moduleList here,
     * cause this pop-up can only be opened after strategy details were loaded.
     */
    if ((strategyInfo as Strategy).moduleList.length === 0) {
      notification.createNotification(
        getMessage(MessageType.RestrictCreateNewVersionForEmptyStrategy),
        'warning',
        dispatch,
      );
    } else {
      dispatch(openCreateNewStrategyVersionPopUp(strategyId, strategyInfo!.name));
    }
  };

  const getBranchRules = () => {
    const moduleKey = params.get('module-branch-key');

    if (moduleKey) {
      dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex, isInitialLoad: true }));
    }
  };

  const { branchList, isLoading, conditionsBlockingActionInProgress, rulesBlockingActionInProgress } = useSelector<
    ReduxState,
    BranchInfoState
  >((state) => state.branchInfo);

  const collapsedModuleList = useSelector<ReduxState, boolean[]>(
    (state) => state.decisionStrategies.collapsedModules[strategyId],
  );

  const handleRulesReorder = (reorderedRules: BranchInfoRuleData[], ruleType: RuleType) => {
    const moduleKey = params.get('module-branch-key')!;

    dispatch(
      reorderBranchRulesRequest({
        strategyId,
        moduleKey,
        branchIndex,
        reorderedRuleIds: map(reorderedRules, 'id'),
        ruleType,
      }),
    );
  };

  const handleAddBranchCondition = () => {
    dispatch(
      openAddRulePopUp({
        entityType: 'branch',
        moduleType: ModuleType.RequirementsRules,
      }),
    );
  };

  const collapseAllModules = (modulesCount: number) => {
    dispatch(collapseAllStrategyModules(strategyId, modulesCount));
  };

  const changeModuleCollapseState = (moduleIndex: number, isCollapsed: boolean) => {
    dispatch(changeStrategyModuleCollapseState(strategyId, moduleIndex, isCollapsed));
  };

  const getContextualViewPage = () => {
    if (params.has('new-module')) {
      return <AddModuleContainer strategyId={strategyId} onClose={closeContextualView} />;
    }

    const common = { strategyId };
    if (params.has('module-branch-key')) {
      const moduleId = params.get('module-branch-key')!;
      const moduleType = getModuleType(moduleId);
      const module = isDetailedStrategy(strategyInfo)
        ? strategyInfo.moduleList.find(({ lookupName }) => lookupName === moduleId)
        : null;
      const hasMultipleBranches = module ? module.branches.length > 1 : false;
      const branchKey = getBranchKey(moduleKey, branchIndex);
      const currentBranch = branchList && branchList[branchKey];
      const currentBranchName = module ? module.branches[branchIndex].name : '';

      const onOpenEditRulePopUp = (data: RuleEditPopUpData) => {
        dispatch(openEditRulePopUp(data));
      };

      const onOpenDeleteRulePopUp = (ruleIndex: number, currentEntityType: EntityType) => {
        dispatch(openDeleteRulePopUp({ ruleIndex, entityType: currentEntityType }));
      };

      if (moduleType === 'dataintegration') {
        const getExternalIntegrationInfo = () => {
          dispatch(getExternalIntegrationVariables({ strategyId, moduleId, branchIndex }));
        };
        return (
          <ExternalIntegration
            getBranchRules={getBranchRules}
            name={currentBranch ? currentBranch.name : currentBranchName}
            onClose={closeContextualView}
            onOpenEditExternalIntegrationVariablesPopUp={onOpenEditExternalIntegrationVariablesPopUp}
            getExternalIntegrationInfo={getExternalIntegrationInfo}
            addBranchCondition={handleAddBranchCondition}
            externalIntegrationInfo={editExternalIntegrationVariablesPopUp}
            conditionsList={currentBranch ? currentBranch.conditions : []}
            multipleBranches={hasMultipleBranches}
            handleRuleReorder={handleRulesReorder}
            {...common}
            isStrategyLocked={strategyInfo?.isLocked}
            moduleId={moduleId}
            onOpenEditRulePopUp={onOpenEditRulePopUp}
            onOpenDeleteRulePopUp={onOpenDeleteRulePopUp}
            conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
            isLoading={editExternalIntegrationVariablesPopUp.isLoading}
            onOpenAssignOutputVariablePopUp={onOpenAssignOutputVariablePopUp}
            assignedOutput={editExternalIntegrationVariablesPopUp.assignedOutput}
          />
        );
      }
      return (
        <RulesModuleContainer
          {...common}
          isStrategyLocked={strategyInfo?.isLocked}
          onClose={closeContextualView}
          branchInfo={currentBranch}
          multipleBranches={hasMultipleBranches}
          moduleKey={moduleId}
          handleOpenDownloadPage={handleOpenDownloadPage}
          handleRuleReorder={handleRulesReorder}
          onOpenEditRulePopUp={onOpenEditRulePopUp}
          onOpenDeleteRulePopUp={onOpenDeleteRulePopUp}
          isLoading={isLoading}
          rulesBlockingActionInProgress={rulesBlockingActionInProgress}
          conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
        />
      );
    }
    return null;
  };

  const onCloseEditExternalIntegrationVariablesPopUp = () => dispatch(closeEditExternalIntegrationVariablesPopUp());

  const moduleKey = params.get('module-branch-key')!;

  const editExternalIntegration = async (inputList: ExternalIntegrationInputsList[]) => {
    await dispatchRoutine(editExternalIntegrationRequest({ strategyId, moduleKey, inputList, branchIndex }));
  };

  const handleAssignOutputVariable = async (outputIndex: number, variableId: string) => {
    await dispatchRoutine(assignOutputVariable(strategyId, outputIndex, variableId, moduleKey, branchIndex));
    dispatch(closeAssignOutputVariablePopUp());
  };

  const handleOpenVariableCreation = () => dispatch(openVariableCreation());
  const handleCloseVariableCreation = () => dispatch(closeVariableCreation());

  const handleVariableCreation = (variableData: VariableClientType) => {
    const simpleVariableData = getVariableType(variableData);
    const formattedVariable = { ...variableData, ...simpleVariableData };
    dispatch(setActionOrigin(createVariableRequest(formattedVariable), CreateVariableActionOrigin.Default));
  };

  const deleteRulePopUp: DeleteRulePopUpState = useSelector((state: ReduxState) => state.deleteRulePopUp);

  const getOverlay = (): ReactElement | null => {
    if (ruleCreate.isCreatingVariable) {
      const onSave = (variable: VariableClientType) => {
        handleVariableCreation(variable);
        handleCloseVariableCreation();
      };
      return <CreateVariablePopup onClose={handleCloseVariableCreation} onSave={onSave} />;
    }
    if (ruleCreate.moduleType) {
      return (
        <RuleBuilderPopUpContainer
          strategyId={strategyId}
          handleOpenDownloadPage={handleOpenDownloadPage}
          handleOpenVariableCreation={handleOpenVariableCreation}
        />
      );
    }
    if (ruleEdit.ruleModuleType) {
      return <EditRulePopUpContainer strategyId={strategyId} handleOpenVariableCreation={handleOpenVariableCreation} />;
    }
    if (editExternalIntegrationVariablesPopUp.isOpen) {
      return (
        <EditExternalIntegrationVariablesPopUp
          closePopUp={onCloseEditExternalIntegrationVariablesPopUp}
          externalIntegrationInfo={editExternalIntegrationVariablesPopUp}
          editExternalIntegration={editExternalIntegration}
          openVariableCreation={handleOpenVariableCreation}
          popUpType={editExternalIntegrationVariablesPopUp.type}
          handleOpenVariableCreation={handleOpenVariableCreation}
          assignedOutputId={editExternalIntegrationVariablesPopUp.assignedOutput}
          handleAssignOutputVariable={handleAssignOutputVariable}
        />
      );
    }
    if (deleteRulePopUp.isOpen) {
      return <DeleteRulePopUp strategyId={strategyId} />;
    }

    return null;
  };

  if (!userState) {
    return null;
  }

  const handleEditModuleBranch = (
    branchKeys: BranchKeys,
    { target }: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    history.push(`overview?module-branch-key=${branchKeys.moduleKey}&branchIndex=${branchKeys.branchIndex}`);
    (target as HTMLButtonElement).blur();
  };

  return (
    <>
      <MainLayout
        leftNav={leftNav}
        contextualView={getContextualViewPage()}
        overlay={getOverlay()}
        closeContextualView={closeContextualView}
      >
        <PageWrapper>
          <PageContent noPadding>
            <FullPageLoader isDataLoaded={Boolean(strategyInfo)}>
              {strategyInfo ? (
                <StrategyOverview
                  strategy={strategyInfo}
                  handleStrategyNameUpdate={handleStrategyNameUpdate}
                  handleStrategyDescriptionUpdate={handleStrategyDescriptionUpdate}
                  decisionStrategyUpdates={strategyUpdatesPage}
                  decisionStrategyUpdatesPaginationProps={decisionStrategyUpdatesPaginationProps}
                  onDecisionStrategyUpdatesSort={(field: DecisionStrategyUpdatesSortingField, ascending: boolean) => {
                    batch(() => {
                      dispatch(setSortingType({ field, ascending }));
                      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
                    });
                  }}
                  decisionStrategyUpdatesSortingType={sortingType}
                  openDeleteModulePopUp={openDeleteModulePopUp}
                  onOpenCreateNewStrategyVersionPopUp={onOpenCreateNewStrategyVersionPopUp}
                  onTabChange={onTabChange}
                  strategyPageTab={strategyPageTab}
                  currentModuleKey={moduleKey}
                  currentBranchIndex={branchIndex}
                  collapseAllModules={collapseAllModules}
                  changeModuleCollapseState={changeModuleCollapseState}
                  collapsedModuleList={collapsedModuleList}
                  isLoading={decisionStrategiesState.isLoading}
                  blockingActionInProgress={decisionStrategiesState.blockingActionInProgress}
                  onEditModuleBranch={handleEditModuleBranch}
                />
              ) : (
                <ErrorPageInfo
                  errorType={ErrorPageType.NotFound}
                  errorTitle="Oops. The page was not found."
                  className={styles.errorInfo}
                />
              )}
            </FullPageLoader>
          </PageContent>
          <PageFooter>
            <Footer separator />
          </PageFooter>
        </PageWrapper>
      </MainLayout>
      <DeleteStrategyWizard />
      <ArchiveStrategyWizard />
      <DuplicateStrategyWizard />
    </>
  );
};

export default StrategyPage;
