import { AnyAction, Middleware, MiddlewareAPI } from 'redux';

import { ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS } from './ActionTypes';
import { requestOrganizationUsers } from 'OrganizationUsers/OrganizationUserStore';
import { ReduxState } from 'types/redux';

export const onAddNewCompanyUserSuccessMiddleware: Middleware = (({
  dispatch,
  getState,
}: MiddlewareAPI<ReduxState>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (action.type === ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS) {
    const { organizationUsers } = getState();
    const {
      page,
      itemsPerPage,
      searchInputValue,
      showInactive,
      permissionGroupId,
      phoneAuthentication,
      sortingType,
    } = organizationUsers;

    dispatch(
      (requestOrganizationUsers({
        filters: {
          offset: page,
          count: itemsPerPage,
          search: searchInputValue,
          showInactive,
          permissionGroupId,
          phoneAuthentication,
        },
        sortingType,
      }) as unknown) as AnyAction, // rewrite middlewares to thunks
    );
  }

  return result;
}) as Middleware;

export default onAddNewCompanyUserSuccessMiddleware;
