import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import ProductSectionAccessPermissionsApi from 'api/Core/ProductSectionAccessPermissionsApi';
import responseHandlers from 'api/ResponseHandlers';
import {
  ProductSection,
  ProductSectionAccessPermissions,
  ProductSectionAccessPermissionsMap,
} from 'ProductSectionAccessPermissions/Types';
import ActionType from './ActionTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';

const accessPermissionsApi = new ProductSectionAccessPermissionsApi(responseHandlers);

export const getAccessPermissions = createAsyncThunk<ProductSectionAccessPermissions[]>(
  ActionType.GetProductSectionAccessPermissions,
  () => {
    return accessPermissionsApi.getAll();
  },
);

export const updateAccessPermissions = createAsyncThunk<
  ProductSectionAccessPermissions,
  { permissions: ProductSectionAccessPermissions; section: ProductSection; value: boolean }
>(ActionType.UpdateProductSectionAccessPermissions, ({ permissions, section, value }) => {
  return accessPermissionsApi.update(permissions.id, {
    [section]: value,
  } as ProductSectionAccessPermissionsMap);
});

export const resetAccessPermissionsUpdatingState = createAction<{
  permissionGroupId: PermissionGroupId;
  section: ProductSection;
}>(ActionType.ResetProductSectionAccessPermissionsUpdatingState);
