import BorrowerDefaultVariable, { BorrowerDefaultVariableType } from './BorrowerDefaultVariable';
import IntermediaryDefaultVariable, { IntermediaryDefaultVariableType } from './IntermediaryDefaultVariable';
import applicationConfig from 'config';
import { convertBorrowerDefaultVariablesToCoBorrower } from 'utils/BorrowerVariableUtils';

export const coBorrowerDefaultVariablesByIndex = new Array(applicationConfig.coBorrowersCount).fill(null).map((key, index) => {
  return convertBorrowerDefaultVariablesToCoBorrower(BorrowerDefaultVariable, index);
});

export const [CoBorrowerDefaultVariable] = coBorrowerDefaultVariablesByIndex;

const ApplicationDefaultVariable = {
  Borrower: BorrowerDefaultVariable,
  CoBorrower: CoBorrowerDefaultVariable as BorrowerDefaultVariableType,
  Intermediary: IntermediaryDefaultVariable,
  LoanAmount: 'loan_amount',
};

type ApplicationDefaultVariableDynamicType = typeof ApplicationDefaultVariable;

export interface ApplicationDefaultVariableType extends ApplicationDefaultVariableDynamicType {
  CoBorrower: BorrowerDefaultVariableType;
  Borrower: BorrowerDefaultVariableType;
  Intermediary: IntermediaryDefaultVariableType;
}

export default ApplicationDefaultVariable;
