import React from 'react';
import { AccessPermissionsTableColumnSizes } from 'components/AccessPermissionsTables/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';

const SkeletonAccessPermissionsTableRow = ({ sectionsQuantity }: { sectionsQuantity: number }) => {
  const sectionWidth = (100 - AccessPermissionsTableColumnSizes.GroupName) / sectionsQuantity;

  return (
    <TableRow disableHover>
      <TableBodyCell width={AccessPermissionsTableColumnSizes.GroupName}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>

      {Array(sectionsQuantity)
        .fill(null)
        .map((_value, id) => (
          <TableBodyCell width={sectionWidth} noPadding key={id}>
            <SkeletonRectangle width="20px" height="20px" color="primary6" />
          </TableBodyCell>
        ))}
    </TableRow>
  );
};

export default SkeletonAccessPermissionsTableRow;
