import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import sortByPosition from 'utils/sortByPosition';

const getConfigurationIds = (state: ReduxState, productId: string) => state.applicationDocumentConfigurations.configurationIdsByProductId[productId];
const getConfigurationsById = (state: ReduxState) => state.applicationDocumentConfigurations.configurationsById;

export const getApplicationDocumentConfigurationsSelector = createSelector(
  [getConfigurationIds, getConfigurationsById],
  (configurationIds, configurationsById) => {
    if (!configurationIds) {
      return null;
    }

    return sortByPosition(configurationIds.map((id) => configurationsById[id]));
  },
);
