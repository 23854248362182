export enum ProductCalculationsType {
  GetProductCalculations = 'productCalculations/getProductCalculations',
  GetProductCalculationsSuccess = 'productCalculations/getProductCalculationsSuccess',
  GetProductCalculationsFailure = 'productCalculations/getProductCalculationsFailure',
  CreateProductCalculation = 'productCalculations/createProductCalculation',
  CreateProductCalculationSuccess = 'productCalculations/createProductCalculationSuccess',
  CreateProductCalculationFailure = 'productCalculations/createProductCalculationFailure',
  UpdateProductCalculation = 'productCalculations/updateProductCalculation',
  UpdateProductCalculationSuccess = 'productCalculations/updateProductCalculationSuccess',
  UpdateProductCalculationFailure = 'productCalculations/updateProductCalculationFailure',
  DeleteProductCalculation = 'productCalculations/deleteProductCalculation',
  DeleteProductCalculationSuccess = 'productCalculations/deleteProductCalculationSuccess',
  DeleteProductCalculationFailure = 'productCalculations/deleteProductCalculationFailure',
}
