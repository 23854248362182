import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { LoanOriginationSystemCreateApplicationState } from 'LoanOriginationSystemApplications/CreateApplication/Types';
import { LoanOriginationSystemApplicationsFiltersState } from 'LoanOriginationSystemApplications/Filters/Types';
import { ItemsStateWithPagination } from 'pagination';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationUpdatingState } from './ActionCreator';
import { ApplicationsSortingType } from 'api/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

export interface ApplicationStatusRulesValidationData {
  applicationId: string;
  applicationDisplayId: string;
  applicationName: string;
  status: ApplicationStatus;
  statusRulesValidationResult: ApplicationStatusRulesValidationResult;
}

export interface CreateApplicationProductData {
  product: Product;
  applicationFormPages: string[];
}

export interface DuplicateApplicationParams {
  id: string;
  displayId: string;
  borrowerFullName: string;
  originalApplicationId?: string;
}

export interface LoanOriginationSystemApplicationsState {
  productsList: Product[] | null;
  selectedProduct: Product | null;

  isColumnViewDataLoading: boolean;

  currencySymbol: string;

  columnViewData: SimplifiedApplication[] | null;
  tableViewData: ItemsStateWithPagination<SimplifiedApplication> | null;

  tableViewSortingType: ApplicationsSortingType;
  columnViewSortingType: ApplicationsSortingType;

  filters: LoanOriginationSystemApplicationsFiltersState;
  createApplication: LoanOriginationSystemCreateApplicationState;

  applicationIdToDelete: string | null;
  duplicateApplicationParams: DuplicateApplicationParams | null;

  applicationStatusRulesValidationData: ApplicationStatusRulesValidationData | null;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  isCreateNewApplicationPopupOpen: boolean;

  createApplicationProductData: CreateApplicationProductData | null;
}

export enum ViewType {
  Column = 'column',
  Table = 'table',
  Tile = 'tile',
}
