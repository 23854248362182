import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { updateProduct, UpdateProductActionOrigin } from 'LoanOriginationSystemProducts/ActionCreator';
import {
  setFormPageToDelete,
  toggleFormSettingsPopupVisibility,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationFormConfiguration/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import Header from './Header';
import BorrowerProfileConfigurationPage from './pages/BorrowerProfileConfigurationPage';
import IntermediaryProfileConfigurationPage from './pages/IntermediaryProfileConfigurationPage';
import ApplicationDetailsConfigurationPage from './pages/ApplicationDetailsConfigurationPage';
import DocumentsUploadConfigurationPage from './pages/ApplicationDocumentsConfigurationPage';
import {
  availableApplicationFormPages,
  getApplicationFormPageLabel,
  isApplicationDetailsFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import styles from './ApplicationFormConfiguration.module.scss';

const ApplicationFormConfiguration = () => {
  const dispatchRoutine = useDispatchRoutine();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration.product,
  );

  const [selectedApplicationFormPage, setSelectedApplicationFormPage] = useState(
    availableApplicationFormPages[0],
  );

  const handleTabChange = (tab: string) => {
    setSelectedApplicationFormPage(tab);
  };

  const handleApplicationPageDelete = (formPage: string) => {
    dispatch(setFormPageToDelete(formPage));
  };

  const handleApplicationFormPageAdd = async (formPage: string) => {
    if (!selectedProduct) {
      return;
    }

    const newApplicationFormPages = [...selectedProduct.settings.applicationFormPages, formPage];

    await dispatchRoutine(
      setActionOrigin(
        updateProduct(selectedProduct.id, { settings: { applicationFormPages: newApplicationFormPages } }),
        UpdateProductActionOrigin.AddConfigurableApplicationFormPage,
      ),
    );

    setSelectedApplicationFormPage(formPage);
  };

  const handleFormSettingsClick = () => {
    dispatch(toggleFormSettingsPopupVisibility());
  };

  const renderContent = () => {
    if (
      isBorrowerApplicationFormPage(selectedApplicationFormPage) ||
      isCoBorrowerApplicationFormPage(selectedApplicationFormPage)
    ) {
      return (
        <BorrowerProfileConfigurationPage
          title={getApplicationFormPageLabel(selectedApplicationFormPage)}
          availableBorrowerTypes={selectedProduct?.borrowerTypes}
        />
      );
    }

    if (isIntermediaryApplicationFormPage(selectedApplicationFormPage)) {
      return (
        <IntermediaryProfileConfigurationPage />
      );
    }

    if (isApplicationDetailsFormPage(selectedApplicationFormPage)) {
      return (
        <ApplicationDetailsConfigurationPage productId={selectedProduct?.id} />
      );
    }

    if (isDocumentUploadFormPage(selectedApplicationFormPage)) {
      return (
        <DocumentsUploadConfigurationPage productId={selectedProduct?.id} />
      );
    }

    return null;
  };

  return (
    <div className={styles.applicationFormConfigurationContainer}>
      <Header
        selectedApplicationFormPage={selectedApplicationFormPage}
        applicationFormPages={selectedProduct?.settings.applicationFormPages || null}
        onTabChange={handleTabChange}
        onApplicationFormPageAdd={handleApplicationFormPageAdd}
        onApplicationFormPageDelete={handleApplicationPageDelete}
        onFormSettingsClick={handleFormSettingsClick}
      />
      <div className={styles.configurationContent}>{renderContent()}</div>
    </div>
  );
};

export default ApplicationFormConfiguration;
