import React from 'react';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { DecisionResultListColumnSizes } from 'components/DecisionResultsView/DecisionResultsTable/TableColumnSizes';
import clsx from 'clsx';
import styles from './DecisionResultLine.module.scss';
import { useDecisionResultApi } from 'providers/ApiServiceProvider';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';
import WrapperWithLink from 'components/WrapperWithLink';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import maskNumberValue from 'utils/masks/maskNumberValue';

interface DecisionResultProps {
  decisionResultData: DecisionResultListItem;
  onRowClick: (result: DecisionResultListItem) => void;
  rowLink?: string;
}

const statusStyles = new Map([
  [DecisionResultType.Passed, styles.statusPassed],
  [DecisionResultType.Failed, styles.statusFailed],
  [DecisionResultType.Error, styles.statusError],
]);

export const getStateColor = (status: DecisionResultType) => {
  return statusStyles.get(status);
};

const DecisionResultLine = ({ decisionResultData, rowLink, onRowClick }: DecisionResultProps) => {
  const decisionResultApi = useDecisionResultApi();

  const { id, name, strategyName, strategyVersion, status, decisionName, executionTime } = decisionResultData;

  const handleDownloadDecisionResult = async () => {
    const response = await decisionResultApi.download(id);
    downloadBlobFile(response);
  }

  const generateRowActions = () => {
    return (
      <RowActions
        actions={[
          {
            title: 'View Sub-Decision',
            handler: () => onRowClick(decisionResultData),
          },
          {
            title: 'Download Sub-Decision',
            handler: handleDownloadDecisionResult,
            separatorRequired: true,
          },
        ]}
      />
    );
  };

  return (
    <WrapperWithLink
      href={rowLink || ''}
      onClick={() => onRowClick(decisionResultData)}
    >
      <TableRow useActions>
        <TableBodyCell width={DecisionResultListColumnSizes.Name} overflowed>
          <div>{strategyName} {strategyVersion && `(v${strategyVersion})`}</div>
        </TableBodyCell>
        <TableBodyCell width={DecisionResultListColumnSizes.Name} overflowed>
          {name} ({ decisionName })
        </TableBodyCell>
        <TableBodyCell width={DecisionResultListColumnSizes.ExecutionTime} overflowed>
          {executionTime && `${maskNumberValue(executionTime)} ms`}
        </TableBodyCell>
        <TableBodyCell width={DecisionResultListColumnSizes.Type}>
          <div className={clsx([styles.caseStatus, getStateColor(status)])}>{status}</div>
        </TableBodyCell>
        {generateRowActions()}
      </TableRow>
    </WrapperWithLink>
  );
};

export default DecisionResultLine;
