import React, { FC, useState } from 'react';
import DeletePopUp from 'components/DeletePopup';
import { useDispatch, useSelector } from 'react-redux';
import { VariableStatusChangePopupState } from './Types';
import { ReduxState } from 'types/redux';
import { changeVariableStatusRequest } from 'Variables/VariablesActionCreator';
import { closeVariableStatusChangePopup } from './Actions';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import { VariablesState } from 'Variables/VariablesTypes';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const ARCHIVE_TITLE = 'Archive Variable';
const RESTORE_TITLE = 'Restore Variable';
const ARCHIVE_QUESTION_TEXT = 'Are you sure you want to archive this variable?';
const RESTORE_QUESTION_TEXT = 'Are you sure you want to restore this variable?';

const VariableStatusChangePopup: FC = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);

  const { isOpen, id }: VariableStatusChangePopupState = useSelector(
    (state: ReduxState) => state.variableStatusChangePopup,
  );
  const { items, filters, searched }: VariablesState = useSelector((state: ReduxState) => state.variables);

  const variablesList = filters.searchInputValue ? searched[filters.searchInputValue].items : items;
  const variable = variablesList.find(({ id: variableId }) => variableId === id);

  if (!isOpen) return null;

  const closePopUp = () => {
    dispatch(closeVariableStatusChangePopup());
  };

  const onChangeVariableStatus = async () => {
    const variableName = variable ? variable.displayName : '';
    setIsBlockingActionInProgress(true);

    try {
      await dispatchRoutine(changeVariableStatusRequest(id, variableName, !variable!.isArchived));
      dispatch(closeVariableStatusChangePopup());
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  if (!variable) {
    // Implementation-wise the popup gets unmounted when the variable no longer sits in the store.
    return null;
  }

  return (
    <PopUpWizard
      step={0}
      closePopUp={closePopUp}
      title={variable.isArchived ? RESTORE_TITLE : ARCHIVE_TITLE}
      closable={!isBlockingActionInProgress}
    >
      <DeletePopUp
        handleDelete={onChangeVariableStatus}
        handleClose={closePopUp}
        message={variable.isArchived ? RESTORE_QUESTION_TEXT : ARCHIVE_QUESTION_TEXT}
        isLoading={isBlockingActionInProgress}
        cancelBtnDisabled={isBlockingActionInProgress}
        deleteBtnText={variable.isArchived ? RESTORE_TITLE : ARCHIVE_TITLE}
      />
    </PopUpWizard>
  );
};

export default VariableStatusChangePopup;
