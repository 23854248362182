import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import { IntermediaryApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import MainLayout, { PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageContent from 'MainLayout/PageContent';
import {
  downloadApiRequestTemplate,
} from 'LoanOriginationSystemIntermediaryConfiguration/ActionCreator';
import IntermediaryProfileConfiguration from 'components/LoanOriginationSystem/IntermediaryProfileConfiguration';
import IntermediaryApiRequestTemplatePopup from 'components/LoanOriginationSystem/IntermediaryApiRequestTemplatePopup';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import styles from './IntermediariesConfiguration.module.scss';

const IntermediariesConfiguration = () => {
  const dispatchRoutine = useDispatchRoutine();
  const { isDownloadApiRequestTemplateInProgress } = useSelector(
    (state: ReduxState) => state.intermediaryConfiguration,
  );
  const [isDownloadApiRequestTemplatePopupOpen, setDownloadApiRequestTemplatePopupOpen] = useState(false);

  const leftNav = makeLeftNavigation(
    NavigationLinkId.IntermediariesConfiguration,
    ApplicationSectionName.LoanOriginationSystem,
  );

  const handleDownloadApiTemplateClick = () => {
    setDownloadApiRequestTemplatePopupOpen(true);
  };

  const handleDownloadApiTemplatePopupClose = () => {
    setDownloadApiRequestTemplatePopupOpen(false);
  };

  const handleDownloadApiTemplate = async (requestType: IntermediaryApiRequestTemplateType) => {
    await dispatchRoutine(downloadApiRequestTemplate(requestType));

    setDownloadApiRequestTemplatePopupOpen(false);
  };

  const renderOverlay = () => {
    if (isDownloadApiRequestTemplatePopupOpen) {
      return (
        <IntermediaryApiRequestTemplatePopup
          onDownload={handleDownloadApiTemplate}
          onClose={handleDownloadApiTemplatePopupClose}
          isDownloadInProgress={isDownloadApiRequestTemplateInProgress}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout leftNav={leftNav} overlay={renderOverlay()}>
      <PageWrapperWithFooter footerClassName={styles.footer}>
        <PageContent className={styles.pageContent}>
          <IntermediaryProfileConfiguration onDownloadApiTemplate={handleDownloadApiTemplateClick} />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default IntermediariesConfiguration;
