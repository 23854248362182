import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import CoreSystemApi from 'api/Core/CoreSystemApi';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { DataViewSortingType, TableViewData } from 'api/LoanOriginationSystem/Types';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';

interface UpdateUserPrams {
  isActive?: boolean;
  permissionGroupId?: PermissionGroupId;
}

export interface UpdateUserResult {
  id: string;
  isActive: boolean;
  permissionGroupId: PermissionGroupId;
  firstName: string;
  lastName: string;
}

export interface UserDataFilter {
  offset: number;
  count: number;
  search: string;
  permissionGroupId: PermissionGroupId | null;
  phoneAuthentication: boolean | null;
  showInactive: boolean;
}

export enum OrganizationUsersSortingField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PermissionGroup = 'permissionGroupName',
  PhoneAuthentication = 'phoneAuthentication',
  AccountStatus = 'accountStatus',
}

export type OrganizationUsersSortingType = DataViewSortingType<OrganizationUsersSortingField>;

export interface UserApi {
  getAll(
    filters?: UserDataFilter,
    sortingType?: OrganizationUsersSortingType,
  ): Promise<TableViewData<OrganizationMembership>>;
  getUserInfo(): Promise<UserInfo>;
  getUserByMembershipId(membershipId: string): Promise<OrganizationMembership>;
  update(userId: string, params: UpdateUserPrams): Promise<UpdateUserResult>;
}

export default class UserRestApi extends CoreSystemApi<UserInfo> implements UserApi {
  protected resourceName = 'users';

  public async getAll(filters?: UserDataFilter, sortingType?: OrganizationUsersSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters && filters.permissionGroupId) {
      params.append('permissionGroupId', filters.permissionGroupId);
    }

    if (filters && filters.phoneAuthentication !== null) {
      params.append('phoneAuthentication', filters?.phoneAuthentication!.toString());
    }

    if (filters && filters.showInactive) {
      params.append('showInactive', filters.showInactive.toString());
    }

    return this.getResources<TableViewData<OrganizationMembership>>(params);
  }

  public async getUserInfo() {
    const currentUser = await this.fetch<UserInfo>('/current-user');
    return currentUser;
  }

  public async getUserByMembershipId(membershipId: string) {
    const user = await this.fetch<OrganizationMembership>(`/${this.resourceName}/${membershipId}`);
    return user;
  }

  public async update(userId: string, params: UpdateUserPrams) {
    const result = await this.fetch<UpdateUserResult>(`/${this.resourceName}/${userId}`, 'PUT', {
      isActive: params.isActive,
      permissionGroupId: params.permissionGroupId,
    });
    return result;
  }
}
