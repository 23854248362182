import { Draft } from '@reduxjs/toolkit';
import {
  NormalizedCard,
} from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import {
  BaseVariableConfiguration,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

interface BaseCardsState {
  cardsById: Record<string, NormalizedCard>;
}

export const deleteVariableConfigurationFromCard = <State extends BaseCardsState>(state: Draft<State>, id: string) => {
  const card = Object.values(state.cardsById).find((cardToFind) => cardToFind.fields.includes(id));

  if (!card) {
    return;
  }

  const newCard = {
    ...card,
    fields: card.fields.filter((field) => field !== id),
  };

  state.cardsById[newCard.id] = newCard;
};

export const updateVariableConfigurationOnCards = <State extends BaseCardsState>(
  state: Draft<State>,
  sourceCardId: string,
  configuration: BaseVariableConfiguration,
) => {
  const sourceCard = state.cardsById[sourceCardId];
  const targetCard = state.cardsById[configuration.cardId];

  if (sourceCard.id === targetCard.id) {
    return;
  }

  sourceCard.fields = sourceCard.fields.filter((field) => field !== configuration.id);
  targetCard.fields.push(configuration.id);
};

export const addVariableConfigurationToCard = <State extends BaseCardsState>(state: Draft<State>, configuration: BaseVariableConfiguration) => {
  state.cardsById[configuration.cardId].fields.push(configuration.id);
};

export const updateCardsStore = <State extends BaseCardsState>(state: Draft<State>, newCardsById: Record<string, NormalizedCard> | undefined) => {
  state.cardsById = {
    ...state.cardsById,
    ...(newCardsById || {}),
  };
};
