import { IDENTIFICATION_NUMBER_TYPES } from 'components/VariableForm/DataTypes';
import { formattedCurrencies } from 'components/CurrencySelect/currencies';
import { PHONE_NUMBER_FORMATS } from 'constants/phoneNumberFormats';

export const getIdentificationNumberTypeMessageByValue = (value: string) => {
  const currenType = IDENTIFICATION_NUMBER_TYPES.find((type: { value: string }) => type.value === value);

  return currenType ? currenType.name : '';
};

export const getCurrencyMessageByValue = (value: string) => {
  const currenCurrency = formattedCurrencies.find((currency: { value: string }) => currency.value === value);

  return currenCurrency ? `${currenCurrency.description} (${currenCurrency.name})` : '';
};

export const getPhoneFormatMessageByValue = (value: string) => {
  const currenPhoneNumberFormat = PHONE_NUMBER_FORMATS.find((format: { value: string }) => format.value === value);

  return currenPhoneNumberFormat ? `${currenPhoneNumberFormat.description} (${currenPhoneNumberFormat.name})` : '';
};
