import React, { FC, useState } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import ApplicationNoteForm from 'components/ApplicationNoteForm';
import { ApplicationNotePopUpType } from 'LoanOriginationSystemApplicationPage/Notes/Types';
import { ApplicationNote } from 'api/Types';

interface ApplicationNotePopUpProps {
  create: (note: string) => Promise<unknown>;
  update: (note: string) => Promise<unknown>;
  closePopUp: () => void;
  popUpType: ApplicationNotePopUpType;
  note?: ApplicationNote;
}

export const ApplicationNotePopUp: FC<ApplicationNotePopUpProps> = ({
  closePopUp,
  create,
  update,
  popUpType,
  note: noteData,
}) => {
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);
  const popUpTitle = `${popUpType === ApplicationNotePopUpType.Add ? 'Add' : 'Edit'} Note`;

  const handleNoteSave = async (noteText: string) => {
    try {
      setIsBlockingActionInProgress(true);

      if (popUpType === ApplicationNotePopUpType.Update) {
        await update(noteText);
      } else {
        await create(noteText);
      }
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={popUpTitle} closable={!isBlockingActionInProgress}>
      <ApplicationNoteForm
        note={noteData && noteData.note}
        onSave={handleNoteSave}
        isLoading={isBlockingActionInProgress}
        popUpType={popUpType}
      />
    </PopUpWizard>
  );
};
