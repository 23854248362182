import React, { useEffect, useState } from 'react';
import styles from './CompanyRegionSettings.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import CompanyRegionSettingsEditForm from './CompanyRegionSettingsEditForm';
import CompanyRegionSettingsView from './CompanyRegionSettingsView';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';

const CompanyRegionSettings = () => {
  const dispatch = useDispatch();
  const { regionSettings, isLoading } = useSelector((state: ReduxState) => state.organizationInformation);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const closeEditor = () => {
    setIsEditModeEnabled(false);
  };

  useEffect(() => {
    dispatch(getOrganizationInfo());
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Region Settings</div>
        {!isEditModeEnabled && (
          <ButtonWithImage
            className={styles.editButton}
            onClick={() => setIsEditModeEnabled(true)}
            title="Edit Settings"
            kind="edit"
            disabled={isLoading}
          />
        )}
      </div>
      <div>
        {isEditModeEnabled ? (
          <CompanyRegionSettingsEditForm regionSettings={regionSettings} onCloseEditor={closeEditor} />
        ) : (
          <CompanyRegionSettingsView regionSettings={regionSettings} />
        )}
      </div>
    </>
  );
};

export default CompanyRegionSettings;
