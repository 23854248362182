import React, { FC, useEffect } from 'react';
import DashboardLayout from './ReportingDashboardLayout';
import styles from './ReportingDashboard.module.scss';
import ReportingDashboardFilters from './ReportingDashboardFilters/ReportingDashboardFilters';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import {
  ReportingDashboardFiltersModel,
  ReportingDashboardStep,
} from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import useStandardVariables from 'hooks/useStandardVariables';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import GrowthMetricsLayout from './GrowthMetricsLayout';
import { ChartSettings } from './ReportingDashboardLayout/ReportingDashboardLayout';
import { ReportingDashboardData } from 'api/LoanOriginationSystem/LoanOriginationSystemReportingApi';
import PerformanceIndicatorsLayout from './PerformanceIndicatorsLayout';

const TABS: TabSwitchOption[] = [
  { id: ReportingDashboardStep.GrowthMetrics, label: ReportingDashboardStep.GrowthMetrics },
  { id: ReportingDashboardStep.PerformanceIndicators, label: ReportingDashboardStep.PerformanceIndicators },
];

interface ReportingDashboardProps {
  isLoading: boolean;
  filters: ReportingDashboardFiltersModel;
  step: ReportingDashboardStep;
  data: ReportingDashboardData;
  additionalFiltersActive: boolean;
  onShowAdditionalFiltersPopup: () => void;
  onLoadData: (filters: ReportingDashboardFiltersModel) => void;
  onStepChange: (step: ReportingDashboardStep) => void;
}

const ReportingDashboard: FC<ReportingDashboardProps> = ({
  onShowAdditionalFiltersPopup,
  data,
  filters,
  step,
  isLoading,
  additionalFiltersActive,
  onLoadData,
  onStepChange,
}) => {
  const standardVariables = useStandardVariables();
  const currencySymbol = getFormattedLoanAmountCurrency(standardVariables) || undefined;

  const organization = useOrganizationDetailedInfo();

  const handleFiltersChange = (newFilters: ReportingDashboardFiltersModel) => {
    onLoadData(newFilters);
  };

  const handleSelectTab = (newStep: TabSwitchOption) => {
    onStepChange(newStep.id as ReportingDashboardStep);
  };

  const layout = (step === ReportingDashboardStep.GrowthMetrics
    ? GrowthMetricsLayout
    : PerformanceIndicatorsLayout
  ).map((charts) => {
    return charts.map((settings) => {
      return {
        ...settings,
        data: data[settings.name]?.points,
        total: data[settings.name]?.total,
      };
    });
  }) as [ChartSettings, ChartSettings][];

  useEffect(() => {
    onLoadData(filters);
  }, []);

  return (
    <div>
      <h2 className={styles.headerTitle}>Reporting</h2>
      <TabSwitch tabs={TABS} selectedTabId={step} onSelect={handleSelectTab} />
      <ReportingDashboardFilters
        className={styles.filter}
        filters={filters}
        organizationCreatedAt={organization.createdAt}
        onChange={handleFiltersChange}
        onAdditionalFiltersClick={onShowAdditionalFiltersPopup}
        disabled={isLoading}
        additionalFiltersActive={additionalFiltersActive}
      />
      <DashboardLayout
        isLoading={isLoading}
        layout={layout}
        period={filters.period}
        currencySymbol={currencySymbol}
        filterStartDate={filters.from}
        filterEndDate={filters.to}
      />
    </div>
  );
};

export default ReportingDashboard;
