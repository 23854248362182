import {
  ProductSection,
  ProductSectionAccessPermissionsMap,
} from 'ProductSectionAccessPermissions/Types';
import { invert, mapKeys, mapValues } from 'lodash';

type ProductSectionShortNames = {
  [fullName in ProductSection]: string;
};

const productSectionShortNames: ProductSectionShortNames = {
  loanOriginationSystemApplications: 'losApps',
  loanOriginationSystemBorrowers: 'losBorrow',
  loanOriginationSystemIntermediaries: 'losInterm',
  loanOriginationSystemTasks: 'losTasks',
  loanOriginationSystemEmails: 'losEmails',
  loanOriginationSystemReporting: 'losReports',
  loanOriginationSystemConfiguration: 'losConfig',

  decisionEngineStrategies: 'deStrateg',
  decisionEngineProcessing: 'deProcess',
  decisionEngineDeployment: 'deDeploy',
  decisionEngineReporting: 'deReports',

  companySettingsGeneral: 'csGeneral',
  companySettingsPlanAndBilling: 'csBilling',
  companySettingsTeam: 'csTeam',
  companySettingsVariables: 'csVars',
  companySettingsBranding: 'csBranding',
  companySettingsDataExport: 'csDataExp',
  companySettingsDevelopers: 'csDevs',

  customDigitalLendingPortal: 'customDLP',
  standardDigitalLendingPortal: 'stdDLP',
} as const;

type ProductSectionFullNames = Record<ProductSectionShortNames[keyof ProductSectionShortNames], ProductSection>;
const productSectionFullNames = invert(productSectionShortNames) as ProductSectionFullNames;

export type MinifiedProductSectionAccessPermissions = Record<
  ProductSectionShortNames[keyof ProductSectionShortNames],
  1 | 0
>;

export const prettifyProductSectionAccessPermissions = (
  minifiedPermissions: MinifiedProductSectionAccessPermissions,
): ProductSectionAccessPermissionsMap => {
  const prettifiedKeys = mapKeys(minifiedPermissions, (value, key) => productSectionFullNames[key]) as Record<
    ProductSection,
    1 | 0
  >;
  return mapValues(prettifiedKeys, (value) => value === 1);
};
