import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Card, NormalizedCard } from './Base/BaseCardsApi';
import { BorrowerVariableConfiguration } from './BorrowerVariableConfigurationsApi';
import { BorrowerType } from './LoanOriginationSystemBorrowersApi';

export interface BorrowerProfileCard extends Card<BorrowerVariableConfiguration> {
  borrowerType: BorrowerType;
}

export interface BorrowerProfileNormalizedCard extends NormalizedCard {
  borrowerType: BorrowerType;
}

export interface CreateBorrowerProfileCardParams {
  borrowerType: BorrowerType;
  position: number;
  row: number;
  name: string;
}

export interface UpdateBorrowerProfileCardParams {
  name?: string;
}

export interface BorrowerProfileCardsApi {
  find(borrowerType: BorrowerType): Promise<BorrowerProfileCard[]>;
  create(params: CreateBorrowerProfileCardParams): Promise<BorrowerProfileCard>;
  update(id: string, params: UpdateBorrowerProfileCardParams): Promise<BorrowerProfileCard>;
  delete(id: string): Promise<void>;
}

export default class BorrowerProfileCardsRestApi extends LoanOriginationSystemApi<BorrowerProfileCard>
  implements BorrowerProfileCardsApi {
  protected resourceName = 'borrower-profile-cards';

  public find(borrowerType: BorrowerType) {
    const params = new URLSearchParams();

    params.set('borrowerType', borrowerType);

    return this.getResources(params);
  }

  public create(params: CreateBorrowerProfileCardParams) {
    return this.createResource({
      card: params,
    });
  }

  public update(id: string, params: UpdateBorrowerProfileCardParams) {
    return this.updateResource(id, {
      card: params,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
