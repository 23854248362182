import React from 'react';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import CompanyGeneralSettings from 'components/CompanyGeneralSettings';
import { useParams } from 'react-router';
import {
  COMPANY_GENERAL_SETTINGS_TAB_ROUTES,
  CompanyGeneralSettingsTab,
} from 'components/CompanyGeneralSettings/CompanyGeneralSettings.constants';

const leftNav = makeLeftNavigation(NavigationLinkId.General, ApplicationSectionName.CompanySettings);

interface RouteParams {
  tab?: string;
}
const CompanyGeneralSettingsPage = () => {
  const params = useParams<RouteParams>();
  const selectedTabId =
    params.tab === COMPANY_GENERAL_SETTINGS_TAB_ROUTES.RegionSettings
      ? CompanyGeneralSettingsTab.RegionSettings
      : CompanyGeneralSettingsTab.CompanyInformation;

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapperWithFooter>
        <PageContent>
          <CompanyGeneralSettings selectedTabId={selectedTabId} />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default CompanyGeneralSettingsPage;
