import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { validateLettersNumbersWithSpecialCharacters } from 'input-validators';
import TextInput from 'components/TextInput';
import Label from 'components/Label';
import CheckboxMultiSelectList, { CheckboxMultiSelectListItem } from 'components/CheckboxMultiSelectList';
import { productTypeOptions } from './ProductTypeOptions';

import styles from './ProductBasicInfoForm.module.scss';

export interface ProductBasicInfoFormProps {
  productName: string;
  borrowerTypes: BorrowerType[];
  productType: ProductType | null;
  onProductNameChange: (name: string) => void;
  onBorrowerTypesChange: (borrowerTypes: BorrowerType[]) => void;
  onProductTypeChange: (type: ProductType) => void;
  borrowerTypesDisabled?: boolean;
  productTypeDisabled?: boolean;
}

export interface ProductIconOption {
  type: ProductType;
  image: React.ComponentType;
}

const MAX_PRODUCT_NAME_LENGTH = 50;

const BORROWER_TYPE_CHECKBOX_LIST_ITEMS: CheckboxMultiSelectListItem[] = [{
  id: BorrowerType.Person,
  label: 'Person',
}, {
  id: BorrowerType.Company,
  label: 'Company',
}];

const validateProductNameField = (productName: string) => {
  if (!productName) {
    return 'Product name is required';
  }

  return null;
};

const ProductBasicInfoForm = ({
  productName,
  borrowerTypes,
  productType,
  onProductNameChange,
  onBorrowerTypesChange,
  onProductTypeChange,
  borrowerTypesDisabled,
}: ProductBasicInfoFormProps) => {
  const [productNameError, setProductNameError] = useState<string | null>(null);
  const [productNameFieldBlurred, setProductNameFieldBlurred] = useState(false);

  const handleProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!validateLettersNumbersWithSpecialCharacters(event.target.value)) {
      return;
    }

    onProductNameChange(event.target.value);
    setProductNameError(validateProductNameField(event.target.value));
  };

  const handleBorrowerTypeChange = (selectedBorrowerType: BorrowerType) => {
    const updatedBorrowerTypes = borrowerTypes.includes(selectedBorrowerType)
      ? borrowerTypes.filter((borrowerType) => borrowerType !== selectedBorrowerType)
      : [...borrowerTypes, selectedBorrowerType];

    onBorrowerTypesChange(updatedBorrowerTypes);
  };

  const handleProductNameFieldBlur = () => {
    setProductNameFieldBlurred(true);
    setProductNameError(validateProductNameField(productName));
  };

  const renderProductType = ({ type, image }: ProductIconOption) => {
    const Image = image;

    return (
      <div
        key={type}
        className={clsx(styles.productIcon, type === productType && styles.selectedProductIcon)}
        onClick={() => onProductTypeChange(type)}
      >
        <Image />
      </div>
    );
  }

  return (
    <div>
      <TextInput
        value={productName}
        errorMessage={productNameFieldBlurred ? productNameError || '' : ''}
        labelTitle="Product Name"
        maxLength={MAX_PRODUCT_NAME_LENGTH}
        onChange={handleProductNameChange}
        onBlur={handleProductNameFieldBlur}
        required
      />
      <CheckboxMultiSelectList
        wrapperClassName={styles.checkboxMultiSelectListWrapper}
        label="Borrower Type(s)"
        items={BORROWER_TYPE_CHECKBOX_LIST_ITEMS}
        selectedItemsIds={borrowerTypes}
        onChange={(id) => handleBorrowerTypeChange(id as BorrowerType)}
        isDisabled={() => !!borrowerTypesDisabled}
        required
      />
      <div className={styles.productIconsContainer}>
        <Label className={styles.productTemplatesLabel} required>Product Icon</Label>
        <div className={styles.productIconsInnerContainer}>
          {productTypeOptions.map(renderProductType)}
        </div>
      </div>
    </div>
  );
};

export default ProductBasicInfoForm;
