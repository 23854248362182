import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { AddImage } from 'static/images';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import SkeletonCardsLayout from 'components/LoanOriginationSystem/CardsLayoutConfiguration/SkeletonCardsLayout';
import { DEFAULT_SKELETON_CARDS_LAYOUT } from 'components/LoanOriginationSystem/CardsLayoutConfiguration';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';
import Button from 'components/Button';
import CardsLayoutPreviewRow from './CardsLayoutPreviewRow';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import styles from './CardsLayoutPreview.module.scss';

export interface CardsLayoutPreviewProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  > {
  className?: string;
  cards: CardType[] | null;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  skeletonCardsLayout?: Array<Array<number>>;
  descriptionTooltip?: React.ReactNode;
}

const CardsLayoutPreview = <
  VariableConfigurationType extends BaseVariableConfiguration,
  DataType extends Card<VariableConfigurationType>,
>({
  cards,
  className,
  formatVariableConfigurationDisplayTitle,
  skeletonCardsLayout = DEFAULT_SKELETON_CARDS_LAYOUT,
  descriptionTooltip,
}: PropsWithChildren<CardsLayoutPreviewProps<VariableConfigurationType, DataType>>) => {
  const cardsLayout = useFormLayout(cards);

  const renderLayoutContent = () => {
    if (!cardsLayout) {
      return <SkeletonCardsLayout layout={skeletonCardsLayout} />;
    }

    return (
      <>
        <div className={styles.rowsContainer}>
          {cardsLayout.map((cardsRow, index) => (
            <CardsLayoutPreviewRow
              key={index}
              cards={cardsRow}
              formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
              descriptionTooltip={descriptionTooltip}
            />
          ))}
        </div>
        <WrapperWithHoverableTooltip tooltip={descriptionTooltip}>
          <div className={styles.addCardButton}>
            <AddImage />
          </div>
        </WrapperWithHoverableTooltip>
      </>
    );
  };

  return (
    <div className={clsx(styles.container, className)}>
      {renderLayoutContent()}
      <WrapperWithHoverableTooltip tooltip={descriptionTooltip}>
        <span className={styles.addVariableButtonWrapper}>
          <Button kind="secondary" disabled>Add Variable</Button>
        </span>
      </WrapperWithHoverableTooltip>
    </div>
  );
};

export default CardsLayoutPreview;
