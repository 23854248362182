import { ChangePopupFiltersAction, ResetAllTasksFilters } from 'LoanOriginationSystemTasks/Filters/ActionCreator';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemTasksFiltersActionTypes } from './ActionTypes';
import { getTasks } from 'LoanOriginationSystemTasks/ActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllTasksFilters =>
  action.type === LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters;

const onTasksFiltersChangeMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetAllFiltersAction(action)) {
    dispatch(getTasks());
  }

  return result;
}) as Middleware;

export default onTasksFiltersChangeMiddleware;
