import { createAsyncThunk } from '@reduxjs/toolkit';
import requestWithNormalize from 'utils/requestWithNormalize';
import ApplicationDetailsCardsRestApi, {
  ApplicationDetailsCard,
  ApplicationDetailsNormalizedCard,
  CreateApplicationDetailsCardParams,
  UpdateApplicationDetailsCardParams,
} from 'api/LoanOriginationSystem/ApplicationDetailsCardsApi';
import ApplicationDetailsCardSchema from 'schemas/ApplicationDetailsCardSchema';
import responseHandlers from 'api/ResponseHandlers';
import { ApplicationVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';

interface ApplicationDetailsCardsNormalizedEntities {
  applicationDetailsCards: Record<string, ApplicationDetailsNormalizedCard>;
  applicationVariableConfigurations: Record<string, ApplicationVariableConfiguration>;
}

const applicationDetailsCardsApi = new ApplicationDetailsCardsRestApi(responseHandlers);

export const getApplicationDetailsCards = createAsyncThunk(
  'applicationDetailsCards/getApplicationDetailsCards',
  async (productId: string) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDetailsCard>(() => {
      return applicationDetailsCardsApi.find(productId);
    }, [ApplicationDetailsCardSchema]);

    return entities;
  },
);

export const createApplicationDetailsCard = createAsyncThunk(
  'applicationDetailsCards/createApplicationDetailsCard',
  async (params: CreateApplicationDetailsCardParams) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDetailsCard>(() => {
      return applicationDetailsCardsApi.create(params);
    }, ApplicationDetailsCardSchema);

    return entities;
  },
);

export const updateApplicationDetailsCard = createAsyncThunk(
  'applicationDetailsCards/updateApplicationDetailsCard',
  async (params: UpdateApplicationDetailsCardParams & { id: string }) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDetailsCard>(() => {
      const { id, ...updateParams } = params;

      return applicationDetailsCardsApi.update(id, updateParams);
    }, ApplicationDetailsCardSchema);

    return entities;
  },
);

export const deleteApplicationDetailsCard = createAsyncThunk(
  'applicationDetailsCards/deleteApplicationDetailsCard',
  async (id: string) => {
    await applicationDetailsCardsApi.delete(id);

    return {
      id,
    };
  },
);
