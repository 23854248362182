import React from 'react';
import clsx from 'clsx';
import CustomCheckbox from 'components/CustomCheckbox';
import Label from 'components/Label';
import styles from './CheckboxMultiSelectList.module.scss';

export interface CheckboxMultiSelectListItem {
  id: string;
  label: React.ReactNode;
}

interface CheckboxMultiSelectListProps {
  label?: string;
  items: CheckboxMultiSelectListItem[];
  selectedItemsIds: string[];
  onChange: (id: string) => void;
  className?: string;
  isDisabled?: (itemId: string) => boolean;
  listItemCheckboxClassName?: string;
  listItemLabelClassName?: string;
  wrapperClassName?: string;
  required?: boolean;
}

const CheckboxMultiSelectList = ({
  label,
  items,
  selectedItemsIds,
  isDisabled,
  onChange,
  className,
  listItemCheckboxClassName,
  listItemLabelClassName,
  wrapperClassName,
  required,
}: CheckboxMultiSelectListProps) => {
  const onCheck = (item: CheckboxMultiSelectListItem) => {
    onChange(item.id);
  };

  return (
    <div className={wrapperClassName}>
      {label && <Label required={required}>{label}</Label>}
      <div className={clsx(styles.list, className)}>
        {items.map((item) => (
          <CustomCheckbox
            className={listItemCheckboxClassName}
            labelClassName={listItemLabelClassName}
            key={item.id}
            disabled={isDisabled?.(item.id)}
            checked={selectedItemsIds.includes(item.id)}
            onChange={() => onCheck(item)}
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxMultiSelectList;
