import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

export const getTabIdsByProductId = (state: ReduxState, productId?: string) => {
  return productId ? state.applicationTabsData.tabsIdsByProductId[productId] : null;
};

export const getTabsById = (state: ReduxState) => {
  return state.applicationTabsData.tabsById;
};

export const getTabsByProductId = (state: ReduxState, productId?: string) => createSelector([
  () => getTabIdsByProductId(state, productId),
  getTabsById,
], (tabsIds, tabsById) => {
  if (!tabsIds) {
    return null;
  }

  return tabsIds.map((id) => tabsById[id])
    .sort((firstTab, secondTab) => firstTab.position - secondTab.position);
})(state);
