import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DefaultApplicationStatusName } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import {
  getStatusesRulesValidationResults,
  resetStatusesRulesValidationResults,
} from 'LoanOriginationSystemApplicationPage/StatusesRules/ActionCreator';
import StatusRulesValidationResult from './StatusRulesValidationResult';
import SkeletonStatusRulesValidationContent from './SkeletonStatusRulesValidationContent';
import styles from './StatusesRulesValidationContent.module.scss';

export interface StatusesRulesValidationContentProps {
  application: Application;
}

const StatusesRulesValidationContent = ({ application }: StatusesRulesValidationContentProps) => {
  const dispatch = useDispatch();
  const statusesRulesValidationResults = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.statusesRulesValidationResults,
  );

  useEffect(() => {
    dispatch(getStatusesRulesValidationResults(application.id));

    return () => {
      dispatch(resetStatusesRulesValidationResults());
    };
  }, []);

  const renderStatusRulesBody = () => {
    if (!statusesRulesValidationResults) {
      return <SkeletonStatusRulesValidationContent />;
    }

    const filteredStatusesRulesValidationResults = [...statusesRulesValidationResults]
      .sort(({ status: firstStatus }, { status: secondStatus }) => firstStatus.position - secondStatus.position)
      .filter(({ status }) => status.name !== DefaultApplicationStatusName.Rejected);

    const currentStatusIndex = filteredStatusesRulesValidationResults.findIndex(
      ({ status }) => status.id === application.status.id,
    );

    const renderStatusesRulesValidationResult = (
      { status, ruleValidationResults }: ApplicationStatusRulesValidationResult,
      index: number,
    ) => (
      <StatusRulesValidationResult
        key={status.id}
        status={status}
        ruleValidationResults={ruleValidationResults}
        isCurrentStatus={currentStatusIndex === index}
        initialExpanded={index >= currentStatusIndex}
        statusIndex={index + 1}
      />
    );

    return (
      <div className={styles.results}>
        {filteredStatusesRulesValidationResults.map(renderStatusesRulesValidationResult)}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>Status Rules</h4>
      {renderStatusRulesBody()}
    </div>
  );
};

export default StatusesRulesValidationContent;
