import React from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './UpdateExistingApplicationsPopup.module.scss';

export interface UpdateExistingApplicationPopupProps {
  applicationsCount: number;
  entityName: string;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  usePortal?: boolean;
}

const MAX_COUNT_OF_APPLICATIONS = 10;

const UpdateExistingApplicationsPopup = ({
  applicationsCount,
  entityName,
  onClose,
  isLoading,
  onConfirm,
  usePortal,
}: UpdateExistingApplicationPopupProps) => {
  const isConfirmButtonDisabled = applicationsCount >= MAX_COUNT_OF_APPLICATIONS;

  const renderTooltip = () => {
    if (!isConfirmButtonDisabled) {
      return null;
    }

    return (
      <>
        <p>A maximum of 100 applications can</p>
        <p>be updated by this automatic process</p>
      </>
    );
  };

  return (
    <PopUp usePortal={usePortal} closePopUp={onClose} title="Update Existing Applications?">
      <PopUpContent hasTopMargin>
        <div className={styles.description}>
          You are updating the {entityName}’s profile. To also update their in-process applications (i.e. not Approved or Rejected) with these changes, please click below.
        </div>
        <WrapperWithTooltip tooltip={renderTooltip()}>
          <Button
            onClick={onConfirm}
            kind="primary"
            size="form"
            disabled={applicationsCount >= MAX_COUNT_OF_APPLICATIONS}
            isLoading={isLoading}
            className={styles.confirmButton}
          >
            Update Applications
          </Button>
        </WrapperWithTooltip>
        <Button
          kind="secondary"
          size="form"
          disabled={isLoading}
          onClick={onClose}
        >
          Do Not Update Applications
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default UpdateExistingApplicationsPopup;
