import React, { useState, useEffect, useMemo, FC } from 'react';
import { EventTypeOption } from 'api/Core/EventTypeOptionsApi';
import { useEventTypeOptionsApi } from 'providers/ApiServiceProvider';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';

export interface EventTypeMultiselectProps {
  id: string;
  selectedEventTypes: string[];
  labelTitle?: string;
  onSelectEventType: (eventType: string) => void;
  onDeselectEventType: (eventType: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  hideDescription?: boolean;
  placeholder?: string;
  errorMessage?: string;
}

const EventTypeMultiselect: FC<EventTypeMultiselectProps> = ({
  selectedEventTypes,
  labelTitle,
  onDeselectEventType,
  onSelectEventType,
  id,
  onBlur,
  onFocus,
  placeholder,
  hideDescription,
  errorMessage,
}) => {
  const [eventTypeOptions, setEventTypeOptions] = useState<EventTypeOption[]>([]);

  const eventTypeOptionsApi = useEventTypeOptionsApi();

  useEffect(() => {
    eventTypeOptionsApi.getAll().then((options) => setEventTypeOptions(options))
  }, []);

  const mappedEventTypeOptions = useMemo(() => {
    return eventTypeOptions.map((eventTypeOption) => ({
      name: eventTypeOption.id,
      value: eventTypeOption.id,
      description: !hideDescription ? eventTypeOption.description : '',
    }));
  }, [eventTypeOptions, hideDescription]);

  return (
    <AutoCompletionMultiselect
      placeholder={placeholder}
      labelTitle={labelTitle || ''}
      onSelect={onSelectEventType}
      id={id}
      options={mappedEventTypeOptions}
      selectedOptions={selectedEventTypes}
      onDeleteOption={onDeselectEventType}
      onBlur={onBlur}
      onFocus={onFocus}
      errorMessage={errorMessage}
    />
  );
};

export default EventTypeMultiselect;
