import { LoanOriginationSystemTasksApi } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import tasksPagination from 'components/LoanOriginationSystem/TasksDashboard/Pagination';

export default (api: LoanOriginationSystemTasksApi) => {
  return tasksPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getTasks(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        dueDateRange: params.dueDateRange,
        status: params.selectedStatus,
        members: params.selectedMembers,
      },
      params.sortingType,
      params.selectedApplicationId,
    );
  });
};
