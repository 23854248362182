import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { ApplicationVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import {
  createApplicationVariableConfiguration,
  updateApplicationVariableConfigurationPosition,
  updateApplicationVariableConfigurationRequiredAttribute,
  updateConditionalDisplayRule,
  deleteApplicationVariableConfiguration,
} from './Thunks';
import {
  createApplicationDetailsCard,
  getApplicationDetailsCards,
  updateApplicationDetailsCard,
} from 'ApplicationDetailsCards/Thunks';
import {
  deleteVariableConfiguration,
  updateVariableConfigurationStore,
} from 'LoanOriginationSystemVariablesConfiguration/Utils';

export interface ApplicationVariableConfigurationsState {
  configurationsById: Record<string, ApplicationVariableConfiguration>;
}

const initialState: ApplicationVariableConfigurationsState = {
  configurationsById: {},
};

const applicationVariableConfigurationsReducer = createReducer<ApplicationVariableConfigurationsState>(initialState, (builder) => {
  builder.addCase(deleteApplicationVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfiguration(state, action.payload.id);
  });

  builder.addCase(
    updateApplicationVariableConfigurationRequiredAttribute.pending,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = action.meta.arg.required;
    },
  );

  builder.addCase(
    updateApplicationVariableConfigurationRequiredAttribute.rejected,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = !action.meta.arg.required;
    },
  )

  builder.addMatcher(
    isFulfilled(
      createApplicationVariableConfiguration,
      updateApplicationVariableConfigurationPosition,
      updateConditionalDisplayRule,
    ),
    (state, action) => {
      updateVariableConfigurationStore(state, {
        [action.payload.configuration.id]: action.payload.configuration,
      });
    },
  );

  builder.addMatcher(
    isFulfilled(getApplicationDetailsCards, createApplicationDetailsCard, updateApplicationDetailsCard),
    (state, action) => {
      updateVariableConfigurationStore(state, action.payload.applicationVariableConfigurations);
    },
  );
});

export default applicationVariableConfigurationsReducer;
