import React from 'react';
import { useHistory } from 'react-router';

import LeftNav from 'components/LeftNav/LeftNavigation';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';

export type NotFoundPageSection = '';

export const makeLeftNavigation = (
  activeLinkId: string,
  activeProductTitle?: ApplicationSectionName | NotFoundPageSection,
  noLinks?: boolean,
) => <LeftNav activeLinkId={activeLinkId} activeProductTitle={activeProductTitle} noLinks={noLinks} />;

export const useCloseContextualView = (queryAttribute?: string) => {
  const history = useHistory();

  return () => {
    if (!queryAttribute) {
      history.replace(history.location.pathname);

      return;
    }

    const urlSearchParams = new URLSearchParams(history.location.search);

    urlSearchParams.delete(queryAttribute);

    history.replace(`${history.location.pathname}?${urlSearchParams}`);
  }
};

export const useOpenContextualView = (queryAttribute: string) => {
  const history = useHistory();

  return (queryAttributeValue: string = '') => {
    const urlSearchParams = new URLSearchParams(history.location.search);

    urlSearchParams.set(queryAttribute, queryAttributeValue);

    history.replace(`?${urlSearchParams}`);
  };
};
