import { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { compact, uniq } from 'lodash';
import { Variable } from 'Variables/VariablesTypes';

interface VariableGetter {
  (variableId: string | null | undefined): Variable | undefined;
}

interface VariablesRequester {
  (variableId: string): void;
}

const defaultVariablesRequester: VariablesRequester = () => {
  throw new Error('VariablesRequester implementation was not provided');
};

const VariablesRequesterContext = createContext<VariablesRequester>(defaultVariablesRequester);

export const VariablesRequesterContextProvider = VariablesRequesterContext.Provider;

export const useVariables = (...variableIds: (string | undefined | null)[]) => {
  const requestVariables = useContext(VariablesRequesterContext);

  const stableVariableIdsList = uniq(compact(variableIds)).sort();

  useEffect(() => {
    for (const variableId of stableVariableIdsList) {
      requestVariables(variableId);
    }
  }, [stableVariableIdsList.join(',')]);

  const variablesMap = useSelector<ReduxState, Partial<Record<string, Variable>>>((state) =>
    state.variablesAttributes.byId
  );

  const isLoading = stableVariableIdsList.some((variableId) => !variablesMap[variableId]);

  const getVariable: VariableGetter = (variableId) => (variableId ? variablesMap[variableId] : undefined);

  return {
    isLoading,
    getVariable,
  };
};

export const useVariableAttributes: VariableGetter = (variableId) => {
  const { getVariable } = useVariables(variableId);
  return getVariable(variableId);
};
