import { WebhookEndpointSortingType } from 'api/Webhooks/WebhookEndpointsApi';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT = 10;

export interface WebhookEndpointsPaginationParams {
  sortingType: WebhookEndpointSortingType;
}

const webhookEndpointsPagination = Pagination<ReduxState, string, WebhookEndpointsPaginationParams>(
  'webhookEndpointsTable',
  WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT,
);

export default webhookEndpointsPagination;
