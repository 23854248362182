import React, { FC, Fragment, createRef, RefObject, useState, useEffect } from 'react';
import clsx from 'clsx';
import isEllipsisActive from 'utils/isEllipsisActive';
import RowActions from 'components/RowActions';
import WrapperWithTooltip from 'components/Tooltip';
import { ChevronIcon } from 'static/images';
import styles from './DocumentFoldersBreadcrumbs.module.scss';

interface BreadcrumbLink {
  id: string;
  name: string;
}

interface DocumentFoldersBreadcrumbsProps {
  rootLinkName: string;
  links: BreadcrumbLink[];
  onLinkClick: (id: string | null) => void;
  breadcrumbClassName?: string;
  className?: string;
}

const NUMBER_OF_FULL_TEXT_LINKS = 2;

const getLongestLink = (links: BreadcrumbLink[]) =>
  links.reduce((linkA, linkB) => (linkA.name.length > linkB.name.length ? linkA : linkB));

const isLongestLink = (linkId: string, links: BreadcrumbLink[]) => linkId === getLongestLink(links).id;

const DocumentFoldersBreadcrumbs: FC<DocumentFoldersBreadcrumbsProps> = ({
  rootLinkName,
  links,
  onLinkClick,
  breadcrumbClassName,
  className,
}) => {
  const shouldRenderPopupLinks = links.length > NUMBER_OF_FULL_TEXT_LINKS;
  const textLinks = shouldRenderPopupLinks ? links.slice(-1) : links;
  const popupLinks = links.slice(0, -1);
  const popupTitle = 'Show Path';
  const [linkRefs, setLinkRefs] = useState<Array<RefObject<HTMLDivElement>>>([]);
  const [isLongestLinkTruncated, setIsLongestLinkTruncated] = useState(false);

  useEffect(() => {
    setLinkRefs((refs) => links.map((_, index) => refs[index] || createRef()));
  }, [links]);

  useEffect(() => {
    setIsLongestLinkTruncated(linkRefs.some((ref) => ref?.current && isEllipsisActive(ref?.current)));
  }, [linkRefs, isLongestLinkTruncated]);

  const renderBreadcrumb = (name: string, id: string, index: number) => (
    <div
      className={clsx(
        styles.breadcrumb,
        breadcrumbClassName,
        isLongestLink(id, textLinks) ? styles.breadcrumbLong : styles.breadcrumbShort,
      )}
      onClick={() => onLinkClick(id)}
      ref={linkRefs[index]}
    >
      {name}
    </div>
  );

  return (
    <div className={clsx(styles.breadcrumbsContainer, className)}>
      <div className={clsx(styles.breadcrumb, breadcrumbClassName)} onClick={() => onLinkClick(null)}>
        {rootLinkName}
      </div>
      {shouldRenderPopupLinks ? (
        <>
          <ChevronIcon className={styles.arrow} />
          <RowActions
            className={styles.actionsContainer}
            btnClassName={styles.actionsButton}
            btnTooltip={popupTitle}
            popupTitle={popupTitle}
            actions={popupLinks.map(({ name, id }) => ({
              title: name,
              handler: () => onLinkClick(id),
              separatorRequired: true,
            }))}
          />
        </>
      ) : null}
      {textLinks.map(({ name, id }, index) => (
        <Fragment key={id}>
          <ChevronIcon className={styles.arrow} />
          {isLongestLink(id, textLinks) && isLongestLinkTruncated ? (
            <WrapperWithTooltip tooltip={name}>{renderBreadcrumb(name, id, index)}</WrapperWithTooltip>
          ) : (
            renderBreadcrumb(name, id, index)
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default DocumentFoldersBreadcrumbs;
