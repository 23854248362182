import React, { FC } from 'react';
import RowActions from 'components/RowActions';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { RowActionsProps } from 'components/RowActions/RowActions';

interface ProductRowActionsProps extends Partial<RowActionsProps> {
  productStatus: ProductStatus;
  onChangeStatus: (status: ProductStatus) => void;
  onDuplicate: () => void;
  onEditBasicInformation?: () => void;
}

const ProductRowActions: FC<ProductRowActionsProps> = ({
  productStatus,
  onChangeStatus,
  onDuplicate,
  onEditBasicInformation,
  ...rowActionsProps
}) => {
  const getRowActions = () => {
    const baseActions = [
      ...(onEditBasicInformation ? [{ title: 'Edit Basic Information', handler: onEditBasicInformation }] : []),
      {
        title: 'Duplicate Product',
        handler: () => onDuplicate(),
      },
      {
        title:
          productStatus === ProductStatus.Archived
            ? 'Restore Product'
            : `Change Status to ${productStatus === ProductStatus.Active ? 'Draft' : 'Active'}`,
        handler: () =>
          productStatus === ProductStatus.Archived || productStatus === ProductStatus.Active
            ? onChangeStatus(ProductStatus.Draft)
            : onChangeStatus(ProductStatus.Active),
        separatorRequired: productStatus !== ProductStatus.Archived,
      },
    ];

    if (productStatus === ProductStatus.Archived) {
      return baseActions;
    }

    return [
      ...baseActions,
      {
        title: 'Archive Product',
        handler: () => onChangeStatus(ProductStatus.Archived),
        danger: true,
      },
    ];
  };

  return <RowActions actions={getRowActions()} {...rowActionsProps} />;
};

export default ProductRowActions;
