import { useEffect, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import useDataByHashKey from './useDataByHashKey';
import useConditionalDisplayLogicWorker, {
  DisplayFieldsAttributes,
  WithConditionalDisplayRuleField,
} from './useConditionalDisplayLogicWorker';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';

const RECOMPUTE_DELAY = 500; // ms

const useConditionalDisplayLogic = <Field extends WithConditionalDisplayRuleField>(
  fields: Field[] | null,
  data: FormLayoutData | null,
) => {
  const conditionalDisplayLogicWorker = useConditionalDisplayLogicWorker();

  const [displayFieldsAttributes, setDisplayFieldsAttributes] = useDataByHashKey<DisplayFieldsAttributes>(fields);
  const displayAttributesWereCalculated = !!displayFieldsAttributes;

  const runConditionalDisplayLogic = useCallback(async (conditionalDisplayLogicData: FormLayoutData) => {
    if (!conditionalDisplayLogicWorker || !fields) {
      return;
    }

    const updatedDisplayFieldsAttributes = await conditionalDisplayLogicWorker.runConditionalDisplayLogic(
      fields,
      conditionalDisplayLogicData,
    );

    setDisplayFieldsAttributes((previousDisplayAttributes) => {
      return !previousDisplayAttributes || isConfigurableFormDataChanged(previousDisplayAttributes, updatedDisplayFieldsAttributes)
        ? updatedDisplayFieldsAttributes
        : previousDisplayAttributes;
    });
  }, [conditionalDisplayLogicWorker, fields]);

  const debouncedRunConditionalDisplayLogic = useMemo(() => {
    return displayAttributesWereCalculated
      ? debounce(runConditionalDisplayLogic, RECOMPUTE_DELAY)
      : runConditionalDisplayLogic;
  }, [runConditionalDisplayLogic, displayAttributesWereCalculated]);

  useEffect(() => {
    if (data) {
      debouncedRunConditionalDisplayLogic(data);
    }
  }, [conditionalDisplayLogicWorker, fields, data]);

  return displayFieldsAttributes;
};

export default useConditionalDisplayLogic;
