import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import validateAccountInformationStep, {
  AccountInformationStepValidationResult,
  LABELS,
} from './validateAccountInformationStep';
import { Option } from 'components/SelectInput/SelectInput';
import AutoCompleteWithError from 'components/AutoCompleteWithError';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import FormFooterLink from 'components/Auth/FormFooterLink';
import { AppRoutes } from 'routes/RouteService';
import { CreateAccountInformation } from 'api/Core/AuthApi';
import PasswordInput from 'components/PasswordInput';
import getCountryFromLocale from 'utils/getCountryFromLocale';
import COUNTRY_OPTIONS from 'constants/countryOptions';

import styles from './AccountInformationStep.module.scss';

interface AccountInformationStepProps {
  initialData: CreateAccountInformation | null;
  onContinue: (data: CreateAccountInformation) => void;
  onChange: (data: CreateAccountInformation) => void;
}

const AccountInformationStep: FC<AccountInformationStepProps> = ({ initialData, onContinue, onChange }) => {
  const countryRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const continueRef = useRef<HTMLButtonElement>(null);

  const [companyName, setCompanyName] = useState(initialData?.companyName || '');
  const [country, setCountry] = useState(initialData?.country || getCountryFromLocale());

  const [firstName, setFirstName] = useState(initialData?.firstName || '');
  const [lastName, setLastName] = useState(initialData?.lastName || '');
  const [email, setEmail] = useState(initialData?.email || '');
  const [password, setPassword] = useState(initialData?.password || '');
  const [errors, setErrors] = useState<AccountInformationStepValidationResult>({});

  const handleChange = (setValue: (value: string) => void, key: keyof CreateAccountInformation) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(e.target.value);

    setErrors({ ...errors, [key]: '' });
  };

  const handleCountryChange = ({ value }: Option) => {
    setCountry(value);
    setErrors({ ...errors, country: '' });
  };

  const handleBlur = (key: keyof CreateAccountInformation, value: string) => () => {
    const validationResult = validateAccountInformationStep({ [key]: value });
    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const validateForm = () =>
    validateAccountInformationStep({
      companyName,
      country,
      firstName,
      lastName,
      email,
      password,
    });

  const handleContinue = () => {
    const validationResult = validateForm();
    setErrors(validationResult);

    if (Object.values(validationResult).length === 0) {
      onContinue({ companyName, country, firstName, lastName, email, password });
    }
  };

  useEffect(() => {
    onChange({ companyName, country, firstName, lastName, email, password });
  }, [companyName, country, firstName, lastName, email, password]);

  const continueIsDisabled = Object.values(validateForm()).length > 0;

  return (
    <div>
      <h5 className={styles.subHeader}>Company Information</h5>
      <TextInput
        autoFocus
        value={companyName}
        containerClassName={styles.firstInput}
        labelTitle={LABELS.companyName}
        onChange={handleChange(setCompanyName, 'companyName')}
        errorMessage={errors.companyName}
        onBlur={handleBlur('companyName', companyName)}
        onKeyDown={TriggerEventOnEnterKeyDown(countryRef, 'focus')}
      />
      <AutoCompleteWithError
        value={country}
        ref={countryRef}
        labelTitle={LABELS.country}
        options={COUNTRY_OPTIONS}
        onChange={handleCountryChange}
        errorMessage={errors.country}
        onKeyDown={TriggerEventOnEnterKeyDown(lastNameRef, 'click')}
        onBlur={handleBlur('country', country)}
      />
      <h5 className={styles.subHeader}>Personal Information</h5>
      <TextInput
        value={firstName}
        ref={firstNameRef}
        containerClassName={styles.firstInput}
        labelTitle={LABELS.firstName}
        onChange={handleChange(setFirstName, 'firstName')}
        errorMessage={errors.firstName}
        onBlur={handleBlur('firstName', firstName)}
        onKeyDown={TriggerEventOnEnterKeyDown(lastNameRef, 'focus')}
      />
      <TextInput
        value={lastName}
        inputRef={lastNameRef}
        labelTitle={LABELS.lastName}
        onChange={handleChange(setLastName, 'lastName')}
        errorMessage={errors.lastName}
        onBlur={handleBlur('lastName', lastName)}
        onKeyDown={TriggerEventOnEnterKeyDown(emailRef, 'focus')}
      />
      <TextInput
        value={email}
        inputRef={emailRef}
        labelTitle={LABELS.email}
        onChange={handleChange(setEmail, 'email')}
        errorMessage={errors.email}
        onBlur={handleBlur('email', email)}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordRef, 'focus')}
      />
      {/* autoComplete="new-password" prevents browser to overwrite the field value when the form is loaded */}
      <PasswordInput
        value={password}
        ref={passwordRef}
        labelTitle={LABELS.password}
        autoComplete="new-password"
        errorMessage={errors.password}
        onChange={handleChange(setPassword, 'password')}
        onBlur={handleBlur('password', password)}
        onKeyDown={TriggerEventOnEnterKeyDown(continueRef, 'click')}
      />
      <Button
        alwaysClickable
        size="form"
        kind="secondary"
        ref={continueRef}
        onClick={handleContinue}
        className={styles.continue}
        disabled={continueIsDisabled}
      >
        Continue
      </Button>
      <FormFooterLink
        linkText="Sign In"
        text="Already have an account?"
        to={AppRoutes.SignIn}
        className={styles.signInLink}
      />
    </div>
  );
};

export default AccountInformationStep;
