import { AnyAction, Middleware } from 'redux';
import { EditTaskSuccessAction } from './ActionCreator';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';
import { getTasks } from 'LoanOriginationSystemTasks/ActionCreator';
import { RoutineHandler } from 'middlewares/Fetcher';

const isEditTaskSuccessAction = (action: AnyAction): action is EditTaskSuccessAction =>
  action.type === LoanOriginationSystemEditTaskActionTypes.EditTaskSuccess;

const EditTaskSuccessMiddleware: Middleware = RoutineHandler(async (action: AnyAction, { dispatch }) => {
  if (isEditTaskSuccessAction(action)) {
    dispatch(getTasks({ notReload: true }));
  }
});

export default EditTaskSuccessMiddleware;
