import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import {
  ApplicationHistoryFilterType,
  ApplicationHistorySortingType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import useStateReset from 'hooks/useStateReset';
import {
  changeFilterType,
  changeSelectedMembers,
  changeSortingType,
  changeSearchValue,
  getApplicationHistory,
} from 'LoanOriginationSystemApplicationPage/History/ActionCreator';
import {
  getApplicationHistoryRecords,
  getFilterType,
  getSelectedMembers,
  getSortingType,
  getSearchValue,
} from 'LoanOriginationSystemApplicationPage/History/Selectors';
import { ApplicationHistoryActionType } from 'LoanOriginationSystemApplicationPage/History/ActionTypes';
import Header from './Header';
import HistoryList from './HistoryList';

export interface HistoryTabProps {
  applicationId: string;
}

const RECORDS_PLACEHOLDER_DEFAULT_LENGTH = 10;

const HistoryTab = ({ applicationId }: HistoryTabProps) => {
  const dispatch = useDispatch();
  const organizationMembers = useOrganizationMembers();

  const { isGetRecordsPending, records, filterType, selectedMembers, sortingType, search } = useSelector(
    (state: ReduxState) => ({
      isGetRecordsPending: state.loanOriginationSystemApplicationPage.history.isGetRecordsPending,
      records: getApplicationHistoryRecords(state, applicationId),
      filterType: getFilterType(state),
      selectedMembers: getSelectedMembers(state),
      sortingType: getSortingType(state),
      search: getSearchValue(state),
    }),
  );
  const recordsPlaceholder: null[] = new Array(RECORDS_PLACEHOLDER_DEFAULT_LENGTH).fill(null);

  useStateReset(ApplicationHistoryActionType.ResetState);

  useEffect(() => {
    dispatch(getApplicationHistory(applicationId));
  }, []);

  const handleSortingTypeChange = (newSortingType: ApplicationHistorySortingType) =>
    dispatch(changeSortingType(newSortingType));

  const handleSelectedMembersChange = (members: UserInfo[]) => dispatch(changeSelectedMembers(members));

  const handleFilterTypeChange = (newFilterType: ApplicationHistoryFilterType) =>
    dispatch(changeFilterType(newFilterType));

  const handleSearchClear = () => dispatch(changeSearchValue(''));

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => dispatch(changeSearchValue(event.target.value));

  return (
    <div>
      <Header
        selectedMembers={selectedMembers}
        members={organizationMembers}
        sortingType={sortingType}
        filterType={filterType}
        search={search}
        onSortingTypeChange={handleSortingTypeChange}
        onSelectedMembersChange={handleSelectedMembersChange}
        onFilterTypeChange={handleFilterTypeChange}
        onSearchChange={handleSearchChange}
        onSearchClear={handleSearchClear}
      />
      <HistoryList historyRecords={isGetRecordsPending ? recordsPlaceholder : (records ?? [])} search={search} />
    </div>
  );
};

export default HistoryTab;
