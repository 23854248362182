import React, { FC, ReactNode, useState } from 'react';
import { DragDropContext, DragStart, DropResult, Droppable } from 'react-beautiful-dnd';

interface DocumentFoldersTableDndContextProps {
  children: (currentDraggableId: string | null) => ReactNode;
  onFoldersCombine: (id: string, parentId: string) => void;
}

const DOCUMENT_FOLDERS_DROPPABLE_ID = 'documentFolders';

const DocumentFoldersTableDndContext: FC<DocumentFoldersTableDndContextProps> = ({ onFoldersCombine, children }) => {
  const [currentDraggableId, setCurrentDraggableId] = useState<string | null>(null);

  const handleDragStart = (initial: DragStart) => {
    setCurrentDraggableId(initial.draggableId);
  };

  const handleDragEnd = ({ draggableId, combine }: DropResult) => {
    setCurrentDraggableId(null);

    if (combine) {
      onFoldersCombine(draggableId, combine.draggableId);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Droppable droppableId={DOCUMENT_FOLDERS_DROPPABLE_ID} isCombineEnabled>
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            {children(currentDraggableId)}

            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DocumentFoldersTableDndContext;
