import React from 'react';
import { SkeletonInput } from 'components/Skeleton';
import styles from './FormSkeleton.module.scss';

const FIELDS_COUNT = 9;
const DEFAULT_COLUMNS_COUNT = 2;

export interface FormSkeletonProps {
  fieldsCount?: number;
  columns?: number;
}

const FormSkeleton = ({ fieldsCount = FIELDS_COUNT, columns = DEFAULT_COLUMNS_COUNT }: FormSkeletonProps) => {
  const renderSkeletonFields = (length: number) => {
    return new Array(length).fill(null).map((value, index) => <SkeletonInput key={index} />);
  };

  const containerStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  return <div style={containerStyle} className={styles.container}>{renderSkeletonFields(fieldsCount)}</div>;
};

export default FormSkeleton;
