import React, { FC, LabelHTMLAttributes } from 'react';
import clsx from 'clsx';
import QuestionIcon from 'components/QuestionIcon';
import styles from './Label.module.scss';

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  id?: string;
  required?: boolean;
  className?: string;
  tooltip?: React.ReactNode;
}

const Label: FC<LabelProps> = ({
  children,
  required,
  className,
  tooltip,
  ...restProps
}) => (
  <label {...restProps} className={clsx(styles.label, className)}>
    {children}
    {required && <span className={styles.requiredSymbol}>*</span>}
    {tooltip && <QuestionIcon className={styles.questionIcon} tooltip={tooltip} />}
  </label>
);

export default Label;
