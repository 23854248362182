import { useMemo } from 'react';
import useVariablesSuggestions from 'hooks/useVariablesSuggestions';

const useCodeEditorVariablesGlobals = () => {
  const variablesSuggestions = useVariablesSuggestions();

  return useMemo(() => {
    return variablesSuggestions ? variablesSuggestions.map(({ systemName }) => systemName) : [];
  }, [variablesSuggestions]);
};

export default useCodeEditorVariablesGlobals;
