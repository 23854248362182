import React, { useState } from 'react';
import styles from './ProductsFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemProducts/Filters/ActionCreator';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import TagLine from 'components/LoanOriginationSystem/ApplicationLabels/TagLine';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { capitalize } from 'lodash';

interface ProductsFilterProps {
  statuses: ProductStatus[];
  borrowerType: BorrowerType | null;
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  dueUpdatedDateFrom: Date | null;
  dueUpdatedDateTo: Date | null;
}

interface ProductDropdownItem {
  id: ProductStatus;
  name: string;
  color: string;
}

const getProductStatusColor = (status: ProductStatus) => {
  switch (status) {
    case ProductStatus.Active:
      return styles.activeProductStatusColor;
    case ProductStatus.Draft:
      return styles.draftProductStatusColor;
    default:
      return styles.inactiveProductStatusColor;
  }
};

const productStatuses = [
  {
    id: ProductStatus.Active,
    name: 'Active',
    color: getProductStatusColor(ProductStatus.Active),
  },
  {
    id: ProductStatus.Draft,
    name: 'Draft',
    color: getProductStatusColor(ProductStatus.Draft),
  },
] as ProductDropdownItem[];

const renderProductStatusTag = (label: ProductDropdownItem) => <TagLine minimized {...label} />;

const borrowerTypeOptions = [
  { name: capitalize(BorrowerType.Person), value: BorrowerType.Person },
  { name: capitalize(BorrowerType.Company), value: BorrowerType.Company },
];

const ProductsFilter = ({
  statuses,
  borrowerType,
  dueCreatedDateFrom,
  dueCreatedDateTo,
  dueUpdatedDateFrom,
  dueUpdatedDateTo,
}: ProductsFilterProps) => {
  const dispatch = useDispatch();

  const dropdownSelectedProductStatuses = statuses.map((status) => ({
    id: status,
    name: status,
    color: getProductStatusColor(status),
  }));

  const [statusSelectProps, selectedStatuses] = useAutoCompletionMultiSelectProps<ProductDropdownItem>(
    productStatuses,
    dropdownSelectedProductStatuses,
    (label) => label.id,
    (label) => label.name,
    renderProductStatusTag,
    renderProductStatusTag,
  );

  const [selectedBorrowerType, setSelectedBorrowerType] = useState(borrowerType);
  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateFrom);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateTo);
  const [fromUpdatedDate, setFromUpdatedDate] = useState(dueUpdatedDateFrom);
  const [toUpdatedDate, setToUpdatedDate] = useState(dueUpdatedDateTo);

  const filtersResult: FiltersResult = {
    statuses: selectedStatuses.map((status) => status.id),
    borrowerType: selectedBorrowerType || null,
    dueCreatedDateRange: {
      from: fromCreatedDate,
      to: toCreatedDate,
    },
    dueUpdatedDateRange: {
      from: fromUpdatedDate,
      to: toUpdatedDate,
    },
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const onBorrowerTypeChange = (newOption: Option) => {
    setSelectedBorrowerType(newOption.value as BorrowerType);
  };

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleOnUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromUpdatedDate(from);
    setToUpdatedDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletionMultiselect
        id="productStatus"
        labelTitle="Product Status"
        isLabelTags {...statusSelectProps}
        containerClassName={styles.multiselectContainer}
      />
      <AutoCompletion
        labelTitle="Borrower Type"
        onChange={onBorrowerTypeChange}
        options={borrowerTypeOptions}
        value={selectedBorrowerType || ''}
      />
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Date Created"
      />
      <DateRangePicker
        from={fromUpdatedDate}
        to={toUpdatedDate}
        onChange={handleOnUpdatedDateRangeChange}
        label="Date Updated"
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default ProductsFilter;
