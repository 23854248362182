import { AnyAction } from 'redux';
import { ApplicationFormConfigurationActionType } from './ActionTypes';

export interface ToggleFormSettingsPopupVisibilityAction extends AnyAction {
  type: ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility;
}

export interface SetFormPageToDeleteAction extends AnyAction {
  type: ApplicationFormConfigurationActionType.SetFormPageToDelete;
  payload: {
    formPage: string | null;
  };
}

export const toggleFormSettingsPopupVisibility = (): ToggleFormSettingsPopupVisibilityAction => ({
  type: ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility,
});

export const setFormPageToDelete = (formPage: string | null): SetFormPageToDeleteAction => ({
  type: ApplicationFormConfigurationActionType.SetFormPageToDelete,
  payload: {
    formPage,
  },
});
