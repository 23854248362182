import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  updateApplicationIntermediaryVariablesFailure,
  updateApplicationIntermediaryVariablesSuccess,
  UpdateApplicationIntermediaryVariablesAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationIntermediaryVariablesMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationIntermediaryVariablesAction>(
    ApplicationPageActionType.UpdateApplicationIntermediaryVariables,
    updateApplicationIntermediaryVariablesSuccess,
    updateApplicationIntermediaryVariablesFailure,
    (action) => {
      return api.updateApplicationIntermediaryVariables(action.payload.applicationId, action.payload.variables);
    },
  );

export default UpdateApplicationIntermediaryVariablesMiddleware;
