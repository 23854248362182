import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { ProductConfigurationDocumentFolder } from 'api/Types';
import DocumentFoldersApiRest, {
  ProductConfigurationDocumentFoldersSortingType,
  CreateProductConfigurationDocumentFolderParams,
  UpdateProductConfigurationDocumentFolderParams,
} from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';
import responseHandlers from 'api/ResponseHandlers';
import { ProductConfigurationDocumentFoldersActionTypes } from './ActionTypes';
import { ProductConfigurationDocumentFoldersPopUpType } from './Types';

const documentFoldersApi = new DocumentFoldersApiRest(responseHandlers);

export const getDocumentFolders = createAsyncThunk<ProductConfigurationDocumentFolder[], string, {}>(
  ProductConfigurationDocumentFoldersActionTypes.GetDocumentFolders,
  (productId) => {
    return documentFoldersApi.find(productId);
  },
);

export const createDocumentFolder = createAsyncThunk<
  ProductConfigurationDocumentFolder,
  CreateProductConfigurationDocumentFolderParams,
  {}
>(ProductConfigurationDocumentFoldersActionTypes.CreateDocumentFolder, (params) => {
  return documentFoldersApi.create(params);
});

export const updateDocumentFolder = createAsyncThunk<
  ProductConfigurationDocumentFolder,
  { folderId: string; folder: UpdateProductConfigurationDocumentFolderParams },
  {}
>(ProductConfigurationDocumentFoldersActionTypes.UpdateDocumentFolder, ({ folderId, folder }) => {
  return documentFoldersApi.update(folderId, folder);
});

export const deleteDocumentFolder = createAsyncThunk<void, string, {}>(
  ProductConfigurationDocumentFoldersActionTypes.DeleteDocumentFolder,
  (folderId) => {
    return documentFoldersApi.delete(folderId);
  },
);

export const openDocumentFolderPopup = createAction<ProductConfigurationDocumentFoldersPopUpType>(
  ProductConfigurationDocumentFoldersActionTypes.OpenDocumentFolderPopup,
);

export const closeDocumentFolderPopup = createAction(
  ProductConfigurationDocumentFoldersActionTypes.CloseDocumentFolderPopup,
);

export const setFolderToUpdate = createAction<string | null>(
  ProductConfigurationDocumentFoldersActionTypes.SetFolderToUpdate,
);

export const setParentFolder = createAction<string | null>(
  ProductConfigurationDocumentFoldersActionTypes.SetParentFolder,
);

export const sortDocumentFolders = createAction<ProductConfigurationDocumentFoldersSortingType>(
  ProductConfigurationDocumentFoldersActionTypes.SortDocumentFolders,
);
