import moment from 'moment';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { DataType } from 'Variables/VariablesTypes';

export type CoercedVariableValue = VariableValue | undefined;

const coerceNumberValue = (value: VariableValue) => {
  const formattedValue = Number(value);

  return !Number.isNaN(formattedValue) ? formattedValue : null;
};

const coerceDateValue = (value: VariableValue) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (!value) {
    return value;
  }

  const formattedValue = moment(value).format('MM/DD/YYYY');

  return formattedValue !== 'Invalid date' ? formattedValue : value;
};

const coerceBooleanValue = (value: VariableValue) => {
  if (typeof value !== 'string') {
    return !!value;
  }

  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

export const coerceValue = (value: VariableValue | undefined | null, dataType: DataType) => {
  try {
    if (typeof value === 'string' && String(value).toLowerCase().trim() === 'null') {
      return null;
    }

    if (value === undefined || value === null) {
      return value;
    }

    switch (dataType) {
      case 'String': {
        return String(value);
      }
      case 'Number': {
        return coerceNumberValue(value);
      }
      case 'Boolean': {
        return coerceBooleanValue(value);
      }
      case 'Date': {
        return coerceDateValue(value);
      }
      default: {
        return value;
      }
    }
  } catch (error) {
    return value;
  }
};
