import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

const getProductId = (state: ReduxState, productId: string) => productId;
const getCardsById = (state: ReduxState) => state.applicationDetailsCards.cardsById;
const getApplicationVariablesConfigurationsById = (state: ReduxState) => state.applicationVariableConfigurations.configurationsById;

export const getApplicationDetailsCardsSelector = createSelector(
  [getProductId, getCardsById, getApplicationVariablesConfigurationsById],
  (productId, cardsById, configurationsById) => {
    const cards = Object.values(cardsById).filter((card) => card.productId === productId);

    if (!cards.length) {
      return null;
    }

    return cards.map((card) => ({
      ...card,
      fields: card.fields.map((fieldId) => configurationsById[fieldId]),
    }));
  },
);
