import APIRest from './APIRest';
import {
  DecisionEngineApi,
  TableViewData,
  PaginationParams,
  StrategiesListItem,
  BranchItemInfo,
  BranchKeys,
  BranchIdentityAttrs,
  UpdateRulesOrderParams,
  UpdateModuleBranchesOrderParams,
  ModuleItem,
  GetBranchOptionsParams,
  BranchIdentityParams,
  BranchRuleType,
  ChangeArchiveStatusParams,
  GetVersionsListParams,
  StrategyGroupVersion,
  StrategyGroupVersionApiData,
  VersionsListApiData,
  GetDecisionStrategyUpdatesParams,
} from './Types';

import { VariableItem } from './VariablesType';
import { Strategy, ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import {
  ApiDecisionStrategyUpdates,
  ResponseWithMessage,
  ApiExternalIntegrationVariable,
  CompleteGetExternalIntegrationRequestData,
  ApiExternalIntegrationVariableModuleInput,
  ExternalIntegrationInputsList,
  CompleteGetExternalIntegrationResponseData,
  InputOption,
  ApiExternalIntegrationVariableModuleOutput,
  ExternalIntegrationOutputsList,
  ApiIntegration,
  ProcessingResponseData,
  ApiIntegrationsList,
  Integration,
  BranchMethod,
  ResponseBranchData,
  OutputData,
} from './DecisionEngineStrategiesType';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/Types';
import { CopyModuleData, CreateModuleData, DuplicateBranchData, ModuleRequestDataType } from 'ModuleInfo/Types';
import { Option } from 'components/SelectInput/SelectInput';
import utils from './utils';
import { CompleteEditExternalIntegrationRequestData } from 'EditExternalIntegration/Types';
import { BranchData } from 'AddBranch/Types';
import { BranchInfo, BranchParams, RuleData } from 'BranchInfo/Types';
import {
  CompleteCalculationScriptData,
  CompleteUploadRuleSetRequestData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  DuplicateRuleRequestData,
} from 'RuleCreate/Types';
import { getModuleType } from 'pages/Strategy/utils';
import { CompletedScoringModelRequestData } from 'ScoringModel/Types';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { CompletedDeleteRuleData } from 'RuleDelete/Types';
import { uniqueId } from 'lodash';
import { UpdateVersionStatusesParams } from 'ApiActivation/ActionCreator';
import getNormalizedStatus from 'utils/getNormalizedStatus';

const REST_API_ENDPOINTS = {
  DECISION_STRATEGIES: '/decision/api/standard_strategies',
  UPLOAD_BULK_VARIABLE: '/decision/api/variables?format=json&unflatten=true&handleupload=true&bulk=true',
  INDIVIDUAL_STRATEGY_PROCESSING: '/simulation/api/individual/run',
  INDIVIDUAL_PROCESSING_RESULTS: '/simulation/api/individual/cases',
  INDIVIDUAL_PROCESSING_CASE: '/simulation/api/individual/results',
  MODULE_DATA: '/decision/moduledata',
  ACTIVATIONS: '/integrations',
  BATCH_STRATEGY_PROCESSING: '/simulation/api/batch/run',
  BATCH_PROCESSING_RESULTS: '/simulation/api/batch/results',
  RULES: '/decision/api/standard_rules',
  BRANCH_INFO: '/decision/api/standard_strategies/modules/branch',
};

const getAddRuleSearchParams = () => {
  const params = new URLSearchParams();
  params.set('format', 'json');
  params.set('method', 'addRule');
  return params;
};

const getBranchKeysParams = (
  { moduleKey, branchIndex }: BranchKeys,
  params = new URLSearchParams(),
): URLSearchParams => {
  params.set('moduleKey', moduleKey);
  params.set('branchIndex', branchIndex.toString());
  return params;
};

class DecisionEngineApiRest extends APIRest implements DecisionEngineApi {
  protected origin = process.env.REACT_APP_DE_API_URL;

  private getQueryParams(params: PaginationParams): URLSearchParams {
    const queryParams = new URLSearchParams();
    queryParams.set('format', 'json');
    queryParams.set('pagenum', params.page.toString());
    queryParams.set('limit', params.perPage.toString());
    queryParams.set('type', 'all');
    return queryParams;
  }

  updateStrategyName(id: string, name: string): Promise<void> {
    const params = new URLSearchParams();
    params.set('format', 'json');

    const requestBody = {
      _id: id,
      name,
    };

    return this.fetch<void>(`${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${id}/name?${params}`, 'PATCH', requestBody);
  }

  updateStrategyDescription(id: string, description: string): Promise<void> {
    const params = new URLSearchParams();
    params.set('format', 'json');

    const requestBody = {
      _id: id,
      description,
    };

    return this.fetch<void>(`${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${id}?${params}`, 'PUT', requestBody);
  }

  uploadBulkVariable(variable: File) {
    const formData = new FormData();
    formData.append('variable', variable);
    return this.fetch<VariableItem>(REST_API_ENDPOINTS.UPLOAD_BULK_VARIABLE, 'POST', formData, {
      contentType: null,
    });
  }

  async getDecisionStrategyUpdates(
    params: GetDecisionStrategyUpdatesParams,
    strategyId: string,
  ): Promise<TableViewData<StrategyUpdateType>> {
    const queryParams = this.getQueryParams(params);
    queryParams.set('sort', params.sortBy);
    queryParams.set('order', params.orderBy);

    const { rows, numItems } = await this.fetch<ApiDecisionStrategyUpdates>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/changelogs?${queryParams}`,
      'GET',
    );

    return {
      items: rows,
      total: numItems,
    };
  }

  deleteDecisionStrategyModule(strategyId: string, moduleLookupName: string): Promise<void> {
    const params = new URLSearchParams();
    params.set('method', 'deleteModule');
    params.set('format', 'json');

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/${moduleLookupName}?${params}`,
      'PUT',
    );
  }

  async updateModulesOrder(strategyId: string, moduleIndices: number[]): Promise<Strategy> {
    const params = new URLSearchParams();
    params.set('method', 'updateModuleOrder');
    const body = {
      updated_formatted_module_run_order: moduleIndices.map((moduleIndex) => ({
        module_index: moduleIndex,
      })),
    };

    return this.fetch<Strategy>(`${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}?${params}`, 'PUT', body, {
      isBlockingRequest: true,
    });
  }

  updateModuleInfo(moduleInfo: ModuleRequestDataType, strategyId: string): Promise<void> {
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', 'editModule');
    const { name, lookupName, currentType, active } = moduleInfo;

    const moduleActiveState = active ? 'on' : undefined;
    const requestBody = {
      active: moduleActiveState,
      display_name: name,
      lookup_name: lookupName,
      type: currentType,
    };
    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/module?${params}`,
      'PUT',
      requestBody,
    );
  }

  createNewModule(moduleData: CreateModuleData): Promise<ResponseWithMessage> {
    const { name, currentType, strategyId, moduleIndex } = moduleData;
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', 'create');

    const body = {
      ...(currentType === ModuleType.ExternalIntegration ? { integration_name: name } : { name }),
      type: currentType,
      moduleIndex,
    };

    return this.fetch<ResponseWithMessage>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/modules?${params}`,
      'PUT',
      body,
    );
  }

  duplicateModule(moduleData: CopyModuleData): Promise<ResponseWithMessage> {
    const { strategyId, moduleParams } = moduleData;

    return this.fetch<ResponseWithMessage>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/module/copy`,
      'PUT',
      moduleParams,
    );
  }

  duplicateBranch(branchData: DuplicateBranchData): Promise<ResponseBranchData> {
    const { strategyId } = branchData;

    return this.fetch<ResponseBranchData>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/modules/branches/duplicate`,
      'PUT',
      branchData,
    );
  }

  createNewBranch(branchData: BranchData): Promise<ResponseWithMessage> {
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', BranchMethod.AddBranch);

    return this.fetch<ResponseWithMessage>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${branchData.strategyId}/modules?${params}`,
      'PUT',
      {
        module_name: branchData.moduleId,
      },
    );
  }

  async copyBranchRules(
    ruleType: BranchRuleType,
    copyToParams: BranchIdentityParams,
    copyFromParams: BranchIdentityParams,
  ): Promise<void> {
    const body = {
      copyToParams,
      copyFromParams,
    };

    await this.fetch<void>(
      `/decision/api/standard_strategies/${copyToParams.strategyId}/modules/branches/${ruleType}/copy`,
      'PUT',
      body,
    );
  }

  async findModule(moduleName: string, abortSignal: AbortSignal): Promise<ModuleItem[]> {
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('name', moduleName);

    const modules = await this.fetch<Omit<ModuleItem, 'id'>[]>(
      `/decision/api/standard_strategies/modules/find?${params}`,
      'GET',
      undefined,
      { abortSignal },
    );

    return modules.map((module) => ({
      ...module,
      id: uniqueId('module'),
    }));
  }

  async getBranchInfo({ strategyId, moduleKey, branchIndex }: BranchIdentityAttrs): Promise<BranchInfo> {
    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('strategyId', strategyId);
    params.set('format', 'json');
    const branchKey = `${moduleKey}_${branchIndex}`;

    const response = await this.fetch<BranchInfo>(`${REST_API_ENDPOINTS.BRANCH_INFO}?${params}`, 'GET');
    return { ...response, branchKey };
  }

  async getBranchOptions({ moduleType, ruleType, calculationMode }: GetBranchOptionsParams): Promise<BranchItemInfo[]> {
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('rule-type', ruleType);
    if (moduleType) {
      params.set('module-type', moduleType);
    }

    if (calculationMode) {
      params.set('calculationMode', calculationMode);
    }

    return this.fetch<BranchItemInfo[]>(`/decision/api/standard_strategies/modules/branches?${params}`);
  }

  async addCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void> {
    const { propertyAttribute, strategyId, moduleKey, branchIndex, comparisonValue, calculationMode } = scriptData;

    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('format', 'json');
    params.set('method', 'addRule');

    const body = {
      multiple_rules: [{
        condition_test: 'EQUAL',
        state_property_attribute: propertyAttribute,
        state_property_attribute_value_comparison_type: calculationMode || 'javascript',
        state_property_attribute_value_comparison: comparisonValue || undefined,
      }],
      rule_type: 'simple',
      type: 'calculations',
    };

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/${moduleKey}/${branchIndex}/createRule?${params}`,
      'PUT',
      body,
    );
  }

  async editCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void> {
    const { propertyAttribute, moduleKey, branchIndex, comparisonValue, id, calculationMode } = scriptData;

    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('format', 'json');
    params.set('method', 'editRule');
    params.set('collection', 'rules');

    const body = {
      multiple_rules: [{
        condition_test: 'EQUAL',
        state_property_attribute: propertyAttribute,
        state_property_attribute_value_comparison_type: calculationMode || 'javascript',
        state_property_attribute_value_comparison: comparisonValue || undefined,
      }],
      rule_type: 'simple',
      type: 'calculations',
      _id: id,
    };

    return this.fetch<void>(`${REST_API_ENDPOINTS.RULES}/${id}?${params}`, 'PUT', body);
  }

  addRule(ruleData: CompleteRuleRequestData): Promise<void> {
    const { strategyId, moduleKey, branchIndex, entityType, ruleType, rulesList, outputsList } = ruleData;
    const params = getAddRuleSearchParams();

    const requestData = {
      id: strategyId,
      rule_type: ruleType,
      type: entityType === 'branch' ? 'population' : getModuleType(moduleKey),
    };

    const requestBodyRules = utils.getFormattedRulesList(rulesList);
    const requestBodyOutputs = utils.getFormattedOutputs(outputsList);

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/${moduleKey}/${branchIndex}/createRule?${params}`,
      'PUT',
      { ...requestData, multiple_rules: requestBodyRules, condition_output: requestBodyOutputs },
    );
  }

  duplicateRule(ruleData: DuplicateRuleRequestData): Promise<RuleData> {
    const { strategyId, moduleKey, branchIndex, ruleType, ruleId } = ruleData;
    return this.fetch<RuleData>(`${REST_API_ENDPOINTS.RULES}/${ruleType}/${ruleId}/duplicate`, 'PUT', {
      strategyId,
      moduleKey,
      branchIndex,
    });
  }

  deleteRule(deleteRuleData: CompletedDeleteRuleData): Promise<void> {
    const { strategyId, moduleKey, branchIndex, ruleIndex, entityType } = deleteRuleData;
    const moduleType = getModuleType(moduleKey);
    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('method', 'deleteRule');
    params.set('conditions', `${entityType === 'branch'}`);
    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/segments/${moduleType}/${ruleIndex}?${params}`,
      'PUT',
    );
  }

  editRule(ruleData: CompleteRuleRequestData): Promise<void> {
    const { moduleKey, entityType, ruleType, rulesList, outputsList, id } = ruleData;
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', 'editRule');
    params.set('collection', 'rules');
    const requestData = {
      _id: id,
      rule_type: ruleType,
      type: entityType === 'branch' ? 'population' : getModuleType(moduleKey),
    };

    const requestBodyRules = utils.getFormattedRulesList(rulesList);
    const requestBodyOutputs = utils.getFormattedOutputs(outputsList);

    return this.fetch<void>(`${REST_API_ENDPOINTS.RULES}/${id}?${params}`, 'PUT', {
      ...requestData,
      multiple_rules: requestBodyRules,
      condition_output: requestBodyOutputs,
    });
  }

  addSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void> {
    const {
      strategyId,
      moduleKey,
      branchIndex,
      sourceVariableId,
      simpleOutputValue,
      simpleOutputValueId,
      simpleOutputType,
    } = simpleOutputData;
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', 'addRule');

    const body = {
      id: strategyId,
      multiple_rules: [{
        state_property_attribute: sourceVariableId,
        state_property_attribute_value_comparison:
          simpleOutputType === ComparisonOperandType.VALUE ? simpleOutputValue : simpleOutputValueId,
        state_property_attribute_value_comparison_type: simpleOutputType
      }],
      rule_type: 'simple',
      type: 'assignments',
    };

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/${moduleKey}/${branchIndex}/createRule?${params}`,
      'PUT',
      body,
    );
  }

  editSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void> {
    const {
      strategyId,
      sourceVariableId,
      simpleOutputValue,
      simpleOutputValueId,
      simpleOutputType,
      id,
    } = simpleOutputData;
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('method', 'editRule');
    params.set('collection', 'rules');

    const body = {
      _id: id,
      id: strategyId,
      multiple_rules: [{
        state_property_attribute: sourceVariableId,
        state_property_attribute_value_comparison:
          simpleOutputType === ComparisonOperandType.VALUE ? simpleOutputValue : simpleOutputValueId,
        state_property_attribute_value_comparison_type: simpleOutputType
      }],
      rule_type: 'simple',
      type: 'assignments',
    };

    return this.fetch<void>(`${REST_API_ENDPOINTS.RULES}/${id}?${params}`, 'PUT', body);
  }

  async getVariablesForExternalIntegration(
    externalIntegrationData: CompleteGetExternalIntegrationRequestData,
  ): Promise<CompleteGetExternalIntegrationResponseData> {
    const { strategyId, moduleId, branchIndex } = externalIntegrationData;
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('type', 'dataintegration');

    const moduleInfo = await this.fetch<ApiExternalIntegrationVariable>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/required_model_variables/${strategyId}?${params}`,
      'GET',
    );

    const { data, numOptions, boolOptions, stringOptions, dateOptions } = moduleInfo;

    const { inputs, outputs } = data.modules[moduleId][branchIndex];

    const inputList: ExternalIntegrationInputsList[] = inputs.map(
      (input: ApiExternalIntegrationVariableModuleInput) => {
        return {
          name: input.display_name,
          type: input.data_type,
          variable: input.input_variable,
          example: input.example,
          inputType: input.input_type === 'variable' ? ComparisonOperandType.VARIABLE : ComparisonOperandType.VALUE,
          description: input.description,
        };
      },
    );

    const outputList: ExternalIntegrationOutputsList[] = outputs.map(
      (output: ApiExternalIntegrationVariableModuleOutput) => {
        return {
          apiName: output.api_name,
          name: output.display_name,
          type: output.data_type,
          variable: output.output_variable,
          example: output.example,
          description: output.description,
        };
      },
    );

    const updateVariablesToCorrectFormat = (variableList: InputOption[]): Option[] => {
      const notEmptyVariables = variableList.filter((variable) => {
        return variable.value !== '';
      });

      return notEmptyVariables.map((variable) => ({
        name: variable.label,
        value: variable.value,
      }));
    };

    return {
      inputList,
      outputList,
      numberVariables: updateVariablesToCorrectFormat(numOptions),
      booleanVariables: updateVariablesToCorrectFormat(boolOptions),
      stringVariables: updateVariablesToCorrectFormat(stringOptions),
      dateVariables: updateVariablesToCorrectFormat(dateOptions),
    };
  }

  async getIntegrationsList(): Promise<Integration[]> {
    const { formoptions } = await this.fetch<ApiIntegrationsList>(REST_API_ENDPOINTS.MODULE_DATA, 'GET');
    const integrationsList = formoptions.dataintegration.map((integration: ApiIntegration) => {
      return {
        name: integration.label,
        value: integration.value,
      };
    });

    return integrationsList;
  }

  updateExternalIntegration(externalIntegrationData: CompleteEditExternalIntegrationRequestData): Promise<void> {
    const { strategyId, moduleKey, inputList, branchIndex } = externalIntegrationData;
    const params = new URLSearchParams();
    params.set('variables', 'required');
    params.set('variable_type', 'input');
    params.set('moduleKey', moduleKey);
    params.set('branchIndex', branchIndex.toString());

    const body = {
      saveRequiredVariables: 'SAVE CHANGES',
    };

    inputList.forEach((input, index) => {
      Object.assign(body, {
        [`variables.${index}.input_type`]: input.inputType.toLowerCase(),
        [`variables.${index}.input_variable`]: input.variable || undefined,
      });
    });

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/edit_integration_variables?${params}`,
      'PUT',
      body,
    );
  }

  async updateBranchInfo({ branchName, strategyId, branchIndex, moduleKey }: BranchParams): Promise<BranchParams> {
    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('method', BranchMethod.EditBranch);

    const body = {
      segment_name: branchName,
    };

    await this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/segments/${moduleKey}/${branchIndex}?${params}`,
      'PUT',
      body,
    );

    return {
      branchName,
      branchIndex,
      strategyId,
      moduleKey,
    };
  }

  async deleteBranch({ branchIndex, strategyId, moduleKey }: BranchParams): Promise<BranchParams> {
    const params = new URLSearchParams();
    params.set('method', BranchMethod.DeleteBranch);

    await this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/segments/${moduleKey}/${branchIndex}?${params}`,
      'PUT',
    );

    return {
      branchIndex,
      strategyId,
      moduleKey,
    };
  }

  uploadRuleSet({
    strategyId,
    moduleKey,
    branchIndex,
    file,
    entityType,
  }: CompleteUploadRuleSetRequestData): Promise<void> {
    const formData = new FormData();
    formData.append('ruleSet', file);
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('unflatten', 'true');
    params.set('handleupload', 'true');
    params.set('bulk', 'true');
    params.set('moduleId', moduleKey);
    params.set('segment', branchIndex.toString());
    if (entityType === 'branch') {
      params.set('type', 'population');
    }

    return this.fetch<void>(`/decision/api/strategies/${strategyId}/rules/upload?${params}`, 'POST', formData, {
      contentType: null,
    });
  }

  async getStrategiesList(): Promise<StrategiesListItem[]> {
    const params = new URLSearchParams();
    params.set('format', 'json');
    params.set('pagination', 'true');
    params.set('type', 'decision');
    const { strategies }: ProcessingResponseData = await this.fetch(
      `${REST_API_ENDPOINTS.INDIVIDUAL_STRATEGY_PROCESSING}?${params}`,
    );

    const strategiesList: StrategiesListItem[] = strategies.map(({ _id, display_name, status }) => ({
      id: _id,
      name: display_name,
      status,
    }));

    return strategiesList;
  }

  updateScoringRuleSegment(data: CompletedScoringModelRequestData) {
    const { branchIndex, moduleKey, strategyId, initialScore, outputVariable } = data;
    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('method', 'editSegment');

    const body = {
      segment_initial_score: initialScore,
      segment_output_variable: outputVariable,
    };

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/segments/scorecard/0?${params}`,
      'PUT',
      body,
    );
  }

  updateApiActivations(params: UpdateVersionStatusesParams): Promise<void> {
    const body = {
      groupName: params.groupName,
      testing: params.testing === 'none' ? null : params.testing,
      active: params.active === 'none' ? null : params.active,
    };

    return this.fetch<void>(`${REST_API_ENDPOINTS.ACTIVATIONS}/activate`, 'POST', body);
  }

  updateRulesOrder(data: UpdateRulesOrderParams) {
    const { moduleKey, branchIndex, strategyId, reorderedRuleIds, ruleType } = data;
    const params = getBranchKeysParams({ moduleKey, branchIndex });
    params.set('method', 'editSegment');

    const dataKeyName = ruleType === 'rules' ? 'updated_ruleset' : 'updated_conditions';
    const ruleSetData = reorderedRuleIds.map((id) => ({ _id: id }));

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/segments/requirements/${branchIndex}?${params}`,
      'PUT',
      {
        [dataKeyName]: ruleSetData,
      },
      { isBlockingRequest: true },
    );
  }

  updateModuleBranchesOrder(data: UpdateModuleBranchesOrderParams) {
    const { moduleKey, strategyId, branchIndices } = data;
    const params = new URLSearchParams();
    params.set('strategyId', strategyId);
    params.set('moduleKey', moduleKey);
    params.set('method', 'updateModuleSegmentsOrder');

    return this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}?${params}`,
      'PUT',
      {
        segmentIndices: branchIndices,
      },
      { isBlockingRequest: true },
    );
  }

  runBatchStrategy(strategyId: string, batchData: File, processName?: string): Promise<void> {
    const params = new URLSearchParams();
    params.set('pagination', 'simulations');
    params.set('upload', 'true');

    const formData = new FormData();
    formData.append('upload_file', batchData);
    formData.append('select_testcases', 'file');
    formData.append('strategy', strategyId);
    formData.append('batch_name', processName!);

    return this.fetch(`${REST_API_ENDPOINTS.BATCH_STRATEGY_PROCESSING}?${params}`, 'POST', formData, {
      contentType: null,
    });
  }

  getStrategyListParams(params: Omit<GetVersionsListParams, 'versionsStatuses' | 'sortingType'>) {
    const urlParams = new URLSearchParams();
    urlParams.set('format', 'json');
    if (params.page) {
      urlParams.set('page', params.page.toString());
    }
    if (params.perPage) {
      urlParams.set('per-page', params.perPage.toString());
    }
    if (params.showArchived) {
      urlParams.set('include-archived', params.showArchived.toString());
    }
    if (params.searchInputValue) {
      urlParams.set('search-name', params.searchInputValue);
    }
    if (params.updaters?.length) {
      urlParams.set('updaters', JSON.stringify(params.updaters));
    }

    return urlParams;
  }

  async archiveStrategies(params: ChangeArchiveStatusParams) {
    return this.fetch<void>('/decision/api/strategies/statuses/archive', 'PATCH', params);
  }

  async restoreStrategies(params: ChangeArchiveStatusParams) {
    return this.fetch<void>('/decision/api/strategies/statuses/restore', 'PATCH', params);
  }

  async getVersionsData(strategyName: string, params: Omit<GetVersionsListParams, 'versionsStatuses' | 'sortingType'>) {
    const urlParams = this.getStrategyListParams(params);
    urlParams.set('strategy-name', strategyName);
    urlParams.set('sort-by', params.sortBy);
    urlParams.set('order-by', params.orderBy);

    const versionsListData = await this.fetch<VersionsListApiData>(
      `/decision/api/strategies/versions?${urlParams}`,
      'GET',
    );

    return {
      ...versionsListData,
      items: versionsListData.items.map(
        (strategyGroupVersion: StrategyGroupVersionApiData): StrategyGroupVersion => {
          return {
            ...strategyGroupVersion,
            status: getNormalizedStatus(strategyGroupVersion.status),
          };
        },
      ),
    };
  }

  async assignOutputVariable(
    strategyId: string,
    outputIndex: number,
    variableId: string,
    moduleName: string,
    branchIndex: number,
  ): Promise<OutputData> {
    const urlParams = new URLSearchParams();
    urlParams.set('variables', 'required');
    urlParams.set('variable_type', 'output');
    urlParams.set('index', outputIndex.toString());
    urlParams.set('updateOne', 'true');

    await this.fetch<void>(
      `${REST_API_ENDPOINTS.DECISION_STRATEGIES}/${strategyId}/edit_output_variables/?${urlParams}`,
      'PUT',
      {
        [`variables.${outputIndex}.output_variable`]: variableId,
        moduleName,
        branchIndex,
        saveRequiredVariables: 'SAVE CHANGES',
      },
    );
    return {
      strategyId,
      moduleName,
    };
  }
}

export default DecisionEngineApiRest;
