import formatMonetaryValue from 'utils/formatMonetaryValue';
import { ChartSettings } from './ReportingDashboardLayout/ReportingDashboardLayout';
import {
  formatXValue,
  formatYValue,
  getTitleWithCurrency,
  formatMonetaryTooltipData,
  formatCountTooltipData,
  formatPercentageTooltipData,
  formatPercentageYValue,
} from './ChartFormatters';
import ReportingDashboardChartName from 'LoanOriginationSystemReporting/ReportingDashboardChartName';

const PerformanceIndicatorsLayout: [ChartSettings, ChartSettings][] = [
  [
    {
      name: ReportingDashboardChartName.ApplicationLoanAverage,
      groupName: 'Loan Size',
      getTitle: getTitleWithCurrency('Application Average'),
      getSecondaryTitle: (value: number, monetaryType?: string) => `${formatMonetaryValue(value, monetaryType)} Total`,
      tooltip: 'The average loan amount of all applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatMonetaryTooltipData,
    },
    {
      name: ReportingDashboardChartName.ApprovedApplicationLoanAverage,
      groupName: 'Loan Size',
      getTitle: getTitleWithCurrency('Loan Average'),
      getSecondaryTitle: (value: number, monetaryType?: string) => `${formatMonetaryValue(value, monetaryType)} Total`,
      tooltip: 'The average loan amount of all approved applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatMonetaryTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.CloseRate,
      groupName: 'Pull-Through',
      getTitle: () => 'Close Rate',
      getSecondaryTitle: (value: number) => `${formatPercentageYValue(value)} Close Rate`,
      tooltip:
        'The ratio of approved applications to total applications for applications that were received during the time period',
      formatXValue,
      formatYValue: formatPercentageYValue,
      formatTooltipData: formatPercentageTooltipData,
    },
    {
      name: ReportingDashboardChartName.RejectionRate,
      groupName: 'Pull-Through',
      getTitle: () => 'Rejection Rate',
      getSecondaryTitle: (value: number) => `${formatPercentageYValue(value)} Reject Rate`,
      tooltip:
        'The ratio of rejected applications to total applications for applications that were received during the time period',
      formatXValue,
      formatYValue: formatPercentageYValue,
      formatTooltipData: formatPercentageTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.AverageDaysToApproval,
      groupName: 'Cycle Time',
      getTitle: () => 'Average Days To Approval',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Days To Approval`,
      tooltip: 'The average time between starting and approving applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
    {
      name: ReportingDashboardChartName.AverageDaysToRejection,
      groupName: 'Cycle Time',
      getTitle: () => 'Average Days To Rejection',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Days To Rejection`,
      tooltip: 'The average time between starting and rejecting applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.ApplicationsPerBorrower,
      groupName: 'Cross Selling',
      getTitle: () => 'Applications Per Borrower',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value === Infinity ? 0 : value)} Per Borrower`,
      tooltip: 'The ratio of total applications to total borrowers as of the end of the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
    {
      name: ReportingDashboardChartName.ApplicationsPerIntermediary,
      groupName: 'Cross Selling',
      getTitle: () => 'Applications Per Intermediary',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value === Infinity ? 0 : value)} Per Intermediary`,
      tooltip: 'The ratio of total applications to total intermediaries as of the end of the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
];

export default PerformanceIndicatorsLayout;
