import React, { FC } from 'react';
import {
  ConditionalDisplayRule,
  ConditionalDisplayRuleBuildParams,
} from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import FormulaConditionalDisplayRuleBuilder from './FormulaConditionalDisplayRuleBuilder';

export interface ConditionalDisplayRuleBuilderProps {
  variableName: string;
  conditionalDisplayRule?: ConditionalDisplayRule | null;
  onSave: (conditionalRule: ConditionalDisplayRuleBuildParams) => void;
  isRuleBuildingInProgress?: boolean;
}

const ConditionalDisplayRuleBuilder: FC<ConditionalDisplayRuleBuilderProps> = ({
  variableName,
  conditionalDisplayRule,
  isRuleBuildingInProgress,
  onSave,
}) => (
  <div>
    <FormulaConditionalDisplayRuleBuilder
      formula={conditionalDisplayRule?.formula}
      variableName={variableName}
      onSave={(formula) => onSave({ formula })}
      isRuleBuildingInProgress={isRuleBuildingInProgress}
    />
  </div>
);

export default ConditionalDisplayRuleBuilder;
