import { RuleOutput } from 'RuleCreate/Types';
import { ConditionOutput } from 'api/DecisionEngineStrategiesType';

const getFormattedOutputs = (outputsList: RuleOutput[]) =>
  outputsList.reduce<Partial<ConditionOutput>[]>((accumulator, output) => {
    const { sourceVariableId, operandValue, operandVariableId, operandType } = output;
    accumulator.push({
      value: (operandType === 'variable' ? operandVariableId : operandValue) as string,
      value_type: operandType,
      variable: sourceVariableId!,
    });

    return accumulator;
  }, []);

export default getFormattedOutputs;
