import { History } from 'history';
import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import { createWebhookEndpoint, deleteWebhookEndpoint, updateWebhookEndpoint, getWebhookEndpoints } from 'WebhookEndpoints/Thunks';
import { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from './Pagination';

const WebhookEndpointsTableUpdatesMiddleware = (history: History) => (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (
    isFulfilled(deleteWebhookEndpoint)(action)
    || isFulfilled(createWebhookEndpoint)(action)
    || isFulfilled(updateWebhookEndpoint)(action)
  ) {
    const { webhookEndpointsTable } = getState();
    dispatch(getWebhookEndpoints({
      filters: { count: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT, offset: 0 },
      sortingType: webhookEndpointsTable.sortingType,
    }) as unknown as AnyAction);
  }

  if (isFulfilled(createWebhookEndpoint)(action)) {
    history.push(`/company-settings/webhooks/endpoints?edit=${action.payload.id}`);
  }

  return result;
}) as Middleware;

export default WebhookEndpointsTableUpdatesMiddleware;
