import AutoCompletion from 'components/AutoCompletion';
import { AutoCompletionAsyncProps } from 'components/AutoCompletion/AutoCompletionAsync';
import React, { FC, useCallback } from 'react';
import { useVariablesApi } from 'providers/ApiServiceProvider';
import { DataType, Variable } from 'Variables/VariablesTypes';
import { Option } from 'components/SelectInput/SelectInput';

export interface VariableSelectorProps extends Omit<AutoCompletionAsyncProps, 'fetchOptions' | 'fetchSelectedOption'> {
  dataType?: string;
  onlyCustom?: boolean;
  getAdditionalOptionAttributes?: (variable: Variable) => Partial<Option>;
}

const MAX_VARIABLES_COUNT = 100;

const VariableSelector: FC<VariableSelectorProps> = (props) => {
  const variablesApi = useVariablesApi();

  const fetchSelectedOption = useCallback(async (value: string) => {
    const selectedVariableData = await variablesApi.findById(value);
    if (!selectedVariableData) {
      return undefined;
    }

    return {
      value: selectedVariableData.id,
      name: selectedVariableData.displayName,
    };
  }, []);

  const fetchDropdownVariables = useCallback(async (search: string, abortSignal: AbortSignal) => {
    const { items } = await variablesApi.getVariables(
      {
        search,
        dataType: (props.dataType as unknown) as DataType,
        onlyCustom: props.onlyCustom,count: MAX_VARIABLES_COUNT,
      },
      undefined,
      abortSignal,
    );

    return items.map((variable) => ({
      value: variable.id,
      name: variable.displayName,
      ...(props.getAdditionalOptionAttributes ? props.getAdditionalOptionAttributes(variable) : {}),
    }));
  }, []);

  return <AutoCompletion {...props} fetchOptions={fetchDropdownVariables} fetchSelectedOption={fetchSelectedOption} />;
};

export default VariableSelector;
