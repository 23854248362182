import { ApplicationDocument, ApplicationDocumentsSortingType } from 'api/LoanOriginationSystem/DocumentsApi';

export type DocumentDownloadingState = 'loading' | 'success' | 'failure';

export enum ApplicationDocumentPopUpType {
  UploadDocument = 'uploadDocument',
  RenameDocument = 'renameDocument',
  DeleteDocument = 'deleteDocument',
  RequestCustomerUpload = 'requestCustomerUpload',
  SendESignature = 'sendESignature',
  CreateFolder = 'createFolder',
  RenameFolder = 'renameFolder',
  DeleteFolder = 'deleteFolder',
}

export interface ApplicationDocumentsState {
  documentsById: Record<string, ApplicationDocument>;
  documentsIdsByApplicationId: Record<string, string[]>;
  parentFolderId: null | string;
  search: string;
  sortingType: ApplicationDocumentsSortingType;
  downloadableDocuments: { [documentId: string]: DocumentDownloadingState };
  documentToUpdateId: null | string;
  isPopupOpen: boolean;
  isGetDocumentsPending: boolean;
  isUpdatePending: boolean;
  popUpType: ApplicationDocumentPopUpType;
}
