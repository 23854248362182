import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { DropResult } from 'react-beautiful-dnd';
import { DuplicateApplicationParams, ViewType } from 'LoanOriginationSystemApplications/Types';
import { ApplicationsSortingType, ApplicationSortingField } from 'api/Types';
import { NullableItems, PaginationProps } from 'pagination';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import styles from './Body.module.scss';
import DndListContext from 'components/DndList/DndListContext';
import ColumnBody from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody';
import ColumnHeader from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnHeader';
import ApplicationsTable from './ApplicationsTable';
import SkeletonColumnHeader from './Column/SkeletonColumnHeader';
import SkeletonColumnBody from './Column/SkeletonColumnBody';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { StandardVariables } from 'Variables/VariablesTypes';
import { groupBy } from 'lodash';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';
import { PermissionGroupId } from 'PermissionGroups/Types';

interface BodyProps {
  isColumnViewDataLoading: boolean;
  viewType: ViewType;
  applications: SimplifiedApplication[] | null;
  tableViewData: NullableItems<SimplifiedApplication>;
  paginationProps: PaginationProps;
  tableViewSortingType: ApplicationsSortingType;
  searchInputValue: string;
  userPermissionGroupId: PermissionGroupId | undefined;
  onApplicationsReorder: (
    applicationId: string,
    statusId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
  onTableViewSort: (field: ApplicationSortingField, ascending: boolean) => void;
  onDeleteApplication: (displayId: string) => void;
  onEditApplication: (id: string) => void;
  onDuplicateApplication: (params: DuplicateApplicationParams) => void;
  applicationStatuses: ApplicationStatus[];
  standardVariables: StandardVariables | null;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  onApplicationUpdatingStateReset: (applicationId: string) => void;
  deleteDisabled: boolean;
}

const COLUMN_SKELETONS = Array.from(
  { length: 5 },
  (item, index): ApplicationStatus => ({
    id: index.toString(),
    name: index.toString(),
    position: 0,
    productId: '',
    permissionGroupsAbleToViewApplicationOnBoard: {},
    permissionGroupsToEditApplication: {},
    permissionGroupsToMoveApplicationIntoStatus: {},
    rules: [],
  }),
);

const Body = React.memo(
  ({
    isColumnViewDataLoading,
    viewType,
    applications,
    tableViewData: tableViewProductData,
    paginationProps,
    tableViewSortingType,
    searchInputValue,
    userPermissionGroupId,
    deleteDisabled,
    onApplicationsReorder,
    onTableViewSort,
    applicationStatuses,
    standardVariables,
    onDeleteApplication,
    applicationUpdatingStatesById,
    onApplicationUpdatingStateReset,
    onEditApplication,
    onDuplicateApplication,
  }: BodyProps) => {
    const columnHeadersRef = useRef<HTMLDivElement>(null);
    const columnBodiesRef = useRef<HTMLDivElement>(null);
    const classNames = clsx(styles.container, viewType === ViewType.Column ? styles.columnView : styles.tableView);

    const statuses = applications && userPermissionGroupId ? applicationStatuses : null;

    // If we unwrap this computation from useMemo(),
    // it will be performed on any property change, not just `applications`.
    const groupedApplicationsByStatus = useMemo(() => {
      return groupBy(applications, 'status.name');
    }, [applications]);
    const currencySymbol = getFormattedLoanAmountCurrency(standardVariables);

    const sortedStatuses = useMemo(() => {
      if (!statuses) {
        return COLUMN_SKELETONS;
      }

      return statuses
        .filter(
          (status) =>
            !userPermissionGroupId ||
            (status.permissionGroupsAbleToViewApplicationOnBoard[userPermissionGroupId] ?? true),
        )
        .sort((firstStatus, secondStatus) => firstStatus.position - secondStatus.position);
    }, [statuses]);

    const onReorder = (result: DropResult) => {
      if (result.destination) {
        onApplicationsReorder(
          result.draggableId,
          result.destination.droppableId,
          result.source.index,
          result.destination.index,
        );
      }
    };

    const renderColumnHeaders = () =>
      sortedStatuses.map((status) => {
        if (!applications || !currencySymbol || isColumnViewDataLoading) {
          return <SkeletonColumnHeader key={status.id} />;
        }

        const applicationByStatus = groupedApplicationsByStatus[status.name] || [];

        return (
          <ColumnHeader
            key={status.id}
            applications={applicationByStatus}
            currencySymbol={currencySymbol}
            name={status.name}
          />
        );
      });

    const renderColumnBodies = () => (
      <DndListContext onReorder={onReorder}>
        {(placeholderProps) => (
          <TableWrapperWithFooter ref={columnBodiesRef} className={styles.columnBodiesWrapper} onScroll={onScroll}>
            <div className={styles.columnBodies}>
              {sortedStatuses.map((status) => {
                if (!applications || !currencySymbol || isColumnViewDataLoading) {
                  return <SkeletonColumnBody key={status.id} />;
                }

                const applicationByStatus = groupedApplicationsByStatus[status.name] || [];

                return (
                  <ColumnBody
                    id={status.id}
                    applications={applicationByStatus}
                    key={status.id}
                    currencySymbol={currencySymbol}
                    placeholderStyle={placeholderProps}
                    searchInputValue={searchInputValue}
                    applicationUpdatingStatesById={applicationUpdatingStatesById}
                    onApplicationUpdatingStateReset={onApplicationUpdatingStateReset}
                    deleteDisabled={deleteDisabled}
                    onDeleteApplication={onDeleteApplication}
                    onEditApplication={onEditApplication}
                    onDuplicateApplication={onDuplicateApplication}
                  />
                );
              })}
            </div>
          </TableWrapperWithFooter>
        )}
      </DndListContext>
    );

    const onScroll = () => {
      if (columnHeadersRef.current && columnBodiesRef.current) {
        columnHeadersRef.current.scrollLeft = columnBodiesRef.current.scrollLeft;
      }
    };

    return (
      <div className={classNames}>
        {viewType === ViewType.Column && (
          <>
            <div className={styles.columnHeaders} ref={columnHeadersRef}>
              {renderColumnHeaders()}
            </div>
            {renderColumnBodies()}
          </>
        )}
        {viewType === ViewType.Table && tableViewProductData && (
          <ApplicationsTable
            currencySymbol={currencySymbol}
            source={tableViewProductData}
            paginationProps={paginationProps}
            onSort={onTableViewSort}
            sortingType={tableViewSortingType}
            deleteDisabled={deleteDisabled}
            onDeleteApplication={onDeleteApplication}
            onEditApplication={onEditApplication}
            standardVariables={standardVariables}
            onDuplicateApplication={onDuplicateApplication}
            searchInputValue={searchInputValue}
          />
        )}
      </div>
    );
  },
);

export default Body;
