import { AnyAction, Middleware } from 'redux';
import { ASSIGN_OUTPUT_VARIABLE, ASSIGN_OUTPUT_VARIABLE_SUCCESS } from './ActionTypes';
import {
  assignOutputVariableSuccess,
  assignOutputVariableError,
  AssignOutputVariableAction,
  getExternalIntegrationVariables,
} from './ActionCreator';
import Fetcher, { RoutineHandler } from 'middlewares/Fetcher';
import { DecisionEngineApi } from 'api/Types';
import notification from 'handlers/notification/notificationActionCreator';

const AssignOutputVariableMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<any, AssignOutputVariableAction>(
    ASSIGN_OUTPUT_VARIABLE,
    assignOutputVariableSuccess,
    assignOutputVariableError,
    async ({ payload }) => {
      await api.assignOutputVariable(
        payload.strategyId,
        payload.outputIndex,
        payload.variableId,
        payload.moduleName,
        payload.branchIndex,
      );
      return payload;
    },
  );

export const onAssignOutputVariableSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === ASSIGN_OUTPUT_VARIABLE_SUCCESS) {
      dispatch(
        getExternalIntegrationVariables({
          strategyId: action.payload.strategyId,
          moduleId: action.payload.moduleName,
          branchIndex: action.payload.branchIndex,
        }),
      );
      notification.createNotification('Changes saved successfully!', 'success', dispatch);
    }
  },
);

export default AssignOutputVariableMiddleware;
