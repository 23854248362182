import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { AssigneeTeamMembersType, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import Stepper from 'components/Stepper';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import notification from 'handlers/notification/notificationActionCreator';
import CreateApplicationForm from 'components/LoanOriginationSystem/CreateApplication/CreateApplicationForm';
import {
  createApplication,
  loadBorrowersSuggestions,
  loadIntermediarySuggestions,
  selectBorrower,
  selectCoBorrower,
  selectIntermediary,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { updateIntermediary } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import {
  availableApplicationFormPages,
  getApplicationFormPageLabel,
  getCoBorrowerPageIndex,
  isApplicationDetailsFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import {
  updateBorrower,
  UpdateBorrowerSuccessActionOrigin,
} from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import { batchUploadApplicationDocuments } from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { getBorrowerProfileCards } from 'BorrowerProfileCards/Thunks';
import { getIntermediaryProfileCards } from 'IntermediaryProfileCards/Thunks';
import { getApplicationDetailsCards } from 'ApplicationDetailsCards/Thunks';
import { getApplicationDocumentConfigurations } from 'ApplicationDocumentConfigurations/Thunks';
import { createGetBorrowerProfileCardsSelector } from 'BorrowerProfileCards/Selectors';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import { getApplicationDetailsCardsSelector } from 'ApplicationDetailsCards/Selectors';
import { getApplicationDocumentConfigurationsSelector } from 'ApplicationDocumentConfigurations/Selectors';
import { ReduxState } from 'types/redux';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import replaceAt from 'utils/replaceAt';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { REQUEST_DELAY_MS } from 'middlewares/Debouncer';
import { setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import useStandardVariables from 'hooks/useStandardVariables';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useProductCalculations from 'hooks/useProductCalculations';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import DocumentUploadFile from 'components/LoanOriginationSystem/CreateApplication/Forms/UploadApplicationDocumentsForm/DocumentUploadFile';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import ApplicationAttribute from 'LoanOriginationSystemApplications/CreateApplication/ApplicationAttribute';
import { CreateApplicationProvideResult } from 'LoanOriginationSystemApplications/CreateApplication/CreateApplicationMiddleware';
import ApplicationContextualViewLayout from './ApplicationContextualViewLayout';
import { getTeamMemberIdsByProduct } from './utils';
import styles from './CreateApplication.module.scss';

interface CreateApplicationProps {
  onClose: () => void;
  product: Product;
  applicationFormPages: string[];
  onCreateSuccess: (createdApplicationDisplayId: string | undefined) => void;
  setDataWasChanged: (changed: boolean) => void;
}

const INITIAL_STEP_INDEX = 0;

const CreateApplication = ({
  onClose,
  onCreateSuccess,
  setDataWasChanged,
  applicationFormPages,
  product,
}: CreateApplicationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const productCalculations = useProductCalculations(product.id);

  const availableCoBorrowerPages = useMemo(() => {
    return applicationFormPages.filter((page) => isCoBorrowerApplicationFormPage(page));
  }, [applicationFormPages]);
  const isDocumentUploadPageAvailable = useMemo(() => {
    return !!applicationFormPages.find((page) => isDocumentUploadFormPage(page));
  }, [applicationFormPages]);

  const [currentStepIndex, setCurrentStepIndex] = useState(INITIAL_STEP_INDEX);

  const [defaultBorrowerType] = product.borrowerTypes;

  const [intermediaryFormData, setIntermediaryFormData] = useState({});
  const [borrowerFormData, setBorrowerFormData] = useState({});
  const [borrowerType, setBorrowerType] = useState(defaultBorrowerType);
  const [documentFiles, setDocumentFiles] = useState<DocumentUploadFile[]>([]);
  const [creatingInProgress, setIsCreatingInProgress] = useState(false);

  const [
    coBorrowersFormData,
    setCoBorrowersFormData,
  ] = useState<Array<FormLayoutData>>(new Array(availableCoBorrowerPages.length).fill({}));
  const [coBorrowersTypes, setCoBorrowersTypes] = useState<Array<BorrowerType>>(new Array(availableCoBorrowerPages.length).fill(defaultBorrowerType));

  const [applicationDetailsFormData, setApplicationDetailsFormData] = useState({});

  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const {
    borrowerSuggestions,
    intermediarySuggestions,
    selectedCoBorrowers,
    selectedBorrower,
    selectedIntermediary,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications.createApplication);

  const standardVariables = useStandardVariables();

  const memoizedGetPersonBorrowerProfileCardsSelector = useMemo(createGetBorrowerProfileCardsSelector, []);
  const memoizedGetCompanyBorrowerProfileCardsSelector = useMemo(createGetBorrowerProfileCardsSelector, []);

  const personBorrowerProfileCards = useSelector((state: ReduxState) => memoizedGetPersonBorrowerProfileCardsSelector(state, BorrowerType.Person));
  const companyBorrowerProfileCards = useSelector((state: ReduxState) => memoizedGetCompanyBorrowerProfileCardsSelector(state, BorrowerType.Company));
  const intermediaryProfileCards = useSelector((state: ReduxState) => getIntermediaryProfileCardsSelector(state));
  const applicationDetailsCards = useSelector((state: ReduxState) => getApplicationDetailsCardsSelector(state, product.id));
  const applicationDocumentsConfigurations = useSelector((state: ReduxState) => getApplicationDocumentConfigurationsSelector(state, product.id));

  const steps = useMemo(() => {
    return [...applicationFormPages].sort((firstApplicationFormPage, secondApplicationFormPage) => {
      return availableApplicationFormPages.indexOf(firstApplicationFormPage) - availableApplicationFormPages.indexOf(secondApplicationFormPage);
    }).map((page) => ({
      label: getApplicationFormPageLabel(page),
      type: page,
    }));
  }, [applicationFormPages]);

  const currentStep = steps[currentStepIndex];

  const currentCoBorrowerIndex = useMemo(() => {
    return isCoBorrowerApplicationFormPage(currentStep.type) ? getCoBorrowerPageIndex(currentStep.type) : -1;
  }, [currentStep.type]);

  useEffect(() => {
    if (isBorrowerApplicationFormPage(currentStep.type) || isCoBorrowerApplicationFormPage(currentStep.type)) {
      dispatch(getBorrowerProfileCards(BorrowerType.Person));
      dispatch(getBorrowerProfileCards(BorrowerType.Company));
    }

    if (isIntermediaryApplicationFormPage(currentStep.type)) {
      dispatch(getIntermediaryProfileCards());
    }

    if (isApplicationDetailsFormPage(currentStep.type)) {
      dispatch(getApplicationDetailsCards(product.id));
    }

    if (isDocumentUploadFormPage(currentStep.type)) {
      dispatch(getApplicationDocumentConfigurations(product.id));
    }
  }, [currentStep]);


  const handleContinue = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const handleStepChange = (stepType: string) => {
    const newStepIndex = steps.findIndex(({ type }) => type === stepType);

    setCurrentStepIndex(newStepIndex);
  };

  const handleUnlockBorrowerClick = (borrower: Borrower) => {
    dispatch(setBorrowerToUnlock(borrower));
  };

  const handleBorrowerSelect = (borrower: Borrower | null) => {
    dispatch(selectBorrower(borrower));
  };

  const handleIntermediarySelect = (intermediary: Intermediary | null) => {
    dispatch(selectIntermediary(intermediary));
  };

  const handleCoBorrowerSelect = (borrower: Borrower | null, index: number) => {
    dispatch(selectCoBorrower(borrower, index));
  };

  const editBorrowerData = async (borrowerToUpdate: Borrower, data: FormLayoutData) => {
    const action = setActionOrigin(
      updateBorrower(borrowerToUpdate.id, data),
      UpdateBorrowerSuccessActionOrigin.CreateApplication,
    );

    await dispatchRoutine(action);
  };

  const handleBorrowerProfileUpdate = async (data: FormLayoutData) => {
    if (!selectedBorrower) {
      return;
    }

    await editBorrowerData(selectedBorrower, data);
  };

  const handleCoBorrowerProfileUpdate = async (index: number, data: FormLayoutData) => {
    const coBorrower = selectedCoBorrowers[index];

    if (!coBorrower) {
      return;
    }

    await editBorrowerData(coBorrower, data);
  };

  const handleIntermediaryProfileUpdate = async (data: FormLayoutData) => {
    if (!selectedIntermediary) {
      return;
    }

    await dispatchRoutine(updateIntermediary(data, selectedIntermediary.id));
  };

  const uploadDocuments = async (applicationId: string) => {
    if (!documentFiles.length) {
      return;
    }

    const params = documentFiles.map((documentFile) => {
      return {
        anchor: documentFile.folderId,
        file: new File([documentFile.actualFile], `${documentFile.configurationName} - ${documentFile.actualFile.name}`)
      };
    });

    await dispatchWithUnwrap(batchUploadApplicationDocuments({ applicationId, params }));
  }

  const handleCreateApplication = async () => {
    setIsCreatingInProgress(true);

    try {
      const shouldAddIntermediary = !!applicationFormPages.find((page) => isIntermediaryApplicationFormPage(page));

      const coBorrowers = new Array(availableCoBorrowerPages.length).fill(null).map((key, index) => {
        const selectedCoBorrower = selectedCoBorrowers[index];

        return selectedCoBorrower ? selectedCoBorrower.id : { type: coBorrowersTypes[index], variables: coBorrowersFormData[index] };
      });

      const intermediary = selectedIntermediary ? selectedIntermediary.id : { variables: intermediaryFormData };

      const result = await dispatchRoutineWithResult(
        createApplication({
          productId: product.id,
          borrower: selectedBorrower ? selectedBorrower.id : { variables: borrowerFormData, type: borrowerType },
          coBorrowers,
          intermediary: shouldAddIntermediary ? intermediary : undefined,
          applicationDetails: applicationDetailsFormData,
        }),
      );

      const application = result[ApplicationAttribute.Application] as CreateApplicationProvideResult;

      if (!application[ApplicationAttribute.ApplicationId]) {
        return;
      }

      try {
        await uploadDocuments(application[ApplicationAttribute.ApplicationId]);
      } catch (err) {
        notification.createNotification(
          'Application was created, however documents upload failed. Please, try to upload documents through documents tab.',
          'success',
          dispatch,
        );
      }

      const createdApplicationId = application[ApplicationAttribute.ApplicationDisplayId] as string | undefined;

      onCreateSuccess(createdApplicationId);
    } finally {
      setIsCreatingInProgress(false);
    }
  };

  const handleFileAdd = (file: DocumentUploadFile) => {
    setDocumentFiles([...documentFiles, file]);
  };

  const handleFileRemove = (configurationId: string) => {
    setDocumentFiles(documentFiles.filter((file) => file.configurationId !== configurationId));
  };

  const handleChangeCoBorrowerType = (type: BorrowerType, index: number) => {
    setCoBorrowersTypes(replaceAt(coBorrowersTypes, index, type));
  };

  const handleLoadBorrowerSuggestions = useMemo(() => {
    return debounce(
      (filter: BorrowerSuggestionFilter) => dispatch(loadBorrowersSuggestions(filter)),
      REQUEST_DELAY_MS,
    );
  }, [dispatch]);

  const handleLoadIntermediarySuggestions = useMemo(() => {
    return debounce(
      (filter: IntermediarySuggestionFilter) => dispatch(loadIntermediarySuggestions(filter)),
      REQUEST_DELAY_MS,
    );
  }, [dispatch]);

  const handleBorrowerFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setBorrowerFormData((previousBorrowerFormData) => ({
      ...previousBorrowerFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleIntermediaryFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setIntermediaryFormData((previousIntermediaryFormData) => ({
      ...previousIntermediaryFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleApplicationDetailsFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setApplicationDetailsFormData((previousApplicationDetailsFormData) => ({
      ...previousApplicationDetailsFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleCoBorrowerFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setCoBorrowersFormData((previousCoBorrowersFormData) => {
      return replaceAt(previousCoBorrowersFormData, currentCoBorrowerIndex, {
        ...previousCoBorrowersFormData[currentCoBorrowerIndex],
        [variableConfiguration.variable.systemName]: value,
      })
    });
  }, [currentCoBorrowerIndex]);

  const isContinueButtonDisabled = () => {
    return !personBorrowerProfileCards || !companyBorrowerProfileCards;
  };

  const shouldDisplayContinueButton = () => {
    if (isBorrowerApplicationFormPage(currentStep.type)) {
      return !selectedBorrower?.locked;
    }

    if (isCoBorrowerApplicationFormPage(currentStep.type)) {
      return !selectedCoBorrowers[getCoBorrowerPageIndex(currentStep.type)]?.locked;
    }

    if (isApplicationDetailsFormPage(currentStep.type)) {
      return isDocumentUploadPageAvailable;
    }

    return !isDocumentUploadFormPage(currentStep.type);
  };

  const renderActions = (
    submitDisabled: boolean,
    areFieldsInvalid: boolean
  ) => {
    if (shouldDisplayContinueButton()) {
      return (
        <WithFieldsValidationButton
          size="form"
          kind="secondary"
          className={styles.continueButton}
          disabled={isContinueButtonDisabled()}
          onClick={handleContinue}
          areFieldsInvalid={areFieldsInvalid}
          key={currentStep.type}
        >
          Continue
        </WithFieldsValidationButton>
      );
    }

    return (
      <WithFieldsValidationButton
        size="form"
        kind="primary"
        disabled={submitDisabled}
        onClick={handleCreateApplication}
        className={styles.createApplicationButton}
        isLoading={creatingInProgress}
        areFieldsInvalid={areFieldsInvalid}
      >
        Create Application
      </WithFieldsValidationButton>
    );
  };

  useEffect(() => {
    setDataWasChanged(
      !!selectedBorrower ||
      Object.values(borrowerFormData).filter((value) => !isEmptyVariableValue(value as VariableValue)).length > 0,
    );
  }, [selectBorrower, borrowerFormData]);

  const renderAfterContent = () => (
    <Stepper
      steps={steps}
      currentStep={currentStep.type}
      wrapperClassName={styles.stepperWrapper}
      className={styles.stepper}
      onStepChange={handleStepChange}
    />
  );

  return (
    <ApplicationContextualViewLayout
      contentClassName={styles.content}
      onClose={onClose}
      teamMembersIds={getTeamMemberIdsByProduct(product, accountDetails?.id)}
      displayRoundRobinUser={product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.RoundRobin}
      hideEditTeamMembersButton
      renderAfterContent={renderAfterContent}
    >
      <div className={styles.productName}>{product.name}</div>
      <div className={styles.formContainer}>
        <CreateApplicationForm
          currentStep={currentStep}
          borrowerType={borrowerType}
          coBorrowersFormData={coBorrowersFormData}
          coBorrowersTypes={coBorrowersTypes}
          personBorrowerProfileCards={personBorrowerProfileCards}
          companyBorrowerProfileCards={companyBorrowerProfileCards}
          borrowerFormData={borrowerFormData}
          intermediaryFormData={intermediaryFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          borrowerSuggestions={borrowerSuggestions}
          intermediarySuggestions={intermediarySuggestions}
          selectedBorrower={selectedBorrower}
          selectedCoBorrowers={selectedCoBorrowers}
          selectedIntermediary={selectedIntermediary}
          intermediaryProfileCards={intermediaryProfileCards}
          standardVariables={standardVariables}
          onChangeBorrowerType={(newBorrowerType) => setBorrowerType(newBorrowerType)}
          onBorrowerFieldChange={handleBorrowerFieldChange}
          onIntermediaryFieldChange={handleIntermediaryFieldChange}
          onApplicationDetailsFieldChange={handleApplicationDetailsFieldChange}
          onChangeCoBorrowerType={handleChangeCoBorrowerType}
          onCoBorrowerFieldChange={handleCoBorrowerFieldChange}
          onBorrowerSelect={handleBorrowerSelect}
          onIntermediarySelect={handleIntermediarySelect}
          onCoBorrowerSelect={handleCoBorrowerSelect}
          onLoadBorrowerSuggestions={handleLoadBorrowerSuggestions}
          onLoadIntermediarySuggestions={handleLoadIntermediarySuggestions}
          onUnlockBorrowerClick={handleUnlockBorrowerClick}
          onBorrowerProfileEdit={handleBorrowerProfileUpdate}
          onCoBorrowerProfileEdit={handleCoBorrowerProfileUpdate}
          onIntermediaryProfileEdit={handleIntermediaryProfileUpdate}
          applicationDetailsCards={applicationDetailsCards}
          availableBorrowerTypes={product.borrowerTypes}
          applicationDocumentConfigurations={applicationDocumentsConfigurations}
          documentFiles={documentFiles}
          onDocumentFileAdd={handleFileAdd}
          onDocumentFileRemove={handleFileRemove}
          renderActions={renderActions}
          productCalculations={productCalculations}
        />
      </div>
    </ApplicationContextualViewLayout>
  );
};

export default CreateApplication;
