import { Middleware } from 'redux';

import OrganizationActionType from './ActionTypes';
import {
  GetOrganizationSeatsAction,
  getOrganizationSeatsFailure,
  getOrganizationSeatsSuccess,
} from './CompanyInformationActionCreator';

import { OrganizationApi, SeatsData } from 'api/Core/OrganizationApi';
import Fetcher from 'middlewares/Fetcher';
import SeatsResults from './SeatsResults';

const GetOrganizationSeatsMiddleware: (api: OrganizationApi) => Middleware = (api) =>
  Fetcher<SeatsData, GetOrganizationSeatsAction, {}, Error, SeatsData>(
    OrganizationActionType.GetSeats,
    getOrganizationSeatsSuccess,
    getOrganizationSeatsFailure,
    () => api.getSeats(),
    SeatsResults.Seats,
    (response) => response,
  );

export default GetOrganizationSeatsMiddleware;
