import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import UserCard from 'components/UserCard';
import styles from './ActivateAccount.module.scss';
import { useAuthProvider } from 'providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import useTimer from 'hooks/useTimer';
import { useHistory } from 'react-router-dom';

const SUPPORT_EMAIL = 'support@digifi.io';
const WAIT_FOR_RESEND_TIME = 60; // sec

type LocationState =
  | {
      waitForResend?: boolean;
    }
  | undefined;

const ActivateAccount: FC = () => {
  const history = useHistory();
  const waitForResend = (history.location.state as LocationState)?.waitForResend || false;
  const { seconds, restart, isRunning } = useTimer(WAIT_FOR_RESEND_TIME, {
    autoStart: waitForResend,
  });
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);

  const subTitle = (
    <>
      <div className={styles.subTitleRow}>
        You must verify your email to activate your account. We sent a verification email to the address you provided.
      </div>
      <div className={styles.subTitleRow}>
        If you’re having issues verifying your email, please be sure to check your junk email folder. If you need
        assistance, contact us at <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</ExternalLink>.
      </div>
    </>
  );

  const handleResendEmailClick = async () => {
    await authProvider.resendVerificationEmail();

    notification.createNotification(
      getMessage(MessageType.ActivationEmailSent, { email: accountDetails?.email }),
      'success',
      dispatch,
    );

    restart();
  };

  return (
    <AuthLayout title="Activate Account" subTitle={subTitle}>
      <div className={styles.container}>
        <div className={styles.label}>Didn't receive email?</div>
        {isRunning && (
          <div className={styles.timer}>
            You can resend the email in <div className={styles.seconds}>{seconds}s</div>
          </div>
        )}
        <ButtonWithLoadingState
          className={styles.resendEmailButton}
          size="form"
          kind="secondary"
          onClick={handleResendEmailClick}
          fullWidth
          disabled={isRunning}
        >
          Resend Email
        </ButtonWithLoadingState>
        {accountDetails && (
          <UserCard
            firstName={accountDetails.firstName}
            lastName={accountDetails.lastName}
            imageId={accountDetails.imageId}
            onLogOutClick={() => authProvider.logout()}
            noMargin
            className={styles.userCard}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default ActivateAccount;
