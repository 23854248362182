import createWorkerHook from 'hooks/createWorkerHook';
import { Worker } from 'threads';
import { RunFormulaResult } from 'services/FormulaService';

const useFormulaWorker = createWorkerHook<{
  runFormula: (formula: string, context: Record<string, unknown>) => RunFormulaResult;
  validateFormulaSyntax: (formula: string) => string[];
}>(() => {
  return new Worker('workers/FormulaWorker');
});

export default useFormulaWorker;
