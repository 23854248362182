import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { NullableItems } from 'pagination';

export const getWebhookEventsById = (state: ReduxState) => state.webhookEvents.webhookEventsById;
export const getWebhookEventsIds = (_: ReduxState, eventIds: NullableItems<string>) => eventIds;

export const createGetWebhookEventsSelector = () => {
  return createSelector(
    [getWebhookEventsById, getWebhookEventsIds],
    (eventsById, eventIds) => {
      return eventIds.map((id) => (id ? eventsById[id] : null));
    },
  );
}
