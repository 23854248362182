import React from 'react';
import clsx from 'clsx';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { FormSkeleton } from 'components/Skeleton';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import { InputWithDataTypeStub } from 'components/InputWithDataType';
import { DEFAULT_VARIABLES_COLUMNS_LENGTH } from 'components/LoanOriginationSystem/VariablesConfiguration/VariablesConfigurationCard';
import styles from './CardLayoutPreview.module.scss';

export interface CardLayoutPreviewProps {
  title?: string;
  className?: string;
  columns?: number;
  configurations: BaseVariableConfiguration[] | null;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  descriptionTooltip?: React.ReactNode;
}

const CardLayoutPreview = ({
  configurations,
  columns = DEFAULT_VARIABLES_COLUMNS_LENGTH,
  className,
  title,
  formatDisplayTitle,
  descriptionTooltip,
}: CardLayoutPreviewProps) => {
  const layout = useFormLayout(configurations, columns);

  const variablesContainerStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  const renderColumnItem = ({ variable, id, required }: BaseVariableConfiguration) => (
    <InputWithDataTypeStub
      key={id}
      className={styles.layoutItem}
      label={formatDisplayTitle ? formatDisplayTitle(variable.systemName, variable.displayName) : variable.displayName}
      required={required}
      disabled
      {...getVisualDataTypeWithAttributes(variable)}
      inputTooltip={descriptionTooltip}
      inputTooltipType="hoverable"
    />
  );

  const renderColumn = (column: Array<BaseVariableConfiguration>, index: number) => {
    return (
      <div className={styles.variablesColumn} key={index}>
        {column.map(renderColumnItem)}
      </div>
    );
  };

  const renderVariablesConfigurationCardBody = () => {
    if (!layout) {
      return <FormSkeleton />;
    }

    return (
      <div style={variablesContainerStyle} className={styles.variablesContainer}>
        {layout.map(renderColumn)}
      </div>
    );
  };

  return (
    <div className={clsx(styles.cardPreview, className)}>
      {title && <h5 className={styles.cardTitle}>{title}</h5>}
      {renderVariablesConfigurationCardBody()}
    </div>
  );
};

export default CardLayoutPreview;
