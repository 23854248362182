import { useMemo } from 'react';
import useVariablesSuggestions from 'hooks/useVariablesSuggestions';

const useCodeEditorVariablesSuggestions = () => {
  const variablesSuggestions = useVariablesSuggestions();

  return useMemo(() => {
    if (!variablesSuggestions) {
      return [];
    }

    return variablesSuggestions.filter(({ isArchived }) => !isArchived).map(({ systemName }) => systemName);
  }, [variablesSuggestions]);
};

export default useCodeEditorVariablesSuggestions;
