import { Strategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import DecisionEngineSystemApi from 'api/DecisionEngine/DecisionEngineSystemApi';
import { GetStrategyListParams, StrategyGroupsApiData, StrategyGroupsData, StrategyGroupVersion } from 'api/Types';
import getNormalizedStatus from 'utils/getNormalizedStatus';

export interface IStrategyGroupsApi {
  createNew(): Promise<Strategy>;
  findGroups(params: GetStrategyListParams): Promise<StrategyGroupsData>;
  findAllGroupNames(): Promise<string[]>;
}

class StrategyGroupsApi extends DecisionEngineSystemApi<Strategy> implements IStrategyGroupsApi {
  protected resourceName = 'strategy-groups';

  public createNew(): Promise<Strategy> {
    return this.createResource<Strategy>({});
  }

  public async findGroups(params: GetStrategyListParams) {
    const urlParams = this.getStrategyListParams(params);

    const strategyGroupsData = await this.fetch<StrategyGroupsApiData>(`/${this.resourceName}?${urlParams}`);

    return {
      ...strategyGroupsData,
      items: strategyGroupsData.items.map((strategyGroup) => {
        return {
          ...strategyGroup,
          versions: strategyGroup.versions.map((strategy): StrategyGroupVersion => {
            const { status, ...attributes } = strategy;

            return {
              ...attributes,
              status: getNormalizedStatus(status),
            };
          }),
        }
      })
    };
  }

  public findAllGroupNames() {
    return this.fetch<string[]>(`/${this.resourceName}/all`);
  }

  public delete(strategyName: string) {
    return this.fetch<void>(`/${this.resourceName}`, 'DELETE', { strategyName });
  }

  private getStrategyListParams(params: GetStrategyListParams) {
    const urlParams = new URLSearchParams();
    urlParams.set('format', 'json');
    if (params.page) {
      urlParams.set('page', params.page.toString());
    }
    if (params.perPage) {
      urlParams.set('per-page', params.perPage.toString());
    }
    if (params.searchInputValue) {
      urlParams.set('search-name', params.searchInputValue);
    }
    if (params.updaters?.length) {
      urlParams.set('updaters', JSON.stringify(params.updaters));
    }
    if (params.versionsStatuses?.length) {
      urlParams.set(
        'versionsStatuses',
        JSON.stringify(params.showArchived ? [...params.versionsStatuses, 'archive'] : params.versionsStatuses),
      );
    }
    if (params.sortingType) {
      urlParams.set('sortField', params.sortingType.field);
      urlParams.set('sortDirection', params.sortingType.ascending ? 'asc' : 'desc');
    }

    return urlParams;
  }
}

export default StrategyGroupsApi;
