import applicationsPagination from 'components/LoanOriginationSystem/ApplicationsDashboard/Pagination';
import { SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

export default (api: SimplifiedApplicationsApi) => {
  return applicationsPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getAll(
      params.selectedProduct!.id,
      {
        searchInputValue: params.search,
        selectedMembers: params.selectedMembers,
        selectedStatusesIds: params.selectedStatusesIds,
        selectedLabels: params.selectedLabels,
        selectedIntermediaries: params.selectedIntermediaries,
        createdDateRange: params.createdDateRange,
        updatedDateRange: params.updatedDateRange,
        pagination: {
          count: params.perPage,
          offset: (params.page - 1) * params.perPage,
        },
      },
      params.sortingType,
    );
  });
};
