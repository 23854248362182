import React, { ChangeEvent, FC } from 'react';
import styles from 'components/CompanyInformationUsers/Title/CompanyUsersTitle.module.scss';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import SliderButton from 'components/StrategyGroups/SliderButton';
import FilterButton from 'components/FilterButton';
import PageName from 'constants/PageName';

interface CompanyUsersTitleProps {
  openAddNewUserContextualView: () => void;
  setSearchValue: (searchValue: string) => void;
  searchInputValue: string;
  showInactive: boolean;
  onChangeShowInactive: (active: boolean) => void;
  togglePopUpFilters: (popUpOpeningStatus: boolean) => void;
  areFiltersChanged: () => boolean;
  areUsersLoading: boolean;
}

const CompanyUsersTitle: FC<CompanyUsersTitleProps> = ({
  openAddNewUserContextualView,
  searchInputValue,
  setSearchValue,
  showInactive,
  onChangeShowInactive,
  togglePopUpFilters,
  areFiltersChanged,
  areUsersLoading,
}) => {
  const handleSearchInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => setSearchValue(target.value);

  return (
    <div className={styles.usersHeader}>
      <div className={styles.title}>
        <h2>{PageName.PlatformUsers}</h2>
        <Button kind="primary" size="default" onClick={openAddNewUserContextualView}>
          Add User
        </Button>
      </div>
      <div className={styles.filters}>
        <SearchInput
          placeholder="Search"
          onClear={() => setSearchValue('')}
          onChange={handleSearchInputChange}
          value={searchInputValue}
          containerClassName={styles.searchInput}
          showLoader={!!searchInputValue && areUsersLoading}
        />
        <SliderButton active={showInactive} onChange={onChangeShowInactive} labelText="Show Inactive" />
        <FilterButton
          onClick={() => togglePopUpFilters(true)}
          containerClassName={styles.filterButton}
          active={areFiltersChanged()}
        />
      </div>
    </div>
  );
};

export default CompanyUsersTitle;
