import { pick } from 'lodash';
import { AnyAction } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import { ApplicationHistoryActionType } from './ActionTypes';
import { ApplicationHistoryState } from './Types';
import { ApplicationHistorySortingType } from 'api/LoanOriginationSystem/ApplicationHistoryApi';

type ApplicationHistoryFiltersToSave = Pick<ApplicationHistoryState, 'selectedMembers' | 'filterType'>;

const getFiltersToSave = (state: ReduxState): ApplicationHistoryFiltersToSave => {
  const { history } = state.loanOriginationSystemApplicationPage;

  return pick(history, ['selectedMembers', 'filterType']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemApplicationPage.history;

  return sortingType;
};

const saveTriggerActions = [
  (action: AnyAction) => action.type.includes(ApplicationHistoryActionType.ChangeFilterType),
  (action: AnyAction) => action.type.includes(ApplicationHistoryActionType.ChangeSelectedMembers),
  (action: AnyAction) => action.type.includes(ApplicationHistoryActionType.ChangeSortingType),
];

const applicationHistoryFilterAndSortingSaver = FilterAndSortingSaver<
  ApplicationHistoryFiltersToSave,
  ApplicationHistorySortingType
>({
  key: 'applicationHistory',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: ApplicationHistoryActionType.ResetState,
});

export default applicationHistoryFilterAndSortingSaver;
