import { AnyAction, MiddlewareAPI } from 'redux';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import ApplicationStatusRulesValidationError from 'errors/ApplicationStatusRulesValidationError';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';

const isChangeApplicationStatusFailureAction = (
  action: AnyAction,
): action is FailedAction<LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed> =>
  action.type === LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed;

const STATUS_RULES_VALIDATION_TIMEOUT = 7000;

export const notifyOnApplicationStatusRulesFail = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (!isChangeApplicationStatusFailureAction(action)) {
    return result;
  }

  const error = action.payload;

  if (error instanceof ApplicationStatusRulesValidationError) {
    const props = {
      applicationId: error.applicationId,
      applicationDisplayId: error.applicationDisplayId,
      applicationName: error.applicationName,
      status: error.initialStatus,
      statusRulesValidationResult: error.statusRulesValidationResult,
    };

    notification.createNotification(
      { componentType: NotificationComponentTypes.ApplicationStatusChangeErrorNotificationComponent, props },
      'error',
      dispatch,
      STATUS_RULES_VALIDATION_TIMEOUT,
    );

    return result;
  }

  notification.createNotification(error.message, 'error', dispatch);

  return result;
};
