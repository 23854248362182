import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  updateApplicationBorrowerVariablesSuccess,
  updateApplicationBorrowerVariablesFailure,
  UpdateApplicationBorrowerVariablesAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationBorrowerVariablesMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationBorrowerVariablesAction>(
    ApplicationPageActionType.UpdateApplicationBorrowerVariables,
    updateApplicationBorrowerVariablesSuccess,
    updateApplicationBorrowerVariablesFailure,
    (action) => {
      return api.updateApplicationBorrowerVariables(action.payload.applicationId, action.payload.borrowerId, action.payload.variables);
    },
  );

export default UpdateApplicationBorrowerVariablesMiddleware;
