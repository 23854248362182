import { scaleBand, scaleLinear } from '@visx/scale';
import { ceil } from 'lodash';
import { ScaleBandNumber, ScaleLinearNumber } from './Types';

type UseYScaleReturnValue = [ScaleBandNumber, ScaleLinearNumber];

const useYScale = (maxY: number, height: number): UseYScaleReturnValue => {
  const newMaxY = maxY === 0 ? 1 : maxY;

  const roundedMaxY = ceil(newMaxY, 2);

  const yScale = scaleBand({
    domain: [0, newMaxY / 2, roundedMaxY],
    range: [height, 0],
    paddingInner: 1,
    paddingOuter: 0,
  });

  const yScaleLinear = scaleLinear({
    domain: [0, roundedMaxY],
    range: [height, 0],
  });

  return [yScale, yScaleLinear];
};

export default useYScale;
