import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  CreateApplicationAction,
  CreateApplicationSuccessAction,
  LoadBorrowersSuggestionsSuccessAction,
  LoadIntermediarySuggestionsSuccessAction,
  ResetSelectedCoBorrowerAction,
  SelectBorrowerAction,
  SelectCoBorrowerAction,
  SelectIntermediaryAction,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { UnlockBorrowerSuccessAction } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import {
  UpdateBorrowerSuccessAction,
} from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import applicationConfig from 'config';
import {
  CREATE_APPLICATION_FAILED,
  CREATE_APPLICATION_SUCCESS,
  LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
  LoanOriginationSystemCreateApplicationActionType,
  SELECT_BORROWER,
  SELECT_CO_BORROWER,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import { LoanOriginationSystemCreateApplicationState } from 'LoanOriginationSystemApplications/CreateApplication/Types';
import { LoanOriginationSystemBorrowerLockType } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionTypes';
import { LoanOriginationSystemEditBorrowerActionType } from 'LoanOriginationSystemBorrowers/EditBorrower/ActionTypes';
import { UpdateIntermediarySuccessAction } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionTypes';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { FailedAction } from 'utils/actions/FailedActionCreator';

export const createApplicationInitialState: LoanOriginationSystemCreateApplicationState = {
  borrowerSuggestions: [],
  intermediarySuggestions: [],
  selectedBorrower: null,
  selectedCoBorrowers: new Array(applicationConfig.coBorrowersCount).fill(null),
  selectedIntermediary: null,
};

const unlockBorrower = (borrower: Borrower | null, borrowerId: string) => {
  if (!borrower) {
    return null;
  }

  return {
    ...borrower,
    locked: borrower.id === borrowerId ? false : borrower.locked,
  };
};

const unlockCoBorrowers = (selectedCoBorrowers: Array<Borrower | null>, borrowerId: string) => {
  return selectedCoBorrowers.map((borrower) => {
    return borrower?.id === borrowerId ? { ...borrower, locked: false } : borrower;
  });
};

export const getUpdatedBorrower = (borrower: Borrower | null, newBorrower: Borrower) => {
  if (!borrower) {
    return null;
  }

  return borrower.id === newBorrower.id ? newBorrower : borrower;
};

export const getUpdatedCoBorrowers = (selectedCoBorrowers: Array<Borrower | null>, newBorrower: Borrower) => {
  return selectedCoBorrowers.map((borrower) => borrower?.id === newBorrower.id ? newBorrower : borrower);
};

export const getUpdatedIntermediary = (intermediary: Intermediary | null, newIntermediary: Intermediary) => {
  if (!intermediary) {
    return null;
  }

  return intermediary.id === newIntermediary.id ? newIntermediary : intermediary;
};

type LoanOriginationSystemCreateApplicationsActions =
  | LoadBorrowersSuggestionsSuccessAction
  | LoadIntermediarySuggestionsSuccessAction
  | SelectBorrowerAction
  | SelectCoBorrowerAction
  | CreateApplicationAction
  | CreateApplicationSuccessAction
  | UpdateBorrowerSuccessAction
  | UnlockBorrowerSuccessAction
  | SelectIntermediaryAction
  | UpdateIntermediarySuccessAction
  | ResetSelectedCoBorrowerAction
  | FailedAction<typeof CREATE_APPLICATION_FAILED>;

export const loanOriginationSystemCreateApplicationReducer = (
  state: LoanOriginationSystemCreateApplicationState = createApplicationInitialState,
  action: LoanOriginationSystemCreateApplicationsActions,
): LoanOriginationSystemCreateApplicationState => {
  switch (action.type) {
    case LOAD_BORROWERS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        borrowerSuggestions: action.payload.suggestions,
      };
    case LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess:
      return {
        ...state,
        intermediarySuggestions: action.payload.suggestions,
      };
    case LoanOriginationSystemBorrowerLockType.UnlockBorrowerSuccess:
      return {
        ...state,
        selectedBorrower: unlockBorrower(state.selectedBorrower, action.payload.borrower.id),
        selectedCoBorrowers: unlockCoBorrowers(state.selectedCoBorrowers, action.payload.borrower.id),
      };
    case SELECT_BORROWER: {
      return {
        ...state,
        selectedBorrower: action.payload.borrower,
      };
    }
    case SELECT_CO_BORROWER: {
      const newSelectedCoBorrowers = [...state.selectedCoBorrowers];

      newSelectedCoBorrowers[action.payload.index] = action.payload.borrower;

      return {
        ...state,
        selectedCoBorrowers: newSelectedCoBorrowers,
      };
    }
    case LoanOriginationSystemCreateApplicationActionType.SelectIntermediary:
      return {
        ...state,
        selectedIntermediary: action.payload.intermediary,
      };
    case CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        selectedBorrower: null,
        selectedCoBorrowers: [],
        borrowerSuggestions: [],
      };
    case LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess: {
      return {
        ...state,
        selectedBorrower: getUpdatedBorrower(state.selectedBorrower, action.payload),
        selectedCoBorrowers: getUpdatedCoBorrowers(state.selectedCoBorrowers, action.payload),
      };
    }
    case LoanOriginationSystemCreateApplicationActionType.ResetSelectedCoBorrower: {
      return {
        ...state,
        selectedCoBorrowers: new Array(applicationConfig.coBorrowersCount).fill(null),
      };
    }
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess:
      return {
        ...state,
        selectedIntermediary: getUpdatedIntermediary(state.selectedIntermediary, action.payload),
      };
    default:
      return state;
  }
};
