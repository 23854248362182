import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { CloseButtonImage, LockedImage, UnlockedImage } from 'static/images';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { TabSwitchOption } from 'components/TabSwitch';
import {
  updateBorrower,
  updateBorrowerApplications,
  UpdateBorrowerSuccessActionOrigin,
} from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import useConfirmChanges from 'hooks/useConfirmChanges';
import { getBorrower } from 'LoanOriginationSystemBorrowerDetails/ActionCreator';
import { setBorrowerToLock, setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import BorrowerApplications from './Tabs/BorrowerApplications';
import { getMailtoURI } from 'Email/utils';
import BorrowerEmails from './Tabs/BorrowerEmails';
import { selectBorrowerViewTab, setBorrowerToDelete } from 'LoanOriginationSystemBorrowers/ActionCreator';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useBlockingRequest from 'hooks/useBlockingRequest';
import { getOrganizationEmail } from 'utils/emailGetters';
import ProductSectionAccessRestricted from 'ProductSectionAccessRestricted/ProductSectionAccessRestricted';
import { ProductSection } from 'ProductSectionAccessPermissions/Types';
import ProfileHeader from 'components/LoanOriginationSystem/ProfileHeader';
import UpdateExistingApplicationsPopup from 'components/LoanOriginationSystem/UpdateExistingApplicationsPopup';
import EditBorrowerForm from 'components/LoanOriginationSystem/EditBorrowerForm';
import { RowAction } from 'components/RowActions/RowActions';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import { getConfigurableFormDataDifference, isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { useSimplifiedApplicationsApi } from 'providers/ApiServiceProvider';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import styles from './BorrowerDetails.module.scss';

export interface BorrowerDetailsProps {
  id: string;
  onClose: () => void;
}

interface UpdateExistingApplicationsData {
  borrowerId: string;
  applicationsCount: number;
  data: FormLayoutData;
}

export enum BorrowerDetailsTab {
  Profile = 'Profile',
  Applications = 'Applications',
  Emails = 'Emails',
}

const TABS: TabSwitchOption[] = [
  { label: BorrowerDetailsTab.Profile, id: BorrowerDetailsTab.Profile },
  { label: BorrowerDetailsTab.Applications, id: BorrowerDetailsTab.Applications },
  { label: BorrowerDetailsTab.Emails, id: BorrowerDetailsTab.Emails },
];

const BorrowerDetails = ({ id, onClose }: BorrowerDetailsProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const simplifiedApplicationsApi = useSimplifiedApplicationsApi();

  const { contextualViewTab } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);
  const { borrower } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowerDetails);

  const [borrowerData, setBorrowerData] = useState({});
  const [isBorrowerFormInEditMode, setIsBorrowerFormInEditMode] = useState(false);

  const [isBorrowerUpdateInProgress, useUpdateBorrowerCallback] = useBlockingRequest();
  const [isUpdateBorrowerApplicationsInProgress, useUpdateBorrowerApplicationsCallback] = useBlockingRequest();

  const [
    displayConfirmUpdateBorrowerApplications,
    resetConfirmUpdateBorrowerApplications,
    onConfirmUpdateBorrowerApplications,
    useConfirmUpdateBorrowerApplicationsCallback,
    updateBorrowerApplicationsData,
  ] = useConfirmChanges<UpdateExistingApplicationsData>();

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => {
    return !!borrower?.variables && isConfigurableFormDataChanged(borrower.variables, borrowerData);
  });

  const organizationInfo = useOrganizationDetailedInfo();
  const organizationEmail = getOrganizationEmail(organizationInfo.id);

  useEffect(() => {
    if (!borrower) {
      dispatch(getBorrower(id));

      return;
    }

    setBorrowerData(borrower.variables);
  }, [borrower]);

  const selectTab = (tab: BorrowerDetailsTab) => {
    dispatch(selectBorrowerViewTab(tab));
  };

  useEffect(() => {
    return () => {
      selectTab(BorrowerDetailsTab.Profile);
    };
  }, []);

  const getBorrowerApplicationsCount = async () => {
    try {
      const { total } = await simplifiedApplicationsApi.getBorrowerApplications(id, { count: 1, offset: 0, onlyInProgress: true });

      return total;
    } catch {
      return 0;
    }
  };

  const resetBorrowerForm = () => {
    if (borrower) {
      setBorrowerData(borrower.variables);
    }
  };

  const handleUpdateBorrowerApplications = useConfirmUpdateBorrowerApplicationsCallback(
    useUpdateBorrowerApplicationsCallback(async (updateExistingApplicationsData: UpdateExistingApplicationsData) => {
      await dispatchRoutine(updateBorrowerApplications(
        updateExistingApplicationsData.borrowerId,
        updateExistingApplicationsData.data,
      ));

      resetConfirmUpdateBorrowerApplications();
    }),
    (updateExistingApplicationsData) => updateExistingApplicationsData,
  );

  const handleBorrowerEdit = useUpdateBorrowerCallback(async () => {
    if (!borrower) {
      return;
    }

    const data = getConfigurableFormDataDifference(borrower.variables, borrowerData);

    const action = setActionOrigin(updateBorrower(id, data), UpdateBorrowerSuccessActionOrigin.BorrowerDetails);

    const [applicationsCount] = await Promise.all([getBorrowerApplicationsCount(), dispatchRoutine(action)]);

    if (applicationsCount) {
      handleUpdateBorrowerApplications({
        borrowerId: borrower.id,
        applicationsCount,
        data,
      });
    }

    setIsBorrowerFormInEditMode(false);
  });

  const handleBorrowerUnlock = () => {
    if (!borrower) {
      return;
    }

    dispatch(setBorrowerToUnlock(borrower));
  };

  const handleBorrowerLock = () => {
    if (!borrower) {
      return;
    }

    dispatch(setBorrowerToLock(borrower));
  };

  const handleBorrowerDelete = () => {
    if (!borrower) {
      return;
    }

    dispatch(setBorrowerToDelete(borrower));
  };

  const handleBorrowerDataFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setBorrowerData((previousBorrowerData) => ({
      ...previousBorrowerData,
      [variableConfiguration.variable.systemName]: value,
    }))
  }, []);

  const handleClose = useCallbackWithUnsavedChanges(() => {
    onClose();
  });

  const handleSwitchBorrowerFormEditMode = useCallbackWithUnsavedChanges(() => {
    resetBorrowerForm();
    setIsBorrowerFormInEditMode(!isBorrowerFormInEditMode);
  });

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: borrower!.variables.borrower_email ? borrower!.variables.borrower_email.toString() : '',
      cc: organizationEmail,
    });
  };

  const handlePhoneCall = () => {
    window.location.href = `tel:${borrower!.variables.borrower_phone}`;
  };

  const renderProfileSubtitleIcons = () => {
    if (!borrower) {
      return null;
    }

    return (
      <>
        {borrower.locked ? <LockedImage /> : <UnlockedImage />}
      </>
    );
  };

  const renderHeader = () => {
    const profileData = borrower ? {
      name: getBorrowerFullName(borrower.type, borrower.variables),
      email: borrower.variables[BorrowerDefaultVariable.Email] as string,
      phone: borrower.variables[BorrowerDefaultVariable.PhoneNumber] as string,
      updatedBy: borrower.updatedBy,
      updatedAt: borrower.updatedAt,
    } : null;

    const actions: RowAction[] = borrower ? [{
      title: borrower.locked ? 'Unlock Borrower' : 'Lock Borrower',
      handler: borrower.locked ? handleBorrowerUnlock : handleBorrowerLock,
      separatorRequired: true,
    }, {
      title: 'Delete Borrower',
      danger: true,
      handler: handleBorrowerDelete,
    }] : [];

    return (
      <ProfileHeader
        profileData={profileData}
        onPhoneCall={handlePhoneCall}
        onSendEmail={handleSendEmail}
        actions={actions}
        tabs={TABS}
        selectedTabId={contextualViewTab}
        onSelectTab={({ id: optionId }) => selectTab(optionId as BorrowerDetailsTab)}
        subtitleIcons={renderProfileSubtitleIcons()}
      />
    );
  };

  const renderTab = () => {
    if (contextualViewTab === BorrowerDetailsTab.Profile) {
      return (
        <EditBorrowerForm
          className={styles.editBorrowerForm}
          borrower={borrower}
          borrowerFormData={borrowerData}
          onFieldChange={handleBorrowerDataFieldChange}
          onBorrowerUpdate={handleBorrowerEdit}
          isEditMode={isBorrowerFormInEditMode}
          onSwitchEditMode={handleSwitchBorrowerFormEditMode}
          isUpdating={isBorrowerUpdateInProgress}
        />
      );
    }

    if (contextualViewTab === BorrowerDetailsTab.Applications) {
      return (
        <ProductSectionAccessRestricted productSection={ProductSection.LoanOriginationSystemApplications}>
          <BorrowerApplications borrowerId={id} />
        </ProductSectionAccessRestricted>
      );
    }

    if (contextualViewTab === BorrowerDetailsTab.Emails && borrower) {
      return (
        <ProductSectionAccessRestricted productSection={ProductSection.LoanOriginationSystemEmails}>
          <BorrowerEmails
            borrowerId={id}
            borrowerEmail={borrower!.variables.borrower_email as string}
            organizationEmail={organizationEmail}
          />
        </ProductSectionAccessRestricted>
      );
    }

    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>{renderHeader()}</div>
          <div className={styles.formContainer}>
            {renderTab()}
          </div>
        </div>
        <CloseButtonImage className={styles.closeButton} onClick={handleClose} />
      </div>
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChanges}
        usePortal
      />}
      {displayConfirmUpdateBorrowerApplications && <UpdateExistingApplicationsPopup
        entityName="Borrower"
        applicationsCount={updateBorrowerApplicationsData.applicationsCount}
        onClose={resetConfirmUpdateBorrowerApplications}
        onConfirm={onConfirmUpdateBorrowerApplications}
        isLoading={isUpdateBorrowerApplicationsInProgress}
        usePortal
      />}
    </>
  );
};

export default BorrowerDetails;
