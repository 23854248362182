import React, { FC } from 'react';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { BillingImage, ShareImageCustomColor } from 'static/images';
import styles from './PastDueAlert.module.scss';
import { AppRoutes } from 'routes/RouteService';

interface PastDueAlertProps {
  hideLink: boolean;
}

const PastDueAlert: FC<PastDueAlertProps> = ({ hideLink }) => {
  return (
    <div className={styles.container}>
      <BillingImage className={styles.icon} />
      <div className={styles.message}>
        Your subscription is past due. Please make a payment to avoid losing account access.
      </div>
      {!hideLink && (
        <ExternalLink href={`${process.env.REACT_APP_CORE_API_URL}${AppRoutes.CustomerPortal}`} className={styles.paymentPortalLink}>
          <ShareImageCustomColor className={styles.paymentPortalIcon} />
          <p className={styles.paymentPortalText}>Payment Portal</p>
        </ExternalLink>
      )}
    </div>
  );
};

export default PastDueAlert;
