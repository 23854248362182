import React, { FC, useState, ReactNode } from 'react';
import accepts from 'attr-accept';
import { CloseImage, DocumentIcon } from 'static/images';
import styles from './UploadFile.module.scss';
import clsx from 'clsx';
import { isArray } from 'lodash';

interface UploadFromFileProps {
  file: File | null;
  onFileChange: (file: File | null) => void;
  accept?: string | string[];
  maxFileSize?: number;
  text?: ReactNode;
  closeIcon?: React.ReactNode;
}

export const getFileName = (fileName: string): string =>
  fileName
    .split('.')
    .slice(0, -1)
    .join('.');

export const getFileFormat = (fileName: string): string =>
  fileName
    .split('.')
    .slice(-1)
    .join();

const UploadFile: FC<UploadFromFileProps> = ({
  file,
  onFileChange,
  accept,
  maxFileSize,
  text,
  closeIcon,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);

  const validateFileSize = (fileSize: number) => {
    if (typeof maxFileSize === 'undefined') {
      return true;
    }

    return fileSize <= maxFileSize;
  };

  const validateFile = (newFile: File) => {
    const isAcceptable = !accept || accepts(newFile, accept);

    if (!isAcceptable || !validateFileSize(newFile.size)) {
      setIsError(true);
      return;
    }

    if (isError) {
      setIsError(false);
    }

    onFileChange(newFile);
  };

  const handleDeleteFile = () => onFileChange(null);
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target!.files![0];
    validateFile(newFile);
  };

  const handleFileDrag = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newFile = e.dataTransfer.files[0];
    validateFile(newFile);
    setIsDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDragOver(false);
  };

  const labelClassName = clsx(
    isDragOver ? styles.dragOverInputWrapper : styles.inputWrapper,
    isError && styles.errorInputWrapper,
  );

  const renderCloseImage = () => (
    <div className={styles.fileDeleteContainer} onClick={handleDeleteFile}>
      {closeIcon || <CloseImage className={styles.fileDelete} />}
    </div>
  );

  return (
    <div>
      {file ? (
        <div className={styles.fileInfoContainer}>
          <DocumentIcon className={styles.fileImage} />
          <div className={styles.fileInfo}>
            <span className={styles.fileName}>{getFileName(file.name)}</span>
            <span className={styles.fileFormat}>{getFileFormat(file.name)}</span>
          </div>
          {renderCloseImage()}
        </div>
      ) : (
        <label
          className={labelClassName}
          onDrop={handleFileDrag}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <p className={styles.textContainer}>
            {text || (
              <>
                Drag a file here or <span className={styles.link}> browse </span> for a file to upload.
              </>
            )}
          </p>
          <input
            type="file"
            id="fileInput"
            className={styles.fileInput}
            onChange={handleUploadFile}
            accept={isArray(accept) ? accept.join(',') : accept}
          />
        </label>
      )}
    </div>
  );
};

export default UploadFile;
