import React from 'react';
import { ApplicationDetailsCard } from 'api/LoanOriginationSystem/ApplicationDetailsCardsApi';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { FormLayoutData, VariableValue } from 'api/Types';
import {
  getCoBorrowerPageIndex,
  isApplicationDetailsFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage, isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import { StandardVariables } from 'Variables/VariablesTypes';
import ApplicationDocumentsUploadForm from 'components/LoanOriginationSystem/CreateApplication/Forms/UploadApplicationDocumentsForm';
import DocumentUploadFile from 'components/LoanOriginationSystem/CreateApplication/Forms/UploadApplicationDocumentsForm/DocumentUploadFile';
import {
  CreateBorrowerForm,
  CreateApplicationDetailsForm,
  CreateIntermediaryForm,
} from 'components/LoanOriginationSystem/CreateApplication/Forms';
import { Step } from 'components/Stepper';
import styles from './CreateApplicationForm.module.scss';

interface CreateApplicationFormProps {
  currentStep: Step;
  borrowerFormData: FormLayoutData;
  borrowerType: BorrowerType;
  coBorrowersFormData: Array<FormLayoutData>;
  coBorrowersTypes: Array<BorrowerType>;
  intermediaryFormData: FormLayoutData;
  borrowerSuggestions?: Borrower[];
  intermediarySuggestions?: Intermediary[];
  selectedBorrower?: Borrower | null;
  selectedCoBorrowers?: Array<Borrower | null>;
  selectedIntermediary?: Intermediary | null;
  personBorrowerProfileCards: BorrowerProfileCard[] | null;
  companyBorrowerProfileCards: BorrowerProfileCard[] | null;
  intermediaryProfileCards: Card[] | null;
  applicationDetailsCards: ApplicationDetailsCard[] | null;
  applicationDocumentConfigurations: ApplicationDocumentConfiguration[] | null;
  documentFiles: DocumentUploadFile[];
  onDocumentFileAdd: (file: DocumentUploadFile) => void;
  onDocumentFileRemove: (configurationId: string) => void;
  applicationDetailsFormData: FormLayoutData;
  standardVariables: StandardVariables | null;
  onBorrowerFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onApplicationDetailsFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onBorrowerSelect?: (borrower: Borrower | null) => void;
  onIntermediarySelect?: (intermediary: Intermediary | null) => void;
  onLoadBorrowerSuggestions?: (filter: BorrowerSuggestionFilter) => void;
  onCoBorrowerSelect?: (borrower: Borrower | null, index: number) => void;
  onLoadIntermediarySuggestions?: (filter: IntermediarySuggestionFilter) => void;
  onUnlockBorrowerClick?: (borrower: Borrower) => void;
  onCoBorrowerFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onIntermediaryFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onChangeBorrowerType: (borrowerType: BorrowerType) => void;
  onChangeCoBorrowerType: (coBorrowerType: BorrowerType, index: number) => void;
  onBorrowerProfileEdit: (data: FormLayoutData) => Promise<void>;
  onCoBorrowerProfileEdit: (index: number, data: FormLayoutData) => Promise<void>;
  onIntermediaryProfileEdit: (data: FormLayoutData) => Promise<void>;
  availableBorrowerTypes: BorrowerType[];
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  productCalculations: ProductCalculation[] | null;
}

const CreateApplicationForm = ({
  currentStep,
  borrowerFormData,
  coBorrowersFormData,
  coBorrowersTypes,
  intermediaryFormData,
  borrowerSuggestions = [],
  selectedBorrower = null,
  selectedCoBorrowers = [],
  personBorrowerProfileCards,
  companyBorrowerProfileCards,
  applicationDetailsFormData,
  intermediaryProfileCards,
  standardVariables,
  intermediarySuggestions = [],
  selectedIntermediary = null,
  onBorrowerSelect,
  onLoadBorrowerSuggestions,
  onUnlockBorrowerClick,
  onBorrowerFieldChange,
  onCoBorrowerFieldChange,
  onCoBorrowerSelect,
  onIntermediarySelect,
  onApplicationDetailsFieldChange,
  onIntermediaryFieldChange,
  onLoadIntermediarySuggestions,
  applicationDetailsCards,
  borrowerType,
  onBorrowerProfileEdit,
  onChangeBorrowerType,
  onChangeCoBorrowerType,
  onCoBorrowerProfileEdit,
  onIntermediaryProfileEdit,
  availableBorrowerTypes,
  applicationDocumentConfigurations,
  documentFiles,
  onDocumentFileAdd,
  onDocumentFileRemove,
  renderActions,
  productCalculations,
}: CreateApplicationFormProps) => {
  const createBorrowerFormCommonProps = {
    onUnlockBorrowerClick,
    onLoadSuggestions: onLoadBorrowerSuggestions,
    suggestions: borrowerSuggestions,
    personProfileCards: personBorrowerProfileCards,
    companyProfileCards: companyBorrowerProfileCards,
    standardVariables,
    availableBorrowerTypes,
  };

  const renderForm = () => {
    if (isBorrowerApplicationFormPage(currentStep.type)) {
      return (
        <CreateBorrowerForm
          key={currentStep.type}
          {...createBorrowerFormCommonProps}
          selectedBorrowerType={borrowerType}
          borrowerFormData={borrowerFormData}
          selectedBorrower={selectedBorrower}
          onBorrowerSelect={onBorrowerSelect}
          onFieldChange={onBorrowerFieldChange}
          onChangeBorrowerType={onChangeBorrowerType}
          onEditProfile={onBorrowerProfileEdit}
          renderActions={renderActions}
        />
      );
    }

    if (isCoBorrowerApplicationFormPage(currentStep.type)) {
      const coBorrowerStepIndex = getCoBorrowerPageIndex(currentStep.type);

      return (
        <CreateBorrowerForm
          key={`${currentStep.type}-${coBorrowerStepIndex}`}
          {...createBorrowerFormCommonProps}
          borrowerFormData={coBorrowersFormData[coBorrowerStepIndex]}
          selectedBorrowerType={coBorrowersTypes[coBorrowerStepIndex]}
          suggestions={borrowerSuggestions}
          selectedBorrower={selectedCoBorrowers[coBorrowerStepIndex]}
          onBorrowerSelect={(borrower) => onCoBorrowerSelect?.(borrower, coBorrowerStepIndex)}
          onFieldChange={onCoBorrowerFieldChange}
          onChangeBorrowerType={(type) => onChangeCoBorrowerType(type, coBorrowerStepIndex)}
          onEditProfile={(data) => onCoBorrowerProfileEdit(coBorrowerStepIndex, data)}
          renderActions={renderActions}
        />
      );
    }

    if (isIntermediaryApplicationFormPage(currentStep.type)) {
      return (
        <CreateIntermediaryForm
          selectedIntermediary={selectedIntermediary}
          cards={intermediaryProfileCards}
          onFieldChange={onIntermediaryFieldChange}
          intermediaryFormData={intermediaryFormData}
          standardVariables={standardVariables}
          onLoadSuggestions={onLoadIntermediarySuggestions}
          suggestions={intermediarySuggestions}
          onIntermediarySelect={onIntermediarySelect}
          onEditProfile={onIntermediaryProfileEdit}
          renderActions={renderActions}
        />
      );
    }

    if (isApplicationDetailsFormPage(currentStep.type)) {
      return (
        <CreateApplicationDetailsForm
          cards={applicationDetailsCards}
          applicationDetailsFormData={applicationDetailsFormData}
          onFieldChange={onApplicationDetailsFieldChange}
          borrowerFormData={borrowerFormData}
          intermediaryFormData={intermediaryFormData}
          coBorrowersFormData={coBorrowersFormData}
          selectedBorrower={selectedBorrower}
          selectedIntermediary={selectedIntermediary}
          selectedCoBorrowers={selectedCoBorrowers}
          renderActions={renderActions}
          productCalculations={productCalculations}
        />
      );
    }

    if (isDocumentUploadFormPage(currentStep.type)) {
      return (
        <ApplicationDocumentsUploadForm
          files={documentFiles}
          applicationDocumentConfigurations={applicationDocumentConfigurations}
          onFileAdd={onDocumentFileAdd}
          onFileRemove={onDocumentFileRemove}
          borrowerFormData={borrowerFormData}
          coBorrowersFormData={coBorrowersFormData}
          intermediaryFormData={intermediaryFormData}
          selectedBorrower={selectedBorrower}
          selectedIntermediary={selectedIntermediary}
          selectedCoBorrowers={selectedCoBorrowers}
          applicationDetailsFormData={applicationDetailsFormData}
          renderActions={renderActions}
          productCalculations={productCalculations}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.stepTitle}>{currentStep.label}</h3>
      {renderForm()}
    </div>
  );
};

export default CreateApplicationForm;
