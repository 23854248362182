import React, { useMemo, useState, useCallback } from 'react';
import { BeforeCapture, DropResult } from 'react-beautiful-dnd';
import { BaseDocumentConfiguration } from 'api/LoanOriginationSystem/Base/BaseDocumentConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import DndList from 'components/DndList';
import DraggableUploadFileStub from './DraggableUploadFileStub';
import SkeletonUploadFileStub from './SkeletonUploadFileStub';
import getPosition from 'utils/getPosition';
import sortByPosition from 'utils/sortByPosition';
import ConditionalDisplayRuleBuilderPopup from 'components/ConditionalDisplayRuleBuilderPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import useConfirmChanges from 'hooks/useConfirmChanges';
import useBlockingRequest from 'hooks/useBlockingRequest';
import styles from './DocumentsUploadConfiguration.module.scss';

export interface DocumentsUploadConfigurationProps<DocumentConfigurationType extends BaseDocumentConfiguration>{
  documentConfigurations: DocumentConfigurationType[] | null;
  onReorder: (configuration: DocumentConfigurationType, position: number) => void;
  onEditDocumentConfiguration: (configuration: DocumentConfigurationType) => void;
  onRemoveDocumentConfiguration: (configuration: DocumentConfigurationType) => void;
  onEditDocumentConfigurationConditionalDisplayRule: (
    configuration: DocumentConfigurationType,
    conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null,
  ) => Promise<void>;
  onChangeDocumentConfigurationRequiredAttribute: (configuration: DocumentConfigurationType, required: boolean) => void;
  sortItemsByPosition?: boolean;
  skeletonItemsCount?: number;
}

const DOCUMENTS_UPLOAD_CONFIGURATION_DROPPABLE_ID = 'documentUploadConfiguration';
const DEFAULT_SKELETON_ITEMS_COUNT = 4;
const getDraggableId = (itemId: string) => `item-${itemId}`;

const DocumentsUploadConfiguration = <DocumentConfigurationType extends BaseDocumentConfiguration>({
  onReorder,
  onEditDocumentConfiguration,
  onRemoveDocumentConfiguration,
  onChangeDocumentConfigurationRequiredAttribute,
  documentConfigurations,
  sortItemsByPosition,
  onEditDocumentConfigurationConditionalDisplayRule,
  skeletonItemsCount = DEFAULT_SKELETON_ITEMS_COUNT,
}: DocumentsUploadConfigurationProps<DocumentConfigurationType>) => {
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [
    buildConditionalDisplayRuleFor,
    setBuildConditionalDisplayRuleFor,
  ] = useState<DocumentConfigurationType | null>(null);

  const [isConditionalRuleBuildingInProgress, useBlockingConditionalRuleBuildingCallback] = useBlockingRequest();
  const [isConditionalRuleDeleteInProgress, useBlockingConditionalRuleDeleteCallback] = useBlockingRequest();

  const [
    displayConfirmDeleteConditionalDisplayRulePopup,
    resetDeleteConfirmConditionalRule,
    confirmDeleteConditionalDisplayRule,
    useConfirmDeleteConditionalDisplayRuleCallback,
  ] = useConfirmChanges();

  const sortedItems = useMemo(() => {
    if (sortItemsByPosition && documentConfigurations) {
      return sortByPosition(documentConfigurations);
    }

    return documentConfigurations;
  }, [documentConfigurations, sortItemsByPosition]);

  const draggableItems = useMemo(() => {
    if (!sortedItems) {
      return new Array(skeletonItemsCount).fill(null);
    }

    return sortedItems;
  }, [sortedItems]);

  const handleReorder = (items: DocumentConfigurationType[], result: DropResult) => {
    if (!sortedItems || !result.destination || result.destination.droppableId !== DOCUMENTS_UPLOAD_CONFIGURATION_DROPPABLE_ID) {
      return;
    }

    if (
      result.source.droppableId === result.destination?.droppableId &&
      result.source.index === result.destination?.index
    ) {
      return;
    }

    const configuration = sortedItems[result.source.index];

    const position = getPosition(sortedItems, result.destination.index);

    onReorder(configuration, position);
  };

  const handleDragEnd = () => {
    setDraggingId(null);
  };

  const handleBeforeCapture = (beforeCapture: BeforeCapture) => {
    setDraggingId(beforeCapture.draggableId);
  };

  const handleEditDocumentConfiguration = useCallback((documentConfiguration: DocumentConfigurationType) => {
    onEditDocumentConfiguration(documentConfiguration);
  }, [onEditDocumentConfiguration]);

  const handleRemoveDocumentConfiguration = useCallback((documentConfiguration: DocumentConfigurationType) => {
    onRemoveDocumentConfiguration(documentConfiguration);
  }, [onRemoveDocumentConfiguration]);

  const handleChangeRequiredAttribute = useCallback((documentConfiguration: DocumentConfigurationType) => {
    onChangeDocumentConfigurationRequiredAttribute?.(documentConfiguration, !documentConfiguration.required);
  }, [onChangeDocumentConfigurationRequiredAttribute]);

  const openConditionalDisplayRuleBuilder = useCallback((documentConfiguration: DocumentConfigurationType) => {
    setBuildConditionalDisplayRuleFor(documentConfiguration);
  }, []);

  const handleBuildDocumentConfigurationConditionalDisplayRule = useCallback(
    useBlockingConditionalRuleBuildingCallback(async (conditionalDisplayRule: ConditionalDisplayRuleBuildParams) => {
      if (!buildConditionalDisplayRuleFor) {
        return;
      }

      await onEditDocumentConfigurationConditionalDisplayRule(buildConditionalDisplayRuleFor, conditionalDisplayRule);

      setBuildConditionalDisplayRuleFor(null);
    }),
    [buildConditionalDisplayRuleFor, onEditDocumentConfigurationConditionalDisplayRule],
  );

  const deleteDocumentConfigurationConditionalDisplayRule = useCallback(
    useBlockingConditionalRuleDeleteCallback(async (documentConfiguration: DocumentConfigurationType) => {
      await onEditDocumentConfigurationConditionalDisplayRule(documentConfiguration, null)
    }),
    [onEditDocumentConfigurationConditionalDisplayRule],
  );

  const handleDeleteConditionalDisplayRule = useCallback(
    useConfirmDeleteConditionalDisplayRuleCallback(async (documentConfiguration: DocumentConfigurationType) => {
      await deleteDocumentConfigurationConditionalDisplayRule(documentConfiguration);

      resetDeleteConfirmConditionalRule();
    }),
    [deleteDocumentConfigurationConditionalDisplayRule],
  );

  const renderListItem = (documentConfiguration: DocumentConfigurationType) => {
    return (
      <DraggableUploadFileStub<DocumentConfigurationType>
        documentConfiguration={documentConfiguration}
        isDragging={getDraggableId(documentConfiguration.id) === draggingId}
        key={documentConfiguration.id}
        label={documentConfiguration.name}
        required={documentConfiguration.required}
        onEdit={handleEditDocumentConfiguration}
        onRemove={handleRemoveDocumentConfiguration}
        onChangeRequiredAttribute={handleChangeRequiredAttribute}
        onEditConditionalDisplayRule={openConditionalDisplayRuleBuilder}
        onAddConditionalDisplayRule={openConditionalDisplayRuleBuilder}
        onDeleteConditionalDisplayRule={handleDeleteConditionalDisplayRule}
      />
    );
  };

  const renderSkeletonItem = (index: number) => {
    return (
      <SkeletonUploadFileStub className={styles.skeletonDraggableUploadFileStub} key={index} />
    );
  };

  return (
    <>
      <DndList<DocumentConfigurationType>
        items={draggableItems}
        handleReorder={handleReorder}
        handleDragEnd={handleDragEnd}
        handleBeforeCapture={handleBeforeCapture}
        withPlaceholder
        droppableId={DOCUMENTS_UPLOAD_CONFIGURATION_DROPPABLE_ID}
        renderListItem={renderListItem}
        renderSkeletonItem={renderSkeletonItem}
      />
      {buildConditionalDisplayRuleFor && (
        <ConditionalDisplayRuleBuilderPopup
          variableName={buildConditionalDisplayRuleFor.name}
          conditionalDisplayRule={buildConditionalDisplayRuleFor.conditionalDisplayRule}
          onClose={() => setBuildConditionalDisplayRuleFor(null)}
          onSave={handleBuildDocumentConfigurationConditionalDisplayRule}
          isRuleBuildingInProgress={isConditionalRuleBuildingInProgress}
          usePortal
        />
      )}
      {displayConfirmDeleteConditionalDisplayRulePopup && (
        <ConfirmPopup
          title="Remove Display Condition?"
          message="Are you sure you want to remove display condition for this document?"
          confirmText="Yes, Remove Condition"
          declineText="No, Go Back"
          onPopupClose={resetDeleteConfirmConditionalRule}
          onConfirmClick={confirmDeleteConditionalDisplayRule}
          loading={isConditionalRuleDeleteInProgress}
        />
      )}
    </>
  );
};

export default DocumentsUploadConfiguration;
