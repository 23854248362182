import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { AnyD3Scale } from '@visx/scale';
import React, { FC } from 'react';
import ChartAxisRight from './ChartAxis/ChartAxisRight';
import ChartBottomAxis from './ChartAxis/ChartBottomAxis';
import styles from './components.module.scss';

interface ChartAxisGridProps {
  width: number;
  height: number;
  topPadding?: number;
  leftPadding?: number;
  bottomPadding?: number;
  rightPadding?: number;
  xScale: AnyD3Scale;
  yScale: AnyD3Scale;
  formatXValue?: (value: number) => string;
  formatYValue?: (value: number) => string;
  forceStartXAxisValue?: number;
  forceEndXAxisValue?: number;
}

const GENERAL_VALUE_FORMATTER = (value: number) => value.toString();

const Y_AXIS_LABEL_OFFSET = {
  X: 2,
  Y: -4,
};

const ChartAxisGrid: FC<ChartAxisGridProps> = ({
  width,
  height,
  leftPadding = 0,
  rightPadding = 0,
  topPadding = 0,
  bottomPadding = 0,
  xScale,
  yScale,
  formatXValue = GENERAL_VALUE_FORMATTER,
  formatYValue = GENERAL_VALUE_FORMATTER,
  forceStartXAxisValue,
  forceEndXAxisValue,
}) => {
  const correctedWidth = width - leftPadding - rightPadding;
  const correctedHeight = height - topPadding - bottomPadding;

  return (
    <Group>
      <ChartBottomAxis
        padding={leftPadding}
        y={correctedHeight + topPadding}
        scale={xScale}
        formatValue={formatXValue}
        forceStartXAxisValue={forceStartXAxisValue}
        forceEndXAxisValue={forceEndXAxisValue}
      />
      <GridRows
        top={topPadding}
        scale={yScale}
        width={width}
        tickValues={yScale.domain()}
        stroke={styles.gridLineColor}
        opacity={styles.gridLineOpacity}
        className={styles.axisX}
      />
      <ChartAxisRight
        x={correctedWidth + Y_AXIS_LABEL_OFFSET.X}
        y={topPadding}
        yOffset={Y_AXIS_LABEL_OFFSET.Y}
        scale={yScale}
        formatValue={formatYValue}
      />
    </Group>
  );
};

export default ChartAxisGrid;
