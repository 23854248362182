import { AnyAction } from 'redux';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';
import { setItemsTotal } from 'utils/reducers/setStateField';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import {
  ChangeContextualViewStepAction,
  DeleteBorrowerAction,
  DeleteBorrowerSuccessAction,
  GetBorrowersSuccessAction,
  SelectBorrowerViewTabAction,
  SetBorrowerToDeleteAction,
  SortBorrowersAction,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersState } from './Types';
import { BorrowersSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import borrowersPagination, {
  BORROWERS_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/BorrowersDashboard/pagination';
import {
  initialState as borrowersFiltersInitialState,
  loanOriginationSystemBorrowersFiltersReducer,
} from './Filters/Reducer';
import {
  initialState as createBorrowerInitialState,
  loanOriginationSystemCreateBorrowerReducer,
} from './CreateBorrower/Reducer';
import {
  initialState as borrowerLockInitialState,
  loanOriginationSystemBorrowerLockReducer,
} from './BorrowerLock/Reducer';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './Filters/ActionTypes';
import {
  ChangePopupFiltersAction,
  FilterBorrowersByMembersAction,
  ResetAllBorrowersFilters,
  SetSearchInputValueAction,
} from './Filters/ActionCreator';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';
import borrowersFilterAndSortingSaver from './FilterAndSortingSaver';
import BorrowerHasApplicationsError from 'errors/BorrowerHasApplicationsError';

type LoanOriginationSystemBorrowersType = (
  state: LoanOriginationSystemBorrowersState,
  action: AnyAction,
) => LoanOriginationSystemBorrowersState;

const initialSortingType = {
  field: BorrowersSortingField.UpdatedAt,
  ascending: false,
};

const searchRelevanceSortingType = {
  field: BorrowersSortingField.SearchRelevance,
  ascending: false,
};

const getInitialState = (): LoanOriginationSystemBorrowersState => ({
  items: [],
  page: 1,
  itemsPerPage: BORROWERS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: borrowersFilterAndSortingSaver.getSavedSorting() || initialSortingType,
  searched: {},
  borrowerToDelete: null,
  isDeleting: false,
  filters: { ...borrowersFiltersInitialState, ...borrowersFilterAndSortingSaver.getSavedFilters() },
  createBorrower: createBorrowerInitialState,
  borrowerLock: borrowerLockInitialState,
  contextualViewTab: BorrowerDetailsTab.Profile,
  contextualViewStep: 0,
});

type LoanOriginationSystemBorrowersActions =
  | GetBorrowersSuccessAction
  | SortBorrowersAction
  | DeleteBorrowerAction
  | DeleteBorrowerSuccessAction
  | SetBorrowerToDeleteAction
  | FailedAction<LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure>
  | ChangePopupFiltersAction
  | FilterBorrowersByMembersAction
  | ResetAllBorrowersFilters
  | ChangeContextualViewStepAction
  | SelectBorrowerViewTabAction
  | SetSearchInputValueAction;

const loanOriginationSystemBorrowersReducer = (
  state = getInitialState(),
  action: LoanOriginationSystemBorrowersActions,
): LoanOriginationSystemBorrowersState => {
  switch (action.type) {
    case LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess: {
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: BORROWERS_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || BORROWERS_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SortBorrowers: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        sortingType: action.payload,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrower: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess: {
      return {
        ...state,
        borrowerToDelete: null,
        isDeleting: false,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure: {
      return {
        ...state,
        isDeleting: false,
        borrowerToDelete: (action.payload instanceof BorrowerHasApplicationsError) ? null : state.borrowerToDelete,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDelete: {
      return {
        ...state,
        borrowerToDelete: action.payload.borrower,
      };
    }
    case LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers:
    case LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        filters: loanOriginationSystemBorrowersFiltersReducer(state.filters, action),
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab: {
      const { tab } = action.payload;
      return {
        ...state,
        contextualViewTab: tab,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep: {
      const { step } = action.payload;
      return {
        ...state,
        contextualViewStep: step,
      };
    }
    case LoanOriginationSystemBorrowersFiltersActionTypes.SetSearchInputValue: {
      const { searchValue } = action.payload;
      const prevSearchValue = state.filters.searchInputValue;

      return {
        ...state,
        filters: loanOriginationSystemBorrowersFiltersReducer(state.filters, action),
        // Set sorting by search relevance on search apply and unset sorting on search remove
        ...(prevSearchValue.length === 0 && searchValue.trim().length > 0
          ? { sortingType: searchRelevanceSortingType }
          : null),
        ...(prevSearchValue.length > 0 && searchValue.trim().length === 0 ? { sortingType: initialSortingType } : null),
      };
    }
    default: {
      return {
        ...state,
        filters: loanOriginationSystemBorrowersFiltersReducer(state.filters, action),
        createBorrower: loanOriginationSystemCreateBorrowerReducer(state.createBorrower, action),
        borrowerLock: loanOriginationSystemBorrowerLockReducer(state.borrowerLock, action),
      };
    }
  }
};

export default borrowersPagination.wrapReducer<LoanOriginationSystemBorrowersState>(
  withStateReset(
    loanOriginationSystemBorrowersReducer,
    LoanOriginationSystemBorrowersActionTypes.ResetState,
    getInitialState,
  ) as LoanOriginationSystemBorrowersType,
);
