import React, { useEffect, useCallback } from 'react';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import { useDispatch, useSelector } from 'react-redux';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { ReduxState } from 'types/redux';
import IntermediaryProfileConfigurationHeader from './IntermediaryProfileConfigurationHeader';
import { Variable } from 'Variables/VariablesTypes';
import ViewLayoutConfiguration from 'components/LoanOriginationSystem/ViewLayoutConfiguration';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import {
  createIntermediaryProfileCard,
  deleteIntermediaryProfileCard,
  getIntermediaryProfileCards,
  updateIntermediaryProfileCard,
} from 'IntermediaryProfileCards/Thunks';
import {
  createIntermediaryVariableConfiguration,
  deleteIntermediaryVariableConfiguration,
  updateConditionalDisplayRule,
  updateIntermediaryVariableConfigurationPosition,
  updateIntermediaryVariableConfigurationRequiredAttribute,
} from 'IntermediaryVariableConfigurations/Thunks';

export interface IntermediaryProfileConfigurationProps {
  onDownloadApiTemplate: () => void;
}

const INTERMEDIARY_PREFIX = 'intermediary';

const IntermediaryProfileConfiguration = ({ onDownloadApiTemplate }: IntermediaryProfileConfigurationProps) => {
  const dispatch = useDispatch();
  const cards = useSelector((state: ReduxState) => getIntermediaryProfileCardsSelector(state));

  useEffect(() => {
    dispatch(getIntermediaryProfileCards());
  }, []);

  const handleCardAdd = useCallback(async (position: number, row: number) => {
    await dispatch(createIntermediaryProfileCard({
      position,
      row,
      name: 'New Card',
    }));
  }, []);

  const handleCardNameUpdate = useCallback(async (cardId: string, name: string) => {
    await dispatch(updateIntermediaryProfileCard({
      id: cardId,
      name,
    }));
  }, []);

  const handleCardDelete = useCallback(async (cardId: string) => {
    await dispatch(deleteIntermediaryProfileCard(cardId));
  }, []);

  const handleVariableConfigurationPositionUpdate = useCallback(async (
    variableConfiguration: BaseVariableConfiguration,
    targetCardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => {
    await dispatch(updateIntermediaryVariableConfigurationPosition({
      id: variableConfiguration.id,
      position,
      column,
      sourceCardId,
      cardId: targetCardId,
    }));
  }, []);

  const handleVariableConfigurationAdd = useCallback(async (cardId: string, column: number, position: number, variable: Variable) => {
    await dispatch(createIntermediaryVariableConfiguration({
      cardId,
      position,
      variable: variable.id,
      column,
    }));
  }, []);

  const handleVariableConfigurationDelete = useCallback(async (variableConfiguration: BaseVariableConfiguration) => {
    await dispatch(deleteIntermediaryVariableConfiguration(variableConfiguration.id));
  }, []);

  const handleChangeVariableConfigurationRequiredAttribute = useCallback((variableConfiguration: BaseVariableConfiguration, required: boolean) => {
    dispatch(updateIntermediaryVariableConfigurationRequiredAttribute({
      id: variableConfiguration.id,
      required,
    }));
  }, []);

  const handleEditVariableConfigurationDisplayRule = useCallback(
    async (variableConfiguration: BaseVariableConfiguration, conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null) => {
      await dispatch(updateConditionalDisplayRule({
        id: variableConfiguration.id,
        conditionalDisplayRule,
      }));
    },
    [],
  );

  const getListVariableAttributes = useCallback((variable: Variable) => {
    const isIntermediaryVariable = variable.systemName.startsWith(INTERMEDIARY_PREFIX);

    return {
      disabled: !isIntermediaryVariable,
      tooltip: !isIntermediaryVariable ? (
        <>
          <p>Only variables with system names</p>
          <p>that start with “intermediary” can be</p>
          <p>added to the Intermediary Profile</p>
        </>
      ) : null,
    };
  }, []);

  return (
    <>
      <IntermediaryProfileConfigurationHeader onDownloadApiTemplate={onDownloadApiTemplate} />
      <ViewLayoutConfiguration
        title="Intermediary"
        cards={cards}
        onCardAdd={handleCardAdd}
        onCardNameUpdate={handleCardNameUpdate}
        onCardDelete={handleCardDelete}
        onVariableConfigurationPositionUpdate={handleVariableConfigurationPositionUpdate}
        onVariableConfigurationCreate={handleVariableConfigurationAdd}
        onVariableConfigurationDelete={handleVariableConfigurationDelete}
        onChangeVariableConfigurationRequiredAttribute={handleChangeVariableConfigurationRequiredAttribute}
        onEditVariableConfigurationConditionalDisplayRule={handleEditVariableConfigurationDisplayRule}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        allowStandardVariables
        getListVariableAttributes={getListVariableAttributes}
      />
    </>
  );
};

export default IntermediaryProfileConfiguration;
