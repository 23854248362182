import React, { useMemo } from 'react';
import clsx from 'clsx';
import { getBorrowerMappings, BorrowerMappingsItem } from './BorrowerMappings';
import { AvatarIcon, DuplicateImage } from 'static/images';
import { BLANK_BORROWER_FULL_NAME, getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import {
  getFormattedBorrowerDateOfBirth,
  getFormattedBorrowerIdNumber,
  getFormattedBorrowerPhoneNumber,
  getIdNumberVariableNameByType,
} from 'LoanOriginationSystemOrganization/Utils';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import ApplicationDefaultVariable, { coBorrowerDefaultVariablesByIndex } from 'enums/ApplicationDefaultVariable';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import { getMailtoURI } from 'Email/utils';
import { StandardVariables } from 'Variables/VariablesTypes';
import BorrowerIcon from 'components/LoanOriginationSystem/BorrowerIcon';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';
import WrapperWithTooltip from 'components/Tooltip';
import DuplicatedApplicationTooltip from './DuplicatedApplicationTooltip/DuplicatedApplicationTooltip';
import getOriginalDisplayIdFromDuplicated from 'utils/getOriginalDisplayIdFromDuplicated';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import InfoPanelColumn from './InfoPanelColumn';
import styles from './InfoPanelContent.module.scss';

interface InfoPanelContentProps {
  isOpen: boolean;
  applicationData: Application;
  formatApplicationDate?: (date: Date) => string;
  standardVariables: StandardVariables;
  organizationEmail: string;
}

const MAX_AVATARS_COUNT = 3;
const TEAM_MEMBERS_AVATAR_SIZE = 8.58;

const InfoPanelContent = ({
  isOpen,
  applicationData,
  formatApplicationDate,
  standardVariables,
  organizationEmail,
}: InfoPanelContentProps) => {
  const userPermissionGroupId = useUserPermissionGroupId()!;

  const formatBorrowerValue = (
    mapping: BorrowerMappingsItem,
    application: Application,
    borrowerDefaultVariables: BorrowerDefaultVariableType,
    borrowerType: BorrowerType,
  ) => {
    if (mapping.isDateOfBirth) {
      return getFormattedBorrowerDateOfBirth(
        application.variables[mapping.key],
        standardVariables,
        borrowerDefaultVariables,
      );
    }

    if (mapping.isAddress) {
      const addressFields = [
        application.variables[borrowerDefaultVariables.Street],
        application.variables[borrowerDefaultVariables.City],
        application.variables[borrowerDefaultVariables.State],
        application.variables[borrowerDefaultVariables.Zip],
        application.variables[borrowerDefaultVariables.Country],
      ];

      return addressFields.filter((field) => !!field).join(', ');
    }

    if (mapping.isIdNumber) {
      const idNumberVariableName = getIdNumberVariableNameByType(borrowerType, borrowerDefaultVariables);

      return getFormattedBorrowerIdNumber(
        application.variables[idNumberVariableName],
        standardVariables[idNumberVariableName],
      );
    }

    if (mapping.isPhoneNumber) {
      return (
        getFormattedBorrowerPhoneNumber(
          application.variables[mapping.key],
          userPermissionGroupId,
          standardVariables,
          borrowerDefaultVariables,
        ) || application.variables[mapping.key]
      );
    }

    return application.variables[mapping.key];
  };

  const wrapBorrowerItemContent = (mapping: BorrowerMappingsItem, contentValue: string) => {
    if (mapping.label === 'Email') {
      return <a href={getMailtoURI({ to: contentValue, cc: organizationEmail })}>{contentValue}</a>;
    }

    if (mapping.label === 'Phone') {
      return <a href={`tel:${contentValue}`}>{contentValue}</a>;
    }

    return contentValue;
  };

  const getBorrowerItemOptions = (
    mapping: BorrowerMappingsItem,
    borrowerType: BorrowerType,
    borrowerDefaultVariables = ApplicationDefaultVariable.Borrower,
  ) => {
    const contentValue = formatBorrowerValue(mapping, applicationData, borrowerDefaultVariables, borrowerType);
    const wrappedContentValue = wrapBorrowerItemContent(mapping, contentValue);

    return {
      content: wrappedContentValue,
      useOverflowTextContainer: true,
      tooltipOnHover: contentValue,
    };
  };

  const renderTeamMembersSectionContent = () => {
    return (
      <div className={styles.teamMembersSection}>
        {applicationData.teamMembers?.length > 0 ? (
          <UserAvatarInlineList
            avatarClassName={styles.avatarInUserList}
            source={applicationData.teamMembers}
            size={TEAM_MEMBERS_AVATAR_SIZE}
            maxCount={MAX_AVATARS_COUNT}
          />
        ) : (
          <WrapperWithTooltip tooltip="Unassigned">
            <AvatarIcon />
          </WrapperWithTooltip>
        )}
      </div>
    );
  };

  const originalApplicationDisplayId =
    applicationData.originalApplicationId && getOriginalDisplayIdFromDuplicated(applicationData.displayId);

  const coBorrowerItemsByIndex = useMemo(() => {
    return applicationData.coborrowerIds.map((coBorrowerId, index) => {
      const coBorrowerMapping = getBorrowerMappings(
        coBorrowerDefaultVariablesByIndex[index],
        applicationData.coborrowerTypes[index],
      );

      return coBorrowerMapping.map((mapping) => ({
        label: mapping.label,
        ...getBorrowerItemOptions(
          mapping,
          applicationData.coborrowerTypes[index],
          coBorrowerDefaultVariablesByIndex[index],
        ),
      }));
    });
  }, [applicationData]);

  const borrowerItems = useMemo(() => {
    const borrowerMapping = getBorrowerMappings(ApplicationDefaultVariable.Borrower, applicationData.borrowerType);

    return borrowerMapping.map((mapping) => ({
      label: mapping.label,
      ...getBorrowerItemOptions(mapping, applicationData.borrowerType),
    }));
  }, [applicationData]);

  const applicationDetailsItems = useMemo(() => {
    const approvedItems =
      applicationData.status.name === DefaultApplicationStatus.Approved
        ? [{ label: 'Approval Date', content: formatDate(applicationData.approvedAt!, DateTimeFormat.Long) }]
        : [];

    const rejectedItems =
      applicationData.status.name === DefaultApplicationStatus.Rejected
        ? [
            { label: 'Rejection Date', content: formatDate(applicationData.rejectedAt!, DateTimeFormat.Long) },
            {
              label: 'Decline Reasons',
              content: applicationData.declineReasons?.map((reason) => <div key={reason}>{reason}</div>),
            },
          ]
        : [];

    const intermediaryName = applicationData.variables[ApplicationDefaultVariable.Intermediary.Name];

    const intermediaryItems = applicationData.intermediaryId
      ? [{ label: 'Intermediary', content: intermediaryName }]
      : [];

    return [
      {
        label: 'Application Date',
        content: !formatApplicationDate
          ? formatDate(applicationData.createdAt, DateTimeFormat.Long)
          : formatApplicationDate(applicationData.createdAt),
      },
      {
        label: 'Team Members',
        content: renderTeamMembersSectionContent(),
        contentClassName: styles.teamMembersContent,
      },
      ...approvedItems,
      ...rejectedItems,
      ...intermediaryItems,
    ];
  }, [applicationData]);

  const renderBorrowerFullNameWithIcon = (fullName: string, borrowerType: BorrowerType) => {
    return (
      <div className={clsx(styles.borrowerFullNameWithIcon, !fullName && styles.blankBorrowerFullNameWithIcon)}>
        {fullName || BLANK_BORROWER_FULL_NAME}
        <BorrowerIcon className={styles.borrowerIcon} borrowerType={borrowerType} />
      </div>
    );
  };

  return (
    <div className={styles.infoPanelContentInner}>
      <InfoPanelColumn
        title="Application ID"
        isOpen={isOpen}
        titleContent={
          <>
            #{applicationData.displayId}
            {originalApplicationDisplayId && (
              <WrapperWithHoverableTooltip
                tooltip={<DuplicatedApplicationTooltip originalApplicationDisplayId={originalApplicationDisplayId} />}
              >
                <DuplicateImage className={styles.duplicateIcon} />
              </WrapperWithHoverableTooltip>
            )}
          </>
        }
        items={applicationDetailsItems}
      />
      <InfoPanelColumn
        title="Borrower"
        titleContent={renderBorrowerFullNameWithIcon(
          getBorrowerFullName(applicationData.borrowerType, applicationData.variables),
          applicationData.borrowerType,
        )}
        items={borrowerItems}
        isOpen={isOpen}
      />
      {applicationData.coborrowerIds.map((id, index) => (
        <InfoPanelColumn
          key={`co-borrower-column-${id}`}
          isOpen={isOpen}
          title={`Co-Borrower${index === 0 ? '' : ` ${index + 1}`}`}
          titleContent={renderBorrowerFullNameWithIcon(
            getBorrowerFullName(
              applicationData.coborrowerTypes[index],
              applicationData.variables,
              coBorrowerDefaultVariablesByIndex[index],
            ),
            applicationData.coborrowerTypes[index],
          )}
          items={coBorrowerItemsByIndex[index]}
        />
      ))}
    </div>
  );
};

export default InfoPanelContent;
