import React, { FC, useState } from 'react';
import AutoCompletionSync from 'components/AutoCompletion/AutoCompletionSync';
import Button from 'components/Button';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import styles from './ReportingDashboardAdditionalFilters.module.scss';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { Option } from 'components/SelectInput/SelectInput';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import AutoCompletionAsync from 'components/AutoCompletion/AutoCompletionAsync';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { ReportingDashboardAdditionalFiltersModel } from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';

interface ReportingDashboardAdditionalFiltersProps {
  filters: ReportingDashboardAdditionalFiltersModel;
  organizationMembers: UserInfo[];
  productsList: Product[];
  getIntermediaries: (search: string) => Promise<Intermediary[]>;
  getIntermediaryById: (id: string) => Promise<Intermediary>;
  onClose: () => void;
  onChange: (filter: ReportingDashboardAdditionalFiltersModel) => void;
}

const ReportingDashboardAdditionalFilters: FC<ReportingDashboardAdditionalFiltersProps> = ({
  filters,
  productsList,
  organizationMembers,
  getIntermediaries,
  getIntermediaryById: fetchIntermediaryById,
  onClose,
  onChange,
}) => {
  const mapProductToOption = (product: Product) => {
    return {
      name: product.name,
      value: product.id,
    };
  };

  const mapOrganizationMemberToOption = (member: UserInfo) => {
    return {
      name: `${member.firstName} ${member.lastName}`,
      value: member.id,
      icon: <WithSystemApiUserAvatar user={member} size="small" />,
    };
  };

  const mapIntermediaryToOption = (intermediary: Intermediary) => {
    return {
      name: intermediary.variables[IntermediaryDefaultVariable.Name] as string,
      value: intermediary.id,
    };
  };

  const productOptions: Option[] = productsList.map(mapProductToOption);
  const organizationMemberOptions: Option[] = organizationMembers.map(mapOrganizationMemberToOption);

  const [selectedProduct, setSelectedProduct] = useState<Option | undefined>(
    productOptions.find(({ value }) => value === filters.productId),
  );

  const [selectedOrganizationMember, setSelectedOrganizationMember] = useState<Option | undefined>(
    organizationMemberOptions.find(({ value }) => value === filters.organizationMemberId),
  );

  const [selectedIntermediary, setSelectedIntermediary] = useState<Option | null | undefined>();

  const fetchIntermediaries = async (search: string) => {
    const intermediaries = await getIntermediaries(search);

    return intermediaries.map(mapIntermediaryToOption);
  };

  const fetchSelectedIntermediary = async (intermediaryId: string) => {
    const intermediary = await fetchIntermediaryById(intermediaryId);

    return mapIntermediaryToOption(intermediary);
  };

  const handleApplyFilter = () => {
    onChange({
      intermediaryId: selectedIntermediary === undefined ? filters.intermediaryId : selectedIntermediary?.value,
      organizationMemberId: selectedOrganizationMember?.value,
      productId: selectedProduct?.value,
    });
  };

  const handleResetFiltersClick = () => {
    onChange({
      intermediaryId: undefined,
      organizationMemberId: undefined,
      productId: undefined,
    });
  };

  return (
    <FilterLayout onClose={onClose} onClearFiltersClick={handleResetFiltersClick}>
      <AutoCompletionSync
        labelTitle="Product"
        options={productOptions}
        selectedOption={selectedProduct}
        onChange={setSelectedProduct}
        className={styles.productInput}
      />
      <AutoCompletionSync
        labelTitle="Assigned Team Member"
        options={organizationMemberOptions}
        selectedOption={selectedOrganizationMember}
        onChange={setSelectedOrganizationMember}
      />
      <AutoCompletionAsync
        labelTitle="Intermediary"
        fetchOptions={fetchIntermediaries}
        fetchSelectedOption={fetchSelectedIntermediary}
        onChange={(option) => {
          setSelectedIntermediary(option.value === '' ? null : option);
        }}
        value={selectedIntermediary === undefined ? filters.intermediaryId : selectedIntermediary?.value}
      />
      <Button kind="primary" size="form" onClick={handleApplyFilter} className={styles.applyFilterButton} fullWidth>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default ReportingDashboardAdditionalFilters;
