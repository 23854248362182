import React from 'react';
import {
  ApplicationsTableSize,
  ApplicationsTableColumnSizes,
} from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCirclesList from 'components/Skeleton/SkeletonCirclesList';
import styles from './SkeletonApplicationTableBody.module.scss';
import variables from 'components/RowActions/RowActions.module.scss';

interface SkeletonApplicationTableBodyProps {
  tableSize: ApplicationsTableSize;
  showSearchMatch: boolean;
}

const SkeletonApplicationTableBody = ({ tableSize, showSearchMatch }: SkeletonApplicationTableBodyProps) => (
  <>
    <TableRow disableHover noBottomBorder={showSearchMatch}>
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerFullName}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].DisplayId}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].CreatedAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      {tableSize !== ApplicationsTableSize.Small ? (
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].UpdatedAt}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
      ) : null}
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].Status}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerEmail}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      {tableSize === ApplicationsTableSize.Wide ? (
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerPhoneNumber}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
      ) : null}
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].TeamMembers} noPadding>
        <SkeletonCirclesList color="primary6" width="28px" height="28px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].LoanAmount} className={styles.flexEndJustify}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels>
        {' '}
      </TableBodyCell>
    </TableRow>
    {showSearchMatch ? (
      <TableRow disableHover noTopBorder>
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerFullName}> </TableBodyCell>
        <TableBodyCell width={100 - ApplicationsTableColumnSizes[tableSize].BorrowerFullName} dashedTopBorder>
          <SkeletonRectangle width="340px" color="primary6" height="16px" />
        </TableBodyCell>
        <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
          {' '}
        </TableBodyCell>
      </TableRow>
    ) : null}
  </>
);

export default SkeletonApplicationTableBody;
