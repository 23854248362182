import React, { FC, useState, MouseEvent, ReactElement, useEffect, useRef } from 'react';
import clsx from 'clsx';
import isEllipsisActive from 'utils/isEllipsisActive';
import styles from './CollapsibleText.module.scss';

interface CollapsibleTextProps {
  children: ReactElement;
  textLength: number;
  btnClassName?: string;
}

const CollapsibleText: FC<CollapsibleTextProps> = ({ children, textLength, btnClassName }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const textElRef = useRef(null);

  useEffect(() => {
    setIsTextTruncated(!!textElRef.current && isEllipsisActive(textElRef.current));
  }, [textLength, isTextTruncated]);

  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={clsx(styles.textContainer, !isCollapsed && styles.textContainerExpanded)}>
      <p ref={textElRef} className={isCollapsed ? styles.textCollapsed : styles.textExpanded}>
        {children}
      </p>
      {isTextTruncated ? (
        <button type="button" onClick={handleButtonClick} className={clsx(styles.controlButton, btnClassName)}>
          {isCollapsed ? 'Read More' : 'Show Less'}
        </button>
      ) : null}
    </div>
  );
};

export default CollapsibleText;
