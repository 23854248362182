import Fetcher from 'middlewares/Fetcher';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import {
  GetIntermediaryApplicationsAction,
  getIntermediaryApplicationsFailure,
  getIntermediaryApplicationsSuccess,
} from './ActionCreator';
import { LoanOriginationSystemIntermediaryDetailsActionTypes } from './ActionTypes';
import { INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/IntermediaryDetails/pagination';
import { SimplifiedApplication, SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const GetIntermediaryApplicationsMiddleware = (api: SimplifiedApplicationsApi) =>
  Fetcher<TableViewData<SimplifiedApplication>, GetIntermediaryApplicationsAction>(
    LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplications,
    getIntermediaryApplicationsSuccess,
    getIntermediaryApplicationsFailure,
    (action) => {
      const { intermediaryId, sortingType } = action.payload;

      return api.getIntermediaryApplications(
        intermediaryId,
        {
          offset: 0,
          count: INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT,
        },
        sortingType,
      );
    },
  );

export default GetIntermediaryApplicationsMiddleware;
