import React, { FC } from 'react';
import styles from './components.module.scss';

export interface ChartPointProps {
  x: number;
  y: number;
  hovered?: boolean;
}

const RADIUS = 3;
const HOVERED_RADIUS = 6;

const ChartPoint: FC<ChartPointProps> = ({ x, y, hovered = false }) => {
  return (
    <circle
      r={hovered ? HOVERED_RADIUS : RADIUS}
      cx={x}
      cy={y}
      stroke={hovered ? styles.pointColor : styles.lineColor}
      fill={hovered ? styles.lineColor : styles.pointColor}
      strokeWidth={styles.lineWidth}
    />
  );
};

export default ChartPoint;
