import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import responseHandlers from 'api/ResponseHandlers';
import { Variable } from 'Variables/VariablesTypes';
import { closeReprocessDecisionPopup, openReprocessDecisionPopup } from 'Decisions/RunDecisionStore';
import DecisionApi, { ExtendedDecision } from 'api/DecisionEngine/DecisionApi';
import { DecisionActionTypes } from 'Decisions/actionTypes';

export interface DecisionState {
  data: ExtendedDecision | null;
  loading: boolean;
  notFound: boolean;
  reprocessDecisionPopupOpen: boolean;
  strategyInputs: Variable[];
  decisionId: string | null;
  resultId: string | null;
}

export interface FetchDecisionParams {
  decisionId: string;
  decisionResultId?: string | null;
  abortSignal?: AbortSignal;
}

const decisionApi = new DecisionApi(responseHandlers);

const initialState: DecisionState = {
  data: null,
  loading: false,
  notFound: false,
  reprocessDecisionPopupOpen: false,
  strategyInputs: [],
  decisionId: null,
  resultId: null,
};

export const requestDecision = createAsyncThunk(
  'requestDecision',
  async ({ decisionId, resultId }: { decisionId: string, resultId?: string | null }) => {
    const decision = await decisionApi.findById(decisionId, resultId);
    return decision;
  },
);

export const resetDecisionState = createAction(DecisionActionTypes.ResetDecisionState);

const decisionReducer = createReducer<DecisionState>(initialState, (builder) => {
  builder.addCase(requestDecision.pending, (state, action) => {
    state.data = null;
    state.loading = true;
    state.notFound = false;
    state.decisionId = action.meta.arg.decisionId;
    state.resultId = action.meta.arg.resultId || null;
  });
  builder.addCase(requestDecision.fulfilled, (state, action) => {
    state.data = action.payload;
    state.loading = false;
    state.notFound = false;
  });
  builder.addCase(requestDecision.rejected, (state) => {
    state.data = null;
    state.loading = false;
    state.notFound = true;
  });
  builder.addCase(openReprocessDecisionPopup, (state) => {
    state.reprocessDecisionPopupOpen = true;
  });
  builder.addCase(closeReprocessDecisionPopup, (state) => {
    state.reprocessDecisionPopupOpen = false;
  });
});

export default withStateReset(
  decisionReducer,
  DecisionActionTypes.ResetDecisionState,
  () => initialState,
);
