import CoreSystemApi from 'api/Core/CoreSystemApi';
import { ESignIntegration, ESignIntegrationType } from 'api/LoanOriginationSystem/Types';

export enum DocuSignEnvironmentType {
  Development = 'development',
  Production = 'production',
}

export enum DocuSignAccountBaseUri {
  Demo = 'https://demo.docusign.net',
  Base = 'https://docusign.net',
  NA2 = 'https://na2.docusign.net',
  NA3 = 'https://na3.docusign.net',
  NA4 = 'https://na4.docusign.net',
  EU = 'https://eu.docusign.net',
  AU = 'https://au.docusign.net',
  CA = 'https://ca.docusign.net',
}

export interface DocuSignIntegrationSettings {
  environment: DocuSignEnvironmentType;
  clientId: string;
  userId: string;
  privateKey: string;
  accountId: string;
  accountBaseUri: string;
}

export type DocuSignIntegration = ESignIntegration<ESignIntegrationType.DocuSign, DocuSignIntegrationSettings>;

export interface DocuSignIntegrationApi {
  getIntegration(): Promise<DocuSignIntegration>;
  createIntegration(settings: DocuSignIntegrationSettings): Promise<DocuSignIntegration>;
  updateIntegration(id: string, settings: DocuSignIntegrationSettings): Promise<DocuSignIntegration>;
}

export default class DocuSignIntegrationRestApi extends CoreSystemApi<DocuSignIntegration>
  implements DocuSignIntegrationApi {
  protected resourceName = 'docu-sign-integrations';

  public async getIntegration() {
    const { integration } = await this.fetch<{ integration: DocuSignIntegration }>(`/${this.resourceName}`);

    return integration;
  }

  public async createIntegration(settings: DocuSignIntegrationSettings) {
    return this.createResource({ settings });
  }

  public async updateIntegration(id: string, settings: DocuSignIntegrationSettings) {
    return this.updateResource(id, { settings });
  }
}
