import React, { useEffect } from 'react';
import { ReduxState } from 'types/redux';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  IntermediariesSortingField,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { getIntermediaries, sortIntermediaries } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import {
  setSearchInputValue,
  filterIntermediariesByMembers,
  toggleFiltersPopup,
  resetAllIntermediariesFilters,
} from 'LoanOriginationSystemIntermediariesPage/Filters/ActionCreator';
import pagination, { IntermediariesPaginationParams } from './Pagination';
import styles from './IntermediariesDashboard.module.scss';
import Title from 'components/LoanOriginationSystem/IntermediariesDashboard/Title';
import NoItems from 'components/NoItems';
import IntermediariesTable from './IntermediariesTable';
import SearchNotFound from 'components/SearchNotFound';
import useStandardVariables from 'hooks/useStandardVariables';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  initialState as filtersInitialState,
} from 'LoanOriginationSystemIntermediariesPage/Filters/Reducer';
import { isEqual } from 'lodash';
import { NoResultsIcon } from 'static/images';
import areItemsLoading from 'utils/areItemsLoading';

interface IntermediariesDashboardProps {
  onDeleteIntermediaryPopupOpen: (intermediary: Intermediary) => Promise<void>;
}

const IntermediariesDashboard = ({ onDeleteIntermediaryPopupOpen }: IntermediariesDashboardProps) => {
  const { sortingType, filters } = useSelector((state: ReduxState) => state.loanOriginationSystemIntermediariesState);
  const organizationMembers = useOrganizationMembers();

  const params: IntermediariesPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    dueCreatedDateRange: filters.dueCreatedDateRange,
    dueUpdatedDateRange: filters.dueUpdatedDateRange,
    borrowerTypes: filters.borrowerTypes,
    selectedMembers: filters.selectedMembers,
  };

  const standardVariables = useStandardVariables();
  const intermediaries = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getIntermediaries(filters, sortingType));
  }, []);

  const onCreateNewIntermediary = () => {
    history.replace('?new');
  };

  const onIntermediaryEdit = (id: string) => {
    history.replace(`?edit=${id}`);
  };

  const onSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const onSort = (field: IntermediariesSortingField, ascending: boolean) => {
    dispatch(sortIntermediaries(field, ascending));
  };

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterIntermediariesByMembers(newSelectedMembers));
  };

  const onResetAllFilters = () => {
    dispatch(resetAllIntermediariesFilters());
  };

  const areFiltersChanged = () => {
    return (
      filters.dueCreatedDateRange.from !== null ||
      filters.dueCreatedDateRange.to !== null ||
      filters.dueUpdatedDateRange.from !== null ||
      filters.dueUpdatedDateRange.to !== null ||
      filters.borrowerTypes.length !== 0
    );
  };

  const onFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const isNoIntermediaries =
    !intermediaries.length && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const renderBody = () => {
    if (filters.searchInputValue && !intermediaries.length) {
      return <SearchNotFound searchValue={filters.searchInputValue} className={styles.notFoundContainer} footer />;
    }

    if (!intermediaries.length && isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No intermediaries have been added yet!"
          titleClassName={styles.intermediariesNoDataTitle}
          buttonMessage="Add Intermediary"
          onButtonClick={onCreateNewIntermediary}
          className={styles.intermediariesNoData}
        />
      );
    }

    if (!intermediaries.length && !isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={onResetAllFilters}
          className={styles.noResultsFoundContainer}
        />
      );
    }

    return (
      <IntermediariesTable
        intermediaries={intermediaries}
        paginationProps={paginationProps}
        onSort={onSort}
        sortingType={sortingType}
        onDeleteIntermediaryPopupOpen={onDeleteIntermediaryPopupOpen}
        standardVariables={standardVariables}
        onEdit={onIntermediaryEdit}
        searchInputValue={filters.searchInputValue}
      />
    );
  };

  return (
    <>
      <Title
        onCreateNewIntermediary={onCreateNewIntermediary}
        onSearch={onSearch}
        hideSearch={isNoIntermediaries}
        searchInputValue={filters.searchInputValue}
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        onMemberFilterChange={onMemberFilterChange}
        filtersChanged={areFiltersChanged()}
        onFilterButtonClick={onFilterButtonClick}
        areIntermediariesLoading={areItemsLoading(intermediaries)}
      />
      <div className={styles.contentContainer}>{renderBody()}</div>
    </>
  );
};

export default IntermediariesDashboard;
