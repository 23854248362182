import { createAsyncThunk } from '@reduxjs/toolkit';
import requestWithNormalize from 'utils/requestWithNormalize';
import IntermediaryProfileCardsRestApi, {
  CreateIntermediaryProfileCardParams,
  UpdateIntermediaryProfileCardParams,
} from 'api/LoanOriginationSystem/IntermediaryProfileCardsApi';
import { Card, NormalizedCard } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import IntermediaryProfileCardSchema from 'schemas/IntermediaryProfileCardSchema';
import responseHandlers from 'api/ResponseHandlers';

interface IntermediaryProfileCardsNormalizedEntities {
  intermediaryProfileCards: Record<string, NormalizedCard>;
  intermediaryVariableConfigurations: Record<string, BaseVariableConfiguration>;
}

const intermediaryProfileCardsApi = new IntermediaryProfileCardsRestApi(responseHandlers);

export const getIntermediaryProfileCards = createAsyncThunk(
  'intermediaryProfileCards/getIntermediaryProfileCards',
  async () => {
    const { entities } = await requestWithNormalize<IntermediaryProfileCardsNormalizedEntities, Card>(() => {
      return intermediaryProfileCardsApi.find();
    }, [IntermediaryProfileCardSchema]);

    return entities;
  },
);

export const createIntermediaryProfileCard = createAsyncThunk(
  'intermediaryProfileCards/createIntermediaryProfileCard',
  async (params: CreateIntermediaryProfileCardParams) => {
    const { entities } = await requestWithNormalize<IntermediaryProfileCardsNormalizedEntities, Card>(() => {
      return intermediaryProfileCardsApi.create(params);
    }, IntermediaryProfileCardSchema);

    return entities;
  },
);

export const updateIntermediaryProfileCard = createAsyncThunk(
  'intermediaryProfileCards/updateIntermediaryProfileCard',
  async (params: UpdateIntermediaryProfileCardParams & { id: string }) => {
    const { entities } = await requestWithNormalize<IntermediaryProfileCardsNormalizedEntities, Card>(() => {
      const { id, ...updateParams } = params;

      return intermediaryProfileCardsApi.update(id, updateParams);
    }, IntermediaryProfileCardSchema);

    return entities;
  },
);

export const deleteIntermediaryProfileCard = createAsyncThunk(
  'intermediaryProfileCards/deleteIntermediaryProfileCard',
  async (id: string) => {
    await intermediaryProfileCardsApi.delete(id);

    return {
      id,
    };
  },
);
