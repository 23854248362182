import React from 'react';
import SIZES from 'components/ApiActivation/Table/TableColumnSizes';
import styles from './ActivationTable.module.scss';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import { ApiActivationsPopUpType } from 'ApiActivation/Types';
import TableActionCell from 'components/Table/TableActionCell';
import { StrategyGroup } from 'api/Types';
import { NullableItems } from 'pagination';
import SkeletonStrategyTableRow from './SkeletonStrategyTableRow';
import StrategyTableRow from './StrategyTableRow';
import TableBodyContent from 'components/Table/TableBodyContent';
import useSorting from 'hooks/useSorting';
import { ApiActivationSortingFields, ApiActivationSortingType } from 'DecisionStrategy/DecisionStrategiesTypes';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import ScrollableContainer from 'components/ScrollableContainer';

interface TableProps {
  strategyGroups: NullableItems<StrategyGroup>;
  searchInputValue: string;
  onOpenApiActivationsPopUp: (popUpType: ApiActivationsPopUpType, activationInfo?: StrategyGroup) => void;
  sortingType: ApiActivationSortingType;
  onSort: (field: ApiActivationSortingFields, ascending: boolean) => void;
}

const ActivationTable = ({
  strategyGroups,
  searchInputValue,
  onOpenApiActivationsPopUp,
  sortingType,
  onSort,
}: TableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const onOpenPopUp = (group: StrategyGroup) => {
    onOpenApiActivationsPopUp('change', group);
  };

  const renderRow = (group: StrategyGroup) => (
    <StrategyTableRow
      {...group}
      key={group.name}
      searchInputValue={searchInputValue}
      onOpenPopUp={() => onOpenPopUp(group)}
    />
  );

  return (
    <ScrollableContainer>
      <TableWrapperWithFooter>
        <Table className={styles.table}>
          <TableHead sticky>
            <TableHeadCell
              width={SIZES.NAME}
              ascending={getSortingType(ApiActivationSortingFields.StrategyName)}
              onClick={() => changeSorting(ApiActivationSortingFields.StrategyName)}
            >
              Strategy Name
            </TableHeadCell>
            <TableHeadCell width={SIZES.ACTIVE}>Active Version</TableHeadCell>
            <TableHeadCell width={SIZES.TESTING}>Testing Version</TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBodyContent
            rows={strategyGroups}
            renderSkeletonTableRow={(index) => <SkeletonStrategyTableRow key={index} />}
            renderTableRow={renderRow}
          />
        </Table>
      </TableWrapperWithFooter>
    </ScrollableContainer>
  );
};

export default ActivationTable;
