import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { validateConfigurableFormData } from './validation';

const useConfigurableFormValidation = (
  fields: BaseVariableConfiguration[] | null,
) => {
  return (data: FormLayoutData, displayFieldsAttributes?: Record<string, boolean>) => {
    return validateConfigurableFormData(data, fields || [], displayFieldsAttributes);
  };
};

export default useConfigurableFormValidation;
