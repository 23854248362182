import Debouncer from 'middlewares/Debouncer';
import { VariablesListActionType } from './ActionTypes';
import { getListVariables, SetSearchInputValueAction } from './ActionCreator';

export default Debouncer(
  (payload: SetSearchInputValueAction['payload']) => {
    return getListVariables(payload.search, payload.allowStandardVariables);
  },
  [VariablesListActionType.SetSearchInputValue],
);
