import { useEffect, useRef, useState } from 'react';

const postpone = (fn: () => void) => {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  typeof requestIdleCallback === 'function' ? requestIdleCallback(fn) : setTimeout(fn);
};

export const useInitiallyPipedItems = <Item>(items: Item[], limit: number) => {
  const [pipedItems, setPipedItems] = useState<Item[] | null>(items.slice(0, limit));
  const initialItems = useRef<Item[] | null>(items);

  const pipeMoreItems = (step: number) => {
    if (items !== initialItems.current) {
      return;
    }
    const currentLimit = limit * step;
    setPipedItems(items.slice(0, currentLimit));
    if (currentLimit < items.length) {
      postpone(() => pipeMoreItems(step + 1));
    }
  };

  useEffect(() => {
    postpone(() => pipeMoreItems(2)); // 1st portion is in initial state already
  }, []);

  useEffect(() => {
    if (initialItems.current && initialItems.current !== items) {
      initialItems.current = null; // Garbage-collecting initial items and piped items
      setPipedItems(null);
    }
  }, [items]);

  return items === initialItems.current ? pipedItems as Item[] : items;
};
