import React from 'react';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { DerivedComparisonsTypes } from 'components/RuleRow/Types';
import { getVariableDisplayValue } from 'components/RuleRow/utils';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import AndSeparator from 'components/AndSeparator';
import styles from './StatusRule.module.scss';

export interface StatusRuleProps {
  rule: ApplicationStatusRule;
  onRuleDelete: (statusRule: ApplicationStatusRule) => void;
  onRuleEdit: (statusRule: ApplicationStatusRule) => void;
}

const StatusRule = ({ rule, onRuleEdit, onRuleDelete }: StatusRuleProps) => {
  const [multipleRule] = rule.multipleRules;

  const renderLine = (title: string, value: string) => (
    <div className={styles.statusRuleLine}>
      <p className={styles.lineTitle}>{title}</p>
      <p className={styles.lineValue}>{value}</p>
    </div>
  );

  if (!multipleRule) {
    return null;
  }

  const variableDisplayValue = getVariableDisplayValue(
    {
      ruleId: 0,
      selectedComparison: multipleRule.comparisonOperator,
      firstOperandValue: multipleRule.firstOperandValue,
      firstOperandType: multipleRule.firstOperandType,
      secondOperandValue: multipleRule.secondOperandValue,
      secondOperandType: multipleRule.secondOperandType,
      sourceVariableId: multipleRule.sourceVariable.id,
      firstOperandVariableId: multipleRule.firstOperandVariable?.id,
      secondOperandVariableId: multipleRule.secondOperandVariable?.id,
      firstOperandScript: undefined,
    },
    multipleRule.sourceVariable,
    multipleRule.firstOperandVariable!,
    multipleRule.secondOperandVariable!,
  );

  const actions: RowAction[] = [
    {
      title: 'Edit Status Rule',
      handler: () => onRuleEdit(rule),
      separatorRequired: true,
    },
    {
      title: 'Delete Status Rule',
      danger: true,
      handler: () => onRuleDelete(rule),
    },
  ];

  return (
    <RowActionsContainer>
      <div className={styles.statusRule} onClick={() => onRuleEdit(rule)}>
        <div className={styles.statusRuleContent}>
          <div>
            {renderLine('Variable', multipleRule.sourceVariable.displayName)}
            {renderLine('Comparison', DerivedComparisonsTypes[multipleRule.comparisonOperator])}
            {renderLine('Value', variableDisplayValue)}
          </div>
          <AndSeparator className={styles.andSeparator} />
        </div>
        <RowActions className={styles.rowActions} actions={actions} />
      </div>
    </RowActionsContainer>
  );
};

export default StatusRule;
