import React, { FC } from 'react';
import { AxisRight } from '@visx/axis';
import { AnyD3Scale } from '@visx/scale';
import styles from 'components/Charts/components/components.module.scss';

interface ChartAxisRightProps {
  x?: number;
  y?: number;
  yOffset?: number;
  scale: AnyD3Scale;
  formatValue: (value: any) => string;
}

const ChartAxisRight: FC<ChartAxisRightProps> = ({ x, y, scale, yOffset, formatValue }) => {
  return (
    <AxisRight
      left={x}
      top={y}
      scale={scale}
      hideAxisLine
      hideTicks
      tickLabelProps={(value) => {
        return {
          y: (scale(value) || 0) + yOffset,
          textAnchor: 'end',
          fontSize: styles.textSize,
          fontFamily: styles.fontFamily,
          fontWeight: styles.textWeight,
          fill: styles.textColor,
        };
      }}
      tickFormat={formatValue}
    />
  );
};

export default ChartAxisRight;
