import { AnyAction } from 'redux';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { TableViewData } from 'api/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemBorrowersFiltersState } from './Filters/Types';
import {
  Borrower,
  BorrowersSortingField,
  BorrowersSortingType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';

export type GetBorrowersSuccessData = TableViewData<Borrower> & { searchInputValue: string };

export interface GetBorrowersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowers;
  payload: {
    filters: LoanOriginationSystemBorrowersFiltersState;
    sortingType: BorrowersSortingType;
    abortSignal: AbortSignal;
  };
}

export interface GetBorrowersSuccessAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess;
  payload: GetBorrowersSuccessData;
}

export interface SortBorrowersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.SortBorrowers;
  payload: BorrowersSortingType;
}

export interface DeleteBorrowerAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrower;
  payload: {
    borrowerId: string;
    borrowerName: string;
  };
}

export interface DeleteBorrowerSuccessAction {
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess;
  payload: DeleteBorrowerSuccessResult;
}

export interface DeleteBorrowerFailureAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure;
  payload: Error;
}

export interface DeleteBorrowerSuccessResult {
  name: string;
}

export interface SetBorrowerToDeleteAction {
  type: LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDelete;
  payload: {
    borrower: Borrower | null;
  };
}

export interface SelectBorrowerViewTabAction {
  type: LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab;
  payload: {
    tab: BorrowerDetailsTab;
  };
}

export interface ChangeContextualViewStepAction {
  type: LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep;
  payload: {
    step: number;
  };
}

let getBorrowersAbortController: AbortController | null = null;
export const getBorrowers = (
  filters: LoanOriginationSystemBorrowersFiltersState,
  sortingType: BorrowersSortingType,
): GetBorrowersAction => {
  if (getBorrowersAbortController) {
    getBorrowersAbortController.abort();
  }

  getBorrowersAbortController = new AbortController();

  return {
    type: LoanOriginationSystemBorrowersActionTypes.GetBorrowers,
    payload: {
      filters,
      sortingType,
      abortSignal: getBorrowersAbortController.signal,
    },
  };
};

export const deleteBorrower = (borrowerId: string, borrowerName: string): DeleteBorrowerAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrower,
  payload: {
    borrowerId,
    borrowerName,
  },
});

export const getBorrowersSuccess = (borrowersData: GetBorrowersSuccessData): GetBorrowersSuccessAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess,
  payload: borrowersData,
});

export const deleteBorrowerSuccess = (data: DeleteBorrowerSuccessResult): DeleteBorrowerSuccessAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess,
  payload: data,
});

export const getBorrowersFailed = FailedActionCreator(LoanOriginationSystemBorrowersActionTypes.GetBorrowersFailed);

export const deleteBorrowerFailure = (error: Error) => ({
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure,
  payload: error,
});

export const sortBorrowers = (field: BorrowersSortingField, ascending: boolean): SortBorrowersAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SortBorrowers,
  payload: {
    ascending,
    field,
  },
});

export const setBorrowerToDelete = (borrower: Borrower | null): SetBorrowerToDeleteAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDelete,
  payload: {
    borrower,
  },
});

export const selectBorrowerViewTab = (tab: BorrowerDetailsTab): SelectBorrowerViewTabAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab,
  payload: { tab },
});

export const changeContextualViewStep = (step: number): ChangeContextualViewStepAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep,
  payload: { step },
});
