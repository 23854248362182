import clsx from 'clsx';
import React from 'react';
import { ArrowLongRight } from 'static/images';
import ScrollableTabs from 'components/ScrollableTabs';
import StepComponent from './Step';
import styles from './Stepper.module.scss';

export interface Step {
  label: string;
  type: string;
}

interface StepperProps {
  steps: Step[];
  currentStep: string;
  className?: string;
  wrapperClassName?: string;
  stepClassName?: string;
  onStepChange: (stepName: string) => void;
  alwaysActive?: boolean;
}

const Stepper = ({ steps, currentStep, className, stepClassName, wrapperClassName, alwaysActive, onStepChange }: StepperProps) => {
  const isPassed = (index: number) => index < steps.findIndex((step) => step.type === currentStep);

  const handleClick = (step: string, index: number) => {
    if (!alwaysActive && !isPassed(index)) {
      return;
    }

    onStepChange(step);
  };

  return (
    <div className={clsx(styles.stepsWrapper, wrapperClassName)}>
      <ScrollableTabs className={className}>
        {steps.map((step, index) => (
          <div key={step.type}>
            <StepComponent
              className={stepClassName}
              number={index + 1}
              title={step.label}
              current={step.type === currentStep}
              active={alwaysActive || step.type === currentStep}
              passed={isPassed(index)}
              onClick={() => handleClick(step.type, index)}
            />
            {index < steps.length - 1 && <ArrowLongRight className={styles.arrow} />}
          </div>
        ))}
      </ScrollableTabs>
    </div>
  );
};

export default Stepper;
