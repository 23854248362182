import React, { FC } from 'react';
import clsx from 'clsx';
import { ProductConfigurationDocumentFolder } from 'api/Types';
import {
  ProductConfigurationDocumentFoldersSortingType,
  ProductConfigurationDocumentFoldersSortingFields,
} from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';
import useSorting from 'hooks/useSorting';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableActionCell from 'components/Table/TableActionCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import DocumentFoldersTableDndContext from 'components/DocumentFoldersTableDndContext';
import DocumentFoldersTableDraggableRow from 'components/DocumentFoldersTableDraggableRow';
import DocumentFoldersTableRow from './DocumentFoldersTableRow';
import styles from './DocumentFoldersTable.module.scss';

interface DocumentFoldersTableProps {
  className?: string;
  folders: ProductConfigurationDocumentFolder[];
  sortingType: ProductConfigurationDocumentFoldersSortingType;
  onSort: (field: ProductConfigurationDocumentFoldersSortingFields, ascending: boolean) => void;
  onRowClick: (id: string) => void;
  onRenameFolderClick: (id: string) => void;
  onDeleteFolderClick: (id: string) => void;
  onFoldersCombine: (id: string, parentId: string) => void;
}

const FULL_WIDTH = 100;

const DocumentFoldersTable: FC<DocumentFoldersTableProps> = ({
  className,
  folders = [],
  sortingType,
  onRowClick,
  onRenameFolderClick,
  onDeleteFolderClick,
  onSort,
  onFoldersCombine,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeleton = (index: number) => (
    <TableRow key={index} disableHover useActions>
      <TableBodyCell width={FULL_WIDTH}>
        <SkeletonRectangle width="100%" color="primary6" height="16px" />
      </TableBodyCell>
    </TableRow>
  );

  const renderRow = (folder: ProductConfigurationDocumentFolder, rowClassName: string, disableHover?: boolean) => (
    <DocumentFoldersTableRow
      folder={folder}
      className={rowClassName}
      onClick={onRowClick}
      onRenameFolderClick={onRenameFolderClick}
      onDeleteFolderClick={onDeleteFolderClick}
      disableHover={disableHover}
    />
  );

  return (
    <Table className={clsx(styles.foldersTable, className)}>
      <TableHead className={styles.foldersTableHead}>
        <TableHeadCell
          width={FULL_WIDTH}
          className={styles.foldersTableHeadCell}
          ascending={getSortingType(ProductConfigurationDocumentFoldersSortingFields.Name)}
          onClick={() => changeSorting(ProductConfigurationDocumentFoldersSortingFields.Name)}
        >
          Folder Name
        </TableHeadCell>
        <TableActionCell />
      </TableHead>
      <DocumentFoldersTableDndContext onFoldersCombine={onFoldersCombine}>
        {(currentDraggableId) => (
          <TableBody>
            <TableBodyContent
              rows={folders}
              renderSkeletonTableRow={renderSkeleton}
              renderTableRow={(folder: ProductConfigurationDocumentFolder, index: number) => (
                <DocumentFoldersTableDraggableRow
                  key={folder.id}
                  getIsCombineMode={(documentId) => !!documentId}
                  getDroppableIndex={(droppableId) => folders.findIndex(({ id }) => id === droppableId)}
                  currentDraggableId={currentDraggableId}
                  draggableId={folder.id}
                  draggableIndex={index}
                  placeholderRow={renderRow(folder, styles.foldersTableRowGrayed, true)}
                >
                  {(isDragging, isCombineTarget) =>
                    renderRow(
                      folder,
                      clsx(
                        isDragging && styles.foldersTableRowDragging,
                        isCombineTarget && styles.foldersTableRowCombineTarget,
                      ),
                    )
                  }
                </DocumentFoldersTableDraggableRow>
              )}
            />
          </TableBody>
        )}
      </DocumentFoldersTableDndContext>
    </Table>
  );
};

export default DocumentFoldersTable;
