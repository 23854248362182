import React, { FC, useEffect, useRef } from 'react';
import styles from './Versions.module.scss';
import Pagination from 'components/Pagination/Pagination';
import pagination from 'components/VersionsView/Pagination';
import { batch, useDispatch, useSelector } from 'react-redux';
import useFocusLock from 'components/FocusLock';
import NoStrategyFilterResults from 'components/NoStrategyFilterResults';
import SearchNotFound from 'components/SearchNotFound';
import VersionsTableView from 'components/VersionsView/VersionsTableView';
import Header from 'components/VersionsView/Header/HeaderConnector';
import { ReduxState } from 'types/redux';
import { VersionsListDataState } from 'VersionsViewData/Reducer';
import { resetFilters, getVersionsListData } from 'VersionsViewData/Actions';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import areItemsLoading from 'utils/areItemsLoading';
import useStateReset from 'hooks/useStateReset';
import { VersionsListDataActions } from 'VersionsViewData/ActionTypes';

interface StrategyVersionsProps {
  strategyName: string;
  strategyId: string;
}

const Versions: FC<StrategyVersionsProps> = ({ strategyName, strategyId }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const { searchInputValue, showArchived, sortingType, selectedUpdaters } = useSelector<
    ReduxState,
    VersionsListDataState
  >(({ versionsListData }) => versionsListData);

  const paginationProps = pagination.usePagination({
    searchInputValue,
    showArchived,
    strategyName,
    sortingType,
    selectedUpdaters,
  });
  const listItems = pagination.usePaginatedItems({
    searchInputValue,
    showArchived,
    strategyName,
    sortingType,
    selectedUpdaters,
  });

  useEffect(() => {
    dispatch(getVersionsListData({ strategyName }));
  }, [strategyName, strategyId]);

  const handleResetFilters = () => {
    batch(() => {
      dispatch(resetFilters());
      dispatch(getVersionsListData({ strategyName, showArchived }));
    });
  };

  useFocusLock(containerRef);
  useStateReset(VersionsListDataActions.ClearListData);

  const renderItems = () => {
    if (!listItems.length && !searchInputValue) {
      return <NoStrategyFilterResults onClick={handleResetFilters} />;
    }
    if (!listItems.length && searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }
    return (
      <VersionsTableView
        items={listItems}
        origin={StrategyActionOrigin.StrategyOverviewVersionsList}
        currentVersionId={strategyId}
      />
    );
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <h4>Versions</h4>
      <Header placeholderText="Search Versions" areListItemsLoading={areItemsLoading(listItems)} />
      {renderItems()}
      <Pagination {...paginationProps} />
    </div>
  );
};

export default Versions;
