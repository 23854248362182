import React, { useState, useEffect, ChangeEvent, FC } from 'react';

import styles from './Profile.module.scss';

import TextInput from 'components/TextInput';

import Button from 'components/Button';
import { isEmptyField } from 'utils/validation/validation';
import ImageUpload from 'components/ImageUpload';
import { UserState } from 'AccountDetails/AccountDetailsTypes';
import UserAvatar from 'components/UserAvatar';

interface ProfileProps {
  userInfo: UserState;
  changeUserInfo: (changedInfo: any) => Promise<unknown>;
  managePhoneAuthentication: () => void;
  openChangeEmail: () => void;
  changeUserPhoto: (photo: any) => void;
}

const Profile: FC<ProfileProps> = ({
  userInfo,
  changeUserInfo,
  managePhoneAuthentication,
  openChangeEmail,
  changeUserPhoto,
}) => {
  const { id, firstName: userFirstName, lastName: userLastName, email, imageId, isMfaEnabled, phone } = userInfo;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);

  const [firstNameError, setFirstNameError] = useState('');
  const [secondNameError, setSecondNameError] = useState('');

  useEffect(() => {
    setFirstName(userFirstName || '');
    setLastName(userLastName || '');
  }, [userInfo]);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const handleSecondNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSecondNameError('');
    setLastName(e.target.value);
  };

  const handleSaveChanges = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const isEmptyFirstName = isEmptyField(firstName);
    if (isEmptyFirstName) setFirstNameError('First name is required');

    const isEmptyLastName = isEmptyField(lastName);
    if (isEmptyLastName) setSecondNameError('Last name is required');

    if (isEmptyFirstName || isEmptyLastName) return;
    setIsSavingInProgress(true);
    await changeUserInfo({ id, firstName, lastName });
    setIsSavingInProgress(false);
  };

  const changeEmail = () => {
    openChangeEmail();
  };

  const isUserInfoChanged = () => userFirstName === firstName.trim() && userLastName === lastName.trim();

  const handleImageUpload = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const photo = target.files![0];
    changeUserPhoto(photo);
  };

  return (
    <div className={styles.profilePage}>
      <div className={styles.profileHeader}>
        <div className={styles.profileHeader__title}>
          <h2>My Account</h2>
        </div>
        <div className={styles.profileHeader__user}>
          <div className={styles.profileHeader__photo}>
            <UserAvatar firstName={userFirstName} lastName={userLastName} imageId={imageId} size="large" />
          </div>
          <div className={styles.profileHeader__info}>
            <div className={styles.profileHeader__name}>
              {userFirstName} {userLastName}
            </div>
            <ImageUpload onImageUpload={handleImageUpload} title="Upload Photo" />
          </div>
        </div>
      </div>
      <div className={styles.profileBody}>
        <div className={styles.profileBody__account}>
          <div className={styles.profileBody__accountInformation}>
            <div className={styles.profileBody__title}>
              <h4>My Information</h4>
            </div>
            <div className={styles.profileBody__information}>
              <div className={styles.profileBody__infoItem}>
                <TextInput
                  type="text"
                  labelTitle="First Name"
                  onChange={handleFirstNameChange}
                  errorMessage={firstNameError}
                  value={firstName}
                />
              </div>
              <div className={styles.profileBody__infoItem}>
                <TextInput
                  type="text"
                  labelTitle="Last Name"
                  onChange={handleSecondNameChange}
                  errorMessage={secondNameError}
                  value={lastName}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.profileBody__email} ${styles.section}`}>
            <div className={styles.profileBody__emailTitle}>
              <p className={styles.label}>Email Address</p>
            </div>
            <div className={`${styles.profileBody__emailContent} ${styles.sectionContent}`}>
              <h5>{email}</h5>
              <Button kind="secondary" size="default" onClick={changeEmail}>
                Change Email
              </Button>
            </div>
          </div>
          <div className={`${styles.profileBody__phone} ${styles.section}`}>
            <div className={styles.profileBody__phoneTitle}>
              <p className={styles.label}>Phone</p>
            </div>
            <div className={`${styles.profileBody__phoneContent} ${styles.sectionContent}`}>
              <h5>{isMfaEnabled ? phone : 'Not Authenticated'}</h5>
              {isMfaEnabled ? (
                <Button kind="warning" size="default" onClick={managePhoneAuthentication}>
                  Disable Phone Authentication
                </Button>
              ) : (
                <Button kind="secondary" size="default" onClick={managePhoneAuthentication}>
                  Enable Phone Authentication
                </Button>
              )}
            </div>
          </div>
          <div className={styles.profileBody__save}>
            <Button
              kind="primary"
              size="form"
              onClick={handleSaveChanges}
              disabled={isUserInfoChanged()}
              isLoading={isSavingInProgress}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
