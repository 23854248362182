import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortLabelsAction } from './ActionCreator';
import { LabelsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemLabelsState;

  return sortingType;
};

const saveTriggerActions = [isActionOfType<SortLabelsAction>(LoanOriginationSystemLabelsActionTypes.SortLabels)];

const applicationLabelsSortingSaver = FilterAndSortingSaver<LabelsSortingType>({
  key: 'applicationLabels',
  getSortingToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemLabelsActionTypes.ResetState,
});

export default applicationLabelsSortingSaver;
