import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

export const getCardsFields = (cards: Card[] | null) => {
  if (!cards) {
    return [];
  }

  return cards.reduce((previousFields, card) => ([...previousFields, ...card.fields]), [] as BaseVariableConfiguration[]);
};
