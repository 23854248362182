import React, { FC, ReactNode } from 'react';
import stringMatches, { StringFragment } from 'utils/stringMatches';

import styles from './SearchHighlight.module.scss';

interface SearchHighlightProps {
  search: string;
  caseSensitive?: boolean;
  multiWord?: boolean;
  wrapper?: WrapperFunction;
}

const handleCase = (value: string, isCaseSensitive: boolean) =>
  isCaseSensitive || !value ? value : value.toLowerCase();

interface WrapperFunction {
  (string: string, match: StringFragment, index: number): ReactNode;
}

const wrapperDefault: WrapperFunction = (string, { start, end, isMatched }, index) => {
  const subString = string.slice(start, end);
  if (!isMatched) {
    return subString;
  }
  return (
    <span key={index} className={styles.searchHighlight}>
      {subString}
    </span>
  );
};

const SearchHighlight: FC<SearchHighlightProps> = ({
  children,
  search,
  caseSensitive = false,
  multiWord = false,
  wrapper = wrapperDefault,
}) => {
  if (typeof children !== 'string') {
    /**
     * The expected cases where children are of a type other that "string":
     * 1. there's no content to highlight (missing a property);
     * 2. there's no content and a dummy element is provided.
     */
    return <>{children}</>;
  }

  const matches = stringMatches(handleCase(children, caseSensitive), handleCase(search, caseSensitive), multiWord);

  return <>{matches ? matches.map((match, index) => wrapper(children, match, index)) : children}</>;
};

export default SearchHighlight;
