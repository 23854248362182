import { schema as normalizrSchema, normalize } from 'normalizr';

type RequestFunction<Model> = () => Promise<Model[] | Model>;


const requestWithNormalize = async <Entities, Model>(
  request: RequestFunction<Model>,
  schema: normalizrSchema.Entity | normalizrSchema.Entity[],
) => {
  const data = await request();

  const normalizedData = normalize(data, schema);

  return {
    entities: normalizedData.entities as unknown as Entities,
    ids: normalizedData.result,
  }
};

export default requestWithNormalize;
