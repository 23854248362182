import React from 'react';
import SIZES from 'components/SendGrid/TemplatesTable/TableColumnSizes';
import styles from './TemplatesTable.module.scss';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TemplatesTableBody from './TemplatesTableBody';
import SkeletonTemplatesTableBody from './SkeletonTemplatesTableBody';
import TableActionCell from 'components/Table/TableActionCell';
import { SendGridTemplate } from 'SendGrid/Types';
import { NullableItems } from 'pagination';
import {
  SendGridTemplatesSortingField,
  SendGridTemplatesSortingType,
} from 'api/LoanOriginationSystem/SendGridTemplateApi';
import useSorting from 'hooks/useSorting';

interface TableProps {
  source: NullableItems<SendGridTemplate>;
  searchInputValue: string;
  onDeletePopupOpen: (id: string) => void;
  openEditTemplateContextualView: (id: string) => void;
  onSort: (field: SendGridTemplatesSortingField, ascending: boolean) => void;
  sortingType: SendGridTemplatesSortingType;
}

const TemplatesTable = ({
  source,
  searchInputValue,
  onDeletePopupOpen,
  openEditTemplateContextualView,
  onSort,
  sortingType,
}: TableProps) => {
  const [changeSorting, getSortingType] = useSorting<SendGridTemplatesSortingField>(
    sortingType.field,
    sortingType.ascending,
    onSort,
  );

  const getTemplatesTableBody = () => {
    return source.map((template, index) => {
      if (!template) {
        return <SkeletonTemplatesTableBody key={index} />;
      }

      return (
        <TemplatesTableBody
          key={index}
          template={template}
          searchInputValue={searchInputValue}
          onDeletePopupOpen={onDeletePopupOpen}
          openEditTemplateContextualView={openEditTemplateContextualView}
        />
      );
    });
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell
            width={SIZES.NAME}
            ascending={getSortingType(SendGridTemplatesSortingField.Name)}
            onClick={() => changeSorting(SendGridTemplatesSortingField.Name)}
          >
            Template Name
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.UPDATED}
            ascending={getSortingType(SendGridTemplatesSortingField.UpdatedAt)}
            onClick={() => changeSorting(SendGridTemplatesSortingField.UpdatedAt)}
          >
            Updated
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.STATUS}
            ascending={getSortingType(SendGridTemplatesSortingField.Status)}
            onClick={() => changeSorting(SendGridTemplatesSortingField.Status)}
          >
            Status
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>{getTemplatesTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default TemplatesTable;
