export const GET_APPLICATION_DATA = 'GET_APPLICATION_DATA';
export const GET_APPLICATION_DATA_SUCCESS = 'GET_APPLICATION_DATA_SUCCESS';
export const GET_APPLICATION_DATA_FAILED = 'GET_APPLICATION_DATA_FAILED';
export const OPEN_CHANGE_APPLICATION_STATUS_POPUP = 'OPEN_CHANGE_APPLICATION_STATUS_POPUP';
export const CLOSE_CHANGE_APPLICATION_STATUS_POPUP = 'CANCEL_APPLICATION_STATUS_CHANGE';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';

export enum ApplicationPageActionType {
  UpdateApplicationVariables = 'loanOriginationSystemApplicationPage/updateApplicationVariables',
  UpdateApplicationVariablesSuccess = 'loanOriginationSystemApplicationPage/updateApplicationVariablesSuccess',
  UpdateApplicationVariablesFailure = 'loanOriginationSystemApplicationPage/updateApplicationVariablesFailure',
  GetApplicationVariables = 'loanOriginationSystemApplicationPage/getApplicationVariables',
  GetApplicationVariablesSuccess = 'loanOriginationSystemApplicationPage/getApplicationVariablesSuccess',
  GetApplicationVariablesFailed = 'loanOriginationSystemApplicationPage/getApplicationVariablesFailed',
  SortApplicationVariables = 'loanOriginationSystemApplicationPage/sortApplicationVariables',
  UpdateApplicationTeamMembers = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembers',
  UpdateApplicationTeamMembersSuccess = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembersSuccess',
  UpdateApplicationTeamMembersFailure = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembersFailure',
  AddApplicationLabel = 'loanOriginationSystemApplicationPage/addApplicationLabel',
  AddApplicationLabelSuccess = 'loanOriginationSystemApplicationPage/addApplicationLabelSuccess',
  AddApplicationLabelFailure = 'loanOriginationSystemApplicationPage/addApplicationLabelFailure',
  DeleteApplicationLabel = 'loanOriginationSystemApplicationPage/deleteApplicationLabel',
  DeleteApplicationLabelSuccess = 'loanOriginationSystemApplicationPage/deleteApplicationLabelSuccess',
  DeleteApplicationLabelFailure = 'loanOriginationSystemApplicationPage/deleteApplicationLabelFailure',
  ClearApplicationData = 'loanOriginationSystemApplicationPage/ClearApplicationData',
  UpdateApplicationCoBorrowers = 'loanOriginationSystemApplicationPage/updateApplicationCoBorrowers',
  UpdateApplicationCoBorrowersSuccess = 'loanOriginationSystemApplicationPage/updateApplicationCoBorrowersSuccess',
  UpdateApplicationCoBorrowersFailure = 'loanOriginationSystemApplicationPage/updateApplicationCoBorrowersFailure',
  UpdateApplicationIntermediary = 'loanOriginationSystemApplicationPage/updateApplicationIntermediary',
  UpdateApplicationIntermediarySuccess = 'loanOriginationSystemApplicationPage/updateApplicationIntermediarySuccess',
  UpdateApplicationIntermediaryFailure = 'loanOriginationSystemApplicationPage/updateApplicationIntermediaryFailure',
  UpdateApplicationBorrowerVariables = 'loanOriginationSystemApplicationPage/updateApplicationBorrowerVariables',
  UpdateApplicationBorrowerVariablesSuccess = 'loanOriginationSystemApplicationPage/updateApplicationBorrowerVariablesSuccess',
  UpdateApplicationBorrowerVariablesFailure = 'loanOriginationSystemApplicationPage/updateApplicationBorrowerVariablesFailure',
  UpdateApplicationIntermediaryVariables = 'loanOriginationSystemApplicationPage/updateApplicationIntermediaryVariables',
  UpdateApplicationIntermediaryVariablesSuccess = 'loanOriginationSystemApplicationPage/updateApplicationIntermediaryVariablesSuccess',
  UpdateApplicationIntermediaryVariablesFailure = 'loanOriginationSystemApplicationPage/updateApplicationIntermediaryVariablesFailure',
  SelectCoBorrower = 'loanOriginationSystemApplicationPage/selectCoBorrowerToEdit',
  SelectIntermediary = 'loanOriginationSystemApplicationPage/selectIntermediary',
}
