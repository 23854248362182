import React, { useState } from 'react';
import CheckboxMultiSelectList from 'components/CheckboxMultiSelectList';
import styles from './FilterForm.module.scss';
import Button from 'components/Button';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import TagLine from 'components/LoanOriginationSystem/ApplicationLabels/TagLine';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { IntermediaryNameOption } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import clsx from 'clsx';

export interface FilterFormResult {
  statuses: ApplicationStatus[];
  labels: Label[];
  intermediaries: IntermediaryNameOption[];
  createdDateRange: {
    from: Date | null;
    to: Date | null;
  };
  updatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

interface FilterFormProps {
  statuses: ApplicationStatus[];
  selectedStatusesIds: string[];
  labels: Label[];
  selectedLabels: Label[];
  intermediaries: IntermediaryNameOption[];
  selectedIntermediaries: IntermediaryNameOption[];
  createdDateFrom: Date | null;
  createdDateTo: Date | null;
  updatedDateFrom: Date | null;
  updatedDateTo: Date | null;
  hideStatusFilter: boolean;
  onApply?: (result: FilterFormResult) => void;
}

export const renderLabelIcon = (label: Label) => <TagLine minimized {...label} />;

const FilterForm = ({
  statuses,
  selectedStatusesIds: initialSelectedStatusesIds,
  labels,
  selectedLabels: initialSelectedLabels,
  intermediaries,
  selectedIntermediaries: initialSelectedIntermediaries,
  createdDateFrom,
  createdDateTo,
  updatedDateFrom,
  updatedDateTo,
  onApply,
  hideStatusFilter,
}: FilterFormProps) => {
  const [selectedStatuses, setSelectedStatuses] = useState(
    statuses.filter(({ id }) => initialSelectedStatusesIds.includes(id)),
  );

  const [labelSelectProps, selectedLabels] = useAutoCompletionMultiSelectProps<Label>(
    labels,
    initialSelectedLabels,
    (label) => label.id,
    (label) => label.name,
    renderLabelIcon,
    renderLabelIcon,
  );

  const [intermediarySelectProps, selectedIntermediaries] = useAutoCompletionMultiSelectProps(
    intermediaries,
    initialSelectedIntermediaries,
    (intermediary) => intermediary.id,
    (intermediary) => intermediary.name,
  );

  const [createdAtFrom, setCreatedAtFrom] = useState(createdDateFrom);
  const [createdAtTo, setCreatedAtTo] = useState(createdDateTo);

  const [updatedAtFrom, setUpdatedAtFrom] = useState(updatedDateFrom);
  const [updatedAtTo, setUpdatedAtTo] = useState(updatedDateTo);

  const onStatusesListChange = (statusId: string) => {
    const newSelectedStatuses = selectedStatuses.map((status) => status.id).includes(statusId)
      ? selectedStatuses.filter((status) => status.id !== statusId)
      : [...selectedStatuses, statuses.find((status) => status.id === statusId)!];

    setSelectedStatuses(newSelectedStatuses);
  };

  const onApplyClick = () => {
    onApply?.({
      statuses: selectedStatuses,
      labels: selectedLabels,
      intermediaries: selectedIntermediaries,
      createdDateRange: {
        from: createdAtFrom,
        to: createdAtTo,
      },
      updatedDateRange: {
        from: updatedAtFrom,
        to: updatedAtTo,
      },
    });
  };

  const handleCreatedDateChange = (from: Date | null, to: Date | null) => {
    setCreatedAtFrom(from);
    setCreatedAtTo(to);
  };

  const handleLastUpdatedDateChange = (from: Date | null, to: Date | null) => {
    setUpdatedAtFrom(from);
    setUpdatedAtTo(to);
  };

  return (
    <div>
      {!hideStatusFilter && (
        <>
          <p className={styles.title}>Application Status</p>
          <CheckboxMultiSelectList
            items={statuses.map((status) => ({ id: status.id, label: status.name }))}
            selectedItemsIds={selectedStatuses.map((status) => status.id)}
            onChange={onStatusesListChange}
          />
        </>
      )}
      <AutoCompletionMultiselect
        id="labelsSelect"
        labelTitle="Label"
        containerClassName={clsx(hideStatusFilter && styles.labelSelectContainer)}
        isLabelTags
        {...labelSelectProps}
      />
      <AutoCompletionMultiselect id="intermediarySelect" labelTitle="Intermediary" {...intermediarySelectProps} />
      <DateRangePicker
        label="Application Date Range"
        from={createdAtFrom}
        to={createdAtTo}
        onChange={handleCreatedDateChange}
      />
      <DateRangePicker
        label="Last Updated Date Range"
        from={updatedAtFrom}
        to={updatedAtTo}
        onChange={handleLastUpdatedDateChange}
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onApplyClick}>
        Apply Filter
      </Button>
    </div>
  );
};

export default FilterForm;
