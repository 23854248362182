import React, { FC } from 'react';
import styles from 'components/DecisionResultOverview/DecisionResultOverview.module.scss';
import clsx from 'clsx';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { SkeletonRectangle, SkeletonCircle } from 'components/Skeleton';
import DecisionResultInfoSkeleton from 'components/DecisionResultOverview/Overview/DecisionResultInfoSkeleton';

const SKELETON_CELL_WIDTH = 200;
const VARIABLES_COLUMNS_COUNT = 2;

const DecisionResultOverviewSkeleton: FC = () => {
  const renderVariablesSkeleton = (rowsCount: number) => {
    return Array(rowsCount)
      .fill(null)
      .map((_, index) => (
        <TableRow disableHover key={index} className={clsx(index === 0 && styles.firstVariableSkeleton)}>
          <TableBodyCell width={SKELETON_CELL_WIDTH}>
            <SkeletonRectangle width="200px" color="primary6" height="14px" />
          </TableBodyCell>
          <TableBodyCell width={SKELETON_CELL_WIDTH}>
            <SkeletonRectangle width="200px" color="primary6" height="14px" />
          </TableBodyCell>
        </TableRow>
      ));
  };

  return (
    <div className={styles.page}>
      <div className={styles.caseHeader}>
        <div className={styles.caseHeader__title}>
          <SkeletonRectangle width="600px" height="25px" color="primary20" />
        </div>
        <div className={styles.headerButtonContainer}>
          <SkeletonRectangle width="144px" height="44px" color="primary6" marginRight="12px" />
          <SkeletonRectangle width="44px" height="44px" color="primary6" />
        </div>
      </div>
      <div className={styles.caseInfo}>
        <div className={styles.caseUpdateInfo}>
          <SkeletonCircle width="24px" height="24px" color="primary10" />
          <SkeletonRectangle width="480px" height="16px" color="primary6" className={styles.createdDateSkeleton} />
        </div>
      </div>
      <div className={styles.caseContent}>
        <DecisionResultInfoSkeleton />
        <div className={styles.decisionResultInfo}>
          <SkeletonRectangle width="240px" height="16px" color="primary20" />
          <div className={styles.processingDetailsContainer}>
            {renderVariablesSkeleton(3)}
          </div>
        </div>
        <div className={styles.variableContainer}>
          {new Array(VARIABLES_COLUMNS_COUNT).fill(null).map((_, index) => (
            <div key={index}>
              <SkeletonRectangle width="240px" height="16px" color="primary20" marginBottom="20px" />
              {renderVariablesSkeleton(6)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DecisionResultOverviewSkeleton;
