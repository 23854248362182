import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import Table, { TableBodyContent } from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableActionCell from 'components/Table/TableActionCell';
import TableBody from 'components/Table/TableBody';
import SkeletonEmailLine from './SkeletonEmailLine';
import EmailLine from './EmailLine';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import { EmailsColumnSizes } from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable/TableColumnSizes';
import { Email, EmailsSortingType, EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';

interface EmailsTableProps {
  emails: NullableItems<Email>;
  paginationProps: PaginationProps;
  sortingType: EmailsSortingType;
  onSort: (field: EmailsSortingField, ascending: boolean) => void;
  onOpenEmail: (emailId: string) => void;
  onDeleteEmail: (emailId: string) => void;
  organizationEmail: string;
  searchValue: string;
  stickyMarginTop?: number;
}

const EmailsTable = ({
  emails,
  paginationProps,
  sortingType,
  onSort,
  onOpenEmail,
  onDeleteEmail,
  organizationEmail,
  searchValue,
  stickyMarginTop,
}: EmailsTableProps) => {
  const renderRow = (email: Email) => (
    <EmailLine
      email={email}
      key={email.id}
      onOpenEmail={onOpenEmail}
      onDeleteEmail={onDeleteEmail}
      organizationEmail={organizationEmail}
      searchValue={searchValue}
    />
  );

  const renderSkeletonRow = (index: number) => {
    return <SkeletonEmailLine key={index} />;
  };

  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  return (
    <div>
      <div>
        <Table>
          <TableHead stickyMarginTop={stickyMarginTop} sticky>
            <TableHeadCell
              width={EmailsColumnSizes.CreatedAt}
              ascending={getSortingType(EmailsSortingField.CreatedAt)}
              onClick={() => changeSorting(EmailsSortingField.CreatedAt)}
            >
              Date
            </TableHeadCell>
            <TableHeadCell
              width={EmailsColumnSizes.SentFrom}
              ascending={getSortingType(EmailsSortingField.SentFrom)}
              onClick={() => changeSorting(EmailsSortingField.SentFrom)}
            >
              From
            </TableHeadCell>
            <TableHeadCell
              width={EmailsColumnSizes.SentTo}
              ascending={getSortingType(EmailsSortingField.SentTo)}
              onClick={() => changeSorting(EmailsSortingField.SentTo)}
            >
              To
            </TableHeadCell>
            <TableHeadCell
              width={EmailsColumnSizes.Subject}
              ascending={getSortingType(EmailsSortingField.Subject)}
              onClick={() => changeSorting(EmailsSortingField.Subject)}
            >
              Subject
            </TableHeadCell>
            <TableHeadCell width={EmailsColumnSizes.Attachments} />
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent rows={emails} renderSkeletonTableRow={renderSkeletonRow} renderTableRow={renderRow} />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default EmailsTable;
