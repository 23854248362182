import React from 'react';
import { Switch, Route } from 'react-router';
import SignIn from 'pages/SignIn';
import SelectOrganization from 'pages/SelectOrganization';
import SecurePhoneAuthentication from 'pages/SecurePhoneAuthentication';
import ResetPassword from 'pages/ResetPassword';
import ForgotPassword from 'pages/ForgotPassword';
import CreateAccountInvite from 'pages/CreateAccountInvite';
import { AppRoutes } from 'routes/RouteService';
import CreateAccount from 'pages/CreateAccount';
import UserNotActive from 'pages/UserNotActive';
import LoggedOut from 'pages/LoggedOut';
import ActivateAccount from 'pages/ActivateAccount';
import PastDueSubscription from 'pages/PastDueSubscription';
import CancelledSubscription from 'pages/CancelledSubscription';
import EnvironmentWrapper from 'components/EnvironmentWrapper';

const CreateAccountRouteWrapper = EnvironmentWrapper(
  Route,
  process.env.REACT_APP_DISABLE_CREATE_ACCOUNT?.toLocaleLowerCase() || undefined,
  'false',
  undefined,
);

const Auth = () => {
  return (
    <Switch>
      <Route path={AppRoutes.SignIn}>
        <SignIn />
      </Route>
      <Route path={AppRoutes.SelectOrganization}>
        <SelectOrganization />
      </Route>
      <Route path={AppRoutes.Mfa}>
        <SecurePhoneAuthentication />
      </Route>
      <Route path={AppRoutes.ForgotPassword}>
        <ForgotPassword />
      </Route>
      <Route path={AppRoutes.ResetPassword}>
        <ResetPassword />
      </Route>
      <Route path={AppRoutes.AcceptInvite}>
        <CreateAccountInvite />
      </Route>
      <CreateAccountRouteWrapper path={AppRoutes.CreateAccount}>
        <CreateAccount />
      </CreateAccountRouteWrapper>
      <Route path={AppRoutes.InactiveUser}>
        <UserNotActive />
      </Route>
      <Route path={AppRoutes.LoggedOut}>
        <LoggedOut />
      </Route>
      <Route path={AppRoutes.Activation}>
        <ActivateAccount />
      </Route>
      <Route path={AppRoutes.PastDueSubscription}>
        <PastDueSubscription />
      </Route>
      <Route path={AppRoutes.CancelledSubscription}>
        <CancelledSubscription />
      </Route>
    </Switch>
  );
};

export default Auth;
