import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getVariablesSuggestions } from 'VariablesSuggestions/VariablesSuggestionsStore';

const useVariablesSuggestions = () => {
  const dispatch = useDispatch();

  const { suggestions } = useSelector((state: ReduxState) => state.variablesSuggestions);

  useEffect(() => {
    if (suggestions) {
      return;
    }

    dispatch(getVariablesSuggestions());
  }, []);

  return suggestions;
};

export default useVariablesSuggestions;
