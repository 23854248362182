import { matchPath } from 'react-router';

const isPathMatches = (path: string) => (pathName: string) => {
  if (matchPath(pathName, { path })) {
    return true;
  }

  return false;
};

export default isPathMatches;
