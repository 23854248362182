import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { PermissionGroupId } from 'PermissionGroups/Types';

const useUserPermissionGroupId = () =>
  useSelector<ReduxState, PermissionGroupId | undefined>(
    (state: ReduxState) => state.sessionInfo.user?.permissionGroupId,
  );

export default useUserPermissionGroupId;
