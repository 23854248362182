import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationVariableConfigurationsRestApi, {
  CreateApplicationVariableConfigurationParams,
} from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import responseHandlers from 'api/ResponseHandlers';
import {
  BaseUpdateVariableConfigurationParams,
  UpdateVariableConfigurationConditionalDisplayRuleParams,
  UpdateVariableConfigurationRequiredAttribute,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

type UpdateApplicationVariableConfigurationPositionParams = Pick<BaseUpdateVariableConfigurationParams, 'position' | 'cardId' | 'column'> & {
  id: string;
  sourceCardId: string;
}

const applicationVariableConfigurationsApi = new ApplicationVariableConfigurationsRestApi(responseHandlers);

export const createApplicationVariableConfiguration = createAsyncThunk(
  'applicationVariableConfigurations/createApplicationVariableConfiguration',
  async (params: CreateApplicationVariableConfigurationParams) => {
    const configuration = await applicationVariableConfigurationsApi.create(params);

    return {
      configuration,
    };
  },
);

export const updateApplicationVariableConfigurationPosition = createAsyncThunk(
  'applicationVariableConfigurations/updateApplicationVariableConfigurationPosition',
  async (params: UpdateApplicationVariableConfigurationPositionParams) => {
    const { id, sourceCardId, ...updateParams } = params;

    const configuration = await applicationVariableConfigurationsApi.update(id, updateParams);

    return {
      configuration,
      sourceCardId,
    };
  },
);

export const updateConditionalDisplayRule = createAsyncThunk(
  'applicationVariableConfigurations/updateConditionalDisplayRule',
  async (params: UpdateVariableConfigurationConditionalDisplayRuleParams) => {
    const configuration = await applicationVariableConfigurationsApi.update(params.id, {
      conditionalDisplayRule: params.conditionalDisplayRule,
    });

    return {
      configuration,
    };
  },
);

export const updateApplicationVariableConfigurationRequiredAttribute = createAsyncThunk(
  'applicationVariableConfigurations/updateApplicationVariableConfigurationRequiredAttribute',
  async (params: UpdateVariableConfigurationRequiredAttribute) => {
    const { id, required } = params;

    const configuration = await applicationVariableConfigurationsApi.update(id, {
      required,
    });

    return {
      configuration,
    };
  },
);

export const deleteApplicationVariableConfiguration = createAsyncThunk(
  'applicationVariableConfigurations/deleteApplicationVariableConfiguration',
  async (id: string) => {
    await applicationVariableConfigurationsApi.delete(id);

    return {
      id,
    };
  },
);
