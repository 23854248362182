import React, { FC } from 'react';
import { Tag } from 'components/Tag';

interface UserStatusTagProps {
  isActive: boolean;
  userId?: string;
}

const UserStatusTag: FC<UserStatusTagProps> = ({ isActive, userId }) => {
  return (
    <>
      {isActive && userId && <Tag color="green">Active</Tag>}
      {isActive && !userId && <Tag color="blue">Pending</Tag>}
      {!isActive && <Tag color="gray">Inactive</Tag>}
    </>
  );
};

export default UserStatusTag;
