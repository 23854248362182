import React, { FC, useEffect, useState, useMemo } from 'react';
import {
  DocuSignAccountBaseUri,
  DocuSignEnvironmentType,
  DocuSignIntegration,
  DocuSignIntegrationSettings,
} from 'api/Core/DocuSignIntegrationApi';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import ButtonWithImage from 'components/ButtonWithImage';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import useInputValidation from 'hooks/useInputValidation';
import useConfirmChanges from 'hooks/useConfirmChanges';
import ConfirmPopup from 'components/ConfirmPopup';
import DocuSignCredentialsSkeleton from './DocuSignCredentialsSkeleton';
import styles from './DocuSignCredentials.module.scss';

interface DocuSignCredentialsProps {
  integration: DocuSignIntegration | null;
  isIntegrationLoaded: boolean;
  integrationSavingInProgress: boolean;
  onSaveIntegration: (integrationSettings: DocuSignIntegrationSettings) => void;
}

const ENVIRONMENT_OPTIONS = [{
  value: DocuSignEnvironmentType.Development,
  name: 'Developer Mode',
}, {
  value: DocuSignEnvironmentType.Production,
  name: 'Production Mode',
}];

const API_BASE_PATH_OPTIONS_BY_ENV_TYPE = {
  [DocuSignEnvironmentType.Development]: [{
    value: DocuSignAccountBaseUri.Demo,
    name: DocuSignAccountBaseUri.Demo,
  }],
  [DocuSignEnvironmentType.Production]: [{
    value: DocuSignAccountBaseUri.Base,
    name: DocuSignAccountBaseUri.Base,
  },
  {
    value: DocuSignAccountBaseUri.NA2,
    name: DocuSignAccountBaseUri.NA2,
  }, {
    value: DocuSignAccountBaseUri.NA3,
    name: DocuSignAccountBaseUri.NA3,
  }, {
    value: DocuSignAccountBaseUri.NA4,
    name: DocuSignAccountBaseUri.NA4,
  }, {
    value: DocuSignAccountBaseUri.EU,
    name: DocuSignAccountBaseUri.EU,
  }, {
    value: DocuSignAccountBaseUri.AU,
    name: DocuSignAccountBaseUri.AU,
  }, {
    value: DocuSignAccountBaseUri.CA,
    name: DocuSignAccountBaseUri.CA,
  }],
};

const ONE_LINE_INPUT_MAX_LENGTH = 100;

const DocuSignCredentials: FC<DocuSignCredentialsProps> = ({
  integration,
  isIntegrationLoaded,
  onSaveIntegration,
  integrationSavingInProgress,
}) => {
  const [environment, setEnvironment] = useState(integration?.settings.environment || DocuSignEnvironmentType.Development);
  const [accountBaseUri, setAccountBaseUri] = useState(integration?.settings.accountBaseUri || DocuSignAccountBaseUri.Demo);
  const [clientId, setClientId] = useState(integration?.settings.clientId || '');
  const [userId, setUserId] = useState(integration?.settings.userId || '');
  const [privateKey, setPrivateKey] = useState(integration?.settings.privateKey || '');
  const [accountId, setAccountId] = useState(integration?.settings.accountId || '');
  const [isEditMode, setIsEditMode] = useState(false);

  const [
    displayConfirmUpdateCredentials,
    onResetConfirmUpdateCredentials,
    onConfirmUpdateCredentials,
    useConfirmUpdateCredentialsCallback,
  ] = useConfirmChanges(!!integration);

  const resetData = () => {
    if (!integration) {
      return;
    }

    setEnvironment(integration.settings.environment);
    setClientId(integration.settings.clientId);
    setUserId(integration.settings.userId);
    setPrivateKey(integration.settings.privateKey);
    setAccountId(integration.settings.accountId);
    setAccountBaseUri(integration.settings.accountBaseUri);
  };

  const handleSave = useConfirmUpdateCredentialsCallback(() => {
    onSaveIntegration({
      clientId,
      userId,
      privateKey,
      accountId,
      environment,
      accountBaseUri,
    });

    onResetConfirmUpdateCredentials();
  });

  const [onEnvironmentBlur, onEnvironmentFocus, environmentErrorMessage] = useInputValidation({
    value: environment,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setEnvironment(value as DocuSignEnvironmentType),
  });

  const [onAccountBaseUriBlur, onAccountBaseUriFocus, accountBaseUriErrorMessage] = useInputValidation({
    value: accountBaseUri,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setAccountBaseUri(value as DocuSignAccountBaseUri),
  });

  const [onClientIdBlur, onClientIdFocus, clientIdErrorMessage] = useInputValidation({
    value: clientId,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setClientId(value),
  });

  const [onUserIdBlur, onUserIdFocus, userIdErrorMessage] = useInputValidation({
    value: userId,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setUserId(value),
  });

  const [onAccountIdBlur, onAccountIdFocus, accountIdErrorMessage] = useInputValidation({
    value: accountId,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setAccountId(value),
  });

  const [onPrivateKeyBlur, onPrivateKeyFocus, privateKeyErrorMessage] = useInputValidation({
    value: privateKey,
    required: true,
    disableResetValueOnError: true,
    onChange: (value) => setPrivateKey(value),
  });

  const currentEnvironmentOption = useMemo(() => {
    return ENVIRONMENT_OPTIONS.find((environmentOption) => environmentOption.value === environment);
  }, [environment]);

  const apiBasePathOptions = API_BASE_PATH_OPTIONS_BY_ENV_TYPE[environment];

  const isIntegrationConfigured = () => !!(environment && privateKey && accountId && userId && clientId);

  const handleChangeEditMode = () => {
    setIsEditMode(!isEditMode);

    if (isEditMode) {
      resetData();
    }
  }

  const isSubmitAvailable = () => {
    if (!integration) {
      return isIntegrationConfigured();
    }

    const isIntegrationSettingsChanged =
      environment !== integration.settings.environment
      || accountBaseUri !== integration.settings.accountBaseUri
      || privateKey !== integration.settings.privateKey
      || clientId !== integration.settings.clientId
      || accountId !== integration.settings.accountId
      || userId !== integration.settings.userId;

    return isIntegrationConfigured() && isIntegrationSettingsChanged;
  };

  useEffect(() => {
    if (integration) {
      resetData();
      setIsEditMode(false);
    }
  }, [integration]);

  const handleDocuSignEnvironmentChange = (option: Option) => {
    const updatedEnvironment = option.value as DocuSignEnvironmentType;

    setEnvironment(updatedEnvironment);

    setAccountBaseUri(
      updatedEnvironment === DocuSignEnvironmentType.Development
        ? DocuSignAccountBaseUri.Demo
        : DocuSignAccountBaseUri.Base,
    );
  }
  const handleAccountBaseUriChange = (option: Option) => setAccountBaseUri(option.value as DocuSignAccountBaseUri);
  const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => setClientId(event.target.value);
  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => setUserId(event.target.value);
  const handleAccountIdChange = (event: React.ChangeEvent<HTMLInputElement>) => setAccountId(event.target.value);
  const handlePrivateKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => setPrivateKey(event.target.value);

  const renderForm = () => (
    <>
      <div className={styles.inputsContainer}>
        <div className={styles.inputsRow}>
          <AutoCompletion
            required
            onBlur={onEnvironmentBlur}
            onFocus={onEnvironmentFocus}
            className={styles.autoCompletionInput}
            labelTitle="DocuSign Environment"
            options={ENVIRONMENT_OPTIONS}
            value={environment}
            onChange={handleDocuSignEnvironmentChange}
            errorMessage={environmentErrorMessage}
            hideClearIcon
          />
          <AutoCompletion
            required
            onBlur={onAccountBaseUriBlur}
            onFocus={onAccountBaseUriFocus}
            className={styles.autoCompletionInput}
            labelTitle="Account Base URL"
            options={apiBasePathOptions}
            value={accountBaseUri}
            onChange={handleAccountBaseUriChange}
            errorMessage={accountBaseUriErrorMessage}
            hideClearIcon
          />
        </div>
        <div className={styles.inputsRow}>
          <TextInput
            required
            onBlur={onClientIdBlur}
            onFocus={onClientIdFocus}
            labelTitle="Integration Key"
            onChange={handleClientIdChange}
            value={clientId}
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            errorMessage={clientIdErrorMessage}
          />
          <TextInput
            required
            onBlur={onUserIdBlur}
            onFocus={onUserIdFocus}
            labelTitle="User ID"
            onChange={handleUserIdChange}
            value={userId}
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            errorMessage={userIdErrorMessage}
          />
        </div>
        <div className={styles.inputsRow}>
          <TextInput
            required
            containerClassName={styles.accountIdInput}
            labelTitle="API Account ID"
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            onChange={handleAccountIdChange}
            onBlur={onAccountIdBlur}
            onFocus={onAccountIdFocus}
            errorMessage={accountIdErrorMessage}
            value={accountId}
          />
        </div>
        <div className={styles.inputsRow}>
          <TextInput
            required
            containerClassName={styles.privateKeyInputContainer}
            labelTitle="RSA Private Key"
            onChange={handlePrivateKeyChange}
            onBlur={onPrivateKeyBlur}
            onFocus={onPrivateKeyFocus}
            errorMessage={privateKeyErrorMessage}
            multiline
            value={privateKey}
          />
        </div>
      </div>
    </>
  );

  const renderCredentialsRow = (title: string, value: string) => {
    return (
      <div className={styles.credentialsRow}>
        <p>{title}</p>
        <p>{value}</p>
      </div>
    );
  };

  const renderCredentials = () => (
    <div className={styles.credentialsTable}>
      {renderCredentialsRow(
        'DocuSign Environment',
        integration && currentEnvironmentOption ? currentEnvironmentOption.name : '',
      )}
      {renderCredentialsRow('Account Base URL', integration ? accountBaseUri : '')}
      {renderCredentialsRow('Integration Key', clientId)}
      {renderCredentialsRow('User ID', userId)}
      {renderCredentialsRow('API Account ID', accountId)}
      {renderCredentialsRow('RSA Private Key', privateKey)}
    </div>
  );

  const renderHeaderButtons = () => {
    if (!isIntegrationLoaded) {
      return null;
    }

    return (
      <>
        {!isEditMode && <ButtonWithImage
          className={styles.editButton}
          onClick={handleChangeEditMode}
          title="Edit Credentials"
          kind="edit"
        />}
        {isEditMode && <div className={styles.rightButtonActions}>
          <Button className={styles.closeEditorButton} kind="secondary" onClick={handleChangeEditMode}>
            Close Editor
          </Button>
          <Button
            className={styles.saveChangesButton}
            isLoading={integrationSavingInProgress}
            kind="primary"
            onClick={handleSave}
            disabled={!isSubmitAvailable()}
          >
            Save Changes
          </Button>
        </div>}
      </>
    );
  };

  const renderContent = () => {
    if (!isIntegrationLoaded) {
      return <DocuSignCredentialsSkeleton />;
    }

    if (isEditMode) {
      return renderForm();
    }

    return renderCredentials();
  };

  return (
    <div>
      <div className={styles.header}>
        <h4>Credentials</h4>
        {renderHeaderButtons()}
      </div>
      <div>{renderContent()}</div>
      {displayConfirmUpdateCredentials && <ConfirmPopup
        usePortal
        title="Confirm Credentials Change"
        message="Updating your DocuSign credentials will cause all of your existing templates to be removed from the DigiFi system."
        confirmText="Yes, Update Credentials"
        declineText="No, Go Back"
        onPopupClose={onResetConfirmUpdateCredentials}
        onConfirmClick={onConfirmUpdateCredentials}
      />}
    </div>
  );
};

export default DocuSignCredentials;
