import React, { useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import {
  ApplicationStatus,
  PermissionsField,
  ApplicationStatusPermissions,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { PermissionGroupCheckboxMultiSelectOption } from 'PermissionGroups/Types';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import DndList from 'components/DndList';
import ApplicationStatusCard from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationWorkflowSetup/ApplicationStatusCard';
import ButtonWithImage from 'components/ButtonWithImage';
import styles from './ApplicationStatusesList.module.scss';

export interface ApplicationStatusesListProps {
  statuses: ApplicationStatus[];
  permissionGroups: PermissionGroupCheckboxMultiSelectOption[];
  statusUpdateIds: string[];
  onApplicationStatusPermissionsChange: (
    statusId: string,
    permissionsField: PermissionsField,
    permissions: ApplicationStatusPermissions,
  ) => void;
  onApplicationStatusNameUpdate: (statusId: string, name: string) => Promise<void>;
  onApplicationStatusCreate: () => void;
  onApplicationStatusReorder: (index: number, sourceIndex: number) => void;
  onApplicationStatusDelete: (status: ApplicationStatus) => void;
  onApplicationStatusRuleAdd: (status: ApplicationStatus) => void;
  onApplicationStatusRuleEdit: (statusRule: ApplicationStatusRule) => void;
  onApplicationStatusRuleDelete: (status: ApplicationStatus, rule: ApplicationStatusRule) => void;
}

const STATUSES_INDEX_SKIP = 1;
const DEFAULT_DISABLED_STATUS_INDEX = 0;
const MAX_STATUSES_LENGTH = 20;

const ApplicationStatusesList = ({
  statuses,
  permissionGroups,
  statusUpdateIds,
  onApplicationStatusNameUpdate,
  onApplicationStatusCreate,
  onApplicationStatusReorder,
  onApplicationStatusDelete,
  onApplicationStatusPermissionsChange,
  onApplicationStatusRuleAdd,
  onApplicationStatusRuleEdit,
  onApplicationStatusRuleDelete,
}: ApplicationStatusesListProps) => {
  const { disabledStatus, customStatuses } = useMemo(() => {
    const [firstStatus, ...restStatuses] = statuses;

    return {
      disabledStatus: firstStatus,
      customStatuses: restStatuses,
    };
  }, [statuses]);

  const handleReorder = (_updatedStatuses: ApplicationStatus[], result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    onApplicationStatusReorder(destination.index + STATUSES_INDEX_SKIP, source.index + STATUSES_INDEX_SKIP);
  };

  const renderListItem = (status: ApplicationStatus, index: number, isDragging?: boolean) => (
    <ApplicationStatusCard
      isStatusNameEditable
      className={clsx({ [styles.defaultDisabledStatus]: DEFAULT_DISABLED_STATUS_INDEX === index })}
      displayDragImage={index !== DEFAULT_DISABLED_STATUS_INDEX}
      statusRulesSectionDisabled={index === DEFAULT_DISABLED_STATUS_INDEX}
      addStatusRuleButtonTooltip={index === DEFAULT_DISABLED_STATUS_INDEX && 'Status rules cannot be added to the first status.'}
      isDragging={isDragging}
      isUpdating={statusUpdateIds.includes(status.id)}
      status={status}
      permissionGroups={permissionGroups}
      onStatusRuleAdd={onApplicationStatusRuleAdd}
      onStatusRuleEdit={onApplicationStatusRuleEdit}
      onStatusRuleDelete={onApplicationStatusRuleDelete}
      onPermissionsChange={onApplicationStatusPermissionsChange}
      onStatusNameUpdate={onApplicationStatusNameUpdate}
      onDelete={onApplicationStatusDelete}
      statusIndex={index + 1}
      displayRowActions={index !== DEFAULT_DISABLED_STATUS_INDEX}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.statusesListContent}>
        {renderListItem(disabledStatus, DEFAULT_DISABLED_STATUS_INDEX)}
        <DndList
          handleReorder={handleReorder}
          items={customStatuses}
          withPlaceholder
          droppableId="statuses"
          direction="horizontal"
          listItemClassName={styles.statusCard}
          listClassName={styles.applicationStatusesList}
          renderListItem={(item, index, snapshot) => renderListItem(item, index + 1, snapshot.isDragging)}
        />
        <ButtonWithImage
          className={styles.addStatusButton}
          disabled={statuses.length >= MAX_STATUSES_LENGTH}
          kind="add"
          title={`Add Status ${statuses.length + 1}`}
          onClick={onApplicationStatusCreate}
        />
      </div>
    </div>
  );
};

export default ApplicationStatusesList;
