import { useState } from 'react';

const useBlockingRequest = () => {
  const [isBlockingRequestInProgress, setIsBlockingRequestInProgress] = useState(false);

  const wrapBlockingRequest = async (request: () => Promise<unknown>) => {
    try {
      setIsBlockingRequestInProgress(true);

      await request();
    } finally {
      setIsBlockingRequestInProgress(false);
    }
  };

  const useBlockingRequestCallback = <Args extends unknown[]>(
    request: (...args: Args) => Promise<unknown>,
  ) => {
    return (...args: Args) => {
      return wrapBlockingRequest(() => request(...args));
    };
  }

  return [
    isBlockingRequestInProgress,
    useBlockingRequestCallback,
  ] as [boolean, typeof useBlockingRequestCallback];
};

export default useBlockingRequest;
