import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import DecisionApi, { Decision, DecisionResultType, DecisionsFilter } from 'api/DecisionEngine/DecisionApi';
import { DataViewSortingType } from 'api/Types';
import { ItemsStateWithPagination } from 'pagination';
import withStateReset from 'utils/reducers/withStateReset';
import decisionsPagination from 'pages/Decisions/Pagination';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import responseHandlers from 'api/ResponseHandlers';
import { runApplicationDecision, runIndividualDecision, runMultipleDecisions } from 'Decisions/RunDecisionStore';
import { isUndefined } from 'lodash';
import decisionsFilterAndSortingSaver from 'Decisions/FilterAndSortingSaver';
import { DecisionActionTypes } from 'Decisions/actionTypes';
import StrategyGroupApi from 'api/DecisionEngine/StrategyGroupApi';

export enum DecisionsSortingField {
  Date = 'date',
  Source = 'source',
  DecisionName = 'name',
  ExecutionTime = 'executionTime',
  StrategyName = 'strategyName',
  Result = 'passed',
}

export type DecisionsSortingFieldType = DataViewSortingType<DecisionsSortingField>;
export interface DecisionListFilters {
  decisionResults: DecisionResultType[];
  decisionSource: 'Api' | 'Web' | null;
  strategyStatus: NormalizedStatus | null;
  strategyName: string | null;
  decisionDate: {
    from: Date | null;
    to: Date | null;
  };
}

export interface DecisionsState extends ItemsStateWithPagination<Decision> {
  sortingType: DecisionsSortingFieldType;
  searchInputValue: string;
  isFiltersPopUpVisible: boolean;
  selectedMembers: UserInfo[];
  applicationId: string | null;
  runDecisionPopupOpen: boolean;
  filtersData: {
    strategyNames: string[];
  };
  filtersLoading: boolean;
  filters: DecisionListFilters;
}

export interface FetchDecisionsParams {
  filters: DecisionsFilter;
  sortingType?: DecisionsSortingFieldType;
  abortSignal?: AbortSignal;
}

const ITEMS_PER_PAGE_DEFAULT = 20;

const decisionsApi = new DecisionApi(responseHandlers);
const strategyGroupApi = new StrategyGroupApi(responseHandlers);

export const decisionListFiltersEmptyState = {
  decisionResults: [],
  decisionSource: null,
  strategyStatus: null,
  strategyName: null,
  decisionDate: {
    from: null,
    to: null,
  },
};

export const getInitialState = (): DecisionsState => ({
  itemsTotal: undefined,
  items: [],
  page: 1,
  searchInputValue: '',
  itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
  sortingType: decisionsFilterAndSortingSaver.getSavedSorting() || {
    field: DecisionsSortingField.Date,
    ascending: false,
  },
  error: null,
  isFiltersPopUpVisible: false,
  selectedMembers: (decisionsFilterAndSortingSaver.getSavedFilters()?.selectedMembers || []),
  runDecisionPopupOpen: false,
  filtersData: {
    strategyNames: [],
  },
  applicationId: null,
  filters: {
    ...decisionListFiltersEmptyState,
    ...(decisionsFilterAndSortingSaver.getSavedFilters()?.filters || {}),
  },
  filtersLoading: false,
});

export const requestDecisions = createAsyncThunk(
  DecisionActionTypes.RequestDecisions,
  async ({ filters, sortingType, abortSignal }: FetchDecisionsParams) => {
    const decisions = await decisionsApi.getAll({
      ...filters,
      count: ITEMS_PER_PAGE_DEFAULT,
    }, sortingType!, abortSignal);
    return decisions;
  },
);

export const fetchDecisionListFilters = createAsyncThunk(
  DecisionActionTypes.RequestDecisionListFilters,
  async () => {
    const strategyGroupNames = await strategyGroupApi.findAllGroupNames();
    return { strategyNames: strategyGroupNames };
  },
);

export const toggleFiltersDecisionsPopUp = createAction<{ isFiltersPopUpVisible: boolean }>(
  DecisionActionTypes.ToggleFiltersDecisionsPopUp,
);

export const openRunDecisionPopup = createAction(DecisionActionTypes.OpenRunDecisionPopup);
export const closeRunDecisionPopup = createAction(DecisionActionTypes.CloseRunDecisionPopup);

export const resetFilters = createAction(DecisionActionTypes.ResetFilters);
export const resetAllFilters = createAction(DecisionActionTypes.ResetAllFilters);

export const resetDecisionsState = createAction(DecisionActionTypes.ResetDecisionsState);

const decisionsReducer = createReducer<DecisionsState>(getInitialState(), (builder) => {
  builder.addCase(requestDecisions.pending, (state, action) => {
    state.sortingType = action.meta.arg.sortingType || state.sortingType;
    state.searchInputValue = isUndefined(action.meta.arg.filters.search)
      ? state.searchInputValue
      : action.meta.arg.filters.search;
    state.applicationId = action.meta.arg.filters.applicationId || state.applicationId;
    state.selectedMembers = action.meta.arg.filters.selectedMembers || state.selectedMembers;
    state.filters = {
      ...state.filters,
      ...action.meta.arg.filters,
    };
    if (typeof action.meta.arg.filters.offset === 'number') {
      state.page = action.meta.arg.filters.offset + 1;
    }
    state.itemsTotal = undefined;
  });
  builder.addCase(requestDecisions.fulfilled, (state, action) => {
    state.items = action.payload.items;
    state.itemsTotal = action.payload.total;
  });
  builder.addCase(toggleFiltersDecisionsPopUp, (state, action) => {
    state.isFiltersPopUpVisible = action.payload.isFiltersPopUpVisible;
  });
  builder.addCase(fetchDecisionListFilters.pending, (state) => {
    state.filtersLoading = true;
    state.filtersData = { strategyNames: [] };
  });
  builder.addCase(fetchDecisionListFilters.fulfilled, (state, action) => {
    state.filtersLoading = false;
    state.filtersData = action.payload;
  });
  builder.addCase(resetAllFilters, (state) => {
    state.isFiltersPopUpVisible = false;
    state.selectedMembers = [];
    state.filters = decisionListFiltersEmptyState;
    state.itemsTotal = undefined;
    state.page = 1;
  });
  builder.addCase(resetFilters, (state) => {
    state.isFiltersPopUpVisible = false;
    state.filters = decisionListFiltersEmptyState;
    state.itemsTotal = undefined;
    state.page = 1;
  });
  builder.addCase(openRunDecisionPopup, (state) => {
    state.runDecisionPopupOpen = true;
  });
  builder.addCase(closeRunDecisionPopup, (state) => {
    state.runDecisionPopupOpen = false;
  });
  builder.addCase(runIndividualDecision.fulfilled, (state) => {
    state.runDecisionPopupOpen = false;
  });
  builder.addCase(runIndividualDecision.rejected, (state) => {
    state.runDecisionPopupOpen = true;
  });
  builder.addCase(runMultipleDecisions.fulfilled, (state) => {
    state.runDecisionPopupOpen = false;
  });
  builder.addCase(runMultipleDecisions.rejected, (state) => {
    state.runDecisionPopupOpen = true;
  });
  builder.addCase(runApplicationDecision.fulfilled, (state) => {
    state.runDecisionPopupOpen = false;
  });
  builder.addCase(runApplicationDecision.rejected, (state) => {
    state.runDecisionPopupOpen = true;
  });
});

export default decisionsPagination.wrapReducer<DecisionsState>(
  withStateReset(decisionsReducer, DecisionActionTypes.ResetDecisionsState, () => getInitialState()),
);
