import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import Button from 'components/Button';
import useStateReset from 'hooks/useStateReset';
import CalculatedVariablesTable from './CalculatedVariablesTable';
import { CalculatedVariablesActionType } from 'LoanOriginationSystemProducts/ProductConfiguration/CalculatedVariables/ActionTypes';
import { getProductCalculationsByIdsSelector } from 'ProductCalculations/Selectors';
import ApplicationAutomationCard from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationAutomation/ApplicationAutomationCard';
import { SaveCalculationData } from 'components/CalculationScripts/PopUps/AddCalculationScript/AddCalculationScript';
import EditCalculationPopup from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationAutomation/EditCalculationPopup';
import {
  createProductCalculation,
  deleteProductCalculation,
  getProductCalculations,
  updateProductCalculation,
} from 'ProductCalculations/ActionCreator';
import ConfirmPopup from 'components/ConfirmPopup';
import { ReduxState } from 'types/redux';

export interface CalculatedVariablesProps {
  product?: Product | null;
}

const MAX_CALCULATIONS_COUNT = 500;

const CalculatedVariables: FC<CalculatedVariablesProps> = ({ product }) => {
  const dispatchRoutine = useDispatchRoutine();
  const dispatch = useDispatch();
  const [calculationToEdit, setCalculationToEdit] = useState<ProductCalculation | null>(null);
  const [calculationToDelete, setCalculationToDelete] = useState<ProductCalculation | null>(null);
  const [isAddCalculationPopupVisible, setAddCalculationPopupIsVisible] = useState(false);
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);

  const calculationIds = useSelector((state: ReduxState) => {
    return state.loanOriginationSystemProducts.productConfiguration.calculatedVariables.calculationIds;
  });
  const calculations = useSelector((state: ReduxState) => {
    return getProductCalculationsByIdsSelector(state, calculationIds);
  });

  useEffect(() => {
    if (product) {
      dispatch(getProductCalculations(product.id));
    }
  }, [product]);

  useStateReset(CalculatedVariablesActionType.ResetState);

  const handleEditCalculation = (calculation: ProductCalculation) => {
    setCalculationToEdit(calculation);
  };

  const handleDeleteCalculation = (calculation: ProductCalculation) => {
    setCalculationToDelete(calculation);
  };

  const handleCreateCalculatedVariable = async (data: SaveCalculationData) => {
    if (!product) {
      return;
    }

    try {
      setIsBlockingActionInProgress(true);

      await dispatchRoutine(
        createProductCalculation({
          code: data.code,
          variableId: data.variableId,
          productId: product.id,
        }),
      );

      setAddCalculationPopupIsVisible(false);
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  const handleEditCalculatedVariable = async (data: SaveCalculationData) => {
    if (!calculationToEdit) {
      return;
    }

    try {
      setIsBlockingActionInProgress(true);

      await dispatchRoutine(
        updateProductCalculation(calculationToEdit.id, {
          code: data.code,
          ...(calculationToEdit.variable.id !== data.variableId ? { variableId: data.variableId } : {}),
        }),
      );

      setCalculationToEdit(null);
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  const handleConfirmDeleteProductCalculation = async () => {
    if (!calculationToDelete) {
      return;
    }

    try {
      setIsBlockingActionInProgress(true);

      await dispatchRoutine(deleteProductCalculation(calculationToDelete.id));

      setCalculationToDelete(null);
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  const renderQuestionIconTooltip = () => (
    <>
      <p>These calculations will automatically run and</p>
      <p>recalculate values when Applications for this</p>
      <p>Product are updated</p>
    </>
  );

  const renderActions = () => (
    <Button
      kind="secondary"
      onClick={() => setAddCalculationPopupIsVisible(true)}
      disabled={!calculations || calculations.length >= MAX_CALCULATIONS_COUNT}
    >
      Add Calculation
    </Button>
  );

  const renderAddCalculationPopup = () => {
    if (!isAddCalculationPopupVisible) {
      return null;
    }

    return (
      <EditCalculationPopup
        onSave={handleCreateCalculatedVariable}
        onClose={() => setAddCalculationPopupIsVisible(false)}
        isSaving={isBlockingActionInProgress}
      />
    );
  };

  const renderEditCalculationPopup = () => {
    if (!calculationToEdit) {
      return null;
    }

    return (
      <EditCalculationPopup
        calculation={calculationToEdit}
        onClose={() => setCalculationToEdit(null)}
        onSave={handleEditCalculatedVariable}
        isSaving={isBlockingActionInProgress}
      />
    );
  };

  const renderConfirmDeleteCalculationPopup = () => {
    if (!calculationToDelete) {
      return null;
    }

    return (
      <ConfirmPopup
        title="Delete Calculated Variable"
        message="Are you sure you want to delete this calculated variable?"
        confirmText="Delete Calculated Variable"
        declineText="No, Go Back"
        onPopupClose={() => setCalculationToDelete(null)}
        onConfirmClick={handleConfirmDeleteProductCalculation}
        loading={isBlockingActionInProgress}
      />
    );
  };

  return (
    <>
      <ApplicationAutomationCard
        title="Calculated Variables"
        tooltip={renderQuestionIconTooltip()}
        actions={renderActions()}
      >
        <CalculatedVariablesTable
          calculations={calculations}
          onEditCalculation={handleEditCalculation}
          onDeleteCalculation={handleDeleteCalculation}
          onAddNewCalculation={() => setAddCalculationPopupIsVisible(true)}
        />
      </ApplicationAutomationCard>
      {renderAddCalculationPopup()}
      {renderEditCalculationPopup()}
      {renderConfirmDeleteCalculationPopup()}
    </>
  );
};

export default CalculatedVariables;
