import React, { FC } from 'react';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import CompanyUsersTitle from './Title';
import { NullableItems, PaginationProps } from 'pagination';
import { OrganizationUsersSortingField, OrganizationUsersSortingType } from 'api/Core/UserApi';
import OrganizationUsersTable from './Table/OrganizationUsersTable';
import SearchNotFound from 'components/SearchNotFound';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { NoResultsIcon } from 'static/images';
import NoItems from 'components/NoItems';
import areItemsLoading from 'utils/areItemsLoading';
import styles from './CompanyInformationUsers.module.scss';

interface CompanyInformationUsersProps {
  organizationUsers: NullableItems<OrganizationMembership>;
  paginationProps: PaginationProps;
  openAddNewUserContextualView: () => void;
  openEditUserContextualView: (userId: string) => void;
  updateUser: (userInfo: EditUserParams) => void;
  selectedUserId: string | null;
  currentUserId?: string;
  handleOpenSeatsAreFullPopUp: () => void;
  setSearchValue: (searchValue: string) => void;
  searchInputValue: string;
  showInactive: boolean;
  onChangeShowInactive: (active: boolean) => void;
  togglePopUpFilters: (popUpOpeningStatus: boolean) => void;
  sortingType: OrganizationUsersSortingType;
  onSort: (field: OrganizationUsersSortingField, ascending: boolean) => void;
  permissionGroupId: PermissionGroupId | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
  resetAllFilters: () => void;
}

const CompanyInformationUsers: FC<CompanyInformationUsersProps> = (props) => {
  const {
    organizationUsers,
    paginationProps,
    openAddNewUserContextualView,
    openEditUserContextualView,
    updateUser,
    selectedUserId,
    currentUserId,
    handleOpenSeatsAreFullPopUp,
    setSearchValue,
    searchInputValue,
    showInactive,
    onChangeShowInactive,
    togglePopUpFilters,
    sortingType,
    onSort,
    permissionGroupId,
    phoneAuthentication,
    emailVerified,
    resetAllFilters,
  } = props;

  const areFiltersChanged = () => {
    return permissionGroupId !== null || phoneAuthentication !== null || emailVerified !== null;
  };

  const renderBody = () => {
    if (paginationProps.itemsTotal === 0 && areFiltersChanged()) {
      return (
        <NoItems
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noItemsSubtitle}
          onButtonClick={resetAllFilters}
          className={styles.noResultsFoundContainer}
        />
      );
    }

    if (searchInputValue && paginationProps.itemsTotal === 0) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }

    return (
      <OrganizationUsersTable
        organizationUsers={organizationUsers}
        sortingType={sortingType}
        openEditUserContextualView={openEditUserContextualView}
        updateUser={updateUser}
        currentUserId={currentUserId}
        selectedUserId={selectedUserId}
        handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
        paginationProps={paginationProps}
        onSort={onSort}
      />
    );
  };

  return (
    <div className={styles.usersPage}>
      <CompanyUsersTitle
        openAddNewUserContextualView={openAddNewUserContextualView}
        setSearchValue={setSearchValue}
        searchInputValue={searchInputValue}
        showInactive={showInactive}
        onChangeShowInactive={onChangeShowInactive}
        togglePopUpFilters={togglePopUpFilters}
        areFiltersChanged={areFiltersChanged}
        areUsersLoading={areItemsLoading(organizationUsers)}
      />
      <div className={styles.usersContent}>{renderBody()}</div>
    </div>
  );
};

export default CompanyInformationUsers;
