import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccessPermissions,
  resetAccessPermissionsUpdatingState,
  updateAccessPermissions,
} from 'ProductSectionAccessPermissions/Actions';
import {
  getPermissionGroups,
  openEditPermissionGroups,
  closeEditPermissionGroups,
  bulkUpdatePermissionGroups,
} from 'PermissionGroups/Actions';
import { getAllGroups } from 'PermissionGroups/Selectors';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import PermissionGroupsPageWrapper from 'components/PermissionGroupsPageWrapper';
import { ReduxState } from 'types/redux';
import AccessPermissionsTables, { AccessPermissionsTablesProps } from 'components/AccessPermissionsTables';
import EditPermissionGroupsContextualView from 'components/PermissionGroupsPageWrapper/EditPermissionGroupsContextualView';
import { ProductSectionAccessPermissionsState } from 'ProductSectionAccessPermissions/Reducer';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';

const PermissionGroupsPage = () => {
  const dispatch = useDispatch();

  const { accessPermissionsByGroupId: accessPermissions, accessPermissionsUpdatingState } = useSelector<
    ReduxState,
    ProductSectionAccessPermissionsState
  >((state) => state.productSectionAccessPermissions);

  const { isEditGroupsContextualViewOpen, isUpdatePending } = useSelector((state: ReduxState) => ({
    isEditGroupsContextualViewOpen: state.permissionGroups.isEditGroupsContextualViewOpen,
    isUpdatePending: state.permissionGroups.isUpdatePending,
  }));

  const permissionGroups = useSelector((state: ReduxState) => getAllGroups(state));

  useEffect(() => {
    dispatch(getAccessPermissions());
    dispatch(getPermissionGroups());

    return () => handleCloseContextualView();
  }, []);

  const leftNav = makeLeftNavigation(NavigationLinkId.PermissionGroups, ApplicationSectionName.CompanySettings);

  const updateProductSectionAccessPermissions: AccessPermissionsTablesProps['updateAccessPermissions'] = (
    permissions,
    section,
    value,
  ) => {
    dispatch(updateAccessPermissions({ permissions, section, value }));
  };

  const handleResetAccessPermissionsUpdatingState: AccessPermissionsTablesProps['resetAccessPermissionsUpdatingState'] =
    (permissionGroupId, section) => {
      dispatch(resetAccessPermissionsUpdatingState({ permissionGroupId, section }));
    };

  const closeContextualView = useCloseContextualView();

  const handleOpenContextualView = () => {
    dispatch(openEditPermissionGroups());
  };

  const handleCloseContextualView = () => {
    closeContextualView();
    dispatch(closeEditPermissionGroups());
  };

  const handlePermissionGroupsUpdate = async (groups: PermissionGroup[]) => {
    await dispatch(bulkUpdatePermissionGroups(groups));
    await dispatch(getAccessPermissions());
  };

  const renderContextualView = () => {
    if (isEditGroupsContextualViewOpen) {
      return (
        <EditPermissionGroupsContextualView
          permissionGroups={permissionGroups}
          onUpdate={handlePermissionGroupsUpdate}
          onClose={handleCloseContextualView}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout
      leftNav={leftNav}
      contextualView={renderContextualView()}
      closeContextualView={handleCloseContextualView}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <PermissionGroupsPageWrapper onEditPermissionGroupsClick={handleOpenContextualView}>
            <AccessPermissionsTables
              accessPermissions={accessPermissions}
              permissionGroups={permissionGroups}
              accessPermissionsUpdatingState={accessPermissionsUpdatingState}
              updateAccessPermissions={updateProductSectionAccessPermissions}
              resetAccessPermissionsUpdatingState={handleResetAccessPermissionsUpdatingState}
              isLoading={isUpdatePending}
            />
          </PermissionGroupsPageWrapper>
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default PermissionGroupsPage;
