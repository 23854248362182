import React from 'react';
import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import { CalendarImage } from 'static/images';
import { DEFAULT_DATE_FORMAT } from 'utils/formatDateValue';

import styles from './DatePickerInputStub.module.scss';

export interface DatePickerInputStubProps extends Omit<TextInputStubProps, 'placeholder'> {
  dateFormat?: string;
}

const DatePickerInputStub = ({ dateFormat = DEFAULT_DATE_FORMAT, inputIcon, ...restProps }: DatePickerInputStubProps) => {
  const renderPlaceholder = () => (
    <>
      <div>{dateFormat}</div>
      {!inputIcon && <CalendarImage />}
    </>
  );

  return (
    <TextInputStub {...restProps} inputIcon={inputIcon} stubInputClassName={styles.stubInput} placeholder={renderPlaceholder()} />
  );
};

export default DatePickerInputStub;
