import React, { FC, ReactNode } from 'react';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import { Option } from 'components/SelectInput/SelectInput';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { VisualDataType } from 'Variables/VariablesTypes';
import ComparisonOperand from 'components/ComparisonOperand';
import getShowingDataType from 'utils/ruleBuilder/getShowingDataType';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import getDataTypeAttributes from 'utils/ruleBuilder/getDataTypeAttributes';

export interface RuleBuilderOutputCoreProps {
  sourceVariableId?: string;
  setSourceVariableId: (id: string) => void;
  operandValue: string;
  setOperandValue: (value: string) => void;
  operandVariableId?: string;
  setOperandVariableId: (id: string) => void;
  operandType: ComparisonOperandType;
  setOperandType: (type: ComparisonOperandType) => void;
  visualDataType?: VisualDataType;
  link: ReactNode;
}

const RuleBuilderOutputCore: FC<RuleBuilderOutputCoreProps> = ({
  sourceVariableId,
  setSourceVariableId,
  operandValue,
  setOperandValue,
  operandVariableId,
  setOperandVariableId,
  operandType,
  setOperandType,
  link,
}) => {
  const sourceVariable = useVariableAttributes(sourceVariableId!);

  const dataTypeWithAttributes = getDataTypeAttributes(sourceVariable!);

  return (
    <>
      <InputWithDataTypeLabel
        labelTitle="Output Variable"
        value={sourceVariableId}
        onChange={({ value }: Option) => setSourceVariableId(value)}
        dataType={getShowingDataType(dataTypeWithAttributes)}
        link={link}
      />
      {sourceVariableId && (
        <ComparisonOperand
          value={operandValue}
          variableId={operandVariableId}
          operandType={operandType}
          onValueChange={setOperandValue}
          onVariableIdChange={setOperandVariableId}
          onTypeChange={setOperandType}
          labelTitle="Output Value"
          {...dataTypeWithAttributes}
        />
      )}
    </>
  );
};

export default RuleBuilderOutputCore;
