import React, { FC, ReactNode } from 'react';
import styles from './RulesListWrapper.module.scss';
import { CloseButtonImage } from 'static/images';
import BranchConditions from 'components/BranchConditions';
import { RuleData, RuleType } from 'BranchInfo/Types';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import { BranchNameInput } from 'components/BranchRow';
import { EntityType } from 'RuleCreate/Types';
import { RuleEditPopUpData } from 'RuleEdit/Types';

interface RulesListWrapperTypes {
  onClose: () => void;
  addBranchCondition: () => void;
  name: string;
  multipleBranches: boolean;
  conditionsList: RuleData[];
  children: ReactNode;
  strategyId: string;
  isStrategyLocked?: boolean;
  branchIndex: number;
  moduleId: string;
  downloadBranchConditionsCSV: (population: string) => void;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  openDeleteBranchPopUp: (index: number, entityType: EntityType) => void;
  onOpenEditBranchPopUp: (data: RuleEditPopUpData) => void;
  conditionsBlockingActionInProgress: boolean;
  isLoading: boolean;
}

const RulesListWrapper: FC<RulesListWrapperTypes> = ({
  onClose,
  addBranchCondition,
  name,
  conditionsList,
  multipleBranches,
  children,
  downloadBranchConditionsCSV,
  strategyId,
  isStrategyLocked,
  branchIndex,
  moduleId,
  handleRuleReorder,
  openDeleteBranchPopUp,
  onOpenEditBranchPopUp,
  conditionsBlockingActionInProgress,
  isLoading,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          {name ? (
            <BranchNameInput
              strategyId={strategyId}
              branchName={name}
              branchIndex={branchIndex}
              moduleId={moduleId}
              className={styles.branchInputContainer}
              isStrategyLocked={isStrategyLocked}
            />
          ) : (
            <SkeletonRectangle width="600px" height="44px" color="primary6" className={styles.skeletonName} />
          )}
        </div>
        <CloseButtonImage className={styles.closeImage} onClick={onClose} />
      </div>
      <div className={styles.content}>
        {children}
        <BranchConditions
          strategyId={strategyId}
          addBranchCondition={addBranchCondition}
          branchConditions={conditionsList}
          multipleBranches={multipleBranches}
          downloadCSV={downloadBranchConditionsCSV}
          handleRuleReorder={handleRuleReorder}
          openDeleteBranchPopUp={openDeleteBranchPopUp}
          onOpenEditBranchPopUp={onOpenEditBranchPopUp}
          conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default RulesListWrapper;
