import { AnyAction } from '@reduxjs/toolkit';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { getSortingType } from './Selectors';
import { ApplicationDocumentsSortingType } from 'api/LoanOriginationSystem/DocumentsApi';

const saveTriggerActions = [
  (action: AnyAction) => action.type.includes(ApplicationDocumentsActionType.SortApplicationDocuments),
];

const applicationDocumentsSortingSaver = FilterAndSortingSaver<ApplicationDocumentsSortingType>({
  key: 'applicationDocuments',
  getSortingToSave: getSortingType,
  saveTriggerActions,
  resetActionType: ApplicationDocumentsActionType.ResetState,
});

export default applicationDocumentsSortingSaver;
