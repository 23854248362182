import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'utils/formatDateValue';

const getDefaultDateFormat = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = moment(variableValue.toString(), dateFormat).toDate();
  const normalizedDate = moment(UTCFormatDate).format(DEFAULT_DATE_FORMAT);
  return normalizedDate;
};

export default getDefaultDateFormat;
