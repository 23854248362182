enum ReportingDashboardRange {
  AllTime = 'All Time',
  LastThreeYears = 'Last 3 Years',
  LastTwelveMonths = 'Last 12 Months',
  ThisYear = 'This Year',
  ThisMonth = 'This Month',
  LastThirtyDays = 'Last 30 Days',
  Custom = 'Custom',
}

export default ReportingDashboardRange;
