import React, { FC } from 'react';
import clsx from 'clsx';
import { SIZES } from 'components/Decisions/Table/columnSizes';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import styles from './DecisionRow.module.scss';
import SearchHighlight from 'components/SearchHighlight';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import { Decision, DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { DecisionsTableColumnDisplayName } from 'components/Decisions/Table/types';
import WrapperWithLink from 'components/WrapperWithLink';
import maskNumberValue from 'utils/masks/maskNumberValue';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';

export type StatusType = 'Active' | 'Inactive' | 'Testing';

interface DecisionRowProps {
  decision: Decision;
  searchInputValue: string;
  getRowActions: (decision: Decision) => RowAction[];
  handleClick: (decisionData: Decision) => void;
  rowLink?: string;
}

const statusStyles = new Map([
  [DecisionResultType.Passed, styles.statusPassed],
  [DecisionResultType.Failed, styles.statusFailed],
  [DecisionResultType.Error, styles.statusError],
]);

export const getStateColor = (status: DecisionResultType) => {
  return statusStyles.get(status);
};

const DecisionRow: FC<DecisionRowProps> = ({
  decision,
  searchInputValue,
  getRowActions,
  handleClick,
  rowLink,
}) => {
  const hasResults = decision.resultsCount > 0;

  const getStrategyNameToDisplay = () => {
    if (!decision.strategyNames.length) {
      return null;
    }

    return <SearchHighlight search={searchInputValue}>
      {decision.strategyNames.join(', ')}
    </SearchHighlight>;
  }

  const hasMultipleResults = Object.keys(decision.resultStatuses).length > 1 || Object.values(decision.resultStatuses)[0] > 1;
  return (
    <WrapperWithLink
      href={rowLink || ''}
      onClick={() => hasResults && handleClick(decision)}
    >
      <TableRow disabled={!hasResults} key={decision.id} useActions className={styles.row}>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Date]} overflowed>
          <div className={styles.avatarDateContainer}>
            <WithSystemApiUserAvatar
              user={decision.createdBy}
              size="small"
              className={styles.avatar}
            />
            <DateTime time={decision.createdAt} format={DateTimeFormat.Long} />
          </div>
        </TableBodyCell>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.StrategyName]} overflowed>
          {getStrategyNameToDisplay()}
        </TableBodyCell>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.DecisionName]} overflowed>
          <SearchHighlight search={searchInputValue}>
            {decision.name}
          </SearchHighlight>
          {decision.resultsCount > 1 ? ` (${decision.resultsCount} Sub-Decisions)` : ''}
        </TableBodyCell>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Source]} overflowed>
          {decision.source === 'Api' ? decision.source.toUpperCase() : decision.source}
        </TableBodyCell>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.ExecutionTime]} overflowed>
          {decision.executionTime && `${maskNumberValue(decision.executionTime)} ms`}
        </TableBodyCell>
        <TableBodyCell width={SIZES[DecisionsTableColumnDisplayName.Result]} noPadding className={styles.statusLabels}>
          {hasResults && Object.keys(decision.resultStatuses).map((statusName) => <div className={
            clsx(hasResults && [styles.caseStatus, getStateColor(statusName as DecisionResultType)])
          }>
            {`${statusName} ${hasMultipleResults ? `(${decision.resultStatuses[statusName]})` : ''}`}
          </div>)}
        </TableBodyCell>
        <RowActionsContainer>
          <RowActions actions={getRowActions(decision)} id={decision.id} />
        </RowActionsContainer>
      </TableRow>
    </WrapperWithLink>
  );
};

export default DecisionRow;
