import { ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  AutoLoanRefinancingProductTypeImage,
  BusinessLoanProductTypeImage,
  CommercialLoanProductTypeImage,
  CreditCardProductTypeImage,
  CustomProductTypeImage,
  HomeEquityLoanProductTypeImage,
  HomeImprovementLoanProductTypeImage,
  MortgageProductTypeImage,
  PersonalLoanProductTypeImage,
  PointOfSaleFinancingProductTypeImage,
} from 'static/images';

export const productTypeOptions = [
  { name: 'Personal Loan', image: PersonalLoanProductTypeImage, type: ProductType.PersonalLoan },
  { name: 'Auto Loan', image: AutoLoanRefinancingProductTypeImage, type: ProductType.AutoLoanRefinancing },
  { name: 'Commercial Loan', image: CommercialLoanProductTypeImage, type: ProductType.CommercialLoan },
  { name: 'Credit Card', image: CreditCardProductTypeImage, type: ProductType.CreditCard },
  { name: 'Home Equity Loan', image: HomeEquityLoanProductTypeImage, type: ProductType.HomeEquityLoan },
  { name: 'Home Improvement Loan', image: HomeImprovementLoanProductTypeImage, type: ProductType.HomeImprovementLoan },
  { name: 'Mortgage', image: MortgageProductTypeImage, type: ProductType.Mortgage },
  { name: 'Point-Of-Sale Financing', image: PointOfSaleFinancingProductTypeImage, type: ProductType.PointOfSaleFinancing },
  { name: 'Small Business Loan', image: BusinessLoanProductTypeImage, type: ProductType.SmallBusinessLoan },
  { name: 'Custom', image: CustomProductTypeImage, type: ProductType.Custom },
]
