import React, { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import Users from 'components/CompanyInformationUsers';
import { ReduxState } from 'types/redux';
import {
  openSeatsAreFullPopUp,
  closeSeatsAreFullPopUp,
  getOrganizationSeats,
} from 'CompanyInformation/CompanyInformationActionCreator';
import { OrganizationState } from 'CompanyInformation/CompanyInformationTypes';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import AddNewCompanyUser from 'components/AddNewCompanyUser';
import { AddNewCompanyUserRequest } from 'AddNewCompanyUser/AddNewCompanyUserActionCreator';
import { AddNewUserParams } from 'AddNewCompanyUser/AddNewCompanyUserTypes';
import { useQueryParams } from 'hooks/useQueryParam';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import { useHistory } from 'react-router-dom';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import SeatsAreFullPopUp from 'components/CompanyInformationUsers/SeatsAreFullPopUp';
import {
  editOrganizationUser,
  OrganizationUsersState,
  requestOrganizationUsers,
  toggleFiltersOrganizationUsersPopUp,
} from 'OrganizationUsers/OrganizationUserStore';
import { OrganizationUsersActionTypes } from 'OrganizationUsers/ActionTypes';
import UsersFilter from 'components/CompanyInformationUsers/UsersFilter';
import usersPagination, { UsersPaginationParams } from './Pagination';
import { OrganizationUsersSortingField, OrganizationUsersSortingType } from 'api/Core/UserApi';
import useStateReset from 'hooks/useStateReset';
import { PermissionGroupId } from 'PermissionGroups/Types';
import EditCompanyUserContainer from 'components/EditCompanyUser/EditCompanyUserContainer';
import { USER_ADD_RESULT } from 'AddNewCompanyUser/AddNewCompanyUserConstants';
import { UserAddResult } from 'api/Types';
import notification from 'handlers/notification/notificationActionCreator';
import { mapPermissionGroupIntoOption } from 'PermissionGroups/utils';
import usePermissionGroups from 'hooks/usePermissionGroups';

const FIRST_PAGE_NUMBER = 1;

const CompanyInformationUsers = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();
  const params = useQueryParams();
  const selectedMembershipId = params.get('edit');
  const history = useHistory();

  const { isSeatsAreFullPopUpOpen, purchasedSeats, occupiedSeats } = useSelector<
    ReduxState,
    OrganizationState
  >((state) => state.organizationInformation);

  const permissionGroups = usePermissionGroups().map(mapPermissionGroupIntoOption);

  useStateReset(OrganizationUsersActionTypes.ResetState);

  const {
    page,
    itemsPerPage,
    searchInputValue,
    showInactive,
    isFiltersPopUpVisible,
    permissionGroupId,
    emailVerified,
    phoneAuthentication,
    sortingType,
    usersDetailedAttributes,
    isUserDetailedAttributesLoading,
  } = useSelector<ReduxState, OrganizationUsersState>((state) => state.organizationUsers);

  const paginationParams = {
    searchInputValue,
    sortingType,
    permissionGroupId,
    phoneAuthentication,
    showInactive,
  } as UsersPaginationParams;

  const usersList = usersPagination.usePaginatedItems(paginationParams);
  const paginationProps = usersPagination.usePagination(paginationParams);

  const getOrganizationUsers = (
    userOffset: number,
    userItemsPerPage: number,
    userSearch: string,
    userShowInactive: boolean,
    userPermissionGroupId: PermissionGroupId | null,
    userPhoneAuthentication: boolean | null,
    userSortingType: OrganizationUsersSortingType,
  ) => {
    dispatch(
      requestOrganizationUsers({
        filters: {
          offset: userOffset,
          count: userItemsPerPage,
          search: userSearch,
          showInactive: userShowInactive,
          permissionGroupId: userPermissionGroupId,
          phoneAuthentication: userPhoneAuthentication,
        },
        sortingType: userSortingType,
      }),
    );
  };

  useEffect(() => {
    getOrganizationUsers(
      page,
      itemsPerPage,
      searchInputValue,
      showInactive,
      permissionGroupId,
      phoneAuthentication,
      sortingType,
    );
  }, []);

  const currentUserId = useSelector<ReduxState>(({ accountDetails }) => accountDetails?.id) as string | undefined;
  const leftNav = makeLeftNavigation(NavigationLinkId.Users, ApplicationSectionName.CompanySettings);

  const updateUser = async (userInfo: EditUserParams) => {
    await dispatch(editOrganizationUser(userInfo));
    dispatch(usersPagination.reloadCurrentPage(paginationParams));
  };

  const handleCloseContextualView = useCloseContextualView();

  const handleEditPermissionGroupsClick = () => {
    window.open(`${window.location.origin}/company-settings/permission-groups`, '_blank');
  };

  const getContextualViewPage = () => {
    if (params.has('new')) {
      const addNewUser = async (userInfo: AddNewUserParams) => {
        const results = await dispatchRoutineWithResult(AddNewCompanyUserRequest(userInfo));
        const result = USER_ADD_RESULT in results ? (results[USER_ADD_RESULT as any] as UserAddResult) : null;

        if (result === UserAddResult.SuccessfullyAdded) {
          handleCloseContextualView();
          notification.createNotification(`Email invitation sent to ${userInfo.username}.`, 'success', dispatch);
        }

        if (result === UserAddResult.NoSeatsAvailable) {
          await dispatchRoutine(getOrganizationSeats());
          handleOpenSeatsAreFullPopUp();
        }
      };

      return (
        <AddNewCompanyUser
          addNewUser={addNewUser}
          onClose={handleCloseContextualView}
          permissionGroups={permissionGroups}
          onEditPermissionGroupsClick={handleEditPermissionGroupsClick}
        />
      );
    }

    if (params.has('edit')) {
      return (
        <EditCompanyUserContainer
          selectedMembershipId={selectedMembershipId!}
          usersDetailedAttributes={usersDetailedAttributes}
          isUserDetailedAttributesLoading={isUserDetailedAttributesLoading}
          updateUser={updateUser}
          handleCloseContextualView={handleCloseContextualView}
          handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
          currentUserId={currentUserId}
          permissionGroups={permissionGroups}
          handleEditPermissionGroupsClick={handleEditPermissionGroupsClick}
        />
      );
    }

    return null;
  };

  const handleOpenSeatsAreFullPopUp = () => {
    dispatch(openSeatsAreFullPopUp());
  };

  const handleCloseSeatsAreFullPopUp = () => {
    dispatch(closeSeatsAreFullPopUp());
  };

  const renderOverlay = () => {
    if (isSeatsAreFullPopUpOpen) {
      if (purchasedSeats === null || occupiedSeats === null) {
        throw new Error('Seats not loaded');
      }

      return (
        <SeatsAreFullPopUp
          maxSeats={purchasedSeats}
          currentSeats={occupiedSeats}
          onPopupClose={handleCloseSeatsAreFullPopUp}
        />
      );
    }

    return null;
  };

  const resetAllFilters = () => {
    getOrganizationUsers(FIRST_PAGE_NUMBER, itemsPerPage, searchInputValue, showInactive, null, null, sortingType);
  };

  const togglePopUpFilters = (popUpOpeningStatus: boolean) => {
    dispatch(toggleFiltersOrganizationUsersPopUp({ popUpOpeningStatus }));
  };

  const onFiltersPopupClose = () => {
    togglePopUpFilters(false);
  };

  const onFiltersApply = (permissionGroupFilter: PermissionGroupId, phoneAuthenticationFilter: string) => {
    batch(() => {
      getOrganizationUsers(
        page,
        itemsPerPage,
        searchInputValue,
        showInactive,
        permissionGroupFilter || null,
        phoneAuthenticationFilter === '' ? null : phoneAuthenticationFilter === 'enabled',
        sortingType,
      );
      onFiltersPopupClose();
    });
  };

  const onClearFiltersClick = () => {
    batch(() => {
      resetAllFilters();
      onFiltersPopupClose();
    });
  };

  const rightSidePopupView = isFiltersPopUpVisible && (
    <UsersFilter
      onFiltersApply={onFiltersApply}
      onClearFiltersClick={onClearFiltersClick}
      onFiltersPopupClose={onFiltersPopupClose}
      permissionGroups={permissionGroups}
      permissionGroupId={permissionGroupId}
      phoneAuthentication={phoneAuthentication}
    />
  );

  const openAddNewUserContextualView = () => {
    history.replace('users?new');
  };

  const openEditUserContextualView = (userId: string) => {
    history.replace(`users?edit=${userId}`);
  };

  const setSearchValue = (searchValue: string) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchValue,
      showInactive,
      permissionGroupId,
      phoneAuthentication,
      sortingType,
    );
  };

  const onChangeShowInactive = (active: boolean) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchInputValue,
      active,
      permissionGroupId,
      phoneAuthentication,
      sortingType,
    );
  };

  const onSort = (field: OrganizationUsersSortingField, ascending: boolean) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchInputValue,
      showInactive,
      permissionGroupId,
      phoneAuthentication,
      {
        field,
        ascending,
      },
    );
  };

  return (
    <MainLayout
      contextualView={getContextualViewPage()}
      leftNav={leftNav}
      closeContextualView={handleCloseContextualView}
      overlay={renderOverlay()}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <Users
            organizationUsers={usersList}
            paginationProps={paginationProps}
            openAddNewUserContextualView={openAddNewUserContextualView}
            openEditUserContextualView={openEditUserContextualView}
            updateUser={updateUser}
            selectedUserId={selectedMembershipId}
            currentUserId={currentUserId}
            handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
            setSearchValue={setSearchValue}
            searchInputValue={searchInputValue}
            showInactive={showInactive}
            onChangeShowInactive={onChangeShowInactive}
            togglePopUpFilters={togglePopUpFilters}
            sortingType={sortingType}
            onSort={onSort}
            permissionGroupId={permissionGroupId}
            emailVerified={emailVerified}
            phoneAuthentication={phoneAuthentication}
            resetAllFilters={resetAllFilters}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default CompanyInformationUsers;
