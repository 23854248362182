import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { WebhookEvent } from 'api/Webhooks/WebhookEventsApi';
import { CreateWebhookEndpointParams, UpdateWebhookEndpointParams } from 'api/Webhooks/WebhookEndpointsApi';
import { useQueryParams } from 'hooks/useQueryParam';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useConfirmChanges from 'hooks/useConfirmChanges';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import NavigationLinkId from 'enums/NavigationLinkId';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import QuestionIcon from 'components/QuestionIcon';
import TabSwitch from 'components/TabSwitch';
import { TabSwitchOption } from 'components/TabSwitch/TabSwitch';
import WebhookEndpointsDashboard from 'components/Webhooks/WebhookEndpointsDashboard';
import WebhookEventsDashboard from 'components/Webhooks/WebhookEventsDashboard';
import WebhookEventsFilters from 'components/Webhooks/WebhookEventsFilters';
import { WebhookEventsFiltersResult } from 'components/Webhooks/WebhookEventsFilters/WebhookEventsFilters';
import WebhookEndpointDetailsContextualView from 'components/Webhooks/WebhookEndpointDetailsContextualView';
import ConfirmPopup from 'components/ConfirmPopup';
import CreateWebhookEndpointPopup from 'components/Webhooks/CreateWebhookEndpointPopup';
import { changeFilters, clearFilters } from 'Webhooks/WebhookEventsTable/Filters/WebhookEventsTableFiltersStore';
import WebhookEventDetailsPopup from 'components/Webhooks/WebhookEventDetailsPopup';
import {
  createWebhookEndpoint,
  deleteWebhookEndpoint,
  generateNewWebhookEndpointSecret,
  getWebhookEndpoint,
  updateWebhookEndpoint,
} from 'WebhookEndpoints/Thunks';
import { deleteWebhookEvent, retryWebhookEvent, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import styles from './Webhooks.module.scss';

const leftNav = makeLeftNavigation(NavigationLinkId.Webhooks, ApplicationSectionName.CompanySettings);

enum WebhookTab {
  Endpoints = 'endpoints',
  Events = 'events',
}

const TABS: TabSwitchOption[] = [{
  label: 'Endpoints',
  id: WebhookTab.Endpoints,
}, {
  label: 'Events',
  id: WebhookTab.Events,
}];

const Webhooks = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useQueryParams();
  const { tab } = useParams<{ tab: string }>();
  const [displayWebhookEventsFilter, setDisplayWebhookEventFilter] = useState(false);
  const [displayCreateWebhookEndpointPopup, setDisplayCreateWebhookEndpointPopup] = useState(false);
  const [webhookEventToShowDetails, setWebhookEventToShowDetails] = useState<WebhookEvent | null>(null);

  const closeContextualView = useCloseContextualView();

  const [isDeleteWebhookInProgress, useBlockingDeleteWebhookCallback] = useBlockingRequest();
  const [isWebhookStatusChangeInProgress, useBlockingWebhookStatusChangeCallback] = useBlockingRequest();
  const [isNewEndpointGeneratingInProgress, useBlockingNewWebhookEndpointGenerateCallback] = useBlockingRequest();
  const [isCreateNewWebhookEndpointInProgress, useBlockingWebhookEndpointCreationCallback] = useBlockingRequest();
  const [isWebhookEventDeleteInProgress, useBlockingWebhookEventDeleteCallback] = useBlockingRequest();
  const [isResendEventInProgress, useBlockingResendCallback] = useBlockingRequest();
  const [isWebhookEndpointUpdateInProgress, useBlockingWebhookEndpointUpdateCallback] = useBlockingRequest();

  const webhookEndpointId = params.get('edit');

  const filters = useSelector((state: ReduxState) => state.webhookEventsTable.filters);

  const webhookEndpoint = useSelector((state: ReduxState) => {
    return webhookEndpointId ? state.webhookEndpoints.webhookEndpointsById[webhookEndpointId] : null
  });

  const [
    displayConfirmDeleteWebhook,
    resetConfirmDeleteWebhook,
    onConfirmDeleteWebhook,
    useConfirmDeleteWebhookCallback,
  ] = useConfirmChanges();

  const [
    displayConfirmWebhookStatusChange,
    resetConfirmWebhookStatusChange,
    onConfirmWebhookStatusChange,
    useConfirmWebhookStatusChangeCallback,
    isNextStatusActive,
  ] = useConfirmChanges<boolean>();

  const [
    displayConfirmGenerateNewWebhookEndpointSecret,
    resetConfirmGenerateNewWebhookEndpointSecret,
    onConfirmGenerateNewWebhookEndpointSecret,
    useConfirmGenerateWebhookEndpointSecretCallback,
  ] = useConfirmChanges();

  const [
    displayConfirmDeleteWebhookEvent,
    resetConfirmDeleteWebhookEvent,
    onConfirmDeleteWebhookEvent,
    useConfirmDeleteWebhookEventCallback,
  ] = useConfirmChanges();

  useEffect(() => {
    if (webhookEndpointId) {
      dispatch(getWebhookEndpoint(webhookEndpointId));
    }
  }, [webhookEndpointId]);

  const renderQuestionIconTooltip = () => (
    <>
      <p>Create webhook endpoints, so that DigiFi can</p>
      <p>notify you when asynchronous events occur</p>
    </>
  );

  const handleTabChange = ({ id }: TabSwitchOption) => {
    history.push(`/company-settings/webhooks/${id}`);
  };

  const handleDeleteWebhook = useConfirmDeleteWebhookCallback(useBlockingDeleteWebhookCallback(async (webhookId: string) => {
    await dispatchWithUnwrap(deleteWebhookEndpoint(webhookId));

    history.replace('?');

    resetConfirmDeleteWebhook();
  }));

  const handleChangeWebhookStatus = useConfirmWebhookStatusChangeCallback(
    useBlockingWebhookStatusChangeCallback(async (webhookId: string, active: boolean) => {
      await dispatchWithUnwrap(updateWebhookEndpoint({
        id: webhookId,
        active,
      }));

      resetConfirmWebhookStatusChange();
    }),
    (webhookId, active: boolean) => !active,
  );

  const handleGenerateNewWebhookEndpointSecret = useConfirmGenerateWebhookEndpointSecretCallback(
    useBlockingNewWebhookEndpointGenerateCallback(async (webhookId: string) => {
      await dispatchWithUnwrap(generateNewWebhookEndpointSecret(webhookId));

      resetConfirmGenerateNewWebhookEndpointSecret();
    }),
  );

  const handleCreateWebhookEndpointPopup = useBlockingWebhookEndpointCreationCallback(
    async (createWebhookEndpointParams: CreateWebhookEndpointParams) => {
      await dispatchWithUnwrap(createWebhookEndpoint(createWebhookEndpointParams));
      setDisplayCreateWebhookEndpointPopup(false);
    },
  );

  const handleDeleteWebhookEvent = useConfirmDeleteWebhookEventCallback(
    useBlockingWebhookEventDeleteCallback(async (webhookEventId: string) => {
      await dispatchWithUnwrap(deleteWebhookEvent({
        webhookEventId,
        actionOrigin: tab === WebhookTab.Events
          ? WebhookEventsActionOrigin.WebhookEventsTable
          : WebhookEventsActionOrigin.WebhookEndpointEventsTable,
      }));

      resetConfirmDeleteWebhookEvent();
      setWebhookEventToShowDetails(null);
    }),
  );

  const handleResendWebhookEvent = useBlockingResendCallback(async (webhookEventId: string) => {
    await dispatchWithUnwrap(retryWebhookEvent(webhookEventId));

    if (webhookEventToShowDetails) {
      setWebhookEventToShowDetails(null);
    }
  });

  const handleUpdateWebhookEndpoint = useBlockingWebhookEndpointUpdateCallback(
    async (webhookEndpointIdToUpdate: string, updateParams: Partial<UpdateWebhookEndpointParams>) => {
      await dispatchWithUnwrap(updateWebhookEndpoint({
        id: webhookEndpointIdToUpdate,
        ...updateParams,
      }));
    },
  );

  const handleApplyWebhookEventFilters = (filtersResult: WebhookEventsFiltersResult) => {
    dispatch(changeFilters({
      statuses: filtersResult.statuses,
      eventTypes: filtersResult.eventTypes,
      dueCreatedDateRange: {
        from: filtersResult.dueCreatedDateFrom,
        to: filtersResult.dueCreatedDateTo,
      },
    }));

    setDisplayWebhookEventFilter(false);
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());

    setDisplayWebhookEventFilter(false);
  };

  const renderTabContent = () => {
    if (tab === WebhookTab.Endpoints) {
      return (
        <WebhookEndpointsDashboard
          onDeleteWebhook={handleDeleteWebhook}
          onCreateWebhookEndpointButtonClick={() => setDisplayCreateWebhookEndpointPopup(true)}
          onChangeWebhookEndpointStatus={handleChangeWebhookStatus}
        />
      );
    }

    return (
      <WebhookEventsDashboard
        onFiltersButtonClick={() => setDisplayWebhookEventFilter(true)}
        onClearFilters={handleClearFilters}
        onDisplayWebhookEvent={(webhookEvent) => setWebhookEventToShowDetails(webhookEvent)}
        onDeleteWebhookEvent={handleDeleteWebhookEvent}
        onResendWebhookEvent={handleResendWebhookEvent}
      />
    );
  };

  const renderRightSidePopupView = () => {
    if (displayWebhookEventsFilter) {
      return (
        <WebhookEventsFilters
          onApplyFilters={handleApplyWebhookEventFilters}
          eventTypes={filters.eventTypes}
          statuses={filters.statuses}
          dueCreatedDateFrom={filters.dueCreatedDateRange.from}
          dueCreatedDateTo={filters.dueCreatedDateRange.to}
          onCloseFilters={() => setDisplayWebhookEventFilter(false)}
          onClearFilters={handleClearFilters}
        />
      );
    }

    return null;
  };

  const renderContextualView = () => {
    if (!webhookEndpointId) {
      return null;
    }

    return (
      <WebhookEndpointDetailsContextualView
        webhookEndpointId={webhookEndpointId}
        webhookEndpoint={webhookEndpoint}
        onDeleteWebhook={handleDeleteWebhook}
        onDeleteWebhookEvent={handleDeleteWebhookEvent}
        onChangeWebhookStatus={handleChangeWebhookStatus}
        onWebhookEventClick={(webhookEvent) => setWebhookEventToShowDetails(webhookEvent)}
        onGenerateNewWebhookEndpointSecret={handleGenerateNewWebhookEndpointSecret}
        onResendWebhookEvent={handleResendWebhookEvent}
        isWebhookEndpointUpdateInProgress={isWebhookEndpointUpdateInProgress}
        onUpdateWebhookEndpoint={handleUpdateWebhookEndpoint}
        onClose={closeContextualView}
      />
    );
  };

  const renderSpecificOverlay = () => {
    if (displayConfirmDeleteWebhook) {
      return (
        <ConfirmPopup
          title="Delete Endpoint"
          message="Are you sure you want to delete this endpoint?"
          confirmText="Yes, Delete Endpoint"
          declineText="No, Go Back"
          onPopupClose={resetConfirmDeleteWebhook}
          onConfirmClick={() => onConfirmDeleteWebhook()}
          loading={isDeleteWebhookInProgress}
        />
      );
    }

    if (displayConfirmGenerateNewWebhookEndpointSecret) {
      return (
        <ConfirmPopup
          title="Generate New Key"
          message="Are you sure you want to generate a new key? This will replace your current key, which will become immediately invalid."
          confirmText="Yes, Generate Key"
          declineText="No, Go Back"
          onPopupClose={resetConfirmGenerateNewWebhookEndpointSecret}
          onConfirmClick={onConfirmGenerateNewWebhookEndpointSecret}
          loading={isNewEndpointGeneratingInProgress}
        />
      );
    }

    if (displayConfirmWebhookStatusChange) {
      const nextStatus = isNextStatusActive ? 'inactive' : 'active';

      return (
        <ConfirmPopup
          title="Change Endpoint Status"
          message={`Are you sure you want to change endpoint status to ${nextStatus}?`}
          confirmText="Yes, Change Status"
          declineText="No, Go Back"
          onPopupClose={resetConfirmWebhookStatusChange}
          onConfirmClick={onConfirmWebhookStatusChange}
          loading={isWebhookStatusChangeInProgress}
        />
      );
    }

    if (displayCreateWebhookEndpointPopup) {
      return (
        <CreateWebhookEndpointPopup
          onSubmit={handleCreateWebhookEndpointPopup}
          onClose={() => setDisplayCreateWebhookEndpointPopup(false)}
          isCreating={isCreateNewWebhookEndpointInProgress}
        />
      );
    }

    if (webhookEventToShowDetails) {
      return (
        <WebhookEventDetailsPopup
          webhookEvent={webhookEventToShowDetails}
          onResendEvent={handleResendWebhookEvent}
          onDeleteEvent={handleDeleteWebhookEvent}
          onClose={() => setWebhookEventToShowDetails(null)}
          isResending={isResendEventInProgress}
        />
      );
    }

    return null;
  };

  const renderOverlay = () => {
    const specificOverlay = renderSpecificOverlay();

    if (!specificOverlay && !displayConfirmDeleteWebhookEvent) {
      return null;
    }

    return (
      <>
        {specificOverlay}
        {displayConfirmDeleteWebhookEvent && (
          <ConfirmPopup
            title="Delete Webhook Event"
            message="Are you sure you want to delete this webhook event?"
            confirmText="Yes, Delete Event"
            declineText="No, Go Back"
            onPopupClose={resetConfirmDeleteWebhookEvent}
            onConfirmClick={onConfirmDeleteWebhookEvent}
            loading={isWebhookEventDeleteInProgress}
          />
        )}
      </>
    );
  }

  if (!Object.values(WebhookTab).includes(tab as WebhookTab)) {
    return <Redirect to="/company-settings/webhooks/endpoints" />;
  }

  return (
    <MainLayout
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      leftNav={leftNav}
      rightSidePopupView={renderRightSidePopupView()}
      closeContextualView={closeContextualView}
    >
      <PageWrapperWithFooter>
        <PageContent noPadding className={styles.pageContent}>
          <div className={styles.header}>
            <h2 className={styles.headerTitle}>DigiFi Webhooks</h2>
            <QuestionIcon size={24} tooltip={renderQuestionIconTooltip()} />
          </div>
          <TabSwitch
            tabs={TABS}
            selectedTabId={tab}
            onSelect={handleTabChange}
          />
          <div className={styles.tabContent}>
            {renderTabContent()}
          </div>
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Webhooks;
