import { AnyScaleBand } from '@visx/shape/lib/types';
import { MouseEventHandler, RefObject } from 'react';
import { Point } from './Types';

const findClosetPoint = (x: number, scale: AnyScaleBand, points: Point[]) => {
  const precision = scale.step() / 2;

  const currentPoint =
    points.length > 1
      ? points.find((point) => x >= scale(point.x)! - precision && x <= scale(point.x)! + precision)!
      : points[0];

  return currentPoint;
};

type UseLineChartTooltipReturnValue = [MouseEventHandler<Element>, MouseEventHandler<Element>];

const getLineChartTooltip = (
  container: RefObject<SVGSVGElement>,
  scale: AnyScaleBand,
  points: Point[],
  setTooltipPoint: (point: Point | null) => void,
): UseLineChartTooltipReturnValue => {
  const handleMouseMove = (event: MouseEvent) => {
    const rect = container.current?.getBoundingClientRect();
    const x = event.clientX - (rect?.left || 0);

    const currentPoint = findClosetPoint(x, scale, points);

    if (!currentPoint) {
      return;
    }

    setTooltipPoint(currentPoint);
  };

  const handleMouseLeave = () => {
    setTooltipPoint(null);
  };

  return [
    (handleMouseMove as unknown) as MouseEventHandler<Element>,
    (handleMouseLeave as unknown) as MouseEventHandler<Element>,
  ];
};

export default getLineChartTooltip;
