import APIRest from 'api/APIRest';
import { ApiCredentials } from 'CompanyInformation/CompanyInformationTypes';

export interface OrganizationInfo {
  id: string;
  name: string;
  city: string;
  imageId: string | null;
  legalAddress: string;
  country: string;
  lastActiveAt: number | null;
  userId?: string;
  isUserActive: boolean;
  createdAt: Date;
}

export interface OrganizationDetailedInfo extends OrganizationInfo {
  primaryContact: string | null;
  regionSettings: {
    phoneNumberFormat: string | null;
    currencyFormat: string | null;
    dateFormat: string | null;
    idNumberType: string | null;
  };
  products: {
    text_recognition: boolean;
    machine_learning: boolean;
    rules_engine: boolean;
    loan_acquisition: boolean;
  };
}

export interface SeatsData {
  purchasedSeats: number;
  occupiedSeats: number;
}

export interface OrganizationApi {
  getCurrent(): Promise<OrganizationDetailedInfo>;
  getSeats(): Promise<SeatsData>;
  getFreeSeat(email: string): Promise<boolean>;
  getApiCredentials(): Promise<ApiCredentials>;
}

export default class OrganizationApiRest extends APIRest implements OrganizationApi {
  protected origin = process.env.REACT_APP_CORE_API_URL;

  protected resourceName = 'organizations';

  public async getCurrent() {
    return this.fetch<OrganizationDetailedInfo>(`/${this.resourceName}/current`, 'GET');
  }

  public async getSeats() {
    return this.fetch<SeatsData>(`/${this.resourceName}/seats`, 'GET');
  }

  public async getFreeSeat(email: string) {
    const params = new URLSearchParams();
    params.append('email', email);

    return this.fetch<boolean>(`/${this.resourceName}/free-seat?${params}`, 'GET');
  }

  public async getApiCredentials() {
    return this.fetch<ApiCredentials>(`/${this.resourceName}/api-credentials`, 'GET');
  }
}
