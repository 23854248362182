import formatMonetaryValue from 'utils/formatMonetaryValue';
import { ChartSettings } from './ReportingDashboardLayout/ReportingDashboardLayout';
import {
  formatXValue,
  formatYValue,
  getTitleWithCurrency,
  formatMonetaryTooltipData,
  formatCountTooltipData,
} from './ChartFormatters';
import ReportingDashboardChartName from 'LoanOriginationSystemReporting/ReportingDashboardChartName';

const GrowthMetricsLayout: [ChartSettings, ChartSettings][] = [
  [
    {
      name: ReportingDashboardChartName.ApplicationLoanAmountTotal,
      groupName: 'Applications',
      getTitle: getTitleWithCurrency('Amount'),
      getSecondaryTitle: (value: number, currencySymbol?: string) =>
        `${formatMonetaryValue(value, currencySymbol)} Total`,
      tooltip: 'The total loan amount of all applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatMonetaryTooltipData,
    },
    {
      name: ReportingDashboardChartName.ApplicationNumberTotal,
      groupName: 'Applications',
      getTitle: () => 'Count',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The number of all applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.ApprovedApplicationsLoanAmountTotal,
      groupName: 'Loans',
      getTitle: getTitleWithCurrency('Amount'),
      getSecondaryTitle: (value: number, currencySymbol?: string) =>
        `${formatMonetaryValue(value, currencySymbol)} Total`,
      tooltip: 'The total loan amount of approved applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatMonetaryTooltipData,
    },
    {
      name: ReportingDashboardChartName.ApprovedApplicationsNumberTotal,
      groupName: 'Loans',
      getTitle: () => 'Count',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The number of approved applications that were received during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.BorrowerNumberTotal,
      groupName: 'Borrowers',
      getTitle: () => 'Total',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The total number of borrowers at the end of the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
    {
      name: ReportingDashboardChartName.NewBorrowerNumberTotal,
      groupName: 'Borrowers',
      getTitle: () => 'New',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The number of new borrowers added during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
  [
    {
      name: ReportingDashboardChartName.IntermediaryNumberTotal,
      groupName: 'Intermediaries',
      getTitle: () => 'Total',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The total number of intermediaries at the end of the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
    {
      name: ReportingDashboardChartName.NewIntermediaryNumberTotal,
      groupName: 'Intermediaries',
      getTitle: () => 'New',
      getSecondaryTitle: (value: number) => `${formatMonetaryValue(value)} Total`,
      tooltip: 'The number of new intermediaries added during the time period',
      formatXValue,
      formatYValue,
      formatTooltipData: formatCountTooltipData,
    },
  ],
];

export default GrowthMetricsLayout;
