import React, { FC } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';
import styles from './DeleteDocumentFolderPopUp.module.scss';

interface DeleteDocumentFolderPopUpProps {
  deleteDocumentFolder: () => Promise<unknown>;
  closePopUp: () => void;
  isLoading: boolean;
  isApplicationFolder?: boolean;
}

const DeleteDocumentFolderPopUp: FC<DeleteDocumentFolderPopUpProps> = ({
  closePopUp,
  deleteDocumentFolder,
  isLoading,
  isApplicationFolder = false,
}) => {
  const title = 'Delete Folder';

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={title} closable={!isLoading}>
      <DeletePopup
        isLoading={isLoading}
        handleDelete={deleteDocumentFolder}
        message={
          <>
            <p>
              Are you sure you want to delete this folder? Any {isApplicationFolder ? 'documents or' : ''} folders
              within this folder will also be deleted.
            </p>

            {isApplicationFolder ? null : (
              <p className={styles.bottomParagraph}>
                This will not impact the folders of existing Applications, but will impact future Applications.
              </p>
            )}
          </>
        }
        handleClose={closePopUp}
        cancelBtnDisabled={isLoading}
        deleteBtnText={title}
      />
    </PopUpWizard>
  );
};

export default DeleteDocumentFolderPopUp;
