import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export interface UnlockBorrowerAction extends AnyAction {
  type: LoanOriginationSystemBorrowerLockType.UnlockBorrower;
  payload: {
    borrowerId: string;
  };
}

export interface UnlockBorrowerSuccessAction {
  type: LoanOriginationSystemBorrowerLockType.UnlockBorrowerSuccess;
  payload: {
    borrower: Borrower;
  };
}

export interface LockBorrowerAction extends AnyAction {
  type: LoanOriginationSystemBorrowerLockType.LockBorrower;
  payload: {
    borrowerId: string;
  };
}

export interface LockBorrowerSuccessAction extends AnyAction {
  type: LoanOriginationSystemBorrowerLockType.LockBorrowerSuccess;
  payload: {
    borrower: Borrower;
  };
}

export interface SetBorrowerToUnlockAction {
  type: LoanOriginationSystemBorrowerLockType.SetBorrowerToUnlock;
  payload: {
    borrower: Borrower | null;
  };
}

export interface SetBorrowerToLockAction extends AnyAction {
  type: LoanOriginationSystemBorrowerLockType.SetBorrowerToLock;
  payload: {
    borrower: Borrower | null;
  }
}

export const unlockBorrower = (borrowerId: string): UnlockBorrowerAction => ({
  type: LoanOriginationSystemBorrowerLockType.UnlockBorrower,
  payload: {
    borrowerId,
  },
});

export const unlockBorrowerSuccess = (borrower: Borrower): UnlockBorrowerSuccessAction => ({
  type: LoanOriginationSystemBorrowerLockType.UnlockBorrowerSuccess,
  payload: {
    borrower,
  },
});

export const setBorrowerToLock = (borrower: Borrower | null): SetBorrowerToLockAction => ({
  type: LoanOriginationSystemBorrowerLockType.SetBorrowerToLock,
  payload: {
    borrower,
  },
});

export const lockBorrower = (borrowerId: string): LockBorrowerAction => ({
  type: LoanOriginationSystemBorrowerLockType.LockBorrower,
  payload: {
    borrowerId,
  },
});

export const lockBorrowerSuccess = (borrower: Borrower): LockBorrowerSuccessAction => ({
  type: LoanOriginationSystemBorrowerLockType.LockBorrowerSuccess,
  payload: {
    borrower,
  },
});

export const setBorrowerToUnlock = (borrower: Borrower | null): SetBorrowerToUnlockAction => ({
  type: LoanOriginationSystemBorrowerLockType.SetBorrowerToUnlock,
  payload: {
    borrower,
  },
});

export const unlockBorrowerFailure = FailedActionCreator(LoanOriginationSystemBorrowerLockType.UnlockBorrowerFailure);
export const lockBorrowerFailure = FailedActionCreator(LoanOriginationSystemBorrowerLockType.LockBorrowerFailure);
