import React, { useEffect, useState } from 'react';
import { useAuthProvider } from 'providers/AuthProvider';
import AuthLayout from 'components/Auth/AuthLayout';
import CreateAccountInviteForm from 'components/Auth/CreateAccountInviteForm';
import { useParams } from 'react-router';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'routes/RouteService';
import InvitationLinkExpired from 'pages/InvitationLinkExpired';
import { CreateNewAccountInviteData } from 'api/Core/UserInviteService';

interface CreateAccountInviteParams {
  token: string;
}

const CreateAccountInvite = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const { push } = useHistory();
  const params = useParams<CreateAccountInviteParams>();
  const [hasLinkExpired, setHasLinkExpired] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  const onSuccess = (notificationText: string) => {
    notification.createNotification(notificationText, 'success', dispatch);
    authProvider.logout();
    push(AppRoutes.SignIn);
  };

  const handleSubmit = async (data: CreateNewAccountInviteData) => {
    await authProvider.acceptInvite(params.token, data);

    onSuccess('Your account has been created. Please, sign in.');
  };

  const acceptInvite = async () => {
    try {
      const isAccepted = await authProvider.acceptInvite(params.token);

      if (isAccepted) {
        onSuccess('Invite accepted. Please, sign in.');
      } else {
        setIsNewUser(true);
      }
    } catch (error) {
      setHasLinkExpired(true);
    }
  };

  useEffect(() => {
    acceptInvite();
  }, []);

  const handleSignInClick = () => {
    push(AppRoutes.SignIn);
  };

  if (hasLinkExpired) {
    return <InvitationLinkExpired onSignInClick={handleSignInClick} />;
  }

  return (
    <AuthLayout
      title="Create Your Account"
      subTitle={isNewUser ? 'Please provide your name and set your password.' : ''}
    >
      {isNewUser && <CreateAccountInviteForm onSubmit={handleSubmit} />}
    </AuthLayout>
  );
};

export default CreateAccountInvite;
