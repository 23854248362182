import React, { ChangeEvent, ComponentType, useEffect, useState } from 'react';
import styles from './AddModule.module.scss';
import {
  CalculationScriptsImage,
  CloseButtonImage,
  ExternalIntegrationImage,
  ImportExistingModuleImage,
  RequirementsRulesImage,
  RuleBasedOutputsImage,
  ScoringModelImage,
  SimpleOutputsImage,
} from 'static/images';
import SelectableItem from 'components/SelectableItem';
import Button from 'components/Button';
import { CopyModuleData, CreateModuleData } from 'ModuleInfo/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { Integration } from 'api/DecisionEngineStrategiesType';
import TextInput from 'components/TextInput';
import ExternalIntegrationSelector from 'components/AddModulePage/ExternalIntegrationSelector';
import ModuleSelector from 'components/AddModulePage/ModuleSelector';
import { isEmpty } from 'lodash';
import { ModuleItem } from 'api/Types';
import trimAll from 'utils/trimAll';
import { validateLettersNumbersOnly } from 'input-validators';

interface DefaultModuleOptionType {
  Image: ComponentType;
  title: string;
  description: string;
  type: ModuleType;
}

const defaultModuleOptionsList: DefaultModuleOptionType[] = [
  {
    Image: RequirementsRulesImage,
    title: 'Requirements Rules',
    description: 'Requirements for a decision to pass and assignment of decline reasons',
    type: ModuleType.RequirementsRules,
  },
  {
    Image: RuleBasedOutputsImage,
    title: 'Rule-Based Outputs',
    description: 'Rules that assign values to output variables if required conditions pass',
    type: ModuleType.RuleBasedOutputs,
  },
  {
    Image: ScoringModelImage,
    title: 'Scoring Model',
    description: 'Scorecard that runs rules, adds weights and produces a resulting score',
    type: ModuleType.ScoringModel,
  },
  {
    Image: CalculationScriptsImage,
    title: 'Calculation Scripts',
    description: 'Formula that assigns a result to variables using Excel-like syntax',
    type: ModuleType.CalculationScripts,
  },
  {
    Image: SimpleOutputsImage,
    title: 'Simple Outputs',
    description: 'Assigns values to output variables',
    type: ModuleType.SimpleOutputs,
  },
  {
    Image: ExternalIntegrationImage,
    title: 'External Integration',
    description: 'Integrations with data sources and machine learning models',
    type: ModuleType.ExternalIntegration,
  },
  {
    Image: ImportExistingModuleImage,
    title: 'Import Existing Module',
    description: 'Duplicates an existing module from this or another Strategy',
    type: ModuleType.ImportExistingModule,
  },
];

interface AddModuleProps {
  onModuleSave: (data: Pick<CreateModuleData, 'name' | 'currentType' | 'moduleIndex'>) => Promise<void>;
  onModuleCopy: (params: CopyModuleData['moduleParams']) => Promise<void>;
  onClose: () => void;
  integrationsList: Integration[];
  getIntegrationsList: () => void;
  moduleIndex: number;
}

const AddModule = ({
  onModuleSave,
  onModuleCopy,
  onClose,
  integrationsList,
  getIntegrationsList,
  moduleIndex,
}: AddModuleProps) => {
  const [currentModuleType, setCurrentModuleType] = useState<ModuleType | undefined>();
  const [moduleName, setModuleTitle] = useState<string>('');
  const [copyModuleParams, setCopyModuleParams] = useState<ModuleItem | null>(null);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleChangeTitle = ({ target }: ChangeEvent<HTMLInputElement>): void => setModuleTitle(target.value);
  const handleChangeActiveModuleType = (type: ModuleType): void => {
    setCurrentModuleType(type);
  };

  const isExternalIntegrationSelected = currentModuleType === ModuleType.ExternalIntegration;
  const isImportExistingModuleSelected = currentModuleType === ModuleType.ImportExistingModule;

  useEffect(() => {
    getIntegrationsList();
  }, []);

  const currentModuleName =
    currentModuleType === ModuleType.ExternalIntegration
      ? (integrationsList.find((integration) => integration.name === moduleName) || {}).value
      : trimAll(moduleName);

  const handleOnSave = async (): Promise<void> => {
    try {
      setIsSaveLoading(true);

      if (isImportExistingModuleSelected) {
        await onModuleCopy({
          strategyId: copyModuleParams!.strategyId,
          moduleKey: copyModuleParams!.moduleKey,
          moduleName: copyModuleParams!.moduleName,
          moduleType: copyModuleParams!.moduleType,
          moduleIndex,
        });
      } else {
        await onModuleSave({
          currentType: currentModuleType as ModuleType,
          name: currentModuleName as string,
          moduleIndex,
        });
      }

      onClose();
    } finally {
      setIsSaveLoading(false);
    }
  };

  const changeSelectedIntegration = (integrationName: string) => {
    setModuleTitle(integrationName);
  };

  const isSaveButtonDisabled = () => {
    if (!currentModuleType) {
      return true;
    }
    return isImportExistingModuleSelected ? isEmpty(copyModuleParams) : !trimAll(moduleName);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Add Module</h2>
        <CloseButtonImage className={styles.closeImage} onClick={onClose} />
      </div>
      <div className={styles.description}>Module Type</div>
      <div className={styles.content}>
        {defaultModuleOptionsList.map((moduleOption, index) => {
          const { Image, title, description, type } = moduleOption;
          return (
            <SelectableItem
              Image={Image}
              title={title}
              description={description}
              key={index}
              onClick={handleChangeActiveModuleType}
              selected={type === currentModuleType}
              type={type}
            >
              {type === ModuleType.ExternalIntegration && isExternalIntegrationSelected ? (
                <ExternalIntegrationSelector
                  integrationsList={integrationsList}
                  setSelectedIntegration={changeSelectedIntegration}
                />
              ) : null}
              {type === ModuleType.ImportExistingModule && isImportExistingModuleSelected ? (
                <ModuleSelector handleModuleChange={setCopyModuleParams} selectedModuleParams={copyModuleParams} />
              ) : null}
            </SelectableItem>
          );
        })}
      </div>
      {!isImportExistingModuleSelected && (
        <div className={styles.inputContainer}>
          <TextInput
            labelTitle="Module Name"
            value={moduleName}
            onChange={handleChangeTitle}
            disabled={isExternalIntegrationSelected}
            valueValidator={validateLettersNumbersOnly}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button
          size="form"
          kind="primary"
          onClick={handleOnSave}
          disabled={isSaveButtonDisabled()}
          isLoading={isSaveLoading}
        >
          Add Module
        </Button>
      </div>
    </div>
  );
};

export default AddModule;
