import { createAction, createReducer, isAnyOf } from '@reduxjs/toolkit';
import { WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import {
  getWebhookEndpoints,
  createWebhookEndpoint,
  updateWebhookEndpoint, getWebhookEndpoint, generateNewWebhookEndpointSecret,
} from './Thunks';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { WebhookEndpointsActionType } from './WebhookEndpointsActionType';

export interface WebhookEndpointsState {
  webhookEndpointsById: Record<string, WebhookEndpoint>;
}

const initialState: WebhookEndpointsState = {
  webhookEndpointsById: {},
};

export const saveWebhookEndpoints = createAction<WebhookEndpoint[]>(WebhookEndpointsActionType.SaveWebhookEndpoints);

const webhookEndpointsReducer = createReducer<WebhookEndpointsState>(initialState, (builder) => {
  builder.addCase(getWebhookEndpoints.fulfilled, (state, action) => {
    state.webhookEndpointsById = {
      ...state.webhookEndpointsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addCase(saveWebhookEndpoints, (state, action) => {
    state.webhookEndpointsById = {
      ...state.webhookEndpointsById,
      ...normalizeEntityArray(action.payload),
    };
  });

  builder.addCase(generateNewWebhookEndpointSecret.fulfilled, (state, action) => {
    state.webhookEndpointsById[action.payload.id].endpointSecret = action.payload.endpointSecret;
  });

  builder.addMatcher(
    isAnyOf(getWebhookEndpoint.fulfilled, updateWebhookEndpoint.fulfilled, createWebhookEndpoint.fulfilled),
    (state, action) => {
      state.webhookEndpointsById = {
        ...state.webhookEndpointsById,
        [action.payload.id]: action.payload,
      };
    });
  });

export default webhookEndpointsReducer;
