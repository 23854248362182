import ReportingDashboardRange from './ReportingDashboardRange';
import moment from 'moment';
import { min } from 'lodash';

const LoanOriginationSystemReportingDefaultFilters = {
  [ReportingDashboardRange.LastThreeYears]: () => {
    return moment()
      .subtract(3, 'years')
      .startOf('day')
      .toDate();
  },
  [ReportingDashboardRange.LastTwelveMonths]: () => {
    return moment()
      .subtract(1, 'year')
      .startOf('day')
      .toDate();
  },
  [ReportingDashboardRange.ThisYear]: () => {
    return moment()
      .startOf('year')
      .startOf('day')
      .toDate();
  },
  [ReportingDashboardRange.LastThirtyDays]: () => {
    return moment()
      .subtract(30, 'days')
      .startOf('day')
      .toDate();
  },
  [ReportingDashboardRange.ThisMonth]: () => {
    return moment()
      .startOf('month')
      .startOf('day')
      .toDate();
  },
  [ReportingDashboardRange.AllTime]: (date: Date) => {
    const lastYear = moment()
      .subtract(1, 'year')
      .startOf('day')
      .toDate();

    return min([lastYear, date]) || lastYear;
  },
};

export const getStartOfTheDay = (date = new Date()) =>
  moment(date)
    .startOf('day')
    .toDate();
export const getEndOfTheDay = (date = new Date()) =>
  moment(date)
    .endOf('day')
    .toDate();

export default LoanOriginationSystemReportingDefaultFilters;
