import { BorrowerSuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const MIN_LENGTH = 2;

const shouldShowSuggestions = (filter: BorrowerSuggestionFilter) => {
  return (
    filter.firstName?.length >= MIN_LENGTH ||
    filter.lastName?.length >= MIN_LENGTH ||
    filter.phoneNumber?.length >= MIN_LENGTH ||
    filter.email?.length >= MIN_LENGTH ||
    filter.companyName?.length >= MIN_LENGTH ||
    filter.personalIdNumber?.length >= MIN_LENGTH ||
    filter.companyIdNumber?.length >= MIN_LENGTH ||
    filter.dateOfBirth?.length >= MIN_LENGTH
  );
};

export default shouldShowSuggestions;
