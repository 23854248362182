import { WebhookEventStatus } from 'api/Webhooks/WebhookEventsApi';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { WebhookEventsTableFiltersActionType } from './WebhookEventsTableFiltersActionType';

export interface ChangeFiltersPayload {
  eventTypes: string[];
  statuses: WebhookEventStatus[];
  dueCreatedDateRange: {
    from: Date | null,
    to: Date | null,
  };
}

export interface WebhookEventsTableFiltersState {
  statuses: WebhookEventStatus[],
  search: string;
  eventTypes: string[];
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

export const initialState: WebhookEventsTableFiltersState = {
  statuses: [],
  search: '',
  eventTypes: [],
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
};

export const setSearchInputValue = createAction<string>(WebhookEventsTableFiltersActionType.SetSearchInputValue);
export const changeFilters = createAction<ChangeFiltersPayload>(WebhookEventsTableFiltersActionType.ChangeFilters);
export const clearFilters = createAction(WebhookEventsTableFiltersActionType.ClearFilters);

const webhookEventsTableFiltersReducer = createReducer(initialState, (builder) => {
  builder.addCase(changeFilters, (state, action) => {
    state.statuses = action.payload.statuses;
    state.eventTypes = action.payload.eventTypes;
    state.dueCreatedDateRange = action.payload.dueCreatedDateRange;
  });

  builder.addCase(clearFilters, (state) => {
    state.statuses = [];
    state.eventTypes = [];
    state.dueCreatedDateRange = { from: null, to: null };
  });

  builder.addCase(setSearchInputValue, (state, action) => {
    state.search = action.payload;
  });
});

export default webhookEventsTableFiltersReducer
