import React, { FC, useEffect, useState } from 'react';
import AutoCompletion from 'components/AutoCompletion';
import { StrategiesListItem, VariableValueMap } from 'api/Types';
import InputWithDataType from 'components/InputWithDataType';
import styles from 'components/ApplicationDecisionRunForm/ApplicationDecisionRunForm.module.scss';
import Button from 'components/Button';
import { Option } from 'components/SelectInput/SelectInput';
import { Tag } from 'components/Tag';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import formatValueByDataType from 'utils/formatValueByDataType';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { InputValues } from 'components/RunDecisionForm/RunDecisionForm';
import { statusColors } from 'components/Decisions/PopUps/RunMultipleDecision';
import { Variable } from 'Variables/VariablesTypes';

interface RunDecisionFormProps {
  strategies: StrategiesListItem[];
  strategyInputs: Variable[] | null;
  onRunDecision: (decisionName: string) => void;
  onStrategyChange: (strategyId: string) => void;
  currentStrategy?: string | null;
  inputValues: VariableValueMap;
  dataLoading?: boolean;
  isLoading?: boolean;
}

const TABLE_CELL_WIDTH = 300;

const ApplicationDecisionRunForm: FC<RunDecisionFormProps> = ({
  currentStrategy = '',
  strategies,
  strategyInputs,
  inputValues,
  onRunDecision,
  onStrategyChange,
  dataLoading,
  isLoading,
}) => {
  const [decisionName, setDecisionName] = useState<string>('');
  const [inputs, setInputs] = useState<InputValues | null>(null);

  useEffect(() => {
    if (currentStrategy && strategyInputs) {
      const inputsToSet: Record<string, string> = strategyInputs.reduce(
        (resultInputs, { systemName }) => {
          const inputValue = inputValues[systemName];
          const value = isEmptyVariableValue(inputValue) ? '' : inputValue;

          return {
            ...resultInputs,
            [systemName]: value.toString(),
          };
        },
        {},
      );

      setInputs(inputsToSet);
    }
  }, [strategyInputs, currentStrategy]);

  const handleRunDecision = () => {
    if (!currentStrategy || !strategyInputs) {
      return;
    }

    onRunDecision(decisionName);
  };

  const renderInput = (input: Variable) => {
    const inputValue = inputs?.[input.systemName];

    return (
      <TableRow disableHover key={input.displayName}>
        <TableBodyCell width={TABLE_CELL_WIDTH} className={styles.variableFieldName}>{input.displayName}</TableBodyCell>
        <TableBodyCell width={TABLE_CELL_WIDTH} className={styles.iconContainer}>
          {isEmptyVariableValue(inputValue)
            ? 'null'
            : formatValueByDataType(inputValue, input)}
        </TableBodyCell>
      </TableRow>
    );
  };

  const renderStrategyInputs = () => {
    if (!strategyInputs?.length) {
      return null;
    }

    return (
      <Table className={styles.table}>
          <TableHead>
          <TableHeadCell width={TABLE_CELL_WIDTH}>Variable name</TableHeadCell>
        <TableHeadCell width={TABLE_CELL_WIDTH}>Value</TableHeadCell>
      </TableHead>
        <TableBody>{strategyInputs.map(renderInput)}</TableBody>
      </Table>
    );
  };

  const getStrategyOptions = (): Option[] => {
    return strategies.map(({ id, name, status }: StrategiesListItem) => {
      const normalizedStatus = getNormalizedStatus(status);
      return {
        name,
        value: id,
        iconAfter: <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
      };
    });
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.input}>
          <AutoCompletion
            required
            disabled={dataLoading}
            labelTitle="Strategy Name"
            onChange={({ value }: Option) => onStrategyChange(value || '')}
            value={currentStrategy || ''}
            options={getStrategyOptions()}
            loading={dataLoading}
          />
        </div>
        <div className={styles.input}>
          <InputWithDataType
            dataType="Text"
            labelTitle="Decision Name"
            value={decisionName}
            options={null}
            onChange={(value: string) => setDecisionName(value)}
          />
        </div>
        {!dataLoading && currentStrategy && renderStrategyInputs()}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          kind="primary"
          size="form"
          disabled={!currentStrategy || !strategyInputs || dataLoading}
          isLoading={isLoading}
          onClick={handleRunDecision}
        >
          Run Decision
        </Button>
      </div>
    </>
  );
};

export default ApplicationDecisionRunForm;
