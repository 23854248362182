const SIZES = {
  FIRST_NAME: 14,
  LAST_NAME: 14,
  EMAIL: 22,
  PHONE_AUTHENTICATION: 16,
  PERMISSION_GROUP: 16,
  ACCOUNT_STATUS: 11,
};

export default SIZES;
