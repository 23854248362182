import { isPending, AnyAction } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import { ReportingDashboardState } from './LoanOriginationSystemReportingStore';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';

type ReportingFiltersToSave = Pick<ReportingDashboardState, 'filters' | 'additionalFilters'>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters, additionalFilters } = state.reportingDashboard;

  return {
    filters,
    additionalFilters,
  };
};

const saveTriggerActions = [
  (action: AnyAction) =>
    isPending(action) && action.type.includes(LoanOriginationSystemReportingActionType.GetReportingDashboardData),
];

const reportingFilterSaver = FilterAndSortingSaver<ReportingFiltersToSave>({
  key: 'reporting',
  getFiltersToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemReportingActionType.ResetState,
});

export default reportingFilterSaver;
