import React, { FC, ReactNode } from 'react';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import CardsLayoutPreview from 'components/LoanOriginationSystem/CardsLayoutPreview';
import ProfileDescriptionTooltip from './ProfileDescriptionTooltip';
import styles from './ProfileConfigurationPage.module.scss';

export interface ProfileConfigurationPageProps {
  cards: Card[] | null;
  title: string;
  actions?: ReactNode;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  profileName: string;
  onGoToProfileClick: () => void;
}

const ProfileConfigurationPage: FC<ProfileConfigurationPageProps> = ({
  cards,
  title,
  actions,
  formatVariableConfigurationDisplayTitle,
  profileName,
  onGoToProfileClick,
}) => {
  const renderDescriptionTooltip = () => (
    <ProfileDescriptionTooltip onGoToProfileClick={onGoToProfileClick} profileName={profileName} />
  );

  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleWithActions}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.actions}>
            {actions}
          </div>
        </div>
      </div>
      <CardsLayoutPreview
        cards={cards}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        descriptionTooltip={renderDescriptionTooltip()}
      />
    </>
  );
};

export default ProfileConfigurationPage;
