import {
  ChangePopupFiltersAction,
  ClearAllFiltersAction,
  FilterApplicationsByMembersAction,
  GetProductIntermediariesSuccessAction,
  GetProductLabelsSuccessAction,
  SetSearchInputValueAction,
  ToggleFiltersPopupAction,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import {
  ApplicationsActionType,
  CHANGE_POPUP_FILTERS,
  FILTER_APPLICATIONS_BY_MEMBERS,
  GET_PRODUCT_INTERMEDIARIES_SUCCESS,
  GET_PRODUCT_LABELS_SUCCESS,
  SET_SEARCH_INPUT_VALUE,
  TOGGLE_FILTERS_POPUP,
} from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import {
  LoanOriginationSystemApplicationsFiltersState,
  BaseLoanOriginationSystemApplicationsFilters,
} from 'LoanOriginationSystemApplications/Filters/Types';

export const baseApplicationsFiltersInitialState: BaseLoanOriginationSystemApplicationsFilters = {
  searchInputValue: '',
  selectedMembers: [],
  selectedStatusesIds: [],
  selectedLabels: [],
  selectedIntermediaries: [],
  createdDateRange: {
    from: null,
    to: null,
  },
  updatedDateRange: {
    from: null,
    to: null,
  },
};

export const applicationsFiltersInitialState: LoanOriginationSystemApplicationsFiltersState = {
  popupVisible: false,
  labels: [],
  intermediaries: [],
  ...baseApplicationsFiltersInitialState,
};

type LoanOriginationSystemApplicationsFiltersActions =
  | SetSearchInputValueAction
  | FilterApplicationsByMembersAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | GetProductLabelsSuccessAction
  | GetProductIntermediariesSuccessAction
  | ClearAllFiltersAction;

export const loanOriginationSystemApplicationsFiltersReducer = (
  state: LoanOriginationSystemApplicationsFiltersState = applicationsFiltersInitialState,
  action: LoanOriginationSystemApplicationsFiltersActions,
): LoanOriginationSystemApplicationsFiltersState => {
  switch (action.type) {
    case SET_SEARCH_INPUT_VALUE:
      return {
        ...state,
        searchInputValue: action.payload.searchValue,
      };
    case FILTER_APPLICATIONS_BY_MEMBERS:
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    case TOGGLE_FILTERS_POPUP:
      return {
        ...state,
        popupVisible: !state.popupVisible,
      };
    case CHANGE_POPUP_FILTERS:
      return {
        ...state,
        selectedStatusesIds: action.payload.statusesIds,
        selectedLabels: action.payload.labels,
        selectedIntermediaries: action.payload.intermediaries,
        createdDateRange: action.payload.createdDateRange,
        updatedDateRange: action.payload.updatedDateRange,
        popupVisible: false,
      };
    case GET_PRODUCT_LABELS_SUCCESS:
      return {
        ...state,
        labels: action.payload.labels,
      };
    case GET_PRODUCT_INTERMEDIARIES_SUCCESS:
      return {
        ...state,
        intermediaries: action.payload.intermediaries,
      };
    case ApplicationsActionType.ClearAllFilters:
      return {
        ...applicationsFiltersInitialState,
        labels: state.labels,
        intermediaries: state.intermediaries,
      };
    default:
      return state;
  }
};
