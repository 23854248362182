import React, { PropsWithChildren } from 'react';
import styles from './ScrollableContainer.module.scss';
import clsx from 'clsx';

interface ScrollableContainerProps {
  className?: string;
}

const ScrollableContainer = ({ children, className }: PropsWithChildren<ScrollableContainerProps>) => {
  return (
    <div className={clsx(styles.scrollableContainer, className)}>{children}</div>
  );
}

export default ScrollableContainer;
