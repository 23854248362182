import millify from 'millify';

const REGEX = /(\d+)(\.?)(\d*)(\w)?/;

const formatWithPostfix = (value: number, precision = 0) => {
  const result = millify(value, { precision });

  if (!precision) {
    return result;
  }

  const [, firstPart = '', , secondPart = '', postfix = ''] = result.match(REGEX) || [];

  return `${firstPart}.${secondPart.padEnd(precision, '0')}${postfix}`;
};

export default formatWithPostfix;
