import React, { useState, useCallback, FC } from 'react';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import JexlCodeEditor from 'components/CodeEditor/JexlCodeEditor';
import Button from 'components/Button';
import TestFormulaPopup from 'components/TestFormulaPopup';
import useCodeEditorVariablesGlobals from 'components/CodeEditor/useCodeEditorVariablesGlobals';
import useCodeEditorVariablesSuggestions from 'components/CodeEditor/useCodeEditorVariablesSuggestions';
import styles from './FormulaConditionalDisplayRuleBuilder.module.scss';

export interface FormulaConditionalDisplayRuleBuilderProps {
  formula?: string;
  variableName: string;
  onSave: (formula: string) => void;
  isRuleBuildingInProgress?: boolean;
}

const TRUE_STRING = 'true';

const FormulaConditionalDisplayRuleBuilder: FC<FormulaConditionalDisplayRuleBuilderProps> = ({
  formula: initialFormula,
  onSave,
  isRuleBuildingInProgress,
}) => {
  const [codeEditorWasBlurred, setCodeEditorWasBlurred] = useState(false);
  const [displayTestFormulaPopup, setDisplayTestFormulaPopup] = useState(false);
  const [formula, setFormula] = useState(initialFormula || '');
  const [error, setError] = useState('');
  const globalsToHighlight = useCodeEditorVariablesGlobals();
  const variablesSuggestions = useCodeEditorVariablesSuggestions();

  const isFormulaChanged = formula !== initialFormula;

  const handleSyntaxValidationComplete = useCallback((errors: string[]) => {
    setError(errors[0] || '');
  }, []);

  const handleFormulaChange = useCallback((updatedFormula: string) => {
    setFormula(updatedFormula);
  }, []);

  const handleCodeEditorBlur = useCallback(() => {
    setCodeEditorWasBlurred(true);
  }, []);

  const isValidFormulaOutput = (formulaOutput: VariableValue | undefined) => {
    if (typeof formulaOutput === 'string') {
      return formulaOutput.toLowerCase() === TRUE_STRING;
    }

    if (typeof formulaOutput !== 'boolean') {
      return false;
    }

    return formulaOutput;
  }

  const renderTestConditionButtonTooltip = () => {
    if (error) {
      return 'This is an invalid Formula and cannot be tested.';
    }

    if (!formula) {
      return 'Add a formula before testing.';
    }

    return null;
  };

  const validateTestCondition = (formulaOutput: VariableValue | undefined) => {
    const valid = isValidFormulaOutput(formulaOutput);

    return {
      valid,
      description: valid
        ? 'The condition passes.'
        : 'The condition does not pass.',
    };
  };

  const renderLabelTooltip = () => (
    <>
      <p>This condition must return “true”</p>
      <p>(string) or TRUE (boolean) to pass</p>
    </>
  );

  return (
    <div className={styles.container}>
      <JexlCodeEditor
        labelTooltip={renderLabelTooltip()}
        label="Display Condition"
        initialValue={formula}
        testCodeTitle="Test Condition"
        onSyntaxValidationComplete={handleSyntaxValidationComplete}
        error={(codeEditorWasBlurred && error) ? 'This is an invalid Formula and cannot be saved.' : ''}
        onChange={handleFormulaChange}
        required
        testCodeButtonDisabled={!formula || !!error}
        variablesSuggestions={variablesSuggestions}
        globalsToHighlight={globalsToHighlight}
        onTestCodeButtonClick={() => setDisplayTestFormulaPopup(true)}
        testCodeButtonTooltip={renderTestConditionButtonTooltip()}
        onBlur={handleCodeEditorBlur}
      />
      <Button
        className={styles.saveButton}
        disabled={!formula || !!error || !isFormulaChanged}
        kind="primary"
        size="form"
        onClick={() => onSave(formula)}
        isLoading={isRuleBuildingInProgress}
      >
        Save Changes
      </Button>
      {displayTestFormulaPopup && (
        <TestFormulaPopup
          usePortal
          title="Test Condition"
          formula={formula}
          onClose={() => setDisplayTestFormulaPopup(false)}
          validateCondition={validateTestCondition}
        />
      )}
    </div>
  );
};

export default FormulaConditionalDisplayRuleBuilder;
