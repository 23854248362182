import React, { FC, MouseEvent, useRef, useState } from 'react';
import { PopoverOrigin } from '@material-ui/core';
import clsx from 'clsx';
import styles from './RowActions.module.scss';
import { MoreImage } from 'static/images';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import Separator from 'components/Separator';
import WrapperWithTooltip from 'components/Tooltip';
import Spinner from 'components/Spinner';

export interface RowAction {
  title: string;
  handler: (id?: string) => void;
  danger?: boolean;
  separatorRequired?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

export interface RowActionsProps {
  id?: string;
  actions: RowAction[];
  handleDeleteRule?: () => void;
  className?: string;
  btnClassName?: string;
  popupClassName?: string;
  alwaysVisible?: boolean;
  isLoading?: boolean;
  popupTitle?: string;
  btnTooltip?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const RowActions: FC<RowActionsProps> = React.memo(({
  id,
  actions,
  className,
  btnClassName,
  popupClassName,
  popupTitle,
  btnTooltip,
  alwaysVisible,
  isLoading,
  anchorOrigin,
  transformOrigin,
}) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const handleOpenPopUp = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    setIsPopUpOpen(true);
  };
  const handleClosePopUp = (actionCallback?: RowAction['handler']): void => {
    setIsPopUpOpen(false);
    if (actionCallback) {
      actionCallback(id);
    }
  };
  const popupHandlerRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={clsx(styles.buttonContainer, className)} onClick={(e) => e.stopPropagation()}>
      <WrapperWithTooltip tooltip={btnTooltip}>
        <button
          type="button"
          className={clsx(
            styles.actionsButton,
            btnClassName,
            (alwaysVisible || isLoading) && styles.alwaysVisible,
            isLoading && styles.loading,
          )}
          onClick={handleOpenPopUp}
          ref={popupHandlerRef}
          disabled={isLoading}
        >
          {isLoading ? <Spinner className={styles.spinner} size={24} /> : <MoreImage />}
        </button>
      </WrapperWithTooltip>
      <ContextualPopUp
        open={isPopUpOpen}
        anchorEl={popupHandlerRef.current}
        onClose={() => handleClosePopUp()}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ActionPopUp className={popupClassName} onClose={() => handleClosePopUp()} title={popupTitle}>
          {actions.map(({ title, danger, handler, separatorRequired, disabled, tooltip }) => (
            <WrapperWithTooltip tooltip={tooltip} key={title}>
              <div className={styles.actionItemContainer}>
                <ActionPopUpItem
                  disabled={disabled}
                  danger={danger}
                  onClick={() => handleClosePopUp(handler)}
                  className={clsx(disabled && styles.disabledAction)}
                >
                  {title}
                </ActionPopUpItem>
                {separatorRequired && <Separator />}
              </div>
            </WrapperWithTooltip>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </div>
  );
});

export default RowActions;
