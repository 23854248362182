import React, { FC } from 'react';
import clsx from 'clsx';
import OverflowedText from 'components/OverflowedText';
import ButtonWithImage from 'components/ButtonWithImage';
import Button from 'components/Button';
import styles from './DetailsForm.module.scss';

export interface DetailsFormField {
  label: string;
  value: React.ReactNode;
}

export interface DetailsFormProps {
  isEditMode?: boolean;
  fields: Array<DetailsFormField> | null;
  labelWidth?: string;
  columns?: number;
  onSwitchEditMode?: () => void;
  title: string;
  isSaveChangesInProgress?: boolean;
  isSaveChangesButtonDisabled?: boolean;
  onSaveChanges?: () => void;
}

const DEFAULT_COLUMNS = 2;
const DEFAULT_LABEL_WIDTH = '50%';

const DetailsForm: FC<DetailsFormProps> = ({
  fields,
  columns = DEFAULT_COLUMNS,
  labelWidth = DEFAULT_LABEL_WIDTH,
  isEditMode,
  title,
  onSwitchEditMode,
  isSaveChangesInProgress,
  onSaveChanges,
  isSaveChangesButtonDisabled,
  children,
}) => {
  const isFirstFieldInColumn = (index: number) => {
    return index < columns;
  };

  const renderField = (field: DetailsFormField, index: number) => (
    <div key={`${field.label}-${index}`} className={clsx(styles.field, isFirstFieldInColumn(index) && styles.withTopBorderField)}>
      <div style={{ width: labelWidth }} className={styles.fieldLabel}>
        <OverflowedText>
          {field.label}
        </OverflowedText>
      </div>
      <div style={{ width: `calc(100% - ${labelWidth})` }} className={styles.fieldValue}>
        <OverflowedText>
          {field.value}
        </OverflowedText>
      </div>
    </div>
  );

  const renderContent = () => {
    if (isEditMode) {
      return children;
    }

    if (!fields) {
      return (
        <div>
          Skeleton
        </div>
      );
    }

    return (
      <>
        {fields.map(renderField)}
      </>
    );
  }

  const detailsFormContentStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  return (
    <div className={styles.detailsForm}>
      <div className={styles.detailsFormHeader}>
        <h4 className={styles.headerTitle}>{title}</h4>
        {!isEditMode && children && <ButtonWithImage
          disabled={!fields}
          className={styles.editInformationButton}
          kind="edit"
          onClick={onSwitchEditMode}
          title="Edit Information"
        />}
      </div>
      {isEditMode && <div className={styles.editFormActions}>
        <Button
          onClick={() => onSwitchEditMode?.()}
          className={styles.closeEditorButton}
          kind="secondary"
        >
          Close Editor
        </Button>
        <Button
          className={styles.saveChangesButton}
          isLoading={isSaveChangesInProgress}
          disabled={isSaveChangesButtonDisabled}
          onClick={onSaveChanges}
          kind="primary"
        >
          Save Changes
        </Button>
      </div>}
      <div style={detailsFormContentStyle} className={styles.detailsFormContent}>
        {renderContent()}
      </div>
    </div>
  );
};

export default DetailsForm;
