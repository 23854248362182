import { EditVariableSuccessAction } from 'Variables/VariablesActionCreator';
import { VariableItem } from 'api/VariablesType';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import GetProductCalculationsError from 'errors/GetProductCalculationsError';
import { ACTION_TYPE_EDIT_VARIABLE_SUCCESS } from 'Variables/ActionTypes';
import { ProductCalculationsState } from './Types';
import { ProductCalculationsType } from './ActionTypes';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  CreateProductCalculationSuccessAction,
  DeleteProductCalculationSuccessAction,
  GetProductCalculationsAction,
  GetProductCalculationsSuccessAction,
  UpdateProductCalculationSuccessAction,
} from './ActionCreator';

export type ProductCalculationActions =
  | EditVariableSuccessAction
  | GetProductCalculationsAction
  | GetProductCalculationsSuccessAction
  | FailedAction<ProductCalculationsType.GetProductCalculationsFailure, undefined, GetProductCalculationsError>
  | CreateProductCalculationSuccessAction
  | UpdateProductCalculationSuccessAction
  | DeleteProductCalculationSuccessAction;

export const initialState: ProductCalculationsState = {
  calculationsById: {},
};

const getNewProductCalculation = (calculation: ProductCalculation, variable: VariableItem) => {
  if (calculation.variable.id === variable.id) {
    return {
      ...calculation,
      variable: {
        id: variable.id,
        displayName: variable.displayName,
        systemName: variable.systemName,
        dataType: variable.dataType,
      },
    };
  }

  return calculation;
};

const updateVariableForProductCalculationsById = (
  calculationsById: Record<string, ProductCalculation>,
  variable: VariableItem,
) => {
  return Object.values(calculationsById).reduce((newCalculationsById, calculation) => {
    return {
      ...newCalculationsById,
      [calculation.id]: getNewProductCalculation(calculation, variable),
    };
  }, {} as Record<string, ProductCalculation>);
};

const productCalculationsReducer = (
  state: ProductCalculationsState = initialState,
  action: ProductCalculationActions,
): ProductCalculationsState => {
  switch (action.type) {
    case ProductCalculationsType.GetProductCalculationsSuccess: {
      return {
        ...state,
        calculationsById: normalizeEntityArray(action.payload.calculations),
      };
    }
    case ProductCalculationsType.CreateProductCalculationSuccess:
    case ProductCalculationsType.UpdateProductCalculationSuccess: {
      const { calculation } = action.payload;

      return {
        ...state,
        calculationsById: {
          ...state.calculationsById,
          [calculation.id]: calculation,
        },
      };
    }
    case ProductCalculationsType.DeleteProductCalculationSuccess: {
      const newCalculationsById = { ...state.calculationsById };

      delete newCalculationsById[action.payload.calculationId];

      return {
        ...state,
        calculationsById: newCalculationsById,
      };
    }
    case ACTION_TYPE_EDIT_VARIABLE_SUCCESS: {
      const { variable, meta } = action.payload;

      if (!meta) {
        return state;
      }

      return {
        ...state,
        calculationsById: updateVariableForProductCalculationsById(state.calculationsById, variable),
      };
    }
    default: {
      return state;
    }
  }
};

export default productCalculationsReducer;
