import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';
import UserRestApi, {
  OrganizationUsersSortingField,
  OrganizationUsersSortingType,
  UserDataFilter,
} from 'api/Core/UserApi';
import { ItemsStateWithPagination } from 'pagination';
import usersPagination from 'pages/CompanyInformationUsers/Pagination';
import responseHandlers from 'api/ResponseHandlers';
import withStateReset from 'utils/reducers/withStateReset';
import UserInviteApiRest from 'api/Core/UserInviteService';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import { OrganizationUsersActionTypes } from './ActionTypes';
import organizationUsersFilterAndSortingSaver from './FilterAndSortingSaver';

export interface OrganizationUsersState extends ItemsStateWithPagination<OrganizationMembership> {
  sortingType: OrganizationUsersSortingType;
  searchInputValue: string;
  showInactive: boolean;
  isFiltersPopUpVisible: boolean;
  permissionGroupId: PermissionGroupId | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
  usersDetailedAttributes: Partial<Record<string, OrganizationMembership>>;
  isUserDetailedAttributesLoading: boolean;
}

export interface FetchUsersParams {
  filters: UserDataFilter;
  sortingType: OrganizationUsersSortingType;
}

export const userInviteApi = new UserInviteApiRest(responseHandlers);
const userApi = new UserRestApi(responseHandlers);

const getInitialState = (): OrganizationUsersState => ({
  items: [],
  page: 1,
  itemsPerPage: 10,
  itemsTotal: undefined,
  searchInputValue: '',
  showInactive: false,
  isFiltersPopUpVisible: false,
  permissionGroupId: null,
  phoneAuthentication: null,
  emailVerified: null,
  sortingType: organizationUsersFilterAndSortingSaver.getSavedSorting() || {
    field: OrganizationUsersSortingField.FirstName,
    ascending: true,
  },
  error: null,
  usersDetailedAttributes: {},
  isUserDetailedAttributesLoading: false,
  ...organizationUsersFilterAndSortingSaver.getSavedFilters(),
});

export const resendInvite = createAsyncThunk(
  OrganizationUsersActionTypes.ResendInvite,
  async (membershipId: string) => {
    await userInviteApi.resendInvite(membershipId);
  },
);

export const requestOrganizationUsers = createAsyncThunk(
  OrganizationUsersActionTypes.RequestOrganizationUsers,
  async ({ filters, sortingType }: FetchUsersParams) => {
    const users = await userApi.getAll(
      {
        count: filters.count,
        offset: (filters.offset - 1) * filters.count,
        search: filters.search,
        permissionGroupId: filters.permissionGroupId,
        phoneAuthentication: filters.phoneAuthentication,
        showInactive: filters.showInactive,
      },
      sortingType,
    );

    return users;
  },
);

export const requestOrganizationUser = createAsyncThunk('requestOrganizationUser', async (membershipId: string) => {
  const user = await userApi.getUserByMembershipId(membershipId);

  return user;
});

export const editOrganizationUser = createAsyncThunk(
  'editOrganizationUser',
  async ({ userId, isActive, permissionGroupId }: EditUserParams) => {
    const user = await userApi.update(userId, { isActive, permissionGroupId });

    return user;
  },
);

export const toggleFiltersOrganizationUsersPopUp = createAction<{ popUpOpeningStatus: boolean }>(
  OrganizationUsersActionTypes.ToggleFiltersPopUp,
);

export const resetOrganizationUsersState = createAction(OrganizationUsersActionTypes.ResetState);

const OrganizationUsersReducer = createReducer<OrganizationUsersState>(getInitialState(), (builder) => {
  builder.addCase(requestOrganizationUsers.pending, (state, action) => {
    state.showInactive = action.meta.arg.filters.showInactive!;
    state.permissionGroupId = action.meta.arg.filters.permissionGroupId!;
    state.phoneAuthentication = action.meta.arg.filters.phoneAuthentication!;
    state.sortingType = action.meta.arg.sortingType;
    state.searchInputValue = action.meta.arg.filters.search;
    state.itemsTotal = undefined;
  });
  builder.addCase(requestOrganizationUsers.fulfilled, (state, action) => {
    state.items = action.payload.items;
    state.itemsTotal = action.payload.total;
    state.page = 1;
  });
  builder.addCase(requestOrganizationUser.pending, (state) => {
    state.isUserDetailedAttributesLoading = true;
  });
  builder.addCase(requestOrganizationUser.fulfilled, (state, action) => {
    state.usersDetailedAttributes = {
      ...state.usersDetailedAttributes,
      [action.payload.membershipId]: {
        ...action.payload,
      },
    };
    state.isUserDetailedAttributesLoading = false;
  });
  builder.addCase(requestOrganizationUser.rejected, (state) => {
    state.isUserDetailedAttributesLoading = false;
  });
  builder.addCase(toggleFiltersOrganizationUsersPopUp, (state, action) => {
    state.isFiltersPopUpVisible = action.payload.popUpOpeningStatus;
  });
});

export default usersPagination.wrapReducer<OrganizationUsersState>(
  withStateReset(OrganizationUsersReducer, OrganizationUsersActionTypes.ResetState, getInitialState),
);
