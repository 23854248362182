import React, { ChangeEvent, cloneElement, ReactElement, ReactNode, useState, useEffect } from 'react';
import { isEqual, mapValues, startCase } from 'lodash';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import OptionsInput from 'components/OptionsInput';
import applicationConfig from 'config';
import {
  DATE_FORMATS,
  IDENTIFICATION_NUMBER_TYPES,
  NUMBER_DATA_TYPES,
  TEXT_DATA_TYPES,
  VARIABLE_DATA_TYPES,
} from './DataTypes';
import {
  Variable,
  VariableAccessPermissionType,
  VariableClientType,
  VariableAccessPermissions,
  VisualDataType,
} from 'Variables/VariablesTypes';
import { DATA_TYPES_PARTIAL_READ_SUPPORTED, getVariableDataTypeName, getVisualDataType } from 'Variables/utils';
import FormRow from 'components/FormRow/FormRow';
import { Option } from 'components/SelectInput/SelectInput';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import { formattedCurrencies } from 'components/CurrencySelect/currencies';
import { SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import InformationPopup from 'components/InformationPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import List from 'components/List';
import getMessage, { MessageType } from 'constants/messages';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';
import trimAll from 'utils/trimAll';
import VariableTooltipWrapper from './VariableTooltipWrapper';
import {
  convertBorrowerSystemNameToCoBorrower,
  convertBorrowerDisplayTitleToCoBorrower,
  isBorrowerVariableSystemName,
  isCoBorrowerVariableSystemName,
} from 'utils/BorrowerVariableUtils';
import { validateLettersNumbersOnly } from 'input-validators';
import styles from './VariableForm.module.scss';
import SliderButton from 'components/StrategyGroups/SliderButton';
import { PermissionGroupId } from 'PermissionGroups/Types';
import Label from 'components/Label';
import usePermissionGroups from 'hooks/usePermissionGroups';

export interface VariableFormProps {
  onSave: (variableData: VariableClientType) => void;
  children: ReactElement<{ disabled?: boolean; onSave?: () => void }>;
  variable?: Variable;
  topRightElement?: ReactNode;
  regionSettings?: RegionSettings;
  additionalCoBorrowerToAdd?: number;
}

const EMPTY_SPACE_REGEXP = /\s+/g;
const SPECIAL_SYMBOLS_REGEXP = /[^a-z\d_]/g;
const STARTS_WITH_NUMBER_REGEX = /^\d/;
export const FORBIDDEN_OPTION_SYMBOLS_REGEXP = /[,;<>]/g;

const CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description', 'value'];
const IDENTIFICATION_NUMBER_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description'];

interface OptionSpec extends Omit<Option, 'disabled'> {
  isAvailable: (dataType: VisualDataType) => boolean;
}

const ACCESS_PERMISSIONS_SELECT_OPTIONS: OptionSpec[] = [
  {
    name: 'Read and Edit',
    value: VariableAccessPermissionType.ReadWrite,
    description: 'Data will be visible and editable',
    isAvailable: () => true,
  },
  {
    name: 'Read Only',
    value: VariableAccessPermissionType.ReadOnly,
    description: 'Data will be visible but not editable',
    isAvailable: () => true,
  },
  {
    name: 'Partially Hidden',
    value: VariableAccessPermissionType.PartialRead,
    description: 'Data will be partially hidden',
    isAvailable: (dataType) => DATA_TYPES_PARTIAL_READ_SUPPORTED.includes(dataType),
  },
  {
    name: 'Fully Hidden',
    value: VariableAccessPermissionType.NoAccess,
    description: 'Data will be completely hidden',
    isAvailable: () => true,
  },
];

const getAccessPermissionsOptions = (dataType: VisualDataType | undefined): Option[] =>
  ACCESS_PERMISSIONS_SELECT_OPTIONS.map(({ isAvailable, ...option }) => {
    if (!dataType) {
      return option;
    }

    const isDisabled = !isAvailable(dataType);

    const tooltip = isDisabled
      ? getMessage(MessageType.VariablePartiallyHiddenUnsupported, { dataType: getVariableDataTypeName(dataType) })
      : undefined;

    return {
      ...option,
      tooltip,
      disabled: isDisabled,
    };
  });

const getCompatibleDataTypes = (currentDataType: VisualDataType) => {
  if (currentDataType === 'Number' || currentDataType === 'Monetary' || currentDataType === 'Percentage') {
    return NUMBER_DATA_TYPES;
  }
  if (currentDataType === 'Text' || currentDataType === 'List') {
    return TEXT_DATA_TYPES;
  }
  return VARIABLE_DATA_TYPES;
};

const getDataTypeChangeTooltip = (sourceDataType: VisualDataType, targetDataType: VisualDataType) => {
  const sourceDataTypeName = getVariableDataTypeName(sourceDataType);
  const targetDataTypeName = getVariableDataTypeName(targetDataType);
  return getMessage(MessageType.VariableDataTypeChangeForbidden, {
    sourceDataType: sourceDataTypeName,
    targetDataType: targetDataTypeName,
  });
};

const getDataTypeOptions = (currentDataType: VisualDataType | undefined, isVariableSaved: boolean) => {
  if (typeof currentDataType === 'undefined' || !isVariableSaved) {
    return VARIABLE_DATA_TYPES;
  }
  const compatibleDataTypes = getCompatibleDataTypes(currentDataType);
  return VARIABLE_DATA_TYPES.map((option) => {
    const isEnabled = compatibleDataTypes.includes(option);
    const tooltip = isEnabled ? null : getDataTypeChangeTooltip(currentDataType, option.value as VisualDataType);
    return {
      ...option,
      disabled: !isEnabled,
      tooltip,
    };
  });
};

const getValue = (value: string | null, defaultValue: string): string | null => {
  if (value) {
    return value;
  }

  if (defaultValue) {
    return defaultValue;
  }

  return null;
};

const getDefaultShowPermissions = (permissions: VariableAccessPermissions) =>
  Object.keys(permissions).length > 0 &&
  Object.values(permissions).some((permission) => permission !== VariableAccessPermissionType.ReadWrite);

const arePermissionsSet = (permissions: VariableAccessPermissions) =>
  Object.values(permissions).every((permission) => Object.values(VariableAccessPermissionType).includes(permission));

const VariableForm = ({
  onSave,
  children,
  variable,
  topRightElement,
  regionSettings,
  additionalCoBorrowerToAdd = applicationConfig.coBorrowersCount,
}: VariableFormProps) => {
  const {
    displayName: name,
    description: fetchedDescription,
    systemName: fetchedSystemName,
    optionsList: fetchedOptionsList,
    permissions = {},
    phoneNumberFormat = null,
    identificationNumberType = null,
    identificationNumberDescription = null,
    currency = null,
    dateFormat = null,
  } = variable || {};

  const dataType = variable && getVisualDataType(variable);

  const {
    phoneNumberFormat: defaultPhoneNumberFormat = '',
    currencyFormat: defaultCurrencyFormat = '',
    dateFormat: defaultDateFormat = '',
  } = regionSettings || {};

  const [displayName, setDisplayName] = useState<string>(name || '');
  const [systemName, setSystemName] = useState<string>(fetchedSystemName || '');
  const [description, setDescription] = useState<string>(fetchedDescription || '');
  const [currentDataType, setCurrentDataType] = useState<VisualDataType | undefined>(dataType);
  const [optionsList, setOptionsList] = useState<string[]>(fetchedOptionsList || []);
  const [changedPermissions, setChangedPermissions] = useState<VariableAccessPermissions>(permissions);
  const [showPermissions, setShowPermissions] = useState(getDefaultShowPermissions(permissions));
  const [changedPhoneNumberFormat, setChangedPhoneNumberFormat] = useState(
    getValue(phoneNumberFormat, defaultPhoneNumberFormat),
  );
  const [changedIdentificationNumberType, setChangedIdentificationNumberType] = useState(
    getValue(identificationNumberType, ''),
  );
  const [changedIdentificationNumberDescription, setChangedIdentificationNumberDescription] = useState(
    identificationNumberDescription,
  );
  const [changedCurrency, setChangedCurrency] = useState(getValue(currency, defaultCurrencyFormat));
  const [changedDateFormat, setChangedDateFormat] = useState(getValue(dateFormat, defaultDateFormat));
  const [displayVariableNameConflictPopup, setDisplayVariableNameConflictPopup] = useState(false);
  const [displayConfirmCreatingCoBorrowerVariablesPopup, setDisplayConfirmCreatingCoBorrowerVariablesPopup] = useState(
    false,
  );

  useEffect(() => {
    setChangedPhoneNumberFormat(variable?.phoneNumberFormat ? phoneNumberFormat : defaultPhoneNumberFormat);
    setChangedCurrency(variable?.currency ? currency : defaultCurrencyFormat);
    setChangedDateFormat(variable?.dateFormat ? dateFormat : defaultDateFormat);
    setChangedIdentificationNumberType(variable?.identificationNumberType ? identificationNumberType : '');
  }, [variable]);

  const permissionGroups = usePermissionGroups();

  const onVariableSave = () => {
    if (currentDataType === 'PhoneNumber' && !changedPhoneNumberFormat) {
      return;
    }

    const correctDisplayName = trimAll(displayName);

    const getDataTypeParams = () => {
      switch (currentDataType) {
        case 'Monetary': {
          return {
            currency: changedCurrency!,
          };
        }
        case 'List': {
          return {
            optionsList,
          };
        }
        case 'Date': {
          return {
            dateFormat: changedDateFormat!,
          };
        }
        case 'PhoneNumber': {
          return {
            phoneNumberFormat: changedPhoneNumberFormat as string | null,
          };
        }
        case 'IdentificationNumber': {
          return {
            identificationNumberType: changedIdentificationNumberType,
            identificationNumberDescription: changedIdentificationNumberDescription,
          };
        }
        default: {
          return {};
        }
      }
    };

    const variableData: VariableClientType = {
      displayName: correctDisplayName,
      description,
      dataType: currentDataType as VisualDataType,
      permissions: changedPermissions,
      optionsList: null,
      phoneNumberFormat: null,
      identificationNumberType: null,
      identificationNumberDescription: null,
      currency: null,
      dateFormat: null,
      ...getDataTypeParams(),
    };

    if (isCoBorrowerVariableSystemName(systemName) && !variable) {
      setDisplayVariableNameConflictPopup(true);

      return;
    }

    onSave(variableData);
  };

  const onPhoneNumberFormatChange = ({ value }: Option) => {
    setChangedPhoneNumberFormat(value);
  };

  const onIdentificationNumberTypeChange = ({ value }: Option) => {
    setChangedIdentificationNumberType(value);
  };

  const onIdentificationNumberDescriptionChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setChangedIdentificationNumberDescription(target.value);
  };

  const onCurrencyChange = ({ value }: Option) => {
    setChangedCurrency(value);
  };
  const onDateFormatChange = ({ value }: Option) => {
    setChangedDateFormat(value);
  };

  const updateSystemName = (displayNameToSet: string) => {
    const systemNameToSet = displayNameToSet
      .trim()
      .toLowerCase()
      .replace(EMPTY_SPACE_REGEXP, '_')
      .replace(SPECIAL_SYMBOLS_REGEXP, '');
    setSystemName(systemNameToSet);
  };

  const onDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const displayNameToSet = e.target.value;
    setDisplayName(displayNameToSet);
    if (!variable?.id) {
      updateSystemName(displayNameToSet);
    }
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
  const onDataTypeChange = ({ value }: Option) => setCurrentDataType(value as VisualDataType);

  const onAddOption = (value: string) => setOptionsList((prevOptionsList) => [...prevOptionsList, value]);
  const onDeleteOption = (value: string) => setOptionsList(optionsList.filter((option) => option !== value));

  const onShowPermissionsChange = () => {
    if (!variable?.dependsOn) {
      if (showPermissions) {
        setChangedPermissions(mapValues(changedPermissions, () => VariableAccessPermissionType.ReadWrite));
      }

      setShowPermissions(!showPermissions);
    }
  };

  const onAccessPermissionsChange = (
    permissionType: VariableAccessPermissionType,
    permissionGroupId: PermissionGroupId,
  ) => {
    setChangedPermissions({
      ...changedPermissions,
      [permissionGroupId]: permissionType,
    });
  };

  const areRequiredFieldsSet =
    displayName && currentDataType && (!showPermissions || arePermissionsSet(changedPermissions));
  const areAttributesChanged = [
    [name, displayName],
    [description, fetchedDescription],
    [currentDataType, dataType],
    [permissions, changedPermissions],
    [phoneNumberFormat, changedPhoneNumberFormat],
    [identificationNumberType, changedIdentificationNumberType],
    [identificationNumberDescription, changedIdentificationNumberDescription],
    [currency, changedCurrency],
    [dateFormat, changedDateFormat],
  ].some(([a, b]) => !isEqual(a, b));

  const areAttributesWithOptionsListChanged = areAttributesChanged || !isEqual(fetchedOptionsList, optionsList);

  const areExtraAttributesSet = () => {
    switch (currentDataType) {
      case 'List': {
        return Boolean(optionsList?.length);
      }
      case 'Monetary': {
        return Boolean(changedCurrency);
      }
      case 'PhoneNumber': {
        return Boolean(changedPhoneNumberFormat);
      }
      case 'IdentificationNumber': {
        return Boolean(changedIdentificationNumberType);
      }
      default: {
        return true;
      }
    }
  };

  const isSaveButtonEnabled =
    areRequiredFieldsSet &&
    (currentDataType === 'List' ? areAttributesWithOptionsListChanged : areAttributesChanged) &&
    areExtraAttributesSet();

  const renderConfirmCreateCoBorrowerVariablesMessage = () => {
    const listItems = new Array(additionalCoBorrowerToAdd).fill(null).map((key, index) => {
      return `${convertBorrowerDisplayTitleToCoBorrower(
        displayName,
        index,
      )} (system name: ”${convertBorrowerSystemNameToCoBorrower(systemName, index)}”)`;
    });

    return (
      <>
        <p>
          You are adding a variable that starts with the text “Borrower”. The following variables will also be created
          automatically in case your Loan Products or Decision Strategies include coborrowers.
        </p>
        <List className={styles.list} listItemClassName={styles.listItem} items={listItems} />
      </>
    );
  };

  const handleSaveButtonClick = () => {
    if (isBorrowerVariableSystemName(systemName) && !variable) {
      setDisplayConfirmCreatingCoBorrowerVariablesPopup(true);

      return;
    }

    onVariableSave();
  };

  const handleConfirmAddCoBorrowerVariablesClick = () => {
    setDisplayConfirmCreatingCoBorrowerVariablesPopup(false);

    onVariableSave();
  };

  const validateDisplayNameValue = (value: string) => {
    return (
      validateLettersNumbersOnly(value) &&
      !STARTS_WITH_NUMBER_REGEX.test(value) &&
      (!value.length || !!value.trim().length)
    );
  };

  return (
    <>
      <VariableTooltipWrapper variable={variable} fieldName="Display Name" ignoreDependsOn>
        <TextInput
          disabled={variable?.isStandard}
          labelTitle="Display Name"
          value={displayName}
          onChange={onDisplayNameChange}
          valueValidator={validateDisplayNameValue}
          topRightElement={topRightElement}
        />
      </VariableTooltipWrapper>
      <VariableTooltipWrapper tooltip="The System Name cannot be modified">
        <TextInput labelTitle="System Name" value={systemName} disabled />
      </VariableTooltipWrapper>
      <VariableTooltipWrapper variable={variable} fieldName="Data Type">
        <AutoCompletion
          disabled={variable?.isStandard || !!variable?.dependsOn}
          labelTitle="Data Type"
          onChange={onDataTypeChange}
          value={currentDataType}
          options={getDataTypeOptions(currentDataType, Boolean(variable?.id))}
        />
      </VariableTooltipWrapper>
      {currentDataType === 'List' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <OptionsInput
            disabled={!!variable?.dependsOn}
            id="listOptions"
            selectedOptions={optionsList}
            labelTitle="List of Options"
            onDeleteOption={onDeleteOption}
            onAddOption={onAddOption}
            onFormatValue={(value: string) => value.replace(FORBIDDEN_OPTION_SYMBOLS_REGEXP, '')}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'PhoneNumber' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <CountryCodeSelect
            disabled={!!variable?.dependsOn}
            labelTitle="Phone Number Format"
            onChange={onPhoneNumberFormatChange}
            value={changedPhoneNumberFormat || undefined}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'IdentificationNumber' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Identification Number Type"
            onChange={onIdentificationNumberTypeChange}
            value={changedIdentificationNumberType || undefined}
            options={IDENTIFICATION_NUMBER_TYPES}
            searchedOptionAttrs={IDENTIFICATION_NUMBER_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
          />
        </VariableTooltipWrapper>
      )}
      {changedIdentificationNumberType === 'Other' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <TextInput
            disabled={!!variable?.dependsOn}
            labelTitle="Identification Number Description"
            value={changedIdentificationNumberDescription || ''}
            onChange={onIdentificationNumberDescriptionChange}
            placeholder="Type up to 35 characters"
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'Monetary' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Currency Format"
            onChange={onCurrencyChange}
            value={changedCurrency || undefined}
            options={formattedCurrencies}
            searchedOptionAttrs={CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'Date' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Date Format"
            onChange={onDateFormatChange}
            value={changedDateFormat || undefined}
            options={DATE_FORMATS}
          />
        </VariableTooltipWrapper>
      )}
      <TextInput labelTitle="Description" value={description} onChange={onDescriptionChange} />

      <div className={styles.permissions}>
        <Label>Variable Permissions</Label>

        <div className={styles.permissionsSliderWrapper}>
          <SliderButton
            className={styles.permissionsSlider}
            active={showPermissions}
            disabled={!!variable?.dependsOn}
            onChange={onShowPermissionsChange}
            labelText="Set custom display options for Permission Groups"
            labelClassName={styles.permissionsSliderLabel}
          />
        </div>

        {showPermissions ? (
          <FormRow>
            {permissionGroups.map(({ id, name: permissionGroupName }) => {
              const value = changedPermissions[id] ?? VariableAccessPermissionType.ReadWrite;
              const onChange = ({ value: type }: Option) => {
                onAccessPermissionsChange(type as VariableAccessPermissionType, id);
              };

              return (
                <VariableTooltipWrapper key={`${id}_permissions`} variable={variable} ignoreStandard>
                  <AutoCompletion
                    disabled={!!variable?.dependsOn}
                    labelTitle={`${startCase(permissionGroupName)} Permissions`}
                    onChange={onChange}
                    value={value}
                    options={getAccessPermissionsOptions(currentDataType)}
                    required
                    className={styles.permissionsSelect}
                  />
                </VariableTooltipWrapper>
              );
            })}
          </FormRow>
        ) : null}
      </div>
      {cloneElement(children, { disabled: !isSaveButtonEnabled, onSave: handleSaveButtonClick })}
      {displayVariableNameConflictPopup && (
        <InformationPopup
          title="Variable Naming Conflict"
          description="For data integrity reasons within the DigiFi platform, you cannot add a variable that starts with the text “Coborrower”. "
          onClose={() => setDisplayVariableNameConflictPopup(false)}
          usePortal
        />
      )}
      {displayConfirmCreatingCoBorrowerVariablesPopup && (
        <ConfirmPopup
          usePortal
          title="Co-Borrower Variables"
          message={renderConfirmCreateCoBorrowerVariablesMessage()}
          confirmText="Confirm"
          declineText="No, Go Back"
          onPopupClose={() => setDisplayConfirmCreatingCoBorrowerVariablesPopup(false)}
          onConfirmClick={handleConfirmAddCoBorrowerVariablesClick}
        />
      )}
    </>
  );
};

export default VariableForm;
