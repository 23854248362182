import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { has } from 'lodash';
import AutoCompletion from 'components/AutoCompletion';
import { VariableValueMap } from 'api/Types';
import InputWithDataType from 'components/InputWithDataType';
import styles from 'components/RunDecisionForm/RunDecisionForm.module.scss';
import Button from 'components/Button';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { Option } from 'components/SelectInput/SelectInput';
import { Tag } from 'components/Tag';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import { DropdownStrategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import TextInput from 'components/TextInput';
import TransitionComponent from 'components/HeightTransitionComponent';
import { statusColors } from 'components/Decisions/PopUps/RunMultipleDecision';
import { Variable } from 'Variables/VariablesTypes';

interface RunDecisionFormProps {
  strategies: DropdownStrategy[];
  strategyInputs: Variable[] | null;
  onRunDecision: (inputs: VariableValueMap) => void;
  onStrategyChange: (strategyId: string) => void;
  currentStrategy: string | null;
  dataLoading: boolean;
  initialInputValues?: VariableValueMap;
  inputsLoading?: boolean;
  decisionRunInProgress?: boolean;
}

export interface InputValues {
  [inputName: string]: string;
}

const RunDecisionForm: FC<RunDecisionFormProps> = ({
  currentStrategy = '',
  strategies,
  strategyInputs,
  initialInputValues,
  onRunDecision,
  onStrategyChange,
  inputsLoading,
  decisionRunInProgress,
  dataLoading,
}) => {
  const [decisionName, setDecisionName] = useState('');
  const [inputs, setInputs] = useState<InputValues | null>(null);

  useEffect(() => {
    if (currentStrategy && strategyInputs) {
      const inputValuesToSet: InputValues = strategyInputs.reduce(
        (acc, { systemName }) => {
          const value = has(initialInputValues, systemName) ? initialInputValues![systemName] : '';
          acc[systemName] = value && value.toString();
          return acc;
        },
        {},
      );
      setInputs(inputValuesToSet);
    }
  }, [strategyInputs, currentStrategy]);

  const handleStrategyChange = ({ value }: Option) => onStrategyChange(value || '');
  const inputChangeHandler = (attr: string) => (value: string) => {
    setInputs({ ...inputs, [attr]: value });
  };

  const handleRunDecision = () => {
    if (!inputs) {
      return;
    }

    onRunDecision({
      ...inputs,
      decisionName,
    });
  };

  const renderInputs = () => {
    if (!inputs || !strategyInputs || !strategyInputs.length) {
      return null;
    }

    return (
      <div className={styles.inputsContainer}>
        <h4 className={styles.inputsTitle}>Decision Inputs</h4>
        {strategyInputs.map((input) => {
          const inputValue = inputs[input.systemName];
          const inputDisplayValue =
            typeof inputValue !== 'undefined' && inputValue !== null ? inputValue.toString() : '';

          return (
            <div className={styles.input} key={input.systemName}>
              <InputWithDataType
                value={inputDisplayValue}
                options={input.optionsList ? variableOptionsListToSelectInputOptions(input.optionsList) : null}
                labelTitle={input.displayName}
                onChange={inputChangeHandler(input.systemName)}
                disabledValidation
                {...getVisualDataTypeWithAttributes(input)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const getStrategyOptions = (): Option[] => {
    return strategies.map(({ id, name, status }: DropdownStrategy) => {
      const normalizedStatus = getNormalizedStatus(status);
      return {
        name,
        value: id,
        iconAfter: <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
      };
    });
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={styles.input}>
          <AutoCompletion
            required
            disabled={dataLoading}
            labelTitle="Strategy Name"
            onChange={handleStrategyChange}
            value={currentStrategy || ''}
            options={getStrategyOptions()}
            loading={dataLoading}
            showLoader
          />
          <TextInput
            labelTitle="Decision Name"
            value={decisionName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setDecisionName(e.target.value)}
          />
        </div>
        <TransitionComponent>{!inputsLoading && renderInputs()}</TransitionComponent>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          size="form"
          kind="primary"
          onClick={handleRunDecision}
          disabled={!currentStrategy || !strategyInputs || dataLoading}
          isLoading={decisionRunInProgress}
        >
          Run Decision
        </Button>
      </div>
    </>
  );
};

export default RunDecisionForm;
