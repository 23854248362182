import { WebhookEventSortingField, WebhookEventSortingType } from 'api/Webhooks/WebhookEventsApi';
import { createAction, createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import webhookEndpointEventsPagination, { WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { ItemsStateWithPagination } from 'pagination';
import { getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import { WebhookEndpointEventsTableActionType } from './WebhookEndpointEventsTableActionType';

export interface WebhookEndpointEventsTableState extends ItemsStateWithPagination<string> {
  sortingType: WebhookEventSortingType;
  searched: {
    [index: string]: ItemsStateWithPagination<string>;
  };
  search: string;
}

export const initialState: WebhookEndpointEventsTableState = {
  items: [],
  page: 1,
  itemsPerPage: WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    field: WebhookEventSortingField.CreatedAt,
    ascending: false,
  },
  searched: {},
  search: '',
};

export const changeSortingType = createAction<WebhookEventSortingType>(WebhookEndpointEventsTableActionType.ChangeSortingType);
export const resetState = createAction(WebhookEndpointEventsTableActionType.ResetState);

const webhookEndpointEventsTableReducer = createReducer(initialState, (builder) => {
  builder.addCase(getWebhookEvents.fulfilled, (state, action) => {
    if (action.meta.actionOrigin !== WebhookEventsActionOrigin.WebhookEndpointEventsTable) {
      return;
    }

    if (action.meta.search) {
      state.searched[action.meta.search] = {
        error: '',
        page: 1,
        itemsPerPage: WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT,
        items: action.payload.items.map((item) => item.id),
        itemsTotal: action.payload.total,
      }

      return;
    }

    state.error = '';
    state.page = 1;
    state.itemsPerPage = WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT;
    state.items = action.payload.items.map((item) => item.id);
    state.itemsTotal = action.payload.total;
  });

  builder.addCase(changeSortingType, (state, action) => {
    state.sortingType = action.payload;
  });
});


export default webhookEndpointEventsPagination.wrapReducer(
  withStateReset(
    webhookEndpointEventsTableReducer,
    WebhookEndpointEventsTableActionType.ResetState,
    () => ({ ...initialState }),
  ),
);
