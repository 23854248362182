import React, { FC, useState } from 'react';
import { FormatTooltipData, FormatXValueType, FormatYValueType } from 'components/Charts/LineChart/LineChart';
import ChartLayout from 'components/Charts/ChartLayout';
import LineChart from 'components/Charts/LineChart';
import styles from './ReportingDashboardLayout.module.scss';
import { Point } from 'components/Charts/components/Types';
import ChartLayoutSkeleton from 'components/Charts/ChartLayout/ChartLayoutSkeleton';
import { ReportingDashboardPeriod } from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import ReportingDashboardChartName from 'LoanOriginationSystemReporting/ReportingDashboardChartName';

export interface ChartSettings {
  name: ReportingDashboardChartName;
  groupName: string;
  tooltip: string;
  forceMaxY?: number;
  data?: Point[];
  total?: number;
  getTitle: (monetaryType?: string) => string;
  getSecondaryTitle: (value: number, monetaryType?: string) => string;
  formatXValue: FormatXValueType;
  formatYValue: FormatYValueType;
  formatTooltipData: (period: ReportingDashboardPeriod, filterEndDate: Date, currency?: string) => FormatTooltipData;
}

interface DashboardLayoutProps {
  layout: [ChartSettings, ChartSettings][];
  currencySymbol?: string;
  period: ReportingDashboardPeriod;
  isLoading: boolean;
  filterStartDate: Date;
  filterEndDate: Date;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({
  layout,
  currencySymbol,
  period,
  isLoading,
  filterStartDate,
  filterEndDate,
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleOnResize = (width: number, height: number) => {
    if (width !== size.width || height !== size.height) {
      setSize({ width, height });
    }
  };

  return (
    <div>
      {layout.map((charts, i) => (
        <div className={styles.chartsContainer} key={charts[0].groupName}>
          <h3 className={styles.groupTitle}>{charts[0].groupName}</h3>
          <div className={styles.chartsRow}>
            {charts.map((chartSettings, j) => (
              <>
                {(isLoading || !chartSettings.data) && (
                  <ChartLayoutSkeleton
                    key={chartSettings.name}
                    title={chartSettings.getTitle(currencySymbol)}
                    tooltip={chartSettings.tooltip}
                    className={styles.chart}
                  />
                )}
                {!isLoading && chartSettings.data && (
                  <ChartLayout
                    key={chartSettings.name}
                    onResize={i === 0 && j === 0 ? handleOnResize : undefined}
                    className={styles.chart}
                    title={chartSettings.getTitle(currencySymbol)}
                    secondaryTitle={chartSettings.getSecondaryTitle(chartSettings.total!, currencySymbol)}
                    tooltip={chartSettings.tooltip}
                  >
                    <LineChart
                      width={size.width}
                      height={size.height}
                      formatXValue={chartSettings.formatXValue}
                      formatYValue={chartSettings.formatYValue}
                      formatTooltipData={chartSettings.formatTooltipData(period, filterEndDate, currencySymbol)}
                      points={chartSettings.data}
                      forceMaxY={chartSettings.forceMaxY}
                      forceStartXAxisValue={Number(filterStartDate)}
                      forceEndXAxisValue={Number(filterEndDate)}
                    />
                  </ChartLayout>
                )}
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardLayout;
