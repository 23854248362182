import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from 'Webhooks/WebhookEventsTable/Pagination';
import { changeFilters, clearFilters } from './WebhookEventsTableFiltersStore';

const isChangeWebhookEventsFiltersAction = (action: AnyAction) => {
  return action.type === changeFilters.type || action.type === clearFilters.type;
};

const changeWebhookEventsFiltersMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangeWebhookEventsFiltersAction(action)) {
    const { webhookEventsTable } = getState();
    const { filters } = webhookEventsTable;

    dispatch(
      getWebhookEvents({
        filters: {
          statuses: filters.statuses,
          search: filters.search,
          eventTypes: filters.eventTypes,
          dueCreatedDateFrom: filters.dueCreatedDateRange.from,
          dueCreatedDateTo: filters.dueCreatedDateRange.to,
          count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
          offset: 0,
        },
        sortingType: webhookEventsTable.sortingType,
        actionOrigin: WebhookEventsActionOrigin.WebhookEventsTable,
      }) as unknown as AnyAction,
    );
  }

  return result;
}) as Middleware;

export default changeWebhookEventsFiltersMiddleware;
