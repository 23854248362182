import { Middleware } from 'redux';
import { ReduxState } from 'types/redux';
import { DecisionStrategyUpdatesActions } from './ActionTypes';
import {
  DecisionStrategyUpdates,
  GetDecisionStrategyUpdatesAction,
  getDecisionStrategyUpdatesError,
  getDecisionStrategyUpdatesSuccess,
} from './ActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { STRATEGY_UPDATES_PER_PAGE_DEFAULT } from 'pages/Strategy/Pagination';
import { DecisionEngineApi, OrderBy } from 'api/Types';

const GetDecisionStrategyUpdates: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<DecisionStrategyUpdates, GetDecisionStrategyUpdatesAction, ReduxState>(
    DecisionStrategyUpdatesActions.GetUpdatesData,
    getDecisionStrategyUpdatesSuccess,
    getDecisionStrategyUpdatesError,
    async ({ payload: strategyId }, { decisionStrategyUpdates }) => {
      const { sortingType } = decisionStrategyUpdates;

      const strategyUpdates = await api.getDecisionStrategyUpdates(
        {
          page: 1,
          perPage: STRATEGY_UPDATES_PER_PAGE_DEFAULT,
          sortBy: sortingType.field,
          orderBy: sortingType.ascending ? OrderBy.Asc : OrderBy.Desc,
        },
        strategyId,
      );

      return {
        strategyId,
        ...strategyUpdates,
      };
    },
  );

export default GetDecisionStrategyUpdates;
