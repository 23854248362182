import { createContext, useContext } from 'react';
import { DecisionEngineApi, DownloadAPI } from 'api/Types';
import { LoanOriginationSystemProductsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { VariablesApi } from 'api/Core/VariablesApi';
import { ESignTemplatesApi } from 'api/Core/ESignTemplatesApi';
import { LoanOriginationSystemLabelsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { OrganizationApi } from 'api/Core/OrganizationApi';
import DecisionResultApi from 'api/DecisionEngine/DecisionResultApi';
import { EventTypeOptionsApi } from 'api/Core/EventTypeOptionsApi';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemIntermediariesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';
import { ProductConfigurationDocumentFoldersApi } from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';
import { ApplicationTabsApi } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { ProductCalculationsApi } from 'api/LoanOriginationSystem/ProductCalculationsApi';

export interface ApiProviderValue {
  downloadApi: DownloadAPI;
  decisionEngineApi: DecisionEngineApi;
  variablesApi: VariablesApi;
  productsApi: LoanOriginationSystemProductsApi;
  applicationDocumentsApi: ApplicationDocumentsApi;
  decisionResultApi: DecisionResultApi;
  emailsApi: LoanOriginationSystemEmailsApi;
  eSignTemplatesApi: ESignTemplatesApi;
  labelsApi: LoanOriginationSystemLabelsApi;
  organizationApi: OrganizationApi;
  eventTypeOptionsApi: EventTypeOptionsApi;
  borrowersApi: LoanOriginationSystemBorrowersApi;
  intermediariesApi: LoanOriginationSystemIntermediariesApi;
  simplifiedApplicationsApi: SimplifiedApplicationsApi;
  documentFoldersApi: ProductConfigurationDocumentFoldersApi;
  applicationTabsApi: ApplicationTabsApi;
  productCalculationsApi: ProductCalculationsApi;
}

export type Services =
  | DownloadAPI
  | DecisionEngineApi
  | VariablesApi
  | LoanOriginationSystemProductsApi
  | ApplicationDocumentsApi
  | DecisionResultApi
  | LoanOriginationSystemEmailsApi
  | ESignTemplatesApi
  | LoanOriginationSystemLabelsApi
  | OrganizationApi
  | EventTypeOptionsApi
  | LoanOriginationSystemBorrowersApi
  | LoanOriginationSystemIntermediariesApi
  | SimplifiedApplicationsApi
  | ProductConfigurationDocumentFoldersApi
  | ApplicationTabsApi
  | ProductCalculationsApi;

const ApiContext = createContext<ApiProviderValue>({} as ApiProviderValue);

const getService = <ServiceType extends Services>(serviceName: keyof ApiProviderValue) => () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const services = useContext(ApiContext);

  return services[serviceName] as ServiceType;
};

export const useDownloadApi = getService<DownloadAPI>('downloadApi');

export const useDecisionEngineApi = getService<DecisionEngineApi>('decisionEngineApi');

export const useVariablesApi = getService<VariablesApi>('variablesApi');

export const useProductsApi = getService<LoanOriginationSystemProductsApi>('productsApi');

export const useApplicationDocumentsApi = getService<ApplicationDocumentsApi>('applicationDocumentsApi');

export const useEmailsApi = getService<LoanOriginationSystemEmailsApi>('emailsApi');

export const useESignTemplatesApi = getService<ESignTemplatesApi>('eSignTemplatesApi');

export const useLabelsApi = getService<LoanOriginationSystemLabelsApi>('labelsApi');

export const useOrganizationApi = getService<OrganizationApi>('organizationApi');

export const useDecisionResultApi = getService<DecisionResultApi>('decisionResultApi');

export const useEventTypeOptionsApi = getService<EventTypeOptionsApi>('eventTypeOptionsApi');

export const useBorrowersApi = getService<LoanOriginationSystemBorrowersApi>('borrowersApi');

export const useSimplifiedApplicationsApi = getService<SimplifiedApplicationsApi>('simplifiedApplicationsApi');

export const useDocumentFoldersApi = getService<ProductConfigurationDocumentFoldersApi>('documentFoldersApi');

export const useApplicationTabsApi = getService<ApplicationTabsApi>('applicationTabsApi');

export const useIntermediariesApi = getService<LoanOriginationSystemIntermediariesApi>('intermediariesApi');

export const useProductCalculationsApi = getService<ProductCalculationsApi>('productCalculationsApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
