import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DocuSignLogo, EmptyDocumentsImage, DropdownImage } from 'static/images';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  ApplicationDocument,
  ApplicationDocumentFile,
  ApplicationDocumentsSortingField,
} from 'api/LoanOriginationSystem/DocumentsApi';
import {
  downloadApplicationDocument,
  downloadArchiveOfApplicationDocuments,
  getApplicationDocuments,
  setSearchValue,
  sortApplicationDocuments,
  setParentFolder,
  openApplicationDocumentPopup,
  setDocumentToUpdate,
  updateApplicationDocument,
  resetApplicationDocumentsState,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { ApplicationDocumentPopUpType } from 'LoanOriginationSystemApplicationPage/Documents/Types';
import {
  getDocumentsByApplicationId,
  getNestedDocuments,
  getNestedDocumentsPath,
  getSortingType,
  getParentFolderId,
} from 'LoanOriginationSystemApplicationPage/Documents/Selectors';
import { isFile, isFolder } from 'LoanOriginationSystemApplicationPage/Documents/utils';
import TabHeader from 'components/LoanOriginationSystem/ApplicationTabs/TabHeader';
import TabNoItems from 'components/LoanOriginationSystem/ApplicationTabs/TabNoItems';
import SearchInput from 'components/SearchInput';
import SearchNotFound from 'components/SearchNotFound';
import Button from 'components/Button';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ActionPopUp from 'components/ActionPopUp';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ApplicationDocumentsTable from './ApplicationDocumentsTable';
import styles from './ApplicationDocuments.module.scss';
import { showSnack } from 'Snackbar/ActionCreator';
import { SnackbarComponentTypes } from 'Snackbar/Types';
import { nanoid } from 'nanoid';
import ButtonWithImage from 'components/ButtonWithImage';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { useHistory } from 'react-router-dom';
import { FileType } from 'pages/LoanOriginationSystem/FilePreview';
import Separator from 'components/Separator';
import DocumentFoldersBreadcrumbs from 'components/DocumentFoldersBreadcrumbs';
import LoadingMask from 'components/LoadingMask';

export interface ApplicationDocumentsProps {
  applicationId: string;
  applicationBorrowerFirstName: VariableValue;
  applicationBorrowerLastName: VariableValue;
  product: Product | null;
}

const FILE_PREVIEW_PATH = `/los/filepreview/${FileType.ApplicationDocument}`;
const DOCUMENTS_PLACEHOLDER_DEFAULT_LENGTH = 20;

const ApplicationDocuments = ({
  applicationId,
  product,
  applicationBorrowerFirstName,
  applicationBorrowerLastName,
}: ApplicationDocumentsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addDocumentContextualPopupOpen, setAddDocumentContextualPopupOpen] = useState(false);
  const [isDocumentCombineInProgress, setIsDocumentCombineInProgress] = useState(false);
  const shouldPreserveParentFolderIdRef = useRef(false);
  const addDocumentContainerRef = useRef<HTMLInputElement | null>(null);

  const actionsTitle = 'Add New';
  const documentsPlaceholder: null[] = new Array(DOCUMENTS_PLACEHOLDER_DEFAULT_LENGTH).fill(null);

  const { search, isGetDocumentsPending } = useSelector((state: ReduxState) => state.loanOriginationSystemDocuments);

  const { documents, nestedDocuments, nestedDocumentsPath, sortingType, parentFolderId } = useSelector(
    (state: ReduxState) => ({
      documents: getDocumentsByApplicationId(state, applicationId),
      nestedDocuments: getNestedDocuments(state, applicationId),
      nestedDocumentsPath: getNestedDocumentsPath(state),
      sortingType: getSortingType(state),
      parentFolderId: getParentFolderId(state),
    }),
  );

  useEffect(
    () => () => {
      dispatch(resetApplicationDocumentsState(shouldPreserveParentFolderIdRef.current));
    },
    [],
  );

  useEffect(() => {
    dispatch(getApplicationDocuments(applicationId));
  }, []);

  const handleSearchClear = () => {
    dispatch(setSearchValue(''));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(event.target.value));
  };

  const handleApplicationDocumentDownload = async (document: ApplicationDocumentFile) => {
    const documentDownloadingId = nanoid();
    batch(() => {
      dispatch(
        showSnack('Downloading Document', {
          componentType: SnackbarComponentTypes.ApplicationDownloadDocumentComponent,
          props: { document, documentDownloadingId },
        }),
      );
      dispatch(downloadApplicationDocument({ documentId: document.id, documentDownloadingId }));
    });
  };

  const handleDownloadArchiveOfApplicationDocuments = async () => {
    const documentDownloadingId = nanoid();
    batch(() => {
      dispatch(
        showSnack('Downloading Document', {
          componentType: SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
          props: {
            documentName: `Application Documents - ${applicationBorrowerFirstName} ${applicationBorrowerLastName}`,
            documentDownloadingId,
          },
        }),
      );
      dispatch(downloadArchiveOfApplicationDocuments({ applicationId, documentDownloadingId }));
    });
  };

  const handleOpenDocumentPreview = (document: ApplicationDocumentFile) => {
    shouldPreserveParentFolderIdRef.current = true;
    history.push(`${FILE_PREVIEW_PATH}/${document.extension}/${document.id}/${document.name}`);
  };

  const handleApplicationDocumentClick = (document: ApplicationDocument) =>
    isFile(document) ? handleOpenDocumentPreview(document) : dispatch(setParentFolder(document.id));

  const handleOpenRenameApplicationDocumentPopup = (document: ApplicationDocument) => {
    batch(() => {
      dispatch(
        openApplicationDocumentPopup(
          isFile(document) ? ApplicationDocumentPopUpType.RenameDocument : ApplicationDocumentPopUpType.RenameFolder,
        ),
      );
      dispatch(setDocumentToUpdate(document.id));
    });
  };

  const handleSort = (field: ApplicationDocumentsSortingField, ascending: boolean) => {
    dispatch(sortApplicationDocuments({ field, ascending }));
  };

  const handleOpenAddDocumentContextualPopup = () => {
    setAddDocumentContextualPopupOpen(true);
  };

  const handleCloseAddDocumentContextualPopup = (handler?: () => void) => {
    setAddDocumentContextualPopupOpen(false);

    handler?.();
  };

  const handleApplicationDocumentDelete = (document: ApplicationDocument) => {
    batch(() => {
      dispatch(
        openApplicationDocumentPopup(
          isFile(document) ? ApplicationDocumentPopUpType.DeleteDocument : ApplicationDocumentPopUpType.DeleteFolder,
        ),
      );
      dispatch(setDocumentToUpdate(document.id));
    });
  };

  const handleDocuSignButtonClick = () => {
    dispatch(openApplicationDocumentPopup(ApplicationDocumentPopUpType.SendESignature));
  };

  const handleApplicationDocumentsCombine = async (documentId: string, parentId: string) => {
    const parentDocument = documents?.find((document) => document.id === parentId);
    if (parentDocument && isFolder(parentDocument)) {
      setIsDocumentCombineInProgress(true);
      await dispatch(updateApplicationDocument({ documentId, document: { parentId } }));
      setIsDocumentCombineInProgress(false);
    }
  };

  const actions = [
    {
      title: 'Upload Document',
      handler: () => dispatch(openApplicationDocumentPopup(ApplicationDocumentPopUpType.UploadDocument)),
    },
    {
      title: 'Create Folder',
      handler: () => dispatch(openApplicationDocumentPopup(ApplicationDocumentPopUpType.CreateFolder)),
      separatorRequired: true,
    },
    {
      title: 'Request Customer Upload',
      handler: () => dispatch(openApplicationDocumentPopup(ApplicationDocumentPopUpType.RequestCustomerUpload)),
    },
  ];

  const renderNoItems = () => {
    if (search) {
      return <SearchNotFound searchValue={search} />;
    }

    return <TabNoItems title="This folder is empty" icon={<EmptyDocumentsImage />} />;
  };

  const renderTable = () => {
    if (!isGetDocumentsPending && documents?.length === 0) {
      return renderNoItems();
    }

    return (
      <>
        <ApplicationDocumentsTable
          source={isGetDocumentsPending ? documentsPlaceholder : (nestedDocuments ?? [])}
          sortingType={sortingType}
          searchInputValue={search}
          onSort={handleSort}
          onApplicationDocumentDownload={handleApplicationDocumentDownload}
          onApplicationDocumentClick={handleApplicationDocumentClick}
          onApplicationDocumentDelete={handleApplicationDocumentDelete}
          onApplicationDocumentRename={handleOpenRenameApplicationDocumentPopup}
          onApplicationDocumentsCombine={handleApplicationDocumentsCombine}
        />
        {!parentFolderId && (
          <ButtonWithImage
            title="Download All Documents"
            onClick={handleDownloadArchiveOfApplicationDocuments}
            kind="download"
            className={styles.downloadAttachments}
          />
        )}
      </>
    );
  };

  const renderDocuSignButton = () => {
    if (!product || !product.settings.docuSignEnabled) {
      return null;
    }

    return (
      <Button onClick={handleDocuSignButtonClick} className={styles.docuSignButton} kind="secondary" size="default">
        <DocuSignLogo />
      </Button>
    );
  };

  const renderActions = () => (
    <>
      {renderDocuSignButton()}
      <div ref={addDocumentContainerRef}>
        <Button
          className={styles.addDocumentButton}
          kind="primary"
          size="default"
          onClick={handleOpenAddDocumentContextualPopup}
        >
          {actionsTitle}
          <DropdownImage className={styles.dropdownImage} />
        </Button>
        <ContextualPopUp
          open={addDocumentContextualPopupOpen}
          anchorEl={addDocumentContainerRef.current}
          onClose={() => handleCloseAddDocumentContextualPopup()}
        >
          <ActionPopUp
            onClose={() => handleCloseAddDocumentContextualPopup()}
            title={actionsTitle}
            className={styles.actionsPopup}
          >
            {actions.map(({ title, handler, separatorRequired }) => (
              <>
                <ActionPopUpItem key={title} onClick={() => handleCloseAddDocumentContextualPopup(handler)}>
                  {title}
                </ActionPopUpItem>
                {separatorRequired && <Separator />}
              </>
            ))}
          </ActionPopUp>
        </ContextualPopUp>
      </div>
    </>
  );

  const renderSearchInput = () =>
    isGetDocumentsPending || documents?.length !== 0 || search ? (
      <SearchInput placeholder="Search" value={search} onChange={handleSearchChange} onClear={handleSearchClear} />
    ) : null;

  return (
    <>
      <TabHeader
        title={
          <DocumentFoldersBreadcrumbs
            breadcrumbClassName={styles.tabHeaderBreadcrumbs}
            rootLinkName="Documents"
            links={nestedDocumentsPath}
            onLinkClick={(id: string | null) => dispatch(setParentFolder(id))}
          />
        }
        actions={renderActions()}
        className={styles.tabHeader}
      />
      {renderSearchInput()}
      {renderTable()}
      {isDocumentCombineInProgress && <LoadingMask background="light" />}
    </>
  );
};

export default ApplicationDocuments;
