import { DataFilter, DataViewSortingType, TableViewData } from 'api/Types';
import { SendGridTemplate } from 'SendGrid/Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';

export enum TemplatesFilterOptions {
  All = 'All',
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface SendGridTemplateDataFilter extends DataFilter {
  active: TemplatesFilterOptions | null;
  members: UserInfo[];
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

export enum SendGridTemplatesSortingField {
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Status = 'active',
}

export type SendGridTemplatesSortingType = DataViewSortingType<SendGridTemplatesSortingField>;

class SendGridTemplateApi extends LoanOriginationSystemApi<SendGridTemplate> {
  protected resourceName = 'email-templates';

  getSendGridTemplates(filters: SendGridTemplateDataFilter, sortingType: SendGridTemplatesSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);
    filters.members.forEach((member) => params.append('teamMemberIds', member.id));

    if (filters.dueCreatedDateRange.from) {
      params.append('dueCreatedDateFrom', formatDate(filters.dueCreatedDateRange.from, DateTimeFormat.ISOString));
    }

    if (filters.dueCreatedDateRange.to) {
      params.append('dueCreatedDateTo', formatDate(filters.dueCreatedDateRange.to, DateTimeFormat.ISOString));
    }

    if (filters.dueUpdatedDateRange.from) {
      params.append('dueUpdatedDateFrom', formatDate(filters.dueUpdatedDateRange.from, DateTimeFormat.ISOString));
    }

    if (filters.dueUpdatedDateRange.to) {
      params.append('dueUpdatedDateTo', formatDate(filters.dueUpdatedDateRange.to, DateTimeFormat.ISOString));
    }

    if (filters.active !== null) {
      params.set('active', filters.active === TemplatesFilterOptions.Active ? 'true' : 'false');
    }

    return this.getResources<TableViewData<SendGridTemplate>>(params);
  }

  getById(id: string) {
    return this.getResourceById<SendGridTemplate>(id);
  }

  getActiveSendGridTemplates() {
    const queryParams = new URLSearchParams();
    queryParams.set('active', 'true');

    return this.getResources<TableViewData<SendGridTemplate>>(queryParams);
  }

  importBatch(emailTemplates: Array<{ name: string; templateId: string }>): Promise<SendGridTemplate[]> {
    return this.fetch<SendGridTemplate[]>(`/${this.resourceName}/import-batch`, 'POST', { emailTemplates });
  }

  updateTemplate(id: string, templateUpdate: Partial<SendGridTemplate>): Promise<SendGridTemplate> {
    return this.fetch<SendGridTemplate>(`/${this.resourceName}/${id}`, 'PATCH', templateUpdate);
  }

  delete(templateId: string): Promise<SendGridTemplate> {
    return this.deleteResource(templateId);
  }
}

export default SendGridTemplateApi;
