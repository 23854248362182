import React, { FC } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import StrategyCreateNewVersion from 'components/StrategyCreateNewVersionPopUp';

interface CreateNewStrategyVersionProps {
  closePopUp: () => void;
  title: string;
  onCreateNewStrategyVersion: () => Promise<void>;
}

const CreateNewStrategyVersion: FC<CreateNewStrategyVersionProps> = ({
  closePopUp,
  title,
  onCreateNewStrategyVersion,
}) => {
  const handleCreateNewStrategy = () => onCreateNewStrategyVersion();

  return (
    <PopUp title={title} closePopUp={closePopUp}>
      <PopUpContent hasTopMargin>
        <StrategyCreateNewVersion onCreateNewStrategyVersionRequest={handleCreateNewStrategy} onClose={closePopUp} />
      </PopUpContent>
    </PopUp>
  );
};

export default CreateNewStrategyVersion;
