import { Middleware } from 'redux';

import { ReduxState } from 'types/redux';
import { ACTION_TYPE_EDIT_VARIABLE_REQUEST } from './ActionTypes';
import {
  editVariableFailure,
  EditVariableRequestAction,
  editVariableSuccess,
  EditVariableSuccessParams,
} from './VariablesActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { SavedVariableApiType } from './VariablesTypes';
import { VariablesApi } from 'api/Core/VariablesApi';

const EditVariableMiddleware: (api: VariablesApi) => Middleware = (api) =>
  Fetcher<EditVariableSuccessParams, EditVariableRequestAction, ReduxState>(
    ACTION_TYPE_EDIT_VARIABLE_REQUEST,
    editVariableSuccess,
    editVariableFailure,
    async ({ payload }, state) => {
      const variable = await api.updateVariable(payload as SavedVariableApiType);

      return {
        variable,
        permissionGroupId: state.sessionInfo.user?.permissionGroupId,
      };
    },
  );

export default EditVariableMiddleware;
