import React, { FC } from 'react';
import clsx from 'clsx';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import ButtonToggleGroup, { ButtonToggleGroupProps } from 'components/ButtonToggleGroup/ButtonToggleGroup';
import styles from './BorrowerTypeToggleGroup.module.scss';

export interface BorrowerTypeToggleGroupProps extends Omit<
  ButtonToggleGroupProps, 'options' | 'onSelect' | 'selectedValue'
> {
  className?: string;
  buttonClassName?: string;
  selectedBorrowerType: BorrowerType | null;
  onChange: (type: BorrowerType) => void;
  availableBorrowerTypes: BorrowerType[];
}

const BorrowerTypeToggleGroup: FC<BorrowerTypeToggleGroupProps> = ({
  className,
  selectedBorrowerType,
  onChange,
  buttonClassName,
  availableBorrowerTypes,
  ...restProps
}) => (
  <ButtonToggleGroup
    {...restProps}
    className={clsx(styles.buttonToggleGroup, className)}
    buttonClassName={clsx(styles.buttonFromToggleGroup, buttonClassName)}
    options={Object.values(BorrowerType)}
    selectedValue={selectedBorrowerType || ''}
    onSelect={(type) => onChange(type as BorrowerType)}
    isOptionDisabled={(type) => !availableBorrowerTypes.includes(type as BorrowerType)}
  />
);

export default BorrowerTypeToggleGroup;
