import { AnyAction } from 'redux';
import { FormLayoutData } from 'api/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';

export enum UpdateBorrowerSuccessActionOrigin {
  BorrowerDetails = 'BorrowerDetails',
  CreateApplication = 'CreateApplication',
}

export interface UpdateBorrowerAction extends AnyAction {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrower;
  payload: {
    id: string;
    data: FormLayoutData;
  };
}

export interface UpdateBorrowerSuccessAction extends ActionWithOrigin<UpdateBorrowerSuccessActionOrigin> {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess;
  payload: Borrower;
}

export interface UpdateBorrowerApplicationsAction extends AnyAction {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplications;
  payload: {
    borrowerId: string;
    data: FormLayoutData;
  };
}

export interface UpdateBorrowerApplicationsSuccessAction extends AnyAction {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplicationsSuccess;
}

export const updateBorrower = (id: string, borrowerData: FormLayoutData): UpdateBorrowerAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrower,
  payload: {
    id,
    data: borrowerData,
  },
});

export const updateBorrowerSuccess = (newBorrower: Borrower): UpdateBorrowerSuccessAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess,
  payload: newBorrower,
});

export const updateBorrowerApplications = (borrowerId: string, data: FormLayoutData): UpdateBorrowerApplicationsAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplications,
  payload: {
    borrowerId,
    data,
  },
});

export const updateBorrowerApplicationsSuccess = (): UpdateBorrowerApplicationsSuccessAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplicationsSuccess,
});

export const updateBorrowerFailure = FailedActionCreator(
  LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerFailure,
);

export const updateBorrowerApplicationsFailure = FailedActionCreator(
  LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplicationsFailure,
);
