import React, { FC } from 'react';
import clsx from 'clsx';
import { ProductConfigurationDocumentFolder } from 'api/Types';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import RowActions from 'components/RowActions';
import { FileSystemFolderIcon } from 'static/images';
import styles from './DocumentFoldersTableRow.module.scss';

const FULL_WIDTH = 100;

interface DocumentFolderTableRowProps {
  folder: ProductConfigurationDocumentFolder;
  onClick: (id: string) => void;
  onRenameFolderClick: (id: string) => void;
  onDeleteFolderClick: (id: string) => void;
  className: string;
  disableHover?: boolean;
}

const DocumentFolderTableRow: FC<DocumentFolderTableRowProps> = ({
  folder,
  onClick,
  onRenameFolderClick,
  onDeleteFolderClick,
  className,
  disableHover,
}) => (
  <TableRow
    useActions
    disableHover={disableHover}
    onClick={() => onClick(folder.id)}
    className={clsx(styles.foldersTableRow, className)}
  >
    <TableBodyCell width={FULL_WIDTH} className={styles.foldersTableBodyCell}>
      <FileSystemFolderIcon />
      <p className={styles.foldersTableBodyCellName}>{folder.name}</p>
    </TableBodyCell>
    <RowActions
      actions={[
        {
          title: 'Rename Folder',
          handler: () => onRenameFolderClick(folder.id),
          separatorRequired: true,
        },
        {
          title: 'Delete Folder',
          handler: () => onDeleteFolderClick(folder.id),
          danger: true,
        },
      ]}
      id={folder.id}
    />
  </TableRow>
);

export default DocumentFolderTableRow;
