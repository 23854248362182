import React from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import CardTableView from 'components/LoanOriginationSystem/CardsForm/CardTableView';
import ButtonWithImage from 'components/ButtonWithImage';
import { CloseImage } from 'static/images';
import DetailsCardSkeleton from './DetailsCardSkeleton';
import styles from './DetailsCard.module.scss';

export interface DetailsCardProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>
> {
  title: string;
  data: FormLayoutData;
  cards: CardType[] | null;
  onEditClick?: () => void;
  onCloseButtonClick?: () => void;
  editButtonName?: string;
  titleIcons?: React.ReactNode;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  hideCloseButton?: boolean;
  displayFieldsAttributes?: Record<string, boolean>;
  displaySkeleton?: boolean;
}

const DetailsCard = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>
>({
  title,
  cards,
  data,
  formatVariableConfigurationDisplayTitle,
  titleIcons,
  editButtonName,
  onEditClick,
  hideCloseButton,
  onCloseButtonClick,
  displayFieldsAttributes,
  displaySkeleton,
}: DetailsCardProps<VariableConfigurationType, CardType>) => {
  const layout = useFormLayout(cards);

  const renderCard = (card: CardType) => {
    return (
      <div key={card.id}>
        <h6 className={styles.cardName}>{card.name}</h6>
        <CardTableView
          className={styles.cardTableView}
          fieldClassName={styles.cardTableViewField}
          fieldValueClassName={styles.cardTableFieldValue}
          key={card.id}
          fields={card.fields}
          data={data}
          formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
          displayFieldsAttributes={displayFieldsAttributes}
        />
      </div>
    );
  };

  const renderRow = (cardsInRow: CardType[], index: number) => {
    return (
      <div className={styles.row} key={index}>
        {cardsInRow.map(renderCard)}
      </div>
    );
  };

  if (!layout || displaySkeleton) {
    return <DetailsCardSkeleton />;
  }

  return (
    <div className={styles.container}>
      {!hideCloseButton && <CloseImage onClick={onCloseButtonClick} className={styles.closeImage} />}
      <div className={styles.headerContainer}>
        <div className={styles.title}>
          {title}
        </div>
        {titleIcons && <div className={styles.titleIcons}>
          {titleIcons}
        </div>}
        <ButtonWithImage className={styles.editButton} kind="edit" onClick={onEditClick} title={editButtonName || 'Edit'} />
      </div>
      <div className={styles.rowsContainer}>
        {layout.map(renderRow)}
      </div>
    </div>
  );
};

export default DetailsCard;
