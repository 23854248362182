import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortEmailsAction } from './ActionCreator';
import { ChangePopupFiltersAction, ResetAllEmailsFilters } from './Filters/ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './Filters/ActionTypes';
import { LoanOriginationSystemEmailsFiltersState } from './Filters/Types';
import { EmailsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import isPathMatches from 'utils/isPathMatches';

type EmailsFiltersToSave = Pick<LoanOriginationSystemEmailsFiltersState, 'createdAtRange' | 'selectedBorrower'>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters } = state.loanOriginationSystemEmails;

  return pick(filters, ['createdAtRange', 'selectedBorrower']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemEmails;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<ChangePopupFiltersAction>(LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllEmailsFilters>(LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters),
  isActionOfType<SortEmailsAction>(LoanOriginationSystemEmailsActionTypes.SortEmails),
];

const isEmailsPage = isPathMatches('/los/emails');
const isApplicationEmailsPage = isPathMatches('/los/applications/:id');

const emailsFilterAndSortingSaver = FilterAndSortingSaver<EmailsFiltersToSave, EmailsSortingType>({
  key: [
    ['emails', isEmailsPage],
    ['applicationEmails', isApplicationEmailsPage],
  ],
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemEmailsActionTypes.ResetState,
  filtersSwitchActionType: LoanOriginationSystemEmailsActionTypes.SavedFiltersSwitch,
});

export default emailsFilterAndSortingSaver;
