import React, { FC } from 'react';
import BaseCodeEditor, { BaseCodeEditorProps } from './BaseCodeEditor/BaseCodeEditor';
import JexlCodeEditor from './JexlCodeEditor';

export type CodeEditorMode = 'jexl' | 'javascript' | 'json';

export interface CodeEditorProps extends Omit<BaseCodeEditorProps, 'syntaxValidationWorker'> {
  mode: CodeEditorMode;
}

const CodeEditor: FC<CodeEditorProps> = ({ mode, ...codeEditorProps }) => {
  if (mode === 'jexl') {
    return <JexlCodeEditor {...codeEditorProps} singleLineEditor />;
  }

  return <BaseCodeEditor {...codeEditorProps} />;
};

export default CodeEditor;
