import React from 'react';
import { Route, Switch } from 'react-router';

import Home from './Home';
import Profile from './Profile';
import CompanyInformationUsers from 'pages/CompanyInformationUsers';
import DigifiApi from 'pages/DigifiApi';
import DocuSign from 'pages/DocuSign';
import Variables from 'pages/Variables';
import StrategyPage from 'pages/Strategy';
import StrategyGroups from 'pages/StrategyGroups';
import LoanOriginationSystem from './LoanOriginationSystem';
import ApiActivationPage from 'pages/ApiActivation';
import ProductAccessCheck from 'components/RouteWithPermissions/ProductAccessCheck';
import ProductSectionAccessCheck from 'components/RouteWithPermissions/ProductSectionAccessCheck';
import { DigifiProduct, ProductSection } from 'components/RouteWithPermissions/Types';
import SendGridPage from 'pages/SendGrid';
import SecureFileUploadPage from 'pages/SecureFileUpload';
import Billing from 'pages/Billing';
import PermissionGroups from 'pages/PermissionGroups';
import Auth from './Auth';
import { AppRoutes } from './RouteService';
import ConfirmEmail from 'pages/ConfirmEmail';
import PageName from 'constants/PageName';
import Decisions from 'pages/Decisions';
import NotFoundPage from 'pages/NotFound';
import DecisionResultOverviewPage from 'pages/DecisionResultOverview';
import CompanyGeneralSettingsPage from 'pages/CompanyGeneralSettingsPage';
import Webhooks from 'pages/Webhooks';

export default () => (
  <Switch>
    <Route path="/los">
      <ProductAccessCheck product={DigifiProduct.LoanOriginationSystem}>
        <LoanOriginationSystem />
      </ProductAccessCheck>
    </Route>
    <Route path={AppRoutes.ConfirmEmail}>
      <ConfirmEmail />
    </Route>
    <Route path="/auth">
      <Auth />
    </Route>
    <Route path="/home">
      <Home />
    </Route>
    <Route path="/dashboard">
      <Home />
    </Route>
    <Route path="/account/profile">
      <Profile />
    </Route>
    <Route path="/company-settings/general/:tab?">
      <ProductSectionAccessCheck pageName={PageName.General} productSection={ProductSection.CompanySettingsGeneral}>
        <CompanyGeneralSettingsPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/users">
      <ProductSectionAccessCheck pageName={PageName.PlatformUsers} productSection={ProductSection.CompanySettingsTeam}>
        <CompanyInformationUsers />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/permission-groups">
      <ProductSectionAccessCheck
        pageName={PageName.PermissionGroups}
        productSection={ProductSection.CompanySettingsTeam}
      >
        <PermissionGroups />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/strategies/:strategy/:tab/">
      <ProductAccessCheck product={DigifiProduct.DecisionEngine}>
        <ProductSectionAccessCheck
          pageName={PageName.DecisionStrategies}
          productSection={ProductSection.DecisionEngineStrategies}
        >
          <StrategyPage />
        </ProductSectionAccessCheck>
      </ProductAccessCheck>
    </Route>
    <Route path="/decision/strategies">
      <ProductAccessCheck product={DigifiProduct.DecisionEngine}>
        <ProductSectionAccessCheck
          pageName={PageName.DecisionStrategies}
          productSection={ProductSection.DecisionEngineStrategies}
        >
          <StrategyGroups />
        </ProductSectionAccessCheck>
      </ProductAccessCheck>
    </Route>
    <Route path="/company-settings/digifi-api">
      <ProductSectionAccessCheck
        pageName={PageName.DigiFiApiKeys}
        productSection={ProductSection.CompanySettingsDevelopers}
      >
        <DigifiApi />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/docu-sign/:tab">
      <ProductSectionAccessCheck
        pageName={PageName.DocuSignIntegration}
        productSection={ProductSection.CompanySettingsDevelopers}
      >
        <DocuSign />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/webhooks/:tab">
      <ProductSectionAccessCheck pageName={PageName.DigifiWebhooks} productSection={ProductSection.CompanySettingsDevelopers}>
        <Webhooks />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/variables/all">
      <ProductSectionAccessCheck pageName={PageName.Variables} productSection={ProductSection.CompanySettingsVariables}>
        <Variables />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decisions/:decisionId">
      <ProductSectionAccessCheck pageName={PageName.Decisions} productSection={ProductSection.DecisionEngineProcessing}>
        <DecisionResultOverviewPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decisions">
      <ProductSectionAccessCheck pageName={PageName.Decisions} productSection={ProductSection.DecisionEngineProcessing}>
        <Decisions />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/api/run">
      <ProductSectionAccessCheck
        pageName={PageName.Deployment}
        productSection={ProductSection.DecisionEngineDeployment}
      >
        <ApiActivationPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/send-grid/:tab/">
      <ProductSectionAccessCheck
        pageName={PageName.SendGridIntegration}
        productSection={ProductSection.CompanySettingsDevelopers}
      >
        <SendGridPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path={AppRoutes.SecureUpload}>
      <SecureFileUploadPage />
    </Route>
    <Route path="/company-settings/billing/:tab?">
      <ProductSectionAccessCheck
        pageName={PageName.PlanAndBilling}
        productSection={ProductSection.CompanySettingsPlanAndBilling}
      >
        <Billing />
      </ProductSectionAccessCheck>
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);
