import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './ButtonToggleGroup.module.scss';

export interface ButtonToggleGroupProps {
  options: string[];
  selectedValue: string;
  onSelect: (value: string) => void;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
  isOptionDisabled?: (option: string) => boolean;
  renderOptionTooltip?: (option: string) => ReactNode;
}

const ButtonToggleGroup: FC<ButtonToggleGroupProps> = ({
  options,
  selectedValue,
  className,
  buttonClassName,
  disabled,
  isOptionDisabled,
  renderOptionTooltip,
  onSelect,
}) => {
  const handleButtonClick = (value: string) => () => {
    if (value !== selectedValue) {
      onSelect(value);
    }
  };

  return (
    <div className={clsx(styles.buttonToggleGroup, className)}>
      {options.map((value) => {
        const optionDisabled = isOptionDisabled?.(value);

        return (
          <WrapperWithTooltip key={value} tooltip={renderOptionTooltip?.(value)}>
            <span>
              <Button
                className={clsx(styles.button, (disabled || optionDisabled) && styles.disabled, selectedValue === value && styles.selected, buttonClassName)}
                onClick={handleButtonClick(value)}
                disabled={disabled || optionDisabled}
              >
                {value}
              </Button>
            </span>
          </WrapperWithTooltip>
        );
      })}
    </div>
  );
};

export default ButtonToggleGroup;
