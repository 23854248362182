import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import {
  BaseCreateDocumentConfigurationParams,
  BaseUpdateDocumentConfigurationParams,
} from './Base/BaseDocumentConfigurationsApi';

export interface ApplicationDocumentConfiguration {
  id: string;
  name: string;
  position: number;
  folderId?: string | null;
  required?: boolean;
  productId: string;
  organizationId: string;
}

export interface CreateApplicationDocumentConfigurationParams extends BaseCreateDocumentConfigurationParams {
  productId: string;
  folderId?: string | null;
}

export interface UpdateApplicationDocumentConfigurationParams extends BaseUpdateDocumentConfigurationParams {
  folderId?: string | null;
}

export interface ApplicationDocumentConfigurationsApi {
  find(productId: string): Promise<ApplicationDocumentConfiguration[]>;
  create(params: CreateApplicationDocumentConfigurationParams): Promise<ApplicationDocumentConfiguration>;
  update(id: string, params: UpdateApplicationDocumentConfigurationParams): Promise<ApplicationDocumentConfiguration>;
  delete(id: string): Promise<void>;
}

export default class ApplicationDocumentConfigurationsRestApi extends LoanOriginationSystemApi<ApplicationDocumentConfiguration>
  implements ApplicationDocumentConfigurationsApi {
  protected resourceName = 'application-document-configurations';

  public find(productId: string) {
    const params = new URLSearchParams();

    params.set('productId', productId);

    return this.getResources(params);
  }

  public create(params: CreateApplicationDocumentConfigurationParams) {
    return this.createResource(params);
  }

  public update(id: string, params: UpdateApplicationDocumentConfigurationParams) {
    return this.updateResource(id, params);
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
