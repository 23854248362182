import { AnyAction } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import { ChangeUserInfoParams, User, ChangeUserPhotoParams } from './AccountDetailsTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { UpdateUserInfoCredentials } from 'api/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetUserInfoSuccessAction extends AnyAction {
  type: AccountDetailsActionType.GetAccountDetailsSuccess;
  payload: { user: UserInfo };
}

export interface ChangeUserInfoSuccessAction extends AnyAction {
  payload: { user: User };
}

export interface ChangeUserInfoRequestAction extends AnyAction {
  payload: ChangeUserInfoParams;
}

export interface ChangeUserPhotoRequestAction extends AnyAction {
  payload: ChangeUserPhotoParams;
}

export interface ConfirmPasswordAction extends AnyAction {
  type: AccountDetailsActionType.ConfirmPassword;
  payload: { password: string };
}

export interface ConfirmPasswordActionSuccess extends AnyAction {
  type: AccountDetailsActionType.ConfirmPasswordSuccess;
}

export interface ConfirmPasswordActionFailure extends AnyAction {
  type: AccountDetailsActionType.ConfirmPasswordFailure;
}

export interface ChangeEmailAction extends AnyAction {
  type: AccountDetailsActionType.ChangeEmail;
  payload: { email: string };
}

export interface ChangeEmailActionSuccess extends AnyAction {
  type: AccountDetailsActionType.ChangeEmailSuccess;
  payload: { user: UpdateUserInfoCredentials };
}

export interface ChangeUserPhotoSuccessAction extends AnyAction {
  type: AccountDetailsActionType.ChangeAccountPhotoSuccess;
  payload: { user: User };
}

export interface DisablePhoneAuthenticationAction extends AnyAction {
  type: AccountDetailsActionType.DisablePhoneAuthentication;
  payload: { code: string };
}

export interface DisablePhoneAuthenticationSuccessAction extends AnyAction {
  type: AccountDetailsActionType.DisablePhoneAuthenticationSuccess;
  payload: { user: User };
}

export interface EnablePhoneAuthenticationAction extends AnyAction {
  type: AccountDetailsActionType.EnablePhoneAuthentication;
  payload: { code: string; phone: string };
}

export interface EnablePhoneAuthenticationSuccessAction extends AnyAction {
  type: AccountDetailsActionType.EnablePhoneAuthenticationSuccess;
  payload: { user: User };
}

export interface RequestPhoneAuthenticationAction extends AnyAction {
  type: AccountDetailsActionType.RequestPhoneAuthentication;
  payload: { phone?: string };
}

export interface RequestPhoneAuthenticationSuccessAction extends AnyAction {
  type: AccountDetailsActionType.RequestPhoneAuthenticationSuccess;
}

export interface VerifyAccountEmailAction extends AnyAction {
  type: AccountDetailsActionType.ConfirmAccountEmail;
  payload: { token: string };
}

export interface VerifyAccountEmailSuccessAction extends AnyAction {
  type: AccountDetailsActionType.ConfirmAccountEmailSuccess;
}

export const GetUserInfoSuccess = (user: UserInfo): GetUserInfoSuccessAction => {
  return {
    type: AccountDetailsActionType.GetAccountDetailsSuccess,
    payload: { user },
  };
};

export const GetUserInfoError = FailedActionCreator(AccountDetailsActionType.GetAccountDetailsFailure);

export const GetUserInfoRequest = () => {
  return {
    type: AccountDetailsActionType.GetAccountDetails,
  };
};

export const ChangeUserInfoSuccess = (successInfo: User): ChangeUserInfoSuccessAction => {
  return {
    type: AccountDetailsActionType.UpdateAccountDetailsSuccess,
    payload: { user: successInfo },
  };
};

export const ChangeUserInfoError = FailedActionCreator(AccountDetailsActionType.UpdateAccountDetailsFailure);

export const ChangeUserInfoRequest = (userInfo: ChangeUserInfoParams): ChangeUserInfoRequestAction => {
  return {
    type: AccountDetailsActionType.UpdateAccountDetails,
    payload: userInfo,
  };
};

export const ChangeUserPhotoRequest = (changeUserPhotoInfo: ChangeUserPhotoParams): ChangeUserPhotoRequestAction => {
  return {
    type: AccountDetailsActionType.ChangeAccountPhoto,
    payload: changeUserPhotoInfo,
  };
};

export const ChangeUserPhotoSuccess = (changeUserPhotoSuccessInfo: User): ChangeUserPhotoSuccessAction => {
  return {
    type: AccountDetailsActionType.ChangeAccountPhotoSuccess,
    payload: { user: changeUserPhotoSuccessInfo },
  };
};

export const ChangeUserPhotoError = FailedActionCreator(AccountDetailsActionType.ChangeAccountPhotoFailure);

export const confirmPassword = (password: string): ConfirmPasswordAction => {
  return {
    type: AccountDetailsActionType.ConfirmPassword,
    payload: { password },
  };
};

export const confirmPasswordSuccess = (): ConfirmPasswordActionSuccess => {
  return {
    type: AccountDetailsActionType.ConfirmPasswordSuccess,
  };
};

export const confirmPasswordFailed = (): ConfirmPasswordActionFailure => ({
  type: AccountDetailsActionType.ConfirmPasswordFailure,
});

export const changeEmail = (email: string): ChangeEmailAction => ({
  type: AccountDetailsActionType.ChangeEmail,
  payload: { email },
});

export const changeEmailSuccess = (user: UpdateUserInfoCredentials): ChangeEmailActionSuccess => ({
  type: AccountDetailsActionType.ChangeEmailSuccess,
  payload: { user },
});

export const changeEmailFailure = FailedActionCreator(AccountDetailsActionType.ChangeEmailFailure);

export const disablePhoneAuthentication = (code: string): DisablePhoneAuthenticationAction => ({
  type: AccountDetailsActionType.DisablePhoneAuthentication,
  payload: { code },
});

export const disablePhoneAuthenticationSuccess = (user: User): DisablePhoneAuthenticationSuccessAction => ({
  type: AccountDetailsActionType.DisablePhoneAuthenticationSuccess,
  payload: { user },
});

export const disablePhoneAuthenticationFailure = FailedActionCreator(
  AccountDetailsActionType.DisablePhoneAuthenticationFailure,
);

export const enablePhoneAuthentication = (code: string, phone: string): EnablePhoneAuthenticationAction => ({
  type: AccountDetailsActionType.EnablePhoneAuthentication,
  payload: { code, phone },
});

export const enablePhoneAuthenticationSuccess = (user: User): EnablePhoneAuthenticationSuccessAction => ({
  type: AccountDetailsActionType.EnablePhoneAuthenticationSuccess,
  payload: { user },
});

export const enablePhoneAuthenticationFailure = FailedActionCreator(
  AccountDetailsActionType.EnablePhoneAuthenticationFailure,
);

export const requestPhoneAuthentication = (phone?: string): RequestPhoneAuthenticationAction => ({
  type: AccountDetailsActionType.RequestPhoneAuthentication,
  payload: { phone },
});

export const requestPhoneAuthenticationSuccess = (): RequestPhoneAuthenticationSuccessAction => ({
  type: AccountDetailsActionType.RequestPhoneAuthenticationSuccess,
});

export const requestPhoneAuthenticationFailure = FailedActionCreator(
  AccountDetailsActionType.RequestPhoneAuthenticationFailure,
);

export const confirmAccountEmail = (token: string): VerifyAccountEmailAction => ({
  type: AccountDetailsActionType.ConfirmAccountEmail,
  payload: { token },
});

export const confirmAccountEmailSuccess = (): VerifyAccountEmailSuccessAction => ({
  type: AccountDetailsActionType.ConfirmAccountEmailSuccess,
});

export const confirmAccountEmailFailure = FailedActionCreator(AccountDetailsActionType.ConfirmAccountEmailFailure);
