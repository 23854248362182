import React, { useMemo } from 'react';
import RowActions from 'components/RowActions';
import { RowActionsProps } from 'components/RowActions/RowActions';

interface ApplicationRowActionsProps extends Partial<RowActionsProps> {
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  deleteDisabled: boolean;
  editApplicationTitle?: string;
}

const ApplicationRowActions = ({
  onEdit,
  onDelete,
  onDuplicate,
  actions,
  deleteDisabled,
  editApplicationTitle,
  ...rowActionsProps
}: ApplicationRowActionsProps) => {
  const rowActions = useMemo(() => [
    {
      title: editApplicationTitle || 'Edit Application',
      handler: onEdit,
      danger: false,
      separatorRequired: false,
    },
    {
      title: 'Duplicate Application',
      handler: onDuplicate,
      danger: false,
      separatorRequired: true,
    },
    {
      title: 'Delete Application',
      danger: true,
      handler: onDelete,
      disabled: deleteDisabled,
      tooltip: deleteDisabled ? 'Only users with Owner-level permissions can delete applications' : undefined,
    },
    ...(actions || []),
  ], [editApplicationTitle, onEdit, onDuplicate, onDelete, deleteDisabled, ...(actions || [])]);

  return (
    <RowActions
      actions={rowActions}
      {...rowActionsProps}
    />
  );
};

export default ApplicationRowActions;
