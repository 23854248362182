import BaseVariableConfigurationsRestApi, {
  BaseCreateVariableConfigurationParams,
  BaseVariableConfiguration,
  BaseVariableConfigurationsApi,
} from './Base/BaseVariableConfigurationsApi';

export interface ApplicationDataTabVariableConfiguration extends BaseVariableConfiguration {
  productId: string;
}

export interface CreateApplicationDataTabVariableConfigurationParams extends BaseCreateVariableConfigurationParams {
  productId: string;
}

export interface FindApplicationDataTabVariableConfigurationParams {
  productId: string;
}

export type ApplicationDataTabVariableConfigurationsApi = BaseVariableConfigurationsApi<
  ApplicationDataTabVariableConfiguration,
  FindApplicationDataTabVariableConfigurationParams,
  CreateApplicationDataTabVariableConfigurationParams
>;

export default class ApplicationDataTabVariableConfigurationsRestApi extends BaseVariableConfigurationsRestApi<
  ApplicationDataTabVariableConfiguration,
  FindApplicationDataTabVariableConfigurationParams,
  CreateApplicationDataTabVariableConfigurationParams
> implements ApplicationDataTabVariableConfigurationsApi {
  protected resourceName = 'application-data-tab-variable-configurations';

  protected getUrlSearchParamsByFindParams(params: FindApplicationDataTabVariableConfigurationParams) {
    const urlSearchParams = super.getUrlSearchParamsByFindParams(params);

    urlSearchParams.set('productId', params.productId);

    return urlSearchParams;
  }
}
