import React, { FC } from 'react';
import styles from './ErrorPageInfo.module.scss';
import { ErrorInSystemImage, ShareImage, NotFoundPageImage } from 'static/images';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { AppRoutes } from 'routes/RouteService';

export enum ErrorPageType {
  SystemError = 'systemError',
  NotFound = 'notFound',
  NotFoundContextualView = 'notFoundContextualView',
}

const getErrorImage = (errorType: ErrorPageType) => {
  switch (errorType) {
    case ErrorPageType.SystemError:
      return <ErrorInSystemImage />;
    case ErrorPageType.NotFound:
      return <NotFoundPageImage />;
    case ErrorPageType.NotFoundContextualView:
      return <NotFoundPageImage />;
    default:
      return <ErrorInSystemImage />;
  }
};

interface ErrorPageInfoProps {
  errorType: ErrorPageType;
  errorTitle: string;
  className?: string;
}

const ErrorPageInfo: FC<ErrorPageInfoProps> = ({ errorType, errorTitle, className }) => {
  const history = useHistory();

  const isContextualView = errorType === ErrorPageType.NotFoundContextualView;

  const handleGoToDashboard = () => {
    history.push(AppRoutes.Home);
    /**
     * A smarter way to get rid of ErrorPage would be to reset state in ErrorBoundary component.
     * But as long as we get here after an exception, makes sense to reload the whole page.
     */
    window.location.reload();
  };

  const handleCloseContextualView = () => {
    history.push(history.location.pathname);
  };

  return (
    <div className={clsx(styles.errorMainInfo, className && className)}>
      {getErrorImage(errorType)}
      <h3 className={clsx(styles.errorTitle, errorType === ErrorPageType.SystemError && styles.errorSystemTitle)}>
        {errorTitle}
      </h3>
      <div className={styles.contactText}>
        <p>If you believe this is a system error, please</p>
        <ExternalLink className={styles.contactLink} href="https://www.digifi.io/contact-support">
          Contact DigiFi <ShareImage />
        </ExternalLink>
      </div>
      <Button
        kind={errorType === ErrorPageType.NotFoundContextualView ? 'secondary' : 'primary'}
        size="form"
        className={styles.dashboardButton}
        onClick={isContextualView ? handleCloseContextualView : handleGoToDashboard}
      >
        {isContextualView ? 'Close Window' : 'Go to Dashboard'}
      </Button>
    </div>
  );
};

export default ErrorPageInfo;
