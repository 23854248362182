import React from 'react';
import clsx from 'clsx';
import { DocIcon, FileIcon, ImageIcon, JsonIcon, PdfIcon, PptIcon, XlsIcon, FileSystemFolderIcon } from 'static/images';
import {
  ApplicationDocument,
  ApplicationDocumentFile,
  DocumentExtension,
} from 'api/LoanOriginationSystem/DocumentsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { isFolder, isFile } from 'LoanOriginationSystemApplicationPage/Documents/utils';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import { ApplicationDocumentsTableSize } from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/ApplicationDocumentsTable/TableColumnSizes';
import SearchHighlight from 'components/SearchHighlight';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import styles from './ApplicationDocumentItemRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';

interface ApplicationDocumentItemRowProps {
  className: string;
  disableHover?: boolean;
  document: ApplicationDocument;
  onClick: (document: ApplicationDocument) => void;
  searchInputValue: string;
  onApplicationDocumentDelete: (document: ApplicationDocument) => void;
  onApplicationDocumentDownload: (document: ApplicationDocumentFile) => void;
  onApplicationDocumentRename: (document: ApplicationDocument) => void;
}

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
]);

export const getBytesInMb = (bytes: number) => {
  return bytes / (1024 * 1024);
};

export const SMALL_SIZE_DISPLAY_SIZE = 0.1;

// if file less then 5kb, then .toFixed(1) will return "0.0" and we show it like <SMALL_SIZE_DISPLAY_SIZE>
const getFileSize = (document: ApplicationDocumentFile) =>
  Number(getBytesInMb(document.size).toFixed(1)) || SMALL_SIZE_DISPLAY_SIZE;

const mapDocumentToActionTitle = (action: string, document: ApplicationDocument) =>
  `${action} ${isFolder(document) ? 'Folder' : 'Document'}`;

const ApplicationDocumentItemRow = ({
  className,
  disableHover,
  document,
  searchInputValue,
  onClick,
  onApplicationDocumentDelete,
  onApplicationDocumentDownload,
  onApplicationDocumentRename,
}: ApplicationDocumentItemRowProps) => {
  const renderCellContent = (content: string, cellClassName?: string) => (
    <OverflowedText className={cellClassName}>
      <SearchHighlight search={searchInputValue}>{content}</SearchHighlight>
    </OverflowedText>
  );

  const actions = [
    ...(isFile(document)
      ? [
          {
            title: 'Download Document',
            handler: () => onApplicationDocumentDownload(document),
            danger: false,
          },
        ]
      : []),
    {
      title: mapDocumentToActionTitle('Rename', document),
      handler: () => onApplicationDocumentRename(document),
      separatorRequired: true,
    },
    {
      title: mapDocumentToActionTitle('Delete', document),
      handler: () => onApplicationDocumentDelete(document),
      danger: true,
      separatorRequired: false,
    },
  ];

  return (
    <TableRow
      useActions
      key={document.id}
      onClick={() => onClick(document)}
      className={clsx(styles.tableRow, className)}
      disableHover={disableHover}
    >
      <TableBodyCell width={ApplicationDocumentsTableSize.Name} noPadding>
        <div className={styles.extensionIcon}>
          {isFolder(document) ? <FileSystemFolderIcon /> : iconsByExtension.get(document.extension) || <FileIcon />}
        </div>
        {renderCellContent(document.name, styles.nameCell)}
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.Type} overflowed>
        {isFile(document) ? document.extension : ' '}
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.Size} overflowed noPadding>
        {isFile(document) ? `${getFileSize(document)} Mb` : null}
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.CreatedAt} noPadding>
        <WithSystemApiUserAvatar className={styles.avatar} user={document.createdBy} size="small" />
        <OverflowedText>{formatDate(document.createdAt, DateTimeFormat.Short)}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.UpdatedAt} noPadding>
        <WithSystemApiUserAvatar className={styles.avatar} user={document.updatedBy} size="small" />
        <OverflowedText>{formatDate(document.updatedAt, DateTimeFormat.Short)}</OverflowedText>
      </TableBodyCell>
      <RowActions actions={actions} popupClassName={styles.actionsPopup} />
    </TableRow>
  );
};

export default ApplicationDocumentItemRow;
