import React, { ChangeEvent, FC, useRef, useState } from 'react';
import TextInput from 'components/TextInput';
import PasswordInput from 'components/PasswordInput';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import styles from './CreateAccountInviteForm.module.scss';
import { CreateNewAccountInviteData } from 'api/Core/UserInviteService';
import validateCreateAccountInviteForm, { LABELS } from './validateCreateAccountInviteForm';

interface CreateAccountInviteFormProps {
  onSubmit: (data: CreateNewAccountInviteData) => Promise<void>;
}

const CreateAccountInviteForm: FC<CreateAccountInviteFormProps> = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const lastNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [errors, setErrors] = useState<Partial<CreateNewAccountInviteData>>({});

  const handleInputChange = (setValue: (value: string) => void, key: keyof CreateNewAccountInviteData) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(e.target.value);

    setErrors({ ...errors, [key]: '' });
  };

  const handleBlur = (key: keyof CreateNewAccountInviteData, value: string) => () => {
    const validationResult = validateCreateAccountInviteForm({ [key]: value });
    setErrors({ ...errors, [key]: validationResult[key] });
  };

  const getFormData = () => ({ firstName, lastName, password });

  const handleSubmit = async () => {
    const data = getFormData();
    const newErrors = validateCreateAccountInviteForm(data);
    setErrors(errors);

    if (Object.keys(newErrors).length === 0) {
      await onSubmit(data);
    }
  };

  const formData = getFormData();
  const continueIsDisabled = Object.values(validateCreateAccountInviteForm(formData)).length > 0;

  return (
    <div>
      <TextInput
        value={firstName}
        onChange={handleInputChange(setFirstName, 'firstName')}
        onKeyDown={TriggerEventOnEnterKeyDown(lastNameRef, 'focus')}
        containerClassName={styles.firstName}
        autoComplete="new-password"
        labelTitle={LABELS.firstName}
        errorMessage={errors.firstName}
        onBlur={handleBlur('firstName', firstName)}
        autoFocus
      />
      <TextInput
        value={lastName}
        onChange={handleInputChange(setLastName, 'lastName')}
        labelTitle="Last Name"
        ref={lastNameRef}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordRef, 'focus')}
        autoComplete="new-password"
        errorMessage={errors.lastName}
        onBlur={handleBlur('lastName', lastName)}
      />
      <PasswordInput
        labelTitle={LABELS.password}
        onChange={handleInputChange(setPassword, 'password')}
        value={password}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
        ref={passwordRef}
        autoComplete="new-password"
        errorMessage={errors.password}
        onBlur={handleBlur('password', password)}
      />
      <ButtonWithLoadingState
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        ref={submitButtonRef}
        className={styles.createAccount}
        disabled={continueIsDisabled}
      >
        Create Account
      </ButtonWithLoadingState>
    </div>
  );
};

export default CreateAccountInviteForm;
