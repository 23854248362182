import React, { FC, useEffect } from 'react';
import styles from './DecisionView.module.scss';
import { CloseButtonImage } from 'static/images';
import clsx from 'clsx';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  DecisionResultsSortingField,
  DecisionResultsSortingFieldType,
  DecisionResultsState,
  requestDecisionResults,
  RESET_DECISION_RESULTS_STATE_ACTION_PREFIX,
} from 'Decisions/DecisionResultsStore';
import useStateReset from 'hooks/useStateReset';
import { DateTimeFormat } from 'utils/dateFormat';
import DateTime from 'components/DateTime';
import { Link } from 'react-router-dom';
import { Option } from 'components/SelectInput/SelectInput';
import AutoCompletion from 'components/AutoCompletion';
import DecisionResultsTable from './DecisionResultsTable';
import pagination, {
  DECISION_RESULTS_PER_PAGE_DEFAULT,
  DecisionResultsPaginationParams,
} from 'pages/Decisions/DecisionResultsPagination';
import capitalize from 'capitalize';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { useDecisionResultApi } from 'providers/ApiServiceProvider';
import { ReduxState } from 'types/redux';
import useAbortSignal from 'hooks/useAbortSignal';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import RowActions from 'components/RowActions';
import { DecisionState, requestDecision } from 'Decisions/DecisionStore';
import DecisionResultsHeaderSkeleton from 'components/DecisionResultsView/DecisionResultsHeaderSkeleton';
import DecisionFilterSkeleton from 'components/DecisionResultsView/DecisionFilterSkeleton';

interface DecisionViewProps {
  decisionId: string;
  onClose: () => void;
  getRowLink: (result: DecisionResultListItem) => string;
  onRowClick: (result: DecisionResultListItem) => void;
  onDeleteDecision: (decisionId: string) => void;
}

interface DecisionResultsFilter {
  resultType?: DecisionResultType;
  offset?: number;
}

const STATUS_FILTER_OPTIONS_ORDER = ['all', 'passed', 'failed', 'error'];

const DecisionView: FC<DecisionViewProps> = ({
  decisionId,
  getRowLink,
  onRowClick,
  onClose,
  onDeleteDecision,
}) => {
  const dispatch = useDispatch();
  const decisionResultApi = useDecisionResultApi();

  useEffect(() => {
    dispatch(requestDecision({ decisionId }));
    fetchDecisionResults({
      count: decisionResultsItemsPerPage || DECISION_RESULTS_PER_PAGE_DEFAULT,
      decisionId,
      sortingType: decisionResultsSortingType,
    });
  }, []);

  const {
    page: decisionResultsPage,
    itemsPerPage: decisionResultsItemsPerPage,
    sortingType: decisionResultsSortingType,
    resultType,
    filters,
  } = useSelector<ReduxState, DecisionResultsState>((state) => state.decisionResults);
  const {
    data: decision,
    notFound: decisionNotFound,
  } = useSelector<ReduxState, DecisionState>((state) => state.decision);
  const paginationParams = {
    decisionId,
    resultType,
    sortingType: decisionResultsSortingType
  } as DecisionResultsPaginationParams;

  const decisionResults = pagination.usePaginatedItems(paginationParams);
  const paginationProps = pagination.usePagination(paginationParams);
  const fetchDecisionResults = useAbortSignal((
    abortSignal: AbortSignal,
    params: DecisionResultsFilter = {},
    sortBy: DecisionResultsSortingFieldType = decisionResultsSortingType,
  ) => {
    dispatch(
      requestDecisionResults({
        offset: (decisionResultsPage - 1)  * decisionResultsItemsPerPage,
        count: decisionResultsItemsPerPage,
        decisionId,
        sortingType: sortBy,
        ...(resultType ? { resultType } : {}),
        ...params,
        abortSignal,
      }),
    );
  });

  const filterOptions = STATUS_FILTER_OPTIONS_ORDER.reduce<Option[]>((result, value) => {
    if (value === 'all' && filters.filtersTotal !== undefined) {
      return [
        ...result,
        {
          value,
          name: `All Sub-Decisions (${filters.filtersTotal} of ${filters.filtersTotal})`,
        },
      ];
    }
    const filterOption = filters.groupedByStatus.find(({ status }) => status === value);
    if (!filterOption) {
      return result;
    }

    return [
      ...result,
      {
        value,
        name: `${capitalize(filterOption.status)} (${filterOption.count} of ${filters.filtersTotal})`,
      },
    ];
  }, []);

  useStateReset(RESET_DECISION_RESULTS_STATE_ACTION_PREFIX);

  const handleDownloadDecision = async () => {
    const response = await decisionResultApi.downloadMany(decisionId);
    downloadBlobFile(response);
  }

  const onSort = (field: DecisionResultsSortingField, ascending: boolean) => {
    fetchDecisionResults({
      sortingType: {
        field,
        ascending,
      },
      offset: 0,
      resultType: resultType !== filterOptions[0].value ? (resultType as DecisionResultType) : undefined,
    });
  };

  const handleResultTypeChange = (selectedOption: Option) => {
    fetchDecisionResults({
      decisionId,
      sortingType: decisionResultsSortingType,
      resultType: selectedOption !== filterOptions[0] ? (selectedOption.value as DecisionResultType) : undefined,
      offset: 0,
    });
  };

  const renderNotFoundDecision = () => (
    <>
      <div className={styles.notFoundHeader}>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <ErrorPageInfo errorType={ErrorPageType.NotFoundContextualView} errorTitle="The page was not found." />
    </>
  );

  const renderResultsTable = () => (
    <DecisionResultsTable
      decisionResults={decisionResults}
      paginationProps={paginationProps}
      onSort={onSort}
      sortingType={decisionResultsSortingType}
      getRowLink={getRowLink}
      onRowClick={onRowClick}
    />
  );

  const renderHeader = () => (
    <>
      <div className={styles.header}>
        <div className={styles.titleActionsContainer}>
          <h1 className={styles.title}>{decision?.name}</h1>
          <RowActions
            btnClassName={styles.actionsButton}
            alwaysVisible
            actions={[{
              title: 'Download Decision',
              handler: () => handleDownloadDecision(),
              separatorRequired: true,
            }, {
              title: 'Delete Decision',
              handler: () => onDeleteDecision(decisionId),
              danger: true,
            }]}
          />
        </div>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <div className={styles.decisionDescription}>
        <WithSystemApiUserAvatar
          user={decision!.createdBy}
          className={styles.userAvatar}
          size="small"
        />
        <p>
          Created <DateTime time={decision!.createdAt} format={DateTimeFormat.Long} />{' '}
          {decision!.applicationId && decision!.applicationDisplayId && (
            <>
              for the application{' '}
              <Link to={`/los/applications/${decision!.applicationDisplayId}`} className={styles.headerLink}>
                {decision!.applicationName}
              </Link>
            </>
          )}
        </p>
      </div>
    </>
  );

  const renderHeaderSkeleton = () => <DecisionResultsHeaderSkeleton />;
  const renderDecisionFilterSkeleton = () => <DecisionFilterSkeleton />;

  if (decisionNotFound) {
    return <div className={clsx(styles.viewContainer)}>{renderNotFoundDecision()}</div>;
  }

  return <div className={clsx(styles.viewContainer)}>
    {decision ? renderHeader() : renderHeaderSkeleton()}
    <div className={styles.viewBody}>
      <div className={styles.decisionFilter}>
        {decision ? <AutoCompletion
          onChange={handleResultTypeChange}
          value={resultType || filterOptions[0]?.value}
          options={filterOptions}
          hideClearIcon
          className={styles.filterInput}
          selectControlClassName={styles.filterInputSelectControl}
        /> : renderDecisionFilterSkeleton()}
      </div>
      {renderResultsTable()}
    </div>
  </div>;
};

export default DecisionView;
