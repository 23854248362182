import Fetcher from 'middlewares/Fetcher';
import { ProductCalculationsApi } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import {
  DeleteProductCalculationAction,
  deleteProductCalculationSuccess,
  deleteProductCalculationFailure,
} from './ActionCreator';
import { ProductCalculationsType } from './ActionTypes';

const DeleteProductCalculationMiddleware = (api: ProductCalculationsApi) =>
  Fetcher<string, DeleteProductCalculationAction>(
    ProductCalculationsType.DeleteProductCalculation,
    deleteProductCalculationSuccess,
    deleteProductCalculationFailure,
    async (action) => {
      await api.delete(action.payload.calculationId);

      return action.payload.calculationId;
    },
  );

export default DeleteProductCalculationMiddleware;
