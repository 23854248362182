import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonTabsList.module.scss';
import clsx from 'clsx';

const MIN_TAB_WIDTH = 74;
const MAX_TAB_WIDTH = 100;
const TABS_LENGTH = 7;

const TABS = new Array(TABS_LENGTH).fill(null).map(() => {
  return Math.floor(Math.random() * (MAX_TAB_WIDTH - MIN_TAB_WIDTH) + MIN_TAB_WIDTH)
});

export interface SkeletonProps {
  className?: string;
}

const TabListSkeleton = ({ className }: SkeletonProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      {TABS.map((width, index) => (
        <SkeletonRectangle
          key={index}
          width={`${width}px`}
          height="16px"
          color="primary6"
        />
      ))}
    </div>
  );
};

export default TabListSkeleton;
