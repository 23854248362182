import React from 'react';
import WrapperWithTooltip from 'components/Tooltip';
import { FunctionIcon } from 'static/images';

const CalculationsFunctionIcon = () => {
  const renderInputStubIconTooltip = () => (
    <>
      <p>This variable is automatically</p>
      <p>calculated</p>
    </>
  );

  return (
    <WrapperWithTooltip tooltip={renderInputStubIconTooltip()}>
      <FunctionIcon />
    </WrapperWithTooltip>
  );
};

export default CalculationsFunctionIcon;
