import { applyMiddleware, compose, createStore } from 'redux';
// Handlers
import combinedReducers from 'handlers';
import LeftNavMiddleware from 'MainLayout/leftNavReducer/LeftNavMiddlewares';
// API
import AuthenticationAPIRest from 'api/Authentication';
import DownloadAPIRest from 'api/Download';
import createAuthHandler from 'AuthHandler';
import AuthApiRest from 'api/Core/AuthApi';
import AuthEventEmitter from 'eventHandlers/AuthEventEmitter';
// Middlewares
import thunk from 'redux-thunk';
import OrganizationApi from 'api/Organization';
import DecisionEngineApiRest from 'api/DecisionEngineApiRest';
import AccountDetailsMiddleware from 'AccountDetails/AccountDetailsMiddleware';
import ChangeUserMiddleware from 'AccountDetails/ChangeUserMiddleware';
import ChangeUserPhotoMiddleware, {
  onChangeUserPhotoSuccessMiddleware,
} from 'AccountDetails/ChangeUserPhotoMiddlewares';
import onChangeUserSuccessMiddleware from 'AccountDetails/ChangeUserSuccessMiddleware';
import GetOrganizationSeatsMiddleware from 'CompanyInformation/GetOrganizationSeatsMiddleware';
import GetOrganizationFreeSeatMiddleware from 'CompanyInformation/GetOrganizationFreeSeatMiddleware';
import ChangeCompanyMiddleware from 'CompanyInformation/ChangeCompanyMiddleware';
import OnChangeCompanySuccessMiddleware from 'CompanyInformation/OnChangeCompanySuccessMiddleware';
import EditCompanyUserMiddleware from 'EditCompanyUser/EditCompanyUserMiddleware';
import OnEditCompanyUserSuccessMiddleware from 'EditCompanyUser/OnEditCompanyUserSuccessMiddleware';
import AddNewCompanyUserMiddleware from 'AddNewCompanyUser/AddNewCompanyUserMiddleware';
import OnAddNewCompanyUserSuccessMiddleware from 'AddNewCompanyUser/OnAddNewCompanyUserSuccessMiddleware';
import VerifyPasswordMiddlewareForGenerateFirstSecret from 'GenerateFirstSecret/VerifyPasswordMiddleware';
import onGenerateFirstSecretPasswordSuccessMiddleware from 'GenerateFirstSecret/OnVerifyPasswordSuccessMiddleware';
import GenerateFirstSecretMiddleware from 'GenerateFirstSecret/GenerateFirstSecretMiddleware';
import onGenerateFirstSecretSuccessMiddleware from 'GenerateFirstSecret/OnGenerateFirstSecretSuccessMiddleware';
import VerifyPasswordMiddlewareForGenerateSecondSecret from 'GenerateSecondSecret/VerifyPasswordMiddleware';
import onGenerateSecondSecretPasswordSuccessMiddleware from 'GenerateSecondSecret/OnVerifyPasswordSuccessMiddleware';
import GenerateSecondSecretMiddleware from 'GenerateSecondSecret/GenerateSecondSecretMiddleware';
import onGenerateSecondSecretSuccessMiddleware from 'GenerateSecondSecret/OnGenerateSecondSecretSuccessMiddleware';
import DeleteDecisionStrategyMiddleware from 'DecisionStrategy/DeleteDecisionStrategyMiddleware';
import OnDeleteDecisionStrategySuccessMiddleware from 'DecisionStrategy/OnDeleteDecisionStrategySuccessMiddleware';
import errorNotificationsMiddleware from 'middlewares/ErrorNotificationsMiddleware';
import GetDecisionStrategyInfo from 'DecisionStrategy/GetDecisionStrategyInfoMiddleware';
import { onRenameStrategySuccessMiddleware, StrategyNameMiddleware } from 'DecisionStrategy/StrategyNameMiddleware';
import { onChangeVariableStatusSuccessMiddleware } from 'Variables/OnChangeVariableStatusSuccessMiddleware';
import ConfirmPasswordMiddleware from 'AccountDetails/ConfirmPasswordMiddleware';
import ChangeUserEmailMiddleware from 'AccountDetails/ChangeUserEmailMiddleware';

import CreateNewStrategyVersionMiddleware, {
  CreateNewStrategyVersionSuccessMiddleware,
} from 'CreateNewStrategyVersion/CreateNewStrategyVersionMiddlewares';
import {
  CreateVariableMiddleware,
  notifyOnSuccessSaveVariableMiddleware,
  onDefaultSaveVariableSuccessMiddleware,
  EditVariableMiddleware,
  GetVariablesMiddleware,
} from 'Variables';
import variablesFilterAndSortingSaver from 'Variables/FilterAndSortingSaver';
import {
  DeleteDecisionStrategyModuleMiddleware,
  onUpdateBranchOrderSuccessMiddleware,
  onDeleteDecisionStrategyModuleSuccessMiddleware,
  ReorderModuleBranchesMiddleware,
} from 'DecisionStrategy/DeleteDecisionStrategyModuleMiddleware';
import {
  DeleteBranchMiddleware,
  DuplicateBranchMiddleware,
  GetBranchInfoMiddleware,
  onDeleteBranchSuccessMiddleware,
  onDuplicateBranchSuccessMiddleware,
  onUpdateBranchNameSuccessMiddleware,
  onUpdateRuleOrderSuccessMiddleware,
  ReorderBranchRulesMiddleware,
  UpdateBranchNameMiddleware,
} from 'BranchInfo/Middlewares';
import variablesPagination from 'pages/Variables/Pagination';
import GetDecisionStrategyUpdates from 'DecisionStrategyUpdates/GetDecisionStrategyUpdatesMiddleware';
import { decisionStrategyUpdatesPagination } from 'pages/Strategy/Pagination';
import decisionStrategyUpdatesSortingSaver from 'DecisionStrategyUpdates/SortingSaver';
import DecisionStrategyModuleReorderMiddleware, {
  onUpdateModuleOrderSuccessMiddleware,
} from 'DecisionStrategy/DecisionStrategyModuleReorderMiddleware';
import variablesSearchMiddleware from 'Variables/VariablesSearchMiddleware';
import AddModuleMiddleware, {
  onNewModuleAddedSuccessMiddleware,
  onUpdateModuleInfoSuccessMiddleware,
  onUpdateModuleNameSuccessMiddleware,
  UpdateModuleInfoMiddleware,
  UpdateModuleNameMiddleware,
} from 'ModuleInfo/ModuleInfoMiddlewares';
import AddBranchMiddleware, { onCreateBranchSuccessMiddleware } from 'AddBranch/AddBranchMiddleware';
import GetExternalIntegrationVariablesMiddleware from 'EditExternalIntegrationVariablesPopUp/GetExternalIntegrationVariablesMiddleware';
import GetIntegrationsListMiddleware from 'ExternalIntegrationsList/GetIntegrationsListMiddleware';
import {
  EditExternalIntegrationMiddleware,
  onEditExternalIntegrationSuccessMiddleware,
} from 'EditExternalIntegration/Middlewares';
import {
  CreateCalculationScriptMiddleware,
  CreateRuleMiddleware,
  CreateSimpleOutputMiddleware,
  DuplicateRule,
  onCreateRuleSuccessMiddleware,
  onDuplicateRuleSuccessMiddleware,
  UploadRuleSetMiddleware,
} from 'RuleCreate/Middlewares';
import { GetStrategyGroupsDataMiddleware } from 'StrategyGroupsData/Middlewares';
import strategyGroupsFilterSaver from 'StrategyGroupsData/FilterSaver';
import { onUpdateScoringRueSuccessMiddleware, UpdateScoringRuleSegmentMiddleware } from 'ScoringModel/Middlewares';
import loanOriginationSystemMiddlewares from './loanOriginationSystem';
import { CopyModuleBranchRulesMiddleware, GetBranchOptionsMiddleware } from 'CopyModuleBranchRules/Middlewares';
import {
  onStrategyDescriptionSuccessMiddleware,
  StrategyDescriptionMiddleware,
} from 'DecisionStrategy/StrategyDescriptionMiddleware';
import {
  GetApiActivationsMiddleware,
  onUpdateApiActivationsSuccessMiddleware,
  UpdateApiActivationsMiddleware,
  DownloadApiActivationsMiddleware,
  onDownloadApiActivationsSuccessMiddleware,
  GetDropdownGroupedStrategiesMiddleware,
} from 'ApiActivation/Middlewares';
import { DeleteRuleMiddleware, onDeleteRuleSuccessMiddleware } from 'RuleDelete/Middlewares';
import {
  EditCalculationScriptMiddleware,
  EditRuleMiddleware,
  EditSimpleOutputMiddleware,
  onEditRuleSuccessMiddleware,
} from 'RuleEdit/Middlewares';
import history from './history';
import { DuplicateModuleMiddleware } from 'DuplicateModule/Middlewares';
import {
  ArchiveStrategiesMiddleware,
  onChangeArchiveStatusFailureMiddleware,
  onChangeArchiveStatusSuccessMiddleware,
  RestoreStrategiesMiddleware,
} from 'DecisionStrategy/ArchiveStrategiesMiddleware';
import strategyGroupsPagination from 'pages/StrategyGroups/Pagination';
import { GetVersionsListDataMiddleware } from 'VersionsViewData/Middlewares';
import versionsPagination from 'components/VersionsView/Pagination';
import versionsFilterAndSortingSaver from 'VersionsViewData/FilterAndSortingSaver';
import { OrderBy } from 'api/Types';
import AssignOutputVariableMiddleware, {
  onAssignOutputVariableSuccessMiddleware,
} from 'EditExternalIntegrationVariablesPopUp/AssignOutputVariableMiddleware';
import UploadCompanyLogoMiddleware, { onChangeLogoSuccessMiddleware } from 'CompanyLogoUpload/Middlewares';
import { onUpdateRegionSettingsSuccessMiddleware, UpdateRegionSettingsMiddleware } from 'RegionSettings/Middlewares';
import GetVariableByIdMiddleware from 'VariablesAttributes/Middlewares';
import setSnackLifeTimeMiddleware from 'Snackbar/Middleware';
import SendGridIntegrationApi from 'api/LoanOriginationSystem/SendGridIntegrationApi';
import {
  SaveSendGridIntegrationMiddleware,
  SaveSendGridIntegrationSuccessMiddleware,
  GetSendGridIntegrationMiddleware,
  GetTemplatesRequestMiddleware,
  GetTemplatesToImportRequestMiddleware,
  GetSenderEmailsRequestMiddleware,
  ImportSendGridTemplatesMiddleware,
  ImportSendGridTemplatesSuccessMiddleware,
  UpdateTemplateVariablesMappingRequestMiddleware,
  UpdateTemplateStatusRequestSuccessMiddleware,
  UpdateTemplateVariablesMappingRequestSuccessMiddleware,
  UpdateTemplateSenderEmailRequestMiddleware,
  UpdateTemplateStatusRequestMiddleware,
  DeleteTemplateRequestMiddleware,
  DeleteTemplateRequestSuccessMiddleware,
  TemplatesPaginationMiddleware,
  SortTemplatesMiddleware,
} from 'SendGrid/Middlewares';
import SendGridImportApi from 'api/LoanOriginationSystem/SendGridImportApi';
import SendGridTemplateApi from 'api/LoanOriginationSystem/SendGridTemplateApi';
import VariablesRestApi from 'api/Core/VariablesApi';
import AccountDetailsRestApi from 'api/Core/AccountDetailsApi';
import LoanOriginationSystemLabelsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import WebhookEndpointsRestApi from 'api/Webhooks/WebhookEndpointsApi';
import WebhookEventsRestApi from 'api/Webhooks/WebhookEventsApi';
import EventTypeOptionsRestApi from 'api/Core/EventTypeOptionsApi';
import {
  CreateStrategyMiddleware,
  CreateStrategySuccessMiddleware,
  DuplicateStrategyMiddleware,
  DuplicateStrategySuccessMiddleware,
} from 'Strategies/Middlewares';
import StrategyApi from 'api/DecisionEngine/StrategyApi';
import companySettingsMiddlewares from './companySettings';
import OrganizationApiRest from 'api/Core/OrganizationApi';
import GetOrganizationsMiddleware from 'AccountDetails/GetOrganizationsMiddleware';
import responseHandlers from 'api/ResponseHandlers';
import PasswordConfirmationRestApi from 'api/Core/PasswordConfirmationApi';
import RequestPhoneAuthenticationMiddleware from 'AccountDetails/RequestPhoneAuthenticationMiddleware';
import EnablePhoneAuthenticationMiddleware from 'AccountDetails/EnablePhoneAuthenticationMiddleware';
import DisablePhoneAuthenticationMiddleware from 'AccountDetails/DisablePhoneAuthenticationMiddleware';
import OrganizationMembershipApiRest from 'api/Core/OrganizationMembershipApi';
import ConfirmEmailMiddleware from 'AccountDetails/ConfirmEmailMiddleware';
import EmailVerificationApi from 'api/Core/EmailVerificationApi';
import UserRestApi from 'api/Core/UserApi';
import GetCompanyInformationMiddleware from 'CompanyInformation/GetCompanyInformationMiddleware';
import RedirectionsMiddleware from 'RedirectionsMiddleware';
import DecisionResultsPaginationMiddleware from 'Decisions/DecisionResultsPaginationMiddleware';
import DecisionsPaginationMiddleware from 'Decisions/DecisionsPaginationMiddleware';
import DecisionApi from 'api/DecisionEngine/DecisionApi';
import ResendVerificationForExpiredTokenMiddleware from 'EmailVerificationLinkExpired/ResendVerificationForExpiredTokenMiddleware';
import notifyOnSuccessResendVerificationLinkMiddleware from 'EmailVerificationLinkExpired/NotifyOnSuccessResendVerificationLinkMiddleware';
import ApiActivationPaginationMiddleware from 'ApiActivation/ApiActivationPaginationMiddleware';
import searchApiActivationMiddleware from 'ApiActivation/SearchApiActivationMiddleware';
import sortApiActivationMiddleware from 'ApiActivation/SortApiActivationMiddleware';
import apiActivationFilterAndSortingSaver from 'ApiActivation/FilterAndSortingSaver';
import StrategyGroupsApi from 'api/DecisionEngine/StrategyGroupApi';
import UserInviteApiRest from 'api/Core/UserInviteService';
import emailsFilterAndSortingSavers from 'LoanOriginationSystemEmails/FilterAndSortingSaver';
import tasksFilterAndSortingSavers from 'LoanOriginationSystemTasks/FilterAndSortingSaver';
import * as Sentry from '@sentry/react';
import DecisionResultApi from 'api/DecisionEngine/DecisionResultApi';
import RequestDropdownStrategiesMiddleware from 'ApiActivation/RequestDropdownStrategiesMiddleware';
import WebhookEndpointsPaginationMiddleware from 'Webhooks/WebhookEndpointsTable/WebhookEndpointsPaginationMiddleware';
import WebhookEventsPaginationMiddleware from 'Webhooks/WebhookEventsTable/WebhookEventsPaginationMiddleware';
import changeWebhookEventsFiltersMiddleware from 'Webhooks/WebhookEventsTable/Filters/ChangeWebhookEventsFiltersMiddleware';
import webhookEndpointNotificationsMiddleware from 'WebhookEndpoints/WebhookEndpointNotificationsMiddleware';
import WebhookEndpointsTableUpdatesMiddleware from 'Webhooks/WebhookEndpointsTable/WebhookEndpointsTableUpdatesMiddleware';
import webhookEventsTableUpdatesMiddleware from 'Webhooks/WebhookEventsTable/WebhookEventsTableUpdatesMiddleware';
import webhookEndpointEventsTableUpdatesMiddleware from 'Webhooks/WebhookEndpointDetails/WebhookEndpointEventsTable/WebhookEndpointEventsTableUpdatesMiddleware';
import webhookEventNotificationsMiddleware from 'WebhookEvents/WebhookEventNotificationsMiddleware';
import WebhookEndpointEventsPaginationMiddleware from 'Webhooks/WebhookEndpointDetails/WebhookEndpointEventsTable/WebhookEndpointEventsPaginationMiddleware';
import AsyncThunkRejectionNotificationMiddleware from 'middlewares/AsyncThunkRejectionNotificationMiddleware';
import decisionsFilterAndSortingSaver from 'Decisions/FilterAndSortingSaver';

const clientAuthApi = new AuthenticationAPIRest(responseHandlers);
const organizationApi = new OrganizationApi(responseHandlers);
const sendGridIntegrationApi = new SendGridIntegrationApi(responseHandlers);
const sendGridImportApi = new SendGridImportApi(responseHandlers);
const strategiesApi = new StrategyApi(responseHandlers);
const sendGridTemplateApi = new SendGridTemplateApi(responseHandlers);
const decisionEngineApi = new DecisionEngineApiRest(responseHandlers);
const passwordConfirmationApi = new PasswordConfirmationRestApi(responseHandlers);
export const variablesApi = new VariablesRestApi(responseHandlers);
export const downloadApi = new DownloadAPIRest(responseHandlers);
const accountApi = new AccountDetailsRestApi(responseHandlers);
export const labelsApi = new LoanOriginationSystemLabelsRestApi(responseHandlers);
export const organizationCoreApi = new OrganizationApiRest(responseHandlers);
export const organizationMembershipApi = new OrganizationMembershipApiRest(responseHandlers);
export const emailVerificationApi = new EmailVerificationApi(responseHandlers);
export const eventTypeOptionsApi = new EventTypeOptionsRestApi(responseHandlers);
const userApi = new UserRestApi(responseHandlers);
const authenticationApi = new AuthApiRest(responseHandlers);
const strategyGroupsApi = new StrategyGroupsApi(responseHandlers);
const decisionsApi = new DecisionApi(responseHandlers);
export const decisionResultApi = new DecisionResultApi(responseHandlers);
export const userInviteApi = new UserInviteApiRest(responseHandlers);
const webhookEndpointsApi = new WebhookEndpointsRestApi(responseHandlers);
const webhookEventsApi = new WebhookEventsRestApi(responseHandlers);

export const authHandler = createAuthHandler({
  authApi: new AuthApiRest(responseHandlers),
  authEvents: AuthEventEmitter,
  localStorage,
});

const redirectionsMiddleware = RedirectionsMiddleware(authHandler, history);

const emailsSavedFiltersSwitchMiddleware = emailsFilterAndSortingSavers.getFiltersSwitchMiddleware(history);
const decisionsSavedFiltersSwitchMiddleware = decisionsFilterAndSortingSaver.getFiltersSwitchMiddleware(history);
const tasksSavedFiltersSwitchMiddleware = tasksFilterAndSortingSavers.getFiltersSwitchMiddleware(history);

const getUserMiddleware = AccountDetailsMiddleware(userApi);
const changeUserMiddleware = ChangeUserMiddleware(accountApi);
const changeUserPhotoMiddleware = ChangeUserPhotoMiddleware(accountApi);
const requestPhoneAuthenticationMiddleware = RequestPhoneAuthenticationMiddleware(accountApi);
const enablePhoneAuthenticationMiddleware = EnablePhoneAuthenticationMiddleware(accountApi);
const disablePhoneAuthenticationMiddleware = DisablePhoneAuthenticationMiddleware(accountApi);
const getOrganizationsMiddleware = GetOrganizationsMiddleware(organizationMembershipApi);

const getOrganizationSeatsMiddleware = GetOrganizationSeatsMiddleware(organizationCoreApi);
const getOrganizationFreeSeatMiddleware = GetOrganizationFreeSeatMiddleware(organizationCoreApi);
const changeCompanyMiddleware = ChangeCompanyMiddleware(organizationApi);
const getCompanyInformationMiddleware = GetCompanyInformationMiddleware(organizationCoreApi);
const addNewCompanyUserMiddleware = AddNewCompanyUserMiddleware(organizationApi);
const verifyPasswordMiddlewareForGenerateFirstSecret = VerifyPasswordMiddlewareForGenerateFirstSecret(
  passwordConfirmationApi,
);
const generateFirstSecretMiddleware = GenerateFirstSecretMiddleware(clientAuthApi);
const verifyPasswordMiddlewareForGenerateSecondSecret = VerifyPasswordMiddlewareForGenerateSecondSecret(
  passwordConfirmationApi,
);
const generateSecondSecretMiddleware = GenerateSecondSecretMiddleware(clientAuthApi);
const deleteDecisionStrategyMiddleware = DeleteDecisionStrategyMiddleware(strategyGroupsApi, strategiesApi);
const editCompanyUserMiddleware = EditCompanyUserMiddleware(userApi);
const getVariablesMiddleware = GetVariablesMiddleware(variablesApi);
const getDecisionStrategyInfo = GetDecisionStrategyInfo(strategiesApi);
const strategyNameMiddleware = StrategyNameMiddleware(decisionEngineApi);
const strategyDescriptionMiddleware = StrategyDescriptionMiddleware(decisionEngineApi);
const getDecisionStrategyUpdates = GetDecisionStrategyUpdates(decisionEngineApi);
const deleteDecisionStrategyModuleMiddleware = DeleteDecisionStrategyModuleMiddleware(decisionEngineApi);
const createNewStrategyVersionMiddleware = CreateNewStrategyVersionMiddleware(strategiesApi);
const getBranchInfoMiddleware = GetBranchInfoMiddleware(decisionEngineApi);
const reorderModuleBranchesMiddleware = ReorderModuleBranchesMiddleware(decisionEngineApi);
const reorderBranchRulesMiddleware = ReorderBranchRulesMiddleware(decisionEngineApi);
const duplicateBranchMiddleware = DuplicateBranchMiddleware(decisionEngineApi);
const getIntegrationsListMiddleware = GetIntegrationsListMiddleware(decisionEngineApi);
const updateDecisionStrategyBranchNameMiddleware = UpdateBranchNameMiddleware(decisionEngineApi);
const deleteBranchMiddleware = DeleteBranchMiddleware(decisionEngineApi);
const editExternalIntegrationMiddleware = EditExternalIntegrationMiddleware(decisionEngineApi);
const getApiActivationsMiddleware = GetApiActivationsMiddleware(strategyGroupsApi);
const getDropdownGroupedStrategiesMiddleware = GetDropdownGroupedStrategiesMiddleware(strategiesApi);
const updateApiActivationsMiddleware = UpdateApiActivationsMiddleware(decisionEngineApi);
const getVersionsViewDataMiddleware = GetVersionsListDataMiddleware(decisionEngineApi);
const downloadApiActivationsMiddleware = DownloadApiActivationsMiddleware(downloadApi);
const assignOutputVariableMiddleware = AssignOutputVariableMiddleware(decisionEngineApi);
const uploadCompanyLogoMiddleware = UploadCompanyLogoMiddleware(organizationApi);
const updateRegionSettingsMiddleware = UpdateRegionSettingsMiddleware(organizationApi);
const getVariableByIdMiddleware = GetVariableByIdMiddleware(variablesApi);
const confirmPasswordMiddleware = ConfirmPasswordMiddleware(passwordConfirmationApi);
const changeUserEmailMiddleware = ChangeUserEmailMiddleware(accountApi);
const decisionsPaginationMiddleware = DecisionsPaginationMiddleware(decisionsApi);
const decisionResultsPaginationMiddleware = DecisionResultsPaginationMiddleware(decisionResultApi);
const requestDropdownStrategiesMiddleware = RequestDropdownStrategiesMiddleware(strategiesApi);
const variablesPaginationMiddleware = variablesPagination.getItemsFetcherMiddleware(
  async ({
    searchInputValue,
    dueCreatedDateRange,
    dueUpdatedDateRange,
    onlyStandard,
    onlyCustom,
    dataType,
    showArchived,
    ...params
  }) => {
    return variablesApi.getVariables(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: searchInputValue,
        dueCreatedDateRange,
        dueUpdatedDateRange,
        onlyStandard,
        onlyCustom,
        visualDataType: dataType || undefined,
        teamMembers: params.selectedMembers,
        includeArchived: showArchived,
      },
      params.sortingType,
    );
  },
);

const strategyGroupsPaginationMiddleware = strategyGroupsPagination.getItemsFetcherMiddleware(
  async ({ selectedUpdaters, ...params }) => {
    return strategyGroupsApi.findGroups({
      updaters: selectedUpdaters.map(({ id }) => id),
      ...params,
    });
  },
);

const versionsPaginationMiddleware = versionsPagination.getItemsFetcherMiddleware(
  async ({ strategyName, sortingType, selectedUpdaters, ...params }) => {
    const { field: sortBy, ascending } = sortingType;
    return decisionEngineApi.getVersionsData(strategyName, {
      ...params,
      updaters: selectedUpdaters.map(({ id }) => id),
      sortBy,
      orderBy: ascending ? OrderBy.Asc : OrderBy.Desc,
    });
  },
);

const decisionStrategyUpdatesPaginationMiddleware = decisionStrategyUpdatesPagination.getItemsFetcherMiddleware(
  async ({ strategyId, page, perPage, sortingType }) => {
    return decisionEngineApi.getDecisionStrategyUpdates(
      {
        page,
        perPage,
        sortBy: sortingType.field,
        orderBy: sortingType.ascending ? OrderBy.Asc : OrderBy.Desc,
      },
      strategyId,
    );
  },
);

const saveSendGridIntegrationMiddleware = SaveSendGridIntegrationMiddleware(sendGridIntegrationApi);
const getSendGridIntegrationMiddleware = GetSendGridIntegrationMiddleware(sendGridIntegrationApi);
const deleteTemplateRequestMiddleware = DeleteTemplateRequestMiddleware(sendGridTemplateApi);
const getTemplatesRequestMiddleware = GetTemplatesRequestMiddleware(sendGridTemplateApi);
const templatesPaginationMiddleware = TemplatesPaginationMiddleware(sendGridTemplateApi);
const getTemplatesToImportRequestMiddleware = GetTemplatesToImportRequestMiddleware(sendGridImportApi);
const getSenderEmailsRequestMiddleware = GetSenderEmailsRequestMiddleware(sendGridImportApi);
const importSendGridTemplatesMiddleware = ImportSendGridTemplatesMiddleware(sendGridTemplateApi);
const updateTemplateStatusRequestMiddleware = UpdateTemplateStatusRequestMiddleware(sendGridTemplateApi);
const updateTemplateSenderEmailRequestMiddleware = UpdateTemplateSenderEmailRequestMiddleware(sendGridTemplateApi);
const updateTemplateVariablesMappingRequestMiddleware = UpdateTemplateVariablesMappingRequestMiddleware(
  sendGridTemplateApi,
);
const createVariableMiddleware = CreateVariableMiddleware(variablesApi);
const editVariableMiddleware = EditVariableMiddleware(variablesApi);
const addModuleMiddleware = AddModuleMiddleware(decisionEngineApi);
const updateModuleInfoMiddleware = UpdateModuleInfoMiddleware(decisionEngineApi);
const updateModuleNameMiddleware = UpdateModuleNameMiddleware(decisionEngineApi);
const addBranchMiddleware = AddBranchMiddleware(decisionEngineApi);
const getExternalIntegrationVariablesMiddleware = GetExternalIntegrationVariablesMiddleware(decisionEngineApi);
const createRuleMiddleware = CreateRuleMiddleware(decisionEngineApi);
const deleteRuleMiddleware = DeleteRuleMiddleware(decisionEngineApi);
const createCalculationScriptMiddleware = CreateCalculationScriptMiddleware(decisionEngineApi);
const createSimpleOutputMiddleware = CreateSimpleOutputMiddleware(decisionEngineApi);
const onDeleteDecisionStrategySuccessMiddleware = OnDeleteDecisionStrategySuccessMiddleware(history);
const updateScoringRuleSegmentMiddleware = UpdateScoringRuleSegmentMiddleware(decisionEngineApi);
const getStrategyGroupsDataMiddleware = GetStrategyGroupsDataMiddleware(strategyGroupsApi);
const uploadRuleSetMiddleware = UploadRuleSetMiddleware(decisionEngineApi);
const duplicateRule = DuplicateRule(decisionEngineApi);
const editRuleMiddleware = EditRuleMiddleware(decisionEngineApi);
const editCalculationScriptMiddleware = EditCalculationScriptMiddleware(decisionEngineApi);
const editSimpleOutputMiddleware = EditSimpleOutputMiddleware(decisionEngineApi);
const archiveStrategiesMiddleware = ArchiveStrategiesMiddleware(decisionEngineApi);
const restoreStrategiesMiddleware = RestoreStrategiesMiddleware(decisionEngineApi);

const decisionStrategyModuleReorderMiddleware = DecisionStrategyModuleReorderMiddleware(decisionEngineApi);

const createNewStrategyVersionSuccessMiddleware = CreateNewStrategyVersionSuccessMiddleware(history);
const createStrategyMiddleware = CreateStrategyMiddleware(strategyGroupsApi);
const createStrategySuccessMiddleware = CreateStrategySuccessMiddleware(history);
const duplicateStrategyMiddleware = DuplicateStrategyMiddleware(strategiesApi);
const duplicateStrategySuccessMiddleware = DuplicateStrategySuccessMiddleware(history);
const apiActivationPaginationMiddleware = ApiActivationPaginationMiddleware(strategyGroupsApi);

const getBranchOptionsMiddleware = GetBranchOptionsMiddleware(decisionEngineApi);
const copyModuleBranchRulesMiddleware = CopyModuleBranchRulesMiddleware(decisionEngineApi);
const duplicateModuleMiddleware = DuplicateModuleMiddleware(decisionEngineApi);
const confirmEmailMiddleware = ConfirmEmailMiddleware(emailVerificationApi);
const resendVerificationEmailForExpiredTokenMiddleware = ResendVerificationForExpiredTokenMiddleware(authenticationApi);
const webhookEndpointsPaginationMiddleware = WebhookEndpointsPaginationMiddleware(webhookEndpointsApi);
const webhookEventsPaginationMiddleware = WebhookEventsPaginationMiddleware(webhookEventsApi);

const webhookEndpointsTableUpdatesMiddleware = WebhookEndpointsTableUpdatesMiddleware(history);
const webhookEndpointEventsPaginationMiddleware = WebhookEndpointEventsPaginationMiddleware(webhookEventsApi);

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const AppReduxStore = createStore(
  combinedReducers,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  compose<any>(
    applyMiddleware(
      thunk,
      redirectionsMiddleware,
      LeftNavMiddleware,
      getOrganizationSeatsMiddleware,
      getOrganizationFreeSeatMiddleware,
      getCompanyInformationMiddleware,
      changeCompanyMiddleware,
      OnChangeCompanySuccessMiddleware,
      getUserMiddleware,
      changeUserMiddleware,
      onChangeUserSuccessMiddleware,
      changeUserPhotoMiddleware,
      onChangeUserPhotoSuccessMiddleware,
      addNewCompanyUserMiddleware,
      OnAddNewCompanyUserSuccessMiddleware,
      verifyPasswordMiddlewareForGenerateFirstSecret,
      onGenerateFirstSecretPasswordSuccessMiddleware,
      generateFirstSecretMiddleware,
      onGenerateFirstSecretSuccessMiddleware,
      verifyPasswordMiddlewareForGenerateSecondSecret,
      onGenerateSecondSecretPasswordSuccessMiddleware,
      generateSecondSecretMiddleware,
      onGenerateSecondSecretSuccessMiddleware,
      deleteDecisionStrategyMiddleware,
      onDeleteDecisionStrategySuccessMiddleware,
      editCompanyUserMiddleware,
      OnEditCompanyUserSuccessMiddleware,
      errorNotificationsMiddleware,
      AsyncThunkRejectionNotificationMiddleware,
      getVariablesMiddleware,
      getDecisionStrategyInfo,
      strategyNameMiddleware,
      strategyDescriptionMiddleware,
      onStrategyDescriptionSuccessMiddleware,
      onRenameStrategySuccessMiddleware,
      variablesPaginationMiddleware,
      variablesFilterAndSortingSaver.getMiddleware(),
      strategyGroupsPaginationMiddleware,
      apiActivationPaginationMiddleware,
      searchApiActivationMiddleware,
      sortApiActivationMiddleware,
      apiActivationFilterAndSortingSaver.getMiddleware(),
      versionsPaginationMiddleware,
      versionsFilterAndSortingSaver.getMiddleware(),
      onChangeVariableStatusSuccessMiddleware,
      getSendGridIntegrationMiddleware,
      deleteTemplateRequestMiddleware,
      DeleteTemplateRequestSuccessMiddleware,
      getTemplatesRequestMiddleware,
      templatesPaginationMiddleware,
      SortTemplatesMiddleware,
      getTemplatesToImportRequestMiddleware,
      getSenderEmailsRequestMiddleware,
      saveSendGridIntegrationMiddleware,
      SaveSendGridIntegrationSuccessMiddleware,
      importSendGridTemplatesMiddleware,
      ImportSendGridTemplatesSuccessMiddleware,
      updateTemplateStatusRequestMiddleware,
      updateTemplateSenderEmailRequestMiddleware,
      updateTemplateVariablesMappingRequestMiddleware,
      UpdateTemplateStatusRequestSuccessMiddleware,
      UpdateTemplateVariablesMappingRequestSuccessMiddleware,
      notifyOnSuccessSaveVariableMiddleware,
      onDefaultSaveVariableSuccessMiddleware,
      createVariableMiddleware,
      editVariableMiddleware,
      getDecisionStrategyUpdates,
      onUpdateModuleOrderSuccessMiddleware,
      decisionStrategyUpdatesPaginationMiddleware,
      decisionStrategyUpdatesSortingSaver.getMiddleware(),
      deleteDecisionStrategyModuleMiddleware,
      decisionStrategyModuleReorderMiddleware,
      createNewStrategyVersionMiddleware,
      createNewStrategyVersionSuccessMiddleware,
      variablesSearchMiddleware,
      addModuleMiddleware,
      updateModuleInfoMiddleware,
      updateModuleNameMiddleware,
      onUpdateModuleInfoSuccessMiddleware,
      onUpdateModuleNameSuccessMiddleware,
      addBranchMiddleware,
      onCreateBranchSuccessMiddleware,
      onNewModuleAddedSuccessMiddleware,
      getBranchInfoMiddleware,
      reorderModuleBranchesMiddleware,
      reorderBranchRulesMiddleware,
      duplicateBranchMiddleware,
      onDuplicateBranchSuccessMiddleware,
      updateDecisionStrategyBranchNameMiddleware,
      deleteBranchMiddleware,
      onUpdateBranchNameSuccessMiddleware,
      onDeleteBranchSuccessMiddleware,
      onUpdateRuleOrderSuccessMiddleware,
      createStrategyMiddleware,
      createStrategySuccessMiddleware,
      duplicateStrategyMiddleware,
      duplicateStrategySuccessMiddleware,
      getExternalIntegrationVariablesMiddleware,
      getIntegrationsListMiddleware,
      createRuleMiddleware,
      createCalculationScriptMiddleware,
      createSimpleOutputMiddleware,
      editExternalIntegrationMiddleware,
      onEditExternalIntegrationSuccessMiddleware,
      decisionsFilterAndSortingSaver.getMiddleware(),
      decisionsSavedFiltersSwitchMiddleware,
      ...loanOriginationSystemMiddlewares,
      onCreateRuleSuccessMiddleware,
      onUpdateBranchOrderSuccessMiddleware,
      onDeleteDecisionStrategyModuleSuccessMiddleware,
      updateScoringRuleSegmentMiddleware,
      onUpdateScoringRueSuccessMiddleware,
      getStrategyGroupsDataMiddleware,
      strategyGroupsFilterSaver.getMiddleware(),
      getBranchOptionsMiddleware,
      copyModuleBranchRulesMiddleware,
      duplicateModuleMiddleware,
      uploadRuleSetMiddleware,
      duplicateRule,
      onDuplicateRuleSuccessMiddleware,
      getApiActivationsMiddleware,
      getDropdownGroupedStrategiesMiddleware,
      onUpdateApiActivationsSuccessMiddleware,
      updateApiActivationsMiddleware,
      deleteRuleMiddleware,
      onDeleteRuleSuccessMiddleware,
      editRuleMiddleware,
      onEditRuleSuccessMiddleware,
      editCalculationScriptMiddleware,
      editSimpleOutputMiddleware,
      archiveStrategiesMiddleware,
      restoreStrategiesMiddleware,
      onChangeArchiveStatusSuccessMiddleware,
      onChangeArchiveStatusFailureMiddleware,
      getVersionsViewDataMiddleware,
      downloadApiActivationsMiddleware,
      onDownloadApiActivationsSuccessMiddleware,
      assignOutputVariableMiddleware,
      onAssignOutputVariableSuccessMiddleware,
      uploadCompanyLogoMiddleware,
      updateRegionSettingsMiddleware,
      onChangeLogoSuccessMiddleware,
      onUpdateRegionSettingsSuccessMiddleware,
      getVariableByIdMiddleware,
      setSnackLifeTimeMiddleware,
      ...companySettingsMiddlewares,
      getOrganizationsMiddleware,
      confirmPasswordMiddleware,
      changeUserEmailMiddleware,
      requestPhoneAuthenticationMiddleware,
      enablePhoneAuthenticationMiddleware,
      disablePhoneAuthenticationMiddleware,
      confirmEmailMiddleware,
      resendVerificationEmailForExpiredTokenMiddleware,
      notifyOnSuccessResendVerificationLinkMiddleware,
      decisionsPaginationMiddleware,
      decisionResultsPaginationMiddleware,
      requestDropdownStrategiesMiddleware,
      emailsSavedFiltersSwitchMiddleware,
      tasksSavedFiltersSwitchMiddleware,
      webhookEndpointsPaginationMiddleware,
      webhookEventsPaginationMiddleware,
      changeWebhookEventsFiltersMiddleware,
      webhookEndpointNotificationsMiddleware,
      webhookEventsTableUpdatesMiddleware,
      webhookEndpointEventsTableUpdatesMiddleware,
      webhookEventNotificationsMiddleware,
      webhookEndpointsTableUpdatesMiddleware,
      webhookEndpointEventsPaginationMiddleware,
    ),
    sentryReduxEnhancer,
  ),
);
// @ts-ignore
if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  // @ts-ignore
  module.hot.accept(combinedReducers, () => {
    const nextRootReducer = combinedReducers;
    // @ts-ignore
    AppReduxStore.replaceReducer(nextRootReducer);
  });
}

export { history, decisionEngineApi };

export default AppReduxStore;
