import webhookEndpointEventsPagination from './Pagination';
import { WebhookEventsApi } from 'api/Webhooks/WebhookEventsApi';
import { saveWebhookEvents } from 'WebhookEvents/WebhookEventsStore';

export default (api: WebhookEventsApi) => {
  return webhookEndpointEventsPagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.find({
      count: params.perPage,
      offset: (params.page - 1) * params.perPage,
      search: params.search,
      webhookId: params.webhookId,
    }, params.sortingType);

    dispatch(saveWebhookEvents(items));

    return {
      items: items.map((item) => item.id),
      total,
    };
  });
};
