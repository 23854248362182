import applicationConfig from 'config';

export enum DefaultApplicationFormPage {
  Borrower = 'borrower',
  CoBorrower = 'coBorrower',
  Intermediary = 'intermediary',
  ApplicationDetails = 'applicationDetails',
  DocumentUpload = 'documentUpload',
}

export enum ApplicationFormPageLabel {
  Borrower = 'Borrower Profile',
  CoBorrower = 'Co-Borrower Profile',
  Intermediary = 'Intermediary Profile',
  ApplicationDetails = 'Application Details',
  DocumentUpload = 'Document Upload',
}

export const getCoBorrowerPageTypeByIndex = (index: number) => {
  return index === 0 ? DefaultApplicationFormPage.CoBorrower : `${DefaultApplicationFormPage.CoBorrower}_${index + 1}`;
};

const generateCoBorrowerFormPages = () => {
  return new Array(applicationConfig.coBorrowersCount).fill(null).map((key, index) => {
    return getCoBorrowerPageTypeByIndex(index);
  });
};

export const getCoBorrowerPageIndex = (page: string) => {
  const [, index] = page.split('_');

  return index ? Number(index) - 1 : 0;
};

export const isAlwaysAvailableApplicationFormPage = (formPage: string) => {
  return isBorrowerApplicationFormPage(formPage) || isApplicationDetailsFormPage(formPage);
};

export const isBorrowerApplicationFormPage = (formPage: string) => {
  return formPage === DefaultApplicationFormPage.Borrower;
};

export const isCoBorrowerApplicationFormPage = (formPage: string) => {
  return formPage.startsWith(DefaultApplicationFormPage.CoBorrower);
};

export const isIntermediaryApplicationFormPage = (formPage: string) => {
  return formPage === DefaultApplicationFormPage.Intermediary;
};

export const isApplicationDetailsFormPage = (formPage: string) => {
  return formPage === DefaultApplicationFormPage.ApplicationDetails;
};

export const isDocumentUploadFormPage = (formPage: string) => {
  return formPage === DefaultApplicationFormPage.DocumentUpload;
};

export const getApplicationFormPageLabel = (page: string, ignoreIndex = false) => {
  if (isBorrowerApplicationFormPage(page)) {
    return ApplicationFormPageLabel.Borrower;
  }

  if (isCoBorrowerApplicationFormPage(page)) {
    const index = getCoBorrowerPageIndex(page);

    return index === 0 || ignoreIndex ? ApplicationFormPageLabel.CoBorrower : `Co-Borrower ${index + 1} Profile`;
  }

  if (isIntermediaryApplicationFormPage(page)) {
    return ApplicationFormPageLabel.Intermediary;
  }

  if (isApplicationDetailsFormPage(page)) {
    return ApplicationFormPageLabel.ApplicationDetails;
  }

  if (isDocumentUploadFormPage(page)) {
    return ApplicationFormPageLabel.DocumentUpload;
  }

  return '';
};

export const availableApplicationFormPages = [
  DefaultApplicationFormPage.Borrower,
  ...generateCoBorrowerFormPages(),
  DefaultApplicationFormPage.Intermediary,
  DefaultApplicationFormPage.ApplicationDetails,
  DefaultApplicationFormPage.DocumentUpload,
];
