import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import {
  IntermediariesSortingType,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const INTERMEDIARIES_PER_PAGE_DEFAULT = 20;

export interface IntermediariesPaginationParams {
  search: string;
  sortingType: IntermediariesSortingType;
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  borrowerTypes: string[];
  selectedMembers: UserInfo[];
}

const intermediariesPagination = Pagination<ReduxState, Intermediary, IntermediariesPaginationParams>(
  'loanOriginationSystemIntermediariesState',
  INTERMEDIARIES_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default intermediariesPagination;
