import React, { FC, useMemo } from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import { getFormDataByVariableConfigurations } from 'components/LoanOriginationSystem/EditApplication/utils';
import { getCardsFields } from 'components/LoanOriginationSystem/CardsForm/utils';
import { getBorrowerFullName, formatDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import BorrowerIcon from 'components/LoanOriginationSystem/BorrowerIcon';

export interface ExistingBorrowerProfileProps {
  application: Application;
  personBorrowerProfileCards: BorrowerProfileCard[] | null;
  companyBorrowerProfileCards: BorrowerProfileCard[] | null;
  onEditData: (data: FormLayoutData) => Promise<void>;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const ExistingBorrowerProfile: FC<ExistingBorrowerProfileProps> = ({
  application,
  personBorrowerProfileCards,
  companyBorrowerProfileCards,
  onEditData,
  renderActions,
}) => {
  const borrowerProfileCards = application.borrowerType === BorrowerType.Person ? personBorrowerProfileCards : companyBorrowerProfileCards;

  const borrowerData = useMemo(() => {
    return getFormDataByVariableConfigurations(application, getCardsFields(borrowerProfileCards));
  }, [application, borrowerProfileCards]);

  return (
    <ExistingProfile
      data={borrowerData}
      title={getBorrowerFullName(application.borrowerType, application.variables)}
      titleIcons={<BorrowerIcon borrowerType={application.borrowerType} />}
      cards={borrowerProfileCards}
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
      onEditProfileData={onEditData}
      hideCloseButton
      renderActions={renderActions}
    />
  );
};

export default ExistingBorrowerProfile;
