import { Variable } from 'Variables/VariablesTypes';
import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';
import { ConditionalDisplayRule, ConditionalDisplayRuleBuildParams } from './ConditionalDisplayRuleApi';

export interface BaseVariableConfiguration {
  id: string;
  variable: Variable;
  column: number;
  position: number;
  cardId: string;
  default?: boolean;
  required?: boolean;
  conditionalDisplayRule?: ConditionalDisplayRule | null;
}

export interface BaseCreateVariableConfigurationParams {
  column: number;
  position: number;
  variable: string;
  cardId: string;
}

export interface BaseUpdateVariableConfigurationParams {
  position?: number;
  column?: number;
  cardId?: string;
  required?: boolean;
  conditionalDisplayRule?: ConditionalDisplayRuleBuildParams | null;
}

export interface UpdateVariableConfigurationConditionalDisplayRuleParams {
  id: string;
  conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null;
}

export interface UpdateVariableConfigurationRequiredAttribute {
  id: string;
  required: boolean;
}

export interface BaseVariableConfigurationsApi<
  VariableConfigurationModel extends BaseVariableConfiguration = BaseVariableConfiguration,
  FindParams = void,
  CreateParams extends BaseCreateVariableConfigurationParams = BaseCreateVariableConfigurationParams,
  UpdateParams extends BaseUpdateVariableConfigurationParams = BaseUpdateVariableConfigurationParams,
> {
  getAll(params: FindParams): Promise<VariableConfigurationModel[]>;
  create(params: CreateParams): Promise<VariableConfigurationModel>;
  update(id: string, params: UpdateParams): Promise<VariableConfigurationModel>;
  delete(id: string): Promise<void>;
}

export default abstract class BaseVariableConfigurationsRestApi<
  VariableConfigurationModel extends BaseVariableConfiguration = BaseVariableConfiguration,
  FindParams = void,
  CreateParams extends BaseCreateVariableConfigurationParams = BaseCreateVariableConfigurationParams,
  UpdateParams extends BaseUpdateVariableConfigurationParams = BaseUpdateVariableConfigurationParams,
> extends LoanOriginationSystemApi<VariableConfigurationModel> {
  public getAll(params: FindParams) {
    const urlSearchParams = this.getUrlSearchParamsByFindParams(params);

    return this.getResources<VariableConfigurationModel[]>(urlSearchParams);
  }

  public create(params: CreateParams) {
    return this.createResource({
      variableConfiguration: params,
    });
  }

  public update(id: string, params: UpdateParams) {
    return this.updateResource(id, {
      variableConfiguration: params,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getUrlSearchParamsByFindParams(_params: FindParams) {
    return new URLSearchParams();
  }
}
