import Fetcher from 'middlewares/Fetcher';
import { DropdownStrategy } from 'api/Types';
import StrategyApi from 'api/DecisionEngine/StrategyApi';
import {
  requestDropdownStrategiesFailure,
  requestDropdownStrategiesSuccess,
  RequestDropdownStrategiesSuccessAction
} from 'ApiActivation/ActionCreator';
import { ApiActivationActionType } from 'ApiActivation/ActionTypes';

const RequestDropdownStrategiesMiddleware = (api: StrategyApi) =>
  Fetcher<DropdownStrategy[], RequestDropdownStrategiesSuccessAction>(
    ApiActivationActionType.RequestDropdownStrategies,
    requestDropdownStrategiesSuccess,
    requestDropdownStrategiesFailure,
    () => {
      return api.getDropdownStrategies();
    },
  );

export default RequestDropdownStrategiesMiddleware;
