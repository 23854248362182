import React, { useMemo, useCallback, FocusEvent } from 'react';
import clsx from 'clsx';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import InputWithDataType from 'components/InputWithDataType';
import { isPhoneNumber } from 'components/InputWithDataType/InputWithDataType';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import { convertVariableInput, convertVariableValueToString } from 'components/ConfigurableForm/utils';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { LoaderState } from 'components/LoaderWithState';
import CalculationsFunctionIcon from 'components/LoanOriginationSystem/CalculationsFunctionIcon';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { getInputModeFromVariableConfiguration, getMaskModeBasedOnPermissions } from './utils';
import styles from './ConfigurableFormVariableConfiguration.module.scss';

export interface ConfigurableFormVariableConfigurationProps<VariableConfigurationType extends BaseVariableConfiguration> {
  className?: string;
  variableConfiguration: VariableConfigurationType;
  value: VariableValue;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  required?: boolean;
  tabIndex: number;
  userPermissionGroupId: PermissionGroupId;
  formatDisplayTitle?: (systemName: string, displayName: string) => string;
  onChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  visible?: boolean;
  isCalculated?: boolean;
}

const ConfigurableFormVariableConfiguration = <VariableConfigurationType extends BaseVariableConfiguration>({
  className,
  variableConfiguration,
  value,
  showLoader,
  loaderState,
  required,
  tabIndex,
  userPermissionGroupId,
  formatDisplayTitle,
  onChange,
  onFocus,
  onBlur,
  onLoaderStateReset,
  visible = true,
  isCalculated,
}: ConfigurableFormVariableConfigurationProps<VariableConfigurationType>) => {
  const valueAsString = convertVariableValueToString(value);

  const visualDataTypeWithAttributes = getVisualDataTypeWithAttributes(variableConfiguration.variable);
  const { variable } = variableConfiguration;

  const labelTitle = useMemo(() => {
    return formatDisplayTitle
      ? formatDisplayTitle(variable.systemName, variable.displayName)
      : variable.displayName;
  }, [formatDisplayTitle, variable]);

  const inputMode = useMemo(() => {
    return getInputModeFromVariableConfiguration(variable, userPermissionGroupId);
  }, [variable, userPermissionGroupId]);

  const maskMode = useMemo(() => {
    return getMaskModeBasedOnPermissions(visualDataTypeWithAttributes.dataType, variable, userPermissionGroupId)
  }, [variable, visualDataTypeWithAttributes.dataType, userPermissionGroupId]);

  const options = useMemo(() => {
    return variable.optionsList
      ? variableOptionsListToSelectInputOptions(variable.optionsList)
      : null
  }, [variable.optionsList]);

  const phoneNumberAttributes = isPhoneNumber(visualDataTypeWithAttributes) ? { phoneNumberWithFlag: true } : {};

  const handleChange = useCallback((updatedValue: string) => {
    onChange(variableConfiguration, convertVariableInput(updatedValue, variableConfiguration.variable.dataType));
  }, [onChange, variableConfiguration]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(variableConfiguration, event);
  }, [onFocus, variableConfiguration]);

  const handleBlur = useCallback((event?: FocusEvent<HTMLInputElement>) => {
    onBlur?.(variableConfiguration, event);
  }, [onBlur, variableConfiguration]);

  const handleLoaderStateReset = useCallback(() => {
    onLoaderStateReset?.(variableConfiguration);
  }, [onLoaderStateReset, variableConfiguration]);

  const inputIcon = useMemo(() => {
    if (!isCalculated) {
      return null;
    }

    return (
      <CalculationsFunctionIcon />
    );
  }, [isCalculated]);

  return (
    <div className={clsx(styles.input, !visible && styles.hiddenInput, className)}>
      <InputWithDataType
        {...visualDataTypeWithAttributes}
        {...inputMode}
        {...phoneNumberAttributes}
        {...maskMode}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onLoaderStateReset={handleLoaderStateReset}
        value={valueAsString}
        labelTitle={labelTitle}
        options={options}
        disabled={isCalculated}
        tabIndex={tabIndex}
        required={!!required}
        showLoader={showLoader}
        loaderState={loaderState}
        inputIcon={inputIcon}
        disableResetValueOnError
      />
    </div>
  );
};

export default React.memo(ConfigurableFormVariableConfiguration) as typeof ConfigurableFormVariableConfiguration;
