import React, { useMemo, FC } from 'react';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { getFormDataByVariableConfigurations } from 'components/LoanOriginationSystem/EditApplication/utils';
import { getCardsFields } from 'components/LoanOriginationSystem/CardsForm/utils';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';

export interface ExistingIntermediaryProfileProps {
  application: Application;
  intermediaryProfileCards: Card[] | null;
  onEditData: (data: FormLayoutData) => Promise<void>;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const ExistingIntermediaryProfile: FC<ExistingIntermediaryProfileProps> = ({
  application,
  intermediaryProfileCards,
  onEditData,
  renderActions,
}) => {
  const intermediaryData = useMemo(() => {
    return getFormDataByVariableConfigurations(application, getCardsFields(intermediaryProfileCards));
  }, [application, intermediaryProfileCards]);

  return (
    <ExistingProfile
      data={intermediaryData}
      title={application.variables[IntermediaryDefaultVariable.Name] as string}
      cards={intermediaryProfileCards}
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
      onEditProfileData={onEditData}
      hideCloseButton
      renderActions={renderActions}
    />
  );
};

export default ExistingIntermediaryProfile;
