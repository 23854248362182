import {
  TableViewData,
  FormLayoutData,
  DataFilter,
  VariableValue,
  DataViewSortingType,
  SearchHighlight,
} from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';

export interface Borrower {
  id: string;
  variables: Record<string, VariableValue>;
  type: BorrowerType;
  locked: boolean;
  updatedAt: Date;
  createdAt: Date;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
  highlights?: SearchHighlight[];
}

export interface BaseBorrowerInfo {
  id: string;
  name: string;
}

export interface BorrowersDataFilter extends DataFilter {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  isCompany: boolean;
  isPerson: boolean;
  members: UserInfo[];
}

export interface BorrowerSuggestionFilter {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  personalIdNumber: string;
  companyIdNumber: string;
  dateOfBirth: string;
}

export interface BorrowerApplicationsDataFilter extends DataFilter {
  onlyInProgress?: boolean;
}

export enum BorrowerType {
  Person = 'person',
  Company = 'company',
}

export enum BorrowersSortingField {
  BorrowerName = 'fullName',
  IdNumber = 'idNumber',
  Phone = 'phoneNumber',
  Email = 'email',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  SearchRelevance = 'searchRelevance',
}

export enum BorrowerApiRequestTemplateType {
  CreateBorrower = 'createBorrower',
  UpdateBorrower = 'updateBorrower',
}

enum BorrowerReturnType {
  Standard = 'standard',
  Compact = 'compact',
}

export type BorrowersSortingType = DataViewSortingType<BorrowersSortingField>;

export interface LoanOriginationSystemBorrowersApi {
  getBorrowers(
    filters: BorrowersDataFilter,
    sortingType: BorrowersSortingType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<Borrower>>;
  getCompactBorrowersData(): Promise<BaseBorrowerInfo[]>;
  createBorrower(type: BorrowerType, data: FormLayoutData): Promise<Borrower>;
  deleteBorrower(id: string): void;
  getBorrowerById(id: string): Promise<Borrower>;
  updateBorrower(id: string, data: FormLayoutData): Promise<Borrower>;
  lockBorrower(id: string): Promise<Borrower>;
  unlockBorrower(id: string): Promise<Borrower>;
  updateBorrowerApplications(
    borrowerId: string,
    data: FormLayoutData,
  ): Promise<void>;
  getBorrowersSuggestions(filter: BorrowerSuggestionFilter): Promise<Borrower[]>;
}

export default class LoanOriginationSystemBorrowersRestApi extends LoanOriginationSystemApi<Borrower>
  implements LoanOriginationSystemBorrowersApi {
  protected resourceName = 'borrowers';

  public async getBorrowers(
    filters: BorrowersDataFilter,
    sortingType: BorrowersSortingType,
    abortSignal?: AbortSignal,
  ) {
    const { isCompany, isPerson, dueCreatedDateRange, dueUpdatedDateRange, members } = filters;

    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    members.forEach((member) => params.append('teamMemberIds', member.id));

    if (isCompany && !isPerson) {
      params.append('companyType', 'true');
    }
    if (isPerson && !isCompany) {
      params.append('personType', 'true');
    }
    if (dueCreatedDateRange.from) {
      params.append('dueCreatedDateRangeFrom', formatDate(dueCreatedDateRange.from, DateTimeFormat.ISOString));
    }
    if (dueCreatedDateRange.to) {
      params.append('dueCreatedDateRangeTo', formatDate(dueCreatedDateRange.to, DateTimeFormat.ISOString));
    }
    if (dueUpdatedDateRange.from) {
      params.append('dueUpdatedDateRangeFrom', formatDate(dueUpdatedDateRange.from, DateTimeFormat.ISOString));
    }
    if (dueUpdatedDateRange.to) {
      params.append('dueUpdatedDateRangeTo', formatDate(dueUpdatedDateRange.to, DateTimeFormat.ISOString));
    }
    params.append('dataReturnType', BorrowerReturnType.Standard);

    return this.getResources<TableViewData<Borrower>>(params, abortSignal);
  }

  public async getCompactBorrowersData() {
    const params = new URLSearchParams();
    params.append('dataReturnType', BorrowerReturnType.Compact);

    return this.fetch<BaseBorrowerInfo[]>(`/${this.resourceName}/?${params}`);
  }

  public createBorrower(borrowerType: BorrowerType, data: FormLayoutData) {
    return this.createResource({
      borrower: {
        type: borrowerType,
        variables: data,
      },
    });
  }

  public deleteBorrower(id: string) {
    return this.deleteResource(id);
  }

  public getBorrowerById(id: string) {
    return this.getResourceById(id);
  }

  public updateBorrower(id: string, borrowerData: FormLayoutData) {
    return this.updateResource(id, { borrower: { variables: borrowerData } });
  }

  public updateBorrowerApplications(borrowerId: string, data: FormLayoutData) {
    return this.fetch<void>(`/${this.resourceName}/${borrowerId}/applications`, 'PUT', {
      variables: data,
    });
  }

  public lockBorrower(id: string): Promise<Borrower> {
    return this.updateResource(id, { borrower: { locked: true } });
  }

  public unlockBorrower(id: string) {
    return this.updateResource(id, { borrower: { locked: false } });
  }

  public getBorrowersSuggestions(filter: BorrowerSuggestionFilter) {
    const params = new URLSearchParams();

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        params.set(key, filter[key]);
      }
    });

    return this.fetch<Borrower[]>(`/${this.resourceName}/suggestions/?${params}`);
  }
}
