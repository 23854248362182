import CoreSystemApi from 'api/Core/CoreSystemApi';

export interface EventTypeOption {
  id: string;
  name: string;
  description: string;
}

export interface EventTypeOptionsApi {
  getAll(): Promise<EventTypeOption[]>;
}

export default class EventTypeOptionsRestApi extends CoreSystemApi<EventTypeOption> implements EventTypeOptionsApi {
  protected resourceName = 'event-type-options';

  public async getAll() {
    return this.getResources();
  }
}
