enum PageName {
  General = 'General',
  PlatformUsers = 'Platform Users',
  PermissionGroups = 'Permission Groups',
  DecisionStrategies = 'Decision Strategies',
  DigiFiApiKeys = 'DigiFi API Keys',
  DocuSignIntegration = 'DocuSign Integration',
  Variables = 'Variables',
  Decisions = 'Decisions',
  Deployment = 'Deployment',
  SendGridIntegration = 'SendGrid Integration',
  PlanAndBilling = 'Plan & Billing',
  DigifiWebhooks = 'DigiFi Webhooks',
}

export default PageName;
