import { AnyAction } from 'redux';
import {
  CreateProductCalculationParams,
  ProductCalculation,
} from 'api/LoanOriginationSystem/ProductCalculationsApi';
import { UpdateCalculationParams } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import { ProductCalculationsType } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import GetProductCalculationsError from 'errors/GetProductCalculationsError';

export interface GetProductCalculationsSuccessParams {
  productId: string;
  calculations: ProductCalculation[];
}

export interface GetProductCalculationsAction extends AnyAction {
  type: ProductCalculationsType.GetProductCalculations;
  payload: {
    productId: string;
  };
}

export interface GetProductCalculationsSuccessAction extends AnyAction {
  type: ProductCalculationsType.GetProductCalculationsSuccess;
  payload: GetProductCalculationsSuccessParams;
}

export interface CreateProductCalculationAction extends AnyAction {
  type: ProductCalculationsType.CreateProductCalculation;
  payload: CreateProductCalculationParams;
}

export interface CreateProductCalculationSuccessAction extends AnyAction {
  type: ProductCalculationsType.CreateProductCalculationSuccess;
  payload: {
    calculation: ProductCalculation;
  };
}

export interface UpdateProductCalculationAction extends AnyAction {
  type: ProductCalculationsType.UpdateProductCalculation;
  payload: {
    calculationId: string;
  } & UpdateCalculationParams;
}

export interface UpdateProductCalculationSuccessAction extends AnyAction {
  type: ProductCalculationsType.UpdateProductCalculationSuccess;
  payload: {
    calculation: ProductCalculation;
  };
}

export interface DeleteProductCalculationAction extends AnyAction {
  type: ProductCalculationsType.DeleteProductCalculation;
  payload: {
    calculationId: string;
  };
}

export interface DeleteProductCalculationSuccessAction extends AnyAction {
  type: ProductCalculationsType.DeleteProductCalculationSuccess;
  payload: {
    calculationId: string;
  };
}

export const getProductCalculations = (productId: string): GetProductCalculationsAction => ({
  type: ProductCalculationsType.GetProductCalculations,
  payload: {
    productId,
  },
});

export const getProductCalculationsSuccess = (
  params: GetProductCalculationsSuccessParams,
): GetProductCalculationsSuccessAction => ({
  type: ProductCalculationsType.GetProductCalculationsSuccess,
  payload: params,
});

export const createProductCalculation = (params: CreateProductCalculationParams): CreateProductCalculationAction => ({
  type: ProductCalculationsType.CreateProductCalculation,
  payload: params,
});

export const createProductCalculationSuccess = (
  calculation: ProductCalculation,
): CreateProductCalculationSuccessAction => ({
  type: ProductCalculationsType.CreateProductCalculationSuccess,
  payload: {
    calculation,
  },
});

export const updateProductCalculation = (
  calculationId: string,
  params: UpdateCalculationParams,
): UpdateProductCalculationAction => ({
  type: ProductCalculationsType.UpdateProductCalculation,
  payload: {
    calculationId,
    ...params,
  },
});

export const updateProductCalculationSuccess = (
  calculation: ProductCalculation,
): UpdateProductCalculationSuccessAction => ({
  type: ProductCalculationsType.UpdateProductCalculationSuccess,
  payload: {
    calculation,
  },
});

export const deleteProductCalculation = (calculationId: string): DeleteProductCalculationAction => ({
  type: ProductCalculationsType.DeleteProductCalculation,
  payload: {
    calculationId,
  },
});

export const deleteProductCalculationSuccess = (calculationId: string): DeleteProductCalculationSuccessAction => ({
  type: ProductCalculationsType.DeleteProductCalculationSuccess,
  payload: {
    calculationId,
  },
});

export const getProductCalculationsFailure = FailedActionCreator<GetProductCalculationsError>(
  ProductCalculationsType.GetProductCalculationsFailure,
);

export const createProductCalculationFailure = FailedActionCreator(
  ProductCalculationsType.CreateProductCalculationFailure,
);

export const updateProductCalculationFailure = FailedActionCreator(
  ProductCalculationsType.UpdateProductCalculationFailure,
);

export const deleteProductCalculationFailure = FailedActionCreator(
  ProductCalculationsType.DeleteProductCalculationFailure,
);
