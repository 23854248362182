import { AnyAction, MiddlewareAPI } from 'redux';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import BorrowerHasApplicationsError from 'errors/BorrowerHasApplicationsError';
import getMessage, { MessageType } from 'constants/messages';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';
import { selectBorrowerViewTab } from './ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';

const isDeleteBorrowerFailureAction = (
  action: AnyAction,
): action is FailedAction<LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure> =>
  action.type === LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure;

const NOTIFICATION_TIMEOUT = 7000;

export const notifyOnBorrowerDeleteFailure = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (!isDeleteBorrowerFailureAction(action)) {
    return result;
  }

  const error = action.payload;

  if (error instanceof BorrowerHasApplicationsError) {
    const message = getMessage(MessageType.BorrowerToDeleteHasApplications, {
      borrowerName: error.borrowerName,
    });

    notification.createNotification(
      {
        componentType: NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent,
        props: {
          message,
          url: `/los/borrowers/?edit=${error.borrowerId}`,
          selectApplicationsTabActionCreator: () => selectBorrowerViewTab(BorrowerDetailsTab.Applications),
        },
      },
      'error',
      dispatch,
      NOTIFICATION_TIMEOUT,
    );

    return result;
  }

  notification.createNotification(error.message, 'error', dispatch);

  return result;
};
