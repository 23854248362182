import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { UPDATE_STRATEGY_DESCRIPTION_REQUEST, UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS } from './ActionTypes';
import {
  getDecisionStrategyInfoRequest,
  UpdateStrategyDescriptionRequestAction,
  updateStrategyDescriptionSuccess,
  updateStrategyDescriptionFailure,
  UpdateStrategyDescriptionRequestSuccessAction,
} from './DecisionStrategiesActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { batch } from 'react-redux';
import { DecisionEngineApi } from 'api/Types';

export const StrategyDescriptionMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<string, UpdateStrategyDescriptionRequestAction>(
    UPDATE_STRATEGY_DESCRIPTION_REQUEST,
    updateStrategyDescriptionSuccess,
    updateStrategyDescriptionFailure,
    async ({ payload: strategyInfo }) => {
      await api.updateStrategyDescription(strategyInfo.id, strategyInfo.description);
      return strategyInfo.id;
    },
  );

const isUpdateStrategyDescriptionSuccessAction = (
  action: AnyAction,
): action is UpdateStrategyDescriptionRequestSuccessAction =>
  action.type === UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS;

export const onStrategyDescriptionSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isUpdateStrategyDescriptionSuccessAction(action)) {
    batch(() => {
      const strategyId = action.payload;
      dispatch(getDecisionStrategyInfoRequest(strategyId));
    });
  }
  return result;
};
