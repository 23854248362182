import clsx from 'clsx';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import React, { FC, MouseEvent } from 'react';
import { getFormattedWidth } from 'components/Table/utils';
import styles from './TableBodyCell.module.scss';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';

interface TableBodyCellProps {
  width?: number;
  className?: string;
  noPadding?: boolean;
  lastColumnCell?: boolean;
  overflowed?: boolean;
  pixels?: boolean;
  disableHover?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  asNameCell?: boolean;
  withRightPadding?: boolean;
  error?: boolean;
  overflowContainerClassName?: string;
  dashedTopBorder?: boolean;
}

const TableBodyCell: FC<TableBodyCellProps> = ({
  children,
  className,
  width,
  noPadding = false,
  lastColumnCell = false,
  overflowed = false,
  onClick,
  pixels = false,
  disableHover = false,
  asNameCell,
  error,
  withRightPadding,
  overflowContainerClassName,
  dashedTopBorder,
}) => {
  const renderCellContext = () => {
    const content = children ? <>{children}</> : <TableBodyCellNoContent />;

    if (overflowed) {
      return <OverflowedText className={overflowContainerClassName}>{content}</OverflowedText>;
    }

    return content;
  };

  return (
    <div
      className={clsx(
        styles.tableBodyCell,
        noPadding && styles.noPaddings,
        lastColumnCell && styles.lastColumnCell,
        disableHover ? styles.tableBodyCell : styles.tableBodyCellHover,
        asNameCell && styles.nameCell,
        error && styles.errorCell,
        withRightPadding && styles.withRightPadding,
        dashedTopBorder && styles.dashedTopBorder,
        className,
      )}
      style={{ width: getFormattedWidth(pixels, width) }}
      onClick={onClick}
    >
      {renderCellContext()}
    </div>
  );
};

export default TableBodyCell;
