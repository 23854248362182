import Fetcher from 'middlewares/Fetcher';
import {
  LockBorrowerAction,
  lockBorrowerFailure,
  lockBorrowerSuccess,
} from './ActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';
import {
  Borrower,
  LoanOriginationSystemBorrowersApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const LockBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower, LockBorrowerAction>(
    LoanOriginationSystemBorrowerLockType.LockBorrower,
    lockBorrowerSuccess,
    lockBorrowerFailure,
    (action) => {
      return api.lockBorrower(action.payload.borrowerId);
    },
  );

export default LockBorrowerMiddleware;
