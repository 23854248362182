import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { SortEmailsAction } from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';

const isSortEmailsAction = (action: AnyAction): action is SortEmailsAction =>
  action.type === LoanOriginationSystemEmailsActionTypes.SortEmails;

const SortEmailsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortEmailsAction(action)) {
    const {
      loanOriginationSystemEmails: { filters, sortingType },
    } = getState();

    const paginationParams: EmailsPaginationParams = {
      search: filters.searchInputValue,
      sortingType,
      createdAtDateRange: filters.createdAtRange,
      selectedBorrower: action.payload.borrowerId || filters.selectedBorrower || null,
    };

    dispatch(pagination.setPage(1, paginationParams));
  }

  return result;
}) as Middleware;

export default SortEmailsMiddleware;
