import React, { FC, useEffect, useState } from 'react';
import styles from './DecisionsFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  decisionListFiltersEmptyState,
  fetchDecisionListFilters,
  requestDecisions,
  resetFilters,
  toggleFiltersDecisionsPopUp,
  DecisionsState,
} from 'Decisions/DecisionsStore';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { ReduxState } from 'types/redux';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import { Option } from 'components/SelectInput/SelectInput';
import TagLine from 'components/LoanOriginationSystem/ApplicationLabels/TagLine';
import AutoCompletion from 'components/AutoCompletion';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { isEqual } from 'lodash';

const results = [
  { name: 'Passed', value: DecisionResultType.Passed, color: styles.passedLabelColor },
  { name: 'Failed', value: DecisionResultType.Failed, color: styles.failedLabelColor },
  { name: 'Error', value: DecisionResultType.Error, color: styles.errorLabelColor },
];

const sources = [
  { name: 'API', value: 'Api' },
  { name: 'Web', value: 'Web' },
];

const strategyStatuses = [
  { name: NormalizedStatus.Active, value: 'active', color: styles.passedLabelColor },
  { name: NormalizedStatus.Testing, value: 'testing', color: styles.strategyStatusTesting },
  { name: NormalizedStatus.Inactive, value: 'null', color: styles.strategyStatusInactive },
];

interface DecisionsFilterProps {
  hideStrategyStatusFilter?: boolean;
}

export const renderResultIcon = (result: Option) => <TagLine minimized color={result.color!} name={result.name} />;

const DecisionsFilter: FC<DecisionsFilterProps> = ({ hideStrategyStatusFilter }) => {
  const dispatch = useDispatch();
  const {
    filters,
    filtersData,
    sortingType,
    searchInputValue,
    applicationId,
    filtersLoading,
    selectedMembers,
  } = useSelector<ReduxState, DecisionsState>(
    (state) => state.decisions,
  );
  useEffect(() => {
    dispatch(fetchDecisionListFilters());
  }, []);

  const [fromDate, setFromDate] = useState(filters.decisionDate?.from || null);
  const [toDate, setToDate] = useState(filters.decisionDate?.to || null);
  const [source, setSource] = useState<'Api' | 'Web' | null>(filters.decisionSource);
  const [strategyStatus, setStrategyStatus] = useState<NormalizedStatus | null>(filters.strategyStatus);
  const [strategyName, setStrategyName] = useState(filters.strategyName);

  const sortedStrategyNames = filtersData.strategyNames ? [...filtersData.strategyNames].sort() : [];
  const strategyNamesInputOptions = sortedStrategyNames
    .map((name) => ({
      name,
      value: name,
    }));

  const initialSelectedResults = filters.decisionResults
    ? filters.decisionResults.map((result) => ({
        name: result,
        value: result,
      }))
    : [];

  const [resultSelectProps, selectedResultTypes] = useAutoCompletionMultiSelectProps<Option>(
    results,
    initialSelectedResults,
    (result) => result.value,
    (result) => result.name,
    renderResultIcon,
    renderResultIcon,
  );

  const onSourceChange = (option: Option) => {
    setSource(option.value as 'Api' | 'Web' | null);
  };
  const onStrategyStatusChange = (option: Option) => {
    setStrategyStatus(option.value as NormalizedStatus | null);
  };
  const onStrategyNameChange = (option: Option) => {
    setStrategyName(option.value);
  };
  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersDecisionsPopUp({ isFiltersPopUpVisible: false }));
  };
  const onClearFiltersClick = () => {
    dispatch(resetFilters());
    dispatch(requestDecisions({
      filters: {
        ...decisionListFiltersEmptyState,
        applicationId,
        offset: 0,
        selectedMembers,
        search: searchInputValue,
      },
      sortingType,
    }));
  };
  const getSelectedDecisionResultTypes = () =>
    selectedResultTypes.map((result) => result.value as DecisionResultType);
  const onFiltersApply = () => {
    batch(() => {
      dispatch(requestDecisions({
        filters: {
          ...filters,
          offset: 0,
          decisionResults: getSelectedDecisionResultTypes(),
          decisionSource: source || null,
          strategyStatus: strategyStatus || null,
          strategyName,
          decisionDate: {
            from: fromDate,
            to: toDate,
          },
          selectedMembers,
        },
        sortingType,
      }));
      onFiltersPopupClose();
    });
  };
  const areFiltersChanged = () => {
    const selectedResultTypeNames = getSelectedDecisionResultTypes();
    const sortedDecisionResultTypes = [...filters.decisionResults].sort();
    const resultTypesChanged = filters.decisionResults.length
      ? !isEqual(selectedResultTypeNames.sort(), sortedDecisionResultTypes)
      : !!selectedResultTypeNames.length;
    return (
      (filters.decisionDate ? fromDate !== filters.decisionDate.from : !!fromDate)
      || (filters.decisionDate ? toDate !== filters.decisionDate.to : !!toDate)
      || resultTypesChanged
      || source !== filters.decisionSource
      || (!hideStrategyStatusFilter && strategyStatus !== filters.strategyStatus)
      || strategyName !== filters.strategyName
    );
  };

  const handleOnDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletionMultiselect id="resultsSelect" labelTitle="Decision Result" isLabelTags {...resultSelectProps} />
      <AutoCompletion labelTitle="Decision Source" onChange={onSourceChange} options={sources} value={source || ''} />
      {!hideStrategyStatusFilter && <AutoCompletion
        labelTitle="Strategy Status (Time Of Decision)"
        onChange={onStrategyStatusChange}
        options={strategyStatuses}
        value={strategyStatus || ''}
        isLabelTag
      />}
      <AutoCompletion
        loading={filtersLoading}
        disabled={filtersLoading}
        labelTitle="Strategy Name"
        onChange={onStrategyNameChange}
        options={strategyNamesInputOptions}
        value={strategyName || ''}
      />
      <DateRangePicker from={fromDate} to={toDate} onChange={handleOnDateRangeChange} label="Decision Date" />
      <Button
        disabled={!areFiltersChanged()}
        kind="primary"
        size="form"
        className={styles.applyFilter}
        onClick={onFiltersApply}
      >
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default DecisionsFilter;
