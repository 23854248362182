import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SetShowArchivedAction, ResetFiltersAction, SetUpdatersAction } from './Actions';
import { StrategyGroupActionType } from './ActionTypes';
import { StrategyGroupsState } from './Reducer';

type StrategyGroupsFiltersToSave = Pick<StrategyGroupsState, 'selectedUpdaters' | 'showArchived'>;

const getFiltersToSave = (state: ReduxState) => {
  const { selectedUpdaters, showArchived } = state.strategyGroups;

  return { selectedUpdaters, showArchived };
};

const saveTriggerActions = [
  isActionOfType<SetShowArchivedAction>(StrategyGroupActionType.SetShowArchived),
  isActionOfType<SetUpdatersAction>(StrategyGroupActionType.SetUpdaters),
  isActionOfType<ResetFiltersAction>(StrategyGroupActionType.ResetFilters),
];

const strategyGroupsFilterSaver = FilterAndSortingSaver<StrategyGroupsFiltersToSave>({
  key: 'strategyGroups',
  getFiltersToSave,
  saveTriggerActions,
  resetActionType: StrategyGroupActionType.ClearStrategyGroupsData,
});

export default strategyGroupsFilterSaver;
