import ConfirmPopup from 'components/ConfirmPopup';
import React from 'react';
import formatDisplayId from 'utils/userIdFormat';
import { DuplicateApplicationParams } from 'LoanOriginationSystemApplications/Types';

interface DuplicateApplicationConfirmationPopupProps {
  onConfirmClick: () => void;
  onClose: () => void;
  loading: boolean;
  params: DuplicateApplicationParams;
}

const DuplicateApplicationConfirmationPopup = ({
  onConfirmClick,
  onClose,
  loading,
  params,
}: DuplicateApplicationConfirmationPopupProps) => {
  const formattedDisplayId = formatDisplayId(params.displayId);

  const message = `
    This will create a new application with the same data and documents as the application for
    ${params.borrowerFullName} (Application ${formattedDisplayId}).
  `;

  return (
    <ConfirmPopup
      message={message}
      confirmText="Duplicate Application"
      declineText="No, Go Back"
      title="Duplicate Application"
      onConfirmClick={onConfirmClick}
      onPopupClose={onClose}
      loading={loading}
    />
  );
};

export default DuplicateApplicationConfirmationPopup;
