import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { createWebhookEndpoint, deleteWebhookEndpoint, updateWebhookEndpoint } from './Thunks';

const webhookEndpointNotificationsMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isFulfilled(deleteWebhookEndpoint)(action)) {
    notification.createNotification(getMessage(MessageType.WebhookEndpointDeleted), 'success', dispatch);
  }

  if (isFulfilled(createWebhookEndpoint)(action)) {
    notification.createNotification(getMessage(MessageType.WebhookEndpointCreated), 'success', dispatch);
  }

  if (isFulfilled(updateWebhookEndpoint)(action)) {
    notification.createNotification(getMessage(MessageType.WebhookEndpointUpdated), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default webhookEndpointNotificationsMiddleware;
