enum NavigationLinkId {
  // Loan Origination System
  Applications = 'Applications',
  Borrowers = 'Borrowers',
  Intermediaries = 'Intermediaries',
  Tasks = 'Tasks',
  Reporting = 'Reporting',
  Emails = 'Emails',
  Configuration = 'Configuration',
  Products = 'Products',
  BorrowersConfiguration = 'Borrowers Configuration',
  IntermediariesConfiguration = 'Intermediaries Configuration',
  ProcessAutomation = 'Process Automation',
  ApplicationLabels = 'Application Labels',

  // Decision Engine
  Strategies = 'Decision Strategies',
  Decisions = 'Decisions',
  Deployment = 'Deployment',

  // Company Settings
  General = 'General',
  Team = 'Team',
  Users = 'Platform Users',
  PermissionGroups = 'Permission Groups',
  Variables = 'Variables',
  Developers = 'Developers',
  DigiFiAPIKeys = 'DigiFi API Keys',
  DocuSign = 'DocuSign Integration',
  SendGrid = 'SendGrid Integration',
  PlanAndBilling = 'Plan & Billing',
  Webhooks = 'DigiFi Webhooks',
}

export default NavigationLinkId;
