import { Middleware } from 'redux';
import {
  EmailVerificationLinkExpiredActionTypes,
  ResendEmailVerificationForExpiredTokenAction,
  resendEmailVerificationForExpiredTokenFailure,
  resendEmailVerificationForExpiredTokenSuccess,
} from 'EmailVerificationLinkExpired/Actions';
import Fetcher from 'middlewares/Fetcher';
import { AuthApi } from 'api/Core/AuthApi';

const ResendVerificationForExpiredTokenMiddleware: (api: AuthApi) => Middleware = (api) =>
  Fetcher<void, ResendEmailVerificationForExpiredTokenAction>(
    EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredToken,
    resendEmailVerificationForExpiredTokenSuccess,
    resendEmailVerificationForExpiredTokenFailure,
    async ({ payload }) => {
      await api.resendVerificationEmailForExpiredToken(payload.verificationToken);
    },
  );

export default ResendVerificationForExpiredTokenMiddleware;
