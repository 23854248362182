import { Point } from 'components/Charts/components/Types';
import { ReportingDashboardPeriod } from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import moment from 'moment';
import formatDate from 'utils/dateFormat';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import formatWithPostfix from 'utils/formatWithPostfix';

export const getTitleWithCurrency = (title: string) => {
  return (currencySymbol?: string) => (currencySymbol ? `${title} (${currencySymbol})` : title);
};

export const formatXValue = (value: number) => {
  return formatDate(new Date(value), 'MMM DD, YYYY');
};

export const formatYValue = (value: number) => {
  if (value === 0) {
    return '0';
  }

  const millifiedValue = formatWithPostfix(value);

  return millifiedValue.length > 2 ? millifiedValue : formatWithPostfix(value, 2);
};

export const formatPercentageYValue = (value: number) => {
  const millifiedValue = value === 0 || value === Infinity ? '0' : formatWithPostfix(value * 100);

  return `${millifiedValue}%`;
};

const isLastPoint = (point: Point, period: ReportingDashboardPeriod, endDate: Date) => {
  const date = new Date(point.x);

  if (period === 'Monthly') {
    return date.getFullYear() === endDate.getFullYear() && date.getMonth() === endDate.getMonth();
  }

  if (period === 'Annually') {
    return date.getFullYear() === endDate.getFullYear();
  }

  return false;
};

export const formatTooltipData = (
  period: ReportingDashboardPeriod,
  filterEndDate: Date,
  postfix?: string,
  formatValue = formatMonetaryValue,
) => (point: Point) => {
  const tooltipData = {
    title: formatXValue(point.x),
    subTitle: formatValue(point.y, postfix),
  };

  if (period !== 'Daily') {
    const targetEndDate = isLastPoint(point, period, filterEndDate)
      ? filterEndDate
      : moment(point.x)
          .endOf(period === 'Monthly' ? 'month' : 'year')
          .toDate();

    const endDate = formatXValue(Number(targetEndDate));

    tooltipData.title = `${tooltipData.title} - ${endDate}`;
  }

  return tooltipData;
};

export const formatPercentageTooltipData = (period: ReportingDashboardPeriod, filterEndDate: Date) => {
  return formatTooltipData(period, filterEndDate, '', formatPercentageYValue);
};

export const formatCountTooltipData = (period: ReportingDashboardPeriod, filterEndDate: Date) => {
  return formatTooltipData(period, filterEndDate);
};

export const formatMonetaryTooltipData = (
  period: ReportingDashboardPeriod,
  filterEndDate: Date,
  currencySymbol?: string,
) => {
  return formatTooltipData(period, filterEndDate, currencySymbol);
};
