import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout, { PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import PageContent from 'MainLayout/PageContent';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import Decisions from 'components/Decisions';
import { ReduxState } from 'types/redux';
import {
  closeRunDecisionPopup,
  DecisionsState,
  openRunDecisionPopup,
  requestDecisions,
} from 'Decisions/DecisionsStore';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import DecisionsFilter from 'components/Decisions/DecisionsFilter';
import DecisionView from 'components/DecisionResultsView';
import { useQueryParams } from 'hooks/useQueryParam';
import DeleteDecisionPopUp from 'components/Decisions/PopUps/DeleteDecisionPopUp';
import RunDecisionPopUpContainer from 'components/Decisions/PopUps/RunDecisionPopUpContainer';
import {
  closeDeleteDecisionPopUp,
  deleteDecision,
  DeleteDecisionState,
  openDeleteDecisionPopUp
} from 'Decisions/DeleteDecisionStore';
import { Decision } from 'api/DecisionEngine/DecisionApi';
import { useHistory } from 'react-router';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';
import styles from './Decisions.module.scss';

const leftNav = makeLeftNavigation(NavigationLinkId.Decisions, ApplicationSectionName.DecisionEngine);

const DecisionsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const closeContextualView = useCloseContextualView();
  const organizationMembers = useOrganizationMembers();
  const params = useQueryParams();
  const decisionId = params.get('decision-id');
  const {
    isFiltersPopUpVisible,
    runDecisionPopupOpen,
    sortingType: decisionsSortingType,
    filters: decisionListFilters,
  } = useSelector<ReduxState, DecisionsState>((state) => state.decisions);
  const { decisionIdToDelete } = useSelector<ReduxState, DeleteDecisionState>(
    (state) => state.deleteDecision,
  );

  const handleOpenDeleteDecisionPopUp = (id: string) => {
    dispatch(openDeleteDecisionPopUp({ decisionId: id }));
  };
  const handleCloseDeleteDecisionPopUp = () => {
    dispatch(closeDeleteDecisionPopUp());
  };
  const onCloseContextualView = () => {
    closeContextualView();
  };

  const renderContextualView = () => {
    if (decisionId) {
      return (
        <DecisionView
          decisionId={decisionId}
          onClose={onCloseContextualView}
          getRowLink={getDecisionResultLink}
          onRowClick={handleDecisionResultRowClick}
          onDeleteDecision={handleOpenDeleteDecisionPopUp}
        />
      );
    }
    return null;
  };

  const renderRightSidePopupView = () => isFiltersPopUpVisible && <DecisionsFilter />;
  const handleDeleteDecision = async () => {
    await dispatch(deleteDecision(decisionIdToDelete!));
    if (decisionId) {
      history.push('/decisions');
    }

    dispatch(requestDecisions({
      filters: {
        ...decisionListFilters,
      },
      sortingType: decisionsSortingType,
    }));
  };
  const handleDecisionRan = async (createdDecisionId: string, createdResultsCount: number) => {
    if (createdResultsCount === 1) {
      history.push(`/decisions/${createdDecisionId}`);
      return;
    }

    await dispatch(requestDecisions({
      filters: {
        offset: 0,
        ...decisionListFilters,
      },
      sortingType: decisionsSortingType,
    }));
    history.push(`?decision-id=${createdDecisionId}`);
  }
  const handleOpenRunDecisionPopup = () => {
    dispatch(openRunDecisionPopup());
  }
  const handleCloseRunDecisionPopup = () => {
    dispatch(closeRunDecisionPopup());
  }

  const handleDecisionClick = (decisionData: Decision) => {
    if (decisionData.resultsCount > 1) {
      history.push(`?decision-id=${decisionData.id}`);
      return;
    }

    history.push(`/decisions/${decisionData.id}`);
  };
  const handleDecisionResultRowClick = (result: DecisionResultListItem) => {
    history.push(getDecisionResultLink(result));
  }

  const getDecisionRowLink = (decision: Decision) => {
    return decision.resultsCount > 1 ? `/decisions?decision-id=${decision.id}` : `/decisions/${decision.id}`
  }
  const getDecisionResultLink = (result: DecisionResultListItem) => {
    return `/decisions/${result.decisionId}?result-id=${result.id}`;
  }

  const renderOverlay = () => {
    if (decisionIdToDelete) {
      return (
        <DeleteDecisionPopUp
          closePopUp={handleCloseDeleteDecisionPopUp}
          onDeleteDecision={handleDeleteDecision}
        />
      );
    }
    if (runDecisionPopupOpen) {
      return (
        <RunDecisionPopUpContainer
          popUpType="select"
          onClose={handleCloseRunDecisionPopup}
          onDecisionRan={handleDecisionRan}
        />
      );
    }
    return null;
  };

  return (
    <MainLayout
      leftNav={leftNav}
      contextualView={renderContextualView()}
      rightSidePopupView={renderRightSidePopupView()}
      overlay={renderOverlay()}
      closeContextualView={() => history.push('/decisions')}
    >
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <Decisions
            title="Decisions"
            members={organizationMembers}
            handleOpenDeleteDecisionPopUp={handleOpenDeleteDecisionPopUp}
            openRunDecisionPopup={handleOpenRunDecisionPopup}
            onDecisionLink={handleDecisionClick}
            getRowLink={getDecisionRowLink}
          />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default DecisionsPage;
