import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Body from './Body';
import {
  changeApplicationUpdatingState,
  getProductDataByViewType,
  getProductsList,
  openDeleteApplicationPopup,
  selectProduct,
  setCreateApplicationProductData,
  sortColumnViewProductData,
  sortTableViewProductData,
  toggleCreateNewApplicationPopupOpen,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { ApplicationSortingField, ApplicationsSortingType } from 'api/Types';
import { DuplicateApplicationParams, ViewType } from 'LoanOriginationSystemApplications/Types';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import pagination, { ApplicationsPaginationParams } from './Pagination';
import {
  clearAllFilters,
  filterApplicationsByMembers,
  setSearchInputValue,
  toggleFiltersPopup,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import useStandardVariables from 'hooks/useStandardVariables';
import { useHistory } from 'react-router';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import LandingView from 'components/LandingView/LandingView';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { isAlwaysAvailableApplicationFormPage } from 'ApplicationFormPage';
import { NoResultsIcon } from 'static/images';
import useViewType from 'hooks/useViewType';
import RouteService from 'routes/RouteService';
import Header from './Header';
import openDuplicateApplicationPopupOrShowErrorMessage from 'LoanOriginationSystemApplications/openDuplicateApplicationPopupOrShowErrorMessage';
import {
  ApplicationStatusesActionOrigin,
  getApplicationStatuses,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import areItemsLoading from 'utils/areItemsLoading';
import styles from './ApplicationDashboard.module.scss';

const NO_PRODUCTS_DESCRIPTION =
  'To get started with DigiFi’s Loan Origination System and customize that platform for your business, please add your first product.';

interface ApplicationDashboardProps {
  onApplicationsReorder: (
    applicationId: string,
    statusId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
}

const ApplicationsDashboard = ({ onApplicationsReorder }: ApplicationDashboardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const standardVariables = useStandardVariables();

  const {
    tableViewData,
    columnViewData,
    productsList,
    selectedProduct,
    tableViewSortingType,
    filters,
    columnViewSortingType,
    applicationUpdatingStatesById,
    isColumnViewDataLoading,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications);

  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, selectedProduct?.id)) || [];

  const { user } = useSelector((state: ReduxState) => state.sessionInfo);
  const organizationMembers = useOrganizationMembers();
  const viewType = useViewType();

  useEffect(() => {
    if (!productsList) {
      dispatch(getProductsList());
    }
  }, []);

  useEffect(() => {
    if (
      productsList &&
      productsList.length > 0 &&
      (!selectedProduct || !productsList.some(({ id }) => selectedProduct.id === id))
    ) {
      dispatch(selectProduct(productsList[0].id));
    }
  }, [productsList]);

  useEffect(() => {
    if (selectedProduct && applicationStatuses.length === 0) {
      dispatch(
        setActionOrigin(getApplicationStatuses(selectedProduct.id), ApplicationStatusesActionOrigin.Applications),
      );
    }
  }, [selectedProduct]);

  const onViewTypeChange = (newViewType: ViewType) => {
    history.push(RouteService.getApplicationsUrl(newViewType));

    if (selectedProduct) {
      dispatch(
        getProductDataByViewType(
          selectedProduct?.id,
          newViewType,
          filters,
          newViewType === ViewType.Column ? columnViewSortingType : tableViewSortingType,
        ),
      );
    }
  };

  const onSearch = useCallback(
    (search: string) => {
      dispatch(setSearchInputValue(search, viewType));
    },
    [viewType],
  );

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterApplicationsByMembers(selectedProduct!.id, newSelectedMembers, viewType));
  };

  const onProductSelect = (product: Product) => {
    if (selectedProduct?.id !== product.id) {
      dispatch(selectProduct(product.id));
    }
  };

  const onTableViewSort = useCallback((field: ApplicationSortingField, ascending: boolean) => {
    dispatch(sortTableViewProductData(field, ascending));
  }, []);

  const openFiltersPopup = () => {
    dispatch(toggleFiltersPopup());
  };

  const onCreateNewApplication = () => {
    if (!productsList || productsList.length === 0) {
      return;
    }

    const [product] = productsList;
    const { applicationFormPages } = product.settings;

    const isAdditionalFormPageExists = applicationFormPages.some((page) => !isAlwaysAvailableApplicationFormPage(page));

    if (productsList.length > 1 || isAdditionalFormPageExists) {
      dispatch(toggleCreateNewApplicationPopupOpen());

      return;
    }

    dispatch(
      setCreateApplicationProductData({
        product,
        applicationFormPages: applicationFormPages.filter((page) => isAlwaysAvailableApplicationFormPage(page)),
      }),
    );

    history.push(RouteService.getApplicationsUrl(viewType, true));
  };

  const onColumnViewSortingTypeChange = ({ field, ascending }: ApplicationsSortingType) => {
    dispatch(sortColumnViewProductData(field, ascending));
  };

  const paginationParams = {
    selectedProduct,
    selectedMembers: filters.selectedMembers,
    search: filters.searchInputValue,
    sortingType: tableViewSortingType,
    selectedLabels: filters.selectedLabels,
    createdDateRange: filters.createdDateRange,
    updatedDateRange: filters.updatedDateRange,
    selectedIntermediaries: filters.selectedIntermediaries,
    selectedStatusesIds: filters.selectedStatusesIds,
  } as ApplicationsPaginationParams;

  const paginationItems = pagination.usePaginatedItems(paginationParams);
  const paginationProps = pagination.usePagination(paginationParams);

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters(viewType));
  };

  const onDeleteApplication = useCallback((applicationId: string) => {
    dispatch(openDeleteApplicationPopup(applicationId));
  }, []);

  const handleApplicationUpdatingStateReset = useCallback((applicationId: string) => {
    dispatch(changeApplicationUpdatingState(applicationId, null));
  }, []);

  const onEditApplication = useCallback((displayId: string) => {
    history.push(`/los/applications/${displayId}`);
  }, []);

  const onDuplicateApplication = useCallback((params: DuplicateApplicationParams) => {
    openDuplicateApplicationPopupOrShowErrorMessage(params, dispatch);
  }, []);

  if (productsList?.length === 0) {
    return (
      <LandingView
        title="Welcome to DigiFi’s Loan Origination System!"
        description={NO_PRODUCTS_DESCRIPTION}
        buttonText="New Product"
        onButtonClick={() => history.push('/los/configuration/products?new')}
      />
    );
  }

  const arePopupFiltersChanged = (filtersToCheck = filters) => {
    return (
      (viewType === ViewType.Table && filtersToCheck.selectedStatusesIds.length > 0) ||
      filtersToCheck.selectedLabels.length > 0 ||
      filtersToCheck.selectedIntermediaries.length > 0 ||
      filtersToCheck.createdDateRange.from !== null ||
      filtersToCheck.createdDateRange.to !== null ||
      filtersToCheck.updatedDateRange.from !== null ||
      filtersToCheck.updatedDateRange.to !== null
    );
  };

  const areApplicationsEmpty =
    viewType === ViewType.Column ? columnViewData?.length === 0 : tableViewData?.items.length === 0;

  const areUserOrPopupFiltersChanged = arePopupFiltersChanged() || filters.selectedMembers.length > 0;

  return (
    <>
      <Header
        selectedProduct={selectedProduct}
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        viewType={viewType}
        searchInputValue={filters.searchInputValue}
        productsList={productsList || []}
        subTitleHidden={!areUserOrPopupFiltersChanged && !filters.searchInputValue && areApplicationsEmpty}
        filtersChanged={arePopupFiltersChanged()}
        columnViewSortingType={columnViewSortingType}
        onViewTypeChange={onViewTypeChange}
        onSearch={onSearch}
        onMemberFilterChange={onMemberFilterChange}
        onProductSelect={onProductSelect}
        openFiltersPopup={openFiltersPopup}
        onCreateNewApplication={onCreateNewApplication}
        onColumnViewSortingTypeChange={onColumnViewSortingTypeChange}
        areApplicationsLoading={
          viewType === ViewType.Column ? isColumnViewDataLoading : areItemsLoading(paginationItems)
        }
      />
      {!areUserOrPopupFiltersChanged && !filters.searchInputValue && areApplicationsEmpty && (
        <NoItems
          className={styles.noApplicationsAddedContainer}
          title="No applications have been added yet!"
          buttonMessage="Add New Application"
          buttonClassName={styles.noApplicationsAddedButton}
          titleClassName={styles.noApplicationsAddedTitle}
          onButtonClick={onCreateNewApplication}
        />
      )}
      {filters.searchInputValue && areApplicationsEmpty && (
        <SearchNotFound className={styles.searchNotFound} searchValue={filters.searchInputValue} />
      )}
      {!filters.searchInputValue && areUserOrPopupFiltersChanged && areApplicationsEmpty && (
        <NoItems
          className={styles.noApplicationsFoundContainer}
          title="No Results Found"
          subtitle="Please remove or adjust your filters."
          buttonMessage="Clear Filters"
          icon={<NoResultsIcon />}
          buttonClassName={styles.noApplicationsFoundButton}
          titleClassName={styles.noApplicationsFoundTitle}
          subtitleClassName={styles.noApplicationsFoundSubtitle}
          onButtonClick={handleClearAllFilters}
        />
      )}
      {!areApplicationsEmpty && (
        <Body
          isColumnViewDataLoading={isColumnViewDataLoading}
          applications={columnViewData}
          viewType={viewType}
          onApplicationsReorder={onApplicationsReorder}
          tableViewData={paginationItems}
          paginationProps={paginationProps}
          tableViewSortingType={tableViewSortingType}
          onTableViewSort={onTableViewSort}
          searchInputValue={filters.searchInputValue}
          userPermissionGroupId={user?.permissionGroupId}
          applicationStatuses={applicationStatuses}
          standardVariables={standardVariables}
          deleteDisabled={!user?.isOwner}
          onDeleteApplication={onDeleteApplication}
          applicationUpdatingStatesById={applicationUpdatingStatesById}
          onApplicationUpdatingStateReset={handleApplicationUpdatingStateReset}
          onEditApplication={onEditApplication}
          onDuplicateApplication={onDuplicateApplication}
        />
      )}
    </>
  );
};

export default ApplicationsDashboard;
