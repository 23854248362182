import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoItems from 'components/NoItems';
import { EmptyTableImage } from 'static/images';
import useStandardVariables from 'hooks/useStandardVariables';
import RelatedApplicationsTable from 'components/LoanOriginationSystem/RelatedApplicationsTable';
import { ReduxState } from 'types/redux';
import pagination, {
  IntermediaryApplicationsPaginationParams,
} from 'components/LoanOriginationSystem/IntermediaryDetails/pagination';
import { ApplicationSortingField } from 'api/LoanOriginationSystem/Types';
import styles from './IntermediaryApplications.module.scss';
import {
  getIntermediaryApplications,
  sortIntermediaryApplications,
} from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';

interface IntermediaryApplicationsProps {
  intermediaryId: string;
}

const IntermediaryApplications = ({ intermediaryId }: IntermediaryApplicationsProps) => {
  const dispatch = useDispatch();

  const { applicationsSortingType } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediaryDetails,
  );

  useEffect(() => {
    dispatch(getIntermediaryApplications(intermediaryId, applicationsSortingType));
  }, []);

  const handleSort = (field: ApplicationSortingField, ascending: boolean) => {
    dispatch(sortIntermediaryApplications(intermediaryId, { field, ascending }));
  };

  const params = {
    sortingType: applicationsSortingType,
    intermediaryId,
  } as IntermediaryApplicationsPaginationParams;

  const applications = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);
  const standardVariables = useStandardVariables();

  if (paginationProps.itemsTotal === 0) {
    return (
      <NoItems
        className={styles.noItems}
        title="This intermediary has not applied yet!"
        icon={<EmptyTableImage />}
      />
    );
  }

  return (
    <RelatedApplicationsTable
      className={styles.table}
      standardVariables={standardVariables}
      source={applications}
      paginationProps={paginationProps}
      sortingType={applicationsSortingType}
      onSort={handleSort}
      showBorrowerColumn
    />
  );
};

export default IntermediaryApplications;
