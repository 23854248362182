import { StrategyGroup } from 'api/Types';
import strategyGroupsPagination from 'pages/StrategyGroups/Pagination';
import { ItemsStateWithPagination, NullableItems } from 'pagination';
import { StrategyGroupActionType } from 'StrategyGroupsData/ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import strategyGroupsFilterSaver from './FilterSaver';

export interface StrategyGroupsState {
  items: NullableItems<StrategyGroup>;
  page: number;
  loading: boolean;
  itemsTotal?: number;
  itemsPerPage: number;
  updaters: UserInfo[];
  selectedUpdaters: UserInfo[];
  searchInputValue: string;
  showArchived: boolean;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<StrategyGroup>;
  };
  versionsStatuses: SystemStatus[];
}

const PER_PAGE_DEFAULT = 12;

const initialState: StrategyGroupsState = {
  items: [],
  loading: false,
  page: 1,
  itemsTotal: undefined,
  itemsPerPage: PER_PAGE_DEFAULT,
  updaters: [],
  selectedUpdaters: [],
  searchInputValue: '',
  searched: {},
  showArchived: false,
  versionsStatuses: ['active', 'testing', null],
};

const getInitialStateWithSavedFilters = (): StrategyGroupsState => ({
  ...initialState,
  ...strategyGroupsFilterSaver.getSavedFilters(),
});

const StrategyGroupsReducer = (state = getInitialStateWithSavedFilters(), action: any): StrategyGroupsState => {
  switch (action.type) {
    case StrategyGroupActionType.GetStrategyGroupsData: {
      return {
        ...initialState,
        showArchived: state.showArchived,
        searchInputValue: state.searchInputValue,
        selectedUpdaters: state.selectedUpdaters,
        updaters: state.updaters,
        ...action.payload,
        loading: true,
      };
    }
    case StrategyGroupActionType.GetStrategyGroupsDataSuccess: {
      const { perPage, total, searchInputValue, items, users, selectedUpdaters } = action.payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          loading: false,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal: total,
              page: 1,
              itemsPerPage: PER_PAGE_DEFAULT,
              showArchived: state.showArchived,
              updaters: users,
              selectedUpdaters,
            },
          },
        };
      }
      return {
        ...state,
        loading: false,
        itemsTotal: total,
        itemsPerPage: perPage,
        items,
        updaters: users,
        selectedUpdaters,
      };
    }
    case StrategyGroupActionType.GetStrategyGroupsDataFailure:
    case StrategyGroupActionType.ClearStrategyGroupsData: {
      return getInitialStateWithSavedFilters()
    }
    case StrategyGroupActionType.SetStrategyGroupsSearchInput: {
      return {
        ...state,
        searchInputValue: action.payload,
        page: 1,
        loading: true,
      };
    }
    case StrategyGroupActionType.SetShowArchived: {
      return {
        ...state,
        showArchived: action.payload,
        page: 1,
      };
    }
    case StrategyGroupActionType.SetUpdaters: {
      return {
        ...state,
        selectedUpdaters: action.payload,
        page: 1,
        loading: true,
      };
    }
    case StrategyGroupActionType.ResetFilters: {
      return {
        ...initialState,
        loading: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default strategyGroupsPagination.wrapReducer<StrategyGroupsState>(StrategyGroupsReducer);
