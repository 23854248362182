export interface StringFragment {
  start: number;
  end: number;
  isMatched: boolean;
}

const getSingleWordMatch = (string: string, subString: string): StringFragment[] | null => {
  if (subString.length === 0) {
    return null;
  }

  const matches: StringFragment[] = [];
  let start = 0;

  for (let index = string.indexOf(subString, start); index !== -1; index = string.indexOf(subString, start)) {
    if (index !== start) {
      matches.push({
        start,
        end: index,
        isMatched: false,
      });
    }

    const end = index + subString.length;
    matches.push({
      start: index,
      end,
      isMatched: true,
    });
    start = end;
  }

  if (start !== string.length) {
    matches.push({
      start,
      end: string.length,
      isMatched: false,
    });
  }

  return matches;
};

const getFirstFragment = (index: number, matches: StringFragment[]): StringFragment[] =>
  index === 0 && matches[index].start > 0
    ? [
        {
          start: 0,
          end: matches[index].start,
          isMatched: false,
        },
      ]
    : [];

const getMissingFragment = (
  index: number,
  matches: StringFragment[],
  currentFragment: StringFragment,
): StringFragment[] =>
  matches.length > index + 1 && matches[index + 1].start > currentFragment.end
    ? [
        {
          start: currentFragment.end,
          end: matches[index + 1].start,
          isMatched: false,
        },
      ]
    : [];

const getLastFragment = (index: number, matches: StringFragment[], stringLength: number): StringFragment[] =>
  matches.length === index + 1 && matches[index].end < stringLength
    ? [
        {
          start: matches[index].end,
          end: stringLength,
          isMatched: false,
        },
      ]
    : [];

const combineOverlappingFragments = (result: StringFragment[], fragment: StringFragment) => [
  ...result,
  ...(result.length > 0 && result[result.length - 1].end >= fragment.start
    ? [
        ...(result[result.length - 1].end >= fragment.end
          ? []
          : [
              {
                start: result[result.length - 1].end,
                end: fragment.end,
                isMatched: true,
              },
            ]),
      ]
    : [fragment]),
];

const getWordMatchesFromLongString = (string: string, longString: string): StringFragment[] => {
  const defaultFragment: StringFragment[] = [{ start: 0, end: string.length, isMatched: false }];

  const calculatedFragments = longString
    .split(' ')
    .flatMap((subString) => getSingleWordMatch(string, subString)?.filter((match) => match.isMatched))
    .filter((match): match is StringFragment => typeof match !== 'undefined')
    .sort((matchA, matchB) => matchA.start - matchB.start)
    .reduce(combineOverlappingFragments, [])
    .reduce(
      (result: StringFragment[], fragment, index, matches) => [
        ...result,
        ...getFirstFragment(index, matches),
        fragment,
        ...getMissingFragment(index, matches, fragment),
        ...getLastFragment(index, matches, string.length),
      ],
      [],
    );

  return calculatedFragments.length ? calculatedFragments : defaultFragment;
};

// try to match the whole substring at first and split it into words in case of failure
const getMultiWordMatch = (string: string, longString: string): StringFragment[] | null => {
  if (longString.length === 0) {
    return null;
  }

  const singleWordMatch = getSingleWordMatch(string, longString.trim());

  if (singleWordMatch?.some((match) => match.isMatched)) {
    return singleWordMatch;
  }

  return getWordMatchesFromLongString(string, longString.trim());
};

const stringMatches = (string: string, subString: string, multiWord = false): StringFragment[] | null =>
  multiWord ? getMultiWordMatch(string, subString) : getSingleWordMatch(string, subString);

export default stringMatches;
