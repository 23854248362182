import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import styles from './RunDecisionPop.module.scss';
import AutoCompletion from 'components/AutoCompletion';
import { Tag } from 'components/Tag';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import TextInput from 'components/TextInput';
import UploadFile from 'components/UploadFile';
import Button from 'components/Button';
import { Option } from 'components/SelectInput/SelectInput';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import TransitionComponent from 'components/HeightTransitionComponent';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import {
  changeRunDecisionStrategy,
  requestDropdownStrategies,
  RESET_RUN_DECISION_STATE_ACTION_PREFIX,
  RunDecisionState,
  runMultipleDecisions,
} from 'Decisions/RunDecisionStore';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useStateReset from 'hooks/useStateReset';
import Label from 'components/Label';
import ButtonWithImage from 'components/ButtonWithImage';

const ACCEPT_FORMATS = ['.csv', '.xls', '.xlsx'];

export const statusColors: Map<NormalizedStatus, 'green' | 'gray' | 'blue'> = new Map([
  [NormalizedStatus.Active, 'green'],
  [NormalizedStatus.Inactive, 'gray'],
  [NormalizedStatus.Testing, 'blue'],
]);

interface RunMultipleDecisionsPopUpProps {
  onDecisionRan: (decisionId: string, resultsCount: number) => void;
}

const RunMultipleDecisionPopUp: FC<RunMultipleDecisionsPopUpProps> = ({ onDecisionRan }) => {
  const dispatch = useDispatch();
  const downloadApi = useDownloadApi();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  useStateReset(RESET_RUN_DECISION_STATE_ACTION_PREFIX);

  const [file, setFile] = useState<File | null>(null);
  const [decisionName, setDecisionName] = useState<string | undefined>(undefined);

  const {
    dropdownStrategies,
    currentStrategyId,
    isLoading,
  } = useSelector<ReduxState, RunDecisionState>((state) => state.runDecision);

  useEffect(() => {
    dispatch(requestDropdownStrategies());
  }, []);

  const handleStrategyChange = ({ value }: Option) => dispatch(changeRunDecisionStrategy({ strategyId: value }));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDecisionName(e.target.value);
  };

  const handleDownloadTemplate = () => {
    downloadApi.downloadBatchTemplate(currentStrategyId!);
  };

  const handleClick = async () => {
    if (!file) {
      return;
    }

    const { title: strategyName, status: strategyStatus, version: strategyVersion } = dropdownStrategies.items
      .find(({ id }) => currentStrategyId === id)!;

    const { decisionId, resultsCount } = await dispatchWithUnwrap(runMultipleDecisions({
      strategyName,
      strategyStatus,
      strategyVersion,
      decisionName,
      file,
    }));
    onDecisionRan(decisionId, resultsCount);
  };

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <AutoCompletion
          required
          loading={dropdownStrategies.isLoading}
          disabled={dropdownStrategies.isLoading}
          labelTitle="Strategy Name"
          onChange={handleStrategyChange}
          value={currentStrategyId || ''}
          options={dropdownStrategies.items.map(({ id, name, status }) => ({
            name,
            value: id,
            iconAfter: id !== null && (
              <Tag color={statusColors.get(getNormalizedStatus(status))}>{getNormalizedStatus(status)}</Tag>
            ),
          }))}
        />
        <TextInput labelTitle="Decision Name" value={decisionName} onChange={handleInputChange} placeholder="Optional" />
        <TransitionComponent>
          {currentStrategyId && (
            <div className={styles.inputsContainer}>
              <h4 className={styles.title}>Decision Inputs</h4>
              <div className={styles.labelContainer}>
                <Label required className={styles.dropzoneLabel}>
                  Source Data File
                </Label>
                <ButtonWithImage
                  disabled={!currentStrategyId}
                  title="Download Template"
                  onClick={handleDownloadTemplate}
                  className={styles.downloadTemplateButton}
                />
              </div>
              <UploadFile
                onFileChange={handleFileChange}
                file={file}
                accept={ACCEPT_FORMATS}
                text={
                  <>
                    Drag a batch processing file here or <span className={styles.link}>browse</span> for a file to
                    upload.
                  </>
                }
              />
            </div>
          )}
        </TransitionComponent>
      </div>
      <div className={styles.buttonsContainer}>
        <Button kind="primary" size="form" disabled={!file} onClick={handleClick} isLoading={isLoading}>
          Run Decision
        </Button>
      </div>
    </>
  );
};

export default RunMultipleDecisionPopUp;
