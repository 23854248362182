import React, { FC } from 'react';
import clsx from 'clsx';
import { FormSkeleton, SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonCardsLayout.module.scss';

export interface SkeletonCardsLayoutProps {
  layout: Array<Array<number>>;
  maxColumnsPerCard?: number;
  cardClassName?: string;
}

const SkeletonCardsLayout: FC<SkeletonCardsLayoutProps> = ({ layout, maxColumnsPerCard, cardClassName }) => {
  const renderCard = (fieldsCount: number, index: number) => (
    <div key={index} className={clsx(styles.card, cardClassName)}>
      <SkeletonRectangle width="168px" height="24px" color="primary6" />
      <FormSkeleton columns={maxColumnsPerCard} fieldsCount={fieldsCount} />
    </div>
  );

  const renderRow = (row: Array<number>, rowIndex: number) => (
    <div className={styles.row} key={rowIndex}>
      {row.map((fieldsCount, index) => renderCard(fieldsCount, index))}
    </div>
  );

  return (
    <div className={styles.container}>
      {layout.map((row, index) => renderRow(row, index))}
    </div>
  );
};

export default SkeletonCardsLayout;
