import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { EmailVerificationLinkExpiredActionTypes } from './Actions';

const isResendEmailVerificationForExpiredTokenSuccessAction = (action: AnyAction) =>
  action.type === EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredTokenSuccess;

const NotifyOnSuccessResendVerificationLinkMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isResendEmailVerificationForExpiredTokenSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ResendVerificationForExpiredToken), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default NotifyOnSuccessResendVerificationLinkMiddleware;
