import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { Variable } from 'Variables/VariablesTypes';
import { VariablesAttributesActionTypes } from './ActionTypes';
import { VariablesDataFilter } from 'api/Core/VariablesApi';

export interface LoadVariablesAction {
  type: VariablesAttributesActionTypes.LoadVariables;
  payload: VariablesDataFilter;
}

export interface LoadVariablesSuccessAction {
  type: VariablesAttributesActionTypes.LoadVariablesSuccess;
  payload: Variable[];
}

export const loadVariables = (params: VariablesDataFilter): LoadVariablesAction => ({
  type: VariablesAttributesActionTypes.LoadVariables,
  payload: params,
});

export const loadVariablesSuccess = (payload: Variable[]): LoadVariablesSuccessAction => ({
  type: VariablesAttributesActionTypes.LoadVariablesSuccess,
  payload,
});

export const loadVariablesFailure = FailedActionCreator(VariablesAttributesActionTypes.LoadVariablesFailure);
