import React, { FC } from 'react';
import { ReactComponent as ProductSectionAccessRestrictedIcon } from 'ProductSectionAccessRestricted/ProductSectionAccessRestrictedIcon.svg';
import { ProductSection } from 'ProductSectionAccessPermissions/Types';
import useProductSectionAccessPermissions from 'ProductSectionAccessPermissions/useProductSectionAccessPermissions';
import ProductSectionNames from 'constants/ProductSectionNames';

import styles from 'ProductSectionAccessRestricted/ProductSectionAccessRestrictedStyles.module.scss';

interface SectionAccessRestrictedProps {
  productSection: ProductSection;
}

const ProductSectionAccessRestricted: FC<SectionAccessRestrictedProps> = ({ productSection, children }) => {
  const isSectionAccessible = useProductSectionAccessPermissions(productSection);

  if (isSectionAccessible === undefined) {
    return null;
  }

  if (isSectionAccessible) {
    return <>{children}</>;
  }

  const sectionName = ProductSectionNames[productSection] || 'this product section';

  return (
    <div className={styles.container}>
      <ProductSectionAccessRestrictedIcon className={styles.icon} />
      <caption className={styles.message}>
        You don't have access to this page
      </caption>
      <p className={styles.details}>
        You don’t have the required permissions to access {sectionName}.<br/>
        Please contact your account owner to request access.
      </p>
    </div>
  );
}

export default ProductSectionAccessRestricted;
