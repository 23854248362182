import React, { FC } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';
import { DeleteStrategyWizardConnectedProps } from 'DeleteStrategyWizard/DeleteStrategyWizardConnector';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const TYPE_TO_TITLE_TEXT_MAPPING = {
  strategy: 'Delete Strategy',
  version: 'Delete Version',
};

const DeleteStrategyWizard: FC<DeleteStrategyWizardConnectedProps> = (props) => {
  const { closePopUp, type, isOpen, deleteStrategy, id, name, version, isLoading, isCurrent, actionOrigin } = props;
const {
    items,
  } = useSelector((state: ReduxState) => state.versionsListData);
  if (!isOpen) {
    return null;
  }

  const currentStrategy = items.find(strategy => strategy.title === name);

  const message =
    type === 'strategy'
      ? `Please confirm that you would like to delete all versions of ${currentStrategy && currentStrategy.displayTitle || name}.`
      : `Please confirm that you would like to delete ${currentStrategy && currentStrategy.displayTitle || name} Version ${version}.`;

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={TYPE_TO_TITLE_TEXT_MAPPING[type!]} closable={!isLoading}>
      <DeletePopup
        isLoading={isLoading}
        handleDelete={() => deleteStrategy({ id, name, version }, isCurrent, type!, actionOrigin)}
        message={message}
        handleClose={closePopUp}
        cancelBtnDisabled={isLoading}
      />
    </PopUpWizard>
  );
};

export default DeleteStrategyWizard;
