import React, { FC, ChangeEvent, useState } from 'react';
import { CloseButtonImage } from 'static/images';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import ButtonWithImage from 'components/ButtonWithImage';
import { AddNewUserParams } from 'AddNewCompanyUser/AddNewCompanyUserTypes';
import { validateEmail } from 'utils/validation/validation';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import useLoadingState from 'hooks/useLoadingState';
import { PermissionGroupSelectOption } from 'PermissionGroups/Types';
import styles from './AddNewCompanyUser.module.scss';

interface AddNewCompanyUserProps {
  permissionGroups: PermissionGroupSelectOption[];
  addNewUser: (userInfo: AddNewUserParams) => Promise<void>;
  onClose: () => void;
  onEditPermissionGroupsClick: () => void;
}

const AddNewCompanyUser: FC<AddNewCompanyUserProps> = ({
  permissionGroups,
  addNewUser,
  onClose,
  onEditPermissionGroupsClick,
}) => {
  const [username, setUsername] = useState('');
  const [permissionGroupId, setPermissionGroupId] = useState('');

  const [usernameError, setUsernameError] = useState('');

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsernameError('');
    setUsername(e.target.value);
  };

  const handlePermissionGroupChange = ({ value }: Option) => {
    setPermissionGroupId(value);
  };

  const isUserInfoEmpty = !(username && permissionGroupId);

  const [isAddingInProgress, submitNewUser] = useLoadingState(() => addNewUser({ username, permissionGroupId }));

  const handleAddNewUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const usernameErrorMessage = validateEmail(username);
    if (usernameErrorMessage) {
      setUsernameError(usernameErrorMessage);
    } else {
      submitNewUser();
    }
  };

  const renderBody = () => {
    return (
      <>
        <TextInput
          type="text"
          labelTitle="Email"
          onChange={handleUsernameChange}
          errorMessage={usernameError}
          value={username}
          required
        />
        <AutoCompletion
          labelTitle="Permissions Group"
          onChange={handlePermissionGroupChange}
          value={permissionGroupId}
          options={permissionGroups}
          required
          link={
            <ButtonWithImage
              kind="link"
              title="Edit Permission Groups"
              className={styles.editPermissionGroupsButton}
              onClick={onEditPermissionGroupsClick}
            />
          }
        />
        <Button
          kind="primary"
          size="form"
          onClick={handleAddNewUser}
          disabled={isUserInfoEmpty}
          isLoading={isAddingInProgress}
        >
          Add User
        </Button>
      </>
    );
  };

  return (
    <div className={styles.addNewUser}>
      <div className={styles.addNewUser__header}>
        <h2>Add User</h2>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.addNewUser__content}>{renderBody()}</div>
    </div>
  );
};

export default AddNewCompanyUser;
