import React, { useState } from 'react';
import styles from './CompanyInformation.module.scss';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import CompanyInformationEditForm from './CompanyInformationEditForm/CompanyInformationEditForm';
import ButtonWithImage from 'components/ButtonWithImage';
import CompanyInformationView from './CompanyInformationView';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const CompanyInformation = () => {
  const organizationInfo = useOrganizationDetailedInfo();
  const organizationUsers = useOrganizationMembers();
  const { isLoading } = useSelector((state: ReduxState) => state.organizationInformation);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const closeEditor = () => {
    setIsEditModeEnabled(false);
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Company Information</div>
        {!isEditModeEnabled && (
          <ButtonWithImage
            className={styles.editButton}
            onClick={() => setIsEditModeEnabled(true)}
            title="Edit Details"
            kind="edit"
            disabled={isLoading}
          />
        )}
      </div>
      <div>
        {isEditModeEnabled ? (
          <CompanyInformationEditForm
            organizationInfo={organizationInfo}
            organizationUsers={organizationUsers}
            onCloseEditor={closeEditor}
          />
        ) : (
          <CompanyInformationView organizationInfo={organizationInfo} organizationUsers={organizationUsers} />
        )}
      </div>
    </>
  );
};

export default CompanyInformation;
