import { ApplicationsSortingType, DataFilter, TableViewData, VariableValue, SearchHighlight } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BaseLoanOriginationSystemApplicationsFilters } from 'LoanOriginationSystemApplications/Filters/Types';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { ApplicationStatus, ApplicationStatusPermissions } from './LoanOriginationSystemApplicationStatusesApi';

interface DenormalizedSimplifiedApplication {
  id: string;
  displayId: string;
  variables: Record<string, VariableValue>;
  status: string;
  borrowerId: string;
  coborrowerIds: string[];
  borrowerFullName: string;
  productId: string;
  productName: string;
  coborrowerFullNames: string[];
  intermediaryId?: string;
  teamMembers: string[];
  labels: string[];
  createdAt: Date;
  updatedAt: Date;
  originalApplicationId?: string;
}

export interface SimplifiedApplication {
  id: string;
  displayId: string;
  variables: Record<string, VariableValue>;
  status: ApplicationStatus;
  borrowerId: string;
  coborrowerIds: string[];
  borrowerFullName: string;
  productId: string;
  productName: string;
  coborrowerFullNames: string[];
  intermediaryId?: string;
  teamMembers: {
    id: string;
    firstName: string;
    lastName: string;
    imageId?: string;
  }[];
  labels: BaseLabelInfo[];
  createdAt: Date;
  updatedAt: Date;
  originalApplicationId?: string;
  highlights?: SearchHighlight[];
  declineReasons?: string[];
}

interface BaseUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  imageId?: string;
}

interface BaseApplicationStatusInfo {
  id: string;
  name: string;
  permissionGroupsToEditApplication: ApplicationStatusPermissions;
  permissionGroupsToMoveApplicationIntoStatus: ApplicationStatusPermissions;
  permissionGroupsAbleToViewApplicationOnBoard: ApplicationStatusPermissions;
}

interface IDenormalizedResponseData {
  items: DenormalizedSimplifiedApplication[];
  total: number;
  labels: BaseLabelInfo[];
  statuses: BaseApplicationStatusInfo[];
  users: BaseUserInfo[];
}

interface EntityApplicationsFilter extends DataFilter {
  onlyInProgress?: boolean;
}

export interface SimplifiedApplicationsApi {
  getAll(
    productId?: string,
    filters?: BaseLoanOriginationSystemApplicationsFilters,
    sortingType?: ApplicationsSortingType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<SimplifiedApplication>>;
  getBorrowerApplications(
    id: string,
    filters: EntityApplicationsFilter,
    sortingType?: ApplicationsSortingType,
  ): Promise<TableViewData<SimplifiedApplication>>;
  getIntermediaryApplications(
    id: string,
    filter: EntityApplicationsFilter,
    sortingType?: ApplicationsSortingType,
  ): Promise<TableViewData<SimplifiedApplication>>;
}

export default class SimplifiedApplicationsApiRest extends LoanOriginationSystemApi<SimplifiedApplication>
  implements SimplifiedApplicationsApi {
  protected resourceName = 'simplified-applications';

  public async getAll(
    productId?: string,
    filters?: BaseLoanOriginationSystemApplicationsFilters,
    sortingType?: ApplicationsSortingType,
    abortSignal?: AbortSignal,
  ) {
    const params = this.getPaginationUrlSearchParams(filters?.pagination, sortingType);

    if (productId) {
      params.set('productId', productId);
    }

    if (filters) {
      params.set('search', filters.searchInputValue);
      filters.selectedStatusesIds.forEach((statusId) => params.append('statusIds', statusId));
      filters.selectedLabels.forEach((label) => params.append('labelIds', label.id));
      filters.selectedIntermediaries.forEach((intermediary) => params.append('intermediaryIds', intermediary.id));
      filters.selectedMembers.forEach((member) => params.append('teamMemberIds', member.id));
    }

    if (filters?.createdDateRange.from) {
      params.append('createdAtFrom', filters.createdDateRange.from.toISOString());
    }

    if (filters?.createdDateRange.to) {
      params.append('createdAtTo', filters.createdDateRange.to.toISOString());
    }

    if (filters?.updatedDateRange.from) {
      params.append('updatedAtFrom', filters.updatedDateRange.from.toISOString());
    }

    if (filters?.updatedDateRange.to) {
      params.append('updatedAtTo', filters.updatedDateRange.to.toISOString());
    }

    if (typeof filters?.visibleOnBoard !== 'undefined') {
      params.append('visibleOnBoard', filters.visibleOnBoard.toString());
    }

    params.append('excludeProduct', 'true');

    const denormalizedResponse = await this.getResources<IDenormalizedResponseData>(params, abortSignal);

    return this.normalizeResponse(denormalizedResponse);
  }

  public async getBorrowerApplications(id: string, filters: EntityApplicationsFilter, sortingType?: ApplicationsSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);
    params.append('borrowerId', id);

    if (filters.onlyInProgress) {
      params.append('onlyInProgress', filters.onlyInProgress.toString());
    }

    const denormalizedResponse = await this.getResources<IDenormalizedResponseData>(params);
    return this.normalizeResponse(denormalizedResponse);
  }

  public async getIntermediaryApplications(id: string, filters: EntityApplicationsFilter, sortingType?: ApplicationsSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);
    params.append('intermediaryIds', id);

    if (filters.onlyInProgress) {
      params.append('onlyInProgress', filters.onlyInProgress.toString());
    }

    const denormalizedResponse = await this.getResources<IDenormalizedResponseData>(params);
    return this.normalizeResponse(denormalizedResponse);
  }

  private normalizeResponse(responseData: IDenormalizedResponseData) {
    return {
      total: responseData.total,
      items: responseData.items.map((item) => ({
        ...item,
        status: responseData.statuses[item.status],
        teamMembers: item.teamMembers.map((teamMemberId) => responseData.users[teamMemberId]).filter(Boolean),
        labels: item.labels.map((labelId) => responseData.labels[labelId]),
      })),
    };
  }
}
