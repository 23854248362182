import { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import { VariableValue } from 'api/LoanOriginationSystem/Types';

const BORROWER_SYSTEM_NAME_PREFIX = 'borrower';
const COBORROWER_SYSTEM_NAME_PREFIX = 'coborrower';
const BORROWER_DISPLAY_NAME_PREFIX = 'Borrower';
const COBORROWER_DISPLAY_NAME_PREFIX = 'Coborrower';
const DEFAULT_COBORROWER_INDEX = 0;

export const isBorrowerVariableSystemName = (systemName: string) => {
  return systemName.startsWith(BORROWER_SYSTEM_NAME_PREFIX);
};

export const isCoBorrowerVariableSystemName = (systemName: string) => {
  return systemName.startsWith(COBORROWER_SYSTEM_NAME_PREFIX);
};

export const convertBorrowerSystemNameToCoBorrower = (borrowerSystemName: string, coBorrowerIndex: number = DEFAULT_COBORROWER_INDEX) => {
  return borrowerSystemName.replace(
    BORROWER_SYSTEM_NAME_PREFIX,
    coBorrowerIndex === 0 ? COBORROWER_SYSTEM_NAME_PREFIX : `${COBORROWER_SYSTEM_NAME_PREFIX}_${coBorrowerIndex + 1}`
  );
};

export const convertBorrowerDisplayTitleToCoBorrower = (borrowerDisplayTitle: string, coBorrowerIndex: number = DEFAULT_COBORROWER_INDEX) => {
  return borrowerDisplayTitle.replace(
    new RegExp(BORROWER_DISPLAY_NAME_PREFIX, 'i'),
    coBorrowerIndex === 0 ? COBORROWER_DISPLAY_NAME_PREFIX : `${COBORROWER_DISPLAY_NAME_PREFIX} ${coBorrowerIndex + 1}`,
  );
};

export const convertBorrowerDefaultVariablesToCoBorrower = (borrowerDefaultVariables: BorrowerDefaultVariableType, index = 0) => {
  return Object.keys(borrowerDefaultVariables).reduce((previousCoBorrowerDefaultVariables, key) => ({
    ...previousCoBorrowerDefaultVariables,
    [key]: convertBorrowerSystemNameToCoBorrower(borrowerDefaultVariables[key], index),
  }), {}) as BorrowerDefaultVariableType;
};

export const convertBorrowerVariablesToCoBorrower = (borrowerVariables: Record<string, VariableValue>, index: number) => {
  return Object.keys(borrowerVariables).reduce((previousCoBorrowerVariables, key) => ({
    ...previousCoBorrowerVariables,
    [convertBorrowerSystemNameToCoBorrower(key, index)]: borrowerVariables[key],
  }), {});
};
