import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './Decisions.module.scss';
import useStateReset from 'hooks/useStateReset';
import SearchInput from 'components/SearchInput';
import NoItems from 'components/NoItems';
import SearchNotFound from 'components/SearchNotFound';
import Button from 'components/Button';
import DecisionsTable from './Table';
import { Decision, DecisionsFilter } from 'api/DecisionEngine/DecisionApi';
import {
  DecisionsSortingField,
  DecisionsSortingFieldType,
  DecisionsState,
  requestDecisions,
  resetAllFilters,
  toggleFiltersDecisionsPopUp,
} from 'Decisions/DecisionsStore';
import FilterButton from 'components/FilterButton';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { NoResultsIcon } from 'static/images';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { useDecisionResultApi } from 'providers/ApiServiceProvider';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import useAbortSignal from 'hooks/useAbortSignal';
import { DecisionActionTypes } from 'Decisions/actionTypes';
import ScrollableContainer from 'components/ScrollableContainer';
import clsx from 'clsx';
import decisionsPagination, { DecisionsPaginationParams } from 'pages/Decisions/Pagination';

export interface DecisionsProps {
  members: UserInfo[];
  handleOpenDeleteDecisionPopUp: (decisionId: string) => void;
  openRunDecisionPopup: () => void;
  onDecisionLink: (decision: Decision) => void;
  getRowLink?: (decision: Decision) => string;
  applicationId?: string;
  title?: string;
  titleClass?: string;
  embedded?: boolean;
}

const DEBOUNCE_TIME = 300;

const Decisions: FC<DecisionsProps> = ({
  title,
  titleClass,
  applicationId,
  members,
  handleOpenDeleteDecisionPopUp,
  openRunDecisionPopup,
  onDecisionLink,
  getRowLink,
  embedded,
}) => {
  const dispatch = useDispatch();
  const {
    sortingType,
    searchInputValue,
    selectedMembers,
    filters,
    applicationId: idFromStore,
  } = useSelector<ReduxState, DecisionsState>((state) => state.decisions);
  const paginationParams = {
    ...filters,
    searchInputValue,
    sortingType,
    selectedMembers,
    applicationId: idFromStore,
  } as DecisionsPaginationParams;

  const decisions = decisionsPagination.usePaginatedItems(paginationParams);
  const paginationProps = decisionsPagination.usePagination(paginationParams);

  const [searchValue, setSearchValue] = useState(searchInputValue);

  const fetchDecisions = useAbortSignal((
    abortSignal: AbortSignal,
    params: DecisionsFilter = { ...filters, selectedMembers },
    sortBy: DecisionsSortingFieldType = sortingType,
  ) => {
    dispatch(
      requestDecisions({
        filters: {
          applicationId,
          offset: 0,
          ...params,
        },
        sortingType: sortBy,
        abortSignal,
      }),
    );
  });

  useEffect(() => {
    fetchDecisions();
  }, []);

  const handleSearchInput = (search: string) => {
    fetchDecisions({
      ...filters,
      search,
      offset: 0,
    });
  };
  const onSort = (field: DecisionsSortingField, ascending: boolean) => {
    fetchDecisions({
      ...filters,
    }, { field, ascending });
  };
  const onMemberFilterChange = (teamMembersToSelect: UserInfo[]) => {
    fetchDecisions({
      ...filters,
      selectedMembers: teamMembersToSelect,
      offset: 0,
    });
  };
  const togglePopUpFilters = (isPopUpVisible: boolean) => {
    dispatch(toggleFiltersDecisionsPopUp({ isFiltersPopUpVisible: isPopUpVisible }));
  };
  const areFiltersChanged = () => {
    return (
      !!filters.decisionDate.from ||
      !!filters.decisionDate.to ||
      !!filters.decisionResults.length ||
      !!filters.decisionSource ||
      !!filters.strategyStatus ||
      !!filters.strategyName
    );
  };
  const resetFilters = () => {
    dispatch(resetAllFilters());
    fetchDecisions({
      offset: 0,
    });
  };

  const empty = paginationProps.itemsTotal === 0;

  useStateReset(DecisionActionTypes.ResetDecisionsState);

  const decisionResultApi = useDecisionResultApi();
  const debouncedOnSearchInputChange = useCallback(debounce(handleSearchInput, DEBOUNCE_TIME), [filters]);

  const handleSearchInputChange = useCallback((value: string) => {
    setSearchValue(value);
    debouncedOnSearchInputChange(value);
  }, [filters]);
  const onSelectedUpdatersChange = (selectedUpdatersList: UserInfo[]) => {
    onMemberFilterChange(selectedUpdatersList);
  };
  const renderFilters = () => {
    if (empty && !searchInputValue && !selectedMembers.length && !areFiltersChanged()) {
      return null;
    }

    return (
      <div className={clsx(styles.filterContainer, embedded && styles.filterContainer__embedded)}>
        <SearchInput
          placeholder="Search"
          containerClassName={styles.decisionsSearchContainer}
          value={searchValue}
          onChange={({ target }) => handleSearchInputChange(target.value)}
          onClear={() => handleSearchInputChange('')}
        />
        <UserFilter
          users={members}
          selectedUsers={selectedMembers}
          onChange={onSelectedUpdatersChange}
          className={styles.userFilter}
        />
        <FilterButton
          onClick={() => togglePopUpFilters(true)}
          active={areFiltersChanged()}
          containerClassName={styles.filterButton}
        />
      </div>
    );
  };

  const handleDecisionDownload = async (decision: Decision) => {
    const response = await decisionResultApi.downloadMany(decision.id);
    downloadBlobFile(response);
  }

  const renderEmptyState = () => {
    if (!searchInputValue && !areFiltersChanged() && !selectedMembers.length) {
      return (
        <NoItems
          title="No decisions have been run yet!"
          className={clsx(styles.noData, embedded && styles.noData__embedded)}
          titleClassName={styles.noDataTitle}
          buttonMessage="Run Decision"
          onButtonClick={openRunDecisionPopup}
          footer={!embedded}
        />
      );
    }

    if (searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} footer className={styles.notFound} />;
    }

    return <NoItems
      title="No Results Found"
      icon={<NoResultsIcon />}
      buttonMessage="Clear filters"
      subtitle="Please remove or adjust your filters."
      onButtonClick={resetFilters}
      className={clsx(styles.noData, embedded && styles.noData__embedded)}
      footer={!embedded}
    />;
  };

  const renderContent = () => {
    if (embedded) {
      return renderTable();
    }

    return (
      <ScrollableContainer>{renderTable()}</ScrollableContainer>
    );
  };

  const renderTable = () => (
    <DecisionsTable
      decisions={decisions}
      paginationProps={paginationProps}
      searchInputValue={searchValue}
      sortableFieldKeys={Object.values(DecisionsSortingField)}
      onSort={onSort}
      sortingType={sortingType}
      handleRowClick={onDecisionLink}
      getRowLink={getRowLink}
      getRowActions={(decision: Decision) => [
        {
          title: 'View Decision',
          handler: () => onDecisionLink(decision),
          disabled: decision.resultsCount === 0,
        },
        {
          title: 'Download Decision',
          handler: () => handleDecisionDownload(decision),
          separatorRequired: true,
          disabled: decision.resultsCount === 0,
        },
        {
          title: 'Delete Decision',
          handler: () => handleOpenDeleteDecisionPopUp(decision.id),
          danger: true,
        },
      ]}
      embedded={embedded}
    />
);

  return (
    <>
      <div className={clsx(styles.decisionsHeader, embedded && styles.decisionsHeader__embedded)}>
        <h2 className={titleClass || styles.headerTitle}>{title}</h2>
        <Button
          kind="primary"
          onClick={openRunDecisionPopup}
          className={styles.runDecisionButton}
        >
          Run Decision
        </Button>
      </div>
      {renderFilters()}
      {empty ? renderEmptyState() : renderContent()}
    </>
  );
};

export default Decisions;
