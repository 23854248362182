import React, { useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ApplicationVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import ViewLayoutConfiguration from 'components/LoanOriginationSystem/ViewLayoutConfiguration';
import { getApplicationDetailsCardsSelector } from 'ApplicationDetailsCards/Selectors';
import {
  createApplicationDetailsCard,
  deleteApplicationDetailsCard,
  getApplicationDetailsCards,
  updateApplicationDetailsCard,
} from 'ApplicationDetailsCards/Thunks';
import useProductCalculations from 'hooks/useProductCalculations';
import { Variable } from 'Variables/VariablesTypes';
import {
  createApplicationVariableConfiguration,
  deleteApplicationVariableConfiguration,
  updateConditionalDisplayRule,
  updateApplicationVariableConfigurationPosition,
  updateApplicationVariableConfigurationRequiredAttribute,
} from 'ApplicationVariableConfigurations/Thunks';
import styles from './ApplicationDetailsConfigurationPage.module.scss';

export interface ApplicationDetailsConfigurationPageProps {
  productId?: string;
}

const ApplicationDetailsConfigurationPage: FC<ApplicationDetailsConfigurationPageProps> = ({ productId }) => {
  const dispatch = useDispatch();

  const productCalculations = useProductCalculations(productId || null);

  const applicationDetailsCards = useSelector((state: ReduxState) => {
    return productId ? getApplicationDetailsCardsSelector(state, productId) : null;
  });

  useEffect(() => {
    if (productId) {
      dispatch(getApplicationDetailsCards(productId));
    }
  }, [productId]);

  const handleCreateCard = useCallback(async (position: number, row: number) => {
    if (!productId) {
      return;
    }

    await dispatch(createApplicationDetailsCard({
      position,
      row,
      name: 'New Card',
      productId,
    }));
  }, [productId]);

  const handleUpdateCardName = useCallback(async (cardId: string, name: string) => {
    await dispatch(updateApplicationDetailsCard({
      id: cardId,
      name,
    }));
  }, []);

  const handleDeleteCard = useCallback(async (cardId: string) => {
    await dispatch(deleteApplicationDetailsCard(cardId));
  }, []);

  const handleCreateVariableConfiguration = useCallback(async (
    cardId: string,
    column: number,
    position: number,
    variable: Variable,
  ) => {
    if (!productId) {
      return;
    }

    await dispatch(createApplicationVariableConfiguration({
      cardId,
      column,
      position,
      variable: variable.id,
      productId,
    }));
  }, [productId]);

  const handleVariableConfigurationDelete = useCallback(async (variableConfiguration: ApplicationVariableConfiguration) => {
    await dispatch(deleteApplicationVariableConfiguration(variableConfiguration.id));
  }, []);

  const handleUpdateVariableConfigurationPosition = useCallback(async (
    variableConfiguration: ApplicationVariableConfiguration,
    targetCardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => {
    await dispatch(updateApplicationVariableConfigurationPosition({
      id: variableConfiguration.id,
      position,
      column,
      sourceCardId,
      cardId: targetCardId,
    }));
  }, []);

  const handleChangeVariableConfigurationRequiredAttribute = useCallback((variableConfiguration: ApplicationVariableConfiguration, required: boolean) => {
    dispatch(updateApplicationVariableConfigurationRequiredAttribute({
      id: variableConfiguration.id,
      required,
    }));
  }, []);

  const handleEditVariableConfigurationDisplayRule = useCallback(
    async ({ id }: ApplicationVariableConfiguration, conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null) => {
      await dispatch(updateConditionalDisplayRule({
        id,
        conditionalDisplayRule,
      }))
    },
    [],
  );

  return (
    <ViewLayoutConfiguration
      className={styles.layout}
      title="Application Details"
      cards={applicationDetailsCards}
      onCardAdd={handleCreateCard}
      onCardNameUpdate={handleUpdateCardName}
      onCardDelete={handleDeleteCard}
      onVariableConfigurationCreate={handleCreateVariableConfiguration}
      onVariableConfigurationDelete={handleVariableConfigurationDelete}
      onVariableConfigurationPositionUpdate={handleUpdateVariableConfigurationPosition}
      onEditVariableConfigurationConditionalDisplayRule={handleEditVariableConfigurationDisplayRule}
      onChangeVariableConfigurationRequiredAttribute={handleChangeVariableConfigurationRequiredAttribute}
      allowStandardVariables
      calculations={productCalculations}
    />
  );
};

export default ApplicationDetailsConfigurationPage;
