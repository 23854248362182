import React, { FC, useEffect } from 'react';
import RunDecisionForm from 'components/RunDecisionForm';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { VariableValueMap } from 'api/Types';
import {
  changeRunDecisionStrategy,
  requestDropdownStrategies,
  RESET_RUN_DECISION_STATE_ACTION_PREFIX,
  RunDecisionState,
  runIndividualDecision
} from 'Decisions/RunDecisionStore';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useStateReset from 'hooks/useStateReset';

interface RunSingleDecisionPopUpProps {
  onDecisionRan: (decisionId: string, resultsCount: number) => void;
}

const RunSingleDecisionPopUp: FC<RunSingleDecisionPopUpProps> = ({ onDecisionRan }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  useStateReset(RESET_RUN_DECISION_STATE_ACTION_PREFIX);

  const {
    dropdownStrategies,
    currentStrategyId,
    inputs,
    isLoading,
    inputValues,
  } = useSelector<ReduxState, RunDecisionState>((state) => state.runDecision);

  useEffect(() => {
    dispatch(requestDropdownStrategies());
    if (currentStrategyId) {
      dispatch(changeRunDecisionStrategy({ strategyId: currentStrategyId }));
    }
  }, []);

  const handleRunDecision = async (inputsList: VariableValueMap) => {
    const { title: strategyName, status: strategyStatus, version: strategyVersion } = dropdownStrategies.items
      .find(({ id }) => currentStrategyId === id)!;
    const { decisionName, ...inputsExcludingName } = inputsList;
    const { decisionId, resultsCount } = await dispatchWithUnwrap(runIndividualDecision({
      strategyName,
      strategyStatus,
      strategyVersion,
      decisionName: decisionName as string,
      inputs: inputsExcludingName,
    }));
    onDecisionRan(decisionId, resultsCount);
  }

  const handleStrategyChange = (strategyId: string) => dispatch(changeRunDecisionStrategy({ strategyId }));

  return (
    <RunDecisionForm
      strategies={dropdownStrategies.items}
      currentStrategy={currentStrategyId}
      strategyInputs={inputs.items}
      onRunDecision={handleRunDecision}
      onStrategyChange={handleStrategyChange}
      inputsLoading={inputs.isLoading}
      decisionRunInProgress={isLoading}
      initialInputValues={inputValues}
      dataLoading={inputs.isLoading || dropdownStrategies.isLoading}
    />
  );
};

export default RunSingleDecisionPopUp;
