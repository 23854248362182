import { Strategy, StrategyModule, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import DecisionEngineSystemApi from 'api/DecisionEngine/DecisionEngineSystemApi';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import formattedStrategyModules from 'api/utils/formattedStrategyModules';
import { DecisionStrategy } from 'api/DecisionEngineStrategiesType';
import { DropdownStrategy } from 'api/Types';

export interface IFindParams {
  status?: SystemStatus;
}

export interface IStrategiesApi {
  findById(id: string): Promise<Strategy>;
  createNewVersion(id: string, name: string): Promise<Strategy>;
  copy(id: string): Promise<Strategy>;
  findDropdownGroupedStrategies(groupTitle: string): Promise<DropdownStrategy[]>;
  getDropdownStrategies(findParams: IFindParams): Promise<DropdownStrategy[]>;
}

class StrategyApi extends DecisionEngineSystemApi<Strategy> implements IStrategiesApi {
  protected resourceName = 'strategies';

  public async findById(id: string): Promise<Strategy> {
    const strategy = await this.getResourceById<DecisionStrategy>(id);
    const status = getNormalizedStatus((strategy.status as unknown) as SystemStatus);
    const moduleList: StrategyModule[] = formattedStrategyModules(strategy);

    return {
      ...strategy,
      id: strategy.id,
      name: strategy.name,
      version: strategy.version,
      isLocked: strategy.isLocked,
      versionsCount: strategy.versionsCount,
      updated: strategy.updatedAt,
      editorFirstName: strategy.updatedBy?.firstName,
      editorLastName: strategy.updatedBy?.lastName,
      editorImageId: strategy.updatedBy?.imageId,
      description: strategy.description,
      latestVersion: strategy.latestVersion,
      status,
      moduleList,
    };
  }

  public createNewVersion(id: string, name: string): Promise<Strategy> {
    return this.fetch<Strategy>(`/${this.resourceName}/${id}`, 'POST', { name });
  }

  public copy(id: string): Promise<Strategy> {
    return this.fetch<Strategy>(`/${this.resourceName}/${id}`, 'POST', {});
  }

  public delete(id: string): Promise<Strategy> {
    return this.fetch<Strategy>(`/${this.resourceName}/${id}`, 'DELETE', {});
  }

  public async findDropdownGroupedStrategies(groupTitle: string) {
    return this.fetch<DropdownStrategy[]>(`/${this.resourceName}/dropdown/${groupTitle}`);
  }

  public getDropdownStrategies(findParams: IFindParams = {}): Promise<DropdownStrategy[]> {
    const params = new URLSearchParams();
    if (findParams.status) {
      params.set('status', findParams.status || 'null');
    }

    return this.fetch<DropdownStrategy[]>(`/${this.resourceName}/dropdown?${params.toString()}`, 'GET');
  }
}

export default StrategyApi;
