import { ProductConfigurationDocumentFolder } from 'api/Types';
import { ProductConfigurationDocumentFoldersSortingType } from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';

export enum ProductConfigurationDocumentFoldersPopUpType {
  Create = 'create',
  Rename = 'rename',
  Delete = 'delete',
}

export interface ProductConfigurationDocumentFoldersState {
  foldersById: Record<string, ProductConfigurationDocumentFolder>;
  foldersIdsByProductId: Record<string, string[]>;
  parentFolderId: null | string;
  folderToUpdateId: null | string;
  isPopupOpen: boolean;
  isUpdatePending: boolean;
  popUpType: ProductConfigurationDocumentFoldersPopUpType;
  sortingType: ProductConfigurationDocumentFoldersSortingType;
}
