import { ApplicationSortingField } from 'api/Types';

export interface ColumnViewSortOptions {
  id: string;
  text: string;
  tooltip?: string;
  field: ApplicationSortingField;
  ascending: boolean;
}

export const COLUMN_VIEW_SORT_OPTIONS: ColumnViewSortOptions[] = [
  { id: '1', text: 'Last Updated: New to Old', field: ApplicationSortingField.UpdatedAt, ascending: false },
  { id: '2', text: 'Last Updated: Old to New', field: ApplicationSortingField.UpdatedAt, ascending: true },
  { id: '3', text: 'Application Date: New to Old', field: ApplicationSortingField.CreatedAt, ascending: false },
  { id: '4', text: 'Application Date: Old to New', field: ApplicationSortingField.CreatedAt, ascending: true },
  { id: '5', text: 'Loan Amount: High to Low', field: ApplicationSortingField.LoanAmount, ascending: false },
  { id: '6', text: 'Loan Amount: Low to High', field: ApplicationSortingField.LoanAmount, ascending: true },
  {
    id: '7',
    text: 'Search Relevance',
    field: ApplicationSortingField.SearchRelevance,
    ascending: false,
    tooltip: 'Only eligible once search is applied',
  },
];
