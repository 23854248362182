import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  isPendingActionWithPrefix,
  isFulfilledActionWithPrefix,
  isRejectedActionWithPrefix,
} from 'utils/actions/ActionWithPrefix';
import withStateReset from 'utils/reducers/withStateReset';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { ApplicationDocumentsSortingField } from 'api/LoanOriginationSystem/DocumentsApi';
import {
  getApplicationDocuments,
  uploadApplicationDocument,
  createDocumentFolder,
  updateApplicationDocument,
  deleteApplicationDocument,
  openApplicationDocumentPopup,
  closeApplicationDocumentPopup,
  setDocumentToUpdate,
  sortApplicationDocuments,
  setParentFolder,
  setSearchValue,
  removeDownloadedDocument,
  downloadApplicationDocument,
  downloadArchiveOfApplicationDocuments,
} from './ActionCreator';
import { ApplicationDocumentsState, ApplicationDocumentPopUpType } from './Types';
import { ApplicationDocumentsActionType, ApplicationDocumentsActionTypesPrefix } from './ActionTypes';
import applicationDocumentsSortingSaver from './SortingSaver';

const getInitialState = (): ApplicationDocumentsState => ({
  search: '',
  documentsById: {},
  documentsIdsByApplicationId: {},
  parentFolderId: null,
  sortingType: applicationDocumentsSortingSaver.getSavedSorting() || {
    field: ApplicationDocumentsSortingField.UpdatedAt,
    ascending: false,
  },
  downloadableDocuments: {},
  documentToUpdateId: null,
  isPopupOpen: false,
  isGetDocumentsPending: false,
  isUpdatePending: false,
  popUpType: ApplicationDocumentPopUpType.UploadDocument,
});

const isPendingApplicationDocumentsAction = isPendingActionWithPrefix(ApplicationDocumentsActionTypesPrefix, [
  getApplicationDocuments.typePrefix,
  downloadApplicationDocument.typePrefix,
  downloadArchiveOfApplicationDocuments.typePrefix,
]);
const isFulfilledApplicationDocumentsAction = isFulfilledActionWithPrefix(ApplicationDocumentsActionTypesPrefix, [
  getApplicationDocuments.typePrefix,
  downloadApplicationDocument.typePrefix,
  downloadArchiveOfApplicationDocuments.typePrefix,
]);
const isRejectedApplicationDocumentsAction = isRejectedActionWithPrefix(ApplicationDocumentsActionTypesPrefix, [
  getApplicationDocuments.typePrefix,
  downloadApplicationDocument.typePrefix,
  downloadArchiveOfApplicationDocuments.typePrefix,
]);

const applicationDocumentsReducer = createReducer<ApplicationDocumentsState>(getInitialState(), (builder) => {
  builder
    .addCase(getApplicationDocuments.pending, (state) => {
      state.isGetDocumentsPending = true;
    })
    .addCase(getApplicationDocuments.fulfilled, (state, action) => {
      state.isGetDocumentsPending = false;
      state.documentsById = { ...state.documentsById, ...normalizeEntityArray(action.payload) };
      state.documentsIdsByApplicationId[action.meta.arg] = action.payload.map((document) => document.id);
    })
    .addCase(getApplicationDocuments.rejected, (state) => {
      state.isGetDocumentsPending = false;
    })
    .addCase(updateApplicationDocument.fulfilled, (state, action) => {
      state.documentsById[action.payload.id] = action.payload;
    })
    .addCase(deleteApplicationDocument.fulfilled, (state, action) => {
      const deletedDocument = state.documentsById[action.meta.arg];

      state.documentsIdsByApplicationId[deletedDocument.applicationId] = (
        state.documentsIdsByApplicationId[deletedDocument.applicationId] || []
      ).filter((documentId) => documentId !== deletedDocument.id);
    })
    .addCase(openApplicationDocumentPopup, (state, action) => {
      state.isPopupOpen = true;
      state.popUpType = action.payload;
    })
    .addCase(closeApplicationDocumentPopup, (state) => {
      state.isPopupOpen = false;
    })
    .addCase(setDocumentToUpdate, (state, action) => {
      state.documentToUpdateId = action.payload;
    })
    .addCase(setParentFolder, (state, action) => {
      state.parentFolderId = action.payload;
    })
    .addCase(sortApplicationDocuments, (state, action) => {
      state.sortingType = action.payload;
    })
    .addCase(setSearchValue, (state, action) => {
      state.search = action.payload;
    })
    .addCase(removeDownloadedDocument, (state, action) => {
      const updatedDocumentList = state.downloadableDocuments;
      delete updatedDocumentList[action.payload];

      state.downloadableDocuments = updatedDocumentList;
    })
    .addMatcher(isAnyOf(uploadApplicationDocument.fulfilled, createDocumentFolder.fulfilled), (state, action) => {
      state.documentsById[action.payload.id] = action.payload;
      state.documentsIdsByApplicationId[action.payload.applicationId].push(action.payload.id);
    })
    .addMatcher(
      isAnyOf(downloadApplicationDocument.pending, downloadArchiveOfApplicationDocuments.pending),
      (state, action) => {
        state.downloadableDocuments[action.meta.arg.documentDownloadingId] = 'loading';
      },
    )
    .addMatcher(
      isAnyOf(downloadApplicationDocument.fulfilled, downloadArchiveOfApplicationDocuments.fulfilled),
      (state, action) => {
        state.downloadableDocuments[action.meta.arg.documentDownloadingId] = 'success';
      },
    )
    .addMatcher(
      isAnyOf(downloadApplicationDocument.rejected, downloadArchiveOfApplicationDocuments.rejected),
      (state, action) => {
        state.downloadableDocuments[action.meta.arg.documentDownloadingId] = 'failure';
      },
    )
    .addMatcher(isPendingApplicationDocumentsAction, (state) => {
      state.isUpdatePending = true;
    })
    .addMatcher(
      (action) => isFulfilledApplicationDocumentsAction(action) || isRejectedApplicationDocumentsAction(action),
      (state) => {
        state.isUpdatePending = false;
      },
    );
});

export default withStateReset(
  applicationDocumentsReducer,
  ApplicationDocumentsActionType.ResetState,
  (state, action) => ({
    ...getInitialState(),
    ...(action.payload
      ? {
          parentFolderId: state.parentFolderId,
        }
      : {}),
  }),
);
