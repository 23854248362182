import Fetcher from 'middlewares/Fetcher';

import { Middleware, MiddlewareAPI, AnyAction } from 'redux';
import { DecisionEngineApi } from 'api/Types';
import notification from 'handlers/notification/notificationActionCreator';
import { batch } from 'react-redux';
import {
  CREATE_RULE,
  CREATE_RULE_SUCCESS,
  CREATE_CALCULATION_SCRIPT,
  CREATE_SIMPLE_OUTPUT,
  CREATE_SIMPLE_OUTPUT_SUCCESS,
  CREATE_CALCULATION_SCRIPT_SUCCESS,
  UPLOAD_RULE_SET_SUCCESS,
  UPLOAD_RULE_SET,
  DUPLICATE_RULE_SUCCESS,
  DUPLICATE_RULE,
} from './ActionType';
import {
  createRuleSuccess,
  createRuleFailure,
  closeAddRulePopUp,
  createSimpleOutputSuccess,
  createSimpleOutputFailure,
  createCalculationScriptSuccess,
  createCalculationScriptFailure,
  uploadRuleSetSuccess,
  uploadRuleSetFailure,
  duplicateRuleSuccess,
  duplicateRuleFailure,
} from './ActionCreator';
import {
  SaveRuleAction,
  SaveCalculationScriptAction,
  SaveSimpleOutputAction,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  CompleteCalculationScriptData,
  SaveRuleCommonSuccessAction,
  UploadRuleSetAction,
  CompleteUploadRuleSetRequestData,
  DuplicateRuleAction,
  DuplicateRuleRequestData,
} from './Types';
import { getBranchInfoRequest } from 'BranchInfo/ActionCreator';
import { COPY_BRANCH_RULES_SUCCESS } from 'CopyModuleBranchRules/ActionTypes';
import getMessage, { MessageType } from 'constants/messages';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';

const isCreateNewRuleAction = (action: AnyAction): action is SaveRuleCommonSuccessAction =>
  action.type === CREATE_RULE_SUCCESS ||
  action.type === CREATE_CALCULATION_SCRIPT_SUCCESS ||
  action.type === CREATE_SIMPLE_OUTPUT_SUCCESS ||
  action.type === UPLOAD_RULE_SET_SUCCESS ||
  action.type === COPY_BRANCH_RULES_SUCCESS;

export const CreateRuleMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteRuleRequestData, SaveRuleAction>(
    CREATE_RULE,
    createRuleSuccess,
    createRuleFailure,
    async ({ payload }) => {
      await api.addRule(payload);
      return payload;
    },
  );

export const CreateCalculationScriptMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteCalculationScriptData, SaveCalculationScriptAction>(
    CREATE_CALCULATION_SCRIPT,
    createCalculationScriptSuccess,
    createCalculationScriptFailure,
    async ({ payload }) => {
      await api.addCalculationScript(payload);
      return payload;
    },
  );

export const CreateSimpleOutputMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteSimpleOutputRequestData, SaveSimpleOutputAction>(
    CREATE_SIMPLE_OUTPUT,
    createSimpleOutputSuccess,
    createSimpleOutputFailure,
    async ({ payload }) => {
      await api.addSimpleOutput(payload);
      return payload;
    },
  );

export const UploadRuleSetMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteUploadRuleSetRequestData, UploadRuleSetAction>(
    UPLOAD_RULE_SET,
    uploadRuleSetSuccess,
    uploadRuleSetFailure,
    async ({ payload }) => {
      await api.uploadRuleSet(payload);
      return payload;
    },
  );

const getRuleCreateCorrectMessage = ({ type }: AnyAction) => {
  switch (type) {
    case COPY_BRANCH_RULES_SUCCESS: {
      return MessageType.RuleSetFromCSVImported;
    }
    case UPLOAD_RULE_SET_SUCCESS: {
      return MessageType.RuleSetFromCSVUploaded;
    }
    default: {
      return MessageType.RuleAdded;
    }
  }
};

export const onCreateRuleSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isCreateNewRuleAction(action)) {
    const { strategyId, moduleKey, branchIndex } = action.payload;
    batch(() => {
      dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex }));
      dispatch(closeAddRulePopUp());
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      const messageType = getRuleCreateCorrectMessage(action);
      notification.createNotification(getMessage(messageType), 'success', dispatch);
    });
  }
  return result;
};

export const DuplicateRule: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<DuplicateRuleRequestData, DuplicateRuleAction>(
    DUPLICATE_RULE,
    duplicateRuleSuccess,
    duplicateRuleFailure,
    async ({ payload }) => {
      await api.duplicateRule(payload);
      return payload;
    },
  );

const isDuplicateRuleAction = (action: AnyAction): action is SaveRuleCommonSuccessAction =>
  action.type === DUPLICATE_RULE_SUCCESS;

export const onDuplicateRuleSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isDuplicateRuleAction(action)) {
    const { strategyId, moduleKey, branchIndex } = action.payload;
    batch(() => {
      dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex }));
      notification.createNotification(getMessage(MessageType.RuleAdded), 'success', dispatch);
    });
  }
  return result;
};
