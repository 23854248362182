import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import {
  updateApplicationDocument,
  uploadApplicationDocument,
  deleteApplicationDocument,
  createDocumentFolder,
  requestCustomerUpload,
  createDocuSignEnvelope,
} from './ActionCreator';

const NotifyOnApplicationDocumentsAsyncActionsSuccessMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isFulfilled(deleteApplicationDocument)(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentDeleted), 'success', dispatch);
  }

  if (isFulfilled(uploadApplicationDocument)(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentUploaded), 'success', dispatch);
  }

  if (isFulfilled(createDocumentFolder)(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentFolderCreated), 'success', dispatch);
  }

  if (isFulfilled(updateApplicationDocument)(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentUpdated), 'success', dispatch);
  }

  if (isFulfilled(requestCustomerUpload)(action)) {
    notification.createNotification(getMessage(MessageType.RequestCustomerUploadSuccess), 'success', dispatch);
  }

  if (isFulfilled(createDocuSignEnvelope)(action)) {
    notification.createNotification(getMessage(MessageType.DocuSignEnvelopeCreated), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default NotifyOnApplicationDocumentsAsyncActionsSuccessMiddleware;
