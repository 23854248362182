import { BorrowerType } from './LoanOriginationSystemBorrowersApi';
import BaseVariableConfigurationsRestApi, {
  BaseVariableConfigurationsApi,
  BaseCreateVariableConfigurationParams,
  BaseVariableConfiguration,
} from './Base/BaseVariableConfigurationsApi';

export interface BorrowerVariableConfiguration extends BaseVariableConfiguration {
  borrowerType: BorrowerType;
}

export interface IFindBorrowerVariableConfigurationsParams {
  borrowerType: BorrowerType;
}

export interface CreateBorrowerVariableConfigurationParams extends BaseCreateVariableConfigurationParams {
  borrowerType: BorrowerType;
}

export type BorrowerVariableConfigurationsApi = BaseVariableConfigurationsApi<
  BorrowerVariableConfiguration,
  IFindBorrowerVariableConfigurationsParams,
  CreateBorrowerVariableConfigurationParams
>;

export default class BorrowerVariableConfigurationsRestApi extends BaseVariableConfigurationsRestApi<
  BorrowerVariableConfiguration,
  IFindBorrowerVariableConfigurationsParams,
  CreateBorrowerVariableConfigurationParams
> implements BorrowerVariableConfigurationsApi {
  protected resourceName = 'borrower-variable-configurations';

  protected getUrlSearchParamsByFindParams(params: IFindBorrowerVariableConfigurationsParams) {
    const urlSearchParams = super.getUrlSearchParamsByFindParams(params);

    urlSearchParams.set('borrowerType', params.borrowerType);

    return urlSearchParams;
  }
}
