import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { ProductCalculationsApi } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import GetProductCalculationsError from 'errors/GetProductCalculationsError';
import {
  GetProductCalculationsAction,
  getProductCalculationsSuccess,
  getProductCalculationsFailure,
  GetProductCalculationsSuccessParams,
} from './ActionCreator';
import { ProductCalculationsType } from './ActionTypes';

const GetProductCalculationsMiddleware = (api: ProductCalculationsApi) =>
  Fetcher<GetProductCalculationsSuccessParams, GetProductCalculationsAction, ReduxState, GetProductCalculationsError>(
    ProductCalculationsType.GetProductCalculations,
    getProductCalculationsSuccess,
    getProductCalculationsFailure,
    async (action) => {
      try {
        const calculations = await api.getByProduct(action.payload.productId);

        return {
          productId: action.payload.productId,
          calculations,
        };
      } catch (err) {
        throw new GetProductCalculationsError(err.message, action.payload.productId);
      }
    },
  );

export default GetProductCalculationsMiddleware;
