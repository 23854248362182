import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  UpdateApplicationCoBorrowersAction,
  updateApplicationCoBorrowersFailure,
  updateApplicationCoBorrowersSuccess,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationCoBorrowersMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationCoBorrowersAction>(
    ApplicationPageActionType.UpdateApplicationCoBorrowers,
    updateApplicationCoBorrowersSuccess,
    updateApplicationCoBorrowersFailure,
    (action) => {
      return api.updateApplicationCoBorrowers(action.payload.applicationId, action.payload.params);
    },
  );

export default UpdateApplicationCoBorrowersMiddleware;
