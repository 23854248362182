import React, { FC } from 'react';
import { WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import DetailsForm from 'components/DetailsForm';
import Button from 'components/Button';
import NeedHelpSection from 'components/NeedHelpSection';
import styles from './WebhookEndpointIntegration.module.scss';

export interface WebhookEndpointIntegrationProps {
  webhookEndpoint: WebhookEndpoint | null;
  onGenerateNewSecret: () => void;
}

const INTEGRATION_COLUMNS = 1;

const WebhookEndpointIntegration: FC<WebhookEndpointIntegrationProps> = ({
  webhookEndpoint,
  onGenerateNewSecret,
}) => {
  const fields = webhookEndpoint ? [{
    label: 'Webhook Secret Key',
    value: webhookEndpoint.endpointSecret,
  }] : null

  return (
    <div className={styles.webhookEndpointIntegrationContainer}>
      <DetailsForm
        labelWidth="210px"
        title="Integration"
        columns={INTEGRATION_COLUMNS}
        fields={fields}
      />
      <Button
        disabled={!webhookEndpoint}
        kind="primary"
        className={styles.generateNewKeyButton}
        onClick={onGenerateNewSecret}
      >
        Generate New Key
      </Button>
      <NeedHelpSection
        className={styles.needHelpSection}
        link="https://docs.digifi.io/reference/securing-webhook-endpoints"
        linkTitle="Developer Guide"
      />
    </div>
  );
};

export default WebhookEndpointIntegration;
