import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  UpdateProductAction,
  UpdateProductActionOrigin,
  UpdateProductSuccessAction,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import { ApplicationFormConfigurationState } from './Types';
import {
  SetFormPageToDeleteAction,
  ToggleFormSettingsPopupVisibilityAction,
} from './ActionCreator';
import { ApplicationFormConfigurationActionType } from './ActionTypes';

export const initialState: ApplicationFormConfigurationState = {
  isFormSettingsPopupOpen: false,
  isFormSettingUpdateInProgress: false,
  isFormPageDeleteInProgress: false,
  formPageToDelete: null,
};

type ApplicationFormConfigurationActions =
  | SetFormPageToDeleteAction
  | ToggleFormSettingsPopupVisibilityAction
  | UpdateProductAction
  | UpdateProductSuccessAction
  | FailedAction<LoanOriginationSystemProductsActionType.UpdateProductFailure, UpdateProductActionOrigin>

const applicationFormConfigurationReducer = (state = initialState, action: ApplicationFormConfigurationActions) => {
  switch (action.type) {
    case ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility: {
      return {
        ...state,
        isFormSettingsPopupOpen: !state.isFormSettingsPopupOpen,
      };
    }
    case LoanOriginationSystemProductsActionType.UpdateProduct: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: true,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: true,
        };
      }

      return state;
    }
    case LoanOriginationSystemProductsActionType.UpdateProductSuccess: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: false,
          isFormSettingsPopupOpen: false,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: false,
          formPageToDelete: null,
        };
      }

      return state;
    }
    case LoanOriginationSystemProductsActionType.UpdateProductFailure: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: false,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: false,
        };
      }

      return state;
    }
    case ApplicationFormConfigurationActionType.SetFormPageToDelete: {
      return {
        ...state,
        formPageToDelete: action.payload.formPage,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationFormConfigurationReducer;
