import React, { useState } from 'react';
import clsx from 'clsx';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import {
  getCoBorrowerPageIndex,
  getCoBorrowerPageTypeByIndex, isAlwaysAvailableApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import { productTypeOptions } from 'components/LoanOriginationSystem/ProductBasicInfoForm/ProductTypeOptions';
import styles from './CreateNewApplicationPopup.module.scss';
import ApplicationFormPageCheckbox from './ApplicationFormPageCheckbox';

export interface CreateNewApplicationPopupProps {
  products: Product[];
  initialProduct: Product | null;
  onSubmit: (product: Product, applicationFormPages: string[]) => void;
  onClose: () => void;
}

const CreateNewApplicationPopup = ({ products, onClose, onSubmit, initialProduct }: CreateNewApplicationPopupProps) => {
  const [selectedProduct, setSelectedProduct] = useState(initialProduct || products[0]);

  const { applicationFormPages: availableApplicationFormPages } = selectedProduct.settings;

  const [isIntermediaryPageSelected, setIsIntermediaryPageSelected] = useState(false);
  const [selectedCoBorrowerPages, setSelectedCoBorrowerPages] = useState<string[]>([]);

  const handleProductChange = (product: Product) => {
    setSelectedProduct(product);

    setSelectedCoBorrowerPages([]);
  };

  const renderProduct = (product: Product) => {
    const productTypeOption = productTypeOptions.find(({ type }) => type === product.type);
    const Image = productTypeOption?.image;

    const isSelected = selectedProduct.id === product.id;

    return (
      <div
        key={product.id}
        onClick={() => handleProductChange(product)}
        className={clsx(styles.product, isSelected && styles.selectedProduct)}
      >
        <div className={styles.productContent}>
          <div className={styles.productType}>{productTypeOption?.name}</div>
          <h5 className={styles.productName}>{product.name}</h5>
        </div>
        <div>
          {Image && <Image className={styles.productTypeImage} />}
        </div>
      </div>
    );
  };

  const filterSelectedCoBorrowersByIndex = (index: number) => {
    return selectedCoBorrowerPages.filter((page) => {
      const pageIndex = getCoBorrowerPageIndex(page);

      return pageIndex < index;
    });
  };

  const handleCoBorrowerCheckboxChange = (coBorrowerPage: string, index: number) => {
    const newSelectedCoBorrowerPages = selectedCoBorrowerPages.includes(coBorrowerPage)
      ? filterSelectedCoBorrowersByIndex(index)
      : [...selectedCoBorrowerPages, coBorrowerPage];

    setSelectedCoBorrowerPages(newSelectedCoBorrowerPages);
  };

  const renderCoBorrowerCheckbox = (coBorrowerPage: string) => {
    const coBorrowerPageIndex = getCoBorrowerPageIndex(coBorrowerPage);
    const label = coBorrowerPageIndex === 0 ? 'Include Co-Borrower Page' : `Include Co-Borrower ${coBorrowerPageIndex + 1} Page`;
    const disabled = coBorrowerPageIndex !== 0 && !selectedCoBorrowerPages.includes(getCoBorrowerPageTypeByIndex(coBorrowerPageIndex - 1));

    return (
      <ApplicationFormPageCheckbox
        key={coBorrowerPage}
        checked={selectedCoBorrowerPages.includes(coBorrowerPage)}
        disabled={disabled}
        label={label}
        onChange={() => handleCoBorrowerCheckboxChange(coBorrowerPage, coBorrowerPageIndex)}
      />
    );
  };

  const renderAdditionalProductSettingsSection = () => {
    if (!selectedProduct) {
      return null;
    }

    const isIntermediaryPageActive = availableApplicationFormPages.some((page) => isIntermediaryApplicationFormPage(page));

    const availableCoBorrowerPages = availableApplicationFormPages.filter((page) => isCoBorrowerApplicationFormPage(page));

    if (!isIntermediaryPageActive && !availableCoBorrowerPages.length) {
      return null;
    }

    return (
      <div className={styles.additionalSettingsContainer}>
        <h6 className={styles.sectionHeader}>Application Form Options</h6>
        {availableCoBorrowerPages.map(renderCoBorrowerCheckbox)}
        {isIntermediaryPageActive && (
          <ApplicationFormPageCheckbox
            checked={isIntermediaryPageSelected}
            label="Include Intermediary Page"
            onChange={() => setIsIntermediaryPageSelected(!isIntermediaryPageSelected)}
          />
        )}
      </div>
    );
  };

  const handleSubmit = () => {
    if (!selectedProduct) {
      return;
    }

    const applicationFormPages = availableApplicationFormPages.filter((page) => {
      if (isIntermediaryApplicationFormPage(page)) {
        return isIntermediaryPageSelected;
      }

      if (isCoBorrowerApplicationFormPage(page)) {
        return selectedCoBorrowerPages.includes(page);
      }

      return isAlwaysAvailableApplicationFormPage(page) || isDocumentUploadFormPage(page);
    });

    onSubmit(
      selectedProduct,
      applicationFormPages,
    );
  };

  return (
    <PopUp closePopUp={onClose} title="New Application">
      <PopUpContent hasTopMargin>
        <h6 className={styles.sectionHeader}>Select Product</h6>
        <div className={styles.products}>{products.map(renderProduct)}</div>
        {renderAdditionalProductSettingsSection()}
        <Button
          onClick={handleSubmit}
          kind="secondary"
          size="form"
          className={styles.continueButton}
          disabled={!selectedProduct}
        >
          Continue
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateNewApplicationPopup;
