import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { BorrowerProfileNormalizedCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import {
  getBorrowerProfileCards,
  createBorrowerProfileCard,
  updateBorrowerProfileCard,
  deleteBorrowerProfileCard,
} from './Thunks';
import {
  createBorrowerVariableConfiguration,
  deleteBorrowerVariableConfiguration,
  updateBorrowerVariableConfigurationPosition,
} from 'BorrowerVariableConfigurations/Thunks';
import {
  addVariableConfigurationToCard,
  deleteVariableConfigurationFromCard,
  updateCardsStore,
  updateVariableConfigurationOnCards,
} from 'Cards/Utils';

export interface BorrowerProfileCardsState {
  cardsById: Record<string, BorrowerProfileNormalizedCard>;
}

const initialState: BorrowerProfileCardsState = {
  cardsById: {},
};

const borrowerProfileCardsReducer = createReducer<BorrowerProfileCardsState>(initialState, (builder) => {
  builder.addCase(deleteBorrowerProfileCard.fulfilled, (state, action) => {
    delete state.cardsById[action.payload.id];
  });

  builder.addCase(deleteBorrowerVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfigurationFromCard(state, action.payload.id);
  });

  builder.addCase(updateBorrowerVariableConfigurationPosition.fulfilled, (state, action) => {
    updateVariableConfigurationOnCards(state, action.payload.sourceCardId, action.payload.configuration);
  });

  builder.addCase(createBorrowerVariableConfiguration.fulfilled, (state, action) => {
    addVariableConfigurationToCard(state, action.payload.configuration);
  });

  builder.addMatcher(
    isFulfilled(getBorrowerProfileCards, createBorrowerProfileCard, updateBorrowerProfileCard),
    (state, action) => {
      updateCardsStore(state, action.payload.borrowerProfileCards);
    },
  );
});

export default borrowerProfileCardsReducer;
