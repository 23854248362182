import { ProductConfigurationDocumentFolder, DataViewSortingType } from 'api/Types';
import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';

export enum ProductConfigurationDocumentFoldersSortingFields {
  Name = 'name',
}

export type ProductConfigurationDocumentFoldersSortingType = DataViewSortingType<
  ProductConfigurationDocumentFoldersSortingFields
>;

export type CreateProductConfigurationDocumentFolderParams = Omit<ProductConfigurationDocumentFolder, 'id'>;

export type UpdateProductConfigurationDocumentFolderParams = Partial<
  Omit<ProductConfigurationDocumentFolder, 'id' | 'productId'>
>;

export interface ProductConfigurationDocumentFoldersApi {
  find(productId: string): Promise<ProductConfigurationDocumentFolder[]>;
  create(params: CreateProductConfigurationDocumentFolderParams): Promise<ProductConfigurationDocumentFolder>;
  update(
    folderId: string,
    folder: UpdateProductConfigurationDocumentFolderParams,
  ): Promise<ProductConfigurationDocumentFolder>;
  delete(folderId: string): Promise<void>;
}

export default class ProductConfigurationDocumentFoldersApiRest
  extends LoanOriginationSystemApi<ProductConfigurationDocumentFolder>
  implements ProductConfigurationDocumentFoldersApi {
  protected resourceName = 'document-folders';

  public async find(productId: string) {
    const params = new URLSearchParams();
    params.set('productId', productId);

    return this.getResources(params);
  }

  public async create(params: CreateProductConfigurationDocumentFolderParams) {
    return this.createResource({ folder: params });
  }

  public async update(id: string, params: UpdateProductConfigurationDocumentFolderParams) {
    return this.updateResource(id, { folder: params });
  }

  public async delete(folderId: string) {
    return this.deleteResource(folderId);
  }
}
