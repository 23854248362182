export const ProductConfigurationDocumentFoldersActionTypesPrefix = 'documentFolders/';

export const ProductConfigurationDocumentFoldersActionTypes = {
  GetDocumentFolders: `${ProductConfigurationDocumentFoldersActionTypesPrefix}getDocumentFolders`,
  CreateDocumentFolder: `${ProductConfigurationDocumentFoldersActionTypesPrefix}createDocumentFolder`,
  UpdateDocumentFolder: `${ProductConfigurationDocumentFoldersActionTypesPrefix}updateDocumentFolder`,
  DeleteDocumentFolder: `${ProductConfigurationDocumentFoldersActionTypesPrefix}deleteDocumentFolder`,
  SortDocumentFolders: `${ProductConfigurationDocumentFoldersActionTypesPrefix}sortDocumentFolders`,
  SetParentFolder: `${ProductConfigurationDocumentFoldersActionTypesPrefix}setParentFolder`,
  SetFolderToUpdate: `${ProductConfigurationDocumentFoldersActionTypesPrefix}setFolderToUpdate`,
  OpenDocumentFolderPopup: `${ProductConfigurationDocumentFoldersActionTypesPrefix}openDocumentFolderPopup`,
  CloseDocumentFolderPopup: `${ProductConfigurationDocumentFoldersActionTypesPrefix}closeDocumentFolderPopup`,
  ResetState: `${ProductConfigurationDocumentFoldersActionTypesPrefix}resetState`,
};
