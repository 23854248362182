import {
  OrganizationInfoType,
  UpdateOrganizationInfoParams,
  UpdateRegionSettingsRequestData,
} from 'CompanyInformation/CompanyInformationTypes';
import {
  ApiActivationSortingType,
  ModuleType,
  NormalizedStatus,
  Strategy,
  SystemStatus,
} from 'DecisionStrategy/DecisionStrategiesTypes';
import { VariableItem } from './VariablesType';
import { CopyModuleData, CreateModuleData, DuplicateBranchData, ModuleData } from 'ModuleInfo/Types';
import {
  Integration,
  ResponseWithMessage,
  CompleteGetExternalIntegrationRequestData,
  CompleteGetExternalIntegrationResponseData,
  ResponseBranchData,
  OutputData,
} from './DecisionEngineStrategiesType';
import { BranchInfo, BranchInfoRequestData, BranchParams, RuleData, RuleType } from 'BranchInfo/Types';
import { CompleteEditExternalIntegrationRequestData } from 'EditExternalIntegration/Types';
import { BranchData } from 'AddBranch/Types';
import {
  CompleteCalculationScriptData,
  CompleteUploadRuleSetRequestData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  DuplicateRuleRequestData,
} from 'RuleCreate/Types';
import { CompletedScoringModelRequestData } from 'ScoringModel/Types';
import { CompletedDeleteRuleData } from 'RuleDelete/Types';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { UpdateVersionStatusesParams } from 'ApiActivation/ActionCreator';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { TableViewData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { AddNewUserParams } from 'AddNewCompanyUser/AddNewCompanyUserTypes';

export enum CaseResult {
  Passed = 'Passed',
  Failed = 'Failed',
  Error = 'Error',
}

export interface UpdateUserInfoCredentials {
  _id: string;
  firstName: string;
  lastName: string;
}

export enum UserAddResult {
  SuccessfullyAdded,
  NoSeatsAvailable,
}

export interface OrganizationApiType {
  updateOrganizationInfo(organizationInfo: UpdateOrganizationInfoParams): Promise<OrganizationInfoType>;
  updateRegionSettings(regionSettingsData: UpdateRegionSettingsRequestData): Promise<OrganizationInfoType>;
  updateOrganizationLogo(logo: File): Promise<OrganizationInfoType>;
  addNewUser(userParams: AddNewUserParams): Promise<UserAddResult>;
}

export interface PaginationParams {
  page: number;
  perPage: number;
}

export interface UpdateRulesOrderParams {
  reorderedRuleIds: string[];
  moduleKey: string;
  branchIndex: number;
  strategyId: string;
  ruleType: RuleType;
}

export interface UpdateModuleBranchesOrderParams {
  strategyId: string;
  moduleKey: string;
  branchIndices: number[];
}

export interface ItemsWithTotalCount<Item> {
  items: Item[];
  itemsTotal: number | undefined;
}

export interface DecisionStrategiesRequestParams extends PaginationParams {
  search?: string;
}

export interface IndividualHistoryRequestParams extends PaginationParams {
  search?: string;
}

export interface BatchCaseRequestParams extends PaginationParams {
  search?: string;
}

export type BranchRuleType = 'ruleset' | 'conditions';
export type CalculationScriptMode = 'jexl' | 'javascript';

export interface GetBranchOptionsParams {
  moduleType: ModuleType | null;
  ruleType: BranchRuleType;
  calculationMode?: CalculationScriptMode;
}

export interface ModuleItem {
  id: string;
  strategyId: string;
  moduleKey: string;
  strategyName: string;
  moduleName: string;
  moduleType: ModuleType;
}

export interface BranchItemInfo {
  strategyId: string;
  strategyName: string;
  moduleKey: string;
  moduleName: string;
  moduleType: ModuleType;
  branchIndex: number;
  branchName: string;
  rulesCount: number;
}

export interface BranchKeys {
  moduleKey: string;
  branchIndex: number;
}

export interface BranchIdentityAttrs extends BranchKeys {
  strategyId: string;
  moduleType: ModuleType;
}

export interface BranchIdentityParams {
  moduleKey: string;
  strategyId: string;
  branchIndex: number;
}

export interface CopyBranchRulesParams {
  ruleType: BranchRuleType;
  copyToBranchParams: BranchIdentityParams;
  copyFromBranchPrams: BranchIdentityParams;
}

export interface BatchHistoryRequestParams extends PaginationParams {
  search?: string;
}

export interface StrategyGroupVersionApiData {
  id: string;
  isLocked: boolean;
  name: string;
  status: SystemStatus;
  title: string;
  displayTitle: string;
  versionsCount: number;
  updatedDate: string;
  version: number;
  description: string;
  updatedByUserInfo: UserInfo;
  hasModules: boolean;
}

export interface StrategyGroupVersion {
  id: string;
  isLocked: boolean;
  name: string;
  status: NormalizedStatus;
  title: string;
  displayTitle: string;
  updatedDate: string;
  version: number;
  versionsCount: number;
  description: string;
  updatedByUserInfo: UserInfo;
  hasModules: boolean;
}

export interface StrategyGroup<Version = StrategyGroupVersion> {
  name: string;
  title: string;
  versionsNumber: number;
  archived: boolean;
  versions: Version[];
}

interface StrategyListData<ItemType> {
  items: ItemType[];
  page: number;
  perPage: number;
  total: number;
  users: UserInfo[];
  selectedUpdaters: UserInfo[];
}

export type StrategyGroupsData = StrategyListData<StrategyGroup>;
export type StrategyGroupsApiData = StrategyListData<StrategyGroup<StrategyGroupVersionApiData>>;
export type VersionsListApiData = StrategyListData<StrategyGroupVersionApiData>;
export type VersionsListData = StrategyListData<StrategyGroupVersion> & { searchInputValue?: string };

export interface DropdownStrategy {
  id: string;
  status: SystemStatus;
  version: number;
  title: string;
  name: string;
}

export interface DecisionEngineApi {
  deleteDecisionStrategyModule(strategyId: string, moduleLookupName: string): Promise<void>;
  updateModuleBranchesOrder(data: UpdateModuleBranchesOrderParams): Promise<void>;
  updateStrategyName(id: string, name: string): Promise<void>;
  updateStrategyDescription(id: string, description: string): Promise<void>;
  getVariablesForExternalIntegration(
    externalIntegrationData: CompleteGetExternalIntegrationRequestData,
  ): Promise<CompleteGetExternalIntegrationResponseData>;
  uploadBulkVariable(variable: File): Promise<VariableItem>;
  updateModulesOrder(strategyId: string, moduleIndices: number[]): Promise<Strategy>;
  updateModuleInfo(moduleInfo: ModuleData, strategyId: string): Promise<void>;
  updateBranchInfo(branchInfo: BranchParams): Promise<BranchParams>;
  createNewModule(moduleData: CreateModuleData): Promise<ResponseWithMessage>;
  duplicateModule(moduleData: CopyModuleData): Promise<ResponseWithMessage>;
  duplicateBranch(branchData: DuplicateBranchData): Promise<ResponseBranchData>;
  createNewBranch(branchData: BranchData): Promise<ResponseWithMessage>;
  copyBranchRules(
    ruleType: BranchRuleType,
    copyToParams: BranchIdentityParams,
    copyFromParams: BranchIdentityParams,
  ): Promise<void>;
  getDecisionStrategyUpdates(params: GetDecisionStrategyUpdatesParams, strategyId: string): Promise<TableViewData<StrategyUpdateType>>;
  getBranchInfo({ strategyId, moduleKey, branchIndex }: BranchInfoRequestData): Promise<BranchInfo>;
  deleteBranch({ strategyId, moduleKey, branchIndex }: BranchParams): Promise<BranchParams>;
  getBranchOptions(params: GetBranchOptionsParams): Promise<BranchItemInfo[]>;
  addCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void>;
  editCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void>;
  addRule(ruleData: CompleteRuleRequestData): Promise<void>;
  deleteRule(data: CompletedDeleteRuleData): Promise<void>;
  editRule(ruleData: CompleteRuleRequestData): Promise<void>;
  addSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void>;
  editSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void>;
  getIntegrationsList(): Promise<Integration[]>;
  getStrategiesList(): Promise<StrategiesListItem[]>;
  updateExternalIntegration(externalIntegrationData: CompleteEditExternalIntegrationRequestData): Promise<void>;
  findModule(moduleName: string, abortSignal: AbortSignal): Promise<ModuleItem[]>;
  uploadRuleSet(ruleSet: CompleteUploadRuleSetRequestData): Promise<void>;
  updateScoringRuleSegment(data: CompletedScoringModelRequestData): Promise<void>;
  updateApiActivations(activationList: UpdateVersionStatusesParams): Promise<void>;
  updateRulesOrder(data: UpdateRulesOrderParams): Promise<void>;
  runBatchStrategy(strategyId: string, batchData: File, processName?: string): Promise<void>;
  duplicateRule(ruleData: DuplicateRuleRequestData): Promise<RuleData>;
  archiveStrategies(params: ChangeArchiveStatusParams): Promise<void>;
  restoreStrategies(params: ChangeArchiveStatusParams): Promise<void>;
  getVersionsData(strategyName: string, params: GetVersionsListParams): Promise<VersionsListData>;
  assignOutputVariable(
    strategyId: string,
    outputIndex: number,
    variableId: string,
    moduleName: string,
    branchIndex: number,
  ): Promise<OutputData>;
}

export interface ChangeArchiveStatusParams {
  id?: string;
  groupName?: string;
}

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

interface SortParams {
  sortBy: string;
  orderBy: OrderBy;
}

export interface GetStrategyListParams {
  page: number;
  perPage: number;
  showArchived: boolean;
  searchInputValue?: string;
  updaters?: string[];
  versionsStatuses?: SystemStatus[];
  sortingType?: ApiActivationSortingType;
}

export interface GetVersionsListParams extends GetStrategyListParams, SortParams {}

export interface GetDecisionStrategyUpdatesParams extends PaginationParams, SortParams {}

export interface DownloadAPI {
  downloadApiTemplateURL(strategyId: string): Promise<void>;
  downloadRulesTemplate(moduleType: string, strategyId: string, moduleKey: string, branchIndex: number): void;
  downloadBatchTemplate(strategyId: string): Promise<void>;
}

export interface ChangeStrategyArchiveStatusParams {
  type: string;
  strategyName: string;
  actionOrigin?: StrategyActionOrigin;
  id?: string;
  version?: number;
}

export interface StrategiesListItem {
  id: string;
  name: string;
  status: SystemStatus;
}

export interface IndividualProcessingHistoryCase {
  id: string;
  name: string;
  type: 'Web' | 'API';
  createdBy: string;
  updatedBy: string;
  date: string;
  strategy_name: string;
  strategyId: string;
  inputs: VariableValueMap;
  passed: boolean;
  errors: string[];
}

export interface ApplicationNote {
  id: string;
  createdAt: string;
  updatedAt: string;
  note: string;
  author: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

export interface VariableValueMap {
  [key: string]: VariableValue;
}

export interface ProductConfigurationDocumentFolder {
  id: string;
  name: string;
  parentId: null | string;
  productId: string;
}

export * from './LoanOriginationSystem/Types';
