import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { NullableItems } from 'pagination';

export const getWebhookEndpointsById = (state: ReduxState) => state.webhookEndpoints.webhookEndpointsById;
export const getWebhookEndpointIds = (_: ReduxState, endpointIds: NullableItems<string>) => endpointIds;

export const getWebhookEndpointsSelector = createSelector(
  [getWebhookEndpointsById, getWebhookEndpointIds],
  (endpointsById, endpointIds) => {
    return endpointIds.map((id) => (id ? endpointsById[id] : null));
  },
);
