import Fetcher from 'middlewares/Fetcher';
import {
  CREATE_NEW_MODULE_REQUEST,
  MODULE_ADDED_SUCCESS,
  UPDATE_MODULE_INFO_REQUEST,
  UPDATE_MODULE_INFO_REQUEST_SUCCESS,
  UPDATE_MODULE_NAME,
  UPDATE_MODULE_NAME_SUCCESS,
} from './ActionTypes';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import {
  CreateNewModuleRequestAction,
  createNewModuleRequestFailure,
  createModuleAddedRequestSuccess,
  ModuleAddedSuccessAction,
  UpdateModuleInfoRequestAction,
  updateModuleInfoRequestFailure,
  updateModuleInfoRequestSuccess,
  UpdateModuleInfoRequestSuccessAction,
  updateModuleNameFailure,
  updateModuleNameSuccess,
  UpdateModuleNameSuccessAction,
} from 'ModuleInfo/ModuleInfoActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import {
  addCollapsedStrategyModule,
  getDecisionStrategyInfoRequest,
} from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { DecisionEngineApi } from 'api/Types';
import { batch } from 'react-redux';
import { ModuleRequestDataType } from './Types';
import getMessage, { MessageType } from 'constants/messages';
import { ModuleTypeName } from 'components/RulesModule/Types';

const AddModuleMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ModuleAddedSuccessAction['payload'], CreateNewModuleRequestAction>(
    CREATE_NEW_MODULE_REQUEST,
    createModuleAddedRequestSuccess,
    createNewModuleRequestFailure,
    async ({ payload }) => {
      await api.createNewModule(payload);
      return {
        strategyId: payload.strategyId,
        moduleType: payload.currentType,
        moduleName: payload.name,
      };
    },
  );

const isNewModuleAddedAction = (action: AnyAction): action is ModuleAddedSuccessAction =>
  action.type === MODULE_ADDED_SUCCESS;

export const onNewModuleAddedSuccessMiddleware: Middleware = <ReduxState,>({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isNewModuleAddedAction(action)) {
    const { strategyId, moduleName, moduleType } = action.payload;
    batch(() => {
      dispatch(addCollapsedStrategyModule(strategyId));
      dispatch(getDecisionStrategyInfoRequest(strategyId));
    });

    const messageData = { moduleName, moduleTypeName: ModuleTypeName[moduleType] };
    notification.createNotification(getMessage(MessageType.ModuleAdded, messageData), 'success', dispatch);
  }
  return result;
};

export const UpdateModuleInfoMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ModuleRequestDataType, UpdateModuleInfoRequestAction>(
    UPDATE_MODULE_INFO_REQUEST,
    updateModuleInfoRequestSuccess,
    updateModuleInfoRequestFailure,
    async ({ payload }) => {
      const { strategyId, ...moduleInfo } = payload;
      await api.updateModuleInfo(moduleInfo, strategyId);
      return payload;
    },
  );

export const UpdateModuleNameMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ModuleRequestDataType, UpdateModuleInfoRequestAction>(
    UPDATE_MODULE_NAME,
    updateModuleNameSuccess,
    updateModuleNameFailure,
    async ({ payload }) => {
      const { strategyId, ...moduleInfo } = payload;
      await api.updateModuleInfo(moduleInfo, strategyId);
      return payload;
    },
  );

const isUpdateModuleSuccessAction = (action: AnyAction): action is UpdateModuleInfoRequestSuccessAction =>
  action.type === UPDATE_MODULE_INFO_REQUEST_SUCCESS;

export const onUpdateModuleInfoSuccessMiddleware: Middleware = <ReduxState,>({
  dispatch,
}: MiddlewareAPI<ReduxState>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);
  if (isUpdateModuleSuccessAction(action)) {
    const { strategyId } = action.payload;

    dispatch(getDecisionStrategyInfoRequest(strategyId));
  }

  return result;
};

const isUpdateModuleNameSuccessAction = (action: AnyAction): action is UpdateModuleNameSuccessAction =>
  action.type === UPDATE_MODULE_NAME_SUCCESS;

export const onUpdateModuleNameSuccessMiddleware: Middleware = <ReduxState,>({
  dispatch,
}: MiddlewareAPI<ReduxState>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);
  if (isUpdateModuleNameSuccessAction(action)) {
    const { strategyId, name } = action.payload;

    notification.createNotification(
      getMessage(MessageType.ModuleNameUpdated, {
        moduleName: name,
      }),
      'success',
      dispatch,
    );
    dispatch(getDecisionStrategyInfoRequest(strategyId));
  }

  return result;
};

export default AddModuleMiddleware;
