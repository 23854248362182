import { useMemo, useState } from 'react';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { TabSwitchOption } from 'components/TabSwitch';
import {
  availableApplicationFormPages,
  DefaultApplicationFormPage,
  getApplicationFormPageLabel,
  getCoBorrowerPageIndex, getCoBorrowerPageTypeByIndex,
  isAlwaysAvailableApplicationFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { convertBorrowerDefaultVariablesToCoBorrower } from 'utils/BorrowerVariableUtils';

type Callback = () => void;

const getProfileName = (
  application: Application,
  page: string,
) => {
  if (isBorrowerApplicationFormPage(page)) {
    return getBorrowerFullName(application.borrowerType, application.variables);
  }

  if (isIntermediaryApplicationFormPage(page)) {
    return application.intermediaryId ? application.variables[IntermediaryDefaultVariable.Name] : '';
  }

  if (isCoBorrowerApplicationFormPage(page)) {
    const index = getCoBorrowerPageIndex(page);

    return application.coborrowerTypes[index] ? getBorrowerFullName(
      application.coborrowerTypes[index],
      application.variables,
      convertBorrowerDefaultVariablesToCoBorrower(BorrowerDefaultVariable, index),
    ) : '';
  }

  return '';
};

const getLabel = (application: Application, page: string) => {
  const profileName = getProfileName(application, page);

  return profileName ? `${getApplicationFormPageLabel(page)} (${profileName})` : getApplicationFormPageLabel(page);
};

const useEditApplicationTabs = (
  application: Application | null,
  product: Product | null,
): [
  TabSwitchOption[] | null,
  string,
  (tabId: string) => void,
  Callback,
  Callback,
  Callback,
] => {
  const [selectedTabId, setSelectedTabId] = useState<string>(DefaultApplicationFormPage.Borrower);
  const [isAddingCoBorrower, setIsAddingCoBorrower] = useState(false);
  const [isAddingIntermediary, setIsAddingIntermediary] = useState(false);

  const tabs = useMemo(() => {
    if (!application || !product) {
      return null;
    }

    const { applicationFormPages } = product.settings;

    const availableCoBorrowerPagesCount = applicationFormPages.filter((page) => isCoBorrowerApplicationFormPage(page)).length;
    const isIntermediaryFormPageAvailable = !!applicationFormPages.find((page) => isIntermediaryApplicationFormPage(page));

    const availablePages = availableApplicationFormPages.filter((page) => {
      if (isCoBorrowerApplicationFormPage(page)) {
        const coBorrowerPageIndex = getCoBorrowerPageIndex(page);

        return !!application.coborrowerIds[coBorrowerPageIndex] || (isAddingCoBorrower && coBorrowerPageIndex === application.coborrowerIds.length);
      }

      if (isIntermediaryApplicationFormPage(page)) {
        return !!application.intermediaryId || isAddingIntermediary;
      }

      if (isDocumentUploadFormPage(page)) {
        return applicationFormPages.includes(page);
      }

      return isAlwaysAvailableApplicationFormPage(page);
    });

    const shouldShowAddCoBorrowerPage = availableCoBorrowerPagesCount > application.coborrowerIds.length && !isAddingCoBorrower;
    const shouldShowAddIntermediaryPage = !application.intermediaryId && isIntermediaryFormPageAvailable && !isAddingIntermediary;

    const addCoBorrowerPageTab: TabSwitchOption = {
      label: 'Add Co-Borrower',
      id: 'addCoBorrower',
      type: 'button',
      onClick: () => {
        setIsAddingCoBorrower(true);
        setIsAddingIntermediary(false);
        setSelectedTabId(getCoBorrowerPageTypeByIndex(application.coborrowerIds.length));
      },
    };

    const addIntermediaryPageTab: TabSwitchOption = {
      label: 'Add Intermediary',
      id: 'addIntermediary',
      type: 'button',
      onClick: () => {
        setIsAddingIntermediary(true);
        setIsAddingCoBorrower(false);
        setSelectedTabId(DefaultApplicationFormPage.Intermediary);
      },
    };

    return [
      ...availablePages.map((page) => ({ label: getLabel(application, page), id: page })),
      ...(shouldShowAddCoBorrowerPage ? [addCoBorrowerPageTab] : []),
      ...(shouldShowAddIntermediaryPage ? [addIntermediaryPageTab] : []),
    ];
  }, [application, product, isAddingCoBorrower, isAddingIntermediary]);

  const handleTabChange = (tabId: string) => {
    setSelectedTabId(tabId);

    setIsAddingCoBorrower(false);
    setIsAddingIntermediary(false);
  };

  const resetIsAddingCoBorrower = () => setIsAddingCoBorrower(false);
  const resetIsAddingIntermediary = () => setIsAddingIntermediary(false);

  const handleReset = () => {
    if (!tabs) {
      return;
    }

    const [firstTab] = tabs;

    setSelectedTabId(firstTab.id);
    setIsAddingIntermediary(false);
    setIsAddingCoBorrower(false);
  };

  return [
    tabs,
    selectedTabId,
    handleTabChange,
    handleReset,
    resetIsAddingCoBorrower,
    resetIsAddingIntermediary,
  ];
};

export default useEditApplicationTabs;
