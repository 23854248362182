import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import useProductCalculations from 'hooks/useProductCalculations';
import BaseTabLayout, {
  BaseTabLayoutProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/BaseTabLayout';
import CardsLayoutConfiguration from 'components/LoanOriginationSystem/CardsLayoutConfiguration';
import { getApplicationDataTabCardsSelector } from 'ApplicationDataTabCards/Selectors';
import {
  createApplicationDataTabCard,
  deleteApplicationDataTabCard,
  getApplicationDataTabCards,
  updateApplicationDataTabCard,
} from 'ApplicationDataTabCards/Thunks';
import {
  createApplicationDataTabVariableConfiguration,
  deleteApplicationDataTabVariableConfiguration,
  updateConditionalDisplayRule,
  updateApplicationDataTabVariableConfigurationPosition,
  updateVariableConfigurationRequiredAttribute,
} from 'ApplicationDataTabVariableConfigurations/Thunks';
import { Variable } from 'Variables/VariablesTypes';

export const SKELETON_CARDS_LAYOUT = [
  [2, 1],
  [2],
  [1, 1],
];

const MAX_CARDS_PER_COLUMN = 50;

const DataTab = ({ tab, ...restProps }: BaseTabLayoutProps) => {
  const dispatch = useDispatch();
  const cards: ApplicationDataTabCard[] | null = useSelector((state: ReduxState) => getApplicationDataTabCardsSelector(state, tab.id));

  const productCalculations = useProductCalculations(tab.productId);

  useEffect(() => {
    dispatch(getApplicationDataTabCards(tab.id));
  }, [tab]);

  const handleCardNameUpdate = useCallback((cardId: string, name: string) => {
    dispatch(updateApplicationDataTabCard({
      id: cardId,
      name,
    }));
  }, []);

  const handleCardDelete = useCallback((cardId: string) => {
    dispatch(deleteApplicationDataTabCard(cardId));
  }, []);

  const handleCardAdd = useCallback((position: number, row: number) => {
    dispatch(createApplicationDataTabCard({
      name: 'New Section',
      tabId: tab.id,
      position,
      row,
    }));
  }, [tab]);

  const handleVariableConfigurationAdd = useCallback((cardId: string, column: number, position: number, variable: Variable) => {
    dispatch(createApplicationDataTabVariableConfiguration({
      cardId,
      position,
      column,
      variable: variable.id,
      productId: tab.productId
    }));
  }, [tab]);

  const handleVariableConfigurationDelete = useCallback((variableConfiguration: ApplicationDataTabVariableConfiguration) => {
    dispatch(deleteApplicationDataTabVariableConfiguration(variableConfiguration.id));
  }, []);

  const handleVariableConfigurationPositionUpdate = useCallback((
    variableConfiguration: ApplicationDataTabVariableConfiguration,
    targetCardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => {
    dispatch(
      updateApplicationDataTabVariableConfigurationPosition({
        id: variableConfiguration.id,
        position,
        column,
        cardId: targetCardId,
        sourceCardId,
      }),
    );
  }, []);

  const handleEditVariableConfigurationConditionalDisplayRule = useCallback(
    async (
      variableConfiguration: ApplicationDataTabVariableConfiguration,
      conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null,
    ) => {
      await dispatch(updateConditionalDisplayRule({
        id: variableConfiguration.id,
        conditionalDisplayRule,
      }));
    },
    [],
  );

  const handleChangeVariableConfigurationRequiredAttribute = useCallback(
    async (variableConfiguration: ApplicationDataTabVariableConfiguration, required: boolean) => {
      await dispatch(updateVariableConfigurationRequiredAttribute({
        id: variableConfiguration.id,
        required,
      }));
    },
    [],
  );

  return (
    <CardsLayoutConfiguration
      cards={cards}
      onCreateCard={handleCardAdd}
      onUpdateCardName={handleCardNameUpdate}
      onDeleteCard={handleCardDelete}
      onCreateVariableConfiguration={handleVariableConfigurationAdd}
      onDeleteVariableConfiguration={handleVariableConfigurationDelete}
      onUpdateVariableConfigurationPosition={handleVariableConfigurationPositionUpdate}
      skeletonCardsLayout={SKELETON_CARDS_LAYOUT}
      allowStandardVariables
      maxCardsPerColumn={MAX_CARDS_PER_COLUMN}
      onEditVariableConfigurationConditionalDisplayRule={handleEditVariableConfigurationConditionalDisplayRule}
      onChangeVariableConfigurationRequiredAttribute={handleChangeVariableConfigurationRequiredAttribute}
      calculations={productCalculations}
      displaySkeleton={!productCalculations}
    >
      {(renderActions, renderLayoutContent) => (
        <BaseTabLayout {...restProps} tab={tab} renderActions={renderActions}>
          {renderLayoutContent()}
        </BaseTabLayout>
      )}
    </CardsLayoutConfiguration>
  );
};

export default DataTab;
