import deepAssign from 'utils/deepAssign';

const setStateField = (fieldName: string, fieldValue: string | number | null | undefined = undefined) => <State>(
  state: State,
  path: string | string[],
): State => {
  if (typeof path === 'string') {
    return {
      ...state,
      [path]: {
        ...state[path],
        [fieldName]: fieldValue,
      },
    };
  }
  return deepAssign<State>(state, path, { [fieldName]: fieldValue });
};

export const setItemsTotal = setStateField('itemsTotal');

export default setStateField;
