import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import { SIZES } from './TableColumnSizes';
import useSorting from 'hooks/useSorting';
import styles from './LabelsTable.module.scss';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonLabelLine from './SkeletonLabelLine';
import LabelLine from './LabelLine';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableActionCell from 'components/Table/TableActionCell';
import { Label, LabelsSortingType, LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

interface LabelsTableProps {
  labels: NullableItems<Label>;
  paginationProps: PaginationProps;
  onSort: (field: LabelsSortingField, ascending: boolean) => void;
  sortingType: LabelsSortingType;
  onPopUpOpen: (id: string) => void;
  onUpdatePopupOpen: (id: string) => void;
}

const LabelsTable = ({
  labels,
  paginationProps,
  onSort,
  sortingType,
  onPopUpOpen,
  onUpdatePopupOpen,
}: LabelsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (label: Label) => (
    <LabelLine label={label} key={label.id} onPopUpOpen={onPopUpOpen} onUpdatePopupOpen={onUpdatePopupOpen} />
  );

  return (
    <div className={styles.labelsTableInner}>
      <div className={styles.labelsTable}>
        <Table>
          <TableHead sticky>
            <TableHeadCell
              width={SIZES.NAME}
              ascending={getSortingType(LabelsSortingField.LabelName)}
              onClick={() => changeSorting(LabelsSortingField.LabelName)}
            >
              Label
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.CREATED}
              ascending={getSortingType(LabelsSortingField.CreatedAt)}
              onClick={() => changeSorting(LabelsSortingField.CreatedAt)}
            >
              Created
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.UPDATED}
              ascending={getSortingType(LabelsSortingField.UpdatedAt)}
              onClick={() => changeSorting(LabelsSortingField.UpdatedAt)}
            >
              Updated
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent
              renderSkeletonTableRow={(index) => <SkeletonLabelLine key={index} />}
              rows={labels}
              renderTableRow={renderRow}
            />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default LabelsTable;
