import { repeat } from 'lodash';
import { VariableAccessPermissionType, VariableAdvancedDataTypeAttributes } from 'Variables/VariablesTypes';
import { getVisualDataType } from 'Variables/utils';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import { formatPhoneNumber } from 'components/PhoneInput';
import getIdentificationNumberMask from './masks/maskIdentificationNumber';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import formatMonetaryValue from './formatMonetaryValue';
import formatPercentageValue from './formatPercentageValue';
import maskNumberValue from './masks/maskNumberValue';
import formatDateValue from './formatDateValue';

const ASTERISK = '*';
export const FULLY_HIDDEN_VARIABLE_PLACEHOLDER = repeat(ASTERISK, 3);

export interface FormatValueByDataTypeOptions {
  formatBooleanValue?: (value: string) => string;
  accessPermissions?: VariableAccessPermissionType;
}

const DEFAULT_FORMAT_VALUE_BY_DATA_TYPE_OPTIONS = {
  formatBooleanValue: (value: string) => value.toUpperCase(),
};

const formatPhoneNumberWithPermissions = (phoneNumber: string, accessPermissions?: VariableAccessPermissionType) => {
  if (accessPermissions === VariableAccessPermissionType.PartialRead || accessPermissions === VariableAccessPermissionType.NoAccess) {
    return phoneNumber;
  }
  try {
    return formatPhoneNumber(phoneNumber);
  } catch (e) {
    return phoneNumber;
  }
}

const formatValueByDataType = (
  variableValue: VariableValue | null | undefined,
  sourceVariableData: VariableAdvancedDataTypeAttributes | undefined,
  options: FormatValueByDataTypeOptions = DEFAULT_FORMAT_VALUE_BY_DATA_TYPE_OPTIONS,
) => {
  if (typeof variableValue === 'undefined' || sourceVariableData === undefined) {
    return variableValue;
  }

  const visualDataType = getVisualDataType(sourceVariableData);
  const { currency, identificationNumberType, dateFormat } = sourceVariableData;

  const currencySymbol = currency ? getCurrencySymbol(currency) : '';

  // eslint-disable-next-line default-case
  switch (visualDataType) {
    case 'Number':
    case 'Monetary':
    case 'Percentage':
    case 'Boolean': {
      if (options.accessPermissions === VariableAccessPermissionType.NoAccess) {
        return FULLY_HIDDEN_VARIABLE_PLACEHOLDER;
      }
    }
  }

  if (variableValue === null) {
    return variableValue;
  }

  if (visualDataType === 'Number') {
    return maskNumberValue(variableValue.toString());
  }

  if (visualDataType === 'Monetary') {
    return formatMonetaryValue(variableValue as number, currencySymbol);
  }

  if (visualDataType === 'Percentage') {
    return formatPercentageValue(variableValue as number);
  }

  if (visualDataType === 'PhoneNumber') {
    return formatPhoneNumberWithPermissions(variableValue as string, options.accessPermissions);
  }

  if (visualDataType === 'IdentificationNumber' && identificationNumberType) {
    const INMask = getIdentificationNumberMask(identificationNumberType);

    return INMask(variableValue.toString());
  }

  if (visualDataType === 'Date') {
    return formatDateValue(variableValue, dateFormat, options.accessPermissions);
  }

  if (visualDataType === 'Boolean') {
    return options.formatBooleanValue ? options.formatBooleanValue(variableValue.toString()) : variableValue.toString();
  }

  return variableValue;
};

export default formatValueByDataType;
