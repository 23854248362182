import React, { FC, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { ReduxState } from 'types/redux';
import {
  WebhookEvent,
  WebhookEventDataFilter,
  WebhookEventSortingField,
  WebhookEventSortingType,
} from 'api/Webhooks/WebhookEventsApi';
import { NoResultsIcon } from 'static/images';
import WebhookEventsTable from 'components/Webhooks/WebhookEventsTable';
import QuestionIcon from 'components/QuestionIcon';
import SearchInput from 'components/SearchInput';
import SearchNotFound from 'components/SearchNotFound';
import FilterButton from 'components/FilterButton';
import NoItems from 'components/NoItems';
import { useDispatch, useSelector } from 'react-redux';
import useStateReset from 'hooks/useStateReset';
import pagination, { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from 'Webhooks/WebhookEventsTable/Pagination';
import { setSearchInputValue } from 'Webhooks/WebhookEventsTable/Filters/WebhookEventsTableFiltersStore';
import { changeSortingType } from 'Webhooks/WebhookEventsTable/WebhookEventsTableStore';
import { WebhookEventsTableActionType } from 'Webhooks/WebhookEventsTable/WebhookEventsTableActionType';
import { createGetWebhookEventsSelector } from 'WebhookEvents/Selectors';
import { getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import styles from './WebhookEventsDashboard.module.scss';

export interface WebhookEventsDashboardProps {
  onFiltersButtonClick: () => void;
  onClearFilters: () => void;
  onDisplayWebhookEvent: (webhookEvent: WebhookEvent) => void;
  onDeleteWebhookEvent: (id: string) => void;
  onResendWebhookEvent: (id: string) => void;
}

const WebhookEventsDashboard: FC<WebhookEventsDashboardProps> = ({
  onFiltersButtonClick,
  onClearFilters,
  onDeleteWebhookEvent,
  onDisplayWebhookEvent,
  onResendWebhookEvent,
}) => {
  const dispatch = useDispatch();
  const sortingType = useSelector((state: ReduxState) => state.webhookEventsTable.sortingType);
  const filters = useSelector((state: ReduxState) => state.webhookEventsTable.filters);

  useStateReset(WebhookEventsTableActionType.ResetState);

  const initialFiltersAttributes = { offset: 0, count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT };

  const params = {
    sortingType,
    ...filters,
  };

  const requestWebhookEvents = (filtersToApply: WebhookEventDataFilter, sortingTypeToApply: WebhookEventSortingType) => {
    dispatch(getWebhookEvents({
      filters: filtersToApply,
      sortingType: sortingTypeToApply,
      actionOrigin: WebhookEventsActionOrigin.WebhookEventsTable,
    }));
  };

  const getWebhookEventsSelector = useMemo(() => {
    return createGetWebhookEventsSelector();
  }, []);

  const webhookEventsIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  const webhookEvents = useSelector((state: ReduxState) => getWebhookEventsSelector(state, webhookEventsIds));

  const renderTooltip = () => (
    <>
      <p>Events include only the updated information</p>
      <p>and are automatically deleted after 3 days</p>
    </>
  );

  useEffect(() => {
    requestWebhookEvents({
      ...filters,
      dueCreatedDateFrom: filters.dueCreatedDateRange.from,
      dueCreatedDateTo: filters.dueCreatedDateRange.to,
      ...initialFiltersAttributes,
    }, sortingType);
  }, []);

  const handleSearchClear = () => {
    dispatch(setSearchInputValue(''));

    requestWebhookEvents({
      ...filters,
      ...initialFiltersAttributes,
      dueCreatedDateFrom: filters.dueCreatedDateRange.from,
      dueCreatedDateTo: filters.dueCreatedDateRange.to,
      search: '',
    }, sortingType);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(requestWebhookEvents, 300);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInputValue(event.target.value));

    debouncedSearch({
      ...filters,
      ...initialFiltersAttributes,
      dueCreatedDateFrom: filters.dueCreatedDateRange.from,
      dueCreatedDateTo: filters.dueCreatedDateRange.to,
      search: event.target.value,
    }, sortingType);
  };

  const handleSort = (sortingField: WebhookEventSortingField, ascending: boolean) => {
    dispatch(changeSortingType({
      field: sortingField,
      ascending,
    }));

    requestWebhookEvents(
      {
        ...filters,
        ...initialFiltersAttributes,
        dueCreatedDateFrom: filters.dueCreatedDateRange.from,
        dueCreatedDateTo: filters.dueCreatedDateRange.to,
      },
      { field: sortingField, ascending },
    );
  };

  const areFiltersChanged = () => {
    return filters.dueCreatedDateRange.to !== null
      || filters.dueCreatedDateRange.from !== null
      || !!filters.eventTypes.length
      || !!filters.statuses.length;
  };

  const shouldRenderNoItemsAddedSection = paginationProps.itemsTotal === 0 && !filters.search && !areFiltersChanged();

  const renderContent = () => {
    if (shouldRenderNoItemsAddedSection) {
      return (
        <NoItems className={styles.noItemsAddedSection} title="No events have been emitted yet!" />
      );
    }

    if (filters.search && !webhookEvents.length) {
      return (
        <SearchNotFound searchValue={filters.search} />
      );
    }

    if (areFiltersChanged() && !webhookEvents.length) {
      return (
        <NoItems
          className={styles.noResultsFoundContainer}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={onClearFilters}
        />
      );
    }

    return (
      <WebhookEventsTable
        source={webhookEvents}
        paginationProps={paginationProps}
        sortingType={sortingType}
        searchInputValue={filters.search}
        onSort={handleSort}
        onWebhookEventClick={onDisplayWebhookEvent}
        onWebhookEventDelete={onDeleteWebhookEvent}
        onWebhookEventResend={onResendWebhookEvent}
        withEndpointColumn
      />
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerTitleWithTooltip}>
          <h4 className={styles.headerTitle}>Events</h4>
          <QuestionIcon size={24} tooltip={renderTooltip()} />
        </div>
        {!shouldRenderNoItemsAddedSection && <FilterButton
          containerClassName={styles.filterButtonContainer}
          active={areFiltersChanged()}
          onClick={onFiltersButtonClick}
        />}
      </div>
      {!shouldRenderNoItemsAddedSection && <SearchInput
        value={filters.search}
        containerClassName={styles.searchInputContainer}
        placeholder="Search"
        onClear={handleSearchClear}
        onChange={handleSearchChange}
      />}
      {renderContent()}
    </div>
  );
};

export default WebhookEventsDashboard;
