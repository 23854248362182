import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { Decision, DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { DecisionsSortingFieldType } from 'Decisions/DecisionsStore';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';

export const DECISIONS_PER_PAGE_DEFAULT = 20;

export interface DecisionsPaginationParams {
  searchInputValue: string;
  sortingType: DecisionsSortingFieldType;
  selectedMembers: UserInfo[];
  decisionResults: DecisionResultType[];
  decisionSource: 'Api' | 'Web' | null;
  strategyStatus: NormalizedStatus | null;
  strategyName: string | null;
  decisionDate: {
    from: Date | null;
    to: Date | null;
  };
  applicationId?: string | null;
}

const decisionsPagination = Pagination<ReduxState, Decision, DecisionsPaginationParams>(
  'decisions',
  DECISIONS_PER_PAGE_DEFAULT,
);

export default decisionsPagination;
