import Fetcher from 'middlewares/Fetcher';
import { ProductCalculation, ProductCalculationsApi } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import {
  CreateProductCalculationAction,
  createProductCalculationSuccess,
  createProductCalculationFailure,
} from './ActionCreator';
import { ProductCalculationsType } from './ActionTypes';

const CreateProductCalculationMiddleware = (api: ProductCalculationsApi) =>
  Fetcher<ProductCalculation, CreateProductCalculationAction>(
    ProductCalculationsType.CreateProductCalculation,
    createProductCalculationSuccess,
    createProductCalculationFailure,
    (action) => {
      return api.create(action.payload);
    },
  );

export default CreateProductCalculationMiddleware;
