import React, { FC } from 'react';
import styles from './InputWithDataType.module.scss';
import clsx from 'clsx';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import { AutoCompletionAsyncProps } from 'components/AutoCompletion/AutoCompletionAsync';
import { Variable } from 'Variables/VariablesTypes';
import { Option } from 'components/SelectInput/SelectInput';

interface InputWithDataTypeProps extends Omit<AutoCompletionAsyncProps, 'fetchOptions'> {
  dataType?: string;
  description?: string;
  placeholderType?: string;
  needDataType?: boolean;
  onlyCustom?: boolean;
  getAdditionalOptionAttributes?: (variable: Variable) => Partial<Option>;
}

const InputWithDataTypeLabel: FC<InputWithDataTypeProps> = ({
  dataType,
  link,
  description,
  placeholderType,
  needDataType,
  onlyCustom,
  getAdditionalOptionAttributes,
  ...props
}) => {
  const { labelTitle, onChange, value, disabled, optionsListClassName } = props;
  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        <VariableSelector
          labelTitle={labelTitle}
          onChange={onChange}
          value={value}
          hasRightNeighbour
          disabled={disabled}
          dataType={needDataType ? dataType : undefined}
          onlyCustom={onlyCustom}
          getAdditionalOptionAttributes={getAdditionalOptionAttributes}
          optionsListClassName={optionsListClassName}
        />
        <div className={styles.dataTypeContainer}>
          <div className={styles.link}>{link}</div>
          <div className={clsx(styles.typeContainer, placeholderType && styles.typeContainerPlaceholder)}>
            {placeholderType || dataType}
          </div>
        </div>
      </div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default InputWithDataTypeLabel;
