import React, { FC } from 'react';
import RuleBuilderCore from 'components/RuleBuilderCore';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import styles from './RuleBuilderCoreAdapter.module.scss';
import { ComparisonOperator, ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import RuleBuilderDivider from 'components/RuleBuilderDivider/RuleBuilderDivider';
import { RuleBuilderDividerTextWrapper } from 'components/RuleBuilderDivider';
import { RulePopUpType } from 'RuleCreate/Types';

interface RuleBuilderCoreAdapterProps {
  onRuleChange: ({ ruleId, key, value }: { ruleId: number; key: string; value: string }) => void;
  ruleId: number;
  onRuleDelete: (id: number) => void;
  sourceVariableId: string | undefined;
  selectedComparison: ComparisonOperator | undefined;
  firstOperandValue: string;
  firstOperandVariableId: string | undefined;
  secondOperandValue: string;
  secondOperandVariableId: string | undefined;
  firstOperandType: ComparisonOperandType;
  secondOperandType: ComparisonOperandType;
  type: RulePopUpType;
  openVariableCreation: () => void;
}

const RuleBuilderCoreAdapter: FC<RuleBuilderCoreAdapterProps> = ({
  ruleId,
  onRuleChange,
  onRuleDelete,
  type,
  openVariableCreation,
  ...props
}) => {
  const setSourceVariableId = (value: string): void => {
    onRuleChange({ ruleId, key: 'sourceVariableId', value });
  };

  const setSelectedComparison = (value: string): void => {
    onRuleChange({ ruleId, key: 'selectedComparison', value });
  };

  const setFirstOperandValue = (value: string): void => {
    onRuleChange({ ruleId, key: 'firstOperandValue', value });
  };

  const setFirstOperandVariableId = (value: string): void => {
    onRuleChange({ ruleId, key: 'firstOperandVariableId', value });
  };

  const setSecondOperandValue = (value: string): void => {
    onRuleChange({ ruleId, key: 'secondOperandValue', value });
  };

  const setSecondOperandVariableId = (value: string): void => {
    onRuleChange({ ruleId, key: 'secondOperandVariableId', value });
  };

  const setFirstOperandType = (value: string): void => {
    onRuleChange({ ruleId, key: 'firstOperandType', value });
  };

  const setSecondOperandType = (value: string): void => {
    onRuleChange({ ruleId, key: 'secondOperandType', value });
  };

  const DeleteRuleButton = () => (
    <div className={styles.deleteRuleButton} onClick={() => onRuleDelete(ruleId)}>
      Delete Condition
    </div>
  );

  return (
    <>
      {ruleId > 0 && (
        <div className={styles.dividerContainer}>
          <RuleBuilderDivider>
            <RuleBuilderDividerTextWrapper>{type === 'OR' ? 'Or' : 'And'}</RuleBuilderDividerTextWrapper>
          </RuleBuilderDivider>
        </div>
      )}
      <RuleBuilderCore
        {...props}
        setSourceVariableId={setSourceVariableId}
        setSelectedComparison={setSelectedComparison}
        setFirstOperandValue={setFirstOperandValue}
        setFirstOperandVariableId={setFirstOperandVariableId}
        setSecondOperandValue={setSecondOperandValue}
        setSecondOperandVariableId={setSecondOperandVariableId}
        setFirstOperandType={setFirstOperandType}
        setSecondOperandType={setSecondOperandType}
        link={ruleId === 0 ? <CreateNewVariableLink onClick={openVariableCreation} /> : <DeleteRuleButton />}
      />
    </>
  );
};

export default RuleBuilderCoreAdapter;
