import React from 'react';
import { get } from 'lodash';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { IntermediariesColumnSizes } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import styles from './IntermediaryLine.module.scss';
import SearchHighlight from 'components/SearchHighlight';
import SearchMatchResults from 'components/SearchMatchResults';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import {
  getFormattedIntermediaryIdNumber,
  getFormattedIntermediaryPhoneNumber,
} from 'LoanOriginationSystemOrganization/Utils';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import { StandardVariables } from 'Variables/VariablesTypes';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import getSearchMatchingField from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/getSearchMatchingField';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import variables from 'components/RowActions/RowActions.module.scss';

interface IntermediaryLineProps {
  intermediary: Intermediary;
  standardVariables: StandardVariables;
  onDeleteIntermediaryPopupOpen: (intermediary: Intermediary) => Promise<void>;
  onEdit: (id: string) => void;
  searchInputValue: string;
}

const IntermediaryLine = ({
  intermediary,
  searchInputValue,
  standardVariables,
  onDeleteIntermediaryPopupOpen,
  onEdit,
}: IntermediaryLineProps) => {
  const userPermissionGroupId = useUserPermissionGroupId()!;

  const formattedPhone = getFormattedIntermediaryPhoneNumber(
    intermediary.variables[IntermediaryDefaultVariable.PhoneNumber],
    standardVariables,
  );

  const renderCellContent = (content: string) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue} multiWord>
        {content || '-'}
      </SearchHighlight>
    </OverflowedText>
  );

  return (
    <div className={styles.tableRowWrapper}>
      <TableRow
        useActions
        onClick={() => onEdit(intermediary.id)}
        noBottomBorder={searchInputValue.trim().length > 0}
        className={styles.tableRow}
      >
        <TableBodyCell width={IntermediariesColumnSizes.Name} className={styles.intermediaryName}>
          {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Name] as string)}
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Type}>
          {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Type] as string)}
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.IdNumber}>
          {renderCellContent(getFormattedIntermediaryIdNumber(intermediary.variables, standardVariables) as string)}
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Phone}>{renderCellContent(formattedPhone)}</TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Email}>
          {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Email] as string)}
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.CreatedAt} noPadding>
          <WithSystemApiUserAvatar user={intermediary.createdBy} className={styles.avatar} size="small" />
          <OverflowedText>{formatDate(intermediary.createdAt, DateTimeFormat.Short)}</OverflowedText>
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.UpdatedAt} noPadding>
          <WithSystemApiUserAvatar user={intermediary.updatedBy} className={styles.avatar} size="small" />
          <OverflowedText>{formatDate(intermediary.updatedAt, DateTimeFormat.Short)}</OverflowedText>
        </TableBodyCell>

        <RowActions
          actions={[
            {
              title: 'Edit Intermediary',
              handler: () => onEdit(intermediary.id),
              danger: false,
              separatorRequired: true,
            },
            {
              title: 'Delete Intermediary',
              handler: () => onDeleteIntermediaryPopupOpen(intermediary),
              danger: true,
            },
          ]}
        />
      </TableRow>
      {searchInputValue.trim().length > 0 ? (
        <TableRow className={styles.tableRow} noTopBorder onClick={() => onEdit(intermediary.id)}>
          <TableBodyCell width={IntermediariesColumnSizes.Name}> </TableBodyCell>
          <TableBodyCell width={100 - IntermediariesColumnSizes.Name} dashedTopBorder>
            <SearchMatchResults
              searchInputValue={searchInputValue}
              searchHighlights={intermediary.highlights ?? []}
              getMatchingField={getSearchMatchingField((path) => get(intermediary, path), standardVariables, userPermissionGroupId)}
            />
          </TableBodyCell>
          <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
            {' '}
          </TableBodyCell>
        </TableRow>
      ) : null}
    </div>
  );
};

export default IntermediaryLine;
