import Fetcher from 'middlewares/Fetcher';
import { DeleteIntermediaryAction, deleteIntermediaryFailure, deleteIntermediarySuccess } from './ActionCreator';
import {
  Intermediary,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { RequestError } from 'utils/fetch';
import IntermediaryHasApplicationsError from 'errors/IntermediaryHasApplicationsError';
import { LoanOriginationSystemIntermediariesActionType } from './ActionTypes';

const DeleteIntermediaryMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary, DeleteIntermediaryAction>(
    LoanOriginationSystemIntermediariesActionType.DeleteIntermediary,
    deleteIntermediarySuccess,
    deleteIntermediaryFailure,
    async (action) => {
      try {
        return await api.deleteIntermediary(action.payload.intermediaryId);
      } catch (err) {
        if (err instanceof RequestError && err.data && err.data.intermediaryHasApplications) {
          throw new IntermediaryHasApplicationsError(action.payload.intermediaryId, action.payload.intermediaryName);
        }

        throw err;
      }
    },
  );

export default DeleteIntermediaryMiddleware;
