import React, { FC, useEffect, useState } from 'react';
import { SmallFolderIcon } from 'static/images';
import { ProductConfigurationDocumentFolder } from 'api/Types';
import { ApplicationTab, ApplicationTabType } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import TextInput from 'components/TextInput';
import AutoCompletionSync from 'components/AutoCompletion/AutoCompletionSync';
import { Option } from 'components/SelectInput/SelectInput';
import ButtonWithImage from 'components/ButtonWithImage';
import WrapperWithTooltip from 'components/Tooltip';
import { useApplicationTabsApi, useDocumentFoldersApi } from 'providers/ApiServiceProvider';
import styles from './EditApplicationDocumentConfigurationPopup.module.scss';

export interface EditApplicationDocumentConfigurationData {
  name: string;
  folderId: string | null;
}

export interface EditApplicationDocumentConfigurationPopupProps {
  productId: string;
  onSave: (data: EditApplicationDocumentConfigurationData) => void;
  onClose: () => void;
  documentConfiguration?: ApplicationDocumentConfiguration;
  usePortal?: boolean;
  isLoading?: boolean;
}

const ROOT_FOLDER_OPTION = {
  name: 'Root Folder',
  value: '',
  icon: <SmallFolderIcon />
};

const getFolderOptions = (
  parentFolderId: string | null,
  parentFolderName: string,
  folders: ProductConfigurationDocumentFolder[],
): Array<Option> => {
  const childrenFolders = folders.filter((folder) => folder.parentId === parentFolderId);

  return childrenFolders.flatMap((folder) => {
    const folderName = `${parentFolderName} / ${folder.name}`;

    return [
      { name: folderName, value: folder.id, icon: <SmallFolderIcon /> } as Option,
      ...getFolderOptions(folder.id, folderName, folders),
    ];
  });
};

const EditApplicationDocumentConfigurationPopup: FC<EditApplicationDocumentConfigurationPopupProps> = ({
  productId,
  onSave,
  isLoading,
  onClose,
  usePortal,
  documentConfiguration,
}) => {
  const documentFoldersApi = useDocumentFoldersApi();
  const applicationTabsApi = useApplicationTabsApi();

  const [documentConfigurationData, setDocumentConfigurationData] = useState<EditApplicationDocumentConfigurationData>({
    name: '',
    folderId: null,
  });
  const [documentTab, setDocumentTab] = useState<ApplicationTab | null>();
  const [areTabsLoaded, setTabsLoaded] = useState(false);
  const [foldersOptions, setFoldersOptions] = useState<null | Option[]>(null);

  useEffect(() => {
    if (documentConfiguration) {
      setDocumentConfigurationData({
        name: documentConfiguration.name,
        folderId: documentConfiguration.folderId || null,
      })
    }
  }, [documentConfiguration]);

  useEffect(() => {
    documentFoldersApi
      .find(productId)
      .then((folders) => setFoldersOptions([
        ROOT_FOLDER_OPTION,
        ...getFolderOptions(null, ROOT_FOLDER_OPTION.name, folders),
      ]));

    applicationTabsApi.find(productId)
      .then((tabs) => {
        setTabsLoaded(true);
        setDocumentTab(tabs.find((tab) => tab.type === ApplicationTabType.Documents) || null);
      });
  }, [productId]);

  const isDataWasChanged = () => {
    if (!documentConfiguration) {
      return true;
    }

    const { folderId: currentFolderId = null, name: currentName } = documentConfiguration;
    const { folderId: updatedFolderId = null, name: updatedName } = documentConfigurationData;

    return currentName !== updatedName || currentFolderId !== updatedFolderId;
  }

  const isSaveButtonDisabled = () => {
    return !documentConfigurationData.name || !isDataWasChanged();
  }

  const handleDocumentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentConfigurationData({
      ...documentConfigurationData,
      name: event.target.value,
    });
  };

  const handleFolderChange = (option: Option) => {
    setDocumentConfigurationData({
      ...documentConfigurationData,
      folderId: option.value || null,
    });
  };

  const handleEditFoldersClick = () => {
    if (!documentTab) {
      return;
    }

    window.open(
      `${window.location.origin}/los/configuration/products/${productId}/application-setup?tab-id=${documentTab.id}`,
      '_blank',
    );
  };

  const renderEditFolderLinkTooltip = () => {
    if (!documentTab && areTabsLoaded) {
      return (
        <>
          <p>Add the Documents tab in</p>
          <p>Applications Views to edit folders</p>
        </>
      )
    }

    return null;
  };

  const renderLink = () => (
    <WrapperWithTooltip tooltip={renderEditFolderLinkTooltip()}>
      <span>
        <ButtonWithImage
          className={styles.editFolderButton}
          onClick={handleEditFoldersClick}
          disabled={!documentTab}
          title="Edit Folders"
          kind="link"
        />
      </span>
    </WrapperWithTooltip>
  );

  const title = documentConfiguration ? 'Edit Application Document' : 'Add Application Document';
  const saveButtonName = documentConfiguration ? 'Edit Document' : 'Add Document';

  return (
    <PopUp usePortal={usePortal} closePopUp={onClose} title={title} closable={!isLoading}>
      <PopUpContent>
        <TextInput
          value={documentConfigurationData.name}
          onChange={handleDocumentNameChange}
          labelTitle="Document Name"
          required
        />
        <AutoCompletionSync
          inputValueContainerClassName={styles.selectInput}
          labelTitle="Folder To Save Document"
          link={renderLink()}
          value={documentConfigurationData.folderId || ''}
          options={foldersOptions || []}
          loading={!foldersOptions}
          onChange={handleFolderChange}
        />
        <Button
          className={styles.saveButton}
          onClick={() => onSave(documentConfigurationData)}
          kind="primary"
          size="form"
          isLoading={isLoading}
          disabled={isSaveButtonDisabled()}
        >
          {saveButtonName}
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditApplicationDocumentConfigurationPopup;
