import { AnyAction } from 'redux';
import {
  GET_BRANCH_INFO_REQUEST,
  GET_BRANCH_INFO_REQUEST_SUCCESS,
  GET_BRANCH_INFO_REQUEST_FAILURE,
  REORDER_BRANCH_RULES,
  REORDER_BRANCH_RULES_SUCCESS,
  REORDER_BRANCH_RULES_FAILURE,
} from './ActionTypes';
import { BranchInfoState } from './Types';
import { cloneDeep, find, has } from 'lodash';
import { getBranchKey } from './utils/getBranchKey';

const initialState: BranchInfoState = {
  branchList: null,
  error: '',
  isLoading: false,
  rulesBlockingActionInProgress: false,
  conditionsBlockingActionInProgress: false,
};

const BranchInfoReducer = (state = initialState, action: AnyAction): BranchInfoState => {
  switch (action.type) {
    case GET_BRANCH_INFO_REQUEST: {
      const { moduleKey, branchIndex } = action.payload;
      const branchKey = getBranchKey(moduleKey, branchIndex);

      const getLoadingState = () => {
        if (!state.branchList) {
          return true;
        }
        return !has(state.branchList, branchKey);
      };

      return {
        ...state,
        error: '',
        isLoading: getLoadingState(),
        rulesBlockingActionInProgress: false,
        conditionsBlockingActionInProgress: false,
      };
    }
    case GET_BRANCH_INFO_REQUEST_SUCCESS: {
      const { ruleSet, conditions, displayName, branchKey, calculationMode } = action.payload;
      return {
        ...state,
        branchList: {
          ...state.branchList,
          [branchKey]: {
            rules: ruleSet,
            conditions,
            name: displayName,
            calculationMode,
            stateRecoveryCopy: {},
          },
        },
        isLoading: false,
      };
    }
    case GET_BRANCH_INFO_REQUEST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case REORDER_BRANCH_RULES: {
      const { reorderedRuleIds, ruleType, moduleKey, branchIndex } = action.payload;
      const branchKey = getBranchKey(moduleKey, branchIndex);

      const updatedRecoveryCopy = {
        ...state.branchList![branchKey].stateRecoveryCopy,
        [ruleType]: state.branchList![branchKey][ruleType],
      };

      const rulesRecoveryCopy = cloneDeep(state.branchList![branchKey][ruleType]);
      const updatedRules = reorderedRuleIds.map((id: string) => find(rulesRecoveryCopy, { id }));

      return {
        ...state,
        branchList: {
          ...state.branchList,
          [branchKey]: {
            ...state.branchList![branchKey],
            stateRecoveryCopy: updatedRecoveryCopy,
            [ruleType]: updatedRules,
          },
        },
        [ruleType === 'rules' ? 'rulesBlockingActionInProgress' : 'conditionsBlockingActionInProgress']: true,
      };
    }
    case REORDER_BRANCH_RULES_SUCCESS: {
      const { ruleType, moduleKey, branchIndex } = action.payload;
      const branchKey = getBranchKey(moduleKey, branchIndex);

      return {
        ...state,
        branchList: {
          ...state.branchList,
          [branchKey]: {
            ...state.branchList![branchKey],
            stateRecoveryCopy: {
              ...state.branchList![branchKey].stateRecoveryCopy,
              [ruleType]: [],
            },
          },
        },
        [ruleType === 'rules' ? 'rulesBlockingActionInProgress' : 'conditionsBlockingActionInProgress']: false,
      };
    }
    case REORDER_BRANCH_RULES_FAILURE: {
      const { branchKey, message, ruleType } = action.payload;
      return {
        ...state,
        branchList: {
          ...state.branchList,
          [branchKey]: {
            ...state.branchList![branchKey],
            [ruleType]: state.branchList![branchKey].stateRecoveryCopy[ruleType],
            stateRecoveryCopy: {
              ...state.branchList![branchKey].stateRecoveryCopy,
              [ruleType]: [],
            },
          },
        },
        rulesBlockingActionInProgress: false,
        conditionsBlockingActionInProgress: false,
        error: message,
      };
    }
    default:
      return state;
  }
};

export default BranchInfoReducer;
