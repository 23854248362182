import React, { useEffect } from 'react';
import styles from './CreateTask.module.scss';
import { CloseButtonImage } from 'static/images';
import CreateTaskForm from './CreateTaskForm';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getOrganizationApplications } from 'LoanOriginationSystemOrganization/ActionCreator';
import { createTask } from 'LoanOriginationSystemTasks/CreateTask/ActionCreator';
import { NewTask } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import useOrganizationMembers from 'hooks/useOrganizationMembers';

interface CreateTaskProps {
  onClose: () => void;
  setDataWasChanged: (changed: boolean) => void;
  applicationId?: string;
  onCreated: () => void;
}

const CreateTask = ({ onClose, setDataWasChanged, applicationId, onCreated }: CreateTaskProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { applicationsListInfo } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemOrganization,
  );
  const { isCreating } = useSelector((state: ReduxState) => state.loanOriginationSystemTasks.createTask);
  const organizationMembers = useOrganizationMembers();

  useEffect(() => {
    dispatch(getOrganizationApplications());
  }, []);

  const handleTaskCreate = async (newTaskData: NewTask) => {
    await dispatchRoutine(createTask(newTaskData));
    onCreated();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Create Task</h2>
          <CloseButtonImage className={styles.closeButton} onClick={onClose} />
        </div>
        {organizationMembers.length > 0 && (
          <CreateTaskForm
            isCreating={isCreating}
            handleTaskCreate={handleTaskCreate}
            applications={applicationsListInfo}
            members={organizationMembers}
            setDataWasChanged={setDataWasChanged}
            applicationId={applicationId}
          />
        )}
      </div>
    </div>
  );
};

export default CreateTask;
