import React, { CSSProperties, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import {
  BaseVariableConfiguration,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import EditableInput from 'components/EditableInput';
import DroppableList from 'components/DndList/DroppableList';
import VariableConfigurationDraggableInputStub from 'components/LoanOriginationSystem/VariablesConfiguration/VariableConfigurationDraggableInputStub';
import { FormSkeleton } from 'components/Skeleton';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import styles from './VariablesConfigurationCard.module.scss';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import trimAll from 'utils/trimAll';
import { validateLettersNumbersWithSpecialCharacters } from 'input-validators';

export interface VariablesConfigurationCardProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CalculationType extends BaseCalculation,
> {
  title?: string;
  isTitleEditable?: boolean;
  className?: string;
  columns?: number;
  hideTitle?: boolean;
  calculations?: CalculationType[] | null;
  placeholderStyle: CSSProperties | null;
  configurations: VariableConfigurationType[] | null;
  onUpdateCardName?: (name: string) => void;
  onDeleteVariableConfiguration: (variableConfiguration: VariableConfigurationType) => void;
  onChangeVariableConfigurationRequiredAttribute?: (variableConfiguration: VariableConfigurationType, required: boolean) => void;
  onDeleteVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onAddVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onEditVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  draggingId: string | null;
  cardId: string;
  droppableType: string;
  isDragDisabled?: boolean;
  isDropDisabled?: boolean;
  showActions?: boolean;
  cardActions?: RowAction[];
}

export const DEFAULT_VARIABLES_COLUMNS_LENGTH = 2;
const MAX_CARD_NAME_LENGTH = 40;
const DEFAULT_VARIABLE_OFFSET = 24;

const getDroppableId = (cardId: string, column: number) => `${cardId}-${column}`;
const getDraggableId = (itemId: string | number, containerDroppableId: string) => `item-${containerDroppableId}-${itemId}`;

const VariablesConfigurationCard = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CalculationType extends BaseCalculation,
>({
  configurations,
  columns = DEFAULT_VARIABLES_COLUMNS_LENGTH,
  className,
  cardId,
  calculations,
  isTitleEditable,
  placeholderStyle,
  onDeleteVariableConfiguration,
  title,
  formatVariableConfigurationDisplayTitle,
  isDragDisabled,
  isDropDisabled,
  draggingId,
  droppableType,
  showActions,
  cardActions,
  onUpdateCardName,
  hideTitle,
  onChangeVariableConfigurationRequiredAttribute,
  onAddVariableConfigurationConditionalDisplayRule,
  onDeleteVariableConfigurationConditionalDisplayRule,
  onEditVariableConfigurationConditionalDisplayRule,
}: VariablesConfigurationCardProps<VariableConfigurationType, CalculationType>) => {
  const cardNameInputProps = useAsyncValueInputProps({
    value: title || '',
    handleChange: (newCardName) => {
      const correctCardName = trimAll(newCardName);

      onUpdateCardName?.(correctCardName);
    },
    saveEmptyValue: true,
  });

  const layout = useFormLayout(configurations, columns);

  const variablesContainerStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  const handleVariableConfigurationDelete = useCallback((variableConfiguration: VariableConfigurationType) => {
    onDeleteVariableConfiguration(variableConfiguration);
  }, [onDeleteVariableConfiguration]);

  const handleChangeVariableConfigurationRequiredAttribute = useCallback((variableConfiguration: VariableConfigurationType) => {
    onChangeVariableConfigurationRequiredAttribute?.(variableConfiguration, !variableConfiguration.required);
  }, [onChangeVariableConfigurationRequiredAttribute]);

  const calculationsBySystemName = useMemo(() => {
    if (!calculations) {
      return {};
    }

    return calculations.reduce((previousCalculationsBySystemName, calculation) => ({
      ...previousCalculationsBySystemName,
      [calculation.variable.systemName]: calculation,
    }), {});
  }, [calculations]);

  const renderDraggableLayoutItem = (variableConfiguration: VariableConfigurationType, columnIndex: number) => {
    const { id, variable } = variableConfiguration;
    const isCalculatedVariable = calculationsBySystemName[variable.systemName];

    return (
      <VariableConfigurationDraggableInputStub
        key={id}
        variableConfiguration={variableConfiguration}
        className={styles.layoutItem}
        isDragging={getDraggableId(id, getDroppableId(cardId, columnIndex)) === draggingId}
        onDelete={handleVariableConfigurationDelete}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        onChangeRequiredAttributeOption={handleChangeVariableConfigurationRequiredAttribute}
        onAddConditionalDisplayRule={onAddVariableConfigurationConditionalDisplayRule}
        onDeleteConditionalDisplayRule={onDeleteVariableConfigurationConditionalDisplayRule}
        onEditConditionalDisplayRule={onEditVariableConfigurationConditionalDisplayRule}
        isCalculated={isCalculatedVariable}
      />
    );
  };

  const renderColumn = (column: Array<VariableConfigurationType>, index: number) => {
    return (
      <div className={styles.variablesColumn} key={index}>
        <DroppableList<VariableConfigurationType>
          droppableId={getDroppableId(cardId, index)}
          droppableType={droppableType}
          items={column}
          renderListItem={(variable) => renderDraggableLayoutItem(variable, index)}
          listItemClassName={styles.variable}
          withPlaceholder
          placeholderStyles={{
            ...placeholderStyle,
            width: placeholderStyle?.width
              ? `calc(${placeholderStyle.width}px - ${DEFAULT_VARIABLE_OFFSET}px)`
              : placeholderStyle?.width,
          }}
          getDraggableId={getDraggableId}
          isDropDisabled={isDropDisabled}
          isDragDisabled={isDragDisabled}
          placeholderClassName={styles.placeholder}
        />
      </div>
    );
  };

  const renderVariablesConfigurationCardBody = () => {
    if (!layout) {
      return <FormSkeleton />;
    }

    return (
      <div style={variablesContainerStyle} className={styles.variablesContainer}>
        {layout.map(renderColumn)}
      </div>
    );
  };

  const renderTitle = () => (
    <EditableInput
      {...cardNameInputProps}
      placeholder="Section Name"
      className={clsx(styles.configurationCardTitle, !configurations?.length && styles.noItemsTitle)}
      disabled={!isTitleEditable}
      maxLength={MAX_CARD_NAME_LENGTH}
      valueValidator={validateLettersNumbersWithSpecialCharacters}
    />
  );

  return (
    <RowActionsContainer>
      <div className={clsx(styles.configurationCard, hideTitle && styles.withoutTitleConfigurationCard, className)}>
        <div className={styles.configurationCardHeader}>
          {!hideTitle && renderTitle()}
          {showActions && <RowActions className={styles.rowActions} actions={cardActions || []} />}
        </div>
        {renderVariablesConfigurationCardBody()}
      </div>
    </RowActionsContainer>
  );
};

export default VariablesConfigurationCard;
