import React from 'react';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import styles from './DecisionView.module.scss';

const DecisionResultsHeaderSkeleton = () => (
  <>
    <div className={styles.header}>
      <SkeletonRectangle width="600px" color="primary6" height="25px" />
    </div>
    <div className={styles.decisionDescription}>
      <SkeletonCircle width="24px" height="24px" color="primary10" marginRight="8px" />
      <SkeletonRectangle width="232px" height="16px" color="primary6" className={styles.createdDateSkeleton} />
    </div>
  </>

);

export default DecisionResultsHeaderSkeleton;
