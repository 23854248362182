import React from 'react';

import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import { DropdownImage } from 'static/images';

import styles from './AutoCompletionStub.module.scss';

const AutoCompletionStub = ({ placeholder, inputIcon, ...restProps }: TextInputStubProps) => {
  const renderPlaceholder = () => (
    <>
      <div>{placeholder}</div>
      {!inputIcon && <DropdownImage />}
    </>
  );

  return (
    <TextInputStub {...restProps} inputIcon={inputIcon} stubInputClassName={styles.stubInput} placeholder={renderPlaceholder()} />
  );
};

export default AutoCompletionStub;
