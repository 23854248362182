import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { isFulfilled } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { deleteWebhookEvent, getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';

const webhookEventsTableUpdatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isFulfilled(deleteWebhookEvent)(action) && action.meta.actionOrigin === WebhookEventsActionOrigin.WebhookEventsTable) {
    const { webhookEventsTable } = getState();

    dispatch(getWebhookEvents({
      filters: {
        search: webhookEventsTable.filters.search,
        statuses: webhookEventsTable.filters.statuses,
        eventTypes: webhookEventsTable.filters.eventTypes,
        dueCreatedDateTo: webhookEventsTable.filters.dueCreatedDateRange.to,
        dueCreatedDateFrom: webhookEventsTable.filters.dueCreatedDateRange.from,
        count: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
        offset: 0,
      },
      sortingType: webhookEventsTable.sortingType,
      actionOrigin: WebhookEventsActionOrigin.WebhookEventsTable,
    }) as unknown as AnyAction);
  }

  return result;
}) as Middleware;

export default webhookEventsTableUpdatesMiddleware;
