import React, { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import EditableInput from 'components/EditableInput';
import styles from './BaseTabLayout.module.scss';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import trimAll from 'utils/trimAll';
import { validateLettersNumbersWithSpecialCharacters } from 'input-validators';

export interface BaseTabLayoutProps {
  className?: string;
  inputClassName?: string;
  tab: ApplicationTab;
  onTabNameUpdate: (tabId: string, newName: string) => void;
  renderActions?: () => ReactNode;
  renderSubtitle?: () => ReactNode;
}

const TAB_MAX_NAME_LENGTH = 40;

const BaseTabLayout = ({
  children,
  tab,
  onTabNameUpdate,
  renderSubtitle,
  renderActions,
  className,
  inputClassName,
}: PropsWithChildren<BaseTabLayoutProps>) => {
  const tabNameInputProps = useAsyncValueInputProps({
    value: tab.name,
    placeholderText: tab.name,
    handleChange: (newNabName) => {
      const correctTabName = trimAll(newNabName);

      onTabNameUpdate(tab.id, correctTabName);
    },
  });

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <EditableInput
            {...tabNameInputProps}
            className={clsx(styles.editableInput, inputClassName)}
            maxLength={TAB_MAX_NAME_LENGTH}
            valueValidator={validateLettersNumbersWithSpecialCharacters}
          />

          {renderSubtitle && renderSubtitle()}
        </div>

        {renderActions && <div className={styles.actionsContainer}>{renderActions()}</div>}
      </div>
      {children}
    </div>
  );
};

export default BaseTabLayout;
