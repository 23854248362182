import React from 'react';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './CoBorrowersInfo.module.scss';

export interface CoBorrowersInfoProps {
  coBorrowerFullNames: string[];
}

const CoBorrowersInfo = ({ coBorrowerFullNames }: CoBorrowersInfoProps) => {
  if (!coBorrowerFullNames.length) {
    return null;
  }

  const renderCoBorrowerFullName = (name: string, index: number) => (
    <p key={`${name}-${index}`} className={styles.name}>{name}</p>
  );

  const renderTooltip = () => (
    <>
      {coBorrowerFullNames.map(renderCoBorrowerFullName)}
    </>
  );

  return (
    <WrapperWithTooltip tooltip={renderTooltip()}>
      <div className={styles.info}>+{coBorrowerFullNames.length}</div>
    </WrapperWithTooltip>
  )
};

export default CoBorrowersInfo;
