import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './DecisionEngine.module.scss';
import Decisions from 'components/Decisions';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import {
  openRunDecisionPopup,
} from 'Decisions/DecisionsStore';
import {
  openDeleteDecisionPopUp,
} from 'Decisions/DeleteDecisionStore';
import { Decision } from 'api/DecisionEngine/DecisionApi';
import { useQueryParams } from 'hooks/useQueryParam';
import { useHistory } from 'react-router';

interface DecisionEngineTabProps {
  applicationId: string;
}

const DecisionEngineTab = ({ applicationId }: DecisionEngineTabProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const organizationMembers = useOrganizationMembers();

  const handleOpenDeleteDecisionPopUp = (id: string) => {
    dispatch(openDeleteDecisionPopUp({ decisionId: id }));
  };
  const handleOpenRunDecisionPopup = () => {
    dispatch(openRunDecisionPopup());
  }
  const handleDecisionLink = (decision: Decision) => {
    if (decision.resultsCount > 1) {
      history.push(`?tab-id=${queryParams.get('tab-id')}&decision-id=${decision.id}`);
      return;
    }
    history.push(`?tab-id=${queryParams.get('tab-id')}&decision-overview-id=${decision.id}`);
  }

  return (
    <Decisions
      embedded
      title="Decision Engine"
      titleClass={styles.headerTitle}
      applicationId={applicationId}
      members={organizationMembers}
      handleOpenDeleteDecisionPopUp={handleOpenDeleteDecisionPopUp}
      openRunDecisionPopup={handleOpenRunDecisionPopup}
      onDecisionLink={handleDecisionLink}
    />
  );
};

export default DecisionEngineTab;
