import { Group } from '@visx/group';
import React, { FC } from 'react';
import ChartPoint, { ChartPointProps } from './ChartPoint';

interface ChartPointGroupProps extends React.SVGProps<SVGGElement> {
  data: ChartPointProps[];
}

const ChartPointGroup: FC<ChartPointGroupProps> = ({ data, ...svgProps }) => {
  return (
    <Group {...svgProps}>
      {data.map((props) => (
        <ChartPoint key={`${props.x}-${props.y}`} {...props} />
      ))}
    </Group>
  );
};

export default ChartPointGroup;
