import Debouncer from 'middlewares/Debouncer';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getProducts } from 'LoanOriginationSystemProducts/ActionCreator';
import { LoanOriginationSystemProductsFiltersActionTypes } from 'LoanOriginationSystemProducts/Filters/ActionTypes';

export default Debouncer<UserInfo[]>(
  (payload, getState) => {
    const { loanOriginationSystemProducts } = getState();
    const { filters } = loanOriginationSystemProducts;

    return getProducts(filters);
  },
  [LoanOriginationSystemProductsFiltersActionTypes.FilterProductsByMembers],
);
