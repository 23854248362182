import React from 'react';
import clsx from 'clsx';
import { Droppable } from 'react-beautiful-dnd';
import DroppableListContent, { DroppableListItemsProps } from 'components/DndList/DroppableListItems/DroppableListItems';
import DndListPlaceholder from 'components/DndList/DndListPlaceholder';

import styles from 'components/DndList/DndList.module.scss';

export interface DroppableListItemProps<Item> extends DroppableListItemsProps<Item> {
  droppableId: string;
  placeholderStyles?: React.CSSProperties | null;
  listClassName?: string;
  placeholderClassName?: string;
  withPlaceholder?: boolean;
  isDropDisabled?: boolean;
  droppableType?: string;
  direction?: 'vertical' | 'horizontal';
}

const DroppableList = <Item extends { id: string | number }>({
  droppableId,
  withPlaceholder,
  listClassName,
  placeholderStyles,
  droppableType,
  isDropDisabled,
  direction = 'vertical',
  placeholderClassName,
  ...draggableListContentProps
}: DroppableListItemProps<Item>) => {

  const renderPlaceholder = () => {
    return withPlaceholder && <DndListPlaceholder className={placeholderClassName} style={placeholderStyles!} />;
  };

  const containerClasses = clsx(
    styles.listContainer,
    { [styles.vertical]: direction === 'vertical', [styles.horizontal]: direction === 'horizontal' },
    listClassName,
  );

  return (
    <Droppable
      direction={direction}
      droppableId={droppableId}
      type={droppableType || droppableId}
      isDropDisabled={isDropDisabled}
    >
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={containerClasses}>
          <DroppableListContent<Item>
            {...droppableProvided.droppableProps}
            {...draggableListContentProps}
            droppableId={droppableId}
          />
          {droppableProvided.placeholder}
          {placeholderStyles && snapshot.isDraggingOver && renderPlaceholder()}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableList;
