import React, { FC } from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import styles from './InformationPopup.module.scss';

export interface InformationPopupProps {
  title: string;
  description: string;
  onClose: () => void;
  usePortal?: boolean;
}

const InformationPopup: FC<InformationPopupProps> = ({ usePortal, onClose, title, description }) => {
  return (
    <PopUp usePortal={usePortal} closePopUp={onClose} title={title}>
      <PopUpContent hasTopMargin>
        <p className={styles.description}>{description}</p>
        <Button
          kind="secondary"
          size="form"
          onClick={onClose}
        >
          Okay, Go Back
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default InformationPopup;
