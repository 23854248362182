import { AnyAction } from 'redux';
import loanOriginationSystemApplicationsReducer from 'LoanOriginationSystemApplications/Reducer';
import loanOriginationSystemApplicationPageReducer from 'LoanOriginationSystemApplicationPage/Reducer';
import loanOriginationSystemIntermediariesReducer from 'LoanOriginationSystemIntermediariesPage/Reducer';
import loanOriginationSystemBorrowersReducer from 'LoanOriginationSystemBorrowers/Reducer';
import loanOriginationSystemBorrowerDetailsReducer from 'LoanOriginationSystemBorrowerDetails/Reducer';
import variablesListReducer from 'LoanOriginationSystemVariablesList/Reducer';
import loanOriginationSystemLabelsReducer from 'LoanOriginationSystemLabelsPage/Reducer';
import loanOriginationSystemTasksReducer from 'LoanOriginationSystemTasks/Reducer';
import loanOriginationSystemDocumentsReducer from 'LoanOriginationSystemApplicationPage/Documents/Reducer';
import loanOriginationSystemOrganizationReducer from 'LoanOriginationSystemOrganization/Reducer';
import loanOriginationSystemApplicationTabsReducer from 'LoanOriginationSystemApplicationTabs/Reducer';
import { LoanOriginationSystemOrganizationState } from 'LoanOriginationSystemOrganization/Types';
import loanOriginationSystemProductsReducer from 'LoanOriginationSystemProducts/Reducer';
import { LoanOriginationSystemApplicationTabsState } from 'LoanOriginationSystemApplicationTabs/Types';
import automatedProcessesReducer from 'ProcessAutomation/Reducer';
import { ProcessAutomationState } from 'ProcessAutomation/Types';
import loanOriginationSystemDeclineReasonsReducer from 'LoanOriginationSystemDeclineReasons/Reducer';
import { LoanOriginationSystemDeclineReasonsState } from 'LoanOriginationSystemDeclineReasons/Types';
import loanOriginationSystemApplicationStatusesReducer from 'LoanOriginationSystemApplicationStatuses/Reducer';
import { LoanOriginationSystemApplicationStatusesState } from 'LoanOriginationSystemApplicationStatuses/Types';
import uploadSecureDocumentReducer from 'UploadSecureDocument/Reducer';
import { UploadSecureDocumentState } from 'UploadSecureDocument/Types';
import LoanOriginationSystemIntermediaryDetailsReducer from 'LoanOriginationSystemIntermediaryDetails/Reducer';
import applicationDataTabCardsReducer, { ApplicationDataTabCardsState } from 'ApplicationDataTabCards/ApplicationDataTabCardsStore';
import applicationDataTabVariableConfigurationsReducer, {
  ApplicationDataTabVariableConfigurationsState,
} from 'ApplicationDataTabVariableConfigurations/ApplicationDataTabVariableConfigurationsStore';
import ProductConfigurationDocumentFoldersReducer from 'ProductConfigurationDocumentFolders/Reducer';
import ReportingDashboardReducer from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import ProductCalculationsReducer from 'ProductCalculations/Reducer';
import { ProductCalculationsState } from 'ProductCalculations/Types';
import borrowerProfileCardsReducer from 'BorrowerProfileCards/BorrowerProfileCardsStore';
import borrowerVariableConfigurationsReducer from 'BorrowerVariableConfigurations/BorrowerVariableConfigurationsStore';
import intermediaryProfileCardsReducer from 'IntermediaryProfileCards/IntermediaryProfileCardsStore';
import intermediaryVariableConfigurationsReducer from 'IntermediaryVariableConfigurations/IntermediaryVariableConfigurationsStore';
import applicationDetailsCardsReducer from 'ApplicationDetailsCards/ApplicationDetailsCardsStore';
import applicationVariableConfigurationsReducer from 'ApplicationVariableConfigurations/ApplicationVariableConfigurationsStore';
import applicationDocumentConfigurationsReducer from 'ApplicationDocumentConfigurations/ApplicationDocumentConfigurationsStore';

export default {
  loanOriginationSystemApplications: loanOriginationSystemApplicationsReducer,
  loanOriginationSystemApplicationPage: loanOriginationSystemApplicationPageReducer,
  loanOriginationSystemIntermediariesState: loanOriginationSystemIntermediariesReducer,
  loanOriginationSystemBorrowers: loanOriginationSystemBorrowersReducer,
  loanOriginationSystemBorrowerDetails: loanOriginationSystemBorrowerDetailsReducer,
  loanOriginationSystemTasks: loanOriginationSystemTasksReducer,
  loanOriginationSystemDocuments: loanOriginationSystemDocumentsReducer,
  applicationTabsData: loanOriginationSystemApplicationTabsReducer as (
    state: LoanOriginationSystemApplicationTabsState,
    actions: AnyAction,
  ) => LoanOriginationSystemApplicationTabsState,
  applicationStatusesData: loanOriginationSystemApplicationStatusesReducer as (
    state: LoanOriginationSystemApplicationStatusesState,
    action: AnyAction,
  ) => LoanOriginationSystemApplicationStatusesState,
  loanOriginationSystemOrganization: loanOriginationSystemOrganizationReducer as (
    state: LoanOriginationSystemOrganizationState,
    action: AnyAction,
  ) => LoanOriginationSystemOrganizationState,
  applicationDataTabCards: applicationDataTabCardsReducer as (
    state: ApplicationDataTabCardsState,
    action: AnyAction,
  ) => ApplicationDataTabCardsState,
  applicationDataTabVariableConfigurations: applicationDataTabVariableConfigurationsReducer as (
    state: ApplicationDataTabVariableConfigurationsState,
    action: AnyAction,
  ) => ApplicationDataTabVariableConfigurationsState,
  loanOriginationSystemVariablesList: variablesListReducer,
  loanOriginationSystemLabelsState: loanOriginationSystemLabelsReducer,
  loanOriginationSystemProducts: loanOriginationSystemProductsReducer,
  loanOriginationSystemAutomatedProcesses: automatedProcessesReducer as (
    state: ProcessAutomationState,
    action: AnyAction,
  ) => ProcessAutomationState,
  loanOriginationSystemDeclineReasons: loanOriginationSystemDeclineReasonsReducer as (
    state: LoanOriginationSystemDeclineReasonsState,
    action: AnyAction,
  ) => LoanOriginationSystemDeclineReasonsState,
  uploadSecureDocument: uploadSecureDocumentReducer as (
    state: UploadSecureDocumentState,
    action: AnyAction,
  ) => UploadSecureDocumentState,
  loanOriginationSystemIntermediaryDetails: LoanOriginationSystemIntermediaryDetailsReducer,
  productConfigurationDocumentFolders: ProductConfigurationDocumentFoldersReducer,
  reportingDashboard: ReportingDashboardReducer,
  productCalculations: ProductCalculationsReducer as (
    state: ProductCalculationsState,
    action: AnyAction,
  ) => ProductCalculationsState,
  borrowerProfileCards: borrowerProfileCardsReducer,
  borrowerVariableConfigurations: borrowerVariableConfigurationsReducer,
  intermediaryProfileCards: intermediaryProfileCardsReducer,
  intermediaryVariableConfigurations: intermediaryVariableConfigurationsReducer,
  applicationDetailsCards: applicationDetailsCardsReducer,
  applicationVariableConfigurations: applicationVariableConfigurationsReducer,
  applicationDocumentConfigurations: applicationDocumentConfigurationsReducer,
};
