import { RuleData, RuleType } from 'BranchInfo/Types';
import RuleRow from 'components/RuleRow';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import React, { FC } from 'react';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import styles from './RulesTable.module.scss';
import { RulesOutputsTitles } from './Types';
import ruleTableStyles from 'components/RuleRow/RuleRow.module.scss';
import WithBordersDndList from 'components/WithBordersDndList';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import DndList from 'components/DndList';
import { EntityType } from 'RuleCreate/Types';
import SkeletonRuleLine from './SkeletonRuleLine';
import clsx from 'clsx';

interface RulesTableProps {
  strategyId: string;
  rulesList: RuleData[];
  moduleType: ModuleType;
  openDeleteRulePopUp: (index: number, entityType: EntityType) => void;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  onOpenEditRulePopUp: (data: RuleEditPopUpData) => void;
  isLoading: boolean;
}

const DEFAULT_ROWS_AMOUNT = 10;

const getTableHeader = (moduleType: ModuleType, rulesLength: number) => (
  <div className={clsx(styles.tableHeaderRow, !rulesLength && styles.tableHeaderUnderline)}>
    {moduleType === 'assignments' || moduleType === 'calculations' ? (
      <>
        <div className={styles.variableHeaderCellWide}>{moduleType === 'assignments' ? 'Output Variable' : 'Calculated Variable'}</div>
        <div className={styles.valueHeaderCellWide}>
          {moduleType === 'assignments' ? 'Result Assigned' : 'Formula'}
        </div>
      </>
    ) : (
      <>
        <div className={styles.variableHeaderCell}>Variable</div>
        <div className={styles.comparisonHeaderCell}>Comparison</div>
        <div className={styles.valueHeaderCell}>Value</div>
        <div className={styles.outputHeaderCell}>{RulesOutputsTitles[moduleType]}</div>
      </>
    )}
  </div>
);

const skeletonRender = (moduleType: ModuleType) => {
  const skeletonRows = new Array(DEFAULT_ROWS_AMOUNT).fill(null);
  return skeletonRows.map((_, index) => <SkeletonRuleLine moduleType={moduleType} key={index} />);
};

const RulesTable: FC<RulesTableProps> = ({
  strategyId,
  rulesList,
  moduleType,
  openDeleteRulePopUp,
  handleRuleReorder,
  onOpenEditRulePopUp,
  isLoading,
}) => {
  const isSimpleTable = moduleType === 'assignments' || moduleType === 'calculations';
  const renderRule = (rule: RuleData, index: number, draggableSnapshot: DraggableStateSnapshot) => {
    const { id, ruleType, type } = rule;
    const openEditRulePopUp = () => onOpenEditRulePopUp({ id, ruleType, ruleModuleType: type, entityType: 'module' });
    return (
      <RuleRow
        key={`rule_${rule.id}`}
        strategyId={strategyId}
        type="ruleset"
        variableCellClass={isSimpleTable ? styles.variableWide : styles.variable}
        valueCellClass={isSimpleTable ? styles.valueWide : styles.value}
        outputCellClass={isSimpleTable ? undefined : styles.output}
        comparisonCellClass={isSimpleTable ? undefined : styles.comparison}
        rule={rule}
        showConditionOutput={!isSimpleTable}
        hideComparison={isSimpleTable}
        isDragging={draggableSnapshot.isDragging}
        openDeleteRulePopUp={() => openDeleteRulePopUp(index, 'module')}
        openEditRulePopUp={openEditRulePopUp}
        moduleType={moduleType}
      />
    );
  };

  return (
    <div>
      {getTableHeader(moduleType, rulesList.length)}
      {!isLoading ? (
        <WithBordersDndList
          droppableId="rulesList"
          items={rulesList}
          renderListItem={renderRule}
          handleReorder={(list: RuleData[]) => handleRuleReorder(list, 'rules')}
          renderInPortal
          listItemClassName={ruleTableStyles.draggableRuleRow}
          withPlaceholder
        >
          {(listProps) => <DndList {...listProps} />}
        </WithBordersDndList>
      ) : (
        <div className={styles.skeletonTable}>{skeletonRender(moduleType)}</div>
      )}
    </div>
  );
};

export default RulesTable;
