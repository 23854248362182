import React from 'react';
import { useDispatch } from 'react-redux';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { setApplicationStatusRulesValidationData } from 'LoanOriginationSystemApplications/ActionCreator';
import styles from './ApplicationStatusChangeErrorNotification.module.scss';

export interface ApplicationStatusChangeErrorNotificationProps {
  applicationId: string;
  applicationDisplayId: string;
  applicationName: string;
  status: ApplicationStatus;
  statusRulesValidationResult: ApplicationStatusRulesValidationResult;
}

const ApplicationStatusChangeErrorNotification = ({
  applicationId,
  applicationDisplayId,
  applicationName,
  status,
  statusRulesValidationResult,
}: ApplicationStatusChangeErrorNotificationProps) => {
  const dispatch = useDispatch();

  const handleViewDetailsClick = () => {
    dispatch(
      setApplicationStatusRulesValidationData({
        applicationId,
        applicationDisplayId,
        applicationName,
        status,
        statusRulesValidationResult,
      }),
    );
  };

  return (
    <div>
      This status change is not allowed because one or more status rules do not pass.{' '}
      <strong className={styles.viewDetails} onClick={handleViewDetailsClick}>
        View Details
      </strong>
    </div>
  );
};

export default ApplicationStatusChangeErrorNotification;
