import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

const getCardsById = (state: ReduxState) => state.intermediaryProfileCards.cardsById;
const getIntermediaryVariablesConfigurationsById = (state: ReduxState) => state.intermediaryVariableConfigurations.configurationsById;

export const getIntermediaryProfileCardsSelector = createSelector(
  [getCardsById, getIntermediaryVariablesConfigurationsById],
  (cardsById, configurationsById) => {
    const cards = Object.values(cardsById);

    if (!cards.length) {
      return null;
    }

    return cards.map((card) => ({
      ...card,
      fields: card.fields.map((fieldId) => configurationsById[fieldId]),
    }));
  },
);
