enum ReportingDashboardChartName {
  ApplicationLoanAmountTotal = 'ApplicationLoanAmountTotal',
  ApplicationNumberTotal = 'ApplicationNumberTotal',
  ApprovedApplicationsLoanAmountTotal = 'ApprovedApplicationsLoanAmountTotal',
  ApprovedApplicationsNumberTotal = 'ApprovedApplicationsNumberTotal',
  BorrowerNumberTotal = 'BorrowerNumberTotal',
  NewBorrowerNumberTotal = 'NewBorrowerNumberTotal',
  IntermediaryNumberTotal = 'IntermediaryNumberTotal',
  NewIntermediaryNumberTotal = 'NewIntermediaryNumberTotal',
  ApplicationLoanAverage = 'ApplicationLoanAverage',
  ApprovedApplicationLoanAverage = 'ApprovedApplicationLoanAverage',
  AverageDaysToApproval = 'AverageDaysToApprove',
  AverageDaysToRejection = 'AverageDaysToRejection',
  CloseRate = 'CloseRate',
  RejectionRate = 'RejectionRate',
  ApplicationsPerBorrower = 'ApplicationsPerBorrower',
  ApplicationsPerIntermediary = 'ApplicationsPerIntermediary',
}

export default ReportingDashboardChartName;
