import React from 'react';
import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import { ApplicationDocumentsTableSize } from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/ApplicationDocumentsTable/TableColumnSizes';

const SkeletonApplicationDocumentItemRow = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={ApplicationDocumentsTableSize.Name}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.Type}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.Size}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.CreatedAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.UpdatedAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonApplicationDocumentItemRow;
