import { Middleware } from 'redux';

import { ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST } from './ActionTypes';
import {
  AddNewCompanyUserError,
  AddNewCompanyUserSuccess,
  AddNewCompanyUserRequestAction,
} from './AddNewCompanyUserActionCreator';

import { OrganizationApiType, UserAddResult } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { USER_ADD_RESULT } from 'AddNewCompanyUser/AddNewCompanyUserConstants';

const AddNewCompanyUserMiddleware: (api: OrganizationApiType) => Middleware = (api) =>
  Fetcher<UserAddResult, AddNewCompanyUserRequestAction>(
    ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST,
    AddNewCompanyUserSuccess,
    AddNewCompanyUserError,
    async ({ payload }) => {
      const result = await api.addNewUser(payload);

      return result;
    },
    USER_ADD_RESULT,
    (result) => result,
  );

export default AddNewCompanyUserMiddleware;
