import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './List.module.scss';

export interface ListProps {
  className: string;
  items: string[];
  listItemClassName?: string;
}

const List: FC<ListProps> = ({
  className,
  items,
  listItemClassName,
}) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li className={clsx(styles.listItem, listItemClassName)} key={`${index}_${item}`}><p>{item}</p></li>
      ))}
    </ul>
  );
};

export default List;
