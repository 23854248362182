import React, { FC } from 'react';
import clsx from 'clsx';
import Label from 'components/Label';
import uploadFileStyles from './UploadFile.module.scss';
import styles from './UploadFileStub.module.scss';

export interface UploadFileStubProps {
  className?: string;
  label?: string;
  required?: boolean;
}

const UploadFileStub: FC<UploadFileStubProps> = ({ label, required, className }) => {
  return (
    <div className={clsx(styles.uploadFileStubContainer, className)}>
      {label && <Label className={styles.label} required={required}>{label}</Label>}
      <div className={clsx(uploadFileStyles.inputWrapper, styles.stubInputWrapper)}>
        Users will be able to upload a document here
      </div>
    </div>
  );
};

export default UploadFileStub;
