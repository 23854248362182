import DecisionProcessingApi from 'api/DecisionProcessing/DecisionProcessingApi';
import { Variable } from 'Variables/VariablesTypes';
import { VariableValueMap } from 'api/Types';
import { SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';

export interface IDecisionRunApiRest {
  getStrategyInputs(strategyId: string): Promise<Variable[]>;
}

interface BaseDecisionRunParams {
  strategyName: string;
  strategyStatus: SystemStatus;
  strategyVersion: number;
  decisionName?: string;
}

export interface IndividualDecisionRunParams extends BaseDecisionRunParams {
  inputs: VariableValueMap;
  applicationId?: string | null;
}

export interface DecisionRunResponse {
  executionTime: number;
  decisionId: string;
  resultsCount: number;
}

export interface MultipleDecisionRunParams extends BaseDecisionRunParams {
  file: File;
}

export default class DecisionRunApiRest extends DecisionProcessingApi implements IDecisionRunApiRest {
  public getStrategyInputs(strategyId: string): Promise<Variable[]> {
    return this.fetch<Variable[]>(`/strategy-inputs/${strategyId}`, 'GET');
  }

  public runIndividual(params: IndividualDecisionRunParams): Promise<DecisionRunResponse> {
    return this.fetch(`/individual-decisions`, 'POST', {
      ...params,
    });
  }

  public runMultiple(params: MultipleDecisionRunParams): Promise<DecisionRunResponse> {
    const formData = new FormData();
    formData.append('decisionName', params.decisionName || '');
    formData.append('file', params.file);
    formData.append('fileName', params.file.name);
    formData.append('strategyName', params.strategyName);
    formData.append('strategyStatus', params.strategyStatus || 'null');
    formData.append('strategyVersion', params.strategyVersion ? params.strategyVersion.toString() : '');

    return this.fetch('/multi-decisions', 'POST', formData, {
      contentType: null,
    });
  }
}
