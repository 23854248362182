import React, { useCallback } from 'react';
import Card from './Card';
import styles from './ColumnBody.module.scss';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import clsx from 'clsx';
import DroppableList from 'components/DndList/DroppableList';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import { useInitiallyPipedItems } from 'components/LimitedInitialLoad/limitedInitialLoad';
import { DuplicateApplicationParams } from 'LoanOriginationSystemApplications/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

interface ColumnBodyProps {
  id: string;
  applications: SimplifiedApplication[];
  currencySymbol: string;
  placeholderStyle: React.CSSProperties | null;
  searchInputValue: string;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  deleteDisabled: boolean;
  onApplicationUpdatingStateReset: (applicationId: string) => void;
  onEditApplication: (displayId: string) => void;
  onDeleteApplication: (id: string) => void;
  onDuplicateApplication: (params: DuplicateApplicationParams) => void;
}

const APPLICATIONS_RENDERED_SIMULTANEOUSLY_COUNT = process.env.NODE_ENV === 'production' ? 250 : 50;

const ColumnBody = React.memo(({
  id,
  applications,
  currencySymbol,
  placeholderStyle,
  searchInputValue,
  applicationUpdatingStatesById,
  onApplicationUpdatingStateReset,
  onEditApplication,
  onDeleteApplication,
  onDuplicateApplication,
  deleteDisabled,
}: ColumnBodyProps) => {
  const renderApplication = useCallback((
    targetApplication: SimplifiedApplication,
    _index: number,
    draggableSnapshot: DraggableStateSnapshot,
  ) => {
    return (
      <Card
        {...targetApplication}
        key={targetApplication.id}
        currencySymbol={currencySymbol}
        highlightString={searchInputValue}
        className={clsx(draggableSnapshot.isDragging && styles.draggingCard)}
        updatingState={applicationUpdatingStatesById[targetApplication.id]}
        onApplicationUpdatingStateReset={onApplicationUpdatingStateReset}
        disabled={applicationUpdatingStatesById[targetApplication.id] === 'updating'}
        onEditApplication={onEditApplication}
        onDeleteApplication={onDeleteApplication}
        onDuplicateApplication={onDuplicateApplication}
        deleteDisabled={deleteDisabled}
      />
    );
  }, [applicationUpdatingStatesById, searchInputValue]);

  const isDragDisabled = useCallback(
    (item: SimplifiedApplication) => applicationUpdatingStatesById[item.id] === 'updating',
    [applicationUpdatingStatesById],
  );

  const getDraggableId = useCallback((itemId: string | number) => itemId as string, []);

  const pipedApplications = useInitiallyPipedItems(applications, APPLICATIONS_RENDERED_SIMULTANEOUSLY_COUNT);

  return (
    <div className={styles.content}>
      <DroppableList<SimplifiedApplication>
        droppableId={id}
        droppableType="card"
        items={pipedApplications}
        renderListItem={renderApplication}
        placeholderStyles={placeholderStyle}
        listItemClassName={styles.card}
        withPlaceholder
        isDragDisabled={isDragDisabled}
        getDraggableId={getDraggableId}
      />
    </div>
  );
});

export default ColumnBody;
