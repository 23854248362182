import React, { ChangeEvent, FocusEvent, useMemo } from 'react';
import TextInput from 'components/TextInput/TextInput';
import {
  DateWithAttributes,
  IdentificationNumberWithAttributes,
  ListWithAttributes,
  MonetaryWithAttributes,
  PhoneNumberWithAttributes,
  UndefinedVisualDataTypeWithNoAttributes,
  VisualDataTypeWithAttributes,
} from 'Variables/VariablesTypes';
import DatePicker from 'components/DatePicker';
import AutoCompletion from 'components/AutoCompletion';
import MonetaryInput from 'components/MonetaryInput';
import PercentageInput from 'components/PercentageInput';
import NumberInput from 'components/NumberInput';
import { Option } from 'components/SelectInput/SelectInput';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';
import IdentificationNumberInput from 'components/IdentificationNumberInput';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import { InputSize } from 'components/DatePicker/Input';
import EmailInput from 'components/EmailInput';
import useInputValidation from 'hooks/useInputValidation';
import getValidationMessage from './getValidationMessage';
import styles from './InputWithDataType.module.scss';

interface InputWithDataTypeProps {
  value: string;
  options: Option[] | null;
  labelTitle: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabledValidation?: boolean;
  showLoader?: boolean;
  disableResetValueOnError?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  hasLeftNeighbour?: boolean;
  containerClassName?: string;
  inputSize?: InputSize;
  inputIcon?: React.ReactNode;
  raw?: boolean;
}

const BOOLEAN_VALUE_DROPDOWN_OPTIONS: Option[] = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false' },
];

export const isPhoneNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is PhoneNumberWithAttributes => props.dataType === 'PhoneNumber';

export const isIdentificationNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is IdentificationNumberWithAttributes => props.dataType === 'IdentificationNumber';

export const isMonetaryDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is MonetaryWithAttributes => props.dataType === 'Monetary';

export const isDateDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is DateWithAttributes => props.dataType === 'Date';

export const isListDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is ListWithAttributes => props.dataType === 'List';

const InputWithDataType = React.memo((props: InputWithDataTypeProps & VisualDataTypeWithAttributes) => {
  const {
    dataType,
    value,
    labelTitle,
    onChange,
    onFocus,
    onBlur,
    readOnly,
    disabled,
    required,
    showLoader,
    loaderState,
    onLoaderStateReset,
    disabledValidation,
    disableResetValueOnError,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
    inputSize,
    inputIcon,
    raw,
  } = props;
  const handleChange = ({ value: booleanValue }: Option) => onChange(booleanValue);

  const validators = useMemo(() => {
    return [(valueToValidate: string) => getValidationMessage(props, valueToValidate, required)];
  }, [
    dataType,
    (props as PhoneNumberWithAttributes).phoneNumberFormat,
    (props as PhoneNumberWithAttributes).phoneNumberWithFlag,
    (props as IdentificationNumberWithAttributes).identificationNumberType,
    (props as IdentificationNumberWithAttributes).identificationNumberDescription,
    (props as MonetaryWithAttributes).currency,
    (props as DateWithAttributes).dateFormat,
    (props as ListWithAttributes).optionsList,
  ]);

  const [handleBlur, handleFocus, errorMessage] = useInputValidation({
    validators,
    value,
    onChange,
    onFocus,
    onBlur,
    required,
    disabledValidation,
    disableResetValueOnError,
  });

  const commonInputProps = {
    errorMessage,
    value,
    labelTitle,
    readOnly,
    disabled,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange,
    showLoader,
    loaderState,
    onLoaderStateReset,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
    inputIcon,
    raw,
    required,
  };

  if (isPhoneNumber(props)) {
    const { phoneNumberFormat, phoneNumberWithFlag } = props;
    return (
      <PhoneNumberInput
        {...commonInputProps}
        withFlag={phoneNumberWithFlag}
        country={phoneNumberFormat}
      />
    );
  }

  if (isIdentificationNumber(props)) {
    const { identificationNumberType, identificationNumberDescription } = props;
    return (
      <IdentificationNumberInput
        {...commonInputProps}
        identificationNumberType={identificationNumberType!}
        identificationNumberDescription={identificationNumberDescription}
      />
    );
  }

  if (isMonetaryDataType(props)) {
    const { currency } = props;
    const currencySymbol = currency ? getCurrencySymbol(currency) : null;

    return (
      <MonetaryInput
        {...commonInputProps}
        placeholder={dataType}
        currencySymbol={currencySymbol}
      />
    );
  }

  if (isDateDataType(props)) {
    const { dateFormat } = props;

    return (
      <DatePicker
        {...commonInputProps}
        inputSize={inputSize}
        dateFormat={dateFormat!}
      />
    );
  }

  if (isListDataType(props)) {
    const selectedOption = value
      ? {
          name: value,
          value,
        }
      : undefined;

    const formattedOptionsList = props.optionsList.map((option) => ({
      name: option,
      value: option,
    }));

    return (
      <AutoCompletion
        {...commonInputProps}
        selectedOption={selectedOption}
        options={formattedOptionsList || []}
        onChange={handleChange}
        placeholder={dataType}
        hasRightNeighbour
      />
    );
  }
  switch (dataType) {
    case 'Percentage':
      return <PercentageInput {...commonInputProps} placeholder={dataType} />;
    case 'Number':
      return (
        <NumberInput
          {...commonInputProps}
          onBlur={(_value, event) => handleBlur(event)}
          placeholder={dataType}
        />
      );
    case 'Boolean': {
      const getAutoCompletionProps = () => {
        if (raw && !BOOLEAN_VALUE_DROPDOWN_OPTIONS.some((option) => option.value === value)) {
          const selectedOption = {
            name: value,
            value,
          };
          return {
            ...commonInputProps,
            selectedOption,
          };
        }
        return commonInputProps;
      }

      return (
        <AutoCompletion
          {...getAutoCompletionProps()}
          options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
          onChange={handleChange}
          placeholder={dataType}
          hasRightNeighbour
        />
      );
    }
    case 'EmailAddress':
      return <EmailInput {...commonInputProps} onFocus={handleFocus} onBlur={handleBlur} />;
    default:
      return (
        <div className={styles.container}>
          <TextInput
            {...commonInputProps}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
            hasRightNeighbour
            placeholder={dataType}
          />
        </div>
      );
  }
});

export default InputWithDataType;
