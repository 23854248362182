import { AnyAction, MiddlewareAPI } from 'redux';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { UnlockBorrowerSuccessAction } from './ActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';

const isUnlockBorrowerSuccessAction = (action: AnyAction): action is UnlockBorrowerSuccessAction =>
  action.type === LoanOriginationSystemBorrowerLockType.UnlockBorrowerSuccess;

const unlockBorrowerSuccessMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isUnlockBorrowerSuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.BorrowerUnlocked, {
        borrowerName: getBorrowerFullName(
          action.payload.borrower.type,
          action.payload.borrower.variables,
        ),
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};

export default unlockBorrowerSuccessMiddleware;
