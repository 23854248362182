import { LoanOriginationSystemIntermediaryDetailsState } from './Types';
import { LoanOriginationSystemIntermediaryDetailsActionTypes } from './ActionTypes';
import {
  GetIntermediaryApplicationsSuccessAction,
  GetIntermediaryData,
  GetIntermediaryDataSuccess,
  ResetIntermediaryDetails,
  SelectIntermediaryViewTabAction,
  SortIntermediaryApplicationsAction,
} from './ActionCreator';
import { ApplicationSortingField } from 'api/LoanOriginationSystem/Types';
import intermediaryApplicationsPagination, { INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/IntermediaryDetails/pagination';
import { AnyAction } from 'redux';
import { IntermediaryDetailsTab } from 'components/LoanOriginationSystem/IntermediaryDetails';
import { UpdateIntermediarySuccessAction } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionTypes';

export const initialState: LoanOriginationSystemIntermediaryDetailsState = {
  intermediary: null,
  isUpdating: false,
  applications: null,
  applicationsSortingType: {
    field: ApplicationSortingField.CreatedAt,
    ascending: true,
  },
  contextualViewTab: IntermediaryDetailsTab.Profile,
};

export type LoanOriginationSystemIntermediaryDetailsAction =
  | GetIntermediaryData
  | GetIntermediaryDataSuccess
  | ResetIntermediaryDetails
  | GetIntermediaryApplicationsSuccessAction
  | SortIntermediaryApplicationsAction
  | SelectIntermediaryViewTabAction
  | UpdateIntermediarySuccessAction;

type LoanOriginationSystemIntermediaryDetailsRedicerType = (
  state: LoanOriginationSystemIntermediaryDetailsState,
  action: AnyAction,
) => LoanOriginationSystemIntermediaryDetailsState;

const loanOriginationSystemIntermediaryDetailsReducer = (
  state: LoanOriginationSystemIntermediaryDetailsState = initialState,
  action: LoanOriginationSystemIntermediaryDetailsAction,
): LoanOriginationSystemIntermediaryDetailsState => {
  switch (action.type) {
    case LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryData: {
      return {
        ...state,
      };
    }
    case LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryDataSuccess: {
      return {
        ...state,
        intermediary: action.payload.data,
      };
    }
    case LoanOriginationSystemIntermediaryDetailsActionTypes.ResetIntermediaryDetails: {
      return {
        ...initialState,
      };
    }
    case LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryApplicationsSuccess: {
      return {
        ...state,
        applications: {
          page: 1,
          itemsPerPage: state.applications?.itemsPerPage || INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT,
          error: '',
          items: action.payload.items,
          itemsTotal: action.payload.total,
        },
      };
    }
    case LoanOriginationSystemIntermediaryDetailsActionTypes.SortIntermediaryApplications: {
      return {
        ...state,
        applicationsSortingType: action.payload.sortingType,
      };
    }
    case LoanOriginationSystemIntermediaryDetailsActionTypes.SelectIntermediaryViewTab: {
      return {
        ...state,
        contextualViewTab: action.payload.tab,
      };
    }
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess: {
      if (!state.intermediary || action.payload.id !== state.intermediary.id) {
        return state;
      }

      return {
        ...state,
        intermediary: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default intermediaryApplicationsPagination.wrapReducer<LoanOriginationSystemIntermediaryDetailsState>(
  loanOriginationSystemIntermediaryDetailsReducer as LoanOriginationSystemIntermediaryDetailsRedicerType,
);
