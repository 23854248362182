import React, { useEffect, useRef, useState } from 'react';
import styles from './EditableInput.module.scss';
import { InputProps } from 'hooks/useAsyncValueInputProps';
import useFontsLoaded from 'hooks/useFontsLoaded';
import KeyEventEnum from 'common/KeyEventEnum';
import InputWithValidation from 'components/InputWithValidation';
import { validateLettersNumbersOnly } from 'input-validators';

export interface EditableInputOwnProps {
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  valueValidator?: (value: string) => boolean;
}

const EditableInput = (props: EditableInputOwnProps & InputProps) => {
  const { className, valueValidator = validateLettersNumbersOnly, onChange, ...inputProps } = props;
  const [currentInputWidth, setCurrentInputWidth] = useState(0);
  const hiddenTextElement = useRef<HTMLParagraphElement>(null);
  const inputElement = useRef<HTMLInputElement>(null);

  const recalculateWidth = () => {
    const { width } = hiddenTextElement.current!.getBoundingClientRect();

    setCurrentInputWidth(width);
  };

  useFontsLoaded(recalculateWidth);

  useEffect(() => {
    recalculateWidth();
  }, [inputProps.value]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyEventEnum.Enter) {
      inputElement.current!.blur();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={className || ''}>
      <InputWithValidation
        onKeyUp={handleKeyUp}
        ref={inputElement}
        type="text"
        {...inputProps}
        onChange={handleChange}
        style={{ width: currentInputWidth }}
        className={styles.editableInput}
        valueValidator={valueValidator}
      />
      <p ref={hiddenTextElement} className={styles.textLabel}>
        {inputProps.value || inputProps.placeholder}
      </p>
    </div>
  );
};

export default EditableInput;
