import React, { FC } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  WebhookEvent,
  WebhookEventSortingField,
  WebhookEventSortingType,
} from 'api/Webhooks/WebhookEventsApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import Pagination from 'components/Pagination';
import WebhookEventItemRow from './WebhookEventItemRow';
import { WebhookEventColumnSize, WebhookEventWithEndpointColumnSize } from './TableColumnSize';
import SkeletonWebhookEventItemRow from './SkeletonWebhookEventItemRow';
import styles from './WebhookEventsTable.module.scss';

interface WebhookEventsTableProps {
  source: NullableItems<WebhookEvent>;
  paginationProps: PaginationProps;
  sortingType: WebhookEventSortingType;
  searchInputValue: string;
  onSort: (field: WebhookEventSortingField, ascending: boolean) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
  onWebhookEventDelete: (id: string) => void;
  onWebhookEventResend: (id: string) => void;
  withEndpointColumn?: boolean;
}

const WebhookEventsTable: FC<WebhookEventsTableProps> = ({
  source,
  paginationProps,
  sortingType,
  searchInputValue,
  onSort,
  onWebhookEventClick,
  onWebhookEventDelete,
  onWebhookEventResend,
  withEndpointColumn,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonTableRow = (index: number) => <SkeletonWebhookEventItemRow withEndpointColumn={withEndpointColumn} key={index} />;

  const renderTableRow = (item: WebhookEvent) => {
    return (
      <WebhookEventItemRow
        key={item.id}
        searchInputValue={searchInputValue}
        webhookEvent={item}
        onClick={() => onWebhookEventClick(item)}
        onWebhookEventDelete={onWebhookEventDelete}
        onWebhookEventResend={onWebhookEventResend}
        withEndpointColumn={withEndpointColumn}
      />
    );
  };

  const ColumnSize = withEndpointColumn ? WebhookEventWithEndpointColumnSize : WebhookEventColumnSize;

  return (
    <Table>
      <div className={styles.table}>
        <div className={styles.tableContainer}>
          <Table>
            <TableHead sticky>
              {withEndpointColumn && (
                <TableHeadCell
                  width={WebhookEventWithEndpointColumnSize.Endpoint}
                  ascending={getSortingType(WebhookEventSortingField.Endpoint)}
                  onClick={() => changeSorting(WebhookEventSortingField.Endpoint)}
                >
                  Endpoint
                </TableHeadCell>
              )}
              <TableHeadCell
                width={ColumnSize.EventType}
                ascending={getSortingType(WebhookEventSortingField.EventType)}
                onClick={() => changeSorting(WebhookEventSortingField.EventType)}
              >
                Event Type
              </TableHeadCell>
              <TableHeadCell
                width={ColumnSize.EventDate}
                ascending={getSortingType(WebhookEventSortingField.CreatedAt)}
                onClick={() => changeSorting(WebhookEventSortingField.CreatedAt)}
              >
                Event Date
              </TableHeadCell>
              <TableHeadCell
                ascending={getSortingType(WebhookEventSortingField.Status)}
                onClick={() => changeSorting(WebhookEventSortingField.Status)}
                width={ColumnSize.Status}
              >
                Status
              </TableHeadCell>
              <TableHeadCell
                width={ColumnSize.LastRunAt}
                ascending={getSortingType(WebhookEventSortingField.LastRunAt)}
                onClick={() => changeSorting(WebhookEventSortingField.LastRunAt)}
              >
                Last Run
              </TableHeadCell>
              <TableHeadCell
                width={ColumnSize.NextRunAt}
                ascending={getSortingType(WebhookEventSortingField.NextRunAt)}
                onClick={() => changeSorting(WebhookEventSortingField.NextRunAt)}
              >
                Next Run
              </TableHeadCell>
              <TableActionCell />
            </TableHead>
            <TableBody>
              <TableBodyContent
                renderSkeletonTableRow={renderSkeletonTableRow}
                renderTableRow={renderTableRow}
                rows={source}
              />
            </TableBody>
          </Table>
          <Pagination {...paginationProps} />
        </div>
      </div>
    </Table>
  );
};

export default WebhookEventsTable;
