import { WebhookEventSortingField, WebhookEventSortingType } from 'api/Webhooks/WebhookEventsApi';
import { createAction, createReducer } from '@reduxjs/toolkit';
import mountChildReducers from 'utils/childReducers';
import withStateReset from 'utils/reducers/withStateReset';
import webhookEventsPagination, { WEBHOOK_EVENTS_PER_PAGE_DEFAULT } from './Pagination';
import { ItemsStateWithPagination } from 'pagination';
import { getWebhookEvents, WebhookEventsActionOrigin } from 'WebhookEvents/Thunks';
import webhookEventsTableFiltersReducer, {
  clearFilters,
  initialState as webhookEventsTableFiltersInitialState,
  WebhookEventsTableFiltersState,
} from './Filters/WebhookEventsTableFiltersStore';
import { WebhookEventsTableActionType } from './WebhookEventsTableActionType';

export interface WebhookEventsTableState extends ItemsStateWithPagination<string> {
  sortingType: WebhookEventSortingType;
  searched: {
    [index: string]: ItemsStateWithPagination<string>;
  };
  filters: WebhookEventsTableFiltersState;
}

const initialState: WebhookEventsTableState = {
  items: [],
  page: 1,
  itemsPerPage: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    field: WebhookEventSortingField.CreatedAt,
    ascending: false,
  },
  searched: {},
  filters: webhookEventsTableFiltersInitialState,
};

export const changeSortingType = createAction<WebhookEventSortingType>(WebhookEventsTableActionType.ChangeSortingType);
export const resetState = createAction(WebhookEventsTableActionType.ResetState);

const webhookEventsTableReducer = createReducer(initialState, (builder) => {
  builder.addCase(getWebhookEvents.fulfilled, (state, action) => {
    if (action.meta.actionOrigin !== WebhookEventsActionOrigin.WebhookEventsTable) {
      return;
    }

    if (action.meta.search) {
      state.searched[action.meta.search] = {
        error: '',
        page: 1,
        itemsPerPage: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
        items: action.payload.items.map((item) => item.id),
        itemsTotal: action.payload.total,
      }

      return;
    }

    state.error = '';
    state.page = 1;
    state.itemsPerPage = WEBHOOK_EVENTS_PER_PAGE_DEFAULT;
    state.items = action.payload.items.map((item) => item.id);
    state.itemsTotal = action.payload.total;
  });

  builder.addCase(clearFilters, (state, action) => {
    state.filters = webhookEventsTableFiltersReducer(state.filters, action);

    if (state.filters.search) {
      state.searched[state.filters.search] = {
        items: [],
        page: 1,
        itemsPerPage: WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
        itemsTotal: undefined,
        error: null,
      };

      return;
    }

    state.error = null;
    state.items = [];
    state.itemsPerPage = WEBHOOK_EVENTS_PER_PAGE_DEFAULT;
    state.itemsTotal = undefined;
  });

  builder.addCase(changeSortingType, (state, action) => {
    state.sortingType = action.payload;
  });
});

const childReducers = {
  filters: webhookEventsTableFiltersReducer,
};

export default webhookEventsPagination.wrapReducer(
  withStateReset(
    mountChildReducers(webhookEventsTableReducer, childReducers),
    WebhookEventsTableActionType.ResetState,
    () => ({ ...initialState }),
  ),
);
