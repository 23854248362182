import React, { FC } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import styles from './CreateNewApplicationPopup.module.scss';

export interface ApplicationFormPageCheckboxProps {
  checked: boolean;
  label: string;
  onChange: () => void;
  disabled?: boolean;
}

const ApplicationFormPageCheckbox: FC<ApplicationFormPageCheckboxProps> = ({
  label,
  onChange,
  checked,
  disabled,
}) => (
  <div className={styles.checkboxContainer}>
    <CustomCheckbox
      className={styles.checkbox}
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  </div>
);

export default ApplicationFormPageCheckbox;
