import React, { useCallback, useEffect, useState } from 'react';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import ApplicationsDashboard from 'components/LoanOriginationSystem/ApplicationsDashboard';
import ApplicationsFilter from 'components/LoanOriginationSystem/ApplicationsFilter';
import { useDispatch, useSelector, batch } from 'react-redux';
import { ReduxState } from 'types/redux';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import CreateApplication from 'components/LoanOriginationSystem/CreateApplication';
import { useQueryParams } from 'hooks/useQueryParam';
import UnlockCustomerPopup from 'components/LoanOriginationSystem/CreateApplication/UnlockBorrowerPopup';
import {
  resetSelectedCoBorrowers,
  selectBorrower,
  selectIntermediary,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { setBorrowerToUnlock, unlockBorrower } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import {
  changeApplicationStatus,
  ChangeApplicationStatusActionOrigin,
  closeDeleteApplicationPopup,
  closeDuplicateApplicationPopup,
  deleteApplication,
  duplicateApplication,
  getProductDataByViewType,
  moveApplicationWithoutChangingStatus,
  setCreateApplicationProductData,
  toggleCreateNewApplicationPopupOpen,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import DeleteApplicationConfirmationPopup from 'components/LoanOriginationSystem/DeleteApplicationConfirmationPopup/DeleteApplicationConfirmationPopup';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import useViewType from 'hooks/useViewType';
import { useHistory } from 'react-router';
import RouteService from 'routes/RouteService';
import ChangeStatusConfirmationPopup from 'components/LoanOriginationSystem/ApplicationDetails/ChangeStatusConfirmationPopup';
import CreateNewApplicationPopup from 'components/LoanOriginationSystem/CreateNewApplicationPopup';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import { getDeclineReasons } from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import useStateReset from 'hooks/useStateReset';
import styles from './Applications.module.scss';
import DuplicateApplicationConfirmationPopup from 'components/LoanOriginationSystem/DuplicateApplicationConfirmationPopup/DuplicateApplicationConfirmationPopup';
import ApplicationAttribute from 'LoanOriginationSystemApplications/CreateApplication/ApplicationAttribute';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const leftNav = makeLeftNavigation(NavigationLinkId.Applications, ApplicationSectionName.LoanOriginationSystem);

interface ApplicationToReject {
  application: SimplifiedApplication;
  sourceIndex: number;
  destinationIndex: number;
}

const Applications = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();

  const history = useHistory();
  const params = useQueryParams();

  const {
    filters,
    applicationIdToDelete,
    duplicateApplicationParams,
    selectedProduct,
    tableViewSortingType,
    columnViewSortingType,
    columnViewData,
    isCreateNewApplicationPopupOpen,
    productsList,
    createApplicationProductData,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications);
  const {
    borrowerLock: { borrowerToUnlock, isUnlockingInProgress },
  } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);

  const rightSidePopupView = filters.popupVisible && <ApplicationsFilter />;

  const [isDeletingApplication, setIsDeletingApplication] = useState(false);
  const [isDuplicatingApplication, setIsDuplicatingApplication] = useState(false);

  const [dataWasChanged, setDataWasChanged] = useState(false);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);

  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const declineReasons = selectedProduct ? declineReasonsByProductId[selectedProduct.id] : [];

  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, selectedProduct?.id)) || [];

  const [applicationIdToReject, setApplicationIdToReject] = useState<ApplicationToReject | null>(null);

  useStateReset(LoanOriginationSystemApplicationsActionType.ResetState);

  const closeContextualView = () => {
    history.push(RouteService.getApplicationsUrl(viewType));
  };

  const onCloseContextualView = () => {
    if (dataWasChanged) {
      setUnsavedChangesPopupOpen(true);

      return;
    }

    closeContextualView();
  };

  const onCreateApplicationSuccess = (createdApplicationDisplayId: string | undefined) => {
    if (createdApplicationDisplayId) {
      history.push(RouteService.getApplicationPage(createdApplicationDisplayId));
    }
  };

  const renderContextualView = () => {
    if (params.has('new') && createApplicationProductData) {
      return (
        <CreateApplication
          onClose={onCloseContextualView}
          onCreateSuccess={onCreateApplicationSuccess}
          setDataWasChanged={(changed) => setDataWasChanged(changed)}
          product={createApplicationProductData.product}
          applicationFormPages={createApplicationProductData.applicationFormPages}
        />
      );
    }

    return null;
  };

  const onPopupClose = () => {
    dispatch(setBorrowerToUnlock(null));
  };

  const onUnlockClick = () => {
    if (!borrowerToUnlock) {
      return;
    }

    dispatch(unlockBorrower(borrowerToUnlock.id));
  };

  const viewType = useViewType();

  const handleDeleteApplication = async () => {
    setIsDeletingApplication(true);

    await dispatchRoutine(deleteApplication(applicationIdToDelete!));

    await dispatchRoutine(
      getProductDataByViewType(
        selectedProduct!.id,
        viewType,
        filters,
        viewType === ViewType.Column ? columnViewSortingType : tableViewSortingType,
      ),
    );

    setIsDeletingApplication(false);
  };

  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);

  const handleDuplicateApplication = async () => {
    setIsDuplicatingApplication(true);

    try {
      const result = await dispatchRoutineWithResult(duplicateApplication(duplicateApplicationParams!.id));

      if (result[ApplicationAttribute.ApplicationId]) {
        history.push(`/los/applications/${result[ApplicationAttribute.ApplicationId]}`);
      }
    } finally {
      setIsDuplicatingApplication(false);
    }
  };

  const clearForm = () => {
    dispatch(selectBorrower(null));
    dispatch(selectIntermediary(null));
    dispatch(resetSelectedCoBorrowers());
  };

  const changeStatus = (
    application: SimplifiedApplication | null,
    status: ApplicationStatus,
    selectedDeclineReasons?: string[],
    sourceIndex?: number,
    destinationIndex?: number,
  ) => {
    if (!application) {
      return;
    }

    const action = setActionOrigin(
      changeApplicationStatus({
        applicationId: application.id,
        applicationDisplayId: application.displayId,
        applicationName: application.borrowerFullName,
        initialStatus: application.status,
        statusToSet: status,
        declineReasons: selectedDeclineReasons,
        sourceIndex,
        destinationIndex,
      }),
      ChangeApplicationStatusActionOrigin.ApplicationsDashboard,
    );

    dispatch(action);
  };

  const onApplicationsReorder = useCallback((
    applicationId: string,
    statusId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const status = applicationStatuses.find(({ id }) => id === statusId)!;
    const application = columnViewData?.find(({ id }) => id === applicationId)!;

    if (status.id === application.status.id) {
      dispatch(moveApplicationWithoutChangingStatus(application, status, destinationIndex));
      return;
    }

    if (status.name === DefaultApplicationStatus.Rejected) {
      dispatch(moveApplicationWithoutChangingStatus(application, status, destinationIndex));
      setApplicationIdToReject({ application, sourceIndex, destinationIndex });
      return;
    }

    const selectedDeclineReasons = undefined;
    changeStatus(application, status, selectedDeclineReasons, sourceIndex, destinationIndex);
  }, [applicationStatuses, columnViewData]);

  const handleChangeStatusClick = (selectedDeclineReasons: string[]) => {
    const status = applicationStatuses.find(({ name }) => name === DefaultApplicationStatus.Rejected)!;

    const { application, sourceIndex, destinationIndex } = applicationIdToReject!;

    changeStatus(application, status, selectedDeclineReasons, sourceIndex, destinationIndex);
    setApplicationIdToReject(null);
  };

  const onChangeStatusConfirmationPopupCancel = () => {
    const { application, sourceIndex } = applicationIdToReject!;

    dispatch(moveApplicationWithoutChangingStatus(application, application.status, sourceIndex));

    setApplicationIdToReject(null);
  };

  const handleCreateNewApplicationPopupSubmit = (product: Product, applicationFormPages: string[]) => {
    batch(() => {
      dispatch(setCreateApplicationProductData({ product, applicationFormPages }));
      dispatch(toggleCreateNewApplicationPopupOpen());
    });

    history.push(RouteService.getApplicationsUrl(viewType, true));
  };

  const handleCloseCreateApplicationPopup = () => {
    dispatch(toggleCreateNewApplicationPopupOpen());
  };

  const renderOverlay = () => {
    if (applicationIdToReject) {
      return (
        <ChangeStatusConfirmationPopup
          onChangeStatusClick={handleChangeStatusClick}
          declineReasons={declineReasons}
          onPopupClose={onChangeStatusConfirmationPopupCancel}
          statusToChange={DefaultApplicationStatus.Rejected}
        />
      );
    }

    if (unsavedChangesPopupOpen) {
      return (
        <UnsavedChangesPopup
          onPopupClose={() => setUnsavedChangesPopupOpen(false)}
          onLeaveClick={() => {
            closeContextualView();
            setUnsavedChangesPopupOpen(false);
          }}
        />
      );
    }

    if (borrowerToUnlock) {
      return (
        <UnlockCustomerPopup
          onPopupClose={onPopupClose}
          onUnlockClick={onUnlockClick}
          isUnlockingInProgress={isUnlockingInProgress}
        />
      );
    }

    if (applicationIdToDelete) {
      return (
        <DeleteApplicationConfirmationPopup
          onConfirmClick={handleDeleteApplication}
          onClose={() => dispatch(closeDeleteApplicationPopup())}
          loading={isDeletingApplication}
        />
      );
    }

    if (duplicateApplicationParams) {
      return (
        <DuplicateApplicationConfirmationPopup
          onConfirmClick={handleDuplicateApplication}
          onClose={() => dispatch(closeDuplicateApplicationPopup())}
          loading={isDuplicatingApplication}
          params={duplicateApplicationParams}
        />
      );
    }

    if (isCreateNewApplicationPopupOpen && productsList) {
      return (
        <CreateNewApplicationPopup
          initialProduct={selectedProduct}
          products={productsList}
          onSubmit={handleCreateNewApplicationPopupSubmit}
          onClose={handleCloseCreateApplicationPopup}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedProduct) {
      if (!declineReasons) {
        dispatch(getDeclineReasons(selectedProduct.id));
      }

      dispatchRoutine(getProductDataByViewType(selectedProduct!.id, viewType));
    }
  }, [selectedProduct]);

  useEffect(() => {
    return history.listen(() => {
      clearForm();
    });
  }, [selectedProduct, accountDetails]);

  return (
    <MainLayout
      leftNav={leftNav}
      rightSidePopupView={rightSidePopupView}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      overlay={renderOverlay()}
    >
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <ApplicationsDashboard onApplicationsReorder={onApplicationsReorder} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Applications;
