import React from 'react';
import clsx from 'clsx';
import { NullableItems } from 'pagination';
import {
  ApplicationDocumentsSortingType,
  ApplicationDocument,
  ApplicationDocumentsSortingField,
  ApplicationDocumentFile,
} from 'api/LoanOriginationSystem/DocumentsApi';
import useSorting from 'hooks/useSorting';
import { isFolder } from 'LoanOriginationSystemApplicationPage/Documents/utils';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import DocumentFoldersTableDndContext from 'components/DocumentFoldersTableDndContext';
import DocumentFoldersTableDraggableRow from 'components/DocumentFoldersTableDraggableRow';
import DocumentItemRow from './ApplicationDocumentItemRow';
import SkeletonDocumentItemRow from './SkeletonApplicationDocumentItemRow';
import { ApplicationDocumentsTableSize } from './TableColumnSizes';
import styles from './ApplicationDocumentsTable.module.scss';

interface ApplicationDocumentsTableProps {
  source: NullableItems<ApplicationDocument>;
  sortingType: ApplicationDocumentsSortingType;
  searchInputValue: string;
  onSort: (field: ApplicationDocumentsSortingField, ascending: boolean) => void;
  onApplicationDocumentDownload: (document: ApplicationDocumentFile) => void;
  onApplicationDocumentClick: (document: ApplicationDocument) => void;
  onApplicationDocumentDelete: (document: ApplicationDocument) => void;
  onApplicationDocumentRename: (document: ApplicationDocument) => void;
  onApplicationDocumentsCombine: (documentId: string, parentId: string) => void;
}

const ApplicationDocumentsTable = ({
  source,
  sortingType,
  searchInputValue,
  onSort,
  onApplicationDocumentDownload,
  onApplicationDocumentRename,
  onApplicationDocumentClick,
  onApplicationDocumentDelete,
  onApplicationDocumentsCombine,
}: ApplicationDocumentsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (item: ApplicationDocument, className: string, disableHover?: boolean) => (
    <DocumentItemRow
      className={className}
      disableHover={disableHover}
      searchInputValue={searchInputValue}
      document={item}
      onClick={onApplicationDocumentClick}
      onApplicationDocumentDelete={onApplicationDocumentDelete}
      onApplicationDocumentDownload={onApplicationDocumentDownload}
      onApplicationDocumentRename={onApplicationDocumentRename}
    />
  );

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky stickyMarginTop={TABLE_HEADER_OFFSET}>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Name}
            ascending={getSortingType(ApplicationDocumentsSortingField.Name)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Name)}
          >
            Name
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Type}
            ascending={getSortingType(ApplicationDocumentsSortingField.Extension)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Extension)}
          >
            Type
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Size}
            ascending={getSortingType(ApplicationDocumentsSortingField.Size)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Size)}
          >
            Size
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.CreatedAt}
            ascending={getSortingType(ApplicationDocumentsSortingField.CreatedAt)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.CreatedAt)}
          >
            Created
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.UpdatedAt}
            ascending={getSortingType(ApplicationDocumentsSortingField.UpdatedAt)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.UpdatedAt)}
          >
            Updated
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <DocumentFoldersTableDndContext onFoldersCombine={onApplicationDocumentsCombine}>
          {(currentDraggableId) => (
            <TableBody className={currentDraggableId ? styles.tableBodyDragging : undefined}>
              <TableBodyContent
                renderSkeletonTableRow={(index) => <SkeletonDocumentItemRow key={index} />}
                renderTableRow={(item: ApplicationDocument, index: number) => (
                  <DocumentFoldersTableDraggableRow
                    key={item.id}
                    getIsCombineMode={(documentId) => {
                      const documentToCombineWith = source.find((document) => document?.id === documentId);
                      return !!documentToCombineWith && isFolder(documentToCombineWith);
                    }}
                    getDroppableIndex={(documentId) => source.findIndex((document) => document?.id === documentId)}
                    currentDraggableId={currentDraggableId}
                    draggableId={item.id}
                    draggableIndex={index}
                    placeholderRow={renderRow(item, styles.tableRowGrayed, true)}
                  >
                    {(isDragging, isCombineTarget) =>
                      renderRow(
                        item,
                        clsx(
                          isDragging && styles.tableRowDragging,
                          isFolder(item) && isCombineTarget && styles.tableRowCombineTarget,
                        ),
                      )
                    }
                  </DocumentFoldersTableDraggableRow>
                )}
                rows={source}
              />
            </TableBody>
          )}
        </DocumentFoldersTableDndContext>
      </Table>
    </div>
  );
};

export default ApplicationDocumentsTable;
