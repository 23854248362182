import React, { useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import areArraysEqual from 'utils/areArraysEqual';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import { WebhookEventStatus } from 'api/Webhooks/WebhookEventsApi';
import styles from './WebhookEventsFilters.module.scss';

export interface WebhookEventsFiltersResult {
  eventTypes: string[];
  statuses: WebhookEventStatus[];
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
}

interface WebhookEventsFiltersProps {
  eventTypes: string[];
  statuses: WebhookEventStatus[];
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  onCloseFilters: () => void;
  onApplyFilters: (filters: WebhookEventsFiltersResult) => void;
  onClearFilters: () => void;
}

const getStatusColor = (status: WebhookEventStatus) => {
  if (status === WebhookEventStatus.Failed) {
    return styles.errorColor;
  }

  return styles.successColor;
}

const AVAILABLE_STATUSES = [
  {
    id: WebhookEventStatus.Succeeded,
    name: capitalize(WebhookEventStatus.Succeeded),
    color: getStatusColor(WebhookEventStatus.Succeeded),
  },
  {
    id: WebhookEventStatus.Failed,
    name: capitalize(WebhookEventStatus.Failed),
    color: getStatusColor(WebhookEventStatus.Failed),
  },
];

const WebhookEventsFilters = ({
  statuses,
  eventTypes,
  dueCreatedDateFrom,
  dueCreatedDateTo,
  onCloseFilters,
  onApplyFilters,
  onClearFilters,
}: WebhookEventsFiltersProps) => {
  const [selectedEventTypes, setSelectedEventTypes] = useState(eventTypes);
  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateFrom);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateTo);

  const dropdownSelectedStatuses = useMemo(() => {
    return statuses.map((status) => ({
      id: status,
      name: capitalize(status),
      color: getStatusColor(status),
    }));
  }, [statuses]);

  const [statusSelectProps, selectedStatuses, setSelectedStatuses] = useAutoCompletionMultiSelectProps(
    AVAILABLE_STATUSES,
    dropdownSelectedStatuses,
    (label) => label.id,
    (label) => label.name,
  );

  useEffect(() => {
    setSelectedEventTypes(eventTypes);
    setSelectedStatuses(dropdownSelectedStatuses);
    setFromCreatedDate(dueCreatedDateFrom);
    setToCreatedDate(dueCreatedDateTo);
  }, [statuses, eventTypes, dueCreatedDateFrom, dueCreatedDateTo]);

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleSelectEventType = (eventType: string) => {
    setSelectedEventTypes([...selectedEventTypes, eventType]);
  };

  const handleDeselectEventType = (eventTypeToDeselect: string) => {
    setSelectedEventTypes(selectedEventTypes.filter((eventType) => eventType !== eventTypeToDeselect));
  };

  const handleApplyClick = () => {
    onApplyFilters({
      eventTypes: selectedEventTypes,
      statuses: selectedStatuses.map((status) => status.id as WebhookEventStatus),
      dueCreatedDateFrom: fromCreatedDate,
      dueCreatedDateTo: toCreatedDate,
    });
  };

  const isApplyButtonDisabled = () => {
    const selectedStatusesIds = selectedStatuses.map(({ id }) => id);

    return areArraysEqual(eventTypes, selectedEventTypes)
      && areArraysEqual(statuses, selectedStatusesIds)
      && dueCreatedDateTo === toCreatedDate
      && dueCreatedDateFrom === fromCreatedDate;
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFilters}
      onClose={onCloseFilters}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletionMultiselect
        containerClassName={styles.statusMultiselectContainer}
        id="status"
        labelTitle="Status"
        isLabelTags {...statusSelectProps}
      />
      <EventTypeMultiselect
        labelTitle="Event Type"
        id="eventTypes"
        selectedEventTypes={selectedEventTypes}
        onSelectEventType={handleSelectEventType}
        onDeselectEventType={handleDeselectEventType}
        hideDescription
      />
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Event Date"
      />
      <Button
        kind="primary"
        size="form"
        disabled={isApplyButtonDisabled()}
        className={styles.applyFiltersButton}
        onClick={handleApplyClick}
      >
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default WebhookEventsFilters;
