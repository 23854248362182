import React, { FC, useRef } from 'react';
import styles from './EmailView.module.scss';
import { Email } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { CloseButtonImage, ReplyImage, ForwardImage } from 'static/images';
import ButtonWithImage from 'components/ButtonWithImage';
import EmailAttachmentsTable from './EmailAttachmentsTable';
import typography from 'styles/typography.module.scss';
import { batch, useDispatch } from 'react-redux';
import {
  downloadEmailAttachments,
  downloadEmailAttachmentsFailure,
  downloadEmailAttachmentsSuccess,
} from 'LoanOriginationSystemEmails/ActionCreator';
import { sendReplyEmail } from 'components/LoanOriginationSystem/EmailsDashboard/utils/sendReplyEmail';
import { forwardEmail } from 'components/LoanOriginationSystem/EmailsDashboard/utils/forwardEmail';
import Button from 'components/Button';
import { nanoid } from 'nanoid';
import { showSnack } from 'Snackbar/ActionCreator';
import { SnackbarComponentTypes } from 'Snackbar/Types';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { useEmailsApi } from 'providers/ApiServiceProvider';
import Header from './Header';
import clsx from 'clsx';

interface EmailViewProps {
  currentEmailInfo: Email;
  onClose: () => void;
  organizationEmail: string;
  className?: string;
  isWizardView?: boolean;
}

const DEFAULT_IFRAME_MARGING = 72 + 16; // 36px in top + 36px in bottom + 16px internal iframe marging
const mailStylesConnect = `<style>@import url(${typography.mainFont}); body,span,p{font-family: Montserrat, 'sans-serif' !important; color: ${styles.mailTextColor}; font-size: ${styles.mailTextFontSize}; line-height: ${styles.mailTextLineHeight}; font-weight: ${styles.mailTextFontWeight}; font-style: normal}</style>`;

const EmailView: FC<EmailViewProps> = ({ currentEmailInfo, onClose, organizationEmail, isWizardView, className }) => {
  const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { subject, html, files, id } = currentEmailInfo;
  const downloadApi = useEmailsApi();

  const resizeIframe = () => {
    const iframeContentHeight = iframeRef.current!.contentWindow!.document.body.scrollHeight;
    iframeRef.current!.style.height = `${iframeContentHeight + DEFAULT_IFRAME_MARGING}px`;
  };

  const handleDownloadEmailAttachments = async () => {
    const attachmentDownloadingId = nanoid();

    batch(() => {
      dispatch(
        showSnack('Downloading Attachments', {
          componentType: SnackbarComponentTypes.ApplicationEmailAttachmentsComponent,
          props: {
            attachment: {
              name: subject,
              format: 'zip',
            },
            attachmentDownloadingId,
          },
        }),
      );
      dispatch(downloadEmailAttachments(attachmentDownloadingId));
    });
    try {
      const response = await downloadApi.downloadAttachments(id);
      downloadBlobFile(response);

      dispatch(downloadEmailAttachmentsSuccess(attachmentDownloadingId));
    } catch (error) {
      dispatch(downloadEmailAttachmentsFailure(attachmentDownloadingId));
    }
  };

  return (
    <div className={clsx(styles.emailViewContainer, className)}>
      <div className={styles.header}>
        <h1 className={styles.title}>{subject}</h1>
        {isWizardView ? (
          <ButtonWithImage title="Back To Borrower Emails" kind="leftArrow" onClick={onClose} />
        ) : (
          <CloseButtonImage onClick={onClose} className={styles.closeButton} />
        )}
      </div>
      <Header email={currentEmailInfo} organizationEmail={organizationEmail} />
      <iframe
        ref={iframeRef}
        srcDoc={`${mailStylesConnect}${html}`}
        className={styles.mailContent}
        title="Mail Content"
        onLoad={resizeIframe}
        scrolling="no"
      />
      {Boolean(files.length) && (
        <div className={styles.attachmentsContainer}>
          <h6 className={styles.attachmentsTitle}>{files.length} Attachments</h6>
          <EmailAttachmentsTable attachments={files} />
          <ButtonWithImage
            title="Download Attachments"
            onClick={handleDownloadEmailAttachments}
            kind="download"
            className={styles.downloadAttachments}
          />
        </div>
      )}

      <div className={styles.buttonsContainer}>
        <Button
          kind="secondary"
          onClick={() => sendReplyEmail(currentEmailInfo, organizationEmail)}
          className={styles.replyButton}
        >
          <ReplyImage />
          Reply
        </Button>
        <Button kind="secondary" onClick={() => forwardEmail(currentEmailInfo, organizationEmail)}>
          <ForwardImage />
          Forward
        </Button>
      </div>
    </div>
  );
};

export default EmailView;
