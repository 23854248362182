import { AnyAction, MiddlewareAPI } from 'redux';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { LockBorrowerSuccessAction } from './ActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';

const isLockBorrowerSuccessAction = (action: AnyAction): action is LockBorrowerSuccessAction =>
  action.type === LoanOriginationSystemBorrowerLockType.LockBorrowerSuccess;

const lockBorrowerSuccessMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isLockBorrowerSuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.BorrowerLocked, {
        borrowerName: getBorrowerFullName(
          action.payload.borrower.type,
          action.payload.borrower.variables,
        ),
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};

export default lockBorrowerSuccessMiddleware;
