import React from 'react';
import clsx from 'clsx';
import { CheckedImage } from 'static/images';
import styles from './Step.module.scss';

interface StepProps {
  className?: string;
  title: string;
  passed?: boolean;
  active?: boolean;
  current?: boolean;
  number: number;
  onClick?: () => void;
}

const Step = ({ title, passed, current, active, number, onClick, className }: StepProps) => {
  const stepStyles = clsx(
    styles.step,
    {
      [styles.active]: active,
      [styles.current]: current,
      [styles.passed]: passed,
    },
    className,
  );

  return (
    <div className={stepStyles} onClick={onClick}>
      <div className={styles.number}>{passed ? <CheckedImage /> : number}</div>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

export default Step;
