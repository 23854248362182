import React, { FC  } from 'react';
import { WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import { Tag } from 'components/Tag';
import { WebhookEndpointsColumnSize } from 'components/Webhooks/WebhookEndpointsTable/TableColumnSize';
import styles from './WebhookEndpointItemRow.module.scss';

interface WebhookEndpointItemRowProps {
  webhookEndpoint: WebhookEndpoint;
  onClick: (id: string) => void;
  onChangeEndpointStatus: (id: string, active: boolean) => void;
  onWebhookEndpointDelete: (id: string) => void;
  onWebhookEndpointEdit: (id: string) => void;
}

const WebhookEndpointItemRow: FC<WebhookEndpointItemRowProps> = ({
  webhookEndpoint,
  onClick,
  onChangeEndpointStatus,
  onWebhookEndpointDelete,
  onWebhookEndpointEdit,
}) => {
  const renderCellContent = (content: React.ReactNode) => (
    <OverflowedText>
      {content || <TableBodyCellNoContent />}
    </OverflowedText>
  );

  const renderStatusTag = () => {
    if (webhookEndpoint.active) {
      return <Tag color="green">Active</Tag>
    }

    return <Tag color="gray">Inactive</Tag>
  }

  return (
    <TableRow useActions key={webhookEndpoint.id} onClick={() => onClick(webhookEndpoint.id)}>
      <TableBodyCell width={WebhookEndpointsColumnSize.Url}>
        {renderCellContent(webhookEndpoint.url)}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.Description}>
        {webhookEndpoint.description && renderCellContent(webhookEndpoint.description)}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.ListeningFor}>
        {renderCellContent(webhookEndpoint.events.join(', '))}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.Status}>
        {renderStatusTag()}
      </TableBodyCell>
      <TableBodyCell width={WebhookEndpointsColumnSize.UpdatedAt} noPadding>
        <WithSystemApiUserAvatar className={styles.avatar} user={webhookEndpoint.updatedBy} size="small" />
        <OverflowedText>{formatDate(webhookEndpoint.updatedAt, DateTimeFormat.Short)}</OverflowedText>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit Endpoint',
            handler: () => onWebhookEndpointEdit(webhookEndpoint.id),
          },
          {
            title: webhookEndpoint.active ? 'Set as Inactive' : 'Set as Active',
            handler: () => onChangeEndpointStatus(webhookEndpoint.id, !webhookEndpoint.active),
            separatorRequired: true,
          },
          {
            title: 'Delete Endpoint',
            handler: () => onWebhookEndpointDelete(webhookEndpoint.id),
            danger: true,
          },
        ]}
      />
    </TableRow>
  );
};

export default WebhookEndpointItemRow;
