import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { ApplicationDocument, ApplicationDocumentsSortingField } from 'api/LoanOriginationSystem/DocumentsApi';
import { isFile, isFolder } from 'LoanOriginationSystemApplicationPage/Documents/utils';

export const getSortingType = (state: ReduxState) => state.loanOriginationSystemDocuments.sortingType;

export const getSearchValue = (state: ReduxState) => state.loanOriginationSystemDocuments.search;

export const getDocumentIdsByApplicationId = (state: ReduxState, applicationId: string) =>
  state.loanOriginationSystemDocuments.documentsIdsByApplicationId[applicationId];

export const getDocumentsById = (state: ReduxState) => state.loanOriginationSystemDocuments.documentsById;

export const getDocumentsByApplicationId = createSelector(
  [getDocumentIdsByApplicationId, getDocumentsById],
  (documentsIds, documentsById) => {
    if (!documentsIds) {
      return null;
    }

    return documentsIds.map((id) => documentsById[id]);
  },
);

export const getDocumentToUpdateId = (state: ReduxState) => state.loanOriginationSystemDocuments.documentToUpdateId;

export const getDocumentToUpdate = createSelector(
  [getDocumentsById, getDocumentToUpdateId],
  (documentsIds, documentToUpdateId) => {
    if (!documentsIds || !documentToUpdateId) {
      return null;
    }

    return documentsIds[documentToUpdateId];
  },
);

export const getParentFolderId = (state: ReduxState) => state.loanOriginationSystemDocuments.parentFolderId;

let prevNameSortOrderAscending = false;

export const getNestedDocuments = createSelector(
  [getDocumentsByApplicationId, getParentFolderId, getSortingType, getSearchValue],
  (documents, parentFolderId, { ascending, field }, search) => {
    if (!documents) {
      return null;
    }

    return documents
      .filter(({ parentId }) => parentId === parentFolderId)
      .filter((document) =>
        search ? isFile(document) && document.name.toLowerCase().includes(search.trim().toLowerCase()) : document,
      )
      .sort((docA, docB) => {
        if (isFolder(docA) && isFolder(docB)) {
          if (field === ApplicationDocumentsSortingField.Name) {
            prevNameSortOrderAscending = ascending;

            return ascending ? docA[field].localeCompare(docB[field]) : docB[field].localeCompare(docA[field]);
          }

          return prevNameSortOrderAscending ? docA.name.localeCompare(docB.name) : docB.name.localeCompare(docA.name);
        }
        if (isFolder(docA)) return -1;
        if (isFolder(docB)) return 1;

        switch (field) {
          case ApplicationDocumentsSortingField.Name:
          case ApplicationDocumentsSortingField.Extension: {
            return ascending ? docA[field].localeCompare(docB[field] ?? '') : docB[field]?.localeCompare(docA[field]);
          }
          case ApplicationDocumentsSortingField.CreatedAt:
          case ApplicationDocumentsSortingField.UpdatedAt: {
            return ascending
              ? new Date(docA[field]).getTime() - new Date(docB[field]).getTime()
              : new Date(docB[field]).getTime() - new Date(docA[field]).getTime();
          }
          case ApplicationDocumentsSortingField.Size: {
            return ascending ? docA[field] - docB[field] : docB[field] - docA[field];
          }
          default: {
            return -1;
          }
        }
      });
  },
);

const getPath = (documents: Record<string, ApplicationDocument>, id: string | null): ApplicationDocument[] =>
  id && documents[id] ? [...getPath(documents, documents[id].parentId), documents[id]] : [];

export const getNestedDocumentsPath = createSelector(
  [getDocumentsById, getParentFolderId],
  (documentsById, parentFolderId) => getPath(documentsById, parentFolderId),
);
