import React, { PropsWithChildren } from 'react';
import BaseTabLayout, {
  BaseTabLayoutProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/BaseTabLayout';
import styles from './SuccessTab.module.scss';

export interface SuccessTabProps extends BaseTabLayoutProps {
  icon: React.ReactNode;
  tabType: string;
}

const SuccessTab = ({ icon, tabType, children, ...restProps }: PropsWithChildren<SuccessTabProps>) => (
  <BaseTabLayout {...restProps}>
    <div className={styles.tabContent}>
      <div className={styles.iconContainer}>{icon}</div>
      <h5 className={styles.successTitle}>
        Success{' '}
        <span aria-label="success" role="img">
          🎉
        </span>
      </h5>
      <div className={styles.description}>
        <p>The {tabType} tab has been activated for your product.</p>
        {children}
      </div>
    </div>
  </BaseTabLayout>
);

export default SuccessTab;
