import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortIntermediariesAction } from './ActionCreator';
import {
  FilterIntermediariesByMembersAction,
  ChangePopupFiltersAction,
  ResetAllIntermediariesFilters,
} from './Filters/ActionCreator';
import { SORT_INTERMEDIARIES, LoanOriginationSystemIntermediariesActionType } from './ActionTypes';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './Filters/ActionTypes';
import { LoanOriginationSystemIntermediariesFiltersState } from './Filters/Types';
import { IntermediariesSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

type IntermediaryFiltersToSave = Pick<
  LoanOriginationSystemIntermediariesFiltersState,
  'selectedMembers' | 'borrowerTypes' | 'dueUpdatedDateRange' | 'dueCreatedDateRange'
>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters } = state.loanOriginationSystemIntermediariesState;

  return pick(filters, [
    'selectedMembers',
    'borrowerTypes',
    'dueUpdatedDateRange',
    'dueCreatedDateRange',
  ]);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemIntermediariesState;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<FilterIntermediariesByMembersAction>(
    LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers,
  ),
  isActionOfType<ChangePopupFiltersAction>(LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllIntermediariesFilters>(LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters),
  isActionOfType<SortIntermediariesAction>(SORT_INTERMEDIARIES),
];

const intermediariesFilterAndSortingSaver = FilterAndSortingSaver<IntermediaryFiltersToSave, IntermediariesSortingType>(
  {
    key: 'intermediaries',
    getFiltersToSave,
    getSortingToSave,
    saveTriggerActions,
    resetActionType: LoanOriginationSystemIntermediariesActionType.ResetState,
  },
);

export default intermediariesFilterAndSortingSaver;
