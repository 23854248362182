import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  filterProductsByMembers,
  resetAllProductsFilters,
  setSearchInputValue,
  setShowArchived,
  toggleFiltersPopup,
} from 'LoanOriginationSystemProducts/Filters/ActionCreator';
import { getProducts, setProductToDuplicate, setStatusChangeData } from 'LoanOriginationSystemProducts/ActionCreator';
import ProductsDashboardHeader from './ProductsDashboardHeader';
import ProductsList from './ProductsList';
import styles from './ProductsDashboard.module.scss';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { intersection } from 'lodash';
import { NoResultsIcon } from 'static/images';

export interface ProductsDashboardProps {
  onCreateProduct: () => void;
  onProductSelect: (product: Product) => void;
  onDownloadApiRequestTemplate: () => void;
}

const DEFAULT_PRODUCTS = new Array(8).fill(null);

const ProductsDashboard = ({
  onCreateProduct,
  onProductSelect,
  onDownloadApiRequestTemplate,
}: ProductsDashboardProps) => {
  const dispatch = useDispatch();
  const organizationMembers = useOrganizationMembers();
  const { filters, items: products, isLoading } = useSelector((state: ReduxState) => state.loanOriginationSystemProducts);

  useEffect(() => {
    if (!products) {
      dispatch(getProducts(filters));
    }
  }, []);

  const handleSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const handleChangeShowArchived = () => {
    dispatch(setShowArchived(!filters.showArchived));
  };

  const handleProductDuplicate = (product: Product) => {
    dispatch(setProductToDuplicate(product));
  };

  const handleChangeProductStatus = (product: Product, status: ProductStatus) => {
    dispatch(
      setStatusChangeData({
        product,
        status,
      }),
    );
  };

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterProductsByMembers(newSelectedMembers));
  };

  const onFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const areFiltersChanged = () => {
    return (
      !!intersection(filters.statuses, [ProductStatus.Active, ProductStatus.Draft]).length ||
      filters.dueCreatedDateRange.from !== null ||
      filters.dueCreatedDateRange.to !== null ||
      filters.dueUpdatedDateRange.from !== null ||
      filters.dueUpdatedDateRange.to !== null ||
      filters.borrowerType !== null
    );
  };

  const displayFilters =
    !products
    || !!products.length
    || !!filters.search
    || areFiltersChanged()
    || !!filters.selectedMembers.length
    || isLoading;

  const onClearFiltersClick = () => {
    dispatch(resetAllProductsFilters());
  };

  const renderBody = () => {
    if (products && !products.length && !isLoading) {
      if (areFiltersChanged() || filters.selectedMembers.length) {
        return (
          <NoItems
            className={styles.noResultsFoundContainer}
            title="No Results Found"
            icon={<NoResultsIcon />}
            buttonMessage="Clear all filters"
            subtitle="Please remove or adjust your filters."
            onButtonClick={onClearFiltersClick}
          />
        )
      }

      if (filters.search) {
        return <SearchNotFound searchValue={filters.search} />;
      }

      if (!filters.search && !areFiltersChanged() && !filters.selectedMembers.length) {
        return (
          <NoItems
            className={styles.noItems}
            titleClassName={styles.noItemsTitle}
            buttonClassName={styles.noItemsButton}
            title="No products have been added yet!"
            buttonMessage="Add Product"
            onButtonClick={onCreateProduct}
          />
        )
      }
    }

    return (
      <ProductsList
        products={products || DEFAULT_PRODUCTS}
        onProductSelect={onProductSelect}
        onProductDuplicate={handleProductDuplicate}
        onChangeProductStatus={handleChangeProductStatus}
      />
    );
  };

  return (
    <div className={styles.container}>
      <ProductsDashboardHeader
        search={filters.search}
        showArchived={filters.showArchived}
        onSearch={handleSearch}
        onAddProduct={onCreateProduct}
        onChangeShowArchived={handleChangeShowArchived}
        onDownloadApiRequestTemplate={onDownloadApiRequestTemplate}
        displayFilters={displayFilters}
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        onMemberFilterChange={onMemberFilterChange}
        onFilterButtonClick={onFilterButtonClick}
        filtersChanged={areFiltersChanged()}
        areProductsLoading={!products}
      />
      {renderBody()}
    </div>
  );
};

export default ProductsDashboard;
