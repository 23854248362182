import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import ApplicationDocumentsApiRest, {
  ApplicationDocument,
  ApplicationDocumentsSortingType, BatchUploadDocumentParams,
  UpdateApplicationDocumentParams,
} from 'api/LoanOriginationSystem/DocumentsApi';
import DocuSignEnvelopeApiRest from 'api/LoanOriginationSystem/DocuSignEnvelopeApi';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { RequestUploadParams } from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';
import responseHandlers from 'api/ResponseHandlers';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { ApplicationDocumentPopUpType } from './Types';

const applicationDocumentsApi = new ApplicationDocumentsApiRest(responseHandlers);
const docuSignEnvelopeApiRest = new DocuSignEnvelopeApiRest(responseHandlers);

export const getApplicationDocuments = createAsyncThunk<ApplicationDocument[], string, {}>(
  ApplicationDocumentsActionType.GetApplicationDocuments,
  (applicationId) => {
    return applicationDocumentsApi.getAll(applicationId);
  },
);

export const uploadApplicationDocument = createAsyncThunk<
  ApplicationDocument,
  { applicationId: string; file: File; parentId: string | null },
  {}
>(ApplicationDocumentsActionType.UploadApplicationDocument, ({ applicationId, file, parentId }) => {
  return applicationDocumentsApi.upload(applicationId, file, parentId);
});

export const createDocumentFolder = createAsyncThunk<
  ApplicationDocument,
  { applicationId: string; name: string; parentId: string | null },
  {}
>(ApplicationDocumentsActionType.CreateApplicationDocumentFolder, ({ applicationId, name, parentId }) => {
  return applicationDocumentsApi.createFolder(applicationId, name, parentId);
});

export const updateApplicationDocument = createAsyncThunk<
  ApplicationDocument,
  { documentId: string; document: UpdateApplicationDocumentParams },
  {}
>(ApplicationDocumentsActionType.UpdateApplicationDocument, async ({ documentId, document }) => {
  return applicationDocumentsApi.update(documentId, document);
});

export const deleteApplicationDocument = createAsyncThunk<void, string, {}>(
  ApplicationDocumentsActionType.DeleteApplicationDocument,
  (documentId) => {
    return applicationDocumentsApi.delete(documentId);
  },
);

export const requestCustomerUpload = createAsyncThunk<string, RequestUploadParams, {}>(
  ApplicationDocumentsActionType.RequestCustomerUpload,
  (params) => {
    return applicationDocumentsApi.requestUpload(params);
  },
);

export const createDocuSignEnvelope = createAsyncThunk<void, { applicationId: string; templateId: string }, {}>(
  ApplicationDocumentsActionType.CreateDocuSignEnvelope,
  ({ applicationId, templateId }) => {
    return docuSignEnvelopeApiRest.createEnvelope(applicationId, templateId);
  },
);

export const downloadApplicationDocument = createAsyncThunk<
  void,
  { documentId: string; documentDownloadingId: string },
  {}
>(ApplicationDocumentsActionType.DownloadDocument, async ({ documentId }) => {
  const response = await applicationDocumentsApi.download(documentId);
  downloadBlobFile(response);
});

export const downloadArchiveOfApplicationDocuments = createAsyncThunk<
  void,
  { applicationId: string; documentDownloadingId: string },
  {}
>(ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments, async ({ applicationId }) => {
  const response = await applicationDocumentsApi.downloadMany(applicationId);
  downloadBlobFile(response);
});

export const batchUploadApplicationDocuments = createAsyncThunk<void, { applicationId: string; params: BatchUploadDocumentParams[] }>(
  ApplicationDocumentsActionType.BatchUploadApplicationDocuments,
  async ({ applicationId, params }) => {
    await applicationDocumentsApi.batchUpload(applicationId, params);
  },
);

export const setParentFolder = createAction<string | null>(ApplicationDocumentsActionType.SetParentFolder);

export const sortApplicationDocuments = createAction<ApplicationDocumentsSortingType>(
  ApplicationDocumentsActionType.SortApplicationDocuments,
);

export const openApplicationDocumentPopup = createAction<ApplicationDocumentPopUpType>(
  ApplicationDocumentsActionType.OpenApplicationDocumentPopup,
);

export const closeApplicationDocumentPopup = createAction(ApplicationDocumentsActionType.CloseApplicationDocumentPopup);

export const setDocumentToUpdate = createAction<string | null>(ApplicationDocumentsActionType.SetDocumentToUpdate);

export const setSearchValue = createAction<string>(ApplicationDocumentsActionType.SetSearchValue);

export const removeDownloadedDocument = createAction<string>(ApplicationDocumentsActionType.RemoveDownloadedDocument);

export const resetApplicationDocumentsState = createAction<boolean>(ApplicationDocumentsActionType.ResetState);
