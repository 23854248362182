import { AnyAction } from 'redux';
import { keyBy } from 'lodash';
import { Variable } from 'Variables/VariablesTypes';
import { LoadVariablesAction, LoadVariablesSuccessAction } from './Actions';
import { VariablesAttributesActionTypes } from './ActionTypes';

export type VariablesAttributesState = {
  byId: Partial<Record<string, Variable>>,
  bySystemName: Partial<Record<string, Variable>>,
};

const initialState: VariablesAttributesState = {
  byId: {},
  bySystemName: {},
};

type HandledAction =
  | LoadVariablesAction
  | LoadVariablesSuccessAction;

const VariablesSelectorReducer = (state = initialState, action: HandledAction): VariablesAttributesState => {
  switch (action.type) {
    case VariablesAttributesActionTypes.LoadVariablesSuccess: {
      const variables = action.payload;
      const variablesById = keyBy(variables, 'id');
      const variablesBySystemName = keyBy(variables, 'systemName');
      return {
        ...state,
        byId: {
          ...state.byId,
          ...variablesById,
        },
        bySystemName: {
          ...state.bySystemName,
          ...variablesBySystemName,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default VariablesSelectorReducer as (
  state: VariablesAttributesState,
  action: AnyAction,
) => VariablesAttributesState;
