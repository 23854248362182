import { CreateNewAccountInviteData } from 'api/Core/UserInviteService';
import getMessage from 'constants/messages';
import { validatePassword } from 'utils/validation/validation';

const MIN_LENGTH = 2;

export const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
};

const validateCreateAccountInviteForm = (data: Partial<CreateNewAccountInviteData>) => {
  const errors: Partial<CreateNewAccountInviteData> = Object.keys(data).reduce((result, key) => {
    if (data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key].length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const passwordErrorMessageType = validatePassword(data.password || '');

  if (passwordErrorMessageType) {
    errors.password = getMessage(passwordErrorMessageType);
  }

  return errors;
};

export default validateCreateAccountInviteForm;
