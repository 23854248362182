import React, { FC } from 'react';
import { ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { DateTimeFormat } from 'utils/dateFormat';
import TableColumnSizes from 'components/DocuSign/TemplatesTable/TableColumnSizes';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import SearchHighlight from 'components/SearchHighlight';
import RowActions from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { WarningNotificationIcon } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import Tag, { TagColor } from 'components/Tag/Tag';
import DateTime from 'components/DateTime';
import styles from './TemplateTableRow.module.scss';

interface TemplateTableRowProps {
  template: ESignTemplate;
  searchInputValue: string;
  onTemplateDelete: (id: string) => void;
  onTemplateEdit: (id: string) => void;
}

const getTagColorByActive = (active: boolean): TagColor => {
  return active ? 'green' : 'gray';
};

const TemplateTableRow: FC<TemplateTableRowProps> = ({
  template,
  searchInputValue,
  onTemplateDelete,
  onTemplateEdit,
}) => {
  const { id, name, updatedBy, active, fields, recipients, variablesMapping, recipientsMapping } = template;

  const isFieldsUnmapped =
    Object.values(variablesMapping).length !== fields.length ||
    Object.values(recipientsMapping).length !== recipients.length;

  const actions = [
    {
      title: 'Edit Template',
      handler: () => onTemplateEdit(id),
      separatorRequired: true,
    },

    {
      title: 'Delete Template',
      handler: () => onTemplateDelete(id),
      danger: true,
    },
  ];

  return (
    <TableRow onClick={() => onTemplateEdit(id)} useActions>
      <TableBodyCell width={TableColumnSizes.Name} className={styles.nameCell}>
        <SearchHighlight search={searchInputValue}>{name}</SearchHighlight>
        {isFieldsUnmapped && (
          <WrapperWithTooltip tooltip="This template has unmapped fields">
            <WarningNotificationIcon />
          </WrapperWithTooltip>
        )}
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.UpdatedAt} className={styles.updatedCell} noPadding>
        <WithSystemApiUserAvatar user={updatedBy} size="small" />
        <DateTime time={template.updatedAt} format={DateTimeFormat.Long} />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Status}>
        <Tag color={getTagColorByActive(active)}>{active ? 'Active' : 'Inactive'}</Tag>
      </TableBodyCell>
      <RowActions actions={actions} />
    </TableRow>
  );
};

export default TemplateTableRow;
