import React, { useEffect, useState } from 'react';
import TasksForm from 'components/LoanOriginationSystem/TasksForm';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import {
  NewTask,
  Task,
  TaskApplicationInfo,
  TaskStatus,
} from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import Button from 'components/Button';
import styles from './EditTaskForm.module.scss';
import { isEqual } from 'lodash';
import {
  UserInfo
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

interface EditTaskProps {
  applications: SimplifiedApplication[];
  organizationMembers: UserInfo[];
  task: Task;
  setDataWasChanged: (changed: boolean) => void;
  handleTaskEdit: (id: string, editTaskData: NewTask) => void;
  isUpdatingInProgress: boolean;
  lockApplication?: boolean;
  showGoToApplicationLink?: boolean;
}

const EditTaskForm = ({
  applications,
  organizationMembers,
  task,
  setDataWasChanged,
  handleTaskEdit,
  isUpdatingInProgress,
  lockApplication = false,
  showGoToApplicationLink = true,
}: EditTaskProps) => {
  const [selectedStatus, setSelectedStatus] = useState<TaskStatus>(TaskStatus.NotDone);
  const [selectedApplication, setSelectedApplication] = useState<TaskApplicationInfo | null>(task.application);
  const [dueDate, setDueDate] = useState<string>(task.dueDate);
  const [description, setDescription] = useState(task.description);

  const editTaskInitialValue: NewTask = {
    description: task.description,
    application: task.application,
    teamMembers: task.teamMembers,
    dueDate: task.dueDate,
    status: TaskStatus.NotDone,
  };

  const [membersProps, selectedMembers] = useAutoCompletionMultiSelectProps(
    organizationMembers,
    [...task.teamMembers],
    (member) => member.id,
    (member) => `${member.firstName} ${member.lastName}`,
    (member) => <WithSystemApiUserAvatar user={member} size="small" />,
    (member) => <WithSystemApiUserAvatar user={member} size="small" />,
  );

  const getTask = () => ({
    description,
    application: selectedApplication!,
    teamMembers: selectedMembers,
    dueDate: dueDate!,
    status: selectedStatus,
  });

  const validateTask = () => {
    return description && dueDate;
  };

  const isEditTaskDataWasChanged = !isEqual(editTaskInitialValue, getTask());

  useEffect(() => {
    setDataWasChanged(isEditTaskDataWasChanged);
  }, [description, selectedStatus, selectedMembers, selectedApplication, dueDate]);

  return (
    <div className={styles.taskFormContainer}>
      <TasksForm
        description={description}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        applications={applications}
        members={organizationMembers}
        dueDate={dueDate}
        setDueDate={setDueDate}
        selectedApplication={selectedApplication}
        setSelectedApplication={setSelectedApplication}
        setDescription={setDescription}
        membersProps={membersProps}
        lockApplication={lockApplication}
        showGoToApplicationLink={showGoToApplicationLink}
      />
      <Button
        size="form"
        kind="primary"
        className={styles.createButton}
        disabled={!isEditTaskDataWasChanged || !validateTask()}
        onClick={() => handleTaskEdit(task.id, getTask())}
        isLoading={isUpdatingInProgress}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default EditTaskForm;
