import React, { FC } from 'react';
import styles from 'components/ApiActivation/Table/StrategyTableVersion/StrategyTableVersion.module.scss';
import StrategyVersionInfo from 'components/StrategyGroups/StrategyVersionInfo';
import { StrategyGroupVersion } from 'api/Types';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';

interface StrategyTableVersionProps {
  versions: StrategyGroupVersion[];
  status: NormalizedStatus;
}

const StrategyTableVersion: FC<StrategyTableVersionProps> = ({ versions, status }) => {
  const currentVersion = versions.find((version) => version.status === status);
  return (
    <div className={styles.versionContainer}>
      {currentVersion ? (
        <StrategyVersionInfo
          version={currentVersion}
          isTable
          key={currentVersion.id}
          disabled
          className={styles.version}
        />
      ) : (
        <div className={styles.emptyVersion}>—</div>
      )}
    </div>
  );
};

export default StrategyTableVersion;
