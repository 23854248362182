import React, { FC } from 'react';
import ButtonWithImage from 'components/ButtonWithImage';
import styles from './ProfileDescriptionTooltip.module.scss';

export interface ProfileDescriptionTooltipProps {
  profileName: string;
  onGoToProfileClick: () => void;
}

const ProfileDescriptionTooltip: FC<ProfileDescriptionTooltipProps> = ({
  profileName,
  onGoToProfileClick,
}) => (
  <div className={styles.profileDescriptionTooltip}>
    <p>You can change this layout in the</p>
    <p>{profileName} Profiles section</p>
    <ButtonWithImage
      onClick={onGoToProfileClick}
      className={styles.goToBorrowerProfileButton}
      title={`Go To ${profileName} Profiles`}
      kind="link"
    />
  </div>
);

export default ProfileDescriptionTooltip;
