import borrowersPagination from 'components/LoanOriginationSystem/BorrowersDashboard/pagination';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export default (api: LoanOriginationSystemBorrowersApi) => {
  return borrowersPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getBorrowers(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        isCompany: params.isCompany,
        isPerson: params.isPerson,
        dueCreatedDateRange: params.dueCreatedDateRange,
        dueUpdatedDateRange: params.dueUpdatedDateRange,
        members: params.selectedMembers,
      },
      params.sortingType,
    );
  });
};
