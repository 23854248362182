import { Worker } from 'threads';
import createWorkerHook from './createWorkerHook';

interface VariablePosition {
  line: number;
  column: number;
}

interface GlobalVariable {
  name: string;
  nodes: Array<{
    start: number;
    end: number;
    endPosition: VariablePosition;
    startPosition: VariablePosition;
  }>;
}

export const useGlobalsSearchWorker = createWorkerHook<{
  searchGlobals: (code: string) => GlobalVariable[];
}>(() => {
  return new Worker('workers/GlobalsSearchWorker');
});
