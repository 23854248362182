import { useSelector } from 'react-redux';
import { ProductSection, ProductSectionAccessPermissionsMap } from './Types';
import { ReduxState } from 'types/redux';

const useProductSectionAccessPermissions = (productSection: ProductSection) => {
  const productSectionAccessPermissions = useSelector<ReduxState, ProductSectionAccessPermissionsMap | null>(
    ({ sessionInfo }) => sessionInfo.accessPermissions,
  );

  if (!productSectionAccessPermissions) {
    return undefined;
  }

  return productSectionAccessPermissions[productSection];
};

export default useProductSectionAccessPermissions;
