import { AnyAction } from 'redux';
import { Borrower, BorrowerSuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  CREATE_APPLICATION,
  CREATE_APPLICATION_FAILED,
  CREATE_APPLICATION_SUCCESS,
  LOAD_BORROWERS_SUGGESTIONS,
  LOAD_BORROWERS_SUGGESTIONS_FAILED,
  LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
  LoanOriginationSystemCreateApplicationActionType,
  SELECT_BORROWER,
  SELECT_CO_BORROWER,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Application, CreateApplicationParams } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetBorrowersSuggestionsActionPayload {
  filter: BorrowerSuggestionFilter;
}

export interface GetIntermediarySuggestionsActionPayload {
  filter: IntermediarySuggestionFilter;
}

export interface LoadBorrowersSuggestionsAction extends AnyAction {
  type: typeof LOAD_BORROWERS_SUGGESTIONS;
  payload: GetBorrowersSuggestionsActionPayload;
}

export interface LoadIntermediarySuggestionsAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestions;
  payload: GetIntermediarySuggestionsActionPayload;
}

export interface LoadBorrowersSuggestionsSuccessAction extends AnyAction {
  type: typeof LOAD_BORROWERS_SUGGESTIONS_SUCCESS;
  payload: {
    suggestions: Borrower[];
  };
}

export interface LoadIntermediarySuggestionsSuccessAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess;
  payload: {
    suggestions: Intermediary[];
  };
}

export interface SelectBorrowerAction extends AnyAction {
  type: typeof SELECT_BORROWER;
  payload: {
    borrower: Borrower | null;
  };
}

export interface SelectIntermediaryAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediary;
  payload: {
    intermediary: Intermediary | null;
  };
}

export interface SelectCoBorrowerAction extends AnyAction {
  type: typeof SELECT_CO_BORROWER;
  payload: {
    borrower: Borrower | null;
    index: number;
  };
}

export interface CreateApplicationAction extends AnyAction {
  type: typeof CREATE_APPLICATION;
  payload: CreateApplicationParams;
}

export interface CreateApplicationSuccessAction extends AnyAction {
  type: typeof CREATE_APPLICATION_SUCCESS;
  payload: {
    application: Application;
  };
}

export interface ResetSelectedCoBorrowerAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.ResetSelectedCoBorrower;
}

export const loadBorrowersSuggestions = (filter: BorrowerSuggestionFilter): LoadBorrowersSuggestionsAction => {
  return {
    type: LOAD_BORROWERS_SUGGESTIONS,
    payload: {
      filter,
    },
  };
};

export const loadBorrowersSuggestionsSuccess = (suggestions: Borrower[]): LoadBorrowersSuggestionsSuccessAction => {
  return {
    type: LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
    payload: {
      suggestions,
    },
  };
};

export const loadBorrowersSuggestionsFailed = FailedActionCreator(LOAD_BORROWERS_SUGGESTIONS_FAILED);

export const loadIntermediarySuggestions = (
  filter: IntermediarySuggestionFilter,
): LoadIntermediarySuggestionsAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestions,
    payload: {
      filter,
    },
  };
};

export const loadIntermediarySuggestionsSuccess = (
  suggestions: Intermediary[],
): LoadIntermediarySuggestionsSuccessAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess,
    payload: {
      suggestions,
    },
  };
};

export const loadIntermediarySuggestionsFailed = FailedActionCreator(
  LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsFailed,
);

export const selectBorrower = (borrower: Borrower | null): SelectBorrowerAction => {
  return {
    type: SELECT_BORROWER,
    payload: {
      borrower,
    },
  };
};

export const selectIntermediary = (intermediary: Intermediary | null): SelectIntermediaryAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediary,
    payload: {
      intermediary,
    },
  };
};

export const selectCoBorrower = (borrower: Borrower | null, index: number): SelectCoBorrowerAction => {
  return {
    type: SELECT_CO_BORROWER,
    payload: {
      borrower,
      index,
    },
  };
};

export const createApplication = (params: CreateApplicationParams): CreateApplicationAction => {
  return {
    type: CREATE_APPLICATION,
    payload: params,
  };
};

export const createApplicationSuccess = (application: Application): CreateApplicationSuccessAction => {
  return {
    type: CREATE_APPLICATION_SUCCESS,
    payload: {
      application,
    },
  };
};

export const resetSelectedCoBorrowers = (): ResetSelectedCoBorrowerAction => ({
  type: LoanOriginationSystemCreateApplicationActionType.ResetSelectedCoBorrower,
});

export const createApplicationFailed = FailedActionCreator(CREATE_APPLICATION_FAILED);
