export const PermissionGroupsActionTypePrefix = 'permissionGroups';

export const PermissionGroupsActionType = {
  GetPermissionGroups: `${PermissionGroupsActionTypePrefix}/GetPermissionGroups`,
  UpdatePermissionGroup: `${PermissionGroupsActionTypePrefix}/UpdatePermissionGroup`,
  BulkUpdatePermissionGroups: `${PermissionGroupsActionTypePrefix}/BulkUpdatePermissionGroups`,
  CreatePermissionGroup: `${PermissionGroupsActionTypePrefix}/CreatePermissionGroup`,
  DeletePermissionGroup: `${PermissionGroupsActionTypePrefix}/DeletePermissionGroup`,
  OpenEditPermissionGroup: `${PermissionGroupsActionTypePrefix}/OpenEditPermissionGroup`,
  CloseEditPermissionGroup: `${PermissionGroupsActionTypePrefix}/CloseEditPermissionGroup`,
};

export default PermissionGroupsActionType;
