import { getCountries, getCountryCallingCode } from 'components/PhoneInput';
import phoneNumberInputLocale from 'react-phone-number-input/locale/en.json';
import { Option } from 'components/SelectInput/SelectInput';

export const PHONE_NUMBER_FORMATS: Option[] = getCountries().map((countryCode: string) => {
  return {
    value: countryCode,
    name: `+${getCountryCallingCode(countryCode)}`,
    description: phoneNumberInputLocale[countryCode],
  };
});
