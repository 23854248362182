export enum LoanOriginationSystemTasksActionTypes {
  GetTasks = 'loanOriginationSystemTasks/getTasks',
  GetTasksSuccess = 'loanOriginationSystemTasks/getTasksSuccess',
  GetTasksFailure = 'loanOriginationSystemTasks/getTasksFailure',
  SortTasks = 'loanOriginationSystemTasks/sortTasks',
  DeleteTask = 'loanOriginationSystemTasksFilters/deleteTask',
  DeleteTaskSuccess = 'loanOriginationSystemTasksFilters/deleteTaskSuccess',
  DeleteTaskFailure = 'loanOriginationSystemTasksFilters/deleteTaskFailure',
  OpenDeleteTaskPopup = 'loanOriginationSystemTasksFilters/openDeleteTaskPopup',
  CloseDeleteTaskPopup = 'loanOriginationSystemTasksFilters/closeDeleteTaskPopup',
  ResetState = 'loanOriginationSystemTasks/resetState',
  SavedFiltersSwitch = 'loanOriginationSystemTasks/switchSavedFilters',
}
