import React from 'react';
import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import { BorrowersColumnSizes } from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/TableColumnSizes';
import variables from 'components/RowActions/RowActions.module.scss';

const SkeletonBorrowerItemRow = ({ showSearchMatch }: { showSearchMatch: boolean }) => {
  return (
    <>
      <TableRow disableHover useActions noBottomBorder={showSearchMatch}>
        <TableBodyCell width={BorrowersColumnSizes.Name}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.IdNumber}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.Phone}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.Email}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.CreatedAt} noPadding>
          <SkeletonCircle width="28px" height="28px" color="primary6" />
          <SkeletonRectangle width="80%" height="16px" color="primary6" marginLeft="8px" />
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.UpdatedAt} noPadding>
          <SkeletonCircle width="28px" height="28px" color="primary6" />
          <SkeletonRectangle width="80%" height="16px" color="primary6" marginLeft="8px" />
        </TableBodyCell>
        <TableActionCell />
      </TableRow>
      {showSearchMatch ? (
        <TableRow disableHover noTopBorder>
          <TableBodyCell width={BorrowersColumnSizes.Name}> </TableBodyCell>
          <TableBodyCell width={100 - BorrowersColumnSizes.Name} dashedTopBorder>
            <SkeletonRectangle width="340px" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
            {' '}
          </TableBodyCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default SkeletonBorrowerItemRow;
