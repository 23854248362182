import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';
import { UpdateBorrowerApplicationsAction, updateBorrowerApplicationsSuccess, updateBorrowerApplicationsFailure } from './ActionCreator';

const UpdateBorrowerApplicationsMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<void, UpdateBorrowerApplicationsAction>(
    LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerApplications,
    updateBorrowerApplicationsSuccess,
    updateBorrowerApplicationsFailure,
    async (action) => {
      await api.updateBorrowerApplications(action.payload.borrowerId, action.payload.data);
    },
  );

export default UpdateBorrowerApplicationsMiddleware;
