import { createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  ApplicationHistoryFilterType,
  ApplicationHistorySortingField,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import {
  getApplicationHistory,
  changeFilterType,
  changeSelectedMembers,
  changeSortingType,
  changeSearchValue,
} from './ActionCreator';
import { ApplicationHistoryState } from './Types';
import { ApplicationHistoryActionType } from './ActionTypes';
import applicationHistoryFilterAndSortingSaver from './FilterAndSortingSaver';

export const getInitialState = (): ApplicationHistoryState => ({
  recordsById: {},
  recordsIdsByApplicationId: {},
  selectedMembers: [],
  filterType: ApplicationHistoryFilterType.AllTypes,
  ...applicationHistoryFilterAndSortingSaver.getSavedFilters(),
  sortingType: applicationHistoryFilterAndSortingSaver.getSavedSorting() || {
    field: ApplicationHistorySortingField.CreatedAt,
    ascending: false,
  },
  search: '',
  isGetRecordsPending: false,
});

const applicationHistoryReducer = createReducer<ApplicationHistoryState>(getInitialState(), (builder) => {
  builder
    .addCase(getApplicationHistory.pending, (state) => {
      state.isGetRecordsPending = true;
    })
    .addCase(getApplicationHistory.fulfilled, (state, action) => {
      state.isGetRecordsPending = false;
      state.recordsById = { ...state.recordsById, ...normalizeEntityArray(action.payload) };
      state.recordsIdsByApplicationId[action.meta.arg] = action.payload.map((record) => record.id);
    })
    .addCase(getApplicationHistory.rejected, (state) => {
      state.isGetRecordsPending = false;
    })
    .addCase(changeFilterType, (state, action) => {
      state.filterType = action.payload;
    })
    .addCase(changeSelectedMembers, (state, action) => {
      state.selectedMembers = action.payload;
    })
    .addCase(changeSortingType, (state, action) => {
      state.sortingType = action.payload;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.search = action.payload;
    });
});

export default withStateReset(applicationHistoryReducer, ApplicationHistoryActionType.ResetState, getInitialState);
