import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import PermissionGroupsApi, {
  PermissionGroup,
  CreatePermissionGroupParams,
  UpdatePermissionGroupParams,
  BulkUpdatePermissionGroupParams,
} from 'api/Core/PermissionGroupsApi';
import responseHandlers from 'api/ResponseHandlers';
import ActionType from './ActionTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';

const permissionGroupsApi = new PermissionGroupsApi(responseHandlers);

export const getPermissionGroups = createAsyncThunk<PermissionGroup[]>(ActionType.GetPermissionGroups, () => {
  return permissionGroupsApi.getAll();
});

export const createPermissionGroup = createAsyncThunk<PermissionGroup, CreatePermissionGroupParams>(
  ActionType.CreatePermissionGroup,
  (params) => {
    return permissionGroupsApi.create(params);
  },
);

export const updatePermissionGroup = createAsyncThunk<
  PermissionGroup,
  { id: PermissionGroupId; permissionGroup: UpdatePermissionGroupParams }
>(ActionType.UpdatePermissionGroup, ({ id, permissionGroup }) => {
  return permissionGroupsApi.update(id, permissionGroup);
});

export const bulkUpdatePermissionGroups = createAsyncThunk<PermissionGroup[], BulkUpdatePermissionGroupParams[]>(
  ActionType.BulkUpdatePermissionGroups,
  (permissionGroups) => {
    return permissionGroupsApi.bulkUpdate(permissionGroups);
  },
);

export const deletePermissionGroup = createAsyncThunk<void, PermissionGroupId>(
  ActionType.DeletePermissionGroup,
  (id) => {
    return permissionGroupsApi.delete(id);
  },
);

export const openEditPermissionGroups = createAction(ActionType.OpenEditPermissionGroup);

export const closeEditPermissionGroups = createAction(ActionType.CloseEditPermissionGroup);
