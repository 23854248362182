import { createAction, createReducer } from '@reduxjs/toolkit';
import { WebhookEvent } from 'api/Webhooks/WebhookEventsApi';
import {
  getWebhookEvents,
} from './Thunks';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import { WebhookEventsActionType } from './WebhookEventsActionType';

export interface WebhookEventsState {
  webhookEventsById: Record<string, WebhookEvent>;
}

const initialState: WebhookEventsState = {
  webhookEventsById: {},
};

export const saveWebhookEvents = createAction<WebhookEvent[]>(WebhookEventsActionType.SaveWebhookEvents);

const webhookEventsReducer = createReducer<WebhookEventsState>(initialState, (builder) => {
  builder.addCase(getWebhookEvents.fulfilled, (state, action) => {
    state.webhookEventsById = {
      ...state.webhookEventsById,
      ...normalizeEntityArray(action.payload.items),
    };
  });

  builder.addCase(saveWebhookEvents, (state, action) => {
    state.webhookEventsById = {
      ...state.webhookEventsById,
      ...normalizeEntityArray(action.payload),
    };
  })
});

export default webhookEventsReducer;
