export const GET_INTERMEDIARIES = 'GET_INTERMEDIARIES';
export const GET_INTERMEDIARIES_SUCCESS = 'GET_INTERMEDIARIES_SUCCESS';
export const GET_INTERMEDIARIES_FAILED = 'GET_INTERMEDIARIES_FAILED';
export const SORT_INTERMEDIARIES = 'SORT_INTERMEDIARIES';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE';

export enum LoanOriginationSystemIntermediariesActionType {
  DeleteIntermediary = 'loanOriginationSystemIntermediaries/deleteIntermediary',
  DeleteIntermediarySuccess = 'loanOriginationSystemIntermediaries/deleteIntermediarySuccess',
  DeleteIntermediaryFailure = 'loanOriginationSystemIntermediaries/deleteIntermediaryFailure',
  ResetState = 'loanOriginationSystemIntermediaries/resetState',
  SetIntermediaryToDelete = 'loanOriginationSystemIntermediaries/setIntermediaryToDelete',
}
