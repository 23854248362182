import {
  DataFilter,
  DataViewSortingType,
  FormLayoutData,
  TableViewData,
  VariableValue,
  SearchHighlight,
} from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';

export interface IntermediarySuggestionFilter {
  name: string;
  email: string;
  phoneNumber: string;
  idNumber: string;
}

export interface GetIntermediariesDataResult {
  items: Intermediary[];
  total: number;
}

export interface ColumnProductDataFilter {
  search: string;
  members: UserInfo[];
  statuses: string[];
  labels: Label[];
  intermediaries: UserInfo[];
  createdDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

export interface Intermediary {
  id: string;
  variables: Record<string, VariableValue>;
  createdAt: Date;
  updatedAt: Date;
  createdBy: UserInfo;
  updatedBy: UserInfo;
  highlights?: SearchHighlight[];
}

export interface IntermediaryNameOption {
  id: string;
  name: string;
}

export enum IntermediariesSortingField {
  IntermediaryName = 'name',
  IntermediaryType = 'type',
  Phone = 'phoneNumber',
  Email = 'email',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  IdNumber = 'idNumber',
  SearchRelevance = 'searchRelevance',
}

export enum IntermediaryApiRequestTemplateType {
  CreateIntermediary = 'createIntermediary',
  UpdateIntermediary = 'updateIntermediary',
}

interface IntermediaryDataFilter extends DataFilter {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  borrowerTypes: string[];
  members: UserInfo[];
}

export interface IntermediariesApplicationsDataFilter extends DataFilter {
  onlyInProgress?: boolean;
}

export type IntermediariesSortingType = DataViewSortingType<IntermediariesSortingField>;

export interface LoanOriginationSystemIntermediariesApi {
  getIntermediaries(
    filters?: IntermediaryDataFilter,
    sortingType?: IntermediariesSortingType,
    abortSignal?: AbortSignal,
  ): Promise<TableViewData<Intermediary>>;

  getIntermediaryNames(filters?: DataFilter): Promise<TableViewData<IntermediaryNameOption>>;

  findIntermediaries(search: string, count: number): Promise<Intermediary[]>;

  getIntermediary(id: string): Promise<Intermediary>;

  deleteIntermediary(id: string): Promise<Intermediary>;

  updateIntermediary(id: string, data: FormLayoutData): Promise<Intermediary>;

  createIntermediary(data: FormLayoutData): Promise<Intermediary>;

  updateIntermediaryApplications(
    id: string,
    variables: FormLayoutData
  ): Promise<void>;

  getIntermediarySuggestions(filter: IntermediarySuggestionFilter): Promise<Intermediary[]>;
}

export default class LoanOriginationSystemIntermediariesRestApi extends LoanOriginationSystemApi<Intermediary>
  implements LoanOriginationSystemIntermediariesApi {
  protected resourceName = 'intermediaries';

  public async findIntermediaries(search: string, count: number) {
    const params = new URLSearchParams();

    if (search) {
      params.append('search', search);
    }

    params.append('count', count.toString());

    const { items } = await this.getResources<TableViewData<Intermediary>>(params);

    return items;
  }

  public getIntermediaries(
    filters?: IntermediaryDataFilter,
    sortingType?: IntermediariesSortingType,
    abortSignal?: AbortSignal,
  ) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters?.dueCreatedDateRange.from) {
      params.append('dueCreatedDateFrom', formatDate(filters.dueCreatedDateRange.from, DateTimeFormat.ISOString));
    }

    if (filters?.dueCreatedDateRange.to) {
      params.append('dueCreatedDateTo', formatDate(filters.dueCreatedDateRange.to, DateTimeFormat.ISOString));
    }

    if (filters?.dueUpdatedDateRange.from) {
      params.append('dueUpdatedDateFrom', formatDate(filters.dueUpdatedDateRange.from, DateTimeFormat.ISOString));
    }

    if (filters?.dueUpdatedDateRange.to) {
      params.append('dueUpdatedDateTo', formatDate(filters.dueUpdatedDateRange.to, DateTimeFormat.ISOString));
    }

    filters?.borrowerTypes.forEach((type) => params.append('borrowerTypeIds', type));
    filters?.members.forEach((member) => params.append('teamMemberIds', member.id));

    return this.getResources<TableViewData<Intermediary>>(params, abortSignal);
  }

  public getIntermediaryNames(filters?: DataFilter) {
    const params = this.getPaginationUrlSearchParams(filters, {
      field: IntermediariesSortingField.IntermediaryName,
      ascending: true,
    });

    return this.fetch<TableViewData<IntermediaryNameOption>>(`/${this.resourceName}/names?${params.toString()}`);
  }

  public getIntermediary(id: string) {
    return this.getResourceById(id);
  }

  public deleteIntermediary(id: string) {
    return this.deleteResource<Intermediary>(id);
  }

  public createIntermediary(data: FormLayoutData) {
    return this.createResource({
      intermediary: {
        variables: data,
      },
    });
  }

  public updateIntermediary(id: string, data: FormLayoutData) {
    return this.updateResource(id, { intermediary: { variables: data } });
  }

  public updateIntermediaryApplications(id: string, variables: FormLayoutData): Promise<void> {
    return this.fetch<void>(`/${this.resourceName}/${id}/applications`, 'PUT', {
      variables,
    });
  }

  public getIntermediarySuggestions(filter: IntermediarySuggestionFilter) {
    const params = new URLSearchParams();

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        params.set(key, filter[key]);
      }
    });

    return this.fetch<Intermediary[]>(`/${this.resourceName}/suggestions/?${params}`);
  }
}
