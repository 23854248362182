import React, { CSSProperties, useMemo } from 'react';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NullableItems } from 'pagination';
import { DroppableList } from 'components/DndList';
import DraggableInputStub from 'components/DraggableInputStub';
import Spinner from 'components/Spinner';
import { SkeletonInput } from 'components/Skeleton';
import SearchNotFound from 'components/SearchNotFound';
import VariablesPaneLayout from './VariablesPaneLayout';
import styles from './VariablesPane.module.scss';
import { Variable } from 'Variables/VariablesTypes';

export interface ListVariableAttributes {
  disabled?: boolean;
  tooltip?: React.ReactNode;
}

export interface VariablesPaneProps {
  variables: NullableItems<Variable>;
  search: string;
  onClosePane: () => void;
  onCreateNewVariable: () => void;
  placeholderStyles: CSSProperties | null;
  paneDroppableId: string;
  paneDroppableType: string;
  draggingId: string | null;
  hasMore?: boolean;
  onLoadMore: () => void;
  onSearch: (search: string) => void;
  allowStandardVariables?: boolean;
  disabledVariablesIds?: string[];
  getListVariableAttributes?: (variable: Variable) => ListVariableAttributes;
}

const INFINITE_SCROLL_VARIABLES_CONTAINER_ID = 'infiniteScrollVariablesContainer';

const VariablesPane = ({
  placeholderStyles,
  onClosePane,
  paneDroppableId,
  paneDroppableType,
  variables,
  draggingId,
  onCreateNewVariable,
  onLoadMore,
  search,
  hasMore,
  onSearch,
  allowStandardVariables,
  disabledVariablesIds = [],
  getListVariableAttributes,
}: VariablesPaneProps) => {
  const getDraggableId = (itemId: string | number, droppableId: string) => `item-${droppableId}-${itemId}`;

  const disabledVariablesIdsMap = useMemo(() => {
    return disabledVariablesIds.reduce((previousDisabledVariablesIdsMap, variableId) => ({
      ...previousDisabledVariablesIdsMap,
      [variableId]: true,
    }), {});
  }, [disabledVariablesIds]);

  const isDragDisabled = (variable: Variable) => {
    const { disabled } = getListVariableAttributes?.(variable) || {};

    return disabled || disabledVariablesIdsMap[variable.id];
  };

  const renderVariableStub = (variable: Variable) => {
    const defaultDisabled = disabledVariablesIdsMap[variable.id];
    const defaultTooltip = disabledVariablesIdsMap[variable.id] ? 'This variable is already being used' : '';

    const { tooltip, disabled } = getListVariableAttributes?.(variable) || {};

    return (
      <DraggableInputStub
        tooltip={tooltip || defaultTooltip}
        key={variable.id}
        disabled={disabled || defaultDisabled}
        label={variable.displayName}
        isDragging={draggingId === getDraggableId(variable.id, paneDroppableId)}
        {...getVisualDataTypeWithAttributes(variable)}
      />
    );
  };

  const renderSkeletonItem = (index: number) => <SkeletonInput key={index} />;

  const renderLoader = () => (
    <div className={styles.loaderContainer}>
      <Spinner size={24} />
    </div>
  );

  const renderScrollContent = () => {
    if (!variables.length && search) {
      return <SearchNotFound className={styles.searchNotFound} searchValue={search} />;
    }

    return (
      <DroppableList
        droppableId={paneDroppableId}
        items={variables}
        droppableType={paneDroppableType}
        renderListItem={renderVariableStub}
        getDraggableId={getDraggableId}
        listClassName={styles.droppableList}
        listItemClassName={styles.draggableInputStub}
        withPlaceholder
        placeholderStyles={placeholderStyles}
        isDragDisabled={isDragDisabled}
        renderSkeletonItem={renderSkeletonItem}
        isDropDisabled
      />
    );
  };

  return (
    <VariablesPaneLayout
      onCreateNewVariable={onCreateNewVariable}
      onClose={onClosePane}
      onSearch={onSearch}
      search={search}
      allowStandardVariables={allowStandardVariables}
    >
      <div id={INFINITE_SCROLL_VARIABLES_CONTAINER_ID} className={styles.infiniteScrollContainer}>
        <InfiniteScroll
          dataLength={variables.length}
          next={onLoadMore}
          hasMore={hasMore || false}
          loader={renderLoader()}
          scrollableTarget={INFINITE_SCROLL_VARIABLES_CONTAINER_ID}
        >
          {renderScrollContent()}
        </InfiniteScroll>
      </div>
    </VariablesPaneLayout>
  );
};

export default VariablesPane;
