import { Product, ProductSection } from 'ProductSectionAccessPermissions/Types';

const PRODUCT_TITLES = {
  [Product.LoanOriginationSystem]: 'Loan Origination System',
  [Product.DecisionEngine]: 'Decision Engine',
  [Product.CompanySettings]: 'Company Settings',
  [Product.DigitalLendingPortal]: 'Digital Lending Portals',
};

const SECTION_TITLE: Record<ProductSection, string> = {
  [ProductSection.CompanySettingsGeneral]: 'General',
  [ProductSection.CompanySettingsPlanAndBilling]: 'Plan & Billing',
  [ProductSection.CompanySettingsTeam]: 'Team',
  [ProductSection.CompanySettingsVariables]: 'Variables',
  [ProductSection.CompanySettingsBranding]: 'Branding',
  [ProductSection.CompanySettingsDataExport]: 'Data Export',
  [ProductSection.CompanySettingsDevelopers]: 'Developers',

  [ProductSection.LoanOriginationSystemApplications]: 'Applications',
  [ProductSection.LoanOriginationSystemBorrowers]: 'Borrowers',
  [ProductSection.LoanOriginationSystemIntermediaries]: 'Intermediaries',
  [ProductSection.LoanOriginationSystemTasks]: 'Tasks',
  [ProductSection.LoanOriginationSystemEmails]: 'Emails',
  [ProductSection.LoanOriginationSystemReporting]: 'Reporting',
  [ProductSection.LoanOriginationSystemConfiguration]: 'Configuration',

  [ProductSection.DecisionEngineStrategies]: 'Strategies',
  [ProductSection.DecisionEngineProcessing]: 'Decisions',
  [ProductSection.DecisionEngineDeployment]: 'Deployment',
  [ProductSection.DecisionEngineReporting]: 'Reporting',

  [ProductSection.CustomDigitalLendingPortal]: 'Custom Portals',
  [ProductSection.StandardDigitalLendingPortal]: 'Standard Portals',
};

const DEFAULT_ROWS_AMOUNT = 3;

export { PRODUCT_TITLES, SECTION_TITLE, DEFAULT_ROWS_AMOUNT };
