import React, { FC, useEffect } from 'react';
import styles from './VersionsView.module.scss';
import Header from './Header';
import { VersionsViewConnectedProps } from 'components/VersionsView/VersionsViewConnector';
import { CloseButtonImage } from 'static/images';
import pagination from './Pagination';
import Pagination from 'components/Pagination';
import VersionsTableView from 'components/VersionsView/VersionsTableView';
import { batch } from 'react-redux';
import NoStrategyFilterResults from 'components/NoStrategyFilterResults';
import SearchNotFound from 'components/SearchNotFound';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import areItemsLoading from 'utils/areItemsLoading';

interface VersionsViewProps {
  strategyName: string;
  initialShowArchived: boolean;
  onClose: () => void;
  resetFilters: () => void;
  strategyId?: string;
}

const VersionsView: FC<VersionsViewProps & VersionsViewConnectedProps> = ({
  onClose,
  initialShowArchived,
  strategyName,
  strategyId,
  selectedUpdaters,
  getVersionsListData,
  searchInputValue,
  showArchived,
  sortingType,
  resetFilters,
}) => {
  const paginationProps = pagination.usePagination({
    searchInputValue,
    showArchived,
    strategyName,
    sortingType,
    selectedUpdaters,
  });
  const listItems = pagination.usePaginatedItems({
    searchInputValue,
    showArchived,
    strategyName,
    sortingType,
    selectedUpdaters,
  });
  const handleResetFilters = () => {
    batch(() => {
      resetFilters();
      getVersionsListData({ strategyName, showArchived: initialShowArchived });
    });
  };

  useEffect(() => {
    getVersionsListData({ strategyName, showArchived: initialShowArchived });
  }, [strategyId]);

  const renderItems = () => {
    if (!listItems.length && !searchInputValue) {
      return <NoStrategyFilterResults onClick={handleResetFilters} />;
    }
    if (!listItems.length && searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }
    return (
      <VersionsTableView
        items={listItems}
        origin={StrategyActionOrigin.StrategyGroupVersionsList}
        currentVersionId={strategyId}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{strategyName}</h2>
        <CloseButtonImage className={styles.closeImage} onClick={onClose} />
      </div>

      <Header placeholderText="Search Versions" areListItemsLoading={areItemsLoading(listItems)} />
      <div className={styles.tableContainer}>
        {renderItems()}
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default VersionsView;
