import React, { FC } from 'react';
import styles from 'components/StrategyOverview/StrategyOverview.module.scss';
import { CollapseIcon } from 'static/images';
import { Strategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import DecisionProcess from './DecisionProcess';
import {
  StrategyUpdateType,
  DecisionStrategyUpdatesSortingType,
  DecisionStrategyUpdatesSortingField,
} from 'DecisionStrategyUpdates/Types';
import { NullableItems, PaginationProps } from 'pagination';
import StrategyUpdates from './StrategyUpdates';
import Versions from './Versions';
import { StrategyPageTabType } from 'components/StrategyOverview';
import StrategyHeader from './StrategyHeader';
import { DecisionProcessProps } from 'components/StrategyOverview/DecisionProcess/DecisionProcess';
import { StrategyGroupVersion } from 'api/Types';
import { isDetailedStrategy } from 'pages/Strategy/utils';

export interface StrategyProps {
  strategy: Strategy | StrategyGroupVersion;
  handleStrategyNameUpdate: (id: string, name: string) => void;
  handleStrategyDescriptionUpdate: (id: string, description: string) => void;
  decisionStrategyUpdates: NullableItems<StrategyUpdateType>;
  decisionStrategyUpdatesPaginationProps: PaginationProps;
  decisionStrategyUpdatesSortingType: DecisionStrategyUpdatesSortingType;
  onDecisionStrategyUpdatesSort: (field: DecisionStrategyUpdatesSortingField, ascending: boolean) => void;
  openDeleteModulePopUp: (moduleLookupName: string, moduleIndex: number) => void;
  onOpenCreateNewStrategyVersionPopUp: () => void;
  onTabChange: (tab: StrategyPageTabType) => void;
  strategyPageTab: StrategyPageTabType;
  currentModuleKey: string;
  currentBranchIndex: number;
  collapseAllModules: (modulesCount: number) => void;
  changeModuleCollapseState: (moduleIndex: number, isCollapsed: boolean) => void;
  collapsedModuleList: boolean[];
  isLoading: boolean;
  blockingActionInProgress: boolean;
  onEditModuleBranch: DecisionProcessProps['onEditModuleBranch'];
}

const StrategyOverview: FC<StrategyProps> = (props) => {
  const {
    strategy,
    handleStrategyNameUpdate,
    handleStrategyDescriptionUpdate,
    decisionStrategyUpdates,
    decisionStrategyUpdatesPaginationProps,
    onDecisionStrategyUpdatesSort,
    decisionStrategyUpdatesSortingType,
    openDeleteModulePopUp,
    onOpenCreateNewStrategyVersionPopUp,
    onTabChange,
    strategyPageTab,
    currentModuleKey,
    currentBranchIndex,
    collapseAllModules,
    changeModuleCollapseState,
    collapsedModuleList,
    isLoading,
    blockingActionInProgress,
    onEditModuleBranch,
  } = props;
  const { id, isLocked } = strategy;

  const getStrategyContent = () => {
    switch (strategyPageTab) {
      case 'overview': {
        return (
          <DecisionProcess
            strategyId={id}
            isStrategyLocked={isLocked}
            openDeleteModulePopUp={openDeleteModulePopUp}
            currentModuleKey={currentModuleKey}
            currentBranchIndex={currentBranchIndex}
            collapsedModuleList={collapsedModuleList}
            changeModuleCollapseState={changeModuleCollapseState}
            isLoading={isLoading}
            blockingActionInProgress={blockingActionInProgress}
            moduleList={isDetailedStrategy(strategy) ? strategy.moduleList : []}
            onEditModuleBranch={onEditModuleBranch}
          />
        );
      }
      case 'versions': {
        return <Versions strategyName={strategy.name} strategyId={strategy.id} />;
      }
      case 'updates': {
        return (
          <StrategyUpdates
            strategyId={id}
            decisionStrategyUpdates={decisionStrategyUpdates}
            decisionStrategyUpdatesPaginationProps={decisionStrategyUpdatesPaginationProps}
            onDecisionStrategyUpdatesSort={onDecisionStrategyUpdatesSort}
            decisionStrategyUpdatesSortingType={decisionStrategyUpdatesSortingType}
          />
        );
      }
      default: {
        throw new Error(`Incorrect page tab: ${strategyPageTab}`);
      }
    }
  };

  const isCollapsedButtonDisabled =
    isLoading || !isDetailedStrategy(strategy) || strategy.moduleList.length === 0 || blockingActionInProgress;

  return (
    <div className={styles.strategyPage}>
      <div className={styles.strategyGeneralInfo}>
        <StrategyHeader
          strategy={strategy}
          handleStrategyNameUpdate={handleStrategyNameUpdate}
          onOpenCreateNewStrategyVersionPopUp={onOpenCreateNewStrategyVersionPopUp}
          handleStrategyDescriptionUpdate={handleStrategyDescriptionUpdate}
          blockingActionInProgress={blockingActionInProgress}
        />
        <div className={styles.strategySwitch}>
          <input
            type="radio"
            id="decision-process"
            name="switch"
            checked={strategyPageTab === 'overview'}
            onChange={() => onTabChange('overview')}
          />
          <label htmlFor="decision-process">Decision Process</label>
          <input
            type="radio"
            id="versions"
            name="switch"
            checked={strategyPageTab === 'versions'}
            onChange={() => onTabChange('versions')}
          />
          <label htmlFor="versions">Versions</label>
          <input
            type="radio"
            id="updates"
            name="switch"
            checked={strategyPageTab === 'updates'}
            onChange={() => onTabChange('updates')}
          />
          <label htmlFor="updates">Update History</label>
          {strategyPageTab === 'overview' && (
            <button
              type="button"
              className={styles.collapsedButton}
              onClick={() => isDetailedStrategy(strategy) && collapseAllModules(strategy.moduleList.length)}
              disabled={isCollapsedButtonDisabled}
            >
              <CollapseIcon />
              Collapse All Branches
            </button>
          )}
        </div>
      </div>
      <div className={styles.strategyContent}>{getStrategyContent()}</div>
    </div>
  );
};

export default StrategyOverview;
