import React, { useState, FC, useMemo } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { CreateWebhookEndpointParams } from 'api/Webhooks/WebhookEndpointsApi';
import EventTypeMultiselect from 'components/EventTypeMultiselect';
import CustomCheckbox from 'components/CustomCheckbox';
import useInputValidation from 'hooks/useInputValidation';
import { validateUrl } from 'utils/validation/validation';
import styles from './CreateWebhookEndpointPopup.module.scss';

interface CreateWebhookEndpointPopupProps {
  onSubmit: (params: CreateWebhookEndpointParams) => void;
  onClose: () => void;
  isCreating?: boolean;
  usePortal?: boolean;
}

const URL_VALIDATORS = [validateUrl];

const CreateWebhookEndpointPopup: FC<CreateWebhookEndpointPopupProps> = ({
  onSubmit,
  onClose,
  isCreating,
  usePortal,
}) => {
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [events, setEvents] = useState<string[]>([]);
  const [eventsInputWasFocused, setEventsInputWasFocused] = useState(false);
  const [eventsInputBlurred, setEventsInputBlurred] = useState(false);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.currentTarget.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value);
  };

  const handleSelectEvent = (eventType: string) => {
    setEvents([...events, eventType]);
  };

  const handleDeselectEvent = (eventType: string) => {
    setEvents(events.filter((event) => event !== eventType));
  };

  const handleAddEndpointButtonClick = () => {
    onSubmit({
      url,
      description,
      events,
      active,
    });
  };

  const eventsMultiselectInputError = useMemo(() => {
    return events.length ? '' : 'This field is required';
  }, [events]);

  const [
    handleUrlBlur,
    handleUrlFocus,
    urlErrorMessage,
    isUrlFieldValid,
  ] = useInputValidation({
    validators: URL_VALIDATORS,
    value: url,
    onChange: (value) => setUrl(value),
    disableResetValueOnError: true,
    required: true,
  });

  const isEndpointButtonDisabled = !isUrlFieldValid || !!eventsMultiselectInputError;

  return (
    <PopUp usePortal={usePortal} title="Add Endpoint" closePopUp={onClose}>
      <PopUpContent>
        <TextInput
          labelTitle="URL"
          required
          placeholder="https://yourdomain.com"
          onChange={handleUrlChange}
          value={url}
          onFocus={handleUrlFocus}
          onBlur={handleUrlBlur}
          errorMessage={urlErrorMessage}
        />
        <TextInput
          labelTitle="Description"
          onChange={handleDescriptionChange}
          value={description}
        />
        <EventTypeMultiselect
          placeholder="Listening For"
          id="listeningFor"
          labelTitle="Listening For"
          selectedEventTypes={events}
          onSelectEventType={handleSelectEvent}
          onDeselectEventType={handleDeselectEvent}
          onFocus={() => setEventsInputWasFocused(true)}
          onBlur={() => setEventsInputBlurred(true)}
          errorMessage={eventsInputWasFocused && eventsInputBlurred ? eventsMultiselectInputError : ''}
        />
        <div className={styles.statusCheckboxContainer}>
          <label className={styles.label}>Status</label>
          <CustomCheckbox
            checked={active}
            onChange={(event, checked) => setActive(checked)}
            containerClassName={styles.checkboxContainer}
            label="Set endpoint as Active"
          />
        </div>
        <Button
          kind="primary"
          size="form"
          disabled={isEndpointButtonDisabled}
          className={styles.addEndpointButton}
          onClick={handleAddEndpointButtonClick}
          isLoading={isCreating}
        >
          Add Endpoint
        </Button>
        <Button
          kind="secondary"
          size="form"
          onClick={onClose}
        >
          No, Go Back
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateWebhookEndpointPopup;
