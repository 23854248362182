import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SetSortingTypeAction } from './ActionCreator';
import { DecisionStrategyUpdatesActions } from './ActionTypes';
import { DecisionStrategyUpdatesSortingType } from './Types';

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.decisionStrategyUpdates;

  return sortingType;
};

const saveTriggerActions = [isActionOfType<SetSortingTypeAction>(DecisionStrategyUpdatesActions.SetSortingType)];

const decisionStrategyUpdatesSortingSaver = FilterAndSortingSaver<DecisionStrategyUpdatesSortingType>({
  key: 'decisionStrategyUpdates',
  getSortingToSave,
  saveTriggerActions,
  resetActionType: DecisionStrategyUpdatesActions.ResetState,
});

export default decisionStrategyUpdatesSortingSaver;
