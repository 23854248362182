import React, { FC } from 'react';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';
import { AccessPermissionsTableColumnSizes } from 'components/AccessPermissionsTables/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import {
  AccessPermissionsUpdatingState,
  ProductSectionAccessPermissions,
  ProductSection,
} from 'ProductSectionAccessPermissions/Types';
import CustomCheckbox from 'components/CustomCheckbox';
import LoaderWithState from 'components/LoaderWithState';
import styles from './AccessPermissionsTableRow.module.scss';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { OwnerPermissionGroupName } from 'PermissionGroups/constants';

const canChangePermissions = (permissionGroupName: string, section: ProductSection) => {
  if (permissionGroupName === OwnerPermissionGroupName) {
    return false;
  }

  switch (section) {
    case ProductSection.CompanySettingsTeam:
    case ProductSection.CompanySettingsPlanAndBilling:
      return false;
    default:
      return true;
  }
};

export interface AccessPermissionsTableRowProps {
  permissionGroup: PermissionGroup;
  accessPermissions: Record<PermissionGroupId, ProductSectionAccessPermissions>;
  accessPermissionsUpdatingState: AccessPermissionsUpdatingState;
  sections: ProductSection[];
  updateAccessPermissions: (
    permissions: ProductSectionAccessPermissions,
    section: ProductSection,
    value: boolean,
  ) => void;
  resetAccessPermissionsUpdatingState: (permissionGroupId: PermissionGroupId, section: ProductSection) => void;
}

const AccessPermissionsTableRow: FC<AccessPermissionsTableRowProps> = ({
  permissionGroup,
  accessPermissions,
  accessPermissionsUpdatingState,
  sections,
  updateAccessPermissions,
  resetAccessPermissionsUpdatingState,
}) => {
  if (!accessPermissions[permissionGroup.id]) {
    return null;
  }

  const sectionWidth = (100 - AccessPermissionsTableColumnSizes.GroupName) / sections.length;

  return (
    <TableRow disableHover>
      <TableBodyCell width={AccessPermissionsTableColumnSizes.GroupName} className={styles.productTitle}>
        {permissionGroup.name}
      </TableBodyCell>

      {sections.map((section) => (
        <TableBodyCell width={sectionWidth} key={section} noPadding>
          {!accessPermissionsUpdatingState[permissionGroup.id]?.[section] ? (
            <CustomCheckbox
              checked={accessPermissions[permissionGroup.id].permissions[section]}
              onClick={() =>
                updateAccessPermissions(
                  accessPermissions[permissionGroup.id],
                  section,
                  !accessPermissions[permissionGroup.id].permissions[section],
                )
              }
              disabled={!canChangePermissions(permissionGroup.name, section)}
              className={styles.checkbox}
            />
          ) : (
            <LoaderWithState
              state={accessPermissionsUpdatingState[permissionGroup.id][section]}
              onStateReset={() => resetAccessPermissionsUpdatingState(permissionGroup.id, section)}
              useOnlyUpdatingState
            />
          )}
        </TableBodyCell>
      ))}
    </TableRow>
  );
};

export default AccessPermissionsTableRow;
