import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import { DropdownStrategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import StrategyApi, { IFindParams } from 'api/DecisionEngine/StrategyApi';
import DecisionRunApiRest, {
  IndividualDecisionRunParams,
  MultipleDecisionRunParams
} from 'api/DecisionProcessing/DecisionRunApi';
import responseHandlers from 'api/ResponseHandlers';
import { Variable } from 'Variables/VariablesTypes';
import { VariableValueMap } from 'api/Types';
import ApplicationDecisionRunApiRest, {
  ApplicationDecisionRunParams
} from 'api/LoanOriginationSystem/ApplicationDecisionRunApi';

export type RunDecisionPopUpType = 'select' | 'single' | 'multiple' | 'single-application-run' | null;

export interface RunDecisionState {
  decisionPopUpType: RunDecisionPopUpType;
  dropdownStrategies: {
    items: DropdownStrategy[];
    isLoading: boolean;
  };
  inputs: {
    items: Variable[] | null;
    isLoading: boolean;
  };
  applicationId: string | null;
  inputValues: VariableValueMap;
  currentStrategyId: string | null;
  isLoading: boolean;
}

const strategiesApi = new StrategyApi(responseHandlers);
const decisionRunApi = new DecisionRunApiRest(responseHandlers);
const applicationDecisionRunApi = new ApplicationDecisionRunApiRest(responseHandlers);

const initialState: RunDecisionState = {
  decisionPopUpType: null,
  dropdownStrategies: {
    items: [],
    isLoading: false,
  },
  inputs: {
    items: [],
    isLoading: false,
  },
  applicationId: null,
  inputValues: {},
  currentStrategyId: null,
  isLoading: false,
};

export const RESET_RUN_DECISION_STATE_ACTION_PREFIX = 'resetRunDecisionState';

export const requestDropdownStrategies = createAsyncThunk<DropdownStrategy[], IFindParams | undefined>(
  'requestDropdownStrategies', async (params?: IFindParams) => {
  const strategies = await strategiesApi.getDropdownStrategies(params);
  return strategies;
});

export const changeRunDecisionStrategy = createAsyncThunk(
  'changeRunDecisionStrategy',
  async ({ strategyId }: { strategyId: string }) => {
    if (strategyId) {
      const inputs = await decisionRunApi.getStrategyInputs(strategyId);
      return inputs;
    }
    return [];
  },
);

export const openReprocessDecisionPopup = createAction<{
  inputs: VariableValueMap,
  strategyId: string,
}>('openReprocessDecisionPopup');
export const closeReprocessDecisionPopup = createAction('closeReprocessDecisionPopup');

interface DecisionRunResult {
  decisionId: string;
  resultsCount: number;
}

export const runIndividualDecision = createAsyncThunk<DecisionRunResult, IndividualDecisionRunParams>(
  'runIndividualDecision',
  async (params: IndividualDecisionRunParams) => {
    const { decisionId, resultsCount } = await decisionRunApi.runIndividual(params);
    return { decisionId, resultsCount };
  },
);
export const runMultipleDecisions = createAsyncThunk<DecisionRunResult, MultipleDecisionRunParams>(
  'runMultipleDecisions',
  async (params: MultipleDecisionRunParams) => {
    const { decisionId, resultsCount } = await decisionRunApi.runMultiple(params);
    return { decisionId, resultsCount };
  },
);
export const runApplicationDecision = createAsyncThunk<DecisionRunResult, ApplicationDecisionRunParams>(
  'makeApplicationDecision',
  async (params) => {
    const result = await applicationDecisionRunApi.runDecision(params);
    return result;
  }
);

export const changeRunDecisionsPopUpType = createAction<{ popUpType: RunDecisionPopUpType }>(
  'changeRunDecisionsPopUpType',
);

export const resetRunDecisionState = createAction(RESET_RUN_DECISION_STATE_ACTION_PREFIX);

const runDecisionReducer = createReducer<RunDecisionState>(initialState, (builder) => {
  builder.addCase(requestDropdownStrategies.pending, (state) => {
    state.dropdownStrategies.isLoading = true;
  });
  builder.addCase(requestDropdownStrategies.fulfilled, (state, action) => {
    state.dropdownStrategies.items = action.payload;
    state.dropdownStrategies.isLoading = false;
  });
  builder.addCase(changeRunDecisionStrategy.pending, (state, action) => {
    state.currentStrategyId = action.meta.arg.strategyId;
    state.inputs.isLoading = true;
  });
  builder.addCase(changeRunDecisionStrategy.fulfilled, (state, action) => {
    state.inputs.items = action.payload;
    state.inputs.isLoading = false;
  });
  builder.addCase(changeRunDecisionsPopUpType, (state, action) => {
    state.decisionPopUpType = action.payload.popUpType;
  });
  builder.addCase(openReprocessDecisionPopup, (state, action) => {
    state.decisionPopUpType = 'single';
    state.inputValues = action.payload.inputs;
    state.currentStrategyId = action.payload.strategyId;
  });
  builder.addCase(closeReprocessDecisionPopup, (state) => {
    state.decisionPopUpType = null;
  });
  builder.addCase(runIndividualDecision.pending, (state, action) => {
    state.isLoading = true;
    state.inputValues = action.meta.arg.inputs;
  });
  builder.addCase(runIndividualDecision.fulfilled, (state) => {
    state = { ...initialState };
    return state;
  });
  builder.addCase(runIndividualDecision.rejected, (state) => {
    state.isLoading = false;
  });
  builder.addCase(runApplicationDecision.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(runApplicationDecision.fulfilled, (state) => {
    state = { ...initialState };
    return state;
  });
  builder.addCase(runApplicationDecision.rejected, (state) => {
    state.isLoading = false;
  });
  builder.addCase(runMultipleDecisions.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(runMultipleDecisions.fulfilled, (state) => {
    state = { ...initialState };
    return state;
  });
  builder.addCase(runMultipleDecisions.rejected, (state) => {
    state.isLoading = false;
  });
});

export default withStateReset(runDecisionReducer, RESET_RUN_DECISION_STATE_ACTION_PREFIX, () => initialState);
