import { Dispatch } from 'react';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import {
  UpdateApplicationBorrowerVariablesSuccessAction, UpdateApplicationIntermediaryVariablesSuccessAction,
  UpdateApplicationSuccessAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import {
  ApplicationPageActionType,
  UPDATE_APPLICATION_SUCCESS,
} from 'LoanOriginationSystemApplicationPage/ActionTypes';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';

const CREATE_APPLICATION_SUCCESS_MESSAGE = 'The application has been updated.';

const isUpdateApplicationSuccessAction = (action: AnyAction): action is UpdateApplicationSuccessAction =>
  action.type === UPDATE_APPLICATION_SUCCESS;

const isUpdateApplicationBorrowerVariablesSuccessAction = (action: AnyAction): action is UpdateApplicationBorrowerVariablesSuccessAction =>
  action.type === ApplicationPageActionType.UpdateApplicationBorrowerVariablesSuccess;

const isUpdateApplicationIntermediaryVariablesSuccessAction = (action: AnyAction): action is UpdateApplicationIntermediaryVariablesSuccessAction =>
  action.type === ApplicationPageActionType.UpdateApplicationIntermediaryVariablesSuccess;

const UpdateApplicationSuccessMiddleware = () =>
  (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);

    if (
      isUpdateApplicationSuccessAction(action) ||
      isUpdateApplicationBorrowerVariablesSuccessAction(action) ||
      isUpdateApplicationIntermediaryVariablesSuccessAction(action)
    ) {
      notification.createNotification(CREATE_APPLICATION_SUCCESS_MESSAGE, 'success', dispatch);
    }

    return result;
  }) as Middleware;

export default UpdateApplicationSuccessMiddleware;
