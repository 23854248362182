import React, { FC } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import styles from './ChartLayout.module.scss';
import clsx from 'clsx';
import { SkeletonText } from 'components/Skeleton';

interface ChartLayoutSkeletonProps {
  title: string;
  tooltip: string;
  className?: string;
}

const ChartLayoutSkeleton: FC<ChartLayoutSkeletonProps> = ({ title, tooltip, className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.titleLine}>
        <p className={styles.title}>{title}</p>
        <QuestionIcon
          tooltipClassName={styles.hintTooltip}
          className={styles.hint}
          tooltip={tooltip}
          size={20}
          tooltipPosition="top"
        />
        <div className={styles.secondaryTitle}>
          <SkeletonText lineHeight="20px" width="114px" height="16px" color="primary6" />
        </div>
      </div>
      <div className={styles.chart}>
        <div>
          <div className={styles.row}>
            <SkeletonText lineHeight="16px" width="36px" height="12px" color="primary6" />
          </div>
          <div className={styles.row}>
            <SkeletonText lineHeight="16px" width="36px" height="12px" color="primary6" />
          </div>
          <div className={styles.row}>
            <SkeletonText lineHeight="16px" width="36px" height="12px" color="primary6" />
          </div>
        </div>
        <div className={styles.footer}>
          <SkeletonText lineHeight="16px" width="72px" height="12px" color="primary6" />
          <SkeletonText lineHeight="16px" width="72px" height="12px" color="primary6" />
        </div>
      </div>
    </div>
  );
};

export default ChartLayoutSkeleton;
