import { useVariableBySystemName } from 'Variables/VariablesBySystemNameLoader';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import { getVariableAccessPermissions } from 'Variables/utils';

const useVariableAccessPermissionsBySystemName = (systemName: string) => {
  const variable = useVariableBySystemName(systemName);
  const userPermissionGroupId = useUserPermissionGroupId();

  return getVariableAccessPermissions(variable, userPermissionGroupId);
};

export default useVariableAccessPermissionsBySystemName;
