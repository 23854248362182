import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import { PermissionGroupId } from 'PermissionGroups/Types';

export enum Product {
  LoanOriginationSystem = 'loanOriginationSystem',
  DecisionEngine = 'decisionEngine',
  CompanySettings = 'companySettings',
  DigitalLendingPortal = 'digitalLendingPortal',
}

export enum ProductSection {
  LoanOriginationSystemApplications = 'loanOriginationSystemApplications',
  LoanOriginationSystemBorrowers = 'loanOriginationSystemBorrowers',
  LoanOriginationSystemIntermediaries = 'loanOriginationSystemIntermediaries',
  LoanOriginationSystemTasks = 'loanOriginationSystemTasks',
  LoanOriginationSystemEmails = 'loanOriginationSystemEmails',
  LoanOriginationSystemReporting = 'loanOriginationSystemReporting',
  LoanOriginationSystemConfiguration = 'loanOriginationSystemConfiguration',

  DecisionEngineStrategies = 'decisionEngineStrategies',
  DecisionEngineProcessing = 'decisionEngineProcessing',
  DecisionEngineDeployment = 'decisionEngineDeployment',
  DecisionEngineReporting = 'decisionEngineReporting',

  CompanySettingsGeneral = 'companySettingsGeneral',
  CompanySettingsPlanAndBilling = 'companySettingsPlanAndBilling',
  CompanySettingsTeam = 'companySettingsTeam',
  CompanySettingsVariables = 'companySettingsVariables',
  CompanySettingsBranding = 'companySettingsBranding',
  CompanySettingsDataExport = 'companySettingsDataExport',
  CompanySettingsDevelopers = 'companySettingsDevelopers',

  CustomDigitalLendingPortal = 'customDigitalLendingPortal',
  StandardDigitalLendingPortal = 'standardDigitalLendingPortal',
}

export const ProductSectionAccessPermissionsStructure: Record<Product, ProductSection[]> = {
  [Product.CompanySettings]: [
    ProductSection.CompanySettingsGeneral,
    ProductSection.CompanySettingsPlanAndBilling,
    ProductSection.CompanySettingsTeam,
    ProductSection.CompanySettingsVariables,
    ProductSection.CompanySettingsDevelopers,
  ],
  [Product.LoanOriginationSystem]: [
    ProductSection.LoanOriginationSystemApplications,
    ProductSection.LoanOriginationSystemBorrowers,
    ProductSection.LoanOriginationSystemIntermediaries,
    ProductSection.LoanOriginationSystemTasks,
    ProductSection.LoanOriginationSystemEmails,
    ProductSection.LoanOriginationSystemReporting,
    ProductSection.LoanOriginationSystemConfiguration,
  ],
  [Product.DecisionEngine]: [
    ProductSection.DecisionEngineStrategies,
    ProductSection.DecisionEngineProcessing,
    ProductSection.DecisionEngineDeployment,
    ProductSection.DecisionEngineReporting,
  ],
  [Product.DigitalLendingPortal]: [
    ProductSection.CustomDigitalLendingPortal,
    ProductSection.StandardDigitalLendingPortal,
  ],
};

export type ProductSectionWithoutDLP = Exclude<
  ProductSection,
  ProductSection.CustomDigitalLendingPortal | ProductSection.StandardDigitalLendingPortal
>;

export type ProductSectionAccessPermissionsMap = Record<ProductSection, boolean>;

export interface ProductSectionAccessPermissions {
  id: string;
  permissionGroupId: PermissionGroupId;
  permissionGroupName: string;
  permissions: ProductSectionAccessPermissionsMap;
}

type AccessPermissionUpdatingState = Partial<Record<ProductSection, LoaderState | undefined>>;

export type AccessPermissionsUpdatingState = Record<PermissionGroupId, AccessPermissionUpdatingState>;
