import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

export const getFormDataByVariableConfigurations = (
  application: Application,
  fields: BaseVariableConfiguration[],
  mapSystemName?: (systemName: string) => string,
) => {
  return fields.reduce((data, { variable }) => {
    return {
      ...data,
      [variable.systemName]: application.variables[mapSystemName ? mapSystemName(variable.systemName) : variable.systemName],
    }
  }, {} as Record<string, VariableValue>);
};
