import searchApplicationsMiddleware from 'LoanOriginationSystemApplications/SearchApplicationsMiddleware';
import searchIntermediariesMiddleware from 'LoanOriginationSystemIntermediariesPage/SearchIntermediariesMiddleware';
import filterApplicationsByMembersMiddleware from 'LoanOriginationSystemApplications/FilterApplicationsByMembersMiddleware';
import GetFilterLabelsMiddleware from 'LoanOriginationSystemApplications/Filters/GetFilterLabelsMiddleware';
import GetFiltersIntermediariesMiddleware from 'LoanOriginationSystemApplications/Filters/GetFiltersIntermediariesMiddleware';
import onFiltersChangeMiddleware from 'LoanOriginationSystemApplications/Filters/OnFiltersChangeMiddleware';
import searchBorrowersMiddleware from 'LoanOriginationSystemBorrowers/SearchBorrowersMiddleware';
import searchLabelsMiddleware from 'LoanOriginationSystemLabelsPage/SearchLabelsMiddleware';
import searchTasksMiddleware from 'LoanOriginationSystemTasks/SearchTasksMiddleware';
import searchEmailsMiddleware from 'LoanOriginationSystemEmails/SearchEmailsMiddleware';
import onTasksFiltersChangeMiddleware from 'LoanOriginationSystemTasks/Filters/OnFiltersChangeMiddleware';
import onIntermediariesFiltersChangeMiddleware from 'LoanOriginationSystemIntermediariesPage/Filters/OnFiltersChangeMiddleware';
import onBorrowersFiltersChangeMiddleware from 'LoanOriginationSystemBorrowers/Filters/OnFiltersChangeMiddleware';
import onTemplatesFiltersChangeMiddleware from 'SendGrid/Filters/OnFiltersChangeMiddleware';
import onEmailsFiltersChangeMiddleware from 'LoanOriginationSystemEmails/Filters/OnFiltersChangeMiddleware';
import filterTasksByMembersMiddleware from 'LoanOriginationSystemTasks/Filters/FilterTasksByMembersMiddleware';
import filterIntermediariesByMembersMiddleware from 'LoanOriginationSystemIntermediariesPage/Filters/FilterIntermediariesByMembersMiddleware';
import filterBorrowersByMembersMiddleware from 'LoanOriginationSystemBorrowers/Filters/FilterBorrowersByMembersMiddleware';
import filterProductsByMembersMiddleware from 'LoanOriginationSystemProducts/Filters/FilterProductsByMembersMiddleware';
import filterTemplatesByMembersMiddleware from 'SendGrid/Filters/FilterTemplatesByMembersMiddleware';
import LoanOriginationSystemLabelsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import LoanOriginationSystemIntermediariesRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import clearAllFiltersMiddleware from 'LoanOriginationSystemApplications/Filters/ClearAllFiltersMiddleware';
import applicationsFilterAndSortingSaver from 'LoanOriginationSystemApplications/FilterAndSortingSaver';
import borrowersFilterAndSortingSaver from 'LoanOriginationSystemBorrowers/FilterAndSortingSaver';
import intermediariesFilterAndSortingSaver from 'LoanOriginationSystemIntermediariesPage/FilterAndSortingSaver';
import tasksFilterAndSortingSavers from 'LoanOriginationSystemTasks/FilterAndSortingSaver';
import emailsFilterAndSortingSavers from 'LoanOriginationSystemEmails/FilterAndSortingSaver';
import reportingFilterSaver from 'LoanOriginationSystemReporting/FilterSaver';
import productsFilterSaver from 'LoanOriginationSystemProducts/FilterSaver';
import applicationDocumentsSortingSaver from 'LoanOriginationSystemApplicationPage/Documents/SortingSaver';
import applicationNotesSortingSaver from 'LoanOriginationSystemApplicationPage/Notes/SortingSaver';
import applicationHistoryFilterAndSortingSaver from 'LoanOriginationSystemApplicationPage/History/FilterAndSortingSaver';
import applicationLabelsSortingSaver from 'LoanOriginationSystemLabelsPage/SortingSaver';
import { TemplatesSearchMiddleware } from 'SendGrid/Middlewares';
import responseHandlers from 'api/ResponseHandlers';

const loanOriginationSystemLabelsApi = new LoanOriginationSystemLabelsRestApi(responseHandlers);
const loanOriginationSystemIntermediariesRestApi = new LoanOriginationSystemIntermediariesRestApi(responseHandlers);

const getProductLabelsMiddleware = GetFilterLabelsMiddleware(loanOriginationSystemLabelsApi);
const getProductIntermediariesMiddleware = GetFiltersIntermediariesMiddleware(
  loanOriginationSystemIntermediariesRestApi,
);

export default [
  searchApplicationsMiddleware,
  searchIntermediariesMiddleware,
  filterApplicationsByMembersMiddleware,
  getProductLabelsMiddleware,
  getProductIntermediariesMiddleware,
  onFiltersChangeMiddleware,
  searchBorrowersMiddleware,
  searchLabelsMiddleware,
  searchTasksMiddleware,
  TemplatesSearchMiddleware,
  onTasksFiltersChangeMiddleware,
  onIntermediariesFiltersChangeMiddleware,
  onBorrowersFiltersChangeMiddleware,
  onTemplatesFiltersChangeMiddleware,
  onEmailsFiltersChangeMiddleware,
  filterTasksByMembersMiddleware,
  filterIntermediariesByMembersMiddleware,
  filterBorrowersByMembersMiddleware,
  filterTemplatesByMembersMiddleware,
  searchEmailsMiddleware,
  clearAllFiltersMiddleware,
  filterProductsByMembersMiddleware,
  applicationsFilterAndSortingSaver.getMiddleware(),
  borrowersFilterAndSortingSaver.getMiddleware(),
  intermediariesFilterAndSortingSaver.getMiddleware(),
  tasksFilterAndSortingSavers.getMiddleware(),
  emailsFilterAndSortingSavers.getMiddleware(),
  reportingFilterSaver.getMiddleware(),
  productsFilterSaver.getMiddleware(),
  applicationDocumentsSortingSaver.getMiddleware(),
  applicationNotesSortingSaver.getMiddleware(),
  applicationHistoryFilterAndSortingSaver.getMiddleware(),
  applicationLabelsSortingSaver.getMiddleware(),
];
