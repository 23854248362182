import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { DataType } from 'Variables/VariablesTypes';
import { unhandledCase } from 'utils/unhandledCase';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';

export const convertVariableInput = (valueInput: string, dataType: DataType): VariableValue => {
  switch (dataType) {
    case 'Boolean': {
      return valueInput ? valueInput.toLowerCase().trim() === 'true' : null;
    }
    case 'Number': {
      return valueInput ? Number(valueInput) : null;
    }
    case 'Date': {
      return valueInput || null;
    }
    case 'String': {
      return valueInput || null;
    }
    default: {
      return unhandledCase(dataType);
    }
  }
};

export const convertVariableValueToString = (variableValue: VariableValue | undefined, transformNullAndUndefinedToString?: boolean): string => {
  if (variableValue === null) {
    return transformNullAndUndefinedToString ? 'null' : '';
  }
  switch (typeof variableValue) {
    case 'boolean':
    case 'number': {
      return String(variableValue);
    }
    case 'undefined': {
      return transformNullAndUndefinedToString ? 'undefined' : '';
    }
    case 'string': {
      return variableValue;
    }
    default: {
      return unhandledCase(variableValue);
    }
  }
};

const isVariableValueChanged = (newVariableValue: VariableValue, initialVariableValue: VariableValue) => {
  if (isEmptyVariableValue(newVariableValue) && isEmptyVariableValue(initialVariableValue)) {
    return false;
  }

  return newVariableValue !== initialVariableValue;
};

export const isConfigurableFormDataChanged = (
  initialData: Record<string, VariableValue>,
  newData: Record<string, VariableValue>,
) => {
  return Object.keys(newData).some((key) => isVariableValueChanged(newData[key], initialData[key]));
};

export const getConfigurableFormDataDifference = (
  initialData: Record<string, VariableValue> | null,
  newData: Record<string, VariableValue> | null,
) => {
  if (!initialData || !newData) {
    return initialData === null
      ? (newData || {})
      : (initialData || {});
  }

  return Object.keys(newData).reduce((previousVariables, key) => ({
    ...previousVariables,
    ...(isVariableValueChanged(newData[key], initialData[key]) ? { [key]: newData[key] } : {}),
  }), {});
}
