import React, { useState, useCallback, useMemo } from 'react';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import { getConfigurableFormDataDifference, isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCalculationsLogic from 'hooks/useCalculationsLogic';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import styles from './EditFormDataPopup.module.scss';

export interface EditFormDataPopupProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
> {
  initialData: FormLayoutData;
  cards: CardType[] | null;
  title: string | React.ReactNode;
  titleIcons?: React.ReactNode;
  onPopupClose: () => void;
  onSubmit: (data: FormLayoutData) => void;
  isUpdatingInProgress?: boolean;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  usePortal?: boolean;
  calculations?: CalculationType[] | null;
}

const EditFormDataPopup = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  title,
  titleIcons,
  cards,
  initialData,
  onPopupClose,
  formatDisplayTitle,
  isUpdatingInProgress,
  onSubmit,
  usePortal,
  calculations,
}: EditFormDataPopupProps<VariableConfigurationType, CardType, CalculationType>) => {
  const [formData, setFormData] = useState(initialData);

  const isDataWasChanged = useMemo(() => {
    return isConfigurableFormDataChanged(initialData, formData);
  }, [formData]);

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => {
    return isDataWasChanged;
  });

  const disableCalculationsLogic = typeof calculations === 'undefined';

  const formDataWithCalculations = useCalculationsLogic(calculations || null, formData, disableCalculationsLogic);

  const fields = useCardFields(cards);

  const displayFieldsAttributes = useConditionalDisplayLogic(fields, formDataWithCalculations);
  const validateFormData = useConfigurableFormValidation(fields);

  const handleFieldChange = useCallback(({ variable }: BaseVariableConfiguration, value: VariableValue) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [variable.systemName]: value,
    }));
  }, []);

  const renderForm = () => {
    return (
      <CardsForm
        className={styles.cardsForm}
        cards={cards}
        data={formDataWithCalculations || {}}
        onFieldChange={handleFieldChange}
        displayFieldsAttributes={displayFieldsAttributes}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        isEditMode
        displayHeader={false}
        calculations={calculations}
        displaySkeleton={!displayFieldsAttributes}
      />
    );
  };

  const handleClose = useCallbackWithUnsavedChanges(() => {
    onPopupClose();
  });

  const handleSubmit = () => {
    const dataDifference = getConfigurableFormDataDifference(initialData, formData);

    onSubmit(dataDifference);
  };

  return (
    <PopUp usePortal={usePortal} closePopUp={handleClose} title={title} titleIcons={titleIcons}>
      <PopUpContent>
        {renderForm()}
        <WithFieldsValidationButton
          size="form"
          kind="primary"
          className={styles.saveChangesButton}
          disabled={!isDataWasChanged || !cards}
          onClick={handleSubmit}
          isLoading={isUpdatingInProgress}
          areFieldsInvalid={!validateFormData(formDataWithCalculations || {}, displayFieldsAttributes)}
        >
          Save Changes
        </WithFieldsValidationButton>
      </PopUpContent>
      {displayUnsavedChanges && (
        <UnsavedChangesPopup onPopupClose={onLeaveUnsavedChanges} onLeaveClick={onConfirmUnsavedChanges} />
      )}
    </PopUp>
  );
};

export default EditFormDataPopup;
