import React from 'react';
import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import { CalculationsTableColumnSize } from './TableColumnSizes';
import styles from './SkeletonCalculationRowItem.module.scss';

const SkeletonCalculationRowItem = () => {
  return (
    <TableRow disableHover useActions>
      <TableBodyCell className={styles.tableBodyCell} width={CalculationsTableColumnSize.VariableName}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell className={styles.tableBodyCell} width={CalculationsTableColumnSize.CalculationCode}>
        <SkeletonRectangle width="100%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonCalculationRowItem;
