import React, { CSSProperties } from 'react';
import {
  BaseVariableConfiguration,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import AddCardButton from 'components/LoanOriginationSystem/CardsLayoutConfiguration/AddCardButton/AddCardButton';
import VariablesConfigurationCard from 'components/LoanOriginationSystem/VariablesConfiguration/VariablesConfigurationCard';
import styles from './CardsRow.module.scss';

export interface DataTabRowProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  > {
  cards: CardType[];
  sourceDroppableId: string | null;
  draggingId: string | null;
  placeholderStyles: CSSProperties | null;
  droppableType: string;
  onCardAdd: (row: number, column: number) => void;
  onCardDelete: (cardId: string) => void;
  onCardNameUpdate: (cardId: string, name: string) => void;
  onDeleteVariableConfiguration: (variableConfiguration: VariableConfigurationType) => void;
  onChangeVariableConfigurationRequiredAttribute?: (variableConfiguration: VariableConfigurationType, required: boolean) => void;
  onDeleteVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onAddVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onEditVariableConfigurationConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  rowIndex: number;
  maxCardsPerRow?: number;
  maxCardsPerColumn?: number;
  maxVariablesPerCard?: number;
  maxColumnsPerCard?: number;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  calculations?: CalculationType[] | null;
}

const DEFAULT_MAX_CARDS_PER_ROW = 4;
const DEFAULT_MAX_CARDS_PER_COLUMN = 10;
const DEFAULT_MAX_VARIABLES_PER_CARD = 50;
const DEFAULT_MAX_COLUMN_PER_CARD = 1;

const CardsRow = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  cards,
  draggingId,
  sourceDroppableId,
  placeholderStyles,
  droppableType,
  onCardAdd,
  onCardDelete,
  onCardNameUpdate,
  onDeleteVariableConfiguration,
  onChangeVariableConfigurationRequiredAttribute,
  onAddVariableConfigurationConditionalDisplayRule,
  onDeleteVariableConfigurationConditionalDisplayRule,
  onEditVariableConfigurationConditionalDisplayRule,
  rowIndex,
  calculations,
  formatVariableConfigurationDisplayTitle,
  maxCardsPerRow = DEFAULT_MAX_CARDS_PER_ROW,
  maxCardsPerColumn = DEFAULT_MAX_CARDS_PER_COLUMN,
  maxVariablesPerCard = DEFAULT_MAX_VARIABLES_PER_CARD,
  maxColumnsPerCard = DEFAULT_MAX_COLUMN_PER_CARD,
}: DataTabRowProps<VariableConfigurationType, CardType, CalculationType>) => {
  const handleAddCardInSameRow = () => {
    onCardAdd(rowIndex, cards.length);
  };

  const handleAddCardInNewRow = () => {
    onCardAdd(rowIndex + 1, 0);
  };

  const renderCard = (card: CardType) => (
    <VariablesConfigurationCard
      key={card.id}
      title={card.name}
      calculations={calculations}
      columns={maxColumnsPerCard}
      className={styles.variablesConfigurationCard}
      placeholderStyle={placeholderStyles}
      configurations={card.fields}
      onDeleteVariableConfiguration={onDeleteVariableConfiguration}
      onUpdateCardName={(name) => onCardNameUpdate(card.id, name)}
      draggingId={draggingId}
      cardId={card.id}
      isDropDisabled={card.id !== sourceDroppableId && card.fields.length >= maxVariablesPerCard}
      droppableType={droppableType}
      formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
      isTitleEditable
      showActions
      onChangeVariableConfigurationRequiredAttribute={onChangeVariableConfigurationRequiredAttribute}
      onAddVariableConfigurationConditionalDisplayRule={onAddVariableConfigurationConditionalDisplayRule}
      onDeleteVariableConfigurationConditionalDisplayRule={onDeleteVariableConfigurationConditionalDisplayRule}
      onEditVariableConfigurationConditionalDisplayRule={onEditVariableConfigurationConditionalDisplayRule}
      cardActions={[
        {
          title: 'Delete Section',
          danger: true,
          handler: () => onCardDelete(card.id),
        },
      ]}
    />
  );

  const renderAddCardInSameRowImage = () => {
    if (cards.length === maxCardsPerRow) {
      return null;
    }

    return <AddCardButton onClick={handleAddCardInSameRow} className={styles.addCardInSameRowButton} />;
  };

  const renderAddCardInNewRowImage = () => {
    if (rowIndex + 1 === maxCardsPerColumn) {
      return null;
    }

    return <AddCardButton onClick={handleAddCardInNewRow} className={styles.addCardInNewRowButton} />;
  };

  return (
    <div className={styles.row}>
      {renderAddCardInSameRowImage()}
      {renderAddCardInNewRowImage()}
      {cards.map(renderCard)}
    </div>
  );
};

export default CardsRow;
