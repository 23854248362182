import { useRef } from 'react';

const useAbortSignal = <Callback extends (...args: any[]) => any>(callback: Callback) => {
  const fetchAbortController = useRef<AbortController | null>(null);

  return (...args: any) => {
    if (fetchAbortController.current) {
      fetchAbortController.current.abort();
    }
    fetchAbortController.current = new AbortController();

    callback(fetchAbortController.current!.signal, ...args);
  }
};

export default useAbortSignal;
