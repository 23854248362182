import React, { FC } from 'react';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import SIZES from 'components/CompanyInformationUsers/TableColumnSizes';
import { OrganizationUsersSortingField, OrganizationUsersSortingType } from 'api/Core/UserApi';
import TableActionCell from 'components/Table/TableActionCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonUserLine from 'components/CompanyInformationUsers/SkeletonUserLine';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import UserLine from 'components/CompanyInformationUsers/UserLine';
import { NullableItems, PaginationProps } from 'pagination';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';

interface OrganizationUsersTableProps {
  organizationUsers: NullableItems<OrganizationMembership>;
  sortingType: OrganizationUsersSortingType;
  openEditUserContextualView: (userId: string) => void;
  updateUser: (userInfo: EditUserParams) => void;
  currentUserId?: string;
  selectedUserId: string | null;
  handleOpenSeatsAreFullPopUp: () => void;
  paginationProps: PaginationProps;
  onSort: (field: OrganizationUsersSortingField, ascending: boolean) => void;
}

const DEFAULT_ROWS_AMOUNT = 10;

const OrganizationUsersTable: FC<OrganizationUsersTableProps> = ({
  organizationUsers,
  sortingType,
  openEditUserContextualView,
  updateUser,
  currentUserId,
  selectedUserId,
  handleOpenSeatsAreFullPopUp,
  paginationProps,
  onSort,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (membership: OrganizationMembership) => (
    <UserLine
      user={membership}
      openEditUserContextualPopUp={openEditUserContextualView}
      key={membership.membershipId}
      updateUser={updateUser}
      isSelected={selectedUserId === membership.membershipId}
      handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
      isCurrentUser={currentUserId === membership.membershipId}
    />
  );

  const tableRows = organizationUsers || new Array(DEFAULT_ROWS_AMOUNT).fill(null);
  return (
    <>
      <Table>
        <TableHead sticky>
          <TableHeadCell
            width={SIZES.FIRST_NAME}
            ascending={getSortingType(OrganizationUsersSortingField.FirstName)}
            onClick={() => changeSorting(OrganizationUsersSortingField.FirstName)}
          >
            First Name
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.LAST_NAME}
            ascending={getSortingType(OrganizationUsersSortingField.LastName)}
            onClick={() => changeSorting(OrganizationUsersSortingField.LastName)}
          >
            Last Name
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.EMAIL}
            ascending={getSortingType(OrganizationUsersSortingField.Email)}
            onClick={() => changeSorting(OrganizationUsersSortingField.Email)}
          >
            Email
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.PERMISSION_GROUP}
            ascending={getSortingType(OrganizationUsersSortingField.PermissionGroup)}
            onClick={() => changeSorting(OrganizationUsersSortingField.PermissionGroup)}
          >
            Permission Group
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.PHONE_AUTHENTICATION}
            ascending={getSortingType(OrganizationUsersSortingField.PhoneAuthentication)}
            onClick={() => changeSorting(OrganizationUsersSortingField.PhoneAuthentication)}
          >
            Phone Authentication
          </TableHeadCell>
          <TableHeadCell
            width={SIZES.ACCOUNT_STATUS}
            ascending={getSortingType(OrganizationUsersSortingField.AccountStatus)}
            onClick={() => changeSorting(OrganizationUsersSortingField.AccountStatus)}
          >
            Account Status
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            rows={tableRows}
            renderSkeletonTableRow={(index) => <SkeletonUserLine key={index} />}
            renderTableRow={renderRow}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default OrganizationUsersTable;
