import { ActionWithOrigin } from './ActionWithOrigin';

export interface FailedAction<TypeType = string, ActionOrigin = undefined, ErrorType extends Error = Error>
  extends ActionWithOrigin<ActionOrigin> {
  type: TypeType;
  payload: ErrorType;
  error: true;
}

const FailedActionCreator = <ErrorType extends Error = Error>(type: string) => (
  error: ErrorType,
): FailedAction<typeof type, undefined, ErrorType> => {
  return {
    type,
    payload: error,
    error: true,
  };
};

export default FailedActionCreator;
