import React from 'react';
import { IntermediariesColumnSizes } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import TableActionCell from 'components/Table/TableActionCell';
import variables from 'components/RowActions/RowActions.module.scss';

const SkeletonIntermediaryLine = ({ showSearchMatch }: { showSearchMatch: boolean }) => {
  return (
    <>
      <TableRow disableHover useActions noBottomBorder={showSearchMatch}>
        <TableBodyCell width={IntermediariesColumnSizes.Name}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Type}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.IdNumber}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Phone}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.Email}>
          <SkeletonRectangle width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.CreatedAt} noPadding>
          <SkeletonCircle width="28px" height="28px" color="primary6" />
          <SkeletonRectangle width="80%" height="16px" color="primary6" marginLeft="8px" />
        </TableBodyCell>
        <TableBodyCell width={IntermediariesColumnSizes.UpdatedAt} noPadding>
          <SkeletonCircle width="28px" height="28px" color="primary6" />
          <SkeletonRectangle width="80%" height="16px" color="primary6" marginLeft="8px" />
        </TableBodyCell>
        <TableActionCell />
      </TableRow>
      {showSearchMatch ? (
        <TableRow disableHover noTopBorder>
          <TableBodyCell width={IntermediariesColumnSizes.Name}> </TableBodyCell>
          <TableBodyCell width={100 - IntermediariesColumnSizes.Name} dashedTopBorder>
            <SkeletonRectangle width="340px" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
            {' '}
          </TableBodyCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default SkeletonIntermediaryLine;
