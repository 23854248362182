import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getIntermediaryProfileCards } from 'IntermediaryProfileCards/Thunks';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';

interface EditIntermediaryFormProps {
  className?: string;
  intermediaryFormData: FormLayoutData;
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onIntermediaryUpdate: () => void;
  isUpdatingInProgress: boolean;
  intermediary: Intermediary | null;
  onSwitchEditMode?: () => void;
  isEditMode?: boolean;
  editModeOnly?: boolean;
}

const EditIntermediary = ({
  className,
  intermediaryFormData,
  onFieldChange,
  onIntermediaryUpdate,
  isUpdatingInProgress,
  intermediary,
  onSwitchEditMode,
  isEditMode,
  editModeOnly,
}: EditIntermediaryFormProps) => {
  const dispatch = useDispatch();
  const cards = useSelector((state: ReduxState) => getIntermediaryProfileCardsSelector(state));

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, intermediaryFormData);
  const validateIntermediaryData = useConfigurableFormValidation(fields);

  useEffect(() => {
    dispatch(getIntermediaryProfileCards());
  }, []);

  const dataWasChanged = useCallback(() => {
    return !!(intermediary?.variables && isConfigurableFormDataChanged(intermediary.variables, intermediaryFormData));
  }, [intermediary, intermediaryFormData]);

  const areFieldsInvalid = !validateIntermediaryData(intermediaryFormData, displayFieldsAttributes);

  const renderForm = () => {
    return (
      <CardsForm
        title="Profile"
        cards={cards}
        data={intermediaryFormData}
        onFieldChange={onFieldChange}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        onSwitchEditMode={onSwitchEditMode}
        isEditMode={isEditMode}
        switchEditModeButton="Edit Profile"
        displayHeader={!editModeOnly}
        isUpdatingInProgress={isUpdatingInProgress}
        isSaveChangesButtonDisabled={!dataWasChanged() || !cards}
        onSaveChangesClick={onIntermediaryUpdate}
        areFieldsInvalid={areFieldsInvalid}
        displayFieldsAttributes={displayFieldsAttributes}
        displaySkeleton={!displayFieldsAttributes}
      />
    );
  };

  return (
    <div className={className}>
      {renderForm()}
    </div>
  );
};

export default EditIntermediary;
