import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { getApplicationDataTabCardsSelector } from 'ApplicationDataTabCards/Selectors';
import { getApplicationDataTabCards } from 'ApplicationDataTabCards/Thunks';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { updateApplicationVariables } from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { getConfigurableFormDataDifference, isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCardFields from 'hooks/useCardFields';
import useCalculationsLogic from 'hooks/useCalculationsLogic';
import useProductCalculations from 'hooks/useProductCalculations';

export interface DataTabProps {
  tab: ApplicationTab;
  application: Application;
}

const COLUMNS_PER_CARD = 1;
const SKELETON_CARDS_LAYOUT = [
  [2, 1],
  [2],
  [1, 1],
];

const DataTab = ({ tab, application }: DataTabProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const cards = useSelector((state: ReduxState) => getApplicationDataTabCardsSelector(state, tab.id));

  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState(application.variables);

  const [isApplicationUpdateInProgress, useUpdateApplicationCallback] = useBlockingRequest();

  const productCalculations = useProductCalculations(tab.productId);

  const dataWithCalculations = useCalculationsLogic(productCalculations, data);

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, dataWithCalculations);
  const validateData = useConfigurableFormValidation(fields);

  const isDataWasChanged = useMemo(() => {
    return isConfigurableFormDataChanged(application.variables, data);
  }, [application.variables, data]);

  useEffect(() => {
    dispatch(getApplicationDataTabCards(tab.id));

    setData(application.variables);

    setIsEditMode(false);
  }, [tab]);

  useEffect(() => {
    setData(application.variables);
  }, [application.variables]);

  const handleSwitchEditMode = () => {
    setData(isEditMode ? application.variables : data);
    setIsEditMode(!isEditMode);
  };

  const handleApplicationDataUpdate = useUpdateApplicationCallback(async () => {
    await dispatchRoutine(updateApplicationVariables(application.id, getConfigurableFormDataDifference(application.variables, data)));

    setIsEditMode(false);
  });

  const handleFieldChange = async ({ variable }: BaseVariableConfiguration, value: VariableValue) => {
    setData({ ...data, [variable.systemName]: value });
  };

  return (
    <div>
      <CardsForm
        title={tab.name}
        cards={cards}
        data={dataWithCalculations || {}}
        onFieldChange={handleFieldChange}
        isEditMode={isEditMode}
        onSwitchEditMode={handleSwitchEditMode}
        columnsPerCards={COLUMNS_PER_CARD}
        skeletonCardsLayout={SKELETON_CARDS_LAYOUT}
        isSaveChangesButtonDisabled={!cards || !isDataWasChanged}
        areFieldsInvalid={!validateData(dataWithCalculations || {}, displayFieldsAttributes)}
        onSaveChangesClick={handleApplicationDataUpdate}
        isUpdatingInProgress={isApplicationUpdateInProgress}
        displayFieldsAttributes={displayFieldsAttributes}
        calculations={productCalculations}
        displaySkeleton={!displayFieldsAttributes}
      />
    </div>
  );
};

export default DataTab;
