import { FailedAction } from 'utils/actions/FailedActionCreator';
import applicationNotesPagination, {
  APPLICATION_NOTES_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/pagination';
import {
  AddApplicationNoteAction,
  AddApplicationNoteSuccessAction,
  CloseApplicationNotePopupAction,
  DeleteApplicationNoteAction,
  DeleteApplicationNoteSuccessAction,
  GetApplicationNotesSuccessAction,
  OpenApplicationNotePopupAction,
  SetCurrentApplicationNoteAction,
  SetSearchValueAction,
  SortApplicationNotesAction,
  GetApplicationNotesAction,
} from './ActionCreator';
import { ApplicationNotePopUpType, ApplicationNotesState } from './Types';
import { ApplicationNotesActionType } from './ActionTypes';
import { ApplicationNotesSortingField } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import { AnyAction } from 'redux';
import withStateReset from 'utils/reducers/withStateReset';
import applicationNotesSortingSaver from './SortingSaver';

type LoanOriginationSystemNotesType = (state: ApplicationNotesState, action: AnyAction) => ApplicationNotesState;

export type ApplicationNotesActions =
  | GetApplicationNotesAction
  | GetApplicationNotesSuccessAction
  | AddApplicationNoteAction
  | AddApplicationNoteSuccessAction
  | OpenApplicationNotePopupAction
  | CloseApplicationNotePopupAction
  | SetSearchValueAction
  | SortApplicationNotesAction
  | SetCurrentApplicationNoteAction
  | DeleteApplicationNoteAction
  | DeleteApplicationNoteSuccessAction
  | FailedAction<ApplicationNotesActionType.DeleteApplicationNoteFailure>
  | FailedAction<ApplicationNotesActionType.AddApplicationNoteFailure>;

export const getInitialState = (): ApplicationNotesState => ({
  search: '',
  items: [],
  page: 1,
  itemsPerPage: APPLICATION_NOTES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: applicationNotesSortingSaver.getSavedSorting() || {
    field: ApplicationNotesSortingField.UpdatedAt,
    ascending: false,
  },
  searched: {},
  currentNote: null,
  isPopupOpen: false,
  popUpType: ApplicationNotePopUpType.Add,
  isLoading: false,
});

const applicationNotesReducer = (
  state: ApplicationNotesState = getInitialState(),
  action: ApplicationNotesActions,
): ApplicationNotesState => {
  switch (action.type) {
    case ApplicationNotesActionType.GetApplicationNotes: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ApplicationNotesActionType.GetApplicationNotesSuccess: {
      if (action.payload.search) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.search]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: APPLICATION_NOTES_PER_PAGE_DEFAULT,
              error: '',
            },
          },
          isLoading: false,
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state.itemsPerPage || APPLICATION_NOTES_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
        isLoading: false,
      };
    }
    case ApplicationNotesActionType.OpenApplicationNotePopup: {
      return {
        ...state,
        isPopupOpen: true,
        popUpType: action.payload,
      };
    }
    case ApplicationNotesActionType.CloseApplicationNotePopup: {
      return {
        ...state,
        isPopupOpen: false,
      };
    }
    case ApplicationNotesActionType.SetSearchValue: {
      return {
        ...state,
        search: action.payload.search,
        isLoading: true,
      };
    }
    case ApplicationNotesActionType.SortApplicationNotes: {
      return {
        ...state,
        sortingType: action.payload.sortingType,
      };
    }
    case ApplicationNotesActionType.SetCurrentApplicationNote: {
      return {
        ...state,
        currentNote: action.payload.noteId,
      };
    }
    case ApplicationNotesActionType.DeleteApplicationNoteFailure: {
      return {
        ...state,
        isPopupOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationNotesPagination.wrapReducer(
  withStateReset(
    applicationNotesReducer,
    ApplicationNotesActionType.ResetState,
    getInitialState,
  ) as LoanOriginationSystemNotesType,
);
