import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationDataTabVariableConfigurationsRestApi, {
  CreateApplicationDataTabVariableConfigurationParams,
} from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import responseHandlers from 'api/ResponseHandlers';
import {
  BaseUpdateVariableConfigurationParams,
  UpdateVariableConfigurationConditionalDisplayRuleParams,
  UpdateVariableConfigurationRequiredAttribute,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

type UpdateApplicationDataTabVariableConfigurationPositionParams = Pick<BaseUpdateVariableConfigurationParams, 'position' | 'cardId' | 'column'> & {
  id: string;
  sourceCardId: string;
}

const applicationDataTabVariableConfigurationsApi = new ApplicationDataTabVariableConfigurationsRestApi(responseHandlers);

export const createApplicationDataTabVariableConfiguration = createAsyncThunk(
  'applicationDataTabVariableConfigurations/createApplicationDataTabVariableConfiguration',
  async (params: CreateApplicationDataTabVariableConfigurationParams) => {
    const configuration = await applicationDataTabVariableConfigurationsApi.create(params);

    return {
      configuration,
    };
  },
);

export const updateApplicationDataTabVariableConfigurationPosition = createAsyncThunk(
  'applicationDataTabVariableConfigurations/updateApplicationDataTabVariableConfigurationPosition',
  async (params: UpdateApplicationDataTabVariableConfigurationPositionParams) => {
    const { id, sourceCardId, ...updateParams } = params;

    const configuration = await applicationDataTabVariableConfigurationsApi.update(id, updateParams);

    return {
      configuration,
      sourceCardId,
    };
  },
);

export const updateVariableConfigurationRequiredAttribute = createAsyncThunk(
  'applicationDataTabVariableConfigurations/updateVariableConfigurationRequiredAttribute',
  async (params: UpdateVariableConfigurationRequiredAttribute) => {
    const { id, required } = params;

    const configuration = await applicationDataTabVariableConfigurationsApi.update(id, {
      required,
    });

    return {
      configuration,
    };
  },
);


export const updateConditionalDisplayRule = createAsyncThunk(
  'applicationDataTabVariableConfigurations/updateConditionalDisplayRule',
  async (params: UpdateVariableConfigurationConditionalDisplayRuleParams) => {
    const configuration = await applicationDataTabVariableConfigurationsApi.update(params.id, {
      conditionalDisplayRule: params.conditionalDisplayRule,
    });

    return {
      configuration,
    };
  },
);

export const deleteApplicationDataTabVariableConfiguration = createAsyncThunk(
  'applicationDataTabVariableConfigurations/deleteDataTabApplicationVariableConfiguration',
  async (id: string) => {
    await applicationDataTabVariableConfigurationsApi.delete(id);

    return {
      id,
    };
  },
);
