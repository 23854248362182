import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  changeApplicationStatusSuccess,
  changeApplicationStatusFailed,
  ChangeApplicationStatusAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { RequestError } from 'utils/fetch';
import ChangeApplicationStatusError from 'errors/ChangeApplicationStatusError';
import ApplicationStatusRulesValidationError from 'errors/ApplicationStatusRulesValidationError';
import prolongRequest from 'utils/prolongRequest';

const CHANGE_APPLICATION_STATUS_MIN_REQUEST_TIME = 1000;

const ChangeApplicationStatusMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, ChangeApplicationStatusAction>(
    LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus,
    changeApplicationStatusSuccess,
    changeApplicationStatusFailed,
    prolongRequest(async (action: ChangeApplicationStatusAction) => {
      const {
        applicationId,
        applicationDisplayId,
        applicationName,
        statusToSet,
        initialStatus,
        declineReasons,
        sourceIndex,
      } = action.payload;

      try {
        const updatedApplication = await api.changeApplicationStatus(applicationId, statusToSet.id, declineReasons);

        return updatedApplication;
      } catch (error) {
        if (error instanceof RequestError && error.data && error.data.statusRulesValidationResult) {
          throw new ApplicationStatusRulesValidationError(
            applicationId,
            applicationName,
            applicationDisplayId,
            initialStatus,
            sourceIndex,
            error.data.statusRulesValidationResult as ApplicationStatusRulesValidationResult,
          );
        }

        throw new ChangeApplicationStatusError(
          error.message,
          applicationId,
          applicationDisplayId,
          applicationName,
          initialStatus,
          sourceIndex,
        );
      }
    }, CHANGE_APPLICATION_STATUS_MIN_REQUEST_TIME),
  );

export default ChangeApplicationStatusMiddleware;
