import React, { FC, useState } from 'react';
import { UpdateWebhookEndpointParams, WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import { WebhookEvent } from 'api/Webhooks/WebhookEventsApi';
import { TabSwitchOption } from 'components/TabSwitch/TabSwitch';
import DetailsHeader from 'components/DetailsHeader';
import { RowAction } from 'components/RowActions/RowActions';
import { SkeletonRectangle } from 'components/Skeleton';
import { Tag } from 'components/Tag';
import WebhookEndpointOverview from './WebhookEndpointOverview';
import WebhookEndpointIntegration from './WebhookEndpointIntegration';
import WebhookEndpointEvents from './WebhookEndpointEvents';
import styles from './WebhookEndpointDetails.module.scss';

enum WebhookEndpointDetailsTab {
  Overview = 'overview',
  Integration = 'integration',
  Events = 'events',
}

export interface WebhookEndpointDetailsProps {
  webhookEndpoint: WebhookEndpoint | null;
  webhookEndpointId: string;
  onDeleteWebhook: (id: string) => void;
  onChangeWebhookStatus: (id: string, active: boolean) => void;
  onGenerateNewWebhookEndpointSecret: (id: string) => void;
  onDeleteWebhookEvent: (webhookEventId: string) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
  onResendWebhookEvent: (webhookEventId: string) => void;
  onUpdateWebhookEndpoint: (webhookEndpointId: string, params: Partial<UpdateWebhookEndpointParams>) => Promise<void>;
  isWebhookEndpointUpdateInProgress?: boolean;
}

const TABS: TabSwitchOption[] = [{
  id: WebhookEndpointDetailsTab.Overview,
  label: 'Overview',
}, {
  id: WebhookEndpointDetailsTab.Integration,
  label: 'Integration',
}, {
  id: WebhookEndpointDetailsTab.Events,
  label: 'Events',
}];

const WebhookEndpointDetails: FC<WebhookEndpointDetailsProps> = ({
  webhookEndpoint,
  webhookEndpointId,
  onDeleteWebhook,
  onChangeWebhookStatus,
  onGenerateNewWebhookEndpointSecret,
  onDeleteWebhookEvent,
  onWebhookEventClick,
  onResendWebhookEvent,
  onUpdateWebhookEndpoint,
  isWebhookEndpointUpdateInProgress,
}) => {
  const [selectedTab, setSelectedTab] = useState<WebhookEndpointDetailsTab>(WebhookEndpointDetailsTab.Overview);

  const renderTab = () => {
    if (selectedTab === WebhookEndpointDetailsTab.Overview) {
      return (
        <WebhookEndpointOverview
          webhookEndpoint={webhookEndpoint}
          onUpdateWebhookEndpoint={onUpdateWebhookEndpoint}
          isUpdateInProgress={isWebhookEndpointUpdateInProgress}
        />
      );
    }

    if (selectedTab === WebhookEndpointDetailsTab.Integration) {
      return (
        <WebhookEndpointIntegration
          webhookEndpoint={webhookEndpoint}
          onGenerateNewSecret={() => onGenerateNewWebhookEndpointSecret(webhookEndpointId)}
        />
      );
    }

    return (
      <WebhookEndpointEvents
        webhookEndpointId={webhookEndpointId}
        onDeleteWebhookEvent={onDeleteWebhookEvent}
        onWebhookEventClick={onWebhookEventClick}
        onResendWebhookEvent={onResendWebhookEvent}
    />
    );
  };

  const handleTabSelect = ({ id }: TabSwitchOption) => setSelectedTab(id as WebhookEndpointDetailsTab);

  const updaterInfo = webhookEndpoint ? { updatedBy: webhookEndpoint.updatedBy, updatedAt: webhookEndpoint.updatedAt } : null;

  const actions: RowAction[] | null = webhookEndpoint ? [{
    title: webhookEndpoint.active ? 'Set As Inactive' : 'Set As Active',
    handler: () => onChangeWebhookStatus(webhookEndpointId, !webhookEndpoint.active),
    separatorRequired: true,
  }, {
    title: 'Delete Webhook',
    handler: () => onDeleteWebhook(webhookEndpointId),
    danger: true,
  }] : null;

  const renderSubtitleTags = () => {
    if (!webhookEndpoint) {
      return (
        <SkeletonRectangle width="59px" height="24px" color="primary6" />
      );
    }

    if (webhookEndpoint.active) {
      return (
        <Tag color="green">Active</Tag>
      );
    }

    return (
      <Tag color="gray">Inactive</Tag>
    );
  };

  return (
    <div>
      <DetailsHeader
        className={styles.detailsHeader}
        title="Endpoint Details"
        actions={actions}
        updaterInfo={updaterInfo}
        tabs={TABS}
        selectedTabId={selectedTab}
        onSelectTab={handleTabSelect}
        subtitleTags={renderSubtitleTags()}
      />
      {renderTab()}
    </div>
  );
};

export default WebhookEndpointDetails;
