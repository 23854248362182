import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';
import { getBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { UpdateBorrowerSuccessAction, UpdateBorrowerSuccessActionOrigin } from './ActionCreator';

const isUpdateBorrowerSuccessAction = (action: AnyAction): action is UpdateBorrowerSuccessAction =>
  action.type === LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess;

const isUpdateBorrowerDetailsSuccessAction = (action: AnyAction): action is UpdateBorrowerSuccessAction =>
  isUpdateBorrowerSuccessAction(action) &&
  action.meta?.actionOrigin === UpdateBorrowerSuccessActionOrigin.BorrowerDetails;

export const updateBorrowerSuccessMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isUpdateBorrowerSuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.BorrowerUpdated, {
        borrowerName: getBorrowerFullName(action.payload.type, action.payload.variables),
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};

export const updateBorrowerDetailsSuccessMiddleware = ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  if (isUpdateBorrowerDetailsSuccessAction(action)) {
    const { loanOriginationSystemBorrowers } = getState();

    const { filters, sortingType } = loanOriginationSystemBorrowers;

    dispatch(getBorrowers(filters, sortingType));
  }

  return next(action);
};
