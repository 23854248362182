import {
  IntermediaryNameOption,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import {
  GetProductIntermediariesAction,
  getProductIntermediariesSuccess,
  getProductIntermediariesFailed,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { GET_PRODUCT_INTERMEDIARIES } from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const GetFiltersIntermediariesMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<IntermediaryNameOption[], GetProductIntermediariesAction>(
    GET_PRODUCT_INTERMEDIARIES,
    getProductIntermediariesSuccess,
    getProductIntermediariesFailed,
    async () => {
      const { items } = await api.getIntermediaryNames();

      return items;
    },
  );

export default GetFiltersIntermediariesMiddleware;
