import React, { FC, useEffect } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import SelectDecisionRunPopup from './SelectDecisionRun';
import RunSingleDecisionPopUp from './RunSingleDecision';
import RunMultipleDecisionPopUp from './RunMultipleDecision';
import {
  changeRunDecisionsPopUpType,
  RunDecisionPopUpType,
  RunDecisionState
} from 'Decisions/RunDecisionStore';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import RunApplicationDecisionPopup from 'components/Decisions/PopUps/RunApplicationDecision';
import styles from './RunDecisionPop.module.scss';

interface RunDecisionPopUpContainerProps {
  popUpType: RunDecisionPopUpType;
  onDecisionRan: (decisionId: string, resultsCount: number) => void;
  onClose: () => void;
  application?: Application;
}

const RunDecisionPopUpContainer: FC<RunDecisionPopUpContainerProps> = ({
  popUpType,
  onClose,
  onDecisionRan,
  application,
}) => {
  const dispatch = useDispatch();
  const { decisionPopUpType } = useSelector<ReduxState, RunDecisionState>(
    (state) => state.runDecision,
  );

  useEffect(() => {
    dispatch(changeRunDecisionsPopUpType({ popUpType }))
  }, [popUpType]);

  const handleChangeOpenRunDecisionPopUpType = (popUpTypeToSet: RunDecisionPopUpType) => {
    dispatch(changeRunDecisionsPopUpType({ popUpType: popUpTypeToSet }))
  }

  const getPopUpTitle = () => {
    switch (decisionPopUpType) {
      case 'select':
        return 'Run Decision';
      case 'single':
        return 'Run Single Decision';
      case 'multiple':
        return 'Run Multiple Decisions';
      case 'single-application-run':
        return 'Run Decision Engine';
      default:
        return '';
    }
  };

  const getPopUpContent = () => {
    switch (decisionPopUpType) {
      case 'select':
        return <SelectDecisionRunPopup
          changeOpenRunDecisionPopUpType={handleChangeOpenRunDecisionPopUpType!}
        />;
      case 'single':
        return <RunSingleDecisionPopUp onDecisionRan={onDecisionRan} />;
      case 'multiple':
        return <RunMultipleDecisionPopUp onDecisionRan={onDecisionRan} />;
      case 'single-application-run':
        return <RunApplicationDecisionPopup onDecisionRan={onDecisionRan} application={application!} />;
      default:
        return null;
    }
  };

  const getTooltipContent = () => {
    if (decisionPopUpType !== 'multiple') {
      return null;
    }

    return (
      <div className={styles.centeredTooltip}>
        <p>The maximum number of decisions</p>
        <p>that can be run at once is 10,000</p>
      </div>
    );
  }

  return (
    <PopUp title={getPopUpTitle()} titleTooltip={getTooltipContent()} closePopUp={onClose}>
      <PopUpContent>{getPopUpContent()}</PopUpContent>
    </PopUp>
  );
};

export default RunDecisionPopUpContainer;
