import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './HeaderWithActions.module.scss';

export interface HeaderWithActionsProps {
  title: string;
  className?: string;
  titleClassName?: string;
  titleWithActionsContainerClassName?: string;
  titleActionsContainerClassName?: string;
  headerActionsContainerClassName?: string;
  titleActions?: ReactNode;
  headerActions?: ReactNode;
}

const HeaderWithActions: FC<HeaderWithActionsProps> = ({
  className,
  titleClassName,
  titleWithActionsContainerClassName,
  titleActionsContainerClassName,
  headerActionsContainerClassName,
  title,
  headerActions,
  titleActions,
}) => {
  return (
    <div className={clsx(styles.header, className)}>
      <div className={clsx(styles.titleWithActionsContainer, titleWithActionsContainerClassName)}>
        <h4 className={clsx(styles.title, titleClassName)}>{title}</h4>
        {titleActions && <div className={clsx(styles.titleActionsContainer, titleActionsContainerClassName)}>{titleActions}</div>}
      </div>
      {headerActions && <div className={clsx(styles.headerActionsContainer, headerActionsContainerClassName)}>{headerActions}</div>}
    </div>
  );
};

export default HeaderWithActions;
