import { AnyAction } from 'redux';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface FiltersResult {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  borrowerTypes: string[];
}

export interface SetSearchInputValueAction extends AnyAction {
  type: LoanOriginationSystemIntermediariesFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchInputValue: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemIntermediariesFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface FilterIntermediariesByMembersAction extends AnyAction {
  type: LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers;
  payload: {
    members: UserInfo[];
  };
}

export interface ResetAllIntermediariesFilters extends AnyAction {
  type: LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters;
}

export const setSearchInputValue = (searchInputValue: string): SetSearchInputValueAction => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchInputValue,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters,
    payload: {
      dueCreatedDateRange: {
        from: null,
        to: null,
      },
      dueUpdatedDateRange: {
        from: null,
        to: null,
      },
      borrowerTypes: [],
    },
  };
};

export const filterIntermediariesByMembers = (members: UserInfo[]): FilterIntermediariesByMembersAction => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers,
    payload: {
      members,
    },
  };
};

export const resetAllIntermediariesFilters = (): ResetAllIntermediariesFilters => {
  return {
    type: LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters,
  };
};
