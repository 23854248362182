import React, { useState } from 'react';
import styles from './InfoPanel.module.scss';
import InfoPanelTitle from './InfoPanelTitle';
import clsx from 'clsx';
import InfoPanelContent from './InfoPanelContent';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getOrganizationEmail } from 'utils/emailGetters';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { StandardVariables } from 'Variables/VariablesTypes';

interface InfoPanelProps {
  productName: string;
  statusName: string;
  statusNumber: number;
  statusesAmount: number;
  applicationData: Application;
  standardVariables: StandardVariables;
  formatApplicationDate?: (date: Date) => string;
  isStatusVisible: boolean | null;
  hideEditApplicationLink?: boolean;
  className?: string;
  openByDefault?: boolean;
}

const InfoPanel = ({
  productName,
  statusName,
  statusesAmount,
  statusNumber,
  applicationData,
  standardVariables,
  formatApplicationDate,
  isStatusVisible,
  className,
  hideEditApplicationLink = false,
  openByDefault,
}: InfoPanelProps) => {
  const [isOpen, setIsOpen] = useState(!!openByDefault);

  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);

  return (
    <div className={clsx(styles.infoPanelInner, className)}>
      <InfoPanelTitle
        productName={productName}
        statusName={statusName}
        statusesAmount={statusesAmount}
        statusNumber={statusNumber}
        setOpen={() => setIsOpen(!isOpen)}
        currentState={isOpen}
        isStatusVisible={isStatusVisible!}
        hideEditApplicationLink={hideEditApplicationLink}
      />
      <InfoPanelContent
        standardVariables={standardVariables}
        formatApplicationDate={formatApplicationDate}
        applicationData={applicationData}
        isOpen={isOpen}
        organizationEmail={getOrganizationEmail(organizationInfo.id)}
      />
    </div>
  );
};

export default InfoPanel;
