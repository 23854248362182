import { IdentificationNumberWithAttributes, VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import { validateEmail, validateIDNumber, validatePhoneNumber } from 'utils/validation/validation';

const getValidationMessage = (
  variableWithAttributes: VisualDataTypeWithAttributes,
  value: string,
  required?: boolean,
): string => {
  const { dataType } = variableWithAttributes;

  switch (dataType) {
    case 'PhoneNumber': {
      return validatePhoneNumber(value, required);
    }
    case 'IdentificationNumber': {
      const { identificationNumberType } = variableWithAttributes as IdentificationNumberWithAttributes;
      return validateIDNumber(value, identificationNumberType!, required);
    }
    case 'EmailAddress': {
      return validateEmail(value, required);
    }
    default: {
      return '';
    }
  }
};

export default getValidationMessage;
