import React from 'react';
import SearchInput from 'components/SearchInput';
import FilterButton from 'components/FilterButton';
import styles from './Header.module.scss';
import Button from 'components/Button';
import { openConnectEmailsPopup } from 'LoanOriginationSystemEmails/ActionCreator';
import { useDispatch } from 'react-redux';
import { getMailtoURI } from 'Email/utils';

interface HeaderProps {
  searchInputValue: string;
  filtersChanged: boolean;
  subTitleHidden: boolean;
  onSearch: (search: string) => void;
  openFiltersPopup: () => void;
  organizationEmail: string;
  areEmailsLoading: boolean;
}

const Header = ({
  searchInputValue,
  filtersChanged,
  subTitleHidden,
  onSearch,
  openFiltersPopup,
  organizationEmail,
  areEmailsLoading,
}: HeaderProps) => {
  const dispatch = useDispatch();

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const onSearchClear = () => {
    onSearch('');
  };

  const handleConnectEmails = () => {
    dispatch(openConnectEmailsPopup());
  };

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({ cc: organizationEmail });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Emails</div>
        <div className={styles.buttonContainer}>
          <Button kind="secondary" className={styles.connectEmailsButton} onClick={handleConnectEmails}>
            Connect Emails
          </Button>
          <Button kind="primary" className={styles.sendEmailButton} onClick={handleSendEmail}>
            Send Email
          </Button>
        </div>
      </div>
      {!subTitleHidden && (
        <div className={styles.subTitle}>
          <SearchInput
            placeholder="Search"
            value={searchInputValue}
            onChange={onSearchInputChange}
            onClear={onSearchClear}
            showLoader={!!searchInputValue && areEmailsLoading}
          />
          <FilterButton onClick={openFiltersPopup} active={filtersChanged} />
        </div>
      )}
    </div>
  );
};

export default Header;
