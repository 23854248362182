import withStateReset from 'utils/reducers/withStateReset';
import { setItemsTotal } from 'utils/reducers/setStateField';
import { LoanOriginationSystemIntermediariesState } from './Types';
import {
  GET_INTERMEDIARIES_SUCCESS,
  LoanOriginationSystemIntermediariesActionType,
  SORT_INTERMEDIARIES,
} from './ActionTypes';
import { AnyAction } from 'redux';
import intermediariesPagination, { INTERMEDIARIES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/IntermediariesDashboard/Pagination';
import {
  initialState as intermediariesFiltersInitialState,
  loanOriginationSystemIntermediariesFilterReducer,
} from './Filters/Reducer';
import { IntermediariesSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import IntermediaryHasApplicationsError from 'errors/IntermediaryHasApplicationsError';
import {
  initialState as createIntermediaryInitialState,
  loanOriginationSystemCreateIntermediaryReducer,
} from './CreateIntermediary/Reducer';
import {
  DeleteIntermediaryAction, DeleteIntermediaryFailureAction, DeleteIntermediarySuccessAction,
  GetIntermediariesSuccessAction,
  SetIntermediaryToDeleteAction,
  SortIntermediariesAction,
} from './ActionCreator';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './Filters/ActionTypes';
import {
  ChangePopupFiltersAction,
  FilterIntermediariesByMembersAction,
  ResetAllIntermediariesFilters,
  SetSearchInputValueAction,
} from './Filters/ActionCreator';
import intermediariesFilterAndSortingSaver from './FilterAndSortingSaver';

const initialSortingType = {
  field: IntermediariesSortingField.UpdatedAt,
  ascending: false,
};

const searchRelevanceSortingType = {
  field: IntermediariesSortingField.SearchRelevance,
  ascending: false,
};

const getInitialState = (): LoanOriginationSystemIntermediariesState => ({
  filters: { ...intermediariesFiltersInitialState, ...intermediariesFilterAndSortingSaver.getSavedFilters() },
  items: [],
  page: 1,
  itemsPerPage: INTERMEDIARIES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  searched: {},
  sortingType: intermediariesFilterAndSortingSaver.getSavedSorting() || initialSortingType,
  intermediaryToDelete: null,
  isDeleteInProgress: false,
  createIntermediary: createIntermediaryInitialState,
});

type LoanOriginationSystemIntermediariesActions =
  | GetIntermediariesSuccessAction
  | SortIntermediariesAction
  | SetIntermediaryToDeleteAction
  | DeleteIntermediaryAction
  | FilterIntermediariesByMembersAction
  | ResetAllIntermediariesFilters
  | ChangePopupFiltersAction
  | DeleteIntermediarySuccessAction
  | DeleteIntermediaryFailureAction
  | SetSearchInputValueAction;

type LoanOriginationSystemIntermediariesType = (
  state: LoanOriginationSystemIntermediariesState,
  action: AnyAction,
) => LoanOriginationSystemIntermediariesState;

const loanOriginationSystemIntermediariesReducer = (
  state = getInitialState(),
  action: LoanOriginationSystemIntermediariesActions,
): LoanOriginationSystemIntermediariesState => {
  switch (action.type) {
    case GET_INTERMEDIARIES_SUCCESS:
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: INTERMEDIARIES_PER_PAGE_DEFAULT,
              error: null,
            },
          },
          intermediaryToDelete: null,
          isDeleteInProgress: false,
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || INTERMEDIARIES_PER_PAGE_DEFAULT,
        error: null,
        items: action.payload.items,
        itemsTotal: action.payload.total,
        isDeleteInProgress: false,
      };
    case SORT_INTERMEDIARIES:
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        sortingType: action.payload,
      };
    case LoanOriginationSystemIntermediariesActionType.SetIntermediaryToDelete: {
      return {
        ...state,
        intermediaryToDelete: action.payload.intermediary,
      };
    }
    case LoanOriginationSystemIntermediariesActionType.DeleteIntermediary: {
      return {
        ...state,
        isDeleteInProgress: true,
      };
    }
    case LoanOriginationSystemIntermediariesActionType.DeleteIntermediarySuccess: {
      return {
        ...state,
        isDeleteInProgress: false,
        intermediaryToDelete: null,
      };
    }
    case LoanOriginationSystemIntermediariesActionType.DeleteIntermediaryFailure: {
      return {
        ...state,
        isDeleteInProgress: false,
        intermediaryToDelete: (action.payload instanceof IntermediaryHasApplicationsError) ? null : state.intermediaryToDelete,
      };
    }
    case LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers:
    case LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        filters: loanOriginationSystemIntermediariesFilterReducer(state.filters, action),
      };
    }
    case LoanOriginationSystemIntermediariesFiltersActionTypes.SetSearchInputValue: {
      const { searchInputValue } = action.payload;
      const prevSearchValue = state.filters.searchInputValue;

      return {
        ...state,
        filters: loanOriginationSystemIntermediariesFilterReducer(state.filters, action),
        // Set sorting by search relevance on search apply and unset sorting on search remove
        ...(prevSearchValue.length === 0 && searchInputValue.trim().length > 0
          ? { sortingType: searchRelevanceSortingType }
          : null),
        ...(prevSearchValue.length > 0 && searchInputValue.trim().length === 0 ? { sortingType: initialSortingType } : null),
      };
    }
    default:
      return {
        ...state,
        filters: loanOriginationSystemIntermediariesFilterReducer(state.filters, action),
        createIntermediary: loanOriginationSystemCreateIntermediaryReducer(state.createIntermediary, action),
      };
  }
};

export default intermediariesPagination.wrapReducer<LoanOriginationSystemIntermediariesState>(
  withStateReset(
    loanOriginationSystemIntermediariesReducer,
    LoanOriginationSystemIntermediariesActionType.ResetState,
    getInitialState,
  ) as LoanOriginationSystemIntermediariesType,
);
