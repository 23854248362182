import React, { FC } from 'react';
import BorrowerTypeToggleGroup from 'components/LoanOriginationSystem/BorrowerTypeToggleGroup';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerTypeToggleGroupProps } from 'components/LoanOriginationSystem/BorrowerTypeToggleGroup/BorrowerTypeToggleGroup';

export type ProductBorrowerTypeToggleGroupProps = Omit<BorrowerTypeToggleGroupProps, 'renderOptionTooltip'>;

const ProductBorrowerTypeToggleGroup: FC<ProductBorrowerTypeToggleGroupProps> = ({
  availableBorrowerTypes,
  ...restProps
}) => {
  const renderOptionTooltip = (option: string) => {
    if (availableBorrowerTypes.includes(option as BorrowerType)) {
      return null;
    }

    if (option === BorrowerType.Company) {
      return 'This Product has a borrower type of Person';
    }

    return 'This Product has a borrower type of Company';
  };

  return (
    <BorrowerTypeToggleGroup availableBorrowerTypes={availableBorrowerTypes} renderOptionTooltip={renderOptionTooltip} {...restProps} />
  );
};

export default ProductBorrowerTypeToggleGroup;
