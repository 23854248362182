import React, { FC } from 'react';
import AutoCompletionSync from 'components/AutoCompletion/AutoCompletionSync';
import { Option } from 'components/SelectInput/SelectInput';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import ButtonToggleGroup from 'components/ButtonToggleGroup';
import styles from './ReportingDashboardFilters.module.scss';
import clsx from 'clsx';
import FilterButton from 'components/FilterButton';
import {
  ReportingDashboardFiltersModel,
  ReportingDashboardPeriod,
} from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import { noop } from 'lodash';
import ReportingDashboardRange from 'LoanOriginationSystemReporting/ReportingDashboardRange';
import LoanOriginationSystemReportingDefaultFilters, {
  getEndOfTheDay,
  getStartOfTheDay,
} from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingDefaultFilters';

const OPTIONS: Option[] = [
  { name: ReportingDashboardRange.AllTime, value: ReportingDashboardRange.AllTime },
  { name: ReportingDashboardRange.LastThreeYears, value: ReportingDashboardRange.LastThreeYears },
  { name: ReportingDashboardRange.LastTwelveMonths, value: ReportingDashboardRange.LastTwelveMonths },
  { name: ReportingDashboardRange.ThisYear, value: ReportingDashboardRange.ThisYear },
  { name: ReportingDashboardRange.ThisMonth, value: ReportingDashboardRange.ThisMonth },
  { name: ReportingDashboardRange.LastThirtyDays, value: ReportingDashboardRange.LastThirtyDays },
  { name: ReportingDashboardRange.Custom, value: ReportingDashboardRange.Custom },
];

const PERIODS: ReportingDashboardPeriod[] = ['Annually', 'Monthly', 'Daily'];

interface ReportingDashboardFiltersProps {
  disabled: boolean;
  className?: string;
  organizationCreatedAt: Date;
  filters: ReportingDashboardFiltersModel;
  additionalFiltersActive: boolean;
  onChange: (filters: ReportingDashboardFiltersModel) => void;
  onAdditionalFiltersClick: () => void;
}

const ReportingDashboardFilters: FC<ReportingDashboardFiltersProps> = ({
  filters,
  className,
  organizationCreatedAt,
  onChange,
  onAdditionalFiltersClick,
  additionalFiltersActive,
  disabled,
}) => {
  const minDate = LoanOriginationSystemReportingDefaultFilters[ReportingDashboardRange.AllTime](organizationCreatedAt);

  const handleDatePickerChange = (from: Date | null, to: Date | null) => {
    onChange({
      from: getStartOfTheDay(from || filters.from),
      to: getEndOfTheDay(to || filters.to),
      period: filters.period,
      range: OPTIONS[OPTIONS.length - 1].value as ReportingDashboardRange,
    });
  };

  const handleDateOptionSelect = (option: Option) => {
    onChange({
      from: LoanOriginationSystemReportingDefaultFilters[option.value]?.(organizationCreatedAt) || filters.from,
      to: getEndOfTheDay(),
      period: filters.period,
      range: option.value as ReportingDashboardRange,
    });
  };

  const handlePeriodSelect = (newPeriod: string) => {
    onChange({
      ...filters,
      period: newPeriod as ReportingDashboardPeriod,
    });
  };

  return (
    <div className={clsx(styles.container, className)}>
      <AutoCompletionSync
        className={clsx(styles.selectDateOptionContainer, disabled && styles.disabled)}
        selectInputClassName={styles.selectDateOptionInput}
        onChange={handleDateOptionSelect}
        options={OPTIONS}
        selectedOption={OPTIONS.find(({ value }) => value === filters.range)}
        hideClearIcon
        disabled={disabled}
      />
      <DateRangePicker
        datePickerFromClassName={styles.datePickerFrom}
        datePickerToClassName={styles.datePickerTo}
        onChange={handleDatePickerChange}
        from={filters.from}
        to={filters.to}
        inputClassName={clsx(styles.datePickerInput, disabled && styles.disabled)}
        disabled={disabled}
        minDate={minDate}
        maxDate={getEndOfTheDay()}
      />
      <ButtonToggleGroup
        className={styles.buttonGroup}
        selectedValue={filters.period}
        onSelect={handlePeriodSelect}
        options={PERIODS}
        disabled={disabled}
      />
      <FilterButton
        containerClassName={clsx(styles.filterButton, disabled && styles.disabled)}
        onClick={disabled ? noop : onAdditionalFiltersClick}
        active={additionalFiltersActive}
        disabled={disabled}
      />
    </div>
  );
};

export default ReportingDashboardFilters;
