import React, { ReactNode } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip, { TooltipPosition } from 'components/Tooltip';
import styles from './QuestionIcon.module.scss';

export interface QuestionIconProps {
  tooltip: ReactNode;
  tooltipPosition?: TooltipPosition;
  className?: string;
  tooltipClassName?: string;
  size?: number;
}

const DEFAULT_SIZE = 16;
const DEFAULT_TOOLTIP_POSITION: TooltipPosition = 'top';

const QuestionIcon = ({
  tooltip,
  tooltipPosition = DEFAULT_TOOLTIP_POSITION,
  tooltipClassName,
  className,
  size = DEFAULT_SIZE,
}: QuestionIconProps) => (
  <WrapperWithTooltip tooltip={tooltip} position={tooltipPosition} className={tooltipClassName}>
    <p style={{ width: size, height: size }} className={clsx(styles.icon, className)} />
  </WrapperWithTooltip>
);

export default QuestionIcon;
