import { AnyAction } from 'redux';
import OrganizationActionType from './ActionTypes';
import { OrganizationInfoType, UpdateOrganizationInfoParams } from './CompanyInformationTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { SeatsData, OrganizationDetailedInfo } from 'api/Core/OrganizationApi';

export interface ChangeOrganizationInfoSuccessAction extends AnyAction {
  type: OrganizationActionType.ChangeOrganizationInfoSuccess;
  payload: OrganizationInfoType;
}

export interface ChangeOrganizationInfoRequestAction extends AnyAction {
  type: OrganizationActionType.ChangeOrganizationInfoRequest;
  payload: UpdateOrganizationInfoParams;
}

export interface GetOrganizationSeatsAction extends AnyAction {
  type: OrganizationActionType.GetSeats;
}

export interface GetOrganizationFreeSeatAction extends AnyAction {
  type: OrganizationActionType.GetFreeSeat;
  payload: string;
}

export interface GetOrganizationFreeSeatSuccessAction extends AnyAction {
  type: OrganizationActionType.GetFreeSeatSuccess;
  payload: boolean;
}

export interface GetOrganizationInfoAction extends AnyAction {
  type: OrganizationActionType.GetOrganizationInfo;
}

export interface GetOrganizationInfoSuccessAction extends AnyAction {
  type: OrganizationActionType.GetOrganizationInfoSuccess;
  payload: OrganizationDetailedInfo;
}

export interface GetOrganizationSeatsSuccessAction extends AnyAction {
  type: OrganizationActionType.GetSeatsSuccess;
  payload: SeatsData;
}

export interface OpenSeatsAreFullPopUpAction extends AnyAction {
  type: OrganizationActionType.OpenSeatsAreFullPopUp;
}

export interface CloseSeatsAreFullPopUpAction extends AnyAction {
  type: OrganizationActionType.CloseSeatsAreFullPopUp;
}

export const getOrganizationInfo = (): GetOrganizationInfoAction => {
  return {
    type: OrganizationActionType.GetOrganizationInfo,
  };
};

export const getOrganizationInfoSuccess = (
  organizationInfo: OrganizationDetailedInfo,
): GetOrganizationInfoSuccessAction => {
  return {
    type: OrganizationActionType.GetOrganizationInfoSuccess,
    payload: organizationInfo,
  };
};

export const getOrganizationInfoError = FailedActionCreator(OrganizationActionType.GetOrganizationInfoFailure);

export const updateOrganizationInfoSuccess = (
  successInfo: OrganizationInfoType,
): ChangeOrganizationInfoSuccessAction => {
  return {
    type: OrganizationActionType.ChangeOrganizationInfoSuccess,
    payload: successInfo,
  };
};

export const updateOrganizationInfoError = FailedActionCreator(OrganizationActionType.ChangeOrganizationInfoFailure);

export const updateOrganizationInfoRequest = (
  organizationInfo: UpdateOrganizationInfoParams,
): ChangeOrganizationInfoRequestAction => {
  return {
    type: OrganizationActionType.ChangeOrganizationInfoRequest,
    payload: organizationInfo,
  };
};

export const getOrganizationSeats = (): GetOrganizationSeatsAction => {
  return {
    type: OrganizationActionType.GetSeats,
  };
};

export const getOrganizationSeatsSuccess = (seats: SeatsData): GetOrganizationSeatsSuccessAction => {
  return {
    type: OrganizationActionType.GetSeatsSuccess,
    payload: seats,
  };
};

export const getOrganizationFreeSeat = (email: string): GetOrganizationFreeSeatAction => {
  return {
    type: OrganizationActionType.GetFreeSeat,
    payload: email,
  };
};

export const getOrganizationFreeSeatSuccess = (hasFreeSeat: boolean): GetOrganizationFreeSeatSuccessAction => {
  return {
    type: OrganizationActionType.GetFreeSeatSuccess,
    payload: hasFreeSeat,
  };
};

export const getOrganizationFreeSeatFailure = FailedActionCreator(OrganizationActionType.GetFreeSeatFailure);

export const getOrganizationSeatsFailure = FailedActionCreator(OrganizationActionType.GetSeatsFailure);

export const openSeatsAreFullPopUp = (): OpenSeatsAreFullPopUpAction => {
  return {
    type: OrganizationActionType.OpenSeatsAreFullPopUp,
  };
};

export const closeSeatsAreFullPopUp = (): CloseSeatsAreFullPopUpAction => {
  return {
    type: OrganizationActionType.CloseSeatsAreFullPopUp,
  };
};
