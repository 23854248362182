import { createAsyncThunk } from '@reduxjs/toolkit';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import requestWithNormalize from 'utils/requestWithNormalize';
import BorrowerProfileCardsRestApi, {
  BorrowerProfileCard,
  BorrowerProfileNormalizedCard,
  CreateBorrowerProfileCardParams,
  UpdateBorrowerProfileCardParams,
} from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import BorrowerProfileCardSchema from 'schemas/BorrowerProfileCardSchema';
import responseHandlers from 'api/ResponseHandlers';
import { BorrowerVariableConfiguration } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';

interface BorrowerProfileCardsNormalizedEntities {
  borrowerProfileCards: Record<string, BorrowerProfileNormalizedCard>;
  borrowerVariableConfigurations: Record<string, BorrowerVariableConfiguration>;
}

const borrowerProfileCardsApi = new BorrowerProfileCardsRestApi(responseHandlers);

export const getBorrowerProfileCards = createAsyncThunk(
  'borrowerProfileCards/getBorrowerProfileCards',
  async (borrowerType: BorrowerType) => {
    const { entities } = await requestWithNormalize<BorrowerProfileCardsNormalizedEntities, BorrowerProfileCard>(() => {
      return borrowerProfileCardsApi.find(borrowerType);
    }, [BorrowerProfileCardSchema]);

    return entities;
  },
);

export const createBorrowerProfileCard = createAsyncThunk(
  'borrowerProfileCards/createBorrowerProfileCard',
  async (params: CreateBorrowerProfileCardParams) => {
    const { entities } = await requestWithNormalize<BorrowerProfileCardsNormalizedEntities, BorrowerProfileCard>(() => {
      return borrowerProfileCardsApi.create(params);
    }, BorrowerProfileCardSchema);

    return entities;
  },
);

export const updateBorrowerProfileCard = createAsyncThunk(
  'borrowerProfileCards/updateBorrowerProfileCard',
  async (params: UpdateBorrowerProfileCardParams & { id: string }) => {
    const { entities } = await requestWithNormalize<BorrowerProfileCardsNormalizedEntities, BorrowerProfileCard>(() => {
      const { id, ...updateParams } = params;

      return borrowerProfileCardsApi.update(id, updateParams);
    }, BorrowerProfileCardSchema);

    return entities;
  },
);

export const deleteBorrowerProfileCard = createAsyncThunk(
  'borrowerProfileCards/deleteBorrowerProfileCard',
  async (id: string) => {
    await borrowerProfileCardsApi.delete(id);

    return {
      id,
    };
  },
);
