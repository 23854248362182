import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { resetProductConfiguration } from 'LoanOriginationSystemProducts/ProductConfiguration/ActionCreator';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import {
  getProduct,
  GetProductActionOrigin,
} from 'LoanOriginationSystemProducts/ActionCreator';
import ApplicationSetup from './ApplicationSetup';
import ApplicationWorkflowSetup from './ApplicationWorkflowSetup';
import ApplicationFormConfiguration from './ApplicationFormConfiguration';
import styles from './ProductConfiguration.module.scss';
import ApplicationAutomation from './ApplicationAutomation';

export interface ProductConfigurationProps {
  productId: string;
  step: ProductConfigurationStep;
}

const ProductConfiguration = ({ productId, step }: ProductConfigurationProps) => {
  const dispatch = useDispatch();
  const { product: selectedProduct } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration,
  );

  useEffect(() => {
    if (!selectedProduct) {
      const action = setActionOrigin(getProduct(productId), GetProductActionOrigin.ProductConfiguration);

      dispatch(action);
    }

    return () => {
      dispatch(resetProductConfiguration());
    };
  }, []);

  const renderStepContent = () => {
    if (step === ProductConfigurationStep.ApplicationFormConfiguration) {
      return <ApplicationFormConfiguration />;
    }

    if (step === ProductConfigurationStep.ApplicationSetup) {
      return <ApplicationSetup />;
    }

    if (step === ProductConfigurationStep.ApplicationAutomation) {
      return <ApplicationAutomation />;
    }

    return <ApplicationWorkflowSetup />;
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {renderStepContent()}
      </div>
    </div>
  );
};

export default ProductConfiguration;
