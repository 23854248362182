import React, { FC } from 'react';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import SIZES from 'components/CompanyInformationUsers/TableColumnSizes';
import styles from './UserLine.module.scss';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import clsx from 'clsx';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import UserStatusTag from 'components/UserStatusTag/UserStatusTag';
import { AvatarIcon } from 'static/images';
import UserAvatar from 'components/UserAvatar';
import useCompanyFreeSeatsCheck from 'AddNewCompanyUser/useCompanyFreeSeatsCheck';

interface UserLineProps {
  user: OrganizationMembership;
  openEditUserContextualPopUp: (userId: string) => void;
  updateUser: (userInfo: EditUserParams) => void;
  isSelected: boolean;
  isCurrentUser: boolean;
  handleOpenSeatsAreFullPopUp: () => void;
}

const userActionLabels = {
  activateAccount: 'Activate Account',
  deactivateAccount: 'Deactivate Account',
};

const UserLine: FC<UserLineProps> = ({
  user,
  openEditUserContextualPopUp,
  updateUser,
  isSelected,
  handleOpenSeatsAreFullPopUp,
  isCurrentUser,
}) => {
  const {
    firstName,
    lastName,
    email,
    isMfaEnabled,
    permissionGroupId,
    permissionGroupName,
    membershipId,
    imageId,
    isActive,
    userId,
  } = user;

  const [isTogglingStatusLoading, checkFreeSeats] = useCompanyFreeSeatsCheck();

  const toggleActive = async () => {
    if (!isActive) {
      const hasFreeSeat = await checkFreeSeats(email, handleOpenSeatsAreFullPopUp);

      if (!hasFreeSeat) {
        return;
      }
    }

    updateUser({
      isActive: !isActive,
      userId: membershipId,
      permissionGroupId,
    });
  };

  const handleEditUser = () => {
    openEditUserContextualPopUp(membershipId);
  };

  const avatarUser = userId
    ? {
        firstName: firstName!,
        lastName: lastName!,
        imageId,
      }
    : null;

  return (
    <RowActionsContainer>
      <div
        className={clsx(styles.userLine, isSelected && styles.userLineActive)}
        onClick={isTogglingStatusLoading ? undefined : handleEditUser}
      >
        <TableRow disabled={isTogglingStatusLoading}>
          <TableBodyCell width={SIZES.FIRST_NAME} noPadding>
            <div className={styles.userLine__image}>
              {avatarUser && <UserAvatar {...avatarUser} size="small" />}
              {!avatarUser && <AvatarIcon />}
            </div>
            <p>{userId ? firstName : '—'}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.LAST_NAME}>
            <p>{userId ? lastName : '—'}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.EMAIL}>
            <p>{email}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.PERMISSION_GROUP}>
            <p className={styles.permissionGroup}>{permissionGroupName}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.PHONE_AUTHENTICATION}>
            {userId && <p>{isMfaEnabled ? 'Enabled' : 'Disabled'}</p>}
          </TableBodyCell>
          <TableBodyCell width={SIZES.ACCOUNT_STATUS}>
            <UserStatusTag isActive={isActive} userId={userId} />
          </TableBodyCell>
          <RowActions
            actions={[
              {
                title: 'Edit User',
                handler: handleEditUser,
                danger: false,
                separatorRequired: isActive,
              },
              {
                danger: isActive,
                handler: () => toggleActive(),
                title: isActive ? userActionLabels.deactivateAccount : userActionLabels.activateAccount,
                disabled: isCurrentUser,
              },
            ]}
            isLoading={isTogglingStatusLoading}
          />
        </TableRow>
      </div>
    </RowActionsContainer>
  );
};

export default UserLine;
