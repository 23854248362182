import React from 'react';
import { FormLayoutData, VariableValue } from 'api/Types';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { SuggestionItem } from 'components/LoanOriginationSystem/CreateApplication/SuggestionsPopper';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import NewIntermediaryForm from './NewIntermediaryForm';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { StandardVariables } from 'Variables/VariablesTypes';

interface IntermediaryFormProps {
  intermediaryFormData: FormLayoutData;
  selectedIntermediary: Intermediary | null;
  cards: Card[] | null;
  standardVariables: StandardVariables | null;
  suggestions: Intermediary[];
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onLoadSuggestions?: (intermediarySuggestionFilter: IntermediarySuggestionFilter) => void;
  onIntermediarySelect?: (intermediary: Intermediary | null) => void;
  onEditProfile: (data: FormLayoutData) => Promise<void>;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const IntermediaryForm = ({
  intermediaryFormData,
  selectedIntermediary,
  cards,
  standardVariables,
  suggestions,
  onFieldChange,
  onLoadSuggestions,
  onIntermediarySelect,
  onEditProfile,
  renderActions,
}: IntermediaryFormProps) => {
  const suggestionOptions = suggestions.map(
    (suggestion) =>
      ({
        id: suggestion.id,
        name: suggestion.variables[IntermediaryDefaultVariable.Name],
        phone: suggestion.variables[IntermediaryDefaultVariable.PhoneNumber],
        email: suggestion.variables[IntermediaryDefaultVariable.Email],
      } as SuggestionItem),
  );

  const onSuggestionSelect = (suggestionItem: SuggestionItem) => {
    onIntermediarySelect?.(suggestions.find(({ id }) => id === suggestionItem.id)!);
  };

  if (selectedIntermediary) {
    return (
      <ExistingProfile
        cards={cards}
        title={selectedIntermediary.variables[IntermediaryDefaultVariable.Name] as string}
        data={selectedIntermediary.variables}
        onCloseButtonClick={() => onIntermediarySelect?.(null)}
        onEditProfileData={onEditProfile}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        renderActions={renderActions}
      />
    );
  }

  return (
    <NewIntermediaryForm
      cards={cards}
      intermediaryData={intermediaryFormData}
      standardVariables={standardVariables}
      onFieldChange={onFieldChange}
      suggestions={suggestionOptions}
      onSuggestionSelect={onSuggestionSelect}
      onLoadSuggestions={onLoadSuggestions}
      renderActions={renderActions}
    />
  );
};

export default IntermediaryForm;
