import { createReducer } from '@reduxjs/toolkit';
import { ProductConfigurationDocumentFoldersSortingFields } from 'api/LoanOriginationSystem/ProductConfigurationDocumentFoldersApi';
import {
  isPendingActionWithPrefix,
  isFulfilledActionWithPrefix,
  isRejectedActionWithPrefix,
} from 'utils/actions/ActionWithPrefix';
import withStateReset from 'utils/reducers/withStateReset';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  getDocumentFolders,
  createDocumentFolder,
  updateDocumentFolder,
  deleteDocumentFolder,
  openDocumentFolderPopup,
  closeDocumentFolderPopup,
  setFolderToUpdate,
  setParentFolder,
  sortDocumentFolders,
} from './ActionCreator';
import { ProductConfigurationDocumentFoldersState, ProductConfigurationDocumentFoldersPopUpType } from './Types';
import {
  ProductConfigurationDocumentFoldersActionTypes,
  ProductConfigurationDocumentFoldersActionTypesPrefix,
} from './ActionTypes';

export const initialState: ProductConfigurationDocumentFoldersState = {
  foldersById: {},
  foldersIdsByProductId: {},
  parentFolderId: null,
  folderToUpdateId: null,
  isPopupOpen: false,
  isUpdatePending: false,
  popUpType: ProductConfigurationDocumentFoldersPopUpType.Create,
  sortingType: { field: ProductConfigurationDocumentFoldersSortingFields.Name, ascending: true },
};

const isPendingDocumentFoldersAction = isPendingActionWithPrefix(ProductConfigurationDocumentFoldersActionTypesPrefix);
const isFulfilledDocumentFoldersAction = isFulfilledActionWithPrefix(
  ProductConfigurationDocumentFoldersActionTypesPrefix,
);
const isRejectedDocumentFoldersAction = isRejectedActionWithPrefix(
  ProductConfigurationDocumentFoldersActionTypesPrefix,
);

const ProductConfigurationDocumentFoldersReducer = createReducer<ProductConfigurationDocumentFoldersState>(
  initialState,
  (builder) => {
    builder
      .addCase(getDocumentFolders.fulfilled, (state, action) => {
        state.foldersById = { ...state.foldersById, ...normalizeEntityArray(action.payload) };
        state.foldersIdsByProductId[action.meta.arg] = action.payload.map((folder) => folder.id);
      })
      .addCase(createDocumentFolder.fulfilled, (state, action) => {
        state.foldersById[action.payload.id] = action.payload;
        state.foldersIdsByProductId[action.payload.productId].push(action.payload.id);
      })
      .addCase(updateDocumentFolder.fulfilled, (state, action) => {
        state.foldersById[action.payload.id] = action.payload;
      })
      .addCase(deleteDocumentFolder.fulfilled, (state, action) => {
        const deletedFolder = state.foldersById[action.meta.arg];

        state.foldersIdsByProductId[deletedFolder.productId] = (
          state.foldersIdsByProductId[deletedFolder.productId] || []
        ).filter((folderId) => folderId !== deletedFolder.id);
      })
      .addCase(openDocumentFolderPopup, (state, action) => {
        state.isPopupOpen = true;
        state.popUpType = action.payload;
      })
      .addCase(closeDocumentFolderPopup, (state) => {
        state.isPopupOpen = false;
      })
      .addCase(setFolderToUpdate, (state, action) => {
        state.folderToUpdateId = action.payload;
      })
      .addCase(setParentFolder, (state, action) => {
        state.parentFolderId = action.payload;
      })
      .addCase(sortDocumentFolders, (state, action) => {
        state.sortingType = action.payload;
      })
      .addMatcher(isPendingDocumentFoldersAction, (state) => {
        state.isUpdatePending = true;
      })
      .addMatcher(
        (action) => isFulfilledDocumentFoldersAction(action) || isRejectedDocumentFoldersAction(action),
        (state) => {
          state.isUpdatePending = false;
        },
      );
  },
);

export default withStateReset(
  ProductConfigurationDocumentFoldersReducer,
  ProductConfigurationDocumentFoldersActionTypes.ResetState,
  () => initialState,
);
