import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { History } from 'history';
import { ReduxState } from 'types/redux';
import { doesActionRequireStateReset } from 'stores/Actions';
import { simpleAction } from 'utils/actions/SimpleAction';
import {
  FilterAndSortingSaverParams,
  FilterAndSortingSaverReturnType,
  FilterSaverParams,
  FilterSaverReturnType,
  SortingSaverParams,
  SortingSaverReturnType,
  FilterAndSortingSaverCommonParams,
  FilterAndSortingSaverCommonReturnType,
  IsActionOfType,
  Key,
} from './Types';

const prefix = 'filterAndSortingSaver';
const getFullKey = (...keys: string[]) => `${prefix}/${keys.join('/')}`;
const getKey = (key: Key, type: 'filters' | 'sorting') => (pathName: string): string | null => {
  if (typeof key === 'string') {
    return getFullKey(key, type);
  }

  const selectedKey = key.find(([, isPathConditionMet]) => isPathConditionMet(pathName));

  return selectedKey ? getFullKey(selectedKey[0], type) : null;
};
const getAllKeys = (key: Key, type: 'filters' | 'sorting') => () => {
  if (Array.isArray(key)) {
    return key.map(([keyString]) => getFullKey(keyString, type));
  }

  return [getFullKey(key, type)];
};

const dateISORegExp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const dateReviver = (key: string, value: any) => {
  if (typeof value === 'string' && dateISORegExp.exec(value)) {
    return new Date(value);
  }

  return value;
};

const getSavedItems = <Item>(key: string | null): Item | null => {
  if (!key) {
    return null;
  }

  const filtersString = localStorage.getItem(key);

  if (!filtersString) {
    return null;
  }

  try {
    return JSON.parse(filtersString, dateReviver) as Item;
  } catch (e) {
    return null;
  }
};

const setSavedItems = <Value>(key: string | null, value: Value) =>
  key && localStorage.setItem(key, JSON.stringify(value));
const removeSavedItems = (key: string) => localStorage.removeItem(key);

const isActionTypeOneOf = (actionTypeChecks: IsActionOfType[]) => (action: AnyAction) =>
  actionTypeChecks.some((isActionOfType) => isActionOfType(action));

function FilterAndSortingSaver<FiltersToSave, SortingToSave>(
  params: FilterAndSortingSaverParams<FiltersToSave, SortingToSave>,
): FilterAndSortingSaverReturnType<FiltersToSave, SortingToSave>;
function FilterAndSortingSaver<FiltersToSave>(
  params: FilterSaverParams<FiltersToSave>,
): FilterSaverReturnType<FiltersToSave>;
function FilterAndSortingSaver<SortingToSave>(
  params: SortingSaverParams<SortingToSave>,
): SortingSaverReturnType<SortingToSave>;

function FilterAndSortingSaver<FiltersToSave, SortingToSave>(
  params: FilterAndSortingSaverCommonParams<FiltersToSave, SortingToSave>,
): FilterAndSortingSaverCommonReturnType<FiltersToSave, SortingToSave> {
  const {
    key,
    getFiltersToSave,
    getSortingToSave,
    saveTriggerActions,
    resetActionType,
    filtersSwitchActionType,
  } = params;

  const getFiltersKey = getKey(key, 'filters');
  const getSortingKey = getKey(key, 'sorting');
  const getAllFiltersKeys = getAllKeys(key, 'filters');
  const getAllSortingKeys = getAllKeys(key, 'sorting');
  let currentLocationPathName = '';
  const isSaveTrigger = isActionTypeOneOf(saveTriggerActions);

  const saveFiltersAndSorting = (state: ReduxState) => {
    if (getFiltersToSave) {
      setSavedItems(getFiltersKey(currentLocationPathName), getFiltersToSave(state));
    }

    if (getSortingToSave) {
      setSavedItems(getSortingKey(currentLocationPathName), getSortingToSave(state));
    }
  };

  const deleteSavedFiltersAndSorting = () => {
    if (getFiltersToSave) {
      getAllFiltersKeys().forEach(removeSavedItems);
    }

    if (getSortingToSave) {
      getAllSortingKeys().forEach(removeSavedItems);
    }
  };

  const getMiddleware = () =>
    (({ getState, dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
      const result = next(action);

      if (isSaveTrigger(action)) {
        saveFiltersAndSorting(getState());
      }

      if (doesActionRequireStateReset(action)) {
        deleteSavedFiltersAndSorting();

        if (resetActionType) {
          dispatch(simpleAction(resetActionType));
        }
      }

      return result;
    }) as Middleware;

  const getFiltersSwitchMiddleware = (history: History) =>
    (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => {
      const handleLocationChange = () => {
        if (
          getFiltersKey(history.location.pathname) !== getFiltersKey(currentLocationPathName) &&
          filtersSwitchActionType
        ) {
          currentLocationPathName = history.location.pathname;
          dispatch(simpleAction(filtersSwitchActionType));
        }
      };

      history.listen(handleLocationChange);

      handleLocationChange();

      return (action: AnyAction) => next(action);
    }) as Middleware;

  return {
    getMiddleware,
    getFiltersSwitchMiddleware,
    ...(getFiltersToSave
      ? {
          getSavedFilters: () => getSavedItems<FiltersToSave>(getFiltersKey(currentLocationPathName)),
        }
      : {}),
    ...(getSortingToSave
      ? {
          getSavedSorting: () => getSavedItems<SortingToSave>(getSortingKey(currentLocationPathName)),
        }
      : {}),
  };
}

export default FilterAndSortingSaver;
