import React, { FC, useState } from 'react';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import { PermissionGroupSelectOption, PermissionGroupId } from 'PermissionGroups/Types';
import styles from './UsersFilter.module.scss';

const PHONE_AUTHENTICATION = [
  {
    name: 'Enabled',
    value: 'enabled',
  },
  {
    name: 'Disabled',
    value: 'disabled',
  },
];

const INPUT_EMPTY_VALUE = '';

const isNotNull = <T,>(value: T | null): value is T => value !== null;

interface UsersFilterProps {
  permissionGroups: PermissionGroupSelectOption[];
  permissionGroupId: PermissionGroupId | null;
  phoneAuthentication: boolean | null;
  onFiltersApply: (permissionGroupFilter: PermissionGroupId, phoneAuthenticationFilter: string) => void;
  onFiltersPopupClose: () => void;
  onClearFiltersClick: () => void;
}

const UsersFilter: FC<UsersFilterProps> = ({
  onFiltersPopupClose,
  onFiltersApply,
  onClearFiltersClick,
  permissionGroups,
  permissionGroupId,
  phoneAuthentication,
}) => {
  const getInitialPhoneAuthentication = () => {
    if (!isNotNull(phoneAuthentication)) {
      return INPUT_EMPTY_VALUE;
    }

    return phoneAuthentication ? 'enabled' : 'disabled';
  };

  const [permissionGroupFilter, setPermissionGroupFilter] = useState<PermissionGroupId | string>(
    isNotNull(permissionGroupId) ? permissionGroupId : INPUT_EMPTY_VALUE,
  );
  const [phoneAuthenticationFilter, setPhoneAuthenticationFilter] = useState<string>(getInitialPhoneAuthentication);

  const handlePermissionGroupChange = (option: Option) => {
    setPermissionGroupFilter(option.value);
  };

  const handlePhoneAuthenticationChange = (option: Option) => {
    setPhoneAuthenticationFilter(option.value);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        className={styles.permissionGroupSelect}
        labelTitle="Permissions Group"
        onChange={handlePermissionGroupChange}
        value={permissionGroupFilter}
        options={permissionGroups}
      />
      <AutoCompletion
        labelTitle="Phone Authentication"
        onChange={handlePhoneAuthenticationChange}
        value={phoneAuthenticationFilter}
        options={PHONE_AUTHENTICATION}
      />
      <Button
        kind="primary"
        size="form"
        className={styles.applyFilter}
        onClick={() => onFiltersApply(permissionGroupFilter, phoneAuthenticationFilter)}
      >
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default UsersFilter;
