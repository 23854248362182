import { useMemo } from 'react';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { getCardsFields } from 'components/LoanOriginationSystem/CardsForm/utils';

const useCardFields = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>
>(cards: CardType[] | null) => {
  return useMemo(() => {
    if (!cards) {
      return null;
    }

    return getCardsFields(cards);
  }, [cards]);
};

export default useCardFields;
