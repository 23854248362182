import BillingTab from 'BillingPage/BillingTab';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import {
  COMPANY_GENERAL_SETTINGS_TAB_ROUTES,
  CompanyGeneralSettingsTab
} from 'components/CompanyGeneralSettings/CompanyGeneralSettings.constants';

export enum AppRoutes {
  AcceptInvite = '/auth/accept-invite/:token',
  Activation = '/auth/activation',
  CancelledSubscription = '/auth/subscription-cancelled',
  CreateAccount = '/auth/create-account',
  ForgotPassword = '/auth/forgot',
  ConfirmEmail = '/email-confirmation',
  Home = '/home',
  InactiveUser = '/auth/user-not-active',
  LoggedOut = '/auth/logged-out',
  PastDueSubscription = '/auth/subscription-past-due',
  ResetPassword = '/auth/reset-password',
  Mfa = '/auth/mfa',
  SecureUpload = '/secure-customer-document-upload/:token',
  SelectOrganization = '/auth/select-organization',
  SignIn = '/auth/sign-in',
  DocuSignCredentials = '/company-settings/docu-sign/credentials',
  CustomerPortal = '/plan-and-billing/portal',
}

export enum ExternalRoutes {
  PaymentPortal = '',
  ContactSales = 'https://www.digifi.io/contact-sales',
}

export default class RouteService {
  static getApplicationsUrl(viewType?: ViewType, createNew = false) {
    const params = new URLSearchParams();
    params.append('view', viewType as string);

    if (createNew) {
      params.append('new', '');
    }

    return `/los/applications?${params}`;
  }

  static getApplicationPage(displayId: string) {
    return `/los/applications/${displayId}`;
  }

  static getBillingPage(tab: BillingTab) {
    const baseUrl = '/company-settings/billing';

    return tab === BillingTab.Overview ? baseUrl : `${baseUrl}/${tab.toLowerCase()}`;
  }

  static getCompanyGeneralSettingsPage(tab: CompanyGeneralSettingsTab) {
    const baseUrl = '/company-settings/general';

    return tab === CompanyGeneralSettingsTab.CompanyInformation ? baseUrl : `${baseUrl}/${COMPANY_GENERAL_SETTINGS_TAB_ROUTES[tab]}`;
  }
}
