import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { GET_COLUMN_VIEW_PRODUCT_DATA } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  getColumnViewProductDataSuccess,
  getColumnViewProductDataFailed,
  GetColumnViewProductDataAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { SimplifiedApplication, SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const GetColumnViewProductDataMiddleware = (api: SimplifiedApplicationsApi) =>
  Fetcher<SimplifiedApplication[], GetColumnViewProductDataAction, ReduxState>(
    GET_COLUMN_VIEW_PRODUCT_DATA,
    getColumnViewProductDataSuccess,
    getColumnViewProductDataFailed,
    async (action, { loanOriginationSystemApplications }) => {
      const {
        productId,
        filters = loanOriginationSystemApplications.filters,
        sortingType = loanOriginationSystemApplications.columnViewSortingType,
        abortSignal,
      } = action.payload;

      const apiFilters = {
        ...filters,
        selectedStatusesIds: [],
        visibleOnBoard: true,
      };

      const applications = await api.getAll(productId, apiFilters, sortingType, abortSignal);

      return applications.items;
    },
  );

export default GetColumnViewProductDataMiddleware;
