export default class CacheService<CacheData> {
  private cache = new Map<string, CacheData>();

  public set(key: string, value: CacheData) {
    this.cache.set(key, value);
  }

  public get(key: string) {
    return this.cache.get(key);
  }
}
