import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';

interface UnwrappableAction {
  payload: any;
  meta?: any;
  error?: any;
}

interface AsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch<ReduxState>;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
}

const useDispatchWithUnwrap = () => {
  const dispatch = useDispatch();

  return async <Returned, ThunkArg, ThunkApiConfig extends AsyncThunkConfig>(
    action: AsyncThunkAction<Returned, ThunkArg, ThunkApiConfig>,
  ) => {
    const result = await dispatch(action);

    return unwrapResult((result as unknown) as UnwrappableAction) as Returned;
  };
};

export default useDispatchWithUnwrap;
