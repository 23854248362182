import { WebhookEventSortingType } from 'api/Webhooks/WebhookEventsApi';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT = 10;

export interface WebhookEndpointEventsPaginationParams {
  webhookId: string;
  search?: string;
  sortingType: WebhookEventSortingType;
}

const webhookEndpointEventsPagination = Pagination<ReduxState, string, WebhookEndpointEventsPaginationParams>(
  ['webhookEndpointDetails', 'webhookEndpointEventsTable'],
  WEBHOOK_ENDPOINT_EVENTS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),

);

export default webhookEndpointEventsPagination;
