import React, { useState } from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import DetailsCard from './DetailsCard';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import EditFormDataPopup from 'components/LoanOriginationSystem/EditFormDataPopup';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { DetailsCardProps } from './DetailsCard/DetailsCard';

export interface ExistingProfileProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
> extends Omit<DetailsCardProps<VariableConfigurationType, CardType>, 'onEditClick'> {
  onEditProfileData: (data: FormLayoutData) => Promise<void>;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  calculations?: CalculationType[] | null;
}

const ExistingProfile = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  data,
  title,
  cards,
  onEditProfileData,
  formatVariableConfigurationDisplayTitle,
  editButtonName,
  hideCloseButton,
  titleIcons,
  calculations,
  onCloseButtonClick,
  renderActions,
}: ExistingProfileProps<VariableConfigurationType, CardType, CalculationType>) => {
  const [isProfileUpdateInProgress, useBlockingProfileUpdateRequest] = useBlockingRequest();
  const [displayEditPopup, setDisplayEditPopup] = useState(false);

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, data);

  const validateExistingProfileData = useConfigurableFormValidation(fields);

  const handleEditProfileData = useBlockingProfileUpdateRequest(async (formData: FormLayoutData) => {
    await onEditProfileData(formData);

    setDisplayEditPopup(false);
  });

  return (
    <>
      <DetailsCard
        title={title}
        data={data}
        cards={cards}
        hideCloseButton={hideCloseButton}
        formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
        onEditClick={() => setDisplayEditPopup(true)}
        editButtonName={editButtonName || 'Edit Profile'}
        titleIcons={titleIcons}
        onCloseButtonClick={onCloseButtonClick}
        displayFieldsAttributes={displayFieldsAttributes}
        displaySkeleton={!displayFieldsAttributes}
      />
      {displayEditPopup && (
        <EditFormDataPopup
          usePortal
          formatDisplayTitle={formatVariableConfigurationDisplayTitle}
          initialData={data}
          cards={cards}
          title={title}
          titleIcons={titleIcons}
          onPopupClose={() => setDisplayEditPopup(false)}
          onSubmit={handleEditProfileData}
          isUpdatingInProgress={isProfileUpdateInProgress}
          calculations={calculations}
        />
      )}
      {renderActions?.(!cards, !validateExistingProfileData(data, displayFieldsAttributes))}
    </>
  );
};

export default ExistingProfile;
