import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import {
  DecisionListFilters,
  DecisionsSortingFieldType,
  DecisionsState,
} from 'Decisions/DecisionsStore';
import { AnyAction, isPending } from '@reduxjs/toolkit';
import { DecisionActionTypes } from 'Decisions/actionTypes';
import isPathMatches from 'utils/isPathMatches';

interface DecisionListFiltersToSave {
  filters: Pick<
    DecisionListFilters,
    'decisionResults' | 'decisionSource' | 'strategyStatus' | 'strategyName' | 'decisionDate'
    >;
}

type DecisionListFieldsToSave = Pick<DecisionsState, 'selectedMembers'> & DecisionListFiltersToSave;

const getFiltersToSave = (state: ReduxState) => {
  const { filters, selectedMembers } = state.decisions;

  return {
    selectedMembers,
    filters: pick(filters, [
      'decisionResults',
      'decisionSource',
      'strategyStatus',
      'strategyName',
      'decisionDate',
    ]),
  };
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.decisions;

  return sortingType;
};

const saveTriggerActions = [
  (action: AnyAction) =>
    isPending(action) && action.type.includes(DecisionActionTypes.RequestDecisions),
];

const isDecisionsPage = isPathMatches('/decisions');
const isApplicationDecisionsPage = isPathMatches('/los/applications/:id');

const decisionsFilterAndSortingSaver = FilterAndSortingSaver<DecisionListFieldsToSave, DecisionsSortingFieldType>({
  key: [
    ['decisions', isDecisionsPage],
    ['applicationDecisions', isApplicationDecisionsPage],
  ],
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: DecisionActionTypes.ResetDecisionsState,
  filtersSwitchActionType: DecisionActionTypes.ResetDecisionsState,
});

export default decisionsFilterAndSortingSaver;
