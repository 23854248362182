import { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

const SECONDS_IN_MINUTE = 60;

const calculateTotalSeconds = ({ minutes, seconds }: { minutes: number, seconds: number }) =>
  minutes * SECONDS_IN_MINUTE + seconds;

interface TimerOptions {
  autoStart?: boolean;
}

const useTimer = (timeoutSeconds: number, options: TimerOptions = {}) => {
  const {
    autoStart = true,
  } = options;

  const stopwatch = useStopwatch({
    autoStart,
  });



  useEffect(() => {
    if (stopwatch.isRunning && calculateTotalSeconds(stopwatch) >= timeoutSeconds) {
      stopwatch.pause();
    }
  }, [stopwatch.minutes, stopwatch.seconds]);

  return {
    isRunning: stopwatch.isRunning,
    seconds: timeoutSeconds - calculateTotalSeconds(stopwatch),
    restart: () => {
      stopwatch.reset();
      stopwatch.start();
    },
  };
};

export default useTimer;
