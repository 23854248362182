import Fetcher from 'middlewares/Fetcher';
import { ProductCalculation, ProductCalculationsApi } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import {
  UpdateProductCalculationAction,
  updateProductCalculationSuccess,
  updateProductCalculationFailure,
} from './ActionCreator';
import { ProductCalculationsType } from './ActionTypes';

const UpdateProductCalculationMiddleware = (api: ProductCalculationsApi) =>
  Fetcher<ProductCalculation, UpdateProductCalculationAction>(
    ProductCalculationsType.UpdateProductCalculation,
    updateProductCalculationSuccess,
    updateProductCalculationFailure,
    (action) => {
      return api.update(action.payload.calculationId, {
        code: action.payload.code,
        variableId: action.payload.variableId,
      });
    },
  );

export default UpdateProductCalculationMiddleware;
