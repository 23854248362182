import { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getIdNumberVariableNameByType } from 'LoanOriginationSystemOrganization/Utils';

export interface BorrowerMappingsItem {
  label: string;
  key: string;
  isDateOfBirth?: boolean;
  isAddress?: boolean;
  isIdNumber?: boolean;
  isPhoneNumber?: boolean;
  borrowerType?: BorrowerType;
}

export const getBorrowerMappings = (
  borrowerDefaultVariable: BorrowerDefaultVariableType,
  type: BorrowerType,
): BorrowerMappingsItem[] => {
  return [
    { label: 'Email', key: borrowerDefaultVariable.Email },
    { label: 'Phone', key: borrowerDefaultVariable.PhoneNumber, isPhoneNumber: true },
    { label: 'ID Number', key: getIdNumberVariableNameByType(type, borrowerDefaultVariable), isIdNumber: true },
    {
      label: 'Date Of Birth',
      key: borrowerDefaultVariable.DateOfBirth,
      isDateOfBirth: true,
      borrowerType: BorrowerType.Person,
    },
    { label: 'Address', key: borrowerDefaultVariable.Street, isAddress: true },
  ].filter((mapping) => !mapping.borrowerType || mapping.borrowerType === type);
};
