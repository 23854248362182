import { AnyAction } from 'redux';
import { DecisionStrategyUpdatesActions } from './ActionTypes';
import { DecisionStrategyUpdatesState, DecisionStrategyUpdatesSortingField } from './Types';
import { decisionStrategyUpdatesPagination, STRATEGY_UPDATES_PER_PAGE_DEFAULT } from 'pages/Strategy/Pagination';
import { GetDecisionStrategyUpdatesSuccessAction, SetSortingTypeAction, ResetStateAction } from './ActionCreator';
import decisionStrategyUpdatesSortingSaver from './SortingSaver';

const getInitialState = (): DecisionStrategyUpdatesState => ({
  strategiesById: {},
  sortingType: decisionStrategyUpdatesSortingSaver.getSavedSorting() || {
    field: DecisionStrategyUpdatesSortingField.UpdatedAt,
    ascending: false,
  },
});

type DecisionStrategyUpdatesAction = GetDecisionStrategyUpdatesSuccessAction | SetSortingTypeAction | ResetStateAction;

type DecisionStrategyUpdatesType = (
  state: DecisionStrategyUpdatesState,
  action: AnyAction,
) => DecisionStrategyUpdatesState;

const decisionStrategyUpdatesReducer = (
  state = getInitialState(),
  action: DecisionStrategyUpdatesAction,
): DecisionStrategyUpdatesState => {
  switch (action.type) {
    case DecisionStrategyUpdatesActions.GetUpdatesDataSuccess: {
      const { strategyId, items, total } = action.payload;

      return {
        ...state,
        strategiesById: {
          ...state.strategiesById,
          [strategyId]: {
            items,
            itemsTotal: total,
            page: 1,
            itemsPerPage: STRATEGY_UPDATES_PER_PAGE_DEFAULT,
            error: '',
          },
        },
      };
    }
    case DecisionStrategyUpdatesActions.SetSortingType: {
      return { ...state, sortingType: action.payload };
    }
    case DecisionStrategyUpdatesActions.ResetState: {
      return getInitialState();
    }
    default:
      return state;
  }
};

export default decisionStrategyUpdatesPagination.wrapReducer<DecisionStrategyUpdatesState>(
  decisionStrategyUpdatesReducer as DecisionStrategyUpdatesType,
);
