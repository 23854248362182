import { WebhookEndpointSortingField, WebhookEndpointSortingType } from 'api/Webhooks/WebhookEndpointsApi';
import { createAction, createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import webhookEndpointsPagination, { WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT } from './Pagination';
import { ItemsStateWithPagination } from 'pagination';
import { getWebhookEndpoints } from 'WebhookEndpoints/Thunks';
import { WebhookEndpointsTableActionType } from './WebhookEndpointsTableActionType';

export interface WebhookEndpointsTableState extends ItemsStateWithPagination<string> {
  sortingType: WebhookEndpointSortingType;
}

const initialState: WebhookEndpointsTableState = {
  items: [],
  page: 1,
  itemsPerPage: WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    field: WebhookEndpointSortingField.UpdatedAt,
    ascending: false,
  },
};

export const changeSortingType = createAction<WebhookEndpointSortingType>(WebhookEndpointsTableActionType.ChangeSortingType);
export const resetState = createAction(WebhookEndpointsTableActionType.ResetState);

const webhookEndpointsTableReducer = createReducer(initialState, (builder) => {
  builder.addCase(getWebhookEndpoints.fulfilled, (state, action) => {
    state.error = '';
    state.page = 1;
    state.itemsPerPage = WEBHOOK_ENDPOINTS_PER_PAGE_DEFAULT;
    state.items = action.payload.items.map((item) => item.id);
    state.itemsTotal = action.payload.total;
  });

  builder.addCase(changeSortingType, (state, action) => {
    state.sortingType = action.payload;
  });
});

export default webhookEndpointsPagination.wrapReducer(
  withStateReset(webhookEndpointsTableReducer, WebhookEndpointsTableActionType.ResetState, () => ({ ...initialState })),
);
