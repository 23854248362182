import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { VariableValue } from 'api/Types';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';

const DEFAULT_BORROWER_PREFIX = 'Borrower ';
export const BLANK_BORROWER_FULL_NAME = 'blank';

export const getBorrowerFullName = (
  borrowerType: BorrowerType,
  variables: Record<string, VariableValue>,
  borrowerDefaultVariables = BorrowerDefaultVariable,
) => {
  const borrowerFullName = borrowerType === BorrowerType.Person
    ? `${variables[borrowerDefaultVariables.FirstName] || ''} ${variables[borrowerDefaultVariables.LastName] || ''}`
    : (variables[borrowerDefaultVariables.CompanyName] as string || '');

  return borrowerFullName.trim();
};

export const formatDisplayTitle = (systemName: string, title: string) => {
  return title.replace(new RegExp(DEFAULT_BORROWER_PREFIX, 'i'), '');
};
