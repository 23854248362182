import { Middleware } from 'redux';

import { ACTION_TYPE_CREATE_VARIABLE_REQUEST } from './ActionTypes';
import { createVariableFailure, CreateVariableRequestAction, createVariableSuccess } from './VariablesActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { VariablesApi } from 'api/Core/VariablesApi';
import { VariableItem } from 'api/VariablesType';

const CreateVariableMiddleware: (api: VariablesApi) => Middleware = (api) =>
  Fetcher<VariableItem, CreateVariableRequestAction>(
    ACTION_TYPE_CREATE_VARIABLE_REQUEST,
    createVariableSuccess,
    createVariableFailure,
    async ({ payload }) => {
      return api.createVariable(payload);
    },
  );

export default CreateVariableMiddleware;
