import React, { FC } from 'react';
import clsx from 'clsx';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import RowActions, { RowAction } from 'components/RowActions/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import styles from './DetailsHeader.module.scss';

export interface DetailsHeaderProps {
  title?: string | null;
  updaterInfo?: {
    updatedBy?: UserInfo | null;
    updatedAt: Date;
  } | null;
  actions: RowAction[] | null;
  className?: string;
  tabs: TabSwitchOption[];
  selectedTabId: string;
  onSelectTab: (tab: TabSwitchOption) => void;
  subtitleTags?: React.ReactNode;
  renderAfterRowActions?: () => React.ReactNode;
}

const DetailsHeader: FC<DetailsHeaderProps> = ({
  title,
  className,
  updaterInfo,
  actions,
  tabs,
  selectedTabId,
  onSelectTab,
  subtitleTags,
  renderAfterRowActions,
}) => {

  const renderTitle = () => {
    if (!title) {
      return <SkeletonRectangle width="40%" height="44px" color="primary6" />;
    }

    return <h2 className={styles.title}>{title}</h2>;
  };

  const renderActions = () => {
    if (!actions) {
      return <SkeletonRectangle marginLeft="14px" marginRight="22px" width="24px" height="24px" color="primary6" />;
    }

    return (
      <RowActions
        className={styles.actionsButton}
        actions={actions}
      />
    );
  };

  const renderUpdaterInformation = () => {
    if (!updaterInfo) {
      return (
        <>
          <SkeletonCircle marginRight="8px" width="24px" height="24px" color="primary6" />
          <SkeletonRectangle width="241px" height="20px" color="primary6" />
        </>
      );
    }

    return (
      <>
        <WithSystemApiUserAvatar className={styles.avatar} size={8.57} user={updaterInfo.updatedBy} />
        <p>Updated {formatDate(updaterInfo.updatedAt, DateTimeFormat.Short)}</p>
      </>
    );
  };

  const renderSections = () => {
    return (
      <>
        <div className={styles.titleSection}>
          {renderTitle()}
          {renderActions()}
          {renderAfterRowActions?.()}
        </div>
        <div className={styles.subtitleSection}>
          {renderUpdaterInformation()}
          {subtitleTags && <div className={styles.subtitleTags}>{subtitleTags}</div>}
        </div>
      </>
    );
  };

  return (
    <div className={clsx(styles.detailsHeader, className)}>
      {renderSections()}
      <TabSwitch tabs={tabs} selectedTabId={selectedTabId} onSelect={onSelectTab} />
    </div>
  );
};

export default DetailsHeader;
