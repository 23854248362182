export enum DecisionActionTypes {
  RequestDecisions = 'requestDecisions',
  RequestDecisionListFilters = 'requestDecisionListFilters',
  OpenRunDecisionPopup = 'openRunDecisionPopup',
  CloseRunDecisionPopup = 'closeRunDecisionPopup',
  ResetFilters = 'resetFilters',
  ResetAllFilters = 'resetAllFilters',
  ResetDecisionsState = 'resetDecisionsState',
  ResetDecisionState = 'resetDecisionState',
  ToggleFiltersDecisionsPopUp = 'toggleFiltersDecisionsPopUp',
}
