import React, { useEffect, useState, FC, useMemo } from 'react';
import clsx from 'clsx';
import AceEditor, { IAceEditorProps } from 'react-ace';
import _, { debounce } from 'lodash';

import { Ace, config } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';

import { useGlobalsSearchWorker } from 'hooks/useGlobalsSearchWorker';

import QuestionIcon from 'components/QuestionIcon';
import WrapperWithTooltip from 'components/Tooltip';
import Label from 'components/Label';

import { PlayIcon } from 'static/images';

import './overridings.scss';

import styles from './BaseCodeEditor.module.scss';

export interface BaseCodeEditorProps {
  initialValue: string;
  disabled?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  placeholder?: string;
  options?: IAceEditorProps['setOptions'];
  onTestCodeButtonClick?: () => void;
  editorClassName?: string;
  editorContainerClassName?: string;
  labelClassName?: string;
  testCodeButtonClassName?: string;
  fontSize?: number;
  error?: string;
  tooltip?: React.ReactNode;
  displayError?: boolean;
  displayTestCodeButton?: boolean;
  testCodeButtonDisabled?: boolean;
  onSyntaxValidationComplete?: (errors: string[]) => void;
  variablesSuggestions?: string[];
  functionsSuggestions?: string[];
  testCodeTitle?: string;
  globalsToHighlight?: string[];
  testCodeButtonTooltip?: React.ReactNode;
  previewMode?: boolean;
  validateCodeSyntax?: (code: string) => Promise<string[]>;
  singleLineEditor?: boolean;
  required?: boolean;
  labelTooltip?: React.ReactNode;
}

const DEFAULT_OPTIONS: IAceEditorProps['setOptions'] = {
  tabSize: 2,
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  maxLines: 12,
  minLines: 2,
  useWorker: false,
  highlightGutterLine: false,
  autoScrollEditorIntoView: true,
  printMargin: false,
  wrap: true,
  indentedSoftWrap: false,
};

const DEFAULT_SIDE_PADDING = 20;
const DEFAULT_TOP_MARGIN = 16;
const DEFAULT_BOTTOM_MARGIN = 20;
const DEFAULT_RIGHT_MARGIN = 10;
const DEFAULT_LEFT_MARGIN = 10;
const DEFAULT_FONT_SIZE = 16;
const DEFAULT_TEST_CODE_TITLE = 'Test Code';

config.set('loadWorkerFromBlob', false);

const ENTER_PLUS_SHIFT_KEY = 'Enter|Shift-Enter';

const GLOBALS_HIGHLIGHTING_DELAY = 200;
const SYNTAX_VALIDATION_DELAY = 150;

const BaseCodeEditor: FC<BaseCodeEditorProps> = React.memo(
  ({
    initialValue,
    label,
    className,
    options,
    placeholder,
    onChange,
    onTestCodeButtonClick,
    editorClassName,
    editorContainerClassName,
    labelClassName,
    testCodeButtonClassName,
    disabled,
    fontSize = DEFAULT_FONT_SIZE,
    error,
    onBlur,
    onFocus,
    tooltip,
    displayTestCodeButton = true,
    displayError = true,
    onSyntaxValidationComplete,
    testCodeButtonDisabled,
    variablesSuggestions,
    functionsSuggestions,
    testCodeTitle = DEFAULT_TEST_CODE_TITLE,
    globalsToHighlight: componentGlobalsToHighlight,
    testCodeButtonTooltip,
    previewMode,
    validateCodeSyntax,
    singleLineEditor,
    required,
    labelTooltip,
  }) => {
    const globalsSearchWorker = useGlobalsSearchWorker();
    const [markers, setMarkers] = useState<Array<any>>([]);
    const [editor, setEditor] = useState<Ace.Editor | null>(null);

    useEffect(() => {
      if (editor && variablesSuggestions) {
        const uniqVariablesSuggestions = _.uniq(variablesSuggestions);
        const uniqFunctionsSuggestions = _.uniq(functionsSuggestions);

        editor.completers = [
          {
            getCompletions(
              aceEditor: Ace.Editor,
              session: Ace.EditSession,
              position: Ace.Point,
              prefix: string,
              callback: Ace.CompleterCallback,
            ) {
              callback(null, [
                ...uniqVariablesSuggestions.map((variableSuggestion) => ({
                  caption: variableSuggestion,
                  value: variableSuggestion,
                  score: 0,
                  meta: `Variable`,
                })),
                ...uniqFunctionsSuggestions.map((functionSuggestion) => ({
                  caption: functionSuggestion,
                  value: functionSuggestion,
                  score: 0,
                  meta: 'Function',
                })),
              ]);
            },
          },
        ];
      }
    }, [editor, variablesSuggestions, functionsSuggestions]);

    useEffect(() => {
      const handleAnnotationsChange = () => {
        if (!editor || !onSyntaxValidationComplete || validateCodeSyntax) {
          return;
        }

        const annotations = editor.getSession().getAnnotations();
        const errors = annotations
          .filter((annotation) => annotation.type === 'error')
          .map((annotation) => annotation.text);

        onSyntaxValidationComplete(errors);
      };

      // @ts-ignore
      editor?.getSession().on('changeAnnotation', handleAnnotationsChange);

      return () => {
        editor?.getSession().removeEventListener('changeAnnotations', handleAnnotationsChange);
      };
    }, [editor, onSyntaxValidationComplete, validateCodeSyntax]);

    useEffect(() => {
      const handlePaste = (event: { text: string }) => {
        if (!singleLineEditor) {
          return;
        }

        event.text = event.text.replace(/[\r\n]+/g, ' ');
      };

      editor?.on('paste', handlePaste);

      return () => {
        editor?.removeEventListener('paste', handlePaste);
      };
    }, [editor, singleLineEditor]);

    useEffect(() => {
      const handleBlur = () => {
        onBlur?.();
      };

      const handleFocus = () => {
        onFocus?.();
      };

      editor?.on('blur', handleBlur);
      editor?.on('focus', handleFocus);

      return () => {
        editor?.removeEventListener('blur', handleBlur);
        editor?.removeEventListener('focus', handleFocus);
      };
    }, [editor, onBlur, onFocus]);

    useEffect(() => {
      if (!editor) {
        return;
      }

      debouncedSearchGlobals(editor.getValue(), componentGlobalsToHighlight);
    }, [componentGlobalsToHighlight, editor]);

    const debouncedSearchGlobals = useMemo(() => {
      return debounce(async (code: string, globalsToHighlight: string[] | undefined) => {
        if (!globalsSearchWorker) {
          return;
        }

        const globals = await globalsSearchWorker.searchGlobals(code);

        const markedGlobals = globalsToHighlight ? globals.filter(({ name }) => globalsToHighlight.includes(name)) : [];

        const newMarkers = markedGlobals.flatMap(({ nodes }) => {
          return nodes.map((node) => ({
            startRow: node.startPosition.line - 1,
            startCol: node.startPosition.column,
            endRow: node.endPosition.line - 1,
            endCol: node.endPosition.column,
            type: 'text',
            className: styles.variableHighlight,
          }));
        });

        setMarkers(newMarkers);
      }, GLOBALS_HIGHLIGHTING_DELAY);
    }, [globalsSearchWorker]);

    const debouncedValidateCodeSyntax = useMemo(() => {
      return debounce(async (code: string) => {
        const errors = await validateCodeSyntax?.(code);

        onSyntaxValidationComplete?.(errors || []);
      }, SYNTAX_VALIDATION_DELAY);
    }, [validateCodeSyntax, onSyntaxValidationComplete]);

    const handleChange = (value: string) => {
      debouncedSearchGlobals(value, componentGlobalsToHighlight);
      debouncedValidateCodeSyntax(value);

      onChange?.(value);
    };

    const handleLoad = (loadedEditor: Ace.Editor) => {
      setEditor(loadedEditor);

      loadedEditor.completers = [];

      if (!previewMode) {
        loadedEditor.renderer.setPadding(DEFAULT_SIDE_PADDING);
        loadedEditor.renderer.setScrollMargin(
          DEFAULT_TOP_MARGIN,
          DEFAULT_BOTTOM_MARGIN,
          DEFAULT_LEFT_MARGIN,
          DEFAULT_RIGHT_MARGIN,
        );
      }
    };

    const handleTestCodeButtonClick = () => {
      if (testCodeButtonDisabled || error) {
        return;
      }

      onTestCodeButtonClick?.();
    };

    return (
      <div className={className}>
        {(displayTestCodeButton || label) && (
          <div className={styles.header}>
            <div className={styles.labelWithTooltipContainer}>
              {label && <Label
                required={required}
                className={labelClassName}
                tooltip={labelTooltip}
              >
                {label}
              </Label>}
              {tooltip && <QuestionIcon size={16} className={styles.questionIcon} tooltip={tooltip} />}
            </div>
            {displayTestCodeButton && (
              <WrapperWithTooltip tooltip={testCodeButtonTooltip}>
                <div
                  className={clsx(
                    styles.testCodeButton,
                    (error || testCodeButtonDisabled) && styles.disabledTestCodeButton,
                    testCodeButtonClassName,
                  )}
                  onClick={handleTestCodeButtonClick}
                >
                  <PlayIcon />
                  {testCodeTitle}
                </div>
              </WrapperWithTooltip>
            )}
          </div>
        )}
        <div
          className={clsx(
            styles.aceEditorContainer,
            displayError && error && styles.withErrorCodeEditorContainer,
            previewMode && styles.aceEditorContainerPreviewMode,
            editorContainerClassName,
          )}
        >
          <AceEditor
            className={clsx(
              styles.aceEditor,
              disabled && styles.disabledAceEditor,
              previewMode && styles.aceEditorPreviewMode,
              editorClassName,
            )}
            placeholder={placeholder}
            mode="javascript"
            theme="tomorrow"
            name="aceEditor"
            defaultValue={initialValue}
            setOptions={{
              ...DEFAULT_OPTIONS,
              ...(!validateCodeSyntax ? { useWorker: true } : {}),
              ...(previewMode ? { showGutter: false } : {}),
              ...(options || {}),
            }}
            onLoad={handleLoad}
            onChange={handleChange}
            readOnly={disabled || previewMode}
            fontSize={fontSize}
            showPrintMargin
            showGutter
            highlightActiveLine={false}
            markers={markers}
            commands={
              singleLineEditor
                ? [
                    {
                      name: ENTER_PLUS_SHIFT_KEY,
                      bindKey: {
                        win: ENTER_PLUS_SHIFT_KEY,
                        mac: ENTER_PLUS_SHIFT_KEY,
                      },
                      exec: 'null',
                    },
                  ]
                : []
            }
          />
        </div>
        {displayError && error && <div className={styles.error}>{error}</div>}
      </div>
    );
  },
);

export default BaseCodeEditor;
