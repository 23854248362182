import {
  SetSearchInputValueAction,
  FilterProductsByMembersAction,
  ToggleFiltersPopupAction,
  ChangePopupFiltersAction,
  SetShowArchivedAction,
  ResetAllProductsFilters,
} from './ActionCreator';
import { LoanOriginationSystemProductsFiltersActionTypes } from './ActionTypes';
import { LoanOriginationSystemProductsFiltersState } from './Types';

export const initialState: LoanOriginationSystemProductsFiltersState = {
  search: '',
  statuses: [],
  showArchived: false,
  selectedMembers: [],
  borrowerType: null,
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
  dueUpdatedDateRange: {
    from: null,
    to: null,
  },
  isPopUpVisible: false,
};

type LoanOriginationSystemProductsFiltersActions =
  | SetSearchInputValueAction
  | SetShowArchivedAction
  | FilterProductsByMembersAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | ResetAllProductsFilters;

const loanOriginationSystemProductsFiltersReducer = (
  state: LoanOriginationSystemProductsFiltersState = initialState,
  action: LoanOriginationSystemProductsFiltersActions,
): LoanOriginationSystemProductsFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.SetShowArchived: {
      return {
        ...state,
        showArchived: action.payload.showArchived,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.FilterProductsByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopUpVisible: !state.isPopUpVisible,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        isPopUpVisible: false,
        statuses: action.payload.statuses,
        borrowerType: action.payload.borrowerType,
        dueCreatedDateRange: action.payload.dueCreatedDateRange,
        dueUpdatedDateRange: action.payload.dueUpdatedDateRange,
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemProductsFiltersReducer;
