import React, { FC } from 'react';
import styles from './OrganizationList.module.scss';
import { OrganizationInfo } from 'api/Core/OrganizationApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import clsx from 'clsx';
import { SkeletonCircle } from 'components/Skeleton';
import SkeletonText from 'components/Skeleton/SkeletonText';
import { HandleKeyDown } from 'utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'common/KeyEventEnum';
import { OrganizationDefaultIcon } from 'static/images';
import UserStatusTag from 'components/UserStatusTag/UserStatusTag';
import QuestionIcon from 'components/QuestionIcon';
import UserAvatar from 'components/UserAvatar';

interface OrganizationListProps {
  organizations: OrganizationInfo[] | null;
  onSelect: (organization: OrganizationInfo) => void;
}

const SKELETON_ORGANIZATIONS = Array.from({ length: 2 }, () => null);

const OrganizationList: FC<OrganizationListProps> = ({ organizations, onSelect }) => {
  const getStatusTooltipText = (organization: OrganizationInfo) => {
    return !organization.isUserActive
      ? 'Your account is not active'
      : 'Please accept your emailed invitation to access this account';
  };

  const renderLine = (organization: OrganizationInfo | null, index: number) => {
    const isActive = organization && organization.isUserActive && organization.userId;

    const isEnabled = !!organization && isActive;

    const handleClick = () => isActive && onSelect(organization!);

    return (
      <div
        className={clsx(styles.line, !isEnabled && styles.disabled)}
        key={organization?.id || index}
        onClick={handleClick}
        onKeyDown={HandleKeyDown(KeyEventEnum.Enter, handleClick)}
        tabIndex={index + 1}
      >
        {organization && organization.imageId && (
          <UserAvatar
            firstName=""
            lastName=""
            size="small"
            imageUrl={organization.imageId}
            className={clsx(styles.logo, styles.centred)}
          />
        )}
        {organization && !organization.imageId && (
          <div className={styles.organizationDefaultIcon}>
            <OrganizationDefaultIcon />
          </div>
        )}
        {!organization && <SkeletonCircle className={styles.logo} width="36px" height="36px" color="primary10" />}
        <div
          className={clsx(organization && !organization.lastActiveAt && styles.centred, !isActive && styles.isInactive)}
        >
          <div className={styles.title}>
            {organization && (
              <>
                <div className={styles.name}>{organization.name}</div>
                <div className={styles.status}>
                  <UserStatusTag isActive={organization.isUserActive} userId={organization.userId} />
                  {(!organization.isUserActive || !organization.userId) && <QuestionIcon tooltip={getStatusTooltipText(organization)} className={styles.statusTooltip} />}
                </div>
              </>
            )}
          </div>
          {organization && organization.lastActiveAt && (
            <div className={styles.lastActiveDate}>
              Last Active {formatDate(new Date(organization.lastActiveAt), DateTimeFormat.Long)}
            </div>
          )}
          {!organization && <SkeletonText width="108px" height="16px" lineHeight="20px" color="primary20" />}
          {!organization && <SkeletonText width="220px" height="12px" lineHeight="16px" color="primary6" />}
        </div>
      </div>
    );
  };

  return (
    <div>
      {organizations?.map(renderLine)}
      {!organizations && SKELETON_ORGANIZATIONS.map(renderLine)}
    </div>
  );
};

export default OrganizationList;
