import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

const getTabId = (state: ReduxState, tabId: string) => tabId;
const getCardsById = (state: ReduxState) => state.applicationDataTabCards.cardsById;
const getApplicationDataTabVariablesConfigurationsById = (state: ReduxState) => state.applicationDataTabVariableConfigurations.configurationsById;
const getIsCardsLoaded = (state: ReduxState, tabId: string) => state.applicationDataTabCards.cardsLoadedByTabId[tabId];

export const getApplicationDataTabCardsSelector = createSelector(
  [getTabId, getCardsById, getIsCardsLoaded, getApplicationDataTabVariablesConfigurationsById],
  (tabId, cardsById, isCardsLoaded, configurationsById) => {
    const cards = Object.values(cardsById).filter((card) => card.tabId === tabId);

    if (!cards.length && !isCardsLoaded) {
      return null;
    }

    return cards.map((card) => ({
      ...card,
      fields: card.fields.map((fieldId) => configurationsById[fieldId]),
    }));
  },
);
