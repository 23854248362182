import React, { FC } from 'react';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { CustomersImage, BriefCaseIcon } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './BorrowerIcon.module.scss';

export interface BorrowerIconProps {
  className?: string;
  borrowerType: BorrowerType;
}

const BorrowerIcon: FC<BorrowerIconProps> = ({ className, borrowerType }) => {
  const Icon = borrowerType === BorrowerType.Person ? CustomersImage : BriefCaseIcon;

  return (
    <WrapperWithTooltip tooltip={capitalize(borrowerType)}>
      <Icon className={clsx(styles.borrowerIcon, className)} />
    </WrapperWithTooltip>
  );
};

export default BorrowerIcon;
