import { LoanOriginationSystemProductsFiltersActionTypes } from './ActionTypes';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { AnyAction } from 'redux';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export interface FiltersResult {
  statuses: ProductStatus[];
  borrowerType: BorrowerType | null;
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

export interface SetSearchInputValueAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue;
  payload: string;
}

export interface SetShowArchivedAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.SetShowArchived;
  payload: {
    showArchived: boolean;
  };
}

export interface FilterProductsByMembersAction extends AnyAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.FilterProductsByMembers;
  payload: {
    members: UserInfo[];
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}
export interface ResetAllProductsFilters extends AnyAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.ResetAllFilters;
}

export const setSearchInputValue = (search: string): SetSearchInputValueAction => ({
  type: LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue,
  payload: search,
});

export const setShowArchived = (showArchived: boolean): SetShowArchivedAction => ({
  type: LoanOriginationSystemProductsFiltersActionTypes.SetShowArchived,
  payload: {
    showArchived,
  },
});

export const filterProductsByMembers = (members: UserInfo[]): FilterProductsByMembersAction => {
  return {
    type: LoanOriginationSystemProductsFiltersActionTypes.FilterProductsByMembers,
    payload: {
      members,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemProductsFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemProductsFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemProductsFiltersActionTypes.ChangePopupFilters,
    payload: {
      statuses: [],
      borrowerType: null,
      dueCreatedDateRange: {
        from: null,
        to: null,
      },
      dueUpdatedDateRange: {
        from: null,
        to: null,
      },
    },
  };
};

export const resetAllProductsFilters = (): ResetAllProductsFilters => {
  return {
    type: LoanOriginationSystemProductsFiltersActionTypes.ResetAllFilters,
  };
};
