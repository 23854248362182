import formatDisplayId from 'utils/userIdFormat';
import formatValueByDataType from 'utils/formatValueByDataType';
import { getVariableAccessPermissions } from 'Variables/utils';
import { StandardVariables } from 'Variables/VariablesTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';

const getSearchMatchingField = (
  getEntityFieldValue: (path: string) => string | string[],
  searchInputValue: string,
  standardVariables: StandardVariables | null,
  userPermissionGroupId: PermissionGroupId,
) => (path: string): { name: string; value: string } | null => {
  const fieldValue = getEntityFieldValue(path);

  if (typeof fieldValue === 'string') {
    if (path === 'displayId') {
      return {
        name: 'Application ID',
        value: formatDisplayId(fieldValue),
      };
    }

    const fieldName = path.split('.').pop()!;
    const variable = standardVariables?.[fieldName];

    if (!variable) {
      return null;
    }

    return {
      name: variable.displayName,
      value: formatValueByDataType(fieldValue, variable, {
        accessPermissions: getVariableAccessPermissions(variable, userPermissionGroupId),
      }),
    };
  }

  if (path === 'declineReasons') {
    const value = fieldValue.find((reason) =>
      searchInputValue
        .trim()
        .split(' ')
        .some((q) => reason.toLowerCase().includes(q)),
    )!;

    return {
      name: 'Decline Reasons',
      value,
    };
  }

  return null;
};

export default getSearchMatchingField;
