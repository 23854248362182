import React from 'react';

import { Route, Switch } from 'react-router';
import Applications from 'pages/Applications';
import Application from 'pages/LoanOriginationSystem/Application';
import Intermediaries from 'pages/LoanOriginationSystem/Intermediaries';
import Borrowers from 'pages/Borrowers';
import BorrowersConfiguration from 'pages/BorrowersConfiguration';
import Labels from 'pages/LoanOriginationSystem/Labels';
import Tasks from 'pages/LoanOriginationSystem/Tasks';
import IntermediariesConfiguration from 'pages/IntermediariesConfiguration';
import ProcessAutomation from 'pages/LoanOriginationSystem/ProcessAutomation';
import Products from 'pages/Products';
import Emails from 'pages/LoanOriginationSystem/Emails';
import Product from 'pages/Product';
import FilePreviewPage from 'pages/LoanOriginationSystem/FilePreview';
import Reporting from 'pages/LoanOriginationSystem/Reporting/Reporting';
import ProductSectionAccessCheck from 'components/RouteWithPermissions/ProductSectionAccessCheck';
import { ProductSection } from 'ProductSectionAccessPermissions/Types';

export default () => {
  return (
    <Switch>
      <Route path="/los/applications/:applicationDisplayId">
        <ProductSectionAccessCheck
          pageName="Applications"
          productSection={ProductSection.LoanOriginationSystemApplications}
        >
          <Application />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/applications">
        <ProductSectionAccessCheck
          pageName="Applications"
          productSection={ProductSection.LoanOriginationSystemApplications}
        >
          <Applications />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/borrowers" render={() => <Borrowers />}>
        <ProductSectionAccessCheck pageName="Borrowers" productSection={ProductSection.LoanOriginationSystemBorrowers}>
          <Borrowers />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/intermediaries">
        <ProductSectionAccessCheck
          pageName="Intermediaries"
          productSection={ProductSection.LoanOriginationSystemIntermediaries}
        >
          <Intermediaries />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/tasks">
        <ProductSectionAccessCheck pageName="Tasks" productSection={ProductSection.LoanOriginationSystemTasks}>
          <Tasks />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/reporting">
        <ProductSectionAccessCheck pageName="Reporting" productSection={ProductSection.LoanOriginationSystemReporting}>
          <Reporting />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/emails">
        <ProductSectionAccessCheck pageName="Emails" productSection={ProductSection.LoanOriginationSystemEmails}>
          <Emails />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/configuration/borrowers">
        <ProductSectionAccessCheck
          pageName="Borrowers"
          productSection={ProductSection.LoanOriginationSystemConfiguration}
        >
          <BorrowersConfiguration />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/settings/applicationlabels">
        <ProductSectionAccessCheck pageName="Labels" productSection={ProductSection.LoanOriginationSystemConfiguration}>
          <Labels />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/configuration/intermediaries">
        <ProductSectionAccessCheck
          pageName="Intermediaries"
          productSection={ProductSection.LoanOriginationSystemConfiguration}
        >
          <IntermediariesConfiguration />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/configuration/products/:productId/:step?">
        <ProductSectionAccessCheck
          pageName="Products"
          productSection={ProductSection.LoanOriginationSystemConfiguration}
        >
          <Product />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/configuration/products">
        <ProductSectionAccessCheck
          pageName="Products"
          productSection={ProductSection.LoanOriginationSystemConfiguration}
        >
          <Products />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/configuration/processautomation">
        <ProductSectionAccessCheck
          pageName="Process Automation"
          productSection={ProductSection.LoanOriginationSystemConfiguration}
        >
          <ProcessAutomation />
        </ProductSectionAccessCheck>
      </Route>
      <Route path="/los/filepreview/:fileType/:fileExtension/:fileId/:filename">
        {/* TODO find out which section this belongs to */}
        <FilePreviewPage />
      </Route>
    </Switch>
  );
};
