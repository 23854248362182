import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import { OrganizationUsersSortingType } from 'api/Core/UserApi';
import { PermissionGroupId } from 'PermissionGroups/Types';

export const USERS_PER_PAGE_DEFAULT = 15;

export interface UsersPaginationParams {
  searchInputValue: string;
  sortingType: OrganizationUsersSortingType;
  permissionGroupId: PermissionGroupId | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
  showInactive: boolean;
}

const usersPagination = Pagination<ReduxState, OrganizationMembership, UsersPaginationParams>(
  'organizationUsers',
  USERS_PER_PAGE_DEFAULT,
);

export default usersPagination;
