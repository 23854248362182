import React from 'react';
import { useHistory } from 'react-router';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { ReduxState } from 'types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { LoanOriginationSystemIntermediariesActionType } from 'LoanOriginationSystemIntermediariesPage/ActionTypes';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import {
  deleteIntermediary,
  setIntermediaryToDelete,
} from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import NavigationLinkId from 'enums/NavigationLinkId';
import styles from './Intermediaries.module.scss';
import IntermediariesDashboard from 'components/LoanOriginationSystem/IntermediariesDashboard';
import CreateIntermediary from 'components/LoanOriginationSystem/CreateIntermediary';
import IntermediaryDetails from 'components/LoanOriginationSystem/IntermediaryDetails';
import ConfirmPopup from 'components/ConfirmPopup';
import IntermediariesFilter from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesFilter';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const leftNav = makeLeftNavigation(NavigationLinkId.Intermediaries, ApplicationSectionName.LoanOriginationSystem);

const Intermediaries = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();
  const history = useHistory();
  const closeContextualView = useCloseContextualView();

  const createIntermediaryFormAvailable = params.has('new');
  const editIntermediaryFormAvailable = params.has('edit');

  useStateReset(LoanOriginationSystemIntermediariesActionType.ResetState);

  const { intermediaryToDelete, isDeleteInProgress, filters } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediariesState,
  );

  const onPopupClose = () => dispatch(setIntermediaryToDelete(null));
  const handleIntermediaryDeletePopupOpen = async (intermediary: Intermediary) => {
    dispatch(setIntermediaryToDelete(intermediary));
  };

  const onDeleteIntermediary = async () => {
    if (!intermediaryToDelete) {
      return;
    }

    await dispatchRoutine(deleteIntermediary(intermediaryToDelete.id, intermediaryToDelete.variables[IntermediaryDefaultVariable.Name] as string));

    history.replace('/los/intermediaries');
  }

  const onCloseContextualView = () => {
    closeContextualView();
  };

  const renderContextualView = () => {
    if (createIntermediaryFormAvailable) {
      return (
        <CreateIntermediary onClose={onCloseContextualView} />
      );
    }

    if (editIntermediaryFormAvailable) {
      return (
        <IntermediaryDetails
          onClose={onCloseContextualView}
          id={params.get('edit')!}
        />
      );
    }

    return null;
  };

  const renderOverlay = () => {
    if (intermediaryToDelete) {
      return (
        <ConfirmPopup
          title="Delete Intermediary"
          message="Are you sure you want to remove this intermediary?"
          confirmText="Yes, Delete Intermediary"
          declineText="No, Go Back"
          onPopupClose={onPopupClose}
          onConfirmClick={onDeleteIntermediary}
          loading={isDeleteInProgress}
        />
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <IntermediariesFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      borrowerTypes={filters.borrowerTypes}
    />
  );

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <IntermediariesDashboard onDeleteIntermediaryPopupOpen={handleIntermediaryDeletePopupOpen} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Intermediaries;
