import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { BorrowerVariableConfiguration } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import {
  createBorrowerVariableConfiguration,
  updateBorrowerVariableConfigurationPosition,
  updateBorrowerVariableConfigurationRequiredAttribute,
  deleteBorrowerVariableConfiguration,
  updateConditionalDisplayRule,
} from './Thunks';
import {
  createBorrowerProfileCard,
  getBorrowerProfileCards,
  updateBorrowerProfileCard,
} from 'BorrowerProfileCards/Thunks';
import {
  deleteVariableConfiguration,
  updateVariableConfigurationStore,
} from 'LoanOriginationSystemVariablesConfiguration/Utils';

export interface BorrowerVariableConfigurationsState {
  configurationsById: Record<string, BorrowerVariableConfiguration>;
}

const initialState: BorrowerVariableConfigurationsState = {
  configurationsById: {},
};

const borrowerVariableConfigurationsReducer = createReducer<BorrowerVariableConfigurationsState>(initialState, (builder) => {
  builder.addCase(deleteBorrowerVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfiguration(state, action.payload.id);
  });

  builder.addCase(
    updateBorrowerVariableConfigurationRequiredAttribute.pending,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = action.meta.arg.required;
    },
  );

  builder.addCase(
    updateBorrowerVariableConfigurationRequiredAttribute.rejected,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = !action.meta.arg.required;
    },
  );

  builder.addMatcher(
    isFulfilled(
      createBorrowerVariableConfiguration,
      updateBorrowerVariableConfigurationPosition,
      updateConditionalDisplayRule,
    ),
    (state, action) => {
      updateVariableConfigurationStore(state, {
        [action.payload.configuration.id]: action.payload.configuration,
      });
    },
  );

  builder.addMatcher(
    isFulfilled(getBorrowerProfileCards, createBorrowerProfileCard, updateBorrowerProfileCard),
    (state, action) => {
      updateVariableConfigurationStore(state, action.payload.borrowerVariableConfigurations);
    },
  );
});

export default borrowerVariableConfigurationsReducer;
