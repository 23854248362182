import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import {
  changeContextualViewStep,
  deleteBorrower,
  selectBorrowerViewTab,
  setBorrowerToDelete,
} from 'LoanOriginationSystemBorrowers/ActionCreator';
import {
  lockBorrower,
  setBorrowerToLock,
  setBorrowerToUnlock,
  unlockBorrower,
} from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from 'LoanOriginationSystemBorrowers/ActionTypes';
import { ApplicationSectionName, ProductSection } from 'components/RouteWithPermissions/Types';
import BorrowersDashboard from 'components/LoanOriginationSystem/BorrowersDashboard';
import CreateBorrower from 'components/LoanOriginationSystem/CreateBorrower';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import EditBorrower from 'components/LoanOriginationSystem/BorrowerDetails';
import UnlockCustomerPopup from 'components/LoanOriginationSystem/CreateApplication/UnlockBorrowerPopup';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import styles from './Borrowers.module.scss';
import NavigationLinkId from 'enums/NavigationLinkId';
import BorrowersFilter from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersFilter';
import EmailView from 'components/LoanOriginationSystem/EmailView';
import { getOrganizationEmail } from 'utils/emailGetters';
import EmailNotFound from 'components/LoanOriginationSystem/EmailView/EmailNotFound';
import DeleteEmailConfirmationPopup
  from 'components/LoanOriginationSystem/DeleteEmailConfirmationPopup/DeleteEmailConfirmationPopup';
import { closeDeleteEmailPopup, deleteEmail, getEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import ContextualViewWizard from 'components/ContextualViewWizard';
import { useHistory } from 'react-router-dom';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';
import { resetBorrowerDetails } from 'LoanOriginationSystemBorrowerDetails/ActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER } from 'components/LoanOriginationSystem/BorrowerDetails/Tabs/BorrowerEmails/BorrowerEmails';
import useProductSectionAccessPermissions from 'ProductSectionAccessPermissions/useProductSectionAccessPermissions';

const leftNav = makeLeftNavigation(NavigationLinkId.Borrowers, ApplicationSectionName.LoanOriginationSystem);

const Borrowers = () => {
  const params = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const closeContextualView = useCloseContextualView();
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const { isDeleting, borrowerToDelete, filters, contextualViewStep } = useSelector((state: ReduxState) => ({
    isDeleting: state.loanOriginationSystemBorrowers.isDeleting,
    borrowerToDelete: state.loanOriginationSystemBorrowers.borrowerToDelete,
    filters: state.loanOriginationSystemBorrowers.filters,
    contextualViewStep: state.loanOriginationSystemBorrowers.contextualViewStep,
  }));
  const { isUnlockingInProgress, isLockingInProgress, borrowerToUnlock, borrowerToLock } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemBorrowers.borrowerLock,
  );
  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);
  const {
    items: emailList,
    emailIdToDelete,
    deleteEmailInProgress,
    filters: emailsFilters,
    sortingType: emailsSortingType,
    itemsTotal: emailItemsTotal,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  useStateReset(LoanOriginationSystemBorrowersActionTypes.ResetState);

  const createBorrowerFormAvailable = params.has('new');
  const editBorrowerFormAvailable = params.has('edit');
  const editBorrowerId = params.get('edit');
  const borrowerEmailId = params.get('borrowerEmailId');

  const filtersWithBorrowerId = {
    ...emailsFilters,
    selectedBorrower: editBorrowerId,
  };

  const areEmailsAccessible = useProductSectionAccessPermissions(ProductSection.LoanOriginationSystemEmails);

  useEffect(() => {
    if (areEmailsAccessible && emailItemsTotal === undefined) {
      dispatch(getEmails(filtersWithBorrowerId, emailsSortingType));
    }
  }, [areEmailsAccessible, borrowerEmailId]);

  const onComponentLeave = () => {
    dispatch(resetBorrowerDetails());
  };

  const confirmBorrowerDelete = async () => {
    if (!borrowerToDelete) {
      return;
    }

    await dispatchRoutine(deleteBorrower(borrowerToDelete.id, getBorrowerFullName(borrowerToDelete.type, borrowerToDelete.variables)));

    history.replace('/los/borrowers/');
  };

  const onCloseContextualView = () => {
    dispatch(changeContextualViewStep(0));
    closeContextualView();
  };

  const onChangeContextualViewStep = () => {
    history.replace(`?edit=${editBorrowerId}`);
    batch(() => {
      dispatch(changeContextualViewStep(contextualViewStep - 1));
      dispatch(selectBorrowerViewTab(BorrowerDetailsTab.Emails));
    });
  };

  const onBorrowerDelete = async (borrower: Borrower) => {
    dispatch(setBorrowerToDelete(borrower));
  };

  const handleUnlockBorrowerPopupClose = () => dispatch(setBorrowerToUnlock(null));
  const handleBorrowerUnlock = () => {
    if (!borrowerToUnlock) {
      return;
    }

    dispatch(unlockBorrower(borrowerToUnlock.id));
  };

  const handleCloseDeleteEmailPopup = () => dispatch(closeDeleteEmailPopup());
  const handleDeleteEmail = async () => {
    await dispatchRoutine(deleteEmail(emailIdToDelete!));
    closeContextualView();
  };

  const handleConfirmBorrowerToLockPopupClose = () => {
    dispatch(setBorrowerToLock(null));
  };

  const handleConfirmLockBorrower = () => {
    if (!borrowerToLock) {
      return;
    }

    dispatch(lockBorrower(borrowerToLock.id));
  };

  const renderContextualView = () => {
    if (createBorrowerFormAvailable) {
      return (
        <CreateBorrower onClose={onCloseContextualView} />
      );
    }

    if (editBorrowerFormAvailable) {
      return (
        <EditBorrower
          id={params.get('edit')!}
          onClose={onCloseContextualView}
        />
      );
    }

    return null;
  };

  const renderEmailView = () => {
    if (borrowerEmailId && emailList.length) {
      const currentEmailInfo = emailList.find((email) => email.id === borrowerEmailId);
      return currentEmailInfo ? (
        <EmailView
          currentEmailInfo={currentEmailInfo}
          onClose={onChangeContextualViewStep}
          organizationEmail={getOrganizationEmail(organizationInfo.id)}
          className={styles.emailViewContainer}
          isWizardView
        />
      ) : (
        <EmailNotFound onClose={onChangeContextualViewStep} className={styles.emailViewContainer} />
      );
    }

    return null;
  };

  const renderContextualViewWizard = () => {
    if (createBorrowerFormAvailable || editBorrowerFormAvailable || borrowerEmailId) {
      if (borrowerEmailId && contextualViewStep !== EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER) {
        dispatch(changeContextualViewStep(EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER));
      }

      if (borrowerEmailId && emailItemsTotal === undefined) {
        return null;
      }

      return (
        <ContextualViewWizard
          step={contextualViewStep}
          onComponentLeave={onComponentLeave}
          width={styles.contextualViewWizard}
        >
          {renderContextualView()}
          {renderEmailView()}
        </ContextualViewWizard>
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <BorrowersFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      isCompany={filters.isCompany}
      isPerson={filters.isPerson}
    />
  );

  const renderOverlay = () => {
    if (unsavedChangesPopupOpen) {
      return (
        <UnsavedChangesPopup
          onPopupClose={() => setUnsavedChangesPopupOpen(false)}
          onLeaveClick={() => {
            closeContextualView();
            setUnsavedChangesPopupOpen(false);
          }}
        />
      );
    }

    if (borrowerToDelete) {
      return (
        <ConfirmPopup
          title="Delete Borrower"
          message="Are you sure you want to delete this borrower?"
          confirmText="Yes, Delete Borrower"
          declineText="No, Go Back"
          onPopupClose={() => dispatch(setBorrowerToDelete(null))}
          onConfirmClick={confirmBorrowerDelete}
          loading={isDeleting}
        />
      );
    }

    if (borrowerToLock) {
      return (
        <ConfirmPopup
          title="Lock Borrower"
          message="Are you sure you want to lock this Borrower? They will not be able to apply until manually or automatically unlocked."
          confirmText="Yes, Lock Borrower"
          declineText="No, Go Back"
          onPopupClose={handleConfirmBorrowerToLockPopupClose}
          onConfirmClick={handleConfirmLockBorrower}
          loading={isLockingInProgress}
        />
      );
    }

    if (borrowerToUnlock) {
      return (
        <UnlockCustomerPopup
          onPopupClose={handleUnlockBorrowerPopupClose}
          onUnlockClick={handleBorrowerUnlock}
          isUnlockingInProgress={isUnlockingInProgress}
        />
      );
    }

    if (emailIdToDelete) {
      return (
        <DeleteEmailConfirmationPopup
          onConfirmClick={handleDeleteEmail}
          onPopupClose={handleCloseDeleteEmailPopup}
          loading={deleteEmailInProgress}
        />
      );
    }

    return null;
  };

  return (
    <>
      <MainLayout
        leftNav={leftNav}
        rightSidePopupView={rightSidePopupView}
        closeContextualView={onCloseContextualView}
        contextualView={renderContextualViewWizard()}
        overlay={renderOverlay()}
      >
        <PageWrapper>
          <PageContent className={styles.pageContent} noPadding>
            <BorrowersDashboard onBorrowerDelete={onBorrowerDelete} />
          </PageContent>
        </PageWrapper>
      </MainLayout>
    </>
  );
};

export default Borrowers;
