import React from 'react';
import styles from './CompanyGeneralSettingsSkeleton.module.scss';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';

const TABLE_ROWS_COUNT = 4;

const CompanyGeneralSettingsSkeleton = () => {
  return (
    <div className={styles.table}>
      {new Array(TABLE_ROWS_COUNT).fill(null).map((_, index) => (
        <TableRow key={index} disableHover className={styles.tableRow}>
          <TableBodyCell>
            <SkeletonRectangle width="240px" height="14px" color="primary6" marginRight="70px" />
          </TableBodyCell>
          <TableBodyCell>
            <SkeletonRectangle width="300px" height="14px" color="primary6" />
          </TableBodyCell>
        </TableRow>
      ))}
    </div>
  );
};

export default CompanyGeneralSettingsSkeleton;
