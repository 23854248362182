import React, { FC } from 'react';
import { ChangeStrategyStatusWizardConnectedProps } from 'components/ArchiveStrategyWizard/ArchiveStrategyWizardConnector';
import PopUpWizard from 'components/PopUps';
import DeletePopup from 'components/DeletePopup';
import { ArchiveEntityType } from 'components/ArchiveStrategyWizard/changeStrategyStatusWizardReducer';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const ArchiveStrategyWizard: FC<ChangeStrategyStatusWizardConnectedProps> = ({
  closePopUp,
  isOpen,
  archive,
  restore,
  strategyId,
  strategyName,
  entityType,
  versionNumber,
  archived,
  loading,
  actionOrigin,
}: ChangeStrategyStatusWizardConnectedProps) => {
  const {
    items,
  } = useSelector((state: ReduxState) => state.versionsListData);

  if (!isOpen) {
    return null;
  }

  const currentStrategy = items.find(strategy => strategy.title === strategyName);

  const getMessage = () => {
    if (entityType === ArchiveEntityType.Strategy) {
      return archived
        ? `Please confirm that you would like to restore all archived versions of ${currentStrategy && currentStrategy.displayTitle || strategyName}.`
        : `Please confirm that you would like to archive all versions of ${currentStrategy && currentStrategy.displayTitle || strategyName}.`;
    }

    return archived
      ? `Please confirm that you would like to restore ${currentStrategy && currentStrategy.displayTitle || strategyName} Version ${versionNumber}.`
      : `Please confirm that you would like to archive ${currentStrategy && currentStrategy.displayTitle || strategyName} Version ${versionNumber}.`;
  };

  const changeStatus = () => {
    const params =
      entityType === ArchiveEntityType.Strategy
        ? { strategyName, type: 'group' }
        : { id: strategyId!, version: versionNumber!, strategyName, type: 'version' };

    if (archived) {
      restore(params, actionOrigin!);
    } else {
      archive(params, actionOrigin!);
    }
  };

  const actionType = archived ? 'Restore' : 'Archive';

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={`${actionType} ${entityType}`} closable={!loading}>
      <DeletePopup
        message={getMessage()}
        deleteBtnText={actionType}
        handleDelete={changeStatus}
        handleClose={closePopUp}
        isLoading={loading}
        cancelBtnDisabled={loading}
      />
    </PopUpWizard>
  );
};

export default ArchiveStrategyWizard;
