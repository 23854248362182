import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { ReduxState } from 'types/redux';
import responseHandlers from 'api/ResponseHandlers';
import VariablesRestApi from 'api/Core/VariablesApi';
import { IVariableShortInfo } from 'Variables/VariablesTypes';
import {
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
  ACTION_TYPE_EDIT_VARIABLE_SUCCESS,
  VariablesActionType,
} from 'Variables/ActionTypes';
import { CreateVariableSuccessAction } from 'Variables/VariablesActionCreator';

export interface VariablesSuggestionsState {
  suggestions: IVariableShortInfo[] | null;
  loadingInProgress?: boolean;
}

const variablesApi = new VariablesRestApi(responseHandlers);

const initialState: VariablesSuggestionsState = {
  suggestions: null,
  loadingInProgress: false,
};

export const getVariablesSuggestions = createAsyncThunk<
  { variablesSuggestions: IVariableShortInfo[] },
  void,
  { state: ReduxState }
>(
  'getVariablesSuggestions',
  async () => {
    const variablesSuggestions = await variablesApi.getAllVariables();

    return {
      variablesSuggestions,
    };
  },
  {
    condition(arg, { getState }) {
      const { variablesSuggestions } = getState();

      return !variablesSuggestions.loadingInProgress;
    },
  },
);

const VariablesSuggestionsReducer = createReducer<VariablesSuggestionsState>(initialState, (builder) => {
  builder.addCase(getVariablesSuggestions.pending, (state) => {
    state.loadingInProgress = true;
  });
  builder.addCase(getVariablesSuggestions.fulfilled, (state, action) => {
    state.suggestions = action.payload.variablesSuggestions;
    state.loadingInProgress = false;
  });
  builder.addCase(getVariablesSuggestions.rejected, (state) => {
    state.loadingInProgress = false;
  });

  builder.addCase<
    typeof ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
    CreateVariableSuccessAction
  >(ACTION_TYPE_CREATE_VARIABLE_SUCCESS, (state, action) => {
    if (!state.suggestions) {
      return;
    }

    state.suggestions.push({
      id: action.payload.variable.id,
      displayName: action.payload.variable.displayName,
      systemName: action.payload.variable.systemName,
      isArchived: action.payload.variable.isArchived,
      dataType: action.payload.variable.dataType,
    });
  });

  builder.addMatcher(
  (action) => [ACTION_TYPE_EDIT_VARIABLE_SUCCESS, VariablesActionType.ChangeVariableStatusSuccess].includes(action.type),
  (state, action) => {
    if (!state.suggestions || !action.payload.variable) {
      return;
    }

    const suggestion = state.suggestions.find((suggestionToFind) => suggestionToFind.id === action.payload.variable.id);

    if (!suggestion) {
      state.suggestions.push({
        id: action.payload.variable.id,
        displayName: action.payload.variable.displayName,
        systemName: action.payload.variable.systemName,
        isArchived: action.payload.variable.isArchived,
        dataType: action.payload.variable.dataType,
      });

      return;
    }

    suggestion.displayName = action.payload.variable.displayName;
    suggestion.isArchived = action.payload.variable.isArchived;
  });
});

export default VariablesSuggestionsReducer;
