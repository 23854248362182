import React, { FC } from 'react';
import SearchHighlight from 'components/SearchHighlight';
import { SearchHighlight as SearchHighlightType } from 'api/Types';
import styles from './SearchMatchResults.module.scss';

interface SearchMatchResultsProps {
  searchInputValue: string;
  searchHighlights: SearchHighlightType[];
  getMatchingField: (path: string) => { name: string; value: string } | null;
}

const SearchMatchResults: FC<SearchMatchResultsProps> = ({ searchInputValue, searchHighlights, getMatchingField }) => {
  return (
    <div className={styles.searchMatchResultsContainer}>
      {[...searchHighlights]
        .sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA)
        .map(({ path }, index) => {
          const field = getMatchingField(path);

          if (!field) {
            return null;
          }

          return (
            <div key={index} className={styles.searchMatchResult}>
              {field.name}:{' '}
              <SearchHighlight multiWord search={searchInputValue}>
                {field.value}
              </SearchHighlight>
            </div>
          );
        })}
    </div>
  );
};

export default SearchMatchResults;
