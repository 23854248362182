import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import {
  SetShowArchivedAction,
  FilterProductsByMembersAction,
  ChangePopupFiltersAction,
  ResetAllProductsFilters,
} from './Filters/ActionCreator';
import { LoanOriginationSystemProductsFiltersActionTypes } from './Filters/ActionTypes';
import { LoanOriginationSystemProductsFiltersState } from './Filters/Types';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';

type ProductFiltersToSave = Pick<
  LoanOriginationSystemProductsFiltersState,
  | 'statuses'
  | 'showArchived'
  | 'selectedMembers'
  | 'borrowerType'
  | 'dueCreatedDateRange'
  | 'dueUpdatedDateRange'
>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters } = state.loanOriginationSystemProducts;

  return pick(filters, [
    'statuses',
    'showArchived',
    'selectedMembers',
    'borrowerType',
    'dueCreatedDateRange',
    'dueUpdatedDateRange',
  ]);
};

const saveTriggerActions = [
  isActionOfType<SetShowArchivedAction>(LoanOriginationSystemProductsFiltersActionTypes.SetShowArchived),
  isActionOfType<FilterProductsByMembersAction>(
    LoanOriginationSystemProductsFiltersActionTypes.FilterProductsByMembers,
  ),
  isActionOfType<ChangePopupFiltersAction>(LoanOriginationSystemProductsFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllProductsFilters>(LoanOriginationSystemProductsFiltersActionTypes.ResetAllFilters),
];

const productsFilterSaver = FilterAndSortingSaver<ProductFiltersToSave>({
  key: 'products',
  getFiltersToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemProductsActionType.ResetState,
});

export default productsFilterSaver;
