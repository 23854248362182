import moment from 'moment';

export type SplitDateRangePeriod = 'month' | 'day' | 'year';

const formatByPeriod = (date: Date, period: SplitDateRangePeriod) => {
  if (period === 'day') {
    return moment(date).format('YYYY-MM-DDT00:00:00ZZ');
  }

  if (period === 'month') {
    return moment(date).format('YYYY-MM-01T00:00:00ZZ');
  }

  return moment(date).format('YYYY-01-01T00:00:00ZZ');
};

const splitDateRange = (startDate: Date, endDate: Date, period: SplitDateRangePeriod) => {
  const dates = new Set<string>();

  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.add(formatByPeriod(currentDate, period));

    currentDate = moment(currentDate)
      .add({ day: 1 })
      .toDate();
  }

  dates.add(formatByPeriod(endDate, period));

  const result = [...dates.values()];
  result[0] = formatByPeriod(startDate, 'day');

  return result;
};

export default splitDateRange;
