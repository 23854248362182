import { LoanOriginationSystemOrganizationState } from './Types';
import {
  GetOrganizationApplicationsSuccessAction,
  GetOrganizationUsersSuccessAction,
  GetStandardVariablesSuccessAction,
} from './ActionCreator';
import { LoanOriginationSystemOrganizationActionType } from './ActionTypes';
import { EditVariableSuccessAction } from 'Variables/VariablesActionCreator';
import { ACTION_TYPE_EDIT_VARIABLE_SUCCESS } from 'Variables/ActionTypes';
import replaceAt from 'utils/replaceAt';
import { SimplifiedVariable, Variable } from 'Variables/VariablesTypes';
import omit from 'lodash/omit';
import getVariableAttributes from 'utils/getVariableAttributes';
import { editOrganizationUser } from 'OrganizationUsers/OrganizationUserStore';
import { AddNewCompanyUserSuccessAction } from 'AddNewCompanyUser/AddNewCompanyUserActionCreator';
import { ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS } from 'AddNewCompanyUser/ActionTypes';

type LoanOriginationSystemOrganizationActions =
  | GetOrganizationUsersSuccessAction
  | GetStandardVariablesSuccessAction
  | EditVariableSuccessAction
  | GetOrganizationApplicationsSuccessAction
  | AddNewCompanyUserSuccessAction;

const initialState: LoanOriginationSystemOrganizationState = {
  organizationMembers: null,
  applicationsListInfo: [],
  standardVariables: null,
};

const loanOriginationSystemOrganizationReducer = (
  state = initialState,
  action: LoanOriginationSystemOrganizationActions,
): LoanOriginationSystemOrganizationState => {
  switch (action.type) {
    case LoanOriginationSystemOrganizationActionType.GetOrganizationUsersSuccess: {
      return {
        ...state,
        organizationMembers: action.payload,
      };
    }
    case LoanOriginationSystemOrganizationActionType.GetOrganizationApplicationsSuccess: {
      return {
        ...state,
        applicationsListInfo: action.payload.applications,
      };
    }
    case LoanOriginationSystemOrganizationActionType.GetStandardVariablesSuccess: {
      return {
        ...state,
        standardVariables: action.payload.standardVariables.reduce(
          (result: SimplifiedVariable[], variable: Variable) => {
            const simplifiedVariable = omit<Variable>(variable, [
              'updateDate',
              'createDate',
              'createdBy',
              'updatedBy',
              'description',
            ]) as SimplifiedVariable;
            result.push(simplifiedVariable);
            return result;
          },
          [],
        ),
      };
    }
    case editOrganizationUser.fulfilled.type:
    case ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        organizationMembers: null,
      };
    }
    case ACTION_TYPE_EDIT_VARIABLE_SUCCESS: {
      if (!state.standardVariables) {
        return state;
      }

      const { variable } = action.payload;
      const variableIndex = state.standardVariables.findIndex(
        ({ id, dependsOn }) => id === variable.id || dependsOn?.id === variable.id,
      );

      if (variableIndex === -1) {
        return state;
      }

      const variableToUpdate = state.standardVariables[variableIndex];

      const newVariable =
        variableToUpdate.id !== variable.id
          ? {
              ...variableToUpdate,
              ...getVariableAttributes(variable),
              dependsOn: {
                id: variable.id,
                systemName: variable.systemName,
                displayName: variable.displayName,
              },
            }
          : {
              id: variable.id,
              displayName: variable.displayName,
              name: variable.name,
              systemName: variable.systemName,
              dependsOn: variable.dependsOn,
              ...getVariableAttributes(variable),
            };

      return {
        ...state,
        standardVariables: replaceAt(state.standardVariables, variableIndex, newVariable as SimplifiedVariable),
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemOrganizationReducer;
