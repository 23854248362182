import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getTasks, SortTasksAction } from './ActionCreator';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';

const isSortTasksAction = (action: AnyAction): action is SortTasksAction =>
  action.type === LoanOriginationSystemTasksActionTypes.SortTasks;

const sortTasksMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortTasksAction(action)) {
    dispatch(getTasks());
  }

  return result;
}) as Middleware;

export default sortTasksMiddleware;
