import { WebhookEventSortingType, WebhookEventStatus } from 'api/Webhooks/WebhookEventsApi';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const WEBHOOK_EVENTS_PER_PAGE_DEFAULT = 20;

export interface WebhookEventsPaginationParams {
  search?: string;
  sortingType: WebhookEventSortingType;
  webhooksIds?: string[];
  eventTypes?: string[];
  statuses?: WebhookEventStatus[];
  dueCreatedDateFrom?: Date | null;
  dueCreatedDateTo?: Date | null;
}

const webhookEventsPagination = Pagination<ReduxState, string, WebhookEventsPaginationParams>(
  'webhookEventsTable',
  WEBHOOK_EVENTS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),

);

export default webhookEventsPagination;
