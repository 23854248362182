import React from 'react';
import styles from './CompanyRegionSettingsView.module.scss';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';
import {
  getCurrencyMessageByValue,
  getPhoneFormatMessageByValue,
} from './utils';
import CompanyGeneralSettingsSkeleton from 'components/CompanyGeneralSettings/CompanyGeneralSettingsSkeleton';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

interface CompanyRegionSettingsViewProps {
  regionSettings: RegionSettings;
}

const CompanyRegionSettingsView = ({ regionSettings }: CompanyRegionSettingsViewProps) => {
  const { isLoading } = useSelector((state: ReduxState) => state.organizationInformation);

  if (isLoading) {
    return <CompanyGeneralSettingsSkeleton />;
  }

  return (
    <div className={styles.table}>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Phone Format</div>
        <div className={styles.tableRowValue}>{getPhoneFormatMessageByValue(regionSettings.phoneNumberFormat)}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Currency Format</div>
        <div className={styles.tableRowValue}>{getCurrencyMessageByValue(regionSettings.currencyFormat)}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Date Format</div>
        <div className={styles.tableRowValue}>{regionSettings.dateFormat}</div>
      </div>
    </div>
  );
};

export default CompanyRegionSettingsView;
