import React, { ReactElement } from 'react';
import clsx from 'clsx';

import Label from 'components/Label';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';
import WrapperWithTooltip from 'components/Tooltip';

import inputStyles from './TextInput.module.scss';
import stubStyles from './TextInputStub.module.scss';

export type InputTooltipType = 'default' | 'hoverable';

export interface TextInputStubProps {
  label: string;
  placeholder: string | JSX.Element;
  disabled?: boolean;
  stubInputClassName?: string;
  className?: string;
  elementBeforeInput?: JSX.Element | null;
  inputIcon?: React.ReactNode;
  markerIcons?: React.ReactNode;
  inputTooltip?: React.ReactNode;
  inputTooltipType?: InputTooltipType;
  required?: boolean;
}

const TextInputStub = ({
  label,
  placeholder,
  elementBeforeInput,
  disabled,
  stubInputClassName,
  className,
  inputIcon,
  markerIcons,
  required,
  inputTooltip,
  inputTooltipType = 'default',
}: TextInputStubProps) => {
  const wrapInputWithTooltip = (input: ReactElement) => {
    if (inputTooltipType === 'hoverable') {
      return (
        <WrapperWithHoverableTooltip tooltip={inputTooltip} placement="bottom">
          {input}
        </WrapperWithHoverableTooltip>
      )
    }

    return (
      <WrapperWithTooltip tooltip={inputTooltip}>
        {input}
      </WrapperWithTooltip>
    );
  };

  return (
    <div className={clsx(stubStyles.stubInputContainer, className)}>
      <div className={inputStyles.labelWithLink}>
        <Label required={required}>
          {label}
        </Label>
      </div>
      {wrapInputWithTooltip(
        <div className={clsx(stubStyles.stubInput, { [stubStyles.disabled]: disabled }, stubInputClassName)}>
          {elementBeforeInput && <div className={stubStyles.beforeStubInputContainer}>{elementBeforeInput}</div>}
          {placeholder}
          <div className={stubStyles.markerIconsContainer}>{markerIcons}</div>
          <div className={stubStyles.inputIconContainer}>{inputIcon}</div>
        </div>
      )}
    </div>
  );
};

export default TextInputStub;
