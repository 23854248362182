import React, { ReactNode } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import AutoCompletion from 'components/AutoCompletion';

import styles from './ComparisonOperand.module.scss';
import InputWithDataType from 'components/InputWithDataType';
import {
  UndefinedVisualDataTypeWithNoAttributes,
  VariableClientType,
  VisualDataTypeWithAttributes,
} from 'Variables/VariablesTypes';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import { getVariableType } from 'Variables/utils';
import { trim } from 'lodash';

const TYPE_OPTIONS: Option[] = [
  {
    name: 'Value',
    value: ComparisonOperandType.VALUE,
  },
  {
    name: 'Variable',
    value: ComparisonOperandType.VARIABLE,
  },
];

interface ComparisonOperandProps {
  value: string;
  variableId?: string;
  operandType: ComparisonOperandType;
  onValueChange: (value: string) => void;
  onVariableIdChange: (variableId: string) => void;
  onTypeChange: (type: ComparisonOperandType) => void;
  labelTitle?: string;
  description?: string;
  link?: ReactNode;
}

const ComparisonOperand = (
  props: ComparisonOperandProps & (VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes),
) => {
  const {
    value,
    operandType,
    variableId,
    onVariableIdChange,
    onValueChange,
    onTypeChange,
    labelTitle,
    description,
    link,
    ...dataTypeAttributes
  } = props;

  const handleValueChange = (enteredValue: string) => {
    if (enteredValue && !trim(enteredValue)) {
      return;
    }

    onValueChange(enteredValue);
  }

  const renderMainInput = () => {
    if (operandType === ComparisonOperandType.VARIABLE) {
      const onChange = ({ value: variableIdToSet }: Option) => onVariableIdChange(variableIdToSet);
      return (
        <VariableSelector
          value={variableId}
          onChange={onChange}
          labelTitle={labelTitle || 'Variable'}
          hasRightNeighbour
          dataType={getVariableType(dataTypeAttributes as VariableClientType).dataType}
        />
      );
    }
    if (operandType === ComparisonOperandType.VALUE) {
      return (
        <InputWithDataType
          value={value}
          options={[]}
          labelTitle={labelTitle || 'Value'}
          onChange={handleValueChange}
          {...(dataTypeAttributes as VisualDataTypeWithAttributes)}
        />
      );
    }
    return null;
  };

  const handleTypeInputChange = ({ value: operandTypeToSet }: Option) =>
    onTypeChange(operandTypeToSet as ComparisonOperandType);

  return (
    <div className={styles.comparisonOperandContainer}>
      <div className={styles.inputContainer}>
        {renderMainInput()}
        <div className={styles.typeHandlerContainer}>
          <div className={styles.linkContainer}>{link}</div>
          <AutoCompletion
            value={operandType}
            options={TYPE_OPTIONS}
            onChange={handleTypeInputChange}
            hasLeftNeighbour
            hideClearIcon
          />
        </div>
      </div>
      <div className={styles.descriptionContainer}>{description}</div>
    </div>
  );
};

export default ComparisonOperand;
