import React from 'react';
import TabSwitch from 'components/TabSwitch';
import styles from './CompanyGeneralSettings.module.scss';
import { useHistory } from 'react-router';
import RouteService from 'routes/RouteService';
import CompanyInformation from './CompanyInformation';
import { CompanyGeneralSettingsTab, COMPANY_GENERAL_SETTINGS_TABS } from './CompanyGeneralSettings.constants';
import PageName from 'constants/PageName';
import CompanyRegionSettings from './CompanyRegionSettings';

interface CompanyGeneralSettingsProps {
  selectedTabId: CompanyGeneralSettingsTab;
}

const CompanyGeneralSettings = ({ selectedTabId }: CompanyGeneralSettingsProps) => {
  const history = useHistory();

  const handleTabChange = (tab: CompanyGeneralSettingsTab) => {
    history.push(RouteService.getCompanyGeneralSettingsPage(tab));
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{PageName.General}</h2>
      </div>
      <TabSwitch
        className={styles.tabSwitch}
        tabs={COMPANY_GENERAL_SETTINGS_TABS}
        selectedTabId={selectedTabId}
        onSelect={({ id }) => handleTabChange(id as CompanyGeneralSettingsTab)}
      />
      {selectedTabId === CompanyGeneralSettingsTab.CompanyInformation && <CompanyInformation />}
      {selectedTabId === CompanyGeneralSettingsTab.RegionSettings && <CompanyRegionSettings />}
    </div>
  );
};

export default CompanyGeneralSettings;
