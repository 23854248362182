import { Jexl, JexlExpression } from '@digifi/jexl';
import jexlFunctions from '@digifi/jexl-functions';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import CacheService from './CacheService';
import generateObjectHash from 'object-hash';

export interface SuccessFormulaResult {
  value: VariableValue | undefined;
}

export interface ErrorFormulaResult {
  err: string;
}

export type RunFormulaResult = SuccessFormulaResult | ErrorFormulaResult;

export class FormulaService {
  private jexl: Jexl;

  constructor(
    private formulaCompilationCacheService: CacheService<JexlExpression>,
    private formulaRunResultsCacheService: CacheService<RunFormulaResult>,
  ) {
    this.jexl = new Jexl();

    this.jexl.addFunctions(jexlFunctions);
  }

  public static isErrorFormulaResult(runFormulaResult: RunFormulaResult): runFormulaResult is ErrorFormulaResult {
    return 'err' in runFormulaResult;
  }

  public runFormula(formula: string, context: Record<string, unknown>): RunFormulaResult {
    const contextHash = generateObjectHash(context);
    const cacheKey = `${formula}-${contextHash}`;

    const cachedFormulaRunResult = this.formulaRunResultsCacheService.get(cacheKey);

    if (cachedFormulaRunResult) {
      return cachedFormulaRunResult;
    }

    try {
      const expression = this.compileFormula(formula);

      const evalValue = expression.evalSync(context);

      const result = (typeof evalValue === 'object' && evalValue !== null)
        ? { value: evalValue.toString() }
        : { value: evalValue };

      this.formulaRunResultsCacheService.set(cacheKey, result);

      return result;
    } catch (err) {
      const result = { err: err.message };

      this.formulaRunResultsCacheService.set(cacheKey, result);

      return result;
    }
  }

  public validateFormulaSyntax(formula: string) {
    try {
      this.jexl.compile(formula);

      return [];
    } catch (err) {
      return [err.message];
    }
  }

  private compileFormula(formula: string) {
    const cachedExpression = this.formulaCompilationCacheService.get(formula);

    if (cachedExpression) {
      return cachedExpression;
    }

    const expression = this.jexl.compile(formula);

    this.formulaCompilationCacheService.set(formula, expression);

    return expression;
  }
}

export default new FormulaService(
  new CacheService<JexlExpression>(),
  new CacheService<RunFormulaResult>(),
);
