import React from 'react';
import SIZES from 'components/SendGrid/TemplatesTable/TableColumnSizes';
import styles from './TemplatesTableBody.module.scss';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import SearchHighlight from 'components/SearchHighlight';
import RowActions from 'components/RowActions';
import { SendGridTemplate } from 'SendGrid/Types';
import { WarningNotificationIcon } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import StatusTag from 'components/Tag/Tag';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import UserAvatar from 'components/UserAvatar';

interface TemplatesTableBodyProps {
  template: SendGridTemplate;
  searchInputValue: string;
  onDeletePopupOpen: (id: string) => void;
  openEditTemplateContextualView: (id: string) => void;
}

const TemplatesTableBody = ({
  template,
  searchInputValue,
  onDeletePopupOpen,
  openEditTemplateContextualView,
}: TemplatesTableBodyProps) => {
  const { id, name, updatedBy, active, variables, updatedAt } = template;
  const hasUnmappedVariables = Object.keys(variables).some((key) => variables[key] === null);

  return (
    <TableRow onClick={() => openEditTemplateContextualView(id)} useActions>
      <TableBodyCell width={SIZES.NAME} className={styles.nameCell}>
        <SearchHighlight search={searchInputValue}>{name}</SearchHighlight>
        {hasUnmappedVariables && (
          <WrapperWithTooltip tooltip="This template has unmapped fields">
            <WarningNotificationIcon />
          </WrapperWithTooltip>
        )}
      </TableBodyCell>
      <TableBodyCell width={SIZES.UPDATED} className={styles.updatedCell} noPadding>
        <UserAvatar
          firstName={updatedBy?.firstName || ''}
          lastName={updatedBy?.lastName || ''}
          imageId={updatedBy?.imageId}
          size="small"
          tooltip
        />
        <DateTime time={new Date(updatedAt)} format={DateTimeFormat.Long} />
      </TableBodyCell>
      <TableBodyCell width={SIZES.STATUS}>{renderStatusTag(active)}</TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit Template',
            handler: () => openEditTemplateContextualView(id),
            separatorRequired: true,
          },

          {
            title: 'Delete Template',
            handler: () => onDeletePopupOpen(id),
            danger: true,
          },
        ]}
      />
    </TableRow>
  );
};

const renderStatusTag = (active: boolean) => {
  return active ? <StatusTag color="green">Active</StatusTag> : <StatusTag>Inactive</StatusTag>;
};

export default TemplatesTableBody;
