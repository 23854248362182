import React, { useMemo, useState, useCallback, useEffect, FocusEvent } from 'react';
import { FormLayoutData, VariableValue } from 'api/Types';
import { PopperProps } from '@material-ui/core';
import { removeWhiteSpace } from 'utils/validation/validation';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import shouldShowSuggestions from './shouldShowSuggestions';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import SuggestionsPopper, {
  SuggestionItem,
} from 'components/LoanOriginationSystem/CreateApplication/SuggestionsPopper';
import { isPhoneNumber } from 'components/InputWithDataType/InputWithDataType';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { getFormattedIntermediaryPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import { IntermediarySuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { StandardVariables } from 'Variables/VariablesTypes';
import styles from './NewIntermediaryForm.module.scss';

interface NewIntermediaryFormProps {
  intermediaryData: FormLayoutData;
  cards: Card[] | null;
  standardVariables: StandardVariables | null;
  suggestions: SuggestionItem[];
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onLoadSuggestions?: (intermediarySuggestionFilter: IntermediarySuggestionFilter) => void;
  onSuggestionSelect: (suggestionItem: SuggestionItem) => void;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const VARIABLE_WITH_SUGGESTIONS = [
  IntermediaryDefaultVariable.Name,
  IntermediaryDefaultVariable.Email,
  IntermediaryDefaultVariable.PhoneNumber,
  IntermediaryDefaultVariable.IdNumber,
];

const NewIntermediaryForm = ({
  cards,
  intermediaryData,
  suggestions,
  standardVariables,
  onFieldChange,
  onLoadSuggestions,
  onSuggestionSelect,
  renderActions,
}: NewIntermediaryFormProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionAnchorElement, setSuggestionAnchorElement] = useState<PopperProps['anchorEl']>(null);

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, intermediaryData);

  const validateIntermediaryData = useConfigurableFormValidation(fields);

  const formattedSuggestions = useMemo(() => {
    return suggestions.map((suggestion) => {
      return {
        ...suggestion,
        phone: getFormattedIntermediaryPhoneNumber(suggestion.phone, standardVariables),
      };
    });
  }, [suggestions]);

  const intermediarySuggestionFilter = useMemo(() => ({
    email: intermediaryData[IntermediaryDefaultVariable.Email],
    phoneNumber: intermediaryData[IntermediaryDefaultVariable.PhoneNumber],
    name: intermediaryData[IntermediaryDefaultVariable.Name],
    idNumber: intermediaryData[IntermediaryDefaultVariable.IdNumber],
  } as IntermediarySuggestionFilter), [intermediaryData]);

  useEffect(() => {
    emitOnLoadSuggestion();
  }, [intermediarySuggestionFilter, suggestionAnchorElement])

  const emitOnLoadSuggestion = () => {
    if (shouldShowSuggestions(intermediarySuggestionFilter) && suggestionAnchorElement) {
      onLoadSuggestions?.(intermediarySuggestionFilter);

      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    onFieldChange(variableConfiguration, removeWhiteSpace(value));
  }, []);

  const handleFieldFocus = ({ variable }: BaseVariableConfiguration, event: FocusEvent<HTMLInputElement>) => {
    if (isPhoneNumber(getVisualDataTypeWithAttributes(variable))) {
      setSuggestionAnchorElement(event.currentTarget.parentElement);

      return;
    }

    if (VARIABLE_WITH_SUGGESTIONS.includes(variable.systemName)) {
      setSuggestionAnchorElement(event.target);
    }
  };

  const onClosePopper = () => {
    setShowSuggestions(false);
  };

  return (
    <div className={styles.container}>
      <CardsForm
        cards={cards}
        data={intermediaryData}
        onFieldChange={handleFieldChange}
        onFieldFocus={handleFieldFocus}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        isEditMode
        displayFieldsAttributes={displayFieldsAttributes}
        displayHeader={false}
        displaySkeleton={!displayFieldsAttributes}
      />
      <SuggestionsPopper
        placement="bottom-start"
        className={styles.popper}
        suggestions={formattedSuggestions}
        anchorEl={suggestionAnchorElement}
        open={suggestions && showSuggestions}
        onClose={onClosePopper}
        onSelectSuggestion={onSuggestionSelect}
      />
      {renderActions?.(!cards, !validateIntermediaryData(intermediaryData, displayFieldsAttributes))}
    </div>
  );
};

export default NewIntermediaryForm;
