import React, { FC, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import ButtonWithImage from 'components/ButtonWithImage';
import TabSwitch, { TabSwitchOption, TabSwitchSkeleton } from 'components/TabSwitch';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import WrapperWithTooltip from 'components/Tooltip';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ProductConfigurationSubHeader
  from 'components/LoanOriginationSystem/ProductConfiguration/ProductConfigurationSubHeader';
import {
  ApplicationFormPageLabel,
  availableApplicationFormPages,
  getCoBorrowerPageIndex,
  getApplicationFormPageLabel,
  isAlwaysAvailableApplicationFormPage,
  isCoBorrowerApplicationFormPage,
} from 'ApplicationFormPage';
import styles from './Header.module.scss';

export interface HeaderProps {
  selectedApplicationFormPage?: string | null;
  applicationFormPages?: string[] | null;
  onTabChange: (tab: string) => void;
  onApplicationFormPageDelete: (formPage: string) => void;
  onApplicationFormPageAdd: (formPage: string) => void;
  onFormSettingsClick: () => void;
}

const Header: FC<HeaderProps> = ({
  selectedApplicationFormPage,
  applicationFormPages,
  onTabChange,
  onApplicationFormPageAdd,
  onApplicationFormPageDelete,
  onFormSettingsClick,
}) => {
  const [displayAddPagePopup, setDisplayAddPagePopup] = useState(false);
  const addPageButtonRef = useRef<HTMLButtonElement | null>(null);

  const tabs: TabSwitchOption[] | null = useMemo(() => {
    if (!applicationFormPages) {
      return null;
    }

    const baseTabs = [...applicationFormPages]
      .sort((firstPage, secondPage) => {
        return availableApplicationFormPages.indexOf(firstPage) - availableApplicationFormPages.indexOf(secondPage);
      })
      .map((formPage) => ({
        id: formPage,
        label: getApplicationFormPageLabel(formPage),
        disableDelete: isAlwaysAvailableApplicationFormPage(formPage),
      }));

    const displayAddPage = availableApplicationFormPages.some((page) => !applicationFormPages.includes(page));

    return displayAddPage
      ? [
          ...baseTabs,
          {
            label: 'Add Page',
            id: 'addPage',
            type: 'button',
            ref: addPageButtonRef,
            onClick: () => setDisplayAddPagePopup(true),
          },
        ]
      : baseTabs;
  }, [applicationFormPages]);

  const dropdownOptions = useMemo(() => {
    if (!applicationFormPages) {
      return [];
    }

    const availableCoBorrowerPages = availableApplicationFormPages.filter((page) => isCoBorrowerApplicationFormPage(page));
    const coBorrowersPagesCount = availableCoBorrowerPages.filter((page) => applicationFormPages.includes(page)).length;

    return availableApplicationFormPages.filter((page) => {
      if (isAlwaysAvailableApplicationFormPage(page)) {
        return false;
      }

      if (!isCoBorrowerApplicationFormPage(page)) {
        return true;
      }

      const index = getCoBorrowerPageIndex(page);

      return coBorrowersPagesCount === availableCoBorrowerPages.length
        ? index === coBorrowersPagesCount - 1
        : index === coBorrowersPagesCount;
    });
  }, [applicationFormPages]);

  const handleCloseAddPagePopup = () => setDisplayAddPagePopup(false);

  const handleApplicationFormPageAdd = (type: string) => {
    onApplicationFormPageAdd(type);
    handleCloseAddPagePopup();
  };

  const isDropdownOptionDisabled = (type: string) => {
    if (!applicationFormPages) {
      return true;
    }

    if (type === ApplicationFormPageLabel.CoBorrower) {
      return applicationFormPages.filter((page) => isCoBorrowerApplicationFormPage(page)).length === 3;
    }

    return applicationFormPages.includes(type);
  };

  const getDropdownOptionTooltip = (type: string) => {
    const ignoreIndex = true;

    return isCoBorrowerApplicationFormPage(type)
      ? `You can include a maximum of ${3} co-borrower pages`
      : `The ${getApplicationFormPageLabel(type, ignoreIndex)} page is already active`;
  }

  const renderTabDropdownOption = (type: string) => {
    const disabled = isDropdownOptionDisabled(type);
    const ignoreIndex = true;

    return (
      <WrapperWithTooltip key={type} tooltip={disabled ? getDropdownOptionTooltip(type) : ''}>
        <div className={clsx(disabled && styles.disabledAddPageButtonContextualButtonContainer)}>
          <ActionPopUpItem
            className={styles.addPageButtonContextualButton}
            key={type}
            disabled={disabled}
            onClick={() => handleApplicationFormPageAdd(type)}
          >
            {getApplicationFormPageLabel(type, ignoreIndex)}
          </ActionPopUpItem>
        </div>
      </WrapperWithTooltip>
    );
  };

  const renderTabs = () => {
    if (!tabs) {
      return (
        <TabSwitchSkeleton
          itemsCount={availableApplicationFormPages.length}
          className={styles.tabSwitchSkeleton}
        />
      );
    }

    return (
      <TabSwitch
        tabs={tabs}
        selectedTabId={selectedApplicationFormPage || ''}
        onSelect={({ id }) => onTabChange(id)}
        allowTabDelete
        onTabDelete={({ id }) => onApplicationFormPageDelete(id)}
        tabClassName={styles.tab}
        borderContainerClassName={styles.tabSwitchBorderContainer}
        removeTabClassName={styles.removeTabImage}
        displayBorder={false}
      />
    );
  };

  const renderQuestionTooltip = () => (
    <>
      <p>The information, requirements and settings</p>
      <p>for creating new applications.</p>
    </>
  );

  const renderSubHeaderActions = () => (
    <ButtonWithImage
      disabled={!tabs}
      className={styles.formSettingsButton}
      title="Edit Form Settings"
      kind="edit"
      onClick={() => onFormSettingsClick()}
    />
  );

  return (
    <div className={styles.header}>
      <ProductConfigurationSubHeader
        className={styles.subheader}
        renderQuestionTooltip={renderQuestionTooltip}
        renderActions={renderSubHeaderActions}
        title="Application Form"
      />
      {renderTabs()}
      <ContextualPopUp
        anchorEl={addPageButtonRef.current}
        open={displayAddPagePopup}
        onClose={handleCloseAddPagePopup}
        anchorOrigin={{ vertical: 'bottom', horizontal: 0 }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={styles.actionsContainer}>{dropdownOptions.map(renderTabDropdownOption)}</div>
      </ContextualPopUp>
    </div>
  );
};

export default Header;
