import React, { useEffect, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './EmailsFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { getBorrowersForEmailsFilter } from 'LoanOriginationSystemEmails/ActionCreator';
import { ReduxState } from 'types/redux';

interface EmailsFilterProps {
  dueDateFrom: Date | null;
  dueDateTo: Date | null;
  borrower: string | null;
}

const INPUT_EMPTY_VALUE = '';

const EmailsFilter = ({ dueDateFrom, dueDateTo, borrower }: EmailsFilterProps) => {
  const dispatch = useDispatch();

  const { borrowersForFilter, getBorrowersInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemEmails,
  );

  const formattedBorrowers = borrowersForFilter.map((borrowerData) => ({
    name: `${borrowerData.name}`,
    value: borrowerData.id,
  }));

  const [selectedFilterInputValue, setSelectedInputFilterInputValue] = useState(borrower || INPUT_EMPTY_VALUE);

  useEffect(() => {
    dispatch(getBorrowersForEmailsFilter());
  }, []);

  const [fromDate, setFromDate] = useState(dueDateFrom);
  const [toDate, setToDate] = useState(dueDateTo);

  const filtersResult: FiltersResult = {
    createdAtRange: {
      from: fromDate,
      to: toDate,
    },
    borrower: selectedFilterInputValue || null,
  };

  const onFilterChange = (newOption: Option) => {
    setSelectedInputFilterInputValue(newOption.value);
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Borrower"
        onChange={onFilterChange}
        options={formattedBorrowers}
        value={selectedFilterInputValue}
        loading={getBorrowersInProgress}
        className={styles.statusDropdownContainer}
      />
      <DateRangePicker from={fromDate} to={toDate} onChange={handleOnDateRangeChange} label="Email Date" />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default EmailsFilter;
