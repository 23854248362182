import React, { useState, PropsWithChildren, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { DropResult } from 'react-beautiful-dnd';
import {
  BaseVariableConfiguration,
} from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import useConfirmChanges from 'hooks/useConfirmChanges';
import useBlockingRequest from 'hooks/useBlockingRequest';
import Button from 'components/Button';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import VariablesConfiguration from 'components/LoanOriginationSystem/VariablesConfiguration';
import { ListVariableAttributes } from 'components/LoanOriginationSystem/VariablesConfiguration/VariablesPane/VariablesPane';
import CardsRow from './CardsRow';
import SkeletonCardsLayout from './SkeletonCardsLayout';
import AddCardButton from './AddCardButton/AddCardButton';
import getPosition from 'utils/getPosition';
import sortByPosition from 'utils/sortByPosition';
import { Variable } from 'Variables/VariablesTypes';
import ConditionalDisplayRuleBuilderPopup from 'components/ConditionalDisplayRuleBuilderPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import styles from './CardsConfigurationLayout.module.scss';

export interface CardsLayoutConfigurationProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
> {
  cards: CardType[] | null;
  calculations?: CalculationType[] | null;
  onCreateCard: (position: number, row: number) => void;
  onUpdateCardName: (cardId: string, name: string) => void;
  onDeleteCard: (cardId: string) => void;
  onCreateVariableConfiguration: (cardId: string, column: number, position: number, variable: Variable) => void;
  onDeleteVariableConfiguration: (variableConfiguration: VariableConfigurationType) => void;
  onUpdateVariableConfigurationPosition: (
    variableConfiguration: VariableConfigurationType,
    cardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => void;
  onChangeVariableConfigurationRequiredAttribute?: (variableConfiguration: VariableConfigurationType, required: boolean) => void;
  onEditVariableConfigurationConditionalDisplayRule: (
    variableConfiguration: VariableConfigurationType,
    conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null,
  ) => Promise<void>;
  children: (renderActions: () => React.ReactNode, renderLayoutContent: () => React.ReactNode) => React.ReactNode;
  maxVariablesPerCard?: number;
  maxCardsPerRow?: number;
  maxCardsPerColumn?: number;
  maxColumnsPerCard?: number;
  className?: string;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  skeletonCardsLayout?: Array<Array<number>>;
  allowStandardVariables?: boolean;
  isConditionalRuleBuildInProgress?: boolean;
  isConditionalRuleDeleteInProgress?: boolean;
  getListVariableAttributes?: (variable: Variable) => ListVariableAttributes;
  displaySkeleton?: boolean;
}

export const DEFAULT_SKELETON_CARDS_LAYOUT = [[5], [3]];
const DEFAULT_MAX_COLUMNS_PER_CARD = 1;

const CardsLayoutConfiguration = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  cards,
  calculations,
  onCreateCard,
  onUpdateCardName,
  onDeleteCard,
  onCreateVariableConfiguration,
  onDeleteVariableConfiguration,
  onUpdateVariableConfigurationPosition,
  children,
  maxCardsPerColumn,
  maxCardsPerRow,
  maxVariablesPerCard,
  maxColumnsPerCard = DEFAULT_MAX_COLUMNS_PER_CARD,
  className,
  formatVariableConfigurationDisplayTitle,
  skeletonCardsLayout = DEFAULT_SKELETON_CARDS_LAYOUT,
  onChangeVariableConfigurationRequiredAttribute,
  onEditVariableConfigurationConditionalDisplayRule,
  allowStandardVariables,
  getListVariableAttributes,
  displaySkeleton,
}: PropsWithChildren<CardsLayoutConfigurationProps<VariableConfigurationType, CardType, CalculationType>>) => {
  const [variablesPaneOpen, setVariablesPaneOpen] = useState(false);
  const [
    buildConditionalDisplayRuleFor,
    setBuildConditionalDisplayRuleFor,
  ] = useState<VariableConfigurationType | null>(null);
  const [
    displayDeleteConfigurableDisplayRulePopup,
    resetConfirmDeleteConfigurableDisplayRule,
    onConfirmDeleteConfigurableDisplayRule,
    useConfirmDeleteConfigurableDisplayRule,
  ] = useConfirmChanges();

  const [isConditionalRuleBuildingInProgress, useBlockingConditionalRuleBuildingCallback] = useBlockingRequest();
  const [isConditionalRuleDeleteInProgress, useBlockingConditionalRuleDeleteCallback] = useBlockingRequest();

  const usedVariablesIds = useMemo(() => {
    if (!cards) {
      return [];
    }

    return cards.flatMap((card) => card.fields.map(({ variable }) => variable.id));
  }, [cards]);

  const cardsLayout = useFormLayout(cards);

  const handleCardNameUpdate = useCallback((cardId: string, name: string) => {
    onUpdateCardName(cardId, name);
  }, [onUpdateCardName]);

  const handleCardDelete = useCallback((cardId: string) => {
    onDeleteCard(cardId);
  }, [onDeleteCard]);

  const handleCardAdd = useCallback((row: number, column: number) => {
    if (!cards) {
      return;
    }

    const rowCards = cards
      .filter((card) => card.row === row)
      .sort((firstCard, secondCard) => firstCard.position - secondCard.position);

    const position = getPosition(rowCards, column);

    onCreateCard(position, row);
  }, [cards, onCreateCard]);

  const handleVariableConfigurationAdd = useCallback((cardId: string, column: number, row: number, variable: Variable) => {
    const targetCard = cards?.find((card) => card.id === cardId);

    if (!targetCard) {
      return;
    }

    const targetColumn = targetCard.fields
      .filter((field) => field.column === column)
      .sort((firstField, secondField) => firstField.position - secondField.position);

    const position = getPosition(targetColumn, row);

    onCreateVariableConfiguration(cardId, column, position, variable);
  }, [cards, onCreateVariableConfiguration]);

  const handleVariableConfigurationDelete = useCallback((variableConfiguration: VariableConfigurationType) => {
    onDeleteVariableConfiguration(variableConfiguration);
  }, [onDeleteVariableConfiguration]);

  const handleVariableConfigurationReorder = useCallback((result: DropResult) => {
    const { destination, source } = result;

    if (!destination || !cards) {
      return;
    }

    const [cardId, columnIndex] = destination.droppableId.split('-');
    const [sourceCardId, sourceColumnIndex] = source.droppableId.split('-');

    const sourceCard = cards.find((card) => card.id === sourceCardId);
    const targetCard = cards.find((card) => card.id === cardId);

    if (!targetCard || !sourceCard) {
      return;
    }

    const sourceColumn = sortByPosition(
      sourceCard.fields.filter((field) => field.column === Number(sourceColumnIndex)),
    );

    const variableConfigurationToReorder = sourceColumn[source.index];
    const variableConfigurationIdToReorder = variableConfigurationToReorder.id;

    const targetColumn = sortByPosition(
      targetCard.fields.filter((field) => field.column === Number(columnIndex) && field.id !== variableConfigurationIdToReorder),
    );

    const position = getPosition(targetColumn, destination.index);

    onUpdateVariableConfigurationPosition(variableConfigurationToReorder, targetCard.id, sourceCard.id, position, Number(columnIndex));
  }, [cards, onUpdateVariableConfigurationPosition]);

  const handleAddVariableButtonClick = () => {
    setVariablesPaneOpen(true);
  };

  const handleClosePane = useCallback(() => {
    setVariablesPaneOpen(false);
  }, []);

  const openConditionalDisplayRuleBuilderPopup = useCallback((variableConfiguration: VariableConfigurationType) => {
    setBuildConditionalDisplayRuleFor(variableConfiguration);
  }, []);

  const handleBuildVariableConfigurationConditionalDisplayRule = useCallback(
    useBlockingConditionalRuleBuildingCallback(async (conditionalDisplayRule: ConditionalDisplayRuleBuildParams) => {
      if (!buildConditionalDisplayRuleFor) {
        return;
      }

      await onEditVariableConfigurationConditionalDisplayRule(buildConditionalDisplayRuleFor, conditionalDisplayRule);

      setBuildConditionalDisplayRuleFor(null);
    }),
    [buildConditionalDisplayRuleFor, onEditVariableConfigurationConditionalDisplayRule],
  );

  const deleteVariableConfigurationDisplayRule = useCallback(
    useBlockingConditionalRuleDeleteCallback(async (variableConfiguration: VariableConfigurationType) => {
      await onEditVariableConfigurationConditionalDisplayRule(variableConfiguration, null);
    }),
    [onEditVariableConfigurationConditionalDisplayRule],
  );

  const handleDeleteVariableConfigurationConditionalDisplayRule = useCallback(
    useConfirmDeleteConfigurableDisplayRule(async (variableConfiguration: VariableConfigurationType) => {
      await deleteVariableConfigurationDisplayRule(variableConfiguration);

      resetConfirmDeleteConfigurableDisplayRule();
    }),
    [deleteVariableConfigurationDisplayRule],
  );

  const renderActions = () => {
    return (
      <div>
        <Button
          className={styles.addVariableButton}
          disabled={!cards || !cards.length}
          kind="secondary"
          onClick={handleAddVariableButtonClick}
        >
          {allowStandardVariables ? 'Add Variable' : 'Add Custom Variable'}
        </Button>
      </div>
    );
  };

  const renderLayoutContent = () => {
    if (!cardsLayout || displaySkeleton) {
      return <SkeletonCardsLayout maxColumnsPerCard={maxColumnsPerCard} layout={skeletonCardsLayout} />;
    }

    if (!cardsLayout.length) {
      return <AddCardButton onClick={() => handleCardAdd(0, 0)} />;
    }

    return (
      <VariablesConfiguration
        variablesPaneOpen={variablesPaneOpen}
        onClosePane={handleClosePane}
        onReorder={handleVariableConfigurationReorder}
        onAddVariable={handleVariableConfigurationAdd}
        allowStandardVariables={allowStandardVariables}
        disabledVariablesIds={usedVariablesIds}
        getListVariableAttributes={getListVariableAttributes}
      >
        {(placeholderStyles, droppableType, draggingId, sourceDroppableId) => (
          <div className={styles.rowsContainer}>
            {cardsLayout.map((cardsRow, index) => (
              <CardsRow<VariableConfigurationType, CardType, CalculationType>
                key={index}
                cards={cardsRow}
                calculations={calculations}
                droppableType={droppableType}
                sourceDroppableId={sourceDroppableId}
                draggingId={draggingId}
                placeholderStyles={placeholderStyles}
                onCardAdd={handleCardAdd}
                onCardNameUpdate={handleCardNameUpdate}
                onCardDelete={handleCardDelete}
                onDeleteVariableConfiguration={handleVariableConfigurationDelete}
                rowIndex={index}
                maxCardsPerRow={maxCardsPerRow}
                maxCardsPerColumn={maxCardsPerColumn}
                maxVariablesPerCard={maxVariablesPerCard}
                maxColumnsPerCard={maxColumnsPerCard}
                formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
                onChangeVariableConfigurationRequiredAttribute={onChangeVariableConfigurationRequiredAttribute}
                onAddVariableConfigurationConditionalDisplayRule={openConditionalDisplayRuleBuilderPopup}
                onEditVariableConfigurationConditionalDisplayRule={openConditionalDisplayRuleBuilderPopup}
                onDeleteVariableConfigurationConditionalDisplayRule={handleDeleteVariableConfigurationConditionalDisplayRule}
              />
            ))}
          </div>
        )}
      </VariablesConfiguration>
    );
  };

  return (
    <div className={clsx(styles.container, className)}>
      {children(renderActions, renderLayoutContent)}
      {buildConditionalDisplayRuleFor && <ConditionalDisplayRuleBuilderPopup
        conditionalDisplayRule={buildConditionalDisplayRuleFor.conditionalDisplayRule}
        variableName={buildConditionalDisplayRuleFor.variable.displayName}
        onClose={() => setBuildConditionalDisplayRuleFor(null)}
        onSave={handleBuildVariableConfigurationConditionalDisplayRule}
        isRuleBuildingInProgress={isConditionalRuleBuildingInProgress}
      />}
      {displayDeleteConfigurableDisplayRulePopup && <ConfirmPopup
        title="Remove Display Condition?"
        message="Are you sure you want to remove display condition for this variable?"
        confirmText="Yes, Remove Condition"
        declineText="No, Go Back"
        onPopupClose={resetConfirmDeleteConfigurableDisplayRule}
        onConfirmClick={onConfirmDeleteConfigurableDisplayRule}
        loading={isConditionalRuleDeleteInProgress}
      />}
    </div>
  );
};

export default CardsLayoutConfiguration;
