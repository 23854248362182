import React, { FocusEvent } from 'react';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import CardComponent from 'components/LoanOriginationSystem/CardsForm/Card';
import styles from './CardsRow.module.scss';

export interface CardsRowProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
> {
  cards: CardType[];
  data: FormLayoutData;
  onFieldChange: (variableConfiguration: VariableConfigurationType, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfigurationType, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfigurationType, event?: FocusEvent<HTMLInputElement>) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  loaderStateById?: Record<string, LoaderState | null | undefined>;
  onLoaderStateReset?: (variableConfiguration: VariableConfigurationType) => void;
  columnsPerCards?: number;
  isEditMode?: boolean;
  calculations?: CalculationType[] | null;
  displayFieldsAttributes?: Record<string, boolean>;
  rowIndex: number;
}

const CardsRow = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  cards,
  data,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  loaderStateById,
  onLoaderStateReset,
  isEditMode,
  columnsPerCards,
  formatVariableConfigurationDisplayTitle,
  displayFieldsAttributes,
  rowIndex,
  calculations,
}: CardsRowProps<VariableConfigurationType, CardType, CalculationType>) => {
  const renderCard = (card: CardType, index: number) => (
    <CardComponent
      card={card}
      cardIndex={index}
      key={card.id}
      data={data}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      onFieldFocus={onFieldFocus}
      loaderStateById={loaderStateById}
      onLoaderStateReset={onLoaderStateReset}
      isEditMode={isEditMode}
      columnsPerCards={columnsPerCards}
      formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
      displayFieldsAttributes={displayFieldsAttributes}
      rowIndex={rowIndex}
      calculations={calculations}
    />
  );

  return <div className={styles.row}>{cards.map(renderCard)}</div>;
};

export default CardsRow;
