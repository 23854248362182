import { AnyAction } from 'redux';
import { History } from 'history';
import { LoanOriginationSystemApplicationTabsActionTypes } from 'LoanOriginationSystemApplicationTabs/ActionTypes';
import { CreateApplicationTabSuccessAction } from 'LoanOriginationSystemApplicationTabs/ActionCreator';

const isCreateApplicationTabSuccessAction = (action: AnyAction): action is CreateApplicationTabSuccessAction =>
  action.type === LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess;

const CreateApplicationTabSuccessMiddleware = (history: History) => () => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateApplicationTabSuccessAction(action)) {
    history.push(`?tab-id=${action.payload.tab.id}`);
  }

  return result;
};

export default CreateApplicationTabSuccessMiddleware;
