import Fetcher from 'middlewares/Fetcher';
import { GetDecisionsCountAction, getDecisionsCountFailed, getDecisionsCountSuccess } from './ActionCreator';
import BillingPageActionType from './ActionTypes';
import DecisionApi from 'api/DecisionEngine/DecisionApi';

const GetDecisionsCountMiddleware = (api: DecisionApi) =>
  Fetcher<number, GetDecisionsCountAction>(
    BillingPageActionType.GetDecisionsCount,
    getDecisionsCountSuccess,
    getDecisionsCountFailed,
    async (action) => {
      return api.getDecisionRunsCount({
        createdAtFrom: action.payload.createdDate.from,
        createdAtTo: action.payload.createdDate.to,
      });
    },
  );

export default GetDecisionsCountMiddleware;
