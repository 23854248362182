import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemApplicationTabsActionTypes } from 'LoanOriginationSystemApplicationTabs/ActionTypes';
import {
  CreateApplicationTabAction,
  CreateApplicationTabSuccessAction,
  DeleteApplicationTabAction,
  DeleteApplicationTabSuccessAction,
  UpdateApplicationTabAction,
  UpdateApplicationTabSuccessAction,
} from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import { getDocumentFolders } from 'ProductConfigurationDocumentFolders/ActionCreator';
import { LoanOriginationSystemProductApplicationSetupState } from './Types';
import { SetTabToDeleteAction } from './ActionCreator';
import { ProductApplicationSetupActionType } from './ActionTypes';
import {
  createApplicationDataTabCard,
  deleteApplicationDataTabCard,
  updateApplicationDataTabCard,
} from 'ApplicationDataTabCards/Thunks';
import {
  createApplicationDataTabVariableConfiguration,
  deleteApplicationDataTabVariableConfiguration,
  updateApplicationDataTabVariableConfigurationPosition,
} from 'ApplicationDataTabVariableConfigurations/Thunks';

export const initialState: LoanOriginationSystemProductApplicationSetupState = {
  tabToDelete: null,
  isTabDeletingInProgress: false,
  isBlockingUpdateInProgress: false,
};

type LoanOriginationSystemProductApplicationSetupActions =
  | SetTabToDeleteAction
  | CreateApplicationTabAction
  | UpdateApplicationTabAction
  | DeleteApplicationTabAction
  | CreateApplicationTabSuccessAction
  | UpdateApplicationTabSuccessAction
  | DeleteApplicationTabSuccessAction
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabFailure>
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabFailure>
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabFailure>;

const loanOriginationSystemProductApplicationSetupReducer = (
  state = initialState,
  action: LoanOriginationSystemProductApplicationSetupActions,
) => {
  switch (action.type) {
    case ProductApplicationSetupActionType.SetTabToDelete: {
      return {
        ...state,
        tabToDelete: action.payload,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabSuccess: {
      return {
        ...state,
        tabToDelete: null,
        isTabDeletingInProgress: false,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabSuccess: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTab: {
      return {
        ...state,
        isTabDeletingInProgress: true,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTab:
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTab:
    case createApplicationDataTabCard.pending.type:
    case updateApplicationDataTabCard.pending.type:
    case deleteApplicationDataTabCard.pending.type:
    case createApplicationDataTabVariableConfiguration.pending.type:
    case updateApplicationDataTabVariableConfigurationPosition.pending.type:
    case deleteApplicationDataTabVariableConfiguration.pending.type:
    case getDocumentFolders.pending.type: {
      return {
        ...state,
        isBlockingUpdateInProgress: true,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabFailure: {
      return {
        ...state,
        isTabDeletingInProgress: false,
      };
    }
    case createApplicationDataTabCard.fulfilled.type:
    case updateApplicationDataTabCard.fulfilled.type:
    case deleteApplicationDataTabCard.fulfilled.type:
    case createApplicationDataTabVariableConfiguration.fulfilled.type:
    case updateApplicationDataTabVariableConfigurationPosition.fulfilled.type:
    case deleteApplicationDataTabVariableConfiguration.fulfilled.type:
    case getDocumentFolders.fulfilled.type:
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabFailure:
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabFailure:
    case createApplicationDataTabCard.rejected.type:
    case updateApplicationDataTabCard.rejected.type:
    case deleteApplicationDataTabCard.rejected.type:
    case createApplicationDataTabVariableConfiguration.rejected.type:
    case updateApplicationDataTabVariableConfigurationPosition.rejected.type:
    case deleteApplicationDataTabVariableConfiguration.rejected.type:
    case getDocumentFolders.rejected.type: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemProductApplicationSetupReducer;
