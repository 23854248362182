import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { NormalizedApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import {
  getApplicationDataTabCards,
  createApplicationDataTabCard,
  updateApplicationDataTabCard,
  deleteApplicationDataTabCard,
} from './Thunks';
import {
  createApplicationDataTabVariableConfiguration,
  deleteApplicationDataTabVariableConfiguration,
  updateApplicationDataTabVariableConfigurationPosition,
} from 'ApplicationDataTabVariableConfigurations/Thunks';
import {
  addVariableConfigurationToCard,
  deleteVariableConfigurationFromCard,
  updateCardsStore,
  updateVariableConfigurationOnCards,
} from 'Cards/Utils';

export interface ApplicationDataTabCardsState {
  cardsById: Record<string, NormalizedApplicationDataTabCard>;
  cardsLoadedByTabId: Record<string, boolean>;
}

const initialState: ApplicationDataTabCardsState = {
  cardsById: {},
  cardsLoadedByTabId: {},
};

const applicationDataTabCardsReducer = createReducer<ApplicationDataTabCardsState>(initialState, (builder) => {
  builder.addCase(deleteApplicationDataTabCard.fulfilled, (state, action) => {
    delete state.cardsById[action.payload.id];
  });

  builder.addCase(deleteApplicationDataTabVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfigurationFromCard(state, action.payload.id);
  });

  builder.addCase(updateApplicationDataTabVariableConfigurationPosition.fulfilled, (state, action) => {
    updateVariableConfigurationOnCards(state, action.payload.sourceCardId, action.payload.configuration);
  });

  builder.addCase(createApplicationDataTabVariableConfiguration.fulfilled, (state, action) => {
    addVariableConfigurationToCard(state, action.payload.configuration);
  });

  builder.addCase(getApplicationDataTabCards.fulfilled, (state, action) => {
    updateCardsStore(state, action.payload.applicationDataTabCards);

    state.cardsLoadedByTabId[action.payload.tabId] = true;
  });

  builder.addMatcher(
    isFulfilled(createApplicationDataTabCard, updateApplicationDataTabCard),
    (state, action) => {
      updateCardsStore(state, action.payload.applicationDataTabCards);
    },
  );
});

export default applicationDataTabCardsReducer;
