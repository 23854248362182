import { createAsyncThunk } from '@reduxjs/toolkit';
import WebhookEventsRestApi, {
  WebhookEvent,
  WebhookEventDataFilter,
  WebhookEventSortingType
} from 'api/Webhooks/WebhookEventsApi';
import responseHandlers from 'api/ResponseHandlers';
import { TableViewData } from 'api/Types';
import { WebhookEventsActionType } from './WebhookEventsActionType';

const webhookEventsApi = new WebhookEventsRestApi(responseHandlers);

export enum WebhookEventsActionOrigin {
  WebhookEndpointEventsTable = 'WebhookEndpointEventsTable',
  WebhookEventsTable = 'WebhookEventsTable',
}

export const getWebhookEvents = createAsyncThunk<
  TableViewData<WebhookEvent>,
  { filters: WebhookEventDataFilter, sortingType: WebhookEventSortingType; actionOrigin?: WebhookEventsActionOrigin },
  { fulfilledMeta: { search?: string; actionOrigin?: WebhookEventsActionOrigin } }
>(
  WebhookEventsActionType.GetWebhookEvents,
  async ({ filters, sortingType, actionOrigin }, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await webhookEventsApi.find(filters, sortingType), {
      search: filters.search,
      actionOrigin,
    });
  }
);

export const retryWebhookEvent = createAsyncThunk<{ webhookEventId: string; }, string>(
  WebhookEventsActionType.RetryWebhookEvent,
  async (id) => {
    await webhookEventsApi.retry(id);

    return {
      webhookEventId: id,
    };
  },
);

export const deleteWebhookEvent = createAsyncThunk<
  WebhookEvent,
  { webhookEventId: string, actionOrigin?: WebhookEventsActionOrigin },
  { fulfilledMeta: { actionOrigin?: WebhookEventsActionOrigin } }
>(
  WebhookEventsActionType.DeleteWebhookEvent,
  async ({ webhookEventId, actionOrigin }, thunkAPI) => {
    return thunkAPI.fulfillWithValue(await webhookEventsApi.delete(webhookEventId), {
      actionOrigin,
    });
  },
);
