import React, { FC, useEffect } from 'react';
import styles from './AddModule.module.scss';
import { CloseButtonImage } from 'static/images';
import Button from 'components/Button';
import { batch } from 'react-redux';
import BranchConditions from 'components/BranchConditions';
import { EditExternalIntegrationVariablesPopUpState } from 'EditExternalIntegrationVariablesPopUp/Types';
import RequiredVariablesTable from './Tables/RequiredVariablesTable';
import ReceivedVariablesTable from './Tables/ReceivedVariablesTable';
import { RuleData, RuleType } from 'BranchInfo/Types';
import { BranchNameInput } from 'components/BranchRow';
import { useQueryParams } from 'hooks/useQueryParam';
import { EntityType } from 'RuleCreate/Types';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';

interface ExternalIntegrationProps {
  onClose: () => void;
  name: string;
  getBranchRules: () => void;
  onOpenEditExternalIntegrationVariablesPopUp: () => void;
  getExternalIntegrationInfo: () => void;
  addBranchCondition: () => void;
  externalIntegrationInfo: EditExternalIntegrationVariablesPopUpState;
  conditionsList: RuleData[];
  multipleBranches: boolean;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  strategyId: string;
  isStrategyLocked?: boolean;
  moduleId: string;
  onOpenDeleteRulePopUp: (ruleIndex: number, currentEntityType: EntityType) => void;
  onOpenEditRulePopUp: (data: RuleEditPopUpData) => void;
  conditionsBlockingActionInProgress: boolean;
  isLoading: boolean;
  onOpenAssignOutputVariablePopUp: (assignedOutput: string) => void;
  assignedOutput: string;
}

const ExternalIntegration: FC<ExternalIntegrationProps> = ({
  onClose,
  name,
  getBranchRules,
  onOpenEditExternalIntegrationVariablesPopUp,
  getExternalIntegrationInfo,
  addBranchCondition,
  externalIntegrationInfo,
  conditionsList,
  multipleBranches,
  handleRuleReorder,
  strategyId,
  isStrategyLocked,
  moduleId,
  onOpenDeleteRulePopUp,
  onOpenEditRulePopUp,
  conditionsBlockingActionInProgress,
  isLoading,
  onOpenAssignOutputVariablePopUp,
  assignedOutput,
}) => {
  const params = useQueryParams();

  const branchIndex = parseInt(params.get('branchIndex')!, 10);

  useEffect(() => {
    batch(() => {
      getBranchRules();
      getExternalIntegrationInfo();
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          {name ? (
            <BranchNameInput
              branchName={name}
              moduleId={moduleId}
              branchIndex={branchIndex}
              strategyId={strategyId}
              isStrategyLocked={isStrategyLocked}
              className={styles.title}
            />
          ) : (
            <SkeletonRectangle width="600px" height="44px" color="primary6" />
          )}
        </div>
        <CloseButtonImage className={styles.closeImage} onClick={onClose} />
      </div>
      <div className={styles.content}>
        <RequiredVariablesTable
          externalIntegrationInfo={externalIntegrationInfo}
          isLoading={isLoading && !assignedOutput}
        />
        <Button onClick={onOpenEditExternalIntegrationVariablesPopUp} size="form" className={styles.editButton}>
          Edit
        </Button>
        <ReceivedVariablesTable
          externalIntegrationInfo={externalIntegrationInfo}
          isLoading={isLoading && !assignedOutput}
          onOpenAssignOutputVariablePopUp={onOpenAssignOutputVariablePopUp}
        />
        <div className={styles.branchCondition}>
          <BranchConditions
            strategyId={strategyId}
            addBranchCondition={addBranchCondition}
            branchConditions={conditionsList}
            multipleBranches={multipleBranches}
            handleRuleReorder={handleRuleReorder}
            openDeleteBranchPopUp={onOpenDeleteRulePopUp}
            onOpenEditBranchPopUp={onOpenEditRulePopUp}
            conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
            isLoading={isLoading && !assignedOutput}
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalIntegration;
