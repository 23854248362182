import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

const getCalculationIds = (_: ReduxState, calculationIds: string[] | null) => calculationIds;

const getCalculationsById = (state: ReduxState) => state.productCalculations.calculationsById;

export const getProductCalculationsByIdsSelector = createSelector(
  [getCalculationsById, getCalculationIds],
  (calculationsById, calculationIds) => {
    if (!calculationIds) {
      return null;
    }

    return calculationIds.map((calculationId) => calculationsById[calculationId]);
  },
);
