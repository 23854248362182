import { NormalizedStatus, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';

const statusList = new Map<SystemStatus, NormalizedStatus>([
  ['active', NormalizedStatus.Active],
  [null, NormalizedStatus.Inactive],
  ['testing', NormalizedStatus.Testing],
  ['archive', NormalizedStatus.Archive],
]);

const getNormalizedStatus = (status: SystemStatus) => {
  const normalizedStatus = statusList.get(status);
  if (!normalizedStatus) {
    return NormalizedStatus.Unknown;
  }

  return normalizedStatus;
}

export default getNormalizedStatus;
