import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, makeStyles } from '@material-ui/core';
import { CheckboxChecked, CheckboxUnchecked } from 'static/images';
import clsx from 'clsx';
import styles from './CustomCheckbox.module.scss';
import Spinner from 'components/Spinner';

const useStyles = makeStyles({
  root: {
    '&.MuiCheckbox-colorSecondary:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled svg': {
      fill: styles.checkboxBackgroundColor,
      '& > rect': {
        fill: styles.iconBorderColor,
        stroke: styles.iconBorderColor,
      },
      '& > path': {
        fill: styles.iconColor,
      },
    },
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 12,
  },
  label: {
    '& .MuiTypography-root': {
      fontFamily: styles.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: styles.labelColor,
    },
  },
});

interface CustomCheckboxProps extends CheckboxProps {
  label?: React.ReactNode;
  isLoading?: boolean;
  labelClassName?: string;
  containerClassName?: string;
}

const CustomCheckbox = ({
  label,
  isLoading,
  labelClassName,
  className,
  containerClassName,
  ...checkboxProps
}: CustomCheckboxProps) => {
  const classes = useStyles();

  const checkbox = (
    <Checkbox
      className={clsx(className, classes.root)}
      icon={<CheckboxUnchecked />}
      checkedIcon={<CheckboxChecked />}
      disableRipple
      {...checkboxProps}
    />
  );

  const renderCheckbox = () => {
    return isLoading ? <Spinner className={styles.spinner} size={20} /> : checkbox;
  };

  return label ? (
    <FormControlLabel
      control={checkbox}
      label={label}
      classes={{ label: labelClassName }}
      className={clsx(classes.label, containerClassName)}
    />
  ) : (
    renderCheckbox()
  );
};

export default CustomCheckbox;
