import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import TabSwitch from 'components/TabSwitch';
import ProductConfigurationSubHeader from 'components/LoanOriginationSystem/ProductConfiguration/ProductConfigurationSubHeader';
import ApplicationAutomationTab from './ApplicationAutomationTab';
import CalculatedVariables from './CalculatedVariables';
import styles from './ApplicationAutomation.module.scss';

const TABS = Object.values(ApplicationAutomationTab).map((tab) => ({
  label: tab,
  id: tab,
}));

const ApplicationAutomation = () => {
  const { product: selectedProduct } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration,
  );
  const [selectedTab, setSelectedTab] = useState(ApplicationAutomationTab.CalculatedVariables);

  const renderQuestionTooltip = () => (
    <>
      <p>Automated actions when Applications are</p>
      <p>created and calculations that keep Application</p>
      <p>data constantly updated</p>
    </>
  );

  const renderStep = () => {
    return <CalculatedVariables product={selectedProduct} />;
  };

  return (
    <>
      <ProductConfigurationSubHeader
        className={styles.subheader}
        renderQuestionTooltip={renderQuestionTooltip}
        title="Application Automation"
      />
      <TabSwitch
        className={styles.tabsSwitch}
        tabClassName={styles.tab}
        tabs={TABS}
        selectedTabId={selectedTab}
        onSelect={(tab) => setSelectedTab(tab.id as ApplicationAutomationTab)}
        displayBorder
      />
      <div className={styles.applicationAutomationContainer}>{renderStep()}</div>
    </>
  );
};

export default ApplicationAutomation;
