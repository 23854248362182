import React, { useMemo, useState, useEffect } from 'react';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import FilterButton from 'components/FilterButton';
import ViewSwitcher from 'components/LoanOriginationSystem/ApplicationsDashboard/ViewSwitcher';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import styles from './Header.module.scss';
import ProductListDropdown from 'components/LoanOriginationSystem/ApplicationsDashboard/Header/ProductListDropdown';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import SortBySelect from 'components/LoanOriginationSystem/ApplicationsDashboard/Header/SortBySelect/SortBySelect';
import {
  ColumnViewSortOptions,
  COLUMN_VIEW_SORT_OPTIONS,
} from 'LoanOriginationSystemApplications/ColumnViewSortOptions';
import { ApplicationsSortingType, ApplicationSortingField } from 'api/Types';
import { debounce } from 'lodash';

interface HeaderProps {
  viewType: ViewType;
  selectedProduct: Product | null;
  members: UserInfo[];
  searchInputValue: string;
  selectedMembers: UserInfo[];
  productsList: Product[];
  filtersChanged: boolean;
  subTitleHidden: boolean;
  areApplicationsLoading: boolean;
  columnViewSortingType: ApplicationsSortingType;
  onMemberFilterChange: (members: UserInfo[]) => void;
  onSearch: (search: string) => void;
  onViewTypeChange: (viewType: ViewType) => void;
  onProductSelect: (product: Product) => void;
  openFiltersPopup: () => void;
  onCreateNewApplication: () => void;
  onColumnViewSortingTypeChange: (option: ApplicationsSortingType) => void;
}

const SEARCH_INPUT_DEBOUNCE_TIME = 300;

const Header = ({
  viewType,
  selectedProduct,
  members,
  searchInputValue,
  selectedMembers,
  productsList,
  filtersChanged,
  subTitleHidden,
  columnViewSortingType,
  onMemberFilterChange,
  onSearch,
  onViewTypeChange,
  onProductSelect,
  openFiltersPopup,
  onCreateNewApplication,
  onColumnViewSortingTypeChange,
  areApplicationsLoading,
}: HeaderProps) => {
  const [inputValue, setInputValue] = useState(searchInputValue);
  const debouncedOnSearch = useMemo(() => {
    return debounce(onSearch, SEARCH_INPUT_DEBOUNCE_TIME);
  }, [onSearch]);

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedOnSearch(event.target.value);
  };

  const onSearchClear = () => {
    setInputValue('');
    onSearch('');
  };

  useEffect(() => {
    setInputValue(searchInputValue);
  }, [searchInputValue]);

  const sortOptions = useMemo(
    () =>
      COLUMN_VIEW_SORT_OPTIONS.map((option) =>
        option.field === ApplicationSortingField.SearchRelevance
          ? {
              ...option,
              ...(!searchInputValue
                ? {
                    shouldShowTooltip: true,
                    disabled: true,
                    className: styles.disabledOption,
                  }
                : {}),
            }
          : option,
      ),
    [searchInputValue],
  );
  const selectedSortOption = COLUMN_VIEW_SORT_OPTIONS.find(
    ({ field, ascending }) => field === columnViewSortingType.field && ascending === columnViewSortingType.ascending,
  )!;

  const handleOnColumnViewSortingTypeChange = (option: ColumnViewSortOptions) => {
    if (option.field === ApplicationSortingField.SearchRelevance && !searchInputValue) {
      return;
    }

    const sortingType = {
      field: option.field,
      ascending: option.ascending,
    };

    onColumnViewSortingTypeChange(sortingType);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {selectedProduct && (
          <ProductListDropdown selectedProduct={selectedProduct} source={productsList} onSelect={onProductSelect} />
        )}
        <Button
          kind="primary"
          size="default"
          className={styles.title__newApplication}
          onClick={onCreateNewApplication}
          disabled={!productsList.length}
        >
          New Application
        </Button>
      </div>
      {!subTitleHidden && (
        <div className={styles.subTitle}>
          <SearchInput
            placeholder="Search"
            containerClassName={styles.subTitle__search}
            value={inputValue}
            onChange={onSearchInputChange}
            onClear={onSearchClear}
            showLoader={!!inputValue && areApplicationsLoading}
          />
          <div className={styles.subTitle__memberFilter}>
            <UserFilter users={members} selectedUsers={selectedMembers} onChange={onMemberFilterChange} />
          </div>
          <FilterButton onClick={openFiltersPopup} active={filtersChanged} />
          {viewType === ViewType.Column && (
            <SortBySelect
              onSelect={handleOnColumnViewSortingTypeChange}
              selectedOption={selectedSortOption}
              className={styles.sortBySelect}
              options={sortOptions}
            />
          )}
          <ViewSwitcher viewType={viewType} onChange={onViewTypeChange} />
        </div>
      )}
    </div>
  );
};

export default Header;
