import DecisionEngineSystemApi from './DecisionEngineSystemApi';
import { Strategy } from 'DecisionStrategy/DecisionStrategiesTypes';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { DecisionResultsSortingFieldType } from 'Decisions/DecisionResultsStore';

export interface DecisionResultListItem {
  id: string;
  name: string;
  strategyName: string;
  strategyVersion: number;
  status: DecisionResultType;
  decisionId: string;
  decisionName: string;
  executionTime: number;
}

export interface GroupedByStatusFilterOption {
  status: DecisionResultType;
  count: number;
}

export interface DecisionResultListFilters {
  groupedByStatus: GroupedByStatusFilterOption[];
  filtersTotal: number;
}

export interface DecisionResultsListData extends TableViewData<DecisionResultListItem> {
  filters: DecisionResultListFilters;
}

interface GetDecisionResultsFilters {
  offset: number;
  count: number;
  decisionId: string;
  resultType?: DecisionResultType;
}

export interface IDecisionResultApi {
  getDecisionResults(filters: GetDecisionResultsFilters, sortingType: DecisionResultsSortingFieldType): Promise<DecisionResultsListData>;
}

class DecisionResultApi extends DecisionEngineSystemApi<Strategy> implements IDecisionResultApi {
  protected resourceName = 'decision-results';

  public async getDecisionResults(
    { offset, count, decisionId, resultType }: GetDecisionResultsFilters,
    sortingType: DecisionResultsSortingFieldType,
    abortSignal?: AbortSignal,
  ) {
    const params = new URLSearchParams();
    params.set('decisionId', decisionId);
    params.set('offset', offset.toString());
    params.set('count', count.toString());
    params.set('sortField', sortingType.field);
    params.set('sortDirection', sortingType.ascending ? 'asc' : 'desc');

    if (resultType) {
      params.append('resultType', resultType);
    }

    return this.fetch<DecisionResultsListData>(`/decision-results?${params}`, 'GET', undefined, {
      abortSignal,
    });
  }

  public async download(resultId: string) {
    return super.download(`/decision-result-downloads/${resultId}`);
  }

  public async downloadMany(decisionId: string) {
    const params = new URLSearchParams();

    params.set('decision-id', decisionId);
    return super.download(`/decision-result-downloads?${params}`);
  }
}

export default DecisionResultApi;
