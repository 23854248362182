import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { UPDATE_STRATEGY_NAME_REQUEST, UPDATE_STRATEGY_NAME_REQUEST_SUCCESS } from './ActionTypes';
import {
  UpdateStrategyNameRequestAction,
  getDecisionStrategyInfoRequest,
  updateStrategyNameFailure,
  updateStrategyNameSuccess,
  UpdateStrategyNameRequestSuccessAction,
} from './DecisionStrategiesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { batch } from 'react-redux';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { DecisionEngineApi } from 'api/Types';
import { getVersionsListData } from 'VersionsViewData/Actions';

export const StrategyNameMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<{ strategyName: string; strategyId: string }, UpdateStrategyNameRequestAction>(
    UPDATE_STRATEGY_NAME_REQUEST,
    updateStrategyNameSuccess,
    updateStrategyNameFailure,
    async ({ payload: strategyInfo }) => {
      await api.updateStrategyName(strategyInfo.id, strategyInfo.name);
      return {
        strategyId: strategyInfo.id,
        strategyName: strategyInfo.name,
      };
    },
  );

const isRenameStrategySuccessAction = (action: AnyAction): action is UpdateStrategyNameRequestSuccessAction =>
  action.type === UPDATE_STRATEGY_NAME_REQUEST_SUCCESS;

export const onRenameStrategySuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isRenameStrategySuccessAction(action)) {
    batch(() => {
      const { strategyId, strategyName } = action.payload;
      dispatch(getVersionsListData({ strategyName }));
      dispatch(getDecisionStrategyInfoRequest(strategyId));
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(
        getMessage(MessageType.StrategyNameUpdated, {
          strategyName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};
