import React, { ComponentType, FC, useState } from 'react';
import SelectableItem from 'components/SelectableItem';
import { MultipleDecisionIcon, SingleDecisionIcon } from 'static/images';
import styles from './RunDecisionPop.module.scss';
import Button from 'components/Button';
import { RunDecisionPopUpType } from 'Decisions/RunDecisionStore';

export interface RunDecisionType {
  image: ComponentType;
  title: string;
  description: string;
  type: 'single' | 'multiple';
}

interface SelectDecisionRunPopupProps {
  changeOpenRunDecisionPopUpType: (popUpType: RunDecisionPopUpType) => void;
}

const RUN_DECISION_TYPES = [
  {
    image: SingleDecisionIcon,
    title: 'Single Decision',
    description: 'Provide inputs and generate a single decision',
    type: 'single',
  },
  {
    image: MultipleDecisionIcon,
    title: 'Multiple Decisions',
    description: 'Upload a CSV with multiple inputs and generate multiple results',
    type: 'multiple',
  },
] as RunDecisionType[];

const SelectDecisionRunPopup: FC<SelectDecisionRunPopupProps> = ({ changeOpenRunDecisionPopUpType }) => {
  const [activeType, setActiveType] = useState<string>('single');

  const handleSetActiveType = (type: 'single' | 'multiple'): void => setActiveType(type);

  return (
    <div className={styles.selectDecisionRunContainer}>
      {RUN_DECISION_TYPES.map((runDecisionType: RunDecisionType, index: number) => {
        const { image, title, description, type } = runDecisionType;
        return (
          <SelectableItem
            key={index}
            Image={image}
            title={title}
            description={description}
            onClick={handleSetActiveType}
            selected={type === activeType}
            type={type}
            imageClassName={type === activeType ? styles.typeImageActive : styles.typeImage}
          />
        );
      })}
      <Button
        size="form"
        className={styles.continueButton}
        onClick={() => changeOpenRunDecisionPopUpType(activeType as RunDecisionPopUpType)}
      >
        Continue
      </Button>
    </div>
  );
};

export default SelectDecisionRunPopup;
