import React, { useCallback } from 'react';
import clsx from 'clsx';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { ConditionalDisplayRuleBuildParams } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';
import { Variable } from 'Variables/VariablesTypes';
import CardsLayoutConfiguration from 'components/LoanOriginationSystem/CardsLayoutConfiguration';
import { ListVariableAttributes } from 'components/LoanOriginationSystem/VariablesConfiguration/VariablesPane/VariablesPane';
import ViewLayoutConfigurationHeader from './ViewLayoutConfigurationHeader';
import LoadingMask from 'components/LoadingMask';
import useBlockingRequest from 'hooks/useBlockingRequest';
import styles from './ViewLayoutConfiguration.module.scss';

export interface ViewLayoutConfigurationProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
  > {
  title: React.ReactNode;
  cards: CardType[] | null;
  calculations?: CalculationType[] | null;
  onCardAdd: (position: number, row: number) => Promise<void>;
  onCardNameUpdate: (cardId: string, name: string) => Promise<void>;
  onCardDelete: (cardId: string) => Promise<void>;
  onVariableConfigurationPositionUpdate: (
    variableConfiguration: VariableConfigurationType,
    targetCardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => Promise<void>;
  onVariableConfigurationCreate: (
    cardId: string,
    column: number,
    position: number,
    variable: Variable,
  ) => Promise<void>;
  onChangeVariableConfigurationRequiredAttribute?: (variableConfiguration: VariableConfigurationType, required: boolean) => void;
  onEditVariableConfigurationConditionalDisplayRule: (
    variableConfiguration: VariableConfigurationType,
    conditionalDisplayRule: ConditionalDisplayRuleBuildParams | null,
  ) => Promise<void>;
  onVariableConfigurationDelete: (variableConfiguration: VariableConfigurationType) => Promise<void>;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  allowStandardVariables?: boolean;
  className?: string;
  getListVariableAttributes?: (variable: Variable) => ListVariableAttributes;
}

const MAX_CARDS_PER_ROW = 1;
const MAX_COLUMNS_PER_CARD = 2;

const ViewLayoutConfiguration = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  CalculationType extends BaseCalculation,
>({
  title,
  cards,
  onCardAdd,
  onCardDelete,
  onCardNameUpdate,
  onVariableConfigurationPositionUpdate,
  onVariableConfigurationCreate,
  onVariableConfigurationDelete,
  formatVariableConfigurationDisplayTitle,
  allowStandardVariables,
  onChangeVariableConfigurationRequiredAttribute,
  onEditVariableConfigurationConditionalDisplayRule,
  className,
  getListVariableAttributes,
  calculations,
}: ViewLayoutConfigurationProps<VariableConfigurationType, CardType, CalculationType>) => {
  const [isBlockingRequestInProgress, useBlockingRequestCallback] = useBlockingRequest();

  const handleCardAdd = useCallback(useBlockingRequestCallback(async (position: number, row: number) => {
    await onCardAdd(position, row);
  }), [onCardAdd]);

  const handleCardNameUpdate = useCallback(async (cardId: string, name: string) => {
    await onCardNameUpdate(cardId, name);
  }, [onCardNameUpdate]);

  const handleCardDelete = useCallback(useBlockingRequestCallback(async (cardId: string) => {
    await onCardDelete(cardId);
  }), [onCardDelete]);

  const handleVariableConfigurationPositionUpdate = useCallback(useBlockingRequestCallback(async (
    variableConfiguration: VariableConfigurationType,
    targetCardId: string,
    sourceCardId: string,
    position: number,
    column: number,
  ) => {
    await onVariableConfigurationPositionUpdate(
      variableConfiguration,
      targetCardId,
      sourceCardId,
      position,
      column,
    );
  }), [onVariableConfigurationPositionUpdate]);

  const handleVariableConfigurationAdd = useCallback(
    useBlockingRequestCallback(async (cardId: string, column: number, position: number, variable: Variable) => {
      await onVariableConfigurationCreate(
        cardId,
        column,
        position,
        variable,
      );
    }),
    [onVariableConfigurationCreate],
  );

  const handleVariableConfigurationDelete = useCallback(
    useBlockingRequestCallback(async (variableConfiguration: VariableConfigurationType) => {
      await onVariableConfigurationDelete(variableConfiguration);
    }),
    [onVariableConfigurationDelete],
  );

  return (
    <CardsLayoutConfiguration
      className={clsx(styles.layout, className)}
      cards={cards}
      onCreateCard={handleCardAdd}
      onUpdateCardName={handleCardNameUpdate}
      onDeleteCard={handleCardDelete}
      onCreateVariableConfiguration={handleVariableConfigurationAdd}
      onDeleteVariableConfiguration={handleVariableConfigurationDelete}
      onUpdateVariableConfigurationPosition={handleVariableConfigurationPositionUpdate}
      formatVariableConfigurationDisplayTitle={formatVariableConfigurationDisplayTitle}
      onChangeVariableConfigurationRequiredAttribute={onChangeVariableConfigurationRequiredAttribute}
      maxCardsPerRow={MAX_CARDS_PER_ROW}
      maxColumnsPerCard={MAX_COLUMNS_PER_CARD}
      allowStandardVariables={allowStandardVariables}
      getListVariableAttributes={getListVariableAttributes}
      onEditVariableConfigurationConditionalDisplayRule={onEditVariableConfigurationConditionalDisplayRule}
      calculations={calculations}
    >
      {(renderActions, renderCardsLayout) => (
        <>
          <ViewLayoutConfigurationHeader
            title={title}
            renderActions={renderActions}
          />
          {renderCardsLayout()}
          {isBlockingRequestInProgress && <LoadingMask />}
        </>
      )}
    </CardsLayoutConfiguration>
  );
};

export default ViewLayoutConfiguration;
