import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import {
  changeRunDecisionStrategy,
  runApplicationDecision,
  requestDropdownStrategies,
  RESET_RUN_DECISION_STATE_ACTION_PREFIX,
  RunDecisionState,
} from 'Decisions/RunDecisionStore';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ApplicationDecisionRunForm from 'components/ApplicationDecisionRunForm/ApplicationDecisionRunForm';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useStateReset from 'hooks/useStateReset';

interface RunApplicationDecisionPopupProps {
  onDecisionRan: (decisionId: string, resultsCount: number) => void;
  application: Application;
}

const RunApplicationDecisionPopup: FC<RunApplicationDecisionPopupProps> = ({ onDecisionRan, application }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  useStateReset(RESET_RUN_DECISION_STATE_ACTION_PREFIX);

  const {
    dropdownStrategies,
    currentStrategyId,
    inputs,
    isLoading,
  } = useSelector<ReduxState, RunDecisionState>((state) => state.runDecision);
  const { product } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage,
  );

  useEffect(() => {
    dispatch(requestDropdownStrategies({ status: 'active' }));
  }, []);

  if (!product) {
    return null;
  }

  const handleRunDecision = async (decisionName: string) => {
    const { title: strategyName, name: strategyDisplayName } = dropdownStrategies.items
      .find(({ id }) => currentStrategyId === id)!;
    const { decisionId, resultsCount } = await dispatchWithUnwrap(runApplicationDecision({
      applicationId: application.id,
      strategyName,
      strategyDisplayName,
      decisionName,
    }));
    onDecisionRan(decisionId, resultsCount);
  }

  const handleStrategyChange = async (strategyId: string) => {
    dispatch(changeRunDecisionStrategy({ strategyId }));
  }

  return (
    <ApplicationDecisionRunForm
      isLoading={isLoading}
      strategies={dropdownStrategies.items}
      currentStrategy={currentStrategyId}
      strategyInputs={inputs.items}
      onRunDecision={handleRunDecision}
      onStrategyChange={handleStrategyChange}
      dataLoading={inputs.isLoading || dropdownStrategies.isLoading}
      inputValues={application.variables}
    />
  );
};

export default RunApplicationDecisionPopup;
