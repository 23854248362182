import React from 'react';
import clsx from 'clsx';
import { BaseDocumentConfiguration } from 'api/LoanOriginationSystem/Base/BaseDocumentConfigurationsApi';
import { ConditionalDisplayRuleIcon, DragImage } from 'static/images';
import UploadFileStub, { UploadFileStubProps } from 'components/UploadFile/UploadFileStub';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './DraggableUploadFileStub.module.scss';

export interface DraggableUploadFileStubProps<DocumentConfigurationType extends BaseDocumentConfiguration> extends UploadFileStubProps {
  documentConfiguration: DocumentConfigurationType;
  isDragging?: boolean;
  onEdit: (documentConfiguration: DocumentConfigurationType) => void;
  onChangeRequiredAttribute: (documentConfiguration: DocumentConfigurationType) => void;
  onDeleteConditionalDisplayRule: (documentConfiguration: DocumentConfigurationType) => void;
  onEditConditionalDisplayRule: (documentConfiguration: DocumentConfigurationType) => void;
  onAddConditionalDisplayRule: (documentConfiguration: DocumentConfigurationType) => void;
  onRemove: (documentConfiguration: DocumentConfigurationType) => void;
}

const DraggableUploadFileStub = <DocumentConfigurationType extends BaseDocumentConfiguration>({
  documentConfiguration,
  isDragging,
  onEdit,
  onChangeRequiredAttribute,
  onRemove,
  required,
  onEditConditionalDisplayRule,
  onAddConditionalDisplayRule,
  onDeleteConditionalDisplayRule,
  ...restProps
}: DraggableUploadFileStubProps<DocumentConfigurationType>) => {
  const conditionalDisplayRuleExists = !!documentConfiguration.conditionalDisplayRule;

  const actions: RowAction[] = [
    {
      title: 'Edit Document',
      handler: () => onEdit(documentConfiguration),
    },
    {
      title: conditionalDisplayRuleExists ? 'Edit Display Condition' : 'Add Display Condition',
      handler: () => {
        if (conditionalDisplayRuleExists) {
          onEditConditionalDisplayRule(documentConfiguration);
        }

        onAddConditionalDisplayRule(documentConfiguration);
      },
    },
    ...(conditionalDisplayRuleExists ? [{
      title: 'Remove Display Condition',
      handler: () => onDeleteConditionalDisplayRule(documentConfiguration),
    }] : []),
    {
      title: required ? 'Make Document Optional' : 'Make Document Required',
      handler: () => onChangeRequiredAttribute(documentConfiguration),
      separatorRequired: true,
    },
    {
      title: 'Remove Document',
      handler: () => onRemove(documentConfiguration),
      danger: true,
    },
  ];

  const renderConditionalDisplayRuleIconTooltip = () => (
    <>
      <p>This document has conditional</p>
      <p>display logic</p>
    </>
  );

  const renderConditionalDisplayRuleIcon = () => (
    <WrapperWithTooltip tooltip={renderConditionalDisplayRuleIconTooltip()}>
      <ConditionalDisplayRuleIcon className={styles.conditionalDisplayRuleIcon} />
    </WrapperWithTooltip>
  );

  return (
    <RowActionsContainer>
      <div className={clsx(styles.draggableUploadFileStub, isDragging && styles.inDragProcessUploadFileStub)}>
        <DragImage className={styles.dragImage} />
        <UploadFileStub required={required} {...restProps} />
        {conditionalDisplayRuleExists && renderConditionalDisplayRuleIcon()}
        <RowActions className={styles.rowActions} popupClassName={styles.actionPopup} actions={actions} />
      </div>
    </RowActionsContainer>
  );
};

export default React.memo(DraggableUploadFileStub) as typeof DraggableUploadFileStub;
