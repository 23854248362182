import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  CreateApplicationAction,
  createApplicationFailed,
  createApplicationSuccess,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { CREATE_APPLICATION } from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import ApplicationAttribute from './ApplicationAttribute';

export interface CreateApplicationProvideResult {
  [ApplicationAttribute.ApplicationDisplayId]: string;
  [ApplicationAttribute.ApplicationId]: string;
}

const CreateApplicationMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, CreateApplicationAction, {}, Error, CreateApplicationProvideResult>(
    CREATE_APPLICATION,
    createApplicationSuccess,
    createApplicationFailed,
    (action) => {
      return api.createApplication(action.payload);
    },
    ApplicationAttribute.Application,
    (response) => ({
      [ApplicationAttribute.ApplicationId]: response.id,
      [ApplicationAttribute.ApplicationDisplayId]: response.displayId,
    }),
  );

export default CreateApplicationMiddleware;
