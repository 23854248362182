import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import Button from 'components/Button';
import ProductStatusTag from 'components/LoanOriginationSystem/ProductConfiguration/ProductStatusTag';
import ProductRowActions from 'components/LoanOriginationSystem/ProductConfiguration/ProductRowActions';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ActionPopUp from 'components/ActionPopUp';
import { CheckedIcon, CheckedImage } from 'static/images';
import styles from './LeftSidePane.module.scss';

export interface LeftSidePaneProps {
  className?: string;
  product?: Product | null;
  selectedStep: ProductConfigurationStep;
  onStepChange: (step: ProductConfigurationStep) => void;
  onProductStatusChange: (status: ProductStatus) => void;
  onEditBasicInformation: () => void;
  onDuplicate: () => void;
  onCloseProductEditor: () => void;
}

interface ProductStep {
  type: ProductConfigurationStep;
  label: string;
}

const PRODUCT_PROCESS_STEPS: ProductStep[] = [
  {
    type: ProductConfigurationStep.ApplicationFormConfiguration,
    label: 'Application Form',
  },
  {
    type: ProductConfigurationStep.ApplicationSetup,
    label: 'Application Views',
  },
  {
    type: ProductConfigurationStep.WorkflowSetup,
    label: 'Application Workflow',
  },
  {
    type: ProductConfigurationStep.ApplicationAutomation,
    label: 'Application Automation',
  },
];

const LeftSidePane: FC<LeftSidePaneProps> = ({
  className,
  product,
  selectedStep,
  onStepChange,
  onProductStatusChange,
  onDuplicate,
  onEditBasicInformation,
  onCloseProductEditor,
}) => {
  const [displayContextualView, setDisplayContextualView] = useState(false);
  const productStatusTagRef = useRef<HTMLInputElement | null>(null);

  const renderIcon = () => {
    if (product?.status !== ProductStatus.Active) {
      return null;
    }

    return <CheckedIcon className={styles.checkedIcon} />;
  };

  const renderStep = (step: ProductStep) => {
    return (
      <div
        key={step.type}
        className={clsx(styles.step, step.type === selectedStep && styles.selectedStep)}
        onClick={() => onStepChange(step.type)}
      >
        {step.label}
        {step.type === selectedStep && <div className={styles.selectedStepRightBorder} />}
      </div>
    );
  };

  const renderPaneHeader = () => {
    if (!product) {
      return (
        <>
          <div className={styles.skeletonTitleWithActionsContainer}>
            <SkeletonRectangle width="200px" height="28px" color="primary6" />
            <SkeletonRectangle width="36px" height="36px" color="primary6" marginLeft="8px" />
          </div>
          <div className={styles.skeletonUpdaterInformationContainer}>
            <SkeletonCircle width="24px" height="24px" color="primary6" marginRight="8px" />
            <SkeletonRectangle width="167px" height="20px" color="primary6" />
          </div>
        </>
      );
    }

    return (
      <>
        <h4 className={styles.productName}>{product?.name}</h4>
        <div className={styles.updaterInformationContainer}>
          <WithSystemApiUserAvatar className={styles.avatar} size={8.57} user={product.updatedBy} />
          <p>{formatDate(product.updatedAt, DateTimeFormat.Short)}</p>
        </div>
        <ProductRowActions
          className={styles.moreButton}
          productStatus={product.status}
          onChangeStatus={onProductStatusChange}
          onDuplicate={onDuplicate}
          onEditBasicInformation={onEditBasicInformation}
        />
      </>
    );
  };

  const renderProductStatusTag = () => {
    if (!product) {
      return <SkeletonRectangle width="232px" height="44px" color="primary6" marginBottom="12px" />;
    }
    
    const productStatusTagClassName = clsx({
      [styles.selectedTag]: displayContextualView,
      [styles.activeTag]: product.status === ProductStatus.Active,
      [styles.draftTag]: product.status === ProductStatus.Draft,
      [styles.archivedTag]: product.status === ProductStatus.Archived,
    });

    return (
      <div ref={productStatusTagRef}>
        <ProductStatusTag
          onClick={() => setDisplayContextualView(true)}
          className={productStatusTagClassName}
          status={product.status}
          icon={renderIcon()}
          useBackgroundForArchived
        />
      </div>
    );
  }

  const actions = [{
    name: 'Active',
    status: ProductStatus.Active,
  }, {
    name: 'Draft',
    status: ProductStatus.Draft,
  }, {
    name: 'Archived',
    status: ProductStatus.Archived,
  }];

  const handleActionPopupItemClick = (status: ProductStatus) => {
    setDisplayContextualView(false);

    if (product?.status === status) {
      return;
    }

    onProductStatusChange(status);
  };

  return (
    <div className={clsx(styles.leftSidePane, className)}>
      <div className={styles.paneHeader}>
        {renderPaneHeader()}
      </div>
      <div>
        <h6 className={styles.stepsTitle}>Loan Product Setup</h6>
        <div className={styles.steps}>
          {PRODUCT_PROCESS_STEPS.map(renderStep)}
        </div>
      </div>
      <div className={styles.bottomSection}>
        {renderProductStatusTag()}
        <Button kind="secondary" onClick={onCloseProductEditor}>Close Product Editor</Button>
      </div>
      <ContextualPopUp
        anchorEl={productStatusTagRef.current}
        open={displayContextualView}
        onClose={() => setDisplayContextualView(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <ActionPopUp hideTitle className={styles.actionsPopup}>
          {actions.map(({ name, status }) => (
            <ActionPopUpItem className={styles.actionPopupItem} key={name} onClick={() => handleActionPopupItemClick(status)}>
              {name}
              {product?.status === status && <CheckedImage />}
            </ActionPopUpItem>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </div>
  )
};

export default LeftSidePane;
