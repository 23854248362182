import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  updateApplicationIntermediarySuccess,
  UpdateApplicationIntermediaryAction,
  updateApplicationIntermediaryFailure,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationIntermediaryMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationIntermediaryAction>(
    ApplicationPageActionType.UpdateApplicationIntermediary,
    updateApplicationIntermediarySuccess,
    updateApplicationIntermediaryFailure,
    (action) => {
      return api.updateApplicationIntermediary(action.payload.applicationId, action.payload.params);
    },
  );

export default UpdateApplicationIntermediaryMiddleware;
