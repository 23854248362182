import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { DataViewSortingType } from 'api/Types';
import { ItemsStateWithPagination } from 'pagination';
import withStateReset from 'utils/reducers/withStateReset';
import decisionResultsPagination from 'pages/Decisions/DecisionResultsPagination';
import responseHandlers from 'api/ResponseHandlers';
import DecisionResultApi, {
  DecisionResultListFilters,
  DecisionResultListItem
} from 'api/DecisionEngine/DecisionResultApi';

export enum DecisionResultsSortingField {
  StrategyName = 'strategyName',
  Name = 'name',
  ExecutionTime = 'executionTime',
  ResultType = 'resultType',
}

export type DecisionResultsSortingFieldType = DataViewSortingType<DecisionResultsSortingField>;

export interface DecisionResultsState extends ItemsStateWithPagination<DecisionResultListItem> {
  sortingType: DecisionResultsSortingFieldType;
  resultType: DecisionResultType | null;
  filters: DecisionResultListFilters;
}

export interface FetchDecisionResultsParams {
  decisionId: string;
  sortingType: DecisionResultsSortingFieldType;
  offset?: number;
  count?: number;
  abortSignal?: AbortSignal;
  resultType?: DecisionResultType;
}

const ITEMS_PER_PAGE_DEFAULT = 20;

const decisionResultsApi = new DecisionResultApi(responseHandlers);

const initialState: DecisionResultsState = {
  itemsTotal: undefined,
  items: [],
  filters: {
    groupedByStatus: [],
    filtersTotal: 0,
  },
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
  sortingType: { field: DecisionResultsSortingField.Name, ascending: true },
  error: null,
  resultType: null,
};

export const RESET_DECISION_RESULTS_STATE_ACTION_PREFIX = 'resetDecisionResultsState';

export const requestDecisionResults = createAsyncThunk(
  'requestDecisionResults',
  async ({ offset, count, decisionId, resultType, sortingType, abortSignal }: FetchDecisionResultsParams) => {
    const results = await decisionResultsApi.getDecisionResults(
      { offset: offset || 0, count: count || ITEMS_PER_PAGE_DEFAULT, decisionId, resultType },
      sortingType,
      abortSignal,
    );
    return results;
  },
);

export const resetDecisionResultsState = createAction(RESET_DECISION_RESULTS_STATE_ACTION_PREFIX);

const decisionResultsReducer = createReducer<DecisionResultsState>(
  initialState,
  (builder) => {
    builder.addCase(requestDecisionResults.pending, (state, action) => {
      state.resultType = action.meta.arg.resultType || null;
      state.sortingType = action.meta.arg.sortingType;
      state.itemsTotal = undefined;
    });
    builder.addCase(requestDecisionResults.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.itemsTotal = action.payload.total;
      state.filters = action.payload.filters;
      state.page = 1;
    });
  },
);

export default decisionResultsPagination.wrapReducer<DecisionResultsState>(
  withStateReset(decisionResultsReducer, RESET_DECISION_RESULTS_STATE_ACTION_PREFIX, () => initialState),
);
