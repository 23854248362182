import { scaleBand } from '@visx/scale';
import { ScaleBandNumber } from './Types';

const useXScale = (domain: number[], width: number): [ScaleBandNumber] => {
  const xScale = scaleBand<number>({
    domain,
    range: [0, width],
    paddingInner: 1,
    paddingOuter: 0,
  });

  return [xScale];
};

export default useXScale;
