import React from 'react';
import PopUp from 'components/PopUps/PopUp';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import AddCalculationScript from 'components/CalculationScripts/PopUps/AddCalculationScript';
import { SaveCalculationData } from 'components/CalculationScripts/PopUps/AddCalculationScript/AddCalculationScript';

export interface EditDeclineReasonsPopupProps {
  calculation?: ProductCalculation | null;
  isSaving?: boolean;
  usePortal?: boolean;
  onClose: () => void;
  onSave: (data: SaveCalculationData) => void;
}

const EditCalculationPopup = ({ calculation, onClose, usePortal, onSave, isSaving }: EditDeclineReasonsPopupProps) => {
  const title = calculation ? 'Edit Calculation' : 'Add Calculation';

  const renderTooltip = () => (
    <>
      <p>This variable will calculate automatically using</p>
      <p>the calculation you provide. Reference other</p>
      <p>variables using their system name and return a</p>
      <p>single value.</p>
    </>
  );

  return (
    <PopUp titleTooltip={renderTooltip()} usePortal={usePortal} closePopUp={onClose} title={title}>
      <AddCalculationScript
        calculation={calculation?.code}
        sourceVariableId={calculation?.variable.id}
        saveCalculationScript={onSave}
        isSaving={isSaving}
        mode="jexl"
      />
    </PopUp>
  );
};

export default EditCalculationPopup;
