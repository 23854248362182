import React from 'react';
import SkeletonHistoryLine from './SkeletonDecisionLine';
import DecisionRow from 'components/Decisions/Table/DecisionRow';
import { SIZES } from 'components/Decisions/Table/columnSizes';
import { NullableItems, PaginationProps } from 'pagination';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableActionCell from 'components/Table/TableActionCell';
import TableBodyContent from 'components/Table/TableBodyContent';
import { RowAction } from 'components/RowActions/RowActions';
import useSorting from 'hooks/useSorting';
import { DecisionsTableColumnDisplayName } from 'components/Decisions/Table/types';
import { Decision } from 'api/DecisionEngine/DecisionApi';
import { DecisionsSortingField, DecisionsSortingFieldType } from 'Decisions/DecisionsStore';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import styles from './DecisionsTable.module.scss';
import clsx from 'clsx';

interface DecisionsTableProps {
  decisions: NullableItems<Decision>;
  paginationProps: PaginationProps;
  getRowActions: (decision: Decision) => RowAction[];
  handleRowClick: (decisionData: Decision) => void;
  sortableFieldKeys?: string[];
  onSort?: (fieldName: DecisionsSortingField, ascending: boolean) => void;
  searchInputValue?: string;
  sortingType?: DecisionsSortingFieldType;
  stickyMarginTop?: number;
  getRowLink?: (decision: Decision) => string;
  embedded?: boolean;
}

const DisplayNameToFieldNameMapping = {
  [DecisionsTableColumnDisplayName.Date]: DecisionsSortingField.Date,
  [DecisionsTableColumnDisplayName.StrategyName]: DecisionsSortingField.StrategyName,
  [DecisionsTableColumnDisplayName.DecisionName]: DecisionsSortingField.DecisionName,
  [DecisionsTableColumnDisplayName.Source]: DecisionsSortingField.Source,
  [DecisionsTableColumnDisplayName.ExecutionTime]: DecisionsSortingField.ExecutionTime,
  [DecisionsTableColumnDisplayName.Result]: DecisionsSortingField.Result,
};

const DecisionsTable = ({
  decisions,
  paginationProps,
  getRowActions,
  sortableFieldKeys = [],
  searchInputValue = '',
  onSort = () => undefined,
  sortingType = { field: DecisionsSortingField.Date, ascending: false },
  stickyMarginTop,
  handleRowClick,
  getRowLink,
  embedded,
}: DecisionsTableProps) => {
  const [changeSorting, getSortingType] = useSorting<DecisionsSortingField>(
    sortingType.field,
    sortingType.ascending,
    onSort,
  );

  const handleChangeSorting = (fieldKeyName: DecisionsSortingField) => {
    changeSorting(fieldKeyName);
  };

  const renderRow = (decision: Decision) => (
    <DecisionRow
      key={decision.id}
      rowLink={getRowLink && getRowLink(decision)}
      getRowActions={getRowActions}
      decision={decision}
      searchInputValue={searchInputValue}
      handleClick={handleRowClick}
    />
  );
  const renderTableHeadCell = (fieldDisplayName: DecisionsTableColumnDisplayName) => {
    const fieldKeyName = DisplayNameToFieldNameMapping[fieldDisplayName];
    return (
      <TableHeadCell
        key={fieldKeyName}
        width={SIZES[fieldDisplayName]}
        {...(sortableFieldKeys.includes(fieldKeyName) ? { onClick: () => handleChangeSorting(fieldKeyName) } : {})}
        ascending={getSortingType(fieldKeyName)}
      >
        {fieldDisplayName}
      </TableHeadCell>
    );
  };
  const renderTable = () => (
    <Table className={clsx(styles.decisionsTable, embedded && styles.decisionsTable__embedded)}>
      <TableHead sticky stickyMarginTop={stickyMarginTop}>
        {Object.values(DecisionsTableColumnDisplayName).map(renderTableHeadCell)}
        <TableActionCell />
      </TableHead>
      <TableBody>
        <TableBodyContent
          rows={decisions}
          renderTableRow={renderRow}
          renderSkeletonTableRow={(index: number) => <SkeletonHistoryLine key={index} />}
        />
      </TableBody>
      <Pagination {...paginationProps} />
    </Table>
  );

  return embedded ? renderTable() : <TableWrapperWithFooter>{renderTable()}</TableWrapperWithFooter>;
};

export default DecisionsTable;
