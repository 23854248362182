import React, { FC, useMemo } from 'react';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import Table from 'components/Table';
import {
  AccessPermissionsUpdatingState,
  ProductSectionAccessPermissions,
  ProductSectionAccessPermissionsStructure,
} from 'ProductSectionAccessPermissions/Types';
import { PermissionGroupId } from 'PermissionGroups/Types';
import AccessPermissionsTableRow, { AccessPermissionsTableRowProps } from './AccessPermissionsTableRow';
import SkeletonAccessPermissionsTableRow from './SkeletonAccessPermissionsTableRow';
import { AccessPermissionsTableColumnSizes } from './TableColumnSizes';
import { DEFAULT_ROWS_AMOUNT, PRODUCT_TITLES, SECTION_TITLE } from './constants';
import styles from './AccessPermissionsTables.module.scss';

export interface AccessPermissionsTablesProps {
  permissionGroups: PermissionGroup[];
  accessPermissions: Record<PermissionGroupId, ProductSectionAccessPermissions>;
  accessPermissionsUpdatingState: AccessPermissionsUpdatingState;
  updateAccessPermissions: AccessPermissionsTableRowProps['updateAccessPermissions'];
  resetAccessPermissionsUpdatingState: AccessPermissionsTableRowProps['resetAccessPermissionsUpdatingState'];
  isLoading: boolean;
}

const AccessPermissionsTables: FC<AccessPermissionsTablesProps> = ({
  permissionGroups,
  accessPermissions,
  isLoading,
  ...rowProps
}) => {
  const tableRows: (PermissionGroup | null)[] = useMemo(() => {
    if (isLoading || permissionGroups.length === 0 || Object.keys(accessPermissions).length === 0) {
      return new Array(DEFAULT_ROWS_AMOUNT).fill(null);
    }

    return permissionGroups;
  }, [isLoading, permissionGroups, accessPermissions]);

  return (
    <>
      {Object.entries(ProductSectionAccessPermissionsStructure).map(([productName, sections]) => {
        const sectionWidth = (100 - AccessPermissionsTableColumnSizes.GroupName) / sections.length;

        return (
          <div className={styles.permissionsProduct} key={productName}>
            <h4>{PRODUCT_TITLES[productName]}</h4>
            <Table>
              <TableHead sticky>
                <TableHeadCell width={AccessPermissionsTableColumnSizes.GroupName}>Permissions Group</TableHeadCell>
                {sections.map((section) => (
                  <TableHeadCell key={section} width={sectionWidth}>
                    {SECTION_TITLE[section]}
                  </TableHeadCell>
                ))}
              </TableHead>
              <TableBody>
                <TableBodyContent
                  rows={tableRows}
                  renderSkeletonTableRow={(index) => (
                    <SkeletonAccessPermissionsTableRow key={index} sectionsQuantity={sections.length} />
                  )}
                  renderTableRow={(permissionGroup: PermissionGroup) => (
                    <AccessPermissionsTableRow
                      key={permissionGroup.id}
                      permissionGroup={permissionGroup}
                      accessPermissions={accessPermissions}
                      sections={sections}
                      {...rowProps}
                    />
                  )}
                />
              </TableBody>
            </Table>
          </div>
        );
      })}
    </>
  );
};

export default AccessPermissionsTables;
