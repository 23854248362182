import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import {
  DELETE_DECISION_STRATEGY_MODULE_REQUEST,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS,
  REORDER_MODULE_BRANCHES,
  REORDER_MODULE_BRANCHES_SUCCESS,
} from './ActionTypes';
import {
  getDecisionStrategyInfoRequest,
  DeleteDecisionStrategyModuleRequestAction,
  ModuleBranchReorderAction,
  moduleBranchesReorderSuccess,
  moduleBranchesReorderFailure,
  removeCollapsedStrategyModule,
  deleteDecisionStrategyModuleFailure,
  deleteDecisionStrategyModuleSuccess,
  DeleteModuleParams,
  ModuleBranchReorderParams,
} from './DecisionStrategiesActionCreator';

import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { batch } from 'react-redux';
import { DecisionEngineApi } from 'api/Types';
import { ModuleTypeName } from 'components/RulesModule/Types';
import { getVersionsListData } from 'VersionsViewData/Actions';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';

export const DeleteDecisionStrategyModuleMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<DeleteModuleParams, DeleteDecisionStrategyModuleRequestAction>(
    DELETE_DECISION_STRATEGY_MODULE_REQUEST,
    deleteDecisionStrategyModuleSuccess,
    deleteDecisionStrategyModuleFailure,
    async ({ payload }) => {
      await api.deleteDecisionStrategyModule(payload.strategyId, payload.moduleLookupName);
      return payload;
    },
  );

const isDeleteDecisionStrategyModuleSuccessAction = (
  action: AnyAction,
): action is DeleteDecisionStrategyModuleRequestAction =>
  action.type === DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS;

export const onDeleteDecisionStrategyModuleSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isDeleteDecisionStrategyModuleSuccessAction(action)) {
    batch(() => {
      const { strategyId, moduleIndex, moduleLookupName, moduleType, moduleName } = action.payload;

      dispatch(getDecisionStrategyInfoRequest(strategyId));
      dispatch(removeCollapsedStrategyModule(strategyId, moduleIndex, moduleLookupName));
      dispatch(getVersionsListData());
      notification.createNotification(
        getMessage(MessageType.ModuleDeleted, {
          moduleType: ModuleTypeName[moduleType],
          moduleName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};

export const ReorderModuleBranchesMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ModuleBranchReorderParams, ModuleBranchReorderAction>(
    REORDER_MODULE_BRANCHES,
    moduleBranchesReorderSuccess,
    moduleBranchesReorderFailure,
    async ({ payload }) => {
      await api.updateModuleBranchesOrder(payload);
      return payload;
    },
  );

export const onUpdateBranchOrderSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === REORDER_MODULE_BRANCHES_SUCCESS) {
    batch(() => {
      const { strategyId } = action.payload;
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(getMessage(MessageType.BranchesReOrdered), 'success', dispatch);
    });
  }
  return result;
};

export default DeleteDecisionStrategyModuleMiddleware;
