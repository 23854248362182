const LETTERS_AND_NUMBERS_REG_EXP = /^[\w\s]*$/;
// eslint-disable-next-line no-useless-escape
const LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS = /^[a-zA-Z0-9~`!@#$%^&*()-_=+{}\[\]|\/:;"'<>,.? ]*$/;

export const validateLettersNumbersOnly = (value: string) => {
  return LETTERS_AND_NUMBERS_REG_EXP.test(value);
};

export const validateLettersNumbersWithSpecialCharacters = (value: string) => {
  return LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS.test(value);
};
