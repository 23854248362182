export type Enqueue = <ReturnType = unknown>(callback: () => Promise<ReturnType> | ReturnType) => Promise<ReturnType>;

export interface AsyncQueue {
  enqueue: Enqueue;
  enqueueBlocking: Enqueue;
}

const createAsyncQueue = (): AsyncQueue => {
  let lastBlockingOperation: Promise<unknown> = Promise.resolve();

  const enqueue: Enqueue = (callback) => lastBlockingOperation.then(callback);

  const enqueueBlocking: Enqueue = async (callback) => {
    const promise = enqueue(callback);
    lastBlockingOperation = promise;

    try {
      return await promise;
    } finally {
      if (promise === lastBlockingOperation) {
        // no other blocking operation has been queued since this one
        lastBlockingOperation = Promise.resolve();
      }
    }
  };

  return {
    enqueue,
    enqueueBlocking,
  };
};

export default createAsyncQueue;
