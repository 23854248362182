import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Card } from './Base/BaseCardsApi';

export interface CreateIntermediaryProfileCardParams {
  position: number;
  row: number;
  name: string;
}

export interface UpdateIntermediaryProfileCardParams {
  name?: string;
}

export interface IntermediaryProfileCardsApi {
  find(): Promise<Card[]>;
  create(params: CreateIntermediaryProfileCardParams): Promise<Card>;
  update(id: string, params: UpdateIntermediaryProfileCardParams): Promise<Card>;
  delete(id: string): Promise<void>;
}

export default class IntermediaryProfileCardsRestApi extends LoanOriginationSystemApi<Card>
  implements IntermediaryProfileCardsApi {
  protected resourceName = 'intermediary-profile-cards';

  public find() {
    return this.getResources();
  }

  public create(params: CreateIntermediaryProfileCardParams) {
    return this.createResource({
      card: params,
    });
  }

  public update(id: string, params: UpdateIntermediaryProfileCardParams) {
    return this.updateResource(id, {
      card: params,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
