import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortApplicationNotesAction } from './ActionCreator';
import { ApplicationNotesSortingType } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import { ApplicationNotesActionType } from './ActionTypes';

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemApplicationPage.notes;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<SortApplicationNotesAction>(ApplicationNotesActionType.SortApplicationNotes),
];

const applicationNotesSortingSaver = FilterAndSortingSaver<ApplicationNotesSortingType>({
  key: 'applicationNotes',
  getSortingToSave,
  saveTriggerActions,
  resetActionType: ApplicationNotesActionType.ResetState,
});

export default applicationNotesSortingSaver;
