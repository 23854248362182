import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import Table, { TableBodyContent } from 'components/Table';
import TableBody from 'components/Table/TableBody';
import SkeletonCaseLine from './SkeletonDecisionResultLine';
import DecisionResultLine from './DecisionResultLine';
import Pagination from 'components/Pagination';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableActionCell from 'components/Table/TableActionCell';
import TableHead from 'components/Table/TableHead';
import useSorting from 'hooks/useSorting';
import { DecisionResultsSortingField, DecisionResultsSortingFieldType } from 'Decisions/DecisionResultsStore';
import { DecisionResultListColumnSizes } from './TableColumnSizes';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';

interface DecisionResultsTableProps {
  decisionResults: NullableItems<DecisionResultListItem>;
  paginationProps: PaginationProps;
  sortingType: DecisionResultsSortingFieldType;
  onSort: (field: DecisionResultsSortingField, ascending: boolean) => void;
  onRowClick: (result: DecisionResultListItem) => void;
  getRowLink?: (result: DecisionResultListItem) => string;
}

const DecisionResultsTable = ({
  sortingType,
  decisionResults,
  paginationProps,
  onSort,
  getRowLink,
  onRowClick,
}: DecisionResultsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (decisionResult: DecisionResultListItem) => (
    <DecisionResultLine
      decisionResultData={decisionResult}
      key={decisionResult.id}
      rowLink={getRowLink && getRowLink(decisionResult)}
      onRowClick={onRowClick}
    />
  );

  const renderSkeletonRow = (index: number) => {
    return <SkeletonCaseLine key={index} />;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableHeadCell
            width={DecisionResultListColumnSizes.StrategyName}
            ascending={getSortingType(DecisionResultsSortingField.StrategyName)}
            onClick={() => changeSorting(DecisionResultsSortingField.StrategyName)}
          >
            Strategy name
          </TableHeadCell>
          <TableHeadCell
            width={DecisionResultListColumnSizes.Name}
            ascending={getSortingType(DecisionResultsSortingField.Name)}
            onClick={() => changeSorting(DecisionResultsSortingField.Name)}
          >
            Sub-Decision Name
          </TableHeadCell>
          <TableHeadCell
            width={DecisionResultListColumnSizes.ExecutionTime}
            ascending={getSortingType(DecisionResultsSortingField.ExecutionTime)}
            onClick={() => changeSorting(DecisionResultsSortingField.ExecutionTime)}
          >
            Execution Time
          </TableHeadCell>
          <TableHeadCell
            width={DecisionResultListColumnSizes.Type}
            ascending={getSortingType(DecisionResultsSortingField.ResultType)}
            onClick={() => changeSorting(DecisionResultsSortingField.ResultType)}
          >
            Result
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent rows={decisionResults} renderSkeletonTableRow={renderSkeletonRow} renderTableRow={renderRow} />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default DecisionResultsTable;
