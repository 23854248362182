import { Middleware } from 'redux';

import { ACTION_TYPE_EDIT_COMPANY_USER_REQUEST } from './ActionTypes';
import {
  EditCompanyUserError,
  EditCompanyUserSuccess,
  EditCompanyUserRequestAction,
} from './EditCompanyUserActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { UpdateUserResult, UserApi } from 'api/Core/UserApi';

const EditCompanyUserMiddleware: (api: UserApi) => Middleware = (api) =>
  Fetcher<UpdateUserResult, EditCompanyUserRequestAction>(
    ACTION_TYPE_EDIT_COMPANY_USER_REQUEST,
    EditCompanyUserSuccess,
    EditCompanyUserError,
    ({ payload }) =>
      api.update(payload.userId, { isActive: payload.isActive, permissionGroupId: payload.permissionGroupId }),
  );

export default EditCompanyUserMiddleware;
