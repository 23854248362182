import { ApplicationSortingField } from 'api/Types';
import applicationsPagination, {
  APPLICATIONS_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/ApplicationsDashboard/Pagination';
import CurrencySymbols from 'enums/CurrencySymbols';
import withStateReset from 'utils/reducers/withStateReset';
import {
  ApplicationUpdatingState,
  ChangeApplicationStatusAction,
  ChangeApplicationStatusActionOrigin,
  ChangeApplicationStatusSuccessAction,
  ChangeApplicationUpdatingStateAction,
  CloseDeleteApplicationPopupAction,
  CloseDuplicateApplicationPopupAction,
  DeleteApplicationSuccessAction,
  DuplicateApplicationSuccessAction,
  GetColumnViewProductDataAction,
  GetColumnViewProductDataSuccessAction,
  GetProductsListSuccessAction,
  GetTableViewProductDataSuccessAction,
  GetTableViewProductDataAction,
  MoveApplicationWithoutChangingStatusAction,
  OpenDeleteApplicationPopupAction,
  OpenDuplicateApplicationPopupAction,
  SelectProductAction,
  SetApplicationStatusRulesValidationDataAction,
  SetCreateApplicationProductDataAction,
  SortColumnViewProductDataAction,
  SortTableViewProductDataAction,
  ToggleCreateNewApplicationPopupOpenAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import {
  GET_COLUMN_VIEW_PRODUCT_DATA,
  GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_TABLE_VIEW_PRODUCT_DATA,
  GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS,
  LoanOriginationSystemApplicationsActionType,
  SELECT_PRODUCT,
  SORT_TABLE_VIEW_PRODUCT_DATA,
} from 'LoanOriginationSystemApplications/ActionTypes';
import {
  createApplicationInitialState,
  loanOriginationSystemCreateApplicationReducer,
} from 'LoanOriginationSystemApplications/CreateApplication/Reducer';
import {
  applicationsFiltersInitialState,
  baseApplicationsFiltersInitialState,
  loanOriginationSystemApplicationsFiltersReducer,
} from 'LoanOriginationSystemApplications/Filters/Reducer';
import { LoanOriginationSystemApplicationsState } from 'LoanOriginationSystemApplications/Types';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import {
  ChangeProductStatusSuccessAction,
  CreateProductSuccessAction,
  DuplicateProductSuccessAction,
  UpdateProductSuccessAction,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import ChangeApplicationStatusError from 'errors/ChangeApplicationStatusError';
import pushAt from 'utils/pushAt';
import mountChildReducers from 'utils/childReducers';
import {
  ChangePopupFiltersAction,
  ClearAllFiltersAction,
  FilterApplicationsByMembersAction,
  SetSearchInputValueAction,
} from './Filters/ActionCreator';
import {
  ApplicationsActionType,
  CHANGE_POPUP_FILTERS,
  FILTER_APPLICATIONS_BY_MEMBERS,
  SET_SEARCH_INPUT_VALUE,
} from './Filters/ActionTypes';
import applicationsFilterAndSortingSaver from './FilterAndSortingSaver';

const initialTableViewSortingType = {
  field: ApplicationSortingField.CreatedAt,
  ascending: false,
};

const initialColumnViewSortingType = {
  field: ApplicationSortingField.UpdatedAt,
  ascending: false,
};

const searchRelevanceSortingType = {
  field: ApplicationSortingField.SearchRelevance,
  ascending: false,
};

const getSavedSorting = (type: 'tableViewSortingType' | 'columnViewSortingType') => {
  const savedSorting = applicationsFilterAndSortingSaver.getSavedSorting();

  return savedSorting && savedSorting[type].field !== ApplicationSortingField.SearchRelevance
    ? savedSorting[type]
    : initialColumnViewSortingType;
};

const getInitialState = (): LoanOriginationSystemApplicationsState => ({
  columnViewData: null,
  tableViewData: null,
  productsList: null,
  isColumnViewDataLoading: false,
  currencySymbol: CurrencySymbols.Usd,
  selectedProduct: applicationsFilterAndSortingSaver.getSavedFilters()?.selectedProduct || null,
  tableViewSortingType: getSavedSorting('tableViewSortingType') || initialTableViewSortingType,
  filters: { ...applicationsFiltersInitialState, ...applicationsFilterAndSortingSaver.getSavedFilters()?.filters },
  createApplication: createApplicationInitialState,
  applicationIdToDelete: null,
  duplicateApplicationParams: null,
  applicationStatusRulesValidationData: null,
  applicationUpdatingStatesById: {},
  columnViewSortingType: getSavedSorting('columnViewSortingType') || initialColumnViewSortingType,
  isCreateNewApplicationPopupOpen: false,
  createApplicationProductData: null,
});

const findProductById = (state: LoanOriginationSystemApplicationsState, productId: string) =>
  state.productsList?.find(({ id }) => id === productId)!;

const changeStatus = (
  applicationId: string,
  status: ApplicationStatus,
  index: number | undefined,
  state: LoanOriginationSystemApplicationsState,
) => {
  const application = state.columnViewData!.find(({ id }) => id === applicationId)!;

  const updatedApplication = {
    ...application,
    status,
  };

  const filteredApplications = state.columnViewData!.filter(({ id }) => id !== applicationId);

  if (index === undefined) {
    return [updatedApplication, ...filteredApplications];
  }

  const applicationsInTargetStatus = filteredApplications.filter(
    (filteredApplication) => filteredApplication.status.id === status.id,
  );

  const indexInGlobalArray =
    index < applicationsInTargetStatus.length
      ? filteredApplications.indexOf(applicationsInTargetStatus[index])
      : filteredApplications.length;

  return pushAt(filteredApplications, indexInGlobalArray, updatedApplication);
};

type LoanOriginationSystemApplicationsActions =
  | GetColumnViewProductDataAction
  | GetColumnViewProductDataSuccessAction
  | GetProductsListSuccessAction
  | GetTableViewProductDataAction
  | GetTableViewProductDataSuccessAction
  | SelectProductAction
  | SortTableViewProductDataAction
  | ChangeApplicationStatusAction
  | CreateProductSuccessAction
  | ChangeProductStatusSuccessAction
  | UpdateProductSuccessAction
  | DuplicateProductSuccessAction
  | OpenDeleteApplicationPopupAction
  | OpenDuplicateApplicationPopupAction
  | CloseDeleteApplicationPopupAction
  | CloseDuplicateApplicationPopupAction
  | DuplicateApplicationSuccessAction
  | FailedAction<LoanOriginationSystemApplicationsActionType.DuplicateApplicationFailed>
  | DeleteApplicationSuccessAction
  | SetApplicationStatusRulesValidationDataAction
  | ChangeApplicationStatusSuccessAction
  | ChangeApplicationUpdatingStateAction
  | FailedAction<
      LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed,
      ChangeApplicationStatusActionOrigin
    >
  | FailedAction<LoanOriginationSystemApplicationsActionType.DeleteApplicationFailed>
  | MoveApplicationWithoutChangingStatusAction
  | SortColumnViewProductDataAction
  | ClearAllFiltersAction
  | SetSearchInputValueAction
  | FilterApplicationsByMembersAction
  | ChangePopupFiltersAction
  | ToggleCreateNewApplicationPopupOpenAction
  | SetCreateApplicationProductDataAction;

const loanOriginationSystemApplicationsReducer = (
  state = getInitialState(),
  action: LoanOriginationSystemApplicationsActions,
): LoanOriginationSystemApplicationsState => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productsList: action.payload.productsList,
      };
    case GET_COLUMN_VIEW_PRODUCT_DATA:
      return {
        ...state,
        isColumnViewDataLoading: true,
      };
    case GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        isColumnViewDataLoading: false,
        columnViewData: action.payload,
      };
    case GET_TABLE_VIEW_PRODUCT_DATA:
      return {
        ...state,
        tableViewData: null,
      };
    case GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        tableViewData: {
          page: 1,
          itemsPerPage: state.tableViewData?.itemsPerPage || APPLICATIONS_PER_PAGE_DEFAULT,
          error: '',
          items: action.payload.items,
          itemsTotal: action.payload.total,
        },
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        columnViewData: null,
        tableViewData: null,
        columnViewSortingType: initialColumnViewSortingType,
        tableViewSortingType: initialTableViewSortingType,
        filters: {
          ...state.filters,
          ...baseApplicationsFiltersInitialState,
          pagination: undefined,
        },
        selectedProduct: findProductById(state, action.payload.productId),
        createApplication: createApplicationInitialState,
      };
    case SORT_TABLE_VIEW_PRODUCT_DATA:
      return {
        ...state,
        tableViewData: null,
        tableViewSortingType: action.payload,
      };
    case LoanOriginationSystemApplicationsActionType.MoveApplicationWithoutChangingStatus: {
      return {
        ...state,
        columnViewData: changeStatus(
          action.payload.application.id,
          action.payload.status,
          action.payload.destinationIndex,
          state,
        ),
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus: {
      if (action.meta?.actionOrigin !== ChangeApplicationStatusActionOrigin.ApplicationsDashboard) {
        return {
          ...state,
          applicationUpdatingStatesById: {
            ...state.applicationUpdatingStatesById,
            [action.payload.applicationId]: ApplicationUpdatingState.Updating,
          },
        };
      }

      return {
        ...state,
        columnViewData: changeStatus(
          action.payload.applicationId,
          action.payload.statusToSet,
          action.payload.destinationIndex,
          state,
        ),
        applicationUpdatingStatesById: {
          ...state.applicationUpdatingStatesById,
          [action.payload.applicationId]: ApplicationUpdatingState.Updating,
        },
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusSuccess: {
      return {
        ...state,
        applicationUpdatingStatesById: {
          ...state.applicationUpdatingStatesById,
          [action.payload.application.id]: ApplicationUpdatingState.Success,
        },
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed: {
      const error = action.payload;

      if (!(error instanceof ChangeApplicationStatusError)) {
        return state;
      }

      const shouldUpdateColumnViewData =
        action.meta?.actionOrigin === ChangeApplicationStatusActionOrigin.ApplicationsDashboard;

      return {
        ...state,
        columnViewData: shouldUpdateColumnViewData
          ? changeStatus(error.applicationId, error.initialStatus, error.previousIndex, state)
          : state.columnViewData,
        applicationUpdatingStatesById: {
          ...state.applicationUpdatingStatesById,
          [error.applicationId]: ApplicationUpdatingState.Failure,
        },
      };
    }
    case LoanOriginationSystemProductsActionType.CreateProductSuccess:
    case LoanOriginationSystemProductsActionType.UpdateProductSuccess:
    case LoanOriginationSystemProductsActionType.DuplicateProductSuccess:
    case LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess:
      return {
        ...state,
        productsList: null,
        selectedProduct: null,
      };
    case LoanOriginationSystemApplicationsActionType.OpenDeleteApplicationPopup:
      return {
        ...state,
        applicationIdToDelete: action.payload.applicationId,
      };
    case LoanOriginationSystemApplicationsActionType.OpenDuplicateApplicationPopup:
      return {
        ...state,
        duplicateApplicationParams: action.payload.params,
      };
    case LoanOriginationSystemApplicationsActionType.CloseDeleteApplicationPopup:
    case LoanOriginationSystemApplicationsActionType.DeleteApplicationFailed:
    case LoanOriginationSystemApplicationsActionType.DeleteApplicationSuccess:
      return {
        ...state,
        applicationIdToDelete: null,
      };
    case LoanOriginationSystemApplicationsActionType.CloseDuplicateApplicationPopup:
    case LoanOriginationSystemApplicationsActionType.DuplicateApplicationFailed:
    case LoanOriginationSystemApplicationsActionType.DuplicateApplicationSuccess:
      return {
        ...state,
        duplicateApplicationParams: null,
      };
    case LoanOriginationSystemApplicationsActionType.SetApplicationStatusRulesValidationData: {
      return {
        ...state,
        applicationStatusRulesValidationData: action.payload,
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationUpdatingState: {
      return {
        ...state,
        applicationUpdatingStatesById: {
          ...state.applicationUpdatingStatesById,
          [action.payload.applicationId]: action.payload.state,
        },
      };
    }
    case LoanOriginationSystemApplicationsActionType.SortColumnViewProductData:
      return {
        ...state,
        columnViewSortingType: action.payload,
      };
    case SET_SEARCH_INPUT_VALUE: {
      const { searchValue } = action.payload;
      const prevSearchValue = state.filters.searchInputValue;

      return {
        ...state,
        columnViewData: null,
        tableViewData: null,
        // Set sorting by search relevance on search apply and unset sorting on search remove
        ...(prevSearchValue.length === 0 && searchValue.trim().length > 0
          ? { tableViewSortingType: searchRelevanceSortingType, columnViewSortingType: searchRelevanceSortingType }
          : null),
        ...(prevSearchValue.length > 0 && searchValue.trim().length === 0
          ? { tableViewSortingType: initialTableViewSortingType, columnViewSortingType: initialColumnViewSortingType }
          : null),
      };
    }
    case FILTER_APPLICATIONS_BY_MEMBERS:
    case CHANGE_POPUP_FILTERS:
    case ApplicationsActionType.ClearAllFilters:
      return {
        ...state,
        columnViewData: null,
        tableViewData: null,
      };
    case LoanOriginationSystemApplicationsActionType.ToggleCreateNewApplicationPopupOpen: {
      return {
        ...state,
        isCreateNewApplicationPopupOpen: !state.isCreateNewApplicationPopupOpen,
      };
    }
    case LoanOriginationSystemApplicationsActionType.SetCreateApplicationProductData: {
      return {
        ...state,
        createApplicationProductData: action.payload,
      };
    }
    default:
      return state;
  }
};

const childReducers = {
  filters: loanOriginationSystemApplicationsFiltersReducer,
  createApplication: loanOriginationSystemCreateApplicationReducer,
};

export default mountChildReducers(
  applicationsPagination.wrapReducer<LoanOriginationSystemApplicationsState>(
    withStateReset(
      loanOriginationSystemApplicationsReducer,
      LoanOriginationSystemApplicationsActionType.ResetState,
      getInitialState,
    ),
  ),
  childReducers,
);
