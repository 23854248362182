import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { DataViewSortingType } from './Types';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';
import { RequestUploadParams } from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';
import { DownloadResponse } from 'api/APIRest';

export enum DocumentExtension {
  Pdf = 'pdf',
  Txt = 'txt',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xml = 'xml',
  Xlsx = 'xlsx',
  Json = 'json',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Jpeg = 'jpeg',
  Png = 'png',
  Jpg = 'jpg',
  Gif = 'gif',
  Csv = 'csv',
  Word = 'word',
  Zip = 'zip',
}

export enum ApplicationDocumentType {
  File = 'file',
  Folder = 'folder',
}

export interface ApplicationDocumentBase {
  id: string;
  applicationId: string;
  type: ApplicationDocumentType;
  parentId: string | null;
  name: string;
  extension: DocumentExtension | null;
  size: number | null;
  createdAt: Date;
  updatedAt: Date;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
}

export interface ApplicationDocumentFolder extends ApplicationDocumentBase {
  type: ApplicationDocumentType.Folder;
  extension: null;
  size: null;
}

export interface ApplicationDocumentFile extends ApplicationDocumentBase {
  type: ApplicationDocumentType.File;
  extension: DocumentExtension;
  size: number;
}

export type ApplicationDocument = ApplicationDocumentFolder | ApplicationDocumentFile;

export enum ApplicationDocumentsSortingField {
  Name = 'name',
  Extension = 'extension',
  Size = 'size',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
}

export interface BatchUploadDocumentParams {
  file: File;
  parentId?: string | null;
  anchor?: string | null;
}

export type ApplicationDocumentsSortingType = DataViewSortingType<ApplicationDocumentsSortingField>;

export type UpdateApplicationDocumentParams = Partial<Pick<ApplicationDocument, 'name' | 'parentId'>>;

export interface ApplicationDocumentsApi {
  getAll(applicationId: string): Promise<ApplicationDocument[]>;
  upload(applicationId: string, file: File, parentId: string | null): Promise<ApplicationDocumentFile>;
  createFolder(applicationId: string, name: string, parentId: string | null): Promise<ApplicationDocumentFolder>;
  batchUpload(applicationId: string, params: BatchUploadDocumentParams[]): Promise<void>;
  update(documentId: string, document: UpdateApplicationDocumentParams): Promise<ApplicationDocument>;
  delete(documentId: string): Promise<void>;
  requestUpload(requestUploadParams: RequestUploadParams): Promise<string>;
  getPublicLink(documentId: string): Promise<string>;
  download(documentId: string): Promise<DownloadResponse>;
  downloadMany(applicationId: string): Promise<DownloadResponse>;
}

export default class ApplicationDocumentsRestApi extends LoanOriginationSystemApi<ApplicationDocument>
  implements ApplicationDocumentsApi {
  public async getAll(applicationId: string) {
    const params = new URLSearchParams();
    params.append('applicationId', applicationId);

    return this.fetch<ApplicationDocument[]>(`/application-documents?${params}`);
  }

  public async upload(applicationId: string, file: File, parentId: string | null) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('applicationId', applicationId);

    if (parentId) {
      formData.append('parentId', parentId);
    }

    return this.fetch<ApplicationDocumentFile>(`/application-documents`, 'POST', formData, {
      contentType: null,
    });
  }

  public async batchUpload(applicationId: string, params: BatchUploadDocumentParams[]) {
    const formData = new FormData();

    params.forEach((batchUploadDocumentParams, index) => {
      formData.append('files', batchUploadDocumentParams.file);

      if (batchUploadDocumentParams.parentId) {
        formData.append(`options[${index}].parentId`, batchUploadDocumentParams.parentId);
      }

      if (batchUploadDocumentParams.anchor) {
        formData.append(`options[${index}].anchor`, batchUploadDocumentParams.anchor);
      }
    });

    formData.append('applicationId', applicationId);

    return this.fetch<void>(`/application-documents/batch`, 'POST', formData, {
      contentType: null,
    });
  }

  public async createFolder(applicationId: string, name: string, parentId: string | null) {
    return this.fetch<ApplicationDocumentFolder>('/application-documents/document-folders', 'POST', {
      document: { applicationId, name, parentId },
    });
  }

  public async delete(documentId: string) {
    return this.fetch<void>(`/application-documents/${documentId}`, 'DELETE');
  }

  public async update(documentId: string, document: UpdateApplicationDocumentParams) {
    return this.fetch<ApplicationDocument>(`/application-documents/${documentId}`, 'PUT', {
      document,
    });
  }

  public async requestUpload(requestUploadParams: RequestUploadParams): Promise<string> {
    return this.fetch('/application-documents/customer-uploads', 'POST', {
      ...requestUploadParams,
    });
  }

  public async getPublicLink(documentId: string) {
    const { token } = await this.fetch(`/application-documents-preview/${documentId}`, 'GET');

    const params = new URLSearchParams();
    params.set('token', token);

    return `${this.origin}/document-preview?${params}`;
  }

  public async download(documentId: string) {
    return super.download(`/application-documents-downloads/${documentId}`);
  }

  public async downloadMany(applicationId: string) {
    const params = new URLSearchParams();

    params.set('applicationId', applicationId);
    return super.download(`/application-documents-downloads/?${params}`);
  }
}
