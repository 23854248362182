import { AnyAction } from 'redux';
import withStateReset from 'utils/reducers/withStateReset';
import { setItemsTotal } from 'utils/reducers/setStateField';
import {
  GET_VARIABLES_REQUEST,
  GET_VARIABLES_REQUEST_SUCCESS,
  GET_VARIABLES_REQUEST_FAILURE,
  VariablesActionType,
} from './ActionTypes';
import { VariablesState } from './VariablesTypes';
import variablesPagination, { VARIABLES_PER_PAGE_DEFAULT } from 'pages/Variables/Pagination';
import { GetVariablesSuccessAction } from './VariablesActionCreator';
import { VariablesSortingField } from 'api/Core/VariablesApi';
import { initialState as variablesFiltersInitialState, variablesFiltersReducer } from './Filters/Reducer';
import { VariablesFiltersActionTypes } from './Filters/ActionTypes';
import variablesFilterAndSortingSaver from './FilterAndSortingSaver';

const getInitialState = (): VariablesState => ({
  items: [],
  page: 1,
  itemsPerPage: 15,
  itemsTotal: undefined,
  sortingType: variablesFilterAndSortingSaver.getSavedSorting() || {
    field: VariablesSortingField.UpdatedAt,
    ascending: false,
  },
  error: null,
  isLoading: false,
  searched: {},
  filters: { ...variablesFiltersInitialState, ...variablesFilterAndSortingSaver.getSavedFilters()?.filters },
  showArchived: variablesFilterAndSortingSaver.getSavedFilters()?.showArchived || false,
});

const variablesReducer = (state: VariablesState = getInitialState(), action: AnyAction): VariablesState => {
  switch (action.type) {
    case GET_VARIABLES_REQUEST_SUCCESS: {
      const { items, itemsTotal, searchInputValue } = (action as GetVariablesSuccessAction).payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal,
              error: '',
              page: 1,
              itemsPerPage: VARIABLES_PER_PAGE_DEFAULT,
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || VARIABLES_PER_PAGE_DEFAULT,
        error: '',
        items,
        itemsTotal,
      };
    }
    case GET_VARIABLES_REQUEST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case GET_VARIABLES_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case VariablesFiltersActionTypes.ResetAllFilters:
    case VariablesFiltersActionTypes.FilterVariablesByMembers:
    case VariablesFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        filters: variablesFiltersReducer(state.filters, action),
      };
    }
    case VariablesActionType.SortVariables: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        sortingType: action.payload,
      };
    }
    case VariablesActionType.SetShowArchived: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        showArchived: action.payload,
      };
    }
    default: {
      return {
        ...state,
        filters: variablesFiltersReducer(state.filters, action),
      };
    }
  }
};

export default variablesPagination.wrapReducer<VariablesState>(
  withStateReset(variablesReducer, VariablesActionType.ResetVariablesState, getInitialState),
);
