import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { isEmptyField, validateEmail, validateIDNumber, validatePhoneNumber } from 'utils/validation/validation';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';

interface VariableValueValidator {
  (variableConfiguration: BaseVariableConfiguration, value: VariableValue): boolean;
}

const isOptionalStringVariableValue = (value: VariableValue): value is Exclude<VariableValue, number | boolean> =>
  typeof value !== 'number' && typeof value !== 'boolean';

const validateRequiredField: VariableValueValidator = ({ required }: BaseVariableConfiguration, value) => {
  if (!required) {
    return true;
  }

  return value !== null && value !== undefined && value !== '' && !isEmptyField(value.toString());
};

const validateEmailVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat } = variable;

  if (dataType !== 'String' || stringFormat !== 'EmailAddress') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateEmail(value || '', required || false);
};

const validateIDNumberVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat, identificationNumberType } = variable;

  if (dataType !== 'String' || stringFormat !== 'IdentificationNumber') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateIDNumber(value || '', identificationNumberType!, required || false);
};

const validatePhoneNumberVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat } = variable;

  if (dataType !== 'String' || stringFormat !== 'PhoneNumber') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validatePhoneNumber(value || '', required || false);
};

const shouldRunValidators = (
  field: BaseVariableConfiguration,
  fieldValue: VariableValue,
  displayFieldsAttributes: Record<string, boolean> | undefined,
) => {
  return !displayFieldsAttributes
    || displayFieldsAttributes[field.id]
    || !isEmptyVariableValue(fieldValue);
};

const validators: VariableValueValidator[] = [
  validateRequiredField,
  validateEmailVariable,
  validateIDNumberVariable,
  validatePhoneNumberVariable,
];

export const validateConfigurableFormData = (
  data: FormLayoutData,
  fields: Array<BaseVariableConfiguration>,
  displayFieldsAttributes?: Record<string, boolean>,
) => {
  return fields.every((field) => {
    const { variable } = field;
    const fieldValue = data[variable.systemName];

    return !shouldRunValidators(field, fieldValue, displayFieldsAttributes)
      || validators.every((validator) => validator(field, data[variable.systemName]))
  });
};
