import React, { FC, useMemo } from 'react';
import { sample } from 'lodash';
import { isCoBorrowerApplicationFormPage } from 'ApplicationFormPage';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import InfoPanel from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import styles from './ApplicationHeaderPreview.module.scss';
import { StandardVariables } from 'Variables/VariablesTypes';
import { convertBorrowerVariablesToCoBorrower, } from 'utils/BorrowerVariableUtils';

export interface ApplicationHeaderPreviewProps {
  product: Product;
  standardVariables: StandardVariables;
}

const getApplicationStubData = (
  availableBorrowerTypes: BorrowerType[],
  availableCoBorrowerPagesCount: number,
) => {
  return {
    applicationName: 'Borrower Name',
    applicationAmount: 12345,
    status: 'Status',
    statusesAmount: 5,
    statusNumber: 1,
    applicationData: {
      id: '123456789',
      status: {
        id: 'status',
        name: 'status',
        permissionGroupsToEditApplication: {},
        permissionGroupsToMoveApplicationIntoStatus: {},
        permissionGroupsAbleToViewApplicationOnBoard: {},
        rules: [],
        position: 0,
        productId: 'test',
      },
      intermediaryId: 'TEST',
      teamMembers: [],
      product: {
        id: 'productTest',
        name: 'productName',
      },
      borrowerId: 'previewBorrowerId',
      borrowerType: sample(availableBorrowerTypes),
      coborrowerTypes: new Array(availableCoBorrowerPagesCount).fill(null).map(() => sample(availableBorrowerTypes)),
      coborrowerIds: new Array(availableCoBorrowerPagesCount).fill(null).map((key, index) => `previewCoBorrowerId_${index}`),
      variables: {
        [ApplicationDefaultVariable.Borrower.CompanyName]: 'Name',
        [ApplicationDefaultVariable.Borrower.FirstName]: 'Name',
        [ApplicationDefaultVariable.Borrower.LastName]: '',
        [ApplicationDefaultVariable.Borrower.Email]: 'email@domain.com',
        [ApplicationDefaultVariable.Borrower.PhoneNumber]: '(555) 555-5555',
        [ApplicationDefaultVariable.Borrower.CompanyIdNumber]: '123456789',
        [ApplicationDefaultVariable.Borrower.PersonalIdNumber]: '123456789',
        [ApplicationDefaultVariable.Borrower.DateOfBirth]: '04/07/1998',
        [ApplicationDefaultVariable.Borrower.Street]: 'Street Address',
        [ApplicationDefaultVariable.Borrower.City]: 'City',
        [ApplicationDefaultVariable.Borrower.Country]: 'Country',
        [ApplicationDefaultVariable.Borrower.State]: 'State',
        [ApplicationDefaultVariable.Borrower.Zip]: '12345',
        ...new Array(availableCoBorrowerPagesCount).fill(null).reduce((previousCoBorrowerVariables, key, index) => ({
          ...previousCoBorrowerVariables,
          ...convertBorrowerVariablesToCoBorrower({
            [ApplicationDefaultVariable.Borrower.CompanyName]: 'Name',
            [ApplicationDefaultVariable.Borrower.FirstName]: 'Name',
            [ApplicationDefaultVariable.Borrower.LastName]: '',
            [ApplicationDefaultVariable.Borrower.Email]: 'email@domain.com',
            [ApplicationDefaultVariable.Borrower.PhoneNumber]: '(555) 555-5555',
            [ApplicationDefaultVariable.Borrower.CompanyIdNumber]: '123456789',
            [ApplicationDefaultVariable.Borrower.PersonalIdNumber]: '123456789',
            [ApplicationDefaultVariable.Borrower.DateOfBirth]: '04/07/1998',
            [ApplicationDefaultVariable.Borrower.Street]: 'Street Address',
            [ApplicationDefaultVariable.Borrower.City]: 'City',
            [ApplicationDefaultVariable.Borrower.Country]: 'Country',
            [ApplicationDefaultVariable.Borrower.State]: 'State',
            [ApplicationDefaultVariable.Borrower.Zip]: '12345',
          }, index),
        }), {}),
        [ApplicationDefaultVariable.Intermediary.Name]: 'Intermediary Name',
      },
      displayId: '123456',
      updatedAt: new Date(),
      createdAt: new Date(),
      updatedBy: {
        id: 'updatedById',
        firstName: 'None',
        lastName: 'None',
      },
      labels: [
        {
          id: '1',
          name: 'Label 1',
          color: '#00C4AC',
        },
        {
          id: '2',
          name: 'Label 2',
          color: '#1E95F8',
        },
      ],
    } as Application,
  };
};

const ApplicationHeaderPreview: FC<ApplicationHeaderPreviewProps> = ({
  standardVariables,
  product,
}) => {

  const applicationStubData = useMemo(() => {
    const availableCoBorrowerPagesCount = product.settings.applicationFormPages.filter(isCoBorrowerApplicationFormPage).length;

    return getApplicationStubData(
      product.borrowerTypes,
      availableCoBorrowerPagesCount,
    );
  }, [product]);

  const handleFormatDate = () => {
    return 'Mon DD, YYYY, HH:MM PM';
  };

  return (
    <div className={styles.headerPreview}>
      <InfoPanel
        className={styles.infoPanel}
        productName={product.name}
        statusName={applicationStubData.status}
        statusNumber={applicationStubData.statusNumber}
        statusesAmount={applicationStubData.statusesAmount}
        applicationData={applicationStubData.applicationData}
        standardVariables={standardVariables}
        isStatusVisible
        formatApplicationDate={handleFormatDate}
        hideEditApplicationLink
        openByDefault
      />
    </div>
  );
};

export default ApplicationHeaderPreview;
