import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import TabSwitch, { TabSwitchSkeleton } from 'components/TabSwitch';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import Button from 'components/Button';
import useStandardVariables from 'hooks/useStandardVariables';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useEditApplicationTabs from './useEditApplicationTabs';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { IntermediarySuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { createGetBorrowerProfileCardsSelector } from 'BorrowerProfileCards/Selectors';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import { getApplicationDetailsCardsSelector } from 'ApplicationDetailsCards/Selectors';
import {
  getCoBorrowerPageIndex,
  isApplicationDetailsFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import useProductCalculations from 'hooks/useProductCalculations';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { convertBorrowerDefaultVariablesToCoBorrower } from 'utils/BorrowerVariableUtils';
import { updateIntermediary } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { updateBorrower } from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import { getBorrowerProfileCards } from 'BorrowerProfileCards/Thunks';
import { getIntermediaryProfileCards } from 'IntermediaryProfileCards/Thunks';
import { getApplicationDetailsCards } from 'ApplicationDetailsCards/Thunks';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { TabSwitchOption } from 'components/TabSwitch/TabSwitch';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import { setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import ApplicationContextualViewLayout
  from 'components/LoanOriginationSystem/CreateApplication/ApplicationContextualViewLayout';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import {
  selectCoBorrower,
  selectIntermediary,
  updateApplicationBorrowerVariables,
  updateApplicationCoBorrowers,
  updateApplicationIntermediary,
  updateApplicationIntermediaryVariables,
  updateApplicationTeamMembers,
  updateApplicationVariables,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { REQUEST_DELAY_MS } from 'middlewares/Debouncer';
import {
  loadBorrowersSuggestions,
  loadIntermediarySuggestions,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import EditApplicationForm, { EditApplicationFormSkeleton } from './EditApplicationForm';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import styles from './EditApplication.module.scss';

interface EditApplicationProps {
  onClose: () => void;
}

const FORM_TABS_TAB_INDEX = 99;

const COBORROWER_TITLE = 'Co-Borrower';
const INTERMEDIARY_TITLE = 'Intermediary';

const EditApplication = ({ onClose }: EditApplicationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const borrowerSuggestions = useSelector((state: ReduxState) => state.loanOriginationSystemApplications.createApplication.borrowerSuggestions);
  const intermediarySuggestions = useSelector((state: ReduxState) => state.loanOriginationSystemApplications.createApplication.intermediarySuggestions);

  const application = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.applicationData);
  const product = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.product);
  const isTeamMembersUpdatingInProgress = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.isTeamMembersUpdatingInProgress);
  const selectedCoBorrower = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.selectedCoBorrower);
  const selectedIntermediary = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.selectedIntermediary);

  const productCalculations = useProductCalculations(product?.id || null);

  const standardVariables = useStandardVariables();

  const [addCoBorrowerFormData, setAddCoBorrowerFormData] = useState({});
  const [addCoBorrowerSelectedBorrowerType, setAddCoBorrowerSelectedBorrowerType] = useState(BorrowerType.Person);
  const [addIntermediaryFormData, setAddIntermediaryFormData] = useState({});

  const [addEntityRequestInProgress, useAddEntityBlockingRequestCallback] = useBlockingRequest();
  const [removeEntityRequestInProgress, useRemoveEntityBlockingRequestCallback] = useBlockingRequest();

  const [displayRemoveCoBorrowerPopup, setDisplayRemoveCoBorrowerPopup] = useState(false);
  const [displayRemoveIntermediaryPopup, setDisplayRemoveIntermediaryPopup] = useState(false);

  const memoizedGetPersonBorrowerProfileCardsSelector = useMemo(createGetBorrowerProfileCardsSelector, []);
  const memoizedGetCompanyBorrowerProfileCardsSelector = useMemo(createGetBorrowerProfileCardsSelector, []);

  const personBorrowerProfileCards = useSelector((state: ReduxState) => memoizedGetPersonBorrowerProfileCardsSelector(state, BorrowerType.Person));
  const companyBorrowerProfileCards = useSelector((state: ReduxState) => memoizedGetCompanyBorrowerProfileCardsSelector(state, BorrowerType.Company));
  const intermediaryProfileCards = useSelector((state: ReduxState) => getIntermediaryProfileCardsSelector(state));
  const applicationDetailsCards = useSelector((state: ReduxState) => product ? getApplicationDetailsCardsSelector(state, product.id) : null);

  const [
    tabs,
    selectedTabId,
    onTabChange,
    onTabReset,
    resetIsAddingCoBorrower,
    resetIsAddingIntermediary,
  ] = useEditApplicationTabs(application, product);

  const addCoBorrowerProfileCards = addCoBorrowerSelectedBorrowerType === BorrowerType.Person ? personBorrowerProfileCards : companyBorrowerProfileCards;

  const borrowerType = application?.borrowerType;
  const coBorrowersTypes = application?.coborrowerTypes;

  const isAddIntermediaryPage = useMemo(() => {
    return !!(isIntermediaryApplicationFormPage(selectedTabId) && application && !application.intermediaryId);
  }, [selectedTabId, application]);

  const isAddCoBorrowerPage = useMemo(() => {
    return !!(isCoBorrowerApplicationFormPage(selectedTabId) &&
      application &&
      getCoBorrowerPageIndex(selectedTabId) === application.coborrowerIds.length);
  }, [selectedTabId, application]);

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges
  ] = useUnsavedChanges(() => {
    if (isAddCoBorrowerPage) {
      return Object.values(addCoBorrowerFormData).filter((value) => !isEmptyVariableValue(value as VariableValue)).length > 0;
    }

    if (isAddIntermediaryPage) {
      return Object.values(addIntermediaryFormData).filter((value) => !isEmptyVariableValue(value as VariableValue)).length > 0;
    }

    return false;
  });

  useEffect(() => {
    if (!borrowerType || !isBorrowerApplicationFormPage(selectedTabId)) {
      return;
    }

    if (!addCoBorrowerProfileCards) {
      dispatch(getBorrowerProfileCards(borrowerType));
    }
  }, [selectedTabId, borrowerType, addCoBorrowerProfileCards]);

  useEffect(() => {
    if (!isCoBorrowerApplicationFormPage(selectedTabId) || !coBorrowersTypes) {
      return;
    }

    const pageIndex = getCoBorrowerPageIndex(selectedTabId);
    const coBorrowerType = coBorrowersTypes[pageIndex] ? coBorrowersTypes[pageIndex] : addCoBorrowerSelectedBorrowerType;
    const profileCards = coBorrowerType === BorrowerType.Person ? personBorrowerProfileCards : companyBorrowerProfileCards;

    if (!profileCards) {
      dispatch(getBorrowerProfileCards(coBorrowerType));
    }
  }, [selectedTabId, coBorrowersTypes, addCoBorrowerSelectedBorrowerType, personBorrowerProfileCards, companyBorrowerProfileCards]);

  useEffect(() => {
    if (isIntermediaryApplicationFormPage(selectedTabId) && !intermediaryProfileCards) {
      dispatch(getIntermediaryProfileCards());
    }
  }, [selectedTabId, intermediaryProfileCards]);

  useEffect(() => {
    if (!product) {
      return;
    }

    if (isApplicationDetailsFormPage(selectedTabId) && !applicationDetailsCards) {
      dispatch(getApplicationDetailsCards(product.id));
    }
  }, [selectedTabId, product, applicationDetailsCards]);

  useEffect(() => {
    return () => {
      dispatch(selectIntermediary(null));
      dispatch(selectCoBorrower(null));
    };
  }, []);

  useEffect(() => {
    if (!product) {
      return;
    }

    const [defaultBorrowerType] = product.borrowerTypes;

    setAddCoBorrowerSelectedBorrowerType(defaultBorrowerType);
  }, [product]);

  const handleTeamMembersChange = async (newTeamMembersIds: string[]) => {
    if (!application) {
      return;
    }

    await dispatchRoutine(updateApplicationTeamMembers(application.id, newTeamMembersIds));
  };

  const handleEditApplicationBorrowerVariables = async (borrowerId: string, variables: FormLayoutData) => {
    if (!application) {
      return;
    }

    await dispatchRoutine(updateApplicationBorrowerVariables(application.id, borrowerId, variables));
  };

  const handleEditApplicationIntermediaryVariables = async (variables: FormLayoutData) => {
    if (!application) {
      return;
    }

    await dispatchRoutine(updateApplicationIntermediaryVariables(application.id, variables));
  };

  const handleEditApplicationDetailsVariables = async (variables: FormLayoutData) => {
    if (!application) {
      return;
    }

    await dispatchRoutine(updateApplicationVariables(application.id, variables));
  };

  const handleEditSelectedIntermediary = async (data: FormLayoutData) => {
    if (!selectedIntermediary) {
      return;
    }

    await dispatchRoutine(updateIntermediary(data, selectedIntermediary.id));
  };

  const handleEditSelectedCoBorrower = async (data: FormLayoutData) => {
    if (!selectedCoBorrower) {
      return;
    }

    await dispatchRoutine(updateBorrower(selectedCoBorrower.id, data));
  }

  const resetFormData = () => {
    if (isAddCoBorrowerPage) {
      const [availableBorrowerType] = product?.borrowerTypes || [];

      setAddCoBorrowerFormData({});
      setAddCoBorrowerSelectedBorrowerType(availableBorrowerType || BorrowerType.Person);
      dispatch(selectCoBorrower(null));
    }

    if (isAddIntermediaryPage) {
      setAddIntermediaryFormData({});
      dispatch(selectIntermediary(null));
    }
  };

  const handleConfirmRemove = useRemoveEntityBlockingRequestCallback(async () => {
    if (!application) {
      return;
    }

    if (isCoBorrowerApplicationFormPage(selectedTabId)) {
      const pageIndex = getCoBorrowerPageIndex(selectedTabId);

      await dispatchRoutine(updateApplicationCoBorrowers(application.id, {
        coBorrowerToDelete: application.coborrowerIds[pageIndex],
      }));

      const coBorrowerToDeleteFullName = getBorrowerFullName(
        application.coborrowerTypes[pageIndex],
        application.variables,
        convertBorrowerDefaultVariablesToCoBorrower(BorrowerDefaultVariable, pageIndex),
      );

      notification.createNotification(
        getMessage(MessageType.ApplicationCoborrowerDeleted, { borrowerName: coBorrowerToDeleteFullName }),
        'success',
        dispatch,
      );

      onTabReset();
      setDisplayRemoveCoBorrowerPopup(false);
    }

    if (isIntermediaryApplicationFormPage(selectedTabId)) {
      await dispatchRoutine(updateApplicationIntermediary(application.id, { intermediary: null }));

      const intermediaryName = application.variables[IntermediaryDefaultVariable.Name];

      notification.createNotification(
        getMessage(MessageType.ApplicationIntermediaryDeleted, { intermediaryName }),
        'success',
        dispatch,
      );

      onTabReset();
      setDisplayRemoveIntermediaryPopup(false);
    }
  });

  const handleRemoveClick = () => {
    if (isCoBorrowerApplicationFormPage(selectedTabId)) {
      setDisplayRemoveCoBorrowerPopup(true);
    }

    if (isIntermediaryApplicationFormPage(selectedTabId)) {
      setDisplayRemoveIntermediaryPopup(true);
    }
  };

  const handleAddEntityClick = useAddEntityBlockingRequestCallback(async () => {
    if (!application) {
      return;
    }

    if (isAddCoBorrowerPage) {
      await dispatchRoutine(updateApplicationCoBorrowers(application.id, {
        coBorrowersToAdd: [
          selectedCoBorrower ? selectedCoBorrower.id : { variables: addCoBorrowerFormData, type: addCoBorrowerSelectedBorrowerType },
        ],
      }));

      const coBorrowerFullName = getBorrowerFullName(
        selectedCoBorrower ? selectedCoBorrower.type : addCoBorrowerSelectedBorrowerType,
        selectedCoBorrower ? selectedCoBorrower.variables : addCoBorrowerFormData,
      );

      notification.createNotification(
        getMessage(MessageType.ApplicationCoborrowerAdded, { borrowerName: coBorrowerFullName }),
        'success',
        dispatch,
      );

      resetFormData();
      resetIsAddingCoBorrower();
    }

    if (isAddIntermediaryPage) {
      await dispatchRoutine(updateApplicationIntermediary(application.id, {
        intermediary: selectedIntermediary ? selectedIntermediary.id : { variables: addIntermediaryFormData },
      }));

      const intermediaryName = selectedIntermediary
        ? selectedIntermediary.variables[IntermediaryDefaultVariable.Name]
        : addIntermediaryFormData[IntermediaryDefaultVariable.Name];

      notification.createNotification(
        getMessage(MessageType.ApplicationIntermediaryAdded, { intermediaryName }),
        'success',
        dispatch,
      );

      resetFormData();
      resetIsAddingIntermediary();
    }
  });

  const handleTabChange = useCallbackWithUnsavedChanges((tab: TabSwitchOption) => {
    resetFormData();
    onTabChange(tab.id);
  });

  const handleTabButtonClick = useCallbackWithUnsavedChanges((tabId: string, defaultTabAction?: () => void) => {
    resetFormData();
    defaultTabAction?.();
  });

  const handleCancelClick = useCallbackWithUnsavedChanges(() => {
    resetFormData();
    onTabReset();
  });

  const handlePopupClose = useCallbackWithUnsavedChanges(() => onClose());

  const handleLoadBorrowerSuggestions = useMemo(() => {
    return debounce(
      (filter: BorrowerSuggestionFilter) => dispatch(loadBorrowersSuggestions(filter)),
      REQUEST_DELAY_MS,
    );
  }, [dispatch]);

  const handleLoadIntermediarySuggestions = useMemo(() => {
    return debounce(
      (filter: IntermediarySuggestionFilter) => dispatch(loadIntermediarySuggestions(filter)),
      REQUEST_DELAY_MS
    );
  }, [dispatch]);

  const handleBorrowerUnlock = (borrower: Borrower) => {
    dispatch(setBorrowerToUnlock(borrower));
  };

  const handleAddCoBorrowerFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setAddCoBorrowerFormData((previousAddCoBorrowerFormData) => ({
      ...previousAddCoBorrowerFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const handleAddIntermediaryFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setAddIntermediaryFormData((previousAddIntermediaryFormData) => ({
      ...previousAddIntermediaryFormData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const renderTabSwitch = () => {
    if (!tabs) {
      return <TabSwitchSkeleton className={styles.tabSwitchSkeleton} />;
    }

    return (
      <TabSwitch
        className={styles.tabsSwitch}
        tabClassName={styles.tab}
        buttonTabClassName={styles.buttonTab}
        tabs={tabs}
        selectedTabId={selectedTabId || ''}
        onSelect={handleTabChange}
        buttonsTabIndex={FORM_TABS_TAB_INDEX}
        onTabButtonClick={handleTabButtonClick}
      />
    );
  };

  const renderTabContent = () => {
    if (!application || !product) {
      return <EditApplicationFormSkeleton />;
    }

    return (
      <EditApplicationForm
        application={application}
        product={product}
        selectedCoBorrower={selectedCoBorrower}
        selectedIntermediary={selectedIntermediary}
        borrowerSuggestions={borrowerSuggestions}
        intermediarySuggestions={intermediarySuggestions}
        selectedTabId={selectedTabId}
        personBorrowerProfileCards={personBorrowerProfileCards}
        companyBorrowerProfileCards={companyBorrowerProfileCards}
        intermediaryProfileCards={intermediaryProfileCards}
        applicationDetailsCards={applicationDetailsCards}
        addCoBorrowerFormData={addCoBorrowerFormData}
        addIntermediaryFormData={addIntermediaryFormData}
        standardVariables={standardVariables}
        onCoBorrowerSelect={(coBorrower) => dispatch(selectCoBorrower(coBorrower))}
        onIntermediarySelect={(intermediary) => dispatch(selectIntermediary(intermediary))}
        onAddCoBorrowerFieldChange={handleAddCoBorrowerFieldChange}
        onAddIntermediaryFieldChange={handleAddIntermediaryFieldChange}
        onChangeAddCoBorrowerType={(type) => setAddCoBorrowerSelectedBorrowerType(type)}
        addCoBorrowerSelectedBorrowerType={addCoBorrowerSelectedBorrowerType}
        onEditApplicationIntermediaryVariables={handleEditApplicationIntermediaryVariables}
        onEditApplicationBorrowerVariables={handleEditApplicationBorrowerVariables}
        onEditApplicationDetailsVariables={handleEditApplicationDetailsVariables}
        onEditSelectedIntermediaryProfile={handleEditSelectedIntermediary}
        onEditSelectedCoBorrowerProfile={handleEditSelectedCoBorrower}
        onLoadBorrowerSuggestions={handleLoadBorrowerSuggestions}
        onLoadIntermediarySuggestions={handleLoadIntermediarySuggestions}
        isAddCoBorrowerPage={isAddCoBorrowerPage}
        isAddIntermediaryPage={isAddIntermediaryPage}
        productCalculations={productCalculations}
        onUnlockBorrowerClick={handleBorrowerUnlock}
        renderActions={renderActionsContainer}
      />
    );
  };

  const getAddEntityTitle = () => {
    if (isAddCoBorrowerPage) {
      return COBORROWER_TITLE;
    }

    if (isAddIntermediaryPage) {
      return INTERMEDIARY_TITLE;
    }

    return '';
  };

  const getRemoveEntityTitle = () => {
    if (isCoBorrowerApplicationFormPage(selectedTabId)) {
      return COBORROWER_TITLE;
    }

    if (isIntermediaryApplicationFormPage(selectedTabId)) {
      return INTERMEDIARY_TITLE;
    }

    return '';
  }

  const shouldRenderDeleteButton = () => {
    return (isCoBorrowerApplicationFormPage(selectedTabId) && !isAddCoBorrowerPage)
      || (isIntermediaryApplicationFormPage(selectedTabId) && !isAddIntermediaryPage);
  }

  const shouldRenderCreateEntityActions = () => {
    return isAddCoBorrowerPage && !selectedCoBorrower?.locked || isAddIntermediaryPage;
  }

  const teamMemberIds = (application?.teamMembers || []).map(({ id }) => id);

  const renderActions = (submitDisabled: boolean, areFieldsInvalid: boolean) => {
    if (shouldRenderCreateEntityActions()) {
      return (
        <>
          <WithFieldsValidationButton
            size="form"
            kind="primary"
            onClick={handleAddEntityClick}
            isLoading={addEntityRequestInProgress}
            areFieldsInvalid={areFieldsInvalid}
            disabled={submitDisabled}
          >
            Add {getAddEntityTitle()}
          </WithFieldsValidationButton>
          <Button
            size="form"
            kind="secondary"
            disabled={addEntityRequestInProgress}
            className={styles.cancelButton}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </>
      );
    }

    if (shouldRenderDeleteButton()) {
      return (
        <Button size="form" kind="warning" onClick={handleRemoveClick} className={styles.removeButton}>
          Remove or Change {getRemoveEntityTitle()}
        </Button>
      );
    }

    return null;
  };

  const renderActionsContainer = (
    submitDisabled: boolean,
    areFieldsInvalid: boolean
  ) => {
    return (
      <div className={styles.actionContainer}>
        {renderActions(submitDisabled, areFieldsInvalid)}
      </div>
    );
  };

  return (
    <ApplicationContextualViewLayout
      onClose={handlePopupClose}
      teamMembersIds={teamMemberIds}
      onTeamMembersChange={handleTeamMembersChange}
      isTeamMembersUpdatingInProgress={isTeamMembersUpdatingInProgress}
    >
      {renderTabSwitch()}
      <div className={styles.tabContainer}>
        {renderTabContent()}
      </div>
      {displayUnsavedChanges && <UnsavedChangesPopup
        usePortal
        onPopupClose={() => onLeaveUnsavedChanges()}
        onLeaveClick={() => onConfirmUnsavedChanges()}
      />}
      {displayRemoveCoBorrowerPopup && <ConfirmPopup
        usePortal
        title="Remove Co-Borrower"
        message="Please confirm that you would like to remove this coborrower from the application. "
        confirmText="Remove Co-Borrower"
        declineText="No, Go Back"
        onPopupClose={() => setDisplayRemoveCoBorrowerPopup(false)}
        onConfirmClick={handleConfirmRemove}
        titleTooltip={(
          <>
            <p>Another co-borrower can be added to the application</p>
            <p>after this one is removed</p>
          </>
        )}
        loading={removeEntityRequestInProgress}
      />}
      {displayRemoveIntermediaryPopup && <ConfirmPopup
        usePortal
        title="Remove Intermediary"
        message="Please confirm that you would like to remove this intermediary from the application. "
        confirmText="Remove Intermediary"
        declineText="No, Go Back"
        onPopupClose={() => setDisplayRemoveIntermediaryPopup(false)}
        onConfirmClick={handleConfirmRemove}
        titleTooltip={(
          <>
            <p>Another intermediary can be added to</p>
            <p>the application after this one is removed</p>
          </>
        )}
        loading={removeEntityRequestInProgress}
      />}
    </ApplicationContextualViewLayout>
  );
};

export default EditApplication;
