import { AnyAction } from 'redux';
import {
  ApplicationPageActionType,
  CLOSE_CHANGE_APPLICATION_STATUS_POPUP,
  GET_APPLICATION_DATA,
  GET_APPLICATION_DATA_FAILED,
  GET_APPLICATION_DATA_SUCCESS,
  OPEN_CHANGE_APPLICATION_STATUS_POPUP,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_FAILED,
  UPDATE_APPLICATION_SUCCESS,
} from './ActionTypes';
import {
  Application,
  UpdateApplicationCoBorrowersParams,
  UpdateApplicationIntermediaryParams,
  UpdateApplicationModel,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import ApplicationVariableSortingField from './ApplicationVariableSortingField';
import { Variable } from 'Variables/VariablesTypes';

export interface GetApplicationDataAction extends AnyAction {
  type: typeof GET_APPLICATION_DATA;
  payload: {
    applicationDisplayId: string;
  };
}

export interface GetApplicationDataSuccessAction extends AnyAction {
  type: typeof GET_APPLICATION_DATA_SUCCESS;
  payload: {
    applicationData: Application;
  };
}
export interface OpenChangeApplicationStatusPopupAction extends AnyAction {
  type: typeof OPEN_CHANGE_APPLICATION_STATUS_POPUP;
  payload: {
    statusToChange: ApplicationStatus;
  };
}
export interface CloseChangeApplicationStatusPopupAction extends AnyAction {
  type: typeof CLOSE_CHANGE_APPLICATION_STATUS_POPUP;
}

export interface UpdateApplicationAction extends AnyAction {
  type: typeof UPDATE_APPLICATION;
  payload: {
    data: UpdateApplicationModel;
  };
}

export interface UpdateApplicationSuccessAction extends AnyAction {
  type: typeof UPDATE_APPLICATION_SUCCESS;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationVariables;
  payload: {
    applicationId: string;
    variables: FormLayoutData;
  };
}

export interface UpdateApplicationVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationVariablesSuccess;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationBorrowerVariablesAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationBorrowerVariables;
  payload: {
    applicationId: string;
    borrowerId: string;
    variables: FormLayoutData;
  };
}

export interface UpdateApplicationBorrowerVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationBorrowerVariablesSuccess;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationIntermediaryVariablesAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationIntermediaryVariables;
  payload: {
    applicationId: string;
    variables: FormLayoutData;
  };
}

export interface UpdateApplicationCoBorrowersAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationCoBorrowers;
  payload: {
    applicationId: string;
    params: UpdateApplicationCoBorrowersParams;
  };
}

export interface UpdateApplicationCoBorrowersSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationCoBorrowersSuccess;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationIntermediaryVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationIntermediaryVariablesSuccess;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationIntermediaryAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationIntermediary;
  payload: {
    applicationId: string,
    params: UpdateApplicationIntermediaryParams;
  };
}

export interface UpdateApplicationIntermediarySuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationIntermediarySuccess;
  payload: {
    application: Application;
  };
}

export interface GetApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.GetApplicationVariables;
  payload: {
    application: Application;
  };
}

export interface GetApplicationVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.GetApplicationVariablesSuccess;
  payload: {
    variables: Variable[];
  };
}

export interface SortApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.SortApplicationVariables;
  payload: {
    field: ApplicationVariableSortingField;
    ascending: boolean;
  };
}

export interface UpdateApplicationTeamMembersAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationTeamMembers;
  payload: {
    applicationId: string;
    teamMembers: string[];
  };
}

export interface UpdateApplicationTeamMembersSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationTeamMembersSuccess;
  payload: {
    application: Application;
  };
}

export interface AddApplicationLabelAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationLabel;
  payload: {
    applicationId: string;
    labelId: string;
  };
}

export interface AddApplicationLabelSuccessAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationLabelSuccess;
  payload: {
    application: Application;
  };
}

export interface DeleteApplicationLabelAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationLabel;
  payload: {
    applicationId: string;
    labelId: string;
  };
}

export interface DeleteApplicationLabelSuccessAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationLabelSuccess;
  payload: {
    application: Application;
  };
}

export interface SelectIntermediaryAction extends AnyAction {
  type: ApplicationPageActionType.SelectIntermediary;
  payload: {
    intermediary: Intermediary | null;
  };
}

export interface SelectCoBorrowerAction extends AnyAction {
  type: ApplicationPageActionType.SelectCoBorrower;
  payload: {
    coBorrower: Borrower | null;
  };
}

export const getApplicationData = (applicationDisplayId: string): GetApplicationDataAction => {
  return {
    type: GET_APPLICATION_DATA,
    payload: {
      applicationDisplayId,
    },
  };
};

export const getApplicationDataSuccess = (applicationData: Application): GetApplicationDataSuccessAction => {
  return {
    type: GET_APPLICATION_DATA_SUCCESS,
    payload: {
      applicationData,
    },
  };
};

export const getApplicationDataFailed = FailedActionCreator(GET_APPLICATION_DATA_FAILED);

export const openChangeApplicationStatusPopup = (
  statusToChange: ApplicationStatus,
): OpenChangeApplicationStatusPopupAction => {
  return {
    type: OPEN_CHANGE_APPLICATION_STATUS_POPUP,
    payload: {
      statusToChange,
    },
  };
};

export const closeChangeApplicationStatusPopup = (): CloseChangeApplicationStatusPopupAction => {
  return {
    type: CLOSE_CHANGE_APPLICATION_STATUS_POPUP,
  };
};

export const updateApplication = (data: UpdateApplicationModel): UpdateApplicationAction => {
  return {
    type: UPDATE_APPLICATION,
    payload: {
      data,
    },
  };
};

export const updateApplicationSuccess = (application: Application): UpdateApplicationSuccessAction => {
  return {
    type: UPDATE_APPLICATION_SUCCESS,
    payload: {
      application,
    },
  };
};

export const updateApplicationVariables = (
  applicationId: string,
  variables: FormLayoutData,
): UpdateApplicationVariablesAction => ({
  type: ApplicationPageActionType.UpdateApplicationVariables,
  payload: {
    applicationId,
    variables,
  },
});

export const updateApplicationTeamMembers = (
  applicationId: string,
  teamMembers: string[],
): UpdateApplicationTeamMembersAction => ({
  type: ApplicationPageActionType.UpdateApplicationTeamMembers,
  payload: {
    applicationId,
    teamMembers,
  },
});

export const updateApplicationTeamMembersSuccess = (
  application: Application,
): UpdateApplicationTeamMembersSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationTeamMembersSuccess,
  payload: {
    application,
  },
});

export const updateApplicationVariablesSuccess = (
  application: Application,
): UpdateApplicationVariablesSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationVariablesSuccess,
  payload: {
    application,
  },
});

export const updateApplicationFailed = FailedActionCreator(UPDATE_APPLICATION_FAILED);
export const updateApplicationVariablesFailure = FailedActionCreator(
  ApplicationPageActionType.UpdateApplicationVariablesFailure,
);

export const getApplicationVariables = (application: Application): GetApplicationVariablesAction => {
  return {
    type: ApplicationPageActionType.GetApplicationVariables,
    payload: {
      application,
    },
  };
};

export const getApplicationVariablesSuccess = (variables: Variable[]): GetApplicationVariablesSuccessAction => {
  return {
    type: ApplicationPageActionType.GetApplicationVariablesSuccess,
    payload: {
      variables,
    },
  };
};

export const getApplicationVariablesFailed = FailedActionCreator(
  ApplicationPageActionType.GetApplicationVariablesFailed,
);

export const addApplicationLabel = (applicationId: string, labelId: string): AddApplicationLabelAction => ({
  type: ApplicationPageActionType.AddApplicationLabel,
  payload: {
    applicationId,
    labelId,
  },
});

export const addApplicationLabelSuccess = (application: Application): AddApplicationLabelSuccessAction => ({
  type: ApplicationPageActionType.AddApplicationLabelSuccess,
  payload: {
    application,
  },
});

export const deleteApplicationLabel = (applicationId: string, labelId: string): DeleteApplicationLabelAction => ({
  type: ApplicationPageActionType.DeleteApplicationLabel,
  payload: {
    applicationId,
    labelId,
  },
});

export const deleteApplicationLabelSuccess = (application: Application): DeleteApplicationLabelSuccessAction => ({
  type: ApplicationPageActionType.DeleteApplicationLabelSuccess,
  payload: {
    application,
  },
});

export const updateApplicationBorrowerVariables = (
  applicationId: string,
  borrowerId: string,
  variables: FormLayoutData,
): UpdateApplicationBorrowerVariablesAction => ({
  type: ApplicationPageActionType.UpdateApplicationBorrowerVariables,
  payload: {
    applicationId,
    borrowerId,
    variables,
  },
});

export const updateApplicationBorrowerVariablesSuccess = (application: Application): UpdateApplicationBorrowerVariablesSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationBorrowerVariablesSuccess,
  payload: {
    application,
  },
});

export const updateApplicationIntermediaryVariables = (applicationId: string, variables: FormLayoutData): UpdateApplicationIntermediaryVariablesAction => ({
  type: ApplicationPageActionType.UpdateApplicationIntermediaryVariables,
  payload: {
    applicationId,
    variables,
  },
});

export const updateApplicationIntermediaryVariablesSuccess = (application: Application): UpdateApplicationIntermediaryVariablesSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationIntermediaryVariablesSuccess,
  payload: {
    application,
  },
});

export const updateApplicationIntermediary = (applicationId: string, params: UpdateApplicationIntermediaryParams): UpdateApplicationIntermediaryAction => ({
  type: ApplicationPageActionType.UpdateApplicationIntermediary,
  payload: {
    applicationId,
    params
  },
});

export const updateApplicationIntermediarySuccess = (application: Application): UpdateApplicationIntermediarySuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationIntermediarySuccess,
  payload: {
    application,
  },
});

export const updateApplicationCoBorrowers = (applicationId: string, params: UpdateApplicationCoBorrowersParams): UpdateApplicationCoBorrowersAction => ({
  type: ApplicationPageActionType.UpdateApplicationCoBorrowers,
  payload: {
    applicationId,
    params,
  },
});

export const updateApplicationCoBorrowersSuccess = (application: Application): UpdateApplicationCoBorrowersSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationCoBorrowersSuccess,
  payload: {
    application,
  },
});

export const selectIntermediary = (intermediary: Intermediary | null): SelectIntermediaryAction => ({
  type: ApplicationPageActionType.SelectIntermediary,
  payload: {
    intermediary,
  },
});

export const selectCoBorrower = (coBorrower: Borrower | null): SelectCoBorrowerAction => ({
  type: ApplicationPageActionType.SelectCoBorrower,
  payload: {
    coBorrower,
  },
});

export const updateApplicationIntermediaryFailure = FailedActionCreator(ApplicationPageActionType.UpdateApplicationIntermediaryFailure);

export const updateApplicationCoBorrowersFailure = FailedActionCreator(ApplicationPageActionType.UpdateApplicationCoBorrowersFailure);

export const updateApplicationBorrowerVariablesFailure = FailedActionCreator(ApplicationPageActionType.UpdateApplicationBorrowerVariablesFailure);

export const updateApplicationIntermediaryVariablesFailure = FailedActionCreator(ApplicationPageActionType.UpdateApplicationIntermediaryVariablesFailure);

export const addApplicationLabelFailure = FailedActionCreator(ApplicationPageActionType.AddApplicationLabelFailure);

export const deleteApplicationLabelFailure = FailedActionCreator(
  ApplicationPageActionType.DeleteApplicationLabelFailure,
);

export const sortApplicationVariables = (
  field: ApplicationVariableSortingField,
  ascending: boolean,
): SortApplicationVariablesAction => {
  return {
    type: ApplicationPageActionType.SortApplicationVariables,
    payload: {
      field,
      ascending,
    },
  };
};

export const updateApplicationTeamMembersFailure = FailedActionCreator(
  ApplicationPageActionType.UpdateApplicationTeamMembersFailure,
);

export interface ClearApplicationDataAction {
  type: ApplicationPageActionType.ClearApplicationData;
}

export const clearApplicationData = (): ClearApplicationDataAction => ({
  type: ApplicationPageActionType.ClearApplicationData,
});
