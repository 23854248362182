import React from 'react';
import clsx from 'clsx';
import { DragImage } from 'static/images';
import InputWithDataTypeStub, { InputWithDataTypeStubProps } from 'components/InputWithDataType/InputWithDataTypeStub';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import styles from './DraggableInputStub.module.scss';

export interface DraggableInputStubProps extends InputWithDataTypeStubProps {
  isDragging?: boolean;
  showActions?: boolean;
  className?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  actions?: RowAction[];
}

const DraggableInputStub = React.memo(({
  tooltip,
  showActions,
  isDragging,
  className,
  disabled,
  required,
  label,
  actions,
  ...restProps
}: DraggableInputStubProps & VisualDataTypeWithAttributes) => (
  <RowActionsContainer>
    <div className={styles.draggableInputStubWrapper}>
      <div className={clsx(styles.draggableInputStub, { [styles.dragging]: isDragging, [styles.disabledInputStub]: disabled }, className)}>
        <div className={styles.innerContainer}>
          {showActions && (
            <RowActions
              className={styles.rowActions}
              popupClassName={styles.rowActionsPopup}
              actions={actions || []}
            />
          )}
          {!disabled && <DragImage className={styles.dragImage} />}
          <InputWithDataTypeStub
            inputTooltip={tooltip}
            label={label}
            required={required}
            disabled={disabled}
            {...restProps}
          />
        </div>
      </div>
    </div>
  </RowActionsContainer>
));

export default DraggableInputStub;
