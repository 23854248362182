import React, { FC, SVGProps, useRef } from 'react';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import ChartPointGroup from './ChartPointGroup';
import styles from './components.module.scss';
import { uniqueId } from 'lodash';
import { ChartPointProps } from './ChartPoint';
import * as allCurves from '@visx/curve';
import { Point } from './Types';

interface ChartLineProps extends SVGProps<SVGGElement> {
  getX?: (value: Point) => number;
  getY?: (value: Point) => number;
  data: ChartPointProps[];
  gradientTargetY: number;
  padding?: number;
  hidePoints?: boolean;
}

const ChartLine: FC<ChartLineProps> = ({
  getX,
  getY,
  data,
  padding = 0,
  gradientTargetY,
  hidePoints = false,
  ...svgProps
}) => {
  const gradientId = useRef(`chart-line-gradient-${uniqueId()}`);

  const gradientData = [
    { x: data[0].x - padding, y: gradientTargetY },
    { x: data[0].x - padding, y: data[0].y },
    ...data,
    { x: data[data.length - 1].x + padding, y: data[data.length - 1].y },
    { x: data[data.length - 1].x + padding, y: gradientTargetY },
  ];

  return (
    <Group {...svgProps}>
      <LinearGradient id={gradientId.current} from={styles.gradientFrom} to={styles.gradientTo} />
      <LinePath
        x={getX}
        y={getY}
        fill={`url(#${gradientId.current})`}
        data={gradientData}
        strokeWidth={0}
        curve={allCurves.curveLinear}
      />
      <LinePath
        x={getX}
        y={getY}
        data={data}
        stroke={styles.lineColor}
        strokeWidth={styles.lineWidth}
        curve={allCurves.curveLinear}
      />
      {!hidePoints && <ChartPointGroup data={data} />}
    </Group>
  );
};

export default ChartLine;
