import { useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import useLoadingState from 'hooks/useLoadingState';
import { getOrganizationFreeSeat, getOrganizationSeats } from 'CompanyInformation/CompanyInformationActionCreator';
import SeatsResults from 'CompanyInformation/SeatsResults';

type CompanyFreeSeatsCheckHookReturnType = [
  boolean,
  (email: string, onSeatsAreFull: () => void) => Promise<boolean>,
];

const useCompanyFreeSeatsCheck = (): CompanyFreeSeatsCheckHookReturnType => {
  const dispatchRoutine = useDispatchRoutineWithResult();

  const [isFreeSeatsInfoLoading, doesHaveFreeSeats] = useLoadingState(async (email: string) => {
    const seatsData = await dispatchRoutine(getOrganizationFreeSeat(email));
    const hasFreeSeat = seatsData[SeatsResults.HasFreeSeat as any] as boolean;
    if (!hasFreeSeat) {
      await dispatchRoutine(getOrganizationSeats());
    }
    return hasFreeSeat;
  });

  const checkFreeSeats = async (email: string, onSeatsAreFull: () => void) => {
    const hasFreeSeat = await doesHaveFreeSeats(email);

    if (!hasFreeSeat) {
      onSeatsAreFull();
    }

    return hasFreeSeat;
  };

  return [isFreeSeatsInfoLoading, checkFreeSeats];
};

export default useCompanyFreeSeatsCheck;
