import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { GET_PRODUCTS_LIST } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  getProductsListFailed,
  GetProductsListRequestAction,
  getProductsListSuccess,
  GetProductsListSuccessActionPayload,
} from 'LoanOriginationSystemApplications/ActionCreator';
import {
  LoanOriginationSystemProductsApi,
  ProductStatus,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';

const GetProductsListMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<GetProductsListSuccessActionPayload, GetProductsListRequestAction, ReduxState>(
    GET_PRODUCTS_LIST,
    getProductsListSuccess,
    getProductsListFailed,
    async () => {
      const productsList = await api.getAll({ statuses: [ProductStatus.Active] });

      return {
        productsList: productsList.map((product) => ({
          ...product,
          statuses: [],
          declineReasons: [],
        })),
      };
    },
  );

export default GetProductsListMiddleware;
