import React from 'react';
import styles from './Application.module.scss';
import { SkeletonTabsList } from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/TabsList';
import SkeletonCardsForm from 'components/LoanOriginationSystem/CardsForm/SkeletonCardsForm';
import ApplicationDetailsSkeleton from 'components/LoanOriginationSystem/ApplicationDetails/ApplicationDetailsSkeleton';

const SKELETON_CARDS_LAYOUT = [[5], [3]];

const ApplicationSkeleton = () => {
  return (
    <>
      <ApplicationDetailsSkeleton />
      <SkeletonTabsList />
      <div className={styles.tabsContainer}>
        <SkeletonCardsForm layout={SKELETON_CARDS_LAYOUT} />
      </div>
    </>
  );
};

export default ApplicationSkeleton;
