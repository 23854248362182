import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SetShowArchivedAction, SetUpdatersAction, SortApiActivationAction } from './ActionCreator';
import { ApiActivationActionType } from './ActionTypes';
import { ApiActivationsState } from './Types';
import { ApiActivationSortingType } from 'DecisionStrategy/DecisionStrategiesTypes';

type ApiActivationFiltersToSave = Pick<ApiActivationsState, 'selectedUpdaters' | 'showArchived'>;

const getFiltersToSave = (state: ReduxState) => {
  return pick(state.apiActivation, ['selectedUpdaters', 'showArchived']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.apiActivation;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<SetShowArchivedAction>(ApiActivationActionType.SetShowArchived),
  isActionOfType<SetUpdatersAction>(ApiActivationActionType.SetUpdaters),
  isActionOfType<SortApiActivationAction>(ApiActivationActionType.SortStrategies),
];

const apiActivationFilterAndSortingSaver = FilterAndSortingSaver<ApiActivationFiltersToSave, ApiActivationSortingType>({
  key: 'apiActivation',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: ApiActivationActionType.ClearApiActivationData,
});

export default apiActivationFilterAndSortingSaver;
