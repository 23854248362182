import { Variable, VariableAccessPermissionType, VisualDataType } from 'Variables/VariablesTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';
import { FULLY_HIDDEN_VARIABLE_PLACEHOLDER } from 'utils/formatValueByDataType';

export const getInputModeFromVariableConfiguration = (variable: Variable, permissionGroupId: PermissionGroupId) => {
  switch (variable.permissions[permissionGroupId]) {
    case VariableAccessPermissionType.ReadOnly: {
      return {
        readOnly: true,
      };
    }
    case VariableAccessPermissionType.NoAccess:
    case VariableAccessPermissionType.PartialRead:
      return {
        disabled: true,
      };
    default: {
      return {};
    }
  }
};

export const getMaskModeBasedOnPermissions = (dataType: VisualDataType, variable: Variable, permissionGroupId: PermissionGroupId) => {
  const permissions = variable.permissions[permissionGroupId] || VariableAccessPermissionType.ReadWrite;

  if (
    permissions === VariableAccessPermissionType.ReadWrite ||
    permissions === VariableAccessPermissionType.ReadOnly
  ) {
    return {};
  }

  // eslint-disable-next-line default-case
  switch (dataType) {
    case 'Number':
    case 'Monetary':
    case 'Percentage':
    case 'Boolean': {
      return {
        value: FULLY_HIDDEN_VARIABLE_PLACEHOLDER,
        raw: true,
      };
    }
    case 'PhoneNumber': {
      return {
        raw: true,
      };
    }
  }

  return {};
};
