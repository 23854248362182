import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemIntermediariesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { LoanOriginationSystemEditIntermediaryTypes } from './ActionTypes';
import {
  UpdateIntermediaryApplicationsAction,
  updateIntermediaryApplicationsSuccess,
  updateIntermediaryApplicationFailure,
} from './ActionCreator';

const UpdateIntermediaryApplicationsMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<void, UpdateIntermediaryApplicationsAction>(
    LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplications,
    updateIntermediaryApplicationsSuccess,
    updateIntermediaryApplicationFailure,
    async (action) => {
      await api.updateIntermediaryApplications(action.payload.intermediaryId, action.payload.variables);
    },
  );

export default UpdateIntermediaryApplicationsMiddleware;
