import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { StrategyUpdateType, DecisionStrategyUpdatesSortingType } from 'DecisionStrategyUpdates/Types';

export const STRATEGY_UPDATES_PER_PAGE_DEFAULT = 5;

interface PaginationParams {
  strategyId: string;
  sortingType: DecisionStrategyUpdatesSortingType;
}

export const decisionStrategyUpdatesPagination = Pagination<ReduxState, StrategyUpdateType, PaginationParams>(
  'decisionStrategyUpdates',
  STRATEGY_UPDATES_PER_PAGE_DEFAULT,
  ({ strategyId }) => ['strategiesById', strategyId],
);
