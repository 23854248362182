import React, { FC } from 'react';
import {
  ConditionalDisplayRule,
  ConditionalDisplayRuleBuildParams,
} from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import ConditionalDisplayBuilder from 'components/ConditionalDisplayRuleBuilder';
import styles from './ConditionalDisplayRuleBuilderPopup.module.scss';

export interface ConditionalDisplayBuilderPopupProps {
  variableName: string;
  conditionalDisplayRule?: ConditionalDisplayRule | null;
  onClose: () => void;
  onSave: (conditionalDisplayRule: ConditionalDisplayRuleBuildParams) => void;
  usePortal?: boolean;
  isRuleBuildingInProgress?: boolean;
}

const ConditionalDisplayRuleBuilderPopup: FC<ConditionalDisplayBuilderPopupProps> = ({
  variableName,
  onClose,
  conditionalDisplayRule,
  isRuleBuildingInProgress,
  onSave,
  usePortal,
}) => {
  const renderTooltip = () => (
    <div className={styles.tooltip}>
      <p>The variable will be displayed only if this condition</p>
      <p>passes or if the variable contains a value</p>
    </div>
  );

  return (
    <PopUp
      usePortal={usePortal}
      closePopUp={onClose}
      title={conditionalDisplayRule ? 'Edit Display Condition' : 'Add Display Condition'}
      titleTooltip={renderTooltip()}
    >
      <PopUpContent hasTopMargin>
        <ConditionalDisplayBuilder
          variableName={variableName}
          conditionalDisplayRule={conditionalDisplayRule}
          isRuleBuildingInProgress={isRuleBuildingInProgress}
          onSave={onSave}
        />
      </PopUpContent>
    </PopUp>
  );
};

export default ConditionalDisplayRuleBuilderPopup;
