import borrowerApplicationsPagination from 'components/LoanOriginationSystem/BorrowerDetails/pagination';
import { SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const BorrowerApplicationsPaginationMiddleware = (api: SimplifiedApplicationsApi) => {
  return borrowerApplicationsPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getBorrowerApplications(
      params.borrowerId,
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
      params.sortingType,
    );
  });
};

export default BorrowerApplicationsPaginationMiddleware;
