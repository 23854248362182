import React from 'react';
import AutoCompletion from 'components/AutoCompletion';
import { AutoCompletionSyncProps, SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './CountryCodeSelect.module.scss';
import { PHONE_NUMBER_FORMATS } from 'constants/phoneNumberFormats';
import { flags } from 'components/PhoneInput';

const options: Option[] = PHONE_NUMBER_FORMATS.map((phoneNumberFormat: Option) => {
  const Flag = flags[phoneNumberFormat.value];

  return {
    value: phoneNumberFormat.value,
    name: phoneNumberFormat.name,
    description: phoneNumberFormat.description,
    icon: <Flag className={styles.flag} />,
  };
});

interface CountryCodeSelectProps extends Omit<AutoCompletionSyncProps, 'options'> {}

const AUTO_COMPLETION_SEARCHED_ATTRS: SearchableOptionAttribute[] = ['name', 'description'];

const CountryCodeSelect = (props: CountryCodeSelectProps) => {
  return <AutoCompletion options={options} searchedOptionAttrs={AUTO_COMPLETION_SEARCHED_ATTRS} {...props} />;
};

export default CountryCodeSelect;
