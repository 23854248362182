import LimitDecimalNumbers from 'components/MonetaryInput/utils';
import maskNumberValue from './masks/maskNumberValue';
import { VariableAccessPermissionType } from 'Variables/VariablesTypes';

const limitDecimalNumbers = LimitDecimalNumbers(2);

const formatMonetaryValue = (value: number, currencyType: string = '', alwaysShowFractionalPart = false) => {
  const valueAsString = value.toString();

  const limitedValue = limitDecimalNumbers(valueAsString);
  const maskedValue = maskNumberValue(limitedValue);
  const currencySymbolText = `${currencyType}${currencyType.length > 1 ? ' ' : ''}`;

  const formattedValue = valueAsString.includes('-')
    ? maskedValue.replace('-', `-${currencySymbolText}`)
    : `${currencySymbolText}${maskedValue}`;

  return alwaysShowFractionalPart ? `${formattedValue}${!formattedValue.includes('.') ? '.00' : ''}` : formattedValue;
};

export default formatMonetaryValue;

export const formatMonetaryValueWithAccessPermissions = (
  accessPermissions: VariableAccessPermissionType | undefined,
  value: number,
  currencyType?: string,
  alwaysShowFractionalPart?: boolean
) => {
  switch (accessPermissions) {
    case VariableAccessPermissionType.ReadWrite:
    case VariableAccessPermissionType.ReadOnly:
      return formatMonetaryValue(value, currencyType, alwaysShowFractionalPart);
    case VariableAccessPermissionType.NoAccess:
    case VariableAccessPermissionType.PartialRead:
      // PartialRead is not supported for Numeric variables, but it's better to have a fallback rather than
      // display an error page or to disclose the value.
      return `${currencyType}***`;
    default:
      return null; // Access permissions not loaded yet.
  }
};
