import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getBorrowerProfileCards } from 'BorrowerProfileCards/Thunks';
import { getBorrowerProfileCardsSelector } from 'BorrowerProfileCards/Selectors';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import ProfileConfigurationPage from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/pages/ProfileConfigurationPage';
import ProductBorrowerTypeToggleGroup from 'components/LoanOriginationSystem/ProductBorrowerTypeToggleGroup';
import { formatDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';

export interface BorrowerProfileConfigurationPageProps {
  title: string;
  availableBorrowerTypes?: BorrowerType[];
}

const BorrowerProfileConfigurationPage: FC<BorrowerProfileConfigurationPageProps> = ({
  title,
  availableBorrowerTypes = [],
}) => {
  const dispatch = useDispatch();
  const [selectedBorrowerType, setSelectedBorrowerType] = useState<BorrowerType | null>(null);

  const borrowerProfileCards = useSelector((state: ReduxState) => {
    return selectedBorrowerType
      ? getBorrowerProfileCardsSelector(state, selectedBorrowerType)
      : null;
  });

  useEffect(() => {
    if (selectedBorrowerType) {
      dispatch(getBorrowerProfileCards(selectedBorrowerType));
    }
  }, [selectedBorrowerType]);

  useEffect(() => {
    const [borrowerType] = availableBorrowerTypes;

    setSelectedBorrowerType(borrowerType);
  }, [availableBorrowerTypes]);

  const handleOnGoToProfileClick = () => {
    window.open(`${window.location.origin}/los/configuration/borrowers`);
  };

  const renderActions = () => (
    <>
      <ProductBorrowerTypeToggleGroup
        selectedBorrowerType={selectedBorrowerType}
        onChange={(type) => setSelectedBorrowerType(type as BorrowerType)}
        availableBorrowerTypes={availableBorrowerTypes || []}
      />
    </>
  );

  return (
    <ProfileConfigurationPage
      cards={borrowerProfileCards}
      title={title}
      actions={renderActions()}
      onGoToProfileClick={handleOnGoToProfileClick}
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
      profileName="Borrower"
    />
  );
};

export default BorrowerProfileConfigurationPage;

