import React, { FC } from 'react';
import styles from './StrategyHeader.module.scss';
import { LockedImage, UnlockedImage } from 'static/images';
import EditableInput from 'components/EditableInput';
import Button from 'components/Button';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import DescriptionArea from 'components/StrategyOverview/DescriptionArea';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import { Strategy, NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import trimAll from 'utils/trimAll';
import RowActions from 'components/RowActions';
import { openArchiveVersionWizard } from 'components/ArchiveStrategyWizard/Actions';
import { openDeleteStrategyWizard } from 'DeleteStrategyWizard/Actions';
import { useDispatch } from 'react-redux';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { openDuplicateStrategyWizard } from 'DuplicateStrategyWizard/Actions';
import StrategyStatusLabel from 'components/StrategyStatusLabel';
import { StrategyGroupVersion } from 'api/Types';
import { isDetailedStrategy } from 'pages/Strategy/utils';
import UserAvatar from 'components/UserAvatar';
import { validateLettersNumbersOnly } from 'input-validators';

interface StrategyHeaderProps {
  strategy: Strategy | StrategyGroupVersion;
  handleStrategyNameUpdate: (id: string, name: string) => void;
  onOpenCreateNewStrategyVersionPopUp: () => void;
  handleStrategyDescriptionUpdate: (id: string, description: string) => void;
  blockingActionInProgress: boolean;
}

const StrategyHeader: FC<StrategyHeaderProps> = ({
  strategy,
  handleStrategyNameUpdate,
  onOpenCreateNewStrategyVersionPopUp,
  handleStrategyDescriptionUpdate,
  blockingActionInProgress,
}) => {
  const dispatch = useDispatch();

  const {
    id,
    name,
    description,
    status,
    version,
    isLocked,
    updated,
    editorFirstName,
    editorLastName,
    editorImageId,
  } = isDetailedStrategy(strategy) ? strategy : {
    ...strategy,
    updated: undefined,
    editorFirstName: undefined,
    editorLastName: undefined,
    editorImageId: undefined,
  };
  const isArchived = status === NormalizedStatus.Archive;
  const strategyNameInputProps = useAsyncValueInputProps({
    value: name,
    handleChange: (strategyName) => {
      const correctStrategyName = trimAll(strategyName);
      handleStrategyNameUpdate(id, correctStrategyName);
    },
  });

  const isStrategyNameEditable = !isLocked && version === 1 && strategy.versionsCount === 1;
  return (
    <>
      <div className={styles.strategyHeader}>
        <EditableInput
          {...strategyNameInputProps}
          className={styles.strategyHeader__title}
          disabled={!isStrategyNameEditable}
          valueValidator={validateLettersNumbersOnly}
        />
        <div className={styles.strategyHeader__button}>
          <Button
            kind="secondary"
            size="default"
            onClick={onOpenCreateNewStrategyVersionPopUp}
            disabled={blockingActionInProgress}
          >
            Create New Version
          </Button>
        </div>
        <RowActions
          btnClassName={styles.strategyHeader__actionsButton}
          alwaysVisible
          actions={[
            {
              title: 'Create Duplicate',
              handler: () =>
                dispatch(openDuplicateStrategyWizard(id, name, StrategyActionOrigin.StrategyGroupVersionsList)),
            },
            {
              title: `${isArchived ? 'Restore' : 'Archive'} Version`,
              handler: () =>
                dispatch(openArchiveVersionWizard(id, name, isArchived, version, StrategyActionOrigin.VersionOverview)),
              separatorRequired: true,
            },
            {
              title: 'Delete Version',
              handler: () =>
                dispatch(
                  openDeleteStrategyWizard(
                    'version',
                    { id, name, version },
                    StrategyActionOrigin.VersionOverview,
                    true,
                  ),
                ),
              danger: true,
            },
          ]}
        />
      </div>
      <div className={styles.strategyInfo}>
        <div className={styles.strategyData}>
          <StrategyStatusLabel status={status} className={styles.statusLabel} />
          <div className={styles.strategyVersion}>
            Version {version}
            {isLocked ? <LockedImage /> : <UnlockedImage />}
          </div>
          <div className={styles.strategyUpdateInfo}>
            <UserAvatar
              imageId={editorImageId}
              firstName={editorFirstName}
              lastName={editorLastName}
              size="tiny"
              tooltip
            />
            {updated && <p>
              Updated <DateTime time={updated} format={DateTimeFormat.Long} />
            </p>}
          </div>
        </div>
        <DescriptionArea
          description={description}
          handleDescriptionUpdate={(descriptionValue) => handleStrategyDescriptionUpdate(id, descriptionValue)}
        />
      </div>
    </>
  );
};

export default StrategyHeader;
