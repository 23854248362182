import CoreSystemApi from 'api/Core/CoreSystemApi';

export interface PermissionGroup {
  id: string;
  name: string;
  position: number;
  hasTeamMembers: boolean;
}

export interface CreatePermissionGroupParams {
  name: string;
  position: number;
}

export type UpdatePermissionGroupParams = Partial<CreatePermissionGroupParams>;

export type BulkUpdatePermissionGroupParams = PermissionGroup;

export interface IPermissionGroupsApi {
  getAll(): Promise<PermissionGroup[]>;
  create(params: CreatePermissionGroupParams): Promise<PermissionGroup>;
  update(id: string, params: UpdatePermissionGroupParams): Promise<PermissionGroup>;
  bulkUpdate(params: BulkUpdatePermissionGroupParams[]): Promise<PermissionGroup[]>;
  delete(id: string): Promise<void>;
}

export default class PermissionGroupsApi extends CoreSystemApi<PermissionGroup> implements IPermissionGroupsApi {
  protected resourceName = 'permission-groups';

  public async getAll() {
    return this.getResources();
  }

  public create(params: CreatePermissionGroupParams) {
    return this.createResource({ ...params });
  }

  public update(id: string, params: UpdatePermissionGroupParams) {
    return this.updateResource(id, { ...params });
  }

  public bulkUpdate(params: BulkUpdatePermissionGroupParams[]) {
    return this.fetch<PermissionGroup[]>(`/${this.resourceName}/bulk`, 'PUT', { permissionGroups: params });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
