import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Title from './Title';
import Subtitle from './Subtitle';
import {
  openChangeApplicationStatusPopup,
  addApplicationLabel,
  deleteApplicationLabel,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import {
  changeApplicationUpdatingState,
  getProductsList,
  openDeleteApplicationPopup,
  selectProduct,
} from 'LoanOriginationSystemApplications/ActionCreator';
import InfoPanel from './InfoPanel';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import CurrencySymbols from 'enums/CurrencySymbols';
import useStandardVariables from 'hooks/useStandardVariables';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { getDeclineReasons } from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import { useHistory } from 'react-router';
import { getProductLabels } from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import openDuplicateApplicationPopupOrShowErrorMessage from 'LoanOriginationSystemApplications/openDuplicateApplicationPopupOrShowErrorMessage';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import {
  ApplicationStatusesActionOrigin,
  getApplicationStatuses,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import ApplicationDetailsSkeleton from './ApplicationDetailsSkeleton';

interface ApplicationDetailsProps {
  applicationDisplayId: string;
}

const ApplicationDetails = ({ applicationDisplayId }: ApplicationDetailsProps) => {
  const dispatch = useDispatch();
  const { applicationData, isAddingLabelInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage,
  );
  const { productsList, selectedProduct, filters, applicationUpdatingStatesById } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplications,
  );
  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, applicationData?.product.id)) || [];
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const declineReasons = applicationData?.product.id ? declineReasonsByProductId[applicationData?.product.id] : null;
  const standardVariables = useStandardVariables();
  const history = useHistory();

  const { user } = useSelector((state: ReduxState) => state.sessionInfo);

  const onStatusSelect = (status: ApplicationStatus) => {
    dispatch(openChangeApplicationStatusPopup(status));
  };

  const handleApplicationUpdatingStateReset = () => {
    if (applicationData) {
      dispatch(changeApplicationUpdatingState(applicationData.id, null));
    }
  };

  useEffect(() => {
    if (!productsList) {
      dispatch(getProductsList());
    }
  }, [applicationDisplayId]);

  useEffect(() => {
    return () => handleApplicationUpdatingStateReset();
  }, []);

  useEffect(() => {
    if (applicationData && !declineReasons) {
      dispatch(getDeclineReasons(applicationData.product.id));
    }
  }, [applicationData]);

  useEffect(() => {
    if (
      applicationData &&
      productsList &&
      productsList.length > 0 &&
      (!selectedProduct || !productsList.some(({ id }) => selectedProduct.id === id))
    ) {
      dispatch(selectProduct(applicationData.product.id));
    }
  }, [productsList, applicationData]);

  useEffect(() => {
    if (applicationData && applicationStatuses.length === 0) {
      dispatch(
        setActionOrigin(
          getApplicationStatuses(applicationData.product.id),
          ApplicationStatusesActionOrigin.Applications,
        ),
      );
    }
  }, [applicationData]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(getProductLabels(selectedProduct.name));
    }
  }, [selectedProduct]);

  if (!applicationData || !standardVariables || !applicationStatuses.length) {
    return <ApplicationDetailsSkeleton />;
  }

  const filteredApplicationStatuses = applicationStatuses.filter((status) => {
    return (
      user?.permissionGroupId &&
      (status.permissionGroupsAbleToViewApplicationOnBoard[user.permissionGroupId] ?? true) &&
      status.name !== DefaultApplicationStatus.Rejected &&
      status.name !== DefaultApplicationStatus.Approved
    );
  });

  const isCurrentStatusVisible = () => {
    const selectedStatus = applicationStatuses.find((status) => {
      return applicationData.status.name === status.name;
    });

    return user && (selectedStatus!.permissionGroupsAbleToViewApplicationOnBoard[user.permissionGroupId] ?? true);
  };

  const statusesAmount = filteredApplicationStatuses.length;

  const status = applicationStatuses.find(({ id }) => id === applicationData.status.id)!;
  const statusNumber = applicationStatuses.indexOf(status) + 1;

  const currencySymbol = getFormattedLoanAmountCurrency(standardVariables);

  const onDeleteApplication = () => {
    dispatch(openDeleteApplicationPopup(applicationData.id));
  };

  const onEditApplication = () => {
    history.push(`/los/applications/${applicationData.displayId}?edit`);
  };

  const onDuplicateApplication = () => {
    openDuplicateApplicationPopupOrShowErrorMessage(
      {
        displayId: applicationData.displayId,
        id: applicationData.id,
        originalApplicationId: applicationData.originalApplicationId,
        borrowerFullName: getBorrowerFullName(applicationData.borrowerType, applicationData.variables),
      },
      dispatch,
    );
  };

  const handleAddLabel = (labelId: string) => {
    if (!applicationData) {
      return;
    }

    dispatch(addApplicationLabel(applicationData.id, labelId));
  };

  const handleDeleteLabel = (label: BaseLabelInfo) => {
    if (!applicationData) {
      return;
    }

    dispatch(deleteApplicationLabel(applicationData.id, label.id));
  };

  return (
    <>
      <Title
        name={getBorrowerFullName(applicationData.borrowerType, applicationData.variables)}
        amount={applicationData.variables[ApplicationDefaultVariable.LoanAmount] as number}
        currencySymbol={currencySymbol || CurrencySymbols.Usd}
        statuses={applicationStatuses}
        onStatusSelect={onStatusSelect}
        selectedStatus={applicationData.status}
        onEditApplication={onEditApplication}
        onDeleteApplication={onDeleteApplication}
        onDuplicateApplication={onDuplicateApplication}
        deleteDisabled={!user?.isOwner}
        sticky
        updatingState={applicationUpdatingStatesById[applicationData.id]}
        onApplicationUpdatingStateReset={handleApplicationUpdatingStateReset}
      />
      <Subtitle
        member={applicationData.updatedBy}
        date={applicationData.updatedAt}
        applicationLabels={applicationData.labels}
        productLabels={filters.labels}
        onAddLabel={handleAddLabel}
        onDeleteLabel={handleDeleteLabel}
        isAddingLabelInProgress={isAddingLabelInProgress}
        displayLabelsSection
      />
      <InfoPanel
        standardVariables={standardVariables}
        productName={applicationData.product.name}
        statusName={applicationData.status.name}
        isStatusVisible={isCurrentStatusVisible()}
        statusesAmount={statusesAmount}
        statusNumber={statusNumber}
        applicationData={applicationData}
      />
    </>
  );
};

export default ApplicationDetails;
