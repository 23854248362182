import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import { DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';
import SkeletonUploadFileStub from 'components/LoanOriginationSystem/DocumentsUploadConfiguration/SkeletonUploadFileStub';
import sortByPosition from 'utils/sortByPosition';
import UploadFile from 'components/UploadFile';
import Label from 'components/Label';
import { CloseImage } from 'static/images';
import DocumentUploadFile from './DocumentUploadFile';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCalculationsLogic from 'hooks/useCalculationsLogic';
import useFullApplicationData from 'components/LoanOriginationSystem/CreateApplication/Forms/useFullApplicationData';
import styles from './UploadApplicationDocumentsForm.module.scss';
import useUploadApplicationDocumentsValidation from './useUploadApplicationDocumentsValidation';

export interface UploadApplicationDocumentsFormProps {
  files: DocumentUploadFile[];
  applicationDocumentConfigurations: ApplicationDocumentConfiguration[] | null;
  onFileAdd: (file: DocumentUploadFile) => void;
  onFileRemove: (configurationId: string) => void;
  applicationDetailsFormData: FormLayoutData;
  borrowerFormData: FormLayoutData;
  intermediaryFormData: FormLayoutData;
  coBorrowersFormData: FormLayoutData[];
  selectedBorrower: Borrower | null;
  selectedCoBorrowers: Array<Borrower | null>;
  selectedIntermediary: Intermediary | null;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
  productCalculations: ProductCalculation[] | null;
}

const SKELETON_DOCUMENTS_COUNT = 5;
const acceptFileTypes = Object.values(DocumentExtension)
  .map((extension) => `.${extension}`)
  .join(',');

const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25 Mb

const UploadApplicationDocumentsForm: FC<UploadApplicationDocumentsFormProps> = ({
  files,
  onFileAdd,
  onFileRemove,
  applicationDocumentConfigurations,
  applicationDetailsFormData,
  borrowerFormData,
  intermediaryFormData,
  coBorrowersFormData,
  selectedCoBorrowers,
  selectedBorrower,
  selectedIntermediary,
  productCalculations,
  renderActions,
}) => {
  const fullApplicationData = useFullApplicationData({
    borrowerFormData,
    intermediaryFormData,
    coBorrowersFormData,
    selectedCoBorrowers,
    selectedBorrower,
    selectedIntermediary,
    applicationDetailsFormData,
  });

  const fullApplicationDataWithCalculations = useCalculationsLogic(productCalculations, fullApplicationData);

  const displayFieldsAttributes = useConditionalDisplayLogic(applicationDocumentConfigurations, fullApplicationDataWithCalculations);
  
  const validateDocuments = useUploadApplicationDocumentsValidation(applicationDocumentConfigurations, files);

  const sortedApplicationDocumentConfiguration = useMemo(() => {
    if (!applicationDocumentConfigurations) {
      return null;
    }

    return sortByPosition(applicationDocumentConfigurations);
  }, [applicationDocumentConfigurations]);

  const filesByConfigurationId = useMemo(() => {
    return files.reduce((previousFilesByConfigurationId, file) => ({
      ...previousFilesByConfigurationId,
      [file.configurationId]: file,
    }), {});
  }, [files]);

  const handleFileChange = (configuration: ApplicationDocumentConfiguration, file: File | null) => {
    if (!file) {
      onFileRemove(configuration.id);

      return;
    }

    onFileAdd({
      actualFile: file,
      configurationId: configuration.id,
      configurationName: configuration.name,
      folderId: configuration.folderId,
    });
  };

  const renderFileUpload = (configuration: ApplicationDocumentConfiguration) => {
    const displayFileUpload = !displayFieldsAttributes
      || displayFieldsAttributes[configuration.id]
      || filesByConfigurationId[configuration.id]?.actualFile;

    return (
      <div className={clsx(styles.fileUploadContainer, !displayFileUpload && styles.hiddenFileUploadContainer)} key={configuration.id}>
        <Label className={styles.fileUploadLabel} required={configuration.required}>{configuration.name}</Label>
        <UploadFile
          accept={acceptFileTypes}
          maxFileSize={MAX_FILE_SIZE}
          file={filesByConfigurationId[configuration.id]?.actualFile}
          onFileChange={(file) => handleFileChange(configuration, file)}
          closeIcon={<CloseImage className={styles.deleteFileIcon} />}
        />
      </div>
    );
  };
  
  const renderFormContent = () => {
    if (!sortedApplicationDocumentConfiguration || !displayFieldsAttributes) {
      return (
        <>
          {new Array(SKELETON_DOCUMENTS_COUNT).fill(null).map((key, index) => (
            <SkeletonUploadFileStub className={styles.fileUploadContainer} key={index} />
          ))}
        </>
      );
    }
    
    return sortedApplicationDocumentConfiguration.map(renderFileUpload);
  };

  return (
    <div>
      {renderFormContent()}
      {renderActions?.(!sortedApplicationDocumentConfiguration, !validateDocuments(displayFieldsAttributes))}
    </div>
  );
};

export default UploadApplicationDocumentsForm;
