import { MiddlewareAPI, AnyAction, isRejected } from '@reduxjs/toolkit';
import tap from 'lodash/tap';
import notification from 'handlers/notification/notificationActionCreator';

export interface AsyncThunkNotificationRejectionMetaData {
  disableNotificationErrorHandling?: boolean;
}

const AsyncThunkRejectionNotificationMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) =>
  tap(next(action), () => {
    if (action.meta?.disableNotificationErrorHandling || !isRejected(action) || action.error.name === 'AbortError') {
      return;
    }

    const { message } = action.error;

    notification.createNotification(message ?? 'Error', 'error', dispatch);
  });

export default AsyncThunkRejectionNotificationMiddleware;
