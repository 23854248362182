import React, { useEffect, useState, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import ApplicationDetails from 'components/LoanOriginationSystem/ApplicationDetails';
import ChangeStatusConfirmationPopup from 'components/LoanOriginationSystem/ApplicationDetails/ChangeStatusConfirmationPopup';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  closeChangeApplicationStatusPopup,
  getApplicationData,
  clearApplicationData,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import {
  createDocuSignEnvelope,
  deleteApplicationDocument,
  requestCustomerUpload,
  uploadApplicationDocument,
  closeApplicationDocumentPopup,
  setDocumentToUpdate,
  createDocumentFolder,
  updateApplicationDocument,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { getParentFolderId, getDocumentToUpdate } from 'LoanOriginationSystemApplicationPage/Documents/Selectors';
import { ApplicationDocumentPopUpType } from 'LoanOriginationSystemApplicationPage/Documents/Types';
import {
  changeApplicationStatus,
  ChangeApplicationStatusActionOrigin,
  closeDeleteApplicationPopup,
  closeDuplicateApplicationPopup,
  deleteApplication,
  duplicateApplication,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { useHistory, useParams } from 'react-router';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useQueryParams } from 'hooks/useQueryParam';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import ApplicationTabs from 'components/LoanOriginationSystem/ApplicationTabs';
import CreateTask from 'components/LoanOriginationSystem/CreateTask';
import EditTask from 'components/LoanOriginationSystem/EditTask';
import { closeDeleteTaskPopup, deleteTask } from 'LoanOriginationSystemTasks/ActionCreator';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import TasksFilter from 'components/LoanOriginationSystem/TasksDashboard/TasksFilter';
import UploadApplicationDocumentPopup from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/UploadApplicationDocumentPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import styles from './Application.module.scss';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationNotePopUpType } from 'LoanOriginationSystemApplicationPage/Notes/Types';
import { ApplicationNotePopUp } from 'ApplicationNoteWizard/ApplicationNoteWizard';
import {
  addApplicationNote,
  closeApplicationNotePopup,
  deleteApplicationNote,
  setCurrentCurrentApplicationNote,
  updateApplicationNote,
} from 'LoanOriginationSystemApplicationPage/Notes/ActionCreator';
import DeleteApplicationNotePopUp from 'ApplicationNoteWizard/DeleteApplicationNoteWizard';
import RequestCustomerUploadPopup from 'components/LoanOriginationSystem/ApplicationTabs/RequestCustomerUploadPopup';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import DeleteApplicationConfirmationPopup from 'components/LoanOriginationSystem/DeleteApplicationConfirmationPopup/DeleteApplicationConfirmationPopup';
import {
  ApplicationStatusesActionOrigin,
  getApplicationStatuses,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import ConnectEmailsPopup from 'components/LoanOriginationSystem/ApplicationTabs/ConnectEmailsPopup';
import { getOrganizationEmail } from 'utils/emailGetters';
import { closeConnectEmailsPopup, closeDeleteEmailPopup, deleteEmail } from 'LoanOriginationSystemEmails/ActionCreator';
import DeleteEmailConfirmationPopup from 'components/LoanOriginationSystem/DeleteEmailConfirmationPopup/DeleteEmailConfirmationPopup';
import EmailView from 'components/LoanOriginationSystem/EmailView';
import EmailNotFound from 'components/LoanOriginationSystem/EmailView/EmailNotFound';
import EditApplication from 'components/LoanOriginationSystem/EditApplication';
import { setBorrowerToUnlock, unlockBorrower } from 'LoanOriginationSystemBorrowers/BorrowerLock/ActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import UnlockCustomerPopup from 'components/LoanOriginationSystem/CreateApplication/UnlockBorrowerPopup';
import { ESignIntegrationType } from 'api/Types';
import {
  resetSelectedCoBorrowers,
  selectIntermediary,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { uniqueId } from 'lodash';
import SendESignaturePopup from 'components/LoanOriginationSystem/SendESignaturePopup';
import { useESignTemplatesApi } from 'providers/ApiServiceProvider';
import DuplicateApplicationConfirmationPopup from 'components/LoanOriginationSystem/DuplicateApplicationConfirmationPopup/DuplicateApplicationConfirmationPopup';
import CreateOrRenameDocumentFolderPopUp from 'components/CreateOrRenameDocumentFolderPopUp';
import DeleteDocumentFolderPopUp from 'components/DeleteDocumentFolderPopUp';
import RenameApplicationDocumentPopup from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/RenameApplicationDocumentPopup';
import ApplicationAttribute from 'LoanOriginationSystemApplications/CreateApplication/ApplicationAttribute';
import ApplicationSkeleton from './ApplicationSkeleton';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';
import DecisionView from 'components/DecisionResultsView';
import DeleteDecisionPopUp from 'components/Decisions/PopUps/DeleteDecisionPopUp';
import RunDecisionPopUpContainer from 'components/Decisions/PopUps/RunDecisionPopUpContainer';
import {
  closeRunDecisionPopup,
  DecisionsState,
  requestDecisions
} from 'Decisions/DecisionsStore';
import {
  closeDeleteDecisionPopUp,
  deleteDecision,
  DeleteDecisionState,
  openDeleteDecisionPopUp
} from 'Decisions/DeleteDecisionStore';
import DecisionsFilter from 'components/Decisions/DecisionsFilter';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';
import DecisionResultContextualView from 'components/LoanOriginationSystem/DecisionResultContextualView';

const leftNav = makeLeftNavigation(NavigationLinkId.Applications, ApplicationSectionName.LoanOriginationSystem);

const Application = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();
  const queryParams = useQueryParams();
  const emailId = queryParams.get('emailId');
  const decisionId = queryParams.get('decision-id');
  const decisionOverviewId = queryParams.get('decision-overview-id');
  const eSignTemplatesApi = useESignTemplatesApi();

  const {
    statusToChange,
    applicationData,
    isStatusChangeInProgress,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage);
  const {
    parentFolderId,
    documentToUpdate,
    isApplicationDocumentsPopupOpen,
    applicationDocumentsPopupType,
    isApplicationDocumentsUpdatePending,
  } = useSelector((state: ReduxState) => ({
    parentFolderId: getParentFolderId(state),
    documentToUpdate: getDocumentToUpdate(state),
    isApplicationDocumentsPopupOpen: state.loanOriginationSystemDocuments.isPopupOpen,
    applicationDocumentsPopupType: state.loanOriginationSystemDocuments.popUpType,
    isApplicationDocumentsUpdatePending: state.loanOriginationSystemDocuments.isUpdatePending,
  }));
  const {
    isPopupOpen: isNotePopupOpen,
    popUpType: notePopUpType,
    currentNote,
    items: notesList,
  } = useSelector((state: ReduxState) => ({
    isPopupOpen: state.loanOriginationSystemApplicationPage.notes.isPopupOpen,
    popUpType: state.loanOriginationSystemApplicationPage.notes.popUpType,
    currentNote: state.loanOriginationSystemApplicationPage.notes.currentNote,
    search: state.loanOriginationSystemApplicationPage.notes.search,
    sortingType: state.loanOriginationSystemApplicationPage.notes.sortingType,
    items: state.loanOriginationSystemApplicationPage.notes.items,
  }));

  const {
    filters: emailFilters,
    sortingType,
    connectEmailsPopUpOpen,
    emailIdToDelete,
    deleteEmailInProgress,
  } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemEmails,
  );
  const {
    filters: decisionFilters,
    isFiltersPopUpVisible,
    sortingType: decisionSortingType,
    runDecisionPopupOpen,
  } = useSelector<ReduxState, DecisionsState>((state) => state.decisions);
  const { decisionIdToDelete } = useSelector<ReduxState, DeleteDecisionState>(
    (state) => state.deleteDecision,
  );
  const emailPaginationParams: EmailsPaginationParams = {
    search: emailFilters.searchInputValue,
    sortingType,
    createdAtDateRange: emailFilters.createdAtRange,
    selectedBorrower: applicationData && applicationData.borrowerId,
  };
  const emailPaginationProps = pagination.usePagination(emailPaginationParams);
  const emailsList = pagination.usePaginatedItems(emailPaginationParams);

  const currentNoteText = notesList.find((note) => note.id === currentNote);
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const { name: organizationName, id: organizationId } = useSelector(
    (state: ReduxState) => state.organizationInformation,
  );
  const { applicationDisplayId } = useParams<{ applicationDisplayId: string }>();

  const declineReasons = applicationData ? declineReasonsByProductId[applicationData.product.id] : [];

  const { filters, taskIdToDelete, isDeleteInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemTasks,
  );

  const {
    borrowerLock: { borrowerToUnlock, isUnlockingInProgress },
  } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);

  const { applicationIdToDelete, duplicateApplicationParams } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplications,
  );

  const params = useQueryParams();
  const history = useHistory();

  const [createOrEditTaskDataWasChanged, setCreateOrEditTaskDataWasChanged] = useState(false);
  const [editApplicationDataWasChanged] = useState(false);
  const [createTaskPopupOpen, setCreateTaskPopupOpen] = useState(false);
  const [taskIdToEdit, setTaskIdToEdit] = useState<string | null>(null);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const [unsavedChangesPopupOpen2, setUnsavedChangesPopupOpen2] = useState(false);
  const [isDeletingApplication, setIsDeletingApplication] = useState(false);
  const [isDuplicatingApplication, setIsDuplicatingApplication] = useState(false);
  const [editApplicationKey, setEditApplicationKey] = useState(uniqueId());

  const handleCloseDeleteDecisionPopUp = () => {
    dispatch(closeDeleteDecisionPopUp());
  };
  const handleDecisionRan = async (createdDecisionId: string) => {
    dispatch(requestDecisions({
      filters: {
        offset: 0,
        ...decisionFilters,
      },
      sortingType: decisionSortingType,
    }));
    history.push(`?tab-id=${queryParams.get('tab-id')}&decision-overview-id=${createdDecisionId}`);
    dispatch(getApplicationData(applicationDisplayId));
  }
  const handleCloseRunDecisionPopup = () => {
    dispatch(closeRunDecisionPopup());
  }
  const handleDeleteDecision = async () => {
    await dispatch(deleteDecision(decisionIdToDelete!));
    dispatch(requestDecisions({
      filters: {
        ...decisionFilters,
      },
      sortingType: decisionSortingType,
    }));
    closeContextualView();
  };

  const getDecisionOverviewUrlQueryPart = (decisionResult: DecisionResultListItem) =>
    `?tab-id=${queryParams.get('tab-id')}&decision-overview-id=${decisionResult.decisionId}&result-id=${decisionResult.id}`;
  const getDecisionResultOverviewLink = (decisionResult: DecisionResultListItem) => {
    return `/los/applications/${applicationDisplayId}${getDecisionOverviewUrlQueryPart(decisionResult)}`;
  }
  const handleDecisionResultClick = (decisionResult: DecisionResultListItem) => {
    history.push(getDecisionOverviewUrlQueryPart(decisionResult));
  }

  const onPopupClose = () => dispatch(closeChangeApplicationStatusPopup());

  const onApplicationNotePopUpClose = () => {
    batch(() => {
      dispatch(closeApplicationNotePopup());
      dispatch(setCurrentCurrentApplicationNote(null));
    });
  };

  const closeContextualView = () => {
    if (taskIdToEdit) {
      setTaskIdToEdit(null);
    }

    if (createTaskPopupOpen) {
      setCreateTaskPopupOpen(false);
    }

    if (params.has('edit')) {
      history.replace(`?tab-id=${queryParams.get('tab-id')}`);
    }

    if (params.has('emailId')) {
      history.replace(`?tab-id=${queryParams.get('tab-id')}`);
    }

    if (params.has('decision-id')) {
      history.replace(`?tab-id=${queryParams.get('tab-id')}`);
    }

    if (params.has('decision-overview-id')) {
      history.push(`?tab-id=${queryParams.get('tab-id')}`);
    }
  };

  const onChangeStatusClick = (status: ApplicationStatus, selectedDeclineReasons: string[]) => {
    if (!applicationData) {
      return;
    }

    const action = setActionOrigin(
      changeApplicationStatus({
        applicationId: applicationData.id,
        applicationDisplayId: applicationData.displayId,
        applicationName: getBorrowerFullName(applicationData.borrowerType, applicationData.variables),
        statusToSet: status,
        initialStatus: applicationData.status,
        declineReasons: selectedDeclineReasons,
      }),
      ChangeApplicationStatusActionOrigin.ApplicationsDetails,
    );

    dispatch(action);
  };

  const onDeleteTaskPopupClose = () => {
    dispatch(closeDeleteTaskPopup());
  };

  const onDeleteTask = () => {
    dispatch(deleteTask(taskIdToDelete!));
  };

  const handleApplicationDocumentsPopUpClose = () =>
    batch(() => {
      dispatch(closeApplicationDocumentPopup());
      dispatch(setDocumentToUpdate(null));
    });

  const handleRenameApplicationDocument = async (updatedName: string) => {
    await dispatch(updateApplicationDocument({ documentId: documentToUpdate!.id, document: { name: updatedName } }));
    handleApplicationDocumentsPopUpClose();
  };

  const handleConfirmApplicationDocumentDelete = async () => {
    if (!documentToUpdate) {
      return;
    }

    await dispatch(deleteApplicationDocument(documentToUpdate.id));
    handleApplicationDocumentsPopUpClose();
  };

  const handleApplicationDocumentUpload = async (file: File) => {
    if (!applicationData) {
      return;
    }

    await dispatch(uploadApplicationDocument({ applicationId: applicationData.id, file, parentId: parentFolderId }));
    dispatch(closeApplicationDocumentPopup());
  };

  const handleRequestCustomerUpload = async (emailData: { subject: string; description: string }) => {
    await dispatch(
      requestCustomerUpload({
        ...emailData,
        applicationId: applicationData!.id,
      }),
    );
    dispatch(closeApplicationDocumentPopup());
  };

  const handleAddApplicationNote = (note: string) => dispatchRoutine(addApplicationNote(applicationData!.id, note));

  const handleUpdateApplicationNote = (note: string) =>
    dispatchRoutine(updateApplicationNote(applicationData!.id, currentNote!, note));

  const handleDeleteApplicationNote = () => dispatchRoutine(deleteApplicationNote(applicationData!.id, currentNote!));

  const handleDeleteApplication = async () => {
    setIsDeletingApplication(true);
    await dispatchRoutine(deleteApplication(applicationIdToDelete!));
    setIsDeletingApplication(false);
    history.push('/los/applications');
  };

  const handleDuplicateApplication = async () => {
    setIsDuplicatingApplication(true);

    try {
      const result = await dispatchRoutineWithResult(duplicateApplication(duplicateApplicationParams!.id));

      if (result[ApplicationAttribute.ApplicationId]) {
        history.push(`/los/applications/${result[ApplicationAttribute.ApplicationId]}`);
      }
    } finally {
      setIsDuplicatingApplication(false);
    }
  };

  const isEditApplication = () => {
    return params.has('edit');
  };

  const onCloseContextualView = () => {
    const taskPopupHasUnsavedChanges = (taskIdToEdit || createTaskPopupOpen) && createOrEditTaskDataWasChanged;
    const editApplicationPopupHasUnsavedChanges = params.has('edit') && editApplicationDataWasChanged;

    if (taskPopupHasUnsavedChanges || editApplicationPopupHasUnsavedChanges) {
      setUnsavedChangesPopupOpen(true);
      return;
    }

    closeContextualView();
  };

  const onConnectEmailsPopupClose = () => dispatch(closeConnectEmailsPopup());

  const handleCloseDeleteEmailPopup = () => dispatch(closeDeleteEmailPopup());
  const handleDeleteEmail = async () => {
    await dispatchRoutine(deleteEmail(emailIdToDelete!));
    closeContextualView();
  };

  const onUnlockClick = () => {
    if (!borrowerToUnlock) {
      return;
    }

    dispatch(unlockBorrower(borrowerToUnlock.id));
  };

  const resetEditApplicationForm = () => {
    dispatch(selectIntermediary(null));
    dispatch(resetSelectedCoBorrowers());

    setEditApplicationKey(uniqueId());
  };

  const handleUnsavedChangesPopupClose = () => {
    if (unsavedChangesPopupOpen) {
      setUnsavedChangesPopupOpen(false);
    } else {
      setUnsavedChangesPopupOpen2(false);
    }
  };

  const handleUnsavedChangesPopupLeave = () => {
    if (unsavedChangesPopupOpen) {
      closeContextualView();
      setUnsavedChangesPopupOpen(false);
    } else {
      resetEditApplicationForm();
      setUnsavedChangesPopupOpen2(false);
    }
  };

  const handleFetchTemplates = useCallback((search: string, type: ESignIntegrationType, abortSignal: AbortSignal) => {
    return eSignTemplatesApi.getDropdownOptions(search, type, abortSignal);
  }, []);

  const handleESignClick = async (templateId: string) => {
    if (!applicationData) {
      return;
    }

    await dispatch(createDocuSignEnvelope({ applicationId: applicationData.id, templateId }));
    dispatch(closeApplicationDocumentPopup());
  };

  const handleApplicationDocumentFolderCreate = async (name: string) => {
    if (!applicationData) {
      return;
    }

    await dispatch(createDocumentFolder({ name, applicationId: applicationData.id, parentId: parentFolderId }));

    handleApplicationDocumentsPopUpClose();
  };

  const handleApplicationDocumentFolderRename = async (name: string) => {
    await dispatch(updateApplicationDocument({ documentId: documentToUpdate!.id, document: { name } }));

    handleApplicationDocumentsPopUpClose();
  };

  const handleApplicationDocumentDelete = async () => {
    await dispatch(deleteApplicationDocument(documentToUpdate!.id));

    handleApplicationDocumentsPopUpClose();
  };

  const renderOverlay = () => {
    if (borrowerToUnlock) {
      return (
        <UnlockCustomerPopup
          onPopupClose={() => dispatch(setBorrowerToUnlock(null))}
          onUnlockClick={onUnlockClick}
          isUnlockingInProgress={isUnlockingInProgress}
        />
      );
    }

    if (statusToChange) {
      return (
        <ChangeStatusConfirmationPopup
          onChangeStatusClick={(selectedDeclineReasons) => onChangeStatusClick(statusToChange, selectedDeclineReasons)}
          declineReasons={declineReasons}
          onPopupClose={onPopupClose}
          statusToChange={statusToChange.name}
          isLoading={isStatusChangeInProgress}
        />
      );
    }

    if (taskIdToDelete) {
      return (
        <ConfirmPopup
          title="Delete Task"
          message="Are you sure you want to remove this task?"
          confirmText="Yes, Delete Task"
          declineText="No, Go Back"
          onPopupClose={onDeleteTaskPopupClose}
          onConfirmClick={onDeleteTask}
          loading={isDeleteInProgress}
        />
      );
    }

    if (unsavedChangesPopupOpen || unsavedChangesPopupOpen2) {
      return (
        <UnsavedChangesPopup
          onPopupClose={handleUnsavedChangesPopupClose}
          onLeaveClick={handleUnsavedChangesPopupLeave}
        />
      );
    }

    if (isApplicationDocumentsPopupOpen) {
      switch (applicationDocumentsPopupType) {
        case ApplicationDocumentPopUpType.UploadDocument: {
          return (
            <UploadApplicationDocumentPopup
              onPopupClose={handleApplicationDocumentsPopUpClose}
              onApplicationDocumentUpload={handleApplicationDocumentUpload}
              isLoading={isApplicationDocumentsUpdatePending}
            />
          );
        }
        case ApplicationDocumentPopUpType.RenameDocument: {
          return (
            <RenameApplicationDocumentPopup
              fileName={documentToUpdate!.name}
              onRenameApplicationDocument={handleRenameApplicationDocument}
              isUpdating={isApplicationDocumentsUpdatePending}
              onPopupClose={handleApplicationDocumentsPopUpClose}
            />
          );
        }
        case ApplicationDocumentPopUpType.DeleteDocument: {
          return (
            <ConfirmPopup
              title="Delete Document"
              message="Are you sure that you want to delete this document?"
              confirmText="Delete Document"
              declineText="No, Go Back"
              onPopupClose={handleApplicationDocumentsPopUpClose}
              onConfirmClick={handleConfirmApplicationDocumentDelete}
              loading={isApplicationDocumentsUpdatePending}
            />
          );
        }
        case ApplicationDocumentPopUpType.RequestCustomerUpload: {
          return (
            <RequestCustomerUploadPopup
              onPopupClose={handleApplicationDocumentsPopUpClose}
              organizationName={organizationName}
              borrowerEmail={applicationData!.variables[BorrowerDefaultVariable.Email] as string}
              handleRequestCustomerUpload={handleRequestCustomerUpload}
            />
          );
        }
        case ApplicationDocumentPopUpType.SendESignature: {
          return applicationData ? (
            <SendESignaturePopup
              application={applicationData}
              type={ESignIntegrationType.DocuSign}
              onGetTemplates={handleFetchTemplates}
              onSendESign={handleESignClick}
              onPopupClose={handleApplicationDocumentsPopUpClose}
              isSendingInProgress={isApplicationDocumentsUpdatePending}
              eSignatureName="DocuSign"
            />
          ) : null;
        }
        case ApplicationDocumentPopUpType.CreateFolder:
        case ApplicationDocumentPopUpType.RenameFolder: {
          return (
            <CreateOrRenameDocumentFolderPopUp
              closePopUp={handleApplicationDocumentsPopUpClose}
              popUpType={applicationDocumentsPopupType}
              create={handleApplicationDocumentFolderCreate}
              rename={handleApplicationDocumentFolderRename}
              folder={documentToUpdate}
              isLoading={isApplicationDocumentsUpdatePending}
            />
          );
        }
        case ApplicationDocumentPopUpType.DeleteFolder: {
          return (
            <DeleteDocumentFolderPopUp
              isApplicationFolder
              closePopUp={handleApplicationDocumentsPopUpClose}
              deleteDocumentFolder={handleApplicationDocumentDelete}
              isLoading={isApplicationDocumentsUpdatePending}
            />
          );
        }
        default:
          break;
      }
    }

    if (isNotePopupOpen) {
      if (notePopUpType === ApplicationNotePopUpType.Add || notePopUpType === ApplicationNotePopUpType.Update) {
        return (
          <ApplicationNotePopUp
            closePopUp={onApplicationNotePopUpClose}
            popUpType={notePopUpType}
            create={handleAddApplicationNote}
            update={handleUpdateApplicationNote}
            note={currentNoteText}
          />
        );
      }

      return (
        <DeleteApplicationNotePopUp
          closePopUp={onApplicationNotePopUpClose}
          deleteApplicationNote={handleDeleteApplicationNote}
        />
      );
    }

    if (applicationIdToDelete) {
      return (
        <DeleteApplicationConfirmationPopup
          onConfirmClick={handleDeleteApplication}
          onClose={() => dispatch(closeDeleteApplicationPopup())}
          loading={isDeletingApplication}
        />
      );
    }

    if (duplicateApplicationParams) {
      return (
        <DuplicateApplicationConfirmationPopup
          onConfirmClick={handleDuplicateApplication}
          onClose={() => dispatch(closeDuplicateApplicationPopup())}
          loading={isDuplicatingApplication}
          params={duplicateApplicationParams}
        />
      );
    }

    if (connectEmailsPopUpOpen) {
      return (
        <ConnectEmailsPopup
          emailAddress={getOrganizationEmail(organizationId)}
          onPopupClose={onConnectEmailsPopupClose}
        />
      );
    }

    if (emailIdToDelete) {
      return (
        <DeleteEmailConfirmationPopup
          onConfirmClick={handleDeleteEmail}
          onPopupClose={handleCloseDeleteEmailPopup}
          loading={deleteEmailInProgress}
        />
      );
    }

    if (decisionIdToDelete) {
      return (
        <DeleteDecisionPopUp
          closePopUp={handleCloseDeleteDecisionPopUp}
          onDeleteDecision={handleDeleteDecision}
        />
      );
    }
    if (runDecisionPopupOpen && applicationData) {
      return (
        <RunDecisionPopUpContainer
          popUpType="single-application-run"
          application={applicationData}
          onClose={handleCloseRunDecisionPopup}
          onDecisionRan={handleDecisionRan}
        />
      );
    }

    return null;
  };

  const renderContextualView = () => {
    if (isEditApplication()) {
      return (
        <EditApplication
          key={editApplicationKey}
          onClose={onCloseContextualView}
        />
      );
    }

    if (createTaskPopupOpen) {
      return (
        <CreateTask
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          applicationId={applicationData?.id}
          onCreated={() => setCreateTaskPopupOpen(false)}
        />
      );
    }

    if (taskIdToEdit) {
      return (
        <EditTask
          id={taskIdToEdit}
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          lockApplication
          showGoToApplicationLink={false}
          onEditSuccess={() => setTaskIdToEdit(null)}
        />
      );
    }

    if (emailId && !!emailPaginationProps.itemsTotal) {
      const currentEmailInfo = emailsList.find((email) => email?.id === emailId);
      return currentEmailInfo ? (
        <EmailView
          currentEmailInfo={currentEmailInfo}
          onClose={onCloseContextualView}
          organizationEmail={getOrganizationEmail(organizationId)}
        />
      ) : (
        <EmailNotFound onClose={onCloseContextualView} />
      );
    }
    if (decisionId) {
      return (
        <DecisionView
          decisionId={decisionId}
          onClose={onCloseContextualView}
          onRowClick={handleDecisionResultClick}
          getRowLink={getDecisionResultOverviewLink}
          onDeleteDecision={() => dispatch(openDeleteDecisionPopUp({ decisionId }))}
        />
      );
    }
    if (decisionOverviewId) {
      return (
        <DecisionResultContextualView
          decisionId={decisionOverviewId}
          resultId={queryParams.get('result-id')}
          onClose={onCloseContextualView}
        />
      );
    }

    return null;
  };

  const renderRightSidePopupView = () => {
    if (isFiltersPopUpVisible) {
      return <DecisionsFilter hideStrategyStatusFilter />;
    }

    if (filters.isPopupVisible) {
      return <TasksFilter
        dueDateFrom={filters.dueDateRange.from}
        dueDateTo={filters.dueDateRange.to}
        status={filters.selectedStatus}
      />;
    }

    return null;
  };

  const applicationStatuses = useSelector(
    (state: ReduxState) => getStatusesByProductId(state, applicationData?.product.id),
  ) || [];

  useEffect(() => {
    if (applicationData && applicationStatuses.length === 0) {
      dispatch(
        setActionOrigin(
          getApplicationStatuses(applicationData.product.id),
          ApplicationStatusesActionOrigin.Applications,
        ),
      );
    }
  }, [applicationData]);

  useEffect(() => {
    dispatch(getApplicationData(applicationDisplayId));

    return () => {
      dispatch(clearApplicationData());
    };
  }, [applicationDisplayId]);

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      rightSidePopupView={renderRightSidePopupView()}
    >
      <PageWrapperWithFooter>
        <PageContent noPadding className={styles.pageContent}>
          {applicationData ? (
            <>
              <ApplicationDetails applicationDisplayId={applicationDisplayId} />
              <ApplicationTabs
                onCreateTask={() => setCreateTaskPopupOpen(true)}
                onEditTask={setTaskIdToEdit}
                selectedTabId={params.get('tab-id')}
              />
            </>
          ) : (
            <ApplicationSkeleton />
          )}
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Application;
