import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { PermissionGroupId } from 'PermissionGroups/Types';

const getGroupsById = (state: ReduxState) => state.permissionGroups.groupsById;

const getGroupId = (_: ReduxState, id: PermissionGroupId) => id;

export const getGroupById = createSelector([getGroupsById, getGroupId], (groupsByIds, groupId) => groupsByIds[groupId]);

export const getAllGroups = createSelector([getGroupsById], (groupsByIds) =>
  Object.values(groupsByIds).sort((groupA, groupB) => groupA.position - groupB.position),
);
