import { createReducer } from '@reduxjs/toolkit';
import mountChildReducers from 'utils/childReducers';
import webhookEndpointEventsTableReducer, {
  WebhookEndpointEventsTableState,
  initialState as webhookEndpointEventsTableInitialState,
} from './WebhookEndpointEventsTable/WebhookEndpointEventsTableStore';

export interface WebhookEndpointDetailsState {
  webhookEndpointEventsTable: WebhookEndpointEventsTableState;
}

const initialState: WebhookEndpointDetailsState = {
  webhookEndpointEventsTable: webhookEndpointEventsTableInitialState,
};

const webhookEndpointDetailsReducer = createReducer(initialState, () => {});

const childReducers = {
  webhookEndpointEventsTable: webhookEndpointEventsTableReducer,
};

export default mountChildReducers(webhookEndpointDetailsReducer, childReducers);
