const IntermediaryDefaultVariable: Record<string, string> = {
  Name: 'intermediary_name',
  IdNumber: 'intermediary_id_number',
  PhoneNumber: 'intermediary_phone',
  Email: 'intermediary_email',
  Type: 'intermediary_type',
};

export type IntermediaryDefaultVariableType = typeof IntermediaryDefaultVariable;

export default IntermediaryDefaultVariable;
