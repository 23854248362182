import { createAsyncThunk } from '@reduxjs/toolkit';
import WebhookEndpointsRestApi, {
  CreateWebhookEndpointParams, UpdateWebhookEndpointParams,
  WebhookEndpoint, WebhookEndpointSortingType,
} from 'api/Webhooks/WebhookEndpointsApi';
import responseHandlers from 'api/ResponseHandlers';
import { DataFilter, TableViewData } from 'api/Types';
import { WebhookEndpointsActionType } from './WebhookEndpointsActionType';

const webhookEndpointsApi = new WebhookEndpointsRestApi(responseHandlers);

export const getWebhookEndpoints = createAsyncThunk<
  TableViewData<WebhookEndpoint>,
  { filters: DataFilter, sortingType: WebhookEndpointSortingType; }
>(
  WebhookEndpointsActionType.GetWebhookEndpoints,
  async ({ filters, sortingType }) => {
    return webhookEndpointsApi.find(filters, sortingType);
  },
);

export const getWebhookEndpoint = createAsyncThunk<WebhookEndpoint, string>(
  WebhookEndpointsActionType.GetWebhookEndpoint,
  (id) => {
    return webhookEndpointsApi.findById(id);
  },
);

export const createWebhookEndpoint = createAsyncThunk<
  WebhookEndpoint,
  CreateWebhookEndpointParams
>(
  WebhookEndpointsActionType.CreateWebhookEndpoint,
  async (params) => {
    return webhookEndpointsApi.create(params);
  },
);

export const generateNewWebhookEndpointSecret = createAsyncThunk<{ id: string, endpointSecret: string; }, string>(
  WebhookEndpointsActionType.GenerateNewWebhookEndpointSecret,
  async (id: string) => {
    return {
      id,
      endpointSecret: await webhookEndpointsApi.generateNewEndpointSecret(id),
    };
  },
);

export const updateWebhookEndpoint = createAsyncThunk<
  WebhookEndpoint,
  { id: string; } & UpdateWebhookEndpointParams
>(
  WebhookEndpointsActionType.UpdateWebhookEndpoint,
  async (params) => {
    const { id, ...updateParams } = params;

    return webhookEndpointsApi.update(id, updateParams);
  },
);

export const deleteWebhookEndpoint = createAsyncThunk<WebhookEndpoint, string>(
  WebhookEndpointsActionType.DeleteWebhookEndpoint,
  async (id) => {
    return webhookEndpointsApi.delete(id);
  },
);
