import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  LockBorrowerAction,
  LockBorrowerSuccessAction,
  SetBorrowerToLockAction,
  SetBorrowerToUnlockAction,
  UnlockBorrowerAction,
  UnlockBorrowerSuccessAction,
} from './ActionCreator';
import { LoanOriginationSystemBorrowerLockType } from './ActionTypes';
import { LoanOriginationSystemBorrowerLockState } from './Types';

export const initialState: LoanOriginationSystemBorrowerLockState = {
  isUnlockingInProgress: false,
  isLockingInProgress: false,
  borrowerToUnlock: null,
  borrowerToLock: null,
};

type LoanOriginationSystemBorrowerLockActions =
  | UnlockBorrowerAction
  | UnlockBorrowerSuccessAction
  | LockBorrowerAction
  | LockBorrowerSuccessAction
  | FailedAction<LoanOriginationSystemBorrowerLockType.LockBorrowerFailure>
  | FailedAction<LoanOriginationSystemBorrowerLockType.UnlockBorrowerFailure>
  | SetBorrowerToUnlockAction
  | SetBorrowerToLockAction;

export const loanOriginationSystemBorrowerLockReducer = (
  state: LoanOriginationSystemBorrowerLockState = initialState,
  action: LoanOriginationSystemBorrowerLockActions,
): LoanOriginationSystemBorrowerLockState => {
  switch (action.type) {
    case LoanOriginationSystemBorrowerLockType.UnlockBorrower: {
      return {
        ...state,
        isUnlockingInProgress: true,
      };
    }
    case LoanOriginationSystemBorrowerLockType.UnlockBorrowerSuccess: {
      return {
        ...state,
        isUnlockingInProgress: false,
        borrowerToUnlock: null,
      };
    }
    case LoanOriginationSystemBorrowerLockType.UnlockBorrowerFailure: {
      return {
        ...state,
        isUnlockingInProgress: false,
      };
    }
    case LoanOriginationSystemBorrowerLockType.LockBorrower: {
      return {
        ...state,
        isLockingInProgress: true,
      };
    }
    case LoanOriginationSystemBorrowerLockType.LockBorrowerSuccess: {
      return {
        ...state,
        isLockingInProgress: false,
        borrowerToLock: null,
      };
    }
    case LoanOriginationSystemBorrowerLockType.LockBorrowerFailure: {
      return {
        ...state,
        isLockingInProgress: false,
      };
    }
    case LoanOriginationSystemBorrowerLockType.SetBorrowerToUnlock: {
      return {
        ...state,
        borrowerToUnlock: action.payload.borrower,
      };
    }
    case LoanOriginationSystemBorrowerLockType.SetBorrowerToLock: {
      return {
        ...state,
        borrowerToLock: action.payload.borrower,
      };
    }
    default: {
      return state;
    }
  }
};
