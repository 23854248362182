export enum IntermediaryRelatedApplicationsTableColumnSizes {
  BorrowerFullName = 20,
  Product = 16,
  Id = 14,
  ApplicationDate = 22,
  Status = 15,
  LoanAmount = 13,
}

export enum BorrowerRelatedApplicationsTableColumnSizes {
  BorrowerFullName = 0,
  Product = 25,
  Id = 16,
  ApplicationDate = 25,
  Status = 21,
  LoanAmount = 13,
}

export type RelatedApplicationsTableColumnSizesType =
  | typeof IntermediaryRelatedApplicationsTableColumnSizes
  | typeof BorrowerRelatedApplicationsTableColumnSizes;
