import React from 'react';
import styles from './Title.module.scss';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';

interface TitleProps {
  onSearch: (search: string) => void;
  hideSearch: boolean;
  onCreatePopupOpen: () => void;
  searchInputValue: string;
  areLabelsLoading: boolean;
}

const Title = ({ onSearch, hideSearch, onCreatePopupOpen, searchInputValue, areLabelsLoading }: TitleProps) => {
  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const onSearchClear = () => {
    onSearch('');
  };

  return (
    <div className={styles.title}>
      <div className={styles.titleInner}>
        <h2 className={styles.titleName}>Application Labels</h2>
        <Button kind="primary" className={styles.addLabelButton} onClick={onCreatePopupOpen}>
          Add Label
        </Button>
      </div>
      {!hideSearch && (
        <SearchInput
          placeholder="Search"
          onClear={onSearchClear}
          onChange={onSearchInputChange}
          value={searchInputValue}
          containerClassName={styles.searchInput}
          showLoader={!!searchInputValue && areLabelsLoading}
        />
      )}
    </div>
  );
};

export default Title;
