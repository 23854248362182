import { AnyAction } from 'redux';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { Email, EmailsSortingField, EmailsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemEmailsFiltersState } from './Filters/Types';
import { BaseBorrowerInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export type GetEmailsDataSuccess = TableViewData<Email> & {
  searchInputValue: string;
};

export interface GetEmailsAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.GetEmails;
  payload: {
    filters: LoanOriginationSystemEmailsFiltersState;
    sortingType: EmailsSortingType;
  };
}

export interface GetEmailsSuccessAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.GetEmailsSuccess;
  payload: GetEmailsDataSuccess;
}

export interface SortEmailsAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.SortEmails;
  payload: {
    field: EmailsSortingField;
    ascending: boolean;
    borrowerId?: string;
  };
}

export interface OpenDeleteEmailPopupAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.OpenDeleteEmailPopup;
  payload: { emailId: string };
}

export interface CloseDeleteEmailPopupAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.CloseDeleteEmailPopup;
}

export interface DeleteEmailAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DeleteEmail;
  payload: { emailId: string };
}

export interface GetBorrowersAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilter;
}

export interface GetBorrowersSuccessAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilterSuccess;
  payload: BaseBorrowerInfo[];
}

export interface DownloadEmailAttachmentsAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachments;
  payload: {
    attachmentId: string;
  };
}

export interface DownloadSingleEmailAttachmentSuccessAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DownloadSingleEmailAttachmentSuccess;
}

export interface DownloadSingleEmailAttachmentAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DownloadSingleEmailAttachment;
  payload: {
    attachmentId: string;
  };
}

export interface DownloadEmailAttachmentsSuccessAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsSuccess;
  payload: {
    attachmentId: string;
  };
}

export interface DownloadEmailAttachmentsFailureAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsFailure;
  payload: {
    attachmentId: string;
  };
}

export interface RemoveDownloadedEmailAttachmentAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.RemoveDownloadedAttachment;
  payload: {
    attachmentDownloadingId: string;
  };
}

export interface SavedFiltersSwitch extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.SavedFiltersSwitch;
}

export const getEmails = (
  filters: LoanOriginationSystemEmailsFiltersState,
  sortingType: EmailsSortingType,
): GetEmailsAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.GetEmails,
    payload: {
      filters,
      sortingType,
    },
  };
};

export const getEmailsSuccess = (emailsData: GetEmailsDataSuccess): GetEmailsSuccessAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.GetEmailsSuccess,
    payload: emailsData,
  };
};

export const getEmailsFailed = FailedActionCreator(LoanOriginationSystemEmailsActionTypes.GetEmailsFailure);

export const sortEmails = (field: EmailsSortingField, ascending: boolean, borrowerId?: string): SortEmailsAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.SortEmails,
    payload: {
      field,
      ascending,
      borrowerId,
    },
  };
};

export const openDeleteEmailPopup = (emailId: string) => ({
  type: LoanOriginationSystemEmailsActionTypes.OpenDeleteEmailPopup,
  payload: {
    emailId,
  },
});

export const closeDeleteEmailPopup = () => ({
  type: LoanOriginationSystemEmailsActionTypes.CloseDeleteEmailPopup,
});

export const deleteEmail = (emailId: string) => ({
  type: LoanOriginationSystemEmailsActionTypes.DeleteEmail,
  payload: {
    emailId,
  },
});

export const deleteEmailSuccess = () => ({
  type: LoanOriginationSystemEmailsActionTypes.DeleteEmailSuccess,
});

export const deleteEmailFailure = FailedActionCreator(LoanOriginationSystemEmailsActionTypes.DeleteEmailFailure);

export interface OpenConnectEmailsPopupAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.OpenConnectEmailsPopup;
}

export interface CloseConnectEmailsPopupAction extends AnyAction {
  type: LoanOriginationSystemEmailsActionTypes.CloseConnectEmailsPopup;
}

export const openConnectEmailsPopup = (): OpenConnectEmailsPopupAction => ({
  type: LoanOriginationSystemEmailsActionTypes.OpenConnectEmailsPopup,
});

export const closeConnectEmailsPopup = (): CloseConnectEmailsPopupAction => ({
  type: LoanOriginationSystemEmailsActionTypes.CloseConnectEmailsPopup,
});

export const getBorrowersForEmailsFilter = (): GetBorrowersAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilter,
  };
};

export const getBorrowersForEmailsFilterSuccess = (borrowersData: BaseBorrowerInfo[]): GetBorrowersSuccessAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilterSuccess,
    payload: borrowersData,
  };
};

export const getBorrowersForEmailsFilterFailure = FailedActionCreator(
  LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilterFailure,
);

export const downloadEmailAttachments = (attachmentId: string): DownloadEmailAttachmentsAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachments,
    payload: {
      attachmentId,
    },
  };
};

export const downloadEmailAttachmentsSuccess = (attachmentId: string): DownloadEmailAttachmentsSuccessAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsSuccess,
    payload: {
      attachmentId,
    },
  };
};

export const downloadEmailAttachmentsFailure = (attachmentId: string): DownloadEmailAttachmentsFailureAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsFailure,
    payload: {
      attachmentId,
    },
  };
};

export const removeDownloadedEmailAttachment = (
  attachmentDownloadingId: string,
): RemoveDownloadedEmailAttachmentAction => ({
  type: LoanOriginationSystemEmailsActionTypes.RemoveDownloadedAttachment,
  payload: {
    attachmentDownloadingId,
  },
});

export const downloadSingleEmailAttachment = (attachmentId: string): DownloadSingleEmailAttachmentAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.DownloadSingleEmailAttachment,
    payload: {
      attachmentId,
    },
  };
};

export const downloadSingleEmailAttachmentSuccess = (): DownloadSingleEmailAttachmentSuccessAction => {
  return {
    type: LoanOriginationSystemEmailsActionTypes.DownloadSingleEmailAttachmentSuccess,
  };
};

export const downloadSingleEmailAttachmentFailure = FailedActionCreator(
  LoanOriginationSystemEmailsActionTypes.DownloadSingleEmailAttachmentFailure,
);
