import decisionResultsPagination from 'pages/Decisions/DecisionResultsPagination';
import DecisionResultApi from 'api/DecisionEngine/DecisionResultApi';

export default (api: DecisionResultApi) => {
  return decisionResultsPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getDecisionResults(
      {
        offset: (params.page - 1) * params.perPage,
        count: params.perPage,
        decisionId: params.decisionId,
        resultType: params.resultType,
      },
      params.sortingType,
    );
  });
};
