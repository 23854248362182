import React, { FC, useEffect } from 'react';
import {
  StrategyUpdateType,
  DecisionStrategyUpdatesSortingType,
  DecisionStrategyUpdatesSortingField,
} from 'DecisionStrategyUpdates/Types';
import { NullableItems, PaginationProps } from 'pagination';
import { SIZES } from 'components/StrategyOverview/StrategyUpdates/TableColumnSizes';
import styles from './StrategyUpdates.module.scss';
import UpdateLine from './UpdateLine';
import Pagination from 'components/Pagination/Pagination';
import SkeletonUpdateLine from './SkeletonUpdateLine';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import NoItems from 'components/NoItems';
import useSorting from 'hooks/useSorting';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';
import useStateReset from 'hooks/useStateReset';
import { DecisionStrategyUpdatesActions } from 'DecisionStrategyUpdates/ActionTypes';
import { useDispatch } from 'react-redux';

interface StrategyUpdatesProps {
  strategyId: string;
  decisionStrategyUpdates: NullableItems<StrategyUpdateType>;
  decisionStrategyUpdatesPaginationProps: PaginationProps;
  decisionStrategyUpdatesSortingType: DecisionStrategyUpdatesSortingType;
  onDecisionStrategyUpdatesSort: (field: DecisionStrategyUpdatesSortingField, ascending: boolean) => void;
}

const StrategyUpdates: FC<StrategyUpdatesProps> = ({
  decisionStrategyUpdates,
  decisionStrategyUpdatesPaginationProps,
  onDecisionStrategyUpdatesSort,
  decisionStrategyUpdatesSortingType,
  strategyId,
}) => {
  const dispatch = useDispatch();
  const [changeSorting, getSortingType] = useSorting(
    decisionStrategyUpdatesSortingType.field,
    decisionStrategyUpdatesSortingType.ascending,
    onDecisionStrategyUpdatesSort,
  );

  useEffect(() => {
    dispatch(getDecisionStrategyUpdatesRequest(strategyId));
  }, [strategyId]);

  useStateReset(DecisionStrategyUpdatesActions.ResetState);

  const renderRow = (strategyUpdate: StrategyUpdateType) => (
    <UpdateLine strategyUpdate={strategyUpdate} key={strategyUpdate.updatedAt} />
  );

  const renderBody = () => {
    if (!decisionStrategyUpdates.length) {
      return (
        <NoItems
          title="No updates have been made yet!"
          titleClassName={styles.updatesNoDataTitle}
          className={styles.updatesNoData}
        />
      );
    }

    return (
      <>
        <div className={styles.strategyUpdatesContent__table}>
          <TableHead>
            <TableHeadCell
              ascending={getSortingType(DecisionStrategyUpdatesSortingField.UpdatedAt)}
              onClick={() => changeSorting(DecisionStrategyUpdatesSortingField.UpdatedAt)}
              width={SIZES.UPDATED}
            >
              Updated
            </TableHeadCell>
            <TableHeadCell
              ascending={getSortingType(DecisionStrategyUpdatesSortingField.UpdatedBy)}
              onClick={() => changeSorting(DecisionStrategyUpdatesSortingField.UpdatedBy)}
              width={SIZES.EDITOR}
            >
              Editor
            </TableHeadCell>
            <TableHeadCell
              ascending={getSortingType(DecisionStrategyUpdatesSortingField.UpdateType)}
              onClick={() => changeSorting(DecisionStrategyUpdatesSortingField.UpdateType)}
              width={SIZES.TYPE}
            >
              Update Type
            </TableHeadCell>
          </TableHead>
          <TableBody>
            <TableBodyContent
              rows={decisionStrategyUpdates}
              renderSkeletonTableRow={(index) => <SkeletonUpdateLine key={index} />}
              renderTableRow={renderRow}
            />
          </TableBody>
        </div>
        <Pagination {...decisionStrategyUpdatesPaginationProps} />
      </>
    );
  };

  return (
    <div className={styles.strategyUpdatesContent}>
      <h4 className={styles.strategyUpdatesContent__title}>Update History</h4>
      {renderBody()}
    </div>
  );
};

export default StrategyUpdates;
