import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { CloseButtonImage } from 'static/images';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ProductStatus, ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { createProduct } from 'LoanOriginationSystemProducts/ActionCreator';
import ProductBasicInfoForm from 'components/LoanOriginationSystem/ProductBasicInfoForm';
import CustomCheckbox from 'components/CustomCheckbox';
import QuestionIcon from 'components/QuestionIcon';
import Button from 'components/Button';
import styles from './CreateProduct.module.scss';

export interface BasicInfoFormData {
  productName: string;
  borrowerTypes: BorrowerType[];
  type: ProductType | null;
  status: ProductStatus;
}

export interface CreateProductProps {
  onClose: () => void;
}

const CreateProduct = ({ onClose }: CreateProductProps) => {
  const dispatch = useDispatch();

  const [basicInfoData, setBasicInfoData] = useState<BasicInfoFormData>({
    productName: '',
    borrowerTypes: [BorrowerType.Person],
    type: ProductType.PersonalLoan,
    status: ProductStatus.Active,
  });

  const { isProductCreatingInProgress } = useSelector((state: ReduxState) => state.loanOriginationSystemProducts);

  const isBasicInfoFormValid = () =>
    basicInfoData.productName.trim() && basicInfoData.borrowerTypes.length > 0 && basicInfoData.type;

  const handleProductNameChange = (productName: string) => setBasicInfoData({ ...basicInfoData, productName });
  const handleBorrowerTypesChange = (borrowerTypes: BorrowerType[]) => setBasicInfoData({ ...basicInfoData, borrowerTypes });
  const handleProductTypeChange = (type: ProductType | null) => setBasicInfoData({ ...basicInfoData, type });
  const handleInitialProductStatusChange = (status: ProductStatus) => setBasicInfoData({ ...basicInfoData, status });

  const handleProductCreate = () => {
    dispatch(
      createProduct(
        basicInfoData.productName.trim(),
        basicInfoData.borrowerTypes,
        basicInfoData.type!,
        basicInfoData.status,
      ),
    );
  };

  const renderTooltip = () => (
    <>
      <p>Your product must be Active to appear on the</p>
      <p>Applications page. Disabling this toggle sets the</p>
      <p>status to Draft. Products can be activated at any</p>
      <p>time.</p>
    </>
  );

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Create Loan Product</h2>
      <ProductBasicInfoForm
        productName={basicInfoData.productName}
        borrowerTypes={basicInfoData.borrowerTypes}
        productType={basicInfoData.type}
        onProductNameChange={handleProductNameChange}
        onBorrowerTypesChange={handleBorrowerTypesChange}
        onProductTypeChange={handleProductTypeChange}
      />
      <div className={styles.productStatusSection}>
        <p>Product Status</p>
        <div>
          <CustomCheckbox
            checked={basicInfoData.status === ProductStatus.Active}
            onChange={(event, checked) =>
              handleInitialProductStatusChange(checked ? ProductStatus.Active : ProductStatus.Draft)
            }
            containerClassName={styles.productStatusCheckboxContainer}
            className={styles.productStatusCheckbox}
            label="This product will be initially set to Active"
          />
          <QuestionIcon className={styles.questionIcon} size={20} tooltip={renderTooltip()} />
        </div>
      </div>
      <Button
        className={styles.createProductButton}
        onClick={handleProductCreate}
        kind="primary"
        size="form"
        isLoading={isProductCreatingInProgress}
        disabled={!isBasicInfoFormValid()}
      >
        Create Product
      </Button>
      <CloseButtonImage onClick={onClose} className={styles.closeImage} />
    </div>
  );
};

export default CreateProduct;
