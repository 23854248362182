import React from 'react';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { ApplicationDetailsCard } from 'api/LoanOriginationSystem/ApplicationDetailsCardsApi';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import {
  getApplicationFormPageLabel,
  getCoBorrowerPageIndex,
  isApplicationDetailsFormPage,
  isBorrowerApplicationFormPage,
  isCoBorrowerApplicationFormPage,
  isDocumentUploadFormPage,
  isIntermediaryApplicationFormPage,
} from 'ApplicationFormPage';
import { CreateBorrowerForm, CreateIntermediaryForm } from 'components/LoanOriginationSystem/CreateApplication/Forms';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import { StandardVariables } from 'Variables/VariablesTypes';
import ExistingBorrowerProfile from './Forms/ExistingBorrowerProfile';
import ExistingIntermediaryProfile from './Forms/ExistingIntermediaryProfile';
import ExistingCoBorrowerProfile from './Forms/ExistingCoBorrowerProfile';
import styles from './EditApplicationForm.module.scss';

interface EditApplicationFormProps {
  application: Application;
  product: Product;
  selectedTabId: string;
  selectedCoBorrower: Borrower | null;
  selectedIntermediary: Intermediary | null;
  borrowerSuggestions?: Borrower[];
  intermediarySuggestions?: Intermediary[];
  personBorrowerProfileCards: BorrowerProfileCard[] | null;
  companyBorrowerProfileCards: BorrowerProfileCard[] | null;
  intermediaryProfileCards: Card[] | null;
  applicationDetailsCards: ApplicationDetailsCard[] | null;
  standardVariables: StandardVariables | null;
  onLoadBorrowerSuggestions?: (filter: BorrowerSuggestionFilter) => void;
  onLoadIntermediarySuggestions?: (filter: IntermediarySuggestionFilter) => void;
  onUnlockBorrowerClick?: (borrower: Borrower) => void;
  addCoBorrowerFormData: FormLayoutData;
  addCoBorrowerSelectedBorrowerType: BorrowerType;
  addIntermediaryFormData: FormLayoutData;
  onAddCoBorrowerFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onAddIntermediaryFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onChangeAddCoBorrowerType: (type: BorrowerType) => void;
  onEditApplicationBorrowerVariables: (borrowerId: string, variables: FormLayoutData) => Promise<void>;
  onEditApplicationIntermediaryVariables: (variables: FormLayoutData) => Promise<void>;
  onEditApplicationDetailsVariables: (variables: FormLayoutData) => Promise<void>;
  onEditSelectedIntermediaryProfile: (data: FormLayoutData) => Promise<void>;
  onEditSelectedCoBorrowerProfile: (data: FormLayoutData) => Promise<void>;
  onCoBorrowerSelect: (coBorrower: Borrower | null) => void;
  onIntermediarySelect: (intermediary: Intermediary | null) => void;
  isAddCoBorrowerPage: boolean;
  isAddIntermediaryPage: boolean;
  productCalculations: ProductCalculation[] | null;
  renderActions: (submitDisabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const EditApplicationForm = ({
  application,
  product,
  selectedTabId,
  selectedCoBorrower,
  selectedIntermediary,
  borrowerSuggestions = [],
  intermediarySuggestions = [],
  personBorrowerProfileCards,
  companyBorrowerProfileCards,
  intermediaryProfileCards,
  standardVariables,
  onLoadBorrowerSuggestions,
  onUnlockBorrowerClick,
  applicationDetailsCards,
  addCoBorrowerFormData,
  addCoBorrowerSelectedBorrowerType,
  addIntermediaryFormData,
  onAddCoBorrowerFieldChange,
  onAddIntermediaryFieldChange,
  onChangeAddCoBorrowerType,
  onEditApplicationBorrowerVariables,
  onEditApplicationIntermediaryVariables,
  onEditApplicationDetailsVariables,
  isAddCoBorrowerPage,
  isAddIntermediaryPage,
  onEditSelectedCoBorrowerProfile,
  onEditSelectedIntermediaryProfile,
  onLoadIntermediarySuggestions,
  onCoBorrowerSelect,
  onIntermediarySelect,
  productCalculations,
  renderActions,
}: EditApplicationFormProps) => {
  const renderForm = () => {
    if (isBorrowerApplicationFormPage(selectedTabId)) {
      return (
        <ExistingBorrowerProfile
          application={application}
          personBorrowerProfileCards={personBorrowerProfileCards}
          companyBorrowerProfileCards={companyBorrowerProfileCards}
          onEditData={(data) => onEditApplicationBorrowerVariables(application.borrowerId, data)}
          renderActions={renderActions}
        />
      );
    }

    if (isCoBorrowerApplicationFormPage(selectedTabId)) {
      const coBorrowerPageIndex = getCoBorrowerPageIndex(selectedTabId);

      return isAddCoBorrowerPage ? (
        <CreateBorrowerForm
          borrowerFormData={addCoBorrowerFormData}
          onBorrowerSelect={onCoBorrowerSelect}
          selectedBorrowerType={addCoBorrowerSelectedBorrowerType}
          suggestions={borrowerSuggestions}
          selectedBorrower={selectedCoBorrower}
          personProfileCards={personBorrowerProfileCards}
          companyProfileCards={companyBorrowerProfileCards}
          standardVariables={standardVariables}
          onFieldChange={onAddCoBorrowerFieldChange}
          onChangeBorrowerType={onChangeAddCoBorrowerType}
          availableBorrowerTypes={product.borrowerTypes}
          onLoadSuggestions={onLoadBorrowerSuggestions}
          onEditProfile={onEditSelectedCoBorrowerProfile}
          onUnlockBorrowerClick={onUnlockBorrowerClick}
          renderActions={renderActions}
        />
      ) : (
        <ExistingCoBorrowerProfile
          application={application}
          personBorrowerProfileCards={personBorrowerProfileCards}
          companyBorrowerProfileCards={companyBorrowerProfileCards}
          coBorrowerIndex={coBorrowerPageIndex}
          onEditData={(data) => onEditApplicationBorrowerVariables(application.coborrowerIds[coBorrowerPageIndex], data)}
          renderActions={renderActions}
        />
      );
    }

    if (isIntermediaryApplicationFormPage(selectedTabId)) {
      return isAddIntermediaryPage ? (
        <CreateIntermediaryForm
          intermediaryFormData={addIntermediaryFormData}
          selectedIntermediary={selectedIntermediary}
          cards={intermediaryProfileCards}
          standardVariables={standardVariables}
          suggestions={intermediarySuggestions}
          onFieldChange={onAddIntermediaryFieldChange}
          onEditProfile={onEditSelectedIntermediaryProfile}
          onLoadSuggestions={onLoadIntermediarySuggestions}
          onIntermediarySelect={onIntermediarySelect}
          renderActions={renderActions}
        />
      ) : (
        <ExistingIntermediaryProfile
          application={application}
          intermediaryProfileCards={intermediaryProfileCards}
          onEditData={onEditApplicationIntermediaryVariables}
          renderActions={renderActions}
        />
      );
    }

    if (isApplicationDetailsFormPage(selectedTabId)) {
      return (
        <ExistingProfile
          data={application.variables}
          title="Application Details"
          cards={applicationDetailsCards}
          editButtonName="Edit Data"
          calculations={productCalculations}
          onEditProfileData={onEditApplicationDetailsVariables}
          hideCloseButton
          renderActions={renderActions}
        />
      );
    }

    if (isDocumentUploadFormPage(selectedTabId)) {
      return (
        <p className={styles.documentsUploadDescription}>
          All of the documents related to this Application can be uploaded, downloaded and viewed from the Documents tab.
        </p>
      );
    }

    return null;
  };

  const getStepTitle = () => {
    if (isAddCoBorrowerPage) {
      return 'Add Co-Borrower';
    }

    if (isAddIntermediaryPage) {
      return 'Add Intermediary';
    }

    return getApplicationFormPageLabel(selectedTabId);
  };

  return (
    <div>
      <h3 className={styles.stepTitle}>{getStepTitle()}</h3>
      {renderForm()}
    </div>
  );
};

export default EditApplicationForm;
