import React, { FC } from 'react';
import ChangeActivation from './ChangeActivation';
import { ApiActivationsPopUpType } from 'ApiActivation/Types';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import RequestTemplate from './RequestTemplate';
import { DropdownStrategy, StrategyGroup } from 'api/Types';

interface ApiIntegrationPopUpProps {
  closePopUp: () => void;
  type: ApiActivationsPopUpType;
  updateActivationList: (groupName: string, active: string | null, testing: string | null) => Promise<void>;
  decisionStrategies: DropdownStrategy[];
  activationInfo: StrategyGroup;
  downloadTemplate: (strategyId: string) => void;
}

const CHANGE_POPUP_TITLE = 'Change Activated API Versions';
const DOWNLOAD_POPUP_TITLE = 'API Request Template';
const POPUP_WARNING_TEXT =
  'Changing your active and testing strategies will result in an immediate change to the system. Before saving, please confirm that all information is correct and has been reviewed.';

const ApiIntegrationPopUp: FC<ApiIntegrationPopUpProps> = ({
  closePopUp,
  type,
  updateActivationList,
  decisionStrategies,
  activationInfo,
  downloadTemplate,
}) => {
  const popUpTitle = type === 'change' ? CHANGE_POPUP_TITLE : DOWNLOAD_POPUP_TITLE;

  return (
    <PopUpWizard
      step={0}
      title={popUpTitle}
      closePopUp={closePopUp}
      warningText={type === 'change' ? POPUP_WARNING_TEXT : ''}
    >
      {type === 'change' ? (
        <ChangeActivation
          strategies={decisionStrategies}
          activationInfo={activationInfo!}
          updateActivationList={updateActivationList}
        />
      ) : (
        <RequestTemplate decisionStrategies={decisionStrategies} downloadTemplate={downloadTemplate} />
      )}
    </PopUpWizard>
  );
};

export default ApiIntegrationPopUp;
