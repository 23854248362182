import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Card, NormalizedCard } from './Base/BaseCardsApi';
import { ApplicationVariableConfiguration } from './ApplicationVariableConfigurationsApi';

export interface ApplicationDetailsCard extends Card<ApplicationVariableConfiguration> {
  productId: string;
}

export interface ApplicationDetailsNormalizedCard extends NormalizedCard {
  productId: string;
}

export interface CreateApplicationDetailsCardParams {
  productId: string;
  position: number;
  row: number;
  name: string;
}

export interface UpdateApplicationDetailsCardParams {
  name?: string;
}

export interface ApplicationDetailsCardsApi {
  find(productId: string): Promise<ApplicationDetailsCard[]>;
  create(params: CreateApplicationDetailsCardParams): Promise<ApplicationDetailsCard>;
  update(id: string, params: UpdateApplicationDetailsCardParams): Promise<ApplicationDetailsCard>;
  delete(id: string): Promise<void>;
}

export default class ApplicationDetailsCardsRestApi extends LoanOriginationSystemApi<ApplicationDetailsCard>
  implements ApplicationDetailsCardsApi {
  protected resourceName = 'application-details-cards';

  public find(productId: string) {
    const params = new URLSearchParams();

    params.set('productId', productId);

    return this.getResources(params);
  }

  public create(params: CreateApplicationDetailsCardParams) {
    return this.createResource({
      card: params,
    });
  }

  public update(id: string, params: UpdateApplicationDetailsCardParams) {
    return this.updateResource(id, {
      card: params,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
