import React from 'react';
import { RelatedApplicationsTableColumnSizesType } from 'components/LoanOriginationSystem/RelatedApplicationsTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import styles from './SkeletonRelatedApplicationTableRow.module.scss';

interface SkeletonRelatedApplicationTableRowProps {
  columnSizes: RelatedApplicationsTableColumnSizesType;
}

const SkeletonRelatedApplicationTableRow = ({ columnSizes }: SkeletonRelatedApplicationTableRowProps) => (
  <TableRow disableHover>
    <TableBodyCell width={columnSizes.BorrowerFullName}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={columnSizes.Product}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={columnSizes.Id}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={columnSizes.ApplicationDate}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={columnSizes.Status}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={columnSizes.LoanAmount} className={styles.flexEndJustify}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonRelatedApplicationTableRow;
