import React, { useEffect } from 'react';
import DecisionResultOverview from 'components/DecisionResultOverview/DecisionResultOverview';
import { useHistory, useParams } from 'react-router';
import { makeLeftNavigation } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DecisionState, requestDecision } from 'Decisions/DecisionStore';
import DecisionResultOverviewSkeleton from 'components/DecisionResultOverview/DecisionResultOverviewSkeleton';
import useStateReset from 'hooks/useStateReset';
import DeleteDecisionPopUp from 'components/Decisions/PopUps/DeleteDecisionPopUp';
import { closeReprocessDecisionPopup, openReprocessDecisionPopup } from 'Decisions/RunDecisionStore';
import RunDecisionPopUpContainer from 'components/Decisions/PopUps/RunDecisionPopUpContainer';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import useQueryParam from 'hooks/useQueryParam';
import {
  closeDeleteDecisionPopUp,
  deleteDecision,
  DeleteDecisionState,
} from 'Decisions/DeleteDecisionStore';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { useDecisionResultApi } from 'providers/ApiServiceProvider';
import { ExtendedDecision } from 'api/DecisionEngine/DecisionApi';
import styles from 'components/DecisionResultOverview/DecisionResultOverview.module.scss';
import Button from 'components/Button';
import { SyncIcon } from 'static/images';
import RowActions from 'components/RowActions';
import { DecisionActionTypes } from 'Decisions/actionTypes';

interface DecisionOverviewParams {
  decisionId: string;
}

const leftNav = makeLeftNavigation(NavigationLinkId.Decisions, ApplicationSectionName.DecisionEngine);

const DecisionOverviewPage = () => {
  const history = useHistory();
  const { decisionId } = useParams<DecisionOverviewParams>();
  const resultId = useQueryParam('result-id');
  const dispatch = useDispatch();
  const decisionResultApi = useDecisionResultApi();

  useStateReset(DecisionActionTypes.ResetDecisionState);

  const {
    data,
    loading,
    reprocessDecisionPopupOpen,
    notFound,
  } = useSelector<ReduxState, DecisionState>((state) => state.decision);
  const { decisionIdToDelete } = useSelector<ReduxState, DeleteDecisionState>(
    (state) => state.deleteDecision,
  );

  useEffect(() => {
    dispatch(requestDecision({ decisionId, resultId }));
  }, [decisionId, resultId]);

  const handleDeleteDecision = async () => {
    await dispatch(deleteDecision(data!.id));
    history.push('/decisions');
  };

  const handleCloseDeleteDecisionPopUp = () => {
    dispatch(closeDeleteDecisionPopUp());
  };

  const handleOpenReprocessDecisionPopup = () => {
    dispatch(openReprocessDecisionPopup({
      strategyId: data!.strategyId,
      inputs: data!.inputs,
    }))
  }
  const handleDecisionRan = (createdDecisionId: string) => {
    dispatch(closeReprocessDecisionPopup());
    history.push(`/decisions/${createdDecisionId}`);
  }
  const handleCloseRunDecisionPopup = () => {
    dispatch(closeReprocessDecisionPopup());
  }

  const handleDownloadResult = async (id: string) => {
    const response = await decisionResultApi.download(id);
    downloadBlobFile(response);
  }

  const renderOverlay = () => {
    if (decisionIdToDelete) {
      return (
        <DeleteDecisionPopUp
          closePopUp={handleCloseDeleteDecisionPopUp}
          onDeleteDecision={handleDeleteDecision}
        />
      );
    }

    if (reprocessDecisionPopupOpen) {
      return (
        <RunDecisionPopUpContainer
          popUpType="single"
          onClose={handleCloseRunDecisionPopup}
          onDecisionRan={handleDecisionRan}
        />
      );
    }
    return null;
  };

  const getDecisionName = (decisionData: ExtendedDecision) => {
    if (decisionData.resultsInGroup > 1) {
      return `${decisionData.decisionName} (${decisionData.name})`
    }

    return decisionData.decisionName;
  }

  const renderHeader = (decisionData: ExtendedDecision) => (
    <div className={styles.caseHeader}>
      <div className={styles.caseHeader__title}>
        <h2>{getDecisionName(decisionData)}</h2>
      </div>
      <div className={styles.headerButtonContainer}>
        <Button
          kind="secondary"
          size="default"
          onClick={handleOpenReprocessDecisionPopup}
          disabled={!decisionData.strategyId}
          className={styles.reProcessDecisionButton}
        >
          <SyncIcon /> Re-Process
        </Button>
        <RowActions
          btnClassName={styles.actionsButton}
          alwaysVisible
          actions={[{
            title: 'Download Results',
            handler: () => handleDownloadResult(decisionData!.id),
            separatorRequired: true,
          }]}
        />
      </div>
    </div>
  );

  const renderContent = (decisionData: ExtendedDecision) => {
    return (
      <>
        {renderHeader(decisionData)}
        <DecisionResultOverview
          decisionData={decisionData}
        />
      </>
    );
  }

  return (
    <MainLayout leftNav={leftNav} overlay={renderOverlay()}>
      <PageWrapper>
        <PageContent noPadding className={styles.pageContent}>
          {data && renderContent(data)}
          {loading && <DecisionResultOverviewSkeleton />}
          {notFound && <ErrorPageInfo
              errorType={ErrorPageType.NotFound}
              errorTitle="The page was not found."
          />}
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default DecisionOverviewPage;
