import PopUp from 'components/PopUps/PopUp';
import React, { FC } from 'react';
import styles from './CompanyRegionSettingsConfirmDialog.module.scss';
import { CheckboxChecked, CheckboxUnchecked } from 'static/images';
import Button from 'components/Button';

const TITLE = 'Confirm Change';
const DESCRIPTION_TEXT = `You are updating the system's region settings. Would you also like to automatically update all variables in the system?`;
const CHECKBOXES_DESCRIPTIONS = [
  {
    name: 'phoneNumberFormat',
    description: 'Update all variables with the Data Type of Phone Number with this phone format.',
  },
  {
    name: 'currencyFormat',
    description: 'Update all variables with the Data Type of Monetary with this currency format.',
  },
  {
    name: 'dateFormat',
    description: 'Update all variables with the Data Type of Date with this date format.',
  },
  {
    name: 'idNumberType',
    description:
      'Update all variables with the Data Type of Identification Number with this identification number type.',
  },
];

interface RegionSettingsChangesConfirmDialogProps {
  isSavingInProgress: boolean;
  checkboxesState: { [key: string]: boolean };
  onSaveRegionSettings: () => Promise<unknown>;
  onChangeCheckboxesState: (name: string) => void;
  showedCheckboxes: string[];
  onClose: () => void;
}

const CompanyRegionSettingsConfirmDialog: FC<RegionSettingsChangesConfirmDialogProps> = ({
  isSavingInProgress,
  checkboxesState,
  onSaveRegionSettings,
  onChangeCheckboxesState,
  showedCheckboxes,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    await onSaveRegionSettings();
  };

  const renderCheckBoxes = () => {
    return showedCheckboxes.map((value) => {
      const isChecked = checkboxesState[value];
      const handleUpdateCheckboxesState = () => {
        onChangeCheckboxesState(value);
      };

      const { description } = CHECKBOXES_DESCRIPTIONS.find(({ name }) => name === value)!;
      return (
        <div className={styles.checkboxLine} key={value}>
          {isChecked ? (
            <CheckboxChecked onClick={handleUpdateCheckboxesState} />
          ) : (
            <CheckboxUnchecked onClick={handleUpdateCheckboxesState} />
          )}
          <p className={styles.text}>{description}</p>
        </div>
      );
    });
  };

  return (
    <PopUp title={TITLE} closePopUp={handleClose}>
      <div className={styles.container}>
        <div className={styles.description}>
          <p>{DESCRIPTION_TEXT}</p>
        </div>
        <div className={styles.checkboxes}>{renderCheckBoxes()}</div>
        <div className={styles.buttons}>
          <Button kind="primary" size="form" onClick={handleSave} isLoading={isSavingInProgress}>
            Confirm Update
          </Button>
          <Button kind="secondary" size="form" onClick={handleClose} disabled={isSavingInProgress}>
            No, Go Back
          </Button>
        </div>
      </div>
    </PopUp>
  );
};

export default CompanyRegionSettingsConfirmDialog;
