import RestrictPermissionsPage from 'pages/RestrictPermissions';
import React, { FC, ReactElement } from 'react';
import { ApplicationSectionName, ProductSection, ProductSectionType } from './Types';
import useProductSectionAccessPermissions from 'ProductSectionAccessPermissions/useProductSectionAccessPermissions';

interface RouteWithPermissionsProps {
  productSection: ProductSection;
  pageName: string;
  children: ReactElement;
}

const productSectionMenuEntries: Record<ProductSectionType, ApplicationSectionName> = {
  // LOS
  [ProductSection.LoanOriginationSystemApplications]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemBorrowers]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemIntermediaries]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemTasks]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemEmails]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemReporting]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemConfiguration]: ApplicationSectionName.LoanOriginationSystem,
  // DE
  [ProductSection.DecisionEngineStrategies]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineProcessing]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineDeployment]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineReporting]: ApplicationSectionName.DecisionEngine,
  // Company Settings
  [ProductSection.CompanySettingsGeneral]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsPlanAndBilling]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsTeam]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsVariables]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsBranding]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsDataExport]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsDevelopers]: ApplicationSectionName.CompanySettings,
};

const ProductSectionAccessCheck: FC<RouteWithPermissionsProps> = ({ productSection, pageName, children }) => {
  const isSectionAccessible = useProductSectionAccessPermissions(productSection);

  if (isSectionAccessible === undefined) {
    return null;
  }

  if (!isSectionAccessible) {
    const menuEntry = productSectionMenuEntries[productSection];

    return <RestrictPermissionsPage pageName={pageName} menuEntry={menuEntry} />;
  }

  return children;
};

export default ProductSectionAccessCheck;
