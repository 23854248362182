import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { DecisionResultType } from 'api/DecisionEngine/DecisionApi';
import { DecisionResultsSortingFieldType } from 'Decisions/DecisionResultsStore';
import { DecisionResultListItem } from 'api/DecisionEngine/DecisionResultApi';

export const DECISION_RESULTS_PER_PAGE_DEFAULT = 20;

export interface DecisionResultsPaginationParams {
  offset: number;
  count: number;
  decisionId: string;
  sortingType: DecisionResultsSortingFieldType;
  resultType?: DecisionResultType;
}

const decisionResultsPagination = Pagination<ReduxState, DecisionResultListItem, DecisionResultsPaginationParams>(
  'decisionResults',
  DECISION_RESULTS_PER_PAGE_DEFAULT,
);

export default decisionResultsPagination;
