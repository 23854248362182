import createWorkerHook, { ModuleMethods } from 'hooks/createWorkerHook';
import { Worker } from 'threads';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { ConditionalDisplayRule } from 'api/LoanOriginationSystem/Base/ConditionalDisplayRuleApi';

export interface WithConditionalDisplayRuleField {
  id: string;
  conditionalDisplayRule?: ConditionalDisplayRule | null;
}

export type DisplayFieldsAttributes = Record<string, boolean>;

interface ConditionalDisplayWorkerModule extends ModuleMethods {
  runConditionalDisplayLogic(
    fields: Array<WithConditionalDisplayRuleField>,
    data: Record<string, VariableValue>,
  ): Promise<DisplayFieldsAttributes>;
}

const useConditionalDisplayLogicWorker = createWorkerHook<ConditionalDisplayWorkerModule>(() => {
  return new Worker('workers/ConditionalDisplayLogicWorker');
});

export default useConditionalDisplayLogicWorker;
