import { Draft } from '@reduxjs/toolkit';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';

interface BaseVariableConfigurationState {
  configurationsById: Record<string, BaseVariableConfiguration>;
}

export const deleteVariableConfiguration = <State extends BaseVariableConfigurationState>(state: Draft<State>, id: string) => {
  const newConfigurationsById = { ...state.configurationsById };

  delete newConfigurationsById[id];

  state.configurationsById = newConfigurationsById;
};

export const updateVariableConfigurationStore = <State extends BaseVariableConfigurationState>(
  state: Draft<State>,
  newVariablesConfigurationsById: Record<string, BaseVariableConfiguration>,
) => {
  state.configurationsById = {
    ...state.configurationsById,
    ...newVariablesConfigurationsById,
  };
};
