import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { BeforeCapture, DropResult } from 'react-beautiful-dnd';
import { negate, isEqual } from 'lodash';
import { nanoid } from 'nanoid';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';
import { OwnerPermissionGroupName } from 'PermissionGroups/constants';
import getPosition from 'utils/getPosition';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import LinkButton from 'components/LinkButton';
import WrapperWithTooltip from 'components/Tooltip';
import DndList from 'components/DndList';
import { AddImage, CloseImage, DragImage } from 'static/images';

import styles from './EditPermissionGroupsContextualView.module.scss';

const EDIT_PERMISSIONS_DROPPABLE_ID = 'permissionGroups';

interface EditPermissionGroupsContextualViewProps {
  permissionGroups: PermissionGroup[];
  onUpdate: (updates: PermissionGroup[]) => Promise<void>;
  onClose: () => void;
}

const isOwner = ({ name }: PermissionGroup) => name === OwnerPermissionGroupName;

const getNewGroup = (position: number): PermissionGroup => ({
  name: '',
  id: nanoid(),
  position,
  hasTeamMembers: false,
});

const EditPermissionGroupsContextualView: FC<EditPermissionGroupsContextualViewProps> = ({
  permissionGroups: initialGroups,
  onUpdate,
  onClose,
}) => {
  const [updatedGroups, setUpdatedGroups] = useState(initialGroups.filter(negate(isOwner)));
  const ownerGroup = initialGroups.find(isOwner)!;

  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const getDraggableId = (itemId: string | number) => itemId as string;

  const onGroupUpdate = (id: string, updatedFields: Partial<PermissionGroup>) =>
    setUpdatedGroups((groups) => {
      const groupToUpdate = groups.find((group) => group.id === id)!;
      const groupToUpdateIndex = groups.findIndex((group) => group.id === id)!;

      return [
        ...groups.slice(0, groupToUpdateIndex),
        { ...groupToUpdate, ...updatedFields },
        ...groups.slice(groupToUpdateIndex + 1),
      ];
    });

  const onGroupNameChange = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    onGroupUpdate(id, { name: event.target.value });

  const onAddGroup = () => setUpdatedGroups((groups) => [...groups, getNewGroup(getPosition(groups, groups.length))]);

  const onBeforeCapture = (event: BeforeCapture) => setDraggingId(event.draggableId);
  const onDragEnd = () => setDraggingId(null);

  const onGroupsReorder = (reorderedGroups: PermissionGroup[], result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    setUpdatedGroups(reorderedGroups);

    const filteredGroups = [ownerGroup, ...updatedGroups].filter(
      (group) => group.id !== updatedGroups[source.index].id,
    );
    onGroupUpdate(updatedGroups[source.index].id, { position: getPosition(filteredGroups, destination.index + 1) });
  };

  const onGroupRemove = (id: string) => setUpdatedGroups((groups) => groups.filter((group) => group.id !== id));

  const onSaveChangesClick = async () => {
    setIsUpdatePending(true);
    await onUpdate(updatedGroups);
    setIsUpdatePending(false);
    onClose();
  };

  const arePermissionGroupsChanged = () =>
    !isEqual(initialGroups, [ownerGroup, ...updatedGroups]) && updatedGroups.every(({ name }) => !!name);

  const renderTooltip = (hasTeamMembers: boolean) => {
    if (!hasTeamMembers) {
      return null;
    }

    return (
      <>
        <p>You cannot delete a permissions group</p>
        <p>that contains team members</p>
      </>
    );
  };

  const renderRemoveButton = (group: PermissionGroup) => {
    if (isOwner(group)) {
      return null;
    }

    return (
      <WrapperWithTooltip tooltip={renderTooltip(group.hasTeamMembers)}>
        <div className={styles.editGroups__removeButtonWrapper}>
          <LinkButton
            className={styles.editGroups__removeButton}
            disabled={group.hasTeamMembers}
            onClick={() => onGroupRemove(group.id)}
          >
            Remove
          </LinkButton>
        </div>
      </WrapperWithTooltip>
    );
  };

  const renderGroup = (group: PermissionGroup, index: number) => (
    <div
      className={clsx(
        styles.editGroups__groupWrapper,
        (isOwner(group) || isUpdatePending) && styles.disabled,
        getDraggableId(group.id) === draggingId && styles.dragging,
      )}
    >
      {!isUpdatePending && <DragImage className={styles.editGroups__dragImage} />}

      <TextInput
        key={group.id}
        type="text"
        containerClassName={styles.editGroups__groupInputWrapper}
        labelTitle={`Permission Group ${index + 2}`}
        onChange={onGroupNameChange(group.id)}
        value={group.name}
        required={isOwner(group)}
        disabled={isOwner(group) || isUpdatePending}
        topRightElement={renderRemoveButton(group)}
      />
    </div>
  );

  const renderBody = () => {
    return (
      <>
        {renderGroup(ownerGroup, -1)}

        <DndList
          handleReorder={onGroupsReorder}
          handleBeforeCapture={onBeforeCapture}
          handleDragEnd={onDragEnd}
          items={updatedGroups}
          droppableId={EDIT_PERMISSIONS_DROPPABLE_ID}
          renderListItem={renderGroup}
          withPlaceholder
          renderInPortal
          getDraggableId={getDraggableId}
        />

        <div className={styles.editGroups__addNewButtonWrapper}>
          <LinkButton onClick={onAddGroup} className={styles.editGroups__addNewButton} disabled={isUpdatePending}>
            <AddImage /> Add Permission Group
          </LinkButton>
        </div>

        <Button
          kind="primary"
          size="form"
          onClick={onSaveChangesClick}
          disabled={!arePermissionGroupsChanged()}
          className={styles.editGroups__saveButton}
          isLoading={isUpdatePending}
        >
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <div className={styles.editGroups}>
      <div className={styles.editGroups__header}>
        <h2>Edit Permission Groups</h2>

        <CloseImage onClick={onClose} className={styles.editGroups__closeImage} />
      </div>

      <div className={styles.editGroups__content}>{renderBody()}</div>
    </div>
  );
};

export default EditPermissionGroupsContextualView;
