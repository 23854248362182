import React, { FC, useRef } from 'react';
import styles from './ApiActivation.module.scss';
import Button from 'components/Button';
import { ApiActivationsPopUpType } from 'ApiActivation/Types';
import SearchNotFound from 'components/SearchNotFound';
import ActivationTable from './Table';
import SearchInput from 'components/SearchInput';
import PageName from 'constants/PageName';
import { DownloadImage } from 'static/images';
import NoItems from 'components/NoItems';
import { useHistory } from 'react-router-dom';
import { StrategyGroup } from 'api/Types';
import { NullableItems } from 'pagination';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import SliderButton from 'components/StrategyGroups/SliderButton';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import debounce from 'lodash/debounce';
import { StrategyListParams } from 'api/DecisionEngineStrategiesType';
import { ApiActivationSortingFields, ApiActivationSortingType } from 'DecisionStrategy/DecisionStrategiesTypes';
import QuestionIcon from 'components/QuestionIcon';
import { validateLettersNumbersOnly } from 'input-validators';
import areItemsLoading from 'utils/areItemsLoading';

interface ApiActivationProps {
  groups: NullableItems<StrategyGroup>;
  onOpenApiActivationsPopUp: (popUpType: ApiActivationsPopUpType, activationInfo?: StrategyGroup) => void;
  onSearch: (search: string) => void;
  searchInputValue: string;
  updaters: UserInfo[];
  selectedUpdaters: UserInfo[];
  showArchived: boolean;
  setUpdaters: (users: UserInfo[]) => void;
  setShowArchived: (showArchived: boolean) => void;
  fetchListData: (params: StrategyListParams) => void;
  onSort: (field: ApiActivationSortingFields, ascending: boolean) => void;
  sortingType: ApiActivationSortingType;
}

const DEBOUNCE_TIME = 300;
const TOOLTIP_TEXT = 'Select the version of each Strategy for use in API requests.';

const ApiActivation: FC<ApiActivationProps> = ({
  groups,
  onOpenApiActivationsPopUp,
  onSearch,
  searchInputValue,
  updaters,
  selectedUpdaters,
  showArchived,
  setUpdaters,
  setShowArchived,
  fetchListData,
  onSort,
  sortingType,
}) => {
  const history = useHistory();
  const debouncedFetchListData = useRef(debounce(fetchListData, DEBOUNCE_TIME)).current;

  const handleGoToStrategiesButton = () => {
    history.push('/decision/strategies');
  };

  const handleSetShowArchived = (enabled: boolean) => {
    setShowArchived(enabled);
    fetchListData({ showArchived: enabled });
  };

  const onSelectedUpdatersChange = (selectedUpdatersList: UserInfo[]) => {
    setUpdaters(selectedUpdatersList);
    debouncedFetchListData({ selectedUpdaters: selectedUpdatersList });
  };

  const renderBody = () => {
    if (groups.length === 0 && !searchInputValue) {
      return (
        <NoItems
          title="No strategies have been created yet!"
          buttonMessage="Go To Strategies"
          onButtonClick={handleGoToStrategiesButton}
          className={styles.activationsNoData}
          titleClassName={styles.activationsNoDataTitle}
          footer
        />
      );
    }

    return (
      <>
        <div className={styles.filterContainer}>
          <SearchInput
            placeholder="Search"
            containerClassName={styles.searchContainer}
            value={searchInputValue}
            onChange={(e) => onSearch(e.target.value)}
            onClear={() => onSearch('')}
            inputValueValidator={validateLettersNumbersOnly}
            showLoader={!!searchInputValue && areItemsLoading(groups)}
          />
          <UserFilter
            users={updaters}
            selectedUsers={selectedUpdaters}
            onChange={onSelectedUpdatersChange}
            className={styles.usersContainer}
          />
          <SliderButton active={showArchived} onChange={handleSetShowArchived} labelText="Show Archived" />
        </div>

        {searchInputValue && groups.length === 0 ? (
          <SearchNotFound searchValue={searchInputValue} className={styles.notFound} footer />
        ) : (
          <ActivationTable
            strategyGroups={groups}
            searchInputValue={searchInputValue}
            onOpenApiActivationsPopUp={onOpenApiActivationsPopUp}
            onSort={onSort}
            sortingType={sortingType}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.PageHeaderTitleContainer}>
          <h2 className={styles.pageTitle}>{PageName.Deployment}</h2>
          <QuestionIcon tooltip={TOOLTIP_TEXT} size={24} tooltipPosition="bottom" />
        </div>
        <Button onClick={() => onOpenApiActivationsPopUp('download')} className={styles.downloadButton}>
          <DownloadImage className={styles.downloadImage} /> API Request Template
        </Button>
      </div>
      {renderBody()}
    </>
  );
};

export default ApiActivation;
