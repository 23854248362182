import React, { FC } from 'react';
import styles from 'components/DecisionResultOverview/Overview/DecisionResultInfo.module.scss';
import Table, { TableBody, TableBodyCell } from 'components/Table';
import TableRow from 'components/Table/TableRow';
import { Link } from 'react-router-dom';
import QuestionIcon from 'components/QuestionIcon';
import clsx from 'clsx';

interface DecisionInfoProps {
  tableData: DecisionResultTableRowConfig[][];
}

export interface DecisionResultTableRowConfig {
  name: string;
  value: string | null;
  link?: string | null;
  tooltip?: string;
}

const DecisionResultInfo: FC<DecisionInfoProps> = ({
  tableData,
}) => {
  const renderFieldValue = (field: DecisionResultTableRowConfig) => {
    if (field.link) {
      return <Link to={field.link} className={styles.link}>{field.value}</Link>;
    }

    return <p>{field.value || <span>&#8212;</span>}</p>
  };

  const renderRow = (config: DecisionResultTableRowConfig, isLastRow: boolean) => {
    return (
      <TableRow key={config.name} disableHover className={clsx(styles.decisionInfoRow, isLastRow && styles.lastRow)}>
        <TableBodyCell width={100} className={styles.noLeftPadding}>
          <div className={styles.columnViewItem}>
            <p className={styles.propertyName}>{config.name}</p>
            <div className={styles.propertyValue}>
              {renderFieldValue(config)}
              {config.tooltip && <QuestionIcon
                  tooltip={config.tooltip}
                  tooltipPosition="top"
                  className={styles.tooltip}
              />}
            </div>
          </div>
        </TableBodyCell>
      </TableRow>
    );
  }

  return (
    <div className={styles.decisionOverviewContainer}>
      <h5 className={styles.decisionTitle}>Decision Overview</h5>
      <div className={styles.decisionInfo}>
        {tableData.map((rowConfig) => {
          return(
            <Table key={rowConfig[0].name} className={styles.decisionInfoCell}>
              <TableBody>
                {rowConfig.map((config, index) =>
                  renderRow(config, (rowConfig.length - 1) === index))
                }
              </TableBody>
            </Table>
          );
        })}
      </div>
    </div>
  );
};

export default DecisionResultInfo;
