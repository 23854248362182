import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getIntermediaryProfileCards } from 'IntermediaryProfileCards/Thunks';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import ProfileConfigurationPage from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/pages/ProfileConfigurationPage';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';

const IntermediaryProfileConfigurationPage = () => {
  const dispatch = useDispatch();
  const intermediaryProfileCards = useSelector((state: ReduxState) => {
    return getIntermediaryProfileCardsSelector(state);
  });

  useEffect(() => {
    dispatch(getIntermediaryProfileCards());
  }, []);

  const handleOnGoToProfileClick = () => {
    window.open(`${window.location.origin}/los/configuration/intermediaries`);
  };

  return (
    <ProfileConfigurationPage
      cards={intermediaryProfileCards}
      title="Intermediary Profile"
      onGoToProfileClick={handleOnGoToProfileClick}
      profileName="Intermediary"
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
    />
  );
};

export default IntermediaryProfileConfigurationPage;

