import { DataFilter, DataViewSortingType, TableViewData } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';

export enum TaskStatus {
  Done = 'Done',
  NotDone = 'Not Done',
}

export interface TaskApplicationInfo {
  id: string;
  displayId: string;
  borrowerFullName: string;
}

export interface Task {
  id: string;
  description: string;
  application: TaskApplicationInfo | null;
  teamMembers: UserInfo[];
  dueDate: string;
  status: TaskStatus;
}

export type NewTask = Omit<Task, 'id'>;

export interface TaskDataFilter extends DataFilter {
  dueDateRange: {
    from: Date | null;
    to: Date | null;
  };
  status: TaskStatus | null;
  members: UserInfo[];
}

export enum TasksSortingFields {
  Description = 'description',
  ApplicationId = 'applicationId',
  BorrowerName = 'borrowerFullName',
  DueDate = 'dueDate',
}

export enum TasksFilterOptions {
  All = 'All',
  Done = 'Done',
  NotDone = 'Not Done',
}

export type TasksSortingType = DataViewSortingType<TasksSortingFields>;

export interface LoanOriginationSystemTasksApi {
  getTasks(
    filters: TaskDataFilter,
    sortingType: TasksSortingType,
    applicationId?: string,
  ): Promise<TableViewData<Task>>;
  deleteTask(taskIdToDelete: string): Promise<void>;
  createTask(newTaskData: NewTask): Promise<Task>;
  getTaskData(id: string): Promise<Task>;
  editTask(id: string, editTaskData: Partial<NewTask>): Promise<Task>;
}

export default class LoanOriginationSystemTasksRestApi extends LoanOriginationSystemApi<Task>
  implements LoanOriginationSystemTasksApi {
  protected resourceName = 'tasks';

  public getTasks(filters: TaskDataFilter, sortingType: TasksSortingType, applicationId?: string) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    filters.members.forEach((member) => params.append('teamMemberIds', member.id));

    if (applicationId) {
      params.append('applicationId', applicationId);
    }

    if (filters.status) {
      params.append('status', filters.status);
    }

    if (filters.dueDateRange.from) {
      params.append('dueDateFrom', formatDate(filters.dueDateRange.from, DateTimeFormat.ISOString));
    }

    if (filters.dueDateRange.to) {
      params.append('dueDateTo', formatDate(filters.dueDateRange.to, DateTimeFormat.ISOString));
    }

    return this.getResources<TableViewData<Task>>(params);
  }

  public deleteTask(id: string) {
    return this.deleteResource(id);
  }

  public createTask(task: NewTask) {
    const params = {
      status: task.status,
      applicationId: task.application?.id,
      teamMemberIds: task.teamMembers.map((member) => member.id),
      dueDate: task.dueDate,
      description: task.description,
    };

    return this.createResource({ task: params });
  }

  public getTaskData(id: string) {
    return this.getResourceById(id);
  }

  public editTask(id: string, task: NewTask) {
    const params = {
      status: task.status,
      applicationId: task.application === null ? null : task.application?.id,
      teamMemberIds: task.teamMembers?.map((member) => member.id),
      dueDate: task.dueDate,
      description: task.description,
    };

    return this.updateResource(id, { task: params });
  }
}
