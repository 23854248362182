import { StrategyGroup } from 'api/Types';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import React, { FC, useState } from 'react';
import Header from './Header';
import StrategiesTilesView from './StrategiesTilesView';
import StrategiesTableView from './StrategiesTableView';
import { NullableItems, PaginationProps } from 'pagination';
import Pagination from 'components/Pagination/Pagination';
import styles from './StrategyGroups.module.scss';
import Button from 'components/Button';
import { batch, useDispatch } from 'react-redux';
import { useQueryParams } from 'hooks/useQueryParam';
import { getStrategyGroupsData, resetFilters } from 'StrategyGroupsData/Actions';
import NoStrategyFilterResults from 'components/NoStrategyFilterResults';
import { useHistory } from 'react-router-dom';
import SearchNotFound from 'components/SearchNotFound';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { createStrategy } from 'Strategies/ActionCreator';
import PageName from 'constants/PageName';
import areItemsLoading from 'utils/areItemsLoading';
import ScrollableContainer from 'components/ScrollableContainer';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';

interface StrategyGroupsProps {
  groups: NullableItems<StrategyGroup>;
  paginationProps: PaginationProps;
  searchInputValue?: string;
}

const StrategyGroups: FC<StrategyGroupsProps> = ({ groups, paginationProps, searchInputValue }) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const history = useHistory();
  const [viewType, setViewType] = useState(ViewType.Tile);
  const [isCreateNewStrategyInLoading, setIsCreateNewStrategyInLoading] = useState(false);

  const handleCreateNewStrategy = async () => {
    setIsCreateNewStrategyInLoading(true);
    await dispatchRoutine(createStrategy());
    setIsCreateNewStrategyInLoading(false);
  };
  const handleResetFilters = () =>
    batch(() => {
      dispatch(resetFilters());
      dispatch(getStrategyGroupsData());
    });
  const queryParams = useQueryParams();
  const strategyName = queryParams.get('strategy-name');

  const handleViewVersions = (name: string) => {
    history.push(`/decision/strategies?strategy-name=${name}`);
  };

  const renderList = () => {
    if (!groups.length && !searchInputValue) {
      return <NoStrategyFilterResults onClick={handleResetFilters} />;
    }

    if (!groups.length && searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }

    return viewType === ViewType.Tile ? (
      <StrategiesTilesView groups={groups} selectedGroupName={strategyName} handleViewAllClick={handleViewVersions} />
    ) : (
      <StrategiesTableView groups={groups} handleRowClick={handleViewVersions} />
    );
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{PageName.DecisionStrategies}</h2>
          <Button
            kind="primary"
            size="default"
            onClick={handleCreateNewStrategy}
            isLoading={isCreateNewStrategyInLoading}
            className={styles.createStrategyButton}
          >
            New Strategy
          </Button>
        </div>
        <Header
          placeholderText="Search"
          viewType={viewType}
          onViewTypeChange={(derivedViewType: ViewType) => setViewType(derivedViewType)}
          areListItemsLoading={areItemsLoading(groups)}
        />
      </div>
      <ScrollableContainer>
        <TableWrapperWithFooter>
          <div className={styles.tableContainer}>
            {renderList()}
            <Pagination {...paginationProps} />
          </div>
        </TableWrapperWithFooter>
      </ScrollableContainer>
    </>
  );
};

export default StrategyGroups;
