import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { DecisionEngineApi } from 'api/Types';
import {
  CreateBranchAction,
  createBranchRequestFailure,
  createBranchRequestSuccess,
} from 'AddBranch/AddBranchActionCreator';
import { CREATE_BRANCH, CREATE_BRANCH_SUCCESS } from './ActionTypes';
import { getDecisionStrategyInfoRequest } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/ActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { batch } from 'react-redux';
import { BranchData } from 'AddBranch/Types';
import getMessage, { MessageType } from 'constants/messages';

const AddBranchMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BranchData, CreateBranchAction>(
    CREATE_BRANCH,
    createBranchRequestSuccess,
    createBranchRequestFailure,
    async ({ payload }) => {
      await api.createNewBranch(payload);
      return payload;
    },
  );

const isCreateBranchSuccessAction = (action: AnyAction): action is CreateBranchAction =>
  action.type === CREATE_BRANCH_SUCCESS;

export const onCreateBranchSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isCreateBranchSuccessAction(action)) {
    const { strategyId, moduleName } = action.payload;
    batch(() => {
      dispatch(getDecisionStrategyInfoRequest(strategyId));
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(
        getMessage(MessageType.BranchAdded, {
          moduleName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};

export default AddBranchMiddleware;
