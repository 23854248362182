import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './UserAvatarInlineList.module.scss';
import { TextSize } from 'components/Avatar';
import RestUsersPopupList from 'components/UserAvatarInlineList/RestUsersPopupList';
import RestUsersAvatar from 'components/UserAvatarInlineList/RestUsersAvatar';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import UserAvatar from 'components/UserAvatar';

interface UserAvatarInlineListProps {
  source: UserInfo[];
  className?: string;
  size?: TextSize;
  maxCount?: number;
  onSelect?: (user: UserInfo) => void;
  selectedUsers?: UserInfo[];
  avatarClassName?: string;
}

const UserAvatarInlineList = ({
  source,
  className,
  maxCount,
  size,
  selectedUsers = [],
  onSelect,
  avatarClassName,
}: UserAvatarInlineListProps) => {
  const [restUsersPopupOpen, setRestUsersPopupOpen] = useState(false);

  const usersCountToView = !maxCount || source.length === maxCount ? source.length : maxCount - 1;
  const usersToView = source.slice(0, usersCountToView);
  const restUsers = source.slice(usersCountToView);
  const avatarCounterRef = useRef<HTMLDivElement>(null);

  const isSelected = (userId: string) => selectedUsers.some(({ id }) => id === userId);

  /**
   * Need to open only users dropdown
   * and stop propagation of another unwanted parent event (redirection, modal opening and etc.)
   * @param event
   */
  const handleRestUsersAvatarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div className={clsx(styles.list, className)}>
      {usersToView.map((user) => (
        <UserAvatar
          {...user}
          size={size}
          tooltip
          active={isSelected(user.id)}
          className={clsx(styles.userAvatar, styles.listItem, avatarClassName)}
          onClick={() => onSelect?.(user)}
          key={user.id}
        />
      ))}
      {restUsers.length > 0 && (
        <div onClick={handleRestUsersAvatarClick} className={styles.listItem} >
          <RestUsersAvatar
            hasItemsSelected={restUsers.some(({ id }) => isSelected(id))}
            usersCount={restUsers.length}
            onClick={() => setRestUsersPopupOpen(true)}
            ref={avatarCounterRef}
            className={styles.userAvatar}
            size={size}
            isActive={restUsersPopupOpen}
          />
          <RestUsersPopupList
            open={restUsersPopupOpen}
            anchorEl={avatarCounterRef.current}
            users={restUsers}
            selectedUsers={selectedUsers}
            onUserSelect={onSelect}
            onClose={() => setRestUsersPopupOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export default UserAvatarInlineList;
