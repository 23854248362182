import { connect } from 'react-redux';
import ListHeader from 'components/ListHeader';
import {
  getVersionsListData,
  setShowArchived,
  setUpdaters,
  setVersionsListSearchInput,
} from 'VersionsViewData/Actions';
import { VersionsListDataState } from 'VersionsViewData/Reducer';
import { validateLettersNumbersOnly } from 'input-validators';

export type HeaderConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ versionsListData }: { versionsListData: VersionsListDataState }) => {
  return {
    updaters: versionsListData.updaters,
    selectedUpdaters: versionsListData.selectedUpdaters,
    showArchived: versionsListData.showArchived,
    searchInputValue: versionsListData.searchInputValue,
    searchInputValueValidator: validateLettersNumbersOnly,
  };
};

const mapDispatchToProps = {
  setUpdaters,
  setShowArchived,
  setSearchInputValue: setVersionsListSearchInput,
  fetchListData: getVersionsListData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListHeader);
