import React, { createRef, forwardRef, RefObject, useEffect, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import styles from 'components/SelectInput/SelectInput.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import DropdownItem from 'components/SelectInput/DropdownItem';
import { isNumber } from 'lodash';

interface DropdownContentProps {
  options: Option[];
  handleSelect: (option: Option) => void;
  autoscroll: boolean;
  focusedItemIndex: number;
  setFocusedItemIndex: (index: number) => void;
  selectedValue?: Option['value'] | Option['value'][];
  containerClassName?: string;
  itemIndexToFocus?: number;
  isLabelTag?: boolean;
}

const DropdownContent = forwardRef<HTMLDivElement, DropdownContentProps>(
  (
    {
      options,
      selectedValue,
      handleSelect,
      setFocusedItemIndex,
      containerClassName,
      autoscroll,
      itemIndexToFocus,
      focusedItemIndex,
      isLabelTag,
    },
    ref,
  ) => {
    const [itemRefs, setItemRefs] = useState<Array<RefObject<HTMLDivElement>>>([]);
    const optionsListClassName = clsx(styles.optionsList, containerClassName);

    useEffect(() => {
      setItemRefs((refs) => {
        const refsList = Array(options.length).fill(null);
        return refsList.map((_ref, refIndex) => refs[refIndex] || createRef());
      });
    }, [options]);

    useLayoutEffect(() => {
      if (autoscroll && isNumber(itemIndexToFocus) && itemRefs[itemIndexToFocus]) {
        const element = itemRefs[itemIndexToFocus]!;

        if (element.current) {
          element.current.scrollIntoView();
        }
      }
    }, [itemIndexToFocus, itemRefs]);

    return (
      <div className={optionsListClassName} ref={ref}>
        {options.map((option, index) => {
          const isSelected = Array.isArray(selectedValue)
            ? selectedValue.includes(option.value)
            : selectedValue === option.value;
          return (
            <DropdownItem
              ref={itemRefs[index]}
              key={option.value}
              option={option}
              index={index}
              handleSelect={handleSelect}
              setFocusedItemIndex={setFocusedItemIndex}
              selected={isSelected}
              focused={focusedItemIndex === index}
              isLabelTag={isLabelTag}
            />
          );
        })}
        {!options.length && <div className={styles.defaultOption}>No results found</div>}
      </div>
    );
  },
);

export default DropdownContent;
