import { LoanOriginationSystemEmailsState } from './Types';
import {
  CloseConnectEmailsPopupAction,
  CloseDeleteEmailPopupAction,
  DeleteEmailAction,
  DownloadEmailAttachmentsAction,
  DownloadEmailAttachmentsFailureAction,
  DownloadEmailAttachmentsSuccessAction,
  GetBorrowersAction,
  GetBorrowersSuccessAction,
  GetEmailsAction,
  GetEmailsSuccessAction,
  OpenConnectEmailsPopupAction,
  OpenDeleteEmailPopupAction,
  SortEmailsAction,
  RemoveDownloadedEmailAttachmentAction,
  SavedFiltersSwitch,
} from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import emailsPagination, {
  EMAILS_PER_PAGE_DEFAULT,
  getPath,
} from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';
import { AnyAction } from 'redux';
import { initialState as filtersInitialState, loanOriginationSystemEmailsFilterReducer } from './Filters/Reducer';
import { ChangePopupFiltersAction, ResetAllEmailsFilters } from './Filters/ActionCreator';
import { EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './Filters/ActionTypes';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';
import { setItemsTotal } from 'utils/reducers/setStateField';
import emailsFilterAndSortingSavers from './FilterAndSortingSaver';

type LoanOriginationSystemEmailsActions =
  | GetEmailsSuccessAction
  | GetEmailsAction
  | FailedAction<LoanOriginationSystemEmailsActionTypes.GetEmailsFailure>
  | OpenDeleteEmailPopupAction
  | CloseDeleteEmailPopupAction
  | DeleteEmailAction
  | SortEmailsAction
  | ResetAllEmailsFilters
  | ChangePopupFiltersAction
  | OpenConnectEmailsPopupAction
  | CloseConnectEmailsPopupAction
  | GetBorrowersAction
  | GetBorrowersSuccessAction
  | DownloadEmailAttachmentsAction
  | DownloadEmailAttachmentsSuccessAction
  | DownloadEmailAttachmentsFailureAction
  | RemoveDownloadedEmailAttachmentAction
  | SavedFiltersSwitch;

type LoanOriginationSystemEmailsType = (
  state: LoanOriginationSystemEmailsState,
  action: AnyAction,
) => LoanOriginationSystemEmailsState;

const getInitialState = (): LoanOriginationSystemEmailsState => ({
  items: [],
  page: 1,
  itemsPerPage: EMAILS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  searched: {},
  byBorrower: {},
  filters: { ...filtersInitialState, ...emailsFilterAndSortingSavers.getSavedFilters() },
  isLoading: false,
  emailIdToDelete: null,
  deleteEmailInProgress: false,
  sortingType: emailsFilterAndSortingSavers.getSavedSorting() || {
    field: EmailsSortingField.CreatedAt,
    ascending: false,
  },
  connectEmailsPopUpOpen: false,
  borrowersForFilter: [],
  getBorrowersInProgress: false,
  downloadableAttachments: {},
});

const loanOriginationSystemEmailsReducer = (
  state = getInitialState(),
  action: LoanOriginationSystemEmailsActions,
): LoanOriginationSystemEmailsState => {
  switch (action.type) {
    case LoanOriginationSystemEmailsActionTypes.GetEmails: {
      return {
        ...state,
        isLoading: true,
        itemsTotal: undefined,
      };
    }

    case LoanOriginationSystemEmailsActionTypes.GetEmailsSuccess: {
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: EMAILS_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || EMAILS_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
        isLoading: false,
      };
    }

    case LoanOriginationSystemEmailsActionTypes.GetEmailsFailure: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case LoanOriginationSystemEmailsActionTypes.SortEmails: {
      return {
        ...state,
        ...setItemsTotal(
          state,
          getPath({ selectedBorrower: state.filters.selectedBorrower, search: state.filters.searchInputValue }),
        ),
        sortingType: {
          field: action.payload.field,
          ascending: action.payload.ascending,
        },
      };
    }
    case LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        ...setItemsTotal(
          state,
          getPath({ selectedBorrower: state.filters.selectedBorrower, search: state.filters.searchInputValue }),
        ),
        filters: loanOriginationSystemEmailsFilterReducer(state.filters, action),
      };
    }
    case LoanOriginationSystemEmailsActionTypes.OpenDeleteEmailPopup: {
      return {
        ...state,
        emailIdToDelete: action.payload.emailId,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.CloseDeleteEmailPopup: {
      return {
        ...state,
        emailIdToDelete: null,
        deleteEmailInProgress: false,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.DeleteEmail: {
      return {
        ...state,
        deleteEmailInProgress: true,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.OpenConnectEmailsPopup: {
      return {
        ...state,
        connectEmailsPopUpOpen: true,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.CloseConnectEmailsPopup: {
      return {
        ...state,
        connectEmailsPopUpOpen: false,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilter: {
      return {
        ...state,
        getBorrowersInProgress: true,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilterSuccess: {
      const borrowers = action.payload;
      return {
        ...state,
        getBorrowersInProgress: false,
        borrowersForFilter: borrowers,
      };
    }
    case LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachments: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        downloadableAttachments: {
          ...state.downloadableAttachments,
          [attachmentId]: 'loading',
        },
      };
    }
    case LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsSuccess: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        downloadableAttachments: {
          ...state.downloadableAttachments,
          [attachmentId]: 'success',
        },
      };
    }
    case LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsFailure: {
      const { attachmentId } = action.payload;
      return {
        ...state,
        downloadableAttachments: {
          ...state.downloadableAttachments,
          [attachmentId]: 'failure',
        },
      };
    }
    case LoanOriginationSystemEmailsActionTypes.RemoveDownloadedAttachment: {
      const { attachmentDownloadingId } = action.payload;
      const updatedAttachmentList = state.downloadableAttachments;
      delete updatedAttachmentList[attachmentDownloadingId];

      return {
        ...state,
        downloadableAttachments: updatedAttachmentList,
      };
    }

    case LoanOriginationSystemEmailsActionTypes.SavedFiltersSwitch: {
      return {
        ...state,
        sortingType: emailsFilterAndSortingSavers.getSavedSorting() || state.sortingType,
        filters: {
          ...state.filters,
          ...emailsFilterAndSortingSavers.getSavedFilters(),
        },
      };
    }

    default:
      return {
        ...state,
        filters: loanOriginationSystemEmailsFilterReducer(state.filters, action),
      };
  }
};

export default emailsPagination.wrapReducer<LoanOriginationSystemEmailsState>(
  withStateReset(
    loanOriginationSystemEmailsReducer,
    LoanOriginationSystemEmailsActionTypes.ResetState,
    getInitialState,
  ) as LoanOriginationSystemEmailsType,
);
