import React from 'react';
import EditableInput, { EditableInputOwnProps } from 'components/EditableInput';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import { updateBranchNameRequest } from 'BranchInfo/ActionCreator';
import { useDispatch } from 'react-redux';
import trimAll from 'utils/trimAll';
import { ERROR_TEXT_CHANGE_MODULE } from 'components/StrategyOverview/DecisionProcess/ModuleList/Module/Module';

interface BranchNameInputProps extends EditableInputOwnProps {
  branchName: string;
  moduleId: string;
  branchIndex: number;
  strategyId: string;
  isStrategyLocked?: boolean;
}

const BranchNameInput = ({
  branchName,
  moduleId,
  branchIndex,
  strategyId,
  isStrategyLocked,
  className,
}: BranchNameInputProps) => {
  const dispatch = useDispatch();

  const updateBranchName = (nameToSet: string, moduleKey: string, index: number) => {
    dispatch(
      updateBranchNameRequest({
        branchName: nameToSet,
        strategyId,
        moduleKey,
        branchIndex: index,
      }),
    );
  };

  const branchNameInputProps = useAsyncValueInputProps({
    value: branchName,
    handleChange: (visibleBranchName) => {
      const correctBranchName = trimAll(visibleBranchName);
      updateBranchName(correctBranchName, moduleId, branchIndex);
    },
    placeholderText: 'Rule Set',
    notEditable: isStrategyLocked,
    notEditableErrorNotificationText: ERROR_TEXT_CHANGE_MODULE,
  });

  return <EditableInput {...branchNameInputProps} className={className} />;
};

export default BranchNameInput;
