import useOnce from 'hooks/useOnce';
import { getPermissionGroups } from 'PermissionGroups/Actions';
import { getAllGroups } from 'PermissionGroups/Selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const usePermissionGroups = () => {
  const dispatch = useDispatch();

  const permissionGroups = useSelector((state: ReduxState) => getAllGroups(state));

  const loadPermissionGroups = useOnce(() => {
    dispatch(getPermissionGroups());
  });

  useEffect(() => {
    if (permissionGroups.length === 0) {
      loadPermissionGroups();
    }
  }, [permissionGroups]);

  return permissionGroups;
};

export default usePermissionGroups;
