import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './AndSeparator.module.scss';

export interface AndSeparatorProps {
  className?: string;
}

const AndSeparator: FC<AndSeparatorProps> = ({ className }) => (
  <div className={clsx(styles.andSeparator, className)}>and</div>
);

export default AndSeparator;
