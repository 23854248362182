import React, { FC } from 'react';
import styles from './UserCard.module.scss';
import UserAvatar from 'components/UserAvatar';

type UserCardTypes = {
  firstName: string;
  lastName: string;
  imageId?: string;
  companyName: string;
  setActiveLinkTitle: (title: string) => void;
  isActive: boolean;
};

const UserCard: FC<UserCardTypes> = ({ firstName, lastName, imageId, companyName, setActiveLinkTitle, isActive }) => {
  const userInfo = { firstName, lastName, imageId };

  const onSetActiveLinkTitle = () => {
    setActiveLinkTitle(`/account/profile`);
  };
  return (
    <div
      className={isActive ? styles.activeUserCardContainer : styles.userCardContainer}
      onClick={onSetActiveLinkTitle}
    >
      <div className={styles.imgContainer}>
        <UserAvatar size="medium" {...userInfo} />
        {isActive && <div className={styles.activeCircle}> </div>}
      </div>
      <div className={styles.userInfo}>
        <p className={isActive ? styles.activeUserName : styles.userName}>{`${firstName} ${lastName}`}</p>
        <p className={styles.companyName}>{companyName}</p>
      </div>
    </div>
  );
};

export default UserCard;
