import React, { useState } from 'react';
import clsx from 'clsx';
import {
  ApplicationStatus,
  PermissionsField,
  ApplicationStatusPermissions,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { PermissionGroupCheckboxMultiSelectOption, PermissionGroupId } from 'PermissionGroups/Types';
import { DragImage } from 'static/images';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import CheckboxMultiSelectList from 'components/CheckboxMultiSelectList';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import EditableInput from 'components/EditableInput';
import LoadingMask from 'components/LoadingMask';
import SkeletonCard from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody/SkeletonCard';
import StatusRules from './StatusRules';
import trimAll from 'utils/trimAll';
import ApplicationStatusCardExpandSection from './ApplicationStatusCardExpandSection/ApplicationStatusCardExpandSection';
import QuestionIcon from 'components/QuestionIcon';
import { validateLettersNumbersWithSpecialCharacters } from 'input-validators';
import styles from './ApplicationStatusCard.module.scss';

export interface ApplicationStatusCardProps {
  status: ApplicationStatus;
  permissionGroups: PermissionGroupCheckboxMultiSelectOption[];
  onPermissionsChange: (statusId: string, permissionsField: PermissionsField, permissions: ApplicationStatusPermissions) => void;
  onStatusRuleAdd: (status: ApplicationStatus) => void;
  onStatusRuleEdit: (statusRule: ApplicationStatusRule) => void;
  onStatusRuleDelete: (status: ApplicationStatus, rule: ApplicationStatusRule) => void;
  actions?: React.ReactNode;
  className?: string;
  statusIndex?: number;
  displayDragImage?: boolean;
  displayRowActions?: boolean;
  displayApplicationCards?: boolean;
  isStatusNameEditable?: boolean;
  onStatusNameUpdate?: (statusId: string, newStatusName: string) => Promise<void>;
  onDelete?: (status: ApplicationStatus) => void;
  isUpdating?: boolean;
  isDragging?: boolean;
  disabled?: boolean;
  statusRulesSectionDisabled?: boolean;
  addStatusRuleButtonTooltip?: React.ReactNode;
}

export const APPLICATION_CARDS_COUNT = 3;
const STATUS_NAME_MAX_LENGTH = 40;

const ApplicationStatusCard = ({
  status,
  permissionGroups,
  disabled,
  isDragging,
  isUpdating,
  onPermissionsChange,
  onStatusRuleAdd,
  onStatusRuleEdit,
  onStatusRuleDelete,
  actions,
  onDelete,
  className,
  displayApplicationCards,
  statusIndex,
  isStatusNameEditable,
  onStatusNameUpdate,
  displayRowActions,
  displayDragImage,
  statusRulesSectionDisabled,
  addStatusRuleButtonTooltip,
}: ApplicationStatusCardProps) => {
  const statusNameProps = useAsyncValueInputProps({
    value: status.name,
    placeholderText: status.name,
    handleChange: async (newStatusName) => {
      const correctedStatusName = trimAll(newStatusName);

      try {
        await onStatusNameUpdate?.(status.id, correctedStatusName);
      } catch (err) {
        statusNameProps.onChange(status.name);
      }
    },
  });
  const [roleBasedPermissionsExpanded, setRoleBasedPermissionsExpanded] = useState(true);
  const [statusRulesExpanded, setStatusRulesExpanded] = useState(true);

  const handleStatusRuleAdd = () => {
    onStatusRuleAdd(status);
  };

  const handleStatusRuleDelete = (rule: ApplicationStatusRule) => {
    onStatusRuleDelete(status, rule);
  };

  const handlePermissionsChange = (permissionsField: PermissionsField) => (permissionGroupId: PermissionGroupId) => {
    const currentValue = status[permissionsField][permissionGroupId];
    const nextValue = typeof currentValue === 'undefined' ? false : !currentValue;

    onPermissionsChange(status.id, permissionsField, { ...status[permissionsField], [permissionGroupId]: nextValue });
  };

  const renderStatusIndex = () => {
    if (!statusIndex) {
      return null;
    }

    return <p className={styles.statusIndex}>Status {statusIndex}</p>;
  };

  const renderRowActions = () => (
    <RowActions
      className={styles.rowActions}
      actions={[
        {
          title: 'Delete Status',
          danger: true,
          handler: () => onDelete?.(status),
        },
      ]}
    />
  );

  const renderApplicationCards = () => (
    <div className={styles.applicationCards}>
      {new Array(APPLICATION_CARDS_COUNT).fill(null).map((item, index) => (
        <SkeletonCard
          stopAnimation
          key={index}
          avatarsListClassname={styles.skeletonAvatarsList}
          primaryColor="primary20"
        />
      ))}
    </div>
  );

  const renderPermissionsCheckboxGroup = (
    permissionsField: PermissionsField,
    title: string,
    tooltip: React.ReactNode,
  ) => (
    <div className={styles.permissionsSection}>
      <div className={styles.permissionSectionHeader}>
        <h6>{title}</h6>
        <QuestionIcon className={styles.permissionsSectionTooltip} tooltip={tooltip} tooltipPosition="top" />
      </div>
      <CheckboxMultiSelectList
        isDisabled={() => !!disabled}
        className={styles.checkboxMultiSelectList}
        listItemCheckboxClassName={styles.checkboxItem}
        items={permissionGroups}
        selectedItemsIds={permissionGroups.map(({ id }) => id).filter((id) => status[permissionsField][id] ?? true)}
        onChange={handlePermissionsChange(permissionsField)}
      />
    </div>
  );

  const renderStatusOptions = () => (
    <div className={styles.statusOptions}>
      <ApplicationStatusCardExpandSection
        title="Role-Based Permissions"
        expanded={roleBasedPermissionsExpanded}
        onExpandClick={() => setRoleBasedPermissionsExpanded(!roleBasedPermissionsExpanded)}
        animateOnMount={false}
      >
        {renderPermissionsCheckboxGroup(
          'permissionGroupsToMoveApplicationIntoStatus',
          'Move Applications To This Status',
          <>
            <p>Team members with the selected roles can</p>
            <p>move applications to this status.</p>
          </>,
        )}
        {renderPermissionsCheckboxGroup(
          'permissionGroupsToEditApplication',
          'Edit Applications In This Status',
          <>
            <p>Team members with the selected roles can</p>
            <p> edit the data, documents and status of </p>
            <p> applications in this status.</p>
          </>,
        )}
        {renderPermissionsCheckboxGroup(
          'permissionGroupsAbleToViewApplicationOnBoard',
          'Show Status On Applications Board',
          <>
            <p>Team members with the selected roles will see</p>
            <p>this status on the applications board.</p>
          </>,
        )}
      </ApplicationStatusCardExpandSection>
      <ApplicationStatusCardExpandSection
        title="Status Rules"
        expanded={statusRulesExpanded}
        onExpandClick={() => setStatusRulesExpanded(!statusRulesExpanded)}
        tooltip="The following rules must pass for an application to be moved to this status"
        transitionContainerClassName={styles.statusRuleTransitionContainer}
        animateOnMount={false}
      >
        <StatusRules
          isAddRuleDisabled={disabled || statusRulesSectionDisabled}
          rules={status.rules}
          onStatusRuleEdit={onStatusRuleEdit}
          onStatusRuleDelete={handleStatusRuleDelete}
          onStatusRuleAdd={handleStatusRuleAdd}
          addRuleButtonTooltip={addStatusRuleButtonTooltip}
        />
      </ApplicationStatusCardExpandSection>
    </div>
  );

  return (
    <RowActionsContainer>
      <div className={clsx(styles.card, { [styles.dragging]: isDragging }, className)}>
        {renderStatusIndex()}
        <div className={styles.cardHeader}>
          {displayDragImage && (
            <div className={styles.dragImage}>
              <DragImage />
            </div>
          )}
          <EditableInput
            {...statusNameProps}
            className={styles.statusName}
            maxLength={STATUS_NAME_MAX_LENGTH}
            disabled={!isStatusNameEditable}
            valueValidator={validateLettersNumbersWithSpecialCharacters}
          />
          {actions}
          {!disabled && displayRowActions && renderRowActions()}
        </div>
        {renderStatusOptions()}
        {displayApplicationCards && renderApplicationCards()}
        {isUpdating && <LoadingMask background="light" />}
      </div>
    </RowActionsContainer>
  );
};

export default ApplicationStatusCard;
