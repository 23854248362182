import React, { FC } from 'react';
import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import {
  WebhookEventColumnSize,
  WebhookEventWithEndpointColumnSize,
} from 'components/Webhooks/WebhookEventsTable/TableColumnSize';

export interface SkeletonWebhookEventItemRowProps {
  withEndpointColumn?: boolean;
}

const SkeletonWebhookEventItemRow: FC<SkeletonWebhookEventItemRowProps> = ({ withEndpointColumn }) => {
  const ColumnSize = withEndpointColumn ? WebhookEventWithEndpointColumnSize : WebhookEventColumnSize;

  return (
    <TableRow disableHover useActions>
      {withEndpointColumn && (
        <TableBodyCell width={WebhookEventWithEndpointColumnSize.Endpoint}>
          <SkeletonRectangle width="80%" color="primary6" height="16px" />
        </TableBodyCell>
      )}
      <TableBodyCell width={ColumnSize.EventType}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.EventDate}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.Status}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.LastRunAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={ColumnSize.NextRunAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonWebhookEventItemRow;
