import { VariableValue } from 'api/LoanOriginationSystem/Types';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatValueByDataType from 'utils/formatValueByDataType';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { SimplifiedVariable, StandardVariables } from 'Variables/VariablesTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';

export const getFormattedLoanAmountCurrency = (standardVariables: StandardVariables | null) => {
  if (!standardVariables) {
    return null;
  }

  const loanAmountVariable = standardVariables[ApplicationDefaultVariable.LoanAmount];

  if (!loanAmountVariable) {
    return null;
  }

  return loanAmountVariable.currency && getCurrencySymbol(loanAmountVariable.currency);
};

export const getIdNumberVariableNameByType = (
  type: BorrowerType,
  borrowerDefaultVariable = BorrowerDefaultVariable,
) => {
  return type === BorrowerType.Person
    ? borrowerDefaultVariable.PersonalIdNumber
    : borrowerDefaultVariable.CompanyIdNumber;
};

export const getFormattedBorrowerIdNumber = (idNumber: VariableValue, standardVariable: SimplifiedVariable | null) => {
  if (!standardVariable) {
    return idNumber;
  }

  return formatValueByDataType(idNumber, standardVariable);
};

export const getFormattedBorrowerPhoneNumber = (
  phoneNumber: VariableValue,
  userPermissionGroupId: PermissionGroupId,
  standardVariables: StandardVariables | null,
  borrowerDefaultVariables = BorrowerDefaultVariable,
) => {
  if (!standardVariables) {
    return phoneNumber;
  }

  const variable = standardVariables[borrowerDefaultVariables.PhoneNumber];
  const accessPermissions = variable.permissions[userPermissionGroupId];

  return formatValueByDataType(phoneNumber, variable, { accessPermissions });
};

export const getFormattedBorrowerDateOfBirth = (
  dateOfBirth: VariableValue,
  standardVariables: StandardVariables | null,
  borrowerDefaultVariables = BorrowerDefaultVariable,
) => {
  if (!standardVariables) {
    return formatDate(dateOfBirth as string, DateTimeFormat.ShortDate);
  }

  return formatValueByDataType(dateOfBirth, standardVariables[borrowerDefaultVariables.DateOfBirth]);
};

export const getFormattedIntermediaryPhoneNumber = (
  phoneNumber: VariableValue,
  standardVariables: StandardVariables | null,
) => {
  if (!standardVariables) {
    return phoneNumber;
  }

  return formatValueByDataType(phoneNumber, standardVariables[IntermediaryDefaultVariable.PhoneNumber]);
};

export const getFormattedIntermediaryIdNumber = (
  variables: Record<string, VariableValue>,
  standardVariables: StandardVariables | null,
) => {
  const idNumber = variables[IntermediaryDefaultVariable.IdNumber] as string;

  if (!standardVariables) {
    return idNumber;
  }

  return formatValueByDataType(idNumber, standardVariables[IntermediaryDefaultVariable.IdNumber]);
};
