import React, { useEffect, useState, useCallback } from 'react';
import { CloseButtonImage } from 'static/images';
import { useDispatch, useSelector } from 'react-redux';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import {
  updateIntermediary,
  updateIntermediaryApplications,
} from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { ReduxState } from 'types/redux';
import { TabSwitchOption } from 'components/TabSwitch';
import EditIntermediary from './Tabs/EditIntermediary';
import IntermediaryApplications from './Tabs/IntermediaryApplications';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { getIntermediaries, setIntermediaryToDelete } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import {
  getIntermediaryData,
  resetIntermediaryDetails,
  selectIntermediaryViewTab,
} from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';
import { RowAction } from 'components/RowActions/RowActions';
import ProfileHeader from 'components/LoanOriginationSystem/ProfileHeader';
import UpdateExistingApplicationsPopup from 'components/LoanOriginationSystem/UpdateExistingApplicationsPopup';
import { getConfigurableFormDataDifference, isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import { getMailtoURI } from 'Email/utils';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useBlockingRequest from 'hooks/useBlockingRequest';
import useConfirmChanges from 'hooks/useConfirmChanges';
import { getOrganizationEmail } from 'utils/emailGetters';
import ProductSectionAccessRestricted from 'ProductSectionAccessRestricted/ProductSectionAccessRestricted';
import { ProductSection } from 'ProductSectionAccessPermissions/Types';
import { useSimplifiedApplicationsApi } from 'providers/ApiServiceProvider';
import styles from './IntermediaryDetails.module.scss';

interface IntermediaryDetailsProps {
  id: string;
  onClose: () => void;
}

interface UpdateExistingApplicationsData {
  intermediaryId: string;
  applicationsCount: number;
  data: FormLayoutData;
}

export enum IntermediaryDetailsTab {
  Profile = 'Profile',
  Applications = 'Applications',
}

const TABS: TabSwitchOption[] = [
  { label: IntermediaryDetailsTab.Profile, id: IntermediaryDetailsTab.Profile },
  { label: IntermediaryDetailsTab.Applications, id: IntermediaryDetailsTab.Applications },
];

const IntermediaryDetails = ({ onClose, id }: IntermediaryDetailsProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const simplifiedApplicationsApi = useSimplifiedApplicationsApi();

  const { intermediary, contextualViewTab } = useSelector((state: ReduxState) => state.loanOriginationSystemIntermediaryDetails);
  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemIntermediariesState);

  const [isIntermediaryFormInEditMode, setIsIntermediaryFormEditMode] = useState(false);
  const [intermediaryData, setIntermediaryData] = useState({});

  const [isIntermediaryUpdateInProgress, useBlockingIntermediaryUpdateCallback] = useBlockingRequest();
  const [isUpdateIntermediaryApplicationsInProgress, useBlockingUpdateIntermediaryApplicationsCallback] = useBlockingRequest();

  const [
    displayConfirmUpdateExistingApplications,
    resetConfirmUpdateExistingApplications,
    onConfirmUpdateExistingApplications,
    useConfirmUpdateExistingApplicationsCallback,
    updateExistingIntermediaryApplicationsData,
  ] = useConfirmChanges<UpdateExistingApplicationsData>();

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => {
    return !!intermediary?.variables && isConfigurableFormDataChanged(intermediary.variables, intermediaryData);
  });

  const organizationInfo = useOrganizationDetailedInfo();
  const organizationEmail = getOrganizationEmail(organizationInfo.id);

  const getIntermediaryApplicationsCount = async () => {
    try {
      const { total } = await simplifiedApplicationsApi.getIntermediaryApplications(id, { count: 1, offset: 0, onlyInProgress: true });

      return total;
    } catch (err) {
      return 0;
    }
  }

  useEffect(() => {
    if (!intermediary) {
      dispatch(getIntermediaryData(id));

      return;
    }

    setIntermediaryData(intermediary.variables);
  }, [intermediary]);

  useEffect(() => {
    return () => {
      dispatch(resetIntermediaryDetails());
    };
  }, []);

  const resetIntermediaryForm = () => {
    if (intermediary) {
      setIntermediaryData(intermediary.variables);
    }
  };

  const handleIntermediaryUpdate = useBlockingIntermediaryUpdateCallback(async () => {
    if (!intermediary) {
      return;
    }

    const data = getConfigurableFormDataDifference(intermediary.variables, intermediaryData);

    const [intermediariesApplicationsCount] = await Promise.all([
      getIntermediaryApplicationsCount(),
      dispatchRoutine(updateIntermediary(data, intermediary.id))
    ]);

    if (intermediariesApplicationsCount) {
      updateExistingApplications({
        intermediaryId: intermediary.id,
        applicationsCount: intermediariesApplicationsCount,
        data,
      });
    }

    setIsIntermediaryFormEditMode(false);

    dispatch(getIntermediaries(filters, sortingType));
  });

  const handleSwitchIntermediaryFormEditMode = useCallbackWithUnsavedChanges(() => {
    resetIntermediaryForm();
    setIsIntermediaryFormEditMode(!isIntermediaryFormInEditMode);
  });

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: intermediary!.variables[IntermediaryDefaultVariable.Email]
        ? intermediary!.variables[IntermediaryDefaultVariable.Email]!.toString()
        : '',
      cc: organizationEmail,
    });
  };

  const handlePhoneCall = () => {
    window.location.href = `tel:${intermediary!.variables[IntermediaryDefaultVariable.PhoneNumber]}`;
  };

  const handleSelectTab = ({ id: optionId }: TabSwitchOption) => {
    dispatch(selectIntermediaryViewTab(optionId as IntermediaryDetailsTab));
  };

  const handleClose = useCallbackWithUnsavedChanges(() => {
    onClose();
  });

  const updateExistingApplications = useConfirmUpdateExistingApplicationsCallback(
    useBlockingUpdateIntermediaryApplicationsCallback(async (updateExistingApplicationsData: UpdateExistingApplicationsData) => {
      await dispatchRoutine(updateIntermediaryApplications(
        updateExistingApplicationsData.intermediaryId,
        updateExistingApplicationsData.data,
      ));

      resetConfirmUpdateExistingApplications();
    }),
    (data) => data,
  );

  const handleIntermediaryDelete = () => {
    if (!intermediary) {
      return;
    }

    dispatch(setIntermediaryToDelete(intermediary));
  };

  const handleIntermediaryDataFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    setIntermediaryData((previousIntermediaryData) => ({
      ...previousIntermediaryData,
      [variableConfiguration.variable.systemName]: value,
    }));
  }, []);

  const renderHeader = () => {
    const profileData = intermediary ? {
      name: intermediary.variables[IntermediaryDefaultVariable.Name] as string,
      email: intermediary.variables[IntermediaryDefaultVariable.Email] as string,
      phone: intermediary.variables[IntermediaryDefaultVariable.PhoneNumber] as string,
      updatedBy: intermediary.updatedBy,
      updatedAt: intermediary.updatedAt,
    } : null;

    const actions: RowAction[] = intermediary ? [{
      title: 'Delete Intermediary',
      danger: true,
      handler: handleIntermediaryDelete,
    }] : [];

    return (
      <ProfileHeader
        profileData={profileData}
        onPhoneCall={handlePhoneCall}
        onSendEmail={handleSendEmail}
        actions={actions}
        tabs={TABS}
        selectedTabId={contextualViewTab}
        onSelectTab={handleSelectTab}
      />
    );
  };

  const renderTabs = () => {
    if (contextualViewTab === IntermediaryDetailsTab.Profile) {
      return (
        <EditIntermediary
          className={styles.editIntermediaryForm}
          intermediaryFormData={intermediaryData}
          onFieldChange={handleIntermediaryDataFieldChange}
          onIntermediaryUpdate={handleIntermediaryUpdate}
          isUpdatingInProgress={isIntermediaryUpdateInProgress}
          intermediary={intermediary}
          isEditMode={isIntermediaryFormInEditMode}
          onSwitchEditMode={handleSwitchIntermediaryFormEditMode}
        />
      );
    }

    if (contextualViewTab === IntermediaryDetailsTab.Applications) {
      return (
        <ProductSectionAccessRestricted productSection={ProductSection.LoanOriginationSystemApplications}>
          <IntermediaryApplications intermediaryId={id} />
        </ProductSectionAccessRestricted>
      );
    }

    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            {renderHeader()}
            <CloseButtonImage className={styles.closeButton} onClick={handleClose} />
          </div>
          <div className={styles.tabsInner}>
            {renderTabs()}
          </div>
        </div>
      </div>
      {displayConfirmUpdateExistingApplications && <UpdateExistingApplicationsPopup
        entityName="Intermediary"
        applicationsCount={updateExistingIntermediaryApplicationsData.applicationsCount}
        onClose={resetConfirmUpdateExistingApplications}
        onConfirm={onConfirmUpdateExistingApplications}
        isLoading={isUpdateIntermediaryApplicationsInProgress}
        usePortal
      />}
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChanges}
        usePortal
      />}
    </>
  );
};

export default IntermediaryDetails;
