import React, { FC } from 'react';
import styles from 'components/DecisionResultOverview/Overview/DecisionResultInfo.module.scss';
import Table, { TableBody } from 'components/Table';
import TableRow from 'components/Table/TableRow';
import { SkeletonRectangle } from 'components/Skeleton';

const DecisionResultInfoSkeleton: FC = () => {
  return (
    <div className={styles.decisionOverviewContainer}>
      <SkeletonRectangle width="300px" height="16px" color="primary20" marginBottom="10px" marginTop="5px" />
      <div className={styles.decisionInfo}>
        <Table className={styles.decisionInfoCell}>
          <TableBody>
            <TableRow disableHover className={styles.decisionInfoRow}>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
            <TableRow disableHover className={styles.decisionInfoRow} noBottomBorder>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={styles.decisionInfoCell}>
          <TableBody>
            <TableRow disableHover className={styles.decisionInfoRow}>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
            <TableRow disableHover className={styles.decisionInfoRow} noBottomBorder>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={styles.decisionInfoCell}>
          <TableBody>
            <TableRow disableHover className={styles.decisionInfoRow}>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
            <TableRow disableHover className={styles.decisionInfoRow} noBottomBorder>
              <div className={styles.columnViewItem}>
                <SkeletonRectangle width="100px" color="primary10" height="12px" marginBottom="8px" marginTop="16px" />
                <div className={styles.propertyValue}>
                  <SkeletonRectangle width="200px" color="primary10" height="12px" marginBottom="18px" />
                </div>
              </div>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default DecisionResultInfoSkeleton;
