import React, { FC, FormEvent, useEffect, useState } from 'react';
import { CloseButtonImage } from 'static/images';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import ButtonWithImage from 'components/ButtonWithImage';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import AutoCompletion from 'components/AutoCompletion';
import ButtonLikeToggle from 'components/ButtonLikeToggle';
import { Option } from 'components/SelectInput/SelectInput';
import EditCompanyUserSkeleton from './EditCompanyUserSkeleton';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import useTimer from 'hooks/useTimer';
import TimerMessage from 'TimerMessage/TimeMessage';
import { resendInvite } from 'OrganizationUsers/OrganizationUserStore';
import { useDispatch } from 'react-redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import useCompanyFreeSeatsCheck from 'AddNewCompanyUser/useCompanyFreeSeatsCheck';
import { PermissionGroupSelectOption } from 'PermissionGroups/Types';
import styles from './EditCompanyUser.module.scss';

interface EditCompanyUserProps {
  updateUser: (userInfo: EditUserParams) => void;
  selectedUserInfo?: OrganizationMembership;
  onClose: () => void;
  selfEdit: boolean;
  handleOpenSeatsAreFullPopUp: () => void;
  isUserDetailedAttributesLoading: boolean;
  permissionGroups: PermissionGroupSelectOption[];
  handleEditPermissionGroupsClick: () => void;
}

const defaultUserInfo: OrganizationMembership = {
  membershipId: '',
  firstName: '',
  lastName: '',
  email: '',
  isMfaEnabled: false,
  isActive: false,
  userId: '',
  permissionGroupId: '',
  permissionGroupName: '',
  isOwner: false,
};

const RESEND_INVITATION_TIMEOUT = 60;

const EditCompanyUser: FC<EditCompanyUserProps> = (props) => {
  const { seconds, restart, isRunning } = useTimer(RESEND_INVITATION_TIMEOUT, { autoStart: false });
  const dispatch = useDispatch();
  const {
    updateUser,
    selectedUserInfo = defaultUserInfo,
    onClose,
    selfEdit,
    handleOpenSeatsAreFullPopUp,
    isUserDetailedAttributesLoading,
    permissionGroups,
    handleEditPermissionGroupsClick,
  } = props;

  const {
    membershipId,
    firstName,
    lastName,
    email,
    permissionGroupId: initialPermissionGroupId,
    isActive,
  } = selectedUserInfo;

  const [permissionGroupId, setPermissionGroup] = useState(initialPermissionGroupId);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeStatusInLoading, checkFreeSeats] = useCompanyFreeSeatsCheck();

  useEffect(() => {
    setPermissionGroup(initialPermissionGroupId);
    setStatus(isActive);
  }, [initialPermissionGroupId, isActive]);

  const handlePermissionGroupChange = ({ value }: Option) => {
    setPermissionGroup(value);
  };

  const handleEditUser = async (e: FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    await updateUser({
      userId: membershipId,
      isActive: status,
      permissionGroupId,
    });
    setIsLoading(false);
    onClose();
  };

  const handleStatusChange = async (e: unknown, isChecked: boolean) => {
    if (!isActive && !status) {
      const hasFreeSeat = await checkFreeSeats(email, handleOpenSeatsAreFullPopUp);

      if (!hasFreeSeat) {
        return;
      }
    }

    setStatus(isChecked);
  };

  const handleResendInviteClick = async () => {
    await dispatch(resendInvite(selectedUserInfo.membershipId));

    notification.createNotification(
      getMessage(MessageType.InvitationSent, { email: selectedUserInfo.email }),
      'success',
      dispatch,
    );

    restart();
  };

  const isUserInfoChanged = !(initialPermissionGroupId === permissionGroupId && isActive === status);

  const getStatusName = () => {
    if (status) {
      return selectedUserInfo.userId ? 'Active' : 'Pending';
    }

    return 'Inactive';
  };

  const renderForm = () => {
    return (
      <>
        <div className={styles.editUser__inputs}>
          {selectedUserInfo.userId && <TextInput type="text" labelTitle="First Name" value={firstName} disabled />}
          {selectedUserInfo.userId && <TextInput type="text" labelTitle="Last Name" value={lastName} disabled />}
          <TextInput type="text" labelTitle="Email" value={email} disabled />

          <AutoCompletion
            labelTitle="Permissions Group"
            onChange={handlePermissionGroupChange}
            value={permissionGroupId}
            options={permissionGroups}
            disabled={selfEdit}
            hideClearIcon
            link={
              <ButtonWithImage
                kind="link"
                title="Edit Permission Groups"
                className={styles.editPermissionGroupsButton}
                onClick={handleEditPermissionGroupsClick}
              />
            }
          />
        </div>
        <div className={`${styles.editUser__sectionList}`}>
          <div className={`${styles.editUser__section}`}>
            <div className={styles.editUser__sectionTitle}>
              <p className={styles.label}>Account Status</p>
            </div>
            <div className={styles.editUser__sectionContent}>
              <h5>{getStatusName()}</h5>
              {!selectedUserInfo.userId && selectedUserInfo.isActive && (
                <ButtonWithLoadingState
                  className={styles.resendInvite}
                  kind="secondary"
                  disabled={isRunning}
                  onClick={handleResendInviteClick}
                >
                  Re-Send Invitation
                </ButtonWithLoadingState>
              )}
              <ButtonLikeToggle
                checked={status}
                className={styles.editUser__activeButton}
                onChange={handleStatusChange}
                isLoading={isChangeStatusInLoading}
                disabled={selfEdit}
              >
                {status ? 'Deactivate Account' : 'Activate Account'}
              </ButtonLikeToggle>
            </div>
            {isRunning && (
              <TimerMessage>
                You can re-send the invitation again in <strong>{isRunning ? seconds : 0}</strong>s
              </TimerMessage>
            )}
          </div>
        </div>
        <Button type="submit" kind="primary" size="form" disabled={!isUserInfoChanged} isLoading={isLoading}>
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <form className={styles.editUser} onSubmit={handleEditUser}>
      <div className={styles.editUser__header}>
        <h2>Edit User</h2>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.editUser__content}>
        {isUserDetailedAttributesLoading ? <EditCompanyUserSkeleton /> : renderForm()}
      </div>
    </form>
  );
};

export default EditCompanyUser;
