import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import useStateReset from 'hooks/useStateReset';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';
import { openDeleteEmailPopup, sortEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import EmailsTable from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable';
import { EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { useHistory } from 'react-router-dom';
import styles from './BorrowerEmails.module.scss';
import NoEmailsConnected from 'components/LoanOriginationSystem/NoEmailsConnected';
import { changeContextualViewStep } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { isEqual } from 'lodash';
import { initialState as filtersInitialState } from 'LoanOriginationSystemEmails/Filters/Reducer';

interface BorrowerEmailsProps {
  borrowerId: string;
  borrowerEmail: string;
  organizationEmail: string;
}

export const EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER = 1;

const BorrowerEmails: FC<BorrowerEmailsProps> = ({ borrowerId, borrowerEmail, organizationEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  useStateReset(LoanOriginationSystemEmailsActionTypes.ResetState);

  const params: EmailsPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    createdAtDateRange: filters.createdAtRange,
    selectedBorrower: borrowerId,
  };

  const emails = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  useEffect(() => {
    dispatch(pagination.reloadCurrentPage(params));
  }, []);

  const isNoEmails =
    paginationProps.itemsTotal === 0 && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const handleSort = (field: EmailsSortingField, ascending: boolean) => {
    dispatch(sortEmails(field, ascending, borrowerId));
  };

  const handleOpenEmail = (id: string) => {
    history.replace(`?edit=${borrowerId}&borrowerEmailId=${id}`);
    dispatch(changeContextualViewStep(EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER));
  };
  const handleDeleteEmail = (id: string) => dispatch(openDeleteEmailPopup(id));

  return (
    <div className={styles.emailsContainer}>
      {isNoEmails ? (
        <NoEmailsConnected
          headerText=""
          email={organizationEmail}
          borrowerEmail={borrowerEmail}
          hideHeader
          descriptionClassName={styles.noEmailsDescriptionContainer}
        />
      ) : (
        <EmailsTable
          paginationProps={paginationProps}
          emails={emails}
          onSort={handleSort}
          sortingType={sortingType}
          onOpenEmail={handleOpenEmail}
          onDeleteEmail={handleDeleteEmail}
          organizationEmail={organizationEmail}
          searchValue={filters.searchInputValue}
          stickyMarginTop={TABLE_HEADER_OFFSET}
        />
      )}
    </div>
  );
};

export default BorrowerEmails;
