import React, { useMemo } from 'react';
import styles from './CompanyInformationView.module.scss';

import { OrganizationState } from 'CompanyInformation/CompanyInformationTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import COUNTRY_OPTIONS from 'constants/countryOptions';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import CompanyGeneralSettingsSkeleton from 'components/CompanyGeneralSettings/CompanyGeneralSettingsSkeleton';
import UserAvatar from 'components/UserAvatar';

const getCountryNameByCode = (countryCode: string) => {
  const currentCountry = COUNTRY_OPTIONS.find((country: { value: string }) => country.value === countryCode);

  return currentCountry ? currentCountry.name : '';
};

interface CompanyInformationViewProps {
  organizationInfo: OrganizationState;
  organizationUsers: UserInfo[];
}

const CompanyInformationView = ({ organizationInfo, organizationUsers }: CompanyInformationViewProps) => {
  const { isLoading } = useSelector((state: ReduxState) => state.organizationInformation);
  const primaryContactUser = useMemo(() => {
    if (!!organizationUsers.length && organizationInfo && organizationInfo.primaryContact) {
      return organizationUsers.find((organizationUser) => organizationUser.id === organizationInfo.primaryContact);
    }

    return null;
  }, [organizationUsers, organizationInfo]);

  if (isLoading) {
    return <CompanyGeneralSettingsSkeleton />;
  }

  return (
    <div className={styles.table}>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Name</div>
        <div className={styles.tableRowValue}>{organizationInfo.name}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Primary Contact</div>
        <div className={styles.tableRowValue}>
          {primaryContactUser ? (
            <>
              <UserAvatar
                size="small"
                firstName={primaryContactUser.firstName}
                lastName={primaryContactUser.lastName}
                imageId={primaryContactUser.imageId}
                className={styles.userAvatar}
              />
              <div>{`${primaryContactUser.firstName} ${primaryContactUser.lastName}`}</div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Legal Address</div>
        <div className={styles.tableRowValue}>{organizationInfo.legalAddress}</div>
      </div>
      <div className={styles.tableRow}>
        <div className={styles.tableRowLabel}>Country</div>
        <div className={styles.tableRowValue}>{getCountryNameByCode(organizationInfo.country)}</div>
      </div>
    </div>
  );
};

export default CompanyInformationView;
