import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';
import { DataFilter, DataViewSortingType, VariableValue } from './Types';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';

export enum ApplicationHistoryType {
  ApplicationCreated = 'applicationCreated',
  ApplicationUpdated = 'applicationUpdated',
  ApplicationDuplicated = 'applicationDuplicated',
  DecisionRun = 'applicationDecisionProcessed',
  StatusTransition = 'statusTransition',
  DocumentCreated = 'documentCreated',
  DocumentDeleted = 'documentDeleted',
  NoteCreated = 'noteCreated',
  NoteDeleted = 'noteDeleted',
  TaskCreated = 'taskCreated',
  TaskDeleted = 'taskDeleted',
  TaskCompleted = 'taskCompleted',
  EmailCreated = 'emailCreated',
  EmailDeleted = 'emailDeleted',
  CoborrowerAdded = 'coborrowerAdded',
  CoborrowerRemoved = 'coborrowerRemoved',
  IntermediaryAdded = 'intermediaryAdded',
  IntermediaryRemoved = 'intermediaryRemoved',
}

export enum ApplicationHistorySortingField {
  CreatedAt = 'createdAt',
}

export enum ApplicationHistoryFilterType {
  AllTypes = 'allTypes',
  ApplicationStatus = 'applicationStatus',
  Data = 'data',
  Tasks = 'tasks',
  Emails = 'emails',
  Documents = 'documents',
  DecisionEngine = 'decisionEngine',
  Notes = 'notes',
  Other = 'other',
}

export type ApplicationHistorySortingType = DataViewSortingType<ApplicationHistorySortingField>;

export interface BaseApplicationHistoryFilters {
  applicationId: string;
  selectedMembers: UserInfo[];
  filterType: ApplicationHistoryFilterType;
}

export type ApplicationHistoryFilters = BaseApplicationHistoryFilters & DataFilter;

interface BaseApplicationHistoryRecord<Type, Data> {
  id: string;
  createdAt: Date;
  createdBy?: UserInfo | null;
  type: Type;
  data: Data;
}

interface ApplicationCreatedData {
  borrowerName: string;
}

export interface ApplicationUpdatedVariable {
  systemName: string;
  displayName: string;
  dataType: string;
  oldValue: VariableValue;
  newValue: VariableValue;
}

export interface ApplicationUpdatedData {
  variables: ApplicationUpdatedVariable[];
}

interface ApplicationDuplicatedData extends ApplicationCreatedData {
  originalApplicationDisplayId: string;
}

interface StatusTransitionData {
  transitionFrom: string;
  transitionTo: string;
  name: string;
}

interface TaskEventData {
  description: string;
}

interface EmailEventData {
  from: string;
  to: string;
  subject: string;
}

interface DecisionRunEventData {
  decisionName: string;
  strategyName: string;
  strategyDisplayName: string;
  strategyVersion: string;
  result: 'passed' | 'failed' | 'errored';
}

interface DocumentEventData {
  documentName: string;
}

interface NoteEventData {
  description: string;
}

interface CoborrowerEventData {
  coborrowerName: string;
}

interface IntermediaryEventData {
  intermediaryName: string;
}

export type ApplicationHistoryRecord =
  | BaseApplicationHistoryRecord<ApplicationHistoryType.ApplicationCreated, ApplicationCreatedData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.ApplicationUpdated, ApplicationUpdatedData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.ApplicationDuplicated, ApplicationDuplicatedData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.StatusTransition, StatusTransitionData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskCreated, TaskEventData & { taskId: string }>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskCompleted, TaskEventData & { taskId: string }>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskDeleted, TaskEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.EmailCreated, EmailEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.EmailDeleted, EmailEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DecisionRun, DecisionRunEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DocumentCreated, DocumentEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DocumentDeleted, DocumentEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.NoteCreated, NoteEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.NoteDeleted, NoteEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.CoborrowerAdded, CoborrowerEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.CoborrowerRemoved, CoborrowerEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.IntermediaryAdded, IntermediaryEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.IntermediaryRemoved, IntermediaryEventData>;

export const typesByHistoryFilterTypeMap = new Map([
  [ApplicationHistoryFilterType.AllTypes, []],
  [ApplicationHistoryFilterType.ApplicationStatus, [ApplicationHistoryType.StatusTransition]],
  [ApplicationHistoryFilterType.Data, [ApplicationHistoryType.ApplicationUpdated]],
  [
    ApplicationHistoryFilterType.Tasks,
    [ApplicationHistoryType.TaskCreated, ApplicationHistoryType.TaskCompleted, ApplicationHistoryType.TaskDeleted],
  ],
  [ApplicationHistoryFilterType.Emails, [ApplicationHistoryType.EmailCreated, ApplicationHistoryType.EmailDeleted]],
  [
    ApplicationHistoryFilterType.Documents,
    [ApplicationHistoryType.DocumentCreated, ApplicationHistoryType.DocumentDeleted],
  ],
  [ApplicationHistoryFilterType.DecisionEngine, [ApplicationHistoryType.DecisionRun]],
  [ApplicationHistoryFilterType.Notes, [ApplicationHistoryType.NoteCreated, ApplicationHistoryType.NoteDeleted]],
  [
    ApplicationHistoryFilterType.Other,
    [
      ApplicationHistoryType.CoborrowerAdded,
      ApplicationHistoryType.CoborrowerRemoved,
      ApplicationHistoryType.IntermediaryAdded,
      ApplicationHistoryType.IntermediaryRemoved,
    ],
  ],
]);

export interface ApplicationHistoryApi {
  getRecords(applicationId: string): Promise<ApplicationHistoryRecord[]>;
}

export default class ApplicationHistoryRestApi extends LoanOriginationSystemApi<ApplicationHistoryRecord>
  implements ApplicationHistoryApi {
  protected resourceName = 'application-history';

  public getRecords(applicationId: string) {
    const params = new URLSearchParams();

    params.set('applicationId', applicationId);

    return this.getResources<ApplicationHistoryRecord[]>(params);
  }
}
