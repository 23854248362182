import React, { FC, DetailedHTMLProps, InputHTMLAttributes, TextareaHTMLAttributes, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { removeWhiteSpace } from 'utils/validation/validation';
import useRandomIdFallback from 'hooks/randomIdFallback';
import InputWithValidation, { InputProps } from 'components/InputWithValidation/InputWithValidation';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import Label from 'components/Label';
import styles from './TextInput.module.scss';
import WrapperWithTooltip from 'components/Tooltip';

export type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type HTMLTextAreaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

interface TextInputProps {
  labelTitle?: string;
  errorMessage?: string;
  error?: boolean;
  topRightElement?: ReactNode;
  multiline?: boolean;
  value?: string;
  hasRightNeighbour?: boolean;
  hasRightPadding?: boolean;
  hasLeftPadding?: boolean;
  containerClassName?: string;
  loaderClassName?: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tooltip?: string;
  inputIcon?: React.ReactNode;
  required?: boolean;
  renderOverlay?: () => ReactNode;
}

type TextInputPropsMultiline = TextInputProps & HTMLTextAreaProps & { inputRef?: Ref<HTMLTextAreaElement> };

export type TextInputPropsSingleLine = TextInputProps &
  HTMLInputProps &
  InputProps & { inputRef?: Ref<HTMLInputElement> };

const isMultiline = (props: TextInputPropsSingleLine | TextInputPropsMultiline): props is TextInputPropsMultiline =>
  props.multiline || false;

const getFieldElement = (props: TextInputPropsSingleLine | TextInputPropsMultiline): JSX.Element => {
  if (isMultiline(props)) {
    const {
      className,
      labelTitle,
      errorMessage,
      multiline,
      containerClassName,
      error,
      inputRef,
      ...fieldProps
    } = props;
    const textAreaClassName = clsx(styles.textArea, (errorMessage || error) && styles.inputError);
    return <textarea className={textAreaClassName} {...fieldProps} ref={inputRef} />;
    // eslint-disable-next-line no-else-return
  } else {
    const {
      className,
      labelTitle,
      errorMessage,
      multiline,
      value: rawValue,
      hasRightNeighbour = false,
      hasRightPadding,
      hasLeftPadding,
      topRightElement,
      inputRef,
      error,
      containerClassName,
      ...fieldProps
    } = props as TextInputPropsSingleLine;
    const value = removeWhiteSpace(rawValue);
    const inputClassName = clsx(
      styles.input,
      (errorMessage || error) && styles.inputError,
      hasRightNeighbour && styles.inputWithRightNeighbour,
      hasRightPadding && styles.inputWithRightPadding,
      hasLeftPadding && styles.inputWithLeftPadding,
      className,
    );
    return <InputWithValidation className={inputClassName} value={value} {...fieldProps} ref={inputRef} />;
  }
};

const TextInput: FC<TextInputPropsSingleLine | TextInputPropsMultiline> = (props) => {
  const { showLoader, onLoaderStateReset, loaderState, loaderClassName, tooltip, required, renderOverlay, inputIcon, ...restProps } = props;
  const { id: providedId, labelTitle, errorMessage, topRightElement, containerClassName } = restProps;

  const id = useRandomIdFallback(providedId);

  const fieldElement = getFieldElement({
    ...restProps,
    id,
  });

  return (
    <div className={clsx(styles.inputContainer, containerClassName)}>
      <div className={styles.labelWithLink}>
        <Label htmlFor={id} required={required}>
          {labelTitle}
        </Label>
        {topRightElement}
      </div>
      <div className={styles.fieldContainer}>
        {showLoader && (
          <LoaderWithState
            className={clsx(styles.loader, loaderClassName)}
            state={loaderState}
            onStateReset={onLoaderStateReset}
          />
        )}
        <WrapperWithTooltip tooltip={tooltip}>{fieldElement}</WrapperWithTooltip>
        {inputIcon && <div className={styles.inputIconContainer}>{inputIcon}</div>}
        {renderOverlay?.()}
      </div>
      {errorMessage && <p className={styles.errorNotification}>{errorMessage}</p>}
    </div>
  );
};

export default TextInput;
