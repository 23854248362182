export enum WebhookEventWithEndpointColumnSize {
  Endpoint = 19,
  EventType = 14,
  EventDate = 17,
  Status = 11,
  LastRunAt = 17,
  NextRunAt = 22,
}

export enum WebhookEventColumnSize {
  EventType = 23,
  EventDate = 18,
  Status = 19,
  LastRunAt = 25,
  NextRunAt = 15,
}
