import createWorkerHook, { ModuleMethods } from 'hooks/createWorkerHook';
import { Worker } from 'threads';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { BaseCalculation } from 'api/LoanOriginationSystem/Base/CalculationsApi';

interface CalculationsLogicWorker extends ModuleMethods {
  runCalculationsLogic<CalculationType extends BaseCalculation>(
    calculations: Array<CalculationType>,
    data: FormLayoutData,
  ): Promise<FormLayoutData>;
}

const useCalculationsLogicWorker = createWorkerHook<CalculationsLogicWorker>(() => {
  return new Worker('workers/CalculationsLogicWorker');
});

export default useCalculationsLogicWorker;
