import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import {
  createApplicationDataTabVariableConfiguration,
  updateApplicationDataTabVariableConfigurationPosition,
  updateConditionalDisplayRule,
  deleteApplicationDataTabVariableConfiguration,
  updateVariableConfigurationRequiredAttribute,
} from './Thunks';
import {
  deleteVariableConfiguration,
  updateVariableConfigurationStore,
} from 'LoanOriginationSystemVariablesConfiguration/Utils';
import {
  createApplicationDataTabCard,
  getApplicationDataTabCards,
  updateApplicationDataTabCard,
} from 'ApplicationDataTabCards/Thunks';

export interface ApplicationDataTabVariableConfigurationsState {
  configurationsById: Record<string, ApplicationDataTabVariableConfiguration>;
}

const initialState: ApplicationDataTabVariableConfigurationsState = {
  configurationsById: {},
};

const applicationDataTabVariableConfigurationsReducer = createReducer<ApplicationDataTabVariableConfigurationsState>(initialState, (builder) => {
  builder.addCase(deleteApplicationDataTabVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfiguration(state, action.payload.id);
  });

  builder.addCase(
    updateVariableConfigurationRequiredAttribute.pending,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = action.meta.arg.required;
    },
  );

  builder.addCase(
    updateVariableConfigurationRequiredAttribute.rejected,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = !action.meta.arg.required;
    },
  );

  builder.addMatcher(
    isFulfilled(
      createApplicationDataTabVariableConfiguration,
      updateApplicationDataTabVariableConfigurationPosition,
      updateConditionalDisplayRule,
    ),
    (state, action) => {
      updateVariableConfigurationStore(state, {
        [action.payload.configuration.id]: action.payload.configuration,
      });
    },
  );

  builder.addMatcher(
    isFulfilled(getApplicationDataTabCards, createApplicationDataTabCard, updateApplicationDataTabCard),
    (state, action) => {
      updateVariableConfigurationStore(state, action.payload.applicationDataTabVariableConfigurations || {});
    },
  );
});

export default applicationDataTabVariableConfigurationsReducer;
