import React, { FC } from 'react';
import styles from './ViewLayoutConfigurationHeader.module.scss';

export interface ViewLayoutConfigurationHeaderProps {
  title: React.ReactNode;
  renderActions?: () => React.ReactNode;
}

const ViewLayoutConfigurationHeader: FC<ViewLayoutConfigurationHeaderProps> = ({
  title,
  renderActions,
}) => {
  return (
    <div className={styles.layoutHeader}>
      <h4>{title}</h4>
      <div className={styles.actionsContainer}>
        {renderActions?.()}
      </div>
    </div>
  );
};

export default ViewLayoutConfigurationHeader;
