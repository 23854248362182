import React from 'react';
import { ApplicationHistoryRecord } from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { NullableItems } from 'pagination';
import HistoryRecord from './HistoryRecord';
import SkeletonHistoryRecord from './SkeletonHistoryRecord';

export interface HistoryListProps {
  historyRecords: NullableItems<ApplicationHistoryRecord>;
  search: string;
}

const HistoryList = ({ historyRecords, search }: HistoryListProps) => {
  const renderHistoryRecord = (historyRecord: ApplicationHistoryRecord | null) => {
    if (!historyRecord) {
      return <SkeletonHistoryRecord />;
    }

    return <HistoryRecord record={historyRecord} search={search} />;
  };

  return <div>{historyRecords.map(renderHistoryRecord)}</div>;
};

export default HistoryList;
