import React, { useMemo } from 'react';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import DraggableInputStub from 'components/DraggableInputStub';
import WrapperWithTooltip from 'components/Tooltip';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { ConditionalDisplayRuleIcon } from 'static/images';
import CalculationsFunctionIcon from 'components/LoanOriginationSystem/CalculationsFunctionIcon';

export interface VariableConfigurationDraggableInputStubProps<
  VariableConfigurationType extends BaseVariableConfiguration
> {
  className?: string;
  variableConfiguration: VariableConfigurationType;
  onChangeRequiredAttributeOption?: (variableConfiguration: VariableConfigurationType) => void;
  onDelete?: (variableConfiguration: VariableConfigurationType) => void;
  onDeleteConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onEditConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  onAddConditionalDisplayRule: (variableConfiguration: VariableConfigurationType) => void;
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  isDragging?: boolean;
  isCalculated?: boolean;
}

const VariableConfigurationDraggableInputStub = <VariableConfigurationType extends BaseVariableConfiguration>({
  className,
  isDragging,
  variableConfiguration,
  onChangeRequiredAttributeOption,
  onDelete,
  onAddConditionalDisplayRule,
  onDeleteConditionalDisplayRule,
  onEditConditionalDisplayRule,
  formatVariableConfigurationDisplayTitle,
  isCalculated,
}: VariableConfigurationDraggableInputStubProps<VariableConfigurationType>) => {
  const { default: isDefault, required, variable, conditionalDisplayRule } = variableConfiguration;
  const label = formatVariableConfigurationDisplayTitle
    ? formatVariableConfigurationDisplayTitle(variable.systemName, variable.displayName)
    : variable.displayName;
  const conditionalDisplayRuleExists = !!conditionalDisplayRule;

  const actions = useMemo(() => {
    return [
      {
        title: conditionalDisplayRuleExists ? 'Edit Display Condition' : 'Add Display Condition',
        handler: () => {
          if (conditionalDisplayRuleExists) {
            onEditConditionalDisplayRule(variableConfiguration);
          }

          onAddConditionalDisplayRule(variableConfiguration);
        },
      },
      ...(conditionalDisplayRuleExists ? [{
        title: 'Remove Display Condition',
        handler: () => onDeleteConditionalDisplayRule(variableConfiguration),
      }] : []),
      ...(onChangeRequiredAttributeOption ? [{
        title: required ? 'Make Field Optional' : 'Make Field Required',
        handler: () => onChangeRequiredAttributeOption?.(variableConfiguration),
        separatorRequired: true,
      }] : []),
      {
        title: 'Remove Variable',
        handler: () => onDelete?.(variableConfiguration),
        danger: true,
        disabled: isDefault,
        tooltip: isDefault ? `${label} cannot be removed.` : '',
      },
    ];
  }, [
    variableConfiguration,
    required,
    isDefault,
    conditionalDisplayRuleExists,
    onDeleteConditionalDisplayRule,
    onEditConditionalDisplayRule,
    onAddConditionalDisplayRule,
    onChangeRequiredAttributeOption,
    onDelete,
  ]);

  const markerIcons = useMemo(() => {
    if (!conditionalDisplayRuleExists) {
      return null;
    }

    return (
      <WrapperWithTooltip
        tooltip={(<>
          <p>This variable has a display</p>
          <p>condition</p>
        </>)}
      >
        <ConditionalDisplayRuleIcon />
      </WrapperWithTooltip>
    );
  }, [conditionalDisplayRuleExists]);

  const inputIcon = useMemo(() => {
    if (!isCalculated) {
      return null;
    }

    return (
      <CalculationsFunctionIcon />
    );
  }, [isCalculated]);

  return (
    <DraggableInputStub
      className={className}
      actions={actions}
      label={label}
      isDragging={isDragging}
      required={required}
      markerIcons={markerIcons}
      inputIcon={inputIcon}
      showActions
      {...getVisualDataTypeWithAttributes(variableConfiguration.variable)}
    />
  );
};

export default React.memo(VariableConfigurationDraggableInputStub) as typeof VariableConfigurationDraggableInputStub;
