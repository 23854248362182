import FailedActionCreator, { FailedAction } from 'utils/actions/FailedActionCreator';
import { TableViewData } from 'api/Types';
import { DecisionStrategyUpdatesActions } from './ActionTypes';
import { StrategyUpdateType, DecisionStrategyUpdatesSortingType } from './Types';

export interface GetDecisionStrategyUpdatesAction {
  type: DecisionStrategyUpdatesActions.GetUpdatesData;
  payload: string;
}

export interface DecisionStrategyUpdates extends TableViewData<StrategyUpdateType> {
  strategyId: string;
}

export interface GetDecisionStrategyUpdatesSuccessAction {
  type: DecisionStrategyUpdatesActions.GetUpdatesDataSuccess;
  payload: DecisionStrategyUpdates;
}

export const getDecisionStrategyUpdatesRequest = (strategyId: string): GetDecisionStrategyUpdatesAction => {
  return {
    type: DecisionStrategyUpdatesActions.GetUpdatesData,
    payload: strategyId,
  };
};

export const getDecisionStrategyUpdatesSuccess = (
  decisionStrategyUpdates: DecisionStrategyUpdates,
): GetDecisionStrategyUpdatesSuccessAction => {
  return {
    type: DecisionStrategyUpdatesActions.GetUpdatesDataSuccess,
    payload: decisionStrategyUpdates,
  };
};

export interface GetDecisionStrategyUpdatesFailureAction
  extends FailedAction<DecisionStrategyUpdatesActions.GetUpdatesDataFailure> {}

export const getDecisionStrategyUpdatesError = FailedActionCreator(
  DecisionStrategyUpdatesActions.GetUpdatesDataFailure,
);

export interface SetSortingTypeAction {
  type: DecisionStrategyUpdatesActions.SetSortingType;
  payload: DecisionStrategyUpdatesSortingType;
}

export const setSortingType = (payload: DecisionStrategyUpdatesSortingType): SetSortingTypeAction => ({
  type: DecisionStrategyUpdatesActions.SetSortingType,
  payload,
});

export interface ResetStateAction {
  type: DecisionStrategyUpdatesActions.ResetState;
}

export const resetState = (): ResetStateAction => ({
  type: DecisionStrategyUpdatesActions.ResetState,
});
