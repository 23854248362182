import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortVariablesAction, SetShowArchivedAction } from './VariablesActionCreator';
import {
  FilterVariablesByMembersAction,
  ChangePopupFiltersAction,
  ResetAllVariablesFilters,
} from './Filters/ActionCreator';
import { VariablesActionType } from './ActionTypes';
import { VariablesFiltersActionTypes } from './Filters/ActionTypes';
import { VariablesFiltersState } from './Filters/Types';
import { VariablesState } from './VariablesTypes';
import { VariablesSortingType } from 'api/Core/VariablesApi';

interface VariablesFiltersToSave {
  filters: Pick<
    VariablesFiltersState,
    'dueCreatedDateRange' | 'dueUpdatedDateRange' | 'onlyCustom' | 'onlyStandard' | 'dataType' | 'selectedMembers'
  >;
}

type VariablesFieldsToSave = Pick<VariablesState, 'showArchived'> & VariablesFiltersToSave;

const getFiltersToSave = (state: ReduxState) => {
  const { filters, showArchived } = state.variables;

  return {
    showArchived,
    filters: pick(filters, [
      'dueCreatedDateRange',
      'dueUpdatedDateRange',
      'onlyCustom',
      'onlyStandard',
      'dataType',
      'selectedMembers',
    ]),
  };
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.variables;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<FilterVariablesByMembersAction>(VariablesFiltersActionTypes.FilterVariablesByMembers),
  isActionOfType<ChangePopupFiltersAction>(VariablesFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllVariablesFilters>(VariablesFiltersActionTypes.ResetAllFilters),
  isActionOfType<SortVariablesAction>(VariablesActionType.SortVariables),
  isActionOfType<SetShowArchivedAction>(VariablesActionType.SetShowArchived),
];

const variablesFilterAndSortingSaver = FilterAndSortingSaver<VariablesFieldsToSave, VariablesSortingType>({
  key: 'variables',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: VariablesActionType.ResetVariablesState,
});

export default variablesFilterAndSortingSaver;
