import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { NullableItems } from 'pagination';
import useDraggableInPortal from 'hooks/useDraggableInPortal';

import styles from 'components/DndList/DndList.module.scss';

export interface DroppableListItemsProps<Item> {
  droppableId: string;
  items: NullableItems<Item>;
  renderListItem: (
    item: Item,
    index: number,
    snapshot: DraggableStateSnapshot,
  ) => ReactNode;
  renderInPortal?: boolean;
  listItemClassName?: string;
  isDragDisabled?: boolean | ((item: Item, index: number) => boolean);
  renderSkeletonItem?: (index: number) => ReactNode;
  getDraggableId?: (itemId: string | number, droppableId: string) => string;
}

const DroppableListItems = React.memo(function DroppableListItemsFn<Item extends { id: string | number }>({
  droppableId,
  renderListItem,
  items,
  renderInPortal,
  listItemClassName,
  isDragDisabled,
  getDraggableId,
  renderSkeletonItem,
}: DroppableListItemsProps<Item>) {
  const renderDraggableInPortal = useDraggableInPortal();

  const renderItemContainer = (
    draggableProvided: DraggableProvided,
    item: Item,
    index: number,
    draggableSnapshot: DraggableStateSnapshot,
  ) => {
    const listItemClasses = clsx(listItemClassName, styles.draggableItem, {
      [styles.draggableItem__dragging]: draggableSnapshot.isDragging,
    });

    return (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        className={listItemClasses}
      >
        {renderListItem(item, index, draggableSnapshot)}
      </div>
    );
  };

  const renderDraggableContent = (item: Item, index: number) => {
    return renderInPortal
      ? renderDraggableInPortal((draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) =>
          renderItemContainer(draggableProvided, item, index, draggableSnapshot),
        )
      : (draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) =>
          renderItemContainer(draggableProvided, item, index, draggableSnapshot);
  };

  return (
    <>
      {items.map((item: Item | null, index: number) => {
        if (!item) {
          return renderSkeletonItem ? renderSkeletonItem(index) : null;
        }

        return (
          <Draggable
            isDragDisabled={typeof isDragDisabled === 'function' ? isDragDisabled(item, index) : isDragDisabled}
            key={item.id}
            draggableId={getDraggableId ? getDraggableId(item.id, droppableId) : `item-${item.id}`}
            index={index}
          >
            {renderDraggableContent(item, index)}
          </Draggable>
        );
      })}
    </>
  );
});

export default DroppableListItems as <Item extends { id: string | number }>(props: DroppableListItemsProps<Item>) => ReactElement;
