import React, { FocusEvent, useMemo, useState, useCallback, useEffect } from 'react';
import { PopperProps } from '@material-ui/core';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import SuggestionsPopper, {
  SuggestionItem,
} from 'components/LoanOriginationSystem/CreateApplication/SuggestionsPopper';
import { FormLayoutData, VariableValue } from 'api/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { BorrowerSuggestionFilter, BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { formatDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { getFormattedBorrowerPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import shouldShowSuggestions from 'components/LoanOriginationSystem/CreateApplication/Forms/CreateBorrowerForm/NewBorrowerForm/shouldShowSuggestions';
import { removeWhiteSpace } from 'utils/validation/validation';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import ProductBorrowerTypeToggleGroup from 'components/LoanOriginationSystem/ProductBorrowerTypeToggleGroup';
import { isPhoneNumber } from 'components/InputWithDataType/InputWithDataType';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { StandardVariables } from 'Variables/VariablesTypes';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import styles from './NewBorrowerForm.module.scss';

interface NewBorrowerFormProps {
  borrowerData: FormLayoutData;
  selectedBorrowerType: BorrowerType;
  suggestions: SuggestionItem[];
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onSuggestionSelect: (suggestionItem: SuggestionItem) => void;
  onLoadSuggestions?: (suggestionFilter: BorrowerSuggestionFilter) => void;
  cards: BorrowerProfileCard[] | null;
  standardVariables: StandardVariables | null;
  onChangeBorrowerType: (borrowerType: BorrowerType) => void;
  availableBorrowerTypes: BorrowerType[];
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const VARIABLES_WITH_SUGGESTIONS = [
  BorrowerDefaultVariable.CompanyName,
  BorrowerDefaultVariable.FirstName,
  BorrowerDefaultVariable.LastName,
  BorrowerDefaultVariable.PersonalIdNumber,
  BorrowerDefaultVariable.CompanyIdNumber,
  BorrowerDefaultVariable.Email,
  BorrowerDefaultVariable.PhoneNumber,
  BorrowerDefaultVariable.DateOfBirth,
];

const NewBorrowerForm = ({
  onSuggestionSelect,
  onLoadSuggestions,
  suggestions,
  borrowerData,
  onFieldChange,
  cards,
  selectedBorrowerType,
  standardVariables,
  onChangeBorrowerType,
  availableBorrowerTypes,
  renderActions,
}: NewBorrowerFormProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionAnchorElement, setSuggestionAnchorElement] = useState<PopperProps['anchorEl']>(null);
  const userPermissionGroupId = useUserPermissionGroupId()!;

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, borrowerData);

  const validateBorrowerData = useConfigurableFormValidation(fields);

  const formattedSuggestions = useMemo(() => {
    return suggestions.map((suggestion) => {
      return {
        ...suggestion,
        phone: getFormattedBorrowerPhoneNumber(suggestion.phone, userPermissionGroupId, standardVariables),
      };
    });
  }, [suggestions, userPermissionGroupId]);

  const borrowerSuggestionsFilter = useMemo(() => ({
    email: borrowerData[BorrowerDefaultVariable.Email],
    firstName: borrowerData[BorrowerDefaultVariable.FirstName],
    lastName: borrowerData[BorrowerDefaultVariable.LastName],
    phoneNumber: borrowerData[BorrowerDefaultVariable.PhoneNumber],
    companyName: borrowerData[BorrowerDefaultVariable.CompanyName],
    personalIdNumber: borrowerData[BorrowerDefaultVariable.PersonalIdNumber],
    companyIdNumber: borrowerData[BorrowerDefaultVariable.CompanyIdNumber],
    dateOfBirth: borrowerData[BorrowerDefaultVariable.DateOfBirth],
  } as BorrowerSuggestionFilter), [borrowerData]);

  useEffect(() => {
    emitOnLoadSuggestion();
  }, [borrowerSuggestionsFilter, suggestionAnchorElement]);

  const emitOnLoadSuggestion = () => {
    if (shouldShowSuggestions(borrowerSuggestionsFilter) && suggestionAnchorElement) {
      onLoadSuggestions?.(borrowerSuggestionsFilter);

      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleFieldChange = useCallback((variableConfiguration: BaseVariableConfiguration, value: VariableValue) => {
    onFieldChange(variableConfiguration, removeWhiteSpace(value));
  }, []);

  const handleFieldFocus = ({ variable }: BaseVariableConfiguration, event: FocusEvent<HTMLInputElement>) => {
    if (isPhoneNumber(getVisualDataTypeWithAttributes(variable))) {
      setSuggestionAnchorElement(event.currentTarget.parentElement);

      return;
    }

    if (VARIABLES_WITH_SUGGESTIONS.includes(variable.systemName)) {
      setSuggestionAnchorElement(event.currentTarget);
    }
  };

  const onClosePopper = () => {
    setShowSuggestions(false);
  };

  return (
    <div className={styles.container}>
      <ProductBorrowerTypeToggleGroup
        className={styles.borrowerTypeButtonToggleGroup}
        selectedBorrowerType={selectedBorrowerType}
        onChange={(type) => onChangeBorrowerType(type)}
        availableBorrowerTypes={availableBorrowerTypes}
      />
      <CardsForm
        cards={cards}
        data={borrowerData}
        onFieldChange={handleFieldChange}
        onFieldFocus={handleFieldFocus}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        isEditMode
        displayFieldsAttributes={displayFieldsAttributes}
        displayHeader={false}
        displaySkeleton={!displayFieldsAttributes}
      />
      <SuggestionsPopper
        placement="bottom-start"
        className={styles.popper}
        suggestions={formattedSuggestions}
        anchorEl={suggestionAnchorElement}
        open={suggestions && showSuggestions}
        onClose={onClosePopper}
        onSelectSuggestion={onSuggestionSelect}
      />
      {renderActions?.(!cards, !validateBorrowerData(borrowerData, displayFieldsAttributes))}
    </div>
  );
};

export default NewBorrowerForm;
