export enum ApplicationsTableColumnSizesSmall {
  BorrowerFullName = 15,
  DisplayId = 12,
  CreatedAt = 17,
  Status = 14,
  BorrowerEmail = 21,
  TeamMembers = 11,
  LoanAmount = 10,
}

export enum ApplicationsTableColumnSizesMedium {
  BorrowerFullName = 13,
  DisplayId = 9,
  CreatedAt = 15,
  UpdatedAt = 15,
  Status = 12,
  BorrowerEmail = 18,
  TeamMembers = 9,
  LoanAmount = 9,
}

export enum ApplicationsTableColumnSizesWide {
  BorrowerFullName = 14,
  DisplayId = 8,
  CreatedAt = 13,
  UpdatedAt = 13,
  Status = 10,
  BorrowerEmail = 17,
  BorrowerPhoneNumber = 9,
  TeamMembers = 8,
  LoanAmount = 8,
}
