import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { getBorrowersSuccess, getBorrowersFailed, GetBorrowersAction, GetBorrowersSuccessData } from './ActionCreator';
import { BORROWERS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/BorrowersDashboard/pagination';

const GetBorrowersMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<GetBorrowersSuccessData, GetBorrowersAction, ReduxState>(
    LoanOriginationSystemBorrowersActionTypes.GetBorrowers,
    getBorrowersSuccess,
    getBorrowersFailed,
    async (action) => {
      const { filters, sortingType, abortSignal } = action.payload;
      const {
        searchInputValue,
        isCompany,
        isPerson,
        dueUpdatedDateRange,
        dueCreatedDateRange,
        selectedMembers,
      } = filters;

      const response = await api.getBorrowers(
        {
          search: searchInputValue,
          offset: 0,
          count: BORROWERS_PER_PAGE_DEFAULT,
          isCompany,
          isPerson,
          dueUpdatedDateRange,
          dueCreatedDateRange,
          members: selectedMembers,
        },
        sortingType,
        abortSignal,
      );

      return {
        ...response,
        searchInputValue,
      };
    },
  );

export default GetBorrowersMiddleware;
