import formatValueByDataType from 'utils/formatValueByDataType';
import { getVariableAccessPermissions } from 'Variables/utils';
import { StandardVariables } from 'Variables/VariablesTypes';
import { PermissionGroupId } from 'PermissionGroups/Types';


const getSearchMatchingField = (
  getEntityFieldValue: (path: string) => string,
  standardVariables: StandardVariables | null,
  userPermissionGroupId: PermissionGroupId,
) => (path: string): { name: string; value: string } | null => {
  // eslint-disable-next-line react/destructuring-assignment
  const fieldName = path.split('.').pop()!;
  const variable = standardVariables?.[fieldName];

  if (!variable) {
    return null;
  }

  return {
    name: variable.displayName,
    value: formatValueByDataType(getEntityFieldValue(path), variable, {
      accessPermissions: getVariableAccessPermissions(variable, userPermissionGroupId),
    }),
  };
};

export default getSearchMatchingField;
