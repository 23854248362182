import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'types/redux';
import { useQueryParams } from 'hooks/useQueryParam';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView, useOpenContextualView } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import {
  ProductSettings,
  ProductStatus,
  UpdateProductParams,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import ConfirmPopup from 'components/ConfirmPopup';
import { RuleBuilderCoreData } from 'RuleCreate/Types';
import { deleteApplicationTab } from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import {
  changeProductStatus,
  duplicateProduct,
  setProductToDuplicate,
  setStatusChangeData,
  updateProduct,
  UpdateProductActionOrigin,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { setTabToDelete } from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationSetup/ActionCreator';
import {
  closeCreateVariablePopup,
  closeDeclineReasonsPopup,
  openCreateVariablePopup,
  setApplicationStatusToDelete,
  setDeleteStatusRuleData,
  setStatusRuleToEdit,
  setStatusToAddRule,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationsWorkflowSetup/ActionCreator';
import {
  setFormPageToDelete,
  toggleFormSettingsPopupVisibility,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationFormConfiguration/ActionCreator';
import EditDeclineReasonsPopup from 'components/LoanOriginationSystem/ProductConfiguration/EditDeclineReasonsPopup';
import ProductConfiguration from 'components/LoanOriginationSystem/ProductConfiguration';
import EditStatusRulePopup from 'components/LoanOriginationSystem/ProductConfiguration/EditStatusRulePopup';
import CreateVariablePopup from 'components/CreateVariablePopup';
import LeftSidePane from 'components/LoanOriginationSystem/ProductConfiguration/LeftSidePane';
import EditProduct from 'components/LoanOriginationSystem/EditProduct';
import FormSettingsPopup
  from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/FormSettingsPopup';
import ChangeProductStatusConfirmPopup
  from 'components/LoanOriginationSystem/ProductsDashboard/ChangeProductStatusConfirmPopup';
import DuplicateProductConfirmationPopup
  from 'components/LoanOriginationSystem/ProductsDashboard/DuplicateProductConfirmationPopup';
import CreateOrRenameDocumentFolderPopUp from 'components/CreateOrRenameDocumentFolderPopUp';
import DeleteDocumentFolderPopUp from 'components/DeleteDocumentFolderPopUp';
import { updateDeclineReasons } from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import {
  createApplicationStatusRule,
  deleteApplicationStatus,
  deleteApplicationStatusRule,
  updateApplicationStatusRule,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import {
  closeDocumentFolderPopup,
  setFolderToUpdate,
  deleteDocumentFolder,
  createDocumentFolder,
  updateDocumentFolder,
} from 'ProductConfigurationDocumentFolders/ActionCreator';
import { VariableClientType } from 'Variables/VariablesTypes';
import { getVariableType } from 'Variables/utils';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { getApplicationFormPageLabel } from 'ApplicationFormPage';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import { ProductConfigurationDocumentFoldersPopUpType } from 'ProductConfigurationDocumentFolders/Types';
import { getFolderToUpdate, getParentFolderId } from 'ProductConfigurationDocumentFolders/Selectors';
import { toggleLeftNavExpand } from 'MainLayout/leftNavReducer';
import styles from './Product.module.scss';

const leftNav = makeLeftNavigation(NavigationLinkId.Products, ApplicationSectionName.LoanOriginationSystem);

const EDIT_PRODUCT_CONTEXTUAL_VIEW_QUERY_ATTRIBUTE = 'edit';

const Product = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const history = useHistory();
  const params = useQueryParams();
  const closeContextualView = useCloseContextualView(EDIT_PRODUCT_CONTEXTUAL_VIEW_QUERY_ATTRIBUTE);
  const openContextualView = useOpenContextualView(EDIT_PRODUCT_CONTEXTUAL_VIEW_QUERY_ATTRIBUTE);
  const { productId, step } = useParams<{ productId: string, step: string }>();

  const {
    selectedProduct,
    isDeclineReasonsPopupOpen,
    tabToDelete,
    isTabDeletingInProgress,
    applicationStatusToDelete,
    isApplicationStatusDeletingInProgress,
    isDeclineReasonsUpdatingInProgress,
    statusToAddRule,
    statusRuleToEdit,
    isStatusRuleCreatingInProgress,
    isStatusDeletingInProgress,
    isStatusRuleUpdatingInProgress,
    deleteStatusRuleData,
    isCreateVariablePopupOpen,
    isVariableCreatingInProgress,
    isFormSettingsPopupOpen,
    isFormSettingUpdateInProgress,
    formPageToDelete,
    isFormPageDeleteInProgress,
    statusChangeData,
    productToDuplicate,
    isDuplicatingInProgress,
    isStatusUpdatingInProgress,
  } = useSelector((state: ReduxState) => ({
    isTabDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationSetup.isTabDeletingInProgress,
    selectedProduct: state.loanOriginationSystemProducts.productConfiguration.product,
    tabToDelete: state.loanOriginationSystemProducts.productConfiguration.applicationSetup.tabToDelete,
    applicationStatusToDelete:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.applicationStatusToDelete,
    isApplicationStatusDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusDeletingInProgress,
    isDeclineReasonsPopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isDeclineReasonsPopupOpen,
    isDeclineReasonsUpdatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup
        .isDeclineReasonsUpdatingInProgress,
    statusToAddRule: state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.statusToAddRule,
    statusRuleToEdit:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.statusRuleToEdit,
    isStatusRuleCreatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusRuleCreatingInProgress,
    isStatusDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusDeletingInProgress,
    isStatusRuleUpdatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusRuleUpdatingInProgress,
    deleteStatusRuleData:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.deleteStatusRuleData,
    isCreateVariablePopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isCreateVariablePopupOpen,
    isVariableCreatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isVariableCreatingInProgress,
    isFormSettingsPopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.isFormSettingsPopupOpen,
    isFormSettingUpdateInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration
        .isFormSettingUpdateInProgress,
    formPageToDelete:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.formPageToDelete,
    isFormPageDeleteInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.isFormPageDeleteInProgress,
    statusChangeData: state.loanOriginationSystemProducts.statusChangeData,
    productToDuplicate: state.loanOriginationSystemProducts.productToDuplicate,
    isDuplicatingInProgress: state.loanOriginationSystemProducts.isDuplicatingInProgress,
    isStatusUpdatingInProgress: state.loanOriginationSystemProducts.isStatusUpdatingInProgress,
  }));
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const [isProductUpdateInProgress, setIsProductUpdateInProgress] = useState(false);
  const {
    isDocumentFoldersPopupOpen,
    isDocumentFoldersUpdatePending,
    documentFoldersPopupType,
    folderToUpdate,
    parentFolderId,
  } = useSelector((state: ReduxState) => ({
    isDocumentFoldersPopupOpen: state.productConfigurationDocumentFolders.isPopupOpen,
    isDocumentFoldersUpdatePending: state.productConfigurationDocumentFolders.isUpdatePending,
    documentFoldersPopupType: state.productConfigurationDocumentFolders.popUpType,
    folderToUpdate: getFolderToUpdate(state),
    parentFolderId: getParentFolderId(state),
  }));

  useEffect(() => {
    dispatch(toggleLeftNavExpand(false));
  }, []);

  const handleStepChange = (nextStep: ProductConfigurationStep) => {
    history.push(`/los/configuration/products/${productId}/${nextStep}`);
  };

  const handleConfirmDeleteApplicationTabPopupClose = () => dispatch(setTabToDelete(null));
  const handleConfirmDeleteApplicationStatusPopupClose = () => dispatch(setApplicationStatusToDelete(null));
  const handleEditDeclineReasonsPopupClose = () => dispatch(closeDeclineReasonsPopup());
  const handleAddStatusRulePopupClose = () => dispatch(setStatusToAddRule(null));
  const handleEditStatusRulePopupClose = () => dispatch(setStatusRuleToEdit(null));
  const handleConfirmDeleteApplicationStatusRulePopupClose = () => dispatch(setDeleteStatusRuleData(null));
  const handleConfirmProductDuplicatePopupClose = () => dispatch(setProductToDuplicate(null));
  const handleConfirmChangeProductStatusPopupClose = () => dispatch(setStatusChangeData(null));
  const handleDocumentFoldersPopUpClose = () =>
    batch(() => {
      dispatch(closeDocumentFolderPopup());
      dispatch(setFolderToUpdate(null));
    });

  const handleConfirmDeleteApplicationTab = () => {
    if (!tabToDelete || !selectedProduct) {
      return;
    }

    dispatch(deleteApplicationTab(tabToDelete.id));
  };

  const handleConfirmDeleteApplicationStatus = () => {
    if (!applicationStatusToDelete || !selectedProduct) {
      return;
    }

    dispatch(deleteApplicationStatus(applicationStatusToDelete.id));
  };

  const handleUpdateDeclineReasons = (newDeclineReasons: string[]) => {
    if (!selectedProduct) {
      return;
    }

    dispatch(updateDeclineReasons(selectedProduct.id, newDeclineReasons));
  };

  const handleCreateApplicationStatusRule = (multipleRules: RuleBuilderCoreData[]) => {
    if (!statusToAddRule) {
      return;
    }

    dispatch(createApplicationStatusRule(statusToAddRule.id, multipleRules));
  };

  const handleConfirmDeleteApplicationStatusRule = () => {
    if (!deleteStatusRuleData) {
      return;
    }

    dispatch(deleteApplicationStatusRule(deleteStatusRuleData.statusId, deleteStatusRuleData.ruleId));
  };

  const handleEditApplicationStatusRule = (multipleRules: RuleBuilderCoreData[]) => {
    if (!statusRuleToEdit) {
      return;
    }

    dispatch(updateApplicationStatusRule(statusRuleToEdit.id, multipleRules));
  };

  const handleCreateVariable = (variable: VariableClientType) => {
    const simpleVariableData = getVariableType(variable);
    const formattedVariable = { ...variable, ...simpleVariableData };

    const action = setActionOrigin(
      createVariableRequest(formattedVariable),
      CreateVariableActionOrigin.ApplicationStatusRule,
    );

    dispatch(action);
  };

  const handleOpenVariableCreation = () => dispatch(openCreateVariablePopup());
  const handleCloseVariableCreation = () => dispatch(closeCreateVariablePopup());

  const handleProductFormSettingsSave = (settings: Partial<ProductSettings>) => {
    if (!selectedProduct) {
      return;
    }

    const action = setActionOrigin(
      updateProduct(selectedProduct.id, { settings }),
      UpdateProductActionOrigin.FormSettings,
    );

    dispatch(action);
  };

  const handleConfirmDeleteApplicationFormPage = () => {
    if (!formPageToDelete || !selectedProduct) {
      return;
    }

    const newApplicationFormPages = selectedProduct.settings.applicationFormPages.filter(
      (formPage) => formPageToDelete !== formPage,
    );

    dispatch(
      setActionOrigin(
        updateProduct(selectedProduct.id, { settings: { applicationFormPages: newApplicationFormPages } }),
        UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage,
      ),
    );
  };

  const handleFormSettingsPopupClose = () => dispatch(toggleFormSettingsPopupVisibility());
  const handleConfirmDeleteApplicationFormPageClose = () => dispatch(setFormPageToDelete(null));

  const handleConfirmChangeProductStatus = () => {
    if (!statusChangeData) {
      return;
    }

    dispatch(changeProductStatus(statusChangeData.product.id, statusChangeData.status));
  };

  const handleConfirmProductDuplicating = () => {
    if (!productToDuplicate) {
      return;
    }

    dispatch(duplicateProduct(productToDuplicate.id));
  };

  const handleCloseProductEditor = () => {
    history.push('/los/configuration/products');
  };

  const handleProductStatusChange = (newStatus: ProductStatus) => {
    if (!selectedProduct) {
      return;
    }

    dispatch(
      setStatusChangeData({
        product: selectedProduct,
        status: newStatus,
      }),
    );
  };

  const handleProductEdit = async (data: Partial<UpdateProductParams>) => {
    if (!selectedProduct) {
      return;
    }

    try {
      setIsProductUpdateInProgress(true);

      await dispatchRoutine(updateProduct(selectedProduct.id, data));

      closeContextualView();
    } finally {
      setIsProductUpdateInProgress(false);
    }
  };

  const handleEditBasicInformation = () => {
    openContextualView();
  };

  const handleProductDuplicate = () => {
    if (!selectedProduct) {
      return;
    }

    dispatch(setProductToDuplicate(selectedProduct));
  };

  const handleCreateDocumentFolder = async (name: string) => {
    await dispatch(createDocumentFolder({ name, productId, parentId: parentFolderId }));

    handleDocumentFoldersPopUpClose();
  };

  const handleRenameDocumentFolder = async (name: string) => {
    await dispatch(updateDocumentFolder({ folderId: folderToUpdate!.id, folder: { name } }));

    handleDocumentFoldersPopUpClose();
  };

  const handleDeleteDocumentFolder = async () => {
    await dispatch(deleteDocumentFolder(folderToUpdate!.id));

    handleDocumentFoldersPopUpClose();
  };

  const renderOverlay = () => {
    if (tabToDelete) {
      const title = `Delete ${tabToDelete.name} Tab`;
      const message = `Are you sure you want to delete the ${tabToDelete.name} Tab?`;

      return (
        <ConfirmPopup
          title={title}
          message={message}
          confirmText="Yes, Delete Tab"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationTabPopupClose}
          onConfirmClick={handleConfirmDeleteApplicationTab}
          loading={isTabDeletingInProgress}
        />
      );
    }

    if (applicationStatusToDelete) {
      return (
        <ConfirmPopup
          title="Delete Status"
          message="Are you sure you want to delete this status?"
          confirmText="Delete Status"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationStatusPopupClose}
          onConfirmClick={handleConfirmDeleteApplicationStatus}
          loading={isApplicationStatusDeletingInProgress}
        />
      );
    }

    if (isDeclineReasonsPopupOpen && selectedProduct) {
      return (
        <EditDeclineReasonsPopup
          declineReasons={declineReasonsByProductId[selectedProduct.id] || []}
          onPopupClose={handleEditDeclineReasonsPopupClose}
          onDeclineReasonsUpdate={handleUpdateDeclineReasons}
          isUpdatingInProgress={isDeclineReasonsUpdatingInProgress}
        />
      );
    }

    if (isCreateVariablePopupOpen) {
      return (
        <CreateVariablePopup
          onClose={handleCloseVariableCreation}
          onSave={handleCreateVariable}
          isSavingInProgress={isVariableCreatingInProgress}
        />
      );
    }

    if (statusToAddRule) {
      return (
        <EditStatusRulePopup
          onPopupClose={handleAddStatusRulePopupClose}
          onRuleSave={handleCreateApplicationStatusRule}
          openVariableCreation={handleOpenVariableCreation}
          isSaving={isStatusRuleCreatingInProgress}
        />
      );
    }

    if (statusRuleToEdit) {
      return (
        <EditStatusRulePopup
          onPopupClose={handleEditStatusRulePopupClose}
          onRuleSave={handleEditApplicationStatusRule}
          openVariableCreation={handleOpenVariableCreation}
          rule={statusRuleToEdit}
          isSaving={isStatusRuleUpdatingInProgress}
        />
      );
    }

    if (deleteStatusRuleData) {
      return (
        <ConfirmPopup
          title="Delete Status Rule"
          message="Are you sure you want to delete this status rule?"
          confirmText="Delete Rule"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationStatusRulePopupClose}
          onConfirmClick={handleConfirmDeleteApplicationStatusRule}
          loading={isStatusDeletingInProgress}
        />
      );
    }

    if (isFormSettingsPopupOpen && selectedProduct) {
      return (
        <FormSettingsPopup
          isSavingInProgress={isFormSettingUpdateInProgress}
          product={selectedProduct}
          onSave={handleProductFormSettingsSave}
          onClose={handleFormSettingsPopupClose}
        />
      );
    }

    if (formPageToDelete) {
      const label = getApplicationFormPageLabel(formPageToDelete);

      return (
        <ConfirmPopup
          title={`Delete ${label} Page`}
          message={`Are you sure you want to delete the ${label} page?`}
          confirmText="Yes, Delete Page"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationFormPageClose}
          onConfirmClick={handleConfirmDeleteApplicationFormPage}
          loading={isFormPageDeleteInProgress}
        />
      );
    }

    if (productToDuplicate) {
      return (
        <DuplicateProductConfirmationPopup
          productName={productToDuplicate.name}
          onPopupClose={handleConfirmProductDuplicatePopupClose}
          onConfirmClick={handleConfirmProductDuplicating}
          loading={isDuplicatingInProgress}
        />
      );
    }

    if (statusChangeData) {
      const { status, product } = statusChangeData;

      return (
        <ChangeProductStatusConfirmPopup
          product={product}
          status={status}
          onPopupClose={handleConfirmChangeProductStatusPopupClose}
          onConfirmClick={handleConfirmChangeProductStatus}
          loading={isStatusUpdatingInProgress}
        />
      );
    }

    if (isDocumentFoldersPopupOpen) {
      return documentFoldersPopupType === ProductConfigurationDocumentFoldersPopUpType.Delete ? (
        <DeleteDocumentFolderPopUp
          closePopUp={handleDocumentFoldersPopUpClose}
          deleteDocumentFolder={handleDeleteDocumentFolder}
          isLoading={isDocumentFoldersUpdatePending}
        />
      ) : (
        <CreateOrRenameDocumentFolderPopUp
          closePopUp={handleDocumentFoldersPopUpClose}
          popUpType={documentFoldersPopupType}
          create={handleCreateDocumentFolder}
          rename={handleRenameDocumentFolder}
          folder={folderToUpdate}
          isLoading={isDocumentFoldersUpdatePending}
        />
      );
    }

    return null;
  };

  if (!Object.values(ProductConfigurationStep).includes(step as ProductConfigurationStep)) {
    return (
      <Redirect
        to={`/los/configuration/products/${productId}/${ProductConfigurationStep.ApplicationFormConfiguration}`}
      />
    );
  }

  const renderContextualView = () => {
    if (params.has('edit') && selectedProduct) {
      return (
        <EditProduct
          product={selectedProduct}
          onClose={closeContextualView}
          onEdit={handleProductEdit}
          isUpdateInProgress={isProductUpdateInProgress}
        />
      );
    }

    return null;
  };

  const renderBeforeContentContainer = () => (
    <LeftSidePane
      className={styles.leftSidePane}
      product={selectedProduct}
      selectedStep={step as ProductConfigurationStep}
      onStepChange={handleStepChange}
      onProductStatusChange={handleProductStatusChange}
      onEditBasicInformation={handleEditBasicInformation}
      onDuplicate={handleProductDuplicate}
      onCloseProductEditor={handleCloseProductEditor}
    />
  );

  return (
    <MainLayout
      beforeContentContainer={renderBeforeContentContainer()}
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      contentContainerClassName={styles.contentContainer}
    >
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <ProductConfiguration productId={productId} step={step as ProductConfigurationStep} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Product;
