import React, { FC, useCallback, useMemo } from 'react';
import jexlFunctions from '@digifi/jexl-functions';
import BaseCodeEditor, { BaseCodeEditorProps } from 'components/CodeEditor/BaseCodeEditor/BaseCodeEditor';
import useFormulaWorker from 'hooks/useFormulaWorker';

export interface JexlCodeEditorProps extends Omit<BaseCodeEditorProps, 'validateCodeSyntax'> {}

const JexlCodeEditor: FC<JexlCodeEditorProps> = ({
  functionsSuggestions: baseFunctionSuggestions,
  ...restProps
}) => {
  const formulaWorker = useFormulaWorker();

  const functionSuggestions = useMemo(() => {
    return [
      ...Object.keys(jexlFunctions),
      ...baseFunctionSuggestions || [],
    ]
  }, [baseFunctionSuggestions]);

  const validateCodeSyntax = useCallback(
    async (code: string) => {
      if (!formulaWorker) {
        return [];
      }

      return formulaWorker.validateFormulaSyntax(code);
    },
    [formulaWorker],
  );

  return <BaseCodeEditor
    {...restProps}
    functionsSuggestions={functionSuggestions}
    validateCodeSyntax={validateCodeSyntax}
  />;
};

export default JexlCodeEditor;
