import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { ApplicationsSortingType } from 'api/LoanOriginationSystem/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

export const BORROWER_APPLICATIONS_PER_PAGE_DEFAULT = 10;

export interface BorrowerApplicationsPaginationParams {
  sortingType: ApplicationsSortingType;
  borrowerId: string;
}

const borrowerApplicationsPagination = Pagination<ReduxState, SimplifiedApplication, BorrowerApplicationsPaginationParams>(
  'loanOriginationSystemBorrowerDetails',
  BORROWER_APPLICATIONS_PER_PAGE_DEFAULT,
  () => ['applications'],
);

export default borrowerApplicationsPagination;
