import React, { FC, useRef, useState } from 'react';
import styles from 'components/DecisionResultOverview/DecisionResultOverview.module.scss';
import { VectorBottomImage } from 'static/images';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import clsx from 'clsx';
import Table from 'components/Table';
import TableBody from 'components/Table/TableBody';
import OverflowedText from 'components/OverflowedText';
import { DecisionResultType, ExtendedDecision } from 'api/DecisionEngine/DecisionApi';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import formatValueByDataType from 'utils/formatValueByDataType';
import { isEmpty } from 'lodash';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import DecisionResultInfo from 'components/DecisionResultOverview/Overview/DecisionResultInfo';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import maskNumberValue from 'utils/masks/maskNumberValue';

export const SYSTEM_VARIABLE_NAMES = ['selected_strategy', 'case_name', 'strategy_status', 'requirements'];

export interface DecisionResultOverviewProps {
  decisionData: ExtendedDecision;
  variablesContainerClassName?: string;
}

export interface ModuleNameStatusMap {
  name: string;
  status: ModuleStatus;
}

export enum ModuleStatus {
  Passed = 'Passed',
  Failed = 'Failed',
  Error = 'Error',
  Completed = 'Completed',
  NotRun = 'Not Run',
}

export type VariablesType = 'inputs' | 'outputs';

const statusStyles = new Map([
  [ModuleStatus.Passed, styles.statusPassed],
  [ModuleStatus.Failed, styles.statusFailed],
  [ModuleStatus.Error, styles.statusError],
  [ModuleStatus.Completed, styles.statusCompleted],
  [ModuleStatus.NotRun, styles.statusNotRun],
]);

const getDecisionResultTypeColor = (resultType: DecisionResultType) => {
  switch (resultType) {
    case DecisionResultType.Passed: {
      return styles.statusPassed;
    }
    case DecisionResultType.Failed: {
      return styles.statusFailed;
    }
    case DecisionResultType.Error: {
      return styles.statusError;
    }
    default: {
      throw new Error(`Unknown decision result: ${resultType}.`);
    }
  }
}

const getModuleStateColor = (status: ModuleStatus) => {
  return statusStyles.get(status);
};

const DecisionResultOverview: FC<DecisionResultOverviewProps> = ({
  decisionData,
  variablesContainerClassName,
}) => {
  const resultTable = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [resultTableHeight, setResultTableHeight] = useState(0);

  const handleChangeTableOpenState = () => {
    setIsOpen(!isOpen);
    if (resultTable.current) {
      const { children: containerChildren } = resultTable.current!;
      const { offsetHeight } = containerChildren[0] as HTMLElement;
      setResultTableHeight(offsetHeight);
    }
  };

  const getVariablesWithFormattedValue = (variables: Record<string, VariableValue>) => {
    const variableSystemNames = Object.keys(variables).filter((name) => !SYSTEM_VARIABLE_NAMES.includes(name));
    return variableSystemNames.reduce((result, systemName) => {
      const variableConfig = decisionData!.variablesMap[systemName];
      const value = variables[systemName];

      return {
        ...result,
        [variableConfig?.displayName || systemName]: variableConfig
          ? formatValueByDataType(value, variableConfig as any)
          : value,
      };
    }, {});
  };

  const getModuleStatus = (moduleName: string) => {
    const individualModule = decisionData.compiledModules
      .find((module) => module.display_name === moduleName);
    const individualModuleIndex = decisionData.modules
      .map((module) => module.display_name)
      .indexOf(moduleName);
    if (decisionData.resultType === DecisionResultType.Passed) {
      return individualModule!.type === ModuleType.RequirementsRules ? ModuleStatus.Passed : ModuleStatus.Completed;
    }
    if (individualModuleIndex !== -1 && individualModuleIndex === decisionData.modules.length - 1) {
      return decisionData.errorMessages?.length ? ModuleStatus.Error : ModuleStatus.Failed;
    }
    if (individualModuleIndex >= 0 && individualModuleIndex < decisionData.modules.length - 1) {
      return individualModule!.type === ModuleType.RequirementsRules ? ModuleStatus.Passed : ModuleStatus.Completed;
    }
    return ModuleStatus.NotRun;
  };

  const getModuleListWithStatus = () => {
    const moduleNames = decisionData.compiledModules.map((module) => module.display_name);
    return moduleNames.reduce((acc, moduleName) => {
      acc.push({
        name: moduleName,
        status: getModuleStatus(moduleName),
      });
      return acc;
    }, [] as ModuleNameStatusMap[]);
  };

  const renderModuleResults = () => {
    const moduleListWithStatus = getModuleListWithStatus();
    return moduleListWithStatus.map(({ name, status }, index) => (
      <div className={clsx(styles.field, index === 0 && styles.firstModuleResult)} key={`${name}_${index}`}>
        <div className={styles.fieldTitle}>
          <OverflowedText>{name}</OverflowedText>
        </div>
        <div className={clsx(styles.fieldValue, styles.statusLabelContainer)}>
          <div className={clsx(styles.moduleStatus, getModuleStateColor(status))}>
            {status}
          </div>
        </div>
      </div>
    ));
  };

  const renderVariables = (variables: Record<string, VariableValue> = {}) => {
    const variablesWithFormattedValues = getVariablesWithFormattedValue(variables);
    return Object.keys(variablesWithFormattedValues).map((key) => (
      <div className={styles.field} key={key}>
        <div className={styles.fieldTitle}>
          <OverflowedText>{key}</OverflowedText>
        </div>
        <div className={styles.fieldValue}>
          <OverflowedText>{`${variablesWithFormattedValues[key]}`}</OverflowedText>
        </div>
      </div>
    ));
  };

  const renderDeclineReasonOrMessage = () => {
    if (decisionData.declineReasons.length) {
      return (
        <>
          <div className={styles.field}>
            <div className={styles.fieldTitle}>
              <OverflowedText>Decline Reasons</OverflowedText>
            </div>
            <div className={styles.fieldValue}>
              <OverflowedText className={styles.withLeftMargin}>
                {decisionData.declineReasons.join(', ')}
              </OverflowedText>
            </div>
          </div>
        </>
      );
    }

    if (decisionData.errorMessages?.length) {
      return (
        <div className={styles.field}>
          <div className={styles.fieldTitle}>
            <OverflowedText>Message</OverflowedText>
          </div>
          <div className={styles.fieldValue}>
            <OverflowedText className={styles.withLeftMargin}>{decisionData.errorMessages.join(', ')}</OverflowedText>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderNoVariables = (variableType: 'input' | 'output') => {
    return (
      <div className={styles.field}>
        <div className={styles.fieldTitle}>
          No {variableType} variables
        </div>
      </div>
    );
  }

  const getExecutionTime = ({ executionTime, decisionExecutionTime, resultsInGroup }: ExtendedDecision) => {
    const timeToDisplay = resultsInGroup === 1 ? decisionExecutionTime : executionTime;
    return timeToDisplay ? `${maskNumberValue(timeToDisplay)} ms` : null;
  }

  const renderDecisionResultInfo = () => {
    return (
      <DecisionResultInfo
        tableData={[
          [{
            name: 'Strategy Name',
            value: `${decisionData.strategyName}${decisionData.strategyVersion ? ` (v${decisionData.strategyVersion})` : ''}`,
            link: `/decision/strategies/${decisionData.strategyId}/overview`,
          }, {
            name: 'Strategy Status',
            value: getNormalizedStatus(decisionData.strategyStatus),
            tooltip: 'The strategy’s status when the decision was made',
          }],
          [{
            name: 'Application Name',
            value: decisionData.applicationName,
            link: decisionData.applicationName && decisionData.applicationDisplayId
              ? `/los/applications/${decisionData.applicationDisplayId}`
              : null,
          }, {
            name: 'Decision Group',
            value: decisionData.resultsInGroup > 1 ? decisionData.decisionName : null,
            link: decisionData.resultsInGroup > 1 ? `/decisions?decision-id=${decisionData.decisionId}` : null,
          }],
          [{
            name: 'Decision Source',
            value: decisionData.source === 'Api' ? decisionData.source.toUpperCase() : decisionData.source,
          }, {
            name: 'Execution Time',
            value: getExecutionTime(decisionData),
          }],
        ]}
      />
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.caseInfo}>
        <div className={styles.caseUpdateInfo}>
          <WithSystemApiUserAvatar
            user={decisionData.createdBy}
            size="tiny"
          />
          <p>
            Created <DateTime time={decisionData.createdAt} format={DateTimeFormat.Long} />
          </p>
           <div className={clsx(styles.moduleStatus, getDecisionResultTypeColor(decisionData.resultType))}>
             {decisionData.resultType}
           </div>
        </div>
      </div>
      <div className={styles.caseContent}>
        {renderDecisionResultInfo()}

        <div className={styles.decisionResultInfo}>
          <h4 className={styles.title}>Processing Details</h4>
          <div className={styles.processingDetailsContainer}>
            <div className={styles.field}>
              <div className={styles.fieldTitle}>
                <OverflowedText>Decision Result</OverflowedText>
              </div>
              <div className={clsx(styles.fieldValue, styles.statusLabelContainer)}>
                <div className={clsx(styles.moduleStatus, getDecisionResultTypeColor(decisionData.resultType))}>
                  {decisionData.resultType}
                </div>
              </div>
            </div>
            {renderDeclineReasonOrMessage()}
            <div
              className={styles.moduleList}
              ref={resultTable}
              style={isOpen ? { maxHeight: resultTableHeight } : {}}
            >
              <Table>
                <TableBody>{renderModuleResults()}</TableBody>
              </Table>
            </div>
            <div className={styles.field}>
              <div className={styles.expandButton} onClick={handleChangeTableOpenState}>
                <p>{isOpen ? 'Hide Module Results' : 'Show Module Results'}</p>
                <button type="button" className={isOpen ? '' : styles.isOpen}>
                  <VectorBottomImage />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx(variablesContainerClassName, styles.variableContainer)}>
          <div>
            <h4 className={styles.title}>Input Variables</h4>
             <div className={styles.fields}>
               {isEmpty(decisionData.inputs) ? renderNoVariables('input') : renderVariables(decisionData.inputs)}
             </div>
          </div>
          <div>
            <h4 className={styles.title}>Output Variables</h4>
             <div className={styles.fields}>
               {isEmpty(decisionData.outputs) ? renderNoVariables('output') : renderVariables(decisionData.outputs)}
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DecisionResultOverview;
