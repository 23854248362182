import React from 'react';
import clsx from 'clsx';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { SuggestionItem } from 'components/LoanOriginationSystem/CreateApplication/SuggestionsPopper';
import { FormLayoutData, VariableValue } from 'api/Types';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import NewBorrowerForm from './NewBorrowerForm';
import BorrowerIcon from 'components/LoanOriginationSystem/BorrowerIcon';
import UnlockBorrower from 'components/LoanOriginationSystem/UnlockBorrower';
import { formatDisplayTitle, getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { StandardVariables } from 'Variables/VariablesTypes';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import styles from './CreateBorrowerForm.module.scss';

export interface CreateBorrowerFormProps {
  borrowerFormData: FormLayoutData;
  selectedBorrowerType: BorrowerType;
  suggestions: Borrower[];
  selectedBorrower: Borrower | null;
  personProfileCards: BorrowerProfileCard[] | null;
  companyProfileCards: BorrowerProfileCard[] | null;
  standardVariables: StandardVariables | null;
  onLoadSuggestions?: (suggestionFilter: BorrowerSuggestionFilter) => void;
  onUnlockBorrowerClick?: (borrower: Borrower) => void;
  onBorrowerSelect?: (borrower: Borrower | null) => void;
  onChangeBorrowerType: (borrowerType: BorrowerType) => void;
  onEditProfile: (data: FormLayoutData) => Promise<void>;
  availableBorrowerTypes: BorrowerType[];
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const CreateBorrowerForm = ({
  suggestions,
  borrowerFormData,
  selectedBorrower,
  personProfileCards,
  companyProfileCards,
  standardVariables,
  onFieldChange,
  onBorrowerSelect,
  onLoadSuggestions,
  onUnlockBorrowerClick,
  onEditProfile,
  selectedBorrowerType,
  onChangeBorrowerType,
  availableBorrowerTypes,
  renderActions,
}: CreateBorrowerFormProps) => {
  const getCardsByBorrowerType = (borrowerType: BorrowerType) => {
    return borrowerType === BorrowerType.Person ? personProfileCards : companyProfileCards
  };

  const handleSuggestionSelect = (suggestionItem: SuggestionItem) => {
    onBorrowerSelect?.(suggestions.find(({ id }) => id === suggestionItem.id)!);
  };

  const handleRemoveExistingBorrower = () => {
    onBorrowerSelect?.(null);
  };

  const suggestionOptions = suggestions.map(
    (suggestion) =>
      ({
        id: suggestion.id,
        name: getBorrowerFullName(suggestion.type, suggestion.variables),
        phone: suggestion.variables[BorrowerDefaultVariable.PhoneNumber],
        email: suggestion.variables[BorrowerDefaultVariable.Email],
      } as SuggestionItem),
  );

  const renderForm = () => {
    if (selectedBorrower) {
      return (
        <ExistingProfile
          cards={getCardsByBorrowerType(selectedBorrower.type)}
          data={selectedBorrower.variables}
          title={getBorrowerFullName(selectedBorrower.type, selectedBorrower.variables)}
          formatVariableConfigurationDisplayTitle={formatDisplayTitle}
          onCloseButtonClick={handleRemoveExistingBorrower}
          editButtonName="Edit Profile"
          onEditProfileData={onEditProfile}
          titleIcons={<BorrowerIcon borrowerType={selectedBorrower.type} />}
          renderActions={renderActions}
        />
      );
    }

    return (
      <NewBorrowerForm
        standardVariables={standardVariables}
        borrowerData={borrowerFormData}
        onFieldChange={onFieldChange}
        cards={getCardsByBorrowerType(selectedBorrowerType)}
        selectedBorrowerType={selectedBorrowerType}
        suggestions={suggestionOptions}
        onSuggestionSelect={handleSuggestionSelect}
        onLoadSuggestions={onLoadSuggestions}
        onChangeBorrowerType={onChangeBorrowerType}
        availableBorrowerTypes={availableBorrowerTypes}
        renderActions={renderActions}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.form)}>
        {renderForm()}
      </div>
      {selectedBorrower?.locked && (
        <div className={clsx(styles.footer, selectedBorrower?.locked && styles.locked)}>
          {selectedBorrower?.locked && (
            <UnlockBorrower onUnlockBorrower={() => onUnlockBorrowerClick?.(selectedBorrower)} />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateBorrowerForm;
