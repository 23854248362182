import { ReportingDashboardPeriod } from 'LoanOriginationSystemReporting/LoanOriginationSystemReportingStore';
import { orderBy, sum } from 'lodash';
import moment from 'moment';
import splitDateRange, { SplitDateRangePeriod } from 'utils/splitDateRange';

export interface LoanOriginationSystemReportingFilters {
  from: Date;
  to: Date;
  period: ReportingDashboardPeriod;
  organizationMemberId?: string;
  intermediaryId?: string;
  productId?: string;
}

export interface ReportingDashboardChartPoint {
  x: number;
  y: number;
}

export type ReportingDashboardChart = {
  points: ReportingDashboardChartPoint[];
  total: number;
};

export interface GroupInformation {
  startDate: string;
}

export interface GroupCountInformation extends GroupInformation {
  startDate: string;
  count: number;
}

export interface ApplicationGroupCountInformation extends GroupCountInformation {
  loanAmount: number;
  loanAmountAverage: number;
}

export interface CountInformationResult {
  totalCount: number;
  groups: GroupCountInformation[];
}

export interface ApplicationCountInformationResult extends CountInformationResult {
  totalLoanAmount: number;
  totalLoanAmountAverage: number;
  groups: ApplicationGroupCountInformation[];
}

export interface GroupStatusChangeInformation extends GroupInformation {
  closeRate: number;
  rejectionRate: number;
  averageTimeToApprove: number;
  averageTimeToReject: number;
  approvedApplicationsCount: number;
  rejectedApplicationsCount: number;
  totalApplicationsCount: number;
}

export interface StatusChangeInformationResult {
  totalAverageTimeToApprove: number;
  totalAverageTimeToReject: number;
  totalCloseRate: number;
  totalRejectionRate: number;
  groups: GroupStatusChangeInformation[];
}

const parseDateXValue = (date: string) => Number(moment(date));

const getSplitPeriodByReportingPeriod = (period: ReportingDashboardPeriod): SplitDateRangePeriod => {
  switch (period) {
    case 'Annually':
      return 'year';
    case 'Monthly':
      return 'month';
    case 'Daily':
      return 'day';
    default:
      return 'day';
  }
};

const fillZeroDates = (
  points: ReportingDashboardChartPoint[],
  filters: LoanOriginationSystemReportingFilters,
): ReportingDashboardChartPoint[] => {
  const ranges = splitDateRange(filters.from, filters.to, getSplitPeriodByReportingPeriod(filters.period));

  return ranges.map((date) => {
    const x0 = parseDateXValue(date);

    const point = points.find(({ x }) => x === x0);

    return {
      x: x0,
      y: point?.y || 0,
    };
  });
};

export const mapData = (
  total: number,
  groups: GroupInformation[],
  filters: LoanOriginationSystemReportingFilters,
  yKey: string,
) => {
  const mappedGroups = groups.map((group) => ({
    x: parseDateXValue(group.startDate),
    y: group[yKey],
  }));

  return {
    total,
    points: fillZeroDates(mappedGroups, filters),
  };
};

export const mapTotalCount = (
  countInformation: CountInformationResult,
  totalCount: number,
  filters: LoanOriginationSystemReportingFilters,
) => {
  const sortedGroups = orderBy(countInformation.groups, (group) => parseDateXValue(group.startDate));
  const mappedSortedGroups = sortedGroups.map((group) => ({
    x: parseDateXValue(group.startDate),
    y: group.count,
  }));

  const filledGroups = fillZeroDates(mappedSortedGroups, filters);

  filledGroups[0].y += totalCount;

  return {
    total: countInformation.totalCount + totalCount,
    points: filledGroups.map(({ x }, index) => ({
      x,
      y: sum(filledGroups.slice(0, index + 1).map(({ y }) => y)),
    })),
  };
};

export const mapCrossSellingInformation = (
  applicationCountInformation: CountInformationResult,
  borrowerIntermediaryCountInformation: CountInformationResult,
  applicationTotalCount: number,
  totalCount: number,
  filters: LoanOriginationSystemReportingFilters,
) => {
  const applicationTotalCountInfo = mapTotalCount(applicationCountInformation, applicationTotalCount, filters);
  const borrowerIntermediaryTotalCountInfo = mapTotalCount(borrowerIntermediaryCountInformation, totalCount, filters);

  const total =
    applicationTotalCount + applicationCountInformation.totalCount === 0
      ? 0
      : (applicationTotalCount + applicationCountInformation.totalCount) /
        (totalCount + borrowerIntermediaryCountInformation.totalCount);

  const points = applicationTotalCountInfo.points.map((point, index) => {
    const y =
      borrowerIntermediaryTotalCountInfo.points[index].y === 0
        ? 0
        : point.y / borrowerIntermediaryTotalCountInfo.points[index].y;

    return {
      x: point.x,
      y,
    };
  });

  return {
    total,
    points,
  };
};
