import BaseVariableConfigurationsRestApi, {
  BaseCreateVariableConfigurationParams,
  BaseVariableConfiguration,
  BaseVariableConfigurationsApi,
} from './Base/BaseVariableConfigurationsApi';

export interface ApplicationVariableConfiguration extends BaseVariableConfiguration {
  productId: string;
}

export interface CreateApplicationVariableConfigurationParams extends BaseCreateVariableConfigurationParams {
  productId: string;
}

export interface FindApplicationVariableConfigurationParams {
  productId: string;
}

export type ApplicationVariableConfigurationsApi = BaseVariableConfigurationsApi<
  ApplicationVariableConfiguration,
  FindApplicationVariableConfigurationParams,
  CreateApplicationVariableConfigurationParams
>;

export default class ApplicationVariableConfigurationsRestApi extends BaseVariableConfigurationsRestApi<
  ApplicationVariableConfiguration,
  FindApplicationVariableConfigurationParams,
  CreateApplicationVariableConfigurationParams
> implements ApplicationVariableConfigurationsApi {
  protected resourceName = 'application-variable-configurations';

  protected getUrlSearchParamsByFindParams(params: FindApplicationVariableConfigurationParams): URLSearchParams {
    const urlSearchParams = super.getUrlSearchParamsByFindParams(params);

    urlSearchParams.set('productId', params.productId);

    return urlSearchParams;
  }
}
