import React, { FC, useEffect, useState } from 'react';
import {
  AssigneeTeamMembersType,
  Product,
  ProductSettings,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import areArraysEqual from 'utils/areArraysEqual';
import PopUpContent from 'components/PopUps/PopUpContent';
import PopUp from 'components/PopUps/PopUp';
import AutoCompletion from 'components/AutoCompletion';
import StyledSwitch from 'components/StyledSwitch';
import LabelsMultiSelect from 'components/LoanOriginationSystem/LabelsMultiSelect';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import { Option } from 'components/SelectInput/SelectInput';
import CheckboxMultiSelectList from 'components/CheckboxMultiSelectList';
import Button from 'components/Button';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import styles from './FormSettingsPopup.module.scss';
import UserAvatar from 'components/UserAvatar';

export interface FormSettingsPopupProps {
  product: Product;
  isSavingInProgress: boolean;
  onSave: (settings: Partial<ProductSettings>) => void;
  onClose: () => void;
}

const ASSIGNEE_TEAM_MEMBERS_TYPE_OPTIONS = [
  {
    value: AssigneeTeamMembersType.OnCreate,
    name: 'Assign the team member that created the new application',
  },
  {
    value: AssigneeTeamMembersType.RoundRobin,
    name: 'Assign a team member based on round-robin selection',
  },
  {
    value: AssigneeTeamMembersType.SpecificTeamMembers,
    name: 'Assign a specific team member to new applications',
  },
  {
    value: '',
    name: 'Do not assign a team member to new applications',
  },
];

const FormSettingsPopup: FC<FormSettingsPopupProps> = ({ product, onClose, onSave, isSavingInProgress }) => {
  const [assigneeTeamMembersType, setAssigneeTeamMembersType] = useState(product.settings.assigneeTeamMembersType);
  const [teamMembersToRoundRobin, setTeamMembersToRoundRobin] = useState(product.settings.teamMembersToRoundRobin);
  const [autoAssignLabels, setAutoAssignLabels] = useState(product.settings.autoAssignLabels);
  const [lockBorrower, setLockBorrower] = useState(product.settings.lockBorrowerOnApplicationCreation);
  const members = useOrganizationMembers();

  const renderUserAvatar = (member: UserInfo, userAvatarClassName?: string) => (
    <UserAvatar
      className={userAvatarClassName}
      size="small"
      firstName={member.firstName}
      lastName={member.lastName}
      imageId={member.imageId}
    />
  );

  const [membersProps, selectedMembers, setSelectedMembers] = useAutoCompletionMultiSelectProps(
    members,
    members.filter(({ id }) => product.settings.teamMembersToAssign.includes(id)),
    (member) => member.id,
    (member) => `${member.firstName} ${member.lastName}`,
    renderUserAvatar,
    renderUserAvatar,
  );

  useEffect(() => {
    setAutoAssignLabels(product.settings.autoAssignLabels);
    setTeamMembersToRoundRobin(product.settings.teamMembersToRoundRobin);
    setLockBorrower(product.settings.lockBorrowerOnApplicationCreation);
    setAssigneeTeamMembersType(product.settings.assigneeTeamMembersType);
    setSelectedMembers(members.filter(({ id }) => product.settings.teamMembersToAssign.includes(id)));
  }, [product]);

  useEffect(() => {
    if (!members.length) {
      return;
    }

    if (product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.RoundRobin) {
      return;
    }

    setTeamMembersToRoundRobin(members.map((member) => member.id));
  }, [members, product]);

  useEffect(() => {
    setSelectedMembers(members.filter(({ id }) => product.settings.teamMembersToAssign.includes(id)));
  }, [members]);

  const handleAssigneeTeamMembersTypeChange = ({ value }: Option) => {
    setAssigneeTeamMembersType(value ? (value as AssigneeTeamMembersType) : null);
  };

  const handleTeamMemberToRoundRobinChange = (teamMemberIdToChange: string) => {
    const newTeamMembersToRoundRobin = teamMembersToRoundRobin.includes(teamMemberIdToChange)
      ? teamMembersToRoundRobin.filter((teamMemberId) => teamMemberId !== teamMemberIdToChange)
      : [...teamMembersToRoundRobin, teamMemberIdToChange];

    setTeamMembersToRoundRobin(newTeamMembersToRoundRobin);
  };

  const handleLabelSelect = (labelId: string) => {
    setAutoAssignLabels([...autoAssignLabels, labelId]);
  };

  const handleLabelDeselect = (deselectLabelId: string) => {
    setAutoAssignLabels(autoAssignLabels.filter((labelId) => deselectLabelId !== labelId));
  };

  const getNewTeamMembersToAssign = () => {
    return assigneeTeamMembersType !== AssigneeTeamMembersType.SpecificTeamMembers
      ? []
      : selectedMembers.map(({ id }) => id);
  };

  const getNewTeamMembersToRoundRobin = () => {
    return assigneeTeamMembersType !== AssigneeTeamMembersType.RoundRobin ? [] : teamMembersToRoundRobin;
  };

  const handleSave = () => {
    const newTeamMembersToAssign = getNewTeamMembersToAssign();
    const newTeamMembersToRoundRobin = getNewTeamMembersToRoundRobin();

    onSave({
      ...(lockBorrower !== product.settings.lockBorrowerOnApplicationCreation
        ? { lockBorrowerOnApplicationCreation: lockBorrower }
        : {}),
      ...(assigneeTeamMembersType !== product.settings.assigneeTeamMembersType ? { assigneeTeamMembersType } : {}),
      ...(!areArraysEqual(autoAssignLabels, product.settings.autoAssignLabels) ? { autoAssignLabels } : {}),
      ...(!areArraysEqual(newTeamMembersToAssign, product.settings.teamMembersToAssign)
        ? { teamMembersToAssign: newTeamMembersToAssign }
        : {}),
      ...(!areArraysEqual(newTeamMembersToRoundRobin, product.settings.teamMembersToRoundRobin)
        ? { teamMembersToRoundRobin: newTeamMembersToRoundRobin }
        : {}),
    });
  };

  const renderTeamMemberAssigneeAdditionalContent = () => {
    if (assigneeTeamMembersType === AssigneeTeamMembersType.SpecificTeamMembers) {
      return <AutoCompletionMultiselect id="teamMembers" labelTitle="Team Members To Assign" {...membersProps} />;
    }

    if (assigneeTeamMembersType === AssigneeTeamMembersType.RoundRobin) {
      const items = members
        .filter((member) => member.active)
        .map((member) => ({
          id: member.id,
          label: (
            <>
              {renderUserAvatar(member, styles.roundRobinAvatar)}
              {member.firstName} {member.lastName}
            </>
          ),
        }));

      return (
        <div className={styles.roundRobinRolesContainer}>
          <label>Team Members Included In Round Robin</label>
          <CheckboxMultiSelectList
            className={styles.roundRobinMultiSelectList}
            listItemCheckboxClassName={styles.checkboxItem}
            listItemLabelClassName={styles.checkboxLabel}
            items={items}
            selectedItemsIds={teamMembersToRoundRobin}
            onChange={handleTeamMemberToRoundRobinChange}
          />
        </div>
      );
    }

    return null;
  };

  const isSaveChangesButtonEnabled = () => {
    if (assigneeTeamMembersType === AssigneeTeamMembersType.SpecificTeamMembers && !members.length) {
      return false;
    }

    return (
      lockBorrower !== product.settings.lockBorrowerOnApplicationCreation ||
      (assigneeTeamMembersType || null) !== (product.settings.assigneeTeamMembersType || null) ||
      !areArraysEqual(autoAssignLabels, product.settings.autoAssignLabels) ||
      !areArraysEqual(getNewTeamMembersToAssign(), product.settings.teamMembersToAssign) ||
      !areArraysEqual(getNewTeamMembersToRoundRobin(), product.settings.teamMembersToRoundRobin)
    );
  };

  return (
    <PopUp classNames={{ popupCore: styles.popupCore }} closePopUp={onClose} title="Application Form Settings">
      <PopUpContent hasTopMargin>
        <LabelsMultiSelect
          labelTitle="Assign Labels To New Applications"
          id="autoAssignLabels"
          onSelect={handleLabelSelect}
          selectedOptions={autoAssignLabels}
          onDeleteOption={handleLabelDeselect}
          hideDropdownOnSelect
        />
        <AutoCompletion
          labelTitle="Assign Team Members To New Applications"
          options={ASSIGNEE_TEAM_MEMBERS_TYPE_OPTIONS}
          value={assigneeTeamMembersType || ''}
          onChange={handleAssigneeTeamMembersTypeChange}
        />
        {renderTeamMemberAssigneeAdditionalContent()}
        <div className={styles.lockBorrowersSwitchContainer}>
          <label>Lock Borrowers</label>
          <div>
            <StyledSwitch
              checked={lockBorrower}
              classes={{ root: styles.lockBorrowersSwitch }}
              onChange={(_event, checked) => setLockBorrower(checked)}
            />
            <p>Prevent borrowers from submitting multiple applications (until unlocked)</p>
          </div>
        </div>
        <Button
          isLoading={isSavingInProgress}
          className={styles.saveChangesButton}
          kind="primary"
          size="form"
          disabled={!isSaveChangesButtonEnabled()}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default FormSettingsPopup;
