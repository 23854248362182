import React, { useEffect, useState, useCallback } from 'react';
import { CloseButtonImage } from 'static/images';
import { useDispatch, useSelector } from 'react-redux';
import { createIntermediary } from 'LoanOriginationSystemIntermediariesPage/CreateIntermediary/ActionCreator';
import { formatDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { getIntermediaryProfileCardsSelector } from 'IntermediaryProfileCards/Selectors';
import { getIntermediaryProfileCards } from 'IntermediaryProfileCards/Thunks';
import { ReduxState } from 'types/redux';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useCardFields from 'hooks/useCardFields';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import styles from './CreateIntermediary.module.scss';

interface CreateIntermediaryProps {
  onClose: () => void;
}

const CreateIntermediary = ({ onClose }: CreateIntermediaryProps) => {
  const dispatch = useDispatch();

  const [intermediaryData, setIntermediaryData] = useState<FormLayoutData>({});

  const cards = useSelector((state: ReduxState) => getIntermediaryProfileCardsSelector(state));

  const { isCreating } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediariesState.createIntermediary,
  );

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChange,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => Object.values(intermediaryData).some((value) => !isEmptyVariableValue(value)));

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, intermediaryData);
  const validateIntermediariesData = useConfigurableFormValidation(fields);

  const handleClose = useCallbackWithUnsavedChanges(() => {
    onClose();
  });

  useEffect(() => {
    dispatch(getIntermediaryProfileCards());
  }, []);

  const handleIntermediaryCreate = () => {
    dispatch(createIntermediary(intermediaryData));
  };

  const handleFieldChange = useCallback(({ variable }: BaseVariableConfiguration, value: VariableValue) => {
    setIntermediaryData((previousIntermediaryData) => ({
      ...previousIntermediaryData,
      [variable.systemName]: value,
    }));
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>Add Intermediary</h2>
            <CloseButtonImage className={styles.closeButton} onClick={handleClose} />
          </div>
          <div className={styles.formContainer}>
            <CardsForm
              cards={cards}
              data={intermediaryData}
              onFieldChange={handleFieldChange}
              formatVariableConfigurationDisplayTitle={formatDisplayTitle}
              displayHeader={false}
              isEditMode
              displayFieldsAttributes={displayFieldsAttributes}
              displaySkeleton={!displayFieldsAttributes}
            />
            <WithFieldsValidationButton
              size="form"
              kind="primary"
              className={styles.createButton}
              disabled={!cards}
              onClick={handleIntermediaryCreate}
              isLoading={isCreating}
              areFieldsInvalid={!validateIntermediariesData(intermediaryData)}
            >
              Add Intermediary
            </WithFieldsValidationButton>
          </div>
        </div>
      </div>
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChange}
        usePortal
      />}
    </>
  );
};

export default CreateIntermediary;
