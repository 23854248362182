import React, { useState, useMemo } from 'react';
import { debounce } from 'lodash';
import styles from './Title.module.scss';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import FilterButton from 'components/FilterButton';

interface TitleProps {
  onCreateNewIntermediary: () => void;
  onSearch: (search: string) => void;
  hideSearch: boolean;
  searchInputValue: string;
  members: UserInfo[];
  selectedMembers: UserInfo[];
  onMemberFilterChange: (members: UserInfo[]) => void;
  filtersChanged: boolean;
  onFilterButtonClick: () => void;
  areIntermediariesLoading: boolean;
}

const SEARCH_INPUT_DEBOUNCE_TIME = 300;

const Title = ({
  onCreateNewIntermediary,
  onSearch,
  hideSearch,
  searchInputValue,
  members,
  selectedMembers,
  onMemberFilterChange,
  filtersChanged,
  onFilterButtonClick,
  areIntermediariesLoading,
}: TitleProps) => {
  const [inputValue, setInputValue] = useState(searchInputValue);
  const debouncedOnSearch = useMemo(() => {
    return debounce(onSearch, SEARCH_INPUT_DEBOUNCE_TIME);
  }, [onSearch]);
  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedOnSearch(event.target.value);
  };
  const onSearchClear = () => {
    setInputValue('');
    onSearch('');
  };

  return (
    <div className={styles.title}>
      <div className={styles.titleInner}>
        <h2 className={styles.titleName}>Intermediaries</h2>
        <Button kind="primary" onClick={onCreateNewIntermediary}>
          Add Intermediary
        </Button>
      </div>
      {!hideSearch && (
        <div className={styles.filtersContainer}>
          <SearchInput
            placeholder="Search"
            onClear={onSearchClear}
            onChange={onSearchInputChange}
            value={inputValue}
            containerClassName={styles.searchInput}
            showLoader={!!inputValue && areIntermediariesLoading}
          />
          <UserFilter users={members} selectedUsers={selectedMembers} onChange={onMemberFilterChange} />
          <FilterButton
            onClick={onFilterButtonClick}
            active={filtersChanged}
            containerClassName={styles.filterButton}
          />
        </div>
      )}
    </div>
  );
};

export default Title;
