import { createSelector } from 'reselect';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ReduxState } from 'types/redux';

const getBorrowerType = (state: ReduxState, borrowerType: BorrowerType) => borrowerType;
const getCardsById = (state: ReduxState) => state.borrowerProfileCards.cardsById;
const getBorrowerVariablesConfigurationsById = (state: ReduxState) => state.borrowerVariableConfigurations.configurationsById;

export const createGetBorrowerProfileCardsSelector = () => {
  return createSelector(
    [getBorrowerType, getCardsById, getBorrowerVariablesConfigurationsById],
    (borrowerType, cardsById, configurationsById) => {
      const cards = Object.values(cardsById).filter((card) => card.borrowerType === borrowerType);

      if (!cards.length) {
        return null;
      }

      return cards.map((card) => ({
        ...card,
        fields: card.fields.map((fieldId) => configurationsById[fieldId]),
      }));
    },
  );
};

export const getBorrowerProfileCardsSelector = createGetBorrowerProfileCardsSelector();
