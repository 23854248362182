import { AnyAction, MiddlewareAPI } from 'redux';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { ChangeProductStatusSuccessAction, getProducts } from './ActionCreator';

const isChangeProductStatusSuccessAction = (action: AnyAction): action is ChangeProductStatusSuccessAction =>
  action.type === LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess;

const notifyOnProductStatusChangeSuccessMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  const { loanOriginationSystemProducts } = getState();

  if (isChangeProductStatusSuccessAction(action)) {
    const messageType =
      action.payload.status === ProductStatus.Archived ? MessageType.ProductArchived : MessageType.ProductStatusChanged;

    notification.createNotification(getMessage(messageType, { productName: action.payload.name }), 'success', dispatch);
    dispatch(getProducts(loanOriginationSystemProducts.filters))
  }

  return result;
};

export default notifyOnProductStatusChangeSuccessMiddleware;
