import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import withStateReset from 'utils/reducers/withStateReset';
import responseHandlers from 'api/ResponseHandlers';
import DecisionApi from 'api/DecisionEngine/DecisionApi';

const decisionApi = new DecisionApi(responseHandlers);

export interface DeleteDecisionState {
  decisionIdToDelete: string | null;
}

const initialState: DeleteDecisionState = {
  decisionIdToDelete: null,
};

export interface DeleteDecisionParams {
  decisionId: string;
}

export const deleteDecision = createAsyncThunk('deleteDecision', async (decisionId: string) => {
  const decision = await decisionApi.delete(decisionId);
  return decision;
});
export const openDeleteDecisionPopUp = createAction<DeleteDecisionParams>('openDeleteDecisionPopUp');
export const closeDeleteDecisionPopUp = createAction('closeDeleteDecisionPopUp');

export const RESET_DELETE_DECISION_STATE_ACTION_PREFIX = 'resetDeleteDecisionState';

export const resetDeleteDecisionState = createAction(RESET_DELETE_DECISION_STATE_ACTION_PREFIX);

const deleteDecisionReducer = createReducer<DeleteDecisionState>(initialState, (builder) => {
  builder.addCase(openDeleteDecisionPopUp, (state, action) => {
    state.decisionIdToDelete = action.payload.decisionId;
  });
  builder.addCase(closeDeleteDecisionPopUp, (state) => {
    state.decisionIdToDelete = null;
  });
  builder.addCase(deleteDecision.fulfilled, (state) => {
    state.decisionIdToDelete = null;
  });
});

export default withStateReset(
  deleteDecisionReducer,
  RESET_DELETE_DECISION_STATE_ACTION_PREFIX,
  () => initialState,
);
