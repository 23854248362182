export const ApplicationDocumentsActionTypesPrefix = 'applicationDocuments/';

export const ApplicationDocumentsActionType = {
  GetApplicationDocuments: `${ApplicationDocumentsActionTypesPrefix}getApplicationDocuments`,
  SetSearchValue: `${ApplicationDocumentsActionTypesPrefix}setSearchValue`,
  SortApplicationDocuments: `${ApplicationDocumentsActionTypesPrefix}sortApplicationDocuments`,
  SetParentFolder: `${ApplicationDocumentsActionTypesPrefix}setParentFolder`,
  SetDocumentToUpdate: `${ApplicationDocumentsActionTypesPrefix}setDocumentToUpdate`,
  OpenApplicationDocumentPopup: `${ApplicationDocumentsActionTypesPrefix}openApplicationDocumentPopup`,
  CloseApplicationDocumentPopup: `${ApplicationDocumentsActionTypesPrefix}closeApplicationDocumentPopup`,
  UploadApplicationDocument: `${ApplicationDocumentsActionTypesPrefix}uploadApplicationDocument`,
  CreateApplicationDocumentFolder: `${ApplicationDocumentsActionTypesPrefix}createApplicationDocumentFolder`,
  UpdateApplicationDocument: `${ApplicationDocumentsActionTypesPrefix}UpdateApplicationDocument`,
  DeleteApplicationDocument: `${ApplicationDocumentsActionTypesPrefix}deleteApplicationDocument`,
  RequestCustomerUpload: `${ApplicationDocumentsActionTypesPrefix}requestCustomerUpload`,
  CreateDocuSignEnvelope: `${ApplicationDocumentsActionTypesPrefix}createDocuSignEnvelope`,
  DownloadDocument: `${ApplicationDocumentsActionTypesPrefix}downloadDocument`,
  RemoveDownloadedDocument: `${ApplicationDocumentsActionTypesPrefix}removeDownloadedDocument`,
  DownloadArchiveOfApplicationDocuments: `${ApplicationDocumentsActionTypesPrefix}downloadArchiveOfApplicationDocuments`,
  BatchUploadApplicationDocuments: `${ApplicationDocumentsActionTypesPrefix}batchUploadApplicationDocuments`,
  ResetState: `${ApplicationDocumentsActionTypesPrefix}resetState`,
};
