import { createAsyncThunk } from '@reduxjs/toolkit';
import requestWithNormalize from 'utils/requestWithNormalize';
import ApplicationDataTabCardsRestApi, {
  CreateApplicationDataTabCardParams,
  NormalizedApplicationDataTabCard,
  UpdateApplicationDataTabCardParams,
  ApplicationDataTabCard,
} from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import ApplicationDataTabCardSchema from 'schemas/ApplicationDataTabCardSchema';
import responseHandlers from 'api/ResponseHandlers';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';

interface ApplicationDetailsCardsNormalizedEntities {
  applicationDataTabCards: Record<string, NormalizedApplicationDataTabCard>;
  applicationDataTabVariableConfigurations: Record<string, ApplicationDataTabVariableConfiguration>;
}

const applicationDataTabCardsApi = new ApplicationDataTabCardsRestApi(responseHandlers);

export const getApplicationDataTabCards = createAsyncThunk(
  'applicationDataTabCards/getApplicationDataTabCards',
  async (tabId: string) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDataTabCard>(() => {
      return applicationDataTabCardsApi.find(tabId);
    }, [ApplicationDataTabCardSchema]);

    return { ...entities, tabId };
  },
);

export const createApplicationDataTabCard = createAsyncThunk(
  'applicationDataTabCards/createApplicationDataTabCard',
  async (params: CreateApplicationDataTabCardParams) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDataTabCard>(() => {
      return applicationDataTabCardsApi.create(params);
    }, ApplicationDataTabCardSchema);

    return entities;
  },
);

export const updateApplicationDataTabCard = createAsyncThunk(
  'applicationDataTabCards/updateApplicationDataTabCard',
  async (params: UpdateApplicationDataTabCardParams & { id: string }) => {
    const { entities } = await requestWithNormalize<ApplicationDetailsCardsNormalizedEntities, ApplicationDataTabCard>(() => {
      const { id, ...updateParams } = params;

      return applicationDataTabCardsApi.update(id, updateParams);
    }, ApplicationDataTabCardSchema);

    return entities;
  },
);

export const deleteApplicationDataTabCard = createAsyncThunk(
  'applicationDataTabCards/deleteApplicationDataTabCard',
  async (id: string) => {
    await applicationDataTabCardsApi.delete(id);

    return {
      id,
    };
  },
);
