import { useState, FocusEvent, useMemo } from 'react';

type InputValidator = (value: string) => string;

export interface InputValidationOptions {
  value: string;
  validators?: InputValidator[];
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabledValidation?: boolean;
  disableResetValueOnError?: boolean;
}

const validateRequiredField = (value: string) => {
  return !value.trim() ? 'This field is required' : '';
};

const validateField = (validators: InputValidator[], value: string) => {
  for (const validator of validators) {
    const result = validator(value);

    if (result) {
      return result;
    }
  }

  return '';
};

const useInputValidation = ({
  value,
  validators,
  onBlur,
  onChange,
  onFocus,
  disabledValidation,
  disableResetValueOnError,
  required,
}: InputValidationOptions): [(event?: FocusEvent<HTMLInputElement>) => void, (event: FocusEvent<HTMLInputElement>) => void, string, boolean] => {
  const [errorMessage, setErrorMessage] = useState('');

  const memoizedValidators = useMemo(() => {
    return [...(required ? [validateRequiredField] : []), ...validators || []];
  }, [validators, required]);

  const validationResult = useMemo(() => {
    return !disabledValidation ? validateField(memoizedValidators, value) : '';
  }, [value, memoizedValidators, disabledValidation]);

  const handleBlur = (event?: FocusEvent<HTMLInputElement>): void => {
    if (validationResult) {
      setErrorMessage(validationResult);
      onChange(disableResetValueOnError ? value : '');
      return;
    }

    setErrorMessage('');
    onChange(value);
    onBlur?.(event);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    if (errorMessage) {
      setErrorMessage('');
    }
    onFocus?.(event);
  };

  return [handleBlur, handleFocus, errorMessage, !validationResult];
};

export default useInputValidation;
