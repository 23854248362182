import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { RuleBuilderCoreData } from 'RuleCreate/Types';
import { MultipleRule } from 'api/DecisionEngineStrategiesType';
import { ComparisonsTypes } from 'containers/EditRulePopUpContainer/Types';

const getFormattedRulesList = (rulesList: RuleBuilderCoreData[]) =>
  rulesList.reduce<Partial<MultipleRule>[]>((accumulator, currentRule) => {
    const {
      firstOperandType,
      firstOperandValue,
      firstOperandVariableId,
      secondOperandType,
      secondOperandValue,
      secondOperandVariableId,
      selectedComparison,
      sourceVariableId,
      optionSeparator,
    } = currentRule;

    const operandsData: Partial<MultipleRule> = {};

    if (selectedComparison === 'RANGE') {
      const minimumValueComparison =
        firstOperandType === ComparisonOperandType.VALUE ? firstOperandValue : firstOperandVariableId;
      operandsData.state_property_attribute_value_minimum = minimumValueComparison;
      operandsData.state_property_attribute_value_minimum_type = firstOperandType.toLowerCase() as ComparisonOperandType;

      const maximumValueComparison =
        secondOperandType === ComparisonOperandType.VALUE ? secondOperandValue : secondOperandVariableId;
      operandsData.state_property_attribute_value_maximum = maximumValueComparison;
      operandsData.state_property_attribute_value_maximum_type = secondOperandType.toLowerCase() as ComparisonOperandType;
    }

    if (selectedComparison !== 'RANGE') {
      const valueComparison =
        firstOperandType === ComparisonOperandType.VALUE ? firstOperandValue : firstOperandVariableId;
      operandsData.state_property_attribute_value_comparison = valueComparison;
      operandsData.state_property_attribute_value_comparison_type = firstOperandType.toLowerCase() as ComparisonOperandType;
    }

    accumulator.push({
      ...operandsData,
      condition_test: selectedComparison as keyof typeof ComparisonsTypes,
      state_property_attribute: sourceVariableId!,
      optionSeparator,
    });

    return accumulator
  }, []);

export default getFormattedRulesList;
