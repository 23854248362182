import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { Variable } from 'Variables/VariablesTypes';

export const LIST_VARIABLES_PER_PAGE_DEFAULT = 20;

export interface ConfigurationVariablesPaginationParams {
  search: string;
  allowStandardVariables?: boolean;
}

const variablesListPagination = Pagination<
  ReduxState,
  Variable,
  ConfigurationVariablesPaginationParams
>(
  'loanOriginationSystemVariablesList',
  LIST_VARIABLES_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
  { sliceItems: false },
);

export default variablesListPagination;
