import { AutomatedProcessesApi } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import automatedProcessPagination from 'components/LoanOriginationSystem/ProcessAutomationDashboard/Pagination';
 
const ProcessAutomationPaginationMiddleware = (api: AutomatedProcessesApi) => {
  return automatedProcessPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getAutomatedProcesses({
      count: params.perPage,
      offset: (params.page - 1) * params.perPage,
      group: params.group,
      category: params.category,
    });
  });
};

export default ProcessAutomationPaginationMiddleware;
