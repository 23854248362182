import clsx from 'clsx';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import React, { FC } from 'react';
import { LockedImage, UnlockedImage } from 'static/images';
import styles from './StrategyVersionInfo.module.scss';
import StrategyStatusLabel from 'components/StrategyStatusLabel';
import { useHistory } from 'react-router-dom';
import VersionActions from 'components/StrategyGroups/StrategiesTableView/VersionActions';
import { StrategyGroupVersion } from 'api/Types';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { useDispatch } from 'react-redux';
import { setStrategyData, StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import UnknownUserAvatar from 'components/UnknownUserAvatar';
import UserAvatar from 'components/UserAvatar';

interface StrategyVersionInfoProps {
  version: StrategyGroupVersion;
  versionActionsClassName?: string;
  isTable?: boolean;
  disabled?: boolean;
  className?: string;
  isStatusDisplay?: boolean;
}

const StrategyVersionInfo: FC<StrategyVersionInfoProps> = ({
  version,
  isTable,
  versionActionsClassName,
  disabled,
  className,
  isStatusDisplay,
}) => {
  const { id, version: versionNumber, isLocked, updatedByUserInfo, status: normalizedStatus, updatedDate } = version;
  const dispatch = useDispatch();
  const history = useHistory();
  const isArchived = normalizedStatus === NormalizedStatus.Archive;
  const containerClassName = clsx(isTable ? styles.tableInfoContainer : styles.cardInfoContainer, className);
  const strategyVersionClassName = clsx(styles.strategyVersion, isTable && styles.strategyVersionTable);
  const dateContainerClassName = clsx(styles.dateContainer, isTable && styles.dateContainerTable);
  const strategyDataClassName = clsx(styles.strategyData, isTable && styles.strategyDataTable);

  const openVersion = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(setStrategyData(version));
    history.push(`/decision/strategies/${id}/overview`);
  };

  return (
    <div
      className={clsx(containerClassName, isArchived && styles.inactiveItem, disabled && styles.disabled)}
      onClick={disabled ? undefined : openVersion}
    >
      <div className={strategyDataClassName}>
        <div className={strategyVersionClassName}>Version {versionNumber}</div>
        {isLocked ? <LockedImage /> : <UnlockedImage />}
        {isStatusDisplay ? <StrategyStatusLabel status={normalizedStatus} /> : null}
      </div>
      <div className={styles.strategyUpdateInfo}>
        {updatedByUserInfo ? (
          <UserAvatar
            firstName={updatedByUserInfo.firstName}
            lastName={updatedByUserInfo.lastName}
            imageId={updatedByUserInfo.imageId}
            size="tiny"
            tooltip
            className={styles.avatar}
            textClassName={styles.avatarText}
          />
        ) : (
          <UnknownUserAvatar size="tiny" tooltip className={styles.avatar} textClassName={styles.avatarText} />
        )}
        <p className={dateContainerClassName}>
          Updated <DateTime time={updatedDate} format={DateTimeFormat.Long} />
        </p>
      </div>
      {disabled ? null : (
        <VersionActions
          className={versionActionsClassName}
          version={version}
          btnClassName={styles.actionsBtnClassName}
          origin={StrategyActionOrigin.StrategyGroupVersionsList}
        />
      )}
    </div>
  );
};

export default StrategyVersionInfo;
