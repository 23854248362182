import { useMemo } from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { convertBorrowerVariablesToCoBorrower } from 'utils/BorrowerVariableUtils';

interface FullApplicationDataProps {
  borrowerFormData: FormLayoutData;
  coBorrowersFormData: Array<FormLayoutData>;
  intermediaryFormData: FormLayoutData;
  applicationDetailsFormData: FormLayoutData;
  selectedBorrower: Borrower | null;
  selectedIntermediary: Intermediary | null;
  selectedCoBorrowers: Array<Borrower | null>;
}

const useFullApplicationData = ({
  borrowerFormData,
  coBorrowersFormData,
  intermediaryFormData,
  selectedBorrower,
  selectedCoBorrowers,
  selectedIntermediary,
  applicationDetailsFormData,
}: FullApplicationDataProps) => {
  const coBorrowersData = useMemo(() => {
    return coBorrowersFormData.map((coBorrowerFormData, index) => {
      const selectedCoBorrower = selectedCoBorrowers[index];

      return selectedCoBorrower ? selectedCoBorrower.variables : coBorrowerFormData;
    })
  }, [coBorrowersFormData, selectedCoBorrowers]);

  const mergedCoBorrowersData = useMemo(() => {
    return coBorrowersData.reduce((previousMergedCoBorrowersData, coBorrowerData, index) => ({
      ...previousMergedCoBorrowersData,
      ...convertBorrowerVariablesToCoBorrower(coBorrowerData, index),
    }), {});
  }, [coBorrowersData]);

  const borrowerData = selectedBorrower ? selectedBorrower.variables : borrowerFormData;
  const intermediaryData = selectedIntermediary ? selectedIntermediary.variables : intermediaryFormData;

  return useMemo(() => ({
    ...borrowerData,
    ...mergedCoBorrowersData,
    ...intermediaryData,
    ...applicationDetailsFormData,
  }), [
    borrowerData,
    intermediaryData,
    mergedCoBorrowersData,
    applicationDetailsFormData,
  ]);
};

export default useFullApplicationData;
