import { Email, EmailsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const EMAILS_PER_PAGE_DEFAULT = 20;

export interface EmailsPaginationParams {
  search: string;
  sortingType: EmailsSortingType;
  createdAtDateRange: {
    from: Date | null;
    to: Date | null;
  };
  selectedBorrower: string | null;
}

export const getPath = ({ selectedBorrower, search }: { selectedBorrower: string | null; search: string }) => {
  if (!selectedBorrower && !search) {
    return [];
  }
  if (!selectedBorrower) {
    return ['searched', search];
  }
  return ['byBorrower', selectedBorrower, search];
};

const emailsPagination = Pagination<ReduxState, Email, EmailsPaginationParams>(
  'loanOriginationSystemEmails',
  EMAILS_PER_PAGE_DEFAULT,
  getPath,
);

export default emailsPagination;
