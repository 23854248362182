import Fetcher from 'middlewares/Fetcher';
import { LoadVariablesAction, loadVariablesFailure, loadVariablesSuccess } from './Actions';
import { VariablesAttributesActionTypes } from './ActionTypes';
import { Variable } from 'Variables/VariablesTypes';
import { VariablesApi } from 'api/Core/VariablesApi';

const GetVariablesByIdMiddleware = (api: VariablesApi) =>
  Fetcher<Variable[], LoadVariablesAction>(
    VariablesAttributesActionTypes.LoadVariables,
    loadVariablesSuccess,
    loadVariablesFailure,
    async ({ payload }) => {
      const { items } = await api.getVariables({ includeArchived: true, ...payload });
      return items;
    },
  );

export default GetVariablesByIdMiddleware;
