import React, { useEffect, useState } from 'react';
import styles from './SecureFileUpload.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSecureDocument, getOrganizationInfo } from 'UploadSecureDocument/ActionCreator';
import { ReduxState } from 'types/redux';
import { UploadSecureDocumentState } from 'UploadSecureDocument/Types';
import { useParams } from 'react-router';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import UploadApplicationDocumentForm from 'components/LoanOriginationSystem/UploadApplicationDocumentForm';
import Button from 'components/Button';
import { NotFoundPageImage } from 'static/images';
import AuthLayout from 'components/Auth/AuthLayout';

const SecureFileUploadPage = () => {
  const [uploadedFileName, setUploadedFileName] = useState('');
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { organizationName, organizationLogo, linkExpired } = useSelector<ReduxState, UploadSecureDocumentState>(
    (state) => state.uploadSecureDocument,
  );

  const handleUploadSecretDocument = async (file: File) => {
    await dispatchRoutine(uploadSecureDocument(token, file));
    setUploadedFileName(file.name);
  };

  useEffect(() => {
    dispatch(getOrganizationInfo(token));
  }, []);

  const renderFileUploaded = () => {
    return (
      <>
        <h2 className={styles.title}>Upload Complete!</h2>
        <p className={styles.description}>
          <span className={styles.highlighted}>{uploadedFileName}</span> has been successfully uploaded. If you need to
          upload additional files, please click the button below.
        </p>
        <Button type="button" kind="secondary" size="form" fullWidth onClick={() => setUploadedFileName('')}>
          Upload Another File
        </Button>
      </>
    );
  };

  const renderUploadForm = () => {
    return (
      <>
        <h2 className={styles.title}>
          {organizationName}
          <br /> Secure File Upload
        </h2>
        <p className={styles.description}>
          Please upload documents by selecting them below. To upload multiple documents, repeat the process.
        </p>
        <UploadApplicationDocumentForm
          onApplicationDocumentUpload={handleUploadSecretDocument}
          buttonText="Upload Document"
          buttonFullWidth
        />
      </>
    );
  };

  const renderLinkExpired = () => {
    return (
      <>
        <NotFoundPageImage />
        <h2 className={styles.title}>Link Expired</h2>
        <p className={styles.description}>
          It looks like your upload link has expired. Please request another link if needed.
        </p>
      </>
    );
  };

  const renderMainContent = () => {
    if (linkExpired) {
      return renderLinkExpired();
    }

    return uploadedFileName ? renderFileUploaded() : renderUploadForm();
  };

  return (
    <AuthLayout organizationLogo={<img src={organizationLogo} alt="Organization Logo" />}>
      {renderMainContent()}
    </AuthLayout>
  );
};

export default SecureFileUploadPage;
