import React, { FC, useEffect } from 'react';
import { requestOrganizationUser } from 'OrganizationUsers/OrganizationUserStore';
import NotFoundCompanyUser from './NotFoundCompanyUser';
import EditCompanyUser from './EditCompanyUser';
import { OrganizationMembership } from 'CompanyInformation/CompanyInformationTypes';
import { useDispatch } from 'react-redux';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import { PermissionGroupSelectOption } from 'PermissionGroups/Types';

interface EditCompanyUserContainerProps {
  selectedMembershipId: string;
  usersDetailedAttributes: Partial<Record<string, OrganizationMembership>>;
  isUserDetailedAttributesLoading: boolean;
  handleCloseContextualView: () => void;
  updateUser: (userInfo: EditUserParams) => Promise<void>;
  currentUserId?: string;
  handleOpenSeatsAreFullPopUp: () => void;
  permissionGroups: PermissionGroupSelectOption[];
  handleEditPermissionGroupsClick: () => void;
}

const EditCompanyUserContainer: FC<EditCompanyUserContainerProps> = ({
  selectedMembershipId,
  usersDetailedAttributes,
  isUserDetailedAttributesLoading,
  handleCloseContextualView,
  updateUser,
  currentUserId,
  handleOpenSeatsAreFullPopUp,
  permissionGroups,
  handleEditPermissionGroupsClick,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestOrganizationUser(selectedMembershipId!));
  }, []);

  return !isUserDetailedAttributesLoading && !usersDetailedAttributes[selectedMembershipId] ? (
    <NotFoundCompanyUser onClose={handleCloseContextualView} />
  ) : (
    <EditCompanyUser
      updateUser={updateUser}
      selectedUserInfo={usersDetailedAttributes[selectedMembershipId]}
      onClose={handleCloseContextualView}
      selfEdit={usersDetailedAttributes[selectedMembershipId]?.userId === currentUserId}
      handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
      isUserDetailedAttributesLoading={isUserDetailedAttributesLoading}
      permissionGroups={permissionGroups}
      handleEditPermissionGroupsClick={handleEditPermissionGroupsClick}
    />
  );
};

export default EditCompanyUserContainer;
