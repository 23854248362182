import React, { ChangeEvent } from 'react';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import StyledSwitch from 'components/StyledSwitch';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';
import styles from './ProductsDashboardHeader.module.scss';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import FilterButton from 'components/FilterButton';
import { SkeletonCirclesList } from 'components/Skeleton';

interface ProductsDashboardHeaderProps {
  search: string;
  showArchived: boolean;
  onSearch: (search: string) => void;
  onAddProduct: () => void;
  onDownloadApiRequestTemplate: () => void;
  onChangeShowArchived: () => void;
  members: UserInfo[];
  selectedMembers: UserInfo[];
  onMemberFilterChange: (members: UserInfo[]) => void;
  onFilterButtonClick: () => void;
  filtersChanged: boolean;
  displayFilters?: boolean;
  areProductsLoading: boolean;
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test1,
  ReactAppEnvironment.Test2,
);

const ProductsDashboardHeader = ({
  search,
  onSearch,
  onAddProduct,
  onDownloadApiRequestTemplate,
  onChangeShowArchived,
  showArchived,
  displayFilters,
  members,
  selectedMembers,
  onMemberFilterChange,
  onFilterButtonClick,
  filtersChanged,
  areProductsLoading,
}: ProductsDashboardHeaderProps) => {
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => onSearch(event.target.value);
  const handleSearchClear = () => onSearch('');

  const renderSubtitle = () => {
    if (!displayFilters) {
      return null;
    }

    return (
      <div className={styles.subTitle}>
        <SearchInput
          placeholder="Search"
          value={search}
          onChange={handleSearchInputChange}
          onClear={handleSearchClear}
          containerClassName={styles.searchInputContainer}
          showLoader={!!search && areProductsLoading}
        />
        {members.length ? (
          <UserFilter users={members} selectedUsers={selectedMembers} onChange={onMemberFilterChange} />
        ) : (
          <SkeletonCirclesList width="36px" height="36px" color="primary6" circlesCount={5} />
        )}
        <div className={styles.showArchivedSwitchContainer}>
          <StyledSwitch
            color="primary"
            className={styles.switchButton}
            checked={showArchived}
            onChange={onChangeShowArchived}
          />
          <p>Show Archived</p>
        </div>
        <FilterButton
          onClick={onFilterButtonClick}
          active={filtersChanged}
          containerClassName={styles.filterButtonContainer}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2 className={styles.headerTitle}>Products</h2>
        <div className={styles.buttonsContainer}>
          <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiRequestTemplate} />
          <Button className={styles.addProductButton} kind="primary" onClick={onAddProduct}>
            Add Product
          </Button>
        </div>
      </div>
      {renderSubtitle()}
    </div>
  );
};

export default ProductsDashboardHeader;
