import React from 'react';
import { get } from 'lodash';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import {
  getFormattedBorrowerIdNumber,
  getFormattedBorrowerPhoneNumber,
  getIdNumberVariableNameByType,
} from 'LoanOriginationSystemOrganization/Utils';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { BorrowersColumnSizes } from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/TableColumnSizes';
import SearchHighlight from 'components/SearchHighlight';
import SearchMatchResults from 'components/SearchMatchResults';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import styles from './BorrowerItemRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import { StandardVariables } from 'Variables/VariablesTypes';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import getSearchMatchingField from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/getSearchMatchingField';
import variables from 'components/RowActions/RowActions.module.scss';

interface BorrowerItemRowProps {
  borrower: Borrower;
  onClick: (id: string) => void;
  searchInputValue: string;
  onBorrowerDelete: (borrower: Borrower) => void;
  onBorrowerEdit: (id: string) => void;
  standardVariables: StandardVariables;
}

const BorrowerItemRow = ({
  borrower,
  searchInputValue,
  standardVariables,
  onClick,
  onBorrowerDelete,
  onBorrowerEdit,
}: BorrowerItemRowProps) => {
  const userPermissionGroupId = useUserPermissionGroupId()!;

  const renderCellContent = (content: string) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue} multiWord>
        {content || <TableBodyCellNoContent />}
      </SearchHighlight>
    </OverflowedText>
  );

  const idNumberVariableName = getIdNumberVariableNameByType(borrower.type, BorrowerDefaultVariable);
  const formattedBorrowerIdNumber = getFormattedBorrowerIdNumber(
    borrower.variables[idNumberVariableName],
    standardVariables[idNumberVariableName],
  );
  const formattedBorrowerPhone = getFormattedBorrowerPhoneNumber(
    borrower.variables[BorrowerDefaultVariable.PhoneNumber],
    userPermissionGroupId,
    standardVariables,
  );

  return (
    <div className={styles.tableRowWrapper} key={borrower.id}>
      <TableRow
        useActions
        onClick={() => onClick(borrower.id)}
        noBottomBorder={searchInputValue.trim().length > 0}
        className={styles.tableRow}
      >
        <TableBodyCell width={BorrowersColumnSizes.Name} className={styles.nameCell}>
          {renderCellContent(getBorrowerFullName(borrower.type, borrower.variables))}
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.IdNumber}>
          {renderCellContent(formattedBorrowerIdNumber)}
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.Phone}>{renderCellContent(formattedBorrowerPhone)}</TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.Email}>
          {renderCellContent(borrower.variables[BorrowerDefaultVariable.Email] as string)}
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.CreatedAt} noPadding>
          <WithSystemApiUserAvatar user={borrower.createdBy} className={styles.avatar} size="small" />
          <OverflowedText>{formatDate(borrower.createdAt, DateTimeFormat.Short)}</OverflowedText>
        </TableBodyCell>
        <TableBodyCell width={BorrowersColumnSizes.UpdatedAt} noPadding>
          <WithSystemApiUserAvatar user={borrower.updatedBy} className={styles.avatar} size="small" />
          <OverflowedText>{formatDate(borrower.updatedAt, DateTimeFormat.Short)}</OverflowedText>
        </TableBodyCell>
        <RowActions
          actions={[
            {
              title: 'Edit Borrower',
              handler: () => onBorrowerEdit(borrower.id),
              danger: false,
              separatorRequired: true,
            },
            {
              title: 'Delete Borrower',
              handler: () => onBorrowerDelete(borrower),
              danger: true,
              separatorRequired: false,
            },
          ]}
        />
      </TableRow>
      {searchInputValue.trim().length > 0 ? (
        <TableRow className={styles.tableRow} noTopBorder onClick={() => onClick(borrower.id)}>
          <TableBodyCell width={BorrowersColumnSizes.Name}> </TableBodyCell>
          <TableBodyCell width={100 - BorrowersColumnSizes.Name} dashedTopBorder>
            <SearchMatchResults
              searchInputValue={searchInputValue}
              searchHighlights={borrower.highlights ?? []}
              getMatchingField={getSearchMatchingField((path) => get(borrower, path), standardVariables, userPermissionGroupId)}
            />
          </TableBodyCell>
          <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
            {' '}
          </TableBodyCell>
        </TableRow>
      ) : null}
    </div>
  );
};

export default BorrowerItemRow;
