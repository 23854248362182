import { Middleware } from 'redux';

import { GET_DECISION_STRATEGY_INFO_REQUEST } from './ActionTypes';
import { getDecisionStrategyInfoSuccess, getDecisionStrategyInfoError } from './DecisionStrategiesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { Strategy } from './DecisionStrategiesTypes';
import { IStrategiesApi } from 'api/DecisionEngine/StrategyApi';

const GetDecisionStrategyInfo: (api: IStrategiesApi) => Middleware = (api) =>
  Fetcher<Strategy>(
    GET_DECISION_STRATEGY_INFO_REQUEST,
    getDecisionStrategyInfoSuccess,
    getDecisionStrategyInfoError,
    ({ payload: strategyId }) => api.findById(strategyId),
  );

export default GetDecisionStrategyInfo;
