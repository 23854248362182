import React, { createContext, ReactElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { once } from 'lodash';
import { LeftNavigationBarType } from 'components/LeftNav/LeftNavigation';
import useCachedForTransition from 'hooks/cachedForTransition';
import { EventEmitter } from 'events';
import MainLayoutNotifications from './Notifications';

import styles from './MainLayout.module.scss';
import RightSidePopupLayout from 'MainLayout/RightSidePopupLayout';
import Snackbar from 'Snackbar/Snackbar';
import ContextualViewWrapper from 'MainLayout/ContextualViewWrapper';

interface MainLayoutProps {
  children: ReactNode;
  leftNav: ReactElement<LeftNavigationBarType>;
  overlay?: ReactNode;
  rightSidePopupView?: ReactNode;
  beforeContentContainer?: ReactNode;
  contentContainerClassName?: string;
}

interface MainLayoutPropsWithContextualView extends MainLayoutProps {
  contextualView: ReactNode;
  closeContextualView?: () => void;
}

const hasContextualView = (props: MainLayoutProps): props is MainLayoutPropsWithContextualView =>
  'contextualView' in props;

const noOp = () => {};

const defaultContextualViewProps = {
  contextualView: null,
  closeContextualView: noOp,
};

export const PageWrapperOffsetContext = createContext<EventEmitter | null>(null);
export const PAGE_WRAPPER_OFFSET_EVENT_OFFSET = 'offset';
export const PAGE_WRAPPER_OFFSET_EVENT_REFRESH = 'refresh';

const MainLayout = (props: MainLayoutProps | MainLayoutPropsWithContextualView) => {
  const { children, leftNav, overlay, rightSidePopupView, beforeContentContainer, contentContainerClassName } = props;

  const { contextualView, closeContextualView } = hasContextualView(props) ? props : defaultContextualViewProps;

  const handleClickOutsideContextualView = once(() => {
    if (contextualView && typeof closeContextualView === 'function') {
      closeContextualView();
    }
  });

  const [visibleContextualView, onContentWrapperTransitionEnd] = useCachedForTransition(contextualView);

  const [contextualViewWidth, setContextualViewWidth] = useState(0);

  const contextualViewWrapperElementRef = useRef<HTMLDivElement>(null);
  const contextualViewWrapperContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contextualView) {
      setContextualViewWidth(0);
      return;
    }
    const contextualViewWrapperElement = contextualViewWrapperElementRef.current!;
    const contextualViewElement = contextualViewWrapperElement.children[0];
    const { width } = contextualViewElement.getBoundingClientRect();
    setContextualViewWidth(width);
  }, [contextualView]);

  const pageWrapperOffsetContextEmitterRef = useRef(once(() => new EventEmitter()));
  const pageWrapperOffsetContextEmitter = pageWrapperOffsetContextEmitterRef.current();

  const [notificationsRightOffset, setNotificationsRightOffset] = useState(0);
  const isOpenContextual = !!contextualView;

  useLayoutEffect(() => {
    // This code queries the right offset of a PageWrapper-like element.
    // It's not required that MainLayout has such child which is capable of providing that offset,
    // that's why it's implemented by providing an event emitter through a React Context.
    // A child that is capable of providing the offset value should subscribe to the "refresh" event,
    // and in response it should emit "offset" with the offset value.
    // If no "offset" event occurs in response to "refresh", we just use the default offset - 0.
    let rightOffset = 0;
    const onOffset = (offset: number) => {
      rightOffset = offset;
    };
    pageWrapperOffsetContextEmitter.addListener(PAGE_WRAPPER_OFFSET_EVENT_OFFSET, onOffset);
    pageWrapperOffsetContextEmitter.emit(PAGE_WRAPPER_OFFSET_EVENT_REFRESH);
    pageWrapperOffsetContextEmitter.removeListener(PAGE_WRAPPER_OFFSET_EVENT_OFFSET, onOffset);
    setNotificationsRightOffset(rightOffset);
  });

  useEffect(() => {
    if (!isOpenContextual && contextualViewWrapperContainerRef.current) {
      contextualViewWrapperContainerRef.current.style.transition = '';
    }
  }, [isOpenContextual]);

  const contentWrapperStyle = {
    transform: contextualViewWidth ? `translateX(${contextualViewWidth}px)` : '',
  };

  const handleContentWrapperTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
    onContentWrapperTransitionEnd(event);

    if (!contextualViewWrapperContainerRef.current || event.currentTarget !== event.target) {
      return;
    }

    if (isOpenContextual) {
      contextualViewWrapperContainerRef.current.style.transition = 'none';
    }
  };

  const shouldAddBackdrop = !!rightSidePopupView;

  return (
    <>
      <div className={styles.mainLayoutContainer}>
        {leftNav}
        <div
          ref={contextualViewWrapperContainerRef}
          className={isOpenContextual ? styles.dynamicContentMoved : styles.dynamicContent}
          style={contentWrapperStyle}
          onTransitionEnd={handleContentWrapperTransitionEnd}
        >
          {visibleContextualView && (
            <ContextualViewWrapper ref={contextualViewWrapperElementRef}>{visibleContextualView}</ContextualViewWrapper>
          )}
          {beforeContentContainer}
          <div className={clsx(styles.contentContainer, contentContainerClassName)} onClick={handleClickOutsideContextualView}>
            <PageWrapperOffsetContext.Provider value={pageWrapperOffsetContextEmitter}>
              {children}
            </PageWrapperOffsetContext.Provider>
          </div>
          {shouldAddBackdrop && <div className={styles.backdrop} />}
        </div>
      </div>
      {overlay && <div className={styles.overlayContainer}>{overlay}</div>}
      <MainLayoutNotifications right={notificationsRightOffset} />
      <RightSidePopupLayout>{rightSidePopupView}</RightSidePopupLayout>
      <Snackbar />
    </>
  );
};

export default MainLayout;
