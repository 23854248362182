import { useMemo } from 'react';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import DocumentUploadFile from './DocumentUploadFile';

const useUploadApplicationDocumentsValidation = (
  configurations: ApplicationDocumentConfiguration[] | null,
  files: DocumentUploadFile[],
) => {
  const filesByConfigurationId = useMemo(() => {
    return files.reduce((previousFileByConfigurationId, documentFile) => ({
      ...previousFileByConfigurationId,
      [documentFile.configurationId]: documentFile,
    }), {});
  }, [files]);

  return (displayFieldsAttributes?: Record<string, boolean>) => {
    return (configurations || []).every((configuration) => {
      return (displayFieldsAttributes && !displayFieldsAttributes[configuration.id])
        || !configuration.required
        || !!filesByConfigurationId[configuration.id];
    })
  };
};

export default useUploadApplicationDocumentsValidation;
