import { AnyAction } from 'redux';
import { ProductApplicationSetupActionType } from './ActionTypes';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';

export interface SetTabToDeleteAction extends AnyAction {
  type: ProductApplicationSetupActionType.SetTabToDelete;
  payload: ApplicationTab | null;
}

export const setTabToDelete = (tab: ApplicationTab | null): SetTabToDeleteAction => ({
  type: ProductApplicationSetupActionType.SetTabToDelete,
  payload: tab,
});
