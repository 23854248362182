import React, { FC } from 'react';
import styles from './VariableLine.module.scss';
import { Variable, VisualDataType } from 'Variables/VariablesTypes';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import SearchHighlight from 'components/SearchHighlight';
import { getVisualDataType } from 'Variables/utils';
import RowActions from 'components/RowActions';
import TableBodyCell from 'components/Table/TableBodyCell';
import { SIZES } from 'components/Variables/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import clsx from 'clsx';
import VariableTypeTag from 'components/VariableTypeTag/VariableTypeTag';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';

interface VariableLineProps {
  variable: Variable;
  openVariableStatusChangePopup: (id: string) => void;
  onEdit: (variableId: string) => void;
  searchInputValue: string;
  isActive: boolean;
}

const VisualDataTypeLabels = new Map<VisualDataType, string>([
  ['PhoneNumber', 'Phone Number'],
  ['IdentificationNumber', 'Identification Number'],
  ['EmailAddress', 'Email Address'],
  ['Percentage', 'Percentage (%)'],
  ['Date', 'Date'],
  ['Number', 'Number'],
  ['List', 'List'],
  ['Text', 'Text'],
  ['Boolean', 'Boolean'],
  ['Monetary', 'Monetary'],
]);

const VariableLine: FC<VariableLineProps> = ({
  variable,
  searchInputValue,
  onEdit,
  openVariableStatusChangePopup,
  isActive,
}) => {
  const { id, displayName, systemName, updateDate, updatedBy, description, isArchived } = variable;

  const changeVariableStatus = () => {
    openVariableStatusChangePopup(id);
  };

  const getFormattedVisualDataType = () => {
    const visualDataType = getVisualDataType(variable);

    if (visualDataType === 'Monetary') {
      const currencySymbol = getCurrencySymbol(variable.currency!) || '$';
      return `${VisualDataTypeLabels.get(visualDataType)} (${currencySymbol})`;
    }

    return VisualDataTypeLabels.get(visualDataType);
  };

  const getRowActions = () => {
    return isArchived
      ? [
          {
            title: 'Restore Variable',
            handler: changeVariableStatus,
          },
        ]
      : [
          {
            title: 'Edit Variable',
            handler: () => onEdit(id),
            separatorRequired: true,
          },
          {
            title: 'Archive Variable',
            handler: changeVariableStatus,
            danger: true,
          },
        ];
  };

  return (
    <div
      className={clsx(
        styles.variableLine,
        isActive && styles.variableLineHighlighted,
        isArchived && styles.variableLineInactive,
      )}
      onClick={() => onEdit(id)}
    >
      <TableRow>
        <TableBodyCell width={SIZES.DISPLAY_NAME} overflowed overflowContainerClassName={styles.variableLine__nameText}>
          <SearchHighlight search={searchInputValue}>{displayName}</SearchHighlight>
        </TableBodyCell>
        <TableBodyCell
          width={SIZES.SYSTEM_NAME}
          overflowed
          overflowContainerClassName={styles.variableLine__systemNameText}
        >
          <SearchHighlight search={searchInputValue}>{systemName}</SearchHighlight>
        </TableBodyCell>
        <TableBodyCell
          width={SIZES.DATA_TYPE}
          overflowed
          overflowContainerClassName={styles.variableLine__dataTypeText}
        >
          {getFormattedVisualDataType()}
        </TableBodyCell>
        <TableBodyCell width={SIZES.VARIABLE_TYPE}>
          <VariableTypeTag variable={variable} />
        </TableBodyCell>
        <TableBodyCell width={SIZES.UPDATED} noPadding>
          <div className={styles.creatorIcon}>
            <WithSystemApiUserAvatar user={updatedBy} size="small" />
          </div>
          <OverflowedText className={styles.variableLine__updatedText}>
            <DateTime time={updateDate} format={DateTimeFormat.Short} />
          </OverflowedText>
        </TableBodyCell>
        <TableBodyCell
          width={SIZES.DESCRIPTION}
          overflowed
          overflowContainerClassName={styles.variableLine__descriptionText}
        >
          <SearchHighlight search={searchInputValue}>{description || <TableBodyCellNoContent />}</SearchHighlight>
        </TableBodyCell>
        <RowActions actions={getRowActions()} />
      </TableRow>
    </div>
  );
};

export default VariableLine;
