import styles from './StrategyStatusLabel.module.scss';
import React from 'react';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import clsx from 'clsx';

const statusStyles = new Map<NormalizedStatus, string>([
  [NormalizedStatus.Active, styles.statusActive],
  [NormalizedStatus.Testing, styles.statusTesting],
  [NormalizedStatus.Archive, styles.statusArchive],
  [NormalizedStatus.Inactive, styles.statusInactive],
]);

const statusLabels = new Map<string, string>([
  [NormalizedStatus.Active, 'Active'],
  [NormalizedStatus.Testing, 'Testing'],
  [NormalizedStatus.Archive, 'Archived'],
  [NormalizedStatus.Inactive, 'Inactive'],
]);

const getStateColor = (status: NormalizedStatus) => statusStyles.get(status);
export const getStateLabel = (status: string) => statusLabels.get(status);

const StrategyStatusLabel = ({ status, className }: { status: NormalizedStatus; className?: string }) => (
  <div className={clsx(styles.strategyStatus, getStateColor(status), className)}>{getStateLabel(status)}</div>
);

export default StrategyStatusLabel;
