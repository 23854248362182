import React, { FC, useMemo } from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { BorrowerProfileCard } from 'api/LoanOriginationSystem/BorrowerProfileCardsApi';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { getFormDataByVariableConfigurations } from 'components/LoanOriginationSystem/EditApplication/utils';
import { getCardsFields } from 'components/LoanOriginationSystem/CardsForm/utils';
import { formatDisplayTitle, getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import ExistingProfile from 'components/LoanOriginationSystem/CreateApplication/ExistingProfile';
import {
  convertBorrowerDefaultVariablesToCoBorrower,
  convertBorrowerSystemNameToCoBorrower,
} from 'utils/BorrowerVariableUtils';
import BorrowerIcon from 'components/LoanOriginationSystem/BorrowerIcon';

export interface ExistingCoBorrowerProfileProps {
  application: Application;
  personBorrowerProfileCards: BorrowerProfileCard[] | null;
  companyBorrowerProfileCards: BorrowerProfileCard[] | null;
  coBorrowerIndex: number;
  onEditData: (data: FormLayoutData) => Promise<void>;
  renderActions?: (disabled: boolean, areFieldsInvalid: boolean) => React.ReactNode;
}

const ExistingCoBorrowerProfile: FC<ExistingCoBorrowerProfileProps> = ({
  application,
  personBorrowerProfileCards,
  companyBorrowerProfileCards,
  coBorrowerIndex,
  onEditData,
  renderActions,
}) => {
  const coBorrowerProfileCards = application.coborrowerTypes[coBorrowerIndex] === BorrowerType.Person
    ? personBorrowerProfileCards
    : companyBorrowerProfileCards;

  const coBorrowerData = useMemo(() => {
    return getFormDataByVariableConfigurations(application, getCardsFields(coBorrowerProfileCards), (systemName) => {
      return convertBorrowerSystemNameToCoBorrower(systemName, coBorrowerIndex);
    });
  }, [application, coBorrowerProfileCards, coBorrowerIndex]);

  const convertedBorrowerDefaultVariables = useMemo(() => {
    return convertBorrowerDefaultVariablesToCoBorrower(BorrowerDefaultVariable, coBorrowerIndex);
  }, [coBorrowerIndex]);

  return (
    <ExistingProfile
      data={coBorrowerData}
      title={getBorrowerFullName(application.coborrowerTypes[coBorrowerIndex], application.variables, convertedBorrowerDefaultVariables)}
      titleIcons={<BorrowerIcon borrowerType={application.coborrowerTypes[coBorrowerIndex]} />}
      cards={coBorrowerProfileCards}
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
      onEditProfileData={onEditData}
      hideCloseButton
      renderActions={renderActions}
    />
  );
};

export default ExistingCoBorrowerProfile;
