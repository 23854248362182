import { LoanOriginationSystemLabelsState } from './Types';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { AnyAction } from 'redux';
import { initialState as labelsFiltersInitialState, loanOriginationSystemLabelsFilterReducer } from './Filters/Reducer';
import {
  CloseCreatePopUpAction,
  CloseDeleteLabelPopupAction,
  CloseUpdateLabelPopUpAction,
  CreateLabelAction,
  CreateLabelSuccessAction,
  DeleteLabelAction,
  DeleteLabelSuccessAction,
  GetLabelInfoAction,
  GetLabelInfoSuccessAction,
  GetLabelsSuccessAction,
  OpenCreatePopUpAction,
  OpenDeleteLabelPopupAction,
  OpenUpdateLabelPopUpAction,
  SortLabelsAction,
  UpdateLabelAction,
  UpdateLabelSuccessAction,
} from './ActionCreator';
import labelsPagination, { LABELS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/LabelsDetails/Pagination';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';
import { LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import applicationLabelsSortingSaver from './SortingSaver';

export const getInitialState = (): LoanOriginationSystemLabelsState => ({
  filters: labelsFiltersInitialState,
  items: [],
  page: 1,
  itemsPerPage: LABELS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  isLoading: false,
  searched: {},
  sortingType: applicationLabelsSortingSaver.getSavedSorting() || {
    field: LabelsSortingField.UpdatedAt,
    ascending: false,
  },
  labelIdToDelete: null,
  blockingActionInProgress: false,
  isCreateLabelPopUpOpen: false,
  labelIdToUpdate: null,
  labelsDetailedAttributes: {},
});

type LoanOriginationSystemLabelsActions =
  | GetLabelsSuccessAction
  | SortLabelsAction
  | OpenDeleteLabelPopupAction
  | CloseDeleteLabelPopupAction
  | DeleteLabelAction
  | DeleteLabelSuccessAction
  | FailedAction<LoanOriginationSystemLabelsActionTypes.DeleteLabelFailure>
  | OpenCreatePopUpAction
  | CloseCreatePopUpAction
  | CreateLabelAction
  | CreateLabelSuccessAction
  | FailedAction<LoanOriginationSystemLabelsActionTypes.CreateLabelFailure>
  | OpenUpdateLabelPopUpAction
  | CloseUpdateLabelPopUpAction
  | GetLabelInfoSuccessAction
  | GetLabelInfoAction
  | FailedAction<LoanOriginationSystemLabelsActionTypes.GetLabelInfoFailure>
  | UpdateLabelAction
  | UpdateLabelSuccessAction
  | FailedAction<LoanOriginationSystemLabelsActionTypes.UpdateLabelFailure>;

type LoanOriginationSystemLabelsType = (
  state: LoanOriginationSystemLabelsState,
  action: AnyAction,
) => LoanOriginationSystemLabelsState;

const loanOriginationSystemLabelsReducer = (
  state = getInitialState(),
  action: LoanOriginationSystemLabelsActions,
): LoanOriginationSystemLabelsState => {
  switch (action.type) {
    case LoanOriginationSystemLabelsActionTypes.GetLabelsSuccess: {
      const { items, total } = action.payload;
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items,
              itemsTotal: total,
              page: 1,
              itemsPerPage: LABELS_PER_PAGE_DEFAULT,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || LABELS_PER_PAGE_DEFAULT,
        error: null,
        items,
        itemsTotal: total,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.SortLabels: {
      return {
        ...state,
        sortingType: action.payload,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.SetLabelToDeleteId: {
      return {
        ...state,
        labelIdToDelete: action.payload,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CloseDeleteLabelPopUp: {
      return {
        ...state,
        labelIdToDelete: null,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.DeleteLabel: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.DeleteLabelSuccess: {
      return {
        ...state,
        labelIdToDelete: null,
        blockingActionInProgress: false,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.DeleteLabelFailure: {
      const { message } = action.payload;

      return {
        ...state,
        labelIdToDelete: null,
        blockingActionInProgress: false,
        error: message,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.OpenCreateLabelPopUp: {
      return {
        ...state,
        isCreateLabelPopUpOpen: true,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CloseCreateLabelPopUp: {
      return {
        ...state,
        isCreateLabelPopUpOpen: false,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CreateLabel: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CreateLabelSuccess: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CreateLabelFailure: {
      const { message } = action.payload;

      return {
        ...state,
        blockingActionInProgress: false,
        error: message,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.OpenUpdateLabelPopUp: {
      const { payload } = action;
      return {
        ...state,
        labelIdToUpdate: payload,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.CloseUpdateLabelPopUp: {
      return {
        ...state,
        labelIdToUpdate: null,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.GetLabelInfo: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.GetLabelInfoSuccess: {
      const { id, name, color } = action.payload;
      return {
        ...state,
        labelsDetailedAttributes: {
          ...state.labelsDetailedAttributes,
          [id]: {
            name,
            color,
          },
        },
        isLoading: false,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.GetLabelInfoFailure: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.UpdateLabel: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case LoanOriginationSystemLabelsActionTypes.UpdateLabelSuccess: {
      const { id, name, color } = action.payload;
      return {
        ...state,
        blockingActionInProgress: false,
        labelIdToUpdate: null,
        labelsDetailedAttributes: {
          ...state.labelsDetailedAttributes,
          [id]: {
            name,
            color,
          },
        },
      };
    }
    case LoanOriginationSystemLabelsActionTypes.UpdateLabelFailure: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    default: {
      return {
        ...state,
        filters: loanOriginationSystemLabelsFilterReducer(state.filters, action),
      };
    }
  }
};

export default labelsPagination.wrapReducer<LoanOriginationSystemLabelsState>(
  withStateReset(
    loanOriginationSystemLabelsReducer,
    LoanOriginationSystemLabelsActionTypes.ResetState,
    getInitialState,
  ) as LoanOriginationSystemLabelsType,
);
