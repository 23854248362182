import React, { FC, useRef, useState } from 'react';
import { Line } from '@visx/shape';
import { Point } from 'components/Charts/components/Types';
import ChartTooltip from 'components/Charts/components/ChartTooltip';
import ChartLine from 'components/Charts/components/ChartLine';
import ChartAxisGrid from 'components/Charts/components/ChartAxisGrid';
import getLineChartTooltip from 'components/Charts/components/useLineChartTooltip';
import useYScale from 'components/Charts/components/useYScale';
import useXScale from 'components/Charts/components/useXScale';
import styles from './LineChart.module.scss';
import { max } from 'lodash';

const PADDINGS = {
  LEFT: 5,
  RIGHT: 5,
  TOP: 16,
  BOTTOM: 24,
};

// This number was calculated manually, so at least 31 items can fit the chart with circles shown on a general screen
const MINIMUM_STEP_BETWEEN_POINTS = 20;

export type FormatXValueType = (value: number) => string;
export type FormatYValueType = (value: number) => string;
export type FormatTooltipData = (point: Point) => { title: string; subTitle: string };

interface LineChartProps {
  width: number;
  height: number;
  points: Point[];
  formatXValue: (value: number) => string;
  formatYValue: (value: number) => string;
  formatTooltipData: (point: Point) => { title: string; subTitle: string };
  forceMaxY?: number;
  forceStartXAxisValue?: number;
  forceEndXAxisValue?: number;
}

const LineChart: FC<LineChartProps> = ({
  width,
  height,
  points,
  formatXValue,
  formatYValue,
  formatTooltipData,
  forceMaxY,
  forceStartXAxisValue,
  forceEndXAxisValue,
}) => {
  const containerRef = useRef<SVGSVGElement>(null);

  const correctedHeight = height - PADDINGS.TOP - PADDINGS.BOTTOM;
  const correctedWidth = width - PADDINGS.LEFT - PADDINGS.RIGHT;

  const maxY = max(points.map(({ y }) => y)) || 1;

  const [xScale] = useXScale(
    points.map(({ x }) => x),
    correctedWidth,
  );

  const [yScale, yScaleLinear] = useYScale(forceMaxY || maxY, correctedHeight);

  const [tooltipPoint, setTooltipPoint] = useState<Point | null>(null);

  const [handleMouseMove, handleMouseLeave] = getLineChartTooltip(containerRef, xScale, points, setTooltipPoint);

  const getTooltipPosition = () => {
    if (!tooltipPoint) {
      return null;
    }

    const rect = containerRef.current!.getBoundingClientRect();

    const circleX = xScale(tooltipPoint.x)!;
    const circleY = yScaleLinear(tooltipPoint.y);

    return {
      x: circleX + rect.left + PADDINGS.LEFT,
      y: circleY + rect.top + PADDINGS.TOP,
    };
  };

  const chartPoints = points.map(({ x, y }) => {
    return {
      x: xScale(x) || 0,
      y: yScaleLinear(y) || 0,
      hovered: x === tooltipPoint?.x,
    };
  });

  const tooltipData = tooltipPoint && formatTooltipData(tooltipPoint);
  const tooltipPosition = tooltipPoint && getTooltipPosition();

  return (
    <svg
      className={styles.container}
      width={width}
      height={height}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
    >
      <ChartAxisGrid
        width={width}
        height={height}
        topPadding={PADDINGS.TOP}
        bottomPadding={PADDINGS.BOTTOM}
        leftPadding={PADDINGS.LEFT}
        rightPadding={PADDINGS.RIGHT}
        xScale={xScale}
        yScale={yScale}
        formatXValue={formatXValue}
        formatYValue={formatYValue}
        forceStartXAxisValue={forceStartXAxisValue}
        forceEndXAxisValue={forceEndXAxisValue}
      />
      {chartPoints.length > 0 && (
        <ChartLine
          data={chartPoints}
          transform={`translate(${PADDINGS.LEFT}, ${PADDINGS.TOP})`}
          getX={(d) => d.x}
          getY={(d) => d.y}
          gradientTargetY={yScaleLinear(0)}
          padding={PADDINGS.LEFT}
          hidePoints={correctedWidth / points.length < MINIMUM_STEP_BETWEEN_POINTS}
        />
      )}
      {tooltipPoint && (
        <Line
          x1={(xScale(tooltipPoint.x) || 0) + PADDINGS.LEFT}
          y1={(yScale(maxY) || 0) + PADDINGS.TOP}
          x2={(xScale(tooltipPoint.x) || 0) + PADDINGS.LEFT}
          y2={(yScale(0) || 0) + PADDINGS.TOP}
          stroke={styles.chartLineColor}
        />
      )}
      {tooltipPoint && (
        <ChartTooltip
          className={styles.tooltip}
          x={tooltipPosition!.x}
          y={tooltipPosition!.y}
          xData={tooltipData!.title}
          yData={tooltipData!.subTitle}
        />
      )}
    </svg>
  );
};

export default LineChart;
