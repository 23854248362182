import Fetcher from 'middlewares/Fetcher';
import {
  GetBorrowersAction,
  getBorrowersForEmailsFilterFailure,
  getBorrowersForEmailsFilterSuccess,
} from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import {
  LoanOriginationSystemBorrowersApi,
  BaseBorrowerInfo,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const GetBorrowersMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<BaseBorrowerInfo[], GetBorrowersAction>(
    LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilter,
    getBorrowersForEmailsFilterSuccess,
    getBorrowersForEmailsFilterFailure,
    async () => {
      const borrowers = await api.getCompactBorrowersData();

      return borrowers;
    },
  );

export default GetBorrowersMiddleware;
