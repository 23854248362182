import webhookEndpointsPagination from './Pagination';
import { WebhookEndpointsApi } from 'api/Webhooks/WebhookEndpointsApi';
import { saveWebhookEndpoints } from 'WebhookEndpoints/WebhookEndpointsStore';

export default (api: WebhookEndpointsApi) => {
  return webhookEndpointsPagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items, total } = await api.find({
      count: params.perPage,
      offset: (params.page - 1) * params.perPage,
    }, params.sortingType);

    dispatch(saveWebhookEndpoints(items));

    return {
      items: items.map((item) => item.id),
      total,
    };
  });
};
