import React, { FC, useRef } from 'react';
import QuestionIcon from 'components/QuestionIcon';
import styles from './ChartLayout.module.scss';
import { useResizeDetector } from 'react-resize-detector';
import clsx from 'clsx';

interface ChartLayoutProps {
  title: string;
  secondaryTitle: string;
  tooltip: string;
  className?: string;
  onResize?: (width: number, height: number) => void;
}

const ChartLayout: FC<ChartLayoutProps> = ({ title, secondaryTitle, tooltip, className, children, onResize }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const paddings = {
    top: parseInt(styles.paddingTop, 10),
    bottom: parseInt(styles.paddingBottom, 10),
  };

  useResizeDetector({
    targetRef: divRef,
    onResize: (width, height) => {
      if (width && height) {
        onResize?.(width, height - paddings.top - paddings.bottom);
      }
    },
    handleHeight: onResize !== null,
    handleWidth: onResize !== null,
  });

  return (
    <div className={clsx(styles.container, className)} ref={divRef}>
      <div className={styles.titleLine}>
        <p className={styles.title}>{title}</p>
        <QuestionIcon tooltipClassName={styles.hintTooltip} className={styles.hint} tooltip={tooltip} size={20} />
        <p className={styles.secondaryTitle}>{secondaryTitle}</p>
      </div>
      <div className={styles.chart}>{children}</div>
    </div>
  );
};

export default ChartLayout;
