import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import LoanOriginationSystemReportingRestApi, {
  ReportingDashboardData,
} from 'api/LoanOriginationSystem/LoanOriginationSystemReportingApi';
import responseHandlers from 'api/ResponseHandlers';
import withStateReset from 'utils/reducers/withStateReset';
import ReportingDashboardRange from './ReportingDashboardRange';
import LoanOriginationSystemReportingDefaultFilters, {
  getEndOfTheDay,
} from './LoanOriginationSystemReportingDefaultFilters';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import reportingFilterSaver from './FilterSaver';

export enum ReportingDashboardStep {
  GrowthMetrics = 'Growth Metrics',
  PerformanceIndicators = 'Performance Indicators',
}

export type ReportingDashboardPeriod = 'Annually' | 'Monthly' | 'Daily';

export interface ReportingDashboardAdditionalFiltersModel {
  organizationMemberId?: string;
  intermediaryId?: string;
  productId?: string;
}

export interface ReportingDashboardFiltersModel {
  from: Date;
  to: Date;
  range: ReportingDashboardRange;
  period: ReportingDashboardPeriod;
}

export interface ReportingDashboardState {
  additionalFiltersPopupShown: boolean;
  additionalFilters: ReportingDashboardAdditionalFiltersModel;
  step: ReportingDashboardStep;
  filters: ReportingDashboardFiltersModel;
  data: ReportingDashboardData;
  isLoading: boolean;
}

export interface GetReportingDashboardDataPayload {
  filters: ReportingDashboardFiltersModel;
  additionalFilters: ReportingDashboardAdditionalFiltersModel;
}

const getInitialState = (): ReportingDashboardState => ({
  additionalFiltersPopupShown: false,
  step: ReportingDashboardStep.GrowthMetrics,
  filters: {
    from: LoanOriginationSystemReportingDefaultFilters[ReportingDashboardRange.LastThirtyDays](),
    to: getEndOfTheDay(),
    range: ReportingDashboardRange.LastThirtyDays,
    period: 'Daily',
    ...reportingFilterSaver.getSavedFilters()?.filters,
  },
  data: {},
  additionalFilters: { ...reportingFilterSaver.getSavedFilters()?.additionalFilters },
  isLoading: false,
});

const newLoanOriginationSystemReporting = new LoanOriginationSystemReportingRestApi(responseHandlers);

export const getReportingDashboardData = createAsyncThunk(
  LoanOriginationSystemReportingActionType.GetReportingDashboardData,
  async (payload: GetReportingDashboardDataPayload) => {
    const chartData = await newLoanOriginationSystemReporting.getAllData({
      from: payload.filters.from,
      to: payload.filters.to,
      period: payload.filters.period,
      intermediaryId: payload.additionalFilters.intermediaryId,
      organizationMemberId: payload.additionalFilters.organizationMemberId,
      productId: payload.additionalFilters.productId,
    });

    return chartData;
  },
);

export const setReportingDashboardStep = createAction<{ step: ReportingDashboardStep }>(
  LoanOriginationSystemReportingActionType.SetReportingDashboardStep,
);

export const showReportingDashboardAdditionalFiltersPopup = createAction(
  LoanOriginationSystemReportingActionType.ShowReportingDashboardAdditionalFiltersPopup,
);

export const hideReportingDashboardAdditionalFiltersPopup = createAction(
  LoanOriginationSystemReportingActionType.HideReportingDashboardAdditionalFiltersPopup,
);

export const setReportingDashboardIsLoading = createAction<boolean>(
  LoanOriginationSystemReportingActionType.SetReportingDashboardIsLoading,
);

const ReportingDashboardReducer = createReducer<ReportingDashboardState>(getInitialState(), (builder) => {
  builder.addCase(getReportingDashboardData.pending, (state, action) => {
    return {
      ...state,
      filters: action.meta.arg.filters,
      additionalFilters: action.meta.arg.additionalFilters,
    };
  });

  builder.addCase(getReportingDashboardData.fulfilled, (state, action) => {
    return {
      ...state,
      data: action.payload,
    };
  });

  builder.addCase(setReportingDashboardStep, (state, action) => {
    return {
      ...state,
      step: action.payload.step,
    };
  });

  builder.addCase(showReportingDashboardAdditionalFiltersPopup, (state) => {
    return {
      ...state,
      additionalFiltersPopupShown: true,
    };
  });

  builder.addCase(hideReportingDashboardAdditionalFiltersPopup, (state) => {
    return {
      ...state,
      additionalFiltersPopupShown: false,
    };
  });

  builder.addCase(setReportingDashboardIsLoading, (state, action) => {
    return {
      ...state,
      isLoading: action.payload,
    };
  });
});

export default withStateReset(
  ReportingDashboardReducer,
  LoanOriginationSystemReportingActionType.ResetState,
  getInitialState,
);
