import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DataFilter, DataViewSortingType, TableViewData } from 'api/Types';
import WebhooksSystemApi from './WebhooksSystemApi';

export interface WebhookEndpoint {
  id: string;
  url: string;
  active: boolean;
  events: string[];
  endpointSecret: string;
  description?: string;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
  updatedAt: Date;
}

export interface CreateWebhookEndpointParams {
  url: string;
  active: boolean;
  events: string[];
  description?: string;
}

export interface UpdateWebhookEndpointParams {
  url?: string;
  active?: boolean;
  description?: string;
  events?: string[];
}

export enum WebhookEndpointSortingField {
  Url = 'url',
  Description = 'description',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type WebhookEndpointSortingType = DataViewSortingType<WebhookEndpointSortingField>;

export interface WebhookEndpointsApi {
  find(params?: DataFilter, sortingType?: WebhookEndpointSortingType): Promise<TableViewData<WebhookEndpoint>>;
  findById(id: string): Promise<WebhookEndpoint>;
  create(params: CreateWebhookEndpointParams): Promise<WebhookEndpoint>;
  update(id: string, params: UpdateWebhookEndpointParams): Promise<WebhookEndpoint>;
  generateNewEndpointSecret(id: string): Promise<string>;
  delete(id: string): Promise<WebhookEndpoint>;
}

export default class WebhookEndpointsRestApi extends WebhooksSystemApi<WebhookEndpoint>
  implements WebhookEndpointsApi {
  protected resourceName = 'webhooks';

  public find(filters?: DataFilter, sortingType?: WebhookEndpointSortingType) {
    return this.getResources<TableViewData<WebhookEndpoint>>(this.getPaginationUrlSearchParams(filters, sortingType));
  }

  public findById(id: string) {
    return this.getResourceById(id);
  }

  public create(params: CreateWebhookEndpointParams) {
    return this.createResource(params);
  }

  public update(id: string, params: UpdateWebhookEndpointParams) {
    return this.updateResource(id, params);
  }

  public async generateNewEndpointSecret(id: string): Promise<string> {
    const { endpointSecret } = await this.fetch<{ endpointSecret: string }>(
      `/${this.resourceName}/${id}/generate-new-secret`,
      'PUT'
    );

    return endpointSecret;
  }

  public delete(id: string) {
    return this.deleteResource<WebhookEndpoint>(id);
  }
}
