import React, { FC, useCallback, useState } from 'react';
import AutoCompletion from 'components/AutoCompletion';
import styles from 'components/AddModulePage/ModuleSelector.module.scss';
import { ModuleItem } from 'api/Types';
import { Option } from 'components/SelectInput/SelectInput';
import { useDecisionEngineApi } from 'providers/ApiServiceProvider';

interface ExistingModuleSelectorProps {
  handleModuleChange: (params: ModuleItem) => void;
  selectedModuleParams: ModuleItem | null;
}

const ModuleSelector: FC<ExistingModuleSelectorProps> = ({ handleModuleChange, selectedModuleParams }) => {
  const [selectedModuleId, setModuleId] = useState(selectedModuleParams ? selectedModuleParams.id : '');
  const [modules, setModules] = useState<ModuleItem[]>(selectedModuleParams ? [selectedModuleParams] : []);

  const decisionEngineApi = useDecisionEngineApi();

  const formatModule = ({ id, moduleName, strategyName }: ModuleItem) => ({
    value: id,
    name: `${strategyName} | ${moduleName}`,
  });

  const fetchModules = useCallback(
    async (name: string, abortSignal: AbortSignal) => {
      if (!name) {
        return modules.map(formatModule);
      }

      const fetchedModules = await decisionEngineApi.findModule(name, abortSignal);
      setModules(fetchedModules);
      return fetchedModules.map(formatModule);
    },
    [selectedModuleParams],
  );

  const handleModuleNameChange = (option: Option) => {
    setModuleId(option.value);
    const moduleData = modules.find(({ id }) => id === option.value)!;

    handleModuleChange(moduleData);
  };

  return (
    <AutoCompletion
      className={styles.moduleSelector}
      labelTitle="Module Name"
      value={selectedModuleId}
      onChange={handleModuleNameChange}
      fetchOptions={fetchModules}
      optionsListClassName={styles.modulesListInput}
      placeholder="Search"
      lightLabel
    />
  );
};

export default ModuleSelector;
