import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './InfoPanelColumn.module.scss';
import { useResizeDetector } from 'react-resize-detector';
import OverflowedText from 'components/OverflowedText';

interface InfoPanelColumnItem {
  label: string;
  content: React.ReactNode;
  labelClassName?: string;
  contentClassName?: string;
  useOverflowTextContainer?: boolean;
  tooltipOnHover?: React.ReactNode;
}

export interface InfoPanelColumnProps {
  tableView?: boolean;
  title: string;
  titleContent: React.ReactNode;
  items: InfoPanelColumnItem[];
  isOpen?: boolean;
}

const TWO_COLUMNS_WIDTH_BREAKPOINT = 440;
const COLLAPSED_HEADER_WIDTH_BREAKPOINT = 300;

const InfoPanelColumn: FC<InfoPanelColumnProps> = ({
  titleContent,
  title,
  items,
  isOpen,
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const [displayItemInTwoColumns, setDisplayItemsInTwoColumns] = useState(true);
  const [collapseHeader, setCollapseHeader] = useState(false);

  useResizeDetector({
    targetRef: columnRef,
    onResize: (width) => {
      if (!width) {
        return;
      }

      setDisplayItemsInTwoColumns(width >= TWO_COLUMNS_WIDTH_BREAKPOINT);
      setCollapseHeader(width <= COLLAPSED_HEADER_WIDTH_BREAKPOINT);
    },
    handleHeight: false,
  });

  const renderItem = (item: InfoPanelColumnItem, index: number) => {
    const wrapToOverflowTextContainer = (content: React.ReactNode) => {
      return (
        <OverflowedText
          useTooltip={!isOpen}
          tooltipOnHover={item.tooltipOnHover}
        >
          {content}
        </OverflowedText>
      );
    };

    return (
      <div
        key={`${item.label}-${index}`}
        className={styles.infoPanelColumnItem}
      >
        <div className={clsx(styles.infoPanelColumnItemLabel, item.labelClassName)}>
          {item.label}
        </div>
        <div className={clsx(styles.infoPanelColumnItemContent, item.contentClassName)}>
          {item.useOverflowTextContainer ? wrapToOverflowTextContainer(item.content) : item.content}
        </div>
      </div>
    );
  };

  const infoPanelColumnItemsStyle = clsx(
    styles.infoPanelColumnItems,
    displayItemInTwoColumns && styles.twoColumnsInfoPanelColumnItems,
    isOpen && styles.openedInfoPanelColumnsItem,
  );

  return (
    <div ref={columnRef} className={styles.infoPanelColumn}>
      <div className={clsx(styles.infoPanelColumnHeader, collapseHeader && styles.collapsedHeader)}>
        <p className={styles.title}>{title}:{' '}</p>
        <div className={styles.titleContent}>{titleContent}</div>
      </div>
      <div className={infoPanelColumnItemsStyle}>
        {items.map(renderItem)}
      </div>
    </div>
  );
};

export default InfoPanelColumn;
