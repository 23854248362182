import React from 'react';
import { RelatedApplicationsTableColumnSizesType } from 'components/LoanOriginationSystem/RelatedApplicationsTable/TableColumnSizes';
import { ApplicationTableRowProps } from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable/ApplicationTableRow';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import ApplicationLabels from 'components/LoanOriginationSystem/ApplicationLabels';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import styles from './RelatedApplicationTableRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { StandardVariables } from 'Variables/VariablesTypes';
import WrapperWithTooltip from 'components/Tooltip';
import CoBorrowersInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';
import { DuplicateImage } from 'static/images';

export interface RelatedApplicationTableRowProps
  extends Omit<
    ApplicationTableRowProps,
    'currencySymbol' | 'deleteDisabled' | 'searchInputValue' | 'tableSize' | 'variableConfigurations'
  > {
  standardVariables: StandardVariables;
  showBorrowerColumn?: boolean;
  columnSizes: RelatedApplicationsTableColumnSizesType;
}

const RelatedApplicationTableRow = ({
  application,
  standardVariables,
  onClick,
  showBorrowerColumn,
  columnSizes,
}: RelatedApplicationTableRowProps) => {
  const loanAmountDisplayValue = formatMonetaryValue(
    application.variables[ApplicationDefaultVariable.LoanAmount] as number,
    getFormattedLoanAmountCurrency(standardVariables) || '',
  );

  return (
    <TableRow key={application.id} onClick={() => onClick(application.displayId)}>
      {showBorrowerColumn && (
        <TableBodyCell width={columnSizes.BorrowerFullName} className={styles.nameCell} noPadding>
          <OverflowedText>{application.borrowerFullName}</OverflowedText>
          <CoBorrowersInfo coBorrowerFullNames={application.coborrowerFullNames} />
        </TableBodyCell>
      )}
      <TableBodyCell width={columnSizes.Product} className={styles.productCell}>
        {!!application.labels.length && <ApplicationLabels labels={application.labels} />}
        <OverflowedText className={styles.productLine}>{application.productName}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={columnSizes.Id} noPadding>
        <OverflowedText>{formatDisplayId(application.displayId)}</OverflowedText>
        {application.originalApplicationId && (
          <WrapperWithTooltip tooltip="Duplicated Application">
            <DuplicateImage className={styles.duplicateIcon} />
          </WrapperWithTooltip>
        )}
      </TableBodyCell>
      <TableBodyCell width={columnSizes.ApplicationDate} overflowed>
        {formatDate(application.createdAt, DateTimeFormat.Short)}
      </TableBodyCell>
      <TableBodyCell width={columnSizes.Status} overflowed>
        {application.status.name}
      </TableBodyCell>
      <TableBodyCell width={columnSizes.LoanAmount} className={styles.loanAmountCell} overflowed>
        {loanAmountDisplayValue}
      </TableBodyCell>
    </TableRow>
  );
};

export default RelatedApplicationTableRow;
