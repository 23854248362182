import React, { useEffect, useState } from 'react';
import { CloseButtonImage } from 'static/images';
import areArraysEqual from 'utils/areArraysEqual';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  Product,
  ProductType,
  UpdateProductParams,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import ProductBasicInfoForm from 'components/LoanOriginationSystem/ProductBasicInfoForm';
import Button from 'components/Button';
import styles from './EditProduct.module.scss';

export interface EditProductProps {
  product: Product;
  onClose: () => void;
  onEdit: (data: Partial<UpdateProductParams>) => void;
  isUpdateInProgress?: boolean;
}

const EditProduct = ({ product, onClose, onEdit, isUpdateInProgress }: EditProductProps) => {
  const [productName, setProductName] = useState(product.name);
  const [productBorrowerTypes, setProductBorrowerTypes] = useState(product.borrowerTypes);
  const [productType, setProductType] = useState(product.type);

  useEffect(() => {
    setProductName(product.name);
    setProductBorrowerTypes(product.borrowerTypes);
    setProductType(product.type);
  }, [product]);

  const handleProductNameChange = (newProductName: string) => setProductName(newProductName);
  const handleBorrowerTypesChange = (newBorrowerTypes: BorrowerType[]) => setProductBorrowerTypes(newBorrowerTypes);
  const handleProductTypeChange = (newType: ProductType) => setProductType(newType);

  const getDataToSave = () => {
    return {
      ...(productName !== product.name ? { name: productName } : {}),
      ...(productType !== product.type ? { type: productType } : {}),
      ...(!areArraysEqual(productBorrowerTypes, product.borrowerTypes) ? { borrowerTypes: productBorrowerTypes } : {}),
    };
  }

  const isDataValid = () => productName.trim() && productBorrowerTypes.length > 0;
  const isDataChanged = () => Object.keys(getDataToSave()).length > 0;

  const handleProductEdit = async () => {
    onEdit(getDataToSave());
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Edit Basic Information</h2>
      <ProductBasicInfoForm
        productName={productName}
        borrowerTypes={productBorrowerTypes}
        productType={productType}
        onProductNameChange={handleProductNameChange}
        onBorrowerTypesChange={handleBorrowerTypesChange}
        onProductTypeChange={handleProductTypeChange}
      />
      <Button
        className={styles.editProductButton}
        onClick={handleProductEdit}
        kind="primary"
        size="form"
        isLoading={isUpdateInProgress}
        disabled={!isDataValid() || !isDataChanged()}
      >
        Save Changes
      </Button>
      <CloseButtonImage onClick={onClose} className={styles.closeImage} />
    </div>
  );
};

export default EditProduct;
