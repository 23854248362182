import React, { ReactElement, FocusEvent } from 'react';

import PhoneInput, {
  // @ts-ignore
  formatPhoneNumber,
  // @ts-ignore
  getCountries,
  // @ts-ignore
  getCountryCallingCode,
  // @ts-ignore
  isValidPhoneNumber,
} from 'react-phone-number-input/input';
// @ts-ignore
import flags from 'react-phone-number-input/flags';

type StandardInputPropsWithoutOnChange = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
>;

export interface PhoneInputProps extends StandardInputPropsWithoutOnChange {
  value?: string;
  placeholder?: string;
  country: string | null;
  onChange: (value: string | undefined) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

const PhoneInputTyped = PhoneInput as (props: PhoneInputProps) => ReactElement;
const formatPhoneNumberTyped = formatPhoneNumber as (phoneNumber: string) => string;
const getCountriesTyped = getCountries as () => string[];
const getCountryCallingCodeTyped = getCountryCallingCode as (countryCode: string) => string;
const isValidPhoneNumberTyped = isValidPhoneNumber as (phoneNumber: string) => boolean;
const flagsTyped = flags as ReactElement[];

export default PhoneInputTyped;

export {
  getCountriesTyped as getCountries,
  getCountryCallingCodeTyped as getCountryCallingCode,
  flagsTyped as flags,
  formatPhoneNumberTyped as formatPhoneNumber,
  isValidPhoneNumberTyped as isValidPhoneNumber,
};
