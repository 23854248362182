import React from 'react';
import styles from './Title.module.scss';
import FilterButton from 'components/FilterButton';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

interface TitleProps {
  hideItem: boolean;
  onSearch: (search: string) => void;
  search: string;
  onFilterButtonClick: () => void;
  members: UserInfo[];
  selectedMembers: UserInfo[];
  onMemberFilterChange: (members: UserInfo[]) => void;
  onCreateNewTask: () => void;
  size: 'small' | 'normal';
  filtersChanged: boolean;
  areTasksLoading: boolean;
}

const Title = ({
  hideItem,
  onSearch,
  onFilterButtonClick,
  members,
  selectedMembers,
  onMemberFilterChange,
  onCreateNewTask,
  size,
  search,
  filtersChanged,
  areTasksLoading,
}: TitleProps) => {
  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const onSearchClear = () => {
    onSearch('');
  };

  return (
    <div className={size === 'normal' ? styles.title : styles.titleSmall}>
      <div className={styles.titleInner}>
        <h2 className={styles.titleName}>Tasks</h2>
        <div className={styles.buttonsInner}>
          <Button kind="primary" className={styles.createTaskButton} onClick={onCreateNewTask}>
            Create Task
          </Button>
        </div>
      </div>
      <div>
        {!hideItem && (
          <div className={styles.searchInner}>
            <div className={styles.filtersContainer}>
              <SearchInput
                onChange={onSearchInputChange}
                onClear={onSearchClear}
                placeholder="Search"
                containerClassName={styles.search}
                value={search}
                showLoader={!!search && areTasksLoading}
              />
              <UserFilter users={members} selectedUsers={selectedMembers} onChange={onMemberFilterChange} />
            </div>
            <FilterButton onClick={onFilterButtonClick} active={filtersChanged} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
