import React from 'react';
import {
  IntermediariesSortingField,
  IntermediariesSortingType,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { NullableItems, PaginationProps } from 'pagination';
import { IntermediariesColumnSizes } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import useSorting from 'hooks/useSorting';
import styles from './IntermadiariesTable.module.scss';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonIntermediaryLine from './SkeletonIntermediaryLine';
import IntermediaryLine from './IntermediaryLine';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableActionCell from 'components/Table/TableActionCell';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { StandardVariables } from 'Variables/VariablesTypes';

interface IntermediariesTableProps {
  intermediaries: NullableItems<Intermediary>;
  standardVariables: StandardVariables | null;
  paginationProps: PaginationProps;
  onSort: (field: IntermediariesSortingField, ascending: boolean) => void;
  sortingType: IntermediariesSortingType;
  onDeleteIntermediaryPopupOpen: (intermediary: Intermediary) => Promise<void>;
  onEdit: (id: string) => void;
  searchInputValue: string;
}

const IntermediariesTable = ({
  intermediaries,
  standardVariables,
  paginationProps,
  onSort,
  sortingType,
  onDeleteIntermediaryPopupOpen,
  onEdit,
  searchInputValue,
}: IntermediariesTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonRow = (index: number) => (
    <SkeletonIntermediaryLine key={index} showSearchMatch={searchInputValue.trim().length > 0} />
  );

  const renderRow = (intermediary: Intermediary, index: number) => {
    if (!intermediary || !standardVariables) {
      return renderSkeletonRow(index);
    }

    return (
      <IntermediaryLine
        key={intermediary.id}
        intermediary={intermediary}
        standardVariables={standardVariables}
        onDeleteIntermediaryPopupOpen={onDeleteIntermediaryPopupOpen}
        onEdit={onEdit}
        searchInputValue={searchInputValue}
      />
    );
  };

  return (
    <div className={styles.intermediariesTableInner}>
      <TableWrapperWithFooter>
        <div className={styles.intermediariesTable}>
          <Table>
            <TableHead sticky>
              <TableHeadCell
                width={IntermediariesColumnSizes.Name}
                ascending={getSortingType(IntermediariesSortingField.IntermediaryName)}
                onClick={() => changeSorting(IntermediariesSortingField.IntermediaryName)}
              >
                Name
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.Type}
                ascending={getSortingType(IntermediariesSortingField.IntermediaryType)}
                onClick={() => changeSorting(IntermediariesSortingField.IntermediaryType)}
              >
                Type
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.IdNumber}
                ascending={getSortingType(IntermediariesSortingField.IdNumber)}
                onClick={() => changeSorting(IntermediariesSortingField.IdNumber)}
              >
                ID Number
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.Phone}
                ascending={getSortingType(IntermediariesSortingField.Phone)}
                onClick={() => changeSorting(IntermediariesSortingField.Phone)}
              >
                Phone
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.Email}
                ascending={getSortingType(IntermediariesSortingField.Email)}
                onClick={() => changeSorting(IntermediariesSortingField.Email)}
              >
                Email
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.CreatedAt}
                ascending={getSortingType(IntermediariesSortingField.CreatedAt)}
                onClick={() => changeSorting(IntermediariesSortingField.CreatedAt)}
              >
                Created
              </TableHeadCell>
              <TableHeadCell
                width={IntermediariesColumnSizes.UpdatedAt}
                ascending={getSortingType(IntermediariesSortingField.UpdatedAt)}
                onClick={() => changeSorting(IntermediariesSortingField.UpdatedAt)}
              >
                Updated
              </TableHeadCell>
              <TableActionCell />
            </TableHead>
            <TableBody>
              <TableBodyContent
                renderSkeletonTableRow={renderSkeletonRow}
                rows={intermediaries}
                renderTableRow={renderRow}
              />
            </TableBody>
          </Table>
          <Pagination {...paginationProps} />
        </div>
      </TableWrapperWithFooter>
    </div>
  );
};

export default IntermediariesTable;
