import { Middleware } from 'redux';

import OrganizationActionType from './ActionTypes';
import {
  GetOrganizationFreeSeatAction,
  getOrganizationFreeSeatFailure,
  getOrganizationFreeSeatSuccess,
} from './CompanyInformationActionCreator';

import { OrganizationApi } from 'api/Core/OrganizationApi';
import Fetcher from 'middlewares/Fetcher';
import SeatsResults from 'CompanyInformation/SeatsResults';

const GetOrganizationFreeSeatMiddleware: (api: OrganizationApi) => Middleware = (api) =>
  Fetcher<boolean, GetOrganizationFreeSeatAction, {}, Error, boolean>(
    OrganizationActionType.GetFreeSeat,
    getOrganizationFreeSeatSuccess,
    getOrganizationFreeSeatFailure,
    ({ payload: email }) => api.getFreeSeat(email),
    SeatsResults.HasFreeSeat,
    (response) => response,
  );

export default GetOrganizationFreeSeatMiddleware;
