import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';
import { DecisionRunResponse } from 'api/DecisionProcessing/DecisionRunApi';

export interface ApplicationDecisionRunParams {
  strategyName: string;
  strategyDisplayName: string;
  decisionName: string;
  applicationId: string;
}

interface ApplicationDecisionRunApi {
  runDecision(params: ApplicationDecisionRunParams): Promise<DecisionRunResponse>;
}

export default class ApplicationDecisionRunApiRest extends LoanOriginationSystemApi<DecisionRunResponse>
  implements ApplicationDecisionRunApi {
  protected resourceName = 'application-decision-processing';

  public async runDecision(params: ApplicationDecisionRunParams) {
    return this.createResource(params);
  }
}
