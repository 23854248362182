import { AnyAction } from 'redux';
import { RegionSettings } from './ActionTypes';
import { RegionSettingsState } from './Types';

const initialState: RegionSettingsState = {
  isUpdatingInProgress: false,
};

const RegionSettingsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RegionSettings.UpdateRegionSettings: {
      return { ...state, isUpdatingInProgress: true };
    }
    case RegionSettings.UpdateRegionSettingsSuccess:
    case RegionSettings.UpdateRegionSettingsFailure: {
      return { ...state, isUpdatingInProgress: false };
    }
    default: {
      return state;
    }
  }
};

export default RegionSettingsReducer;
