import { GetTableViewProductDataResult } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { GET_TABLE_VIEW_PRODUCT_DATA } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  getTableViewProductDataSuccess,
  getTableViewProductDataFailed,
  GetTableViewProductDataAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/ApplicationsDashboard/Pagination';
import { SimplifiedApplicationsApi } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';

const GetTableViewProductDataMiddleware = (api: SimplifiedApplicationsApi) =>
  Fetcher<GetTableViewProductDataResult, GetTableViewProductDataAction, ReduxState>(
    GET_TABLE_VIEW_PRODUCT_DATA,
    getTableViewProductDataSuccess,
    getTableViewProductDataFailed,
    (action, { loanOriginationSystemApplications }) => {
      const {
        filters = loanOriginationSystemApplications.filters,
        productId,
        sortingType = loanOriginationSystemApplications.tableViewSortingType,
        abortSignal,
      } = action.payload;

      return api.getAll(
        productId,
        {
          searchInputValue: filters.searchInputValue,
          selectedMembers: filters.selectedMembers,
          selectedStatusesIds: filters.selectedStatusesIds,
          selectedIntermediaries: filters.selectedIntermediaries,
          selectedLabels: filters.selectedLabels,
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
          pagination: {
            count: APPLICATIONS_PER_PAGE_DEFAULT,
            offset: 0,
          },
        },
        sortingType,
        abortSignal,
      );
    },
  );

export default GetTableViewProductDataMiddleware;
