import React from 'react';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import QuestionIcon from 'components/QuestionIcon';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';
import styles from './IntermediaryProfileConfigurationHeader.module.scss';

export interface IntermediaryProfileConfigurationHeaderProps {
  onDownloadApiTemplate: () => void;
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test1,
  ReactAppEnvironment.Test2,
);

const IntermediaryProfileConfigurationHeader = ({
  onDownloadApiTemplate,
}: IntermediaryProfileConfigurationHeaderProps) => {
  const renderTooltip = () => (
    <>
      <p>Customize your Intermediary profile data</p>
      <p>structure. Put permanent data about</p>
      <p>Intermediaries here and point-in-time data</p>
      <p>on Loan Products.</p>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.headerTitleWithTooltip}>
          <h2 className={styles.headerTitle}>Intermediary Profiles</h2>
          <QuestionIcon className={styles.questionIcon} tooltip={renderTooltip()} size={24} />
        </div>
        <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiTemplate} />
      </div>
    </div>
  );
};

export default IntermediaryProfileConfigurationHeader;
