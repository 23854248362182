/* eslint @typescript-eslint/no-unused-vars: 0 */

import { FormLayoutData, TableViewData, VariableValue } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BaseLoanOriginationSystemApplicationsFilters } from 'LoanOriginationSystemApplications/Filters/Types';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { ApplicationStatus } from './LoanOriginationSystemApplicationStatusesApi';

export interface UpdateApplicationModel {
  id: string;
  borrower?: FormLayoutData;
  applicationDetails?: FormLayoutData;
}

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  // TODO figure out when it's avatarUrl and when imageId
  avatarUrl?: string;
  active?: boolean;
  imageId?: string;
}

export interface Application {
  id: string;
  displayId: string;
  variables: Record<string, VariableValue>;
  status: ApplicationStatus;
  borrowerId: string;
  borrowerType: BorrowerType;
  coborrowerIds: string[];
  coborrowerTypes: BorrowerType[];
  intermediaryId?: string;
  declineReasons?: string[];
  teamMembers: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
  }[];
  labels: BaseLabelInfo[];
  createdAt: Date;
  updatedAt: Date;
  product: {
    id: string;
    name: string;
  };
  updatedBy?: UserInfo | null;
  createdBy?: UserInfo | null;
  rejectedAt?: Date;
  approvedAt?: Date;
  originalApplicationId?: string;
}

interface CreateApplicationBorrowerParams {
  type: BorrowerType;
  variables: FormLayoutData;
}

interface CreateApplicationIntermediaryParams {
  variables: FormLayoutData;
}

export interface CreateApplicationParams {
  productId: string;
  borrower: CreateApplicationBorrowerParams | string;
  coBorrowers: Array<CreateApplicationBorrowerParams | string>;
  intermediary: string | CreateApplicationIntermediaryParams | undefined;
  applicationDetails: FormLayoutData;
}

interface CountFilter {
  createdDateRange: BaseLoanOriginationSystemApplicationsFilters['createdDateRange'];
}

interface EditLabelsParams {
  labelsToAdd?: string[];
  labelsToDelete?: string[];
}

export interface UpdateApplicationIntermediaryParams {
  intermediary: CreateApplicationIntermediaryParams | string | null;
}

interface DeleteApplicationCoBorrowerParams {
  coBorrowerToDelete: string;
}

interface AddApplicationCoBorrowersParams {
  coBorrowersToAdd: Array<CreateApplicationBorrowerParams | string>;
}

export type UpdateApplicationCoBorrowersParams = DeleteApplicationCoBorrowerParams | AddApplicationCoBorrowersParams;

export interface LoanOriginationSystemApplicationsApi {
  getApplicationByDisplayId(displayId: string): Promise<Application>;
  changeApplicationStatus(id: string, statusId: string, declineReasons?: string[]): Promise<Application>;
  createApplication(params: CreateApplicationParams): Promise<Application>;
  updateApplication(data: UpdateApplicationModel): Promise<Application>;
  updateApplicationVariables(applicationId: string, variables: FormLayoutData): Promise<Application>;
  updateApplicationTeamMembers(applicationId: string, teamMemberIds: string[]): Promise<Application>;
  deleteApplication(applicationId: string): Promise<void>;
  duplicateApplication(applicationId: string): Promise<Application>;
  updateApplicationCoBorrowers(id: string, params: UpdateApplicationCoBorrowersParams): Promise<Application>;
  updateApplicationIntermediary(id: string, params: UpdateApplicationIntermediaryParams): Promise<Application>;
  updateApplicationIntermediaryVariables(id: string, variables: FormLayoutData): Promise<Application>;
  updateApplicationBorrowerVariables(id: string, borrowerId: string, variables: FormLayoutData): Promise<Application>;
  addCoborrower(applicationId: string, coborrowerId?: string, coborrowerData?: FormLayoutData): Promise<Application>;
  addIntermediary(
    applicationId: string,
    intermediaryId?: string,
    intermediaryData?: FormLayoutData,
  ): Promise<Application>;
  editLabels(applicationId: string, params: EditLabelsParams): Promise<Application>;
  getCount(filters: CountFilter): Promise<number>;
}

export default class LoanOriginationSystemApplicationsApiRest extends LoanOriginationSystemApi<Application>
  implements LoanOriginationSystemApplicationsApi {
  protected resourceName = 'applications';

  public async getCount(filters: CountFilter) {
    const params = this.getPaginationUrlSearchParams({ count: 1, offset: 0 });

    if (filters?.createdDateRange.from) {
      params.append('createdAtFrom', filters.createdDateRange.from.toISOString());
    }

    if (filters?.createdDateRange.to) {
      params.append('createdAtTo', filters.createdDateRange.to.toISOString());
    }

    const { total } = await this.getResources<TableViewData<Application>>(params);

    return total;
  }

  public getApplicationByDisplayId(displayId: string): Promise<Application> {
    return this.getResourceById(displayId);
  }

  public changeApplicationStatus(id: string, statusId: string, declineReasons?: string[]): Promise<Application> {
    return this.updateResource(id, { application: { statusId, declineReasons } });
  }

  public createApplication(params: CreateApplicationParams) {
    const { productId, applicationDetails, intermediary, coBorrowers, borrower } = params;

    const application = {
      productId,
      borrower,
      coBorrowers,
      intermediary,
      variables: applicationDetails,
    };

    return this.createResource<Application>({ application });
  }

  public updateApplication(data: UpdateApplicationModel): Promise<Application> {
    const application = {
      variables: {
        ...(data.borrower || {}),
        ...(data.applicationDetails || {}),
      },
    };

    return this.updateResource(data.id, { application });
  }

  public updateApplicationVariables(applicationId: string, variables: FormLayoutData) {
    return this.updateResource(applicationId, {
      application: {
        variables,
      },
    });
  }

  public updateApplicationTeamMembers(applicationId: string, teamMemberIds: string[]) {
    return this.updateResource(applicationId, {
      application: {
        teamMemberIds,
      },
    });
  }

  public updateApplicationCoBorrowers(id: string, params: UpdateApplicationCoBorrowersParams) {
    return this.fetch<Application>(`/${this.resourceName}/${id}/coborrowers`, 'PUT', params);
  }

  public updateApplicationIntermediary(id: string, params: UpdateApplicationIntermediaryParams) {
    return this.fetch<Application>(`/${this.resourceName}/${id}/intermediary`, 'PUT', params);
  }

  public updateApplicationBorrowerVariables(id: string, borrowerId: string, variables: FormLayoutData) {
    return this.fetch<Application>(`/${this.resourceName}/${id}/borrowers/${borrowerId}/variables`, 'PUT', {
      variables,
    });
  }

  public updateApplicationIntermediaryVariables(id: string, variables: FormLayoutData): Promise<Application> {
    return this.fetch<Application>(`/${this.resourceName}/${id}/intermediary/variables`, 'PUT', {
      variables,
    });
  }

  public deleteApplication(applicationId: string) {
    return this.deleteResource(applicationId);
  }

  public duplicateApplication(applicationId: string) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/duplicate`, 'POST');
  }

  public deleteCoborrower(applicationId: string, variablesToDelete: string[]) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/coborrower`, 'DELETE', {
      variablesToDelete,
    });
  }

  public deleteIntermediary(applicationId: string, variablesToDelete: string[]) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/intermediary`, 'DELETE', {
      variablesToDelete,
    });
  }

  public addCoborrower(applicationId: string, coborrowerId?: string, coborrowerData?: FormLayoutData) {
    const coborrower = !coborrowerData || coborrowerData.id ? undefined : { variables: coborrowerData };

    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/coborrower`, 'PUT', {
      coborrowerId,
      coborrower,
    });
  }

  public addIntermediary(applicationId: string, intermediaryId?: string, intermediaryData?: FormLayoutData) {
    const intermediary = !intermediaryData || intermediaryData.id ? undefined : { variables: intermediaryData };

    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/intermediary`, 'PUT', {
      intermediaryId,
      intermediary,
    });
  }

  public editLabels(applicationId: string, params: EditLabelsParams) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/labels`, 'PUT', params);
  }
}
