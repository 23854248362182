import React, { ChangeEvent, useState, FC } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import styles from './CreateOrRenameDocumentFolderPopUp.module.scss';

interface Folder {
  id: string;
  name: string;
}

interface CreateOrRenameDocumentFolderPopUpProps {
  create: (name: string) => Promise<unknown>;
  rename: (name: string) => Promise<unknown>;
  closePopUp: () => void;
  popUpType: string;
  folder: Folder | null;
  isLoading: boolean;
}

const MAX_FOLDER_NAME_LENGTH = 50;

const CreateOrRenameDocumentFolderPopUp: FC<CreateOrRenameDocumentFolderPopUpProps> = ({
  closePopUp,
  create,
  rename,
  popUpType,
  folder,
  isLoading,
}) => {
  const isCreateFolderPopup = popUpType.includes('create');
  const [folderName, setFolderName] = useState(isCreateFolderPopup ? '' : folder?.name);
  const popUpTitle = `${isCreateFolderPopup ? 'Create' : 'Rename'} Folder`;

  const handleFolderSave = (name: string) => {
    (isCreateFolderPopup ? create : rename)(name);
  };

  return (
    <PopUp title={popUpTitle} closePopUp={closePopUp} closable={!isLoading}>
      <PopUpContent>
        <TextInput
          labelTitle="Folder Name"
          value={folderName}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setFolderName(target.value)}
          maxLength={MAX_FOLDER_NAME_LENGTH}
        />
        <Button
          kind="primary"
          size="form"
          className={styles.submitButton}
          onClick={() => folderName && handleFolderSave(folderName.trim())}
          isLoading={isLoading}
          disabled={!folderName?.trim() || folderName.trim() === folder?.name}
        >
          {popUpTitle}
        </Button>
        <Button kind="secondary" size="form" onClick={closePopUp}>
          Cancel
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateOrRenameDocumentFolderPopUp;
