import { ItemsStateWithPagination } from 'pagination';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DataViewSortingType } from 'api/Types';

export interface DecisionStrategyUpdatesState {
  strategiesById: {
    [strategyId: string]: ItemsStateWithPagination<StrategyUpdateType>;
  };
  sortingType: DecisionStrategyUpdatesSortingType;
}

export interface StrategyUpdateType {
  changeType: string;
  updatedAt: string;
  updatedBy: UserInfo;
}

export type DecisionStrategyUpdatesSortingType = DataViewSortingType<DecisionStrategyUpdatesSortingField>;

export enum DecisionStrategyUpdatesSortingField {
  UpdateType = 'change_type',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedat',
}
