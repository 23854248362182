import React, { FC, ReactNode } from 'react';
import Portal from 'components/Portal';
import styles from './ChartTooltip.module.scss';
import clsx from 'clsx';

interface ChartTooltipProps {
  className?: string;
  x: number;
  y: number;
  xData: ReactNode;
  yData: ReactNode;
}

const ChartTooltip: FC<ChartTooltipProps> = ({ x, y, xData, yData, className }) => {
  return (
    <Portal>
      <div className={clsx(styles.tooltip, className)} style={{ left: x, top: y }}>
        <p className={styles.xData}>{xData}</p>
        <p className={styles.yData}>{yData}</p>
      </div>
    </Portal>
  );
};

export default ChartTooltip;
