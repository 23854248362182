import { createReducer } from '@reduxjs/toolkit';
import { AccessPermissionsUpdatingState, ProductSectionAccessPermissions } from 'ProductSectionAccessPermissions/Types';
import {
  getAccessPermissions,
  updateAccessPermissions,
  resetAccessPermissionsUpdatingState,
} from 'ProductSectionAccessPermissions/Actions';
import { LoaderState } from 'components/LoaderWithState';
import { PermissionGroupId } from 'PermissionGroups/Types';
import normalizeEntityArray from 'utils/normalizeEntityArray';

export interface ProductSectionAccessPermissionsState {
  accessPermissionsByGroupId: Record<PermissionGroupId, ProductSectionAccessPermissions>;
  accessPermissionsUpdatingState: AccessPermissionsUpdatingState;
}

const initialState: ProductSectionAccessPermissionsState = {
  accessPermissionsByGroupId: {},
  accessPermissionsUpdatingState: {},
};

const productSectionAccessPermissionsReducer = createReducer<ProductSectionAccessPermissionsState>(
  initialState,
  (builder) => {
    builder
      .addCase(getAccessPermissions.fulfilled, (state, action) => {
        state.accessPermissionsByGroupId = normalizeEntityArray(action.payload, 'permissionGroupId');

        state.accessPermissionsUpdatingState = action.payload
          .map(({ permissionGroupId }) => permissionGroupId)
          .reduce((ids, id) => ({ ...ids, [id]: {} }), {});
      })
      .addCase(updateAccessPermissions.pending, (state, action) => {
        const {
          permissions: { permissionGroupId },
          section,
        } = action.meta.arg;

        state.accessPermissionsUpdatingState[permissionGroupId][section] = LoaderState.Updating;
      })
      .addCase(updateAccessPermissions.fulfilled, (state, action) => {
        const {
          permissions: { permissionGroupId },
          section,
        } = action.meta.arg;

        state.accessPermissionsByGroupId[permissionGroupId] = action.payload;
        state.accessPermissionsUpdatingState[permissionGroupId][section] = LoaderState.Success;
      })
      .addCase(updateAccessPermissions.rejected, (state, action) => {
        const {
          permissions: { permissionGroupId },
          section,
        } = action.meta.arg;

        state.accessPermissionsUpdatingState[permissionGroupId][section] = LoaderState.Failure;
      })
      .addCase(resetAccessPermissionsUpdatingState, (state, action) => {
        const { permissionGroupId, section } = action.payload;
        state.accessPermissionsUpdatingState[permissionGroupId][section] = undefined;
      });
  },
);

export default productSectionAccessPermissionsReducer;
