import React from 'react';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell } from 'components/Table';
import NoItems from 'components/NoItems';
import { CalculationsTableColumnSize } from './TableColumnSizes';
import CalculationRowItem from './CalculationRowItem';
import styles from './CalculatedVariablesTable.module.scss';
import SkeletonCalculationRowItem from './SkeletonCalculationRowItem';
import useOrderedCalculations from 'hooks/useOrderedCalculations';

interface TableProps {
  calculations: ProductCalculation[] | null;
  onEditCalculation: (calculation: ProductCalculation) => void;
  onDeleteCalculation: (calculation: ProductCalculation) => void;
  onAddNewCalculation: () => void;
}

const SKELETON_ROWS_COUNT = 10;

const CalculatedVariablesTable = ({
  calculations,
  onDeleteCalculation,
  onEditCalculation,
  onAddNewCalculation,
}: TableProps) => {
  const orderedCalculations = useOrderedCalculations(calculations);

  const renderTableBody = () => {
    if (!orderedCalculations) {
      return new Array(SKELETON_ROWS_COUNT).fill(null).map((item, index) => <SkeletonCalculationRowItem key={index} />);
    }

    return (
      <>
        {orderedCalculations.map((calculation) => (
          <CalculationRowItem
            key={calculation.id}
            calculation={calculation}
            onEditCalculation={onEditCalculation}
            onDeleteCalculation={onDeleteCalculation}
          />
        ))}
      </>
    );
  };

  if (orderedCalculations && !orderedCalculations.length) {
    return (
      <NoItems
        title="No calculations have been added yet!"
        buttonMessage="Add New Calculation"
        buttonClassName={styles.noItemsAddNewCalculationButton}
        onButtonClick={onAddNewCalculation}
      />
    );
  }

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell width={CalculationsTableColumnSize.VariableName}>Calculated Variable</TableHeadCell>
          <TableHeadCell width={CalculationsTableColumnSize.CalculationCode}>Calculation Script</TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default CalculatedVariablesTable;
