import { Middleware } from 'redux';
import { DELETE_DECISION_STRATEGY_REQUEST } from './ActionTypes';
import {
  deleteDecisionStrategySuccess,
  deleteDecisionStrategyError,
  DeleteDecisionStrategyRequestAction,
  StrategyActionOrigin,
} from './DecisionStrategiesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { RequestError } from 'utils/fetch';
import StrategyGroupApi from 'api/DecisionEngine/StrategyGroupApi';
import StrategyApi from 'api/DecisionEngine/StrategyApi';

export interface StrategyInfo {
  name: string;
  id?: string;
  version?: number;
}

const DeleteDecisionStrategies: (groupApi: StrategyGroupApi, strategyApi: StrategyApi) => Middleware = (
  groupApi,
  strategyApi,
) =>
  Fetcher<DeleteDecisionStrategyRequestAction['payload'], DeleteDecisionStrategyRequestAction, null, RequestError>(
    DELETE_DECISION_STRATEGY_REQUEST,
    deleteDecisionStrategySuccess,
    deleteDecisionStrategyError,
    async ({ payload, meta }) => {
      const { actionOrigin } = meta;
      if (actionOrigin === StrategyActionOrigin.StrategiesGroupsList) {
        await groupApi.delete(payload.strategyInfo.name);
      }
      if (
        actionOrigin === StrategyActionOrigin.StrategyGroupVersionsList ||
        actionOrigin === StrategyActionOrigin.VersionOverview
      ) {
        await strategyApi.delete(payload.strategyInfo.id!);
      }

      return payload;
    },
  );

export default DeleteDecisionStrategies;
