import React, { ChangeEvent } from 'react';
import {
  ApplicationHistoryFilterType,
  ApplicationHistorySortingField,
  ApplicationHistorySortingType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { SortBySelect } from 'components/LoanOriginationSystem/ApplicationsDashboard/Header/SortBySelect';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import AutoCompletion from 'components/AutoCompletion';
import SearchInput from 'components/SearchInput';
import styles from './Header.module.scss';

interface ApplicationHistorySortOption {
  id: string;
  text: string;
  field: ApplicationHistorySortingField;
  ascending: boolean;
}

export interface HeaderProps {
  members: UserInfo[];
  selectedMembers: UserInfo[];
  filterType: ApplicationHistoryFilterType;
  sortingType: ApplicationHistorySortingType;
  search: string;
  onFilterTypeChange: (filterType: ApplicationHistoryFilterType) => void;
  onSelectedMembersChange: (members: UserInfo[]) => void;
  onSortingTypeChange: (sortingType: ApplicationHistorySortingType) => void;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
}

const FILTER_TYPE_OPTIONS = [
  { value: ApplicationHistoryFilterType.AllTypes, name: 'All Types' },
  { value: ApplicationHistoryFilterType.ApplicationStatus, name: 'Application Status' },
  { value: ApplicationHistoryFilterType.Data, name: 'Data' },
  { value: ApplicationHistoryFilterType.Tasks, name: 'Tasks' },
  { value: ApplicationHistoryFilterType.Emails, name: 'Emails' },
  { value: ApplicationHistoryFilterType.Documents, name: 'Documents' },
  { value: ApplicationHistoryFilterType.DecisionEngine, name: 'Decision Engine' },
  { value: ApplicationHistoryFilterType.Notes, name: 'Notes' },
  { value: ApplicationHistoryFilterType.Other, name: 'Other' },
];

const SORT_OPTIONS: ApplicationHistorySortOption[] = [
  {
    id: '1',
    text: 'Newest to Oldest',
    field: ApplicationHistorySortingField.CreatedAt,
    ascending: false,
  },
  {
    id: '2',
    text: 'Oldest to Newest',
    field: ApplicationHistorySortingField.CreatedAt,
    ascending: true,
  },
];

const Header = ({
  members,
  selectedMembers,
  sortingType,
  filterType,
  search,
  onSelectedMembersChange,
  onFilterTypeChange,
  onSortingTypeChange,
  onSearchChange,
  onSearchClear,
}: HeaderProps) => {
  const selectedSortOption = SORT_OPTIONS.find(({ field, ascending }) => {
    return field === sortingType.field && ascending === sortingType.ascending;
  });

  const handleSortChange = (option: ApplicationHistorySortOption) => {
    const newSortingType = {
      field: option.field,
      ascending: option.ascending,
    };

    onSortingTypeChange(newSortingType);
  };

  return (
    <div className={styles.header}>
      <h4 className={styles.title}>History</h4>
      <div className={styles.actions}>
        <div className={styles.leftBlock}>
          <SearchInput placeholder="Search" value={search} onChange={onSearchChange} onClear={onSearchClear} />
          <UserFilter
            className={styles.userFilter}
            users={members}
            selectedUsers={selectedMembers}
            onChange={onSelectedMembersChange}
          />
        </div>
        <div className={styles.rightBlock}>
          <AutoCompletion
            className={styles.typeFilterButton}
            hideClearIcon
            options={FILTER_TYPE_OPTIONS}
            value={filterType}
            onChange={(option) => onFilterTypeChange(option.value as ApplicationHistoryFilterType)}
          />
          <SortBySelect
            className={styles.sortBySelect}
            onSelect={handleSortChange}
            selectedOption={selectedSortOption!}
            options={SORT_OPTIONS}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
