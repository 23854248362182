import React, { useState, FC, useCallback } from 'react';
import Button from 'components/Button';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { isEqual } from 'lodash';
import { Option } from 'components/SelectInput/SelectInput';
import TestFormulaPopup from 'components/TestFormulaPopup';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import getDataTypeAttributes from 'utils/ruleBuilder/getDataTypeAttributes';
import getShowingDataType from 'utils/ruleBuilder/getShowingDataType';
import CodeEditor, { CodeEditorMode } from 'components/CodeEditor/CodeEditor';
import useCodeEditorVariableGlobals from 'components/CodeEditor/useCodeEditorVariablesGlobals';
import useCodeEditorVariablesSuggestions from 'components/CodeEditor/useCodeEditorVariablesSuggestions';
import { Variable } from 'Variables/VariablesTypes';
import { getVisualDataType } from 'Variables/utils';

import styles from './AddCalculationScript.module.scss';

export interface SaveCalculationData {
  code: string;
  variableId: string;
}

interface AddCalculationScriptProps {
  calculation?: string;
  sourceVariableId?: string;
  saveCalculationScript: (data: SaveCalculationData) => void;
  openVariableCreation?: () => void;
  isSaving?: boolean;
  useOnlyCustomVariables?: boolean;
  mode: CodeEditorMode;
}

const AddCalculationScript: FC<AddCalculationScriptProps> = ({
  calculation: derivedCalculation,
  sourceVariableId: derivedSourceVariableId,
  useOnlyCustomVariables,
  saveCalculationScript,
  openVariableCreation,
  mode,
  isSaving,
}) => {
  const [sourceVariableId, setSourceVariableId] = useState(derivedSourceVariableId || undefined);
  const [codeEditorWasBlurred, setCodeEditorWasBlurred] = useState(false);

  const [calculation, setCalculation] = useState(derivedCalculation || '');
  const [syntaxErrorExist, setSyntaxErrorExist] = useState<boolean>(false);
  const [isTestCalculationScriptPopupOpen, setTestCalculationScriptPopupOpen] = useState(false);

  const globalsToHighlight = useCodeEditorVariableGlobals();
  const codeEditorVariablesSuggestions = useCodeEditorVariablesSuggestions();

  const handleJSCalculationChange = useCallback((value: string) => {
    setCalculation(value);
  }, []);

  const areCalculationScriptChanged = [
    [sourceVariableId, derivedSourceVariableId],
    [calculation, derivedCalculation],
  ].some(([a, b]) => !isEqual(a, b));

  const isSaveButtonEnabled = sourceVariableId && calculation.trim() && areCalculationScriptChanged && !syntaxErrorExist;

  const onSaveCalculationScript = () => {
    saveCalculationScript({
      variableId: sourceVariableId as string,
      code: calculation,
    });
  };

  const handleSyntaxValidationComplete = useCallback((errors: string[]) => {
    setSyntaxErrorExist(!!errors.length);
  }, []);

  const handleOpenTestCalculation = useCallback(() => {
    setTestCalculationScriptPopupOpen(true);
  }, []);

  const handleBlur = useCallback(() => {
    setCodeEditorWasBlurred(true);
  }, []);

  const sourceVariable = useVariableAttributes(sourceVariableId!);

  const dataTypeWithAttributes = getDataTypeAttributes(sourceVariable);

  const renderTestCalculationScriptPopup = () => {
    if (!isTestCalculationScriptPopupOpen || !sourceVariableId) {
      return null;
    }

    return (
      <TestFormulaPopup
        title="Test Calculation"
        formula={calculation}
        onClose={() => setTestCalculationScriptPopupOpen(false)}
        outputVariableId={sourceVariableId}
      />
    );
  };

  const renderTestCodeButtonTooltip = () => {
    if (!sourceVariableId || syntaxErrorExist) {
      return 'Please complete all fields before testing';
    }

    if (!calculation) {
      return 'Add a calculation before testing';
    }

    return null;
  };

  const getAdditionalOptionAttributes = (variable: Variable) => {
    const visualDataType = getVisualDataType(variable);

    if (visualDataType !== 'PhoneNumber') {
      return {};
    }

    return {
      disabled: true,
      tooltip: 'Phone Number variables cannot be calculated',
    };
  }

  return (
    <div className={styles.content}>
      <InputWithDataTypeLabel
        labelTitle="Calculated Variable"
        onChange={({ value }: Option) => setSourceVariableId(value)}
        value={sourceVariableId}
        className={styles.outputVariablesInput}
        dataType={getShowingDataType(dataTypeWithAttributes)}
        disabled={isSaving}
        onlyCustom={useOnlyCustomVariables}
        link={openVariableCreation && <CreateNewVariableLink onClick={openVariableCreation} />}
        hasRightNeighbour
        getAdditionalOptionAttributes={getAdditionalOptionAttributes}
        optionsListClassName={styles.calculatedVariableOptionsList}
      />
      <div className={styles.equalsSeparator}>
        <div className={styles.separatorLine} />
        <p className={styles.separatorText}>Equals</p>
      </div>
      <CodeEditor
        className={styles.codeEditor}
        initialValue={derivedCalculation || ''}
        label="Formula"
        onChange={handleJSCalculationChange}
        onBlur={handleBlur}
        displayError={codeEditorWasBlurred}
        error={syntaxErrorExist ? `This is an invalid Formula and cannot be saved.` : ''}
        onSyntaxValidationComplete={handleSyntaxValidationComplete}
        onTestCodeButtonClick={handleOpenTestCalculation}
        testCodeButtonDisabled={!sourceVariableId || !calculation}
        variablesSuggestions={codeEditorVariablesSuggestions}
        globalsToHighlight={globalsToHighlight}
        testCodeTitle="Test Calculation"
        displayTestCodeButton={mode === 'jexl'}
        testCodeButtonTooltip={renderTestCodeButtonTooltip()}
        disabled={isSaving}
        mode={mode}
      />
      <Button
        size="form"
        kind="primary"
        className={styles.continueButton}
        onClick={onSaveCalculationScript}
        disabled={!isSaveButtonEnabled}
        isLoading={isSaving}
      >
        Save
      </Button>
      {renderTestCalculationScriptPopup()}
    </div>
  );
};

export default AddCalculationScript;
