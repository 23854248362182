import { AnyAction } from 'redux';

import {
  ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST,
  ACTION_TYPE_GET_INTEGRATIONS_LIST_SUCCESS,
  ACTION_TYPE_GET_INTEGRATIONS_LIST_FAILURE,
} from './ActionTypes';

import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { Integration } from 'api/DecisionEngineStrategiesType';

export interface GetIntegrationsListSuccessAction extends AnyAction {
  type: typeof ACTION_TYPE_GET_INTEGRATIONS_LIST_SUCCESS;
  payload: Integration[];
}

export interface GetIntegrationsListAction extends AnyAction {
  type: typeof ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST;
}

export const getIntegrationsListSuccess = (integrationsList: Integration[]): GetIntegrationsListSuccessAction => {
  return {
    type: ACTION_TYPE_GET_INTEGRATIONS_LIST_SUCCESS,
    payload: integrationsList,
  };
};

export const getIntegrationsListError = FailedActionCreator(ACTION_TYPE_GET_INTEGRATIONS_LIST_FAILURE);

export const getIntegrationsList = (): GetIntegrationsListAction => {
  return {
    type: ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST,
  };
};
