import { AnyAction } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import { UserState } from './AccountDetailsTypes';
import { ChangeUserInfoSuccessAction, GetUserInfoSuccessAction } from './AccountDetailsActionCreator';

export type AccountDetailsReducerState = UserState | null;

const AccountDetailsReducer = (
  state: AccountDetailsReducerState = null,
  action: AnyAction,
): AccountDetailsReducerState => {
  switch (action.type) {
    case AccountDetailsActionType.UpdateAccountDetailsSuccess:
    case AccountDetailsActionType.EnablePhoneAuthenticationSuccess:
    case AccountDetailsActionType.DisablePhoneAuthenticationSuccess:
    case AccountDetailsActionType.ChangeEmailSuccess: {
      const { user } = action.payload;

      return {
        ...user,
        error: '',
        isLoading: false,
      };
    }
    case AccountDetailsActionType.GetAccountDetailsFailure: {
      const { message } = action.payload;
      return {
        ...(state as UserState),
        error: message,
        isLoading: false,
      };
    }
    case AccountDetailsActionType.GetAccountDetails: {
      return {
        ...(state as UserState),
        error: '',
        isLoading: true,
      };
    }
    case AccountDetailsActionType.GetAccountDetailsSuccess: {
      const { user } = (action as GetUserInfoSuccessAction).payload;
      return {
        ...(state as UserState),
        ...user,
        error: '',
        isLoading: false,
      };
    }
    case AccountDetailsActionType.UpdateAccountDetailsFailure: {
      const { message } = action.payload;
      return {
        ...(state as UserState),
        error: message,
        isLoading: false,
      };
    }
    case AccountDetailsActionType.UpdateAccountDetails: {
      const { firstName, lastName } = action.payload;

      return {
        ...(state as UserState),
        firstName,
        lastName,
        error: '',
      };
    }
    case AccountDetailsActionType.ChangeAccountPhotoSuccess: {
      const { user } = (action as ChangeUserInfoSuccessAction).payload;
      return {
        ...(state as UserState),
        error: '',
        imageId: user.imageId,
        isLoading: false,
      };
    }
    case AccountDetailsActionType.ChangeAccountPhotoFailure: {
      const { message } = action.payload;
      return {
        ...(state as UserState),
        error: message,
      };
    }
    case AccountDetailsActionType.ChangeAccountPhoto: {
      return {
        ...(state as UserState),
        error: '',
      };
    }
    case AccountDetailsActionType.RequestPhoneAuthenticationFailure: {
      return {
        ...(state as UserState),
        requestPhoneAuthenticationError: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default AccountDetailsReducer;
