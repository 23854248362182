import { AnyAction } from 'redux';
import { LoanOriginationSystemEditIntermediaryTypes } from './ActionTypes';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface UpdateIntermediaryAction extends AnyAction {
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediary;
  payload: {
    data: FormLayoutData;
    id: string;
  };
}

export interface UpdateIntermediarySuccessAction extends AnyAction {
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess;
  payload: Intermediary;
}

export interface UpdateIntermediaryApplicationsAction extends AnyAction {
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplications;
  payload: {
    intermediaryId: string;
    variables: FormLayoutData;
  };
}

export interface UpdateIntermediaryApplicationsSuccessAction extends AnyAction {
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplicationsSuccess;
}

export const updateIntermediary = (data: FormLayoutData, id: string): UpdateIntermediaryAction => ({
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediary,
  payload: {
    data,
    id,
  },
});

export const updateIntermediarySuccess = (intermediary: Intermediary): UpdateIntermediarySuccessAction => ({
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess,
  payload: intermediary,
});

export const updateIntermediaryApplications = (intermediaryId: string, variables: FormLayoutData): UpdateIntermediaryApplicationsAction => ({
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplications,
  payload: {
    intermediaryId,
    variables,
  },
});

export const updateIntermediaryApplicationsSuccess = (): UpdateIntermediaryApplicationsSuccessAction => ({
  type: LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplicationsSuccess,
});

export const updateIntermediaryFailure = FailedActionCreator(
  LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryFailure,
);

export const updateIntermediaryApplicationFailure = FailedActionCreator(
  LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryApplicationsFailure,
);
