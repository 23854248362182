import { Variable } from 'Variables/VariablesTypes';
import React, { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { loadVariables } from 'VariablesAttributes/Actions';
import { ReduxState } from 'types/redux';

interface VariablesBySystemNameLoaderProps {
  debounceTimeout?: number;
}

interface VariablesBySystemNameContextValue {
  (systemName: string): void;
}

const VariablesBySystemNameContext = createContext<VariablesBySystemNameContextValue>(() => {
  throw new Error('No <VariablesBySystemNameLoader> exists upper in the tree.');
});

export const VariablesBySystemNameLoader: FC<VariablesBySystemNameLoaderProps> = ({ children, debounceTimeout }) => {
  const dispatch = useDispatch();

  const loadVariable = useMemo(() => {
    let systemNames: string[] = [];

    const getVariablesDebounced = debounce(() => {
      dispatch(loadVariables({ systemNames }));
      systemNames = [];
    }, debounceTimeout);

    return (systemName: string) => {
      systemNames.push(systemName);
      getVariablesDebounced();
    };
  }, [debounceTimeout]);

  return (
    <VariablesBySystemNameContext.Provider value={loadVariable}>
      {children}
    </VariablesBySystemNameContext.Provider>
  );
};

export const useVariableBySystemName = (systemName: string) => {
  const loadVariable = useContext(VariablesBySystemNameContext);
  const variablesMap = useSelector<ReduxState, Partial<Record<string, Variable>>>((state) => state.variablesAttributes.bySystemName);

  useEffect(() => {
    loadVariable(systemName);
  }, [systemName]);

  return variablesMap[systemName];
};
