import TasksDashboard from 'components/LoanOriginationSystem/TasksDashboard';
import React from 'react';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import styles from './TasksTab.module.scss';

interface TasksTabProps {
  applicationId: string;
  onCreate: () => void;
  onEdit: (id: string) => void;
}

const TasksTab = ({ applicationId, onCreate, onEdit }: TasksTabProps) => {
  return (
    <TasksDashboard
      className={styles.tasksDashboard}
      size="small"
      applicationId={applicationId}
      showGoToApplicationAction={false}
      onCreate={onCreate}
      onEdit={onEdit}
      stickyMarginTop={TABLE_HEADER_OFFSET}
    />
  );
};

export default TasksTab;
