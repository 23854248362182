import React from 'react';
import { Card } from 'api/LoanOriginationSystem/Base/BaseCardsApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import CardLayoutPreview from 'components/LoanOriginationSystem/CardsLayoutPreview/CardLayoutPreview';
import styles from './CardsLayoutPreviewRow.module.scss';

export interface CardsLayoutPreviewRowProps<
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
  > {
  cards: CardType[];
  formatVariableConfigurationDisplayTitle?: (systemName: string, title: string) => string;
  descriptionTooltip?: React.ReactNode;
}

const CardsLayoutPreviewRow = <
  VariableConfigurationType extends BaseVariableConfiguration,
  CardType extends Card<VariableConfigurationType>,
>({
  cards,
  formatVariableConfigurationDisplayTitle,
  descriptionTooltip,
}: CardsLayoutPreviewRowProps<VariableConfigurationType, CardType>) => {
  const renderCard = (card: CardType) => (
    <CardLayoutPreview
      key={card.id}
      title={card.name}
      className={styles.cardPreview}
      configurations={card.fields}
      descriptionTooltip={descriptionTooltip}
      formatDisplayTitle={formatVariableConfigurationDisplayTitle}
    />
  );

  return (
    <div className={styles.row}>
      {cards.map(renderCard)}
    </div>
  );
};

export default CardsLayoutPreviewRow;
