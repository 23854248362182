import React, { useEffect, useState, ChangeEvent } from 'react';
import styles from './CompanyInformationEditForm.module.scss';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { OrganizationState } from 'CompanyInformation/CompanyInformationTypes';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import COUNTRY_OPTIONS from 'constants/countryOptions';
import { updateOrganizationInfoRequest } from 'CompanyInformation/CompanyInformationActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import UserAvatar from 'components/UserAvatar';

interface CompanyInformationEditFormProps {
  organizationInfo: OrganizationState;
  organizationUsers: UserInfo[];
  onCloseEditor: () => void;
}

const CompanyInformationEditForm = ({
  organizationInfo,
  organizationUsers,
  onCloseEditor,
}: CompanyInformationEditFormProps) => {
  const dispatchRoutine = useDispatchRoutine();
  const { isLoading } = useSelector((state: ReduxState) => state.organizationInformation);

  const [legalAddress, setLegalAddress] = useState('');
  const [primaryContact, setPrimaryContact] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    setLegalAddress(organizationInfo.legalAddress || '');
    setPrimaryContact(organizationInfo.primaryContact || '');
    setCountry(organizationInfo.country || '');
  }, [organizationInfo]);

  const handleLegalAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLegalAddress(e.target.value);
  };

  const handlePrimaryContactChange = ({ value }: Option) => {
    setPrimaryContact(value);
  };

  const handleCountryChange = ({ value }: Option) => {
    setCountry(value);
  };

  const handleSaveChanges = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await dispatchRoutine(updateOrganizationInfoRequest({ legalAddress, primaryContact, country }));
    onCloseEditor();
  };

  const checkIsButtonDisabled = () => {
    const isFormUnchanged =
      organizationInfo.legalAddress === legalAddress.trim() &&
      organizationInfo.country === country.trim() &&
      organizationInfo.primaryContact === primaryContact.trim();

    return isFormUnchanged;
  };

  const formattedUsers = organizationUsers.map(({ firstName, lastName, id, imageId }) => {
    const icon = (
      <UserAvatar
        size="small"
        firstName={firstName}
        lastName={lastName}
        imageId={imageId}
        className={styles.userAvatar}
      />
    );
    return {
      name: `${firstName} ${lastName}`,
      value: id,
      icon,
    };
  });

  return (
    <>
      <div className={styles.fieldRow}>
        <TextInput
          containerClassName={styles.fieldContainer}
          type="text"
          labelTitle="Name"
          value={organizationInfo.name}
          disabled
        />
        <AutoCompletion
          type="text"
          className={styles.fieldContainer}
          labelTitle="Primary Contact"
          onChange={handlePrimaryContactChange}
          options={formattedUsers || []}
          value={primaryContact}
        />
      </div>
      <div className={styles.fieldRow}>
        <TextInput
          containerClassName={styles.fieldContainer}
          type="text"
          labelTitle="Legal Address"
          onChange={handleLegalAddressChange}
          value={legalAddress}
        />
        <AutoCompletion
          className={styles.fieldContainer}
          type="text"
          labelTitle="Country"
          options={COUNTRY_OPTIONS}
          onChange={handleCountryChange}
          value={country}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          kind="primary"
          size="form"
          onClick={handleSaveChanges}
          disabled={checkIsButtonDisabled()}
          isLoading={isLoading}
        >
          Save Changes
        </Button>
        <Button className={styles.closeEditorButton} kind="secondary" size="form" onClick={onCloseEditor}>
          Close Editor
        </Button>
      </div>
    </>
  );
};

export default CompanyInformationEditForm;
