import React from 'react';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import styles from './BorrowerProfilesConfigurationHeader.module.scss';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';
import QuestionIcon from 'components/QuestionIcon';

export interface BorrowerProfilesConfigurationHeaderProps {
  onChangeBorrowerType: (borrowerType: BorrowerType) => void;
  onDownloadApiTemplate: () => void;
  borrowerType: BorrowerType;
  tabs: TabSwitchOption[];
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test1,
  ReactAppEnvironment.Test2,
);

const BorrowerProfilesConfigurationHeader = ({
  tabs,
  borrowerType,
  onChangeBorrowerType,
  onDownloadApiTemplate,
}: BorrowerProfilesConfigurationHeaderProps) => {
  const renderTooltip = () => (
    <>
      <p>Customize your Borrower profile data structure.</p>
      <p>Put permanent data about Borrowers here and</p>
      <p>point-in-time data on Loan Products.</p>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.headerTitleWithTooltip}>
          <h2 className={styles.headerTitle}>Borrower Profiles</h2>
          <QuestionIcon className={styles.questionIcon} tooltip={renderTooltip()} size={24} />
        </div>
        <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiTemplate} />
      </div>
      <TabSwitch
        displayBorder={false}
        tabs={tabs}
        selectedTabId={borrowerType}
        tabClassName={styles.tab}
        onSelect={({ id }) => onChangeBorrowerType(id as BorrowerType)}
      />
    </div>
  );
};

export default BorrowerProfilesConfigurationHeader;
