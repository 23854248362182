import React, { FC, useState } from 'react';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './OverflowedText.module.scss';

interface OverflowedTextProps {
  className?: string;
  useTooltip?: boolean
  tooltipOnHover?: React.ReactNode;
}

const OverflowedText: FC<OverflowedTextProps> = ({
  className,
  useTooltip,
  tooltipOnHover,
  children
}) => {
  const overflowedTextClassName = clsx(
    styles.overflowedText,
    !useTooltip && styles.overflowedTextWithBreakWords,
    className,
  );

  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setDisplayTooltip(event.currentTarget.scrollWidth > event.currentTarget.clientWidth);
  };

  const handleMouseLeave = () => {
    setDisplayTooltip(false);
  };

  if (useTooltip) {
    return (
      <WrapperWithTooltip open={displayTooltip} tooltip={tooltipOnHover || children}>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={overflowedTextClassName}>{children}</div>
      </WrapperWithTooltip>
    );
  }

  return (
    <div className={overflowedTextClassName}>{children}</div>
  );
};

export default OverflowedText;
