import React, { FC } from 'react';
import StyledSwitch from 'components/StyledSwitch';
import clsx from 'clsx';
import styles from 'components/ListHeader/ListHeader.module.scss';

interface SliderButtonProps {
  active: boolean;
  disabled?: boolean;
  onChange: (active: boolean) => void;
  labelText?: string;
  className?: string;
  labelClassName?: string;
}

const SliderButton: FC<SliderButtonProps> = ({
  active,
  disabled = false,
  onChange,
  labelText,
  className,
  labelClassName,
}) => {
  const labelClasses = clsx(styles.labelText, !active && styles['labelText--inactive'], labelClassName);

  return (
    <>
      <StyledSwitch
        checked={active}
        onChange={(_event, checked) => onChange(checked)}
        className={className}
        disabled={disabled}
      />
      {labelText && <p className={labelClasses}>{labelText}</p>}
    </>
  );
};

export default SliderButton;
