import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import { formatDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { getBorrowerProfileCards } from 'BorrowerProfileCards/Thunks';
import { getBorrowerProfileCardsSelector } from 'BorrowerProfileCards/Selectors';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCardFields from 'hooks/useCardFields';

export interface EditBorrowerFormProps {
  borrower: Borrower | null;
  borrowerFormData: FormLayoutData;
  onFieldChange: (variableConfiguration: BaseVariableConfiguration, value: VariableValue) => void;
  onBorrowerUpdate: () => void;
  onSwitchEditMode?: () => void;
  isUpdating?: boolean;
  isEditMode?: boolean;
  editModeOnly?: boolean;
  className?: string;
}

const EditBorrowerForm = ({
  borrower,
  borrowerFormData,
  onBorrowerUpdate,
  editModeOnly,
  onSwitchEditMode,
  onFieldChange,
  isUpdating,
  isEditMode,
  className,
}: EditBorrowerFormProps) => {
  const dispatch = useDispatch();

  const borrowerType = borrower?.type;

  const cards = useSelector((state: ReduxState) => {
    return borrowerType ? getBorrowerProfileCardsSelector(state, borrowerType) : null;
  });

  const fields = useCardFields(cards);
  const displayFieldAttributes = useConditionalDisplayLogic(fields, borrowerFormData);

  const validateBorrowerData = useConfigurableFormValidation(fields);

  const isBorrowerDataWasChanged = useCallback(() => {
    return !!(borrower?.variables && isConfigurableFormDataChanged(borrower.variables, borrowerFormData));
  }, [borrower, borrowerFormData]);

  useEffect(() => {
    if (borrowerType) {
      dispatch(getBorrowerProfileCards(borrowerType));
    }
  }, [borrowerType]);

  const renderForm = () => {
    return (
      <CardsForm
        title="Profile"
        cards={cards}
        data={borrowerFormData}
        onFieldChange={onFieldChange}
        formatVariableConfigurationDisplayTitle={formatDisplayTitle}
        onSwitchEditMode={onSwitchEditMode}
        isEditMode={isEditMode}
        switchEditModeButton="Edit Profile"
        displayHeader={!editModeOnly}
        isSaveChangesButtonDisabled={!isBorrowerDataWasChanged() || !cards}
        isUpdatingInProgress={isUpdating}
        onSaveChangesClick={onBorrowerUpdate}
        areFieldsInvalid={!validateBorrowerData(borrowerFormData, displayFieldAttributes)}
        displayFieldsAttributes={displayFieldAttributes}
        displaySkeleton={!displayFieldAttributes}
      />
    );
  };

  return (
    <div className={className}>
      {renderForm()}
    </div>
  );
};

export default EditBorrowerForm;
