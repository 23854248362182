import { createAsyncThunk } from '@reduxjs/toolkit';
import ApplicationDocumentConfigurationsRestApi, {
  CreateApplicationDocumentConfigurationParams,
  UpdateApplicationDocumentConfigurationParams,
} from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import responseHandlers from 'api/ResponseHandlers';

interface UpdateApplicationDocumentConfigurationRequiredAttributeParams {
  id: string;
  required: boolean;
}

const applicationDocumentConfigurationsApi = new ApplicationDocumentConfigurationsRestApi(responseHandlers);

export const getApplicationDocumentConfigurations = createAsyncThunk(
  'applicationDocumentConfiguration/getApplicationDocumentConfigurations',
  async (productId: string) => {
    const configurations = await applicationDocumentConfigurationsApi.find(productId);

    return {
      configurations,
      productId,
    };
  },
);

export const createApplicationDocumentConfiguration = createAsyncThunk(
  'applicationDocumentConfigurations/createApplicationDocumentConfiguration',
  async (params: CreateApplicationDocumentConfigurationParams) => {
    const configuration = await applicationDocumentConfigurationsApi.create(params);

    return {
      configuration,
    };
  },
);

export const updateApplicationDocumentConfiguration = createAsyncThunk(
  'applicationDocumentConfigurations/updateApplicationDocumentConfiguration',
  async (params: UpdateApplicationDocumentConfigurationParams & { id: string }) => {
    const { id, ...updateParams } = params;

    const configuration = await applicationDocumentConfigurationsApi.update(id, updateParams);

    return {
      configuration,
    };
  },
);

export const updateApplicationDocumentConfigurationRequiredAttribute = createAsyncThunk(
  'applicationDocumentConfigurations/updateApplicationDocumentConfigurationRequiredAttribute',
  async (params: UpdateApplicationDocumentConfigurationRequiredAttributeParams) => {
    const { id, required } = params;

    const configuration = await applicationDocumentConfigurationsApi.update(id, {
      required,
    });

    return {
      configuration,
    };
  },
);

export const deleteApplicationDocumentConfiguration = createAsyncThunk(
  'applicationDocumentConfigurations/deleteApplicationDocumentConfiguration',
  async (id: string) => {
    await applicationDocumentConfigurationsApi.delete(id);

    return {
      id,
    };
  },
);
