import { useRef, useState } from 'react';
import { noop } from 'lodash';

const useUnsavedChanges = (shouldShowUnsavedChanges: () => boolean) => {
  const [displayUnsavedChanges, setDisplayUnsavedChanges] = useState(false);
  const afterConfirmUnsavedChangesCallbackRef = useRef(noop);

  const onLeaveUnsavedChanges = () => {
    setDisplayUnsavedChanges(false);
    afterConfirmUnsavedChangesCallbackRef.current = noop;
  };

  const onConfirmUnsavedChanges = (resetUnsavedChanges: boolean = true) => {
    setDisplayUnsavedChanges(!resetUnsavedChanges);

    afterConfirmUnsavedChangesCallbackRef.current();
  };

  const useCallbackWithUnsavedChanges = <Args extends unknown[]>(callback: (...args: Args) => void) => {
    return (...args: Args) => {
      if (!shouldShowUnsavedChanges()) {
        callback(...args);

        return;
      }

      afterConfirmUnsavedChangesCallbackRef.current = () => callback(...args);
      setDisplayUnsavedChanges(true);
    };
  };

  return [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChanges,
    useCallbackWithUnsavedChanges,
  ] as [boolean, () => void, () => void, typeof useCallbackWithUnsavedChanges];
};

export default useUnsavedChanges;
