import { SendGridState, SendGridTemplate } from './Types';
import templatesPagination, { TEMPLATES_PER_PAGE_DEFAULT } from 'pages/SendGrid/Pagination';
import withStateReset from 'utils/reducers/withStateReset';
import { setItemsTotal } from 'utils/reducers/setStateField';
import {
  GetApplicationVariablesRequestSuccessAction,
  GetSenderEmailsRequestSuccessAction,
  GetSendGridIntegrationSuccessAction,
  GetTemplatesRequestSuccessAction,
  GetTemplatesToImportRequestSuccessAction,
  OpenDeleteTemplatePopUpAction,
  UpdateTemplateStatusSuccessAction,
  UpdateTemplateSenderEmailRequestSuccessAction,
  UpdateTemplateVariablesMappingRequestSuccessAction,
} from './ActionCreator';
import { SendGridActionType } from './ActionTypes';
import { SendGridTemplatesSortingField } from 'api/LoanOriginationSystem/SendGridTemplateApi';
import {
  ACTION_TYPE_CREATE_VARIABLE_FAILURE,
  ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
} from 'Variables/ActionTypes';
import { CreateVariableActionOrigin } from 'Variables/VariablesActionCreator';
import {
  initialState as filtersInitialState,
  loanOriginationSystemTemplatesFilterReducer,
  LoanOriginationSystemTemplatesFilterAction,
} from './Filters/Reducer';
import { AnyAction } from 'redux';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './Filters/ActionTypes';

const initialState: SendGridState = {
  sortingType: {
    field: SendGridTemplatesSortingField.UpdatedAt,
    ascending: false,
  },
  items: [],
  page: 1,
  itemsPerPage: TEMPLATES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  filters: filtersInitialState,
  searched: {},
  templateIdToDelete: null,
  importableTemplates: null,
  applicationVariables: [],
  senderEmails: null,
  integration: null,
  isIntegrationLoaded: false,
  isImportTemplatePopUpOpen: false,
  isDeleteTemplateInProgress: false,
  isImportInProgress: false,
  isCreatingVariablePopupOpen: false,
  isCreatingVariableInProgress: false,
  isVariableMappingPopUpOpen: false,
  isUpdatingSenderEmail: false,
  isUpdatingVariablesMapping: false,
  selectedTemplate: null,
};

const sendGridTemplatesReducer = (state: SendGridState = initialState, action: AnyAction): SendGridState => {
  switch (action.type) {
    case SendGridActionType.OpenDeleteTemplatePopUp: {
      const { payload } = action as OpenDeleteTemplatePopUpAction;

      return {
        ...state,
        templateIdToDelete: payload.templateId,
      };
    }
    case SendGridActionType.CloseDeleteTemplatePopUp: {
      return {
        ...state,
        templateIdToDelete: null,
      };
    }
    case SendGridActionType.DeleteTemplateRequest: {
      return {
        ...state,
        isDeleteTemplateInProgress: true,
      };
    }
    case SendGridActionType.DeleteTemplateRequestSuccess: {
      return {
        ...state,
        isDeleteTemplateInProgress: false,
        templateIdToDelete: null,
      };
    }
    case SendGridActionType.DeleteTemplateRequestError: {
      return {
        ...state,
        isDeleteTemplateInProgress: false,
      };
    }
    case SendGridActionType.OpenImportTemplatePopUp: {
      return {
        ...state,
        isImportTemplatePopUpOpen: true,
        importableTemplates: null,
      };
    }
    case LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters:
    case LoanOriginationSystemTemplatesFiltersActionTypes.FilterTemplatesByMembers:
    case LoanOriginationSystemTemplatesFiltersActionTypes.ResetAllFilters: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        filters: loanOriginationSystemTemplatesFilterReducer(state.filters, action),
      };
    }
    case SendGridActionType.GetApplicationVariablesRequestSuccess: {
      const { payload } = action as GetApplicationVariablesRequestSuccessAction;

      return {
        ...state,
        applicationVariables: payload.applicationVariables,
      };
    }
    case SendGridActionType.GetTemplatesRequestSuccess: {
      const { payload } = action as GetTemplatesRequestSuccessAction;

      if (state.filters.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: payload.templatesPaginationResult.items,
              itemsTotal: payload.templatesPaginationResult.total,
              itemsPerPage: state.itemsPerPage,
              page: 1,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        items: payload.templatesPaginationResult.items,
        itemsTotal: payload.templatesPaginationResult.total,
        page: 1,
        error: null,
      };
    }
    case SendGridActionType.SortTemplates: {
      return {
        ...state,
        ...setItemsTotal(state, state.filters.searchInputValue ? ['searched', state.filters.searchInputValue] : []),
        sortingType: action.payload.sortingType,
      };
    }
    case SendGridActionType.OpenCreateVariablePopup: {
      return {
        ...state,
        isCreatingVariablePopupOpen: true,
      };
    }
    case SendGridActionType.CloseCreateVariablePopup: {
      return {
        ...state,
        isCreatingVariablePopupOpen: false,
      };
    }
    case SendGridActionType.GetTemplatesToImportRequest: {
      return {
        ...state,
        importableTemplates: null,
      };
    }
    case SendGridActionType.GetTemplatesToImportRequestSuccess: {
      const { payload } = action as GetTemplatesToImportRequestSuccessAction;

      return {
        ...state,
        importableTemplates: payload.importableTemplates,
      };
    }
    case SendGridActionType.GetSenderEmailsRequestSuccess: {
      const { payload } = action as GetSenderEmailsRequestSuccessAction;

      return {
        ...state,
        senderEmails: payload.senderEmails,
      };
    }
    case SendGridActionType.ImportTemplatesRequest: {
      return {
        ...state,
        isImportInProgress: true,
      };
    }
    case SendGridActionType.ImportTemplatesRequestSuccess: {
      return {
        ...state,
        isImportInProgress: false,
        isImportTemplatePopUpOpen: false,
        importableTemplates: null,
      };
    }
    case SendGridActionType.ImportTemplatesRequestError: {
      return {
        ...state,
        isImportInProgress: false,
      };
    }
    case SendGridActionType.CloseImportTemplatePopUp: {
      return {
        ...state,
        isImportTemplatePopUpOpen: false,
        importableTemplates: null,
      };
    }
    case SendGridActionType.OpenVariablesMappingPopUp: {
      return {
        ...state,
        isVariableMappingPopUpOpen: true,
      };
    }
    case SendGridActionType.CloseVariablesMappingPopUp: {
      return {
        ...state,
        isVariableMappingPopUpOpen: false,
      };
    }
    case SendGridActionType.UpdateTemplateSenderEmailRequest: {
      return {
        ...state,
        isUpdatingSenderEmail: true,
      };
    }
    case SendGridActionType.UpdateTemplateSenderEmailRequestSuccess: {
      const {
        payload: { templateId, senderEmail },
      } = action as UpdateTemplateSenderEmailRequestSuccessAction;

      const currentTemplateIndex = state.items.findIndex((template) => template.id === templateId);
      const currentTemplate = state.items[currentTemplateIndex];
      const updatedTemplate: SendGridTemplate = {
        ...currentTemplate,
        senderEmail,
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, currentTemplateIndex),
          updatedTemplate,
          ...state.items.slice(currentTemplateIndex + 1),
        ],
        selectedTemplate: state.selectedTemplate?.id === updatedTemplate.id ? updatedTemplate : state.selectedTemplate,
        isUpdatingSenderEmail: false,
      };
    }
    case SendGridActionType.UpdateTemplateSenderEmailRequestError: {
      return {
        ...state,
        isUpdatingSenderEmail: false,
      };
    }
    case SendGridActionType.UpdateTemplateVariablesMappingRequest: {
      return {
        ...state,
        isUpdatingVariablesMapping: true,
      };
    }
    case SendGridActionType.UpdateTemplateVariablesMappingRequestSuccess: {
      const {
        payload: { templateId, variables },
      } = action as UpdateTemplateVariablesMappingRequestSuccessAction;

      const currentTemplateIndex = state.items.findIndex((template) => template.id === templateId);
      const currentTemplate = state.items[currentTemplateIndex];
      const updatedTemplate: SendGridTemplate = {
        ...currentTemplate,
        variables,
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, currentTemplateIndex),
          updatedTemplate,
          ...state.items.slice(currentTemplateIndex + 1),
        ],
        selectedTemplate: state.selectedTemplate?.id === updatedTemplate.id ? updatedTemplate : state.selectedTemplate,
        isUpdatingVariablesMapping: false,
        isVariableMappingPopUpOpen: false,
      };
    }
    case SendGridActionType.UpdateTemplateVariablesMappingRequestError: {
      return {
        ...state,
        isUpdatingVariablesMapping: false,
      };
    }
    case SendGridActionType.UpdateTemplateStatusRequestSuccess: {
      const {
        payload: { templateId, active },
      } = action as UpdateTemplateStatusSuccessAction;

      const currentTemplateIndex = state.items.findIndex((template) => template.id === templateId);
      const currentTemplate = state.items[currentTemplateIndex];
      const updatedTemplate: SendGridTemplate = {
        ...currentTemplate,
        active,
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, currentTemplateIndex),
          updatedTemplate,
          ...state.items.slice(currentTemplateIndex + 1),
        ],
        selectedTemplate: state.selectedTemplate?.id === updatedTemplate.id ? updatedTemplate : state.selectedTemplate,
      };
    }
    case SendGridActionType.GetSendGridIntegrationRequestSuccess: {
      const { clientApiKey } = (action as GetSendGridIntegrationSuccessAction).payload;

      return {
        ...state,
        integration: {
          clientApiKey,
        },
        isIntegrationLoaded: true,
      };
    }
    case SendGridActionType.GetSendGridIntegrationRequestError: {
      return {
        ...state,
        isIntegrationLoaded: true,
      };
    }
    case SendGridActionType.GetTemplateSuccess: {
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    }
    case SendGridActionType.FilterTemplatesByMembers:
    case ACTION_TYPE_CREATE_VARIABLE_REQUEST: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.SendGridVariablesMapping) {
        return state;
      }

      return {
        ...state,
        isCreatingVariableInProgress: true,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_SUCCESS: {
      return {
        ...state,
        isCreatingVariableInProgress: false,
        isCreatingVariablePopupOpen: false,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_FAILURE: {
      return {
        ...state,
        isCreatingVariableInProgress: false,
      };
    }
    default:
      return {
        ...state,
        filters: loanOriginationSystemTemplatesFilterReducer(
          state.filters,
          action as LoanOriginationSystemTemplatesFilterAction,
        ),
      };
  }
};

export default templatesPagination.wrapReducer<SendGridState>(
  withStateReset(sendGridTemplatesReducer, SendGridActionType.ResetTemplatesState, (state) => ({
    ...state,
    sortingType: {
      field: SendGridTemplatesSortingField.UpdatedAt,
      ascending: false,
    },
    items: [],
    page: 1,
    itemsPerPage: TEMPLATES_PER_PAGE_DEFAULT,
    itemsTotal: undefined,
    error: null,
    filters: filtersInitialState,
    searched: {},
  })),
);
