import React from 'react';
import clsx from 'clsx';
import styles from './SkeletonCirclesList.module.scss';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

interface SkeletonCirclesListProps {
  width: string;
  height: string;
  color: SkeletonColorType;
  circlesCount?: number;
  className?: string;
  stopAnimation?: boolean;
}

const SkeletonCirclesList = ({
  width,
  height,
  color,
  circlesCount = 3,
  stopAnimation,
  className,
}: SkeletonCirclesListProps) => {
  const circles = new Array(circlesCount).fill(null);

  return (
    <div className={clsx(styles.skeletonCirclesList, className)}>
      {circles.map((_, index) => (
        <SkeletonCircle
          key={index}
          stopAnimation={stopAnimation}
          width={width}
          height={height}
          color={color}
          className={styles.skeletonCirclesListItem}
        />
      ))}
    </div>
  );
};

export default SkeletonCirclesList;
