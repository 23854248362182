import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import IntermediaryHasApplicationsError from 'errors/IntermediaryHasApplicationsError';
import { selectIntermediaryViewTab } from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { IntermediaryDetailsTab } from 'components/LoanOriginationSystem/IntermediaryDetails';
import { DeleteIntermediaryFailureAction } from './ActionCreator';
import { LoanOriginationSystemIntermediariesActionType } from './ActionTypes';

const isDeleteIntermediaryFailureAction = (
  action: AnyAction,
): action is DeleteIntermediaryFailureAction =>
  action.type === LoanOriginationSystemIntermediariesActionType.DeleteIntermediaryFailure;

const NOTIFICATION_TIMEOUT = 7000;

export const notifyOnIntermediaryDeleteFailure = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (!isDeleteIntermediaryFailureAction(action)) {
    return result;
  }

  const error = action.payload;

  if (error instanceof IntermediaryHasApplicationsError) {
    const message = getMessage(MessageType.IntermediaryToDeleteHasApplications, {
      companyName: error.intermediaryName,
    });

    notification.createNotification(
      {
        componentType: NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent,
        props: {
          message,
          url: `/los/intermediaries/?edit=${error.intermediaryId}`,
          selectApplicationsTabActionCreator: () => selectIntermediaryViewTab(IntermediaryDetailsTab.Applications),
        },
      },
      'error',
      dispatch,
      NOTIFICATION_TIMEOUT,
    );

    return result;
  }

  notification.createNotification(error.message, 'error', dispatch);

  return result;
};
