import React, { FC } from 'react';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import ButtonRound from 'components/ButtonRound';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import RowActions, { RowAction } from 'components/RowActions/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { EmailImage, PhoneImage } from 'static/images';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import styles from './ProfileHeader.module.scss';

export interface ProfileData {
  name: string;
  email?: string;
  phone?: string;
  updatedBy?: UserInfo | null;
  updatedAt: Date;
}

export interface ProfileHeaderProps {
  profileData: ProfileData | null;
  actions: RowAction[];
  onPhoneCall: () => void;
  onSendEmail: () => void;
  tabs: TabSwitchOption[];
  selectedTabId: string;
  onSelectTab: (tab: TabSwitchOption) => void;
  subtitleIcons?: React.ReactNode;
}

const EMAIL_BUTTON_TOOLTIP = 'Email is not provided';
const PHONE_BUTTON_TOOLTIP = 'Phone number is not provided';

const ProfileHeader: FC<ProfileHeaderProps> = ({
  profileData,
  onPhoneCall,
  onSendEmail,
  actions,
  tabs,
  selectedTabId,
  onSelectTab,
  subtitleIcons,
}) => {
  const renderSections = () => {
    if (!profileData) {
      return (
        <>
          <div className={styles.skeletonSection}>
            <SkeletonRectangle width="40%" height="44px" color="primary6" />
            <SkeletonRectangle marginLeft="14px" marginRight="22px" width="24px" height="24px" color="primary6" />
            <SkeletonCircle marginRight="4px" width="44px" height="44px" color="primary6" />
            <SkeletonCircle width="44px" height="44px" color="primary6" />
          </div>
          <div className={styles.skeletonSubtitleSection}>
            <SkeletonCircle marginRight="8px" width="24px" height="24px" color="primary6" />
            <SkeletonRectangle width="241px" height="20px" color="primary6" />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.titleSection}>
          <h2>{profileData.name}</h2>
          <RowActions
            className={styles.actionsButton}
            actions={actions}
          />
          <ButtonRound
            className={styles.emailButton}
            disabled={!profileData.email}
            tooltip={!profileData.email ? EMAIL_BUTTON_TOOLTIP : ''}
            tooltipPosition="bottom-end"
            onClick={onSendEmail}
          >
            <EmailImage />
          </ButtonRound>
          <ButtonRound
            disabled={!profileData.phone}
            tooltip={!profileData.phone ? PHONE_BUTTON_TOOLTIP : ''}
            tooltipPosition="bottom-start"
            onClick={onPhoneCall}
          >
            <PhoneImage />
          </ButtonRound>
        </div>
        <div className={styles.subtitleSection}>
          <WithSystemApiUserAvatar className={styles.avatar} size={8.57} user={profileData.updatedBy} />
          <p>Updated {formatDate(profileData.updatedAt, DateTimeFormat.Short)}</p>
          {subtitleIcons && <div className={styles.subtitleSectionIcons}>{subtitleIcons}</div>}
        </div>
      </>
    );
  };

  return (
    <div className={styles.profileHeader}>
      {renderSections()}
      <TabSwitch tabs={tabs} selectedTabId={selectedTabId} onSelect={onSelectTab} />
    </div>
  );
};

export default ProfileHeader;
