import React, { FC, useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Jexl } from '@digifi/jexl';
import { debounce, noop } from 'lodash';
import { useVariables } from 'hooks/useVariableDataType';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import InputWithDataType from 'components/InputWithDataType';
import { convertVariableInput, convertVariableValueToString } from 'components/ConfigurableForm/utils';
import useCodeEditorVariablesGlobals from 'components/CodeEditor/useCodeEditorVariablesGlobals';
import { SkeletonInput } from 'components/Skeleton';
import TextInput from 'components/TextInput';
import JexlCodeEditor from 'components/CodeEditor/JexlCodeEditor';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { Variable } from 'Variables/VariablesTypes';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import { coerceValue } from 'utils/coerceUtils';
import useFormulaWorker from 'hooks/useFormulaWorker';
import useVariablesSuggestions from 'hooks/useVariablesSuggestions';
import { ErrorNotificationIcon, SuccessNotificationIcon } from 'static/images';
import { FormulaService } from 'services/FormulaService';

import styles from './TestFormulaPopup.module.scss';

interface ValidateConditionResult {
  valid: boolean;
  description: string;
}

export interface TestFormulaPopupProps {
  title: string;
  formula: string;
  outputVariableId?: string;
  usePortal?: boolean;
  validateCondition?: (formulaOutput: VariableValue | undefined) => ValidateConditionResult;
  onClose: () => void;
}

const DEBOUNCE_DELAY = 250;

const TestFormulaPopup: FC<TestFormulaPopupProps> = ({
  title,
  formula,
  onClose,
  usePortal,
  outputVariableId,
  validateCondition,
}) => {
  const [variablesData, setVariablesData] = useState<Record<string, VariableValue>>({});
  const [formulaOutput, setFormulaOutput] = useState<VariableValue | undefined>(null);
  const [shouldShowConditionalRuleResult, setShouldShowConditionalRuleResult] = useState(false);

  const formulaWorker = useFormulaWorker();
  const variablesSuggestions = useVariablesSuggestions();
  const globalsToHighlight = useCodeEditorVariablesGlobals();

  const usedVariablesIds = useMemo(() => {
    if (!variablesSuggestions) {
      return null;
    }

    const jexl = new Jexl();
    const compiledAst = jexl.compile(formula);

    const identifiers = jexl.findIdentifiersByAst(compiledAst.getAst());

    return variablesSuggestions.filter(({ systemName }) => identifiers.includes(systemName)).map(({ id }) => id);
  }, [variablesSuggestions, formula]);

  const { getVariable, isLoading } = useVariables(...(usedVariablesIds ? [...usedVariablesIds, outputVariableId] : []));

  const formulaVariables = useMemo(() => {
    if (isLoading || !usedVariablesIds) {
      return null;
    }

    return usedVariablesIds.map((variableId) => getVariable(variableId));
  }, [isLoading, usedVariablesIds]);

  const outputVariable = useMemo(() => {
    if (isLoading) {
      return null;
    }

    return getVariable(outputVariableId);
  }, [isLoading, outputVariableId]);

  const recalculateOutputVariable = useMemo(() => {
    if (!formulaVariables || !formulaWorker) {
      return null;
    }

    return debounce(async (newVariablesData: Record<string, VariableValue>) => {
      const basicContext = formulaVariables.reduce((previousContext, variable) => {
        return variable ? { ...previousContext, [variable.systemName]: null } : previousContext;
      }, {});

      const context = { ...basicContext, ...newVariablesData };

      const formulaResult = await formulaWorker.runFormula(formula, context);
      const formulaResultValue = FormulaService.isErrorFormulaResult(formulaResult)
        ? formulaResult.err
        : formulaResult.value;

      const coercedValue = outputVariable && !FormulaService.isErrorFormulaResult(formulaResult)
        ? coerceValue(formulaResultValue, outputVariable.dataType)
        : formulaResultValue;

      setFormulaOutput(coercedValue);
      setShouldShowConditionalRuleResult(true);
    }, DEBOUNCE_DELAY);
  }, [formulaVariables, outputVariable, formulaWorker]);

  useEffect(() => {
    recalculateOutputVariable?.(variablesData);
  }, [formulaVariables, outputVariable, formulaWorker]);

  const renderVariable = (variable: Variable | null | undefined) => {
    if (!variable) {
      return null;
    }

    const displayValue = convertVariableValueToString(variablesData[variable.systemName]);

    return (
      <InputWithDataType
        disabledValidation
        disableResetValueOnError
        key={variable.id}
        value={displayValue}
        labelTitle={variable.displayName}
        options={variable.optionsList ? variableOptionsListToSelectInputOptions(variable.optionsList) : null}
        onChange={(newValue) => {
          const converted = convertVariableInput(newValue, variable.dataType);
          const newVariablesData = { ...variablesData, [variable.systemName]: converted };

          setVariablesData(newVariablesData);

          recalculateOutputVariable?.(newVariablesData);
        }}
        {...getVisualDataTypeWithAttributes(variable)}
      />
    );
  };

  const renderOutputVariable = () => {
    const displayValue = convertVariableValueToString(formulaOutput, true);

    if (!outputVariable) {
      return outputVariableId
        ? <SkeletonInput />
        : (
          <TextInput value={displayValue} labelTitle="Result" disabled />
        );
    }

    return (
      <InputWithDataType
        value={displayValue}
        onChange={noop}
        disabledValidation
        disableResetValueOnError
        labelTitle={outputVariable.displayName}
        options={outputVariable.optionsList ? variableOptionsListToSelectInputOptions(outputVariable.optionsList) : null}
        disabled
        {...getVisualDataTypeWithAttributes(outputVariable)}
      />
    );
  };

  const renderInputVariables = () => {
    if (!formulaVariables) {
      return Array(2)
        .fill(null)
        .map((value, index) => <SkeletonInput key={index} />);
    }

    return formulaVariables.map((variable) => renderVariable(variable));
  };

  const renderValidationConditionResult = () => {
    if (!validateCondition || !shouldShowConditionalRuleResult) {
      return null;
    }

    const { valid, description } = validateCondition(formulaOutput);

    return (
      <div className={clsx(styles.validationConditionResult, !valid && styles.invalidValidationConditionResult)}>
        {valid ? <SuccessNotificationIcon /> : <ErrorNotificationIcon />}
        <p>{description}</p>
      </div>
    );
  };

  return (
    <PopUp usePortal={usePortal} closePopUp={onClose} title={title}>
      <PopUpContent hasTopMargin>
        <JexlCodeEditor
          initialValue={formula}
          disabled
          displayTestCodeButton={false}
          globalsToHighlight={globalsToHighlight}
        />
        {renderInputVariables()}
        {renderOutputVariable()}
        {renderValidationConditionResult()}
        <Button className={styles.closeWindowButton} onClick={onClose} kind="secondary" size="form">
          Close Window
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default TestFormulaPopup;
