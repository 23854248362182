import AutoCompletion from 'components/AutoCompletion';
import { SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import Button from 'components/Button/Button';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import { formattedCurrencies } from 'components/CurrencySelect/currencies';
import { DATE_FORMATS } from 'components/VariableForm/DataTypes';
import React, { FC, useEffect, useState } from 'react';
import styles from './CompanyRegionSettingsEditForm.module.scss';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { updateRegionSettings } from 'RegionSettings/Actions';
import CompanyRegionSettingsConfirmDialog from 'components/CompanyGeneralSettings/CompanyRegionSettings/CompanyRegionSettingsEditForm/CompanyRegionSettingsConfirmDialog';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { Option } from 'components/SelectInput/SelectInput';

const DEFAULT_CHECKBOXES_STATE = {
  phoneNumberFormat: false,
  currencyFormat: false,
  dateFormat: false,
};

const CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description', 'value'];

interface CompanyRegionSettingsEditFormProps {
  regionSettings: RegionSettings;
  onCloseEditor: () => void;
}

const CompanyRegionSettingsEditForm: FC<CompanyRegionSettingsEditFormProps> = ({ regionSettings, onCloseEditor }) => {
  const dispatchRoutine = useDispatchRoutine();
  const { isUpdatingInProgress } = useSelector((state: ReduxState) => state.regionSettings);

  const [isUpdateConfirmationPopupOpen, setIsUpdateConfirmationPopupOpen] = useState(false);
  const [phoneNumberFormat, setPhoneNumberFormat] = useState(regionSettings.phoneNumberFormat);
  const [currencyFormat, setCurrencyFormat] = useState(regionSettings.currencyFormat);
  const [dateFormat, setDateFormat] = useState(regionSettings.dateFormat);
  const [checkboxesState, setCheckboxesState] = useState<{ [key: string]: boolean }>(DEFAULT_CHECKBOXES_STATE);
  const [showedCheckboxes, setShowedCheckboxes] = useState<string[]>([]);

  const isPhoneNumberFormatChanged = regionSettings.phoneNumberFormat !== phoneNumberFormat;
  const isCurrencyFormatChanged = regionSettings.currencyFormat !== currencyFormat;
  const isDateFormatChanged = regionSettings.dateFormat !== dateFormat;

  const fieldsChangesStatuses = {
    isPhoneNumberFormatChanged,
    isCurrencyFormatChanged,
    isDateFormatChanged,
  };

  const isSaveButtonEnabled = Object.values(fieldsChangesStatuses).includes(true);

  useEffect(() => {
    setPhoneNumberFormat(regionSettings.phoneNumberFormat);
    setCurrencyFormat(regionSettings.currencyFormat);
    setDateFormat(regionSettings.dateFormat);
  }, [regionSettings]);

  const saveRegionSettings = async () => {
    const regionSettingsData = {
      phoneNumberFormat,
      currencyFormat,
      dateFormat,
      checkboxesState,
    };

    await dispatchRoutine(updateRegionSettings(regionSettingsData));
    setIsUpdateConfirmationPopupOpen(false);
    onCloseEditor();
  };

  const handleChangeCheckboxesState = (name: string) => {
    const oldFiledValue = checkboxesState![name];
    const newCheckboxesState = { ...checkboxesState, [name]: !oldFiledValue };

    setCheckboxesState(newCheckboxesState);
  };

  const handleUpdateConfirmationPopupClose = () => {
    setIsUpdateConfirmationPopupOpen(false);
    setCheckboxesState(DEFAULT_CHECKBOXES_STATE);
  };

  const openConfirmDialog = () => {
    setIsUpdateConfirmationPopupOpen(true);

    const checkboxesChangeStatus = {
      phoneNumberFormat: isPhoneNumberFormatChanged,
      currencyFormat: isCurrencyFormatChanged,
      dateFormat: isDateFormatChanged,
    };
    const checkboxesToShow = Object.keys(checkboxesChangeStatus).filter(
      (checkboxStatus) => checkboxesChangeStatus[checkboxStatus],
    );

    setShowedCheckboxes(checkboxesToShow);
  };

  const handlePhoneNumberFormatChange = ({ value }: Option) => setPhoneNumberFormat(value);

  const handleCurrencyFormatChange = ({ value }: Option) => setCurrencyFormat(value);

  const handleDateFormatChange = ({ value }: Option) => setDateFormat(value);

  return (
    <>
      <div className={styles.fields}>
        <CountryCodeSelect
          className={styles.fieldContainer}
          labelTitle="Phone Format"
          onChange={handlePhoneNumberFormatChange}
          value={phoneNumberFormat}
        />
        <AutoCompletion
          className={styles.fieldContainer}
          labelTitle="Currency Format"
          onChange={handleCurrencyFormatChange}
          value={currencyFormat}
          options={formattedCurrencies}
          searchedOptionAttrs={CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
        />
        <AutoCompletion
          className={styles.fieldContainer}
          labelTitle="Date Format"
          onChange={handleDateFormatChange}
          value={dateFormat}
          options={DATE_FORMATS}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          kind="primary"
          size="form"
          onClick={openConfirmDialog}
          disabled={!isSaveButtonEnabled}
          isLoading={isUpdatingInProgress}
        >
          Save Changes
        </Button>
        <Button className={styles.closeEditorButton} kind="secondary" size="form" onClick={onCloseEditor}>
          Close Editor
        </Button>
      </div>
      {isUpdateConfirmationPopupOpen && (
        <CompanyRegionSettingsConfirmDialog
          onSaveRegionSettings={saveRegionSettings}
          checkboxesState={checkboxesState!}
          onChangeCheckboxesState={handleChangeCheckboxesState}
          showedCheckboxes={showedCheckboxes}
          onClose={handleUpdateConfirmationPopupClose}
          isSavingInProgress={isUpdatingInProgress}
        />
      )}
    </>
  );
};

export default CompanyRegionSettingsEditForm;
