import Fetcher from 'middlewares/Fetcher';
import { VariablesApi } from 'api/Core/VariablesApi';
import { LIST_VARIABLES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/VariablesConfiguration/pagination';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { VariablesListActionType } from './ActionTypes';
import { GetListVariablesAction, getListVariablesSuccess, getListVariablesFailure } from './ActionCreator';
import { Variable } from 'Variables/VariablesTypes';

const GetListVariablesMiddleware = (api: VariablesApi) =>
  Fetcher<TableViewData<Variable> & { search: string }, GetListVariablesAction>(
    VariablesListActionType.GetListVariables,
    getListVariablesSuccess,
    getListVariablesFailure,
    async (action) => {
      const { search, allowStandardVariables } = action.payload;

      const response = await api.getVariables({
        search,
        offset: 0,
        count: LIST_VARIABLES_PER_PAGE_DEFAULT,
        onlyCustom: !allowStandardVariables,
      });

      return {
        search,
        ...response,
      };
    },
  );

export default GetListVariablesMiddleware;
