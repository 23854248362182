import { AnyAction, combineReducers } from 'redux';

import notificationReducer from './notification';
import leftNavReducer from 'MainLayout/leftNavReducer/LeftNavReducer';
import CompanyInformationReducer from 'CompanyInformation/CompanyInformationReducer';
import { AccountDetailsReducer } from 'AccountDetails';
import userOrganizations from 'UserOrganizations/UserOrganizationsReducer';
import generateFirstSecretWizardReducer from 'GenerateFirstSecretWizard/generateFirstSecretWizardReducer';
import generateSecondSecretWizardReducer from 'GenerateSecondSecretWizard/generateSecondSecretWizardReducer';
import { decisionStrategiesReducer } from 'DecisionStrategy';
import deleteStrategyWizardReducer from 'DeleteStrategyWizard/deleteStrategyWizardReducer';
import duplicateStrategyWizardReducer from 'DuplicateStrategyWizard/duplicateStrategyWizardReducer';
import EditCompanyUserReducer from 'EditCompanyUser/EditCompanyUserReducer';
import variablesReducer from 'Variables/VariablesReducer';
import variableStatusChangePopupReducer from 'VariableStatusChangePopup/Reducer';
import docuSignReducer from 'DocuSign/Reducer';
import decisionStrategyUpdatesReducer from 'DecisionStrategyUpdates/Reducer';
import DeleteDecisionStrategyModuleWizardReducer from 'DeleteDecisionStrategyModuleWizard/deleteDecisionStrategyModuleWizardReducer';
import NewStrategyVersionReducer from 'CreateNewStrategyVersion/NewStrategyVersionReducer';
import BranchInfoReducer from 'BranchInfo/Reducer';
import editExternalIntegrationVariablesPopUpReducer from 'EditExternalIntegrationVariablesPopUp/Reducer';
import externalIntegrationsReducer from 'ExternalIntegrationsList/ExternalIntegrationsReducer';
import loanOriginationSystemReducers from './loanOriginationSystem';
import RuleCreateReducer from 'RuleCreate/Reducer';
import StrategyGroupsReducer from 'StrategyGroupsData/Reducer';
import StrategiesReducer from 'Strategies/Reducer';
import ScoringModelReducer from 'ScoringModel/Reducer';
import duplicateModuleBranchReducer, { CopyModuleBranchRulesReducerState } from 'CopyModuleBranchRules/Reducer';
import ApiActivationReducer from 'ApiActivation/Reducer';
import deleteBranchWizardReducer from 'components/DeleteBranchWizard/deleteBranchWizardReducer';
import DeleteRulePopUpReducer from 'RuleDelete/Reducer';
import RuleEditReducer from 'RuleEdit/Reducer';
import changeStrategyStatusWizardReducer from 'components/ArchiveStrategyWizard/changeStrategyStatusWizardReducer';
import VersionsListDataReducer from 'VersionsViewData/Reducer';
import { ReduxState } from 'types/redux';
import { GlobalStoreActions, doesActionRequireStateReset } from 'stores/Actions';
import sendGridTemplatesReducer from 'SendGrid/Reducer';
import eSignTemplatesReducer from 'ESignTemplates/Reducer';
import RegionSettingsReducer from 'RegionSettings/Reducer';
import CompanyLogoUploadDialogReducer from 'CompanyLogoUpload/Reducer';
import loanOriginationSystemEmailsReducer from 'LoanOriginationSystemEmails/Reducer';
import VariablesAttributesReducer from 'VariablesAttributes/Reducer';
import snackbarReducer from 'Snackbar/Reducer';
import borrowerConfigurationReducer from 'LoanOriginationSystemBorrowerConfiguration/Reducer';
import { BorrowerConfigurationState } from 'LoanOriginationSystemBorrowerConfiguration/Types';
import intermediaryConfigurationReducer from 'LoanOriginationSystemIntermediaryConfiguration/Reducer';
import { IntermediaryConfigurationState } from 'LoanOriginationSystemIntermediaryConfiguration/Types';
import eSignIntegrationsReducer from 'ESignIntegrations/Reducer';
import { ESignTemplatesState } from 'ESignTemplates/Types';
import { ESignIntegrationsState } from 'ESignIntegrations/Types';
import companySettingsReducers from './companySettings';
import sessionInfoReducer from 'SessionInfo/Reducer';
import SessionInfoState from 'SessionInfo/Types';
import OrganizationUsersReducer from 'OrganizationUsers/OrganizationUserStore';
import deleteDecisionReducer from 'Decisions/DeleteDecisionStore';
import runDecisionReducer from 'Decisions/RunDecisionStore';
import decisionsReducer from 'Decisions/DecisionsStore';
import decisionReducer from 'Decisions/DecisionStore';
import decisionCasesReducer from 'Decisions/DecisionResultsStore';
import productSectionAccessPermissionsReducer from 'ProductSectionAccessPermissions/Reducer';
import permissionGroupsReducer from 'PermissionGroups/Reducer';
import VariablesSuggestionsReducer from 'VariablesSuggestions/VariablesSuggestionsStore';
import webhookEndpointsReducer from 'WebhookEndpoints/WebhookEndpointsStore';
import webhookEndpointsTableReducer from 'Webhooks/WebhookEndpointsTable/WebhookEndpointsTableStore';
import webhookEventsReducer from 'WebhookEvents/WebhookEventsStore';
import webhookEventsTableReducer from 'Webhooks/WebhookEventsTable/WebhookEventsTableStore';
import webhookEndpointDetailsReducer from 'Webhooks/WebhookEndpointDetails/WebhookEndpointDetailsStore';

const reducers = {
  sessionInfo: sessionInfoReducer as (state: SessionInfoState, action: AnyAction) => SessionInfoState,
  notification: notificationReducer,
  leftNavigation: leftNavReducer,
  accountDetails: AccountDetailsReducer,
  organizationInformation: CompanyInformationReducer,
  organizationUsers: OrganizationUsersReducer,
  userOrganizations,
  generateFirstSecretWizard: generateFirstSecretWizardReducer,
  generateSecondSecretWizard: generateSecondSecretWizardReducer,
  decisionStrategies: decisionStrategiesReducer,
  deleteStrategyWizard: deleteStrategyWizardReducer,
  duplicateStrategyWizard: duplicateStrategyWizardReducer,
  deleteBranchWizard: deleteBranchWizardReducer,
  changeStrategyStatusWizard: changeStrategyStatusWizardReducer,
  editCompanyUsersList: EditCompanyUserReducer,
  variables: variablesReducer,
  variableStatusChangePopup: variableStatusChangePopupReducer,
  docuSign: docuSignReducer,
  decisionStrategyUpdates: decisionStrategyUpdatesReducer,
  deleteDecisionStrategyModuleWizard: DeleteDecisionStrategyModuleWizardReducer,
  newStrategyVersion: NewStrategyVersionReducer,
  branchInfo: BranchInfoReducer,
  editExternalIntegrationVariablesPopUp: editExternalIntegrationVariablesPopUpReducer,
  externalIntegrations: externalIntegrationsReducer,
  ...loanOriginationSystemReducers,
  ruleCreate: RuleCreateReducer,
  strategyGroups: StrategyGroupsReducer,
  apiActivation: ApiActivationReducer,
  strategies: StrategiesReducer,
  versionsListData: VersionsListDataReducer,
  scoringModel: ScoringModelReducer,
  duplicateModuleBranch: duplicateModuleBranchReducer as (
    state: CopyModuleBranchRulesReducerState,
    action: AnyAction,
  ) => CopyModuleBranchRulesReducerState,
  deleteRulePopUp: DeleteRulePopUpReducer,
  ruleEdit: RuleEditReducer,
  sendGrid: sendGridTemplatesReducer,
  regionSettings: RegionSettingsReducer,
  companyLogoUploadDialog: CompanyLogoUploadDialogReducer,
  loanOriginationSystemEmails: loanOriginationSystemEmailsReducer,
  variablesAttributes: VariablesAttributesReducer,
  variablesSuggestions: VariablesSuggestionsReducer,
  snackbar: snackbarReducer,
  borrowerConfiguration: borrowerConfigurationReducer as (
    state: BorrowerConfigurationState,
    action: AnyAction,
  ) => BorrowerConfigurationState,
  intermediaryConfiguration: intermediaryConfigurationReducer as (
    state: IntermediaryConfigurationState,
    action: AnyAction,
  ) => IntermediaryConfigurationState,
  eSignIntegrations: eSignIntegrationsReducer as (
    state: ESignIntegrationsState,
    action: AnyAction,
  ) => ESignIntegrationsState,
  ...companySettingsReducers,
  eSignTemplates: eSignTemplatesReducer as (state: ESignTemplatesState, action: AnyAction) => ESignTemplatesState,
  productSectionAccessPermissions: productSectionAccessPermissionsReducer,
  permissionGroups: permissionGroupsReducer,
  deleteDecision: deleteDecisionReducer,
  runDecision: runDecisionReducer,
  decisions: decisionsReducer,
  decision: decisionReducer,
  decisionResults: decisionCasesReducer,
  webhookEndpoints: webhookEndpointsReducer,
  webhookEndpointsTable: webhookEndpointsTableReducer,
  webhookEvents: webhookEventsReducer,
  webhookEventsTable: webhookEventsTableReducer,
  webhookEndpointDetails: webhookEndpointDetailsReducer,
};

const combinedReducers = combineReducers<ReduxState>(reducers);

const rootReducer = (state: ReduxState, action: AnyAction) => {
  let nextState: Partial<ReduxState> = state;

  if (state && doesActionRequireStateReset(action)) {
    nextState = {
      notification: state.notification,
    };

    if (action.type === GlobalStoreActions.SwitchOrganization) {
      nextState.sessionInfo = state.sessionInfo;
      nextState.accountDetails = state.accountDetails;
    }
  }

  return combinedReducers(nextState as ReduxState, action);
};

export default rootReducer;
