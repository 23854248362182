import React, { FC, useState } from 'react';
import ConfirmPopup from 'components/ConfirmPopup';

interface DeleteDecisionPopUpContainerProps {
  closePopUp: () => void;
  onDeleteDecision: () => Promise<void>;
}

const DeleteDecisionPopUp: FC<DeleteDecisionPopUpContainerProps> = ({ closePopUp, onDeleteDecision }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await onDeleteDecision();
      setIsLoading(false);
      closePopUp();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <ConfirmPopup
      loading={isLoading}
      message="Are you sure you want to delete this decision?"
      confirmText="Delete Decision"
      declineText="No, Go Back"
      title="Delete Decision"
      onConfirmClick={handleDelete}
      onPopupClose={closePopUp}
    />
  );
};

export default DeleteDecisionPopUp;
