import decisionsPagination from 'pages/Decisions/Pagination';
import { IDecisionApi } from 'api/DecisionEngine/DecisionApi';

export default (api: IDecisionApi) => {
  return decisionsPagination.getItemsFetcherMiddleware(async (params) => {
    return api.getAll(
      {
        count: params.perPage,
        applicationId: params.applicationId,
        offset: (params.page - 1) * params.perPage,
        search: params.searchInputValue,
        selectedMembers: params.selectedMembers,
        decisionResults: params.decisionResults,
        decisionSource: params.decisionSource,
        strategyStatus: params.strategyStatus,
        strategyName: params.strategyName,
        decisionDate: {
          from: params.decisionDate?.from,
          to: params.decisionDate?.to,
        },
      },
      params.sortingType,
    );
  });
};
