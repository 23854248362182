import { DecisionsTableColumnDisplayName } from './types';

export const SIZES = {
  [DecisionsTableColumnDisplayName.Date]: 22,
  [DecisionsTableColumnDisplayName.StrategyName]: 20,
  [DecisionsTableColumnDisplayName.DecisionName]: 21,
  [DecisionsTableColumnDisplayName.Source]: 7,
  [DecisionsTableColumnDisplayName.ExecutionTime]: 10,
  [DecisionsTableColumnDisplayName.Result]: 16,
};
