import { createReducer } from '@reduxjs/toolkit';
import { ApplicationDocumentConfiguration } from 'api/LoanOriginationSystem/ApplicationDocumentConfigurationsApi';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  createApplicationDocumentConfiguration,
  getApplicationDocumentConfigurations,
  updateApplicationDocumentConfiguration,
  updateApplicationDocumentConfigurationRequiredAttribute,
  deleteApplicationDocumentConfiguration,
} from './Thunks';

export interface ApplicationDocumentConfigurationsState {
  configurationsById: Record<string, ApplicationDocumentConfiguration>;
  configurationIdsByProductId: Record<string, string[]>;
}

const initialState: ApplicationDocumentConfigurationsState = {
  configurationsById: {},
  configurationIdsByProductId: {},
};

const applicationDocumentConfigurationsReducer = createReducer<ApplicationDocumentConfigurationsState>(initialState, (builder) => {
  builder.addCase(getApplicationDocumentConfigurations.fulfilled, (state, action) => {
    const { configurations } = action.payload;

    state.configurationsById = {
      ...state.configurationsById,
      ...normalizeEntityArray(configurations),
    };

    state.configurationIdsByProductId = {
      ...state.configurationIdsByProductId,
      [action.payload.productId]: configurations.map(({ id }) => id),
    };
  });

  builder.addCase(createApplicationDocumentConfiguration.fulfilled, (state, action) => {
    const { configuration } = action.payload;

    state.configurationsById[configuration.id] = configuration;
    state.configurationIdsByProductId[configuration.productId]?.push(configuration.id);
  });

  builder.addCase(updateApplicationDocumentConfiguration.fulfilled, (state, action) => {
    const { configuration } = action.payload;

    state.configurationsById[configuration.id] = configuration;
  });

  builder.addCase(updateApplicationDocumentConfigurationRequiredAttribute.pending, (state, action) => {
    state.configurationsById[action.meta.arg.id].required = action.meta.arg.required;
  });

  builder.addCase(updateApplicationDocumentConfigurationRequiredAttribute.rejected, (state, action) => {
    state.configurationsById[action.meta.arg.id].required = !action.meta.arg.required;
  });

  builder.addCase(deleteApplicationDocumentConfiguration.fulfilled, (state, action) => {
    const configuration = state.configurationsById[action.payload.id];

    if (!configuration) {
      return;
    }

    delete state.configurationsById[action.payload.id];

    const configurationIds = state.configurationIdsByProductId[configuration.productId];

    state.configurationIdsByProductId[configuration.productId] = configurationIds?.filter((configurationId) => configurationId !== action.payload.id);
  });
});

export default applicationDocumentConfigurationsReducer;
