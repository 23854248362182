import Fetcher from 'middlewares/Fetcher';
import { RequestError } from 'utils/fetch';
import BorrowerHasApplicationsError from 'errors/BorrowerHasApplicationsError';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  DeleteBorrowerAction,
  deleteBorrowerFailure,
  deleteBorrowerSuccess,
  DeleteBorrowerSuccessResult,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';

const DeleteBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<DeleteBorrowerSuccessResult, DeleteBorrowerAction>(
    LoanOriginationSystemBorrowersActionTypes.DeleteBorrower,
    deleteBorrowerSuccess,
    deleteBorrowerFailure,
    async (action) => {
      try {
        await api.deleteBorrower(action.payload.borrowerId);

        return {
          name: action.payload.borrowerName,
        };
      } catch (err) {
        if (err instanceof RequestError && err.data && err.data.borrowerHasApplicationError) {
          throw new BorrowerHasApplicationsError(action.payload.borrowerId, action.payload.borrowerName);
        }

        throw err;
      }
    },
  );

export default DeleteBorrowerMiddleware;
