import React from 'react';
import { MenuProps } from '@material-ui/core';
import styles from './RestUsersPopupList.module.scss';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import UserAvatar from 'components/UserAvatar';

interface RestUsersPopupListProps extends MenuProps {
  users: UserInfo[];
  selectedUsers?: UserInfo[];
  onUserSelect?: (user: UserInfo) => void;
}

const RestUsersPopupList = ({ users, selectedUsers, onUserSelect, ...menuProps }: RestUsersPopupListProps) => {
  const isSelected = (user: UserInfo) => !!selectedUsers?.find(({ id }) => id === user.id);

  return (
    <ContextualPopUp
      {...menuProps}
      PopoverClasses={{ paper: styles.popup }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ maxHeight: styles.contextualPopUpMaxHeight }}
    >
      {users.map((user) => (
        <ActionPopUpItem className={styles.popup__user} onClick={() => onUserSelect?.(user)} key={user.id}>
          <UserAvatar
            firstName={user.firstName}
            lastName={user.lastName}
            imageId={user.imageId}
            active={isSelected(user)}
            className={styles.popup__user__image}
            size={10}
          />
          {user.firstName} {user.lastName}
        </ActionPopUpItem>
      ))}
    </ContextualPopUp>
  );
};

export default RestUsersPopupList;
