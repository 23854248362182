import React, { MouseEvent, useState } from 'react';
import clsx from 'clsx';
import { NewTask, Task, TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import styles from './TaskLine.module.scss';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import CustomCheckbox from 'components/CustomCheckbox';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import { TasksColumnSizes } from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TableColumnSizes';
import { TasksColumnSizesSmall } from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TableColumnSizesSmall';

const MAX_AVATARS_COUNT = 3;

interface TaskLineProps {
  task: Task;
  onTaskDeletePopupOpen: (taskIdToDelete: string) => void;
  onTaskEdit: (id: string) => void;
  onStatusChange: (id: string, editTaskData: Partial<NewTask>) => Promise<void>;
  size: 'normal' | 'small';
  showGoToApplicationAction: boolean;
}

const TaskLine = ({
  task,
  onTaskDeletePopupOpen,
  onTaskEdit,
  onStatusChange,
  size,
  showGoToApplicationAction,
}: TaskLineProps) => {
  const [isStatusChangeInLoading, setIsStatusChangeInLoading] = useState(false);
  const [isCheckboxInLoading, setIsCheckboxInLoading] = useState(false);

  const onTableRowClick = () => (task.status === TaskStatus.Done ? null : onTaskEdit(task.id));

  const onApplicationIdClickHandler = () => {
    if (!task.application) {
      return;
    }

    window.open(`${window.location.origin}/los/applications/${task.application.displayId}`, '_blank');
  };

  const handleStatusChange = async () => {
    await onStatusChange(task.id, { status: task.status === TaskStatus.Done ? TaskStatus.NotDone : TaskStatus.Done });
  };

  const onChangeStatusClick = async () => {
    setIsStatusChangeInLoading(true);
    await handleStatusChange();
    setIsStatusChangeInLoading(false);
  };

  const onCheckboxClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsCheckboxInLoading(true);
    await handleStatusChange();
    setIsCheckboxInLoading(false);
  };

  const generateRowActions = () => {
    const commonActions =
      showGoToApplicationAction && task.application
        ? [
            {
              title: 'Go To Application',
              handler: () => onApplicationIdClickHandler(),
              danger: false,
            },
          ]
        : [];

    if (task.status === TaskStatus.Done) {
      return (
        <RowActions
          actions={[
            ...commonActions,
            {
              title: 'Mark As Incomplete',
              handler: () => onChangeStatusClick(),
              danger: false,
              separatorRequired: true,
            },
            {
              title: 'Delete Task',
              handler: () => onTaskDeletePopupOpen(task.id),
              danger: true,
            },
          ]}
          isLoading={isStatusChangeInLoading}
          className={styles.actionsButton}
        />
      );
    }

    return (
      <RowActions
        actions={[
          ...commonActions,
          {
            title: 'Edit Task',
            handler: () => onTaskEdit(task.id),
            danger: false,
          },
          {
            title: 'Mark As Complete',
            handler: () => onChangeStatusClick(),
            danger: false,
            separatorRequired: true,
          },
          {
            title: 'Delete Task',
            handler: () => onTaskDeletePopupOpen(task.id),
            danger: true,
          },
        ]}
        isLoading={isStatusChangeInLoading}
        className={styles.actionsButton}
      />
    );
  };

  const commonColumnSizes = size === 'normal' ? TasksColumnSizes : TasksColumnSizesSmall;

  const addLineThrough = task.status === TaskStatus.Done && styles.doneTask;

  return (
    <TableRow
      useActions
      onClick={onTableRowClick}
      className={styles.line}
      disabled={isStatusChangeInLoading || isCheckboxInLoading}
    >
      <TableBodyCell
        width={commonColumnSizes.Checkbox}
        className={clsx(styles.checkBoxCell, isCheckboxInLoading && styles.checkboxInLoading)}
      >
        <CustomCheckbox
          className={styles.checkBox}
          checked={task.status === TaskStatus.Done}
          onClick={onCheckboxClick}
          classes={{ root: styles.checkBoxRoot }}
          isLoading={isCheckboxInLoading}
        />
      </TableBodyCell>
      <TableBodyCell width={commonColumnSizes.Description} className={clsx(addLineThrough)} overflowed>
        {task!.description}
      </TableBodyCell>
      {size === 'normal' && (
        <>
          <TableBodyCell className={clsx(addLineThrough)} width={TasksColumnSizes.ApplicationId} overflowed>
            {task.application ? formatDisplayId(task.application.displayId) : `-`}
          </TableBodyCell>
          <TableBodyCell width={TasksColumnSizes.BorrowerName} className={clsx(addLineThrough)} overflowed>
            {task.application ? task.application.borrowerFullName : `-`}
          </TableBodyCell>
        </>
      )}
      <TableBodyCell width={commonColumnSizes.TeamMembers} noPadding className={styles.teamMembersCell}>
        <UserAvatarInlineList source={task.teamMembers} maxCount={MAX_AVATARS_COUNT} size="small" />
      </TableBodyCell>
      <TableBodyCell width={commonColumnSizes.DueDate} className={clsx(addLineThrough)} overflowed>
        {formatDate(task.dueDate, DateTimeFormat.ShortDate)}
      </TableBodyCell>
      {generateRowActions()}
    </TableRow>
  );
};

export default TaskLine;
