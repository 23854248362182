import React from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import {
  ApplicationsTableSize,
  ApplicationsTableColumnSizes,
} from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import ApplicationLabels from 'components/LoanOriginationSystem/ApplicationLabels';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import TableRow from 'components/Table/TableRow';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { getFormattedBorrowerPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import ApplicationRowActions from 'components/LoanOriginationSystem/ApplicationsDashboard/ApplicationRowActions/ApplicationRowActions';
import CoBorrowersInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';
import WrapperWithLink from 'components/WrapperWithLink';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { StandardVariables } from 'Variables/VariablesTypes';
import WrapperWithTooltip from 'components/Tooltip';
import { DuplicateImage } from 'static/images';
import SearchHighlight from 'components/SearchHighlight';
import SearchMatchResults from 'components/SearchMatchResults';
import getSearchMatchingField from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable/getSearchMatchingField';
import TableBodyCellNoContent from 'components/Table/TableBodyCell/TableBodyCellNoContent';
import { DuplicateApplicationParams } from 'LoanOriginationSystemApplications/Types';
import { SimplifiedApplication } from 'api/LoanOriginationSystem/SimplifiedApplicationsApi';
import useUserPermissionGroupId from 'MyAccount/useUserPermissionGroupId';
import { BLANK_BORROWER_FULL_NAME } from 'LoanOriginationSystemBorrowers/utils';
import variables from 'components/RowActions/RowActions.module.scss';
import styles from './ApplicationTableRow.module.scss';

export interface ApplicationTableRowProps {
  application: SimplifiedApplication;
  currencySymbol: string;
  standardVariables: StandardVariables | null;
  deleteDisabled: boolean;
  searchInputValue: string;
  tableSize: ApplicationsTableSize;
  onClick: (displayId: string) => void;
  onEdit?: (id: string) => void;
  onDelete?: (displayId: string) => void;
  onDuplicate?: (params: DuplicateApplicationParams) => void;
}

const MAX_AVATARS_COUNT = 4;

const ApplicationTableRow = ({
  application,
  currencySymbol,
  standardVariables,
  deleteDisabled,
  searchInputValue,
  tableSize,
  onClick,
  onEdit,
  onDelete,
  onDuplicate,
}: ApplicationTableRowProps) => {
  const userPermissionGroupId = useUserPermissionGroupId()!;
  const borrowerPhoneNumber = application.variables[ApplicationDefaultVariable.Borrower.PhoneNumber];
  const formattedBorrowerPhoneNumber = getFormattedBorrowerPhoneNumber(
    borrowerPhoneNumber,
    userPermissionGroupId,
    standardVariables,
  );

  const loanAmount = application.variables[ApplicationDefaultVariable.LoanAmount] as number | null;

  const loanAmountDisplayValue =
    typeof loanAmount === 'number'
      ? formatMonetaryValue(application.variables[ApplicationDefaultVariable.LoanAmount] as number, currencySymbol)
      : '';

  return (
    <WrapperWithLink
      href={`/los/applications/${application.displayId}`}
      onClick={() => onClick(application.displayId)}
      className={styles.tableRowWrapper}
    >
      <TableRow
        key={application.id}
        useActions
        noBottomBorder={searchInputValue.trim().length > 0}
        className={styles.tableRow}
      >
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerFullName} className={styles.nameCell}>
          <ApplicationLabels labels={application.labels} />
          <div className={styles.nameContainer}>
            <OverflowedText className={clsx(styles.nameLine, !application.borrowerFullName && styles.blankNameLine)}>
              <SearchHighlight search={searchInputValue} multiWord>
                {application.borrowerFullName || BLANK_BORROWER_FULL_NAME}
              </SearchHighlight>
            </OverflowedText>
            <CoBorrowersInfo coBorrowerFullNames={application.coborrowerFullNames} />
          </div>
        </TableBodyCell>
        <TableBodyCell className={styles.displayId} width={ApplicationsTableColumnSizes[tableSize].DisplayId}>
          <OverflowedText>
            <SearchHighlight search={searchInputValue} multiWord>
              {formatDisplayId(application.displayId)}
            </SearchHighlight>
          </OverflowedText>
          {application.originalApplicationId && (
            <WrapperWithTooltip tooltip="Duplicated Application">
              <DuplicateImage className={styles.duplicateIcon} />
            </WrapperWithTooltip>
          )}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].CreatedAt} overflowed>
          {formatDate(application.createdAt, DateTimeFormat.Short)}
        </TableBodyCell>
        {tableSize !== ApplicationsTableSize.Small ? (
          <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].UpdatedAt} overflowed>
            {formatDate(application.updatedAt, DateTimeFormat.Short)}
          </TableBodyCell>
        ) : null}
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].Status} overflowed>
          {application.status.name}
        </TableBodyCell>
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerEmail} overflowed>
          <SearchHighlight search={searchInputValue} multiWord>
            {application.variables[ApplicationDefaultVariable.Borrower.Email]}
          </SearchHighlight>
        </TableBodyCell>
        {tableSize === ApplicationsTableSize.Wide ? (
          <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerPhoneNumber} overflowed>
            <SearchHighlight search={searchInputValue} multiWord>
              {formattedBorrowerPhoneNumber || borrowerPhoneNumber || <TableBodyCellNoContent />}
            </SearchHighlight>
          </TableBodyCell>
        ) : null}
        <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].TeamMembers} noPadding>
          {application.teamMembers.length > 0 && (
            <UserAvatarInlineList source={application.teamMembers} size="small" maxCount={MAX_AVATARS_COUNT} />
          )}
        </TableBodyCell>
        <TableBodyCell
          width={ApplicationsTableColumnSizes[tableSize].LoanAmount}
          className={styles.loanAmountCell}
          overflowed
        >
          {loanAmountDisplayValue}
        </TableBodyCell>
        <ApplicationRowActions
          onEdit={() => onEdit?.(application.displayId)}
          onDelete={() => onDelete?.(application.id)}
          onDuplicate={() => onDuplicate?.(application)}
          deleteDisabled={deleteDisabled}
        />
      </TableRow>
      {searchInputValue.trim().length > 0 ? (
        <TableRow className={styles.tableRow} noTopBorder>
          <TableBodyCell width={ApplicationsTableColumnSizes[tableSize].BorrowerFullName}> </TableBodyCell>
          <TableBodyCell width={100 - ApplicationsTableColumnSizes[tableSize].BorrowerFullName} dashedTopBorder>
            <SearchMatchResults
              searchInputValue={searchInputValue}
              searchHighlights={application.highlights ?? []}
              getMatchingField={getSearchMatchingField(
                (path) => get(application, path),
                searchInputValue,
                standardVariables,
                userPermissionGroupId,
              )}
            />
          </TableBodyCell>
          <TableBodyCell width={parseInt(variables.actionsMinWidth, 10)} pixels dashedTopBorder>
            {' '}
          </TableBodyCell>
        </TableRow>
      ) : null}
    </WrapperWithLink>
  );
};

export default ApplicationTableRow;
