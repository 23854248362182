import { mapKeys } from 'lodash';
import { PermissionGroupSelectOption, PermissionGroupCheckboxMultiSelectOption } from 'PermissionGroups/Types';
import { PermissionGroup } from 'api/Core/PermissionGroupsApi';

export const mapPermissionGroupIntoOption = (permissionGroup: PermissionGroup) =>
  mapKeys(permissionGroup, (_value, key) => (key === 'id' ? 'value' : key)) as PermissionGroupSelectOption;

export const mapPermissionGroupIntoCheckboxMultiOption = (permissionGroup: PermissionGroup) =>
  mapKeys(permissionGroup, (_value, key) =>
    key === 'name' ? 'label' : key,
  ) as PermissionGroupCheckboxMultiSelectOption;
