import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { ApplicationDetailsNormalizedCard } from 'api/LoanOriginationSystem/ApplicationDetailsCardsApi';
import {
  getApplicationDetailsCards,
  createApplicationDetailsCard,
  updateApplicationDetailsCard,
  deleteApplicationDetailsCard,
} from './Thunks';
import {
  createApplicationVariableConfiguration,
  deleteApplicationVariableConfiguration,
  updateApplicationVariableConfigurationPosition,
} from 'ApplicationVariableConfigurations/Thunks';
import {
  addVariableConfigurationToCard,
  deleteVariableConfigurationFromCard,
  updateCardsStore,
  updateVariableConfigurationOnCards,
} from 'Cards/Utils';

export interface ApplicationDetailsCardsState {
  cardsById: Record<string, ApplicationDetailsNormalizedCard>;
}

const initialState: ApplicationDetailsCardsState = {
  cardsById: {},
};

const applicationDetailsCardsReducer = createReducer<ApplicationDetailsCardsState>(initialState, (builder) => {
  builder.addCase(deleteApplicationDetailsCard.fulfilled, (state, action) => {
    delete state.cardsById[action.payload.id];
  });

  builder.addCase(deleteApplicationVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfigurationFromCard(state, action.payload.id);
  });

  builder.addCase(updateApplicationVariableConfigurationPosition.fulfilled, (state, action) => {
    updateVariableConfigurationOnCards(state, action.payload.sourceCardId, action.payload.configuration);
  });

  builder.addCase(createApplicationVariableConfiguration.fulfilled, (state, action) => {
    addVariableConfigurationToCard(state, action.payload.configuration);
  });

  builder.addMatcher(
    isFulfilled(getApplicationDetailsCards, createApplicationDetailsCard, updateApplicationDetailsCard),
    (state, action) => {
      updateCardsStore(state, action.payload.applicationDetailsCards);
    },
  );
});

export default applicationDetailsCardsReducer;
