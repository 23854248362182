import React, { FC } from 'react';
import { ProductCalculation } from 'api/LoanOriginationSystem/ProductCalculationsApi';
import { TableBodyCell, TableRow } from 'components/Table';
import { CalculationsTableColumnSize } from './TableColumnSizes';
import RowActions from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import AndSeparator from 'components/AndSeparator';
import styles from './CalculationRowItem.module.scss';

export interface CalculationRowItemProps {
  calculation: ProductCalculation;
  onDeleteCalculation: (calculation: ProductCalculation) => void;
  onEditCalculation: (calculation: ProductCalculation) => void;
}

const CalculationRowItem: FC<CalculationRowItemProps> = ({ calculation, onDeleteCalculation, onEditCalculation }) => {
  const actions: RowAction[] = [
    {
      title: 'Edit Calculation',
      handler: () => onEditCalculation(calculation),
      separatorRequired: true,
    },
    {
      title: 'Delete Calculation',
      danger: true,
      handler: () => onDeleteCalculation(calculation),
    },
  ];

  return (
    <TableRow className={styles.tableRow} onClick={() => onEditCalculation(calculation)} useActions>
      <TableBodyCell className={styles.tableBodyCell} asNameCell width={CalculationsTableColumnSize.VariableName}>
        {calculation.variable.displayName}
      </TableBodyCell>
      <TableBodyCell className={styles.tableBodyCell} width={CalculationsTableColumnSize.CalculationCode}>
        <pre className={styles.code}>
          <code>{calculation.code}</code>
        </pre>
      </TableBodyCell>
      <RowActions actions={actions} />
      <AndSeparator className={styles.andSeparator} />
    </TableRow>
  );
};

export default CalculationRowItem;
