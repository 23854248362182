import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import ApplicationHistoryRestApi, {
  ApplicationHistoryFilterType,
  ApplicationHistoryRecord,
  ApplicationHistorySortingType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { ApplicationHistoryActionType } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import responseHandlers from 'api/ResponseHandlers';

const applicationHistoryRestApi = new ApplicationHistoryRestApi(responseHandlers);

export const getApplicationHistory = createAsyncThunk<ApplicationHistoryRecord[], string, {}>(
  ApplicationHistoryActionType.GetApplicationHistory,
  (applicationId) => {
    return applicationHistoryRestApi.getRecords(applicationId);
  },
);

export const changeFilterType = createAction<ApplicationHistoryFilterType>(
  ApplicationHistoryActionType.ChangeFilterType,
);

export const changeSelectedMembers = createAction<UserInfo[]>(ApplicationHistoryActionType.ChangeSelectedMembers);

export const changeSortingType = createAction<ApplicationHistorySortingType>(
  ApplicationHistoryActionType.ChangeSortingType,
);

export const changeSearchValue = createAction<string>(ApplicationHistoryActionType.ChangeSearchValue);
