import { createReducer, isFulfilled } from '@reduxjs/toolkit';
import { BaseVariableConfiguration } from 'api/LoanOriginationSystem/Base/BaseVariableConfigurationsApi';
import {
  createIntermediaryVariableConfiguration,
  updateIntermediaryVariableConfigurationPosition,
  deleteIntermediaryVariableConfiguration,
  updateIntermediaryVariableConfigurationRequiredAttribute,
  updateConditionalDisplayRule,
} from './Thunks';
import {
  createIntermediaryProfileCard,
  getIntermediaryProfileCards,
  updateIntermediaryProfileCard,
} from 'IntermediaryProfileCards/Thunks';
import {
  deleteVariableConfiguration,
  updateVariableConfigurationStore,
} from 'LoanOriginationSystemVariablesConfiguration/Utils';

export interface IntermediaryVariableConfigurationsState {
  configurationsById: Record<string, BaseVariableConfiguration>;
}

const initialState: IntermediaryVariableConfigurationsState = {
  configurationsById: {},
};

const intermediaryVariableConfigurationsReducer = createReducer<IntermediaryVariableConfigurationsState>(initialState, (builder) => {
  builder.addCase(deleteIntermediaryVariableConfiguration.fulfilled, (state, action) => {
    deleteVariableConfiguration(state, action.payload.id);
  });

  builder.addCase(
    updateIntermediaryVariableConfigurationRequiredAttribute.pending,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = action.meta.arg.required;
    },
  );

  builder.addCase(
    updateIntermediaryVariableConfigurationRequiredAttribute.rejected,
    (state, action) => {
      state.configurationsById[action.meta.arg.id].required = !action.meta.arg.required;
    },
  );

  builder.addMatcher(
    isFulfilled(
      createIntermediaryVariableConfiguration,
      updateIntermediaryVariableConfigurationPosition,
      updateConditionalDisplayRule,
    ),
    (state, action) => {
      updateVariableConfigurationStore(state, {
        [action.payload.configuration.id]: action.payload.configuration,
      });
    },
  );

  builder.addMatcher(
    isFulfilled(getIntermediaryProfileCards, createIntermediaryProfileCard, updateIntermediaryProfileCard),
    (state, action) => {
      updateVariableConfigurationStore(state, action.payload.intermediaryVariableConfigurations);
    },
  );
});

export default intermediaryVariableConfigurationsReducer;
