import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import NavigationLinkId from 'enums/NavigationLinkId';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import ProductsDashboard from 'components/LoanOriginationSystem/ProductsDashboard';
import DuplicateProductConfirmationPopup from 'components/LoanOriginationSystem/ProductsDashboard/DuplicateProductConfirmationPopup';
import ChangeProductStatusConfirmPopup from 'components/LoanOriginationSystem/ProductsDashboard/ChangeProductStatusConfirmPopup';
import CreateProduct from 'components/LoanOriginationSystem/CreateProduct';
import ProductApiRequestsTemplatePopup from 'components/LoanOriginationSystem/ProductApiRequestsTemplatePopup';
import {
  changeProductStatus,
  downloadApiRequestTemplate,
  duplicateProduct,
  setProductToDuplicate,
  setStatusChangeData,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { selectProductForConfiguration } from 'LoanOriginationSystemProducts/ProductConfiguration/ActionCreator';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import { Product, ProductApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import styles from './Products.module.scss';
import ProductsFilter from 'components/LoanOriginationSystem/ProductsDashboard/Filters';

const leftNav = makeLeftNavigation(NavigationLinkId.Products, ApplicationSectionName.LoanOriginationSystem);

const Products = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();
  const history = useHistory();
  const closeContextualView = useCloseContextualView();
  const [isDownloadApiRequestTemplatesPopupOpen, setDownloadApiRequestTemplatesPopupOpen] = useState(false);

  useStateReset(LoanOriginationSystemProductsActionType.ResetState);

  const createProductFormAvailable = params.has('new');

  const {
    productToDuplicate,
    statusChangeData,
    isStatusUpdatingInProgress,
    isDownloadApiRequestTemplateInProgress,
    isDuplicatingInProgress,
    filters,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemProducts);

  const handleProductCreate = () => history.push('?new');

  const handleProductSelect = (product: Product) => {
    dispatch(selectProductForConfiguration(product));

    history.push(`/los/configuration/products/${product.id}/${ProductConfigurationStep.ApplicationFormConfiguration}`);
  };
  const handleConfirmProductDuplicatePopupClose = () => dispatch(setProductToDuplicate(null));
  const handleConfirmChangeProductStatusPopupClose = () => dispatch(setStatusChangeData(null));

  const handleConfirmChangeProductStatus = () => {
    if (!statusChangeData) {
      return;
    }

    dispatch(changeProductStatus(statusChangeData.product.id, statusChangeData.status));
  };

  const handleConfirmProductDuplicating = () => {
    if (!productToDuplicate) {
      return;
    }

    dispatch(duplicateProduct(productToDuplicate.id));
  };

  const handleDownloadApiRequestTemplatesClick = () => {
    setDownloadApiRequestTemplatesPopupOpen(true);
  };

  const handleDownloadApiRequestTemplatesPopupClose = () => {
    setDownloadApiRequestTemplatesPopupOpen(false);
  };

  const handleProductApiRequestTemplateDownload = async (
    productId: string,
    requestType: ProductApiRequestTemplateType,
  ) => {
    await dispatchRoutine(downloadApiRequestTemplate(productId, requestType));

    setDownloadApiRequestTemplatesPopupOpen(false);
  };

  const renderOverlay = () => {
    if (productToDuplicate) {
      return (
        <DuplicateProductConfirmationPopup
          productName={productToDuplicate.name}
          onPopupClose={handleConfirmProductDuplicatePopupClose}
          onConfirmClick={handleConfirmProductDuplicating}
          loading={isDuplicatingInProgress}
        />
      );
    }

    if (statusChangeData) {
      const { status, product } = statusChangeData;

      return (
        <ChangeProductStatusConfirmPopup
          product={product}
          status={status}
          onPopupClose={handleConfirmChangeProductStatusPopupClose}
          onConfirmClick={handleConfirmChangeProductStatus}
          loading={isStatusUpdatingInProgress}
        />
      );
    }

    if (isDownloadApiRequestTemplatesPopupOpen) {
      return (
        <ProductApiRequestsTemplatePopup
          onClose={handleDownloadApiRequestTemplatesPopupClose}
          onDownload={handleProductApiRequestTemplateDownload}
          isDownloadInProgress={isDownloadApiRequestTemplateInProgress}
        />
      );
    }

    return null;
  };

  const renderContextualView = () => {
    if (createProductFormAvailable) {
      return <CreateProduct onClose={closeContextualView} />;
    }

    return null;
  };

  const renderPageContent = () => {
    return (
      <ProductsDashboard
        onDownloadApiRequestTemplate={handleDownloadApiRequestTemplatesClick}
        onCreateProduct={handleProductCreate}
        onProductSelect={handleProductSelect}
      />
    );
  };

  const rightSidePopupView = filters.isPopUpVisible && (
    <ProductsFilter
      statuses={filters.statuses}
      borrowerType={filters.borrowerType}
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
    />
  );

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent className={styles.pageContent} noPadding>
          {renderPageContent()}
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Products;
