import { ItemsStateWithPagination } from 'pagination';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { VariablesSortingType } from 'api/Core/VariablesApi';
import { VariablesFiltersState } from './Filters/Types';
import { PermissionGroupId } from 'PermissionGroups/Types';

export interface VariablesState extends ItemsStateWithPagination<Variable> {
  isLoading: boolean;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<Variable>;
  };
  sortingType: VariablesSortingType;
  filters: VariablesFiltersState;
  showArchived: boolean;
}

export type VisualDataType =
  | 'Number'
  | 'Monetary'
  | 'Percentage'
  | 'List'
  | 'Text'
  | 'LargeText'
  | 'Boolean'
  | 'Date'
  | 'PhoneNumber'
  | 'EmailAddress'
  | 'IdentificationNumber';

export type DataType = 'Number' | 'String' | 'Date' | 'Boolean';

export type VariableNumberFormat = 'Monetary' | 'Percentage';

export type VariableStringFormat = 'PhoneNumber' | 'EmailAddress' | 'IdentificationNumber';

// TODO remove this after refactoring: Variable has no such attribute
export enum VariableValueType {
  JavaScript = 'javascript',
}

export enum VariableAccessPermissionType {
  ReadWrite = 'ReadWrite',
  ReadOnly = 'ReadOnly',
  PartialRead = 'PartialRead',
  NoAccess = 'NoAccess',
}

export type VariableAccessPermissions = Record<PermissionGroupId, VariableAccessPermissionType>;

export interface Variable extends NewVariableApiType {
  id: string;
  systemName: string;
  dataType: DataType;
  createDate: string;
  updateDate: string;
  createdBy: UserInfo;
  updatedBy: UserInfo;
  isStandard?: boolean;
  isArchived?: boolean;
  isNotFound?: true;
  dependsOn?: {
    id: string;
    systemName: string;
    displayName: string;
  } | null;
}

export interface VariableClientType {
  id?: string;
  displayName: string;
  systemName?: string;
  dataType: VisualDataType;
  description: string;
  optionsList: string[] | null;
  permissions: VariableAccessPermissions;
  phoneNumberFormat: string | null;
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
  currency: string | null;
  dateFormat: string | null;
}

export interface VariableAdvancedDataTypeAttributes {
  dataType: DataType;
  optionsList: string[] | null;
  numberFormat: VariableNumberFormat | null;
  stringFormat: VariableStringFormat | null;
  phoneNumberFormat: string | null;
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
  currency: string | null;
  dateFormat: string | null;
}

export interface NewVariableApiType extends VariableAdvancedDataTypeAttributes {
  description: string;
  displayName: string;
  permissions: VariableAccessPermissions;
}

export interface SavedVariableApiType extends NewVariableApiType {
  id: string;
}

type SimpleDataTypeWithNoAttributes = {
  dataType: Exclude<VisualDataType, 'PhoneNumber' | 'IdentificationNumber' | 'Monetary'>;
};

export type PhoneNumberWithAttributes = {
  dataType: 'PhoneNumber';
  phoneNumberFormat: string | null;
  phoneNumberWithFlag?: boolean;
};

export type IdentificationNumberWithAttributes = {
  dataType: 'IdentificationNumber';
  identificationNumberType: string | null;
  identificationNumberDescription: string | null;
};
export type MonetaryWithAttributes = {
  dataType: 'Monetary';
  currency: string | null;
};

export type DateWithAttributes = {
  dataType: 'Date';
  dateFormat: string | null;
};

export type ListWithAttributes = {
  dataType: 'List';
  optionsList: string[];
};

export type VisualDataTypeWithAttributes =
  | SimpleDataTypeWithNoAttributes
  | PhoneNumberWithAttributes
  | IdentificationNumberWithAttributes
  | MonetaryWithAttributes
  | DateWithAttributes
  | ListWithAttributes;

export type UndefinedVisualDataTypeWithNoAttributes = {
  dataType: undefined;
};

export type SimplifiedVariable = Omit<
  Variable,
  'updateDate' | 'createDate' | 'createdBy' | 'updatedBy' | 'description'
>;

export interface IVariableShortInfo {
  id: string;
  displayName: string;
  dataType: DataType;
  systemName: string;
  isArchived: boolean;
}

export type StandardVariables = Record<string, SimplifiedVariable>;
