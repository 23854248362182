import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { hideSnack } from './ActionCreator';
import {
  DocumentDownloadStatusSnackContent,
  EmailAttachmentsDownloadStatusSnackContent,
  SingleEmailAttachmentDownloadStatusSnackContent,
  SnackbarComponentTypes,
  SnackType,
} from './Types';
import { ReduxState } from 'types/redux';
import {
  downloadApplicationDocument,
  downloadArchiveOfApplicationDocuments,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';

export const DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION = 1500;

export const SetDownloadDocumentSnackLifeTimeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (
    isFulfilled(downloadApplicationDocument)(action) ||
    isRejected(downloadApplicationDocument)(action) ||
    isFulfilled(downloadArchiveOfApplicationDocuments)(action) ||
    isRejected(downloadArchiveOfApplicationDocuments)(action)
  ) {
    const { documentDownloadingId } = action.meta.arg;
    const { snackbar } = getState();

    const documentDownloadingStateSnacks = snackbar.filter(
      ({ content }) =>
        content.componentType === SnackbarComponentTypes.ApplicationDownloadDocumentComponent ||
        content.componentType === SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
    ) as SnackType<DocumentDownloadStatusSnackContent>[];

    const currentSnack = documentDownloadingStateSnacks.find(
      (snack) => snack.content.props.documentDownloadingId === documentDownloadingId,
    );

    if (currentSnack) {
      setTimeout(() => {
        dispatch(hideSnack(currentSnack.id));
      }, DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION);
    }
  }

  if (
    action.type === LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsSuccess ||
    action.type === LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsFailure
  ) {
    const { attachmentId } = action.payload;
    const { snackbar } = getState();

    const attachmentDownloadingStateSnacks = snackbar.filter(
      ({ content }) =>
        content.componentType === SnackbarComponentTypes.ApplicationEmailAttachmentsComponent ||
        content.componentType === SnackbarComponentTypes.ApplicationSingleEmailAttachmentComponent,
    ) as SnackType<EmailAttachmentsDownloadStatusSnackContent | SingleEmailAttachmentDownloadStatusSnackContent>[];

    const currentSnack = attachmentDownloadingStateSnacks.find(
      (snack) => snack.content.props.attachmentDownloadingId === attachmentId,
    );

    if (currentSnack) {
      setTimeout(() => {
        dispatch(hideSnack(currentSnack.id));
      }, DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION);
    }
  }
  return result;
}) as Middleware;

export default SetDownloadDocumentSnackLifeTimeMiddleware;
