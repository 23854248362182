import { Invoice, InvoicesSortingField } from 'api/BillingApi';
import { DataViewSortingType } from 'api/Types';
import { getInvoices } from 'BillingPage/ActionCreator';
import NoItems from 'components/NoItems';
import Table, { TableActionCell, TableBody, TableHead, TableHeadCell } from 'components/Table';
import useSorting from 'hooks/useSorting';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Invoices.module.scss';
import InvoicesTableRow from './InvoicesTableRow';
import InvoicesTableSkeletonRow from './InvoicesTableSkeletonRow';
import SIZES from './TableColumnSizes';

interface InvoicesProps {
  invoices: Invoice[] | null;
  sortingType: DataViewSortingType<InvoicesSortingField>;
}

const Invoices: FC<InvoicesProps> = ({ invoices, sortingType }) => {
  const dispatch = useDispatch();

  const [changeSorting, getSortingType] = useSorting(
    sortingType.field,
    sortingType.ascending,
    (field: InvoicesSortingField, ascending: boolean) => {
      dispatch(getInvoices({ field, ascending }));
    },
  );

  const renderRow = (invoice: Invoice) => {
    return <InvoicesTableRow key={invoice.invoiceNumber} invoice={invoice} />;
  };

  const renderSkeletons = () => {
    return Array.from({ length: 10 }).map((item, index) => <InvoicesTableSkeletonRow key={index} />);
  };

  useEffect(() => {
    dispatch(getInvoices(sortingType));
  }, []);

  return (
    <div className={styles.container}>
      <p className={styles.header}>Invoice History</p>
      {invoices?.length === 0 && (
        <NoItems
          title="No invoices have been added yet!"
          className={styles.noData}
          titleClassName={styles.noDataTitle}
        />
      )}
      {(!invoices || invoices.length !== 0) && (
        <>
          <Table>
            <TableHead sticky>
              <TableHeadCell
                width={SIZES.InvoiceDate}
                ascending={getSortingType(InvoicesSortingField.InvoiceDate)}
                onClick={() => changeSorting(InvoicesSortingField.InvoiceDate)}
              >
                Invoice Date
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.DueDate}
                ascending={getSortingType(InvoicesSortingField.DueDate)}
                onClick={() => changeSorting(InvoicesSortingField.DueDate)}
              >
                Due Date
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.Description}
                ascending={getSortingType(InvoicesSortingField.Description)}
                onClick={() => changeSorting(InvoicesSortingField.Description)}
              >
                Description
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.InvoiceNumber}
                ascending={getSortingType(InvoicesSortingField.InvoiceNumber)}
                onClick={() => changeSorting(InvoicesSortingField.InvoiceNumber)}
              >
                Invoice Number
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.Amount}
                ascending={getSortingType(InvoicesSortingField.Amount)}
                onClick={() => changeSorting(InvoicesSortingField.Amount)}
              >
                Amount
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.Status}
                ascending={getSortingType(InvoicesSortingField.Status)}
                onClick={() => changeSorting(InvoicesSortingField.Status)}
              >
                Status
              </TableHeadCell>
              {invoices && <TableActionCell />}
            </TableHead>
            <TableBody>{invoices ? invoices.map(renderRow) : renderSkeletons()}</TableBody>
          </Table>
        </>
      )}
    </div>
  );
};

export default Invoices;
