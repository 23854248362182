import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { LoanOriginationSystemCreateTaskActionTypes } from './ActionTypes';
import { getTasks } from 'LoanOriginationSystemTasks/ActionCreator';
import { CreateTaskSuccessAction } from './ActionCreator';

import getMessage, { MessageType } from 'constants/messages';

const isCreateTaskSuccessAction = (action: AnyAction): action is CreateTaskSuccessAction =>
  action.type === LoanOriginationSystemCreateTaskActionTypes.CreateTaskSuccess;

const CreateTaskSuccessMiddleware = (): Middleware => ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateTaskSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.TaskCreated), 'success', dispatch);

    dispatch(getTasks());
  }

  return result;
};

export default CreateTaskSuccessMiddleware;
