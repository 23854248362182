import React, { FC } from 'react';
import { CloseImage } from 'static/images';
import { UpdateWebhookEndpointParams, WebhookEndpoint } from 'api/Webhooks/WebhookEndpointsApi';
import { WebhookEvent } from 'api/Webhooks/WebhookEventsApi';
import WebhookEndpointDetails from 'components/Webhooks/WebhookEndpointDetails';
import styles from './WebhookEndpointDetailsContextualView.module.scss';

export interface WebhookEndpointDetailsContextualViewProps {
  webhookEndpoint: WebhookEndpoint | null;
  webhookEndpointId: string;
  onChangeWebhookStatus: (id: string, active: boolean) => void;
  onDeleteWebhook: (id: string) => void;
  onGenerateNewWebhookEndpointSecret: (id: string) => void;
  onDeleteWebhookEvent: (webhookEventId: string) => void;
  onResendWebhookEvent: (webhookEventId: string) => void;
  onWebhookEventClick: (webhookEvent: WebhookEvent) => void;
  onUpdateWebhookEndpoint: (webhookEndpointId: string, params: Partial<UpdateWebhookEndpointParams>) => Promise<void>;
  isWebhookEndpointUpdateInProgress?: boolean;
  onClose: () => void;
}

const WebhookEndpointDetailsContextualView: FC<WebhookEndpointDetailsContextualViewProps> = ({
  webhookEndpoint,
  webhookEndpointId,
  onChangeWebhookStatus,
  onDeleteWebhook,
  onGenerateNewWebhookEndpointSecret,
  onDeleteWebhookEvent,
  onWebhookEventClick,
  onResendWebhookEvent,
  onUpdateWebhookEndpoint,
  isWebhookEndpointUpdateInProgress,
  onClose,
}) => {
  return (
    <div className={styles.container}>
      <WebhookEndpointDetails
        webhookEndpoint={webhookEndpoint}
        webhookEndpointId={webhookEndpointId}
        onDeleteWebhook={onDeleteWebhook}
        onChangeWebhookStatus={onChangeWebhookStatus}
        onGenerateNewWebhookEndpointSecret={onGenerateNewWebhookEndpointSecret}
        onDeleteWebhookEvent={onDeleteWebhookEvent}
        onWebhookEventClick={onWebhookEventClick}
        onResendWebhookEvent={onResendWebhookEvent}
        onUpdateWebhookEndpoint={onUpdateWebhookEndpoint}
        isWebhookEndpointUpdateInProgress={isWebhookEndpointUpdateInProgress}
      />
      <CloseImage onClick={onClose} className={styles.closeImage} />
    </div>
  );
};

export default WebhookEndpointDetailsContextualView;
