import React, { FC, ReactNode } from 'react';
import Button from 'components/Button';

import styles from './DeletePopup.module.scss';

interface DeletePopupProps {
  message: string | ReactNode;
  handleDelete: () => void;
  handleClose: () => void;
  isLoading?: boolean;
  deleteBtnText?: string;
  cancelBtnDisabled?: boolean;
}

const DeletePopup: FC<DeletePopupProps> = ({
  handleDelete,
  handleClose,
  message,
  deleteBtnText,
  isLoading,
  cancelBtnDisabled,
}) => {
  return (
    <div className={styles.popUpBody}>
      <div className={styles.popUpBody__description}>{message}</div>
      <div className={styles.popUpBody__button}>
        <Button kind="primary" size="form" onClick={handleDelete} isLoading={isLoading}>
          {deleteBtnText || 'Delete'}
        </Button>
        <Button kind="secondary" size="form" onClick={handleClose} disabled={cancelBtnDisabled}>
          No, Go Back
        </Button>
      </div>
    </div>
  );
};

export default DeletePopup;
