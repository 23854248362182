import {
  ApplicationDocument,
  ApplicationDocumentType,
  ApplicationDocumentFile,
  ApplicationDocumentFolder,
} from 'api/LoanOriginationSystem/DocumentsApi';

export const isFolder = (document: ApplicationDocument): document is ApplicationDocumentFolder =>
  document.type === ApplicationDocumentType.Folder;

export const isFile = (document: ApplicationDocument): document is ApplicationDocumentFile =>
  document.type === ApplicationDocumentType.File;
