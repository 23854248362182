import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortColumnViewProductDataAction, SortTableViewProductDataAction, SelectProductAction } from './ActionCreator';
import {
  ChangePopupFiltersAction,
  ClearAllFiltersAction,
  FilterApplicationsByMembersAction,
} from './Filters/ActionCreator';
import {
  LoanOriginationSystemApplicationsActionType,
  SORT_TABLE_VIEW_PRODUCT_DATA,
  SELECT_PRODUCT,
} from './ActionTypes';
import { CHANGE_POPUP_FILTERS, FILTER_APPLICATIONS_BY_MEMBERS, ApplicationsActionType } from './Filters/ActionTypes';
import { LoanOriginationSystemApplicationsFiltersState } from './Filters/Types';
import { ApplicationsSortingType } from 'api/Types';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';

type ApplicationFiltersFieldsToSave = Pick<
  LoanOriginationSystemApplicationsFiltersState,
  | 'selectedMembers'
  | 'selectedStatusesIds'
  | 'selectedLabels'
  | 'selectedIntermediaries'
  | 'createdDateRange'
  | 'updatedDateRange'
>;

interface ApplicationFiltersToSave {
  filters: ApplicationFiltersFieldsToSave;
  selectedProduct: Product | null;
}

interface ApplicationSortingToSave {
  tableViewSortingType: ApplicationsSortingType;
  columnViewSortingType: ApplicationsSortingType;
}

const getFiltersToSave = (state: ReduxState): ApplicationFiltersToSave => {
  const { filters, selectedProduct } = state.loanOriginationSystemApplications;

  return {
    filters: pick(filters, [
      'selectedMembers',
      'selectedStatusesIds',
      'selectedLabels',
      'selectedIntermediaries',
      'createdDateRange',
      'updatedDateRange',
    ]),
    selectedProduct,
  };
};

const getSortingToSave = (state: ReduxState): ApplicationSortingToSave => {
  const { tableViewSortingType, columnViewSortingType } = state.loanOriginationSystemApplications;

  return {
    tableViewSortingType,
    columnViewSortingType,
  };
};

const saveTriggerActions = [
  isActionOfType<ChangePopupFiltersAction>(CHANGE_POPUP_FILTERS),
  isActionOfType<FilterApplicationsByMembersAction>(FILTER_APPLICATIONS_BY_MEMBERS),
  isActionOfType<SortColumnViewProductDataAction>(
    LoanOriginationSystemApplicationsActionType.SortColumnViewProductData,
  ),
  isActionOfType<SortTableViewProductDataAction>(SORT_TABLE_VIEW_PRODUCT_DATA),
  isActionOfType<ClearAllFiltersAction>(ApplicationsActionType.ClearAllFilters),
  isActionOfType<SelectProductAction>(SELECT_PRODUCT),
];

const applicationsFilterAndSortingSaver = FilterAndSortingSaver<ApplicationFiltersToSave, ApplicationSortingToSave>({
  key: 'applications',
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemApplicationsActionType.ResetState,
});

export default applicationsFilterAndSortingSaver;
