import { pick } from 'lodash';
import { ReduxState } from 'types/redux';
import FilterAndSortingSaver from 'filterAndSortingSaver';
import isActionOfType from 'utils/actions/isActionOfType';
import { SortTasksAction } from './ActionCreator';
import { FilterTasksByMembersAction, ChangePopupFiltersAction, ResetAllTasksFilters } from './Filters/ActionCreator';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import { LoanOriginationSystemTasksFiltersActionTypes } from './Filters/ActionTypes';
import { LoanOriginationSystemTasksFiltersState } from './Filters/Types';
import { TasksSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import isPathMatches from 'utils/isPathMatches';

type TasksFiltersToSave = Pick<
  LoanOriginationSystemTasksFiltersState,
  'selectedMembers' | 'selectedStatus' | 'dueDateRange'
>;

const getFiltersToSave = (state: ReduxState) => {
  const { filters } = state.loanOriginationSystemTasks;

  return pick(filters, ['selectedMembers', 'selectedStatus', 'dueDateRange']);
};

const getSortingToSave = (state: ReduxState) => {
  const { sortingType } = state.loanOriginationSystemTasks;

  return sortingType;
};

const saveTriggerActions = [
  isActionOfType<FilterTasksByMembersAction>(LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers),
  isActionOfType<ChangePopupFiltersAction>(LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters),
  isActionOfType<ResetAllTasksFilters>(LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters),
  isActionOfType<SortTasksAction>(LoanOriginationSystemTasksActionTypes.SortTasks),
];

const isTasksPage = isPathMatches('/los/tasks');
const isApplicationTasksPage = isPathMatches('/los/applications/:id');

const tasksFilterAndSortingSaver = FilterAndSortingSaver<TasksFiltersToSave, TasksSortingType>({
  key: [
    ['tasks', isTasksPage],
    ['applicationTasks', isApplicationTasksPage],
  ],
  getFiltersToSave,
  getSortingToSave,
  saveTriggerActions,
  resetActionType: LoanOriginationSystemTasksActionTypes.ResetState,
  filtersSwitchActionType: LoanOriginationSystemTasksActionTypes.SavedFiltersSwitch,
});

export default tasksFilterAndSortingSaver;
