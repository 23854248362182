import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import { SkeletonSubtitle } from 'components/LoanOriginationSystem/ApplicationDetails/Subtitle';
import { SkeletonInfoPanel } from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel';
import styles from './ApplicationDetailsSkeleton.module.scss';

const ApplicationDetailsSkeleton = () => (
  <div className={styles.skeletonHeader}>
    <div className={styles.skeletonTitle}>
      <div>
        <SkeletonRectangle width="500px" height="44px" color="primary20" marginBottom="8px" />
        <div>
          <SkeletonRectangle width="164px" height="44px" color="primary6" />
          <SkeletonRectangle width="44px" height="44px" color="primary6" />
        </div>
      </div>
      <SkeletonSubtitle />
    </div>
    <SkeletonInfoPanel />
  </div>
);

export default ApplicationDetailsSkeleton;
