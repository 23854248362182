import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import BorrowerTypeToggleGroup from 'components/LoanOriginationSystem/BorrowerTypeToggleGroup';
import { CloseButtonImage } from 'static/images';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { getBorrowerProfileCardsSelector } from 'BorrowerProfileCards/Selectors';
import { getBorrowerProfileCards } from 'BorrowerProfileCards/Thunks';
import { FormLayoutData, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerVariableConfiguration } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import { createBorrower } from 'LoanOriginationSystemBorrowers/CreateBorrower/ActionCreator';
import { formatDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import WithFieldsValidationButton from 'components/WithFieldsValidationButton';
import CardsForm from 'components/LoanOriginationSystem/CardsForm';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import useConditionalDisplayLogic from 'hooks/useConditionalDisplayLogic';
import useCardFields from 'hooks/useCardFields';
import useUnsavedChanges from 'hooks/useUnsavedChanges';
import styles from './CreateBorrower.module.scss';

interface CreateBorrowerProps {
  onClose: () => void;
}

const INITIAL_BORROWER_DATA = {};

const CreateBorrower = ({ onClose }: CreateBorrowerProps) => {
  const dispatch = useDispatch();

  const [borrowerData, setBorrowerData] = useState<FormLayoutData>(INITIAL_BORROWER_DATA);
  const [borrowerType, changeBorrowerType] = useState<BorrowerType>(BorrowerType.Person);

  const cards = useSelector((state: ReduxState) => getBorrowerProfileCardsSelector(state, borrowerType));

  const { isCreating } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers.createBorrower);

  const fields = useCardFields(cards);
  const displayFieldsAttributes = useConditionalDisplayLogic(fields, borrowerData);
  const validateBorrowerData = useConfigurableFormValidation(fields);

  const [
    displayUnsavedChanges,
    onLeaveUnsavedChanges,
    onConfirmUnsavedChange,
    useCallbackWithUnsavedChanges,
  ] = useUnsavedChanges(() => Object.values(borrowerData).some((value) => !isEmptyVariableValue(value)));

  useEffect(() => {
    dispatch(getBorrowerProfileCards(borrowerType));

    setBorrowerData(INITIAL_BORROWER_DATA);
  }, [borrowerType]);

  const handleBorrowerCreate = () => dispatch(createBorrower(borrowerType, borrowerData));

  const handleFieldChange = useCallback(({ variable }: BorrowerVariableConfiguration, value: VariableValue) => {
    setBorrowerData((prevBorrowerData) => ({
      ...prevBorrowerData,
      [variable.systemName]: value,
    }));
  }, []);

  const handleClose = useCallbackWithUnsavedChanges(() => {
    onClose();
  });

  const renderForm = () => (
    <CardsForm
      className={styles.cardsForm}
      cards={cards}
      data={borrowerData}
      onFieldChange={handleFieldChange}
      formatVariableConfigurationDisplayTitle={formatDisplayTitle}
      displayHeader={false}
      isEditMode
      displayFieldsAttributes={displayFieldsAttributes}
      displaySkeleton={!displayFieldsAttributes}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Add Borrower</h2>
        </div>
        <BorrowerTypeToggleGroup
          selectedBorrowerType={borrowerType}
          onChange={(type) => changeBorrowerType(type)}
          availableBorrowerTypes={Object.values(BorrowerType)}
        />
        {renderForm()}
        <WithFieldsValidationButton
          size="form"
          kind="primary"
          className={styles.createButton}
          disabled={!cards}
          onClick={handleBorrowerCreate}
          isLoading={isCreating}
          areFieldsInvalid={!validateBorrowerData(borrowerData, displayFieldsAttributes)}
        >
          Add Borrower
        </WithFieldsValidationButton>
        <CloseButtonImage className={styles.closeButton} onClick={handleClose} />
      </div>
      {displayUnsavedChanges && <UnsavedChangesPopup
        onPopupClose={onLeaveUnsavedChanges}
        onLeaveClick={onConfirmUnsavedChange}
        usePortal
      />}
    </div>
  );
};

export default CreateBorrower;
